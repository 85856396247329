export default [
  {
    name: global._('Breadcrumbs.Dashboard'),
    path: '/'
  },
  {
    name: global._('Breadcrumbs.Properties'),
    path: '/properties'
  },
  {
    name: global._('Breadcrumbs.PropertyDetails'),
    path: '/properties/:propertyId'
  },
  {
    name: global._('Breadcrumbs.Blueprint'),
    path: '/properties/:propertyId/blueprints/:blueprintId'
  },
  {
    name: global._('Breadcrumbs.Documents'),
    path: '/documents'
  },
  {
    name: global._('Breadcrumbs.Admin'),
    path: '/admin'
  },
  {
    name: global._('Breadcrumbs.OrderSettings'),
    path: '/admin/settings/orders'
  },
  {
    name: global._('Breadcrumbs.Partners'),
    path: '/admin/partners'
  },
  {
    name: global._('Breadcrumbs.Customers'),
    path: '/admin/customers'
  },
  {
    name: global._('Breadcrumbs.Organisation'),
    path: '/admin/:organisationId'
  },
  {
    name: global._('Breadcrumbs.AccessGroups'),
    path: '/admin/accessgroups'
  },
  {
    name: global._('Breadcrumbs.AccessGroupNew'),
    path: '/admin/accessgroups/new'
  },
  {
    name: global._('Breadcrumbs.AccessGroupDetails'),
    path: '/admin/accessgroups/details/:id'
  },
  {
    name: global._('Breadcrumbs.Users'),
    path: '/admin/users'
  },
  {
    name: global._('Breadcrumbs.UserImport'),
    path: '/admin/users/import'
  },
  {
    name: global._('Breadcrumbs.Apartments'),
    path: '/admin/apartments/'
  },
  {
    name: global._('Breadcrumbs.Residents'),
    path: '/admin/apartments/users'
  },
  {
    name: global._('Breadcrumbs.ApartmentImport'),
    path: '/admin/apartments/import'
  },
  {
    name: global._('Breadcrumbs.KEEHistory'),
    path: '/admin/kee-history'
  },
  {
    name: global._('Breadcrumbs.KEEDevicesAndLocks'),
    path: '/admin/devices'
  },
  {
    name: global._('Breadcrumbs.KEEDeviceDetails'),
    path: '/admin/devices/:id'
  },
  {
    name: global._('Breadcrumbs.KEELocks'),
    path: '/admin/devices/:orgId/locks/:id'
  },
  {
    name: global._('Breadcrumbs.Cases'),
    path: '/cases'
  },
  {
    name: global._('Breadcrumbs.CaseNew'),
    path: '/cases/new'
  },
  {
    name: global._('Breadcrumbs.CaseDetails'),
    path: '/cases/:id'
  },
  {
    name: global._('Breadcrumbs.MyAccount'),
    path: '/myaccount'
  },
  {
    name: global._('Breadcrumbs.ChangePassword'),
    path: '/myaccount/passwordupdate'
  },
  {
    name: global._('Breadcrumbs.MyOrganisation'),
    path: '/my-organisation'
  },
  {
    name: global._('Breadcrumbs.Organisation'),
    path: '/organisations/:id'
  },
  {
    name: global._('Breadcrumbs.MyKeys'),
    path: '/my-keys'
  },
  {
    name: global._('Breadcrumbs.KeyManagement'),
    path: '/keys'
  },
  {
    name: global._('Breadcrumbs.Keys'),
    path: '/keys/list'
  },
  {
    name: global._('Breadcrumbs.LockSystems'),
    path: '/keys/systems'
  },
  {
    name: global._('Breadcrumbs.LockSystemDetails'),
    path: '/keys/systems/:lockSystemId'
  },
  {
    name: global._('Breadcrumbs.Key'),
    path: '/keys/systems/:lockSystemId/keys/:keyId'
  },
  {
    name: global._('Breadcrumbs.KeyImport'),
    path: '/keys/list/import'
  },
  {
    name: global._('Breadcrumbs.KeyNew'),
    path: '/keys/list/new'
  },
  {
    name: global._('Breadcrumbs.KeyRings'),
    path: '/keys/keyrings'
  },
  {
    name: global._('Breadcrumbs.KeyStorages'),
    path: '/keys/repositories'
  },
  {
    name: global._('Breadcrumbs.KeyStorage'),
    path: '/keys/repositories/:repositoryId'
  },
  {
    name: global._('Breadcrumbs.Key'),
    path: '/keys/repositories/:repositoryId/keys/:keyId'
  },
  {
    name: global._('Breadcrumbs.KeyReceiptTemplates'),
    path: '/keys/receipt-templates'
  },
  {
    name: global._('Breadcrumbs.KeyReceiptTemplate'),
    path: '/keys/receipt-templates/new'
  },
  {
    name: global._('Breadcrumbs.KeyReceiptTemplate'),
    path: '/keys/receipt-templates/:id'
  },
  {
    name: global._('Breadcrumbs.KeyInventory'),
    path: '/keys/inventory'
  },
  {
    name: global._('Breadcrumbs.KeyInventoryNew'),
    path: '/keys/inventory/new'
  },
  {
    name: global._('Breadcrumbs.KeySettings'),
    path: '/keys/settings'
  },
  {
    name: global._('Breadcrumbs.IDControl'),
    path: '/id-control'
  },
  {
    name: global._('Breadcrumbs.IDControlDetails'),
    path: '/id-control/:referenceId'
  },
  {
    name: global._('Breadcrumbs.KeyOrder'),
    path: '/orders'
  },
  {
    name: global._('Breadcrumbs.Order'),
    path: '/orders/:id'
  },
  {
    name: global._('Breadcrumbs.Contact'),
    path: '/contact'
  }
]
