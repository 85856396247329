/**
 * Simple shell page to display the Success component.
 *
 * match.params.part2 will contain the newly created partner id
 *
 * Check Routes.js for more info on url params
 */
import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import Success from '../../../components/Success'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'

const Shell = props => {
  const { history, match, location } = props
  let baseUrl = '/admin/users'
  if (location && location.state && location.state.type === 'servicedesk') {
    baseUrl = '/admin/servicedesk'
  }
  return (
    <Success>
      <Typography
        variant='subtitleBig'
        style={{
          marginBottom: 28
        }}>
        {global._('Admin.SuccessAddUser')}
      </Typography>
      <Button
        style={{ width: '100%' }}
        variant='primary'
        onClick={() => history.push(baseUrl)}>
        {global._('Admin.AddAdditionalUser')}
      </Button>
      <Button
        style={{ width: '100%' }}
        variant='outlined'
        onClick={() =>
          history.push(
            `/admin/users/${match.params.organisationId}/${match.params.userId}`
          )
        }>
        {global._('Admin.ToUser')}
      </Button>
      <Button
        variant='none'
        onClick={() =>
          history.push(`${baseUrl}/${match.params.organisationId}`)
        }>
        {global._('Common.Back')}
      </Button>
    </Success>
  )
}

Shell.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

const ShellWithRouter = withRouter(Shell)
export default ShellWithRouter
