import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import useWindowDimensions from '../../utils/useWindowDimensions'
import Paper from '../../components/Paper'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import FullscreenModal from '../../components/FullscreenModal'
import { history } from '../../redux/store'

const styles = {
  root: {}
}

const ServiceDeactivated = ({ user, classes, scope }) => {
  const { isMobile } = useWindowDimensions()

  const content = (
    <>
      <Typography
        variant='h1'
        block
        align='center'
        style={{ marginTop: isMobile ? 50 : 0, marginBottom: 25 }}
      >
        {global._('ServiceDeactivated.Title')}
      </Typography>
      <Typography
        variant='subtitleBig'
        block
        align='center'
        style={{ marginBottom: isMobile ? 50 : 30 }}
      >
        {global._('ServiceDeactivated.ContactAdmin')}
      </Typography>
      <Button variant='primary' onClick={() => history.push('/')}>
        {global._('ServiceDeactivated.BackToHomepage')}
      </Button>
    </>
  )

  if (isMobile) {
    return (
      <FullscreenModal
        title={global._(`Services.${scope}`)}
        onClose={() => history.push('/')}
        padding
        backgroundColor='var(--color-material-grey)'
        content={content}
      />
    )
  }
  return (
    <Paper
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '70px auto 0',
        paddingBottom: 50
      }}
      biggerPadding
      fixedWidth='800'
    >
      <h2 style={{ fontSize: 46, fontWeight: 300, width: '100%' }}>
        {global._(`Services.${scope}`)}
      </h2>
      {content}
    </Paper>
  )
}

ServiceDeactivated.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object
}

ServiceDeactivated.defaultProps = {
  user: undefined
}

const ServiceDeactivatedWithStyles = injectSheet(styles)(ServiceDeactivated)
export default ServiceDeactivatedWithStyles
