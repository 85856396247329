import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import qs from 'querystring'
import ObjectListMobile from 'src/components/ObjectListMobile'
import NavigationBar from '../../components/NavigationBar'
import Notification from '../../common/Notification'
import Typography from '../../components/Typography'
import ScrollView from '../../components/ScrollView'
import PullToRefresh from '../../components/PullToRefresh'
import withUser from '../../utils/withUser'
import MobileContainer from '../../components/MobileContainer'

const styles = {
  root: {
    background: 'var(--color-white)',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%'
  },
  /* Mobile */
  '@media (max-width: 900px)': {
    root: {
      padding: 0,
      paddingBottom: 30
    }
  }
}

const LockEvents = props => {
  const { classes, history, user } = props

  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({
    limit: 8,
    orderBy: 'createdAt',
    order: 'DESC'
  })
  const [error, setError] = useState(null)

  const fetch = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const { data } = await axios.get(
        `/v1/locks/events?${qs.stringify(filter)}`
      )

      if (filter.offset === 0) {
        setEvents(data)
      } else {
        setEvents([...events, ...data])
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [filter])

  useEffect(() => {
    setFilter({ ...filter, offset: 0 })
  }, [])

  const columns = [
    {
      key: 'icon',
      format: () => <Icon style={{ fontSize: 20 }}>lock_open</Icon>
    },
    {
      key: 'title',
      bold: true,
      format: (one, two, obj) => {
        const op = global._(`KeeHistory.Operations.${obj.operation}`)
        return (
          <Typography
            variant='body'
            ellipsis
            block
            style={{ maxWidth: '95%' }}
          >{`${op} ${obj.lockName}`}</Typography>
        )
      }
    },
    {
      key: 'subtitle',
      format: (one, two, obj) => {
        const subtitle = `${obj.actor} ${moment(obj.createdAt).format(
          'YYYY/MM/DD [kl] HH:mm'
        )}`
        return (
          <Typography
            variant='bodyFaded'
            style={{
              fontSize: 14,
              lineHeight: 1.5,
              marginBottom: 3
            }}
          >
            {subtitle}
          </Typography>
        )
      }
    }
  ]

  return (
    <MobileContainer background='var(--color-white)'>
      <NavigationBar
        title={global._('Locks.Events.Title')}
        backAction={() => history.push('/mylocks')}
      />
      <ScrollView id='scrollable-container'>
        <PullToRefresh
          onRefresh={() => setFilter({ ...filter, offset: 0 })}
          loading={isLoading}
        >
          <div className={classnames('noselect', classes.root)}>
            {error && <Notification type='error' message={error} />}
            <ObjectListMobile
              rowsAreClickable={false}
              objects={events}
              user={user}
              history={history}
              noResultsMessage={global._('Locks.Events.NoEventsAvailable')}
              columns={columns}
              canFetchMoreObjects={
                events.length === filter.offset + filter.limit
              }
              fetchMoreObjects={() =>
                setFilter({ ...filter, offset: filter.offset + filter.limit })
              }
              isLoadingObjects={isLoading}
            />
          </div>
        </PullToRefresh>
      </ScrollView>
    </MobileContainer>
  )
}

LockEvents.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

export default withUser(injectSheet(styles)(LockEvents))
