import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import { getMaxLockShareLimit } from 'src/utils/helpers'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import Button from '../../components/Button'
import SingleLockLayout from '../../components/SingleLock'
import withUser from '../../utils/withUser'
import MobileContainer from '../../components/MobileContainer'

const styles = {}

const SingleLock = props => {
  const { history, user, match } = props

  /* State */
  const [lock, setLock] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const { lockId } = match.params

  const getLock = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const { data } = await axios.get(
        `/v1/organisations/${user.organisation.id}/locks/${lockId}?accessible=true`
      )
      setLock(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setLock(null)
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getLock()
  }, [])

  const { isMobile } = useWindowDimensions()

  if (!isMobile) {
    history.push('/dashboard')
  }

  const canBeShared = () =>
    lock.device.shareable && lock.shares < getMaxLockShareLimit(lock)

  return (
    <MobileContainer>
      <NavigationBar
        title={(lock && lock.name) || ''}
        backAction={() => history.push('/mylocks')}
      />
      <ScrollView
        style={{ backgroundColor: 'var(--color-black)' }}
        id='scrollable-container'
      >
        <div style={{ padding: 'var(--section-padding-default)' }}>
          <SingleLockLayout
            lock={lock}
            setLock={setLock}
            history={history}
            user={user}
            error={error}
            setError={setError}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            button={() => (
              <Button
                variant='primary'
                disabled={!canBeShared(lock)}
                style={{ marginBottom: 40 }}
                onClick={() =>
                  canBeShared(lock) && history.push(`/mylocks/share/${lock.id}`)
                }
              >
                {canBeShared(lock)
                  ? global._('SingleLock.DelegateLock')
                  : global._('SingleLock.NonDelegable')}
              </Button>
            )}
          />
        </div>
      </ScrollView>
    </MobileContainer>
  )
}

SingleLock.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const SingleLockWithStyles = injectSheet(styles)(SingleLock)
const SingleLockWithStylesAndUser = withUser(SingleLockWithStyles)
export default SingleLockWithStylesAndUser
