import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Button from '../Button'
import Modal from '../Modal'
import { parseReceiptTermsContent } from '../../utils/helpers'

const styles = {
  root: {}
}

const KeyReceiptTermsModal = ({
  classes,
  setShowModal,
  content,
  title,
  cancelBtnLabel
}) => (
  <Modal
    onClose={() => setShowModal(false)}
    title={title}
    content={
      // eslint-disable-next-line react/no-danger
      <div
        dangerouslySetInnerHTML={{ __html: parseReceiptTermsContent(content) }}
      />
    }
    buttons={[
      <Button
        variant='none'
        key='show-modal-btn'
        onClick={() => {
          setShowModal(false)
        }}>
        {cancelBtnLabel || global._('Common.Close')}
      </Button>
    ]}
  />
)

KeyReceiptTermsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  setShowModal: PropTypes.func,
  content: PropTypes.any,
  title: PropTypes.string,
  cancelBtnLabel: PropTypes.string
}

KeyReceiptTermsModal.defaultProps = {
  setShowModal: undefined,
  content: null,
  title: null,
  cancelBtnLabel: null
}

const KeyReceiptTermsModalWithStyles = injectSheet(styles)(KeyReceiptTermsModal)
export default KeyReceiptTermsModalWithStyles
