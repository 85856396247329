import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import { truncate } from 'lodash'
import { Element, scroller } from 'react-scroll'
import Typography from '../Typography'
import Button from '../Button'
import Input from '../Input'
import Notification from '../../common/Notification'
import BlueprintContainer from './BlueprintContainer'
import NavigationItem from './NavigationItem'
import ConfirmationModal from '../ConfirmationModal'
import ObjectModal from './modals/ObjectModal'
import Table from '../Table'
import Row from '../Row'
import Column from '../Column'
import FullscreenModal from '../FullscreenModal'
import ItemModal from './modals/ItemModal'
import {
  UserRoles,
  BlueprintObjectItemTypes,
  BlueprintObjectTypes
} from '../../utils/constants'
import Content from '../Content'
import ScrollView from '../ScrollView'
import NavigationBar from '../NavigationBar'
import TabBar from '../TabBar'
import TabBarItem from '../TabBarItem'
import useWindowDimensions from '../../utils/useWindowDimensions'
import PageContentHeader from '../PageContentHeader'
import UploadBlueprint from './modals/UploadBlueprint'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    maxWidth: '100vw'
  },
  checkCircleIcon: {
    position: 'absolute',
    top: 14,
    right: 10,
    width: 24,
    height: 24,
    color: 'var(--color-primary)'
  },
  errorContainer: {
    display: 'flex',
    minHeight: 300,
    justifyContent: 'center',
    alignItems: 'center'
  },
  rightNavigation: {
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-end',
    maxWidth: 320,
    minWidth: 320,
    minHeight: 'calc(100vh - 80px)',
    maxHeight: 'calc(100vh - 80px)',
    zIndex: 100
  },
  rightNavigationHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '25px 20px',
    height: 70,
    backgroundColor: 'var(--color-logo-black)'
  },
  rightNavigationContentContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: 'calc(100% - 70px)',
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
    borderLeft: '1px solid var(--color-middle-grey-2)'
  },
  rightNavigationPlaceholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    flex: 2,
    backgroundColor: 'transparent',
    marginTop: '-90px',
    zIndex: 100,
    pointerEvents: 'none',
    minHeight: '90px' // Solves an issue in Safari
  },
  rightNavigationObjectsContainer: {
    overflow: 'auto',
    paddingBottom: '90px',
    height: '100%',
    position: 'relative',
    userSelect: 'none'
  },
  rightNavigationObjectContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid var(--color-light-grey-2)'
  },
  rightNavigationObject: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 50,
    border: '1px solid var(--color-light-grey-2)',
    borderBottom: '1px solid transparent',
    borderTop: '1px solid transparent',
    borderLeft: '5px solid var(--color-logo-black)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'var(--color-primary-tone)'
    }
  },
  rightNavigationObjectHighlighted: {
    borderLeftColor: 'var(--color-primary)',
    backgroundColor: 'var(--color-primary-tone)',
    color: 'var(--color-text)'
  },
  rightNavigationObjectContentContainer: {
    padding: '12px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: 16
  },
  rightNavigationObjectExpansionContainer: {},
  rightNavigationItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  rightNavigationItem: {
    border: '1px solid var(--color-light-grey-2)',
    borderLeft: '5px solid var(--color-primary-tone-3)',
    borderBottom: '1px solid transparent',
    display: 'flex',
    padding: '7px 14px 7px 20px',
    flex: 1,
    fontSize: 16,
    fontWeight: 400,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rightNavigationItemAdd: {
    padding: '12px 20px',
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'var(--color-primary-tone)',
      borderBottomColor: 'var(--color-primary-tone)'
    }
  },
  rightNavigationItemLabel: {},
  rightNavigationItemIcon: {
    marginLeft: 'auto',
    padding: 12,
    borderRadius: 500,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'var(--color-primary-tone-3)'
    }
  },
  rightNavigationActionsContainer: {},
  addObjectButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 500,
    width: 60,
    height: 60,
    boxShadow: '0 2px 3px 0 rgba(0,0,0,0.12)',
    backgroundColor: 'var(--color-primary)',
    color: 'var(--color-black)',
    pointerEvents: 'all',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.85
    }
  },

  /* Mobile */
  '@media (max-width: 900px)': {
    root: {
      overflow: 'hidden',
      height: '100%'
    },
    addObjectButton: {
      position: 'absolute',
      zIndex: 2,
      width: 48,
      height: 48,
      left: '50%',
      bottom: 32,
      transform: 'translateX(-50%)'
    }
  },
  lockButton: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    top: 16,
    right: 16,
    width: 48,
    height: 48,
    borderRadius: 500,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: '#000'
  },
  helpText: {
    position: 'absolute',
    zIndex: 3,
    transition:
      'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    padding: '6px 12px',
    fontWeight: 400,
    fontSize: '0.675rem',
    lineHeight: 1.14,
    color: '#fff',
    backgroundColor: 'rgba(97, 97, 97, 0.9)',
    borderRadius: 4,
    top: 20,
    left: '50%',
    transform: 'translateX(-50%)'
  }
}

const BlueprintTool = props => {
  const {
    classes,
    computedMatch,
    history,
    user,
    token,
    breadcrumbItems,
    signout
  } = props

  const { propertyId, blueprintId } = computedMatch.params
  const { isMobile } = useWindowDimensions()
  const [blueprint, setBlueprint] = useState(null)
  const [isEditingName, setIsEditingName] = useState(false)
  const [editNameModal, setEditNameModal] = useState(false)
  const [showBlueprintUploadModal, setShowBlueprintUploadModal] =
    useState(false)
  const [isUpdatingName, setIsUpdatingName] = useState(false)
  const [isNameUpdated, setIsNameUpdated] = useState(false)
  const [name, setName] = useState('')
  const [isChangingObjectPosition, setIsChangingObjectPosition] =
    useState(false)
  const [error, setError] = useState()
  const [currentObject, setCurrentObject] = useState(null) // Object modal
  const [currentItem, setCurrentItem] = useState(null) // Item modal
  const [highlightedObject, setHighlightedObject] = useState(null) // Hover
  const [highlightedItem, setHighlightedItem] = useState(null) // Hover
  const [selectedObject, setSelectedObject] = useState(null) // Click
  const [confirmationObject, setConfirmationObject] = useState(null)
  const blueprintNameInputRef = useRef()
  /* Mobile */
  const [showObjectList, setShowObjectList] = useState(false)
  const [isObjectModalOpen, setIsObjectModalOpen] = useState(false)

  const isOnboarding =
    user &&
    user.roles &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.Guest)

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await axios.get(`/v1/blueprints/${blueprintId}`)
        setBlueprint(data)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    })()
  }, [])

  const updateName = async () => {
    const newName = blueprintNameInputRef.current.value
    if (newName && newName !== blueprint.name) {
      setIsUpdatingName(true)
      try {
        await axios.patch(`/v1/blueprints/${blueprint.id}`, { name: newName })
        setBlueprint({
          ...blueprint,
          name: newName
        })
        setIsNameUpdated(true)
      } catch (e) {
        // TODO: Add error modal (?) TBD
        /* eslint-disable-next-line */
        console.error(e)
      }
    }
    setIsUpdatingName(false)
    setIsEditingName(false)
    setTimeout(() => {
      setIsNameUpdated(false)
    }, 2000)
  }

  const showObjectModal = object => {
    setCurrentObject(object || { x: 0.5, y: 0.5 })
    setIsObjectModalOpen(true)
  }

  const onCreateObject = object => {
    setBlueprint({
      ...blueprint,
      objects: [...blueprint.objects, object]
    })
    setCurrentObject(null)
    setSelectedObject(object)
  }

  useEffect(() => {
    if (selectedObject) {
      scroller.scrollTo(`rnc-object-${selectedObject.id}`, {
        containerId: 'rightNavigationObjectsContainer',
        duration: 400,
        smooth: true
      })
    }
  }, [selectedObject])

  const handleShowOverview = () => {
    if (isOnboarding) {
      history.push(
        `/onboarding/properties/${propertyId}/blueprints/${blueprintId}/overview`
      )
    } else {
      history.push(
        `/properties/${propertyId}/blueprints/${blueprintId}/overview`
      )
    }
  }

  const handleCreateNewBlueprint = () => {
    if (isOnboarding) {
      history.push(
        `/onboarding/properties/${propertyId}/blueprints/${blueprintId}/register`
      )
    } else {
      setShowBlueprintUploadModal(true)
    }
  }

  const handleSaveBlueprint = () => {
    history.push(
      `/onboarding/properties/${propertyId}/blueprints/${blueprintId}/register`
    )
  }

  const rotateBlueprint = async e => {
    e.preventDefault()
    e.stopPropagation()
    try {
      let { rotate } = blueprint
      if (rotate === 270) {
        rotate = 0
      } else {
        rotate += 90
      }
      await axios.patch(`/v1/blueprints/${blueprint.id}`, { rotate })
      setBlueprint({
        ...blueprint,
        rotate
      })
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const onUpdateObject = (object, opts) => {
    const objectIdx = blueprint.objects.findIndex(o => o.id === object.id)
    if (objectIdx < 0) return
    const objects = [...blueprint.objects]
    objects.splice(objectIdx, 1, object)
    setBlueprint({
      ...blueprint,
      objects
    })

    if (isMobile && opts && opts.doNotClose) {
      setCurrentObject(object)
    } else if (!opts || !opts.doNotClose) {
      setCurrentObject(null)
      setIsObjectModalOpen(false)
    } else {
      setCurrentObject(object)
    }
  }

  const onDeleteObjectConfirmation = object => {
    setConfirmationObject({
      message: global._('BlueprintTool.ObjectDeleteConfirmation'),
      onConfirm: async () => {
        await axios.delete(
          `/v1/blueprints/${blueprint.id}/objects/${object.id}`
        )
        const objectIdx = blueprint.objects.findIndex(o => o.id === object.id)
        if (objectIdx < 0) return

        setBlueprint({
          ...blueprint,
          objects: blueprint.objects.filter(o => o.id !== object.id)
        })
        setCurrentObject(null)
        setConfirmationObject(null)
      }
    })
  }

  const onCreateItem = item => {
    const object = {
      ...blueprint.objects.find(o => o.id === Number(item.blueprintObjectId))
    }
    object.items = [...object.items, item]
    setCurrentItem(null)
    onUpdateObject(object, { doNotClose: isMobile })
  }

  const onUpdateItem = item => {
    const object = {
      ...blueprint.objects.find(o => o.id == item.blueprintObjectId)
    }
    object.items = [...object.items]
    const itemIdx = object.items.findIndex(i => i.id === item.id)
    if (itemIdx < 0) return
    object.items.splice(itemIdx, 1, item)
    setCurrentItem(null)
    onUpdateObject(object, { doNotClose: isMobile })
  }

  const onDeleteItemConfirmation = item => evt => {
    evt.preventDefault()
    evt.stopPropagation()
    setConfirmationObject({
      message: global._('BlueprintTool.ItemDeleteConfirmation'),
      onConfirm: async () => {
        await axios.delete(
          `/v1/blueprints/${blueprint.id}/objects/${selectedObject.id}/items/${item.id}`
        )
        const object = {
          ...blueprint.objects.find(o => o.id == item.blueprintObjectId)
        }
        object.items = object.items.filter(i => i.id !== item.id)
        setCurrentItem(null)
        onUpdateObject(object)
        setConfirmationObject(null)
      }
    })
  }

  const saveObjectPosition = async ({ x, y }) => {
    try {
      await axios.patch(
        `/v1/blueprints/${blueprintId}/objects/${selectedObject.id}`,
        { x, y }
      )
      const updatedObject = { ...selectedObject, x, y }
      onUpdateObject(updatedObject)
      setSelectedObject(updatedObject)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  let nameInputAdornment
  if (isEditingName) {
    nameInputAdornment = (
      <Button
        style={{
          position: 'absolute',
          padding: 0,
          top: 2,
          right: 10,
          height: 6,
          width: 49
        }}
        variant='noHover'
        onClick={updateName}
        disabled={blueprint.name === name}
      >
        {global._('Common.Save')}
      </Button>
    )
  } else if (isNameUpdated) {
    nameInputAdornment = (
      <Icon className={classes.checkCircleIcon}>check_circle</Icon>
    )
  } else if (!isEditingName) {
    nameInputAdornment = (
      <Button
        style={{
          position: 'absolute',
          top: 14,
          right: 10,
          width: 24,
          height: 24
        }}
        variant='icon'
        icon='edit'
        onClick={() => {
          setIsEditingName(true)
          setTimeout(() => {
            if (blueprintNameInputRef.current) {
              blueprintNameInputRef.current.focus()
            }
          }, 100)
        }}
      />
    )
  }

  if (!blueprint && error) {
    return (
      <div className={classes.errorContainer}>
        <div style={{ maxWidth: 400 }}>
          <Notification type='error' message={error} />
        </div>
      </div>
    )
  }

  if (isMobile) {
    let helpText
    if (isChangingObjectPosition && !selectedObject) {
      helpText = global._('BlueprintTool.Help.SelectObjectToMove')
    }
    return (
      <Content>
        {/* Start of full screen modals, ordered by view stack */}
        {/* Object List */}
        {showBlueprintUploadModal && (
          <UploadBlueprint
            isMobile={isMobile}
            setShowBlueprintUploadModal={setShowBlueprintUploadModal}
            propertyId={propertyId}
            setBlueprint={setBlueprint}
          />
        )}
        {showObjectList && blueprint && (
          <FullscreenModal
            title={blueprint.name}
            onClose={() => setShowObjectList(false)}
            rightActionItem={
              <div>
                <Icon onClick={() => setEditNameModal(true)}>edit</Icon>
              </div>
            }
            content={
              blueprint.objects.length === 0 ? (
                <Typography
                  variant='bodyFaded'
                  align='center'
                  style={{
                    display: 'block',
                    padding: '80px 60px 0px 60px'
                  }}
                >
                  {global._('ObjectListModal.NoObjects')}
                </Typography>
              ) : (
                <div>
                  <Table
                    identifier='object'
                    items={blueprint.objects}
                    icon={
                      <Icon
                        style={{ color: 'var(--color-primary)', fontSize: 20 }}
                      >
                        radio_button_checked
                      </Icon>
                    }
                    rowContent={object => (
                      <Column align='flex-start'>
                        <Row justify='flex-start'>
                          <Typography bold={600}>
                            {global._(`BlueprintObjectTypes.${object.type}`)}
                          </Typography>
                          <Typography
                            bold={600}
                            style={{
                              marginLeft: 5,
                              color: 'var(--color-middle-grey)'
                            }}
                          >
                            {object.id}
                          </Typography>
                        </Row>
                        <Typography
                          style={{
                            color: 'var(--color-middle-grey)',
                            fontSize: '0.875rem'
                          }}
                        >
                          {object.space}
                        </Typography>
                      </Column>
                    )}
                    onRowClick={object => {
                      if (object.type !== BlueprintObjectTypes.Door) {
                        setIsObjectModalOpen(true)
                        setCurrentObject(object)
                      } else {
                        setIsObjectModalOpen(false)
                        setCurrentObject(object)
                        setSelectedObject(object)
                      }
                    }}
                  />
                </div>
              )
            }
            buttons={[
              <Button
                key='object-list-button-add-object'
                variant='primary'
                onClick={() => showObjectModal(null)}
              >
                {global._('ObjectListModal.Button.AddObject')}
              </Button>
            ]}
          />
        )}
        {editNameModal && (
          <FullscreenModal
            title={global._('BlueprintTool.RightNavigation.Button.ChangeName')}
            onClose={() => setEditNameModal(false)}
            content={
              <Content style={{ marginTop: 64, padding: 20 }}>
                <Row style={{ paddingBottom: 20, width: '100%' }}>
                  <Column style={{ width: '100%' }}>
                    <Row style={{ width: '100%' }}>
                      <Input
                        endAdornment={nameInputAdornment}
                        disableHelpText
                        disabled={!isEditingName}
                        ref={blueprintNameInputRef}
                        placeholder='Planritningens namn'
                        defaultValue={blueprint.name}
                        onChange={e => setName(e.target.value)}
                      />
                    </Row>
                    <Row style={{ width: '100%' }}>
                      <Typography
                        style={{
                          color: '#8E8E8E',
                          marginLeft: 15
                        }}
                        variant='small'
                      >
                        {global._('BlueprintTool.BlueprintNameInput')}
                      </Typography>
                    </Row>
                  </Column>
                </Row>
              </Content>
            }
          />
        )}
        {/* Object Items List */}
        {currentObject && (
          <FullscreenModal
            title={`${global._(`BlueprintObjectTypes.${currentObject.type}`)} ${
              currentObject.id
            }`}
            rightActionItem={
              <div
                style={{ padding: 16 }}
                onClick={() => showObjectModal(currentObject)}
                role='button'
                tabIndex='0'
                onKeyPress={() => showObjectModal(currentObject)}
              >
                <Icon>edit</Icon>
              </div>
            }
            onClose={() => setCurrentObject(null)}
            content={
              !currentObject.items || currentObject.items.length === 0 ? (
                <Typography
                  variant='bodyFaded'
                  align='center'
                  style={{
                    display: 'block',
                    padding: '80px 60px 0px 60px'
                  }}
                >
                  {global._('ItemListModal.NoItems')}
                </Typography>
              ) : (
                <div>
                  <Table
                    identifier='item'
                    items={currentObject.items}
                    icon={
                      <Icon
                        style={{ color: 'var(--color-primary)', fontSize: 20 }}
                      >
                        radio_button_checked
                      </Icon>
                    }
                    rowContent={item => (
                      <Column
                        align='flex-start'
                        style={{
                          padding: item.attributes.find(a => a.key === 'notes')
                            ? undefined
                            : '10px 0 12px 0'
                        }}
                      >
                        <Row justify='flex-start'>
                          <Typography bold={600}>
                            {global._(`BlueprintItemTypes.${item.type}`)}
                          </Typography>
                          <Typography
                            bold={600}
                            style={{
                              marginLeft: 5,
                              color: 'var(--color-middle-grey)'
                            }}
                          >{`${currentObject.id}-${item.id}`}</Typography>
                        </Row>
                        {item.attributes.find(a => a.key === 'notes') && (
                          <Typography
                            ellipsis
                            style={{
                              maxWidth: '60vw',
                              color: 'var(--color-middle-grey)',
                              fontSize: '0.875rem'
                            }}
                          >
                            {item.attributes.find(a => a.key === 'notes').value}
                          </Typography>
                        )}
                      </Column>
                    )}
                    onRowClick={item => {
                      setCurrentItem(item)
                    }}
                  />
                </div>
              )
            }
            buttons={[
              currentObject.items && currentObject.items.length > 0 && (
                <Button
                  key='item-list-button-done'
                  variant='outlined'
                  onClick={() => {
                    window.location.reload()
                  }}
                  style={{ marginBottom: 20 }}
                >
                  {global._('ItemListModal.Button.Done')}
                </Button>
              ),
              <Button
                key='item-list-button-add-item'
                variant='primary'
                onClick={() => setCurrentItem({})}
              >
                {global._('ItemListModal.Button.AddItem')}
              </Button>
            ]}
          />
        )}
        {/* Object Modal */}
        {currentObject && isObjectModalOpen && (
          <ObjectModal
            fullscreen
            blueprint={blueprint}
            onClose={() => setCurrentObject(null)}
            object={currentObject}
            onCreateObject={onCreateObject}
            onUpdateObject={onUpdateObject}
            onDeleteObject={onDeleteObjectConfirmation}
          />
        )}
        {/* Item Modal */}
        {currentObject && currentItem && (
          <ItemModal
            fullscreen
            object={currentObject}
            item={currentItem}
            blueprintId={blueprintId}
            onClose={() => setCurrentItem(null)}
            onCreateItem={onCreateItem}
            onUpdateItem={onUpdateItem}
            onDeleteItem={onDeleteItemConfirmation}
          />
        )}
        {confirmationObject && (
          <ConfirmationModal
            fullscreen
            onClose={() => setConfirmationObject(null)}
            onConfirm={confirmationObject.onConfirm}
            message={confirmationObject.message}
          />
        )}
        {/* End of screen stack */}
        <NavigationBar
          title={
            blueprint ? blueprint.name : global._('BlueprintTool.Blueprint')
          }
          backAction={() => history.goBack()}
          rightActionItem={
            <div
              role='button'
              tabIndex='0'
              onKeyPress={() => setShowObjectList(true)}
              onClick={() => setShowObjectList(true)}
            >
              <Icon>menu</Icon>
            </div>
          }
        />
        <ScrollView style={{ overflow: 'hidden', position: 'fixed' }}>
          {blueprint && (
            <BlueprintContainer
              blueprint={blueprint}
              token={token}
              highlightedObject={highlightedObject}
              setHighlightedObject={obj => setHighlightedObject(obj)}
              selectedObject={selectedObject}
              setSelectedObject={obj => {
                if (obj === selectedObject) {
                  setSelectedObject(null)
                } else {
                  setSelectedObject(obj)
                }
                /* If object clicked isn't a door and user isn't changing position of object, open object modal */
                setIsObjectModalOpen(
                  !!(
                    obj.type !== BlueprintObjectTypes.Door &&
                    !isChangingObjectPosition
                  )
                )
                setCurrentObject(isChangingObjectPosition ? null : obj)
              }}
              setCurrentObject={obj => showObjectModal(obj)}
              isChangingObjectPosition={isChangingObjectPosition}
              saveObjectPosition={saveObjectPosition}
              rotateBlueprint={rotateBlueprint}
              rotationDegrees={blueprint.rotate}
            />
          )}
          <div
            className={classes.lockButton}
            role='button'
            tabIndex='0'
            onKeyPress={() =>
              setIsChangingObjectPosition(!isChangingObjectPosition)
            }
            onClick={() =>
              setIsChangingObjectPosition(!isChangingObjectPosition)
            }
          >
            <Icon style={{ fontSize: 26 }}>
              {isChangingObjectPosition ? 'lock_open' : 'lock'}
            </Icon>
          </div>
          <div
            className={classes.addObjectButton}
            role='button'
            tabIndex='0'
            onKeyPress={() => showObjectModal(null)}
            onClick={() => showObjectModal(null)}
          >
            <Icon style={{ fontSize: 40 }}>add</Icon>
          </div>
          {helpText && <div className={classes.helpText}>{helpText}</div>}
        </ScrollView>
        <TabBar>
          {!isOnboarding && (
            <TabBarItem
              title={global._('TabBar.Home')}
              icon='home'
              onClick={() => history.push('/')}
            />
          )}
          <TabBarItem
            title={global._('TabBar.CreateNew')}
            icon='note_add'
            onClick={handleCreateNewBlueprint}
          />
          <TabBarItem
            title={global._('TabBar.Overview')}
            icon='assignment'
            onClick={handleShowOverview}
          />
          {isOnboarding && (
            <TabBarItem
              data-cy='tab-save-objects'
              title={global._('TabBar.Save')}
              icon='save'
              onClick={handleSaveBlueprint}
            />
          )}
          {!isOnboarding && (
            <TabBarItem
              title={global._('TabBar.SignOut')}
              icon='account_circle'
              onClick={signout}
            />
          )}
        </TabBar>
      </Content>
    )
  }

  if (!blueprint) return <div className={classes.root} />

  return (
    <div className={classes.root}>
      <div className={classes.breadcrumbscontainer}>
        {!isOnboarding && (
          <PageContentHeader
            boxedBreadcrumbs
            breadcrumbItems={breadcrumbItems}
          />
        )}
      </div>
      {showBlueprintUploadModal && (
        <UploadBlueprint
          isMobile={isMobile}
          setShowBlueprintUploadModal={setShowBlueprintUploadModal}
          propertyId={propertyId}
          setBlueprint={setBlueprint}
        />
      )}
      {currentObject && (
        <ObjectModal
          blueprint={blueprint}
          onClose={() => setCurrentObject(null)}
          object={currentObject}
          onCreateObject={onCreateObject}
          onUpdateObject={onUpdateObject}
          onDeleteObject={onDeleteObjectConfirmation}
        />
      )}
      {selectedObject && currentItem && (
        <ItemModal
          object={selectedObject}
          item={currentItem}
          blueprintId={blueprintId}
          onClose={() => setCurrentItem(null)}
          onCreateItem={onCreateItem}
          onUpdateItem={onUpdateItem}
          onDeleteItem={onDeleteItemConfirmation}
        />
      )}
      {confirmationObject && (
        <ConfirmationModal
          onClose={() => setConfirmationObject(null)}
          onConfirm={confirmationObject.onConfirm}
          message={confirmationObject.message}
        />
      )}
      {/* Blueprint container */}
      <BlueprintContainer
        blueprint={blueprint}
        token={token}
        rotateBlueprint={rotateBlueprint}
        rotationDegrees={blueprint.rotate}
        highlightedObject={highlightedObject}
        setHighlightedObject={obj => setHighlightedObject(obj)}
        selectedObject={selectedObject}
        setSelectedObject={obj => {
          if (obj === selectedObject) {
            setSelectedObject(null)
          } else {
            setSelectedObject(obj)
          }
        }}
        setCurrentObject={obj => setCurrentObject(obj)}
        isChangingObjectPosition={isChangingObjectPosition}
        saveObjectPosition={saveObjectPosition}
      />
      {/* Right navigation */}
      <div className={classes.rightNavigation}>
        {/* Right navigation header */}
        <div className={classes.rightNavigationHeaderContainer}>
          <div
            style={{ borderBottom: isEditingName ? '1px solid #fff' : 'none' }}
          >
            <Input
              ref={blueprintNameInputRef}
              placeholder='Planritningens namn'
              defaultValue={blueprint.name}
              style={{
                color: '#fff',
                display: !isEditingName && 'none',
                border: 'none',
                marginRight: 20,
                borderRadius: 0,
                padding: 0,
                height: 'auto'
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  updateName()
                }
              }}
            />
          </div>
          {!isEditingName && (
            <Typography ellipsis variant='h2' style={{ color: '#fff' }}>
              {blueprint.name}
            </Typography>
          )}
          {isUpdatingName ? (
            <CircularProgress
              style={{ color: 'var(--color-button-link)', marginLeft: 'auto' }}
              size={24}
              thickness={8}
            />
          ) : (
            <Button
              variant='link'
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                if (isEditingName) {
                  updateName()
                } else {
                  setIsEditingName(true)
                  setTimeout(() => blueprintNameInputRef.current.focus(), 50)
                }
              }}
            >
              {global._(
                `BlueprintTool.RightNavigation.Button.${
                  isEditingName ? 'ChangeNameDone' : 'ChangeName'
                }`
              )}
            </Button>
          )}
        </div>
        {/* Right navigation placeholder */}
        <div className={classes.rightNavigationContentContainer}>
          <div
            className={classes.rightNavigationObjectsContainer}
            id='rightNavigationObjectsContainer'
          >
            {!blueprint.objects.length && (
              <>
                <Typography
                  variant='h4'
                  align='center'
                  style={{
                    color: '#8e8e8e',
                    padding: '0 15px',
                    position: 'absolute',
                    top: '40%',
                    transform: 'translateY("-50%")'
                  }}
                >
                  {global._('BlueprintTool.RightNavigation.Text.Placeholder')}
                </Typography>
                <div style={{ height: 15 }} />
              </>
            )}
            {blueprint.objects.map(object => {
              const isSelected =
                selectedObject && object.id === selectedObject.id
              const isHighlighted =
                isSelected ||
                (highlightedObject && object.id === highlightedObject.id)
              const availableItemTypes = BlueprintObjectItemTypes[object.type]
              return (
                <Element
                  key={`rnc-object-${object.id}`}
                  name={`rnc-object-${object.id}`}
                  className={classes.rightNavigationObjectContainer}
                >
                  <div
                    className={classnames(
                      classes.rightNavigationObject,
                      isHighlighted && classes.rightNavigationObjectHighlighted
                    )}
                    onMouseEnter={() => setHighlightedObject(object)}
                    onMouseLeave={() => setHighlightedObject(null)}
                    onClick={() => {
                      if (isSelected) {
                        setSelectedObject(null)
                      } else {
                        setSelectedObject(object)
                      }
                    }}
                    role='button'
                    tabIndex='0'
                    onKeyPress={() => {
                      if (isSelected) {
                        setSelectedObject(null)
                      } else {
                        setSelectedObject(object)
                      }
                    }}
                  >
                    <div
                      className={classes.rightNavigationObjectContentContainer}
                    >
                      <div>
                        <span style={{ fontWeight: 600 }}>
                          {global._(`BlueprintObjectTypes.${object.type}`)}
                        </span>
                        <span
                          style={{
                            color: 'var(--color-text)',
                            marginLeft: 6
                          }}
                        >
                          {object.id}
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: 'var(--color-middle-grey)',
                            fontSize: 14,
                            fontWeight: 400
                          }}
                        >
                          {truncate(object.space, 20)}
                        </span>
                      </div>
                    </div>
                    <Icon style={{ marginLeft: 'auto', marginRight: 20 }}>
                      {isSelected ? 'expand_less' : 'expand_more'}
                    </Icon>
                    {/* Use this to only show expansion arrow for object that can add items ---- {!!availableItemTypes.length && (
                      <Icon style={{ marginLeft: 'auto', marginRight: 20 }}>{isSelected ? 'expand_less' : 'expand_more'}</Icon>
                    )} */}
                  </div>
                  {/* Expanded */}
                  {isSelected && (
                    <div
                      className={
                        classes.rightNavigationObjectExpansionContainer
                      }
                    >
                      {object.items.map(item => {
                        const isItemHighlighted = highlightedItem === item
                        return (
                          <div
                            key={`rnc-object-${object.id}-item-${item.id}`}
                            className={classes.rightNavigationItemContainer}
                          >
                            <div className={classes.rightNavigationItem}>
                              <div
                                onMouseEnter={() => setHighlightedItem(item)}
                                onMouseLeave={() => setHighlightedItem(null)}
                                onClick={() => setCurrentItem(item)}
                                onKeyPress={() => setCurrentItem(item)}
                                role='button'
                                tabIndex='0'
                                style={{ cursor: 'pointer' }}
                              >
                                <span>
                                  {global._(`BlueprintItemTypes.${item.type}`)}
                                </span>
                                {isItemHighlighted && (
                                  <span style={{ fontSize: 14, marginLeft: 6 }}>
                                    {global._(
                                      'BlueprintTool.RightNavigation.EditItem'
                                    )}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      {!!availableItemTypes.length && (
                        <div className={classes.rightNavigationItemContainer}>
                          <div
                            data-cy='button-add-accessories'
                            className={classnames(
                              classes.rightNavigationItem,
                              classes.rightNavigationItemAdd
                            )}
                            onClick={() => setCurrentItem({})}
                            onKeyPress={() => setCurrentItem({})}
                            role='button'
                            tabIndex='0'
                          >
                            <span>
                              {global._(
                                'BlueprintTool.RightNavigation.Button.AddAccessories'
                              )}
                            </span>
                            <Icon style={{ fontSize: 24 }}>add</Icon>
                          </div>
                        </div>
                      )}

                      <NavigationItem divider />
                      <NavigationItem
                        title={global._(
                          'BlueprintTool.RightNavigation.Button.Edit'
                        )}
                        icon='edit'
                        active
                        highlighted
                        onClick={() => setCurrentObject(object)}
                      />
                      <NavigationItem divider />

                      <NavigationItem
                        title={global._(
                          'BlueprintTool.RightNavigation.Button.Delete'
                        )}
                        icon='delete'
                        active
                        highlighted
                        onClick={() => onDeleteObjectConfirmation(object)}
                      />
                    </div>
                  )}
                </Element>
              )
            })}
          </div>
          <div className={classes.rightNavigationPlaceholderContainer}>
            <div
              className={classes.addObjectButton}
              onClick={() => showObjectModal(null)}
              onKeyPress={() => showObjectModal(null)}
              role='button'
              tabIndex='0'
            >
              <Icon style={{ color: 'var(--color-black)', fontSize: 50 }}>
                add
              </Icon>
            </div>
          </div>
          <div className={classes.rightNavigationActionsContainer}>
            <NavigationItem divider />
            <NavigationItem
              title={global._(
                `BlueprintTool.RightNavigation.Button.${
                  isChangingObjectPosition
                    ? 'StopChangingObjectPosition'
                    : 'ChangeObjectPosition'
                }`
              )}
              icon={isChangingObjectPosition ? 'lock_open' : 'lock'}
              disabled={!selectedObject}
              onClick={() =>
                setIsChangingObjectPosition(!isChangingObjectPosition)
              }
            />
            <NavigationItem divider />
            <NavigationItem
              dataCy='navigation-show-overview'
              title={global._(
                'BlueprintTool.RightNavigation.Button.ShowOverview'
              )}
              icon='assignment'
              onClick={handleShowOverview}
            />
            <NavigationItem divider />
            <NavigationItem
              dataCy='navigation-new-blueprint'
              title={global._(
                'BlueprintTool.RightNavigation.Button.NewBlueprint'
              )}
              icon='note_add'
              onClick={handleCreateNewBlueprint}
            />
            <NavigationItem divider />
            {isOnboarding && (
              <>
                <NavigationItem
                  dataCy='navigation-item-save'
                  title='Spara'
                  icon='save'
                  onClick={handleSaveBlueprint}
                />
                <NavigationItem divider />
              </>
            )}
            <NavigationItem />
          </div>
        </div>
      </div>
    </div>
  )
}

BlueprintTool.propTypes = {
  breadcrumbItems: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired,
  computedMatch: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  user: PropTypes.shape({ roles: PropTypes.arrayOf(PropTypes.string) })
    .isRequired,
  token: PropTypes.string.isRequired,
  signout: PropTypes.func.isRequired
}
BlueprintTool.defaultProps = {
  breadcrumbItems: []
}

const BlueprintToolWithStyles = injectSheet(styles)(BlueprintTool)
export default BlueprintToolWithStyles
