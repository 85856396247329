import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Grid, TextField } from '@material-ui/core'
import axios from 'axios'
import Modal from '../../Modal'
import Button from '../../Button'
import FullscreenModal from '../../FullscreenModal'
import FileContainer from '../../FileContainer'
import DragZone from '../../DragAndDrop/DragZone'
import Notification from '../../../common/Notification'
import Typography from '../../Typography'
import { detectMimeType } from '../../../utils/mime'

const styles = {
  root: {},
  input: {
    width: '100%'
  }
}

const UploadBlueprint = ({
  classes,
  setShowBlueprintUploadModal,
  isMobile,
  propertyId,
  history,
  setBlueprint
}) => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const AllowedFileSize = 10 * 1000 * 1000 // 10 MB
  const AllowedFileTypes = [
    // https://www.sitepoint.com/mime-types-complete-list/
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/pdf'
  ]

  const formikRef = useRef()

  const handleUpload = async (values, resetForm) => {
    try {
      setIsLoading(true)

      const blueprintData = {
        name: values.name,
        propertyId,
        file: values.file
      }

      const { data: blueprint } = await axios.post(
        '/v1/blueprints',
        blueprintData
      )
      resetForm({})

      if (history) {
        history.push(
          `/properties/${blueprint.propertyId}/blueprints/${blueprint.id}`
        )
      } else {
        const { data } = await axios.get(`/v1/blueprints/${blueprint.id}`)
        setBlueprint(data)
      }

      setShowBlueprintUploadModal(false)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  const onFileSelected = selectedFile => {
    const { name, size } = selectedFile
    let errorCode

    if (!selectedFile.type) {
      // eslint-disable-next-line
      selectedFile.type = detectMimeType(name)
    }

    if (!AllowedFileTypes.includes(selectedFile.type)) {
      errorCode = 'ERR_FILE_TYPE'
      setError(errorCode)
    } else if (size > AllowedFileSize) {
      errorCode = 'ERR_FILE_SIZE'
      setError(errorCode)
    } else {
      try {
        formikRef.current.setFieldValue('file', selectedFile)
        setError(null)
      } catch (e) {
        errorCode = e.response ? e.response.data : e.message
        setError(global._(errorCode))
      }
    }
  }

  const onRemoveFile = () => {
    try {
      formikRef.current.setFieldValue('file', {})
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const addBlueprintFormFields = (handleChange, errors, values, handleBlur) => (
    <Form>
      <Grid container spacing={3} style={{ marginBottom: 1 }}>
        {error && (
          <Notification
            type='error'
            message={error}
            style={{ margin: '20px 0' }}
          />
        )}
        <Grid item xs={12}>
          <TextField
            data-cy='input-blueprint-name'
            onChange={handleChange}
            error={errors.name}
            onClick={handleBlur}
            value={values.name}
            name='name'
            className={classes.input}
            label={global._('Properties.UploadBlueprintModal.NameOfBlueprint')}
            helperText={global._('Common.Required')}
            variant='filled'
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='bodyFaded'>
            {global._('Properties.UploadBlueprintModal.UploadBlueprint')}
          </Typography>
        </Grid>
        {values.file && values.file.name && (
          <Grid item xs={12} key={values.file.name}>
            <FileContainer
              file={values.file}
              name={values.file.name}
              size={values.file.size}
              type={values.file.type}
              data={values.file.data}
              onDelete={() => onRemoveFile(values.file)}
            />
          </Grid>
        )}
        {(!values.file || !values.file.name) && (
          <Grid item>
            <div style={{ marginBottom: 12 }}>
              <DragZone
                onFileSelected={onFileSelected}
                buttonOnly
                buttonVariant='outlined'
              />
            </div>
            <Typography
              variant='bodyFaded'
              style={{ fontStyle: 'italic', marginTop: 15 }}>
              {global._('Properties.UploadBlueprintModal.UploadHelpInfo')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Form>
  )

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        name: '',
        file: {}
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(global._('Common.Required')),
        file: Yup.object().shape({
          name: Yup.string().required(global._('Common.Required'))
        })
      })}>
      {({
        errors,
        touched,
        handleChange,
        values,
        handleBlur,
        setFieldValue,
        resetForm
      }) =>
        isMobile ? (
          <FullscreenModal
            title={global._('Properties.UploadBlueprintModal.NewBlueprint')}
            onClose={() => setShowBlueprintUploadModal(false)}
            rightActionItem='close'
            higherZIndex
            padding
            content={addBlueprintFormFields(
              handleChange,
              errors,
              values,
              handleBlur,
              setFieldValue
            )}
            buttons={
              <Button
                variant='primary'
                onClick={() => handleUpload(values, resetForm)}
                disabled={
                  Object.keys(touched).length === 0 ||
                  Object.keys(errors).length ||
                  isLoading
                }
                loading={isLoading}>
                {global._('Common.Add')}
              </Button>
            }
          />
        ) : (
          <Modal
            onClose={() => setShowBlueprintUploadModal(false)}
            title={global._('Properties.UploadBlueprintModal.NewBlueprint')}
            content={
              <div style={{ textAlign: 'left' }}>
                {addBlueprintFormFields(
                  handleChange,
                  errors,
                  values,
                  handleBlur,
                  setFieldValue
                )}
              </div>
            }
            buttons={[
              <Button
                variant='none'
                onClick={() => setShowBlueprintUploadModal(false)}>
                {global._('Common.Cancel')}
              </Button>,
              <Button
                data-cy='button-add-blueprint'
                variant='primary'
                onClick={() => handleUpload(values, resetForm)}
                disabled={
                  Object.keys(touched).length === 0 ||
                  Object.keys(errors).length ||
                  isLoading
                }
                loading={isLoading}>
                {global._('Common.Add')}
              </Button>
            ]}
          />
        )
      }
    </Formik>
  )
}

UploadBlueprint.propTypes = {
  classes: PropTypes.object.isRequired,
  setShowBlueprintUploadModal: PropTypes.func.isRequired,
  propertyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  isMobile: PropTypes.bool.isRequired,
  setBlueprint: PropTypes.func,
  history: PropTypes.object
}

UploadBlueprint.defaultProps = {
  setBlueprint: null,
  history: null
}

const UploadBlueprintWithStyles = injectSheet(styles)(UploadBlueprint)
export default UploadBlueprintWithStyles
