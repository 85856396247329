import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Redirect, Link } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment'
import { UserRoles } from '../../utils/constants'

import 'moment/locale/sv'
import PageContentHeader from '../../components/PageContentHeader'

const columnNames = ['Name', 'Email', 'Phone']

const styles = theme => ({
  root: {},
  headerContainer: {
    padding: 10,
    paddingRight: 20,
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  paper: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 20,
    flex: 1,
    flexWrap: 'wrap'
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  textField: {
    marginTop: 10,
    marginLeft: 20
  },
  icon: {
    alignSelf: 'center',
    color: '#007bff',
    cursor: 'pointer',
    fontSize: 28
  },
  tableRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: 0,
      paddingBottom: 0,
      marginBottom: 0
    }
  }
})

class AdminDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      organisation: null,
      users: [],
      isLoadingFetch: false,
      isLoadingUpdate: false,
      isEditing: false
    }
  }

  async componentDidMount() {
    this.setState({ isLoadingFetch: true })
    const { organisationId } = this.props.match.params
    try {
      const { data: organisation } = await axios.get(
        `/v1/organisations/${organisationId}`
      )
      const { data: users } = await axios.get(
        `/v1/organisations/${organisationId}/users`
      )
      this.setState({ organisation, users })
    } catch (err) {}
    this.setState({ isLoadingFetch: false })
  }

  onUpdateOrganisation = async organisation => {
    if (this.state.isLoadingUpdate) return
    this.setState({ isLoadingUpdate: true })
    try {
      const { data } = await axios.put(
        `/v1/organisations/${organisation.id}`,
        organisation
      )
      data.users = organisation.users
      this.setState({
        isLoadingUpdate: false,
        organisation: data,
        objectToUpdate: null,
        isEditing: false
      })
    } catch (e) {
      this.setState({ isLoadingUpdate: false })
    }
  }

  handleOrganisationChange = name => e => {
    const { value } = e.target
    const objectToUpdate = { ...this.state.objectToUpdate }
    if (value) objectToUpdate[name] = value
    else delete objectToUpdate[name]

    this.setState({ objectToUpdate })
  }

  toggleEditOrganisation = organisation => {
    this.setState(prevState => ({
      isEditing: !prevState.isEditing,
      objectToUpdate: organisation
    }))
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user'))
    if (!user.roles.includes(UserRoles.InternalAdmin))
      return <Redirect to='/dashboard' />
    if (this.state.isLoadingFetch || !this.state.organisation)
      return <div>Loading...</div>
    const { organisation, isEditing, objectToUpdate } = this.state
    const { classes, breadcrumbItems } = this.props

    return (
      <>
        <div className={classes.root}>
          <PageContentHeader breadcrumbItems={breadcrumbItems} />
          <div className={classes.headerContainer}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                justifyContent: 'space-between'
              }}>
              <div style={{ flexDirection: 'column' }}>
                <Typography variant='h6' style={{ color: '#555' }}>
                  {organisation.name}
                </Typography>
              </div>
              <Tooltip
                title={isEditing ? 'Spara ändringar' : 'Redigera användare'}>
                <IconButton
                  aria-label='delete'
                  onClick={() =>
                    isEditing
                      ? this.onUpdateOrganisation(objectToUpdate)
                      : this.toggleEditOrganisation(organisation)
                  }>
                  {isEditing ? (
                    <SaveIcon className={classes.icon} />
                  ) : (
                    <EditIcon className={classes.icon} />
                  )}
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Paper className={classes.paper}>
            <TextField
              className={classes.textField}
              onChange={this.handleOrganisationChange('name')}
              disabled={!isEditing}
              label='Namn'
              variant='outlined'
              value={isEditing ? objectToUpdate.name : organisation.name}
            />
            <TextField
              className={classes.textField}
              onChange={this.handleOrganisationChange('organisationNumber')}
              disabled={!isEditing}
              label='Organisationsnummer'
              variant='outlined'
              value={
                isEditing
                  ? objectToUpdate.organisationNumber
                  : organisation.organisationNumber
              }
            />
            <TextField
              className={classes.textField}
              onChange={this.handleOrganisationChange('address')}
              disabled={!isEditing}
              label='Adress'
              variant='outlined'
              value={isEditing ? objectToUpdate.address : organisation.address}
            />
            <TextField
              className={classes.textField}
              onChange={this.handleOrganisationChange('postalCode')}
              disabled={!isEditing}
              label='Postnummer'
              variant='outlined'
              value={
                isEditing ? objectToUpdate.postalCode : organisation.postalCode
              }
            />
            <TextField
              className={classes.textField}
              onChange={this.handleOrganisationChange('city')}
              disabled={!isEditing}
              label='Stad'
              variant='outlined'
              value={isEditing ? objectToUpdate.city : organisation.city}
            />
            <TextField
              className={classes.textField}
              onChange={this.handleOrganisationChange('reference')}
              disabled={!isEditing}
              label='Referens'
              variant='outlined'
              value={
                isEditing ? objectToUpdate.reference : organisation.reference
              }
            />
            <TextField
              className={classes.textField}
              disabled
              label='Skapad'
              variant='outlined'
              value={
                moment(organisation.createdAt).calendar(null, {
                  sameElse: 'YYYY/MM/DD'
                }) || ''
              }
            />
            <TextField
              className={classes.textField}
              disabled
              label='Uppdaterad'
              variant='outlined'
              value={
                moment(organisation.updatedAt).calendar(null, {
                  sameElse: 'YYYY/MM/DD'
                }) || ''
              }
            />
          </Paper>
          <div className={classes.headerContainer}>
            <Typography variant='h6' style={{ color: '#555' }}>
              Användare
            </Typography>
          </div>
          <Paper className={classes.paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {columnNames.map(column => {
                    if (
                      !user.roles.includes(UserRoles.InternalAdmin) &&
                      column === 'organisationId'
                    )
                      return <TableCell key={column} />
                    return (
                      <TableCell style={{ fontSize: 14 }} key={column}>
                        {global._(column)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.users.length > 0 ? (
                  this.state.users.map(user => (
                    <TableRow key={user.id} className={classes.tableRow}>
                      <TableCell>
                        <Link
                          to={`/admin/users/${user.organisation.id}/${user.id}`}>
                          {user.name || '(inget namn)'}
                        </Link>
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.phone}</TableCell>
                      {/* <TableCell>{user.roles.map(role => {
                    return (
                      <span key={`user-${user.id}-role-${role}`}>{global._(`User.Roles.${role}`)}</span>
                    )
                  })}
                  </TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  <TableRow className={classes.tableRow}>
                    <TableCell colSpan={columnNames.length} align='center'>
                      <Typography>Inga användare</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </>
    )
  }
}

AdminDetail.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired
}

export default withStyles(styles)(AdminDetail)
