import React, { useState, useEffect } from 'react'
import { FormControl, MenuItem, TextField } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { saveAs } from 'file-saver'
import PropTypes from 'prop-types'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import { BlueprintObjectTypes, DefaultSpaces } from '../../../utils/constants'
import FileContainer from '../../FileContainer'
import Notification from '../../../common/Notification'
import Typography from '../../Typography'
import DragZone from '../../DragAndDrop/DragZone'
import AutocompleteCustomOtherSelect from '../../AutocompleteCustomOtherSelect'
import Button from '../../Button'
import Modal from '../../Modal'
import FullscreenModal from '../../FullscreenModal'
import withToken from '../../../utils/withToken'

const styles = () => ({
  formControl: {
    marginBottom: '16px'
  }
})

const ObjectModal = props => {
  const {
    blueprint,
    classes,
    object,
    onClose,
    onCreateObject,
    onUpdateObject,
    onDeleteObject,
    fullscreen,
    token
  } = props
  const userCustomization = JSON.parse(
    localStorage.getItem('user-customization')
  ) || { blueprintToolObjectModalType: BlueprintObjectTypes.Door }
  if (!userCustomization.blueprintToolObjectModalType) {
    userCustomization.blueprintToolObjectModalType = BlueprintObjectTypes.Door
  }
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [spaces, setSpaces] = useState([])
  const [type, setType] = useState(
    userCustomization.blueprintToolObjectModalType
  )
  const [parentObjectId, setParentObjectId] = useState(0)
  const [parentCCTVObjects, setParentCCTVObjects] = useState([])
  const [space, setSpace] = useState()
  const [notes, setNotes] = useState('')
  const [files, setFiles] = useState([])
  const [openAttachmentModalOnUpload, setOpenAttachmentModalOnUpload] =
    useState(null)

  const AllowedFileSize = 50 * 1000 * 1000 // 50 MB
  const imageTypes = ['image/jpeg', 'image/png']
  const videoTypes = [
    'video/mp4', // .mp4
    'video/quicktime' // .mov
  ]

  const getParentOption = obj => ({
    label: `${global._(`BlueprintObjectTypes.${obj.type}`)} ${obj.id}, ${
      obj.blueprintName || ''
    }`,
    value: obj.id
  })

  const getParentObjectOptions = () => {
    let options = []
    if (type === BlueprintObjectTypes.CCTVCamera) {
      options = parentCCTVObjects.map(o => getParentOption(o)) || []
    } else if (type === BlueprintObjectTypes.CCTVSwitch) {
      options =
        parentCCTVObjects
          .filter(o => o.type === BlueprintObjectTypes.CCTVCentral)
          .map(o => getParentOption(o)) || []
    }
    return options
  }

  const getPropertyObjects = async () => {
    const propertyBlueprints = await axios
      .get(`/v1/properties/${blueprint.propertyId}?blueprints=true`)
      .then(response => response.data.blueprints || [])
      .catch(err => {
        const msg = err.response ? err.response.data : err.message
        setError(global._(msg))
        return []
      })

    let propertyCCTVObjects = []
    propertyBlueprints.forEach(b => {
      const blueprintCCTVObjects = b.objects
        .filter(
          elem =>
            elem.type === BlueprintObjectTypes.CCTVCentral ||
            elem.type === BlueprintObjectTypes.CCTVSwitch
        )
        .map(elem => ({ blueprintName: b.name, ...elem }))
      propertyCCTVObjects = propertyCCTVObjects.concat(blueprintCCTVObjects)
    })
    setParentCCTVObjects(propertyCCTVObjects)

    if (object && object.parentObject) {
      const parent = propertyCCTVObjects.find(
        elem => elem.id === object.parentObject
      )
      if (!parent) {
        setParentObjectId(0)
      }
    }
  }

  useEffect(() => {
    let allSpaces

    const language = global.getLanguage()
    if (DefaultSpaces.hasOwnProperty(language)) {
      allSpaces = [...DefaultSpaces[language]]
    } else {
      allSpaces = []
    }

    const parsedSpaces = blueprint.objects.map(o => o.space)
    const additionalSpaces = parsedSpaces.filter(
      (item, index) =>
        !allSpaces.includes(item) && index === parsedSpaces.indexOf(item)
    )
    const blueprintSpaces = allSpaces.concat(additionalSpaces).sort()
    setSpaces(blueprintSpaces)

    getPropertyObjects()
  }, [])

  useEffect(() => {
    if (object) {
      setType(object.type || userCustomization.blueprintToolObjectModalType)
      setParentObjectId(object.parentObject || 0)
      setSpace(object.space)
      setNotes(object.notes || '')
      setFiles(object.files || [])
    }
  }, [object])

  const onFileSelected = async file => {
    setError(null)
    if (files.length >= 10) {
      return setError(global._('BlueprintTool.ObjectModal.Errors.MaxFiles'))
    }
    if (file.size > AllowedFileSize) {
      return setError(global._('BlueprintTool.ObjectModal.Errors.FileSize'))
    }
    if (object.id) {
      try {
        const { data: createdFile } = await axios.post(
          `/v1/blueprints/${blueprint.id}/objects/${object.id}/files`,
          { file }
        )
        const updatedObject = {
          ...object,
          files: [...object.files, createdFile]
        }
        onUpdateObject(updatedObject, { doNotClose: true })

        setOpenAttachmentModalOnUpload(
          imageTypes.includes(createdFile.file.type) ? createdFile.fileId : null
        )

        setFiles(updatedObject.files)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    } else {
      setOpenAttachmentModalOnUpload(
        imageTypes.includes(file.type) ? `${file.name}-${file.size}` : null
      )
      setFiles([...files, file])
    }
  }

  const onRemoveFile = async file => {
    if (object.id && file.id) {
      await axios.delete(
        `/v1/blueprints/${blueprint.id}/objects/${object.id}/files/${file.id}`
      )
      const updatedFiles = files.filter(f => f.id !== file.id)
      setFiles(updatedFiles)
      onUpdateObject(
        {
          ...object,
          files: updatedFiles
        },
        { doNotClose: true }
      )
    } else {
      setFiles(files.filter(f => f !== file))
    }
  }

  const onCreateOrUpdateObject = async () => {
    setError(null)
    setIsLoading(true)
    try {
      if (!type)
        throw new Error('BlueprintTool.ObjectModal.Errors.TypeIsRequired')
      if (!space)
        throw new Error('BlueprintTool.ObjectModal.Errors.SpaceIsRequired')
      const data = {
        type,
        x: object.x,
        y: object.y,
        notes,
        parentObject: parentObjectId > 0 ? parentObjectId : null,
        space
      }

      if (object.id) {
        delete data.type
        const { data: updatedObject } = await axios.patch(
          `/v1/blueprints/${blueprint.id}/objects/${object.id}`,
          data
        )
        onUpdateObject(
          {
            ...object,
            ...updatedObject
          },
          { doNotClose: false }
        )
      } else {
        const { data: createdObject } = await axios.post(
          `/v1/blueprints/${blueprint.id}/objects`,
          data
        )
        createdObject.items = []
        const promises = files.map(fileData =>
          axios.post(
            `/v1/blueprints/${blueprint.id}/objects/${createdObject.id}/files`,
            { file: fileData }
          )
        )
        const createdFiles = await Promise.all(promises)
        createdObject.files = createdFiles.map(res => res.data)
        onCreateObject(createdObject)
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
      setIsLoading(false)
    }
  }

  const spaceOptions = spaces.map(s => ({
    label: s,
    value: s
  }))

  const blueprintObjectTypeOptions = Object.keys(BlueprintObjectTypes).map(
    key => ({
      label: global._(`BlueprintObjectTypes.${key}`),
      value: key
    })
  )

  let Wrapper
  if (fullscreen) {
    Wrapper = FullscreenModal
  } else {
    Wrapper = Modal
  }

  const { isMobile } = useWindowDimensions()

  return (
    <Wrapper
      onClose={onClose}
      title={
        object.id
          ? global._('BlueprintTool.ObjectModal.TitleEdit')
          : global._('BlueprintTool.ObjectModal.TitleNew')
      }
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: fullscreen ? 20 : undefined
          }}
        >
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              data-cy='input-select-object'
              variant='filled'
              select
              name='object-type-select'
              label={global._('BlueprintTool.ObjectModal.ObjectTypeLabel')}
              helperText={
                typeof object.id === 'undefined'
                  ? global._('Common.Required')
                  : undefined
              }
              disabled={typeof object.id === 'number'}
              onChange={event => {
                userCustomization.blueprintToolObjectModalType =
                  event.target.value
                localStorage.setItem(
                  'user-customization',
                  JSON.stringify(userCustomization)
                )
                setType(event.target.value)
              }}
              value={type}
            >
              {blueprintObjectTypeOptions.map(item => (
                <MenuItem
                  key={`${item.value}-${item.label}`}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          {(type === BlueprintObjectTypes.CCTVCamera ||
            type === BlueprintObjectTypes.CCTVSwitch) && (
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                variant='filled'
                select
                name='parent-object-select'
                label={global._(
                  `BlueprintTool.ObjectModal.${type}ParentObjectLabel`
                )}
                helperText={global._('Common.NotRequired')}
                onChange={event => setParentObjectId(event.target.value)}
                value={parentObjectId}
              >
                <MenuItem value={0}>
                  {global._(
                    `BlueprintTool.ObjectModal.${type}ParentObjectPlaceholder`
                  )}
                </MenuItem>
                {getParentObjectOptions().map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
          <AutocompleteCustomOtherSelect
            helperText={global._('Common.Required')}
            label={global._('BlueprintTool.ObjectModal.SpaceLabel')}
            otherOptionLabel={global._(
              'BlueprintTool.ObjectModal.SpaceOtherOptionLabel'
            )}
            otherTextFieldLabel={global._(
              'BlueprintTool.ObjectModal.SpaceOtherTexFieldLabel'
            )}
            placeholder={global._('Common.Choose')}
            options={spaceOptions}
            onValueChange={opt => setSpace(opt && opt.value ? opt.value : '')}
            value={space}
          />
          {type !== BlueprintObjectTypes.Door && (
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                variant='filled'
                multiline
                rowsMax='4'
                label={global._('BlueprintTool.ObjectModal.NotesLabel')}
                helperText={global._('Common.NotRequired')}
                placeholder={global._(
                  'BlueprintTool.ObjectModal.NotesPlaceholder'
                )}
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
            </FormControl>
          )}
          <div style={{ height: 16 }} />
          <Typography variant='h4' style={{ color: '#8e8e8e' }}>
            {global._('BlueprintTool.ObjectModal.UploadText')}
          </Typography>
          <div style={{ height: 20 }} />
          <DragZone
            title={global._('BlueprintTool.ObjectModal.FileDropTitle')}
            buttonVariant='outlined'
            buttonOnly
            onFileSelected={onFileSelected}
          />
          <div style={{ height: 16 }} />
          {files.map(file => {
            const { id, name, size, type: fileType, data } = file.file || file

            const fileIdentifier = object.id ? id : `${name}-${size}`

            return (
              <React.Fragment key={`object-modal-file-${id || name}`}>
                <FileContainer
                  file={file.file || file}
                  src={
                    object &&
                    object.id &&
                    `/v1/blueprints/${blueprint.id}/objects/${object.id}/files/${file.id}`
                  }
                  data={data}
                  name={name}
                  size={size}
                  type={fileType}
                  openAttachmentModalWithoutButtons={
                    isMobile &&
                    openAttachmentModalOnUpload &&
                    fileIdentifier === openAttachmentModalOnUpload
                  }
                  setOpenAttachmentModal={setOpenAttachmentModalOnUpload}
                  endIcon={
                    // eslint-disable-next-line no-nested-ternary
                    isMobile ? (
                      // eslint-disable-next-line no-nested-ternary
                      imageTypes.includes(fileType) ? (
                        <Icon style={{ marginLeft: 'auto' }}>visibility</Icon>
                      ) : videoTypes.includes(fileType) ? (
                        'delete'
                      ) : (
                        ''
                      )
                    ) : undefined
                  }
                  onDelete={() => onRemoveFile(file)}
                  token={token}
                  buttons={url => [
                    object.id && (
                      <Button variant='primary' onClick={() => saveAs(url)}>
                        {global._('Common.Download')}
                      </Button>
                    ),
                    <Button
                      variant='secondary'
                      onClick={() => onRemoveFile(file)}
                    >
                      {global._('Common.Delete')}
                    </Button>
                  ]}
                />
                <div style={{ height: 10 }} />
              </React.Fragment>
            )
          })}
          {!fullscreen && error && (
            <Notification
              style={{ marginTop: 20 }}
              type='error'
              message={error}
            />
          )}
        </div>
      }
      buttons={[
        !fullscreen && (
          <Button
            key='objectModal-btn-1'
            variant='none'
            onClick={onClose}
            style={{ marginRight: object.id ? 'auto' : 10 }}
          >
            {global._('Common.Cancel')}
          </Button>
        ),
        fullscreen && error && (
          <Notification
            key='item-modal-error'
            style={{ marginBottom: 20 }}
            type='error'
            message={error}
          />
        ),
        object.id && (
          <Button
            key='objectModal-btn-3'
            variant='outlined'
            onClick={() => onDeleteObject(object)}
            style={{
              marginRight: fullscreen ? undefined : 15,
              marginBottom: fullscreen ? 20 : undefined
            }}
          >
            {global._('BlueprintTool.ObjectModal.DeleteObject')}
          </Button>
        ),
        <Button
          data-cy='button-add-object'
          key='objectModal-btn-2'
          variant='primary'
          disabled={!type || !space}
          loading={isLoading}
          onClick={onCreateOrUpdateObject}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            object.id
              ? fullscreen
                ? global._('Common.SaveAndClose')
                : global._('Common.Save')
              : global._('Common.Add')
          }
        </Button>
      ]}
    />
  )
}

ObjectModal.propTypes = {
  blueprint: PropTypes.object.isRequired,
  classes: PropTypes.object,
  token: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateObject: PropTypes.func.isRequired,
  onUpdateObject: PropTypes.func.isRequired,
  onDeleteObject: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool
}

ObjectModal.defaultProps = {
  classes: {},
  fullscreen: false
}

export default withStyles(styles)(withToken(ObjectModal))
