import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import {
  Grid,
  Icon,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core'
import moment from 'moment'
import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import Upload from '../../../components/DragAndDrop/DragZone'
import Button from '../../../components/Button'
import ItemBadge from '../../../components/ItemBadge'
import AsyncSelect from '../../../components/AsyncSelect'
import Paper from '../../../components/Paper'
import Notification from '../../../common/Notification'
import Typography from '../../../components/Typography'
import Alert from '../../../components/Alert'
import {
  getSelectedOrg,
  convertDraftJsToPdfMakeObj,
  capitalizeFirstLetter
} from '../../../utils/helpers'
import withUser from '../../../utils/withUser'
import { keyLoanTemplate } from '../../../utils/receiptTemplates'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const styles = {
  root: {
    marginTop: 21
  },
  infoContainer: {
    backgroundColor: 'var(--color-primary-tone-4)',
    border: '1px solid #e0e0e0',
    borderRadius: 3,
    padding: '17px 20px 15px 16px'
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'var(--color-divider-light)'
  },
  signDetailsContainer: {
    display: 'block',
    padding: 4,
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
    borderRadius: 3,
    width: '100%'
  },
  container: {
    width: '100%',
    padding: 20
  },
  singleRadioButton: {
    marginBottom: '0 !important'
  }
}

const Signing = ({ classes, data, onDataChange, ...otherProps }) => {
  const selectedOrg = getSelectedOrg(otherProps.user)
  const [error, setError] = useState(null)
  const [file, setFile] = useState(null)
  const [template, setTemplate] = useState(null)
  const [isCollecting, setIsCollecting] = useState(false)
  const orderRef = useRef(null)
  const intervalRef = useRef(null)
  const [completionData, setCompletionData] = useState(null)
  const [signedAt, setSignedAt] = useState(null)
  const [digitalSignRequestSent, setDigitalSignRequestSent] = useState(false)

  const digitalSignRequestData = () => ({
    keys: data.keys,
    user: data.user,
    expectedReturnDate: data.expectedReturnDate,
    untilFurtherNotice: data.untilFurtherNotice,
    sign: {
      templateId: template.id,
      type: 'bankid',
      orderRef: orderRef.current
    }
  })
  const cancelDigitalSign = async () => {
    clearInterval(intervalRef.current)
    intervalRef.current = null
    setError(null)
    setIsCollecting(false)
    try {
      await axios.post(
        `/v1/organisations/${selectedOrg.id}/flows/keyloan/cancel`,
        digitalSignRequestData()
      )
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  const startCollectInterval = () => {
    setIsCollecting(true)
    intervalRef.current = setInterval(async () => {
      const res = await axios.post(
        `/v1/organisations/${selectedOrg.id}/flows/keyloan/collect`,
        digitalSignRequestData()
      )
      if (res.data.status === 'COMPLETE') {
        setIsCollecting(false)
        setCompletionData(res.data.completionData)
        setSignedAt(moment().format('YYYY-MM-DD [kl] HH:mm'))
        clearInterval(intervalRef.current)
        intervalRef.current = null
        orderRef.current = null
        onDataChange({ completionData: res.data.completionData })
      } else if (res.data.status === 'FAILED') {
        setIsCollecting(false)
        setError(global._('Error.BankID.SigningFailed'))
        clearInterval(intervalRef.current)
        intervalRef.current = null
        orderRef.current = null
      }
    }, 2e3)
  }
  const initiateDigitalSign = async () => {
    setError(null)
    try {
      const res = await axios.post(
        `/v1/organisations/${selectedOrg.id}/flows/keyloan/sign`,
        digitalSignRequestData()
      )
      orderRef.current = res.data.orderRef
      startCollectInterval()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  let pdfName
  if (template) {
    pdfName = `${global._('Keys.New.Receipt.Receipt')}-${template.name}-${
      data.user.name
    }-${moment().format('YYYY-MM-DD')}.pdf`
  }

  const initiateDigitalSignLaterRequest = async () => {
    setError(null)
    try {
      await axios.post(
        `/v1/organisations/${selectedOrg.id}/flows/keyloan/signLater`,
        digitalSignRequestData()
      )

      setDigitalSignRequestSent(true)
      onDataChange({ postponedSignRequestSent: true })
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    setDigitalSignRequestSent(false)
    onDataChange({ postponedSignRequestSent: false })
  }, [data.sign.type, data.sign.digitalSignType])

  const analogSigningContent = (
    <>
      <div className={classes.container}>
      {(!data.user.id || data.sign.type === 'analog') && ( // TODO: update
        <Alert style={{ alignItems: 'flex-start' }}>
          {!data.user.id && (
            <Typography variant='body' bold>
              {global._(
                'Keys.Loan.Signing.InformationTitleNewUser'
              )}
            </Typography>
          )}
          <>
            <Typography
              variant='body'
              bold
              block
              style={{
                marginBottom: 20
              }}>
              {global._('Keys.Loan.Signing.Analog.InformationBodyTitle')}
            </Typography>
            <Typography
              variant='body'
              style={{
                marginTop: data.user.id ? 0 : 24,
                whiteSpace: 'pre'
              }}>
              {global._('Keys.Loan.Signing.Analog.InformationBody')}
            </Typography>
          </>
        </Alert>
      )}
        <>
          <Typography
            variant='subtitle'
            style={{ marginTop: 20, marginBottom: 20 }}>
            {global._('Keys.Loan.Signing.UploadSignedReceipt')}
          </Typography>
          {file ? (
            <Grid xs={6}>
              <ItemBadge
                onEndIconClick={() => {
                  setFile(null)
                  onDataChange({ file: null })
                }}
                label={file.name}
                icon='web_asset'
                endIcon='delete'
              />
            </Grid>
          ) : (
            <Upload
              allowedTypes={[
                'application/pdf',
                'image/jpeg',
                'image/png'
              ]}
              allowedSize={10 * 1000 * 1000}
              buttonOnly
              buttonVariant='outlined'
              onFileSelected={uploadedFile => {
                setFile(uploadedFile)
                onDataChange({ file: uploadedFile })
              }}
            />
          )}
          <Typography body faded block italic style={{ marginTop: 10 }}>
            {global._('Keys.New.Holder.ReceiptFileInfo')}
          </Typography>
        </>
      </div>
    </>
  )

  const digitalSigningContent = (
    <>
    <div className={classes.container} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <FormControl
          component='fieldset'
          fullWidth
        >
          <RadioGroup
            name='holderType'
            onChange={e => {
              onDataChange({ digitalSignType: e.target.value })
            }}
            value={data.sign.digitalSignType}
            style={{ flexDirection: 'row' }}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  value='direct'
                  disabled={digitalSignRequestSent}
                  className={classes.singleRadioButton}
                  control={
                    <Radio
                      style={{ color: 'var(--color-black)' }}
                    />
                  }
                  label={global._('Keys.Loan.Signing.Digital.Direct.Title')}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value='later'
                  disabled={digitalSignRequestSent || (!data.user.personalNumber || !data.user.email)}
                  className={classes.singleRadioButton}
                  control={
                    <Radio
                      style={{ color: 'var(--color-black)' }}
                    />
                  }
                  label={(
                    <span>
                      {global._('Keys.Loan.Signing.Digital.Later.Title')}
                      {
                        !data.user.email && (
                          <Typography variant='body2' block style={{ color: 'var(--color-error)'}}>
                            {global._('Keys.Loan.Signing.Digital.Later.MissingDataError')}
                          </Typography>
                        )
                      }
                    </span>
                  )}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </div>
      <div className={classes.divider} />
      <div className={classes.container}>
        <Grid>
          {!completionData && (
            <>
              <Alert style={{ alignItems: data.sign.type === 'digital' && data.sign.digitalSignType === 'later' ? 'center' : 'flex-start' }}>
                {
                  data.sign.digitalSignType === 'direct' && (
                    <Typography
                      variant='body'
                      bold
                      block
                      style={{
                        marginBottom: 20
                      }}>
                      {global._('Keys.Loan.Signing.Digital.Direct.InformationBodyTitle')}
                    </Typography>
                  )
                }
                <Typography
                  variant='body'
                  style={{
                    marginTop: data.user.id ? 0 : 24
                  }} dangerouslySetInnerHTML={{ __html: digitalSignRequestSent ? global._(`Keys.Loan.Signing.Digital.${capitalizeFirstLetter(data.sign.digitalSignType)}.InformationBodyRequestSent`) : global._(`Keys.Loan.Signing.Digital.${capitalizeFirstLetter(data.sign.digitalSignType)}.InformationBody`, [data.user.email]) }} />
              </Alert>
            </>
          )}
          <div style={{ float: 'left' }}>
            {
              !digitalSignRequestSent && (
                <Button
                  variant={isCollecting ? 'outlined' : 'primary'}
                  disabled={!template}
                  onClick={
                    // eslint-disable-next-line no-nested-ternary
                    data.sign.digitalSignType === 'later' ? initiateDigitalSignLaterRequest : (isCollecting ? cancelDigitalSign : initiateDigitalSign)
                  }
                  style={{
                    marginTop: 20,
                    marginBottom: 20
                  }}>
                  {global._(
                    `Keys.Loan.Signing.${
                      isCollecting
                        ? 'WithdrawBankIdRequest'
                        : 'RequestBankIdSign'
                    }`
                  )}
                </Button>
              )
            }
            {isCollecting && (
              <div
                className={classes.signDetailsContainer}
                style={{
                  backgroundColor: 'var(--color-primary)',
                  color: 'var(--color-logo-black)'
                }}>
                {global._('Keys.Loan.Signing.WaitingForSign', [
                  data.user.name
                ])}
              </div>
            )}
            {completionData && (
              <div
                className={classes.signDetailsContainer}
                style={{
                  backgroundColor: 'var(--color-logo-black)',
                  color: 'white'
                }}>
                {global._('Keys.Loan.Signing.SignedAt', [
                  data.user.name,
                  signedAt
                ])}
              </div>
            )}
          </div>
          {digitalSignRequestSent && (
            <div
              className={classes.signDetailsContainer}
              style={{
                backgroundColor: 'var(--color-logo-black)',
                color: 'white',
                width: '100%',
                marginTop: 20
              }}>
              {global._('Keys.Loan.Signing.Digital.Later.RequestSentInformation', [data.user.email])}
            </div>
          )}
        </Grid>
      </div>
    </>
  )

  return (
    <div className={classes.root}>
      {error && <Notification type='error' message={error} />}
      <Paper noPadding>
        <Typography variant='subtitleBig' style={{ padding: '32px 20px' }}>
          {global._('Keys.Loan.Signing.SectionTitle')}
          <strong>{` ${data.user.name}`}</strong>
        </Typography>
        <div className={classes.divider} />
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={6}>
            <AsyncSelect
              isSearchable
              value={
                template
                  ? { value: template, label: template.name }
                  : null
              }
              disabled={isCollecting || !!completionData}
              key='search-receipt-templates'
              helpText={global._(
                'Keys.Loan.Signing.ReceiptTemplateHelpText'
              )}
              label={obj => obj.name}
              placeholder={global._('Keys.Loan.Signing.ReceiptTemplate')}
              onChange={e => {
                const _template = e ? e.value : null
                setTemplate(_template)
                onDataChange({ template: _template })
              }}
              url={`/v1/organisations/${selectedOrg.id}/keyreceipttemplates?orderBy=createdAt&order=desc&search={{input}}`}
            />
          </Grid>
          <Grid item xs={6}>
            {template && (
                <ItemBadge
                  onEndIconClick={() => {
                    if (completionData) {
                      return
                    }
                    const pdfContent = keyLoanTemplate(
                      template,
                      data.keys,
                      data.user,
                      selectedOrg
                    )
                    const docDefinition =
                      convertDraftJsToPdfMakeObj(pdfContent)
                    docDefinition.info = {
                      title: pdfName
                    }
                    pdfMake.createPdf(docDefinition).print()
                  }}
                  label={pdfName}
                  icon='description'
                  endIcon={
                    completionData ? (
                      <Icon
                        style={{
                          marginLeft: 'auto',
                          color: 'var(--color-success-green)'
                        }}>
                        check_circle
                      </Icon>
                    ) : (
                      'print'
                    )
                  }
                />
            )}
          </Grid>
        </Grid>
        <div className={classes.divider} />
        <div className={classes.container} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <FormControl
            component='fieldset'
            fullWidth
          >
            <RadioGroup
              name='holderType'
              onChange={e => {
                onDataChange({ type: e.target.value })
              }}
              value={data.sign.type}
              style={{ flexDirection: 'row' }}>
              <Grid container>
                <Grid item xs={6}>
                  <FormControlLabel
                    value='digital'
                    disabled={!data.user || !data.user.personalNumber || digitalSignRequestSent}
                    className={classes.singleRadioButton}
                    control={
                      <Radio
                        style={{ color: 'var(--color-black)' }}
                      />
                    }
                    label={(
                      <span>
                        {global._('Keys.Loan.Signing.DigitalSigning')}
                        {
                          !data.user.personalNumber && (
                            <Typography variant='body2' block style={{ color: 'var(--color-error)'}}>
                              {global._('Keys.Loan.Signing.Digital.MissingDataError')}
                            </Typography>
                          )
                        }
                      </span>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value='analog'
                    disabled={digitalSignRequestSent}
                    className={classes.singleRadioButton}
                    control={
                      <Radio
                        style={{ color: 'var(--color-black)' }}
                      />
                    }
                    label={global._('Keys.Loan.Signing.AnalogSigning')}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.divider} />
        {
          data.sign.type === 'analog' ? analogSigningContent : digitalSigningContent
        }
        <div className={classes.divider} />
        
      </Paper>
    </div>
  )
}

Signing.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onDataChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

Signing.defaultProps = {}

const SigningWithStylesAndUser = withUser(injectSheet(styles)(Signing))
export default SigningWithStylesAndUser
