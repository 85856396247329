import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { Today } from '@material-ui/icons'
import { Grid, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import IconColumn from 'src/components/IconColumn'
import { history } from 'src/redux/store'
import AsyncSelect from 'src/components/AsyncSelect'
import Button from '../../../components/Button'
import Notification from '../../../common/Notification'
import Paper from '../../../components/Paper'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ObjectList from '../../../components/ObjectList'
import PageContentHeader from '../../../components/PageContentHeader'
import {
  getSelectedOrg,
  mapKeyInventoryApprovalStatus,
  normalize
} from '../../../utils/helpers'
import withUser from '../../../utils/withUser'
import {
  KeyInventoryStatus,
  KeyInventoryApprovalStatus
} from '../../../utils/constants'
import ConfirmationModal from '../../../components/ConfirmationModal'
import SuccessMessage from '../../../components/SuccessMessage'
import SearchField from '../../../components/SearchField'
import { excel as exportExcel } from '../../../utils/export'

const styles = {
  root: {},
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  },
  listHeader: {
    width: '100%',
    padding: '20px 20px 0'
  }
}

export const MANUAL_VERIFICATION_DATA_STORAGE_KEY =
  '@keys/inventory/manual/data'
export const MANUAL_VERIFICATION_SUCCESS_STORAGE_KEY =
  '@keys/inventory/manual/success'
const stringComparables = [
  'name',
  'marking',
  'status',
  'approvalStatus',
  'verifiedBy',
  'holderName',
  'lockSystemName'
]

const KeyInventoryDetailView = ({ classes, breadcrumbItems, user, match }) => {
  const { inventoryId } = match.params
  const selectedOrg = getSelectedOrg(user)

  const [isLoading, setIsLoading] = useState(false)
  const [confirmFinish, setConfirmFinish] = useState(false)
  const [confirmSendReminder, setConfirmSendReminder] =
    useState(null) /* populated with key object created in useEffect() */
  const [successMessage, setSuccessMessage] = useState(null)
  const [successCounter, setSuccessCounter] = useState(0)

  const [inventory, setInventory] = useState(null)
  const [notes, setNotes] = useState('')
  const [keys, setKeys] = useState([])
  const [filteredKeys, setFilteredKeys] = useState([])
  const [filter, setFilter] = useState({
    orderBy: 'name',
    order: 'desc',
    limit: 25,
    offset: 0
  })

  const [expanded, setExpanded] = useState([])

  const [error, setError] = useState(null)

  /* eslint-disable no-param-reassign */
  const applyFilter = newFilter => {
    let list
    newFilter.offset = 0
    if (newFilter.search && newFilter.search !== filter.search) {
      const search = normalize(newFilter.search.toLowerCase())
      list = keys.filter(key => key.search.indexOf(search) > -1)
    } else {
      list = [...keys]
    }

    const { orderBy, order } = newFilter
    if (orderBy !== filter.orderBy || order !== filter.order) {
      list.sort((a, b) => {
        if (stringComparables.includes(orderBy)) {
          if (filter.order === 'asc') {
            return a[orderBy].localeCompare(b[orderBy])
          }
          return b[orderBy].localeCompare(a[orderBy])
        }
        return 0
      })
    }
    setFilteredKeys(list)
    setFilter(newFilter)
  }

  useEffect(() => {
    applyFilter(filter)
  }, [keys])

  const onUpdate = async (key, val) => {
    if (inventory && inventory[key] == val) {
      return
    }
    setError(null)
    setIsLoading(true)
    try {
      const { data } = await axios.patch(
        `/v1/organisations/${selectedOrg.id}/keyinventories/${inventory.id}`,
        { [key]: val }
      )
      setInventory({ ...inventory, ...data })
      setSuccessMessage(global._('Keys.Inventory.Detail.UpdateSuccessMessage'))
      setSuccessCounter(successCounter + 1)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  const onFinishConfirmed = async () => {
    /* errors are caught and displayed in ConfirmationModal */
    await axios.patch(
      `/v1/organisations/${selectedOrg.id}/keyinventories/${inventory.id}`,
      { status: KeyInventoryStatus.ENDED }
    )
    setInventory({
      ...inventory,
      status: KeyInventoryStatus.ENDED
    })
    setConfirmFinish(false)
  }

  const onSendReminder = async key => {
    /* errors are caught and displayed in ConfirmationModal */
    await axios.post(
      `/v1/organisations/${selectedOrg.id}/keyinventories/${inventory.id}/approvals/${key.approvalId}/reminder`
    )
    setSuccessMessage(global._('Keys.Inventory.Detail.ReminderSent'))
    setSuccessCounter(successCounter + 1)
    setConfirmSendReminder(null)
  }

  const handleManualVerification = () => {
    const data = {
      inventory,
      keys: keys.map(key => ({
        id: key.id,
        name: key.name,
        marking: key.marking,
        holderName: key.holderName,
        approvalStatus: key.approvalStatus
      }))
    }
    localStorage.setItem(
      MANUAL_VERIFICATION_DATA_STORAGE_KEY,
      JSON.stringify(data)
    )
    history.push(`/keys/inventory/${inventory.id}/manual`)
  }

  const onExport = () => {
    const columns = [
      {
        label: global._('Keys.Inventory.Detail.Export.KeyName'),
        value: row => row.name
      },
      {
        label: global._('Keys.Inventory.Detail.Export.KeyMarking'),
        value: row => row.marking
      },
      {
        label: global._('Keys.Inventory.Detail.Export.LockSystemName'),
        value: row => row.lockSystemName
      },
      {
        label: global._('Keys.Inventory.Detail.Export.ApprovalStatus'),
        value: row =>
          global._(`Keys.Inventory.Approvals.Status.${row.approvalStatus}`)
      },
      {
        label: global._('Keys.Inventory.Detail.Export.Holder'),
        value: row => row.holderName
      },
      {
        label: global._('Keys.Inventory.Detail.Export.HolderType'),
        value: row =>
          global._(`Keys.Inventory.Detail.Export.HolderType.${row.holderType}`)
      }
    ]

    const sheet = {
      name: global._('Keys.Inventory.Detail.Export.SheetName'),
      data: keys,
      columns
    }
    exportExcel({
      filename: global._('Keys.Inventory.Detail.Export.Filename', [
        moment(inventory.startDate).format('YYYY-MM-DD')
      ]),
      sheets: [sheet]
    })
  }

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(
          `/v1/organisations/${selectedOrg.id}/keyinventories/${inventoryId}`
        )
        setInventory(data)
        setNotes(data.notes)
        const { keyInventoryApprovals: approvals } = data
        const items = []
        approvals.forEach(approval => {
          const { key } = approval
          let holderName
          let holderType
          let holderProfileUrl
          if (key.user) {
            holderName = key.user.name
            holderType = 'user'
            holderProfileUrl = `/admin/users/${selectedOrg.id}/${key.user.id}`
          } else if (key.keyStorage) {
            holderName = key.keyStorage.name
            holderType = 'storage'
            holderProfileUrl = `/admin/users/${selectedOrg.id}/${key.keyStorage.user.id}`
          } else if (key.apartment) {
            holderName = key.apartment.refId
            holderType = 'apartment'
            holderProfileUrl = `/admin/apartments?search=${key.apartment.refId}&organisationId=${selectedOrg.id}&organisationName=${selectedOrg.name}`
          }
          const item = {
            ...key,
            lockSystemName: key.lockSystem && key.lockSystem.name,
            approvalStatus: approval.status,
            approvalId: approval.id,
            verifiedBy: approval.user ? approval.user.name : '-',
            holderName,
            holderType,
            holderProfileUrl
          }

          /* preprocess for searches */
          item.search = ''
          if (typeof item.holderName === 'string') {
            item.search += item.holderName.toLowerCase()
          }
          if (typeof item.name === 'string') {
            item.search += item.name.toLowerCase()
          }
          if (typeof item.marking === 'string') {
            item.search += item.marking.toLowerCase()
          }

          if (typeof item.verifiedBy === 'string') {
            item.search += item.verifiedBy.toLowerCase()
          }
          item.search = normalize(item.search)

          items.push(item)
        })
        setKeys(items)
        setFilteredKeys(items)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
      setIsLoading(false)
    })()
  }, [])

  const dateSelectionDisabled =
    inventory &&
    (inventory.status !== KeyInventoryStatus.ACTIVE ||
      moment(inventory.startDate).isBefore(moment()))
  const crumb = {
    name:
      inventory && inventory.status === KeyInventoryStatus.ACTIVE
        ? global._('Keys.Inventory.Detail.Active')
        : global._('Keys.Inventory.Detail.Ended'),
    path: '/keys/inventory/:id'
  }
  return (
    <div className={classes.root}>
      <SuccessMessage
        subtitle={global._(
          'Keys.Inventory.Detail.ManualVerificationSuccessMessage'
        )}
        localStorageProp={MANUAL_VERIFICATION_SUCCESS_STORAGE_KEY}
      />
      <SuccessMessage counter={successCounter} subtitle={successMessage} />
      {confirmSendReminder && (
        <ConfirmationModal
          onClose={() => setConfirmSendReminder(null)}
          onConfirm={() => onSendReminder(confirmSendReminder)}
          title={global._('Keys.Inventory.Detail.ConfirmSendReminderTitle')}
          message={global._('Keys.Inventory.Detail.ConfirmSendReminderMessage')}
        />
      )}
      {confirmFinish && (
        <ConfirmationModal
          onClose={() => setConfirmFinish(false)}
          onConfirm={onFinishConfirmed}
          title={global._('Keys.Inventory.Detail.ConfirmFinishTitle')}
          message={global._('Keys.Inventory.Detail.ConfirmFinishMessage')}
        />
      )}
      <PageContentHeader
        breadcrumbItems={[...breadcrumbItems, crumb]}
        actionButtons={
          inventory &&
          inventory.status === KeyInventoryStatus.ACTIVE && (
            <Button
              disabled={isLoading}
              variant='secondary'
              onClick={() => setConfirmFinish(true)}
            >
              {global._('Keys.Inventory.Detail.FinishInventory')}
            </Button>
          )
        }
      />
      <Paper
        style={{ marginTop: 22, padding: 0 }}
        icon='verified_user'
        title={global._('Keys.Inventory.New.Title')}
        subtitle={inventory ? inventory.id.toString() : '-'}
        buttons={
          !inventory ? (
            <LoadingSpinner />
          ) : (
            <>
              {inventory.status === KeyInventoryStatus.ACTIVE && (
                <Button
                  onClick={handleManualVerification}
                  variant='outlined'
                  style={{ marginRight: 20 }}
                >
                  {global._(
                    'Keys.Inventory.Detail.ManualVerificationButtonTitle'
                  )}
                </Button>
              )}
              <Button onClick={onExport} variant='outlined'>
                {global._('Common.Export')}
              </Button>
            </>
          )
        }
      >
        <Grid
          container
          style={{ padding: '22px 20px', marginBottom: 6 }}
          spacing={3}
        >
          <Grid item xs={6}>
            <DatePicker
              style={{ width: '100%' }}
              InputProps={{
                endAdornment: <Today style={{ cursor: 'pointer' }} />
              }}
              inputVariant='filled'
              label={global._('Keys.Inventory.StartDate')}
              format='YYYY-MM-DD'
              helperText={global._('Keys.Inventory.StartDateHelperText')}
              value={inventory ? moment(inventory.startDate) : moment()}
              disabled={dateSelectionDisabled}
              minDate={dateSelectionDisabled ? undefined : moment()}
              okLabel={null}
              cancelLabel={null}
              autoOk
              onChange={date =>
                onUpdate('startDate', date.format('YYYY-MM-DD'))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='filled'
              style={{ width: '100%' }}
              label={global._('Keys.Inventory.Notes')}
              value={notes || ''}
              onChange={e => setNotes(e.target.value)}
              onBlur={() => onUpdate('notes', notes)}
            />
          </Grid>
          {inventory && (
            <Grid item xs={6}>
              <AsyncSelect
                isSearchable
                // helpText={global._('Keys.Inventory.ReceiptTemplateHelperText')}
                value={
                  inventory.keyReceiptTemplate
                    ? {
                        label: inventory.keyReceiptTemplate.name,
                        value: inventory.keyReceiptTemplate
                      }
                    : null
                }
                labelKey='name'
                name='receiptTemplateId'
                isClearable
                placeholder={global._('Keys.New.Receipt.ReceiptTemplate')}
                onChange={option => {
                  if (option) {
                    onUpdate('receiptTemplateId', option.value.id)
                  } else {
                    onUpdate('receiptTemplateId', null)
                  }
                }}
                cacheOptions={false}
                url={`/v1/organisations/${selectedOrg.id}/keyreceipttemplates`}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      {error && <Notification type='error' message={error} />}
      <div style={{ marginTop: 24 }}>
        <ObjectList
          sectionTitle={global._('Keys.List.Title')}
          filterFields={
            <SearchField
              style={{ width: '50%' }}
              label={global._('Keys.Inventory.Detail.SearchPlaceholder')}
              variant='filled'
              value={filter.search || ''}
              onChange={e => applyFilter({ ...filter, search: e.target.value })}
            />
          }
          filter={filter}
          setFilter={applyFilter}
          rowIdentifier={obj => `key-${obj.id}`}
          columns={[
            {
              key: 'icon',
              sortingDisabled: true,
              style: { width: 50 },
              format: () => <IconColumn icon='vpn_key' />
            },
            {
              key: 'name',
              localizationKey: 'Keys.Inventory.Column.Name',
              sortingKey: 'name',
              style: { width: 150 },
              clickable: true
            },
            {
              key: 'marking',
              localizationKey: 'Keys.List.Marking',
              format: value => value || '-',
              sortingKey: 'marking',
              style: { width: 150 },
              clickable: true
            },
            {
              key: 'lockSystemName',
              localizationKey: 'Keys.List.LockSystemName',
              format: value => value || '-',
              sortingKey: 'lockSystemName',
              style: { width: 150 },
              clickable: true
            },
            {
              key: 'approvalStatus',
              localizationKey: 'Keys.List.Status',
              format: status => (
                <span
                  className={`status ${mapKeyInventoryApprovalStatus(status)}`}
                >
                  {global._(`Keys.Inventory.Approvals.Status.${status}`)}
                </span>
              ),
              clickable: true,
              sortingKey: 'approvalStatus',
              style: { width: 105 }
            },
            {
              key: 'verifiedBy',
              localizationKey: 'Keys.Inventory.Column.VerifiedBy',
              clickable: true,
              sortingKey: 'verifiedBy',
              style: { width: 105 }
            },
            {
              key: 'holderName',
              localizationKey: 'Keys.List.Holder',
              clickable: true,
              sortingKey: 'holderName',
              style: { width: 105 }
            }
          ]}
          expandable
          expandedRows={expanded}
          setExpandedRows={setExpanded}
          actionButtons={(id, key) => (
            <>
              <Button
                variant='outlined'
                onClick={() =>
                  window.open(key.holderProfileUrl, '_blank', 'noopener')
                }
                style={{ marginLeft: 'auto' }}
              >
                {global._(
                  `Keys.Inventory.Detail.${
                    key.apartment ? 'ShowApartment' : 'ShowHolderProfile'
                  }`
                )}
              </Button>
              {inventory &&
                inventory.status === KeyInventoryStatus.ACTIVE &&
                key.approvalStatus !== KeyInventoryApprovalStatus.VERIFIED && (
                  <Button
                    variant='outlined'
                    onClick={() => setConfirmSendReminder(key)}
                    style={{ marginLeft: 20 }}
                  >
                    {global._('Keys.Inventory.Detail.SendReminder')}
                  </Button>
                )}
              <Button
                variant='secondary'
                onClick={() =>
                  window.open(`/keys/list/${key.id}`, '_blank', 'noopener')
                }
                style={{ marginLeft: 20 }}
              >
                {global._('Keys.Inventory.Detail.ShowKey')}
              </Button>
            </>
          )}
          objects={filteredKeys.slice(0, filter.offset + filter.limit)}
          canFetchMoreObjects={
            filter.offset + filter.limit < filteredKeys.length
          }
          fetchMoreObjects={() =>
            setFilter({ ...filter, offset: filter.offset + filter.limit })
          }
        />
      </div>
    </div>
  )
}

KeyInventoryDetailView.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired
}

KeyInventoryDetailView.defaultProps = {}

const KeyInventoryDetailViewWithStylesAndUser = withUser(
  injectSheet(styles)(KeyInventoryDetailView)
)
export default KeyInventoryDetailViewWithStylesAndUser
