import { OrganisationOption } from 'src/components/AsyncSelectOrganisation'

export interface ServiceRequestFilter {
  search?: Maybe<string>
  organisationOption?: Maybe<OrganisationOption>
  from?: Maybe<Date>
  to?: Maybe<Date>
}
export interface IApartment {
  id: number
  organisationId: number
  organisation?: Maybe<IOrganisation>
  users?: Maybe<IUser[]>
  refId: string
  number: number
  address: string
  additionalInformation: string | null
  createdAt: Date
  updatedAt: Date
}

export enum IServiceRequestStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED'
}
type Maybe<T> = T | null
export interface IServiceRequest {
  id?: Maybe<number>
  groupId?: Maybe<string>
  organisationId?: Maybe<number>
  organisation?: Maybe<IOrganisation>
  from?: Maybe<Date>
  to?: Maybe<Date>
  apartmentId?: Maybe<number>
  apartment?: Maybe<IApartment>
  message?: Maybe<string>
  userId?: Maybe<number>
  userMessage?: Maybe<string>
  user?: Maybe<IUser>
  status?: Maybe<IServiceRequestStatus>
  createdAt?: Maybe<Date>
  updatedAt?: Maybe<Date>
}

export interface IServiceRequestGroup {
  id: string
  organisationId: number
  organisation?: Maybe<IOrganisation>
  from: Date
  to: Date
  message: string
  createdAt: Date
  updatedAt: Date
  items: IServiceRequest[]
}
