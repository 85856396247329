// Re-generates manifest.json based on variant
// default: default portal variant
// access: delegated access views
let _variant
export const Variants = {
  DEFAULT: 'DEFAULT',
  ACCESS: 'ACCESS'
}

const { origin } = window.location
export const setPWA = (variant, extra) => {
  if (_variant === variant) {
    return
  }
  let icon = '/apple-touch-icon.png'
  const manifest = {
    short_name: 'Låsportalen',
    name: 'Låsportalen',
    icons: [
      {
        src: `${origin}/icons/icon-128.png`,
        sizes: '128x128',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/icon-144.png`,
        sizes: '144x144',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/icon-152.png`,
        sizes: '152x152',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/icon-192.png`,
        sizes: '192x192',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/icon-256.png`,
        sizes: '256x256',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/logo-512.png`,
        sizes: '512x512',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/logo-512.png`,
        sizes: '512x512',
        type: 'image/png',
        purpose: 'maskable'
      }
    ],
    start_url: `${origin}/?ref=pwa`,
    scope: `${origin}/`,
    display: 'standalone',
    theme_color: '#000000',
    orientation: 'portrait',
    background_color: '#1b1c1a'
  }

  if (variant === Variants.ACCESS) {
    icon = '/apple-touch-icon-access.png'
    /* we use a token that can be exchanged only once for new credentials when switching to pwa */
    manifest.start_url = `${origin}/access/${extra.accessUserUuid}?ref=pwa&exchangeToken=${extra.exchangeToken}`
    manifest.short_name = 'Åtkomst'
    manifest.theme_color = '#ffffff'
    manifest.background_color = '#ffffff'
    manifest.name = 'Åtkomst'
    manifest.icons = [
      {
        src: `${origin}/icons/access-128.png`,
        sizes: '128x128',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/access-144.png`,
        sizes: '144x144',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/access-152.png`,
        sizes: '152x152',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/access-192.png`,
        sizes: '192x192',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/access-256.png`,
        sizes: '256x256',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/access-512.png`,
        sizes: '512x512',
        type: 'image/png'
      },
      {
        src: `${origin}/icons/access-512.png`,
        sizes: '512x512',
        type: 'image/png',
        purpose: 'maskable'
      }
    ]
    // TODO: Implement token exchange in DelegatedAccess/Locks.js
  }

  const stringManifest = JSON.stringify(manifest)
  const blob = new Blob([stringManifest], { type: 'application/manifest+json' })
  const manifestURL = URL.createObjectURL(blob)
  document.querySelector('#header-manifest').setAttribute('href', manifestURL)
  document.querySelector('#header-touch-icon').setAttribute('href', icon)
  _variant = variant
}
