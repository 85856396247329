import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import {
  Checkbox,
  FilledInput,
  FormControl,
  Grid,
  Icon,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@material-ui/core'
import queryString from 'querystring'
import moment from 'moment'
import { DatePicker } from '@material-ui/pickers'
import { Clear, Today } from '@material-ui/icons'
import PageContentHeader from '../../components/PageContentHeader'
import useWindowDimensions from '../../utils/useWindowDimensions'
import ObjectList from '../../components/ObjectList'
import { UserRoles, LogOperations, LogTypes } from '../../utils/constants'
import Notification from '../../common/Notification'
import Paper from '../../components/Paper'
import SearchField from '../../components/SearchField'
import AsyncSelect from '../../components/AsyncSelect'
import Button from '../../components/Button'
import KeeHistoryMobile from './KeeHistoryMobile'

const styles = {
  root: {},
  input: {
    width: '100%'
  },
  inputWrapper: {
    marginBottom: 5
  },
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  }
}

const KeeHistory = props => {
  const { classes, history, breadcrumbItems, signout, user } = props

  const [error, setError] = useState(false)
  const [events, setEvents] = useState(false)
  const [canFetchMoreEvents, setCanFetchMoreEvents] = useState(false)
  const [isLoadingEvents, setIsLoadingEvents] = useState(false)

  const localizeOperation = value => {
    if (value === 'all') {
      return global._('KeeHistory.AllOperations')
    }
    return global._(`KeeHistory.Operations.${value}`)
  }
  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isCustomerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.CustomerAdmin)

  const defaultFilter = {
    orderBy: 'createdAt',
    order: 'desc',
    search: '',
    limit: 25,
    offset: 0,
    organisationId: isPartnerAdmin ? null : user.organisation.id,
    organisationName: isPartnerAdmin ? null : user.organisation.name,
    operation: Object.keys(LogOperations).filter(
      v =>
        ![LogOperations.DEVICE_OFFLINE, LogOperations.DEVICE_ONLINE].includes(v)
    ),
    propertyId: ''
  }
  console.log('Filter', defaultFilter)

  const [filter, setFilter] = useState(defaultFilter)
  const [activeFilter, setActiveFilter] = useState(false)

  const fetchEvents = async () => {
    if (!filter.organisationId) {
      return
    }

    setIsLoadingEvents(true)
    setError(false)

    const selectedFilter = { ...filter }

    if (selectedFilter.operation === 'all') {
      delete selectedFilter.operation
    }

    const query = queryString.stringify(selectedFilter)

    try {
      const { data } = await axios.get(
        `/v1/organisations/${selectedFilter.organisationId}/logs/${LogTypes.KEE}?${query}`
      )

      setCanFetchMoreEvents(data.length === filter.limit)

      if (data) {
        if (filter.offset === 0) {
          setEvents(data)
        } else {
          setEvents([...events, ...data])
        }
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }

    setIsLoadingEvents(false)
  }

  const fetchMoreEvents = () => {
    const offset = filter.offset + filter.limit
    const updatedFilter = { ...filter, offset }

    setFilter(updatedFilter)
  }

  useEffect(() => {
    fetchEvents()
  }, [filter])

  const handleFilter = name => e => {
    const aFilter = { ...filter }

    let value

    if (name === 'clearFilter') {
      setFilter(defaultFilter)
      setEvents([])
      setActiveFilter(false)
    } else {
      if (name === 'organisationId') {
        value = (e && e.value && e.value.id) || ''
      } else if (name !== 'createdAtFrom' && name !== 'createdAtTo') {
        ;({ value } = e.target)
      } else {
        value = e
      }

      aFilter[name] = value

      aFilter.offset = 0

      setFilter(aFilter)
      setActiveFilter(true)
    }
  }

  const { isMobile } = useWindowDimensions()

  const columns = [
    {
      key: 'icon',
      localizationKey: 'Blank',
      sortingDisabled: true,
      style: { width: 70 },
      format: () => <Icon className={`${classes.icon}`}>history</Icon>
    },
    {
      key: 'createdAt',
      localizationKey: 'Common.Timestamp',
      longText: true,
      style: { width: 180 },
      hiddenOnSm: true,
      format: date => date && moment(date).format('YYYY/MM/DD HH:mm')
    },
    {
      key: 'userName',
      localizationKey: 'Common.User',
      // longText: true,
      format: (val, obj) => {
        if (obj.agentType === 'DELEGATED_ACCESS') {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{val}</span>
              <span style={{ color: '#888', fontSize: 14 }}>
                {global._('KeeHistory.SharedBy', [obj.delegatorName])}
              </span>
            </div>
          )
        }
        return val || '-'
      }
    },
    // {
    //   key: 'delegatorName',
    //   localizationKey: 'Common.Origin',
    //   longText: true,
    //   format: val => val || '-'
    // },
    {
      key: 'lockName',
      localizationKey: 'Common.Locks',
      longText: true,
      format: val => val || '-'
    },
    {
      key: 'deviceName',
      localizationKey: 'Common.Device',
      longText: true,
      format: val => val || '-'
    },
    {
      key: 'operation',
      localizationKey: 'Common.Operation',
      style: { width: 180 },
      format: val => global._(`KeeHistory.Operations.${val}`)
    },
    {
      key: 'propertyName',
      localizationKey: 'Common.Property',
      longText: true,
      style: { width: 180 },
      format: val => val || '-'
    },
    {
      key: 'organisation',
      localizationKey: 'Common.Organisation',
      sortingDisabled: true,
      longText: true,
      style: { width: 180 },
      format: val => val.name || '-'
    }
  ]

  if (!isPartnerAdmin && !isCustomerAdmin) {
    history.push('/')
  }

  const searchField = () => (
    <Grid item xs={12} md={6}>
      <SearchField
        style={{ width: '100%' }}
        name='name'
        label={
          isMobile
            ? global._('KeeHistory.SearchLabelMobile')
            : global._('Common.Search')
        }
        variant='filled'
        value={filter.search || ''}
        onChange={handleFilter('search')}
        helperText={
          isMobile ? undefined : global._('KeeHistory.SearchHelpText')
        }
      />
    </Grid>
  )

  const filterFields = () => (
    <>
      <Grid container spacing={2} className={classes.inputWrapper}>
        {!isMobile && searchField()}
        <Grid item md={3} className={classes.input}>
          <FormControl fullWidth>
            <DatePicker
              InputProps={{
                endAdornment:
                  filter && filter.createdAtFrom ? (
                    <Clear
                      style={{
                        cursor: 'pointer',
                        marginRight: 10,
                        fontSize: 14
                      }}
                      onClick={() => handleFilter('createdAtFrom')('')}
                    />
                  ) : (
                    <Today style={{ cursor: 'pointer' }} />
                  )
              }}
              inputVariant='filled'
              label={global._('Choose')}
              format='YYYY/MM/DD'
              name='dateFrom-select'
              helperText={global._('Filter.DateFrom')}
              value={filter.createdAtFrom || null}
              cancelLabel={null}
              okLabel={null}
              disableFuture
              maxDate={
                filter.createdAtTo
                  ? filter.createdAtTo
                  : moment().format('YYYY-MM-DD')
              }
              autoOk
              onChange={date =>
                handleFilter('createdAtFrom')(date.format('YYYY-MM-DD'))
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={3} className={classes.input}>
          <FormControl fullWidth>
            <DatePicker
              InputProps={{
                endAdornment:
                  filter && filter.createdAtTo ? (
                    <Clear
                      style={{
                        cursor: 'pointer',
                        marginRight: 10,
                        fontSize: 14
                      }}
                      onClick={() => handleFilter('createdAtTo')('')}
                    />
                  ) : (
                    <Today style={{ cursor: 'pointer' }} />
                  )
              }}
              inputVariant='filled'
              label={global._('Choose')}
              format='YYYY/MM/DD'
              name='dateTo-select'
              helperText={global._('Filter.DateTo')}
              value={filter.createdAtTo || null}
              cancelLabel={null}
              okLabel={null}
              disableFuture
              minDate={
                filter.createdAtFrom
                  ? filter.createdAtFrom
                  : moment().format('YYYY-MM-DD')
              }
              autoOk
              onChange={date =>
                handleFilter('createdAtTo')(date.format('YYYY-MM-DD'))
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.inputWrapper}>
        <Grid item xs={12} md={6}>
          <FormControl style={{ width: '100%', position: 'relative' }}>
            <Select
              labelId='operation-select-label'
              id='operation-select'
              multiple
              value={filter.operation}
              onChange={event => {
                const lastValue =
                  event.target.value[event.target.value.length - 1]
                let value
                if (event.target.value.length === 0 || lastValue === 'all') {
                  value = ['all']
                } else {
                  ;({ value } = event.target)
                  value = value.filter(v => v !== 'all')
                }
                setFilter({
                  ...filter,
                  offset: 0,
                  operation: value
                })
              }}
              input={
                <FilledInput
                  label={global._('KeeHistory.PickAnOperation')}
                  helperText={global._('Filter.ByOperation')}
                />
              }
              helperText={global._('Filter.ByOperation')}
              renderValue={selected => {
                const localizedValues = selected.map(localizeOperation)
                return localizedValues.join(', ')
              }}
              MenuProps={{
                variant: 'menu',
                PaperProps: {
                  style: {}
                }
              }}
            >
              {['all', ...Object.keys(LogOperations)].map(value => (
                <MenuItem key={`operation-${value}`} value={value}>
                  <Checkbox checked={filter.operation.includes(value)} />
                  <ListItemText primary={localizeOperation(value)} />
                </MenuItem>
              ))}
            </Select>
            <InputLabel
              id='operation-select-label'
              style={{ marginLeft: 12, marginTop: 8 }}
            >
              {global._('KeeHistory.PickAnOperation')}
            </InputLabel>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            variant='filled'
            select
            fullWidth
            label={global._('KeeHistory.PickAnOperation')}
            name='object-type-select'
            helperText={global._('Filter.ByOperation')}
            onChange={event => {
              setFilter({
                ...filter,
                offset: 0,
                operation: event.target.value
              })
            }}
            value={filter.operation}
          >
            <MenuItem value='all'>
              {global._('KeeHistory.AllOperations')}
            </MenuItem>
            {Object.keys(LogOperations).map(item => (
              <MenuItem
                key={`operation-${LogOperations[item]}`}
                value={LogOperations[item]}
              >
                {global._(`KeeHistory.Operations.${item}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <AsyncSelect
            labelKey='name'
            isClearable={false}
            style={{ width: '100%' }}
            value={
              filter.organisationId
                ? {
                    value: filter.organisationId,
                    label: filter.organisationName
                  }
                : null
            }
            placeholder={global._('Common.SelectOrganisation')}
            helpText={global._('Filter.ByOrganisation')}
            url='/v1/organisations?search={{input}}&orderBy=name&order=asc&ifHas=kee-logs'
            onChange={opt =>
              setFilter({
                ...filter,
                offset: 0,
                organisationId: opt ? opt.value.id : null,
                organisationName: opt ? opt.value.name : null
              })
            }
          />
        </Grid>
      </Grid>
    </>
  )

  let noResultsMessage
  if (!filter.organisationId) {
    noResultsMessage = global._('KeeHistory.SelectOrganisation')
  } else {
    noResultsMessage = activeFilter
      ? global._('KeeHistory.NoResultsMessage')
      : global._('KeeHistory.SearchNoResultsMessage')
  }

  return (
    <>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      {isMobile ? (
        <KeeHistoryMobile
          error={error}
          signout={signout}
          history={history}
          user={user}
          events={events}
          filter={filter}
          searchField={searchField}
          filterFields={filterFields}
          noResultsMessage={noResultsMessage}
          canFetchMoreEvents={canFetchMoreEvents}
          fetchMoreEvents={fetchMoreEvents}
          isLoadingEvents={isLoadingEvents}
        />
      ) : (
        <div className={classes.root}>
          <PageContentHeader breadcrumbItems={breadcrumbItems} />
          <Paper smallerPadding style={{ marginBottom: 10, paddingBottom: 0 }}>
            {filterFields()}
            <Grid
              container
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTop: '1px solid var(--divider-color)',
                marginLeft: '-25px',
                marginRight: '-25px',
                width: 'calc(100% + 42px)',
                padding: '10px 0',
                marginTop: 10
              }}
            >
              <Grid item>
                <Button variant='none' onClick={handleFilter('clearFilter')}>
                  {global._('Cases.ClearFilter')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <ObjectList
            sectionTitle={global._('KeeHistory.History')}
            noResultsMessage={noResultsMessage}
            columns={columns}
            objects={events}
            history={history}
            setFilter={setFilter}
            filter={filter}
            expandable={false}
            fetchMoreObjects={fetchMoreEvents}
            canFetchMoreObjects={canFetchMoreEvents}
            isLoadingObjects={isLoadingEvents}
          />
        </div>
      )}
    </>
  )
}

KeeHistory.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array,
  signout: PropTypes.func.isRequired
}

KeeHistory.defaultProps = {
  breadcrumbItems: []
}

const KeeHistoryWithStyles = injectSheet(styles)(KeeHistory)
export default KeeHistoryWithStyles
