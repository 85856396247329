import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon, IconButton } from '@material-ui/core'
import Notification from 'src/common/Notification'
import Row from './Row'
import Paper from './Paper'
import Typography from './Typography'
import Button from './Button'
import ObjectList from './ObjectList'
import SearchField from './SearchField'

const styles = {
  root: {},
  header: {
    width: '100%',
    padding: '20px 20px 0',
    minHeight: 68
  },
  icon: {
    fontSize: 18,
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  }
}

const List = ({
  title,
  buttonOnClick,
  buttonTitle,
  searchPlaceholder,
  items,
  columns,
  classes,
  filter,
  setFilter,
  endAdornmentIcon,
  endAdornmentOnClick,
  endAdornmentDisabled,
  isLoading,
  identifier,
  additionalFilterContent,
  error
}) => (
  <Paper style={{ padding: 0 }}>
    <Row justify='space-between' className={classes.header}>
      <Typography variant='subtitleBig' bold>
        {title}
      </Typography>
      {buttonTitle && buttonOnClick && (
        <Button
          loading={isLoading}
          disabled={!items.length}
          variant='outlined'
          onClick={buttonOnClick}
        >
          {buttonTitle}
        </Button>
      )}
    </Row>
    <div style={{ padding: 20 }}>
      <ObjectList
        filterFields={
          <>
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ marginBottom: 16 }}
              />
            )}
            <SearchField
              style={{ width: '100%' }}
              label={searchPlaceholder}
              variant='filled'
              value={filter.search || ''}
              onChange={e => setFilter({ search: e.target.value })}
            />
            {additionalFilterContent}
          </>
        }
        filter={filter}
        setFilter={setFilter}
        rowIdentifier={identifier}
        columns={[
          ...columns,
          {
            key: 'endAdornment',
            sortingDisabled: true,
            style: { width: 70 },
            format: (x, holder) => (
              <IconButton
                disabled={endAdornmentDisabled}
                onClick={() => endAdornmentOnClick(holder)}
              >
                <Icon
                  style={{
                    color: endAdornmentDisabled
                      ? 'var(--color-middle-grey)'
                      : 'var(--color-black)'
                  }}
                >
                  {endAdornmentIcon}
                </Icon>
              </IconButton>
            )
          }
        ]}
        expandable={false}
        objects={items}
        wrapper={false}
        renderEmpty
      />
    </div>
  </Paper>
)

List.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  buttonOnClick: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  items: PropTypes.array,
  columns: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  endAdornmentIcon: PropTypes.string,
  endAdornmentOnClick: PropTypes.func,
  endAdornmentDisabled: PropTypes.bool,
  identifier: PropTypes.func,
  isLoading: PropTypes.bool,
  additionalFilterContent: PropTypes.object,
  error: PropTypes.string
}

List.defaultProps = {
  buttonTitle: null,
  buttonOnClick: null,
  searchPlaceholder: null,
  items: [],
  filter: {},
  setFilter: null,
  error: null,
  endAdornmentIcon: null,
  endAdornmentOnClick: null,
  endAdornmentDisabled: false,
  identifier: obj => obj.id,
  isLoading: false,
  additionalFilterContent: null
}

const ListWithStyles = injectSheet(styles)(List)
export default ListWithStyles
