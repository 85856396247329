import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import TabNavigator from 'src/components/TabNavigator'
import Typography from '../../components/Typography'
import Notification from '../../common/Notification'
import { mapCaseInvoiceStatus, mapCaseIcon } from '../../utils/helpers'
import NavigationBar from '../../components/NavigationBar'
import Theme from '../../helpers/Theme'
import ObjectListMobile from '../../components/ObjectListMobile'
import ScrollView from '../../components/ScrollView'
import SearchField from '../../components/SearchField'
import { UserRoles, OrganisationTypes } from '../../utils/constants'
import MobileContainer from '../../components/MobileContainer'
import Button from '../../components/Button'

const styles = {
  root: {},
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    background: 'var(--color-material-grey)'
  },
  actionButtonContainer: {
    minWidth: '100%',
    position: 'fixed',
    bottom: 'var(--tab-bar-height)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 99,
    background: 'var(--color-white)',
    padding: '24px 24px 24px 24px',
    boxShadow: '0px -4px 10px 0px var(--color-middle-grey-2)'
  }
}

const CasesMobile = ({
  classes,
  user,
  history,
  cases,
  tabNavigationItems,
  handleFilter,
  filter,
  canFetchMoreCases,
  isLoadingCases,
  fetchMoreCases,
  setShowMobileFilter,
  noResultsMessage,
  error,
  updateUnreadStatusOnClick,
  caseListType,
  actionButtonOnClick,
  statusFormat,
  hasActivePaymentMethod
}) => {
  const isAdmin =
    user &&
    Array.isArray(user.roles) &&
    (user.roles.includes(UserRoles.PartnerAdmin) ||
      user.roles.includes(UserRoles.Support))

  const columns = [
    {
      key: 'icon',
      format: obj => (
        <Icon
          onClick={() =>
            isAdmin &&
            updateUnreadStatusOnClick &&
            updateUnreadStatusOnClick(obj)
          }
        >
          {mapCaseIcon(obj.type)}
        </Icon>
      )
    },
    {
      key: 'header',
      bold: true,
      format: title =>
        title && (
          <Typography
            variant='body'
            ellipsis
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )
    },
    {
      key: 'type',
      secondKey: 'id',
      format: (objType, id) => (
        <>
          <Typography
            variant='bodyFaded'
            style={{
              fontSize: 14,
              lineHeight: 1.5,
              display: 'inline-block',
              marginBottom: 3
            }}
          >
            {global._(`CaseTypes.${objType}`)}
          </Typography>
          <Typography
            variant='bodyFaded'
            style={{
              fontSize: 14,
              lineHeight: 1.5,
              display: 'inline-block',
              marginBottom: 3,
              marginLeft: 5
            }}
          >
            {`#${id} `}
          </Typography>
        </>
      )
    },
    {
      key: 'status',
      secondKey: 'invoiced',
      format: (status, invoiced, obj) => (
        <div style={{ display: 'flex' }}>
          {status && statusFormat(status, obj)}
          {invoiced && (
            <span
              className={`status ${mapCaseInvoiceStatus(invoiced)}`}
              style={{ marginLeft: 10 }}
            >
              {global._(`Case.InvoiceStatus.${invoiced}`)}
            </span>
          )}
        </div>
      )
    }
  ]

  let title = global._('Cases.SectionTitle')
  if (caseListType === 'orders') {
    title = global._('Cases.Orders.Keys')
  }

  return (
    <MobileContainer background='var(--color-material-grey)'>
      <NavigationBar
        title={title}
        backAction={() => history.replace('/')}
        rightActionItem={
          caseListType !== 'unread' && (
            <div
              onClick={() => setShowMobileFilter(true)}
              style={{ color: Theme.Colors.PRIMARY }}
            >
              <Icon>tune</Icon>
            </div>
          )
        }
      />
      <ScrollView
        id='scrollable-container'
        style={{
          backgroundColor:
            cases && cases.length > 0 ? 'var(--color-white)' : undefined
        }}
      >
        {caseListType !== 'orders' && (
          <>
            <div className={classes.rootMobile}>
              {error && (
                <Notification
                  type='error'
                  message={error}
                  style={{ margin: '20px 0' }}
                />
              )}
              <SearchField
                style={{ width: '100%', marginBottom: 5 }}
                name='title-search'
                label={global._('Filter.CaseSearchShort')}
                variant='filled'
                value={filter && filter.query}
                onChange={handleFilter('query')}
                className={classes.notchedOutline}
              />
            </div>
            <TabNavigator
              items={tabNavigationItems}
              style={{ background: 'var(--color-material-grey)' }}
            />
          </>
        )}
        <div style={{ flex: 1 }}>
          <ObjectListMobile
            objects={cases}
            user={user}
            history={history}
            filter={filter}
            objectPermalink={
              window.location.pathname.includes('/orders') ? 'orders' : 'cases'
            }
            noResultsMessage={noResultsMessage}
            columns={columns}
            canFetchMoreObjects={canFetchMoreCases}
            fetchMoreObjects={fetchMoreCases}
            isLoadingObjects={isLoadingCases}
          />
        </div>
      </ScrollView>
      {caseListType === 'orders' &&
      user.roles.includes(UserRoles.PartnerAdmin) &&
      user.organisation.type === OrganisationTypes.Partner ? undefined : (
        <div className={classes.actionButtonContainer}>
          <Button
            variant='primary'
            disabled={caseListType === 'orders' && !hasActivePaymentMethod}
            onClick={() => actionButtonOnClick()}
          >
            {caseListType === 'orders'
              ? global._('Cases.Orders.New')
              : global._('Cases.NewCase.Title')}
          </Button>
        </div>
      )}
    </MobileContainer>
  )
}

CasesMobile.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  cases: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  canFetchMoreCases: PropTypes.bool.isRequired,
  isLoadingCases: PropTypes.bool.isRequired,
  fetchMoreCases: PropTypes.func.isRequired,
  setShowMobileFilter: PropTypes.func.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  updateUnreadStatusOnClick: PropTypes.func.isRequired,
  tabNavigationItems: PropTypes.array.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  caseListType: PropTypes.string,
  statusFormat: PropTypes.func
}

CasesMobile.defaultProps = {
  error: undefined,
  caseListType: undefined,
  statusFormat: undefined
}

const CasesMobileWithStyles = injectSheet(styles)(CasesMobile)
export default CasesMobileWithStyles
