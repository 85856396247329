import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon } from '@material-ui/core'

const styles = {
  iconContainer: {
    width: 120,
    height: 120,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'var(--color-primary-tone-3)'
  }
}

const SuccessIcon = ({ classes, containerStyle }) => (
  <span style={containerStyle} className={classes.iconContainer}>
    <Icon style={{ fontSize: 60 }}>check</Icon>
  </span>
)

SuccessIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  containerStyle: PropTypes.object
}

SuccessIcon.defaultProps = {
  containerStyle: {}
}

export default injectSheet(styles)(SuccessIcon)
