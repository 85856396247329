import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import PageContentHeader from 'src/components/PageContentHeader'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import Typography from 'src/components/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import Paper from 'src/components/Paper'
import Button from 'src/components/Button'
import Modal from 'src/components/Modal'
import FullscreenModal from 'src/components/FullscreenModal'
import AsyncSelect from 'src/components/AsyncSelect'
import Icon from '@material-ui/core/Icon'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import { Form, Formik } from 'formik'
import { UserRoles } from 'src/utils/constants'
import * as Yup from 'yup'
import LoadingSpinner from 'src/components/LoadingSpinner'
import InformationBar from 'src/components/InformationBar'
import ObjectList from 'src/components/ObjectList'
import { getAvailableRoles } from 'src/utils/helpers'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import ControlDetailsMobile from './ControlDetailsMobile'
import Notification from '../../common/Notification'

const styles = {
  root: {},
  input: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 20
    }
  },
  buttonsContainer: {
    '& button': {
      '&:not(:last-child)': {
        marginRight: 20
      }
    }
  },
  InformationBarContainer: {
    marginTop: 20
  },
  '@media (max-width: 900px)': {
    buttonsContainer: {
      '& button': {
        marginRight: 0,
        marginBottom: 20
      }
    }
  }
}

const createAccountForm = (
  CompletionDataName,
  controlData,
  setShowCreateAccountModal,
  selectedOrganisation,
  setSelectedOrganisation,
  handleAddUser,
  isLoading,
  isMobile,
  classes,
  error
) => (
  <Formik
    initialValues={{
      name: CompletionDataName,
      email: '',
      role: '',
      message: ''
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required(global._('Common.Required')),
      email: Yup.string().email(),
      role: Yup.string().required(global._('Common.Required'))
    })}
  >
    {({
      errors,
      touched,
      handleChange,
      values,
      handleBlur,
      setFieldValue,
      resetForm
    }) => {
      const formContent = (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={global._('Name')}
                  variant='filled'
                  name='name'
                  value={values.name}
                  error={errors.name}
                  onClick={handleBlur}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={global._('Common.Email')}
                  variant='filled'
                  name='email'
                  value={values.email}
                  error={errors.email}
                  onClick={handleBlur}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <AsyncSelect
                  isSearchable
                  labelKey='name'
                  name='organisation'
                  value={selectedOrganisation}
                  isClearable
                  placeholder={global._('Common.SelectOrganisation')}
                  helpText={global._('Common.Required')}
                  onChange={e => {
                    setSelectedOrganisation(e || null)
                  }}
                  cacheOptions={false}
                  error={errors.organisationId}
                  url='/v1/organisations?search={{input}}&orderBy=name&order=asc'
                />
              </FormControl>
            </Grid>
            {selectedOrganisation && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label={global._('IdControl.Details.AddARole')}
                    variant='filled'
                    name='role'
                    select
                    helperText={global._('Common.Required')}
                    value={values.role}
                    error={errors.role}
                    onClick={handleBlur}
                    onChange={handleChange}
                  >
                    {getAvailableRoles(selectedOrganisation.value) &&
                      getAvailableRoles(selectedOrganisation.value).map(
                        role => (
                          <MenuItem key={role} value={role}>
                            {global._(`User.Roles.${role}`)}
                          </MenuItem>
                        )
                      )}
                  </TextField>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={global._('Common.Message')}
                  variant='filled'
                  name='message'
                  value={values.message}
                  error={errors.message}
                  onClick={handleBlur}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>
          <InformationBar
            classes={classes}
            message={global._('IdControl.Details.CreateUserInfo')}
            compact
            alignCenter
            noShadow
          />
          {error && (
            <Notification
              style={{ marginTop: 20, marginBottom: 20 }}
              type='error'
              message={error}
            />
          )}
        </div>
      )

      const formButtons = [
        !isMobile && (
          <Button
            variant='none'
            onClick={() => setShowCreateAccountModal(false)}
          >
            {global._('Common.Cancel')}
          </Button>
        ),
        <Button
          variant='primary'
          disabled={
            Object.keys(touched).length === 0 ||
            Object.keys(errors).length ||
            !selectedOrganisation
          }
          loading={isLoading}
          onClick={() => {
            handleAddUser(values, resetForm)
          }}
        >
          {global._('Common.Add')}
        </Button>
      ]

      return (
        <Form>
          {isMobile ? (
            <FullscreenModal
              title={global._('IdControl.Details.AddUser')}
              padding
              rightActionItem='close'
              content={formContent}
              buttons={formButtons}
              onClose={() => {
                setShowCreateAccountModal(false)
              }}
            />
          ) : (
            <Modal
              onClose={() => setShowCreateAccountModal(false)}
              title={global._('IdControl.Details.AddUser')}
              content={formContent}
              buttons={formButtons}
            />
          )}
        </Form>
      )
    }}
  </Formik>
)

const ControlDetails = props => {
  const { classes, history, match, user, impersonate, breadcrumbItems } = props
  const [error, setError] = useState(null)
  const [controlData, setControlData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(null)
  const [selectedOrganisation, setSelectedOrganisation] = useState(null)
  const [expandedRows, setExpandedRows] = useState([])

  useEffect(() => {
    if (
      controlData &&
      controlData.user &&
      controlData.user.organisationUserRoles &&
      controlData.user.organisationUserRoles.length === 1
    ) {
      setExpandedRows([
        controlData.user.organisationUserRoles[0].organisationId
      ])
    }
  }, [controlData])

  const { referenceId } = match.params
  const organisationId = user.organisation.id

  const { isMobile } = useWindowDimensions()

  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)

  const initiatorHasAccess = obj => {
    if (user.roles.includes(UserRoles.PartnerAdmin)) {
      if (
        obj &&
        (obj.organisation.id === user.organisation.id ||
          obj.organisation.partnerId === user.organisation.id)
      ) {
        return true
      }
      return false
    }
    return true
  }

  const fetchControlData = async () => {
    setError(null)
    setIsLoading(true)
    try {
      const { data } = await axios.get(
        `/v1/organisations/${organisationId}/idcontrol/${referenceId}`
      )

      setControlData(data)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        history.push('/id-control')
      }

      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchControlData()
  }, [])

  const ParsedCompletionData =
    controlData && JSON.parse(controlData.completionData)
  const CompletionDataName =
    controlData &&
    ParsedCompletionData.user.name
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')

  const handleAddUser = async (values, resetForm) => {
    const userData = {
      name: values.name || null,
      email: values.email || null,
      roles: [values.role] || null,
      message: values.message || null
    }

    setError(null)
    setIsLoading(true)

    try {
      const { data } = await axios.post(
        `/v1/organisations/${selectedOrganisation.value.id}/users`,
        userData
      )

      await axios.patch(
        `/v1/organisations/${organisationId}/idControl/${controlData.referenceId}`,
        { userId: data.id }
      )

      fetchControlData()
      resetForm({})
      setShowCreateAccountModal(false)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
    setIsLoading(false)
  }

  const personName =
    controlData &&
    ((controlData.user && controlData.user.name) || CompletionDataName || '-')

  const successIcon = noMargin => (
    <span
      style={{
        background: 'var(--color-success-green)',
        marginLeft: noMargin ? undefined : 10,
        verticalAlign: 'bottom',
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%'
      }}
    >
      <Icon style={{ fontSize: 20, color: 'var(--color-white)' }}>check</Icon>
    </span>
  )

  const identityVerifiedText = () => (
    <>
      {global._('IdControl.Details.IdentityVerified')}
      {isLoading ? (
        <Skeleton width={160} style={{ marginLeft: 10 }} />
      ) : (
        controlData &&
        ` ${moment(controlData.signedAt).format('YYYY-MM-DD HH:mm')}`
      )}
    </>
  )

  const actionButtons = (id, obj) =>
    initiatorHasAccess(obj) && (
      <div className={classes.buttonsContainer}>
        {
          // eslint-disable-next-line no-nested-ternary
          isLoading ? (
            <div style={{ display: 'flex' }}>
              <Skeleton width={120} height={50} style={{ marginLeft: 10 }} />
              <Skeleton width={120} height={50} style={{ marginLeft: 10 }} />
              <Skeleton width={120} height={50} style={{ marginLeft: 10 }} />
              <Skeleton width={120} height={50} style={{ marginLeft: 10 }} />
            </div>
          ) : (
            controlData &&
            controlData.user &&
            controlData.user.id && (
              <>
                {isPartnerAdmin && !isMobile && (
                  <>
                    {user.organisation.id !== obj.organisation.id && (
                      <Button
                        variant='outlined'
                        onClick={() =>
                          impersonate(controlData.user.id, obj.organisation.id)
                        }
                      >
                        {global._('IdControl.Details.Impersonate')}
                      </Button>
                    )}
                    <Button
                      variant='outlined'
                      onClick={() =>
                        window.open(
                          `/admin/users/${obj.organisation.id}/${controlData.user.id}`,
                          '_blank'
                        )
                      }
                    >
                      {global._('IdControl.Details.EditUser')}
                    </Button>
                  </>
                )}
                <Button
                  variant='outlined'
                  onClick={() =>
                    isMobile
                      ? history.push(`/cases?userId=${controlData.user.id}`)
                      : window.open(
                          `/cases?userId=${controlData.user.id}`,
                          '_blank'
                        )
                  }
                >
                  {global._('IdControl.Details.ShowCases')}
                </Button>
                {!isMobile && (
                  <Button
                    variant='outlined'
                    onClick={() => {
                      window.sessionStorage.setItem(
                        'selected_org',
                        JSON.stringify(obj.organisation)
                      )

                      window.open(`/keys/list?userId=${controlData.user.id}`)
                    }}
                  >
                    {global._('IdControl.Details.ShowKeys')}
                  </Button>
                )}
              </>
            )
          )
        }
      </div>
    )

  if (isMobile) {
    return (
      <ControlDetailsMobile
        history={history}
        personName={personName}
        successIcon={successIcon}
        identityVerifiedText={identityVerifiedText}
        actionButtons={actionButtons}
        controlData={controlData}
        createAccountForm={() =>
          createAccountForm(
            CompletionDataName,
            controlData,
            setShowCreateAccountModal,
            selectedOrganisation,
            setSelectedOrganisation,
            handleAddUser,
            isLoading,
            isMobile,
            classes,
            error
          )
        }
        showCreateAccountModal={showCreateAccountModal}
        setShowCreateAccountModal={setShowCreateAccountModal}
        initiatorHasAccess={initiatorHasAccess}
        isPartnerAdmin={isPartnerAdmin}
      />
    )
  }

  return (
    <>
      {showCreateAccountModal &&
        createAccountForm(
          CompletionDataName,
          controlData,
          setShowCreateAccountModal,
          selectedOrganisation,
          setSelectedOrganisation,
          handleAddUser,
          isLoading,
          isMobile,
          classes,
          error
        )}
      <PageContentHeader breadcrumbItems={breadcrumbItems} />
      {error && (
        <Notification
          style={{ marginTop: 20, marginBottom: 20 }}
          type='error'
          message={error}
        />
      )}
      {isLoading && <LoadingSpinner />}
      {!isLoading && controlData && (
        <ObjectList
          containerStyles={{ paddingTop: 20 }}
          customHeader={
            <>
              <div
                style={{
                  width: 'calc(100% + 40px)',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid var(--color-divider-light)',
                  marginLeft: '-20px',
                  marginRight: '-20px',
                  padding: '0 20px 20px 20px',
                  marginBottom: 16
                }}
              >
                <Typography
                  variant='title'
                  bold
                  style={{ textTransform: 'capitalize' }}
                >
                  {isLoading ? <Skeleton width={200} /> : personName}
                </Typography>
                <Typography
                  bold
                  style={{ display: 'flex', alignItems: 'center' }}
                  ellipsis
                >
                  {identityVerifiedText()}
                  {!isLoading && controlData && successIcon()}
                </Typography>
              </div>
              {!controlData.user && isPartnerAdmin && (
                <div>
                  <Button
                    variant='primary'
                    onClick={() => setShowCreateAccountModal(true)}
                  >
                    {global._('IdControl.Details.CreateAnAccount')}
                  </Button>
                </div>
              )}
            </>
          }
          columns={[
            {
              key: 'name',
              localizationKey: 'Common.OrganisationName',
              style: { width: 300 },
              longText: true,
              sortingDisabled: true,
              format: (one, obj) => obj.organisation.name
            },
            {
              key: 'role',
              localizationKey: 'Common.Role',
              sortingDisabled: true,
              format: role => global._(`User.Roles.${role}`)
            }
          ]}
          objects={
            controlData &&
            controlData.user &&
            controlData.user.organisationUserRoles
          }
          history={history}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          actionButtons={actionButtons}
          actionColumnSize={150}
          genExpansionKey={obj => obj.organisationId}
          replaceActionIcon={obj =>
            !initiatorHasAccess(obj) && (
              <Typography bold style={{ color: 'var(--color-error-red)' }}>
                {global._('IdControl.Details.NoAccess')}
              </Typography>
            )
          }
        />
      )}
    </>
  )
}

ControlDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  impersonate: PropTypes.func.isRequired
}

ControlDetails.defaultProps = {}

const ControlDetailsWithStyles = injectSheet(styles)(ControlDetails)
export default ControlDetailsWithStyles
