import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Breadcrumbs from './Breadcrumbs'

const styles = {}

const PageContentHeader = ({
  breadcrumbItems,
  actionButtons,
  boxedBreadcrumbs
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 24
    }}>
    <Breadcrumbs breadcrumbItems={breadcrumbItems} boxed={boxedBreadcrumbs} />
    {actionButtons && actionButtons}
  </div>
)

PageContentHeader.propTypes = {
  breadcrumbItems: PropTypes.array,
  actionButtons: PropTypes.any,
  boxedBreadcrumbs: PropTypes.bool
}

PageContentHeader.defaultProps = {
  breadcrumbItems: [],
  actionButtons: null,
  boxedBreadcrumbs: false
}

const PageContentHeaderWithStyles = injectSheet(styles)(PageContentHeader)
export default PageContentHeaderWithStyles
