import React, { useState, useRef, useEffect } from 'react'
import injectSheet from 'react-jss'
import axios from 'axios'
import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import { LOGOTYPE_CHANGE_EVENT } from '../../utils/useLogotype'

const styles = {
  root: {},
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 105,
    borderRadius: 3
  },
  uploadContent: {
    display: 'flex',
    height: 47,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'var(--color-material-grey)'
  },
  backgroundContainer: {
    background:
      'linear-gradient(to top right, var(--color-material-grey) calc(50% - 2px), #fff, var(--color-material-grey) calc(50% + 2px))',
    height: 90,
    width: 240,
    borderRadius: 3
  },
  frontContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background:
      'linear-gradient(to top left, rgba(255,255,255,0.001) calc(50% - 2px), #fff, rgba(255,255,255,0.001) calc(50% + 2px))',
    height: 90,
    width: 240,
    borderRadius: 3,
    opacity: '1.1',
    border: '1px solid var(--color-middle-grey)',
    '&:hover': {
      cursor: 'pointer',
      transition: 'border 0.5s',
      border: '1px solid var(--color-button-link)'
    }
  },
  imageContainer: {
    width: 240,
    padding: 12,
    height: 90,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid var(--color-middle-grey)'
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  btnContainer: {
    marginLeft: 5,
    height: 34,
    width: 34,
    minWidth: 34,
    alignSelf: 'flex-end'
  },
  '@media (max-width: 900px)': {
    uploadContent: {
      height: 58
    },
    backgroundContainer: {
      width: '100%'
    },
    frontContainer: {
      width: '100%'
    }
  }
}

const imageTypes = ['image/jpeg', 'image/png']
const UploadLogoType = props => {
  const { user, setError, classes } = props

  const { id } = user.organisation
  const [logoType, setLogoType] = useState()
  const fileInput = useRef(null)

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await axios.get(`v1/organisations/${id}/logotype`)
        if (data) {
          setLogoType(data)
        }
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        if (msg !== 'Error.Organisation.Logotype.NotFound') {
          setError(msg)
        }
      }
    })()
  }, [])

  const onFileSelected = async file => {
    setError(null)

    if (file.size > 1 * 1000 * 1000) {
      return setError(global._('OrganisationDetail.FileTooLarge'))
    }
    if (!imageTypes.includes(file.type)) {
      return setError(global._('OrganisationDetail.AllowedFileTypes'))
    }
    if (file.width < 320) {
      return setError(global._('OrganisationDetail.FileWidthTooSmall'))
    }

    if (file) {
      try {
        await axios.patch(`/v1/organisations/${id}`, { logotype: { ...file } })
        setLogoType({ ...file })
        window.dispatchEvent(new Event(LOGOTYPE_CHANGE_EVENT))
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    } else {
      setLogoType()
    }
  }

  const readAndNotify = file => {
    const { name, type, size } = file
    const reader = new FileReader()
    reader.onload = readEvent => {
      if (typeof onFileSelected === 'function') {
        const base64 = readEvent.target.result.split(';base64,').pop()
        const image = new Image()
        image.src = readEvent.target.result

        image.onload = () => {
          onFileSelected({
            name,
            type,
            data: base64,
            size,
            width: image.width
          })
        }
      }
    }
    reader.readAsDataURL(file)
  }

  const uploadFile = evt => {
    if (evt.target && evt.target.files && evt.target.files.length > 0) {
      const [file] = evt.target.files
      readAndNotify(file)
    }
  }

  const handleSelectFile = evt => {
    evt.preventDefault()
    /* eslint-disable-next-line */
    evt.target.value = null
    fileInput.current.click()
  }

  const handleDelete = async () => {
    try {
      await axios.patch(`/v1/organisations/${id}`, { logotype: null })
      window.dispatchEvent(new Event(LOGOTYPE_CHANGE_EVENT))
      setLogoType(null)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  return (
    <div className={classes.uploadContainer}>
      {!logoType && (
        <>
          <input
            type='file'
            style={{ display: 'none' }}
            ref={fileInput}
            onChange={e => uploadFile(e)}
          />
          <div className={classes.backgroundContainer}>
            <div
              className={classes.frontContainer}
              onClick={e => handleSelectFile(e)}
              onKeyPress={e => handleSelectFile(e)}
              role='button'
              tabIndex='0'>
              <div className={classes.uploadContent}>
                <Typography variant='italic'>
                  {global._('OrganisationDetail.UploadLogotype')}
                </Typography>
                <Typography style={{ marginTop: 5 }} variant='body' bold>
                  {global._('OrganisationDetail.ChooseFile')}
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}
      {logoType && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src={`data:${logoType.type};base64,${logoType.data}`}
              alt='attachment'
            />
          </div>
          <div className={classes.btnContainer}>
            <Button
              variant='icon'
              icon='delete'
              onClick={() => handleDelete()}
            />
          </div>
        </div>
      )}
      <FormHelperText>
        {global._('OrganisationDetail.LogotypeLegend')}
      </FormHelperText>
    </div>
  )
}

UploadLogoType.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired
}

const UploadLogoTypeWithStyles = injectSheet(styles)(UploadLogoType)
export default UploadLogoTypeWithStyles
