import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import './style.css'

class DeleteModal extends React.Component {
  toggle = () => {}

  render() {
    const {
      isOpen,
      title,
      text,
      requireInput,
      confirmText,
      cancelText,
      onCancel,
      onConfirm,
      deleteInput,
      handleDeleteInput,
      isLoadingRemove
    } = this.props

    const canConfirm = requireInput ? requireInput === deleteInput : true
    return (
      <Dialog
        open={isOpen}
        onClose={onCancel || onConfirm}
        aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          {React.isValidElement(text) ? text : <p>{text}</p>}
          {requireInput && (
            <TextField
              id='deleteInput'
              onChange={e => handleDeleteInput(e.target.value)}
            />
          )}
          <DialogActions>
            <Button color='primary' onClick={onCancel}>
              {cancelText}
            </Button>
            <Button
              color='primary'
              disabled={!canConfirm || isLoadingRemove}
              onClick={this.props.onConfirm}>
              {confirmText}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }
}

DeleteModal.defaultProps = {
  confirmText: 'Bekräfta',
  cancelText: 'Avbryt',
  requireInput: null,
  isOpen: false,
  text: null,
  deleteInput: null,
  handleDeleteInput: null,
  isLoadingRemove: false
}

DeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  isLoadingRemove: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  requireInput: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  deleteInput: PropTypes.string,
  handleDeleteInput: PropTypes.func
}

export default DeleteModal
