import React, { useEffect, useRef, useState } from 'react'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import NavigationBar from './NavigationBar'
import MobileTabBar from './MobileTabBar'

export const styles = {
  root: {
    zIndex: props => (props.higherZIndex ? 1050 : 1030),
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: props => (props.withTabBar ? 'var(--tab-bar-height)' : 0),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  content: {
    width: '100%',
    marginTop: props => (props.withNavigationBar ? 64 : 0),
    flex: 1,
    overflow: 'auto',
    padding: props => (props.padding ? 20 : ''),
    paddingBottom: props => (props.noPadding ? '' : 60)
  },
  buttons: {
    minWidth: '100%',
    zIndex: 99,
    padding: props =>
      props.buttons ? [props.buttonPadding ? props.buttonPadding : 24] : '',
    paddingTop: 0,
    display: 'flex',
    flexDirection: props => props.buttonDirection || 'column',
    alignItems: 'center',
    boxShadow: props =>
      props.boxShadow
        ? props.boxShadow
        : '0px -4px 10px 0px var(--color-middle-grey-2)',
    background: 'var(--color-white)',
    '& button': {
      marginBottom: props =>
        props.buttons && props.buttons.length > 1 ? 10 : undefined,
      '&:last-child': {
        margin: 0
      }
    }
  }
}
const _FSM_STACK_COUNTER_EVENT_NAME = '__FSM_STACK_COUNTER_EVENT__'
if (!window._fsmStackCounter) {
  window._fsmStackCounter = 0
}

const FullscreenModal = props => {
  const {
    classes,
    title,
    content,
    buttons,
    onClose,
    rightActionItem,
    backgroundColor,
    withTabBar,
    withNavigationBar
  } = props

  const instanceStackCountRef = useRef(window._fsmStackCounter + 1)
  const [currentStackCounter, setCurrentStackCounter] = useState(
    window._fsmStackCounter
  )

  useEffect(() => {
    window._fsmStackCounter += 1
    window.dispatchEvent(new Event(_FSM_STACK_COUNTER_EVENT_NAME))

    const listener = () => {
      setCurrentStackCounter(window._fsmStackCounter)
    }

    window.addEventListener(_FSM_STACK_COUNTER_EVENT_NAME, listener)

    return () => {
      window.removeEventListener(_FSM_STACK_COUNTER_EVENT_NAME, listener)
      window._fsmStackCounter -= 1
      window.dispatchEvent(new Event(_FSM_STACK_COUNTER_EVENT_NAME))
    }
  }, [])

  return (
    <div className={classes.root} style={{ backgroundColor }}>
      {withNavigationBar && (
        <NavigationBar
          hidden={currentStackCounter > instanceStackCountRef.current}
          title={title}
          backAction={
            typeof onClose === 'function' &&
            (!rightActionItem || rightActionItem !== 'close') &&
            onClose
          }
          rightActionItem={
            rightActionItem === 'close' ? (
              <div
                role='button'
                tabIndex='0'
                onClick={onClose}
                onKeyPress={onClose}
              >
                <Icon>close</Icon>
              </div>
            ) : (
              rightActionItem
            )
          }
        />
      )}
      <div className={classes.content}>{content}</div>
      <div className={classes.buttons}>{buttons}</div>
      {withTabBar && <MobileTabBar />}
    </div>
  )
}

FullscreenModal.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onClose: PropTypes.func,
  rightActionItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonDirection: PropTypes.string,
  backgroundColor: PropTypes.string,
  withTabBar: PropTypes.bool,
  withNavigationBar: PropTypes.bool
}

FullscreenModal.defaultProps = {
  rightActionItem: undefined,
  buttonDirection: 'column',
  backgroundColor: '#fff',
  buttons: null,
  onClose: null,
  withTabBar: false,
  withNavigationBar: true
}

const FullscreenModalWithStyles = injectSheet(styles)(FullscreenModal)
export default FullscreenModalWithStyles
