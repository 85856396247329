import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Theme from '../helpers/Theme'

const styles = {
  root: {
    position: 'fixed',
    zIndex: 1020,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 5,
    paddingBottom: 20,
    height: 'var(--tab-bar-height)',
    backgroundColor: Theme.Colors.LOGO_BLACK,
    color: '#fff'
  }
}

const TabBar = props => {
  const { classes, title, ...otherProps } = props

  return <div className={classes.root} {...otherProps} />
}

TabBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string
}

TabBar.defaultProps = {
  title: ''
}

const TabBarWithStyles = injectSheet(styles)(TabBar)
export default TabBarWithStyles
