import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { FormControl, MenuItem, TextField } from '@material-ui/core'
import parsePhoneNumber from 'libphonenumber-js'
import { europeanCountries } from '../utils/phoneCountryCodes'

const phoneCountryCodes = europeanCountries

const styles = {
  input: {
    '& input': {
      borderLeft: '1px solid',
      borderTopLeftRadius: '0 !important'
    },
    width: '100%'
  },
  select: {
    width: 120,
    '& .MuiSelect-select': {
      borderTopRightRadius: '0 !important'
    }
  }
}

const PhoneNumberField = ({
  classes,
  style,
  value,
  onChange,
  ...otherProps
}) => {
  const [countryCode, setCountryCode] = useState('+46')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [initialLoad, setInitialLoad] = useState(true)

  const parseNumberLocally = (value, isBlurOrFocus) => {
    let modifiedValue = value ? value.replace(/^0{2}/g, '+') : ''

    if (!modifiedValue.startsWith(countryCode)) {
      let parsed = parsePhoneNumber(modifiedValue)
      if (parsed) {
        setCountryCode(`+${parsed.countryCallingCode}`)
        modifiedValue = parsed.formatInternational().replace(/\s/g, '')
      } else if (isBlurOrFocus) {
        parsed = parsePhoneNumber(
          `${countryCode}${modifiedValue.replace(/\s/g, '')}`
        )
        if (parsed) {
          modifiedValue = parsed.formatInternational().replace(/\s/g, '')
        }
      }
    }

    return modifiedValue
  }

  const handleChange = (val, isBlurOrFocus) => {
    const modifiedValue = parseNumberLocally(val, isBlurOrFocus)

    if (typeof onChange === 'function') {
      onChange(modifiedValue)
    }
  }

  useEffect(() => {
    const modifiedValue = parseNumberLocally(value)

    setPhoneNumber(modifiedValue)

    if (initialLoad && value) {
      if (typeof onChange === 'function') {
        onChange(modifiedValue)
      }

      setInitialLoad(false)
    }
  }, [value])

  return (
    <FormControl fullWidth style={style}>
      <div style={{ display: 'flex' }}>
        <TextField
          variant='filled'
          style={{ minWidth: 150 }}
          select
          className={classes.select}
          value={countryCode}
          label={global._('Common.Country')}
          onChange={e => {
            const parsedNumber = parsePhoneNumber(phoneNumber)

            let val

            if (parsedNumber) {
              val = `${e.target.value}${parsedNumber.nationalNumber}`
            } else {
              val = `${e.target.value}${phoneNumber.replace(/^0/g, '')}`
            }

            handleChange(val)
          }}
        >
          {phoneCountryCodes.map(country => (
            <MenuItem
              key={`country-${country.code.replace('+', '')}`}
              value={country.code}
            >
              {`${country.name} (${country.code})`}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.input}
          variant='filled'
          value={phoneNumber}
          onChange={e => handleChange(e.target.value)}
          {...otherProps}
          onBlur={e => handleChange(e.target.value, true)}
          onFocus={e => handleChange(e.target.value, true)}
        />
      </div>
    </FormControl>
  )
}

PhoneNumberField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

PhoneNumberField.defaultProps = {
  value: ''
}

const PhoneNumberFieldWithStyles = injectSheet(styles)(PhoneNumberField)
export default PhoneNumberFieldWithStyles
