import { connect } from 'react-redux'
import { signout, updateOwnUser } from 'src/redux/reducers/login'
import {
  impersonate,
  resendInvitationLink
} from '../../redux/reducers/users/list'
import UserDetail from './UserDetail'

const mapStateToProps = state => ({
  user: state.login.user
})

const mapDispatchToProps = dispatch => ({
  signout: () => dispatch(signout()),
  updateOwnUser: user => dispatch(updateOwnUser(user)),
  impersonate: (userId, orgId) => {
    dispatch(impersonate(userId, orgId))
  },
  resendInvitationLink: user => {
    dispatch(resendInvitationLink(user))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail)
