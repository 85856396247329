import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core'
import axios from 'axios'
import qs from 'qs'
import IconColumn from 'src/components/IconColumn'
import ExpectedReturnDate from 'src/components/ExpectedReturnDate'
import { UserRoles } from '../../../utils/constants'
import KeysNavigation from '../../../components/Keys/KeysNavigation'
import Notification from '../../../common/Notification'
import Button from '../../../components/Button'
import ObjectList from '../../../components/ObjectList'
import Typography from '../../../components/Typography'
import SuccessMessage from '../../../components/SuccessMessage'
import Modal from '../../../components/Modal'
import KeyRingDetails from './KeyRingDetails'
import AsyncSelect from '../../../components/AsyncSelect'
import SearchField from '../../../components/SearchField'
import ItemBadge from '../../../components/ItemBadge'
import { getSelectedOrg } from '../../../utils/helpers'

const styles = {
  root: {},
  singleRadioButton: {
    borderBottom: '1px solid var(--color-divider-light)',
    marginRight: '70px !important',
    width: 160,
    justifyContent: 'space-between',
    '&:last-child': {
      marginRight: '0 !important'
    }
  }
}

const KeyRingsList = props => {
  const { classes, breadcrumbItems, history, user } = props

  const [error, setError] = useState(null)
  const [items, setItems] = useState(null)
  const [expandedRows, setExpandedRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [canFetchMoreItems, setCanFetchMoreItems] = useState(false)
  const [showKeyRingModal, setShowKeyRingModal] = useState(false)
  const [showKeyRingDeleteModal, setShowKeyRingDeleteModal] = useState(false)
  const [deleteKeyRingObj, setDeleteKeyRingObj] = useState(false)
  const [editKeyRingObj, setEditKeyRingObj] = useState(null)
  const [showChangeHolderModal, setShowChangeHolderModal] = useState(null)
  const [keyringDeletedSuccessfully, setKeyringDeletedSuccessfully] =
    useState(null)
  const [tempHolder, setTempHolder] = useState(null)
  const [receiptTemplateId, setReceiptTemplateId] = useState(null)
  const [ringHolderUpdatedSuccessfully, setRingHolderUpdatedSuccessfully] =
    useState(null)
  const [selectedHolderType, setSelectedHolderType] = useState('person')
  const [expectedReturnDate, setExpectedReturnDate] = useState()
  const [untilFurtherNotice, setUntilFurtherNotice] = useState()
  const [filter, setFilter] = useState({
    orderBy: 'id',
    order: 'desc',
    limit: 25,
    offset: 0
  })

  const selectedOrg = getSelectedOrg(user)

  const fetchKeyRings = async () => {
    if (selectedOrg) {
      setIsLoading(true)
      ;(async () => {
        try {
          const { data } = await axios.get(
            `/v1/organisations/${selectedOrg.id}/keyrings?${qs.stringify(
              filter
            )}`
          )
          setCanFetchMoreItems(data.length === filter.limit)

          if (data) {
            if (filter.offset === 0) {
              setItems(data)
            } else {
              setItems([...items, ...data])
            }
          }
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(msg)
        }
      })()
      setIsLoading(false)
    }
  }

  const updateKeyRingHolder = async () => {
    if (selectedOrg && tempHolder) {
      ;(async () => {
        setRingHolderUpdatedSuccessfully(false)

        try {
          setIsLoading(true)
          let returnDateObj = {}
          if (tempHolder.type === 'person') {
            returnDateObj = {
              expectedReturnDate,
              untilFurtherNotice
            }
          }

          await axios.patch(
            `/v1/organisations/${selectedOrg.id}/keyrings/${editKeyRingObj.id}`,
            {
              newHolder: {
                userId:
                  tempHolder.type === 'person' ? tempHolder.item.id : null,
                storageId:
                  tempHolder.type === 'repository' ? tempHolder.item.id : null,
                templateId:
                  selectedHolderType === 'person' &&
                  tempHolder.type === 'person'
                    ? receiptTemplateId
                    : null
              },
              ...returnDateObj
            }
          )

          fetchKeyRings()

          setRingHolderUpdatedSuccessfully(true)
          setUntilFurtherNotice(null)
          setExpectedReturnDate(null)
          setTempHolder(null)
          setReceiptTemplateId(null)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(msg)
        }
      })()
      setIsLoading(false)
    }
  }

  const deleteKeyring = async () => {
    try {
      setIsLoading(true)

      await axios.delete(
        `/v1/organisations/${selectedOrg.id}/keyrings/${deleteKeyRingObj.id}`
      )

      setKeyringDeletedSuccessfully(true)

      fetchKeyRings()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
    setShowKeyRingDeleteModal(false)

    setIsLoading(false)
  }

  const fetchMoreKeyRings = () => {
    const offset = filter.offset + filter.limit
    const updatedFilter = { ...filter, offset }

    setIsLoading(true)

    setFilter(updatedFilter)
  }

  useEffect(() => {
    fetchKeyRings()
  }, [filter])

  const handleFilter = name => e => {
    const aFilter = { ...filter }

    const { value } = e.target

    aFilter[name] = value

    aFilter.offset = 0

    setFilter(aFilter)
  }

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      clickable: false,
      style: { width: 70 },
      format: (objVal, obj) => obj && <IconColumn icon='group_work' />
    },
    {
      key: 'name',
      localizationKey: 'Keys.KeyRings.Name',
      sortingKey: 'name',
      clickable: true,
      longText: true
    },
    {
      key: 'id',
      localizationKey: 'Keys.KeyRings.Id',
      sortingKey: 'id',
      clickable: true,
      style: { width: 120 }
    },
    {
      key: 'keyCount',
      localizationKey: 'Keys.KeyRings.KeyCount',
      clickable: true,
      sortingDisabled: true
    },
    {
      key: 'holderName',
      localizationKey: 'Keys.KeyRings.Holder',
      clickable: true,
      sortingDisabled: true,
      format: objVal => objVal || '-'
    },
    {
      key: 'organisationName',
      localizationKey: 'Keys.KeyRings.Owner',
      sortingDisabled: true,
      clickable: true
    }
  ]

  const Filter = (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SearchField
          style={{ width: '100%' }}
          label={global._('Filter.ByNameOrId')}
          variant='filled'
          value={filter.search || ''}
          onChange={handleFilter('search')}
        />
      </Grid>
    </Grid>
  )

  const TitleContent = (
    <div style={{ marginLeft: 'auto' }}>
      <Button
        variant='primary'
        style={{ width: 130 }}
        onClick={() => {
          setEditKeyRingObj(null)
          setShowKeyRingModal(true)
        }}
      >
        {global._('Keys.Common.Add')}
      </Button>
    </div>
  )

  const actionButtons = (objId, obj) => (
    <>
      {obj && obj.keyCount > 0 && (
        <Button
          variant='outlined'
          style={{ marginLeft: 'var(--section-padding-default)' }}
          onClick={() => {
            setEditKeyRingObj(obj)
            setShowChangeHolderModal(true)
          }}
        >
          {global._('Keys.KeyRings.ChangeRingHolder')}
        </Button>
      )}
      <Button
        variant='outlined'
        disabled={!obj || !obj.keyCount}
        style={{ marginLeft: 'var(--section-padding-default)' }}
        onClick={() => history.push(`/keys/keyrings/${objId}`)}
      >
        {global._('Keys.KeyRings.ShowKeys')}
      </Button>
      {obj.userId && (
        <Button
          variant='outlined'
          style={{ marginLeft: 'var(--section-padding-default)' }}
          onClick={() =>
            history.push(`/admin/users/${obj.organisationId}/${obj.userId}`)
          }
        >
          {global._('Keys.KeyRings.ShowHolderProfile')}
        </Button>
      )}
      <Button
        variant='secondary'
        style={{ marginLeft: 'var(--section-padding-default)' }}
        onClick={() => {
          setEditKeyRingObj(obj)
          setShowKeyRingModal(true)
        }}
      >
        {global._('Keys.KeyRings.Edit')}
      </Button>
      <Button
        variant='secondary'
        disabled={obj && obj.keyCount !== 0}
        style={{ marginLeft: 'var(--section-padding-default)' }}
        onClick={() => {
          setDeleteKeyRingObj(obj)
          setShowKeyRingDeleteModal(true)
        }}
      >
        {global._('Delete')}
      </Button>
    </>
  )

  const invalidFormPerson =
    selectedHolderType === 'person' &&
    (!receiptTemplateId || !(untilFurtherNotice || expectedReturnDate))

  return (
    <div className={classes.root}>
      {showKeyRingDeleteModal && deleteKeyRingObj && (
        <Modal
          onClose={() => setShowKeyRingDeleteModal(false)}
          title={global._('Keys.KeyRings.DeleteModal.Title')}
          content={
            <div style={{ textAlign: 'left' }}>
              <Typography variant='body' block style={{ marginBottom: 15 }}>
                {global._('Keys.KeyRings.DeleteModal.Info')}
              </Typography>
              <Typography
                variant='body'
                bold
                block
                style={{ marginBottom: 15 }}
              >
                {deleteKeyRingObj.name}
              </Typography>
            </div>
          }
          buttons={[
            <Button
              variant='none'
              onClick={() => {
                setShowKeyRingDeleteModal(false)
              }}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              variant='secondary'
              loading={isLoading}
              onClick={() => {
                deleteKeyring()
              }}
            >
              {global._('Delete')}
            </Button>
          ]}
        />
      )}
      {showChangeHolderModal && editKeyRingObj && editKeyRingObj.keyCount > 0 && (
        <Modal
          onClose={() => setShowChangeHolderModal(false)}
          title={global._('Keys.KeyRings.ChangeRingHolder')}
          content={
            <div>
              <div>
                <Typography
                  variant='body'
                  bold
                  style={{ marginBottom: 20 }}
                  block
                >
                  {global._('Keys.KeyRings.NewHolder')}
                </Typography>
                <FormControl
                  component='fieldset'
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  <RadioGroup
                    name='holderType'
                    value={selectedHolderType}
                    onChange={e => {
                      setTempHolder(null)
                      setSelectedHolderType(e.target.value)
                    }}
                    style={{ flexDirection: 'row' }}
                  >
                    <FormControlLabel
                      value='person'
                      labelPlacement='start'
                      control={
                        <Radio style={{ color: 'var(--color-black)' }} />
                      }
                      label={global._('Keys.New.Holder.Person')}
                      className={classes.singleRadioButton}
                    />
                    <FormControlLabel
                      value='repository'
                      labelPlacement='start'
                      control={
                        <Radio style={{ color: 'var(--color-black)' }} />
                      }
                      label={global._('Keys.New.Holder.Repository')}
                      className={classes.singleRadioButton}
                    />
                  </RadioGroup>
                </FormControl>
                <Typography variant='body' italic>
                  {global._(
                    `Keys.New.Holder.${
                      selectedHolderType === 'person'
                        ? 'PersonInfo'
                        : 'RepositoryInfo'
                    }`
                  )}
                </Typography>
                <AsyncSelect
                  isSearchable
                  isClearable={false}
                  key={`search-${
                    selectedHolderType === 'person' ? 'users' : 'storages'
                  }`}
                  dropdownIcon='search'
                  style={{ marginTop: 20 }}
                  helpText={global._(
                    `Keys.New.Holder.${
                      selectedHolderType === 'person'
                        ? 'PersonInputHelpText'
                        : 'RepositoryInputHelpText'
                    }`
                  )}
                  label={obj =>
                    `${obj.name || global._('Common.NoName')} ${
                      selectedHolderType === 'person' ? `(${obj.email})` : ''
                    }`
                  }
                  filter={objects =>
                    objects.filter(
                      obj =>
                        !obj.roles ||
                        (obj.roles.length === 1 &&
                          !obj.roles.includes(UserRoles.CustomerAdminGuest))
                    )
                  }
                  labelKey='name'
                  name='user'
                  placeholder={global._(
                    `Keys.New.Holder.${
                      selectedHolderType === 'person'
                        ? 'PersonInputPlaceholder'
                        : 'RepositoryInputPlaceholder'
                    }`
                  )}
                  onChange={e => {
                    setTempHolder(
                      {
                        item: e.value,
                        type: selectedHolderType
                      } || null
                    )
                  }}
                  url={
                    selectedHolderType === 'person'
                      ? `/v1/organisations/${selectedOrg.id}/users?orderBy=createdAt&order=desc&search={{input}}`
                      : `/v1/organisations/${selectedOrg.id}/keystorages?orderBy=name&order=asc&search={{input}}`
                  }
                />
                {tempHolder && tempHolder.type === selectedHolderType && (
                  <ItemBadge
                    onDelete={() => {
                      setTempHolder(null)
                    }}
                    label={
                      tempHolder &&
                      `${tempHolder.item.name || global._('Common.NoName')} ${
                        selectedHolderType === 'person'
                          ? `(${
                              tempHolder.item.email || global._('Common.NoName')
                            })`
                          : ''
                      }`
                    }
                    icon={selectedHolderType === 'person' ? 'face' : 'archive'}
                    containerStyle={{ margin: '20px 0' }}
                  />
                )}
              </div>
              {selectedHolderType === 'person' && (
                <ExpectedReturnDate
                  borderBottom
                  setUntilFurtherNotice={setUntilFurtherNotice}
                  setExpectedReturnDate={setExpectedReturnDate}
                  expectedReturnDate={expectedReturnDate}
                  untilFurtherNotice={untilFurtherNotice}
                />
              )}
              {selectedHolderType === 'person' && (
                <div style={{ marginTop: 20 }}>
                  <Typography
                    variant='body'
                    bold
                    style={{ marginBottom: 20 }}
                    block
                  >
                    {global._('Keys.KeyRings.ReceiptTemplate')}
                  </Typography>
                  <AsyncSelect
                    isSearchable
                    key='search-receipt-templates'
                    dropdownIcon='search'
                    style={{ marginBottom: 20 }}
                    helpText={global._('Common.Required')}
                    label={global._('Keys.KeyRings.ReceiptTemplate')}
                    labelKey='name'
                    name='receipt-template'
                    placeholder={global._('Keys.KeyRings.ReceiptTemplate')}
                    onChange={e => {
                      setReceiptTemplateId((e && e.value && e.value.id) || '')
                    }}
                    url={`/v1/organisations/${selectedOrg.id}/keyreceipttemplates?orderBy=createdAt&order=desc&search={{input}}`}
                  />
                  <Typography variant='body' italic>
                    {global._('Keys.KeyRings.HelpText.ReceiptTemplateInfo')}
                  </Typography>
                </div>
              )}
            </div>
          }
          buttons={[
            <Button
              variant='none'
              onClick={() => {
                setTempHolder(null)
                setUntilFurtherNotice(null)
                setExpectedReturnDate(null)
                setShowChangeHolderModal(false)
              }}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              disabled={invalidFormPerson || !tempHolder}
              variant='primary'
              onClick={() => {
                updateKeyRingHolder()
                setShowChangeHolderModal(false)
              }}
            >
              {global._('Keys.KeyRings.ChangeHolder')}
            </Button>
          ]}
        />
      )}
      {showKeyRingModal && (
        <KeyRingDetails
          classes={classes}
          currentOrg={selectedOrg}
          setShowKeyRingModal={setShowKeyRingModal}
          fetchKeyRings={fetchKeyRings}
          setEditKeyRingObj={setEditKeyRingObj}
          keyRingObj={editKeyRingObj || undefined}
          isMobile={false}
          user={user}
        />
      )}
      {ringHolderUpdatedSuccessfully && (
        <SuccessMessage
          subtitle={global._(
            `Keys.KeyRings.${
              tempHolder && tempHolder.type === 'person'
                ? 'RingHolderUserUpdatedSuccessfully'
                : 'RingHolderStorageUpdatedSuccessfully'
            }`
          )}
        />
      )}
      {keyringDeletedSuccessfully && (
        <SuccessMessage
          subtitle={global._('Keys.KeyRings.KeyringDeletedSuccessfully')}
        />
      )}
      <SuccessMessage
        subtitle={global._('Keys.KeyRings.RingAddedSuccessfully')}
        localStorageProp='keyRingAddedSuccessfully'
      />
      <SuccessMessage
        subtitle={global._('Keys.KeyRings.RingEditedSuccessfully')}
        localStorageProp='keyRingEditedSuccessfully'
      />
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      <KeysNavigation
        breadcrumbItems={breadcrumbItems}
        user={user}
        error={error}
      />
      <ObjectList
        sectionTitle={global._('Keys.KeyRings.Title')}
        infoDialogTitle={global._('Keys.Common.Information')}
        infoDialogContent={
          <Typography variant='body'>
            {global._('Keys.KeyRings.InfoDialogContent')}
          </Typography>
        }
        titleContent={TitleContent}
        expandable
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        actionButtons={actionButtons}
        filterFields={Filter}
        history={history}
        columns={columns}
        objects={items}
        fetchMoreObjects={fetchMoreKeyRings}
        canFetchMoreObjects={canFetchMoreItems}
        isLoadingObjects={isLoading}
        noResultsMessage={global._(
          `Keys.KeyRings.${
            filter.search ? 'SearchNoResultsMessage' : 'NoResultsMessage'
          }`
        )}
        handleFilter={handleFilter}
        setFilter={setFilter}
        filter={filter}
      />
    </div>
  )
}

KeyRingsList.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object
}

KeyRingsList.defaultProps = {
  user: null
}

const KeyRingsListWithStyles = injectSheet(styles)(KeyRingsList)
export default KeyRingsListWithStyles
