import { Icon, IconButton, TextField } from '@material-ui/core'
import ScrollView from 'src/components/ScrollView'
import Typography from 'src/components/Typography'
import injectSheet from 'react-jss'
import React, { useState } from 'react'
import Row from 'src/components/Row'
import Column from 'src/components/Column'
import Button from 'src/components/Button'
import Alert from 'src/components/Alert'
import PhoneNumberField from '../../../components/PhoneNumberField'

const styles = {
  infoContainer: {
    backgroundColor: 'var(--color-primary-tone-4)',
    border: '1px solid #e0e0e0',
    borderRadius: 3,
    padding: 14
  }
}

const Adornment = ({ label, onClick, icon, iconStyle }) =>
  label ? (
    <Button style={{ width: 'auto' }} variant='none' onClick={onClick}>
      {label}
    </Button>
  ) : (
    <IconButton onClick={onClick}>
      <Icon style={iconStyle}>{icon}</Icon>
    </IconButton>
  )

const SelectRecipient = ({ data, onChange, classes, editMode }) => {
  const [name, setName] = useState(data.name)
  const [phone, setPhone] = useState(data.phone)
  const [isEditingName, setIsEditingName] = useState(false)
  const [isEditingPhone, setIsEditingPhone] = useState(false)

  let nameAdornment
  let phoneAdornment
  if (editMode) {
    if (isEditingName) {
      nameAdornment = (
        <Adornment
          label={global._('Common.Save')}
          onClick={async () =>
            (await onChange('name', name)) && setIsEditingName(false)
          }
        />
      )
    } else {
      nameAdornment = (
        <Adornment icon='edit' onClick={() => setIsEditingName(true)} />
      )
    }

    if (isEditingPhone) {
      phoneAdornment = (
        <Adornment
          label={global._('Common.Save')}
          onClick={async () =>
            (await onChange('phone', phone)) && setIsEditingPhone(false)
          }
        />
      )
    } else {
      phoneAdornment = (
        <Adornment icon='edit' onClick={() => setIsEditingPhone(true)} />
      )
    }
  }

  if (!editMode && data.name) {
    nameAdornment = (
      <Adornment
        icon='cancel'
        onClick={() => onChange('name', '')}
        iconStyle={{ color: 'black' }}
      />
    )
  }
  if (!editMode && data.phone) {
    phoneAdornment = (
      <Adornment
        icon='cancel'
        onClick={() => onChange('phone', '')}
        iconStyle={{ color: 'black' }}
      />
    )
  }

  return (
    <>
      <ScrollView style={{ padding: 20 }}>
        <TextField
          inputRef={input => input && isEditingName && input.focus()}
          variant='filled'
          disabled={editMode && !isEditingName}
          label={global._('Common.Name')}
          helperText={global._('Locks.Share.NameHelperText')}
          value={editMode ? name : data.name}
          onChange={e =>
            editMode
              ? setName(e.target.value)
              : onChange('name', e.target.value)
          }
          style={{ width: '100%' }}
          InputProps={{ endAdornment: nameAdornment }}
        />
        <PhoneNumberField
          inputRef={input => input && isEditingPhone && input.focus()}
          variant='filled'
          disabled={editMode && !isEditingPhone}
          label={global._('Common.Mobile')}
          value={editMode ? phone : data.phone}
          onChange={val => (editMode ? setPhone(val) : onChange('phone', val))}
          style={{ width: '100%', margin: '20px 0' }}
          InputProps={{ endAdornment: phoneAdornment }}
        />
        <Alert>{global._('Locks.Share.ShareAccessInformation')}</Alert>
      </ScrollView>
    </>
  )
}

export default injectSheet(styles)(SelectRecipient)
