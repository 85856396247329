/* eslint-disable react/prefer-stateless-function */
import './env'
import React, { Component } from 'react'
import axios from 'axios'
import * as moment from 'moment-timezone'
import 'moment/locale/sv'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  MuiThemeProvider,
  createTheme,
  StylesProvider
} from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import CssBaseline from '@material-ui/core/CssBaseline'
import UpdateOverlay from 'src/components/UpdateOverlay'
import { Provider } from 'react-redux'
import Routes from './Routes'
// import CookieConsent from './components/CookieConsent'
import { store } from './redux/store'
import Theme from './helpers/Theme'
import { setAuthenticationData } from './redux/reducers/login'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
  ThemeProvider as NewThemeProvider,
  createTheme as newCreateTheme
} from '@mui/material/styles'
import { Offline, OfflineOverlay } from './pages/Offline/OfflineOverlay'

let newTheme = newCreateTheme({
  palette: {
    primary: {
      main: Theme.Colors.PRIMARY
    },
    secondary: { main: green[500] },
    error: { main: Theme.Colors.ERROR },
    success: green
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif'
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          transition: 'all 0.3s',
          paddingTop: '8px',
          height: '70px'
        },
        focused: {
          borderColor: 'var(--color-input-border-focus) !important'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            // height: '70px'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '6px'
        },
        shrink: {
          top: '10px'
        }
      }
    }
  }
})

// import DevLogs from 'src/components/_DevLogs'

moment.tz.setDefault('Europe/Stockholm')
moment.locale('sv')

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 901,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    primary: {
      main: Theme.Colors.PRIMARY
    },
    secondary: { main: green[500] },
    error: { main: Theme.Colors.ERROR },
    success: green
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif'
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiInputLabel: {
      root: {
        marginTop: '3px'
      }
    },
    MuiInputBase: {
      root: {
        ...Theme.Typography.inputs,
        transition: 'all 0.3s',
        background: 'var(--color-input)',
        '&:hover': {
          background: 'var(--color-input-hover) !important'
        },
        '&.Mui-disabled': {
          color: 'var(--color-input-border-disabled) !important',
          '&:before': {
            transitionDuration: '0s',
            borderBottom:
              '2px solid var(--color-input-border-disabled) !important'
          }
        }
      },
      input: {
        borderBottom: '2px solid',
        borderTopLeftRadius: '4px !important',
        borderTopRightRadius: '4px !important',
        borderBottomLeftRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
        height: 'auto'
      },
      inputAdornedEnd: {
        borderRadius: 0
      },
      inputMultiline: {
        borderBottom: 'none !important',
        '&:hover': {
          background: 'none'
        },
        '&:disabled': {
          color: 'var(--color-input-border-disabled) !important'
        }
      },
      adornedEnd: {
        border: 'none',
        '&:before': {
          borderBottom: '2px solid !important'
        }
      },
      multiline: {
        '&:before': {
          borderBottom: '2px solid !important'
        }
      }
    },
    MuiSelect: {
      root: {
        ...Theme.Typography.inputs,
        paddingRight: 50,
        '&.Mui-disabled': {
          color: 'var(--color-input-border-disabled) !important'
        }
      },
      icon: {
        right: 20,
        color: 'var(--color-text)'
      }
    },
    MuiListItem: {
      root: Theme.Typography.inputs
    },
    MuiFormControlLabel: {
      label: Theme.Typography.inputs,
      labelPlacementStart: {
        margin: '0 !important'
      }
    },
    MuiRadio: {
      root: {
        '&.Mui-disabled': {
          color: 'var(--color-grey) !important'
        }
      }
    },
    MuiCheckbox: {
      root: {
        '&:after': {
          content: '""',
          display: 'inline-block',
          position: 'absolute',
          width: 22,
          height: 22,
          backgroundColor: 'var(--color-primary)',
          zIndex: 1
        },
        '&$checked': {
          position: 'relative',
          '&:after': {
            content: '""',
            display: 'inline-block',
            position: 'absolute',
            width: 19,
            height: 19,
            backgroundColor: 'black',
            zIndex: 1
          }
        },
        '& .MuiSvgIcon-root': {
          fill: 'var(--color-primary)',
          width: 33,
          height: 33,
          zIndex: 5
        }
      }
    },
    MuiSwitch: {
      switchBase: {
        color: 'var(--color-switch-base-unchecked)',
        '&$checked': {
          color: 'var(--color-switch-base-checked)'
        },
        '&$checked + $track': {
          backgroundColor: 'var(--color-switch-track-checked)'
        }
      },
      checked: {},
      track: {
        backgroundColor: 'var(--color-switch-track-unchecked)'
      }
    },
    MuiBadge: {
      badge: {
        fontSize: 12,
        fontWeight: 700,
        height: 24,
        minWidth: 24,
        borderRadius: 100,
        fontFamily: Theme.Typography.FontFamily,
        lineHeight: 1,
        padding: '0 5px'
      }
    },
    MuiFilledInput: {
      root: {
        '&.Mui-disabled': {
          color: 'var(--color-input-border-disabled) !important'
        },
        background: 'var(--color-input) !important'
      },
      underline: {
        '&:after': {
          borderBottom: '2px solid var(--color-primary)'
        }
      }
    }
  }
})

axios.defaults.baseURL = window.env.API_BASE_URL

axios.interceptors.response.use(undefined, err => {
  if (err.response && err.response.status === 412) {
    return axios.get('/v1/auth/refresh').then(response => {
      store.dispatch(setAuthenticationData(response.data))
      return axios.request(err.response.config)
    })
  }

  return Promise.reject(err)
})

axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  // eslint-disable-next-line
  config.headers.Authorization = `Bearer ${token}`
  config.headers['x-language'] = global.getLanguage()
  return config
})

class App extends Component {
  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Provider store={store}>
          <StylesProvider injectFirst>
            {/* <DevLogs /> */}
            <NewThemeProvider theme={newTheme}>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <div className='App'>
                  <Routes store={store} />
                </div>
                {/* <CookieConsent /> */}
                <UpdateOverlay />
                <OfflineOverlay />
              </MuiThemeProvider>
            </NewThemeProvider>
          </StylesProvider>
        </Provider>
      </LocalizationProvider>
    )
  }
}

export default App
