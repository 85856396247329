import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField
} from '@material-ui/core'
import Typography from 'src/components/Typography'

const styles = {}

export const AddressFields = ({
  classes,
  title,
  values,
  setFieldValue,
  handleBlur,
  isEligibleToEdit,
  handleChange,
  address,
  city,
  postalCode,
  dependencyKey,
  addressFieldName,
  cityFieldName,
  postalCodeFieldName
}) => (
  <Grid container spacing={2} style={{ marginBottom: 25 }}>
    <Grid item md={12} xs={12}>
      <Typography bold variant='title'>
        {title}
      </Typography>
    </Grid>
    {isEligibleToEdit && dependencyKey && (
      <Grid item md={12} xs={12}>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                name={dependencyKey}
                checked={!values[dependencyKey]}
                onClick={handleBlur}
                onChange={e => {
                  setFieldValue(dependencyKey, !e.target.checked)
                  setFieldValue(
                    addressFieldName,
                    values[dependencyKey] ? address : values.address
                  )
                  setFieldValue(
                    cityFieldName,
                    values[dependencyKey] ? city : values.city
                  )
                  setFieldValue(
                    postalCodeFieldName,
                    values[dependencyKey] ? postalCode : values.postalCode
                  )
                }}
                onBlur={handleBlur}
                disabled={!isEligibleToEdit}
              />
            }
            label={global._('OrganisationDetail.SameAsPostAddress')}
          />
        </FormControl>
      </Grid>
    )}
    <Grid item md={12} xs={12}>
      <FormControl fullWidth>
        <TextField
          variant='filled'
          label={global._('OrganisationDetail.Address')}
          name={addressFieldName}
          value={values[addressFieldName] || ''}
          onChange={handleChange}
          onClick={
            (dependencyKey && !values[dependencyKey]) || !isEligibleToEdit
              ? undefined
              : handleBlur
          }
          // helperText={global._('Common.NotRequired')}
          disabled={
            (dependencyKey && !values[dependencyKey]) || !isEligibleToEdit
          }
        />
      </FormControl>
    </Grid>
    <Grid item md={6} xs={12}>
      <FormControl fullWidth>
        <TextField
          variant='filled'
          label={global._('OrganisationDetail.PostalCode')}
          name={postalCodeFieldName}
          value={values[postalCodeFieldName] || ''}
          onChange={handleChange}
          onClick={
            (dependencyKey && !values[dependencyKey]) || !isEligibleToEdit
              ? undefined
              : handleBlur
          }
          // helperText={global._('Common.NotRequired')}
          disabled={
            (dependencyKey && !values[dependencyKey]) || !isEligibleToEdit
          }
        />
      </FormControl>
    </Grid>
    <Grid item md={6} xs={12}>
      <FormControl fullWidth>
        <TextField
          variant='filled'
          label={global._('OrganisationDetail.City')}
          value={values[cityFieldName] || ''}
          name={cityFieldName}
          onChange={handleChange}
          onClick={
            (dependencyKey && !values[dependencyKey]) || !isEligibleToEdit
              ? undefined
              : handleBlur
          }
          // helperText={global._('Common.NotRequired')}
          disabled={
            (dependencyKey && !values[dependencyKey]) || !isEligibleToEdit
          }
        />
      </FormControl>
    </Grid>
  </Grid>
)

AddressFields.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isEligibleToEdit: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  dependencyKey: PropTypes.string.isRequired,
  addressFieldName: PropTypes.string.isRequired,
  cityFieldName: PropTypes.string.isRequired,
  postalCodeFieldName: PropTypes.string.isRequired
}

const AddressFieldsWithStyles = injectSheet(styles)(AddressFields)
export default AddressFieldsWithStyles
