import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import Search from '@material-ui/icons/Search'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import Typography from '../../../components/Typography'
import Button from '../../../components/Button'
import FullscreenModal from '../../../components/FullscreenModal'
import ObjectListMobile from '../../../components/ObjectListMobile'
import CenteredFloatingButtonMobile from '../../../components/CenteredFloatingButtonMobile'
import ScrollView from '../../../components/ScrollView'
import Notification from '../../../common/Notification'
import SuccessMessage from '../../../components/SuccessMessage'
import MobileContainer from '../../../components/MobileContainer'

const styles = {
  root: {},
  contentHeader: {
    marginBottom: 0,
    padding: 20,
    backgroundColor: 'var(--color-white)',
    borderBottom: '1px solid var(--color-divider-light)',
    display: 'flex'
  },
  headerSectionMobile: {
    display: 'flex',
    flexDirection: 'column'
  }
}

const AccessGroupItemsMobile = props => {
  const {
    classes,
    user,
    history,
    hasChanged,
    setHasChanged,
    unselectedItems,
    setUnselectedItems,
    selectedItems,
    setSelectedItems,
    title,
    noResultsAdded,
    noResultsAvailable,
    addItemsTitle,
    isUsers,
    handleSave,
    error,
    saveSuccess,
    isLoading,
    filter,
    handleFilter,
    setGoToStep,
    setCurrentStep,
    newAccessgroup,
    handleNewAccessgroup,
    updateNewAccessgroupItems,
    handleRemove
  } = props

  const [addItemModalOpen, setAddItemModalOpen] = useState(false)
  const [itemsMarkedForAdd, setItemsMarkedForAdd] = useState([])

  const handleChange = (e, obj) => {
    setHasChanged(true)
    if (e.target.checked) {
      setItemsMarkedForAdd([...itemsMarkedForAdd, { ...obj, added: true }])
    } else {
      setItemsMarkedForAdd(itemsMarkedForAdd.filter(item => item.id !== obj.id))
    }
  }

  const handleAddItems = () => {
    const updated = [...itemsMarkedForAdd, ...selectedItems]
    setUnselectedItems(
      unselectedItems.filter(item =>
        itemsMarkedForAdd.every(itemObj => itemObj.id !== item.id)
      )
    )
    setSelectedItems(updated)
    updateNewAccessgroupItems(updated)
    setItemsMarkedForAdd([])
  }

  const availableItemCheckBox = {
    key: 'object',
    hiddenFor: true,
    format: obj =>
      obj && (
        <FormControlLabel
          style={{ marginLeft: 25, marginRight: 25, paddingTop: 12 }}
          control={
            <Checkbox
              size='small'
              color='primary'
              value={obj.added}
              onChange={e => handleChange(e, obj)}
            />
          }
        />
      )
  }

  const addedItemIcon = {
    key: 'icon',
    format: () => (isUsers ? 'person' : 'lock')
  }

  const itemColumns = [
    {
      key: 'name',
      clickable: false,
      format: name => (
        <Typography variant='body' bold>
          {name}
        </Typography>
      )
    },
    {
      key: 'email',
      clickable: false,
      format: name => (
        <Typography variant='small' faded>
          {name}
        </Typography>
      )
    }
  ]

  const removeButton = {
    key: 'rowactionbutton',
    format: obj =>
      obj && (
        <Button
          style={{ marginLeft: 'auto' }}
          variant='icon'
          icon='delete'
          onClick={() => {
            handleRemove(obj)
            setHasChanged(true)
          }}
          key={`item-remove-button-${obj.id}`}
        />
      )
  }

  return (
    <>
      {addItemModalOpen ? (
        <FullscreenModal
          rightActionItem={
            <div
              onClick={() => {
                handleFilter({ search: '' })
                setAddItemModalOpen(false)
              }}>
              <Icon>close</Icon>
            </div>
          }
          title={addItemsTitle}
          content={
            <ScrollView id='scrollable-container'>
              <div style={{ padding: 'var(--section-padding-default)' }}>
                <TextField
                  style={{ width: '100%' }}
                  variant='filled'
                  value={filter.search || ''}
                  onChange={e => handleFilter({ search: e.target.value })}
                  label={global._('Search')}
                  InputProps={{
                    endAdornment: <Search />
                  }}
                />
              </div>
              <ObjectListMobile
                objects={unselectedItems}
                user={user}
                history={history}
                noResultsMessage={noResultsAvailable}
                columns={[availableItemCheckBox, ...itemColumns]}
                paddingTop={0}
                rowsAreClickable={false}
                noIcon
                isLoadingObjects={isLoading}
                singleColumn
              />
            </ScrollView>
          }
          buttons={[
            <Button
              variant='primary'
              onClick={() => {
                handleAddItems()
                handleFilter({ search: '' })
                setAddItemModalOpen(false)
              }}>
              {global._('Common.Add')}
            </Button>
          ]}
        />
      ) : (
        <FullscreenModal
          onClose={() => {
            if (newAccessgroup) {
              if (!isUsers) setGoToStep(true)
              setCurrentStep(false)
            } else {
              history.goBack()
            }
          }}
          title={title}
          content={
            <MobileContainer>
              <ScrollView
                id='scrollable-container'
                style={{ backgroundColor: 'var(--color-white)' }}>
                <div className={classes.contentHeader}>
                  <div className={classes.headerSectionMobile}>
                    <Typography
                      variant='body2'
                      style={{ color: 'var(--color-middle-grey)' }}>
                      {newAccessgroup
                        ? global._(
                            isUsers
                              ? 'AccessGroups.StepTwoMobile'
                              : 'AccessGroups.StepThreeMobile'
                          )
                        : global._('AccessGroups.Edit')}
                    </Typography>
                    <Typography variant='title' bold>
                      {isUsers
                        ? global._('AccessGroups.Users')
                        : global._('AccessGroups.Locks')}
                    </Typography>
                  </div>
                  {!newAccessgroup && (
                    <Button
                      style={{ maxWidth: 140, marginLeft: 'auto' }}
                      variant='primary'
                      disabled={!hasChanged}
                      onClick={() => handleSave()}>
                      {global._('Common.Save')}
                    </Button>
                  )}
                  {newAccessgroup && (
                    <Button
                      style={{ maxWidth: 150, marginLeft: 'auto' }}
                      variant='primary'
                      disabled={!hasChanged}
                      onClick={() => handleNewAccessgroup()}>
                      {global._(isUsers ? 'Next' : 'AccessGroups.Create')}
                    </Button>
                  )}
                </div>
                {error && (
                  <Notification
                    type='error'
                    message={error}
                    style={{ margin: '20px 0' }}
                  />
                )}
                {saveSuccess && (
                  <SuccessMessage
                    subtitle={global._('AccessGroups.SaveSuccess')}
                  />
                )}
                <ObjectListMobile
                  objects={selectedItems}
                  user={user}
                  history={history}
                  noResultsMessage={noResultsAdded}
                  columns={[addedItemIcon, ...itemColumns, removeButton]}
                  paddingTop={0}
                  rowsAreClickable={false}
                  isLoadingObjects={isLoading}
                  singleColumn
                />
                <CenteredFloatingButtonMobile
                  onClick={() => setAddItemModalOpen(true)}
                />
              </ScrollView>
            </MobileContainer>
          }
        />
      )}
    </>
  )
}

AccessGroupItemsMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hasChanged: PropTypes.bool.isRequired,
  setHasChanged: PropTypes.func.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  noResultsAdded: PropTypes.string.isRequired,
  noResultsAvailable: PropTypes.string.isRequired,
  addItemsTitle: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  unselectedItems: PropTypes.array.isRequired,
  setUnselectedItems: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  setGoToStep: PropTypes.func,
  setCurrentStep: PropTypes.func,
  handleNewAccessgroup: PropTypes.func,
  updateNewAccessgroupItems: PropTypes.func,
  title: PropTypes.string.isRequired,
  error: PropTypes.string,
  filter: PropTypes.object.isRequired,
  newAccessgroup: PropTypes.object,
  isUsers: PropTypes.bool.isRequired,
  saveSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired
}

AccessGroupItemsMobile.defaultProps = {
  setGoToStep: null,
  error: null,
  setCurrentStep: null,
  newAccessgroup: null,
  handleNewAccessgroup: null,
  updateNewAccessgroupItems: null
}

const AccessGroupItemsMobileWithStyles = injectSheet(styles)(
  AccessGroupItemsMobile
)
export default AccessGroupItemsMobileWithStyles
