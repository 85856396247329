import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Grid, Tooltip } from '@material-ui/core'
import axios from 'axios'
import IconColumn from 'src/components/IconColumn'
import InfiniteScroll from 'src/components/InfiniteScroll'
import List from 'src/components/List'
import PageContentHeader from 'src/components/PageContentHeader'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import Notification from 'src/common/Notification'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import SuccessMessage from 'src/components/SuccessMessage'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { normalize } from 'src/utils/helpers'
import AccessGroupItemsMobile from './AccessGroupItemsMobile'

const styles = {
  root: {},
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'calc(100% + 40px)',
    padding: '20px 20px',
    marginLeft: '-20px',
    marginRight: '-20px',
    '&:first-child': {
      paddingTop: 10,
      paddingBottom: 30
    }
  }
}

const LIMIT = 25

const AccessGroupItems = props => {
  const {
    classes,
    user,
    history,
    setGoToStep,
    setCurrentStep,
    newAccessgroup,
    setNewAccessgroup,
    newType,
    newCrumbs,
    setCreateSuccess,
    match
  } = props

  const organisationId =
    (newAccessgroup && newAccessgroup.organisation.id) ||
    match.params.organisationId
  let accessgroupId = null
  const type = newType || match.params.type

  if (match && match.params) {
    // eslint-disable-next-line prefer-destructuring
    accessgroupId = match.params.accessgroupId
  }

  const { isMobile } = useWindowDimensions()
  const isUsers = type === 'users'

  /* Unselected items */
  const [unselectedItems, setUnselectedItems] = useState([])
  const [filteredUnselectedItems, setFilteredUnselectedItems] = useState([])
  const [unselectedFilter, setUnselectedFilter] = useState({})

  /* Selected items */
  const [selectedItems, setSelectedItems] = useState([])
  const [filteredSelectedItems, setFilteredSelectedItems] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({})

  const [accessgroup, setAccessgroup] = useState()
  const [error, setError] = useState(null)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const [offset, setOffset] = useState(0)

  const onFilterChange = async (
    filter,
    originalList,
    setFilter,
    setList,
    selected
  ) => {
    let list

    if (filter.search) {
      const search = normalize(filter.search.toLowerCase())
      list = originalList.filter(item => item.search.includes(search))
    } else {
      list = [...originalList]
    }

    const { orderBy } = filter
    if (orderBy) {
      list.sort((a, b) => {
        if (
          orderBy === 'name' ||
          orderBy === 'email' ||
          orderBy === 'apartmentNumber'
        ) {
          if (typeof a[orderBy] !== 'string') {
            a[orderBy] = ''
          }
          if (typeof b[orderBy] !== 'string') {
            b[orderBy] = ''
          }

          if (filter.order === 'asc') {
            return a[orderBy].localeCompare(b[orderBy])
          }
          return b[orderBy].localeCompare(a[orderBy])
        }
        return 0
      })
    }

    const compareFilter = selected ? selectedFilter : unselectedFilter
    if (
      filter.orderBy !== compareFilter.orderBy ||
      filter.order !== compareFilter.order ||
      filter.search !== compareFilter.search
    ) {
      setOffset(0)
    }

    setList(list)
    setFilter(filter)
  }

  const clearFilters = () => {
    setUnselectedFilter({})
    setSelectedFilter({})
  }

  const onUnselectedFilterChange = filter =>
    onFilterChange(
      filter,
      unselectedItems,
      setUnselectedFilter,
      setFilteredUnselectedItems,
      false
    )
  const onSelectedFilterChange = filter =>
    onFilterChange(
      filter,
      selectedItems,
      setSelectedFilter,
      setFilteredSelectedItems,
      true
    )

  const resolveSearchableAttributes = inputArray => {
    if (inputArray.length) {
      inputArray.forEach(item => {
        item.search = ''
        if (item.name) {
          item.search += item.name.toLowerCase()
        }
        if (item.email) {
          item.search += item.email.toLowerCase()
        }

        const apartmentNumber =
          item.apartmentNumber ||
          (item.apartments &&
            item.apartments[0] &&
            item.apartments[0].number) ||
          ''
        const apartmentAddress =
          item.apartmentAddress ||
          (item.apartments &&
            item.apartments[0] &&
            item.apartments[0].address) ||
          ''

        if (apartmentNumber) {
          item.search += apartmentNumber
        }

        if (apartmentAddress) {
          item.search += apartmentAddress.toLowerCase()
        }

        item.search = normalize(item.search)
      })
    }

    return inputArray
  }

  useEffect(() => {
    ;(async () => {
      if (!newAccessgroup) {
        try {
          setIsLoading(true)
          const { data: itemsData } = await axios.get(
            `/v1/organisations/${organisationId}/${type}`
          )
          const { data: accessgroupData } = await axios.get(
            `/v1/organisations/${organisationId}/accessgroups/${accessgroupId}`
          )
          setAccessgroup(accessgroupData)

          resolveSearchableAttributes(itemsData)
          resolveSearchableAttributes(
            isUsers ? accessgroupData.users : accessgroupData.locks
          )

          setUnselectedItems(
            itemsData.filter(item =>
              isUsers
                ? accessgroupData.users.every(
                    accessgroupUser => accessgroupUser.id !== item.id
                  )
                : accessgroupData.locks.every(
                    accessgroupLock => accessgroupLock.id !== item.id
                  )
            )
          )
          setSelectedItems(
            isUsers ? accessgroupData.users : accessgroupData.locks
          )
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
      }
    })()
    setIsLoading(false)
  }, [])

  useEffect(() => {
    ;(async () => {
      if (newAccessgroup) {
        setIsLoading(true)
        const { data: itemsData } = await axios.get(
          `/v1/organisations/${newAccessgroup.organisation.id}/${type && type}`
        )

        resolveSearchableAttributes(itemsData)

        setUnselectedItems(
          itemsData.filter(item =>
            isUsers
              ? newAccessgroup.users.every(
                  accessgroupUser => accessgroupUser.id !== item.id
                )
              : newAccessgroup.locks.every(
                  accessgroupLock => accessgroupLock.id !== item.id
                )
          )
        )

        setAccessgroup(newAccessgroup)
        setSelectedItems(isUsers ? newAccessgroup.users : newAccessgroup.locks)
        setHasChanged(true)
      }
    })()
    setIsLoading(false)
  }, [type])

  /* update filtered lists when adding or removing keys */
  useEffect(() => {
    onUnselectedFilterChange(unselectedFilter)
    onSelectedFilterChange(selectedFilter)
  }, [selectedItems, unselectedItems])

  const handleSave = async () => {
    setIsLoading(true)
    try {
      setError(null)
      setSaveSuccess(false)

      await axios.put(
        `/v1/organisations/${organisationId}/accessgroups/${accessgroupId}/${type}`,
        selectedItems
      )

      setHasChanged(false)
      setSaveSuccess(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  const handleNewAccessgroup = async () => {
    if (isUsers) {
      setCurrentStep(false)
      setGoToStep(true)
    } else {
      setIsLoading(true)
      try {
        const { data: group } = await axios.post(
          `/v1/organisations/${newAccessgroup.organisation.id}/accessgroups`,
          {
            name: newAccessgroup.name,
            shareLimit: newAccessgroup.shareLimit,
            users: newAccessgroup.users,
            locks: newAccessgroup.locks
          }
        )
        setNewAccessgroup({
          ...newAccessgroup,
          id: group.id,
          organisationId: group.organisationId
        })
        setIsLoading(false)
        setCreateSuccess(true)
        setCurrentStep(false)
      } catch (err) {
        const msg = err.response ? err.response.data : err.message
        setError(global._(msg))
        setIsLoading(false)
      }
    }
  }

  const updateNewAccessgroupItems = itemsArr => {
    if (setNewAccessgroup) {
      if (isUsers) {
        setNewAccessgroup({ ...newAccessgroup, users: [...itemsArr] })
      } else {
        setNewAccessgroup({ ...newAccessgroup, locks: [...itemsArr] })
      }
    }
  }

  const handleAdd = item => {
    setHasChanged(true)
    const updated = [item, ...selectedItems]
    setUnselectedItems(unselectedItems.filter(itemObj => itemObj !== item))
    setSelectedItems(updated)
    updateNewAccessgroupItems(updated)
  }

  const handleRemove = item => {
    setHasChanged(true)
    const updated = selectedItems.filter(itemObj => itemObj !== item)
    setUnselectedItems([item, ...unselectedItems])
    setSelectedItems(updated)
    updateNewAccessgroupItems(updated)
  }

  const crumbsBase = [
    { name: 'Startsida', path: '/' },
    { name: 'Admin', path: '/admin' },
    {
      name: 'Behörighetsgrupper',
      path: `/admin/accessgroups/${organisationId}`
    },
    {
      name: `${accessgroup && accessgroup.name}`,
      path: `/admin/accessgroups/${organisationId}/${accessgroupId}/${type}`
    }
  ]

  const itemColumns = [
    {
      key: 'icon',
      sortingDisabled: true,
      style: { width: 70 },
      format: () => <IconColumn icon={isUsers ? 'person' : 'lock'} />
    },
    {
      key: 'name',
      sortingKey: 'name',
      longText: true,
      localizationKey: 'AccessGroups.Name',
      clickable: false,
      style: { width: isUsers ? '35%' : 300 }
    },
    {
      key: 'apartmentNumber',
      sortingKey: 'apartmentNumber',
      localizationKey: 'Common.Apartment',
      clickable: false,
      style: { width: '15%' },
      hiddenFor: type === 'locks' || window.innerWidth < 1300,
      format: (o, obj) => {
        let colContent = <span>-</span>

        const apartmentAddress =
          obj.apartmentAddress ||
          (obj.apartments && obj.apartments[0] && obj.apartments[0].address) ||
          ''
        const apartmentNumber =
          obj.apartmentNumber ||
          (obj.apartments && obj.apartments[0] && obj.apartments[0].number) ||
          ''

        if (apartmentNumber) {
          colContent = (
            <Tooltip title={apartmentAddress} placement='right'>
              <span>{apartmentNumber}</span>
            </Tooltip>
          )
        }

        return colContent
      }
    },
    {
      key: 'email',
      sortingKey: 'email',
      longText: true,
      localizationKey: 'AccessGroups.Email',
      clickable: false,
      hiddenFor: type === 'locks',
      style: { width: '30%' }
    }
  ]

  const offsetLimit = offset + LIMIT

  if (isLoading) return <LoadingSpinner />

  if (isMobile) {
    return (
      <AccessGroupItemsMobile
        handleSave={handleSave}
        classes={classes}
        user={user}
        history={history}
        hasChanged={hasChanged}
        setHasChanged={setHasChanged}
        unselectedItems={filteredUnselectedItems}
        selectedItems={filteredSelectedItems}
        setUnselectedItems={setUnselectedItems}
        setSelectedItems={setSelectedItems}
        title={`${global._(`AccessGroups.${type && type}`)} ${global._(
          'AccessGroups.With'
        )} ${
          accessgroup &&
          accessgroup.organisation &&
          accessgroup.organisation.name
        }`}
        addItemsTitle={
          isUsers
            ? global._('AccessGroups.AddUsers')
            : global._('AccessGroups.AddLocks')
        }
        noResultsAvailable={
          isUsers
            ? global._('AccessGroups.NoAvailableUsers')
            : global._('AccessGroups.NoAvailableLocks')
        }
        noResultsAdded={
          isUsers
            ? global._('AccessGroups.NoAddedUsers')
            : global._('AccessGroups.NoAddedLocks')
        }
        isUsers={isUsers}
        addItemsText={
          isUsers
            ? global._('AccessGroups.AddUsers')
            : global._('AccessGroups.AddLocks')
        }
        error={error}
        saveSuccess={saveSuccess}
        isLoading={isLoading}
        handleFilter={onUnselectedFilterChange}
        filter={unselectedFilter}
        setGoToStep={setGoToStep}
        setCurrentStep={setCurrentStep}
        newAccessgroup={newAccessgroup || undefined}
        setNewAccessgroup={setNewAccessgroup}
        setCreateSuccess={setCreateSuccess}
        handleNewAccessgroup={handleNewAccessgroup}
        updateNewAccessgroupItems={updateNewAccessgroupItems}
        handleRemove={handleRemove}
      />
    )
  }

  return (
    <>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      {saveSuccess && (
        <SuccessMessage subtitle={global._('AccessGroups.SaveSuccess')} />
      )}
      <PageContentHeader breadcrumbItems={newCrumbs || crumbsBase} />
      <div className={classes.contentHeader}>
        <Typography variant='title' bold>
          {`${global._(`AccessGroups.${type && type}`)} ${global._(
            'AccessGroups.With'
          )} ${
            accessgroup &&
            accessgroup.organisation &&
            accessgroup.organisation.name
          }`}
        </Typography>
        <Button
          variant='outlined'
          onClick={() => history.push(`/admin/accessgroups/${organisationId}`)}
          style={{ marginLeft: 'auto', marginRight: 20 }}
          minWidth
        >
          {global._('Common.Cancel')}
        </Button>
        {newAccessgroup && (
          <>
            <Button
              variant='outlined'
              onClick={() => {
                setCurrentStep(false)
                if (!isUsers) {
                  setGoToStep(true)
                }
                clearFilters()
              }}
              style={{ marginRight: 20 }}
            >
              {global._('AccessGroups.Previous')}
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                clearFilters()
                handleNewAccessgroup()
              }}
              minWidth
            >
              {global._(isUsers ? 'Next' : 'AccessGroups.Create')}
            </Button>
          </>
        )}
        {!newAccessgroup && (
          <Button
            minWidth
            variant='primary'
            disabled={!hasChanged}
            loading={isLoading}
            onClick={() => handleSave()}
          >
            {global._('Common.Save')}
          </Button>
        )}
      </div>
      <InfiniteScroll
        hasMore={
          filteredUnselectedItems.length > offsetLimit ||
          filteredSelectedItems.length > offsetLimit
        }
        fetchMore={() => setOffset(offsetLimit)}
      >
        <Grid container spacing={3} style={{ marginTop: 12 }}>
          <Grid item xs={6}>
            <List
              columns={itemColumns}
              items={filteredUnselectedItems.slice(0, offsetLimit)}
              isLoading={isLoading}
              classes={classes}
              filter={unselectedFilter}
              setFilter={onUnselectedFilterChange}
              title={
                isUsers
                  ? global._('AccessGroups.AddUsers')
                  : global._('AccessGroups.AddLocks')
              }
              endAdornmentOnClick={item => handleAdd(item)}
              searchPlaceholder={global._('Search')}
              endAdornmentIcon='add_circle'
            />
          </Grid>
          <Grid item xs={6}>
            <List
              columns={itemColumns}
              items={filteredSelectedItems.slice(0, offsetLimit)}
              classes={classes}
              filter={selectedFilter}
              setFilter={onSelectedFilterChange}
              title={global._('AccessGroups.Added')}
              buttonTitle={global._('AccessGroups.ClearItems')}
              endAdornmentOnClick={item => handleRemove(item)}
              buttonOnClick={() => {
                const updated = selectedItems.filter(
                  item => !filteredSelectedItems.includes(item)
                )
                setSelectedItems(updated)
                setUnselectedItems([
                  ...filteredSelectedItems,
                  ...unselectedItems
                ])
                setSelectedFilter({ ...selectedFilter, search: '' })
              }}
              searchPlaceholder={global._('Search')}
              endAdornmentIcon='remove_circle'
            />
          </Grid>
        </Grid>
      </InfiniteScroll>
    </>
  )
}

AccessGroupItems.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object,
  newAccessgroup: PropTypes.object,
  setGoToStep: PropTypes.func,
  setCurrentStep: PropTypes.func,
  setNewAccessgroup: PropTypes.func,
  setCreateSuccess: PropTypes.func,
  newType: PropTypes.string,
  newCrumbs: PropTypes.array
}

AccessGroupItems.defaultProps = {
  setGoToStep: null,
  match: null,
  setCurrentStep: null,
  newAccessgroup: null,
  setNewAccessgroup: null,
  newType: null,
  newCrumbs: null,
  setCreateSuccess: null
}

const AccessGroupItemsWithStyles = injectSheet(styles)(AccessGroupItems)
export default AccessGroupItemsWithStyles
