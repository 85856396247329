import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { Grid } from '@material-ui/core'
import IconColumn from 'src/components/IconColumn'
import {
  KeyInventoryApprovalStatus,
  KeyInventoryStatus
} from 'src/utils/constants'
import PageContentHeader from 'src/components/PageContentHeader'
import Button from 'src/components/Button'
import { history } from 'src/redux/store'
import InformationBar from 'src/components/InformationBar'
import SectionTitle from 'src/components/SectionTitle'
import { normalize } from 'src/utils/helpers'
import Notification from '../../../common/Notification'
import List from '../../../components/List'
import InfiniteScroll from '../../../components/InfiniteScroll'
import {
  MANUAL_VERIFICATION_DATA_STORAGE_KEY,
  MANUAL_VERIFICATION_SUCCESS_STORAGE_KEY
} from './KeyInventoryDetailView'

const styles = {
  root: {}
}

const LIMIT = 25
const FilterTypes = {
  Selected: 1,
  Unselected: 2
}

const KeyInventoryManualVerificationView = ({ classes, breadcrumbItems }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [inventory, setInventory] = useState({})

  /* Unselected Keys (left list) */
  const [unselected, setUnselected] = useState([])
  const [filteredUnselected, setFilteredUnselected] = useState([])
  const [unselectedFilter, setUnselectedFilter] = useState({})

  /* Select Keys (right list) */
  const [selected, setSelected] = useState([])
  const [filteredSelected, setFilteredSelected] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({})

  const [offset, setOffset] = useState(0)
  const [error, setError] = useState(null)

  const onFilterChange = (
    filter,
    originalList,
    setFilter,
    setList,
    filterType
  ) => {
    let list
    if (filter.search) {
      const search = normalize(filter.search.toLowerCase())
      list = originalList.filter(item => item.search.includes(search))
    } else {
      list = [...originalList]
    }

    const { orderBy } = filter
    if (orderBy) {
      list.sort((a, b) => {
        if (filter.order === 'asc') {
          return a[orderBy].localeCompare(b[orderBy])
        }
        return b[orderBy].localeCompare(a[orderBy])
      })
    }

    /* only change offset if filter actually changed */
    const compareFilter =
      filterType === FilterTypes.Selected ? selectedFilter : unselectedFilter
    if (
      filter.orderBy !== compareFilter.orderBy ||
      filter.order !== compareFilter.order ||
      filter.search !== compareFilter.search
    ) {
      setOffset(0)
    }
    setList(list)
    setFilter(filter)
  }

  const onUnselectedFilterChange = filter =>
    onFilterChange(
      filter,
      unselected,
      setUnselectedFilter,
      setFilteredUnselected,
      FilterTypes.Unselected
    )
  const onSelectedFilterChange = filter =>
    onFilterChange(
      filter,
      selected,
      setSelectedFilter,
      setFilteredSelected,
      FilterTypes.Selected
    )

  /* update filtered lists when adding or removing Keys */
  useEffect(() => {
    onUnselectedFilterChange(unselectedFilter)
    onSelectedFilterChange(selectedFilter)
  }, [selected, unselected])

  useEffect(() => {
    try {
      const data = JSON.parse(
        localStorage.getItem(MANUAL_VERIFICATION_DATA_STORAGE_KEY)
      )
      const pendingKeys = data.keys.filter(
        key => key.approvalStatus === KeyInventoryApprovalStatus.PENDING
      )
      setUnselected(pendingKeys)
      setInventory(data.inventory)
    } catch (e) {
      setError(global._(e.message))
    }
  }, [])

  const handleGoBack = () => {
    localStorage.removeItem(MANUAL_VERIFICATION_DATA_STORAGE_KEY)
    history.push(`/keys/inventory/${inventory.id}`)
  }

  const handleManualVerification = async () => {
    setIsLoading(true)
    try {
      await axios.post(
        `/v1/organisations/${inventory.organisationId}/keyinventories/${inventory.id}/approvals/manual`,
        selected
      )

      localStorage.setItem(MANUAL_VERIFICATION_SUCCESS_STORAGE_KEY, true)
      handleGoBack()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
      setIsLoading(false)
    }
  }

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      style: { width: 50 },
      format: () => <IconColumn icon='vpn_key' />
    },
    {
      key: 'name',
      localizationKey: 'Keys.Inventory.Column.Name',
      sortingKey: 'name',
      style: { width: 150 },
      clickable: true
    },
    {
      key: 'marking',
      localizationKey: 'Keys.List.Marking',
      sortingKey: 'marking',
      style: { width: 150 },
      clickable: true
    },
    {
      key: 'holderName',
      localizationKey: 'Keys.List.Holder',
      sortingKey: 'holderName',
      style: { width: 150 },
      clickable: true
    }
  ]

  const offsetLimit = offset + LIMIT
  const customCrumbs = [
    {
      name:
        inventory && inventory.status === KeyInventoryStatus.ACTIVE
          ? global._('Keys.Inventory.Detail.Active')
          : global._('Keys.Inventory.Detail.Ended'),
      path: `/keys/inventory/${inventory.id}`
    },
    {
      name: global._('Keys.Inventory.Detail.ManualVerification.Title')
    }
  ]

  return (
    <div className={classes.root}>
      <PageContentHeader
        breadcrumbItems={[...breadcrumbItems, ...customCrumbs]}
      />
      <SectionTitle
        title={global._('Keys.Inventory.Detail.ManualVerification.Title')}
        titleContent={
          <>
            <Button
              disabled={isLoading}
              variant='outlined'
              style={{ marginLeft: 'auto', marginRight: 20 }}
              onClick={handleGoBack}
            >
              {global._('Common.Cancel')}
            </Button>
            <Button
              disabled={!selected.length}
              variant='primary'
              onClick={handleManualVerification}
            >
              {global._('Common.Verify')}
            </Button>
          </>
        }
      />

      <InformationBar
        noShadow
        message={global._(
          'Keys.Inventory.Detail.ManualVerification.InformationMessageContent',
          true
        )}
      />
      {error && <Notification type='error' message={error} />}
      <InfiniteScroll
        hasMore={
          filteredSelected.length > offsetLimit ||
          filteredUnselected.length > offsetLimit
        }
        fetchMore={() => setOffset(offsetLimit)}
      >
        <Grid container spacing={3} style={{ marginTop: 12 }}>
          <Grid item xs={6}>
            <List
              items={filteredUnselected.slice(0, offsetLimit)}
              columns={columns}
              isLoading={isLoading}
              filter={unselectedFilter}
              setFilter={onUnselectedFilterChange}
              title={global._('Keys.Loan.KeySelect.LeftListTitle')}
              buttonTitle={global._('Keys.Loan.KeySelect.LeftListButtonTitle')}
              buttonOnClick={() => {
                setSelected([...filteredUnselected, ...selected])
                setUnselected(
                  unselected.filter(item => !filteredUnselected.includes(item))
                )
              }}
              searchPlaceholder={global._(
                'Keys.Inventory.Detail.SearchPlaceholder'
              )}
              endAdornmentIcon='add_circle'
              endAdornmentOnClick={item => {
                const newUnselected = unselected.filter(item2 => item2 !== item)
                const newSelected = [item, ...selected]
                setUnselected(newUnselected)
                setSelected(newSelected)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <List
              items={filteredSelected.slice(0, offsetLimit)}
              columns={columns}
              filter={selectedFilter}
              setFilter={onSelectedFilterChange}
              title={global._('Keys.Loan.KeySelect.RightListTitle')}
              buttonTitle={global._('Keys.Loan.KeySelect.RightListButtonTitle')}
              buttonOnClick={() => {
                const newUnselected = [...filteredSelected, ...unselected]
                const newSelected = selected.filter(
                  item => !filteredSelected.includes(item)
                )
                setSelected(newSelected)
                setUnselected(newUnselected)
                onSelectedFilterChange({ ...selectedFilter, search: '' })
              }}
              searchPlaceholder={global._(
                'Keys.Inventory.Detail.SearchPlaceholder'
              )}
              endAdornmentIcon='remove_circle'
              endAdornmentOnClick={item => {
                const newUnselected = [item, ...unselected]
                const newSelected = selected.filter(item2 => item2 !== item)
                setUnselected(newUnselected)
                setSelected(newSelected)
              }}
            />
          </Grid>
        </Grid>
      </InfiniteScroll>
    </div>
  )
}

KeyInventoryManualVerificationView.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired
}

KeyInventoryManualVerificationView.defaultProps = {}

export default injectSheet(styles)(KeyInventoryManualVerificationView)
