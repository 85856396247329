import { connect } from 'react-redux'
import Component from './NotFound'

const mapStateToProps = state => ({
  history: state.history
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
