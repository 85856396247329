import React from 'react'
import axios from 'axios'

export default ({ user, apartment, organisation }) => {
  const [settings, setSettings] = React.useState({
    'keys.settings.maxKeyReceiptsPerUser': 6,
    'keys.settings.maxKeyReceiptsPerApartment': 6
  })
  const [isKeyLimitReached, setIsKeyLimitReached] = React.useState(false)
  const [isValidated, setIsValidated] = React.useState(false)
  const [limit, setLimit] = React.useState(0)
  const [count, setCount] = React.useState(0)
  const [hasLoadedSettings, setHasLoadedSettings] = React.useState(false)

  /* Load the organisations max amount of keys per user/apartment */
  React.useEffect(() => {
    ;(async () => {
      try {
        const { data } = await axios.get(
          `/v1/organisations/${organisation.id}/attributes` +
            '?key=keys.settings.maxKeyReceiptsPerUser&' +
            '&key=keys.settings.maxKeyReceiptsPerApartment'
        )
        setSettings({
          ...settings,
          ...data
        })
        setHasLoadedSettings(true)
      } catch (e) {}
    })()
  }, [])

  /* Validate that the user/apartment doesn't exceed the limit */
  React.useEffect(() => {
    if (!hasLoadedSettings) return
    setIsValidated(false)
    if (user || apartment) {
      ;(async () => {
        let _limit
        let query = 'count=true'
        if (user) {
          _limit = settings['keys.settings.maxKeyReceiptsPerUser']
          query += `&userId=${user.id}`
        } else if (apartment) {
          _limit = settings['keys.settings.maxKeyReceiptsPerApartment']
          query += `&apartmentId=${apartment.id}`
        }
        const { data } = await axios.get(
          `/v1/organisations/${organisation.id}/keys?${query}`
        )
        _limit = Number.parseInt(_limit, 10)
        setIsKeyLimitReached(data.count >= _limit)
        setLimit(_limit)
        setCount(data.count)
      })()
    } else {
      setIsKeyLimitReached(false)
    }
    setIsValidated(true)
  }, [user, apartment, hasLoadedSettings])

  return {
    isValidated,
    isKeyLimitReached,
    limit,
    count,
    settings
  }
}
