import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import TextField from '@material-ui/core/TextField'

const styles = {
    input: {
        width: '100%'
    }
}

const Password = props => {
    const {classes, onChange, error, onClick, value, name, label, ...rest} = props
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const handleMouseDownPassword = event => {
        event.preventDefault()
    }

    return (
        <TextField
            onChange={onChange}
            type={showPassword ? 'text' : 'password'}
            error={error}
            onClick={onClick}
            value={value}
            name={name}
            className={classes.input}
            label={label}
            variant='filled'
            InputProps={{
                endAdornment: <InputAdornment position='end'>
                    <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                </InputAdornment>
            }}
            {...rest}
        />
    )
}

Password.protoTypes = {
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func
}

Password.defaultProps = {
    onClick: null
}

const PasswordWithStyle = injectSheet(styles)(Password)
export default PasswordWithStyle