import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon } from '@material-ui/core'
import axios from 'axios'
import { DocumentStatus, UserRoles } from '../../../utils/constants'
import Modal from '../../../components/Modal'
import Typography from '../../../components/Typography'
import NavigationBar from '../../../components/NavigationBar'
import ScrollView from '../../../components/ScrollView'
import Notification from '../../../common/Notification'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Button from '../../../components/Button'
import ItemBadge from '../../../components/ItemBadge'
import Paper from '../../../components/Paper'
import { sizeInMb } from '../../../utils/helpers'
import MobileContainer from '../../../components/MobileContainer'

const styles = {
  rootMobile: {
    padding: 'var(--section-padding-default)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    paddingBottom: 32
  },
  input: {
    width: '100%'
  }
}

const DocumentsDetailMobile = ({ classes, history, match, user }) => {
  const [isLoadingDocument, setIsLoadingDocument] = useState(false)
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false)
  const [document, setDocument] = useState(false)
  const [error, setError] = useState(null)

  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)
  const isCustomerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.CustomerAdmin)

  const { id } = match.params

  const { isMobile } = useWindowDimensions()
  const token = localStorage.getItem('token')

  if (!isMobile) {
    history.push('/documents')
  }

  const fetchDocument = async () => {
    setIsLoadingDocument(true)
    try {
      const { data } = await axios.get(`/v1/documents/${id}`)

      setDocument(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoadingDocument(false)
  }

  const updateApprovalStatus = async isApproved => {
    try {
      await axios.patch(`/v1/documents/${id}/approve`, { isApproved })

      const tempDocObj = { ...document }
      tempDocObj.isApproved = isApproved
      tempDocObj.status = isApproved
        ? DocumentStatus.Accepted
        : DocumentStatus.Rejected

      setDocument(tempDocObj)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const deleteDocument = async () => {
    try {
      await axios.delete(`/v1/documents/${id}`)

      setShowDeleteDocumentModal(false)

      history.push('/documents')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    fetchDocument()
  }, [])

  if (isLoadingDocument) {
    return <LoadingSpinner />
  }

  return (
    <MobileContainer>
      {showDeleteDocumentModal && (
        <Modal
          onClose={() => setShowDeleteDocumentModal(false)}
          title={global._('Documents.DeleteDocument')}
          content={
            <div style={{ textAlign: 'left' }}>
              <Typography variant='body' block style={{ marginBottom: 15 }}>
                {global._('Documents.DeleteDocumentModalConfirmText')}
              </Typography>
              <Typography
                variant='body'
                bold
                block
                style={{ marginBottom: 15 }}
              >
                {document && document.name}
              </Typography>
              <Typography
                variant='body'
                italic
                block
                style={{ marginBottom: 30 }}
              >
                {global._('Documents.DeleteDocumentModalInfo')}
              </Typography>
            </div>
          }
          buttons={[
            <Button
              variant='none'
              onClick={() => setShowDeleteDocumentModal(false)}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button variant='secondary' onClick={() => deleteDocument()}>
              {global._('Delete')}
            </Button>
          ]}
        />
      )}
      <NavigationBar
        title={document ? document.name : global._('Documents.Document')}
        backAction={() => history.push('/documents')}
      />
      <ScrollView id='scrollable-container'>
        <div className={classes.rootMobile}>
          {error && (
            <Notification
              type='error'
              message={error}
              style={{ margin: '20px 0' }}
            />
          )}
          {document &&
            document.status === DocumentStatus.AwaitingApproval &&
            isCustomerAdmin && (
              <Paper
                style={{ marginBottom: 30 }}
                alignItems='left'
                smallerPadding
              >
                <Typography variant='body' style={{ marginBottom: 20 }}>
                  {global._('Documents.TheDocument')}{' '}
                  <span
                    style={{
                      color: 'var(--color-purple)',
                      fontWeight: 'bold',
                      textTransform: 'lowercase'
                    }}
                  >
                    {global._(`Document.Statuses.${document.status}`)}
                  </span>
                </Typography>
                <Button
                  variant='secondary'
                  onClick={() => updateApprovalStatus(false)}
                  style={{
                    marginBottom: 20
                  }}
                >
                  {global._('Documents.Reject')}
                </Button>
                <Button
                  variant='primary'
                  onClick={() => updateApprovalStatus(true)}
                  style={{
                    marginBottom: 10
                  }}
                >
                  {global._('Documents.Accept')}
                </Button>
              </Paper>
            )}
          {(isPartnerAdmin || isSupport || isCustomerAdmin) && (
            <Paper
              style={{ marginBottom: 20 }}
              alignItems='left'
              smallerPadding
            >
              <Typography variant='body'>
                {global._(
                  `Documents.${
                    document.isPublic
                      ? 'VisibleForAllMobile'
                      : 'VisibleForAdminMobile'
                  }`
                )}
              </Typography>
            </Paper>
          )}
          <div>
            <ItemBadge
              label={
                document &&
                document.file &&
                `${document.file.name} (${sizeInMb(document.file.size)} mb)`
              }
              icon='assignment'
              hideDeleteBtn
            />
            <Button
              variant='outlined'
              onClick={() =>
                window.open(
                  `${axios.defaults.baseURL}/v1/documents/${id}/files?token=${token}`
                )
              }
              style={{ marginTop: 14 }}
            >
              {global._('Common.Download')}
            </Button>
          </div>
          {isPartnerAdmin && (
            <div style={{ marginTop: 'auto' }}>
              <Button
                variant='secondary'
                style={{
                  marginRight: 20
                }}
                onClick={() => {
                  setShowDeleteDocumentModal(true)
                }}
              >
                {global._('Delete')}
              </Button>
            </div>
          )}
        </div>
      </ScrollView>
    </MobileContainer>
  )
}

DocumentsDetailMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  signout: PropTypes.func.isRequired
}

DocumentsDetailMobile.defaultProps = {}

const DocumentsDetailMobileWithStyles = injectSheet(styles)(
  DocumentsDetailMobile
)
export default DocumentsDetailMobileWithStyles
