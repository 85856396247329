import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import IconColumn from 'src/components/IconColumn'
import Button from 'src/components/Button'
import Notification from 'src/common/Notification'
import Paper from 'src/components/Paper'
import Typography from 'src/components/Typography'
import PageContentHeader from 'src/components/PageContentHeader'
import {
  getSelectedOrg,
  convertDraftJsToPdfMakeObj,
  normalize
} from 'src/utils/helpers'
import withUser from 'src/utils/withUser'
import AsyncSelect from 'src/components/AsyncSelect'
import ItemBadge from 'src/components/ItemBadge'
import Alert from 'src/components/Alert'
import List from 'src/components/List'
import Column from 'src/components/Column'
import Success from 'src/components/Success'
import InfiniteScroll from 'src/components/InfiniteScroll'
import { generateReturnedKeysReceipt } from 'src/utils/receiptTemplates'
import { history } from '../../../redux/store'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const styles = {
  root: {},
  divider: {
    width: 'calc(100% + 62px)',
    height: 1,
    backgroundColor: 'var(--color-divider-light)',
    margin: '0 -31px'
  }
}

const LIMIT = 25

const ReturnKeys = ({ classes, user }) => {
  const selectedOrg = getSelectedOrg(user)
  const [isLoading, setIsLoading] = useState(false)
  const [stepTwo, setStepTwo] = useState(false)

  /* Unselected keys */
  const [unselectedKeys, setUnselectedKeys] = useState([])
  const [filterUnselectedKeys, setFilterUnselectedKeys] = useState([])
  const [unselectedFilter, setUnselectedFilter] = useState({})

  /* Selected keys */
  const [selectedKeys, setSelectedKeys] = useState([])
  const [selectedStorage, setSelectedStorage] = useState()
  const [filterSelectedKeys, setFilteredSelectedKeys] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({})

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const [offset, setOffset] = useState(0)

  const baseCrumbs = [{ path: '/keys', name: 'Nyckelhantering' }]
  const [crumbs, setCrumbs] = useState([
    ...baseCrumbs,
    {
      path: '',
      name: `${global._('Keys.ReturnKeys.ReturnStep')} ${global._(
        'Keys.ReturnKeys.One'
      )}`
    }
  ])

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      style: { width: 50 },
      format: () => <IconColumn icon='vpn_key' />
    },
    {
      key: 'name',
      localizationKey: 'Keys.ReturnKeys.Name',
      sortingKey: 'name',
      format: name => name || global._('Common.NoName'),
      style: { width: 150 },
      clickable: true
    },
    {
      key: 'marking',
      localizationKey: 'Keys.List.Marking',
      sortingKey: 'marking',
      style: { width: 150 },
      clickable: true
    },
    {
      key: 'holderName',
      localizationKey: 'Keys.List.Holder',
      format: (objVal, obj) => (
        <Column align='left'>
          <span>{objVal || global._('Common.NoName')}</span>
          {obj.holderEmail && (
            <span
              style={{
                color: 'var(--color-text-faded)'
              }}
            >
              {`(${obj.holderEmail})`}
            </span>
          )}
        </Column>
      ),
      sortingKey: 'holderName',
      style: { width: 150 },
      clickable: true
    }
  ]

  const onFilterChange = async (
    filter,
    originalList,
    setFilter,
    setList,
    selected
  ) => {
    let list

    if (filter.search) {
      const search = normalize(filter.search.toLowerCase())
      list = originalList.filter(item => item.search.includes(search))
    } else {
      list = [...originalList]
    }

    const { orderBy } = filter
    if (orderBy) {
      list.sort((a, b) => {
        if (
          orderBy === 'name' ||
          orderBy === 'holderName' ||
          orderBy === 'marking'
        ) {
          if (filter.order === 'asc') {
            return a[orderBy].localeCompare(b[orderBy])
          }
          return b[orderBy].localeCompare(a[orderBy])
        }
        return 0
      })
    }

    const compareFilter = selected ? selectedFilter : unselectedFilter
    if (
      filter.orderBy !== compareFilter.orderBy ||
      filter.order !== compareFilter.order ||
      filter.search !== compareFilter.search
    ) {
      setOffset(0)
    }

    setList(list)
    setFilter(filter)
  }

  const onUnselectedFilterChange = filter =>
    onFilterChange(
      filter,
      unselectedKeys,
      setUnselectedFilter,
      setFilterUnselectedKeys,
      false
    )
  const onSelectedFilterChange = filter =>
    onFilterChange(
      filter,
      selectedKeys,
      setSelectedFilter,
      setFilteredSelectedKeys,
      true
    )

  const onSave = async () => {
    try {
      setIsLoading(true)

      const promises = []
      selectedKeys.forEach(key => {
        promises.push(
          axios.patch(`/v1/organisations/${selectedOrg.id}/keys/${key.id}`, {
            userId: null,
            storageId: selectedStorage.id
          })
        )
      })

      await Promise.all(promises)
      setSuccess(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  const handlePrint = () => {
    try {
      const returnedKeysRaw = generateReturnedKeysReceipt(
        selectedKeys,
        selectedOrg
      )
      const docDefinition = convertDraftJsToPdfMakeObj(returnedKeysRaw)
      docDefinition.info = {
        title: `${user.name}-${moment().format('YYYY-MM-DD')}`
      }
      pdfMake.createPdf(docDefinition).print()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  /* update filtered lists when adding or removing keys */
  useEffect(() => {
    onUnselectedFilterChange(unselectedFilter)
    onSelectedFilterChange(selectedFilter)
  }, [selectedKeys, unselectedKeys])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const { data: _keys } = await axios.get(
          `/v1/organisations/${selectedOrg.id}/keys`
        )

        _keys.forEach(key => {
          key.search = ''
          if (key.name) {
            key.search += key.name.toLowerCase()
          }
          if (key.marking) {
            key.search += key.marking.toLowerCase()
          }
          if (key.holderName) {
            key.search += key.holderName.toLowerCase()
          }
          if (key.holderEmail) {
            key.search += key.holderEmail.toLowerCase()
          }
          key.search = normalize(key.search)
        })

        const unselectedKeysArr = _keys
        setUnselectedKeys(unselectedKeysArr)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
      setIsLoading(false)
    })()
  }, [])

  const offsetLimit = offset + LIMIT

  return (
    <div className={classes.root}>
      {!success && <PageContentHeader breadcrumbItems={crumbs} />}
      {error && (
        <Notification
          style={{ marginBottom: 20 }}
          type='error'
          message={error}
        />
      )}
      {success && (
        <Success>
          <Typography variant='subtitleBig' bold style={{ marginBottom: 28 }}>
            {global._('Keys.ReturnKeys.Success.ReturnComplete')}
          </Typography>
          {selectedStorage.hasEmail === 1 && (
            <Typography
              variant='body'
              style={{ textAlign: 'center', marginBottom: 28 }}
            >
              {global._('Keys.ReturnKeys.Success.EmailSent')}
            </Typography>
          )}
          <Button
            style={{ width: '100%' }}
            variant='outlined'
            onClick={() => handlePrint()}
          >
            {global._('Keys.ReturnKeys.Success.Print')}
          </Button>
          <Button
            style={{ width: '100%' }}
            variant='none'
            onClick={() => history.push('/keys')}
          >
            {global._('Keys.ReturnKeys.Success.Done')}
          </Button>
        </Success>
      )}
      {!success && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant='title' bold>
              {global._('Keys.ReturnKeys.Title')}
            </Typography>
            <Button
              loading={isLoading}
              variant='outlined'
              onClick={() => {
                if (stepTwo) {
                  setStepTwo(false)
                  setCrumbs([
                    ...baseCrumbs,
                    {
                      path: '',
                      name: `${global._(
                        'Keys.ReturnKeys.ReturnStep'
                      )} ${global._('Keys.ReturnKeys.One')}`
                    }
                  ])
                } else {
                  history.push('/keys')
                }
              }}
              minWidth
              style={{ marginLeft: 'auto', marginRight: 20 }}
            >
              {global._(stepTwo ? 'Keys.ReturnKeys.Previous' : 'Common.Cancel')}
            </Button>
            <Button
              loading={isLoading}
              disabled={
                (!stepTwo && !selectedKeys.length) ||
                (stepTwo && !selectedStorage)
              }
              variant='primary'
              onClick={() => {
                if (stepTwo) {
                  onSave()
                } else {
                  setCrumbs([
                    ...baseCrumbs,
                    {
                      path: '',
                      name: `${global._(
                        'Keys.ReturnKeys.ReturnStep'
                      )} ${global._('Keys.ReturnKeys.Two')}`
                    }
                  ])
                  setStepTwo(true)
                }
              }}
              minWidth
            >
              {global._(
                stepTwo ? 'Keys.ReturnKeys.Complete' : 'Keys.ReturnKeys.Next'
              )}
            </Button>
          </div>
          {stepTwo ? (
            <Paper style={{ marginTop: 24 }}>
              <Typography variant='subtitleBig' bold>
                {global._('Keys.ReturnKeys.ChooseStorage')}
              </Typography>
              <div
                style={{
                  width: 'calc(100% + 62px)',
                  height: 1,
                  backgroundColor: 'var(--color-divider-light)',
                  margin: '20px -31px'
                }}
              />
              <Grid container spacing={3} style={{ marginTop: 12 }}>
                <Grid item xs={6}>
                  <AsyncSelect
                    isSearchable
                    isClearable={false}
                    key='search-storages'
                    dropdownIcon='search'
                    helpText={global._('Keys.ReturnKeys.StoragePlaceHelpText')}
                    label={obj => `${obj.name || global._('Common.NoName')}`}
                    labelKey='name'
                    name='user'
                    placeholder={global._(
                      'Keys.ReturnKeys.StoragePlaceSearchPlaceholder'
                    )}
                    onChange={e => setSelectedStorage(e.value || null)}
                    url={`/v1/organisations/${selectedOrg.id}/keystorages?orderBy=name&order=asc&search={{input}}`}
                  />
                  {selectedStorage && (
                    <ItemBadge
                      onDelete={() => setSelectedStorage(null)}
                      label={selectedStorage.name}
                      icon='archive'
                      containerStyle={{ margin: '20px 0' }}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Alert bold>
                    {global._('Keys.ReturnKeys.AlertInfoText')}
                  </Alert>
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <InfiniteScroll
              hasMore={
                filterUnselectedKeys.length > offsetLimit ||
                filterSelectedKeys.length > offsetLimit
              }
              fetchMore={() => setOffset(offsetLimit)}
            >
              <Grid container spacing={3} style={{ marginTop: 12 }}>
                <Grid item xs={6}>
                  <List
                    columns={columns}
                    items={filterUnselectedKeys.slice(0, offsetLimit)}
                    isLoading={isLoading}
                    classes={classes}
                    filter={unselectedFilter}
                    setFilter={onUnselectedFilterChange}
                    title={global._('Keys.ReturnKeys.ChooseKeys')}
                    endAdornmentOnClick={key => {
                      const updated = [key, ...selectedKeys]
                      setUnselectedKeys(
                        unselectedKeys.filter(keyObj => keyObj !== key)
                      )
                      setSelectedKeys(updated)
                    }}
                    searchPlaceholder={global._(
                      'Keys.ReturnKeys.KeysSearchPlaceholder'
                    )}
                    endAdornmentIcon='add_circle'
                  />
                </Grid>
                <Grid item xs={6}>
                  <List
                    columns={columns}
                    items={filterSelectedKeys.slice(0, offsetLimit)}
                    classes={classes}
                    filter={selectedFilter}
                    setFilter={onSelectedFilterChange}
                    title={global._('Keys.ReturnKeys.SelectedKeys')}
                    buttonTitle={global._('Keys.ReturnKeys.Clear')}
                    endAdornmentOnClick={key => {
                      const updated = selectedKeys.filter(
                        keyObj => keyObj !== key
                      )
                      setUnselectedKeys([key, ...unselectedKeys])
                      setSelectedKeys(updated)
                    }}
                    buttonOnClick={() => {
                      const updated = selectedKeys.filter(
                        item => !filterSelectedKeys.includes(item)
                      )
                      setSelectedKeys(updated)
                      setUnselectedKeys([
                        ...filterSelectedKeys,
                        ...unselectedKeys
                      ])
                      onSelectedFilterChange({ ...selectedFilter, search: '' })
                    }}
                    searchPlaceholder={global._(
                      'Keys.ReturnKeys.KeysSearchPlaceholder'
                    )}
                    endAdornmentIcon='remove_circle'
                  />
                </Grid>
              </Grid>
            </InfiniteScroll>
          )}
        </>
      )}
    </div>
  )
}

ReturnKeys.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const ReturnKeysWithStylesAndUser = withUser(injectSheet(styles)(ReturnKeys))
export default ReturnKeysWithStylesAndUser
