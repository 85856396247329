import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import injectSheet from 'react-jss'
import { Radio, TextField } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import Alert from 'src/components/Alert'
import Notification from 'src/common/Notification'
import { ShippingTypes } from 'src/utils/constants'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import Row from 'src/components/Row'
import { phoneNumberRegex } from 'src/utils/helpers'
import PhoneNumberField from '../../../../components/PhoneNumberField'

const styles = {
  root: {},
  content: {
    maxWidth: 600
  },
  section: {
    padding: 20,
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--divider-color)'
    }
  },
  optionRow: {
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--color-divider-light)'
    }
  },

  '@media (max-width: 900px)': {
    section: {
      padding: 'var(--section-padding-default)',
      '&:not(:last-child)': {
        borderBottom: '1px solid var(--divider-color)'
      }
    }
  }
}

const Shipping = ({
  classes,
  productVariant,
  user,
  defaultValues,
  onCompletion
}) => {
  const { isMobile } = useWindowDimensions()
  const [error, setError] = useState(null)
  const [selectedShippingOption, setSelectedShippingOption] = useState()
  const [address, setAddress] = useState(defaultValues.address)
  const [postalCode, setPostalCode] = useState(defaultValues.postalCode)
  const [city, setCity] = useState(defaultValues.city)
  const [phone, setPhone] = useState(defaultValues.phone)
  const [shippingOptions, setShippingOptions] = useState([])
  const [fieldErrors, setFieldErrors] = useState({})

  useEffect(() => {
    setFieldErrors(prevFields => {
      const newFieldErrors = { ...prevFields }

      if (phoneNumberRegex.test(phone)) {
        delete newFieldErrors.phone
      } else {
        newFieldErrors.phone = true
      }

      return newFieldErrors
    })
  }, [phone])

  useEffect(() => {
    ;(async () => {
      try {
        let { data: _shippingOptions } = await axios.get(
          `/v1/organisations/${user.organisation.id}/shipping`
        )
        if (_shippingOptions.length === 0) {
          _shippingOptions = [
            { type: ShippingTypes.COLLECT, price: '0', active: true }
          ]
        }
        setShippingOptions(_shippingOptions)
        if (defaultValues && defaultValues.type) {
          const preselectedOption = _shippingOptions.find(
            opt => opt.type === defaultValues.type
          )
          if (preselectedOption) {
            return setSelectedShippingOption(preselectedOption)
          }
        }
        setSelectedShippingOption(_shippingOptions[0])
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    })()
  }, [])

  const navBarTitle = global._(
    'Keys.Order.Details.NavBarTitles.ShippingDetails'
  )

  return (
    <>
      <div style={{ width: '100%' }}>
        <div className={classes.section} style={{ width: '100%' }}>
          <Typography variant='subtitle' bold>
            {global._(`Keys.Order.Checkout.ShippingDetails.Title`)}
          </Typography>
        </div>
        {!selectedShippingOption ? (
          <div style={{ width: '100%', padding: '24px 16px' }}>
            <Skeleton variant='rect' width={300} height={20} />
            <Row style={{ marginTop: 35 }}>
              <Skeleton variant='circle' width={20} height={20} />
              <Skeleton
                variant='rect'
                width={150}
                height={20}
                style={{ marginTop: 0, marginLeft: 30 }}
              />
              <Skeleton
                variant='rect'
                width={40}
                height={20}
                style={{ marginTop: 0, marginLeft: 'auto' }}
              />
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Skeleton variant='circle' width={20} height={20} />
              <Skeleton
                variant='rect'
                width={120}
                height={20}
                style={{ marginTop: 0, marginLeft: 30 }}
              />
              <Skeleton
                variant='rect'
                width={40}
                height={20}
                style={{ marginTop: 0, marginLeft: 'auto' }}
              />
            </Row>
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ margin: '20px 0' }}
              />
            )}
          </div>
        ) : (
          <>
            <div className={classes.section} style={{ padding: 0 }}>
              {shippingOptions.map(opt => {
                if (!opt.active) {
                  return null
                }
                return (
                  <Row
                    key={opt.type}
                    className={classes.optionRow}
                    style={{
                      padding: '8px 16px'
                    }}
                    onClick={() => setSelectedShippingOption(opt)}
                  >
                    <Radio
                      style={{ color: 'var(--color-black)' }}
                      checked={selectedShippingOption?.type === opt.type}
                    />
                    <Typography variant='body' bold style={{ marginLeft: 10 }}>
                      {global._(`Constants.ShippingTypes.${opt.type}`)}
                    </Typography>
                    <Typography
                      variant='body'
                      bold
                      style={{ marginLeft: 'auto ' }}
                    >
                      {`+ ${opt.price} ${global._('Common.Currency')}`}
                    </Typography>
                  </Row>
                )
              })}
            </div>
            <div className={classes.section}>
              {selectedShippingOption?.type === ShippingTypes.SEND && (
                <>
                  <TextField
                    variant='filled'
                    label={global._('User.AddressOne')}
                    style={{ width: '100%' }}
                    helperText={global._('Common.Required')}
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                  <TextField
                    variant='filled'
                    label={global._('User.ZipCode')}
                    style={{ width: '100%', marginTop: 20 }}
                    helperText={global._('Common.Required')}
                    value={postalCode}
                    onChange={e => setPostalCode(e.target.value)}
                  />
                  <TextField
                    variant='filled'
                    label={global._('User.Town')}
                    style={{ width: '100%', marginTop: 20 }}
                    helperText={global._('Common.Required')}
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
                  <PhoneNumberField
                    variant='filled'
                    label={global._('User.Phone')}
                    style={{ width: '100%', marginTop: 20 }}
                    helperText={
                      fieldErrors.phone && phone
                        ? global._('Common.PhoneNumberInvalid')
                        : global._('Common.Required')
                    }
                    value={phone}
                    error={fieldErrors.phone}
                    onChange={value => setPhone(value)}
                  />
                </>
              )}
              <Alert
                style={{ marginTop: 20 }}
                dangerouslySetInnerHTML={{
                  __html: global._(
                    `Keys.Order.Checkout.Info.${productVariant}.OrderInfo.${selectedShippingOption?.type}`
                  )
                }}
              />
              <Button
                variant='primary'
                disabled={
                  !selectedShippingOption ||
                  (selectedShippingOption.type === ShippingTypes.SEND &&
                    (!address ||
                      !postalCode ||
                      !city ||
                      !phone ||
                      Object.keys(fieldErrors).length !== 0))
                }
                style={{
                  width: '100%',
                  marginTop: 'var(--section-padding-default)'
                }}
                onClick={() => {
                  onCompletion({
                    ...selectedShippingOption,
                    address,
                    postalCode,
                    city,
                    phone
                  })
                }}
              >
                {global._('Common.Proceed')}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

Shipping.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  productVariant: PropTypes.string.isRequired,
  defaultValues: PropTypes.object.isRequired,
  onCompletion: PropTypes.func.isRequired
}

export default injectSheet(styles)(Shipping)
