import React from 'react'
import PropTypes from 'prop-types'
import Workbook from 'react-excel-workbook'
import Button from '@material-ui/core/Button'

const Excel = ({ users, currentUser }) => (
  <Workbook
    filename='export_users.xlsx'
    element={
      <Button
        style={{ float: 'right', marginRight: 10 }}
        color='primary'
        variant='contained'
        disabled={!users.length}>
        Exportera användare
      </Button>
    }>
    <Workbook.Sheet data={users} name='Export S&P Kundportal'>
      <Workbook.Column
        label='Organisation'
        value={user =>
          (user.organisation && user.organisation.name) ||
          (currentUser &&
            currentUser.organisation &&
            currentUser.organisation.name)
        }
      />
      <Workbook.Column label='Namn' value={user => user.name} />
      <Workbook.Column label='Email' value={user => user.email} />
      <Workbook.Column label='Telefon' value={user => user.phone} />
      <Workbook.Column label='Roll' value={user => global._(user.role)} />
    </Workbook.Sheet>
  </Workbook>
)

Excel.propTypes = {
  users: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired
}

export default Excel
