import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Theme from '../../helpers/Theme'
import Row from '../../components/Row'
import Button from '../../components/Button'
import BlueprintObjectList from '../../components/BlueprintObjectList/BlueprintObjectList'
import BlueprintObjectListMobile from '../../components/BlueprintObjectList/BlueprintObjectListMobile'
import { UserRoles } from '../../utils/constants'
import useWindowDimensions from '../../utils/useWindowDimensions'
import Content from '../../components/Content'

const styles = {
  root: {
    backgroundColor: '#fdfdfd',
    display: 'flex',
    flex: 1,
    minHeight: '100%',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: 'url("/bg-image.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundOrigin: 'padding-box'
  },
  contentContainer: {
    width: 760,
    flex: 1,
    minHeight: '100%',
    maxWidth: '100%',
    height: '100%',
    backgroundColor: Theme.Colors.WHITE,
    overflow: 'hidden'
  },
  '@media (max-width: 1200px)': {
    contentContainer: {
      paddingTop: 20
    }
  }
}

const BlueprintListView = props => {
  const {
    classes,
    user,
    computedMatch: {
      params: { propertyId, blueprintId, organisationId }
    },
    history
  } = props

  const { isMobile } = useWindowDimensions()
  const isQuoteRequest = new RegExp('/quoteoverview').test(
    history.location.pathname
  )
  let actionButton
  let exportable = false
  /* If user is Guest, present option to save */
  if (user.roles.includes(UserRoles.Guest)) {
    actionButton = (
      <Button
        variant='primary'
        onClick={() =>
          history.push(
            `/onboarding/properties/${propertyId}/blueprints/${blueprintId}/register`
          )
        }>
        {global._('Common.Save')}
      </Button>
    )
  } else {
    exportable = true
  }

  if (isMobile) {
    return (
      <Content>
        <BlueprintObjectListMobile
          {...props}
          history={history}
          propertyId={propertyId}
          blueprintId={blueprintId}
          actionButton={isQuoteRequest ? null : actionButton}
          exportable={isQuoteRequest ? false : exportable}
          title={
            isQuoteRequest
              ? global._('Properties.RequestForQuotation')
              : global._('BlueprintObjectList.Title')
          }
        />
        {isQuoteRequest && (
          <Row style={{ padding: 20, backgroundColor: Theme.Colors.WHITE }}>
            <Button
              variant='primary'
              onClick={() =>
                history.push(`${history.location.pathname}/quoterequest`)
              }>
              {global._('Next')}
            </Button>
          </Row>
        )}
      </Content>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <BlueprintObjectList
          propertyId={propertyId}
          blueprintId={blueprintId}
          organisationId={organisationId}
          actionButton={isQuoteRequest ? null : actionButton}
          exportable={isQuoteRequest ? false : exportable}
          title={
            isQuoteRequest
              ? global._('Properties.RequestForQuotation')
              : global._('BlueprintObjectList.Title')
          }
        />
        <div className={classes.container}>
          <Row flex justify='flex-end' style={{ padding: '50px 47px' }}>
            <Button
              data-cy='button-go-back'
              variant='none'
              /* length of history should always be more than 2, if not it's most likely a link opened from quote request mail */
              onClick={() =>
                history.length > 2
                  ? history.goBack()
                  : history.push(`/properties/${propertyId}`)
              }>
              {global._(
                user.roles.includes(UserRoles.Guest)
                  ? 'BlueprintListView.BackToBlueprint'
                  : 'Common.Back'
              )}
            </Button>
            {isQuoteRequest && (
              <Button
                data-cy='button-quote-request'
                variant='primary'
                onClick={() =>
                  history.push(`${history.location.pathname}/quoterequest`)
                }
                style={{ marginLeft: 10 }}>
                {global._('Next')}
              </Button>
            )}
          </Row>
        </div>
      </div>
    </div>
  )
}

BlueprintListView.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired, // Inherited from Public/PrivateLayout
  history: PropTypes.object.isRequired, // Inherited from Public/PrivateLayout
  computedMatch: PropTypes.object.isRequired // Inherited from Public/PrivateLayout
}

const RegisterWithStyle = injectSheet(styles)(BlueprintListView)
export default RegisterWithStyle
