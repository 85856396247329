import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Paper from 'src/components/Paper'
import Typography from 'src/components/Typography'
import SuccessIcon from 'src/components/SuccessIcon'
import React, { useState } from 'react'
import DigitalSigning from '../../../../components/DigitalSigning'
import MobileContainer from '../../../../components/MobileContainer'
import NavigationBar from '../../../../components/NavigationBar'
import { Icon } from '@material-ui/core'
import ScrollView from '../../../../components/ScrollView'
import { history } from '../../../../redux/store'
import useWindowDimensions from '../../../../utils/useWindowDimensions'

const styles = {}

const KeyLoanSigning = ({ token, setIsCollecting, setError }) => {
  const [complete, setComplete] = useState(false)

  const signURL = `/v1/flows/keyloan/keys/${token}/sign`

  const { isMobile } = useWindowDimensions()

  const SuccessWrapper = ({ children }) =>
    isMobile ? (
      <MobileContainer showTabBar={false}>
        <NavigationBar
          title={global._('PublicTemporaryKeyLoan.Sign')}
          backAction={() => history.push('/')}
        />
        <ScrollView
          id='scrollable-container'
          style={{ marginTop: 40, paddingLeft: 20, paddingRight: 20 }}
        >
          {children}
        </ScrollView>
      </MobileContainer>
    ) : (
      <>{children}</>
    )

  return (
    <div
      style={{
        background: 'var(--color-light-grey)',
        width: '100%',
        padding: '64px 20px'
      }}
    >
      {complete ? (
        <SuccessWrapper>
          <Paper
            fixedWidth
            style={{
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <SuccessIcon />
            <Typography style={{ marginTop: 40 }} variant='subtitleBig'>
              {global._('PublicTemporaryKeyLoan.SigningCompleted')}
            </Typography>
          </Paper>
        </SuccessWrapper>
      ) : (
        <DigitalSigning
          signURL={signURL}
          requestData={[]}
          title={global._('PublicTemporaryKeyLoan.Sign')}
          showTabBar={false}
          onCancel={() => {
            setIsCollecting(false)
          }}
          onCompletion={() => {
            setComplete(true)
          }}
          onFailure={e => {
            const msg = e.response ? e.response.data : e.message
            setError(msg)
            setIsCollecting(false)
          }}
        />
      )}
    </div>
  )
}

KeyLoanSigning.propTypes = {
  token: PropTypes.string.isRequired,
  setIsCollecting: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}

KeyLoanSigning.defaultProps = {}

const KeyLoanSigningWithStylesAndUser = injectSheet(styles)(KeyLoanSigning)
export default KeyLoanSigningWithStylesAndUser
