import XLSX from 'xlsx'

/* expected structure of 'sheets'
  [{
    name: 'string'
    data: [{
      ...arbitraryData
    }]
    columns: [{
      label: 'string',
      value: row => row.param
    }]}
  }]
*/
export const excel = ({ filename, sheets }) => {
  const workbook = XLSX.utils.book_new()
  sheets.forEach(sheet => {
    const data = []
    const columnNames = sheet.columns.map(col => col.label)
    data.push(columnNames)

    sheet.data.forEach(item => {
      const row = []
      sheet.columns.forEach(col => {
        row.push(col.value(item))
      })
      data.push(row)
    })

    const worksheet = XLSX.utils.aoa_to_sheet(data)

    XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name)
  })

  XLSX.writeFile(workbook, filename)
}

export const newWindowPrint = (title, html) => {
  const newWindow = window.open('', '_blank')
  newWindow.document.write(html)
  newWindow.document.title = title
  newWindow.document.close()
  newWindow.focus()
  newWindow.print()
  newWindow.close()
}

export default {
  excel
}
