import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, Route, Redirect } from 'react-router-dom'
import CustomLink from 'src/components/Link'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from '@date-io/moment'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import { Badge } from '@material-ui/core'
import axios from 'axios'
import { Variants as PWAVariants, setPWA } from 'src/utils/PWASelector'
import deferred from 'src/utils/deferBeforeInstallPromptEvent'
import DeleteModal from '../../common/DeleteModal/DeleteModal'
import Header from '../../components/Header'
import { items } from './listItems'
import { UserRoles, OrganisationServices } from '../../utils/constants'
import useWindowDimensions from '../../utils/useWindowDimensions'
import AddToHomescreen from '../../components/AddToHomescreen'
import RouteList from '../../utils/RouteList'
import packageJson from '../../../package.json'
import useLogotype from '../../utils/useLogotype'
import {
  checkIfOrgHasAccessToScope,
  getUnreadItemsCountObj,
  runUnreadItemsCountFetcher,
  updateUnreadItemsAcrossTabs
} from '../../utils/helpers'
import ChangeOrganisationModal from './ChangeOrganisationModal'
import ServiceDeactivated from './ServiceDeactivated'
import { history } from '../../redux/store'
import { useNotifications } from 'src/hooks/useNotifications'

const drawerWidth = 244

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  menuColumn: {
    position: 'relative',
    width: 80,
    maxWidth: 80,
    minWidth: 80,
    minHeight: '100vh'
  },
  headerAndContentColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto'
  },
  menuButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 22,
    paddingTop: 10
  },
  drawerPaper: {
    borderRight: 'none',
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    height: 'auto !important',
    minHeight: '100vh',
    position: 'static !important',
    overflow: 'visible !important'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: 80
    }
  },
  content: {
    flex: 1,
    overflow: 'auto',
    flexDirection: 'column',
    backgroundColor: '#E8E8E8',
    padding: props => (props.fullWidthContainer ? null : '60px 49px 49px')
  },
  versionLabel: {
    position: 'absolute',
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: '0.5rem',
    color: '#aaa',
    zIndex: 10001
  },

  '@media (max-width: 900px)': {
    root: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: '#fff',
      overflow: 'hidden'
    },
    content: {
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    },
    headerAndContentColumn: {
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    }
  }
})

const UnreadItemsNavBadge = ({ unreadItemsCount }) => (
  <div
    style={{
      backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) ${
        unreadItemsCount.length > 2 ? '63%' : '54%'
      }, var(--color-error-opacity) 52%, var(--color-error-opacity) 100%)`,
      width: unreadItemsCount.length > 2 ? 45 : 37,
      height: unreadItemsCount.length > 2 ? 40 : 37,
      position: 'absolute',
      right: '-17px',
      borderRadius: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Badge
      data-cy='badge-unread-items'
      badgeContent={unreadItemsCount}
      color='error'
      style={{ zIndex: 99 }}
    />
  </div>
)

const PrivateLayout = props => {
  const {
    classes,
    computedMatch,
    deletePayload,
    expires,
    handleDeleteInput,
    handleReverseImpersonate,
    isLoadingRemove,
    isSidebarOpen,
    location,
    remove,
    showDeleteConfirmation,
    signout,
    toggleDeleteConfirmation,
    toggleDrawer,
    token,
    user,
    scope
  } = props

  const userIsGuest =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Guest)
  const userIsAdminGuest =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.CustomerAdminGuest)
  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)
  const isCustomerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.CustomerAdmin)

  if (user && !user.active && user.signupToken) {
    history.push(`/register/${user.signupToken.token}`)
  }

  const [signedOut, setSignedOut] = useState(false)

  const { logotype } = useLogotype(user)

  const [showChangeOrganisationModal, setShowChangeOrganisationModal] =
    useState(false)
  const [selectedOrganisationId, setSelectedOrganisationId] = useState()
  const [userOrganisations, setUserOrganisations] = useState([])
  const [error, setError] = useState(null)

  const unreadCasesCountObj = getUnreadItemsCountObj('unreadCasesCount')
  const [unreadCasesCount, setUnreadCasesCount] = useState(
    isPartnerAdmin || isSupport ? unreadCasesCountObj.count : null
  )

  const unreadOrdersCountObj = getUnreadItemsCountObj('unreadOrdersCount')

  const [unreadOrdersCount, setUnreadOrdersCount] = useState(
    unreadOrdersCountObj.count
  )

  const pendingDocsCountObj = getUnreadItemsCountObj('pendingDocsCount')
  const [pendingDocsCount, setPendingDocsCount] = useState(
    isCustomerAdmin ? pendingDocsCountObj.count : null
  )

  const pendingKeysCountObj = getUnreadItemsCountObj('pendingKeysCount')
  const [pendingKeysCount, setPendingKeysCount] = useState(
    isPartnerAdmin ? pendingKeysCountObj.count : null
  )

  const pendingKeyInventoryApprovalsCountObj = getUnreadItemsCountObj(
    'pendingKeyInventoryApprovalsCount'
  )
  const [
    pendingKeyInventoryApprovalsCount,
    setPendingKeyInventoryApprovalsCount
  ] = useState(
    isPartnerAdmin ? pendingKeyInventoryApprovalsCountObj.count : null
  )

  const multipleOrganisationsUser =
    userOrganisations && userOrganisations.length > 1

  let selectedOrganisation

  if (userOrganisations && userOrganisations.length > 0) {
    ;[selectedOrganisation] = userOrganisations

    if (multipleOrganisationsUser && selectedOrganisationId) {
      selectedOrganisation = userOrganisations.find(
        o => o.id === selectedOrganisationId
      )
    }
  }

  const organisationServices =
    selectedOrganisation && selectedOrganisation.services

  const orgHasAccessToScope = checkIfOrgHasAccessToScope(
    user,
    selectedOrganisation,
    scope
  )

  if (isPartnerAdmin || isSupport) {
    updateUnreadItemsAcrossTabs('unreadCasesCount', setUnreadCasesCount)
    updateUnreadItemsAcrossTabs('unreadOrdersCount', setUnreadOrdersCount)
  } else {
    if (localStorage.getItem('unreadCasesCount')) {
      localStorage.removeItem('unreadCasesCount')
    }

    if (localStorage.getItem('unreadOrdersCount')) {
      localStorage.removeItem('unreadOrdersCount')
    }
  }

  if (isCustomerAdmin) {
    updateUnreadItemsAcrossTabs('pendingDocsCount', setPendingDocsCount)
  }

  if (!isSupport) {
    updateUnreadItemsAcrossTabs('pendingKeysCount', setPendingKeysCount)
    updateUnreadItemsAcrossTabs(
      'pendingKeyInventoryApprovalsCount',
      setPendingKeyInventoryApprovalsCount
    )
  }

  const { notifications } = useNotifications({ fetch: true })

  const { isMobile } = useWindowDimensions()

  const handleDrawer = () => {
    toggleDrawer()
  }

  const handleSignout = () => {
    setSignedOut(true)
    signout()
  }

  const openNewPageOnItemClick = (event, path) => {
    if (path.startsWith('http')) {
      event.preventDefault()
      event.stopPropagation()
      window.open(path, '_blank')
    }
  }

  const breadcrumbs = match => {
    const breadcrumbItems = RouteList.filter(({ path }) =>
      match.path.includes(path)
    ).map(({ path, ...other }) => ({
      path: Object.keys(match.params).length
        ? Object.keys(match.params).reduce(
            (path, param) => path.replace(`:${param}`, match.params[param]),
            path
          )
        : path,
      ...other
    }))
    return breadcrumbItems
  }

  useEffect(() => {
    setPWA(PWAVariants.DEFAULT)
  }, [])

  useEffect(() => {
    if (userIsGuest || userIsAdminGuest) {
      return
    }

    if (isPartnerAdmin || isSupport) {
      runUnreadItemsCountFetcher(
        '/v1/cases?isRead=false&count=true',
        'unreadCasesCount',
        setUnreadCasesCount,
        selectedOrganisation,
        OrganisationServices.CASES,
        user
      )

      runUnreadItemsCountFetcher(
        '/v1/orders?&isRead=false&count=true',
        'unreadOrdersCount',
        setUnreadOrdersCount,
        selectedOrganisation,
        OrganisationServices.KEY_ORDERS,
        user
      )
    }

    if (isCustomerAdmin) {
      runUnreadItemsCountFetcher(
        '/v1/documents?awaitingApproval&count',
        'pendingDocsCount',
        setPendingDocsCount,
        selectedOrganisation,
        OrganisationServices.DOCUMENTS,
        user
      )
    }
    if (!isSupport) {
      runUnreadItemsCountFetcher(
        'v1/keys/receipts?signed=false&count',
        'pendingKeysCount',
        setPendingKeysCount,
        selectedOrganisation,
        OrganisationServices.KEY_MANAGEMENT,
        user
      )
      runUnreadItemsCountFetcher(
        `v1/keys/inventoryapprovals?count=true`,
        'pendingKeyInventoryApprovalsCount',
        setPendingKeyInventoryApprovalsCount,
        selectedOrganisation,
        OrganisationServices.KEY_MANAGEMENT,
        user
      )
    }
  }, [organisationServices])

  const fetchUserOrganisations = async () => {
    try {
      const { data } = await axios.get('/v1/organisations?self=true')
      setUserOrganisations(data)
      const defaultOrgMatch =
        data && data.some(org => org.id === user.defaultOrganisationId)

      if (data.length > 1) {
        if (!user.defaultOrganisationId || !defaultOrgMatch) {
          setShowChangeOrganisationModal(true)
        } else {
          setSelectedOrganisationId(user.defaultOrganisationId)
        }
      } else {
        setSelectedOrganisationId(data[0].id)
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    fetchUserOrganisations()
  }, [user])

  /* validate user object & token existance */
  const item = items.find(i => window.location.pathname.startsWith(i.path))
  if (
    item?.access &&
    user &&
    !user.roles.some(role => item.access.includes(role))
  ) {
    return <Redirect to='/' />
  }

  const now = new Date().getTime() / 1000
  if (!user || userIsGuest || !token || !expires || now > expires) {
    if (signedOut) {
      return <Redirect to='/login' />
    }
    localStorage.setItem('returnTo', location.pathname)
    return <Redirect to='/login' />
  }

  const hasAccessToKEE = checkIfOrgHasAccessToScope(
    user,
    user.organisation,
    OrganisationServices.KEE
  )
  if (
    window.location.hostname !== 'localhost' &&
    !global.__PDA_REDIRECT_ONCE__ &&
    !global.__PDA_INITIATED__ &&
    isMobile &&
    hasAccessToKEE &&
    !window.location.pathname.includes('/mylocks') &&
    !window.location.pathname.includes('orders')
  ) {
    if (!global.__PDA_INITIATED__) {
      global.__PDA_INITIATED__ = true
      global.__PDA_REDIRECT_ONCE__ = true
      return <Redirect to='/mylocks' />
    }
  }

  const changeOrganisationModal = (
    <ChangeOrganisationModal
      user={user}
      error={error}
      setError={setError}
      isMobile={isMobile}
      organisations={userOrganisations}
      selectedOrganisationId={selectedOrganisationId}
      setSelectedOrganisationId={setSelectedOrganisationId}
      setShowChangeOrganisationModal={setShowChangeOrganisationModal}
    />
  )

  const { component: Component, ...rest } = props

  if (isMobile) {
    return (
      <Route
        {...rest}
        render={matchProps => (
          <MuiPickersUtilsProvider utils={moment}>
            <div className={classes.root}>
              {multipleOrganisationsUser &&
                showChangeOrganisationModal &&
                changeOrganisationModal}
              {orgHasAccessToScope ? (
                <Component
                  id='infiniteScrollElement'
                  fetchUserOrganisations={fetchUserOrganisations}
                  selectedOrganisation={selectedOrganisation}
                  organisationServices={organisationServices}
                  history={history}
                  user={rest.user}
                  signout={signout}
                  unreadCasesCount={unreadCasesCount}
                  unreadOrdersCount={unreadOrdersCount}
                  pendingDocsCount={pendingDocsCount}
                  pendingKeysCount={pendingKeysCount}
                  computedMatch={rest.computedMatch}
                  {...matchProps}
                  toggleDeleteConfirmation={toggleDeleteConfirmation}
                  setShowChangeOrganisationModal={
                    multipleOrganisationsUser
                      ? setShowChangeOrganisationModal
                      : undefined
                  }
                  scope={scope}
                />
              ) : (
                <ServiceDeactivated scope={scope} />
              )}
              <AddToHomescreen />
            </div>
          </MuiPickersUtilsProvider>
        )}
      />
    )
  }
  return (
    <Route
      {...rest}
      render={matchProps => (
        <React.Fragment>
          <div className={classes.root}>
            <div className={classes.menuColumn}>
              <Drawer
                variant='permanent'
                style={{
                  height: '100vh',
                  maxHeight: '100vh',
                  minHeight: '100vh',
                  position: 'fixed',
                  zIndex: 9999,
                  paddingRight: 18,
                  overflowY: 'auto'
                }}
                classes={{
                  paper: classNames(
                    classes.drawerPaper,
                    !isSidebarOpen && classes.drawerPaperClose
                  )
                }}
              >
                <div className={classes.menuButtonContainer}>
                  <IconButton
                    style={{ marginRight: isSidebarOpen ? 13 : 18 }}
                    onClick={handleDrawer}
                  >
                    {isSidebarOpen ? (
                      <Icon style={{ color: '#1a1c1a' }}>close</Icon>
                    ) : (
                      <Icon style={{ color: '#1a1c1a' }}>menu</Icon>
                    )}
                  </IconButton>
                </div>
                <List style={{ paddingTop: 0, position: 'static' }}>
                  {items.map(item => {
                    if (item.type === 'section') {
                      return (
                        <React.Fragment key={`menu-section-${item.name}`}>
                          <Divider />
                        </React.Fragment>
                      )
                    }
                    if (!user.roles.some(role => item.access.includes(role)))
                      return null

                    let selected = false
                    if (
                      computedMatch.path.indexOf('users') > -1 &&
                      computedMatch.path.indexOf('organisations') > -1
                    ) {
                      if (item.path.indexOf('users') > -1) {
                        selected = true
                      }
                    } else {
                      selected = computedMatch.url.startsWith(item.path)
                    }

                    return (
                      <React.Fragment key={`menu-${item.key}`}>
                        <Tooltip
                          title={
                            !isSidebarOpen
                              ? global._(
                                  `DashboardDescriptions.${item.key}.Title`
                                )
                              : ''
                          }
                          placement='right'
                          style={{ zIndex: 10000 }}
                          PopperProps={{
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                  offset: '0, 5px'
                                }
                              }
                            }
                          }}
                        >
                          <ListItem
                            data-cy={`list-item-${item.key}`}
                            button
                            component={Link}
                            to={item.path}
                            onClick={e => openNewPageOnItemClick(e, item.path)}
                            selected={selected}
                            style={{
                              padding: '8px 0',
                              paddingLeft: '24px',
                              backgroundColor: selected
                                ? '#b7e1dd'
                                : 'transparent',
                              borderLeftStyle: 'solid',
                              borderLeftWidth: '5px',
                              borderLeftColor: selected
                                ? '#2cd5c4'
                                : 'transparent',
                              position: 'relative'
                            }}
                          >
                            {item.path === '/dashboard' &&
                            notifications?.serviceRequests?.items?.length ? ( // Show a badge if there are unread cases
                              <UnreadItemsNavBadge
                                unreadItemsCount={
                                  notifications?.serviceRequests?.items?.length
                                }
                              />
                            ) : null}
                            {item.path === '/cases' && unreadCasesCount ? ( // Show a badge if there are unread cases
                              <UnreadItemsNavBadge
                                unreadItemsCount={unreadCasesCount}
                              />
                            ) : null}
                            {item.path === '/orders' && unreadOrdersCount ? ( // Show a badge if there are unread cases
                              <UnreadItemsNavBadge
                                unreadItemsCount={unreadOrdersCount}
                              />
                            ) : null}
                            {item.path === '/documents' && pendingDocsCount ? ( // Show a badge if there are unread cases
                              <UnreadItemsNavBadge
                                unreadItemsCount={pendingDocsCount}
                              />
                            ) : null}
                            {item.path === '/my-keys' &&
                            (pendingKeysCount ||
                              pendingKeyInventoryApprovalsCount) ? ( // Show a badge if there are unread cases
                              <UnreadItemsNavBadge
                                unreadItemsCount={
                                  pendingKeysCount +
                                  pendingKeyInventoryApprovalsCount
                                }
                              />
                            ) : null}
                            <ListItemIcon>
                              {React.cloneElement(item.icon, {
                                style: {
                                  fontSize: 18,
                                  color: selected ? '#1a1c1a' : '#8e8e8e'
                                }
                              })}
                            </ListItemIcon>
                            <strong style={{ overflow: 'hidden' }}>
                              <ListItemText
                                primary={global._(
                                  `DashboardDescriptions.${item.key}.Title`
                                )}
                              />
                            </strong>
                          </ListItem>
                        </Tooltip>
                      </React.Fragment>
                    )
                  })}
                  {logotype && logotype.id && (
                    <div
                      style={{
                        height: 110,
                        width: '100%',
                        display: 'inline-block'
                      }}
                    >
                      <CustomLink to={global._('Header.LogoLinkTo')} blank>
                        <img
                          src='/logos/SidebarLogo.svg'
                          alt='Sidebar logo'
                          width={98}
                          style={{ position: 'absolute', marginTop: 55 }}
                        />
                      </CustomLink>
                    </div>
                  )}
                </List>
              </Drawer>
              <span className={classes.versionLabel}>
                {`v${packageJson.version}`}
              </span>
            </div>
            {/* Contains header and content */}
            <div className={classes.headerAndContentColumn}>
              <Header
                user={user}
                handleLogout={handleSignout}
                handleReverseImpersonate={handleReverseImpersonate}
                history={matchProps.history}
                left={80}
                setShowChangeOrganisationModal={
                  multipleOrganisationsUser
                    ? setShowChangeOrganisationModal
                    : undefined
                }
              />
              <main className={classes.content} id='content-container'>
                <MuiPickersUtilsProvider utils={moment}>
                  {orgHasAccessToScope ? (
                    <Component
                      id='infiniteScrollElement'
                      fetchUserOrganisations={fetchUserOrganisations}
                      selectedOrganisation={selectedOrganisation}
                      organisationServices={organisationServices}
                      signout={handleSignout}
                      computedMatch={computedMatch}
                      user={user}
                      token={token}
                      unreadCasesCount={unreadCasesCount}
                      pendingDocsCount={pendingDocsCount}
                      pendingKeysCount={pendingKeysCount}
                      {...matchProps}
                      toggleDeleteConfirmation={toggleDeleteConfirmation}
                      breadcrumbItems={breadcrumbs(computedMatch)}
                      scope={scope}
                    />
                  ) : (
                    <ServiceDeactivated scope={scope} />
                  )}
                </MuiPickersUtilsProvider>
              </main>
            </div>
            {showDeleteConfirmation && (
              <DeleteModal
                onConfirm={remove}
                isOpen={showDeleteConfirmation}
                title='Bekräfta'
                text={deletePayload && deletePayload.content}
                onCancel={toggleDeleteConfirmation}
                isLoadingSubmit={isLoadingRemove}
                requireInput={
                  deletePayload &&
                  deletePayload.deleteConfirmationObject &&
                  deletePayload.deleteConfirmationObject.organisationNumber
                }
                handleDeleteInput={handleDeleteInput}
                deleteInput={deletePayload && deletePayload.input}
              />
            )}
            {showChangeOrganisationModal &&
              multipleOrganisationsUser &&
              changeOrganisationModal}
          </div>
        </React.Fragment>
      )}
    />
  )
}

PrivateLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  computedMatch: PropTypes.object.isRequired,
  deletePayload: PropTypes.object,
  component: PropTypes.elementType,
  expires: PropTypes.number,
  handleDeleteInput: PropTypes.func.isRequired,
  handleReverseImpersonate: PropTypes.func.isRequired,
  isLoadingRemove: PropTypes.bool,
  isSidebarOpen: PropTypes.bool,
  location: PropTypes.object.isRequired,
  remove: PropTypes.func,
  showDeleteConfirmation: PropTypes.bool,
  signout: PropTypes.func.isRequired,
  token: PropTypes.string,
  toggleDeleteConfirmation: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func,
  user: PropTypes.object
}

PrivateLayout.defaultProps = {
  deletePayload: {},
  component: null,
  expires: 0,
  isLoadingRemove: false,
  isSidebarOpen: false,
  remove: null,
  showDeleteConfirmation: false,
  toggleDrawer: null,
  token: '',
  user: null
}

export default withStyles(styles)(PrivateLayout)
