import React, { useState, useEffect } from 'react'
import { FormControl, MenuItem, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  BlueprintItemTypes,
  BlueprintObjectItemTypes,
  ItemAttributes
} from '../../../utils/constants'
import AttributeField from './AttributeField'
import Notification from '../../../common/Notification'
import Button from '../../Button'
import Modal from '../../Modal'
import FullscreenModal from '../../FullscreenModal'

const ItemModal = props => {
  const {
    object,
    item,
    onClose,
    onCreateItem,
    blueprintId,
    onUpdateItem,
    onDeleteItem,
    fullscreen
  } = props
  const userCustomization = JSON.parse(
    localStorage.getItem('user-customization')
  ) || { blueprintToolItemModalType: BlueprintItemTypes.Cylinder }
  if (!userCustomization.blueprintToolItemModalType) {
    userCustomization.blueprintToolItemModalType = BlueprintItemTypes.Cylinder
  }
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState(userCustomization.blueprintToolItemModalType)
  const [attributes, setAttributes] = useState({})

  const itemAttributeFields = ItemAttributes[type]

  useEffect(() => {
    if (item) {
      setType(item.type || userCustomization.blueprintToolItemModalType)
      const mappedAttributes = {}
      if (item.attributes) {
        item.attributes.forEach(attribute => {
          mappedAttributes[attribute.key] = attribute.value
        })
      }
      setAttributes(mappedAttributes)
    }
  }, [item])

  const onCreateOrUpdateItem = async () => {
    setError(null)
    setIsLoading(true)
    try {
      if (!type)
        throw new Error('BlueprintTool.ItemModal.Errors.TypeIsRequired')
      const data = {
        type,
        attributes
      }

      itemAttributeFields.forEach(field => {
        if (
          field.required &&
          !Object.prototype.hasOwnProperty.call(attributes, field.key)
        ) {
          throw new Error(`AttributeFields.Required.${field.key}`)
        }
      })

      if (item.id) {
        delete data.type
        const { data: updatedItem } = await axios.patch(
          `/v1/blueprints/${blueprintId}/objects/${object.id}/items/${item.id}`,
          data
        )
        onUpdateItem({
          ...item,
          ...updatedItem
        })
      } else {
        const { data: createdItem } = await axios.post(
          `/v1/blueprints/${blueprintId}/objects/${object.id}/items`,
          data
        )
        onCreateItem(createdItem)
      }
    } catch (e) {
      setIsLoading(false)
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const availableTypes = BlueprintObjectItemTypes[object.type]
  const itemTypeOptions = availableTypes.map(aType => ({
    label: global._(`BlueprintItemTypes.${aType}`),
    value: aType
  }))

  const Wrapper = fullscreen ? FullscreenModal : Modal

  let saveDisabled = false
  itemAttributeFields.forEach(field => {
    if (
      field.required &&
      !Object.prototype.hasOwnProperty.call(attributes, field.key)
    ) {
      saveDisabled = true
    }
  })
  return (
    <Wrapper
      onClose={onClose}
      title={
        item.id
          ? global._('BlueprintTool.ItemModal.TitleEdit')
          : global._('BlueprintTool.ItemModal.TitleNew')
      }
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: fullscreen ? 20 : undefined
          }}>
          <FormControl fullWidth style={{ paddingBottom: '16px' }}>
            <TextField
              data-cy='select-item-type'
              select
              name='item-type-select'
              variant='filled'
              disabled={typeof item.id !== 'undefined'}
              label={global._('BlueprintTool.ItemModal.ItemTypeLabel')}
              helperText={!item.id && global._('Common.Required')}
              onChange={event => {
                userCustomization.blueprintToolItemModalType =
                  event.target.value
                localStorage.setItem(
                  'user-customization',
                  JSON.stringify(userCustomization)
                )
                setType(event.target.value)
              }}
              value={type}>
              {itemTypeOptions.map(elem => (
                <MenuItem
                  key={`item-type-${elem.value}-option`}
                  value={elem.value}>
                  {elem.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          {itemAttributeFields &&
            itemAttributeFields.map(field => (
              <div
                key={`item-attribute-field-${field.key}`}
                style={{ width: '100%', padding: '16px 0' }}>
                <AttributeField
                  field={field}
                  value={attributes[field.key] || ''}
                  onChange={value =>
                    setAttributes({
                      ...attributes,
                      [field.key]:
                        typeof value === 'object' ? value.value : value
                    })
                  }
                />
              </div>
            ))}
          {!fullscreen && error && (
            <Notification
              style={{ marginTop: 20 }}
              type='error'
              message={error}
            />
          )}
        </div>
      }
      buttons={[
        !fullscreen && (
          <Button
            key='item-modal-btn-1'
            variant='none'
            onClick={onClose}
            style={{ marginRight: item.id ? 'auto' : 10 }}>
            {global._('Common.Cancel')}
          </Button>
        ),
        fullscreen && error && (
          <Notification
            key='item-modal-error'
            style={{ marginBottom: 20 }}
            type='error'
            message={error}
          />
        ),
        item.id && (
          <Button
            key='item-modal-btn-3'
            variant='outlined'
            onClick={onDeleteItem(item)}
            style={{
              marginRight: fullscreen ? undefined : 15,
              marginBottom: fullscreen ? 20 : undefined
            }}>
            {global._('BlueprintTool.ItemModal.DeleteObject')}
          </Button>
        ),
        <Button
          data-cy='button-add-accessory'
          key='item-modal-btn-2'
          variant='primary'
          disabled={saveDisabled}
          loading={isLoading}
          onClick={onCreateOrUpdateItem}>
          {item.id ? global._('Common.Save') : global._('Common.Add')}
        </Button>
      ]}
    />
  )
}

ItemModal.propTypes = {
  object: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateItem: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  blueprintId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  fullscreen: PropTypes.bool
}

ItemModal.defaultProps = {
  fullscreen: false
}

export default ItemModal
