import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import * as PdfJsLib from 'pdfjs-dist'

const Pdf = forwardRef((props, ref) => {
  const {
    src,
    token,
    containerRef,
    onUpdate,
    zoom,
    delta,
    mobile,
    rotationDegrees
  } = props
  const [page, setPage] = useState(null)
  const [resized, setResized] = useState({})

  // Resize trigger delta so that we don't constantly re-render pdf on resize
  useEffect(() => {
    let timeout
    const resizeEnd = () => {
      setResized({})
    }
    const resizeHandler = () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(resizeEnd, 250)
    }
    window.addEventListener('resize', resizeHandler, true)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  useEffect(() => {
    if (!containerRef.current || !ref.current || !page) {
      return
    }

    ;(async () => {
      try {
        const { current: canvas } = ref
        const { current: container } = containerRef
        const desiredWidth = container.offsetWidth
        const desiredHeight = container.offsetHeight
        const viewport = page.getViewport({ scale: 1 })
        let scale = desiredWidth / viewport.width
        if (viewport.height * scale > desiredHeight) {
          scale = desiredHeight / viewport.height
        }

        const scaledViewportWithoutZoom = page.getViewport({ scale })
        const scaledViewport = page.getViewport({ scale: scale * zoom })
        const context = canvas.getContext('2d')
        canvas.height = scaledViewport.height
        canvas.width = scaledViewport.width

        const marginLeft =
          (scaledViewportWithoutZoom.width - scaledViewport.width) / 2.0
        const marginTop =
          (scaledViewportWithoutZoom.height - scaledViewport.height) / 2.0
        canvas.style.marginLeft = `${marginLeft}px`
        canvas.style.marginTop = `${marginTop}px`

        const renderContext = {
          canvasContext: context,
          viewport: scaledViewport
        }
        await page.render(renderContext).promise
        // Call parent to re-render objects
        if (typeof onUpdate === 'function') {
          onUpdate(viewport.width, viewport.height)
        }
      } catch (e) {
        /* eslint-disable-next-line */
        console.error('Failed to render PDF', e)
      }
    })()
  }, [page, ref.current, containerRef, resized, zoom])

  const load = async () => {
    PdfJsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'
    const pdfDocument = await PdfJsLib.getDocument({
      url: src,
      httpHeaders: {
        Authorization: `Bearer ${token}`
      }
    }).promise
    const pdfPage = await pdfDocument.getPage(1)
    setPage(pdfPage)
  }

  useEffect(() => {
    load()
  }, [])

  if (mobile) {
    return (
      <canvas
        ref={ref}
        style={{
          transform: `translate3d(${delta.x}px, ${delta.y}px, 0)`,
          transformOrigin: '0 0'
        }}
      />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        alignItems: 'center',
        marginLeft: delta.x,
        marginTop: delta.y,
        transform: `rotate(${rotationDegrees}deg)`
      }}>
      <canvas ref={ref} />
    </div>
  )
})

Pdf.propTypes = {
  src: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
  delta: PropTypes.object.isRequired,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  rotationDegrees: PropTypes.number.isRequired
}

Pdf.defaultProps = {
  containerRef: null
}

export default Pdf
