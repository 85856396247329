import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { connect } from 'react-redux'
import Typography from '../../components/Typography'
import Row from '../../components/Row'
import Modal from '../../components/Modal'
import FullscreenModal from '../../components/FullscreenModal'
import Button from '../../components/Button'
import RadioButton from '../../components/RadioButton'
import RadioButtonGroup from '../../components/RadioButtonGroup'
import Notification from '../../common/Notification'
import { setAuthenticationData } from '../../redux/reducers/login'
import { history } from '../../redux/store'

const styles = {
  root: {},
  modalInfoSection: {
    padding: 20,
    height: 70,
    borderBottom: '1px solid var(--color-divider-light)'
  }
}

const ChangeOrganisationModal = ({
  user,
  classes,
  isMobile,
  organisations,
  selectedOrganisationId,
  setSelectedOrganisationId,
  setShowChangeOrganisationModal,
  error,
  setError,
  dispatch
}) => {
  const Wrapper = isMobile ? FullscreenModal : Modal

  const [internalSelectedOrgId, setInternalSelectedOrgId] = useState(
    selectedOrganisationId
  )

  const handleUpdate = async () => {
    try {
      setError(null)

      await axios.patch(
        `/v1/organisations/${user.organisation.id}/users/${user.id}`,
        { defaultOrganisationId: internalSelectedOrgId }
      )
      const { data: authData } = await axios.get('/v1/auth/refresh')
      dispatch(setAuthenticationData(authData))
      setShowChangeOrganisationModal(false)

      setSelectedOrganisationId(internalSelectedOrgId)

      history.push('/')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  return (
    <Wrapper
      noContentPadding
      boxShadow='none'
      rightActionItem='close'
      title={global._('Common.ChangeOrganisation')}
      content={
        <>
          {error && (
            <Notification
              type='error'
              message={error}
              style={{ margin: '20px 0' }}
            />
          )}
          <Row className={classes.modalInfoSection}>
            <Typography variant='body' bold>
              {global._('Common.ChangeOrganisationInfo')}
            </Typography>
          </Row>
          <RadioButtonGroup
            value={internalSelectedOrgId}
            onChange={setInternalSelectedOrgId}
          >
            {organisations.map(org => (
              <RadioButton
                key={org.id}
                value={org.id}
                title={org.name}
                subtitle={org.organisationNumber}
              />
            ))}
          </RadioButtonGroup>
        </>
      }
      buttons={
        <>
          {!isMobile && (
            <Button
              key='change-organisation-modal-btn-cancel'
              variant='none'
              onClick={() => setShowChangeOrganisationModal(false)}
            >
              {global._('Common.Cancel')}
            </Button>
          )}
          <Button
            key='change-organisation-modal-btn-submit'
            variant='primary'
            onClick={() => handleUpdate()}
            disabled={!internalSelectedOrgId}
          >
            {global._('Common.Choose')}
          </Button>
        </>
      }
      onClose={() => {
        setShowChangeOrganisationModal(false)
      }}
    />
  )
}

ChangeOrganisationModal.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  organisations: PropTypes.array.isRequired,
  setSelectedOrganisationId: PropTypes.func.isRequired,
  setShowChangeOrganisationModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func,
  selectedOrganisationId: PropTypes.number
}

ChangeOrganisationModal.defaultProps = {
  selectedOrganisationId: null,
  error: null,
  setError: null
}

const ChangeOrganisationModalWithStyles = injectSheet(styles)(
  ChangeOrganisationModal
)
const ConnectedOrganisationModalWithStyles = connect()(
  ChangeOrganisationModalWithStyles
)
export default ConnectedOrganisationModalWithStyles
