import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import IconColumn from 'src/components/IconColumn'
import { ImportStorageKeys } from 'src/utils/constants'
import axios from 'axios'
import _ from 'lodash'
import ImportPreview from 'src/components/ImportPreview'
import Typography from 'src/components/Typography'

const styles = {}

const ApartmentImportPreview = ({ history, breadcrumbItems }) => {
  const [items, setItems] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)

  const handleImport = async () => {
    setIsLoading(true)
    try {
      const data = items
        .filter(u => u.errors.length === 0)
        .map(apartment => ({
          number: apartment.number,
          address: apartment.address,
          additionalInformation: apartment.additionalInformation,
          organisationId: apartment.organisationId
        }))

      if (!data.length) {
        return setIsLoading(false)
      }

      await axios.post(
        `/v1/organisations/${data[0].organisationId}/apartments/import`,
        data
      )

      /* Show success message when returning to main view */
      localStorage.setItem('apartments.import.success', '1')

      /* Remove data used for this preview */
      localStorage.removeItem(ImportStorageKeys.APARTMENTS)

      history.push('/admin/apartments')
    } catch (e) {
      setIsLoading(false)
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const validate = async (apartments, validationData) => {
    try {
      const { data: existingApartments } = await axios.post(
        `/v1/organisations/${validationData.organisationId}/apartments/import/validate`,
        validationData
      )

      /* Transform excel values to preview items in table */
      const apartmentsArr = []

      apartments.forEach(apartment => {
        const errors = []

        const existingApartment =
          existingApartments.length > 0 &&
          existingApartments.find(
            a =>
              a.address === apartment.address && a.number === apartment.number
          )
        const existingApartmentInFile = apartmentsArr.find(
          a => a.address === apartment.address && a.number === apartment.number
        )

        const { number, address, additionalInformation } = apartment

        if (!number) {
          errors.push('Import.Apartments.Preview.Errors.EmptyApartmentNumber')
        } else if (!address) {
          errors.push('Import.Apartments.Preview.Errors.EmptyAddress')
        } else if (existingApartment) {
          errors.push('Import.Apartments.Preview.Errors.ExistingApartment')
        } else if (existingApartmentInFile) {
          errors.push(
            'Import.Apartments.Preview.Errors.DuplicateApartmentInFile'
          )
        }

        apartmentsArr.push({
          number: number || '-',
          address: address || '-',
          additionalInformation: additionalInformation || '-',
          organisationId: validationData.organisationId,
          organisationName: validationData.organisationName,
          errors
        })
      })

      setItems(apartmentsArr)
    } catch (e) {
      setError(e.message)
    }
  }

  useEffect(() => {
    ;(async () => {
      const previewData = JSON.parse(
        localStorage.getItem(ImportStorageKeys.APARTMENTS)
      )

      const validationData = {
        organisationId: previewData.organisation.id,
        organisationName: previewData.organisation.name,
        apartments: []
      }

      previewData.previewItems.forEach(apartment => {
        if (apartment.number && apartment.address) {
          validationData.apartments.push({
            number: apartment.number,
            address: apartment.address
          })
        }
      })

      validationData.apartments = _.uniqWith(
        validationData.apartments,
        _.isEqual
      )

      await validate(previewData.previewItems, validationData)
    })()
  }, [])

  return (
    <>
      <ImportPreview
        locKey='Apartments'
        items={items}
        error={error}
        setError={setError}
        handleImport={handleImport}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        breadcrumbItems={breadcrumbItems}
        headerIcon='location_city'
        columns={[
          {
            key: 'icon',
            sortingDisabled: true,
            style: { width: 60 },
            format: (objVal, obj) =>
              obj && (
                <IconColumn
                  icon='location_city'
                  style={{
                    color:
                      obj.errors.length > 0
                        ? 'var(--color-error-red)'
                        : undefined /* turn icon red when type is invalid */,
                    backgroundColor:
                      obj.errors.length > 0
                        ? 'var(--color-error-red-bg)'
                        : undefined /* turn icon red when type is invalid */
                  }}
                />
              )
          },
          {
            key: 'number',
            localizationKey: 'Widgets.apartments.Attributes.shyNumber',
            sortingDisabled: true,
            style: { width: 130 },
            format: (number, item) => {
              const relatedErrors = [
                'Import.Apartments.Preview.Errors.EmptyApartmentNumber',
                'Import.Apartments.Preview.Errors.ExistingApartment'
              ]
              if (
                item.errors &&
                item.errors.some(err => relatedErrors.includes(err))
              ) {
                return (
                  <Typography bold style={{ color: 'var(--color-error-red)' }}>
                    {number || '-'}
                  </Typography>
                )
              }
              return <Typography>{number || '-'}</Typography>
            }
          },
          {
            key: 'address',
            localizationKey: 'Widgets.apartments.Attributes.address',
            sortingDisabled: true,
            style: { width: '15%' },
            format: (address, item) => {
              const relatedErrors = [
                'Import.Apartments.Preview.Errors.EmptyAddress',
                'Import.Apartments.Preview.Errors.ExistingApartment'
              ]
              if (
                item.errors &&
                item.errors.some(err => relatedErrors.includes(err))
              ) {
                return (
                  <Typography bold style={{ color: 'var(--color-error-red)' }}>
                    {address || '-'}
                  </Typography>
                )
              }
              return <Typography>{address || '-'}</Typography>
            }
          },
          {
            key: 'additionalInformation',
            localizationKey:
              'Widgets.apartments.Attributes.additionalInformation',
            sortingDisabled: true,
            longText: true,
            style: { width: '20%' }
          },
          {
            key: 'organisationName',
            localizationKey: 'Widgets.users.Attributes.organisation',
            clickable: true,
            sortingDisabled: true,
            style: { width: '20%' }
          }
        ]}
      />
    </>
  )
}

ApartmentImportPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired
}

ApartmentImportPreview.defaultProps = {}

const ApartmentImportPreviewWithStyles = injectSheet(styles)(
  ApartmentImportPreview
)
export default ApartmentImportPreviewWithStyles
