import { useState } from 'react'

export const usePersistState = <T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(() => {
    const persistedStateStringified = localStorage.getItem(key)

    if (persistedStateStringified === null) {
      return defaultValue
    }

    try {
      const persistedState = JSON.parse(persistedStateStringified)
      return persistedState
    } catch (e) {
      return defaultValue
    }
  })

  const setPersistedState = (value: T) => {
    localStorage.setItem(key, JSON.stringify(value))
    setState(value)
  }

  return [state, setPersistedState]
}
