import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import moment from 'moment'
import queryString from 'querystring'
import PageContentHeader from '../../components/PageContentHeader'
import Button from '../../components/Button'
import ObjectList from '../../components/ObjectList'
import {
  DocumentStatus,
  InstructionsVideoIds,
  UserRoles
} from '../../utils/constants'
import { mapDocumentStatus } from '../../utils/helpers'
import Modal from '../../components/Modal'
import Typography from '../../components/Typography'
import Notification from '../../common/Notification'
import Filter from './Filter'
import AddDocumentModal from './AddDocumentModal'
import useWindowDimensions from '../../utils/useWindowDimensions'
import DocumentsMobile from './DocumentsMobile'
import SuccessMessage from '../../components/SuccessMessage'

const styles = {
  root: {},
  input: {
    width: '100%'
  }
}

const Documents = ({
  classes,
  breadcrumbItems,
  history,
  user,
  signout,
  location
}) => {
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false)
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false)
  const [canFetchMoreDocuments, setCanFetchMoreDocuments] = useState(false)
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false)
  const [documents, setDocuments] = useState([])
  const [error, setError] = useState(null)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [expandedRows, setExpandedRows] = useState([])
  const [currentDocumentID, setCurrentDocumentID] = useState(null)

  const urlParams = new URLSearchParams(location.search)

  const [filter, setFilter] = useState({
    orderBy: 'name',
    order: 'asc',
    query: urlParams.get('query') || '',
    organisationId: urlParams.get('organisationId') || '',
    type: urlParams.get('type') || '',
    limit: 25,
    offset: 0
  })
  const [activeFilter, setActiveFilter] = useState(false)

  const token = localStorage.getItem('token')
  const isCustomerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.CustomerAdmin)
  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)

  const fetchDocuments = async () => {
    setIsLoadingDocuments(true)
    try {
      const selectedFilter = { ...filter }

      selectedFilter.organisation = ''

      const query = queryString.stringify(selectedFilter)

      const { data } = await axios.get(`/v1/documents?${query}`)

      setCanFetchMoreDocuments(data.length === filter.limit)

      if (data) {
        if (filter.offset === 0) {
          setDocuments(data)
        } else {
          setDocuments([...documents, ...data])
        }
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoadingDocuments(false)
  }

  useEffect(() => {
    fetchDocuments()
  }, [filter])

  const fetchMoreDocuments = () => {
    const offset = filter.offset + filter.limit
    const updatedFilter = { ...filter, offset }

    setIsLoadingDocuments(true)

    setFilter(updatedFilter)
    setActiveFilter(true)
  }

  const updateApprovalStatus = async (doc, isApproved) => {
    try {
      await axios.patch(`/v1/documents/${doc.id}/approve`, {
        isApproved,
        fileId: doc.fileId
      })

      const tempDocs = documents.map(tempDoc => {
        if (tempDoc.id === doc.id) {
          const tempDocObj = { ...tempDoc }
          tempDocObj.isApproved = isApproved
          tempDocObj.status = isApproved
            ? DocumentStatus.Accepted
            : DocumentStatus.Rejected

          return tempDocObj
        }

        return tempDoc
      })

      setDocuments(tempDocs)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const deleteDocument = async docID => {
    try {
      await axios.delete(`/v1/documents/${docID}`)

      setCurrentDocumentID(null)

      const tempDocs = documents.filter(doc => doc.id !== docID)

      setDocuments(tempDocs)
      setShowDeleteDocumentModal(false)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleFilter = name => e => {
    const aFilter = { ...filter }

    if (name === 'organisation') {
      aFilter.organisationId = e && e.value ? e.value.id : ''
      aFilter.organisation = e
    } else {
      const { value } = e.target

      aFilter[name] = value
    }

    aFilter.offset = 0

    setFilter(aFilter)
    setActiveFilter(true)
  }

  const actionButtons = (objectID, obj) => (
    <>
      {isPartnerAdmin && (
        <Button
          variant='outlined'
          style={{
            marginRight: 20
          }}
          onClick={() => {
            setCurrentDocumentID(objectID)
            setShowDeleteDocumentModal(true)
          }}
        >
          {global._('Delete')}
        </Button>
      )}
      <Button
        data-cy='button-download-document'
        variant='outlined'
        onClick={() =>
          window.open(
            `${axios.defaults.baseURL}/v1/documents/${objectID}/files?token=${token}`
          )
        }
        style={{
          marginRight: 20
        }}
      >
        {global._('Common.Download')}
      </Button>
      {obj.status === DocumentStatus.AwaitingApproval && isCustomerAdmin && (
        <>
          <Button
            variant='secondary'
            onClick={() => updateApprovalStatus(obj, false)}
            style={{
              marginRight: 20
            }}
          >
            {global._('Documents.Reject')}
          </Button>
          <Button
            data-cy='table-button-accept-document'
            variant='primary'
            onClick={() => updateApprovalStatus(obj, true)}
            style={{
              marginRight: 20
            }}
          >
            {global._('Documents.Accept')}
          </Button>
        </>
      )}
    </>
  )

  const columns = [
    {
      key: 'name',
      localizationKey: 'Name',
      clickable: true,
      longText: true
    },
    {
      key: 'type',
      localizationKey: 'Type',
      style: { width: '20%' },
      clickable: true,
      format: type => global._(`Document.Types.${type}`),
      sortingDisabled: true
    },
    {
      key: 'isPublic',
      localizationKey: 'Common.Visibility',
      sortingDisabled: true,
      hiddenFor: !isPartnerAdmin && !isSupport && !isCustomerAdmin,
      longText: true,
      format: isPublic =>
        global._(`Documents.${isPublic ? 'VisibleForAll' : 'VisibleForAdmin'}`)
    },
    {
      key: 'organisation',
      localizationKey: 'Common.Company',
      clickable: true,
      sortingKey: 'organisation.name',
      valueKey: 'name',
      hiddenFor: !isPartnerAdmin && !isSupport,
      longText: true
    },
    {
      key: 'status',
      localizationKey: 'Common.Status',
      clickable: true,
      style: { width: '20%' },
      format: status =>
        status && (
          <span className={`status ${mapDocumentStatus(status)}`}>
            {global._(`Document.Statuses.${status}`)}
          </span>
        ),
      hiddenFor: !isPartnerAdmin && !isCustomerAdmin
    },
    {
      key: 'createdAt',
      localizationKey: 'Documents.UploadedAt',
      clickable: true,
      format: date => moment(date).calendar(),
      style: { width: '20%' }
    }
  ]

  const handleUpload = async (values, resetForm) => {
    const aDocument = { ...values }

    aDocument.organisationId =
      aDocument.organisation.id || aDocument.organisation.value.id

    try {
      setShowSuccessMessage(false)

      await axios.post('/v1/documents', aDocument)

      setShowAddDocumentModal(false)

      setShowSuccessMessage(true)

      resetForm({})

      fetchDocuments()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  let instructionsVideoId = null

  const lang = global.getLanguage()

  if (isPartnerAdmin) {
    instructionsVideoId = InstructionsVideoIds[lang].DOCUMENTS.PARTNER_ADMIN
  } else if (isCustomerAdmin) {
    instructionsVideoId = InstructionsVideoIds[lang].DOCUMENTS.CUSTOMER_ADMIN
  }

  const { isMobile } = useWindowDimensions()

  if (isMobile) {
    return (
      <>
        {showAddDocumentModal && (isPartnerAdmin || isCustomerAdmin) && (
          <AddDocumentModal
            setShowAddDocumentModal={setShowAddDocumentModal}
            handleUpload={handleUpload}
            isMobile={isMobile}
            showAddDocumentModal={showAddDocumentModal}
          />
        )}
        {showSuccessMessage ? (
          <SuccessMessage subtitle={global._('Documents.DocumentAdded')} />
        ) : null}
        <DocumentsMobile
          documents={documents}
          history={history}
          user={user}
          signout={signout}
          error={error}
          isMobile={isMobile}
          setError={setError}
          handleFilter={handleFilter}
          instructionsVideoId={instructionsVideoId}
          filter={filter}
          canFetchMoreDocuments={canFetchMoreDocuments}
          fetchMoreDocuments={fetchMoreDocuments}
          isLoadingDocuments={isLoadingDocuments}
          setShowAddDocumentModal={setShowAddDocumentModal}
          noResultsMessage={
            activeFilter
              ? global._('Documents.NoDocumentsMatchFilter')
              : global._('Documents.NoDocumentsFound')
          }
          isPartnerAdmin={isPartnerAdmin}
          isSupport={isSupport}
          isCustomerAdmin={isCustomerAdmin}
        />
      </>
    )
  }

  return (
    <div className={classes.root}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      {showSuccessMessage ? (
        <SuccessMessage subtitle={global._('Documents.DocumentAdded')} />
      ) : null}
      {showAddDocumentModal && (isPartnerAdmin || isCustomerAdmin) && (
        <AddDocumentModal
          setShowAddDocumentModal={setShowAddDocumentModal}
          handleUpload={handleUpload}
          isMobile={isMobile}
          showAddDocumentModal={showAddDocumentModal}
        />
      )}
      {showDeleteDocumentModal && (
        <Modal
          onClose={() => setShowDeleteDocumentModal(false)}
          title={global._('Documents.DeleteDocument')}
          content={
            <div style={{ textAlign: 'left' }}>
              <Typography variant='body' block style={{ marginBottom: 15 }}>
                {global._('Documents.DeleteDocumentModalConfirmText')}
              </Typography>
              <Typography
                variant='body'
                bold
                block
                style={{ marginBottom: 15 }}
              >
                {currentDocumentID &&
                  documents.length > 0 &&
                  documents.find(doc => doc.id === currentDocumentID).name}
              </Typography>
              <Typography
                variant='body'
                italic
                block
                style={{ marginBottom: 30 }}
              >
                {global._('Documents.DeleteDocumentModalInfo')}
              </Typography>
            </div>
          }
          buttons={[
            <Button
              variant='none'
              key='show-delete-document-modal'
              onClick={() => setShowDeleteDocumentModal(false)}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              variant='secondary'
              key='delete-document-modal'
              onClick={() => deleteDocument(currentDocumentID)}
            >
              {global._('Delete')}
            </Button>
          ]}
        />
      )}
      <PageContentHeader
        breadcrumbItems={breadcrumbItems}
        actionButtons={
          (isPartnerAdmin || isCustomerAdmin) && (
            <Button
              data-cy='button-upload-document'
              variant='primary'
              onClick={() => setShowAddDocumentModal(true)}
              style={{ marginLeft: 'auto' }}
            >
              {global._('Documents.AddDocument')}
            </Button>
          )
        }
      />
      <Filter
        user={user}
        setError={setError}
        handleFilter={handleFilter}
        filter={filter}
        isMobile={isMobile}
      />
      <ObjectList
        sectionTitle={global._('Documents.Document')}
        infoDialogTitle={global._('Properties.InformationDialogTitle')}
        infoDialogContent={
          <div style={{ textAlign: 'left' }}>
            <Typography
              style={{ display: 'block', paddingBottom: 12 }}
              variant='body'
            >
              {isPartnerAdmin
                ? global._('Documents.PartnerAdminInfoDialogContent')
                : [
                    isCustomerAdmin
                      ? global._('Documents.CustomerAdminInfoDialogContent')
                      : global._('Documents.CustomerInfoDialogContent')
                  ]}
            </Typography>
          </div>
        }
        instructionsVideoId={instructionsVideoId}
        history={history}
        columns={columns}
        objects={documents}
        expandable
        noResultsMessage={
          activeFilter
            ? global._('Documents.NoDocumentsMatchFilter')
            : global._('Documents.NoDocumentsFound')
        }
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        actionButtons={actionButtons}
        handleFilter={handleFilter}
        setFilter={setFilter}
        filter={filter}
        fetchMoreObjects={fetchMoreDocuments}
        canFetchMoreObjects={canFetchMoreDocuments}
        isLoadingObjects={isLoadingDocuments}
      />
    </div>
  )
}

Documents.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  signout: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

Documents.defaultProps = {
  breadcrumbItems: [],
  user: null
}

const DocumentsWithStyles = injectSheet(styles)(Documents)
export default DocumentsWithStyles
