import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon, IconButton, TextField } from '@material-ui/core'
import Typography from '../../../components/Typography'
import { formatDate } from '../../../utils/helpers'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Row from '../../../components/Row'
import { UserRoles } from '../../../utils/constants'
import NoResultsMessage from '../../../components/NoResultsMessage'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import FullscreenModal from '../../../components/FullscreenModal'
import Modal from '../../../components/Modal'

const styles = {
  comment: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid #8E8E8E',
    borderRadius: 3,
    margin: '20px 0'
  },
  commentIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    minWidth: 30,
    height: 30,
    borderRadius: 500,
    marginRight: 10,
    backgroundColor: 'var(--color-material-grey)',
    color: 'var(--color-middle-grey)'
  },
  newIcon: {
    color: 'var(--color-black)',
    backgroundColor: 'var(--color-primary-tone-3)'
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    marginTop: 4
  },
  commentHeaderText: {},
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  divider: {
    height: 1,
    width: '100%',
    opacity: 0.12,
    backgroundColor: '#000000'
  },
  commentBody: {
    minHeight: 55,
    display: 'flex',
    padding: '15px 10px'
  },
  btnContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    right: 0
  },
  itemBtn: {
    top: 14,
    width: 24,
    height: 24
  },
  bottomBtnContainer: {
    marginTop: 20,
    marginBottom: 20
  },
  lastItem: {
    marginBottom: 20
  },
  '@media (max-width: 900px)': {
    comment: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      border: '1px solid #8E8E8E',
      borderRadius: 3,
      margin: '40px 0'
    },
    headerContent: {
      display: 'flex',
      flexDirection: 'column',
      width: 'initial'
    },
    bottomBtnContainer: {
      position: 'fixed',
      bottom: 90,
      left: 'calc(50% - 26px)'
    },
    lastItem: {
      marginBottom: 50
    },
    commentHeaderText: {
      display: 'block',
      width: '100%'
    }
  }
}

const Comment = props => {
  const {
    user,
    classes,
    items,
    isCaseNotes,
    btnText,
    helpText,
    btnVariant,
    onDeleteItem,
    onEditItem,
    onCreateItem,
    setCommentModalIsOpen
  } = props

  const { isMobile } = useWindowDimensions()
  const isAdmin =
    user &&
    Array.isArray(user.roles) &&
    (user.roles.includes(UserRoles.PartnerAdmin) ||
      user.roles.includes(UserRoles.Support))
  const userIsGuest =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Guest)
  const [caseItem, setCaseItem] = useState()
  const [itemHover, setItemHover] = useState(null)
  const [editingItem, setEditingItem] = useState(null)
  const [itemText, setItemText] = useState(null)
  const [showAddingItem, setShowAddingItem] = useState(false)
  const [itemMarkedForDelete, setItemMarkedForDelete] = useState(null)

  const handleCreateItem = () => {
    onCreateItem(caseItem)
    setCaseItem('')
    if (isMobile) {
      setCommentModalIsOpen(false)
      setShowAddingItem(false)
    }
  }

  const handleEditItem = () => {
    const newItem = { ...editingItem, message: itemText }
    onEditItem(newItem)
    if (isMobile) {
      setCommentModalIsOpen(false)
    }
    setEditingItem(null)
    setItemText('')
  }

  const handleDeleteItem = item => {
    onDeleteItem(item)
  }

  const deleteIcon = item => (
    <IconButton
      onClick={() => setItemMarkedForDelete(item)}
      style={{ padding: 6, margin: '-6px 0' }}
    >
      <Icon style={{ color: 'black' }}>delete</Icon>
    </IconButton>
  )

  if (isMobile && (showAddingItem || editingItem)) {
    return (
      <FullscreenModal
        withTabBar
        onClose={
          editingItem
            ? () => {
                setEditingItem(null)
                setCommentModalIsOpen(false)
              }
            : null
        }
        title={
          editingItem
            ? `Redigera ${isCaseNotes ? 'notering' : 'kommentar'}`
            : `Ny ${isCaseNotes ? 'notering' : 'kommentar'}`
        }
        rightActionItem={
          editingItem &&
          itemHover &&
          (isAdmin || editingItem.userId === user.id) ? (
            <div
              onClick={() => {
                setItemMarkedForDelete(editingItem)
                setCommentModalIsOpen(false)
                setEditingItem(null)
              }}
            >
              <Icon>delete</Icon>
            </div>
          ) : (
            <div
              onClick={() => {
                setCommentModalIsOpen(false)
                setEditingItem(null)
                setShowAddingItem(false)
              }}
            >
              <Icon>close</Icon>
            </div>
          )
        }
        content={
          <div style={{ padding: 20 }}>
            <TextField
              disabled={editingItem && editingItem.userId !== user.id}
              autoFocus={!!showAddingItem}
              style={{ width: '100%' }}
              variant='filled'
              placeholder={
                isCaseNotes
                  ? global._('Case.AddCaseNoteHelpText')
                  : global._('Case.AddCommentHelpText')
              }
              rows={isCaseNotes ? 4 : 10}
              multiline
              value={editingItem ? itemText : caseItem}
              onChange={e =>
                editingItem
                  ? setItemText(e.target.value)
                  : setCaseItem(e.target.value)
              }
            />
          </div>
        }
        buttons={[
          <Button
            disabled={
              !caseItem && editingItem && editingItem.userId !== user.id
            }
            variant='primary'
            onClick={() =>
              editingItem ? handleEditItem() : handleCreateItem(caseItem)
            }
          >
            {editingItem ? global._('Common.Save') : btnText}
          </Button>
        ]}
      />
    )
  }

  let lastItemMargin = 20
  if (isMobile) lastItemMargin = 90

  return (
    <>
      {itemMarkedForDelete && (
        <Modal
          onClose={() => setItemMarkedForDelete(null)}
          title={
            isCaseNotes
              ? global._('CaseDetails.RemoveCaseNote')
              : global._('CaseDetails.RemoveComment')
          }
          content={
            <div style={{ textAlign: 'left' }}>
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='body'
              >
                {isCaseNotes
                  ? global._('CaseDetails.ConfirmRemoveCaseNote')
                  : global._('CaseDetails.ConfirmRemoveComment')}
              </Typography>
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='subtitleSmall'
              >
                {`${global._('CaseDetails.ItemCreatedBy')}: ${
                  itemMarkedForDelete.user.name
                }`}
              </Typography>
            </div>
          }
          buttons={[
            <Button
              variant='secondary'
              onClick={() => {
                handleDeleteItem(itemMarkedForDelete)
                setItemMarkedForDelete(null)
              }}
            >
              {global._('Delete')}
            </Button>,
            <Button variant='none' onClick={() => setItemMarkedForDelete(null)}>
              {global._('Common.Cancel')}
            </Button>
          ]}
        />
      )}
      {items && items.length === 0 && (
        <NoResultsMessage
          content={
            isCaseNotes
              ? global._('CaseDetails.CaseNoNotes')
              : global._('CaseDetails.CaseNoComments')
          }
        />
      )}
      {items &&
        items.map((item, key) => (
          <div
            key={`caseItem-comment-list-item-${item.id}`}
            className={classes.comment}
            style={{
              marginBottom: key === items.length - 1 ? lastItemMargin : null
            }}
            onMouseEnter={() => setItemHover(item)}
            onMouseLeave={() => setItemHover(null)}
          >
            {isMobile && (
              <Typography variant='body2' style={{ marginTop: '-24px' }}>
                {formatDate(item.createdAt)}
              </Typography>
            )}
            <div className={classes.commentHeader}>
              <div className={`${classes.commentIcon} commentIcon`}>
                <Icon style={{ fontSize: 18 }}>face</Icon>
              </div>
              <div style={{ overflow: 'hidden' }}>
                <Typography
                  variant='body2'
                  bold
                  className={classes.commentHeaderText}
                >
                  {item?.user?.name || global._('Common.Removed')}
                </Typography>
                {item.organisation && (
                  <Typography
                    variant='body2'
                    style={{ marginLeft: isMobile ? 'auto' : 10 }}
                    className={classes.commentHeaderText}
                  >
                    {item.organisation.name}
                  </Typography>
                )}
              </div>
              <Row justify='flex-end' flex>
                {/* Comments */}
                {isMobile &&
                  !isCaseNotes &&
                  (item.userId === user.id || isAdmin) &&
                  deleteIcon(item)}
                {/* CaseNotes */}
                {isMobile &&
                  isCaseNotes &&
                  isAdmin &&
                  item.userId === user.id &&
                  deleteIcon(item)}
              </Row>
              {!isMobile && (
                <Typography variant='body2' style={{ marginLeft: 'auto' }}>
                  {formatDate(item.createdAt)}
                </Typography>
              )}
            </div>
            <div className={classes.divider} />
            <div
              className={classes.commentBody}
              style={{
                backgroundColor:
                  item.userId === user.id
                    ? 'var(--color-primary-tone)'
                    : 'var(--color-material-grey)'
              }}
            >
              {item !== editingItem && (
                <>
                  <div
                    style={{ marginRight: 'auto', whiteSpace: 'break-spaces' }}
                  >
                    {item.message}
                  </div>
                  <div className={classes.btnContainer}>
                    {!userIsGuest && (
                      <Row justify='flex-end'>
                        {item.userId === user.id && (
                          <IconButton
                            style={{ padding: 6, margin: '-6px 0' }}
                            onClick={() => {
                              setEditingItem(item)
                              setItemText(item.message)
                            }}
                          >
                            <Icon style={{ color: 'black' }}>edit</Icon>
                          </IconButton>
                        )}

                        {(isAdmin || item.userId === user.id) &&
                          !isCaseNotes &&
                          !isMobile &&
                          deleteIcon(item)}
                        {item.userId === user.id &&
                          isAdmin &&
                          isCaseNotes &&
                          !isMobile &&
                          deleteIcon(item)}
                      </Row>
                    )}
                  </div>
                  <div />
                </>
              )}
              {item === editingItem && !isMobile && (
                <Input
                  multiline
                  endAdornment={
                    <Button
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 10
                      }}
                      variant='noHover'
                      onClick={() => handleEditItem()}
                    >
                      {global._('Common.Save')}
                    </Button>
                  }
                  value={itemText}
                  onChange={e => setItemText(e.target.value)}
                />
              )}
            </div>
          </div>
        ))}
      {!isMobile && (
        <TextField
          data-cy={`input-multiline-${isCaseNotes ? 'note' : 'comment'}`}
          style={{ width: '100%' }}
          rows={isCaseNotes ? 4 : 10}
          variant='filled'
          multiline
          value={caseItem}
          helperText={helpText}
          onChange={e => setCaseItem(e.target.value)}
        />
      )}
      <div className={classes.bottomBtnContainer}>
        <Button
          data-cy={`button-create-${isCaseNotes ? 'note' : 'comment'}`}
          disabled={!caseItem && !isMobile}
          variant={btnVariant}
          onClick={() => {
            if (isMobile) {
              setCommentModalIsOpen(true)
              setShowAddingItem(true)
            } else {
              handleCreateItem(caseItem)
            }
          }}
        >
          {btnText}
        </Button>
      </div>
    </>
  )
}

Comment.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  isCaseNotes: PropTypes.bool,
  btnText: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
  btnVariant: PropTypes.string.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  onCreateItem: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  setCommentModalIsOpen: PropTypes.func
}

Comment.defaultProps = {
  isCaseNotes: false,
  setCommentModalIsOpen: undefined
}

const CommentWithStyles = injectSheet(styles)(Comment)
export default CommentWithStyles
