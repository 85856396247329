import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Grid } from '@material-ui/core'
import axios from 'axios'
import qs from 'qs'
import IconColumn from 'src/components/IconColumn'
import KeysNavigation from '../../components/Keys/KeysNavigation'
import Notification from '../../common/Notification'
import Button from '../../components/Button'
import ObjectList from '../../components/ObjectList'
import Typography from '../../components/Typography'
import LockSystemDetails from './LockSystemDetails'
import SuccessMessage from '../../components/SuccessMessage'
import SearchField from '../../components/SearchField'
import Modal from '../../components/Modal'
import { getSelectedOrg } from '../../utils/helpers'
import { InstructionsVideoIds } from '../../utils/constants'

const styles = {
  root: {}
}

const LockSystems = props => {
  const { classes, breadcrumbItems, history, user } = props

  const [error, setError] = useState(null)
  const [deleteError, setDeleteError] = useState(null)
  const [items, setItems] = useState(null)
  const [expandedRows, setExpandedRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showLockSystemModal, setShowLockSystemModal] = useState(false)
  const [editLockSystemObj, setEditLockSystemObj] = useState(null)
  const [deleteLockSystemObj, setDeleteLockSystemObj] = useState(null)
  const [showDeleteSuccessMessage, setShowDeleteSuccessMessage] = useState(null)
  const [filter, setFilter] = useState({
    orderBy: 'id',
    order: 'asc'
  })

  const selectedOrg = getSelectedOrg(user)

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const fetchLockSystems = async () => {
    if (selectedOrg) {
      try {
        setIsLoading(true)
        const { data } = await axios.get(
          `/v1/organisations/${selectedOrg.id}/locksystems?${qs.stringify(
            filter
          )}`
        )
        setItems(data)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
      }

      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchLockSystems()
  }, [filter])

  const handleDeleteLockSystem = async () => {
    if (deleteLockSystemObj) {
      try {
        setDeleteError(null)
        await axios.delete(
          `/v1/organisations/${selectedOrg.id}/locksystems/${deleteLockSystemObj.id}`
        )

        setShowDeleteSuccessMessage(true)

        const filteredItems = items.filter(
          item => item.id !== deleteLockSystemObj.id
        )
        setItems(filteredItems)

        setDeleteLockSystemObj(null)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setDeleteError(msg)
      }
    }
  }

  const handleFilter = name => e => {
    const aFilter = { ...filter }
    const { value } = e.target
    aFilter[name] = value
    setFilter(aFilter)
  }

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      clickable: false,
      style: { width: 70 },
      format: (objVal, obj) => obj && <IconColumn icon='lock' />
    },
    {
      key: 'name',
      localizationKey: 'Name',
      sortingKey: 'name',
      clickable: true,
      longText: true
    },
    {
      key: 'id',
      localizationKey: 'Common.Id',
      sortingKey: 'id',
      clickable: true,
      style: { width: 120 }
    },
    {
      key: 'keyCount',
      localizationKey: 'Keys.LockSystems.KeyCount',
      sortingDisabled: true,
      clickable: true,
      style: { width: 150 }
    }
  ]

  const Filter = (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SearchField
          style={{ width: '100%' }}
          label={global._('Keys.LockSystems.Search')}
          variant='filled'
          value={filter.search || ''}
          onChange={handleFilter('search')}
        />
      </Grid>
    </Grid>
  )

  const TitleContent = (
    <div style={{ marginLeft: 'auto' }}>
      <Button
        variant='primary'
        style={{ width: 130 }}
        onClick={() => {
          setEditLockSystemObj(null)
          setShowLockSystemModal(true)
        }}
      >
        {global._('Keys.Common.Add')}
      </Button>
    </div>
  )

  const actionButtons = (objId, obj) => (
    <>
      <Button
        variant='outlined'
        style={{ marginLeft: 18 }}
        onClick={() => history.push(`/keys/systems/${objId}`)}
      >
        {global._('Keys.LockSystems.ShowKeys')}
      </Button>
      <Button
        variant='secondary'
        style={{ marginLeft: 18 }}
        onClick={() => {
          setEditLockSystemObj(obj)
          setShowLockSystemModal(true)
        }}
      >
        {global._('Keys.LockSystems.Edit')}
      </Button>
      <Button
        variant='secondary'
        onClick={() => {
          setDeleteLockSystemObj(obj)
        }}
        style={{ marginLeft: 18 }}
      >
        {global._('Keys.Common.Remove')}
      </Button>
    </>
  )

  const lang = global.getLanguage()

  return (
    <div className={classes.root}>
      {deleteLockSystemObj && (
        <Modal
          onClose={() => {
            setDeleteLockSystemObj(null)
          }}
          title={global._('Keys.LockSystems.DeleteLockSystem')}
          content={
            <div style={{ textAlign: 'left' }}>
              {deleteError && (
                <Notification
                  type='error'
                  message={deleteError}
                  style={{ margin: '20px 0' }}
                />
              )}
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='body'
              >
                {global._('Keys.LockSystems.DeleteInfo')}
              </Typography>
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='subtitleSmall'
              >
                {deleteLockSystemObj && deleteLockSystemObj.name}
              </Typography>
            </div>
          }
          buttons={[
            <Button
              variant='none'
              onClick={() => {
                setDeleteLockSystemObj(null)
              }}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              variant='secondary'
              onClick={() => handleDeleteLockSystem()}
            >
              {global._('Delete')}
            </Button>
          ]}
        />
      )}
      {showLockSystemModal && (
        <LockSystemDetails
          classes={classes}
          currentOrg={selectedOrg}
          setShowLockSystemModal={setShowLockSystemModal}
          fetchLockSystems={fetchLockSystems}
          setEditLockSystemObj={setEditLockSystemObj}
          lockSystemObj={editLockSystemObj || undefined}
          user={user}
          isMobile={false}
        />
      )}
      <SuccessMessage
        subtitle={global._('Keys.LockSystems.LockSystemAddedSuccessfully')}
        localStorageProp='lockSystemAddedSuccessfully'
      />
      <SuccessMessage
        subtitle={global._('Keys.LockSystems.LockSystemEditedSuccessfully')}
        localStorageProp='lockSystemEditedSuccessfully'
      />

      {showDeleteSuccessMessage && (
        <SuccessMessage
          subtitle={global._('Keys.LockSystems.LockSystemDeletedSuccessfully')}
        />
      )}
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}

      <KeysNavigation
        breadcrumbItems={breadcrumbItems}
        user={user}
        error={error}
      />
      <ObjectList
        sectionTitle={global._('Keys.LockSystems.Title')}
        infoDialogTitle={global._('Keys.Common.Information')}
        infoDialogContent={
          <Typography variant='body'>
            {global._('Keys.LockSystems.InfoDialogContent')}
          </Typography>
        }
        titleContent={TitleContent}
        expandable
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        actionButtons={actionButtons}
        filterFields={Filter}
        history={history}
        columns={columns}
        objects={items}
        instructionsVideoId={
          InstructionsVideoIds[lang].KEY_MANAGEMENT.LOCK_SYSTEMS
        }
        isLoadingObjects={isLoading}
        noResultsMessage={global._(
          filter.search
            ? 'Keys.LockSystems.SearchNoResultsMessage'
            : 'Keys.LockSystems.NoResultsMessage'
        )}
        handleFilter={handleFilter}
        setFilter={setFilter}
        filter={filter}
      />
    </div>
  )
}

LockSystems.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const LockSystemsWithStyles = injectSheet(styles)(LockSystems)
export default LockSystemsWithStyles
