/*
 * Badge
 *
 * As defined in http://jsd.se/digitallassmed/style-guide/general_components.html
 */
import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'

const styles = {
  root: {
    fontSize: '0.75rem',
    fontWeight: 600,
    padding: '0.16rem 0.5rem',
    borderRadius: 3
  },
  primary: {
    color: 'var(--color-black)',
    backgroundColor: 'var(--color-primary)'
  },
  secondary: {
    color: 'white',
    backgroundColor: 'var(--color-secondary)'
  },
  black: {
    color: 'white',
    backgroundColor: 'var(--color-logo-black)'
  },
  grey: {
    color: 'var(--color-black)',
    backgroundColor: 'var(--color-light-grey)'
  },
  error: {
    color: 'var(--color-black)',
    backgroundColor: 'var(--color-error-opacity)'
  }
}

const Badge = props => {
  const { classes, variant, label } = props

  const rootClasses = classnames(classes.root, classes[variant])
  return <span className={rootClasses}>{label}</span>
}

Badge.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'black', 'grey']),
  label: PropTypes.string
}

Badge.defaultProps = {
  variant: 'primary',
  label: ''
}

const BadgeWithStyles = injectSheet(styles)(Badge)
export default BadgeWithStyles
