import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import KeysNavigation from '../../components/Keys/KeysNavigation'
import Typography from '../../components/Typography'
import Paper from '../../components/Paper'
import SectionTitle from '../../components/SectionTitle'
import Notification from '../../common/Notification'
import NavigationList from '../../components/NavigationList'
import { getSelectedOrg } from '../../utils/helpers'
import { InstructionsVideoIds, UserRoles } from '../../utils/constants'
import SummaryBadge from '../../components/SummaryBadge'

const styles = {
  root: {}
}

export const Section = ({ children, noPadding, noBottomMargin }) => (
  <div
    style={{
      marginBottom: noBottomMargin ? 0 : undefined,
      width: '100%',
      padding: noPadding ? undefined : '20px 20px',
      borderTop: '1px solid var(--color-divider-light)'
    }}
  >
    {children}
  </div>
)

const Keys = ({ classes, history, user, breadcrumbItems }) => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const [summaryData, setSummaryData] = useState({
    systems: 0,
    keys: 0,
    keyrings: 0,
    keyStorages: 0,
    keyReceiptTemplates: 0,
    keyInventories: 0
  })

  const selectedOrg = getSelectedOrg(user)

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)

  useEffect(() => {
    if (selectedOrg) {
      ;(async () => {
        setIsLoading(true)
        try {
          const { data } = await axios.get(
            `/v1/organisations/${selectedOrg.id}/keys/summary`
          )

          setSummaryData(data)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
        setIsLoading(false)
      })()
    }
  }, [])

  const lang = global.getLanguage()

  return (
    <div className={classes.root}>
      {error && (
        <Notification
          style={{ marginBottom: 20 }}
          type='error'
          message={error}
        />
      )}
      <>
        <KeysNavigation
          breadcrumbItems={breadcrumbItems}
          user={user}
          error={error}
        />
        <Paper noPadding bottomPart style={{ paddingBottom: 48 }}>
          <Section>
            <SectionTitle
              title={global._('Keys.Navigation.KeyManagement')}
              infoDialogTitle={global._('Keys.Summary.InfoDialogTitle')}
              infoDialogContent={
                <div style={{ textAlign: 'left' }}>
                  <Typography
                    style={{ display: 'block', paddingBottom: 12 }}
                    variant='body'
                  >
                    {global._(
                      `Keys.Summary.InfoDialogContent.${
                        isPartnerAdmin
                          ? UserRoles.PartnerAdmin
                          : UserRoles.CustomerAdmin
                      }`
                    )}
                  </Typography>
                </div>
              }
              instructionsVideoId={
                InstructionsVideoIds[lang].KEY_MANAGEMENT.BASE
              }
              noTitleMargin
            />
          </Section>
          <Section>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: '-10px',
                marginBottom: '-10px'
              }}
            >
              <SummaryBadge
                label={global._('Keys.Summary.LockSystems')}
                count={summaryData.systems}
                onClick={() => history.push('/keys/systems')}
                loading={isLoading}
              />
              <SummaryBadge
                label={global._('Keys.Summary.Keys')}
                count={summaryData.keys}
                onClick={() => history.push('/keys/list')}
                loading={isLoading}
              />
              <SummaryBadge
                label={global._('Keys.Summary.PassedReturnDate')}
                count={summaryData.passedReturnDateKeys}
                onClick={() => history.push('/keys/list?returnDatePassed=true')}
                loading={isLoading}
              />
              <SummaryBadge
                label={global._('Keys.Summary.KeyRings')}
                count={summaryData.keyrings}
                onClick={() => history.push('/keys/keyrings')}
                loading={isLoading}
              />
              <SummaryBadge
                label={global._('Keys.Summary.Repositories')}
                count={summaryData.keyStorages}
                onClick={() => history.push('/keys/repositories')}
                loading={isLoading}
              />
              <SummaryBadge
                label={global._('Keys.Summary.ReceiptTemplates')}
                count={summaryData.keyReceiptTemplates}
                onClick={() => history.push('/keys/receipt-templates')}
                loading={isLoading}
              />
              <SummaryBadge
                label={global._('Keys.Summary.OngoingInventories')}
                count={summaryData.keyInventories}
                onClick={() => history.push('/keys/inventory')}
                loading={isLoading}
              />
            </div>
          </Section>
          <Section>
            <Typography variant='subtitleBig' bold>
              {global._('Keys.Summary.Activities')}
            </Typography>
          </Section>
          <Section noPadding>
            <NavigationList
              removeLastBorder
              itemList={[
                {
                  icon: 'exit_to_app',
                  iconColor: 'var(--color-primary)',
                  title: global._('Keys.ActionLinks.Lending'),
                  subTitle: global._('Keys.ActionLinks.LendingInfo'),
                  smallerPadding: true,
                  fullBorder: true,
                  onClick: () => history.push('/keys/guides/loan/1')
                },
                {
                  icon: 'system_update_alt',
                  iconColor: 'var(--color-primary)',
                  title: global._('Keys.ActionLinks.Return'),
                  subTitle: global._('Keys.ActionLinks.ReturnInfo'),
                  smallerPadding: true,
                  fullBorder: true,
                  onClick: () => history.push('/keys/guides/return')
                }
              ]}
            />
          </Section>
          <Section>
            <Typography variant='subtitleBig' bold>
              {global._('Common.More')}
            </Typography>
          </Section>
          <Section noPadding>
            <NavigationList
              removeLastBorder
              itemList={[
                {
                  icon: 'settings',
                  iconColor: 'var(--color-primary)',
                  title: global._('Keys.ActionLinks.SettingsTitle'),
                  subTitle: global._('Keys.ActionLinks.SettingsDescription'),
                  smallerPadding: true,
                  fullBorder: true,
                  onClick: () => history.push('/keys/settings')
                }
              ]}
            />
          </Section>
        </Paper>
      </>
    </div>
  )
}

Keys.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  breadcrumbItems: PropTypes.array.isRequired
}

Keys.defaultProps = {
  user: null
}

Section.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool,
  noBottomMargin: PropTypes.bool
}

Section.defaultProps = {
  children: null,
  noPadding: false,
  noBottomMargin: false
}

const KeysWithStyles = injectSheet(styles)(Keys)
export default KeysWithStyles
