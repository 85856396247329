import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { Icon } from '@material-ui/core'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import Button from '../../components/Button'
import Notification from '../../common/Notification'
import 'moment/locale/sv'
import Paper from '../../components/Paper'
import Typography from '../../components/Typography'
import PageContentHeader from '../../components/PageContentHeader'
import useWindowDimensions from '../../utils/useWindowDimensions'
import Content from '../../components/Content'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import SuccessMessage from '../../components/SuccessMessage'

const styles = {
  root: {},
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    marginTop: 1
  },
  input: {
    width: '100%'
  },
  section: {
    width: 'calc(100% + 40px)',
    padding: 20,
    borderBottom: '1px solid var(--color-divider-light)',
    marginLeft: '-20px',
    marginRight: '-20px',
    '&:first-child': {
      paddingTop: 0
    },
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0
    }
  }
}

const PasswordUpdate = props => {
  const { classes, history, breadcrumbItems } = props

  const { isMobile } = useWindowDimensions()

  const [error, setError] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const updatePassword = async ({ currentPassword, newPassword }) => {
    try {
      setShowSuccessMessage(false)
      await axios.post('/v1/users/password', { currentPassword, newPassword })

      if (isMobile) {
        localStorage.setItem('user.password.updated', true)
        history.push('/myaccount')
      }

      setShowSuccessMessage(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global.translate(msg))
    }
  }

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(global._('Common.Required')),
    newPassword: Yup.string().min(8).required(global._('Common.Required')),
    repeatPassword: Yup.string().when('newPassword', {
      is: val => val && val.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref('newPassword')], global._('MyAccount.PasswordMismatch'))
        .required()
    })
  })

  const PasswordForm = hasTitle => (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        repeatPassword: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        updatePassword({
          currentPassword: values.currentPassword,
          newPassword: values.newPassword
        })
        setSubmitting(false)
      }}
    >
      {({
        submitForm,
        errors,
        touched,
        handleChange,
        values,
        handleBlur,
        setTouched
      }) => (
        <Form style={{ width: '100%' }}>
          {hasTitle && (
            <div className={classes.section}>
              <Typography variant='title'>
                {global._('MyAccount.UpdatePassword')}
              </Typography>
            </div>
          )}
          <div className={classes.section}>
            <Grid container spacing={6} style={{ marginBottom: 1 }}>
              <Grid item xs={12}>
                <TextField
                  onChange={handleChange}
                  type='password'
                  error={errors.currentPassword}
                  onClick={handleBlur}
                  value={values.currentPassword}
                  name='currentPassword'
                  className={classes.input}
                  label={global._('MyAccount.CurrentPassword')}
                  variant='filled'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 1 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={handleChange}
                  type='password'
                  error={errors.newPassword}
                  onClick={handleBlur}
                  helperText={global._('MyAccount.PasswordHelpText')}
                  value={values.newPassword}
                  name='newPassword'
                  className={classes.input}
                  label={global._('MyAccount.NewPassword')}
                  variant='filled'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={handleChange}
                  type='password'
                  error={errors.repeatPassword}
                  onClick={handleBlur}
                  value={values.repeatPassword}
                  name='repeatPassword'
                  className={classes.input}
                  label={global._('MyAccount.RepeatPassword')}
                  variant='filled'
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container direction='row-reverse'>
              <Button
                variant='primary'
                disabled={
                  Object.keys(touched).length === 0 ||
                  Object.keys(errors).length
                }
                onClick={() => {
                  submitForm(values)
                  setTouched({})
                }}
                style={{ marginTop: 15 }}
              >
                {global._('Common.Save')}
              </Button>
            </Grid>
          </div>
        </Form>
      )}
    </Formik>
  )

  if (isMobile) {
    return (
      <Content style={{ backgroundColor: 'var(--color-white)' }}>
        <NavigationBar
          title={global._('MyAccount.Password')}
          backAction={() => history.push('/myaccount')}
        />
        <ScrollView id='scrollable-container' fullHeight>
          <div className={classes.rootMobile}>
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ margin: '20px 0' }}
              />
            )}
            {PasswordForm()}
          </div>
        </ScrollView>
      </Content>
    )
  }

  return (
    <div className={classes.root}>
      {showSuccessMessage ? (
        <SuccessMessage subtitle={global._('MyAccount.UpdatedPassword')} />
      ) : null}
      <PageContentHeader breadcrumbItems={breadcrumbItems} />
      {error && <Notification type='error' message={error} />}
      <Paper smallerPadding>{PasswordForm(true)}</Paper>
    </div>
  )
}

PasswordUpdate.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array
}

PasswordUpdate.defaultProps = {
  breadcrumbItems: []
}

const PasswordUpdateWithStyles = injectSheet(styles)(PasswordUpdate)
export default PasswordUpdateWithStyles
