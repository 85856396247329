import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import queryString from 'querystring'
import Breadcrumb from '../../../components/Breadcrumbs'
import Typography from '../../../components/Typography'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Administration from './Administration'
import Button from '../../../components/Button'
import Comment from './Comment'
import CaseData from './CaseData'
import FileContainer from '../../../components/FileContainer'
import DragZone from '../../../components/DragAndDrop/DragZone'
import Modal from '../../../components/Modal'
import { CaseIcons, CaseTypes, UserRoles } from '../../../utils/constants'
import Notification from '../../../common/Notification'
import CaseDetailsMobile from './CaseDetailsMobile'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import NoResultsMessage from '../../../components/NoResultsMessage'
import { defaultCasesFilter } from '../../../utils/helpers'
import { detectMimeType } from '../../../utils/mime'
import { caseTypeUrlMap } from '../Cases'

const styles = {
  root: {
    marginBottom: 60
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  paper: {
    borderRadius: 3,
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
    backgroundColor: 'var(--color-white)',
    width: props =>
      props.user.roles.includes(UserRoles.PartnerAdmin) ||
      props.user.roles.includes(UserRoles.Support)
        ? '70%'
        : '100%'
  },
  sectionRight: {
    display: 'flex',
    flexDirection: 'column',
    width: props =>
      props.user.roles.includes(UserRoles.PartnerAdmin) ||
      props.user.roles.includes(UserRoles.Support)
        ? '30%'
        : 0,
    marginLeft: props =>
      props.user.roles.includes(UserRoles.PartnerAdmin) ||
      props.user.roles.includes(UserRoles.Support)
        ? 10
        : 0
  },
  section: {
    borderBottom: '1px solid #eee',
    display: 'flex',
    padding: '20px 20px',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    minWidth: 60,
    borderRadius: 500,
    backgroundColor: 'var(--color-light-grey)',
    marginRight: 20
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 3,
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
    backgroundColor: 'var(--color-white)',
    marginBottom: 10
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
  },
  skeleton: {
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  caseHeader: {
    wordBreak: 'break-word'
  },
  '@media (max-width: 1200px)': {
    contentContainer: {
      flexDirection: 'column'
    },
    paper: {
      width: () => '100%'
    },
    sectionRight: {
      marginTop: 10,
      marginLeft: () => 0,
      width: () => '100%'
    }
  }
}

const CaseDetails = props => {
  const {
    classes,
    history,
    match,
    user,
    breadcrumbItems,
    location,
    token,
    selectedOrganisation
  } = props

  const caseUrlType = match.url.includes('/orders') ? 'orders' : 'cases'

  const { isMobile } = useWindowDimensions()
  const { id } = match.params
  const isAdminUser =
    user.roles.includes(UserRoles.PartnerAdmin) ||
    user.roles.includes(UserRoles.Support)
  const AllowedFileTypes = [
    'image/jpeg',
    'image/png',
    'image/vnd.dwg', // .dwg
    'image/x-dwg', // .dwg
    'application/acad', // .dwg
    'application/pdf',
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel', // .xls, .xlt, .xla
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template', // .xltx
    'video/mp4', // .mp4
    'video/quicktime' // .mov
  ]
  const AllowedFileSize = 50000000
  const [caseObj, setCaseObj] = useState()
  const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false)
  const [isLoadingCase, setIsLoadingCase] = useState(false)
  const [fileMarkedForDelete, setFileMarkedForDelete] = useState()
  const [error, setError] = useState(null)
  const [caseList, setCaseList] = useState([])
  const [caseIndex, setCaseIndex] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoadingCase(true)
        const { data: resCaseObj } = await axios.get(
          `v1/${caseUrlType}/${id}?caseManagers=true`
        )

        if (
          resCaseObj.type === CaseTypes.Order &&
          match.url.includes('/cases')
        ) {
          history.push(
            `${location.pathname.replace('/cases/', '/orders/')}${
              location.search
            }`
          )
        }

        setCaseObj(resCaseObj)

        let casesFilter = `?${queryString.stringify(
          defaultCasesFilter({ location })
        )}`

        if (location && location.search) {
          casesFilter = location.search
        }

        const { data } = await axios.get(`v1/${caseUrlType}${casesFilter}`)
        setCaseList(data)
        const idx = data.findIndex(caseItem => caseItem.id === resCaseObj.id)
        setCaseIndex(idx)

        setIsLoadingCase(false)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
        setIsLoadingCase(false)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoadingCase(true)
        const { data: resCaseObj } = await axios.get(
          `v1/${caseUrlType}/${id}?caseManagers=true`
        )
        const idx = caseList.findIndex(
          caseItem => caseItem.id === resCaseObj.id
        )
        setCaseIndex(idx)
        setCaseObj(resCaseObj)
        setIsLoadingCase(false)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
        setIsLoadingCase(false)
      }
    })()
  }, [id])

  const updateCaseReadStatus = async () => {
    try {
      // Change case isRead status to read
      await axios.put(`v1/${caseUrlType}/${id}`, { isRead: true })
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  // useEffect(() => {
  //   setError(null)

  //   if (
  //     user &&
  //     isAdminUser &&
  //     caseObj &&
  //     !caseObj.isRead &&
  //     typeof caseObj.isRead === 'boolean'
  //   ) {
  //     updateCaseReadStatus()
  //   }
  // }, [caseObj])

  const changeCase = async action => {
    setIsLoadingCase(true)
    let newCase
    if (action === 'previous') {
      newCase = caseList[caseIndex - 1] ? caseList[caseIndex - 1] : null
    } else if (action === 'next') {
      newCase = caseList[caseIndex + 1] ? caseList[caseIndex + 1] : null
    }

    if (newCase) {
      setCaseObj(newCase)
      history.push(
        `/${caseUrlType}/${newCase.id}${location.search ? location.search : ''}`
      )
    }
    setIsLoadingCase(false)
  }

  const onRemoveFile = async file => {
    setFileMarkedForDelete(null)
    try {
      await axios.delete(`/v1/${caseUrlType}/${caseObj.id}/files/${file.id}`)
      const updatedFiles = caseObj.files.filter(f => f.id !== file.id)
      setCaseObj({ ...caseObj, files: updatedFiles })
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  const onFileSelected = async selectedFile => {
    const { name, size } = selectedFile
    let { type } = selectedFile
    let errorCode

    if (!type) {
      type = detectMimeType(name)
    }

    if (!AllowedFileTypes.includes(type)) {
      errorCode = 'ERR_FILE_TYPE'
      setError(errorCode)
    } else if (size > AllowedFileSize) {
      errorCode = 'ERR_FILE_SIZE'
      setError(errorCode)
    } else {
      try {
        const response = await axios.post(
          `/v1/${caseUrlType}/${id}/files`,
          selectedFile
          // {
          //   maxContentLength: 100000000,
          //   maxBodyLength: 1000000000
          // }
        )
        setCaseObj({ ...caseObj, files: [...caseObj.files, response.data] })
      } catch (e) {
        errorCode = e.response ? e.response.data : e.message
        setError(errorCode)
      }
    }
  }

  const handleAddComment = async comment => {
    if (comment) {
      try {
        const response = await axios.post(`/v1/${caseUrlType}/${id}/comments`, {
          message: comment
        })
        setCaseObj({
          ...caseObj,
          comments: [
            ...caseObj.comments,
            {
              ...response.data,
              user: {
                name: user.name
              },
              organisation: {
                name: user.organisation.name
              }
            }
          ]
        })
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
      }
    }
  }
  const handleEditComment = async comment => {
    if (comment) {
      try {
        await axios.patch(`/v1/${caseUrlType}/comments/${comment.id}`, comment)
        const commentIndex = caseObj.comments.findIndex(
          item => item.id === comment.id
        )
        const newState = [...caseObj.comments]
        newState[commentIndex] = comment
        setCaseObj({ ...caseObj, comments: newState })
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
      }
    }
  }
  const handleDeleteComment = async comment => {
    if (comment) {
      try {
        const { data } = await axios.delete(
          `/v1/${caseUrlType}/comments/${comment.id}`
        )
        const newState = [
          ...caseObj.comments.filter(
            commentItem => commentItem.id !== comment.id
          )
        ]
        setCaseObj({ ...caseObj, comments: newState, ...data })
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
      }
    }
  }

  const handleAddCaseNote = async caseNote => {
    if (caseNote) {
      try {
        const response = await axios.post(`/v1/${caseUrlType}/${id}/notes`, {
          message: caseNote
        })
        const newState = [...caseObj.notes, response.data]
        setCaseObj({ ...caseObj, notes: newState })
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
      }
    }
  }
  const handleEditCaseNote = async caseNote => {
    if (caseNote) {
      try {
        await axios.patch(`v1/${caseUrlType}/notes/${caseNote.id}`, caseNote)
        const noteIndex = caseObj.notes.findIndex(
          note => note.id === caseNote.id
        )
        const newState = [...caseObj.notes]
        newState[noteIndex] = caseNote
        setCaseObj({ ...caseObj, notes: newState })
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
      }
    }
  }
  const handleDeleteCaseNote = async caseNote => {
    if (caseNote) {
      try {
        const { data } = await axios.delete(
          `v1/${caseUrlType}/notes/${caseNote.id}`
        )
        const newState = caseObj.notes.filter(note => note.id !== caseNote.id)
        setCaseObj({ ...caseObj, notes: newState, ...data })
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
      }
    }
  }

  const handleDeleteCase = async () => {
    try {
      const newCaseList = [...caseList]
      const isLast = caseIndex === newCaseList.length - 1
      await axios.delete(`v1/${caseUrlType}/${caseObj.id}`)
      newCaseList.splice(caseIndex, 1)
      const newCase = isLast
        ? newCaseList[caseIndex - 1]
        : newCaseList[caseIndex]
      setCaseList(newCaseList)
      setCaseObj(newCase)
      history.push(
        `/${caseUrlType}/${newCase.id}${location.search ? location.search : ''}`
      )
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
    setShowDeleteCaseModal(false)
  }

  if (isMobile) {
    return (
      <CaseDetailsMobile
        {...props}
        caseObj={caseObj}
        isAdminUser={isAdminUser}
        caseList={caseList}
        caseIndex={caseIndex}
        error={error}
        isLoadingCase={isLoadingCase}
        setError={setError}
        changeCase={changeCase}
        onRemoveFile={onRemoveFile}
        onFileSelected={onFileSelected}
        handleAddComment={handleAddComment}
        handleEditComment={handleEditComment}
        handleDeleteComment={handleDeleteComment}
        handleAddCaseNote={handleAddCaseNote}
        handleEditCaseNote={handleEditCaseNote}
        handleDeleteCaseNote={handleDeleteCaseNote}
        handleDeleteCase={handleDeleteCase}
        setCaseObjCallback={setCaseObj}
        match={match}
      />
    )
  }

  if (isLoadingCase) return <LoadingSpinner />

  /* modifies breadcrumbs to return to e.g /cases OR /cases/claims OR /cases/feedback */
  const modifiedBreadcrumbItems = [...breadcrumbItems]

  if (caseObj && caseObj.type === CaseTypes.Order) {
    modifiedBreadcrumbItems.splice(2, 2, {
      name: `${global._('Common.Order')} #${caseObj.id}`,
      path: ''
    })
  } else {
    modifiedBreadcrumbItems.splice(1, 1, {
      name: global._('Cases.SectionTitle'),
      path: caseTypeUrlMap()
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.btnContainer}>
        <Breadcrumb breadcrumbItems={modifiedBreadcrumbItems} />
        <Button
          variant='outlined'
          style={{
            marginLeft: 'auto',
            maxWidth: 226,
            marginRight: 20
          }}
          type='danger'
          disabled={isLoadingCase || caseIndex === 0 || caseList.length === 0}
          onClick={() => changeCase('previous')}
        >
          {global._('Case.PreviousCase')}
        </Button>
        <Button
          variant='outlined'
          style={{ maxWidth: 194 }}
          disabled={
            isLoadingCase ||
            caseIndex === caseList.length - 1 ||
            caseList.length === 0
          }
          onClick={() => changeCase('next')}
        >
          {global._('Case.NextCase')}
        </Button>
      </div>
      {!isLoadingCase && !caseObj && caseList.length === 0 ? (
        <NoResultsMessage content={global._('CaseDetails.NoCasesMessage')} />
      ) : (
        <>
          {caseObj && (
            <div className={classes.contentContainer}>
              <div className={classes.paper}>
                {error && (
                  <Notification
                    type='error'
                    message={error}
                    style={{ padding: 20 }}
                  />
                )}
                <div
                  className={classes.section}
                  style={{ flexDirection: 'row' }}
                >
                  <div className={classes.iconContainer}>
                    <Icon
                      style={{
                        fontSize: 30,
                        color: 'var(--color-middle-grey)'
                      }}
                    >
                      {CaseIcons[caseObj.type]}
                    </Icon>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      ariant='small'
                      style={{ color: 'var(--color-middle-grey)' }}
                    >
                      {`${global._(`CaseDetailsTypes.${caseObj.type}`)} #${
                        caseObj.id
                      }`}
                    </Typography>
                    <Typography
                      variant='subtitleBig'
                      bold
                      className={classes.caseHeader}
                    >
                      {caseObj.header}
                    </Typography>
                  </div>
                  {user && isAdminUser && caseObj.type !== CaseTypes.Order && (
                    <div style={{ marginLeft: 'auto' }}>
                      <Button
                        style={{ minWidth: 175 }}
                        variant='secondary'
                        onClick={() => setShowDeleteCaseModal(true)}
                      >
                        {global._('Case.DeleteCase')}
                      </Button>
                    </div>
                  )}
                  {showDeleteCaseModal && (
                    <Modal
                      onClose={() => setShowDeleteCaseModal(false)}
                      title={global._('Case.DeleteCase')}
                      content={
                        <div style={{ textAlign: 'left' }}>
                          <Typography
                            style={{
                              display: 'block',
                              paddingBottom: 12
                            }}
                            variant='body'
                          >
                            {global._('Case.DeleteCaseConfirm')}
                          </Typography>
                          <Typography
                            style={{
                              display: 'block',
                              paddingBottom: 12
                            }}
                            variant='subtitleSmall'
                          >
                            {global._('Case.DeleteCaseConfirmText')}
                          </Typography>
                        </div>
                      }
                      buttons={[
                        <Button
                          variant='secondary'
                          onClick={() => handleDeleteCase()}
                        >
                          {global._('Delete')}
                        </Button>,
                        <Button
                          variant='none'
                          onClick={() => setShowDeleteCaseModal(false)}
                        >
                          {global._('Common.Cancel')}
                        </Button>
                      ]}
                    />
                  )}
                </div>
                <CaseData
                  caseObj={caseObj}
                  setError={setError}
                  caseUrlType={caseUrlType}
                  selectedOrganisation={selectedOrganisation}
                />
                <div
                  className={classes.section}
                  style={{
                    flexDirection: 'column'
                  }}
                >
                  <Grid container>
                    {!caseObj.files && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          height: 60,
                          flex: 1,
                          marginBottom: 20
                        }}
                      >
                        <Skeleton
                          animation='wave'
                          variant='rect'
                          height={60}
                          style={{ width: '50%', marginRight: 10 }}
                        />
                        <Skeleton
                          animation='wave'
                          variant='rect'
                          height={60}
                          style={{ width: '50%' }}
                        />
                      </div>
                    )}
                    {caseObj.files &&
                      caseObj.files.length > 0 &&
                      caseObj.files.map(fileObj => (
                        <Grid
                          item
                          xs={fileObj.receipt ? 12 : 6}
                          style={{
                            paddingRight: 10,
                            marginBottom: 10
                          }}
                          key={`file-container-item-${fileObj.id}`}
                        >
                          <FileContainer
                            file={fileObj.file}
                            name={fileObj.file.name}
                            icon={fileObj.receipt ? 'assignment' : undefined}
                            size={fileObj.file.size}
                            type={fileObj.file.type}
                            src={`/v1/${caseUrlType}/${caseObj.id}/files/${fileObj.id}`}
                            endIcon={fileObj.receipt ? 'get_app' : undefined}
                            onDelete={
                              fileObj.receipt
                                ? undefined
                                : () => setFileMarkedForDelete(fileObj)
                            }
                            token={token}
                          />
                        </Grid>
                      ))}
                  </Grid>
                  {fileMarkedForDelete && (
                    <Modal
                      onClose={() => setFileMarkedForDelete(null)}
                      title={global._('CaseDeleteFileModal.Title')}
                      content={
                        <div style={{ textAlign: 'left' }}>
                          <Typography
                            style={{
                              display: 'block',
                              paddingBottom: 12
                            }}
                            variant='body'
                          >
                            {global._('CaseDeleteFileModal.DeleteFile')}
                          </Typography>
                          <Typography
                            style={{
                              display: 'block',
                              paddingBottom: 12
                            }}
                            variant='subtitleSmall'
                          >
                            {fileMarkedForDelete.file.name}
                          </Typography>
                        </div>
                      }
                      buttons={[
                        <Button
                          variant='secondary'
                          onClick={() => onRemoveFile(fileMarkedForDelete)}
                        >
                          {global._('Delete')}
                        </Button>,
                        <Button
                          variant='none'
                          onClick={() => setFileMarkedForDelete(null)}
                        >
                          {global._('Common.Cancel')}
                        </Button>
                      ]}
                    />
                  )}
                  <DragZone
                    onFileSelected={onFileSelected}
                    buttonOnly
                    buttonVariant='outlined'
                  />
                </div>
                <div className={classes.section}>
                  <Typography variant='subtitleBig' bold>
                    {global._('CaseDetails.Comments')}
                  </Typography>
                  {!caseObj.comments && (
                    <div className={classes.skeleton}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirectionn: 'row',
                          marginBottom: 5
                        }}
                      >
                        <Skeleton variant='circle' width={40} height={40} />
                        <Skeleton
                          variant='text'
                          style={{ flex: 1, marginLeft: 5 }}
                          height={35}
                        />
                      </div>
                      <Skeleton
                        animation='wave'
                        variant='rect'
                        width='100%'
                        height={100}
                      />
                    </div>
                  )}
                  {caseObj.comments && (
                    <Comment
                      items={caseObj.comments}
                      btnText={global._('Case.AddComment')}
                      helpText={global._('Case.AddCommentHelpText')}
                      btnVariant='outlined'
                      onDeleteItem={comment => handleDeleteComment(comment)}
                      onEditItem={comment => handleEditComment(comment)}
                      onCreateItem={comment => handleAddComment(comment)}
                      user={user}
                    />
                  )}
                </div>
              </div>
              {caseList.length !== 0 && (
                <div className={classes.sectionRight}>
                  {user && isAdminUser && (
                    <Administration
                      caseObj={caseObj}
                      user={user}
                      setCaseObjCallback={setCaseObj}
                      setError={setError}
                      match={match}
                    />
                  )}
                  {user && isAdminUser && (
                    <div className={classes.container}>
                      <div className={classes.section}>
                        <Typography variant='subtitleBig' bold>
                          {global._('CaseDetails.CaseNotes')}
                        </Typography>
                      </div>
                      <div style={{ padding: '0 20px' }}>
                        {!caseObj.notes && (
                          <div className={classes.skeleton}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirectionn: 'row',
                                marginBottom: 5
                              }}
                            >
                              <Skeleton
                                variant='circle'
                                width={40}
                                height={40}
                              />
                              <Skeleton
                                variant='text'
                                style={{ flex: 1, marginLeft: 5 }}
                                height={35}
                              />
                            </div>
                            <Skeleton
                              animation='wave'
                              variant='rect'
                              width='100%'
                              height={100}
                            />
                          </div>
                        )}
                        {caseObj.notes && (
                          <Comment
                            items={caseObj.notes}
                            isCaseNotes
                            btnText={global._('Case.AddCaseNote')}
                            helpText={global._('Case.AddCaseNoteHelpText')}
                            btnVariant='outlined'
                            onDeleteItem={caseNote =>
                              handleDeleteCaseNote(caseNote)
                            }
                            onEditItem={caseNote =>
                              handleEditCaseNote(caseNote)
                            }
                            onCreateItem={caseNote =>
                              handleAddCaseNote(caseNote)
                            }
                            user={user}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

CaseDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  token: PropTypes.string
}

CaseDetails.defaultProps = {
  breadcrumbItems: [],
  token: ''
}

const CaseDetailsWithStyles = injectSheet(styles)(CaseDetails)
export default CaseDetailsWithStyles
