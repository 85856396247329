import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import injectSheet from 'react-jss'
import Typography from './Typography'
import Row from './Row'
import Column from './Column'

const styles = {
  root: {},
  row: {
    width: '100%'
  },
  column: {
    width: '100%',
    padding: '12px 0'
  }
}

const RadioButton = ({
  classes,
  columnStyle,
  checked,
  onClick,
  title,
  subtitle,
  value,
  lessMargin,
  ...rest
}) => (
  <Row
    onClick={onClick}
    align='center'
    className={classes.row}
    style={{ ...rest.style }}
    {...rest}>
    <Icon style={{ margin: lessMargin ? '18px 26px' : 24 }}>
      {`radio_button_${checked ? 'checked' : 'unchecked'}`}
    </Icon>
    <Column
      className={classes.column}
      style={{ ...columnStyle }}
      align='flex-start'>
      <Typography bold>{title}</Typography>
      <Typography faded>{subtitle}</Typography>
    </Column>
  </Row>
)

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  columnStyle: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  classes: PropTypes.object
}

RadioButton.defaultProps = {
  columnStyle: undefined,
  title: null,
  subtitle: null,
  classes: null
}

const RadioButtonWithStyles = injectSheet(styles)(RadioButton)
export default RadioButtonWithStyles
