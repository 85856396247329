import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import axios from 'axios'
import { CircularProgress, Icon } from '@material-ui/core'
import { sleep } from 'src/utils/helpers'
import Typography from 'src/components/Typography'
import Paper from 'src/components/Paper'
import Button from 'src/components/Button'
import Notification from 'src/common/Notification'
import { getUserMessage } from 'src/utils/BankIDClientMessages'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import NavigationBar from 'src/components/NavigationBar'
import MobileContainer from 'src/components/MobileContainer'
import ScrollView from 'src/components/ScrollView'
import PropTypes from 'prop-types'

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}

interface IDigitalSigningProps {
  classes: { [key: string]: string }
  signURL: string
  requestData: any
  title?: string
  onCancel?: () => void
  onCompletion?: () => void
  onFailure?: (arg0: Error) => void
}

const DigitalSigning = ({
  classes,
  signURL,
  requestData,
  title,
  onCancel,
  onCompletion,
  onFailure,
  showTabBar
}: IDigitalSigningProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [autoStartToken, setAutoStartToken] = useState(null)
  const [vendor, setVendor] = useState(null)
  const [orderRef, setOrderRef] = useState(null)
  const [status, setStatus] = useState(null)
  const [interval, setInterval] = useState(2000)
  const [hintCode, setHintCode] = useState(null)

  const cancel = async () => {
    if (orderRef) {
      try {
        await axios.delete(`${signURL}/${orderRef}`)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global.translate(msg))
      }
    }
    if (onCancel) {
      onCancel()
    }
  }

  const collect = async anOrderRef => {
    try {
      const { data } = await axios.get(`${signURL}/${anOrderRef}`)

      setStatus(data.status)
      setHintCode(data.hintCode)

      if (data.status === 'PENDING') {
        await sleep(interval)
        return collect(anOrderRef)
      }
      if (data.status === 'COMPLETE') {
        setIsLoading(false)
        if (onCompletion) {
          onCompletion()
        }
        return data
      }
      if (data.status === 'FAILED') {
        setIsLoading(false)
        setHintCode(data.hintCode)
        setStatus(data.status)
        if (onFailure) {
          onFailure(new Error('Error.Sign.Failed'))
        }
        return null
      }
      return null
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  const request = async () => {
    try {
      const { data } = await axios.post(signURL, requestData)

      setAutoStartToken(data.autoStartToken)
      setOrderRef(data.orderRef)
      setVendor(data.vendor)
      if (data.interval) {
        setInterval(data.interval * 1000)
      }

      if (data.orderRef) {
        setIsLoading(true)
        collect(data.orderRef)
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setIsLoading(false)
      setError(msg)
      if (onFailure) {
        onFailure(e)
      }
    }
  }

  useEffect(() => {
    request()
  }, [])

  const { isMobile } = useWindowDimensions()

  const content = (
    <Paper
      alignItems='center'
      fixedWidth={!isMobile}
      style={{ paddingTop: 70, paddingBottom: 30 }}
      noShadow={isMobile}
    >
      <div style={{ maxWidth: 340, width: '100%', textAlign: 'center' }}>
        {error && (
          <Notification
            type='error'
            message={error}
            style={{ margin: '20px 0' }}
          />
        )}
        <div>
          <div>
            {vendor === 'SE_BANKID' && (
              <img
                height={50}
                src='/logos/bankid-white.png'
                style={{ filter: 'invert(1)' }}
              />
            )}
            <div>
              {isLoading && (
                <CircularProgress
                  size={40}
                  thickness={6}
                  style={{
                    color: 'var(--color-primary)',
                    margin: '30px 0 20px 0'
                  }}
                />
              )}
              {vendor === 'SE_BANKID' && hintCode !== 'outstandingTransaction' && (
                <Typography block style={{ textAlign: 'center' }}>
                  {getUserMessage(status, hintCode)}
                </Typography>
              )}
            </div>
          </div>
          {vendor === 'SE_BANKID' && isLoading && (
            <Button
              variant='primary'
              key='start-app-button'
              style={{ marginTop: 20 }}
              onClick={() => {
                window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`
              }}
            >
              {global._('MyKeys.Request.BankId.StartApp')}
            </Button>
          )}
        </div>
        <Button variant='none' style={{ marginTop: 60 }} onClick={cancel}>
          {global._('Common.Cancel')}
        </Button>
      </div>
    </Paper>
  )

  if (isMobile) {
    return (
      <MobileContainer showTabBar={showTabBar} background='var(--color-white)'>
        <NavigationBar title={title} backAction={cancel} />
        <ScrollView id='scrollable-container'>{content}</ScrollView>
      </MobileContainer>
    )
  }
  return (
    <div className={classes.root}>
      <Typography variant='h1' style={{ marginBottom: 50 }}>
        {title}
      </Typography>
      {content}
    </div>
  )
}

DigitalSigning.propTypes = {
  showTabBar: PropTypes.bool
}

DigitalSigning.defaultProps = {
  showTabBar: true
}

export default injectSheet(styles)(DigitalSigning)
