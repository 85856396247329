import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import Notification from 'src/common/Notification'
import Typography from 'src/components/Typography'
import withUser from 'src/utils/withUser'
import { DeviceConfig, DeviceOperation } from 'src/utils/constants'
import { publicRequest } from 'src/utils/helpers'
import { AccessTime } from '@material-ui/icons'
import { isLockAccessible } from './LocksList'

const styles = {}

const DELAY = 750

const SingleLock = props => {
  const {
    history,
    user,
    lock,
    setLock,
    button,
    noAccess,
    error,
    setError,
    shared
  } = props

  const [lockErrors, setLockErrors] = useState(null)
  const [activatedLock, setActivatedLock] = useState(null)
  const [isOpeningLock, setIsOpeningLock] = useState(false)
  const [openActionComplete, setOpenActionComplete] = useState(false)
  const [progress, setProgress] = useState(0)

  const timeoutRef = useRef(null)

  const config = lock ? lock.device.config : null
  const stats = lock ? lock.device.stats : null

  const activated = activatedLock === lock /* Mouse/touch down */
  const isOpening = isOpeningLock /* Pulse operation */
  const highlighted = activated || isOpening

  const isAccessible = isLockAccessible(lock)

  const urlSearchParams = new URLSearchParams(history.location.search)

  const handleOpenLock = async () => {
    setError(null)
    try {
      /* PULSE ACTIVATION OPERATION */
      if (config === DeviceConfig.PULSE) {
        setIsOpeningLock(true)
        if (shared) {
          await publicRequest(
            'put',
            `/v1/access/public/locks/${lock.id}/action/${DeviceOperation.PULSE}?response=true`
          )
        } else {
          await axios.put(
            `/v1/organisations/${lock.organisationId}/locks/${lock.id}/action/${DeviceOperation.PULSE}?response=true`
          )
        }
        if (config === DeviceConfig.PULSE) {
          setProgress(0)
        } else {
          setProgress(100)
        }

        const MAX_VALUE = 100
        const runtime = lock.pulseTimeMs / MAX_VALUE
        const startTime = Date.now()

        const interval = setInterval(() => {
          const elapsed = Date.now() - startTime
          const curProgress = elapsed / runtime
          if (curProgress > MAX_VALUE) {
            clearInterval(interval)
            setTimeout(() => {
              setProgress(0)
            }, 200)
          } else {
            Promise.resolve().then(() => {
              setProgress(Math.round(curProgress))
            })
          }
        }, 20)
      } else if (config === DeviceConfig.SAFETRON3600) {
        const updatedLock = { ...lock }

        let lockRequest
        let unlockRequest
        if (shared) {
          lockRequest = () =>
            publicRequest(
              'put',
              `/v1/access/public/locks/${lock.id}/action/${DeviceOperation.LOCK}?response=true`
            )
          unlockRequest = () =>
            publicRequest(
              'put',
              `/v1/access/public/locks/${lock.id}/action/${DeviceOperation.UNLOCK}?response=true`
            )
        } else {
          lockRequest = () =>
            axios.put(
              `/v1/organisations/${lock.organisationId}/locks/${lock.id}/action/${DeviceOperation.LOCK}?response=true`
            )
          unlockRequest = () =>
            axios.put(
              `/v1/organisations/${lock.organisationId}/locks/${lock.id}/action/${DeviceOperation.UNLOCK}?response=true`
            )
        }
        /* OPERATIONS */
        if (stats && stats.status.input1) {
          /* check that bolt is in */
          /* LOCK OPERATION */
          const { data } = await lockRequest()
          if (!data.success) {
            if (data.errorCode === 'ERR_LOCK_FAILED_DOOR_OPEN') {
              return setLockErrors(
                global._('MyLocks.Status.FailedToLockDoorOpen')
              )
            }
            return setLockErrors(
              data.errorMessage || data.errorCode || data.message
            )
          }
          updatedLock.device.stats.status.input1 = 0 /* bolt is not in */
          updatedLock.device.stats.status.input2 = 1 /* bolt is out */
        } else if (stats && stats.status.input2) {
          /* check that bolt is out */
          /* UNLOCK OPERATION */
          const { data } = await unlockRequest()
          if (!data.success) {
            return setLockErrors(data.errorMessage || data.errorCode)
          }

          updatedLock.device.stats.status.input1 = 1
          updatedLock.device.stats.status.input2 = 0
        } else {
          /* RECOVERY ATTEMPT -> UNLOCK */
          const { data } = await unlockRequest()
          if (!data.success) {
            return setLockErrors(data.errorMessage || data.errorCode)
          }

          updatedLock.device.stats.status.input1 = 1
          updatedLock.device.stats.status.input2 = 0
        }
        setLock(updatedLock)
      }
    } catch (e) {
      setLockErrors(global._('MyLocks.ActionError'))
    }
    setIsOpeningLock(false)
  }

  const handleMouseDown = () => evt => {
    setLockErrors(null)
    evt.preventDefault()
    /* Don't handle open locks */
    if (progress || noAccess) return

    setActivatedLock(lock)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setActivatedLock(null)
      handleOpenLock(lock)
    }, DELAY)
    return false
  }

  const handleMouseUp = () => {
    setActivatedLock(null)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  let statusText = global._('MyLocks.Status.Locked')
  let iconName = 'lock'

  if (noAccess || !isAccessible) {
    statusText = global._('DelegatedLocks.NoAccess')
  } else if (lockErrors) {
    /* Errors */
    statusText = lockErrors
  } else if (config === DeviceConfig.PULSE) {
    /* Pulse operations */
    if (isOpening) {
      statusText = global._('MyLocks.Status.Opening')
    } else if (progress) {
      statusText = global._('MyLocks.Status.Unlocked')
      iconName = 'lock_open'
    } else {
      statusText = global._('MyLocks.Status.Locked')
    }
  } else if (config === DeviceConfig.SAFETRON3600) {
    /* SAFETRON operations */
    if (stats && stats.status.input2 && stats.status.input3) {
      statusText = global._('MyLocks.Status.Locked')
    } else if (stats && (stats.status.input1 || stats.status.input3)) {
      statusText = global._('MyLocks.Status.Unlocked')
      iconName = 'lock_open'
    } else {
      statusText = global._('MyLocks.Status.Unknown')
    }
  }

  useEffect(() => {
    if (
      lock &&
      !openActionComplete &&
      urlSearchParams.get('action') === 'open'
    ) {
      setOpenActionComplete(true)
      handleOpenLock()
    }
  }, [lock, openActionComplete])

  const { isMobile } = useWindowDimensions()

  if (!isMobile) {
    history.push('/dashboard')
  }

  return (
    <>
      {error && <Notification type='error' message={error} />}
      {lock && (
        <>
          {lock.device && lock.device.description && (
            <div style={{ marginBottom: 40, marginTop: -24 }}>
              <Typography
                variant='body2'
                bold
                style={{ color: 'var(--color-white)' }}
                centerAlign
                block
              >
                {lock.device.description}
              </Typography>
            </div>
          )}
          {statusText && (
            <div style={{ marginBottom: 16 }}>
              <Typography
                variant='body2'
                bold
                style={{ color: 'var(--color-white)' }}
                centerAlign
                block
              >
                {!isAccessible && <AccessTime style={{ marginRight: 5 }} />}
                {statusText}
              </Typography>
            </div>
          )}
          {!noAccess && isAccessible && (
            <Typography
              variant='body'
              block
              style={{ color: 'var(--color-light-grey)', marginBottom: 24 }}
              italic
              small
              centerAlign
            >
              {global._('SingleLock.UnlockHelpText')}
            </Typography>
          )}
          <div style={{ marginBottom: 50 }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: isAccessible ? 1 : 0.3
              }}
            >
              <div
                style={{
                  width: 250,
                  height: 250,
                  backgroundColor: 'var(--color-white)',
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 30,
                  position: 'relative',
                  cursor: 'pointer',
                  boxShadow: progress
                    ? undefined
                    : 'inset 0 3px 15px 4px rgb(0 0 0 / 50%)'
                }}
                onTouchStart={isAccessible ? handleMouseDown(lock) : undefined}
                onTouchEnd={isAccessible ? handleMouseUp : undefined}
                onTouchCancel={isAccessible ? handleMouseUp : undefined}
                onMouseDown={isAccessible ? handleMouseDown(lock) : undefined}
                onMouseUp={isAccessible ? handleMouseUp : undefined}
                onMouseLeave={isAccessible ? handleMouseUp : undefined}
              >
                {lock && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        transition: highlighted ? 'all 0.8s' : undefined,
                        width: highlighted || progress || noAccess ? 250 : 102,
                        height: highlighted || progress || noAccess ? 250 : 102,
                        background: progress
                          ? 'var(--color-primary-tone)'
                          : noAccess
                          ? 'var(--color-middle-grey)'
                          : 'var(--color-primary)',
                        borderRadius: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                      }}
                    />
                    {!!progress && (
                      <div
                        style={{
                          display: 'flex',
                          width: 250,
                          height: 250,
                          background: 'transparent',
                          borderRadius: '100%',
                          position: 'absolute',
                          border: '11px solid var(--color-white)',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)'
                        }}
                      />
                    )}
                    {!!progress && (
                      <CircularProgress
                        variant='determinate'
                        thickness={2}
                        size={250}
                        value={progress}
                        style={{ position: 'absolute', left: 0, top: 0 }}
                      />
                    )}
                    <Icon
                      style={{
                        fontSize: 45,
                        position: 'relative',
                        color:
                          highlighted || progress
                            ? 'var(--color-black)'
                            : 'var(--color-white)'
                      }}
                    >
                      {iconName}
                    </Icon>
                  </>
                )}
              </div>
            </div>
          </div>
          {button && button(lock)}
        </>
      )}
    </>
  )
}

SingleLock.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  noAccess: PropTypes.bool,
  shared: PropTypes.bool
}

SingleLock.defaultProps = {
  noAccess: false,
  shared: false
}

const SingleLockWithStyles = injectSheet(styles)(SingleLock)
const SingleLockWithStylesAndUser = withUser(SingleLockWithStyles)
export default SingleLockWithStylesAndUser
