import Icon from '@material-ui/core/Icon'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Theme from '../../helpers/Theme'
import Row from '../../components/Row'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import Column from '../../components/Column'
import Notification from '../../common/Notification'
import useWindowDimensions from '../../utils/useWindowDimensions'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import MobileContainer from '../../components/MobileContainer'

const styles = {
  root: {
    backgroundColor: '#fdfdfd',
    display: 'flex',
    flex: 1,
    minHeight: '100%',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: 'url("/bg-image.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundOrigin: 'padding-box'
  },
  contentContainer: {
    width: 760,
    flex: 1,
    minHeight: '100%',
    maxWidth: '100%',
    height: '100%',
    backgroundColor: Theme.Colors.WHITE,
    overflow: 'hidden'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    borderRadius: 500,
    backgroundColor: Theme.Colors.LIGHT_GREY,
    marginRight: 20
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${Theme.Colors.DIVIDER_LIGHT}`,
    padding: '22px 47px',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  '@media (max-width: 1200px)': {
    contentContainer: {
      paddingTop: 20
    }
  },

  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */
  '@media (max-width: 900px)': {
    root: {}
  }
}

const QuoteRequest = props => {
  const {
    classes,
    user,
    computedMatch: {
      params: { propertyId }
    },
    history
  } = props

  const { isMobile } = useWindowDimensions()
  const [isLoading, setIsLoading] = useState(false)
  const [property, setProperty] = useState(null)
  const [requestedKeysCount, setRequestedKeysCount] = useState('')
  const [notes, setNotes] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    ;(async () => {
      const { data: aProperty } = await axios.get(
        `/v1/properties/${propertyId}?blueprints=true`
      )
      setProperty(aProperty)
    })()
  }, [])

  const handleSend = async () => {
    const data = {}
    // Both required
    if (requestedKeysCount) data.requestedKeysCount = requestedKeysCount
    else data.requestedKeysCount = null

    if (notes) data.notes = notes
    else data.notes = null

    setIsLoading(true)
    try {
      data.user = user
      if (Object.keys(data).length > 0) {
        await axios.post(`/v1/properties/${property.id}/quotes`, data)
      }
      setIsLoading(false)
      history.push('quoterequest/success')
    } catch (e) {
      const msg = global._('QuoteRequest.ErrorMsg')
      setError(msg)
      setIsLoading(false)
    }
  }

  if (isMobile) {
    return (
      <MobileContainer>
        <NavigationBar
          title={global._('Properties.RequestForQuotation')}
          backAction={() => history.goBack()}
        />
        <ScrollView>
          <Row
            style={{
              borderBottom: `1px solid ${Theme.Colors.DIVIDER_LIGHT}`,
              padding: 20
            }}
          >
            <div className={classes.iconContainer}>
              {isLoading ? (
                <CircularProgress
                  style={{ color: Theme.Colors.PRIMARY }}
                  size={18}
                  thickness={6}
                />
              ) : (
                <Icon style={{ fontSize: 30, color: '#8e8e8e' }}>business</Icon>
              )}
            </div>
            <Column align='flex-start' justify='flex-start'>
              <Typography variant='small' style={{ fontSize: 14 }}>
                {global._('BlueprintObjectList.Property')}
              </Typography>
              <Typography bold={600} variant='body' style={{ fontSize: 18 }}>
                {property && property.name}
              </Typography>
            </Column>
          </Row>
          <div style={{ padding: 20 }}>
            <Column align='flex-start'>
              <Row style={{ width: '100%' }}>
                <TextField
                  style={{
                    width: '100%'
                  }}
                  variant='filled'
                  label={global._('Properties.InputNumberOfKeys')}
                  value={requestedKeysCount}
                  onChange={e => setRequestedKeysCount(e.target.value)}
                />
              </Row>
              <Row style={{ width: '100%' }}>
                <Typography
                  style={{ color: '#8E8E8E', marginLeft: 15, marginTop: 6 }}
                  variant='small'
                >
                  {global._('Common.NotRequired')}
                </Typography>
              </Row>
            </Column>
            <Column align='flex-start' style={{ marginTop: 20 }}>
              <Row style={{ width: '100%' }}>
                <TextField
                  style={{
                    width: '100%'
                  }}
                  multiline
                  variant='filled'
                  label={global._('BlueprintTool.ObjectModal.NotesPlaceholder')}
                  value={notes}
                  onChange={e => setNotes(e.target.value)}
                />
              </Row>
              <Row style={{ width: '100%' }}>
                <Typography
                  style={{ color: '#8E8E8E', marginLeft: 15, marginTop: 6 }}
                  variant='small'
                >
                  {global._('Common.NotRequired')}
                </Typography>
              </Row>
            </Column>
          </div>
          <div
            style={{
              width: '100%',
              padding: 20
            }}
          >
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ textAlign: 'center', marginBottom: 20 }}
              />
            )}
            <Button
              data-cy='button-send-quote-request'
              variant='primary'
              onClick={handleSend}
            >
              {global._('Skicka')}
            </Button>
          </div>
        </ScrollView>
      </MobileContainer>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.container}>
          <Column align='flex-start' style={{ paddingTop: 30 }}>
            <Row style={{ paddingBottom: 25 }}>
              <Typography variant='title' bold={600}>
                {global._('Properties.RequestForQuotation')}
              </Typography>
            </Row>
            <Row
              justify='space-between'
              align='center'
              style={{ paddingBottom: 16, width: '100%' }}
            >
              <Row>
                <div className={classes.iconContainer}>
                  {isLoading ? (
                    <CircularProgress
                      style={{ color: Theme.Colors.PRIMARY }}
                      size={18}
                      thickness={6}
                    />
                  ) : (
                    <Icon style={{ fontSize: 30, color: '#8e8e8e' }}>
                      business
                    </Icon>
                  )}
                </div>
                <Column align='flex-start' justify='flex-start'>
                  <Typography variant='small' style={{ fontSize: 14 }}>
                    {global._('BlueprintObjectList.Property')}
                  </Typography>
                  <Typography
                    bold={600}
                    variant='body'
                    style={{ fontSize: 18 }}
                  >
                    {property && property.name}
                  </Typography>
                </Column>
              </Row>
            </Row>
          </Column>
        </div>
        <div className={classes.container}>
          <Column align='flex-start'>
            <TextField
              style={{
                width: '100%'
              }}
              variant='filled'
              label={global._('Properties.InputNumberOfKeys')}
              value={requestedKeysCount}
              onChange={e => setRequestedKeysCount(e.target.value)}
            />
            <Typography
              style={{ color: '#8E8E8E', marginLeft: 15, marginTop: 6 }}
              variant='small'
            >
              {global._('Common.NotRequired')}
            </Typography>
          </Column>
        </div>
        <div className={classes.container}>
          <Column align='flex-start'>
            <TextField
              style={{
                width: '100%'
              }}
              multiline
              variant='filled'
              label={global._('BlueprintTool.ObjectModal.NotesPlaceholder')}
              value={notes}
              onChange={e => setNotes(e.target.value)}
            />
            <Typography
              style={{ color: '#8E8E8E', marginLeft: 15, marginTop: 6 }}
              variant='small'
            >
              {global._('Common.NotRequired')}
            </Typography>
          </Column>
        </div>
        <div style={{ marginTop: '20%' }}>
          {error && (
            <div style={{ padding: '0px 47px' }}>
              <Notification
                type='error'
                message={error}
                style={{ textAlign: 'center' }}
              />
            </div>
          )}
          <Row flex justify='flex-end' style={{ padding: '50px 47px' }}>
            <Button
              variant='none'
              onClick={() => history.goBack()}
              style={{ marginRight: 10 }}
            >
              {global._('Common.Back')}
            </Button>
            <Button
              data-cy='button-send-quote-request'
              variant='primary'
              onClick={handleSend}
            >
              {global._('Common.Send')}
            </Button>
          </Row>
        </div>
      </div>
    </div>
  )
}

QuoteRequest.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  computedMatch: PropTypes.object.isRequired
}

const RegisterWithStyle = injectSheet(styles)(QuoteRequest)
export default RegisterWithStyle
