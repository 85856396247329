import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import Typography from './Typography'
import Modal from './Modal'
import Button from './Button'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import FullscreenModal from './FullscreenModal'

const styles = {
  icon: {
    fontSize: 30,
    transition: 'all 0.3s',
    cursor: 'pointer',
    marginTop: 3,
    '&:hover': {
      color: 'var(--color-primary)'
    }
  }
}

const VideoInstructionsModal = ({ classes, videoId }) => {
  const { isMobile } = useWindowDimensions()

  if (!videoId || isMobile) {
    return null
  }

  const [showInstructionsModal, setShowInstructionsModal] = useState(false)

  const instructionsLink = `https://www.youtube.com/embed/${videoId}`

  const playIcon = () => (
    <Icon
      className={classes.icon}
      style={{ marginLeft: isMobile ? 0 : 5 }}
      onClick={() => {
        setShowInstructionsModal(true)
      }}
    >
      play_circle_filled
    </Icon>
  )

  const videoIframe = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <iframe
        width='560'
        height='315'
        src={instructionsLink}
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </div>
  )

  return (
    <>
      {isMobile ? (
        <div
          onClick={() => {
            setShowInstructionsModal(true)
          }}
          style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}
        >
          {playIcon()}{' '}
          <Typography
            variant='body'
            bold
            style={{ marginLeft: 10, marginTop: 3 }}
          >
            {global._('Common.ShowInstructionVideo')}
          </Typography>
        </div>
      ) : (
        playIcon()
      )}
      {showInstructionsModal &&
        (isMobile ? (
          <FullscreenModal
            title={global._('Common.Instructions')}
            onClose={() => setShowInstructionsModal(false)}
            rightActionItem='close'
            higherZIndex
            padding
            content={videoIframe}
          />
        ) : (
          <Modal
            onClose={() => setShowInstructionsModal(false)}
            content={videoIframe}
            buttons={[
              <Button
                variant='none'
                onClick={() => setShowInstructionsModal(false)}
              >
                {global._('Common.Close')}
              </Button>
            ]}
          />
        ))}
    </>
  )
}

VideoInstructionsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  videoId: PropTypes.string
}

VideoInstructionsModal.defaultProps = {
  videoId: ''
}

export default injectSheet(styles)(VideoInstructionsModal)
