import { useEffect } from 'react'
import PropTypes from 'prop-types'
import useWindowDimensions from '../utils/useWindowDimensions'

const InfiniteScroll = props => {
  const { loading, hasMore, fetchMore, children, elementId } = props

  const { isMobile } = useWindowDimensions()

  useEffect(() => {
    const element = elementId ? document.getElementById(elementId) : window
    const eventListenerElement = elementId
      ? document.getElementById(elementId)
      : document

    const onScroll = () => {
      let scrollDistance =
        element.innerHeight + element.scrollY >=
        document.body.scrollHeight -
          69 * 5 /* trigger approx. 4-5 rows before end */

      if (isMobile && elementId) {
        scrollDistance =
          element.scrollTop + element.clientHeight >= element.scrollHeight - 100
      }

      if (scrollDistance && !loading && hasMore) {
        fetchMore()
      }
    }
    eventListenerElement.addEventListener('scroll', onScroll, false)

    return () =>
      eventListenerElement.removeEventListener('scroll', onScroll, false)
  }, [loading, hasMore, fetchMore])

  return children || null
}

InfiniteScroll.propTypes = {
  loading: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func
}

InfiniteScroll.defaultProps = {
  fetchMore: null,
  loading: false
}

export default InfiniteScroll
