import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import injectSheet from 'react-jss'
import NavigationBar from 'src/components/NavigationBar'
import MobileContainer from 'src/components/MobileContainer'
import { FormControl, Icon, MenuItem, TextField } from '@material-ui/core'
import ScrollView from 'src/components/ScrollView'
import Typography from 'src/components/Typography'
import RadioButton from 'src/components/RadioButton'
import RadioButtonGroup from 'src/components/RadioButtonGroup'
import Button from 'src/components/Button'
import Alert from 'src/components/Alert'
import Notification from 'src/common/Notification'
import {
  Country,
  PaymentMethods,
  ProductKeys,
  ProductVariants,
  ShippingTypes
} from 'src/utils/constants'
import FullscreenModal from 'src/components/FullscreenModal'
import { calculateVAT } from 'src/utils/helpers'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import Breadcrumbs from 'src/components/Breadcrumbs'
import Paper from 'src/components/Paper'
import Row from 'src/components/Row'
import ssn from 'src/utils/ssn'
import Shipping from './components/Shipping'

const styles = {
  root: {},
  content: {
    maxWidth: 600
  },
  section: {
    padding: 20,
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--divider-color)'
    }
  },

  '@media (max-width: 900px)': {
    section: {
      padding: 'var(--section-padding-default)',
      '&:not(:last-child)': {
        borderBottom: '1px solid var(--divider-color)'
      }
    }
  }
}

const PaymentDetails = ({ classes, history, user }) => {
  const { isMobile } = useWindowDimensions()
  const urlParams = new URLSearchParams(window.location.search)
  const productKey = urlParams.get('product')
  const variant = urlParams.get('variant')
  let keyId = urlParams.get('keyId')
  const quantity = urlParams.get('quantity')
  const description = urlParams.get('description')
  const color = urlParams.get('color')

  if (keyId && (keyId === 'null' || keyId === 'undefined')) {
    keyId = undefined
  }

  const _initialShowKeyDescriptionModal =
    !keyId &&
    (variant === ProductVariants.Keys.MECHANICAL ||
      variant === ProductVariants.Keys.RFID)
  const _initialShowKeyColorModal = !keyId && !_initialShowKeyDescriptionModal

  const isLostKeyOrder = productKey === ProductKeys.KEY_REPLACEMENT

  const [error, setError] = useState(null)
  const [productPrice, setProductPrice] = useState(539)
  const [productQuantity, setProductQuantity] = useState(quantity || 1)
  const [showShippingDetails, setShowShippingDetails] = useState(true)
  const [shippingDetails, setShippingDetails] = useState({
    type: urlParams.get('shippingType'),
    price: 0,
    address: urlParams.get('shippingAddress') || user.addressOne,
    postalCode: urlParams.get('shippingPostalCode') || user.zipCode,
    city: urlParams.get('shippingCity') || user.town,
    phone: urlParams.get('shippingPhone') || user.phone
  })
  const [showKeyDescriptionModal, setShowKeyDescriptionModal] = useState(
    _initialShowKeyDescriptionModal
  )
  const [showKeyColorModal, setShowKeyColorModal] = useState(
    _initialShowKeyColorModal
  )
  const [keyDescription, setKeyDescription] = useState(description)
  const [keyColor, setKeyColor] = useState(color)

  const paymentOptions = []
  if (user.organisation.country === Country.NO) {
    paymentOptions.push({
      label: global._('Common.PayWithNets'),
      value: PaymentMethods.NETS_EASY
    })
  } else {
    paymentOptions.push({
      label: global._('Common.PayWithSwish'),
      value: PaymentMethods.SWISH
    })
  }
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentOptions[0].value
  )

  useEffect(() => {
    ;(async () => {
      try {
        const { data: products } = await axios.get(
          `/v1/organisations/${user.organisation.id}/products?key=${productKey}&variant=${variant}&checkout=true`
        )
        if (products.length > 0) {
          setProductPrice(products[0].price)
        } else {
          setError(global._('Error.Order.ProductUnavailable'))
        }
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    })()
  }, [])

  let totalPrice = productPrice * productQuantity
  if (shippingDetails.price) {
    totalPrice += Number.parseInt(shippingDetails.price, 10)
  }

  let navBarTitle = global._(
    `Keys.Order.Details.NavBarTitles.${productKey}.${variant}`
  )
  if (isMobile && showShippingDetails) {
    navBarTitle = global._('Keys.Order.Details.NavBarTitles.Shipping')
  }

  const modalButton = (
    <Button
      variant='primary'
      style={{
        width: '100%',
        marginTop: isMobile ? 0 : 20
      }}
      disabled={
        (showKeyDescriptionModal && !keyDescription) ||
        (showKeyColorModal && !keyColor)
      }
      onClick={() => {
        setShowKeyDescriptionModal(false)
        setShowKeyColorModal(false)
      }}
    >
      {global._('Common.Proceed')}
    </Button>
  )
  let content
  if (showKeyDescriptionModal) {
    content = (
      <div className={classes.section}>
        <Typography variant='body' bold style={{ marginBottom: 17 }} block>
          {global._(
            `Keys.Order.Details.DescriptionModal.${variant}.DescribeYourKey`
          )}
        </Typography>
        <Alert>
          {global._(
            `Keys.Order.Details.DescriptionModal.${variant}.DescribeYourKeyInfo`
          )}
        </Alert>
        <FormControl style={{ marginTop: 20 }} fullWidth>
          <TextField
            variant='filled'
            multiline
            rows='5'
            value={keyDescription}
            helperText={global._('Common.Required')}
            label={global._('Common.Description')}
            onChange={e => setKeyDescription(e.target.value)}
            InputProps={{
              shrink: !!keyDescription
            }}
          />
        </FormControl>
        {!isMobile && modalButton}
      </div>
    )
  } else if (showKeyColorModal) {
    content = (
      <div className={classes.section}>
        <FormControl fullWidth>
          <TextField
            variant='filled'
            value={keyColor}
            InputProps={{
              shrink: !!keyDescription
            }}
            helperText={global._('Common.Required')}
            label={global._('Keys.Order.Copy.KeyColor')}
            onChange={e => setKeyColor(e.target.value)}
          />
        </FormControl>
        {!isMobile && modalButton}
      </div>
    )
  } else if (showShippingDetails) {
    content = (
      <Shipping
        user={user}
        onCompletion={_shippingDetails => {
          setShippingDetails(_shippingDetails)
          setShowShippingDetails(false)
        }}
        defaultValues={shippingDetails}
        productVariant={variant}
      />
    )
  } else {
    content = (
      <>
        {error && (
          <Notification
            type='error'
            message={error}
            style={{ margin: '20px 0' }}
          />
        )}
        {!isLostKeyOrder && (
          <div className={classes.section}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Typography variant='body' bold>
                {global._(`Keys.Order.Checkout.Info.${variant}.ProductTitle`)}
              </Typography>
              <Typography variant='subtitle' normalLineHeight>
                {`${productPrice} ${global._('Common.Currency')}`}
              </Typography>
            </div>
            <FormControl
              fullWidth
              className={classes.formControl}
              style={{ marginBottom: 10 }}
            >
              <TextField
                variant='filled'
                select
                name='object-type-select'
                label={global._('Common.Quantity')}
                style={{ marginTop: 20 }}
                value={productQuantity}
                onChange={e => setProductQuantity(+e.target.value)}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(q => (
                  <MenuItem key={`quantity-${q}`} value={q}>
                    {q}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        )}
        <div className={classes.section}>
          <Row justify='space-between' style={{ marginBottom: 5 }}>
            <Typography variant='body'>
              {global._('Common.Products')}
            </Typography>
            <Typography variant='body' normalLineHeight>
              {`${productPrice * productQuantity} ${global._(
                'Common.Currency'
              )}`}
            </Typography>
          </Row>
          <Row justify='space-between' style={{ marginBottom: 10 }}>
            <Typography variant='body'>
              {global._('Common.Shipping')}
            </Typography>
            <Typography variant='body' normalLineHeight>
              {`${shippingDetails.price} ${global._('Common.Currency')}`}
            </Typography>
          </Row>
          <Row justify='space-between' style={{ marginBottom: 5 }}>
            <Typography variant='subtitle'>
              {global._('Common.Total')}
            </Typography>
            <Typography variant='subtitle' normalLineHeight>
              {`${totalPrice} ${global._('Common.Currency')}`}
            </Typography>
          </Row>
          <Row justify='space-between' style={{ marginBottom: 5 }}>
            <Typography variant='body2' faded>
              {global._('Common.VATInfo')}
            </Typography>
            <Typography variant='body' faded>
              {`${calculateVAT(totalPrice)} ${global._('Common.Currency')}`}
            </Typography>
          </Row>
        </div>
        <div className={classes.section}>
          <a
            style={{
              display: 'block'
              // marginLeft: 12
              // fontSize: 14
            }}
            href={global._('Links.StoreTerms')}
            target='_blank'
            rel='noreferrer noopener'
          >
            {global._('Links.StoreTermsText')}
          </a>
        </div>
        {paymentOptions[0].value !== PaymentMethods.NETS_EASY && (
          <div className={classes.section} style={{ padding: 0 }}>
            <RadioButtonGroup
              value={selectedPaymentMethod}
              onChange={setSelectedPaymentMethod}
              withoutBorder
            >
              {paymentOptions.map(p => (
                <RadioButton
                  key={p.value}
                  value={p.value}
                  title={p.label}
                  lessMargin
                />
              ))}
            </RadioButtonGroup>
          </div>
        )}
        <div className={classes.section}>
          <Button
            variant='primary'
            disabled={!totalPrice || !shippingDetails.type}
            style={{
              width: '100%'
            }}
            onClick={() => {
              let url = `/orders/checkout?product=${productKey}&variant=${variant}&quantity=${productQuantity}&description=${encodeURI(
                keyDescription
              )}&color=${encodeURI(
                keyColor
              )}&paymentMethod=${selectedPaymentMethod}&keyId=${
                keyId || ''
              }&shippingType=${shippingDetails.type}`

              if (shippingDetails.type === ShippingTypes.SEND) {
                url += `&shippingAddress=${shippingDetails.address}`
                url += `&shippingPostalCode=${shippingDetails.postalCode}`
                url += `&shippingCity=${shippingDetails.city}`
                url += `&shippingPhone=${shippingDetails.phone}`
              }

              if (selectedPaymentMethod === PaymentMethods.NETS_EASY) {
                url += '&identify=1'
              }
              history.push(url)
            }}
          >
            {selectedPaymentMethod === PaymentMethods.NETS_EASY &&
              global._('Keys.Order.IdControl.VerifyIdentityButtonLabel')}
            {selectedPaymentMethod === PaymentMethods.SWISH &&
              global._('Common.Pay')}
          </Button>
          {isMobile && (
            <Button
              variant='none'
              style={{ marginTop: 'var(--section-padding-default)' }}
              onClick={() => history.push('/orders')}
            >
              {global._('Common.Cancel')}
            </Button>
          )}
        </div>
      </>
    )
  }

  if (isMobile) {
    return (
      <MobileContainer background='var(--color-white)'>
        <NavigationBar
          title={navBarTitle}
          backAction={() => {
            if (showShippingDetails) {
              history.goBack()
            } else {
              setShowShippingDetails(true)
            }
          }}
        />
        {(showKeyDescriptionModal || showKeyColorModal) && (
          <FullscreenModal
            title={navBarTitle}
            onClose={() => history.goBack()}
            higherZIndex
            content={content}
            buttons={modalButton}
          />
        )}
        <ScrollView id='scrollable-container'>{content}</ScrollView>
      </MobileContainer>
    )
  }

  const crumbs = [
    {
      name: global._('Breadcrumbs.Dashboard'),
      path: '/'
    },
    {
      name: global._('Breadcrumbs.OrderKey'),
      path: '/orders'
    },
    {
      name: global._(`Products.Variants.Keys.${variant}`),
      path: `/orders/new?variant=${variant}&keyId=${keyId}`
    },
    {
      name: navBarTitle
    }
  ]

  if (!keyId) {
    crumbs.splice(2, 0, {
      name: global._('Breadcrumbs.KeyVariant'),
      path: '/orders/new'
    })
  }

  return (
    <div>
      <Breadcrumbs breadcrumbItems={crumbs} />
      <Row style={{ width: '100%', marginTop: 30 }}>
        <Typography variant='title' bold>
          {navBarTitle}
        </Typography>
        <Button
          variant='outlined'
          style={{
            marginLeft: 'auto',
            marginRight: 16
          }}
          onClick={() => {
            if (!showShippingDetails) {
              setShowShippingDetails(true)
            } else if (keyId) {
              history.goBack()
            } else if (!showKeyColorModal && !showKeyDescriptionModal) {
              setShowKeyDescriptionModal(_initialShowKeyDescriptionModal)
              setShowKeyColorModal(_initialShowKeyColorModal)
            } else {
              /* Go back to product selection */
              history.push(`/orders/new?variant=${variant}`)
            }
          }}
        >
          {global._('Common.Back')}
        </Button>
        <Button variant='outlined' onClick={() => history.push('/')}>
          {global._('Common.Cancel')}
        </Button>
      </Row>
      <div style={{ height: 20 }} />
      <div className={classes.content}>
        <Paper noPadding>{content}</Paper>
        {/* <Grid container spacing={3}> */}
      </div>
    </div>
  )
}

PaymentDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const PaymentDetailsWithStyles = injectSheet(styles)(PaymentDetails)
export default PaymentDetailsWithStyles
