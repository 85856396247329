import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import NavigationBar from 'src/components/NavigationBar'
import Typography from 'src/components/Typography'
import ScrollView from 'src/components/ScrollView'
import Button from 'src/components/Button'
import SingleLockLayout from 'src/components/SingleLock'
import withUser from 'src/utils/withUser'
import MobileContainer from 'src/components/MobileContainer'
import FullscreenModal from 'src/components/FullscreenModal'
import moment from 'moment'
import { isLockDisabled, publicRequest } from 'src/utils/helpers'

const styles = {}

const SingleDelegatedLock = props => {
  const { history, user, match } = props

  /* State */
  const [lock, setLock] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInformationModal, setShowInformationModal] = useState(null)

  const { lockId } = match.params

  const getLock = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const { data } = await publicRequest(
        'get',
        `/v1/access/public/locks/${lockId}`
      )
      setLock(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setLock(null)
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getLock()
  }, [])

  const { isMobile } = useWindowDimensions()

  const InfoRow = (description, value) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 11
      }}
    >
      <Typography faded>{description}</Typography>
      <Typography bold>{value}</Typography>
    </div>
  )

  return (
    <MobileContainer showTabBar={false}>
      <NavigationBar
        title={(lock && lock.name) || ''}
        backAction={() => history.goBack()}
      />
      {showInformationModal && (
        <FullscreenModal
          title={global._('Common.MoreInfo')}
          onClose={() => setShowInformationModal(false)}
          rightActionItem='close'
          content={
            <div style={{ padding: 'var(--section-padding-default)' }}>
              {InfoRow(
                global._('DelegatedAccess.SingleDelegatedLock.SharedBy'),
                (lock.delegator && lock.delegator.name) || '-'
              )}
              {InfoRow(
                global._('DelegatedAccess.SingleDelegatedLock.AccessFrom'),
                lock.unlimited
                  ? global._('Common.UntilFurtherNotice')
                  : lock.from && moment(lock.from).format('YYYY-MM-DD HH:mm')
              )}
              {InfoRow(
                global._('DelegatedAccess.SingleDelegatedLock.AccessTo'),
                lock.unlimited
                  ? global._('Common.UntilFurtherNotice')
                  : lock.to && moment(lock.to).format('YYYY-MM-DD HH:mm')
              )}
            </div>
          }
        />
      )}
      <ScrollView
        style={{ backgroundColor: 'var(--color-black)' }}
        id='scrollable-container'
        fullHeight
      >
        <div style={{ padding: 'var(--section-padding-default)' }}>
          <SingleLockLayout
            shared
            lock={lock}
            setLock={setLock}
            history={history}
            user={user}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            error={error}
            setError={setError}
            button={() => (
              <Button
                variant='primary'
                style={{ marginBottom: 40 }}
                onClick={() => setShowInformationModal(true)}
              >
                {global._('Common.MoreInfo')}
              </Button>
            )}
            noAccess={isLockDisabled(lock)}
          />
        </div>
      </ScrollView>
    </MobileContainer>
  )
}

SingleDelegatedLock.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const SingleDelegatedLockWithStyles = injectSheet(styles)(SingleDelegatedLock)
const SingleDelegatedLockWithStylesAndUser = withUser(
  SingleDelegatedLockWithStyles
)
export default SingleDelegatedLockWithStylesAndUser
