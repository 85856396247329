import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  TextField,
  Typography
} from '@material-ui/core'
import { useTheme, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 200
  },
  helperText: {
    paddingLeft: theme.spacing(2)
  },
  input: {
    display: 'flex',
    padding: '27px 0px 10px 12px',
    height: '26px'
  },
  formControl: {
    marginBottom: theme.spacing(2)
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  singleValue: {
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 6,
    marginTop: theme.spacing(0),
    left: 0,
    right: 0
  }
})

const selectStyles = {
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: base => ({
    ...base,
    color: useTheme().palette.text.primary,
    '& input': {
      font: 'inherit'
    }
  })
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}
inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props

  return (
    <TextField
      fullWidth
      variant='filled'
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  )
}
Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  selectProps: PropTypes.object.isRequired
}
Control.defaultProps = {
  children: null
}

const emptyValue = ' '
function Option(props) {
  const { children, innerProps, innerRef, isFocused, value } = props
  const customFontWeight = value === emptyValue ? 'bold' : 400

  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      component='div'
      style={{
        fontWeight: customFontWeight
      }}
      {...innerProps}>
      {children}
    </MenuItem>
  )
}
Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  value: PropTypes.string.isRequired
}
Option.defaultProps = {
  children: null,
  innerRef: null,
  isFocused: false,
  isSelected: false
}

function Placeholder(props) {
  const { children, innerProps, selectProps } = props
  return (
    <Typography
      color='textSecondary'
      className={selectProps.classes.placeholder}
      {...innerProps}>
      {children}
    </Typography>
  )
}
Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
}
Placeholder.defaultProps = {
  children: null,
  innerProps: null
}

function SingleValue(props) {
  const { children, innerProps, selectProps } = props
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  )
}
SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
}
SingleValue.defaultProps = {
  children: null,
  innerProps: null
}

function ValueContainer(props) {
  const { children, selectProps } = props
  return <div className={selectProps.classes.valueContainer}>{children}</div>
}
ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
}
ValueContainer.defaultProps = {
  children: null
}

function Menu(props) {
  const { children, innerProps, selectProps } = props
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  )
}
Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
}
Menu.defaultProps = {
  children: null,
  innerProps: null
}

const components = {
  Control,
  Menu,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
}

const AutocompleteCustomOtherSelect = props => {
  const {
    classes,
    isClearable,
    helperText,
    label,
    noOptionsMessage,
    options,
    otherOptionLabel,
    otherTextFieldHelpText,
    otherTextFieldLabel,
    onValueChange,
    placeholder,
    value
  } = props

  const otherOption = {
    label: otherOptionLabel,
    value: emptyValue
  }

  const [selectedValue, setSelectedValue] = useState()
  const [otherDescription, setOtherDescription] = useState('')
  const [showDescription, setShowDescription] = useState(false)
  const [localOptions, setLocalOptions] = useState([])

  useEffect(() => {
    setLocalOptions([otherOption, ...options])
    if (!selectedValue) {
      const index = options.findIndex(item => item.value === value)
      setOtherDescription(index < 0 ? value : '')
      if (index >= 0) {
        setSelectedValue(options[index])
        setShowDescription(selectedValue === emptyValue)
      }
    }
  }, [options])

  return (
    <>
      <FormControl className={classes.formControl} fullWidth>
        <Select
          data-cy='input-select'
          classes={classes}
          styles={selectStyles}
          name='select-single'
          TextFieldProps={{
            variant: 'filled',
            label,
            InputLabelProps: {
              htmlFor: 'select-single',
              shrink: true
            }
          }}
          noOptionsMessage={
            noOptionsMessage || (() => global._('Common.NoOptionsMessage'))
          }
          isClearable={isClearable}
          options={localOptions}
          components={components}
          value={selectedValue}
          placeholder={placeholder || ''}
          onChange={option => {
            setSelectedValue(option)
            const showTextField = option && option.value === emptyValue
            setShowDescription(showTextField)
            const description = otherDescription ? otherDescription.trim() : ''
            const optionValue =
              option === otherOption
                ? { label: description, value: description }
                : option
            onValueChange(optionValue)
          }}
        />
        {helperText && (
          <FormHelperText className={classes.helperText}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
      {showDescription && (
        <FormControl className={classes.formControl} fullWidth>
          <TextField
            label={otherTextFieldLabel}
            variant='filled'
            helperText={otherTextFieldHelpText}
            value={otherDescription || ''}
            onChange={event => {
              if (
                event &&
                event.target &&
                (event.target.value || event.target.value === '')
              ) {
                setOtherDescription(event.target.value)
                onValueChange({
                  label: event.target.value,
                  value: event.target.value
                })
              }
            }}
          />
        </FormControl>
      )}
    </>
  )
}

AutocompleteCustomOtherSelect.propTypes = {
  classes: PropTypes.object,
  isClearable: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  otherOptionLabel: PropTypes.string,
  otherTextFieldHelpText: PropTypes.string,
  otherTextFieldLabel: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string
}

AutocompleteCustomOtherSelect.defaultProps = {
  classes: {},
  isClearable: true,
  helperText: '',
  label: '',
  noOptionsMessage: () => global._('Common.NoOptionsMessage'),
  otherOptionLabel: '',
  otherTextFieldHelpText: '',
  otherTextFieldLabel: '',
  placeholder: undefined,
  value: undefined
}

export default withStyles(styles)(AutocompleteCustomOtherSelect)
