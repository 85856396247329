import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import { CaseTypes } from 'src/utils/constants'
import { Skeleton } from '@material-ui/lab'
import Row from 'src/common/Row'
import Typography from '../../../components/Typography'
import Administration from './Administration'
import Button from '../../../components/Button'
import Comment from './Comment'
import CaseData from './CaseData'
import FileContainer from '../../../components/FileContainer'
import DragZone from '../../../components/DragAndDrop/DragZone'
import Modal from '../../../components/Modal'
import Notification from '../../../common/Notification'
import NoResultsMessage from '../../../components/NoResultsMessage'
import NavigationBar from '../../../components/NavigationBar'
import ScrollView from '../../../components/ScrollView'
import NavigationList from '../../../components/NavigationList'
import FullscreenModal from '../../../components/FullscreenModal'
import MobileContainer from '../../../components/MobileContainer'
import { caseTypeUrlMap } from '../Cases'

const styles = {
  root: {},
  bottomBtnContainer: {
    padding: 20,
    width: '100%'
  },
  '@media (min-height: 700px)': {
    bottomBtnContainer: {
      position: 'fixed',
      bottom: 90
    }
  }
}

const CaseDetailsMobile = props => {
  const {
    classes,
    history,
    user,
    token,
    caseObj,
    setCaseObjCallback,
    changeCase,
    onRemoveFile,
    onFileSelected,
    handleAddComment,
    handleEditComment,
    handleDeleteComment,
    handleAddCaseNote,
    handleEditCaseNote,
    handleDeleteCaseNote,
    handleDeleteCase,
    isAdminUser,
    error,
    setError,
    caseIndex,
    caseList,
    isLoadingCase,
    match
  } = props

  /* Modals */
  const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false)
  const [fileMarkedForDelete, setFileMarkedForDelete] = useState(false)
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false)

  const navigate = navigation => () =>
    history.replace(`${window.location.search}#${navigation}`)
  const hashBack = () => history.replace(window.location.search)
  const listItems = [
    {
      visible: true,
      title: global._('CaseDetails.Details'),
      onClick: navigate('details'),
      icon: 'info'
    },
    {
      visible: true,
      title: global._('CaseDetails.Files'),
      onClick: navigate('files'),
      icon: 'photo'
    },
    {
      visible: true,
      title: global._('CaseDetails.Comments'),
      onClick: navigate('comments'),
      icon: 'comment'
    },
    {
      visible: isAdminUser,
      title: global._('Case.AdministrationTitle'),
      onClick: navigate('admin'),
      icon: 'settings'
    },
    {
      visible: isAdminUser,
      title: global._('CaseDetails.CaseNotes'),
      onClick: navigate('notes'),
      icon: 'description'
    }
  ]

  const navigation = window.location.hash

  const caseUrlType = match.url.includes('/orders') ? 'orders' : 'cases'

  return (
    <>
      {/* MODALS */}
      {navigation === '#details' && (
        <FullscreenModal
          title={global._('CaseDetails.Details')}
          onClose={hashBack}
          content={
            <MobileContainer>
              <ScrollView>
                <CaseData
                  caseObj={caseObj}
                  isAdminUser={isAdminUser}
                  setError={setError}
                  onDelete={() => handleDeleteCase()}
                />
              </ScrollView>
            </MobileContainer>
          }
        />
      )}
      {navigation === '#files' && (
        <FullscreenModal
          title={global._('CaseDetails.Files')}
          onClose={hashBack}
          content={
            <MobileContainer>
              <ScrollView style={{ padding: 20 }}>
                <Grid container>
                  {(!caseObj.files || caseObj.files.length === 0) && (
                    <NoResultsMessage
                      content={global._('CaseDetails.MobileFileEmptyState')}
                    />
                  )}
                  {caseObj.files &&
                    caseObj.files.length > 0 &&
                    caseObj.files.map(fileObj => (
                      <Grid
                        item
                        xs={12}
                        key={`file-container-item-${fileObj.id}`}
                        style={{ marginBottom: 10 }}
                      >
                        <FileContainer
                          file={fileObj.file}
                          name={fileObj.file.name}
                          size={fileObj.file.size}
                          type={fileObj.file.type}
                          src={`/v1/${caseUrlType}/${caseObj.id}/files/${fileObj.id}`}
                          onDelete={
                            fileObj.receipt
                              ? undefined
                              : () => setFileMarkedForDelete(fileObj)
                          }
                          token={token}
                        />
                      </Grid>
                    ))}
                </Grid>
                {fileMarkedForDelete && (
                  <Modal
                    onClose={() => setFileMarkedForDelete(null)}
                    title={global._('CaseDeleteFileModal.Title')}
                    content={
                      <div style={{ textAlign: 'left' }}>
                        <Typography
                          style={{
                            display: 'block',
                            paddingBottom: 12
                          }}
                          variant='body'
                        >
                          {global._('CaseDeleteFileModal.DeleteFile')}
                        </Typography>
                        <Typography
                          style={{
                            display: 'block',
                            paddingBottom: 12
                          }}
                          variant='subtitleSmall'
                        >
                          {fileMarkedForDelete.file.name}
                        </Typography>
                      </div>
                    }
                    buttons={[
                      <Button
                        variant='secondary'
                        onClick={() => {
                          onRemoveFile(fileMarkedForDelete)
                          setFileMarkedForDelete(null)
                        }}
                      >
                        {global._('Delete')}
                      </Button>,
                      <Button
                        variant='none'
                        onClick={() => setFileMarkedForDelete(null)}
                      >
                        {global._('Common.Cancel')}
                      </Button>
                    ]}
                  />
                )}
                <div
                  style={{
                    position: 'fixed',
                    bottom: 110,
                    left: 'calc(50% - 26px)'
                  }}
                >
                  <DragZone
                    onFileSelected={onFileSelected}
                    buttonOnly
                    buttonVariant='circle'
                  />
                </div>
              </ScrollView>
            </MobileContainer>
          }
        />
      )}
      {['#comments', '#notes'].includes(navigation) && (
        <FullscreenModal
          title={
            navigation === '#comments'
              ? global._('CaseDetails.Comments')
              : global._('CaseDetails.CaseNotes')
          }
          onClose={hashBack}
          content={
            <MobileContainer background='white'>
              <ScrollView
                style={{
                  padding: '0 20px',
                  overflow: commentModalIsOpen ? 'hidden' : 'scroll'
                }}
              >
                <Comment
                  items={
                    navigation === '#comments'
                      ? caseObj.comments &&
                        caseObj.comments.sort((a, b) => b.id - a.id)
                      : caseObj.notes &&
                        caseObj.notes.sort((a, b) => b.id - a.id)
                  }
                  btnText={
                    navigation === '#comments'
                      ? global._('Case.AddComment')
                      : global._('Case.AddCaseNote')
                  }
                  isCaseNotes={navigation === '#notes'}
                  commentModalIsOpen={commentModalIsOpen}
                  setCommentModalIsOpen={setCommentModalIsOpen}
                  btnVariant='circle'
                  onDeleteItem={item =>
                    navigation === '#comments'
                      ? handleDeleteComment(item)
                      : handleDeleteCaseNote(item)
                  }
                  onEditItem={item =>
                    navigation === '#comments'
                      ? handleEditComment(item)
                      : handleEditCaseNote(item)
                  }
                  onCreateItem={item =>
                    navigation === '#comments'
                      ? handleAddComment(item)
                      : handleAddCaseNote(item)
                  }
                  user={user}
                />
              </ScrollView>
            </MobileContainer>
          }
        />
      )}
      {user && isAdminUser && navigation === '#admin' && (
        <FullscreenModal
          title={global._('Case.AdministrationTitle')}
          onClose={hashBack}
          content={
            <MobileContainer>
              <ScrollView>
                <Administration
                  caseObj={caseObj}
                  user={user}
                  setCaseObjCallback={setCaseObjCallback}
                  setError={setError}
                  match={match}
                />
              </ScrollView>
            </MobileContainer>
          }
        />
      )}
      <MobileContainer>
        <NavigationBar
          title={caseObj ? caseObj.header : undefined}
          backAction={() => history.push(caseTypeUrlMap())}
        />
        <ScrollView>
          {!isLoadingCase && !caseObj ? (
            <NoResultsMessage
              content={global._('CaseDetails.NoCasesMessage')}
            />
          ) : (
            <>
              {showDeleteCaseModal && (
                <Modal
                  onClose={() => setShowDeleteCaseModal(false)}
                  title={global._('Case.DeleteCase')}
                  content={
                    <div style={{ textAlign: 'left' }}>
                      <Typography
                        style={{
                          display: 'block',
                          paddingBottom: 12
                        }}
                        variant='body'
                      >
                        {global._('Case.DeleteCaseConfirm')}
                      </Typography>
                      <Typography
                        style={{
                          display: 'block',
                          paddingBottom: 12
                        }}
                        variant='subtitleSmall'
                      >
                        {global._('Case.DeleteCaseConfirmText')}
                      </Typography>
                    </div>
                  }
                  buttons={[
                    <Button
                      variant='secondary'
                      onClick={() => {
                        handleDeleteCase()
                        setShowDeleteCaseModal(false)
                      }}
                    >
                      {global._('Delete')}
                    </Button>,
                    <Button
                      variant='none'
                      onClick={() => setShowDeleteCaseModal(false)}
                    >
                      {global._('Common.Cancel')}
                    </Button>
                  ]}
                />
              )}
              <div style={{ padding: 20 }}>
                {!caseList.length || caseIndex === null ? (
                  <Row>
                    <Skeleton
                      animation='wave'
                      variant='rect'
                      height={48}
                      style={{ width: '50%', marginRight: 10 }}
                    />
                    <Skeleton
                      animation='wave'
                      variant='rect'
                      height={48}
                      style={{ width: '50%' }}
                    />
                  </Row>
                ) : (
                  <Grid container fullwidth spacing={1}>
                    <Grid item xs={6}>
                      <Button
                        variant='outlined'
                        type='danger'
                        disabled={isLoadingCase || caseIndex === 0}
                        onClick={() => changeCase('previous')}
                      >
                        {global._('Case.PreviousCaseMobile')}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant='outlined'
                        disabled={
                          isLoadingCase || caseIndex === caseList.length - 1
                        }
                        onClick={() => changeCase('next')}
                      >
                        {global._('Case.NextCaseMobile')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </div>
              {error && (
                <Notification
                  type='error'
                  message={error}
                  style={{ padding: 20 }}
                />
              )}
              <NavigationList itemList={listItems} />
              {user && isAdminUser && caseObj.type !== CaseTypes.Order && (
                <div className={classes.bottomBtnContainer}>
                  <Button
                    variant='secondary'
                    onClick={() => setShowDeleteCaseModal(true)}
                  >
                    {global._('Case.DeleteCase')}
                  </Button>
                </div>
              )}
            </>
          )}
        </ScrollView>
      </MobileContainer>
    </>
  )
}

CaseDetailsMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  token: PropTypes.string,
  caseObj: PropTypes.object,
  setCaseObjCallback: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
  caseIndex: PropTypes.number,
  caseList: PropTypes.array.isRequired,
  isLoadingCase: PropTypes.bool.isRequired,
  isAdminUser: PropTypes.bool.isRequired,
  changeCase: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  handleAddComment: PropTypes.func.isRequired,
  handleEditComment: PropTypes.func.isRequired,
  handleDeleteComment: PropTypes.func.isRequired,
  handleAddCaseNote: PropTypes.func.isRequired,
  handleEditCaseNote: PropTypes.func.isRequired,
  handleDeleteCaseNote: PropTypes.func.isRequired,
  handleDeleteCase: PropTypes.func.isRequired
}

CaseDetailsMobile.defaultProps = {
  token: '',
  caseObj: {},
  error: null,
  caseIndex: 0
}

const CaseDetailsMobileWithStyles = injectSheet(styles)(CaseDetailsMobile)
export default CaseDetailsMobileWithStyles
