import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import MobileContainer from 'src/components/MobileContainer'
import NavigationBar from 'src/components/NavigationBar'
import FullscreenModal from 'src/components/FullscreenModal'
import ScrollView from 'src/components/ScrollView'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import ObjectListMobile from 'src/components/ObjectListMobile'
import { Icon } from '@material-ui/core'

const styles = {
  root: {},
  section: {
    padding: 'var(--section-padding-default)',
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--color-divider-light)'
    }
  }
}

const ControlDetailsMobile = props => {
  const {
    history,
    classes,
    personName,
    controlData,
    successIcon,
    identityVerifiedText,
    actionButtons,
    createAccountForm,
    showCreateAccountModal,
    initiatorHasAccess,
    isPartnerAdmin,
    setShowCreateAccountModal
  } = props

  const [selectedUserRole, setSelectedUserRole] = useState(null)

  return (
    <MobileContainer background='white'>
      <NavigationBar
        title={`${global._('Common.Person')}`}
        backAction={() => history.push('/id-control')}
      />
      {showCreateAccountModal && createAccountForm()}
      {selectedUserRole && (
        <FullscreenModal
          rightActionItem={
            <Icon onClick={() => setSelectedUserRole(null)}>close</Icon>
          }
          title={selectedUserRole.organisation.name}
          content={
            <div>
              <div
                style={{
                  padding: 'var(--section-padding-default)',
                  borderBottom: '1px solid var(--color-divider-light)',
                  marginBottom: 20,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 60,
                    height: 60,
                    borderRadius: 500,
                    backgroundColor: 'var(--color-light-grey)',
                    color: 'var(--color-middle-grey)'
                  }}
                >
                  <Icon>face</Icon>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <Typography variant='body2' faded>
                    {global._(`User.Roles.${selectedUserRole.role}`)}
                  </Typography>
                  <Typography bold variant='subtitleBig'>
                    {controlData.user.name}
                  </Typography>
                </div>
              </div>
              <div style={{ padding: '0 var(--section-padding-default) 0' }}>
                {actionButtons(selectedUserRole.id, selectedUserRole)}
              </div>
            </div>
          }
        />
      )}
      <ScrollView id='scrollable-container'>
        <div className={classes.section}>
          <Typography bold variant='title'>
            {personName}
          </Typography>
        </div>
        <div className={classes.section}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {successIcon(true)}
            <Typography bold style={{ marginLeft: 15 }}>
              {identityVerifiedText()}
            </Typography>
          </div>
        </div>
        {controlData && (
          <ObjectListMobile
            lessPadding
            columns={[
              {
                key: 'icon',
                format: () => 'face'
              },
              {
                key: 'name',
                localizationKey: 'Common.OrganisationName',
                format: (one, two, obj) => (
                  <Typography bold>{obj.organisation.name}</Typography>
                )
              },
              {
                key: 'role',
                localizationKey: 'Common.OrganisationName',
                format: (role, two, obj) =>
                  initiatorHasAccess(obj) ? (
                    <Typography faded variant='body2'>
                      {global._(`User.Roles.${role}`)}
                    </Typography>
                  ) : (
                    <Typography
                      variant='body2'
                      style={{ color: 'var(--color-error-red)' }}
                    >
                      {global._('IdControl.Details.NoAccess')}
                    </Typography>
                  )
              }
            ]}
            objects={
              controlData &&
              controlData.user &&
              controlData.user.organisationUserRoles
            }
            rowOnClick={obj =>
              initiatorHasAccess(obj) && setSelectedUserRole(obj)
            }
            hideArrow={obj => !initiatorHasAccess(obj)}
            rowsAreClickable={false}
          />
        )}
        {controlData && !controlData.user && isPartnerAdmin && (
          <div
            style={{
              marginTop: 20,
              paddingLeft: 'var(--section-padding-default)',
              paddingRight: 'var(--section-padding-default)'
            }}
          >
            <Button
              variant='primary'
              onClick={() => setShowCreateAccountModal(true)}
            >
              {global._('IdControl.Details.CreateAnAccount')}
            </Button>
          </div>
        )}
      </ScrollView>
    </MobileContainer>
  )
}

ControlDetailsMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  personName: PropTypes.any.isRequired,
  successIcon: PropTypes.any.isRequired,
  identityVerifiedText: PropTypes.any.isRequired,
  actionButtons: PropTypes.array.isRequired,
  createAccountForm: PropTypes.func.isRequired,
  showCreateAccountModal: PropTypes.bool.isRequired,
  controlData: PropTypes.object,
  initiatorHasAccess: PropTypes.bool,
  isPartnerAdmin: PropTypes.bool,
  setShowCreateAccountModal: PropTypes.func.isRequired
}

ControlDetailsMobile.defaultProps = {
  controlData: {},
  initiatorHasAccess: false,
  isPartnerAdmin: false
}

const ControlDetailsMobileWithStyles = injectSheet(styles)(ControlDetailsMobile)
export default ControlDetailsMobileWithStyles
