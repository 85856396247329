/* eslint-disable import/prefer-default-export */
import red from '@material-ui/core/colors/red'
import { emphasize } from '@material-ui/core/styles/colorManipulator'

export const styles = theme => ({
  root: {
    padding: 20,
    margin: '34px 49px',
    marginTop: 62
  },
  paperBase: {
    padding: 20
  },
  paperFirst: {
    padding: 20,
    margin: '0 0 34px 0'
  },
  paperTable: {
    marginTop: theme.spacing(3),
    padding: 20
  },
  editIcon: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#212529',
    opacity: '0.5',
    '&:hover': {
      opacity: '1',
      color: '#2196f3'
    }
  },
  clearIcon: {
    cursor: 'pointer',
    marginLeft: 5,
    opacity: '0.5',
    '&:hover': {
      color: '#d80018',
      opacity: '1'
    }
  },
  doneIcon: {
    cursor: 'pointer',
    float: 'right',
    opacity: '0.5',
    margin: 0,
    padding: 0,
    '&:hover': {
      color: '#52b202',
      opacity: '1'
    }
  },
  impersonateIcon: {
    marginLeft: 5,
    cursor: 'pointer',
    opacity: '0.5',
    '&:hover': {
      color: '#ff9100',
      opacity: '1'
    }
  },
  dialog: {
    padding: 24
  },
  dialogActions: {
    marginTop: theme.spacing(3)
  },
  dialogContent: {
    minWidth: 300
  },
  redButton: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    width: '100%',
    '&:hover': {
      backgroundColor: red[700]
    }
  },
  redButtonDeny: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    marginLeft: 10,
    '&:hover': {
      backgroundColor: red[700]
    }
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chip: {
    margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  },
  checkbox: {
    color: '#4caf50'
  },
  textField: {
    width: 300,
    // marginTop: 10,
    marginRight: 10
  }
})
