import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import NavigationBar from 'src/components/NavigationBar'
import Notification from 'src/common/Notification'
import Typography from 'src/components/Typography'
import ScrollView from 'src/components/ScrollView'
import MobileContainer from 'src/components/MobileContainer'
import LocksList from 'src/components/LocksList'
import { DeviceConfig, DeviceOperation } from 'src/utils/constants'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import MyLocksNavigator from './MyLocksNavigator'

const DELAY = 750
const LOCK_TIMER = 8000
const styles = {
  root: {
    backgroundColor: 'var(--color-material-grey)',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  locksContainer: {
    width: '100%'
  },
  lockRow: {
    display: 'flex',
    width: '100%',
    padding: '14px 18px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'var(--color-white)',
    background:
      'linear-gradient(to right, var(--color-primary-tone-4), var(--color-primary-tone-4)) no-repeat',
    backgroundSize: '0% 100%',
    transition: `background-size ${DELAY}ms ease-in-out`,
    borderBottom: '1px solid var(--color-divider-light)'
  },
  lockRowError: {
    backgroundColor: 'var(--color-white)',
    background:
      'linear-gradient(to right, var(--color-error-tone-1), var(--color-error-tone-1)) no-repeat'
  },
  lockIconContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--color-primary)',
    width: 40,
    minWidth: 40,
    height: 40,
    minHeight: 40,
    borderRadius: 500,
    marginRight: 20
  },
  lockIconContainerOpen: {
    backgroundColor: 'transparent'
  },
  lockIconContainerError: {
    backgroundColor: 'var(--color-error-text)'
  },
  lockIcon: {
    color: 'var(--color-white)',
    fontSize: 24
  },
  lockIconOpen: {
    color: 'black'
  },
  countdown: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: 'var(--color-primary)'
  },
  lockContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '90%'
  },

  /* Mobile */
  '@media (max-width: 900px)': {
    root: {
      padding: 0,
      paddingBottom: 30
    }
  }
}

const MyLocks = props => {
  const { classes, history, user } = props

  /* State */
  const [locks, setLocks] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const getLocks = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const { data } = await axios.get(
        `/v1/organisations/${user.organisation.id}/locks?accessible=true&orderBy=name&order=asc`
      )

      setLocks(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setLocks([])
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  /* Initial load */
  useEffect(() => {
    getLocks()
  }, [])

  const { isMobile } = useWindowDimensions()

  if (!isMobile) {
    history.push('/dashboard')
  }

  return (
    <MobileContainer>
      <NavigationBar
        title={global._('TabBar.Locks')}
        backAction={() => history.push('/')}
        rightActionItem={
          <div onClick={() => history.push('/mylocks/events')}>
            <Icon>history</Icon>
          </div>
        }
      />
      <ScrollView>
        <MyLocksNavigator />
        <LocksList
          history={history}
          lockOnClick={lock => history.push(`/mylocks/${lock.id}`)}
          user={user}
          getLocks={getLocks}
          setLocks={setLocks}
          setError={setError}
          isLoading={isLoading}
          locks={locks}
          error={error}
        />
      </ScrollView>
    </MobileContainer>
  )
}

MyLocks.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const MyLocksWithStyles = injectSheet(styles)(MyLocks)
export default MyLocksWithStyles
