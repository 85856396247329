import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Typography from './Typography'

const styles = {
  root: {
    zIndex: 1040,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    /*
    padding: '120px 40px 40px 40px',
    */
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.2)'
  },
  rootContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    minWidth: '100%',
    padding: '0 20px'
  },
  modal: {
    borderRadius: 3,
    maxWidth: 600,
    minWidth: 300,
    width: 600,
    minHeight: 100,
    backgroundColor: '#fff',
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 20,
    borderBottom: props =>
      props.content ? '1px solid rgba(0,0,0, 0.12)' : undefined
  },
  contentContainer: {
    padding: props => (props.noContentPadding ? undefined : 20)
  },
  buttonContainer: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    padding: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& button': {
      marginLeft: 10
    }
  },
  icon: {
    marginRight: 12,
    fontSize: 24,
    paddingTop: 2
  },
  warningIcon: {
    color: '#F67A7A'
  },
  '@media (max-width: 900px)': {
    root: {
      paddingBottom: 150
    }
  }
}

const Modal = props => {
  const { classes, title, type, content, buttons, onClose, open } = props
  const modalRef = useRef()
  const rootRef = useRef()

  useEffect(() => {
    const onKeyPress = evt => {
      if (evt.key === 'Escape' && typeof onClose === 'function') {
        onClose()
      }
    }
    const outsideClickHandler = e => {
      if (!rootRef.current || !modalRef.current || !e.clientX || !e.clientY)
        return
      // Don't trigger outside click before modal is displayed
      if (!rootRef.current.contains(e.target)) return
      if (modalRef.current.contains(e.target)) return
      const { current: modal } = modalRef
      const bounds = modal.getBoundingClientRect()
      if (
        e.clientX < bounds.left ||
        e.clientX > bounds.left + bounds.width ||
        e.clientY < bounds.top ||
        e.clientY > bounds.top + bounds.height
      ) {
        if (typeof onClose === 'function') {
          onClose()
        }
      }
    }
    window.addEventListener('keydown', onKeyPress, false)
    window.addEventListener('click', outsideClickHandler, false)
    return () => {
      window.removeEventListener('keydown', onKeyPress, false)
      window.removeEventListener('click', outsideClickHandler, false)
    }
  }, [])

  let icon
  if (type === 'warning') {
    icon = (
      <Icon className={classnames(classes.icon, classes.warningIcon)}>
        warning
      </Icon>
    )
  }

  if (!open) {
    return null
  }

  return (
    <div
      role='button'
      tabIndex='0'
      className={classes.root}
      ref={rootRef}
      onKeyPress={e => e.key === 'Escape' && onClose && onClose()}>
      <div className={classes.rootContainer}>
        <div className={classes.modal} ref={modalRef}>
          {
            title && (
              <div className={classes.titleContainer}>
                {icon}
                <Typography bold variant='title' align='left'>
                  {title}
                </Typography>
              </div>
            )
          }
          {content && <div className={classes.contentContainer}>{content}</div>}
          <div className={classes.buttonContainer}>{buttons}</div>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  content: PropTypes.node.isRequired,
  buttons: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
}

Modal.defaultProps = {
  type: null,
  open: true
}

const ModalWithStyles = injectSheet(styles)(Modal)
export default ModalWithStyles
