import React from 'react'
import PropTypes from 'prop-types'

const Column = props => {
  const { justify, align, flex, flexDirection, style, ...rest } = props
  const customStyle = props.style || {}
  customStyle.display = 'flex'
  if (justify) customStyle.justifyContent = justify
  if (align) customStyle.alignItems = align
  if (flex) customStyle.flex = 1
  if (flexDirection) customStyle.flexDirection = flexDirection
  return <div style={customStyle} {...rest} />
}

Column.propTypes = {
  justify: PropTypes.string,
  align: PropTypes.string,
  flex: PropTypes.bool,
  flexDirection: PropTypes.string,
  style: PropTypes.object
}

Column.defaultProps = {
  justify: 'flex-start',
  align: 'center',
  flex: false,
  flexDirection: 'column',
  style: null
}

export default Column
