import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import axios from 'axios'
import { Grid, TextField } from '@material-ui/core'
import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import Notification from '../../common/Notification'
import PageContentHeader from '../../components/PageContentHeader'
import Paper from '../../components/Paper'
import SuccessMessage from '../../components/SuccessMessage'
import Modal from '../../components/Modal'
import { getSelectedOrg, convertDraftJsToPdfMakeObj } from '../../utils/helpers'
import ContentEditor from '../../components/ContentEditor'
import CheckboxList from '../../components/CheckboxList'
import {
  standardTermsJson,
  standardTermsWithPrivacyPolicyJson,
  defaultContentFieldsJson
} from '../../utils/receiptTemplates'
import {
  standardTermsJsonNO,
  standardTermsWithPrivacyPolicyJsonNO
} from '../../utils/receiptTemplatesNO'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const styles = {
  root: {},
  headerContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid var(--divider-color)',
    padding: 20
  },
  iconContainer: {
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 500,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  }
}

const ReceiptTemplatesDetails = props => {
  const { classes, history, match, breadcrumbItems, user } = props

  const { id } = match.params

  const selectedOrg = getSelectedOrg(user)

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const isNorwegian = (!!selectedOrg && selectedOrg.country === 'NO')

  const [error, setError] = useState(null)
  const [name, setName] = useState()
  const [saveSuccess, setSavesuccess] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [fetchTermsModal, setFetchTermsModal] = useState(false)
  const [receiptTemplate, setReceiptTemplate] = useState({
    name: '',
    content: ''
  })
  const [content, setContent] = useState()
  const [toggleRender, setToggleRender] = useState(false)
  const [formTouched, setFormTouched] = useState(false)
  const [includePolicyCheckbox, setIncludePolicyCheckbox] = useState([])

  const notValid = !name || !name.trim() || !content
  const showEditor = !id || (id && content)

  useEffect(() => {
    ;(async () => {
      if (id) {
        try {
          const { data } = await axios.get(
            `/v1/organisations/${selectedOrg.id}/keyreceipttemplates/${id}`
          )
          setReceiptTemplate(data)
          setName(data.name)
          setContent(data.content)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
      }
    })()
  }, [])

  const handleSaveOrCreate = async () => {
    try {
      setError(null)
      const url = `/v1/organisations/${selectedOrg.id}/keyreceipttemplates`
      const receiptTemplateObj = { name, content: JSON.stringify(content) }
      if (id) {
        await axios.patch(`${url}/${id}`, receiptTemplateObj)
        setSavesuccess(true)
      } else if (!id) {
        await axios.post(url, receiptTemplateObj)
        localStorage.setItem('receiptTemplateCreatedSuccessfully', true)
        history.push('/keys/receipt-templates')
      }

      setFormTouched(false)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(
        msg.includes('Data too long')
          ? global._('Keys.ReceiptTemplates.ErrorMsg.DataTooLong')
          : msg
      )
    }
  }

  const handleDelete = async () => {
    try {
      await axios.delete(
        `/v1/organisations/${selectedOrg.id}/keyreceipttemplates/${id}`
      )
      localStorage.setItem('receiptTemplateDeletedSuccessfully', true)
      history.replace('/keys/receipt-templates')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const mergePdfContentParts = () => {
    let pdfContent = { blocks: [] }
    pdfContent = {
      ...defaultContentFieldsJson,
      blocks: [...defaultContentFieldsJson.blocks]
    }
    pdfContent.blocks.splice(22, 0, ...content.blocks)
    return pdfContent
  }

  return (
    <>
      <div className={classes.root}>
        {deleteModal && (
          <Modal
            onClose={() => setDeleteModal(false)}
            title={global._('Keys.ReceiptTemplates.Modal.Title')}
            content={
              <div style={{ textAlign: 'left' }}>
                <Typography
                  style={{
                    display: 'block',
                    paddingBottom: 12
                  }}
                  variant='body'>
                  {global._('Keys.ReceiptTemplates.Modal.ConfirmText')}
                </Typography>
              </div>
            }
            buttons={[
              <Button variant='none' onClick={() => setDeleteModal(false)}>
                {global._('Common.Cancel')}
              </Button>,
              <Button variant='secondary' onClick={() => handleDelete()}>
                {global._('Delete')}
              </Button>
            ]}
          />
        )}
        {fetchTermsModal && (
          <Modal
            onClose={() => setFetchTermsModal(false)}
            title={global._('Keys.ReceiptTemplates.TermsModal.Title')}
            content={
              <div style={{ textAlign: 'left' }}>
                <Typography
                  style={{
                    display: 'block',
                    marginTop: 8,
                    paddingBottom: 12
                  }}
                  variant='body'>
                  {global._('Keys.ReceiptTemplates.TermsModal.ConfirmText')}
                </Typography>
                <div
                  style={{
                    borderTop: '1px solid var(--color-divider-light)',
                    marginTop: 18,
                    paddingTop: 11,
                    marginLeft: '-20px',
                    marginRight: '-20px',
                    width: 'calc(100% + 40px)'
                  }}>
                  <CheckboxList
                    checkboxNameKey='id'
                    objects={[
                      {
                        title:
                          'Inkludera villkor för hantering av personuppgifter',
                        id: 1
                      }
                    ]}
                    columns={[
                      {
                        key: 'title',
                        format: obj => (
                          <div style={{ paddingRight: 50 }}>
                            <Typography variant='body' bold>
                              {obj.title}
                            </Typography>
                            <Typography
                              variant='body2'
                              italic
                              block
                              style={{ marginTop: 15 }}>
                              {global._(
                                'Keys.ReceiptTemplates.TermsModal.PolicyCheckboxInfo'
                              )}
                            </Typography>
                          </div>
                        )
                      }
                    ]}
                    checkboxAlignStart
                    selectedOptions={includePolicyCheckbox}
                    setSelectedOptions={setIncludePolicyCheckbox}
                  />
                </div>
              </div>
            }
            buttons={[
              <Button variant='none' onClick={() => setFetchTermsModal(false)}>
                {global._('Common.Cancel')}
              </Button>,
              <Button
                variant='secondary'
                onClick={() => {
                  setContent(
                    isNorwegian ?
                      (includePolicyCheckbox.length > 0
                        ? standardTermsWithPrivacyPolicyJsonNO
                        : standardTermsJsonNO)
                      :
                      (includePolicyCheckbox.length > 0
                        ? standardTermsWithPrivacyPolicyJson
                        : standardTermsJson)
                  )
                  setToggleRender(!toggleRender)
                  setFetchTermsModal(false)
                }}>
                {global._('Keys.ReceiptTemplates.TermsModal.Replace')}
              </Button>
            ]}
          />
        )}
        {saveSuccess && (
          <SuccessMessage subtitle={global._('Keys.KeySavedSuccessMessage')} />
        )}
        <PageContentHeader
          breadcrumbItems={breadcrumbItems}
          actionButtons={
            <div style={{ marginLeft: 'auto' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant='outlined'
                    onClick={() => history.push('/keys/receipt-templates')}
                    style={{ width: 130 }}>
                    {global._('Common.Cancel')}
                  </Button>
                </Grid>
                {id && (
                  <Grid item>
                    <Button
                      variant='outlined'
                      onClick={() => setDeleteModal(true)}
                      style={{ width: 130 }}>
                      {global._('Keys.Common.Remove')}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant='primary'
                    style={{ width: 130 }}
                    onClick={() => handleSaveOrCreate()}
                    disabled={notValid || !formTouched}>
                    {global._(id ? 'Keys.Common.Save' : 'Keys.Common.Add')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          }
        />
        {error && (
          <Notification
            type='error'
            message={error}
            style={{ margin: '20px 0' }}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper alignItems='left' noPadding>
              <div className={classes.headerContainer}>
                <div className={classes.iconContainer}>
                  <Icon>assignment</Icon>
                </div>
                <div
                  className={classes.titleContainer}
                  style={{ marginLeft: 20 }}>
                  <Typography
                    faded
                    variant='body'
                    style={{ fontSize: '0.875rem' }}>
                    {id
                      ? receiptTemplate && receiptTemplate.id
                      : global._('Keys.Common.New')}
                  </Typography>
                  <Typography variant='subtitle' style={{ lineHeight: 1.1 }}>
                    {id
                      ? receiptTemplate && receiptTemplate.name
                      : global._('Keys.ReceiptTemplates.ReceiptTemplate')}
                  </Typography>
                </div>
              </div>
              <Grid container style={{ padding: 20 }}>
                <Grid item xs={6}>
                  <TextField
                    style={{ width: '100%' }}
                    name='name'
                    label={global._('Keys.ReceiptTemplates.ReceiptName')}
                    value={name || ''}
                    onChange={e => {
                      setName(e.target.value)
                      setFormTouched(true)
                    }}
                    variant='filled'
                    helperText={global._('Common.Required')}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper alignItems='left' noPadding>
              <div className={classes.headerContainer}>
                <Typography variant='subtitle' style={{ lineHeight: 1.1 }} bold>
                  {global._('Keys.ReceiptTemplates.Receipt')}
                </Typography>
                <Button
                  variant='outlined'
                  style={{ marginLeft: 'auto', width: 228 }}
                  onClick={() => setFetchTermsModal(true)}>
                  {global._('Keys.ReceiptTemplates.GetStandardTemplate')}
                </Button>
                {id && (
                  <>
                    <Button
                      variant='outlined'
                      style={{ width: 130, marginLeft: 20, marginRight: 20 }}
                      onClick={() => {
                        const pdfContent = mergePdfContentParts()
                        const docDefinition =
                          convertDraftJsToPdfMakeObj(pdfContent)
                        pdfMake.createPdf(docDefinition).print()
                      }}>
                      {global._('Keys.ReceiptTemplates.Print')}
                    </Button>
                    <Button
                      variant='secondary'
                      style={{ width: 192 }}
                      onClick={() => {
                        const pdfContent = mergePdfContentParts()
                        const docDefinition =
                          convertDraftJsToPdfMakeObj(pdfContent)
                        const pdfGenerator = pdfMake.createPdf(docDefinition)
                        pdfGenerator.getBlob(blob => {
                          window.open(URL.createObjectURL(blob), '_blank')
                        })
                      }}>
                      {global._('Keys.ReceiptTemplates.Preview')}
                    </Button>
                  </>
                )}
              </div>
              {showEditor && (
                <ContentEditor
                  content={content}
                  setContent={setContent}
                  parentStateChanged={toggleRender}
                  onChange={() => setFormTouched(true)}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

ReceiptTemplatesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object
}

ReceiptTemplatesDetails.defaultProps = {
  match: null
}

const ReceiptTemplatesDetailsWithStyles = injectSheet(styles)(
  ReceiptTemplatesDetails
)
export default ReceiptTemplatesDetailsWithStyles
