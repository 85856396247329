import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import FullscreenModal from '../../../components/FullscreenModal'
import ObjectListMobile from '../../../components/ObjectListMobile'
import ScrollView from '../../../components/ScrollView'
import Notification from '../../../common/Notification'
import Modal from '../../../components/Modal'
import LoadingSpinner from '../../../components/LoadingSpinner'
import MobileContainer from '../../../components/MobileContainer'

const styles = {
  root: {}
}

const MobileNavigationPage = props => {
  const { classes, user, history, match } = props
  console.log('history', history)
  const { organisationId, accessgroupId } = match.params || null
  const isAccessGroup =
    history &&
    history.location &&
    history.location.pathname.includes(
      `admin/accessgroups/${organisationId}/mob/${accessgroupId}`
    )
  const [isLoading, setIsLoading] = useState()
  const [isLoadingDelete, setIsLoadingDelete] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteError, setDeleteError] = useState(null)
  const [error, setError] = useState(null)
  const [accessGroup, setAccessGroup] = useState()

  useEffect(() => {
    if (organisationId && accessgroupId) {
      ;(async () => {
        setIsLoading(true)
        try {
          const { data } = await axios.get(
            `/v1/organisations/${organisationId}/accessgroups/${accessgroupId}`
          )
          setAccessGroup(data)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
        setIsLoading(false)
      })()
    }
  }, [organisationId, accessgroupId])

  const navigationItems = [
    {
      id: isAccessGroup
        ? `accessgroups/details/${accessGroup && accessGroup.organisationId}/${
            accessGroup && accessGroup.id
          }`
        : `accessgroups/${user.organisation.id}`,
      icon: isAccessGroup ? 'edit' : 'group_work',
      entity: isAccessGroup
        ? global._('AccessGroups.DetailsTitle')
        : global._('AccessGroups.AccessGroupsButton'),
      subtitle: global._('Widgets.accessgroups.Administer')
    },
    {
      id: isAccessGroup
        ? `accessgroups/${accessGroup && accessGroup.organisationId}/${
            accessGroup && accessGroup.id
          }/locks`
        : `accessgroups/${user.organisation.id}/locks`,
      icon: 'lock',
      entity: isAccessGroup
        ? global._('AccessGroups.AssignLocks')
        : global._('AccessGroups.AccessLocksButton'),
      subtitle: global._('Widgets.accessgroups.Show')
    },
    {
      id: isAccessGroup
        ? `accessgroups/${accessGroup && accessGroup.organisationId}/${
            accessGroup && accessGroup.id
          }/users`
        : `accessgroups/${user.organisation.id}/users`,
      icon: isAccessGroup ? 'face' : 'person',
      entity: isAccessGroup
        ? global._('AccessGroups.AssignUsers')
        : global._('AccessGroups.AccessUsersButton'),
      subtitle: global._('Widgets.accessgroups.Show')
    }
  ]
  if (isAccessGroup) {
    navigationItems.push({
      id: `accessgroups/details/${accessGroup?.organisationId}/${accessGroup?.id}#schedule`,
      icon: 'access_time',
      entity: global._('AccessGroups.Schedule'),
      subtitle: global._('Widgets.accessgroups.Schedule')
    })
  }

  const columns = [
    { key: 'icon', format: obj => `${obj && obj.icon}` },
    {
      key: 'entity',
      clickable: true,
      format: entity => (
        <Typography variant='body' bold>
          {entity}
        </Typography>
      )
    }
  ]

  if (!isAccessGroup && !accessgroupId && !organisationId) {
    columns.push({
      key: 'subtitle',
      clickable: true,
      format: subtitle => (
        <Typography
          variant='body'
          faded
          style={{ fontSize: 14, lineHeight: 1.5, display: 'inline-block' }}
        >
          {subtitle}
        </Typography>
      )
    })
  }

  const handleDeleteItem = async () => {
    setDeleteError(null)
    setIsLoadingDelete(true)
    try {
      await axios.delete(
        `/v1/organisations/${accessGroup.organisationId}/accessgroups/${accessGroup.id}`
      )
      history.goBack()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setDeleteError(global._(msg))
    }
    setIsLoadingDelete(false)
  }

  if (isLoading) return <LoadingSpinner />

  return (
    <FullscreenModal
      onClose={() => history.goBack()}
      title={
        isAccessGroup
          ? accessGroup && accessGroup.name
          : global._('Widgets.accessgroups.Subtitle')
      }
      content={
        <MobileContainer>
          {/* Delete modal */}
          {deleteModal && (
            <Modal
              onClose={() => setDeleteModal(false)}
              title={global._('Widgets.accessgroups.DeleteItem')}
              content={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}
                >
                  <Typography variant='body'>
                    {global._('Widgets.accessgroups.DeleteItemConfirm')}
                  </Typography>
                  <Typography style={{ margin: '10px 0' }} variant='subtitle'>
                    {accessGroup.name}
                  </Typography>
                  <Typography italic variant='body'>
                    {global._('Widgets.accessgroups.DeleteItemInfo')}
                  </Typography>
                  {deleteError && (
                    <Notification
                      type='error'
                      message={deleteError}
                      style={{ marginTop: 20 }}
                    />
                  )}
                </div>
              }
              buttons={[
                <Button
                  key='widgets.accessgroups.modal.create.button.cancel'
                  variant='none'
                  onClick={() => setDeleteModal(false)}
                  style={{ marginRight: 10 }}
                >
                  {global._('Common.Cancel')}
                </Button>,
                <Button
                  key='widgets.accessgroups.modal.create.button.remove'
                  variant='primary'
                  onClick={() => handleDeleteItem()}
                  loading={isLoadingDelete}
                >
                  {global._('Common.Remove')}
                </Button>
              ]}
            />
          )}
          <ScrollView id='scrollable-container'>
            <ObjectListMobile
              objectPermalink='admin'
              objects={navigationItems}
              user={user}
              history={history}
              noResultsMessage=''
              columns={columns}
              paddingTop={0}
              singleColumn={isAccessGroup}
            />
            {error && <Notification type='error' message={error} />}
          </ScrollView>
        </MobileContainer>
      }
      buttonPadding={isAccessGroup ? '20px 20px 110px 20px' : undefined}
      boxShadow={isAccessGroup ? 'none' : undefined}
      buttons={
        isAccessGroup
          ? [
              <Button variant='secondary' onClick={() => setDeleteModal(true)}>
                {global._('AccessGroups.Delete')}
              </Button>
            ]
          : undefined
      }
    />
  )
}

MobileNavigationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object
}

MobileNavigationPage.defaultProps = {
  match: null
}

const MobileNavigationPageMobileWithStyles =
  injectSheet(styles)(MobileNavigationPage)
export default MobileNavigationPageMobileWithStyles
