import { FormControl, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import Button from 'src/components/Button'
import Typography from 'src/components/Typography'
import Paper from 'src/components/Paper'
import Alert from 'src/components/Alert'
import Notification from 'src/common/Notification'
import { getCountry, phoneNumberRegex } from 'src/utils/helpers'
import { Country } from 'src/utils/constants'
import withUser from 'src/utils/withUser'
import ssn from 'src/utils/ssn'
import { history } from '../redux/store'
import SsnInput, { validate } from './SsnInput'

const styles = {
  root: {},
  section: {
    padding: 'var(--section-padding-default)',
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--color-divider-light)'
    }
  }
}

const IdControlForm = props => {
  const {
    classes,
    title,
    cancel,
    goBack,
    personalNumber,
    setPersonalNumber,
    sendRequest,
    error,
    isLoading,
    locKey,
    loadingInfo,
    showCancelButtonByDefault,
    mobileNumber,
    setMobileNumber,
    user
  } = props

  const [fieldTouched, setFieldTouched] = useState(false)
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false)

  const formText = isLoading
    ? global._(`${locKey}IdControl.WaitingDescription`)
    : global._(`${locKey}IdControl.VerifyIdentityDescription`)

  const { isMobile } = useWindowDimensions()

  useEffect(() => {
    if (phoneNumberRegex.test(mobileNumber)) {
      setIsPhoneNumberValid(true)
      setFieldTouched(true)
    } else {
      setIsPhoneNumberValid(false)
    }
  }, [mobileNumber])

  const country =
    ssn.getCountry(user.personalNumber) ||
    user.organisation.country ||
    getCountry()

  const printForm = () => (
    <>
      {isLoading && loadingInfo && (
        <Alert style={{ marginBottom: 20 }}>{loadingInfo}</Alert>
      )}
      {!validate(personalNumber) && (
        <FormControl fullWidth>
          <SsnInput
            onSsnValid={({ ssn }) => setPersonalNumber(ssn)}
            style={{ marginBottom: 20 }}
            value={personalNumber}
            onKeyDown={e =>
              personalNumber &&
              e.key === 'Enter' &&
              sendRequest &&
              sendRequest()
            }
          />
        </FormControl>
      )}
      {country === Country.NO && (
        <FormControl fullWidth>
          <TextField
            variant='filled'
            label={global._('Common.Mobile')}
            name='mobileNumber'
            style={{ marginBottom: 20 }}
            helperText={global._('IdControl.MobileNumberHelpText')}
            value={mobileNumber}
            error={fieldTouched && (!mobileNumber || !isPhoneNumberValid)}
            onKeyDown={e => e.key === 'Enter' && sendRequest && sendRequest()}
            onChange={e => {
              if (!fieldTouched) {
                setFieldTouched(true)
              }

              setMobileNumber(e.target.value)
            }}
          />
        </FormControl>
      )}
      {error && (
        <Notification
          style={{ marginBottom: 20 }}
          type='error'
          message={error}
        />
      )}
      <Button
        variant='primary'
        disabled={
          !personalNumber ||
          (country === Country.NO && !mobileNumber && !isPhoneNumberValid) ||
          isLoading
        }
        loading={isLoading}
        onClick={() => sendRequest && sendRequest()}
        style={{ width: '100%' }}
      >
        {global._(`${locKey}IdControl.VerifyIdentityButtonLabel`)}
      </Button>
      {(isLoading || (!isLoading && showCancelButtonByDefault && isMobile)) && (
        <Button
          variant='none'
          style={{ marginTop: 20 }}
          onClick={() => (isLoading && cancel ? cancel() : goBack && goBack())}
        >
          {global._('Common.Cancel')}
        </Button>
      )}
    </>
  )

  if (isMobile) {
    return (
      <>
        {!validate(personalNumber) && (
          <div className={classes.section}>
            <Typography bold>{formText}</Typography>
          </div>
        )}
        <div className={classes.section}>{printForm()}</div>
      </>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {!validate(personalNumber) && (
        <Typography variant='h1' align='center' style={{ marginBottom: 30 }}>
          {title || global._('IdControl.Title')}
        </Typography>
      )}
      <Paper fixedWidth biggerPadding alignItems='center'>
        {!validate(personalNumber) && (
          <Typography align='center' bold style={{ marginBottom: 16 }}>
            {formText}
          </Typography>
        )}
        {printForm()}
      </Paper>
      <Button
        variant='none'
        style={{ marginTop: 15 }}
        onClick={() => {
          if (typeof goBack === 'function') {
            goBack()
          } else {
            history.goBack()
          }
        }}
      >
        {global._('Common.Back')}
      </Button>
    </div>
  )
}

IdControlForm.propTypes = {
  classes: PropTypes.object.isRequired,
  locKey: PropTypes.string,
  showCancelButtonByDefault: PropTypes.bool
}

IdControlForm.defaultProps = {
  locKey: '',
  showCancelButtonByDefault: false
}

const IdControlFormWithStyles = injectSheet(styles)(withUser(IdControlForm))
export default IdControlFormWithStyles
