import React from 'react'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import qs from 'qs'
import Button from 'src/components/Button'
import AsyncSelect from 'src/components/AsyncSelect'
import SuccessMessage from 'src/components/SuccessMessage'
import ImportModal from 'src/components/ImportModal'
import { ImportStorageKeys, UserRoles } from 'src/utils/constants'
import { history } from '../../../../redux/store'
import { Checkbox } from '@material-ui/core'

export default {
  topContent: single =>
    single ? (
      <div style={{ position: 'absolute', top: -59, right: 20 }}>
        <Button
          key='widgets.apartments.button.servicerequests'
          variant='secondary'
          onClick={() => history.push(`/admin/apartments/servicerequests`)}
          style={{ marginLeft: 10 }}
        >
          {global._('Widgets.apartments.ActionButton.ServiceRequests')}
        </Button>
      </div>
    ) : null,
  fetchUrl: (filter, user) => `/v1/apartments?${qs.stringify(filter)}`,
  icon: () => 'location_city',
  attributes: (caller, opts) => {
    const attributes = {}

    let orgDefaultValue

    if (opts.filter.organisationId) {
      orgDefaultValue = {
        value: opts.filter.organisationId,
        label: opts.filter.organisationName
      }
    }

    if (opts.editItem) {
      orgDefaultValue = {
        value: opts.editItem.organisation.id,
        label: opts.editItem.organisation.name
      }
    }

    if (
      caller.roles.includes(UserRoles.SystemAdmin) ||
      caller.roles.includes(UserRoles.PartnerAdmin)
    ) {
      attributes.organisationId = {
        required: true,
        field: (
          <AsyncSelect
            isSearchable
            labelKey='name'
            defaultValue={orgDefaultValue}
            placeholder={
              opts.newItem
                ? global._('Common.SelectOrganisation')
                : global._('Common.Organisation')
            }
            onChange={opt =>
              opts.newItem &&
              opts.setNewItem({
                ...opts.newItem,
                organisationId: opt ? opt.value.id : null,
                organisationType: opt ? opt.value.type : null
              })
            }
            disabled={opts.isLoadingCreate || !opts.newItem}
            helpText={global._('Common.Required')}
            url='/v1/organisations?search={{input}}&orderBy=name&order=asc'
          />
        )
      }
    }

    attributes.number = {
      required: true
    }

    attributes.address = {
      required: true
    }

    attributes.additionalInformation = {}

    return attributes
  },
  modals: ({
    modals,
    setModals,
    user,
    modalFieldsData,
    setModalFieldsData
  }) => {
    const ret = []
    if (modals.import) {
      ret.push(
        <ImportModal
          storageKey={ImportStorageKeys.APARTMENTS}
          transformRow={row => ({
            number: row[0], // string
            address: row[1], // string
            additionalInformation: row[2] // string
          })}
          key='admin.apartments.import.modal'
          previewUrl='/admin/apartments/import'
          templateFilePath={`/templates/importmall_lagenheter_${global.getLanguage()}.xlsx`}
          locKey='Apartments'
          onClose={() => setModals({ ...modals, import: false })}
          fieldsData={modalFieldsData}
          fields={() => {
            let { organisation } = user

            if (modalFieldsData && modalFieldsData.organisation) {
              ;({ organisation } = modalFieldsData)
            } else {
              setModalFieldsData({
                organisation
              })
            }

            return (
              <>
                <AsyncSelect
                  isSearchable
                  isClearable={false}
                  key='import-apartments-organisation-select'
                  dropdownIcon='search'
                  helpText={global._('Common.Required')}
                  value={{ label: organisation.name, value: organisation }}
                  placeholder={global._(
                    `Import.Apartments.Modal.AsyncFieldPlaceholder`
                  )}
                  onChange={opt => {
                    setModalFieldsData({
                      organisation: opt ? opt.value : null
                    })
                  }}
                  url='/v1/organisations?orderBy=name&order=asc&search={{input}}'
                />
              </>
            )
          }}
        />
      )
    }
    ret.push(
      <SuccessMessage
        key='admin.apartments.import.successmessage'
        subtitle={global._('Import.Apartments.Preview.SuccessMessage')}
        localStorageProp='apartments.import.success'
      />
    )
    return ret
  },
  actionButtons: (caller, opts) => {
    const { setNewItem, filter, modals, setModals, single } = opts
    const buttons = []

    if (!caller.roles.includes(UserRoles.Support)) {
      buttons.push(
        <Button
          key='widgets.apartments.button.add.new'
          variant='primary'
          onClick={() =>
            setNewItem({
              organisationId: filter.organisationId || caller.organisation.id
            })
          }
        >
          {global._('Widgets.apartments.ActionButton.AddNew')}
        </Button>
      )

      if (single) {
        buttons.push(
          <Button
            key='widgets.apartments.button.import'
            variant='outlined'
            onClick={() => setModals({ ...modals, import: true })}
          >
            {global._('Widgets.apartments.ActionButton.Import')}
          </Button>
        )
      }
    }

    if (!single) {
      buttons.push(
        <Button
          key='widgets.apartments.button.servicerequests'
          variant='secondary'
          onClick={() => history.push(`/admin/apartments/servicerequests`)}
          style={{ marginLeft: 10 }}
        >
          {global._('Widgets.apartments.ActionButton.ServiceRequests')}
        </Button>
      )
    }
    return buttons
  },
  identifier: org => org.id,
  noResultsMessage: () => global._('Widgets.apartments.NoResultsMessage'),
  MobileTable: {
    icon: 'location_city',
    title: item => item.refId,
    subtitle: item => item.address || '',
    onClick: item => history.push(`/admin/users/${item.id}`)
  },
  Columns: {
    single: [
      {
        key: 'icon',
        sortable: false,
        localizationKey: '',
        style: { width: 60 },
        transform: isExpanded => (
          <div
            className='tableRowIcon'
            style={{
              width: 38,
              height: 38,
              transition: 'background-color 200ms',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isExpanded ? 'white' : 'var(--color-light-grey)',
              color: 'var(--color-middle-grey)',
              borderRadius: 500
            }}
          >
            <Icon style={{ fontSize: 18 }}>location_city</Icon>
          </div>
        )
      },
      {
        key: 'refId',
        sortable: true,
        localizationKey: 'Widgets.apartments.Attributes.apartmentId',
        style: { width: 140 },
        longText: true
      },
      {
        key: 'number',
        sortable: true,
        localizationKey: 'Widgets.apartments.Attributes.shyNumber',
        style: { width: 120 },
        longText: true
      },
      {
        key: 'address',
        sortable: true,
        localizationKey: 'Widgets.apartments.Attributes.address',
        style: { width: 200 },
        longText: true
      },
      {
        key: 'additionalInformation',
        sortable: true,
        localizationKey: 'Widgets.apartments.Attributes.additionalInformation',
        style: { width: 200 },
        longText: true
      },
      {
        key: 'organisationName',
        sortable: true,
        localizationKey: 'Widgets.apartments.Attributes.organisationName',
        style: { width: 200 },
        longText: true
      },
      {
        key: 'users',
        sortable: false,
        localizationKey: 'Widgets.apartments.Attributes.apartmentUsers',

        transform: users => (users ? users.length : 0),
        style: { width: 100 }
      },
      {
        key: 'keys',
        sortable: false,
        localizationKey: 'Widgets.apartments.Attributes.apartmentKeys',
        transform: keys => (keys ? keys.length : 0),
        style: { width: 100 }
      },
      {
        key: 'createdAt',
        sortable: true,
        localizationKey: 'Widgets.apartments.Attributes.createdAt',
        transform: value => moment(value).calendar(),
        hiddenFor: (obj, innerWidth) => innerWidth < 1440,
        style: { width: 110 }
      },
      {
        key: 'chevron',
        localizationKey: '',
        sortable: false,
        style: { width: 70 },
        transform: isExpanded => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton
              style={{
                transition: 'transform 150ms',
                marginLeft: 'auto',
                transform: `rotate(${isExpanded ? -90 : 90}deg)`
              }}
            >
              <Icon>chevron_right</Icon>
            </IconButton>
          </div>
        )
      }
    ],
    /* Minified columns */
    widget: [
      {
        key: 'refId',
        sortable: true,
        localizationKey: 'Widgets.apartments.Attributes.apartmentId',
        longText: true
      },
      {
        key: 'address',
        sortable: true,
        localizationKey: 'Widgets.apartments.Attributes.address',
        longText: true
      },
      {
        key: 'createdAt',
        sortable: true,
        localizationKey: 'Widgets.apartments.Attributes.createdAt',
        transform: value => moment(value).calendar()
      },
      {
        key: 'chevron',
        sortable: false,
        localizationKey: '',
        style: { width: 50 },
        transform: () => (
          <Icon
            style={{ display: 'block', marginLeft: 'auto', marginRight: 5 }}
          >
            chevron_right
          </Icon>
        )
      }
    ]
  },
  rowExpansionContent: (item, caller, opts) => (
    <React.Fragment>
      {!caller.roles.includes(UserRoles.Support) && (
        <Button
          variant='outlined'
          onClick={() => {
            opts.setEditItem(item)
            opts.setEditError(null)
          }}
          style={{ marginRight: 18 }}
        >
          {global._('Common.EditDetails')}
        </Button>
      )}
      <Button
        variant='outlined'
        onClick={() => opts.history.push(`/admin/apartments/users/${item.id}`)}
        disabled={caller.roles.includes(UserRoles.Support)}
        style={{ marginRight: 18 }}
      >
        {global._('Widgets.apartments.EditUsers')}
      </Button>
      {!caller.roles.includes(UserRoles.Support) && (
        <>
          <Button
            variant='outlined'
            onClick={() => {
              window.sessionStorage.setItem(
                'selected_org',
                JSON.stringify(item.organisation)
              )
              window.open(`/keys/list?search=${item.refId}`, '_blank')
            }}
            style={{ marginRight: 18 }}
          >
            {global._('Widgets.apartments.ShowKeys')}
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              const aptObj = { ...item }

              if (item.users.length > 0 || item.keys.length > 0) {
                aptObj.deleteModalSubtitle = global._(
                  'Widgets.apartments.DeleteItemHasUsersOrKeys'
                )
                aptObj.deleteModalConfirm = global._(
                  'Widgets.apartments.DeleteItemHasUsersOrKeysInstructions'
                )
                aptObj.deleteButtonDisabled = true
              } else {
                aptObj.name = `${aptObj.number} ${aptObj.address}`
              }

              aptObj.hideDeleteModalInfo = true

              opts.setDeleteItem(aptObj)
              opts.setDeleteError(null)
            }}
          >
            {global._('Common.Delete')}
          </Button>
        </>
      )}
    </React.Fragment>
  )
}
