import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Notification from '../../common/Notification'
import PasswordStrength, {
  testPasswordStrength,
  Strength
} from '../../common/PasswordStrength'
import Footer from '../../components/Footer'
import Typography from '../../components/Typography'
import Column from '../../components/Column'
import Theme from '../../helpers/Theme'
import useWindowDimensions from '../../utils/useWindowDimensions'
import Content from '../../components/Content'
import NavigationBar from '../../components/NavigationBar'
import MobileLogo from '../../components/MobileLogo'
import Password from '../../components/Password'

const styles = {
  root: {
    minHeight: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.08)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 50
  },
  paper: {
    width: 500,
    padding: '45px 30px',
    backgroundColor: '#fff',
    zIndex: '999',
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 3,
    boxSizing: 'border-box',
    border: '1px solid var(--color-middle-grey-2)',
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)'
  },
  iconcontainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    borderRadius: 500,
    backgroundColor: Theme.Colors.PRIMARY_TONE_3
  },
  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */
  '@media (max-width: 900px)': {
    root: {
      padding: 16,
      marginTop: 64,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      backgroundColor: Theme.Colors.WHITE
    },
    paper: {
      margin: '10px auto 0 auto',
      padding: 0,
      width: '100%',
      maxWidth: 500,
      backgroundColor: Theme.Colors.WHITE,
      zIndex: '999',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: 'none',
      boxShadow: 'none'
    }
  }
}

const Recover = props => {
  const { classes, computedMatch } = props
  const { token } = computedMatch.params
  const { isMobile } = useWindowDimensions()
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const updatePassword = async () => {
    if (
      !password ||
      !passwordRepeat ||
      password !== passwordRepeat ||
      testPasswordStrength(password) === Strength.Weak
    )
      return

    setIsLoading(true)
    try {
      await axios.post('/v1/users/recover/password', { password, token })
      setIsComplete(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global.translate(msg))
    }
    setIsLoading(false)
  }

  const onPasswordChange = e => {
    const { value } = e.target

    setPassword(value)
  }

  const onPasswordRepeatChange = e => setPasswordRepeat(e.target.value)

  const buttonDisabled =
    !password ||
    !passwordRepeat ||
    password !== passwordRepeat ||
    testPasswordStrength(password) === Strength.Weak

  let Wrapper
  if (isMobile) {
    Wrapper = Content
  } else {
    Wrapper = React.Fragment
  }

  return (
    <Wrapper>
      {isMobile && (
        <NavigationBar title={global._('Recover.NewPasswordPlaceholder')} />
      )}
      <div className={classes.root}>
        {isMobile && <MobileLogo alt='Digital Låssmed' />}
        <Typography variant='h1' align='center'>
          {global._('Recover.NewPasswordPlaceholder')}
        </Typography>
        <div className={classes.paper}>
          {error && !isComplete && (
            <Notification
              type='error'
              message={error}
              style={{ marginBottom: 25 }}
            />
          )}
          {!isComplete && (
            <form style={{ width: '100%' }}>
              <Password
                data-cy='recover-new-password'
                helperText={global._('Recover.PasswordHelperText')}
                label={global.translate('Recover.NewPasswordPlaceholder')}
                value={password}
                autoComplete='new-password'
                onChange={onPasswordChange}
              />
              <div style={{ height: 15 }} />
              <Password
                data-cy='recover-repeat-password'
                label={global.translate(
                  'Recover.NewPasswordRepeatPlaceholder'
                )}
                value={passwordRepeat}
                autoComplete='off'
                onChange={onPasswordRepeatChange}
              />
              {password && (
                <PasswordStrength
                  password={password}
                  style={{ marginTop: 15, marginBottom: 15 }}
                />
              )}
            </form>
          )}
          {isComplete && (
            <Column flex style={{ padding: '15px 10px' }}>
              <div className={classes.iconcontainer}>
                <Icon style={{ fontSize: 72 }}>done</Icon>
              </div>
              <Typography
                variant='h5'
                style={{ padding: '50px 0' }}
                align='center'>
                {global._('Recover.CompleteText')}
              </Typography>
            </Column>
          )}
          <div style={{ height: 20 }} />
          {!isComplete && (
            <Button
              variant='primary'
              disabled={buttonDisabled}
              loading={isLoading}
              onClick={updatePassword}>
              {global.translate('Recover.UpdatePasswordButton')}
            </Button>
          )}
          {isComplete && (
            <Button
              variant='primary'
              onClick={() => props.history.push('/login')}
              style={{ paddingRight: 60, paddingLeft: 60 }}>
              {global._('Common.Login')}
            </Button>
          )}
        </div>
        <Footer />
      </div>
    </Wrapper>
  )
}

Recover.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  computedMatch: PropTypes.object.isRequired
}

const RecoverWithStyle = injectSheet(styles)(Recover)
export default RecoverWithStyle
