/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import AsyncSelect from 'src/components/AsyncSelect'
import FileContainer from 'src/components/FileContainer'
import ItemBadge from 'src/components/ItemBadge'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import Modal from 'src/components/Modal'
import Notification from 'src/common/Notification'
import DragZone from 'src/components/DragAndDrop/DragZone'
import { detectMimeType } from 'src/utils/mime'
import { UserRoles } from 'src/utils/constants'
import withUser from 'src/utils/withUser'
import { history } from '../redux/store'
import InformationBar from 'src/components/InformationBar'

const ImportModal = ({
  locKey,
  user,
  templateFilePath,
  onClose,
  storageKey,
  transformRow,
  previewUrl,
  fields,
  fieldsData
}) => {
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)

  const handleFileSelected = (aFile, err) => {
    setError(null)
    if (err) {
      return setError(global._(`Errors.${err}`))
    }
    setFile(aFile)
  }

  const onPreview = () => {
    const wb = XLSX.read(file.data, { type: 'base64' })
    const sheetName = wb.SheetNames[0]
    const sheet = wb.Sheets[sheetName]
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 })
    const previewItems = []
    for (let i = 1; i < data.length; i += 1) {
      const row = data[i]
      if (!row[0] && !row[1] && !row[2]) {
        /* skip empty rows */
      } else {
        const previewData = transformRow(row)

        previewItems.push(previewData)
      }
    }
    if (!previewItems.length) {
      return setError(global._(`Import.${locKey}.Modal.NoItemsInFile`))
    }

    const previewData = {
      ...fieldsData,
      previewItems
    }

    localStorage.setItem(storageKey, JSON.stringify(previewData))

    if (previewUrl) {
      history.push(previewUrl)
    }
  }

  const isContinueDisabled =
    (fieldsData && Object.keys(fieldsData).find(fd => !fieldsData[fd])) || !file

  return (
    <Modal
      onClose={onClose}
      title={global._(`Import.${locKey}.Modal.Title`)}
      content={
        <div>
          {fields && (
            <div
              style={{
                marginBottom: 20,
                paddingBottom: 20,
                borderBottom: '1px solid var(--color-divider-light)',
                marginLeft: '-20px',
                marginRight: '-20px',
                paddingLeft: 20,
                paddingRight: 20
              }}
            >
              {fields()}
            </div>
          )}
          <ItemBadge
            icon='description'
            endIcon='get_app'
            onClick={() => window.open(templateFilePath, '__blank')}
            label={global._(`Import.${locKey}.Modal.DownloadTemplateLabel`)}
          />
          <Typography variant='body' faded block style={{ marginTop: 20 }}>
            {global._(`Import.${locKey}.Modal.UploadHelpText`)}
          </Typography>
          <div style={{ margin: '20px 0 10px 0' }}>
            {file ? (
              <FileContainer
                name={file.name}
                size={file.size}
                type={file.type}
                file={file}
                disableClick
                onDelete={() => setFile(null)}
              />
            ) : (
              <DragZone
                buttonOnly
                buttonVariant='outlined'
                buttonText={global._('DragAndDrop.Button.Browse')}
                allowedTypes={[detectMimeType('file.xlsx')]}
                allowedSize={10 * 1000 * 1000}
                onFileSelected={handleFileSelected}
              />
            )}
          </div>
          <Typography variant='body' italic faded block>
            {global._(`Import.${locKey}.Modal.FileInfoHelpText`)}
          </Typography>
          {error && (
            <Notification
              style={{ marginTop: 12 }}
              type='error'
              message={error}
            />
          )}
          {!error &&
            global._(`Import.${locKey}.Modal.GDPRinfo`) !==
              `Import.${locKey}.Modal.GDPRinfo` && (
              <div style={{ marginTop: 12 }}>
                <InformationBar
                  message={global._(`Import.${locKey}.Modal.GDPRinfo`)}
                  compact
                  alignCenter
                  noShadow
                />
              </div>
            )}
        </div>
      }
      buttons={[
        <Button key='Common.Cancel' variant='none' onClick={onClose}>
          {global._('Common.Cancel')}
        </Button>,
        <Button
          key='Common.Preview'
          variant='primary'
          disabled={isContinueDisabled}
          onClick={onPreview}
        >
          {global._('Common.Preview')}
        </Button>
      ]}
    />
  )
}

ImportModal.propTypes = {
  locKey: PropTypes.string.isRequired,
  templateFilePath: PropTypes.string.isRequired,
  storageKey: PropTypes.string.isRequired,
  transformRow: PropTypes.array.isRequired,
  previewUrl: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  fields: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  fieldsData: PropTypes.object
}

ImportModal.defaultProps = {
  fieldsData: {}
}

export default withUser(ImportModal)
