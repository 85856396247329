import React from 'react'
import PropTypes from 'prop-types'
import Typography from './Typography'

const NoResultsMessage = ({ content }) => {
  let text
  let helpText

  if (typeof content === 'object') {
    text = content.text || undefined
    helpText = content.helpText || undefined
  } else {
    text = content
  }

  return (
    <div
      style={{
        maxWidth: 452,
        width: '100%',
        marginTop: 52,
        marginBottom: 59,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0 var(--section-padding-m-ext-h)'
      }}>
      {text && (
        <Typography
          variant='body'
          block
          faded
          align='center'
          style={{ lineHeight: 1.625 }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      {helpText && (
        <Typography
          variant='body'
          italic
          block
          faded
          align='center'
          style={{ marginTop: 40 }}
          dangerouslySetInnerHTML={{ __html: helpText }}
        />
      )}
    </div>
  )
}

NoResultsMessage.propTypes = {
  content: PropTypes.string.isRequired
}

export default NoResultsMessage
