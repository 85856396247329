import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Route, Redirect } from 'react-router-dom'
import { checkIfOrgHasAccessToScope } from 'src/utils/helpers'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import Header from '../../components/Header'
import { OrganisationServices, UserRoles } from '../../utils/constants'
import { setPWA, Variants } from '../../utils/PWASelector'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#fff'
  },
  container: {
    display: 'flex',
    flex: 1
  }
}

const PublicLayout = props => {
  const {
    component: Component,
    classes,
    user,
    expires,
    publicOnly,
    ...otherProps
  } = props

  const { isMobile } = useWindowDimensions()

  // React.useEffect(() => {
  //   setPWA(Variants.DEFAULT)
  // })
  const now = new Date().getTime() / 1000
  if (
    user &&
    Array.isArray(user.roles) &&
    !user.roles.includes(UserRoles.Guest) &&
    expires > now &&
    publicOnly
  ) {
    let url
    const returnTo = localStorage.getItem('returnTo')
    const hasAccessToKEE = checkIfOrgHasAccessToScope(
      user,
      user.organisation,
      OrganisationServices.KEE
    )
    if (isMobile && hasAccessToKEE) {
      url = '/mylocks'
    } else if (returnTo) {
      localStorage.removeItem('returnTo')
      url = returnTo
    }
    return <Redirect to={url || '/'} />
  }

  return (
    <Route
      {...otherProps}
      render={matchProps => (
        <div className={classes.root}>
          <Header user={user} defaultLogotype />
          <div className={classes.container}>
            <Component {...props} {...matchProps} />
          </div>
        </div>
      )}
    />
  )
}

PublicLayout.propTypes = {
  component: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  expires: PropTypes.number,
  publicOnly: PropTypes.bool
}

PublicLayout.defaultProps = {
  user: null,
  expires: null,
  publicOnly: true
}

const PublicLayoutWithStyles = injectSheet(styles)(PublicLayout)
export default PublicLayoutWithStyles
