import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody
} from '@material-ui/core'
import queryString from 'querystring'
import InfiniteScroll from './InfiniteScroll'
import Paper from './Paper'
import Typography from './Typography'
import Button from './Button'
import NoResultsMessage from './NoResultsMessage'
import Theme from '../helpers/Theme'
import LoadingSpinner from './LoadingSpinner'
import SectionTitle from './SectionTitle'

const styles = {
  root: {},
  listTable: {
    margin: '0 -20px',
    width: 'calc(100% + 40px)',
    tableLayout: 'fixed'
  },
  objectRow: {
    transition: 'all 0.3s',
    cursor: props => (props.cursorObjectRow ? 'pointer' : 'default'),
    '&:hover': {
      backgroundColor: props =>
        props.objRowBgColor
          ? props.objRowBgColor
          : 'var(--color-material-grey)',
      '& .columnIcon': {
        transition: 'all 0.3s',
        backgroundColor: props =>
          props.colIconColor ? props.colIconColor : 'var(--color-white)'
      }
    },
    '& .MuiIcon-root': {
      verticalAlign: 'top'
    }
  },
  column: {
    padding: '14px 10px',
    lineHeight: 1,
    '&:first-child': {
      paddingLeft: 17
    },
    '&:last-child': {
      paddingRight: 17
    }
  },
  tableSort: {
    transition: 'all 0.3s',
    '&:hover': {
      color: 'var(--color-black) !important',
      '& .MuiSvgIcon-root': {
        color: 'var(--color-black) !important'
      }
    }
  },
  bold: {
    fontWeight: 600
  },
  longText: {
    '& span': {
      display: 'inline-block',
      maxWidth: '99%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  hiddenOnSm: {
    '@media (max-width: 1360px)': {
      display: 'none'
    }
  },
  filterFields: {
    borderTop: '1px solid var(--color-divider-light)',
    borderBottom: '1px solid var(--color-divider-light)',
    padding: 'var(--section-padding-default)',
    marginBottom: 20,
    marginLeft: '-20px',
    marginRight: '-20px',
    width: 'calc(100% + 40px)'
  }
}

const ObjectList = ({
  classes,
  sectionTitle,
  infoDialogTitle,
  infoDialogContent,
  titleContent,
  objects,
  isLoadingObjects,
  noResultsMessage,
  columns,
  history,
  objectPermalink,
  actionButtons,
  filter,
  expandedRows,
  setExpandedRows,
  expandable,
  canFetchMoreObjects,
  fetchMoreObjects,
  newTab,
  setObjReadStatusLocally,
  setFilter,
  setLocalFilter,
  localFilterName,
  wrapper,
  filterFields,
  expandableObjectsSlug,
  expandableObjectsPermalink,
  customHeader,
  renderEmpty,
  rowIdentifier,
  containerStyles,
  replaceActionIcon,
  actionColumnSize,
  genExpansionKey,
  useTypography,
  instructionsVideoId
}) => {
  const [orderBy, setOrderBy] = useState(null)

  const handleSorting = colData => {
    const { key, sortingKey } = colData

    const orderByKey = sortingKey || key

    setOrderBy(orderByKey)

    const aFilter = { ...filter }

    aFilter.orderBy = orderByKey

    aFilter.order = aFilter.order === 'asc' ? 'desc' : 'asc'
    aFilter.offset = 0

    if (setLocalFilter) {
      setLocalFilter(aFilter, true, localFilterName, setFilter)
    } else {
      setFilter(aFilter)
    }
  }

  const printColumns = (obj, isExpanded, parentObj) =>
    columns &&
    Array.isArray(columns) &&
    columns.length > 0 &&
    columns.map((col, index) => {
      if (!col) {
        return
      }
      const { hiddenOnSm, bold, longText } = col

      let value

      if (obj[col.key] && col.valueKey) {
        value = obj[col.key][col.valueKey]
      } else if (!obj[col.key] && col.defaultValue) {
        value = col.defaultValue
      } else if (col.format) {
        value = col.format(obj[col.key], obj, parentObj)
      } else {
        value = obj[col.key]
      }

      if (
        (typeof col.hiddenFor !== 'function' && col.hiddenFor) ||
        (typeof col.hiddenFor === 'function' && col.hiddenFor(obj))
      )
        return

      let wrapWithTypography = true
      if (col.useTypography === false) {
        wrapWithTypography = false
      } else if (useTypography) {
        wrapWithTypography = true
      }

      return (
        <TableCell
          data-cy={`table-cell-${index}`}
          key={col.key}
          style={col.style || undefined}
          className={`${classes.column} borderless ${
            bold ? classes.bold : ''
          } ${longText ? classes.longText : ''} ${
            hiddenOnSm ? classes.hiddenOnSm : ''
          }`}
          onClick={() => {
            if (col.clickable && (obj.permalink || objectPermalink)) {
              const url = `/${obj.permalink || objectPermalink}/${obj.id}${
                filter ? `?${queryString.stringify(filter)}` : ''
              }`

              if (setObjReadStatusLocally) {
                setObjReadStatusLocally(obj, true) // Mark object as read locally
              }

              if (newTab) {
                return window.open(url)
              }

              return history.push(url)
            }

            if (expandable) {
              if (isExpanded) {
                return setExpandedRows(
                  expandedRows.filter(
                    id =>
                      id !== (genExpansionKey ? genExpansionKey(obj) : obj.id)
                  )
                )
              }

              return setExpandedRows([
                ...expandedRows,
                genExpansionKey ? genExpansionKey(obj) : obj.id
              ])
            }
          }}
        >
          {wrapWithTypography ? <Typography>{value}</Typography> : value}
        </TableCell>
      )
    })

  const Content = (
    <>
      {customHeader}
      {sectionTitle && (
        <SectionTitle
          title={sectionTitle}
          infoDialogTitle={infoDialogTitle}
          infoDialogContent={infoDialogContent}
          titleContent={titleContent}
          instructionsVideoId={instructionsVideoId}
        />
      )}
      {filterFields && (
        <div className={classes.filterFields}>{filterFields}</div>
      )}
      {isLoadingObjects && !objects && (
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <LoadingSpinner />
        </div>
      )}
      {!isLoadingObjects &&
      (!objects || !Array.isArray(objects) || !objects.length) ? (
        <>
          {noResultsMessage && <NoResultsMessage content={noResultsMessage} />}
        </>
      ) : null}
      {(objects && Array.isArray(objects) && objects.length > 0) ||
      renderEmpty ? (
        <InfiniteScroll
          fetchMore={fetchMoreObjects}
          hasMore={canFetchMoreObjects}
          loading={isLoadingObjects}
        >
          <Table className={classes.listTable}>
            <TableHead>
              <TableRow>
                {columns.map(col => {
                  if (col === null || col.hiddenFor) return

                  return (
                    <TableCell
                      key={col.key}
                      style={col.style || null}
                      className={`${classes.column} ${
                        col.hiddenOnSm ? classes.hiddenOnSm : ''
                      }`}
                    >
                      <TableSortLabel
                        active={
                          (orderBy || filter.orderBy) ===
                          (col.sortingKey || col.key)
                        }
                        direction={filter.order}
                        onClick={() =>
                          !col.sortingDisabled && handleSorting(col)
                        }
                        className={
                          col.sortingDisabled ? undefined : classes.tableSort
                        }
                        hideSortIcon={col.sortingDisabled}
                        style={{
                          color:
                            (orderBy || filter.orderBy) ===
                            (col.sortingKey || col.key)
                              ? '#000'
                              : '#8E8E8E',
                          fontSize: 14,
                          fontWeight: 'bold',
                          fontFamily: Theme.Typography.fontFamily,
                          cursor: col.sortingDisabled && 'auto'
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: global._(col.localizationKey)
                          }}
                        />
                      </TableSortLabel>
                    </TableCell>
                  )
                })}

                {(expandable && actionButtons(objects[0].id, objects[0])) ||
                (replaceActionIcon && replaceActionIcon(objects[0])) ? (
                  <TableCell style={{ width: actionColumnSize || 67 }} />
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {objects.map((obj, index) => {
                const isExpanded = expandedRows.includes(
                  genExpansionKey ? genExpansionKey(obj) : obj.id
                )

                return (
                  <React.Fragment
                    key={`${
                      (rowIdentifier && rowIdentifier(obj)) || obj.type || ''
                    }-${obj.id || index}`}
                  >
                    <TableRow
                      data-cy={`table-row-${index}`}
                      className={`${classes.objectRow} objectRow`}
                      style={{
                        backgroundColor:
                          (isExpanded || obj.darkBG) &&
                          'var(--color-material-grey)'
                      }}
                    >
                      {printColumns(obj, isExpanded)}
                      {expandable &&
                      (!replaceActionIcon ||
                        (replaceActionIcon && !replaceActionIcon(obj))) &&
                      actionButtons(obj.id, obj) &&
                      (!expandableObjectsSlug ||
                        (obj[expandableObjectsSlug] &&
                          obj[expandableObjectsSlug].length > 0)) ? (
                        <TableCell className='expandCol'>
                          <Button
                            data-cy={`button-toggle-row-${index}`}
                            style={{ marginLeft: 'auto', marginRight: 12 }}
                            variant='icon'
                            icon={isExpanded ? 'expand_less' : 'expand_more'}
                            darkBg
                            onClick={() => {
                              if (isExpanded) {
                                setExpandedRows(
                                  expandedRows.filter(
                                    id =>
                                      id !==
                                      (genExpansionKey
                                        ? genExpansionKey(obj)
                                        : obj.id)
                                  )
                                )
                              } else {
                                setExpandedRows([
                                  ...expandedRows,
                                  genExpansionKey
                                    ? genExpansionKey(obj)
                                    : obj.id
                                ])
                              }
                            }}
                          />
                        </TableCell>
                      ) : null}
                      {replaceActionIcon && replaceActionIcon(obj) && (
                        <TableCell>{replaceActionIcon(obj)}</TableCell>
                      )}
                    </TableRow>
                    {isExpanded &&
                      expandableObjectsSlug &&
                      obj[expandableObjectsSlug] &&
                      obj[expandableObjectsSlug].map(expObj => {
                        const expandedObj = { ...expObj }

                        if (expandableObjectsPermalink) {
                          expandedObj.permalink =
                            expandableObjectsPermalink(expObj)
                        }

                        return (
                          <TableRow
                            className={`${classes.objectRow} objectRow`}
                            style={{
                              backgroundColor: 'var(--color-material-grey)'
                            }}
                          >
                            {printColumns(expandedObj, isExpanded, obj)}
                          </TableRow>
                        )
                      })}
                    {isExpanded && actionButtons(obj.id, obj) && (
                      <TableRow
                        style={{
                          backgroundColor: 'var(--color-material-grey)'
                        }}
                      >
                        <TableCell
                          colSpan={
                            columns &&
                            columns.filter(col => !col.hiddenFor).length + 1
                          }
                          style={{ textAlign: 'right', padding: '30px 20px' }}
                        >
                          {actionButtons(obj.id, obj)}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
            </TableBody>
          </Table>
        </InfiniteScroll>
      ) : null}
    </>
  )

  return (
    <div className={classes.root}>
      {wrapper ? (
        <Paper
          smallerPadding
          style={{ paddingTop: 30, ...containerStyles }}
          alignItems='left'
        >
          {Content}
        </Paper>
      ) : (
        <div>{Content}</div>
      )}
    </div>
  )
}

ObjectList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  customHeader: PropTypes.node,
  sectionTitle: PropTypes.string,
  infoDialogTitle: PropTypes.string || PropTypes.object,
  infoDialogContent: PropTypes.any,
  titleContent: PropTypes.any,
  objects: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number
  ]),
  isLoadingObjects: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  columns: PropTypes.array.isRequired,
  objectPermalink: PropTypes.string,
  actionButtons: PropTypes.any,
  filter: PropTypes.object,
  expandedRows: PropTypes.array,
  setExpandedRows: PropTypes.func,
  expandable: PropTypes.bool,
  canFetchMoreObjects: PropTypes.bool,
  fetchMoreObjects: PropTypes.func,
  newTab: PropTypes.bool,
  setObjReadStatusLocally: PropTypes.func,
  setFilter: PropTypes.func,
  wrapper: PropTypes.bool,
  filterFields: PropTypes.any,
  expandableObjectsSlug: PropTypes.string,
  expandableObjectsPermalink: PropTypes.string,
  renderEmpty: PropTypes.bool,
  rowIdentifier: PropTypes.func,
  replaceActionIcon: PropTypes.func,
  containerStyles: PropTypes.object,
  actionColumnSize: PropTypes.number,
  genExpansionKey: PropTypes.func,
  useTypography: PropTypes.bool
}

ObjectList.defaultProps = {
  sectionTitle: null,
  customHeader: null,
  infoDialogTitle: null,
  infoDialogContent: null,
  titleContent: null,
  actionButtons: null,
  filter: {},
  expandedRows: [],
  expandable: true,
  objects: null,
  canFetchMoreObjects: false,
  newTab: false,
  setExpandedRows: null,
  fetchMoreObjects: null,
  setObjReadStatusLocally: null,
  wrapper: true,
  objectPermalink: undefined,
  isLoadingObjects: undefined,
  noResultsMessage: undefined,
  setFilter: undefined,
  expandableObjectsSlug: '',
  expandableObjectsPermalink: '',
  filterFields: undefined,
  renderEmpty: false,
  rowIdentifier: null,
  history: null,
  replaceActionIcon: null,
  containerStyles: null,
  actionColumnSize: null,
  genExpansionKey: undefined,
  useTypography: true
}

const ObjectListWithStyles = injectSheet(styles)(ObjectList)
export default ObjectListWithStyles
