import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import IconColumn from 'src/components/IconColumn'
import Column from 'src/components/Column'
import Row from 'src/components/Row'
import Typography from '../../../../components/Typography'
import { mapKeyStatus } from '../../../../utils/helpers'
import ImportPreview from '../../../../components/ImportPreview'
import {
  KeyType,
  KeyStatus,
  ImportStorageKeys
} from '../../../../utils/constants'

const styles = {}

const KeyImportPreview = ({ classes, history, breadcrumbItems }) => {
  const [error, setError] = useState(null)
  const [items, setItems] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [organisation, setOrganisation] = useState({ name: '' })
  const [lockSystem, setLockSystem] = useState({ name: '' })

  const handleImport = async () => {
    setIsLoading(true)
    try {
      const validItems = items.filter(item => item.errors.length === 0)
      /* Only send the data needed to create the key */
      const data = validItems.map(key => {
        const {
          invalid,
          storage,
          keyring,
          apartment,
          holderId,
          organisationName,
          ...rest
        } = key
        return rest
      })

      if (!data.length) {
        return setIsLoading(false)
      }

      await axios.post(
        `/v1/organisations/${data[0].organisationId}/keys/import`,
        data
      )

      /* Show success message when returning to /keys/list */
      localStorage.setItem('keys.import.success', '1')

      /* Remove data used to present keys in this preview */
      localStorage.removeItem(ImportStorageKeys.KEYS)

      history.push('/keys/list')
    } catch (e) {
      setIsLoading(false)
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const validate = async (previewData, keys, validationData) => {
    if (!Array.isArray(keys) || !keys.length) {
      return
    }
    try {
      const { data: existingKeys } = await axios.post(
        `/v1/organisations/${previewData.organisation.id}/keys/validate`,
        validationData
      )

      const keysArr = []

      keys.forEach(key => {
        const errors = []

        const apartment = existingKeys.apartments.find(
          a => a.refId === key.apartmentRefId
        )
        const storage = existingKeys.storages.find(s => s.id == key.storageId)
        const keyring = existingKeys.keyrings.find(kr => kr.id == key.keyringId)
        const existingMarkingExternal = existingKeys.markings.find(
          m => m === key.marking
        )

        const existingMarkingLocal = keysArr.find(
          k => k !== key && k.marking === key.marking
        )

        if (key.storageId) {
          if (storage) {
            key.storage = storage
          } else {
            errors.push('Import.Keys.Preview.Errors.HolderNotFound')
          }
        }
        if (key.apartmentRefId) {
          if (apartment) {
            key.apartment = apartment
            key.apartmentId = apartment.id
          } else {
            errors.push('Import.Keys.Preview.Errors.HolderNotFound')
          }
        }

        if (key.keyringId) {
          if (keyring) {
            key.keyring = keyring
          } else {
            errors.push('Import.Keys.Preview.Errors.KeyringNotFound')
          }
        }

        if (key.marking) {
          /* duplicate outside of the import */
          if (existingMarkingExternal) {
            errors.push(
              'Import.Keys.Preview.Errors.MarkingAlreadyExistsInLockSystem'
            )
          }

          /* duplicate within the import */
          if (existingMarkingLocal) {
            errors.push(
              'Import.Keys.Preview.Errors.MarkingAlreadyExistsInImport'
            )
          }
        } else {
          errors.push('Import.Keys.Preview.Errors.MarkingIsMissing')
        }

        if (!key.type) {
          errors.push('Import.Keys.Preview.Errors.TypeIsMissing')
        } else if (!Object.values(KeyType).includes(key.type)) {
          errors.push('Import.Keys.Preview.Errors.InvalidType')
        }

        keysArr.push({
          ...key,
          errors
        })
      })

      setItems(keysArr)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    setIsLoading(true)
    ;(async () => {
      try {
        const previewData = JSON.parse(
          localStorage.getItem(ImportStorageKeys.KEYS)
        )

        setOrganisation(previewData.organisation)
        setLockSystem(previewData.lockSystem)
        /* Transform excel values to preview items in table */
        const arr = []
        const validationData = {
          organisationId: previewData.organisation.id,
          lockSystemId: previewData.lockSystem.id,
          apartmentRefIds: [],
          storageIds: [],
          keyringIds: [],
          markings: []
        }
        previewData.previewItems.forEach(key => {
          /* strip empty fields */
          Object.keys(key).forEach(attr => {
            if (!key[attr]) {
              delete key[attr]
            }
          })
          if (key.marking) {
            key.marking = `${key.marking}`
          }

          let type
          /* Transform custom types from excel to KeyTypes */
          if (key.type && key.type.toLowerCase() === 'mekanisk') {
            type = KeyType.MECHANICAL
          } else if (key.type && key.type.toLowerCase() === 'digital') {
            type = KeyType.DIGITAL
          } else if (key.type && key.type.toLowerCase() === 'rfid-tagg') {
            type = KeyType.RFID
          } else {
            ;({ type } = key)
          }

          if (
            key.storageId &&
            !validationData.storageIds.includes(key.storageId)
          ) {
            validationData.storageIds.push(key.storageId)
          }

          if (
            key.apartmentRefId &&
            !validationData.apartmentRefIds.includes(key.apartmentRefId)
          ) {
            validationData.apartmentRefIds.push(key.apartmentRefId)
          }

          if (
            key.keyringId &&
            !validationData.keyringIds.includes(key.keyringId)
          ) {
            validationData.keyringIds.push(key.keyringId)
          }

          if (key.marking && !validationData.markings.includes(key.marking)) {
            validationData.markings.push(key.marking)
          }

          arr.push({
            ...key,
            type,
            status: KeyStatus.ACTIVE,
            organisationName: previewData.organisation.name,
            lockSystemName: previewData.lockSystem.name
          })
        })

        await validate(previewData, arr, validationData)
      } catch (e) {
        setError(e.message)
      }
      setIsLoading(false)
    })()
  }, [])

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      style: { width: 60 },
      format: (objVal, obj) =>
        obj && (
          <IconColumn
            icon='vpn_key'
            style={{
              color: obj.invalid
                ? 'var(--color-error-red)'
                : undefined /* turn icon red when type is invalid */,
              backgroundColor: obj.invalid
                ? 'var(--color-error-red-bg)'
                : undefined /* turn icon red when type is invalid */
            }}
          />
        )
    },
    {
      key: 'name',
      localizationKey: 'Keys.List.KeyName',
      sortingKey: 'name',
      sortingDisabled: true,
      style: { width: 200 },
      format: name => <Typography>{name || '-'}</Typography>
    },
    {
      key: 'marking',
      localizationKey: 'Keys.List.Marking',
      sortingKey: 'marking',
      sortingDisabled: true,
      style: { width: 100 },
      format: (marking, item) => {
        const relatedErrors = [
          'Import.Keys.Preview.Errors.MarkingIsMissing',
          'Import.Keys.Preview.Errors.MarkingAlreadyExistsInLockSystem',
          'Import.Keys.Preview.Errors.MarkingAlreadyExistsInImport'
        ]
        if (
          item.errors &&
          item.errors.some(err => relatedErrors.includes(err))
        ) {
          return (
            <Typography bold style={{ color: 'var(--color-error-red)' }}>
              {marking || '-'}
            </Typography>
          )
        }
        return <Typography>{marking || '-'}</Typography>
      }
    },
    {
      key: 'status',
      localizationKey: 'Keys.List.Status',
      format: status =>
        status && (
          <>
            <span className={`status ${mapKeyStatus(status)}`}>
              {global._(`Keys.Statuses.${status}`)}
            </span>
          </>
        ),
      sortingDisabled: true,
      sortingKey: 'status',
      style: { width: 100 }
    },
    {
      key: 'type',
      localizationKey: 'Keys.List.Type',
      format: (type, item) => {
        let value
        if (type && Object.values(KeyType).includes(type.toUpperCase())) {
          value = global._(`Keys.Types.${type}`)
        } else if (type) {
          value = type
        } else {
          value = '-'
        }
        if (
          item.errors &&
          (item.errors.includes('Import.Keys.Preview.Errors.InvalidType') ||
            item.errors.includes('Import.Keys.Preview.Errors.TypeIsMissing'))
        ) {
          return (
            <Typography
              variant='body'
              bold
              style={{ color: 'var(--color-error-red)' }}
            >
              {value}
            </Typography>
          )
        }
        return <Typography>{value}</Typography>
      },
      sortingKey: 'type',
      sortingDisabled: true,
      style: { width: 100 }
    },
    {
      key: 'keyringId',
      localizationKey: 'Keys.List.KeyRing',
      format: (id, item) => {
        if (
          item.errors &&
          item.errors.includes('Import.Keys.Preview.Errors.KeyringNotFound')
        ) {
          return (
            <Typography bold style={{ color: 'var(--color-error-red)' }}>
              {id}
            </Typography>
          )
        }
        if (item.keyring) {
          return <Typography>{item.keyring.name}</Typography>
        }
        return <Typography>{id}</Typography>
      },
      sortingDisabled: true,
      style: { width: 150 }
    },
    {
      key: 'holderId',
      localizationKey: 'Keys.List.Holder',
      style: { width: 150 },
      format: (id, item) => {
        if (
          item.errors &&
          item.errors.includes('Import.Keys.Preview.Errors.HolderNotFound')
        ) {
          return (
            <Typography bold style={{ color: 'var(--color-error-red)' }}>
              {id}
            </Typography>
          )
        }

        if (item.apartment) {
          return <Typography>{item.apartment.refId}</Typography>
        }
        if (item.storage) {
          return <Typography>{item.storage.name}</Typography>
        }
        return <Typography>{id}</Typography>
      },
      sortingDisabled: true
    }
  ]

  return (
    <div className={classes.root}>
      <ImportPreview
        locKey='Keys'
        items={items}
        error={error}
        setError={setError}
        handleImport={handleImport}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        headerIcon='vpn_key'
        columns={columns}
        breadcrumbItems={breadcrumbItems}
        additionalTitleContent={
          <Column
            align='flex-start'
            justify='flex-start'
            style={{
              borderBottom: '1px solid var(--color-divider-light)',
              padding: 20,
              width: 'calc(100% + 40px)',
              marginLeft: '-20px',
              marginRight: '-20px'
            }}
          >
            <Typography bold style={{ marginBottom: 16 }}>
              {global._('Import.Keys.Preview.InformationalTitle')}
            </Typography>
            <Row style={{ marginBottom: 4 }}>
              <Typography variant='body2' bold faded style={{ width: 150 }}>
                {global._('Import.Keys.Preview.Organisation')}
              </Typography>
              <Typography variant='body2' bold>
                {organisation.name}
              </Typography>
            </Row>
            <Row>
              <Typography variant='body2' bold faded style={{ width: 150 }}>
                {global._('Import.Keys.Preview.LockSystem')}
              </Typography>
              <Typography variant='body2' bold>
                {lockSystem.name}
              </Typography>
            </Row>
          </Column>
        }
      />
    </div>
  )
}

KeyImportPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired
}

KeyImportPreview.defaultProps = {}

const KeyImportPreviewWithStyles = injectSheet(styles)(KeyImportPreview)
export default KeyImportPreviewWithStyles
