import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import Button from '../Button'
import Input from '../Input'
import Link from '../Link'
import Typography from '../Typography'
import FileContainer from '../FileContainer'
import DragZone from '../DragAndDrop/DragZone'
import Notification from '../../common/Notification'
import { UserRoles } from '../../utils/constants'
import NavigationBar from '../NavigationBar'
import TabBar from '../TabBar'
import TabBarItem from '../TabBarItem'
import Content from '../Content'
import ScrollView from '../ScrollView'
import Theme from '../../helpers/Theme'
import useWindowDimensions from '../../utils/useWindowDimensions'
import Footer from '../Footer'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: 600,
    marginTop: 45,
    textAlign: 'center'
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    border: '1px solid #8e8e8e',
    borderRadius: 3,
    padding: 13
  },

  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */

  '@media (max-width: 900px)': {
    root: {
      maxWidth: 'none',
      marginTop: 0,
      paddingBottom: 0,
      padding: 16,
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: Theme.Colors.MATERIAL_GREY
    }
  },
  stepperContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 35
  },
  stepperDot: {
    backgroundColor: Theme.Colors.LIGHT_GREY,
    width: 6,
    height: 6,
    borderRadius: 500,
    margin: 4
  },
  stepperDotActive: {
    backgroundColor: Theme.Colors.GREY
  }
}

const Steps = {
  UPLOAD: 1,
  DETAILS: 2
}

const AllowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf']
const AllowedFileSize = 10 * 1000 * 1000 // 10 MB

const BlueprintWizard = props => {
  const { classes, user, history, computedMatch } = props

  const { isMobile } = useWindowDimensions()
  const { propertyId } = computedMatch.params
  const isOnboarding =
    !user || (user && user.roles && user.roles.includes(UserRoles.Guest))

  const [currentStep, setCurrentStep] = useState(Steps.UPLOAD)
  const [file, setFile] = useState(null)
  const [organisationName, setOrganisationName] = useState('')
  const [blueprintName, setBlueprintName] = useState('')
  const [propertyName, setPropertyName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const onFileSelected = selectedFile => {
    const { type, size } = selectedFile
    let errorCode
    if (!AllowedFileTypes.includes(type)) {
      errorCode = 'ERR_FILE_TYPE'
    } else if (size > AllowedFileSize) {
      errorCode = 'ERR_FILE_SIZE'
    } else {
      setError(null)
      setFile(selectedFile)
      setCurrentStep(Steps.DETAILS)
    }

    if (errorCode) {
      const msg = global._(`BlueprintWizard.Errors.${errorCode}`)
      setError(msg)
    }
  }

  const onRemoveFile = () => {
    setError(null)
    setFile(null)
    setCurrentStep(Steps.UPLOAD)
  }

  const inputIsEmpty = () =>
    propertyName.trim() === '' ||
    blueprintName.trim() === '' ||
    organisationName.trim() === ''

  const onFinish = async () => {
    setError(null)
    setIsLoading(true)
    try {
      const blueprintData = {
        name: blueprintName,
        file
      }

      if (isOnboarding) {
        // Create guest user, organisations and property
        const res = await axios.post('/v1/guest', { organisationName })
        props.setAuthenticationData(res.data)
        const { data: propertyData } = await axios.post('/v1/properties', {
          name: propertyName
        })
        blueprintData.propertyId = propertyData.id
      } else {
        /* Set property id from url params */
        blueprintData.propertyId = propertyId
      }

      const { data: blueprint } = await axios.post(
        '/v1/blueprints',
        blueprintData
      )

      if (isOnboarding) {
        history.push(
          `/onboarding/properties/${blueprint.propertyId}/blueprints/${blueprint.id}`
        )
      } else {
        history.push(
          `/properties/${blueprint.propertyId}/blueprints/${blueprint.id}`
        )
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
      setIsLoading(false)
    }
  }

  /* Mobile */
  if (isMobile) {
    return (
      <Content>
        <NavigationBar
          title={global._('BlueprintWizard.NavigationBarTitle')}
          backAction={() => {
            if (currentStep === Steps.UPLOAD) {
              history.goBack()
            } else {
              setCurrentStep(Steps.UPLOAD)
            }
          }}
        />
        <ScrollView
          style={{
            background:
              currentStep === Steps.DETAILS ? 'var(--color-white)' : undefined
          }}
        >
          <div
            className={classes.root}
            style={{
              backgroundColor:
                currentStep === Steps.UPLOAD
                  ? Theme.Colors.MATERIAL_GREY
                  : '#fff'
            }}
          >
            {currentStep === Steps.UPLOAD && (
              <>
                <Typography
                  variant='h2'
                  align='center'
                  style={{
                    padding: '40px 0',
                    width: 242,
                    maxWidth: '90%',
                    marginTop: 10
                  }}
                >
                  {global._('BlueprintWizard.Text.Title')}
                </Typography>
                <DragZone
                  title={global._('BlueprintWizard.Text.DropZoneTitle')}
                  subtitle={global._('BlueprintWizard.Text.DropZoneSubtitle')}
                  onFileSelected={onFileSelected}
                />
              </>
            )}
            {currentStep === Steps.DETAILS && !!file && (
              <div style={{ width: '100%', marginTop: 56 }}>
                <FileContainer
                  name={file.name}
                  size={file.size}
                  type={file.type}
                  onDelete={onRemoveFile}
                />
                {isOnboarding && (
                  <Input
                    placeholder={global._(
                      'BlueprintWizard.Input.OrganisationName'
                    )}
                    value={organisationName}
                    onChange={e => setOrganisationName(e.target.value)}
                    style={{ marginTop: 18 }}
                  />
                )}
                <Input
                  placeholder={global._('BlueprintWizard.Input.BlueprintName')}
                  value={blueprintName}
                  onChange={e => setBlueprintName(e.target.value)}
                  style={{ marginTop: 14 }}
                />
                {isOnboarding && (
                  <Input
                    placeholder={global._('BlueprintWizard.Input.PropertyName')}
                    value={propertyName}
                    onChange={e => setPropertyName(e.target.value)}
                    style={{
                      marginTop: 14
                    }}
                  />
                )}
              </div>
            )}
            {isOnboarding && currentStep === Steps.UPLOAD && (
              <div style={{ padding: '27px 12px', margin: '0 auto' }}>
                <Typography variant='body2' align='center'>
                  <Link to='/login' style={{ marginRight: 5 }}>
                    {global._('Common.Login')}
                  </Link>
                  {global._('RegisterOnboarding.IfYouAlreadyHaveAnAccount')}
                </Typography>
              </div>
            )}
            <div
              className={classes.stepperContainer}
              style={{
                marginTop: currentStep === Steps.DETAILS ? 48 : undefined,
                paddingBottom: currentStep === Steps.DETAILS ? 0 : undefined
              }}
            >
              <div
                className={classnames(
                  classes.stepperDot,
                  currentStep === Steps.UPLOAD && classes.stepperDotActive
                )}
              />
              <div
                className={classnames(
                  classes.stepperDot,
                  currentStep === Steps.DETAILS && classes.stepperDotActive
                )}
              />
            </div>
            {currentStep === Steps.DETAILS && (
              <div style={{ width: '100%', padding: '0 16px 24px' }}>
                <Button
                  variant='primary'
                  onClick={onFinish}
                  loading={isLoading}
                  disabled={isOnboarding && inputIsEmpty()}
                  style={{
                    width: '100%',
                    marginTop: 50
                  }}
                >
                  {global._('BlueprintWizard.Button.Create')}
                </Button>
              </div>
            )}
          </div>
        </ScrollView>
        {isOnboarding && currentStep === Steps.UPLOAD && <Footer />}
        {!isOnboarding && (
          <TabBar>
            <TabBarItem
              title={global._('Home.Title')}
              icon='home'
              onClick={() => history.push('/')}
              selected
            />
            <TabBarItem
              title={global._('Common.Logout')}
              onClick={() => {
                props.signout()
              }}
              icon='account_circle'
            />
          </TabBar>
        )}
      </Content>
    )
  }

  /*
   * Desktop
   */

  return (
    <div
      className={classes.root}
      style={{
        paddingBottom: isOnboarding ? 110 : 75,
        paddingTop: isOnboarding ? 100 : 0,
        marginTop: isOnboarding ? 0 : 45
      }}
    >
      <Typography
        variant='h1'
        align='center'
        style={{
          width: 375,
          margin: isOnboarding ? '0 auto' : 'auto'
        }}
      >
        {global._('BlueprintWizard.Text.Title')}
      </Typography>
      {isOnboarding && (
        <div style={{ padding: '5px 0', marginTop: 14 }}>
          <Typography variant='body2'>
            <Link to='/login' style={{ marginRight: 5 }}>
              {global._('Common.Login')}
            </Link>
            {global._('RegisterOnboarding.IfYouAlreadyHaveAnAccount')}
          </Typography>
        </div>
      )}
      <div style={{ height: 50 }} />
      {error !== null && (
        <Notification
          type='error'
          message={error}
          style={{ marginBottom: 12 }}
        />
      )}
      {currentStep === Steps.UPLOAD && (
        <div style={{ width: 600, maxWidth: '99%' }}>
          <DragZone
            title={global._('BlueprintWizard.Text.DropZoneTitle')}
            subtitle={global._('BlueprintWizard.Text.DropZoneSubtitle')}
            onFileSelected={onFileSelected}
          />
          {isOnboarding && <Footer />}
        </div>
      )}
      {currentStep === Steps.DETAILS && !!file && (
        <div>
          <FileContainer
            name={file.name}
            size={file.size}
            type={file.type}
            onDelete={onRemoveFile}
          />
          {isOnboarding && (
            <>
              <div style={{ height: 25 }} />
              <Input
                data-cy='input-organisation-name'
                placeholder={global._('BlueprintWizard.Input.OrganisationName')}
                helpText={global._('Common.Required')}
                value={organisationName}
                onChange={e => setOrganisationName(e.target.value)}
                style={{ marginTop: 18 }}
              />
            </>
          )}
          <div style={{ height: 25 }} />
          <Input
            data-cy='input-blueprint-name'
            placeholder={global._('BlueprintWizard.Input.BlueprintName')}
            helpText={global._('Common.Required')}
            value={blueprintName}
            onChange={e => setBlueprintName(e.target.value)}
          />
          {isOnboarding && (
            <>
              <div style={{ height: 25 }} />
              <Input
                data-cy='input-property-name'
                placeholder={global._('BlueprintWizard.Input.PropertyName')}
                helpText={global._('Common.Required')}
                value={propertyName}
                onChange={e => setPropertyName(e.target.value)}
              />
            </>
          )}
          <div style={{ height: 45 }} />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant='none'
              onClick={onRemoveFile}
              style={{ marginRight: 20 }}
            >
              {global._('BlueprintWizard.Button.Back')}
            </Button>
            <Button
              data-cy='button-onboarding-create'
              variant='primary'
              onClick={onFinish}
              loading={isLoading}
              disabled={isOnboarding && inputIsEmpty()}
            >
              {global._('BlueprintWizard.Button.Create')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

BlueprintWizard.propTypes = {
  classes: PropTypes.object.isRequired,
  setAuthenticationData: PropTypes.func.isRequired,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
  computedMatch: PropTypes.object.isRequired
}

BlueprintWizard.defaultProps = {
  user: null
}

const BlueprintWizardWithStyles = injectSheet(styles)(BlueprintWizard)
export default BlueprintWizardWithStyles
