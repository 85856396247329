import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import { clearSelectedOrg } from 'src/utils/helpers'
import Divider from '@material-ui/core/Divider'
import useWindowDimensions from 'src/utils/useWindowDimensions'

const styles = {
  root: {}
}

const SelectedOrganisation = ({
  classes,
  selectedOrg,
  history,
  disableOrgChange,
  linkTo
}) => {
  const { isMobile } = useWindowDimensions()

  return (
    <div
      style={{
        display: isMobile ? undefined : 'flex',
        alignItems: 'center',
        marginBottom: isMobile ? 20 : undefined
      }}
    >
      <Typography
        variant='title'
        bold
        normalLineHeight
        style={{
          marginRight: disableOrgChange ? 0 : 20,
          marginBottom: isMobile ? 20 : undefined
        }}
      >
        {selectedOrg && selectedOrg.name}
      </Typography>
      {!disableOrgChange && (
        <Button
          variant='outlined'
          onClick={() => {
            clearSelectedOrg()
            history.push(linkTo)
          }}
          style={{ marginLeft: 'auto' }}
        >
          {global._('Keys.Common.ChangeCustomer')}
        </Button>
      )}
      {isMobile && (
        <Divider style={{ margin: 'var(--section-padding-default) -20px' }} />
      )}
    </div>
  )
}

SelectedOrganisation.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedOrg: PropTypes.object,
  history: PropTypes.object,
  disableOrgChange: PropTypes.bool,
  linkTo: PropTypes.string
}

SelectedOrganisation.defaultProps = {
  selectedOrg: null,
  history: null,
  disableOrgChange: false,
  linkTo: ''
}

const SelectedOrganisationWithStyles = injectSheet(styles)(SelectedOrganisation)
export default SelectedOrganisationWithStyles
