import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'

const styles = {
  root: {
    position: 'absolute',
    top: 'var(--navigation-bar-height)',
    bottom: props => (props.fullHeight ? 0 : 'var(--tab-bar-height)'),
    left: 0,
    right: 0,
    '-webkit-overflow-scrolling': 'touch',
    overflow: 'auto',
    height: props => (props.fullHeight ? '100%' : ''),
    paddingBottom: props =>
      props.fullHeight && !props.noBottomPadding
        ? 124
        : [props.noBottomPadding ? 70 : '']
  },
  noNavigationBar: {
    top: 0
  },
  noTabBar: {
    bottom: 0
  }
}

const ScrollView = props => {
  const {
    classes,
    noTabBar,
    noNavigationBar,
    fullHeight,
    noBottomPadding,
    ...otherProps
  } = props

  const rootClasses = classnames(classes.root, {
    [classes.noNavigationBar]: noNavigationBar,
    [classes.noTabBar]: noTabBar
  })

  return <div className={rootClasses} {...otherProps} />
}

ScrollView.propTypes = {
  classes: PropTypes.object.isRequired,
  noTabBar: PropTypes.bool,
  noNavigationBar: PropTypes.bool,
  fullHeight: PropTypes.bool,
  noBottomPadding: PropTypes.bool
}

ScrollView.defaultProps = {
  noTabBar: false,
  noNavigationBar: false,
  fullHeight: false,
  noBottomPadding: false
}

const ScrollViewWithStyle = injectSheet(styles)(ScrollView)
export default ScrollViewWithStyle
