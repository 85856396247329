import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import {
  ProductKeys,
  ProductVariants,
  OrganisationTypes
} from 'src/utils/constants'
import Row from 'src/components/Row'
import Notification from 'src/common/Notification'
import Column from 'src/components/Column'
import { Paper } from '@material-ui/core'
import Button from 'src/components/Button'
import Breadcrumbs from 'src/components/Breadcrumbs'
import { hasActivePaymentMethod } from 'src/utils/helpers'
import Typography from '../../components/Typography'
import useWindowDimensions from '../../utils/useWindowDimensions'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import MobileContainer from '../../components/MobileContainer'

const styles = {
  root: {},
  hoverableContainer: {
    '&:hover $cardIcon': {
      backgroundColor: 'var(--color-primary-tone-3)'
    },
    '&:hover $cardIcon .material-icons': {
      color: 'var(--color-dark-grey)'
    },
    '&:hover': {
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.3), 0 3px 4px 0 rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.2)'
    },
    cursor: 'pointer'
  },
  card: {
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
    backgroundColor: 'white',
    border: '1px solid var(--color-divider-light)',
    width: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: 3,
    position: 'relative'
  },
  cardIcon: {
    width: 60,
    height: 60,
    position: 'relative',
    backgroundColor: '#eee',
    borderRadius: 500,
    '& .material-icons': {
      color: '#999',
      fontSize: 30,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  cardDivider: {
    height: 2,
    margin: 0,
    width: '100%',
    backgroundColor: 'var(--color-primary)'
  },

  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */

  '@media (max-width: 900px)': {
    root: {
      padding: '20px 18px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    cardIcon: {
      width: 40,
      height: 40,
      position: 'relative',
      backgroundColor: '#eee',
      borderRadius: 500,
      '& .material-icons': {
        color: '#999',
        fontSize: 20,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }
    }
  }
}

const Order = props => {
  const { classes, user, history, selectedOrganisation } = props

  const urlParams = new URLSearchParams(window.location.search)
  const productKey = urlParams.get('product')
  const variant = urlParams.get('variant')
  let keyId = urlParams.get('keyId')

  if (productKey && variant) {
    return history.push(
      `/orders/new/details?product=${productKey}&variant=${variant}&keyId=${keyId}`
    )
  }

  if (!selectedOrganisation) {
    return null
  }

  if (keyId && (keyId === 'null' || keyId === 'undefined')) {
    keyId = undefined
  }

  const { isMobile } = useWindowDimensions()
  const [error, setError] = useState(null)
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState({ variant })

  const dutyPhone = selectedOrganisation.partner
    ? selectedOrganisation.partner.dutyPhone
    : selectedOrganisation.dutyPhone

  const onSelectProductKey = key => {
    if (key === ProductKeys.KEY_COPY_ON_CALL) {
      return window.open(`tel:${dutyPhone}`)
    }

    return history.push(
      `/orders/new/details?product=${key}&variant=${product.variant}&keyId=${keyId}`
    )
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await axios.get(
          `/v1/organisations/${user.organisation.id}/products?checkout=true`
        )
        setProducts(data)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    })()
  }, [])

  const Wrapper = isMobile ? React.Fragment : Row
  const WrapperProps = isMobile
    ? {}
    : {
        style: {
          width: '100%',
          marginTop: 10
        }
      }
  const content = (
    <>
      {error && <Notification type='error' message={error} />}
      <div className={classes.root}>
        {/* Select product variant (e.g MECHANICAL / DIGITAL / RFID) */}
        {!product.variant && (
          <Column align='flex-start' style={{ width: '100%' }}>
            <Typography variant='title' bold={!isMobile}>
              {global._(
                `Cases.Orders.SelectKeyVariant${
                  isMobile ? 'Mobile' : 'Desktop'
                }`
              )}
            </Typography>
            <Wrapper {...WrapperProps}>
              {Object.values(ProductVariants.Keys).map(variant => {
                const active =
                  products.find(p => p.variant === variant && p.active) || false

                return (
                  <Paper
                    className={active ? classes.hoverableContainer : ''}
                    key={variant}
                    onClick={
                      active
                        ? () => setProduct({ ...product, variant })
                        : undefined
                    }
                    style={{
                      width: isMobile ? '100%' : '33%',
                      marginTop: 20,
                      marginRight: isMobile ? 0 : 12,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                    elevation={3}
                  >
                    <Row
                      align='center'
                      style={{
                        height: 80,
                        width: '100%',
                        padding: 20,
                        borderBottom: '1px solid var(--color-divider-light)',
                        opacity: active ? 1.0 : 0.5
                      }}
                    >
                      <img
                        style={{
                          maxHeight: '100%',
                          maxWidth: '100%',
                          margin: '0 auto'
                        }}
                        src={`/images/products/variants/${variant}.svg`}
                        alt={variant}
                      />
                    </Row>
                    <Typography
                      variant='subtitle'
                      style={{
                        padding: '8px 0',
                        color: active ? 'black' : 'var(--color-middle-grey)'
                      }}
                    >
                      {global._(`Products.Variants.Keys.${variant}`)}
                    </Typography>
                  </Paper>
                )
              })}
            </Wrapper>
          </Column>
        )}
        {/* Select Product Type (e.g Copy, Replacement etc.) */}
        {product.variant && (
          <Column align='flex-start'>
            {!isMobile && (
              <Row style={{ width: '100%', marginBottom: 16 }}>
                <Typography variant='title' bold>
                  {global._('Cases.Orders.SelectProductOption')}
                </Typography>
                <Button
                  variant='outlined'
                  onClick={() => history.goBack()}
                  style={{ marginLeft: 'auto', marginRight: 16 }}
                >
                  {global._('Common.Back')}
                </Button>
                <Button variant='outlined' onClick={() => history.push('/')}>
                  {global._('Common.Cancel')}
                </Button>
              </Row>
            )}
            <Wrapper {...WrapperProps}>
              <Grid
                container
                direction={isMobile ? 'column' : 'row'}
                spacing={2}
              >
                {Object.values(ProductKeys).map(key => {
                  const partnerProduct = products.find(
                    p => p.variant === product.variant && p.key == key
                  ) || { price: 0, active: false }
                  if (
                    key === ProductKeys.KEY_COPY_ON_CALL &&
                    selectedOrganisation.type !== OrganisationTypes.Partner &&
                    !selectedOrganisation.hasDutyAgreement
                  ) {
                    return null
                  }

                  let { active } = partnerProduct
                  const { price } = partnerProduct

                  if (!hasActivePaymentMethod(selectedOrganisation)) {
                    active = false
                  }

                  return (
                    <Grid item xs={12} md={6} lg={6} key={key}>
                      <div
                        className={`${classes.card} ${
                          active ? classes.hoverableContainer : ''
                        }`}
                        style={{
                          minHeight: isMobile ? undefined : 230,
                          cursor:
                            key === ProductKeys.KEY_COPY_ON_CALL
                              ? 'auto'
                              : 'pointer'
                        }}
                        onClick={() =>
                          key !== ProductKeys.KEY_COPY_ON_CALL &&
                          active &&
                          onSelectProductKey(key)
                        }
                      >
                        <Row style={{ margin: isMobile ? 10 : 20 }}>
                          <div className={classes.cardIcon}>
                            <Icon>shopping_cart</Icon>
                          </div>
                          <Typography
                            variant={isMobile ? 'subtitle' : 'title'}
                            bold={!isMobile}
                            style={{
                              marginLeft: 14,
                              color: active
                                ? undefined
                                : 'var(--color-middle-grey)'
                            }}
                          >
                            {global._(
                              `Products.Titles.${product.variant}.${key}`
                            )}
                          </Typography>
                          {partnerProduct.key ===
                            ProductKeys.KEY_COPY_ON_CALL && (
                            <div
                              style={{
                                marginLeft: 'auto',
                                padding: '3px 14.5px 3px',
                                borderRadius: 4,
                                color: 'white',
                                fontSize: 14,
                                fontWeight: 500,
                                backgroundColor: active
                                  ? 'var(--color-error-red)'
                                  : 'var(--color-middle-grey)'
                              }}
                            >
                              {global._('Cases.OnCall')}
                            </div>
                          )}
                        </Row>
                        <div
                          style={{
                            height: 2,
                            width: '100%',
                            backgroundColor: active
                              ? 'var(--color-primary)'
                              : 'var(--color-middle-grey)'
                          }}
                        />
                        <Column
                          justify='flex-start'
                          align='flex-start'
                          style={{
                            margin: isMobile ? 10 : 20,
                            paddingBottom: isMobile ? undefined : 55
                          }}
                        >
                          <Typography
                            variant={isMobile ? 'body2' : 'body'}
                            faded={isMobile}
                          >
                            {global._(
                              `Products.Descriptions.${product.variant}.${
                                !active || !partnerProduct.key
                                  ? 'Disabled'
                                  : partnerProduct.key
                              }`
                            )}
                          </Typography>
                          {active && (
                            <>
                              <div
                                style={{
                                  margin: '20px 0',
                                  position: isMobile ? undefined : 'absolute',
                                  bottom: 5
                                }}
                              >
                                <Typography
                                  style={{ fontSize: 20, marginRight: 6 }}
                                  bold
                                >{`${price || '-'} ${global._(
                                  'Common.Currency'
                                )}`}</Typography>
                                <Typography faded>
                                  {global._('Products.IncludingTaxes')}
                                </Typography>
                              </div>
                              {!isMobile &&
                                selectedOrganisation &&
                                dutyPhone &&
                                key === ProductKeys.KEY_COPY_ON_CALL && (
                                  <Typography
                                    variant='subtitle'
                                    style={{ marginTop: 20, lineHeight: 1 }}
                                  >
                                    <span
                                      style={{
                                        color: 'var(--color-error-red)'
                                      }}
                                    >{`${global._(
                                      'Products.OnCallPhoneNumber'
                                    )}:`}</span>{' '}
                                    {dutyPhone}
                                  </Typography>
                                )}
                              {isMobile && selectedOrganisation && dutyPhone && (
                                <Button
                                  disabled={!partnerProduct.active}
                                  variant='primary'
                                  style={{ marginBottom: 10 }}
                                  onClick={() =>
                                    active && onSelectProductKey(key)
                                  }
                                >
                                  {partnerProduct.key ==
                                  ProductKeys.KEY_COPY_ON_CALL
                                    ? `${global._('Common.Call')} ${dutyPhone}`
                                    : global._('Common.Proceed')}
                                </Button>
                              )}
                            </>
                          )}
                        </Column>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
            </Wrapper>
          </Column>
        )}
      </div>
    </>
  )
  if (isMobile) {
    return (
      <MobileContainer>
        <NavigationBar
          title={global._('Cases.Orders.Keys')}
          backAction={() => {
            if (product.variant && !keyId) {
              setProduct({})
            } else {
              history.goBack()
            }
          }}
        />
        <ScrollView>{content}</ScrollView>
      </MobileContainer>
    )
  }

  const crumbs = [
    {
      name: global._('Breadcrumbs.Dashboard'),
      path: '/'
    },
    {
      name: global._('Breadcrumbs.OrderKey'),
      path: '/orders',
      onClick: () => setProduct({})
    }
  ]

  if (!keyId) {
    crumbs.push({
      name: global._('Breadcrumbs.KeyVariant'),
      path: '/orders/new',
      onClick: () => setProduct({})
    })
  }

  if (product.variant) {
    crumbs.push({
      name: global._(`Products.Variants.Keys.${product.variant}`),
      path: `/orders/new/${product.variant}`
    })
  }
  return (
    <div>
      <Breadcrumbs breadcrumbItems={crumbs} />
      <div style={{ height: 30 }} />
      <div className={classes.content}>
        {content}
        {/* <Grid container spacing={3}> */}
      </div>
    </div>
  )
}

Order.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object
}

Order.defaultProps = {
  history: null
}

const OrderWithStyles = injectSheet(styles)(Order)
export default OrderWithStyles
