import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { Grid } from '@material-ui/core'
import IconColumn from 'src/components/IconColumn'
import Notification from '../../../common/Notification'
import List from '../../../components/List'
import { getSelectedOrg, normalize } from '../../../utils/helpers'
import withUser from '../../../utils/withUser'
import InfiniteScroll from '../../../components/InfiniteScroll'

const styles = {
  root: {}
}

export const STORAGE_KEY_LOAN_SELECTED_KEYS = 'keys.loan.keys'
const LIMIT = 25
const FilterTypes = {
  Selected: 1,
  Unselected: 2
}

const KeySelect = ({ classes, user, data, onDataChange }) => {
  const selectedOrg = getSelectedOrg(user)
  const [isLoading, setIsLoading] = useState(false)

  /* Unselected Keys (left list) */
  const [unselected, setUnselected] = useState([])
  const [filteredUnselected, setFilteredUnselected] = useState([])
  const [unselectedFilter, setUnselectedFilter] = useState({})

  /* Select Keys (right list) */
  const [selected, setSelected] = useState([])
  const [filteredSelected, setFilteredSelected] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({})

  const [offset, setOffset] = useState(0)
  const [error, setError] = useState(null)
  const [leftError, setLeftError] = useState(null)

  const [, rerender] = useState(null)

  useEffect(() => {
    if (data.user && data.user.id && data.validation) {
      const { keyLimit, keyCount } = data.validation
      if (keyCount + selected.length >= keyLimit) {
        setLeftError(global._('Keys.Settings.MaxNumberOfKeysReached.user'))
      } else {
        setLeftError(null)
      }
    }
  }, [data])

  const onFilterChange = (
    filter,
    originalList,
    setFilter,
    setList,
    filterType
  ) => {
    let list
    if (filter.search) {
      const search = normalize(filter.search.toLowerCase())
      list = originalList.filter(item => item.search.includes(search))
    } else {
      list = [...originalList]
    }

    const { orderBy } = filter
    if (orderBy) {
      list.sort((a, b) => {
        if (filter.order === 'asc') {
          return a[orderBy].localeCompare(b[orderBy])
        }
        return b[orderBy].localeCompare(a[orderBy])
      })
    }

    /* only change offset if filter actually changed */
    const compareFilter =
      filterType === FilterTypes.Selected ? selectedFilter : unselectedFilter
    if (
      filter.orderBy !== compareFilter.orderBy ||
      filter.order !== compareFilter.order ||
      filter.search !== compareFilter.search
    ) {
      setOffset(0)
    }
    setList(list)
    setFilter(filter)
  }

  const onUnselectedFilterChange = filter =>
    onFilterChange(
      filter,
      unselected,
      setUnselectedFilter,
      setFilteredUnselected,
      FilterTypes.Unselected
    )
  const onSelectedFilterChange = filter =>
    onFilterChange(
      filter,
      selected,
      setSelectedFilter,
      setFilteredSelected,
      FilterTypes.Selected
    )

  /* update filtered lists when adding or removing Keys */
  useEffect(() => {
    onUnselectedFilterChange(unselectedFilter)
    onSelectedFilterChange(selectedFilter)
  }, [selected, unselected])

  const update = _data => {
    onDataChange({ ...data, ..._data })
  }

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const { data: _keys } = await axios.get(
          `/v1/organisations/${selectedOrg.id}/keys`
        )
        /* preprocess for searches */
        _keys.forEach(key => {
          key.search = ''
          if (key.name) {
            key.search += key.name.toLowerCase()
          }
          if (key.marking) {
            key.search += key.marking.toLowerCase()
          }
          if (key.keyringName) {
            key.search += key.keyringName.toLowerCase()
          } else {
            key.keyringName = ''
          }
          if (key.holderName) {
            key.search += key.holderName.toLowerCase()
          }
          key.search = normalize(key.search)
        })

        let unselectedItems = _keys

        let selectedItems = data.keys

        /* only keep selected items which still exists (e.g remove archived keys) */
        selectedItems = selectedItems.filter(selectedItem =>
          unselectedItems.find(
            unselectedItem => unselectedItem.id === selectedItem.id
          )
        )
        /* remove selected items from unselected list */
        unselectedItems = unselectedItems.filter(
          unselectedItem =>
            !selectedItems.find(
              selectedItem => selectedItem.id === unselectedItem.id
            )
        )
        setUnselected(unselectedItems)
        setSelected(selectedItems)
        onDataChange({ ...data, keys: selectedItems }, true)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
      setIsLoading(false)
    })()
  }, [])

  useEffect(() => {
    setSelected(data.keys)
  }, [data])

  useEffect(() => {
    let timeout
    const listener = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        rerender({})
      }, 200)
    }
    window.addEventListener('resize', listener)
    return window.removeEventListener('resize', listener)
  }, [])

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      style: { width: 50 },
      format: () => <IconColumn icon='vpn_key' />
    },
    {
      key: 'name',
      localizationKey: 'Keys.Loan.KeySelect.Columns.Name',
      sortingKey: 'name',
      style: { width: 150 },
      clickable: true
    },
    {
      key: 'marking',
      localizationKey: 'Keys.Loan.KeySelect.Columns.Marking',
      sortingKey: 'marking',
      style: { width: 150 },
      clickable: true
    },
    {
      key: 'holderName',
      localizationKey: 'Keys.Loan.KeySelect.Columns.Holder',
      sortingKey: 'holderName',
      style: { width: 150 },
      clickable: true
    },
    {
      key: 'keyringName',
      localizationKey: 'Keys.Loan.KeySelect.Columns.KeyRing',
      sortingKey: 'keyringName',
      style: { width: 150 },
      clickable: true,
      hiddenFor: window.innerWidth < 1920
    }
  ]

  const offsetLimit = offset + LIMIT
  return (
    <div className={classes.root}>
      {error && <Notification type='error' message={error} />}
      <InfiniteScroll
        hasMore={
          filteredSelected.length > offsetLimit ||
          filteredUnselected.length > offsetLimit
        }
        fetchMore={() => setOffset(offsetLimit)}
      >
        <Grid container spacing={3} style={{ marginTop: 12 }}>
          <Grid item xs={6}>
            <List
              items={filteredUnselected.slice(0, offsetLimit)}
              columns={columns}
              isLoading={isLoading}
              filter={unselectedFilter}
              setFilter={onUnselectedFilterChange}
              title={global._('Keys.Loan.KeySelect.LeftListTitle')}
              searchPlaceholder={global._(
                'Keys.Loan.KeySelect.SearchPlaceholder'
              )}
              error={leftError}
              endAdornmentIcon='add_circle'
              endAdornmentDisabled={!!leftError}
              endAdornmentOnClick={item => {
                const newUnselected = unselected.filter(item2 => item2 !== item)
                const newSelected = [item, ...selected]
                setUnselected(newUnselected)
                setSelected(newSelected)
                update({ keys: newSelected })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <List
              items={filteredSelected.slice(0, offsetLimit)}
              columns={columns}
              filter={selectedFilter}
              setFilter={onSelectedFilterChange}
              title={global._('Keys.Loan.KeySelect.RightListTitle')}
              buttonTitle={global._('Keys.Loan.KeySelect.RightListButtonTitle')}
              buttonOnClick={() => {
                const newUnselected = [...filteredSelected, ...unselected]
                const newSelected = selected.filter(
                  item => !filteredSelected.includes(item)
                )
                setSelected(newSelected)
                setUnselected(newUnselected)
                onSelectedFilterChange({ ...selectedFilter, search: '' })
                update({ keys: newSelected })
              }}
              searchPlaceholder={global._(
                'Keys.Loan.KeySelect.SearchPlaceholder'
              )}
              endAdornmentIcon='remove_circle'
              endAdornmentOnClick={item => {
                const newUnselected = [item, ...unselected]
                const newSelected = selected.filter(item2 => item2 !== item)
                setUnselected(newUnselected)
                setSelected(newSelected)
                update({ keys: newSelected })
              }}
            />
          </Grid>
        </Grid>
      </InfiniteScroll>
    </div>
  )
}

KeySelect.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onDataChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

KeySelect.defaultProps = {}

const KeySelectWithStylesAndUser = withUser(injectSheet(styles)(KeySelect))
export default KeySelectWithStylesAndUser
