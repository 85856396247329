import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { UserRoles } from 'src/utils/constants'
import PageContentHeader from 'src/components/PageContentHeader'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import IdControlForm from 'src/components/IdControlForm'
import IdControlMobile from './IdControlMobile'

const styles = {
  root: {}
}

const IdControl = props => {
  const { classes, history, user, breadcrumbItems } = props
  const [error, setError] = useState(null)
  const [personalNumber, setPersonalNumber] = useState(null)
  const [mobileNumber, setMobileNumber] = useState(null)
  const [isCollecting, setIsCollecting] = useState(false)

  const orderRef = useRef(null)
  const intervalRef = useRef(null)

  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)

  const digitalSignRequestData = {
    personalNumber,
    mobileNumber
  }

  const cancelDigitalSign = async () => {
    clearInterval(intervalRef.current)
    intervalRef.current = null
    setError(null)
    setIsCollecting(false)
    try {
      await axios.post(
        `/v1/organisations/${user.organisation.id}/idcontrol/identify/cancel/${orderRef.current}`
      )
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  const startCollectInterval = () => {
    setIsCollecting(true)
    intervalRef.current = setInterval(async () => {
      const { data } = await axios.post(
        `/v1/organisations/${user.organisation.id}/idcontrol/identify/collect/${orderRef.current}`,
        digitalSignRequestData
      )
      if (data.status === 'COMPLETE') {
        setIsCollecting(false)

        const { referenceId } = data

        if (referenceId) {
          history.push(`/id-control/${referenceId}`)
        }

        clearInterval(intervalRef.current)
        intervalRef.current = null
        orderRef.current = null
      } else if (data.status === 'FAILED') {
        setIsCollecting(false)
        setError(global._('Error.BankID.SigningFailed'))
        clearInterval(intervalRef.current)
        intervalRef.current = null
        orderRef.current = null
      }
    }, 2e3)
  }

  const initiateDigitalSign = async () => {
    setError(null)
    try {
      const { data } = await axios.post(
        `/v1/organisations/${user.organisation.id}/idcontrol/identify/initiate`,
        digitalSignRequestData
      )
      orderRef.current = data.orderRef
      startCollectInterval()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  if (!isPartnerAdmin && !isSupport) {
    history.push('/dashboard')
  }

  const { isMobile } = useWindowDimensions()

  if (isMobile) {
    return (
      <IdControlMobile
        history={history}
        isLoading={isCollecting}
        cancelAuth={cancelDigitalSign}
        personalNumber={personalNumber}
        setPersonalNumber={setPersonalNumber}
        sendRequest={initiateDigitalSign}
        error={error}
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
      />
    )
  }

  return (
    <>
      <PageContentHeader breadcrumbItems={breadcrumbItems} />
      <IdControlForm
        cancel={cancelDigitalSign}
        personalNumber={personalNumber}
        setPersonalNumber={setPersonalNumber}
        sendRequest={initiateDigitalSign}
        error={error}
        isLoading={isCollecting}
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
      />
    </>
  )
}

IdControl.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

IdControl.defaultProps = {}

const IdControlWithStyles = injectSheet(styles)(IdControl)
export default IdControlWithStyles
