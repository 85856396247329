import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import axios from 'axios'
import PropTypes from 'prop-types'
import { TextField, Switch } from '@material-ui/core'
import Row from 'src/components/Row'
import Column from 'src/components/Column'
import Button from 'src/components/Button'
import Typography from 'src/components/Typography'
import Notification from 'src/common/Notification'
import {
  ProductVariants,
  ProductKeys,
  UserRoles,
  ContactPageURL
} from 'src/utils/constants'
import SuccessMessage from 'src/components/SuccessMessage'
import withUser from 'src/utils/withUser'
import { withRouter } from 'react-router'
import Indicator from './Indicator'

const Products = props => {
  const { user, history, organisationId } = props

  const isPartner =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isCustomerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.CustomerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)
  const readOnly = !isPartner
  if (!isPartner && !isCustomerAdmin && !isSupport) {
    return history.push('/')
  }

  // const [error, setError] = useState()
  const [saved, setSaved] = useState(false)

  const [productsError, setProductsError] = useState(null)
  const [products, setProducts] = useState([])
  const [pendingProducts, setPendingProducts] = useState({})
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)
  const [isSavingProducts, setIsSavingProducts] = useState(false)

  useEffect(() => {
    ;(async () => {
      /* load products */
      setIsLoadingProducts(true)
      try {
        const { data: _products } = await axios.get(
          `/v1/organisations/${organisationId}/products`
        )

        // Add non-existing products
        const DEFAULT_PRICE = '539'
        Object.values(ProductVariants.Keys).forEach(variant => {
          Object.values(ProductKeys).forEach(key => {
            if (
              !_products.find(
                product => product.key === key && product.variant === variant
              )
            ) {
              _products.push({
                key,
                variant,
                active: false,
                price: DEFAULT_PRICE
              })
            }
          })
        })
        setProducts(_products)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setProductsError(global._(msg))
      }
      setIsLoadingProducts(false)
    })()
  }, [])

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false)
      }, 2000)
    }
  }, [saved])

  const updateProduct = async (product, values = {}) => {
    setProductsError(null)
    try {
      const { data: _product } = await axios.put(
        `/v1/organisations/${organisationId}/products/${product.key}`,
        { ...product, ...values }
      )
      setProducts(prevProducts => {
        const _products = [...prevProducts]
        const idx = _products.findIndex(
          p => p.key === product.key && p.variant === product.variant
        )
        _products.splice(idx, 1, _product)
        return _products
      })
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setProductsError(msg)
    }
  }

  const handlePendingProductUpdate = (product, values) => {
    setPendingProducts({
      ...pendingProducts,
      [`${product.key}-${product.variant}`]: {
        ...product,
        ...values
      }
    })
  }

  const handleCommitPendingProductUpdates = async () => {
    setIsSavingProducts(true)
    await Promise.all(
      Object.values(pendingProducts).map(product => updateProduct(product))
    )

    setIsSavingProducts(false)
    setSaved(true)
    setPendingProducts({})
  }

  return (
    <Column align='flex-start' style={{ width: '100%' }}>
      {saved && <SuccessMessage subtitle={global._('Common.ChangesSaved')} />}
      {productsError && <Notification type='error' message={productsError} />}
      {Object.values(ProductVariants.Keys).map(variant => {
        const title = global._(`Products.Variants.Keys.${variant}`)
        return (
          <div
            key={variant}
            style={{
              width: '100%',
              padding: '20px 20px 10px 20px',
              borderBottom: '1px solid var(--color-divider-light)'
            }}
          >
            <Typography bold variant='title'>
              {title}
            </Typography>
            {Object.values(ProductKeys).map((productKey, idx) => {
              const isLast = idx === Object.values(ProductKeys).length - 1
              let product = products.find(
                p => p.key === productKey && p.variant === variant
              )
              const pendingProduct = pendingProducts[`${productKey}-${variant}`]
              if (pendingProduct) {
                product = pendingProduct
              }
              if (!product) return null
              if (readOnly) {
                return (
                  <Row
                    key={productKey}
                    style={{
                      margin: '40px 0',
                      marginBottom: isLast ? 20 : undefined
                    }}
                  >
                    <Indicator variant={product.active ? 'green' : 'grey'} />
                    <Typography bold variant='subtitle' style={{ width: 250 }}>
                      {global._(`Products.Titles.Short.${productKey}`)}
                    </Typography>
                    <Typography
                      variant='body'
                      style={{ color: 'var(--color-middle-grey' }}
                      dangerouslySetInnerHTML={{
                        __html: global._(
                          `Products.${product.active ? 'Active' : 'Inactive'}`,
                          [ContactPageURL]
                        )
                      }}
                    />
                    {product.active && (
                      <Typography
                        bold
                        variant='subtitle'
                        style={{
                          marginLeft: 'auto',
                          marginRight: 12
                        }}
                      >{`${product.price} kr`}</Typography>
                    )}
                  </Row>
                )
              }
              return (
                <Row key={productKey} style={{ margin: '20px 0' }}>
                  <Typography bold variant='subtitle' style={{ width: 250 }}>
                    {global._(`Products.Titles.Short.${productKey}`)}
                  </Typography>
                  <Switch
                    checked={product.active}
                    onChange={() =>
                      handlePendingProductUpdate(product, {
                        active: !product.active
                      })
                    }
                  />
                  <TextField
                    variant='filled'
                    style={{
                      width: 200,
                      marginLeft: 'auto'
                    }}
                    label={global._('Products.PriceIncludingTaxes')}
                    value={product.price || ''}
                    onChange={e => {
                      const price = e.target.value
                        .replace(/\D/g, '')
                        .substr(0, 5)
                      handlePendingProductUpdate(product, { price })
                    }}
                  />
                </Row>
              )
            })}
          </div>
        )
      })}
      <Row justify='flex-end' style={{ width: '100%', padding: '20px' }}>
        {!readOnly && (
          <Button
            variant='primary'
            style={{ paddingLeft: 48, paddingRight: 48 }}
            loading={isSavingProducts}
            disabled={!Object.keys(pendingProducts).length || isSavingProducts}
            onClick={handleCommitPendingProductUpdates}
          >
            {global._('Common.Save')}
          </Button>
        )}
      </Row>
    </Column>
  )
}

Products.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organisationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
}

export default withUser(withRouter(Products))
