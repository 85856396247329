import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import { checkIfOrgHasAccessToScope } from 'src/utils/helpers'
import EntityWidget from './widgets/Entity'
import {
  InstructionsVideoIds,
  OrganisationServices,
  OrganisationTypes,
  UserRoles
} from '../../utils/constants'
import Breadcrumbs from '../../components/Breadcrumbs'
import useWindowDimensions from '../../utils/useWindowDimensions'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import UserComponentData from './widgets/data/User'
import ApartmentComponentData from './widgets/data/Apartment'
import ServiceDeskComponentData from './widgets/data/ServiceDesk'
import DevicesComponentData from './widgets/data/Devices'
import KeeHistoryComponentData from './widgets/data/KeeHistory'
import OrganisationComponentData from './widgets/data/Organisation'
import AccessGroupComponentData from './widgets/data/AccessGroups'
import AccessLocksComponentData from './widgets/data/AccessLocks'
import AccessUsersComponentData from './widgets/data/AccessUsers'
import SettingsOrdersWidgetData from './widgets/data/SettingsOrders'
import MobileContainer from '../../components/MobileContainer'

const styles = {
  root: {}
}

const baseCrumbs = [
  { name: 'Startsida', path: '/' },
  { name: 'Admin', path: '/admin' }
]

const Admin = props => {
  const { history, user, classes, selectedOrganisation } = props
  /* Part 1 - [customers|partners|unbound|users] */
  /* Part 2 - [new|id] */
  /* Part 3 - [new|id] */
  const { isMobile } = useWindowDimensions()
  const { part1, part2, part3 } = props.match.params
  const [crumbs, setCrumbs] = useState(baseCrumbs)
  const [widgets, setWidgets] = useState([])

  let userHasAccessToKEE

  if (selectedOrganisation) {
    userHasAccessToKEE = checkIfOrgHasAccessToScope(
      user,
      selectedOrganisation,
      OrganisationServices.KEE
    )
  }

  const lang = global.getLanguage()

  useEffect(() => {
    ;(async () => {
      const calculatedWidgets = []
      const calculatedCrumbs = [...baseCrumbs]

      const showUserWidget = () => {
        calculatedWidgets.push(
          <EntityWidget
            key='widget.users'
            data={UserComponentData}
            title={global._('Common.Administer')}
            subtitle={global._('Widgets.users.Subtitle')}
            entity='users'
            onNewItem={item =>
              history.push(
                `/admin/users/success/${item.organisation.id}/${item.id}`
              )
            }
            single={false}
            url='/admin/users'
          />
        )
      }

      const showAccessGroupsWidget = () => {
        if (userHasAccessToKEE) {
          calculatedWidgets.push(
            <EntityWidget
              key='widget.accessgroups'
              fetch={false}
              data={AccessGroupComponentData}
              title={global._('Common.Administer')}
              subtitle={global._('Widgets.accessgroups.Subtitle')}
              entity='accessgroups'
              single={false}
              url='/admin/accessgroups/nav'
            />
          )
        }
      }

      const showDeviceWidget = () => {
        calculatedWidgets.push(
          <EntityWidget
            key='widget.devices'
            data={DevicesComponentData}
            title={global._('Common.Administer')}
            subtitle={global._('Widgets.devices.Subtitle')}
            entity='devices'
            onNewItem={item => history.push(`/admin/devices/${item.id}`)}
            single={false}
            url='/admin/devices'
          />
        )
      }

      const showApartmentsWidget = (single, forceModalOpen) => {
        calculatedWidgets.push(
          <EntityWidget
            key='widget.apartments'
            data={ApartmentComponentData}
            title={global._('Common.Administer')}
            subtitle={global._('Widgets.apartments.Subtitle')}
            entity='apartments'
            newItem={forceModalOpen}
            onNewItem={(
              item,
              setNewItem,
              setSuccessMessage,
              setShowSuccessMessage,
              setItems
            ) => {
              setShowSuccessMessage(false)
              setNewItem(null)
              setItems(prevValues => [...prevValues, item])
              setSuccessMessage(
                global._('Widgets.apartments.ApartmentAddedSuccessMessage')
              )
              setShowSuccessMessage(true)
            }}
            single={single}
            url='/admin/apartments'
          />
        )
      }

      const showOrderSettingsWidget = () => {
        calculatedWidgets.push(
          <EntityWidget
            key='widget.ordersettings'
            fetch={false}
            data={SettingsOrdersWidgetData}
            title={global._('Common.Settings')}
            subtitle={global._('Cases.Orders.Keys')}
            single={false}
          />
        )
      }

      const showKeeHistoryWidget = () => {
        if (userHasAccessToKEE) {
          calculatedWidgets.push(
            <EntityWidget
              fetch={false}
              data={KeeHistoryComponentData}
              title={global._('Common.Show')}
              subtitle={global._('Widgets.keeHistory.Subtitle')}
              url='/admin/kee-history'
              entity='keeHistory'
              single={false}
            />
          )
        }
      }

      if (!part1) {
        if (user.roles.includes(UserRoles.SystemAdmin)) {
          if (!isMobile) {
            calculatedWidgets.push(
              <EntityWidget
                key='widget.organisations.partner'
                data={OrganisationComponentData}
                title={user.organisation.name}
                subtitle={global._(
                  `Widgets.organisations.Subtitle.${OrganisationTypes.Partner}`
                )}
                entity='organisations'
                type={OrganisationTypes.Partner}
                onNewItem={item =>
                  history.push(`/admin/partners/success/${item.id}`)
                }
                single={false}
                url='/admin/partners'
              />
            )
            showUserWidget()
            calculatedWidgets.push(
              <EntityWidget
                key='widget.organisations.unbound'
                data={OrganisationComponentData}
                title={global._('Common.Administer')}
                subtitle={global._(
                  `Widgets.organisations.Subtitle.${OrganisationTypes.Unbound}`
                )}
                entity='organisations'
                filter={{
                  type: [OrganisationTypes.Unbound, OrganisationTypes.Guest]
                }}
                type={OrganisationTypes.Unbound}
                single={false}
                url='/admin/unbound'
              />
            )

            const { data: organisations } = await axios.get(
              `/v1/organisations?type=${OrganisationTypes.ServiceDesk}`
            )
            const [organisation] = organisations
            calculatedWidgets.push(
              <EntityWidget
                key='widget.users.support'
                data={ServiceDeskComponentData}
                title={global._('Common.Administer')}
                subtitle={global._('Widgets.ServiceDesk.Subtitle')}
                showAllLabel={global._('Widgets.ServiceDesk.ShowAllLabel')}
                entity='users'
                filter={{
                  role: UserRoles.Support,
                  organisationId: organisation.id,
                  organisationName: organisation.name
                }}
                onNewItem={item =>
                  history.push({
                    pathname: `/admin/users/success/${item.organisation.id}/${item.id}`,
                    state: {
                      type: 'servicedesk'
                    }
                  })
                }
                single={false}
                url='/admin/servicedesk'
              />
            )
          }
          showDeviceWidget()
        } else if (user.roles.includes(UserRoles.PartnerAdmin)) {
          if (!isMobile) {
            calculatedWidgets.push(
              <EntityWidget
                title={user.organisation.name}
                data={OrganisationComponentData}
                subtitle={global._(
                  `Widgets.organisations.Subtitle.${OrganisationTypes.Customer}`
                )}
                key='widget.organisations.customer'
                entity='organisations'
                type={OrganisationTypes.Customer}
                onNewItem={item =>
                  history.push(`/admin/customers/success/${item.id}`)
                }
                single={false}
                url='/admin/customers'
              />
            )
            showUserWidget()
            showApartmentsWidget()
          }
          showDeviceWidget()
          showAccessGroupsWidget()
          showKeeHistoryWidget()

          if (!isMobile) {
            showOrderSettingsWidget()
          }
        } else if (user.roles.includes(UserRoles.Support)) {
          if (!isMobile) {
            calculatedWidgets.push(
              <EntityWidget
                title={global._('Common.All')}
                data={OrganisationComponentData}
                subtitle={global._(
                  `Widgets.organisations.Subtitle.${OrganisationTypes.Customer}`
                )}
                key='widget.organisations.customer'
                entity='organisations'
                type={OrganisationTypes.Customer}
                // onNewItem={item => history.push(`/admin/customers/success/${item.id}`)}
                single={false}
                url='/admin/customers'
              />
            )
            calculatedWidgets.push(
              <EntityWidget
                key='widget.users'
                data={UserComponentData}
                title={global._('Common.All')}
                subtitle={global._('Widgets.users.Subtitle')}
                entity='users'
                // onNewItem={item => history.push(`/admin/users/success/${item.organisation.id}/${item.id}`)}
                single={false}
                url='/admin/users'
              />
            )
            showApartmentsWidget()
          }
        } else if (user.roles.includes(UserRoles.CustomerAdmin)) {
          if (!isMobile) {
            showUserWidget()
            showApartmentsWidget()
          }
          showAccessGroupsWidget()
          showKeeHistoryWidget()
        }
      } else if (part1 === 'partners') {
        calculatedCrumbs.push({ name: 'Partners', path: '/admin/partners' })
        if (!part2 || part2 === 'new') {
          const forceModalOpen = part2 === 'new'
          calculatedWidgets.push(
            <EntityWidget
              key='widget.organisations.partner'
              data={OrganisationComponentData}
              subtitle={global._(
                `Widgets.organisations.Subtitle.${OrganisationTypes.Partner}`
              )}
              entity='organisations'
              type={OrganisationTypes.Partner}
              single
              newItem={
                forceModalOpen ? { type: OrganisationTypes.Partner } : undefined
              }
              onNewItem={item =>
                history.push(`/admin/partners/success/${item.id}`)
              }
            />
          )
        } else {
          // part2 should now be populated with an id of partner
          const partnerId = part2
          const { data: partner } = await axios.get(
            `/v1/organisations/${partnerId}`
          )
          const forceModalOpen = part3 === 'new'
          calculatedCrumbs.push({
            name: 'Kunder',
            path: `/admin/partners/${partnerId}`
          })
          calculatedWidgets.push(
            <EntityWidget
              key='widget.organisations.partner.customers'
              entity='organisations'
              data={OrganisationComponentData}
              subtitle={`${global._('Admin.CustomersBelongingTo')} ${
                partner.name
              }`}
              type={OrganisationTypes.Customer}
              filter={{ partnerId }}
              single
              newItem={
                forceModalOpen
                  ? { type: OrganisationTypes.Customer, partnerId }
                  : undefined
              }
              onNewItem={item =>
                history.push(`/admin/partners/${partnerId}/success/${item.id}`)
              }
            />
          )
        }
      } else if (part1 === 'customers') {
        calculatedCrumbs.push({ name: 'Kunder', path: '/admin/customers' })
        if (!part2 || part2 === 'new') {
          const forceModalOpen = part2 === 'new'
          calculatedWidgets.push(
            <EntityWidget
              key='widget.organisations.customer'
              data={OrganisationComponentData}
              subtitle={global._(
                `Widgets.organisations.Subtitle.${OrganisationTypes.Customer}`
              )}
              instructionsVideoId={InstructionsVideoIds[lang].ADMIN.CUSTOMERS}
              entity='organisations'
              type={OrganisationTypes.Customer}
              newItem={
                forceModalOpen
                  ? { type: OrganisationTypes.Customer }
                  : undefined
              }
              onNewItem={item =>
                history.push(`/admin/customers/success/${item.id}`)
              }
              single
            />
          )
        } else {
          // part2 should now be populated with id of customer
          const customerId = part2
          const forceModalOpen = part3 === 'new'
          const { data: customer } = await axios.get(
            `/v1/organisations/${customerId}`
          )
          calculatedCrumbs.push({
            name: 'Användare',
            path: `/admin/customers/${customerId}`
          })
          calculatedWidgets.push(
            <EntityWidget
              key='widget.customers.users'
              entity='users'
              data={UserComponentData}
              subtitle={`${global._('Admin.UsersBelongingTo')} ${
                customer.name
              }`}
              filter={{
                organisationId: customer.id,
                organisationName: customer.name
              }}
              newItem={
                forceModalOpen ? { organisationId: customer.id } : undefined
              }
              onNewItem={item =>
                history.push(
                  `/admin/users/success/${item.organisation.id}/${item.id}`
                )
              }
              single
            />
          )
        }
      } else if (part1 === 'unbound') {
        calculatedCrumbs.push({
          name: 'Obundna organisationer',
          path: '/admin/unbound'
        })
        calculatedWidgets.push(
          <EntityWidget
            key='widget.organisations.unbound'
            data={OrganisationComponentData}
            subtitle={global._(
              `Widgets.organisations.Subtitle.${OrganisationTypes.Unbound}`
            )}
            entity='organisations'
            filter={{
              type: [OrganisationTypes.Unbound, OrganisationTypes.Guest]
            }}
            type={OrganisationTypes.Unbound}
            single
          />
        )
      } else if (part1 === 'users') {
        calculatedCrumbs.push({ name: 'Användare', path: '/admin/users' })
        if (!part2 || part2 === 'new') {
          const forceModalOpen = part2 === 'new'
          calculatedWidgets.push(
            <EntityWidget
              key='widget.users'
              entity='users'
              subtitle={global._('Widgets.users.Subtitle')}
              data={UserComponentData}
              newItem={forceModalOpen ? {} : undefined}
              onNewItem={item =>
                history.push(
                  `/admin/users/success/${item.organisation.id}/${item.id}`
                )
              }
              single
              instructionsVideoId={InstructionsVideoIds[lang].ADMIN.USERS}
            />
          )
        } else {
          // part2 should now be populated with organisation id
          const organisationId = part2
          const forceModalOpen = part3 === 'new'
          const { data: organisation } = await axios.get(
            `/v1/organisations/${organisationId}`
          )
          calculatedWidgets.push(
            <EntityWidget
              key='widget.users'
              entity='users'
              data={UserComponentData}
              subtitle={global._('Widgets.users.Subtitle')}
              filter={{
                organisationId: organisation.id,
                organisationName: organisation.name
              }}
              newItem={
                forceModalOpen ? { organisationId: organisation.id } : undefined
              }
              onNewItem={item =>
                history.push(
                  `/admin/users/success/${item.organisation.id}/${item.id}`
                )
              }
              single
            />
          )
        }
      } else if (part1 === 'servicedesk') {
        const { data: organisations } = await axios.get(
          `/v1/organisations?type=${OrganisationTypes.ServiceDesk}`
        )
        const [organisation] = organisations
        calculatedCrumbs.push({
          name: 'Servicedesk',
          path: '/admin/servicedesk'
        })
        const filter = {
          organisationId: organisation.id,
          organisationName: organisation.name,
          type: OrganisationTypes.ServiceDesk
        }
        if (!part2 || part2 === 'new') {
          const forceModalOpen = part2 === 'new'
          calculatedWidgets.push(
            <EntityWidget
              key='widget.users.support'
              entity='users'
              subtitle={global._('Widgets.ServiceDesk.Subtitle')}
              filter={filter}
              data={ServiceDeskComponentData}
              newItem={forceModalOpen ? {} : undefined}
              onNewItem={item =>
                history.push({
                  pathname: `/admin/users/success/${item.organisation.id}/${item.id}`,
                  state: {
                    type: 'servicedesk'
                  }
                })
              }
              single
            />
          )
        } else {
          // part2 should now be populated with organisation id
          const forceModalOpen = part3 === 'new'
          calculatedWidgets.push(
            <EntityWidget
              key='widget.users.support'
              entity='users'
              data={ServiceDeskComponentData}
              subtitle={global._('Widgets.ServiceDesk.Subtitle')}
              filter={filter}
              newItem={
                forceModalOpen ? { organisationId: organisation.id } : undefined
              }
              onNewItem={item =>
                history.push({
                  pathname: `/admin/users/success/${item.organisation.id}/${item.id}`,
                  state: {
                    type: 'servicedesk'
                  }
                })
              }
              single
            />
          )
        }
      } else if (part1 === 'accessgroups') {
        const organisationId = part2
        const { data: organisationObj } = organisationId
          ? await axios.get(`/v1/organisations/${organisationId}`)
          : { data: null }
        const newFilter = organisationObj
          ? {
              organisationId: organisationObj.id,
              organisationName: organisationObj.name
            }
          : {
              organisationId: user.organisation.id,
              organisationName: user.organisation.name
            }
        if (part2 && !part3) {
          calculatedCrumbs.push({
            name: 'Behörighetsgrupper',
            path: `/admin/accessgroups/${
              organisationObj.id || user.organisation.id
            }`
          })
          calculatedWidgets.push(
            <EntityWidget
              key='widget.accessgroups'
              data={AccessGroupComponentData}
              title={global._('Common.Administer')}
              subtitle={global._('AccessGroups.AccessGroups')}
              instructionsVideoId={
                InstructionsVideoIds[lang].ADMIN.ACCESS_GROUPS
              }
              entity='accessgroups'
              filter={newFilter}
              single
            />
          )
        } else if (part3 === 'locks') {
          calculatedCrumbs.push({
            name: 'Behörighet per lås',
            path: `/admin/accessgroups/${
              organisationObj.id || user.organisation.id
            }/locks`
          })
          calculatedWidgets.push(
            <EntityWidget
              key='widget.accesslocks'
              data={AccessLocksComponentData}
              subtitle={global._('AccessGroups.AccessLocks')}
              entity='locks'
              filter={newFilter}
              single
            />
          )
        } else if (part3 === 'users') {
          calculatedCrumbs.push({
            name: 'Behörighet per användare',
            path: `/admin/accessgroups/${
              organisationObj.id || user.organisation.id
            }/users`
          })
          calculatedWidgets.push(
            <EntityWidget
              key='widget.accessusers'
              data={AccessUsersComponentData}
              subtitle={global._('AccessGroups.AccessUsers')}
              entity='users'
              filter={newFilter}
              single
            />
          )
        }
      } else if (part1 === 'apartments') {
        calculatedCrumbs.push({ name: 'Lägenheter', path: '/admin/apartments' })
        const forceModalOpen =
          part2 === 'new' &&
          (user.roles.includes(UserRoles.PartnerAdmin) ||
            user.roles.includes(UserRoles.CustomerAdmin))

        showApartmentsWidget(true, forceModalOpen)
      }
      setWidgets(calculatedWidgets)
      setCrumbs(calculatedCrumbs)
    })()
  }, [history.location, userHasAccessToKEE])

  if (isMobile) {
    const navigationTitle = crumbs[crumbs.length - 1].name
    return (
      <MobileContainer>
        <NavigationBar
          title={navigationTitle}
          backAction={() => history.replace('/')}
        />
        <ScrollView
          id='infinite-scroll-anchor'
          style={{ backgroundColor: 'var(--color-white)' }}
        >
          {widgets}
        </ScrollView>
      </MobileContainer>
    )
  }

  return (
    <div className={classes.root}>
      <div style={{ padding: '12px 0 36px 0' }}>
        <Breadcrumbs breadcrumbItems={crumbs} />
      </div>
      <Grid container spacing={3}>
        {widgets}
      </Grid>
    </div>
  )
}

Admin.propTypes = {
  signout: PropTypes.func.isRequired, // inherited from PrivateLayout
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const AdminWithStyles = injectSheet(styles)(Admin)
const AdminWithStylesAndRouter = withRouter(AdminWithStyles)
export default AdminWithStylesAndRouter
