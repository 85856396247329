import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import axios, { AxiosError } from 'axios'
import { TextField } from '@material-ui/core'
import KeysNavigation from '../../components/Keys/KeysNavigation'
import Typography from '../../components/Typography'
import Paper from '../../components/Paper'
import SectionTitle from '../../components/SectionTitle'
import Notification from '../../common/Notification'
import { getSelectedOrg } from '../../utils/helpers'
import { UserRoles } from '../../utils/constants'
import { Section } from './Keys'
import Row from 'src/components/Row'
import Button from 'src/components/Button'
import SuccessMessage from 'src/components/SuccessMessage'

const styles = {
  root: {}
}

type SettingProps = {
  classes: any
  history: any
  user: IUser
  breadCrumbItems: any[]
}

const DEFAULT_MAX_KEYS = 6
const Settings = ({
  classes,
  history,
  user,
  breadcrumbItems
}: SettingProps) => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)
  const [settings, setSettings] = useState({
    maxKeyReceiptsPerUser: 6,
    maxKeyReceiptsPerApartment: 6
  })
  const [pendingSettings, setPendingSettings] = useState({})

  const selectedOrg = getSelectedOrg(user)

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)

  useEffect(() => {
    if (selectedOrg) {
      ;(async () => {
        setIsLoading(true)
        try {
          const { data } = await axios.get(
            `/v1/organisations/${selectedOrg.id}/attributes` +
              '?key=keys.settings.maxKeyReceiptsPerUser&' +
              '&key=keys.settings.maxKeyReceiptsPerApartment'
          )
          // Default values
          if (!data['keys.settings.maxKeyReceiptsPerUser']) {
            data['keys.settings.maxKeyReceiptsPerUser'] = DEFAULT_MAX_KEYS
          }
          if (!data['keys.settings.maxKeyReceiptsPerApartment']) {
            data['keys.settings.maxKeyReceiptsPerApartment'] = DEFAULT_MAX_KEYS
          }
          setSettings(data)
        } catch (e: Error | AxiosError) {
          const msg = e.response ? e.response.data : e.message
          setError((global as any)._(msg))
        }
        setIsLoading(false)
      })()
    }
  }, [])

  const handleSetting = (key: String, value: String | Number) => {
    setPendingSettings({
      ...pendingSettings,
      [key]: value
    })
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      await Promise.all(
        Object.entries(pendingSettings).map(([key, value]) =>
          axios.put(`/v1/organisations/${selectedOrg.id}/attributes`, {
            key,
            value: value || DEFAULT_MAX_KEYS
          })
        )
      )
      setSettings({
        ...settings,
        ...pendingSettings
      })
      setPendingSettings({})
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
      }, 2000)
    } catch (e: Error | AxiosError) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  const lang = (global as any).getLanguage()
  const maxKeyReceiptsPerUser =
    typeof pendingSettings['keys.settings.maxKeyReceiptsPerUser'] === 'string'
      ? pendingSettings['keys.settings.maxKeyReceiptsPerUser']
      : settings['keys.settings.maxKeyReceiptsPerUser']
  const maxKeyReceiptsPerApartment =
    typeof pendingSettings['keys.settings.maxKeyReceiptsPerApartment'] ===
    'string'
      ? pendingSettings['keys.settings.maxKeyReceiptsPerApartment']
      : settings['keys.settings.maxKeyReceiptsPerApartment']

  return (
    <div className={classes.root}>
      {showSuccess && <SuccessMessage subtitle={global._('Common.Saved')} />}
      {error && (
        <Notification
          style={{ marginBottom: 20 }}
          type='error'
          message={error}
        />
      )}
      <>
        <KeysNavigation
          breadcrumbItems={breadcrumbItems}
          user={user}
          error={error}
        />
        <Paper noPadding bottomPart>
          <Section>
            <SectionTitle
              title={global._('Keys.Navigation.Settings')}
              noTitleMargin
            />
          </Section>
          <Section
            style={{ borderBottom: '1px solid var(--color-divider-light)' }}
          >
            <Typography variant='subtitleBig' bold style={{ marginBottom: 14 }}>
              {global._('Keys.Settings.Receipts')}
            </Typography>
            <Row>
              <TextField
                variant='filled'
                label={global._('Keys.Settings.MaxReceiptsPerUser')}
                style={{ marginBottom: 20, marginRight: 10, width: '50%' }}
                InputLabelProps={{
                  shrink: true
                }}
                value={maxKeyReceiptsPerUser}
                onChange={e =>
                  handleSetting(
                    'keys.settings.maxKeyReceiptsPerUser',
                    e.target.value
                  )
                }
              />
              <TextField
                variant='filled'
                label={global._('Keys.Settings.MaxReceiptsPerApartment')}
                style={{ marginBottom: 20, marginLeft: 10, width: '50%' }}
                InputLabelProps={{
                  shrink: true
                }}
                value={maxKeyReceiptsPerApartment}
                onChange={e =>
                  handleSetting(
                    'keys.settings.maxKeyReceiptsPerApartment',
                    e.target.value
                  )
                }
              />
            </Row>
          </Section>
          <div
            style={{
              width: '100%',
              borderBottom: '1px solid var(--color-divider-light)'
            }}
          />
          <Row justify='flex-end' style={{ width: '100%', margin: '16px 0px' }}>
            <Button
              variant='primary'
              style={{ marginRight: 20 }}
              disabled={!Object.keys(pendingSettings).length || isLoading}
              onClick={handleSave}
            >
              {global._('Common.Save')}
            </Button>
          </Row>
        </Paper>
      </>
    </div>
  )
}

export default injectSheet(styles)(Settings)
