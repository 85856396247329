import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { TextField } from '@material-ui/core'
import ssn from '../utils/ssn'

const styles = {
  root: {
    width: '100%'
  },
  helperText: {
    paddingLeft: 11,
    paddingTop: 5
  }
}

const regex = new RegExp(/(^\d{0,8}-?\d{0,4}$)|(^\d{0,11}$)/)

export const validate = value => ssn.valid(value)
const SsnInput = props => {
  const [personalNumber, setPersonalNumber] = useState('')
  const [error, setError] = useState(false)
  const [blur, setBlur] = useState(false)
  const { classes, onSsnValid, onChange, helperText, ...rest } = props
  const showError = error && blur

  const handleSsnValid = value => {
    setPersonalNumber(value)
    if (ssn.valid(value)) {
      const validSsn = ssn.format(value, true)
      onSsnValid({ valid: true, ssn: validSsn })
      setError(false)
    } else {
      onSsnValid({ valid: false, ssn: null })
    }
  }

  const handleInputChange = e => {
    const { value } = e.target

    if (!value) {
      setError(false)
    }

    if (!regex.test(value)) {
      setError(true)
      return
    }
    handleSsnValid(value)
    onChange(e)
  }

  const handleOnBlur = () => {
    if (personalNumber && !ssn.valid(personalNumber)) {
      setError(true)
      setBlur(true)
    }
  }

  useEffect(() => {
    if (props.value && ssn.valid(props.value)) {
      setPersonalNumber(ssn.format(props.value, true, true))
    }
  }, [props.value])

  return (
    <TextField
      error={showError}
      className={classes.root}
      variant='filled'
      type='text'
      fullWidth
      onBlur={handleOnBlur}
      onChange={handleInputChange}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText
        }
      }}
      {...rest}
      value={personalNumber}
      helperText={
        helperText && !showError
          ? helperText
          : showError
          ? global._('PersonalNumber.Error')
          : ' '
      }
    />
  )
}

SsnInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onSsnValid: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  helperText: PropTypes.string
}

SsnInput.defaultProps = {
  onSsnValid: () => null,
  onChange: () => null,
  onKeyPress: () => null,
  label: global._('PersonalNumber.Label'),
  value: null,
  helperText: global._('Terms.PersonalNumberFormat')
}

export default injectSheet(styles)(SsnInput)
