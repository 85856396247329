import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import Icon from '@material-ui/core/Icon'
import Theme from '../helpers/Theme'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0)',
    border: `2px solid ${Theme.Colors.BLACK}`,
    borderRadius: 3,
    // overflow: 'hidden',
    minWidth: 26,
    maxWidth: 26,
    height: 26,
    cursor: 'pointer',
    transition: 'border-width 0ms linear 0ms'
  },
  disabled: {
    cursor: 'default',
    opacity: '0.75'
  },
  checked: {
    transitionDelay: '75ms',
    borderWidth: 0
    // backgroundColor: Theme.Colors.PRIMARY,
  },
  interior: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 75ms ease-in-out 0ms',
    width: 0,
    height: 0,
    backgroundColor: Theme.Colors.PRIMARY,
    borderRadius: 3
    // overflow: 'hidden'
  },
  interiorChecked: {
    width: '100%',
    height: '100%'
  },
  tick: {
    transition: 'all 150ms linear 75ms',
    transform: 'rotate(-90deg)',
    fontSize: 0
  },
  tickActive: {
    fontSize: 28,
    transform: 'rotate(0deg)'
  }
}

const Checkbox = props => {
  const { classes, checked, onChange, disabled, ...otherProps } = props

  const rootClasses = classnames(classes.root, {
    [classes.checked]: checked,
    [classes.disabled]: disabled
  })

  const checkClasses = classnames(classes.interior, {
    [classes.interiorChecked]: checked
  })

  const tickClasses = classnames(classes.tick, {
    [classes.tickActive]: checked
  })

  return (
    <div
      data-cy='input-checkbox'
      className={rootClasses}
      onClick={() => onChange(!checked)}
      {...otherProps}>
      <div className={checkClasses}>
        <Icon className={tickClasses}>done</Icon>
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

Checkbox.defaultProps = {
  disabled: false
}

const CheckboxWithStyles = injectSheet(styles)(Checkbox)
export default CheckboxWithStyles
