import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import Theme from '../helpers/Theme'

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #b1b1b1'
  },
  iconContainer: {
    width: 72,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const Table = props => {
  const {
    classes,
    identifier,
    items,
    rowContent,
    icon,
    onRowClick,
    ...otherProps
  } = props

  return (
    <div className={classes.root} {...otherProps}>
      {items.map(item => (
        <div
          key={`table-row-key-${identifier}-${item.id}`}
          className={classes.row}
          onClick={onRowClick ? () => onRowClick(item) : null}>
          {icon && (
            <div className={classes.iconContainer}>
              {/* If icon is a string, render it in an icon */}
              {typeof icon === 'string' && <Icon>{icon}</Icon>}
              {/* If icon is an object, render it as a node  */}
              {typeof icon === 'object' && icon}
            </div>
          )}
          <div
            className={classes.row}
            style={{
              padding: '14px 0 14px 0',
              borderBottom: '1px solid #e8e8e8'
            }}>
            {rowContent(item)}
            {onRowClick && (
              <div style={{ marginLeft: 'auto', marginRight: 14 }}>
                <Icon style={{ fontSize: 20 }}>chevron_right</Icon>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

Table.propTypes = {
  classes: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
  items: PropTypes.array,
  rowContent: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onRowClick: PropTypes.func
}

Table.defaultProps = {
  items: [],
  icon: null,
  onRowClick: undefined
}

const TableWithStyles = injectSheet(styles)(Table)
export default TableWithStyles
