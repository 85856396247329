import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withUser from 'src/utils/withUser'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import { Tooltip } from '@material-ui/core'
import Typography from './Typography'
import useWindowDimensions from '../utils/useWindowDimensions'
import NoResultsMessage from './NoResultsMessage'

const styles = {
  root: {
    overflowX: 'auto'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    tableLayout: 'fixed'
  },
  header: {
    '& tr': {
      borderBottom: '1px solid var(--divider-color)'
    }
  },
  headerCell: {
    fontSize: '0.875rem',
    color: 'var(--color-middle-grey)',
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '24px 10px 16px 10px',
    minWidth: 50,
    wordBreak: 'break-word',
    hyphens: 'manual',
    '&:first-child': {
      paddingLeft: 18,
      paddingRight: 0
    },
    userSelect: 'none',
    '&:hover,&.selected,&.sortable': {
      '& $tableHeaderSortIcon': {
        visibility: 'visible'
      },
      cursor: 'pointer',
      color: 'black'
    }
  },
  tableHeaderSortIcon: {
    verticalAlign: 'middle',
    visibility: 'hidden',
    transition: 'transform 140ms'
  },
  cell: {
    fontSize: '1rem',
    padding: '12px 10px',
    border: 'none',
    '&:first-child': {
      paddingLeft: 18,
      paddingRight: 0,
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 4,
        top: 0,
        left: 0,
        bottom: 0,
        backgroundColor: 'transparent'
      }
    }
  },
  body: {},
  row: {
    transitionDuration: '200ms',
    transitionProperty: 'border-left, background-color',
    '&:hover': {
      '& .tableRowIcon': {
        backgroundColor: 'white!important'
      },
      '& td:first-child:before': {
        backgroundColor: 'var(--color-primary)'
      },
      cursor: 'pointer',
      backgroundColor: '#f4f4f4'
    },
    borderBottom: '1px solid #e0e0e0'
  },
  rowHighlight: {
    backgroundColor: 'var(--color-primary-tone-4)'
  },
  rowExpanded: {
    backgroundColor: '#f4f4f4'
  },
  cellExpanded: {
    padding: '28px 18px'
  },
  expansionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  chevron: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 'auto',
    fontSize: 16,
    color: 'rgba(0,0,0,0.75)',
    marginRight: 10
  },
  iconContainerMobile: {
    padding: '0px 28px'
  },
  mobileRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  mobileRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0',
    borderBottom: '1px solid var(--divider-color)'
  },
  longText: {
    '& span': {
      display: 'inline-block',
      maxWidth: '99%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  '@media (max-width: 900px)': {
    root: {}
  }
}

const Table = props => {
  const {
    classes,
    history,
    identifier,
    itemIdentifier,
    onScrollEnd,
    mobileData,
    noResultsMessage,
    user
  } = props

  const { isMobile } = useWindowDimensions()
  const scrollRef = useRef()

  useEffect(() => {
    if (onScrollEnd && scrollRef.current) {
      let triggered = false
      const container = isMobile
        ? scrollRef.current.closest('#infinite-scroll-anchor')
        : window
      const onScroll = () => {
        const { current: element } = scrollRef
        const rect = element.getBoundingClientRect()
        if (
          rect.top >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight)
        ) {
          if (!triggered) {
            triggered = true
            onScrollEnd()
          }
        }
      }
      container.addEventListener('scroll', onScroll, false)
      return () => container.removeEventListener('scroll', onScroll, false)
    }
  }, [onScrollEnd, scrollRef.current])

  const colHiddenFor = (col, item) =>
    (typeof col.hiddenFor !== 'function' && col.hiddenFor) ||
    (typeof col.hiddenFor === 'function' &&
      col.hiddenFor(item, window.innerWidth, user))

  if (isMobile) {
    return (
      <div className={classes.root}>
        {props.items &&
          props.items.map(item => {
            const title = mobileData.title(item)
            const subtitle = mobileData.subtitle(item)

            return (
              <div
                key={`${identifier}.table.mobile.item.${itemIdentifier(item)}`}
                className={classes.mobileRowContainer}
                onClick={
                  mobileData.onClick
                    ? () => mobileData.onClick(item)
                    : undefined
                }
              >
                <div className={classes.iconContainerMobile}>
                  <Icon style={{ color: '#888' }}>{mobileData.icon}</Icon>
                </div>
                <div className={classes.mobileRow}>
                  <div className={classes.column}>
                    <Typography bold>{title}</Typography>
                    <Typography style={{ fontSize: '0.9rem' }} faded>
                      {subtitle}
                    </Typography>
                  </div>
                  <div className={classes.chevron}>
                    <Icon>chevron_right</Icon>
                  </div>
                </div>
              </div>
            )
          })}
        {(!props.items || !props.items.length) && noResultsMessage && (
          <NoResultsMessage content={noResultsMessage} />
        )}
        <div style={{ visibility: 'hidden' }} ref={scrollRef} />
      </div>
    )
  }
  return (
    <div className={classes.root}>
      {props.items && !!props.items.length && (
        <table className={classes.table}>
          <thead className={classes.header}>
            <tr>
              {props.columns &&
                props.columns.map(col => {
                  const key = col.sortKey || col.key
                  const selected = col.sortable && props.sort.key === key
                  const order =
                    props.sort.key === key ? props.sort.order : 'desc'

                  if (colHiddenFor(col, col)) return

                  return (
                    <th
                      key={`table.${identifier}.table.header.${col.key}`}
                      className={classnames(classes.headerCell, { selected })}
                      style={col.style || undefined}
                      onClick={() => {
                        if (col.sortable && props.onSort) {
                          const newOrder =
                            props.sort.key === key &&
                            props.sort.order === 'desc'
                              ? 'asc'
                              : 'desc'
                          props.onSort(key, newOrder)
                        }
                      }}
                    >
                      <span
                        style={{ marginRight: 4 }}
                        dangerouslySetInnerHTML={{
                          __html: global._(col.localizationKey)
                        }}
                      />
                      {col.sortable && (
                        <Icon
                          className={classes.tableHeaderSortIcon}
                          style={{
                            fontSize: 20,
                            transform:
                              selected && order === 'asc'
                                ? 'rotate(-180deg)'
                                : 'rotate(0deg)'
                          }}
                        >
                          arrow_downward
                        </Icon>
                      )}
                    </th>
                  )
                })}
            </tr>
          </thead>
          <tbody className={classes.body}>
            {props.items.map((item, index) => {
              const isExpanded =
                props.rowExpansionContent &&
                props.expanded.includes(itemIdentifier(item))
              return (
                <React.Fragment
                  key={`table.${identifier}.${itemIdentifier(
                    item
                  )}.fragment-${index}`}
                >
                  <tr
                    className={classnames(classes.row, {
                      [classes.rowExpanded]: isExpanded,
                      [classes.rowHighlight]: item.highlight
                    })}
                    onClick={() => {
                      if (props.onRowClick) {
                        props.onRowClick(item)
                      }
                    }}
                  >
                    {props.columns.map(col => {
                      let value = item[col.key]
                      if (col.key === 'chevron' || col.key === 'icon') {
                        value = col.transform(isExpanded, value, item)
                      } else if (col.transform) {
                        value = col.transform(value, item)
                      }

                      const { longText } = col

                      if (colHiddenFor(col, item)) return

                      let displayedValue = value

                      if (longText && value && value.length > 15) {
                        displayedValue = (
                          <Tooltip title={value} placement='right'>
                            <span>{value}</span>
                          </Tooltip>
                        )
                      }

                      return (
                        <td
                          key={`table.${identifier}.body.row.${itemIdentifier(
                            item
                          )}.cell.${col.key}`}
                          className={`${classes.cell} ${
                            longText ? classes.longText : ''
                          }`}
                          style={col.style || null}
                        >
                          {displayedValue}
                        </td>
                      )
                    })}
                  </tr>
                  {props.expanded.includes(itemIdentifier(item)) && (
                    <tr className={classes.rowExpanded}>
                      <td
                        className={classes.cellExpanded}
                        colSpan={
                          props.columns.filter(c => !colHiddenFor(c, item))
                            .length
                        }
                      >
                        <div className={classes.expansionContainer}>
                          {props.rowExpansionContent &&
                            props.rowExpansionContent(item)}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      )}
      {(!props.items || !props.items.length) && noResultsMessage && (
        <NoResultsMessage content={noResultsMessage} />
      )}
      <div style={{ visibility: 'hidden' }} ref={scrollRef} />
    </div>
  )
}

Table.propTypes = {
  classes: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  itemIdentifier: PropTypes.func.isRequired,
  onScrollEnd: PropTypes.func,
  onSort: PropTypes.func,
  sort: PropTypes.object,
  items: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  expanded: PropTypes.array,
  rowExpansionContent: PropTypes.func, // item => (<Content />)
  mobileData: PropTypes.shape({
    onClick: PropTypes.func, // item => ...
    icon: PropTypes.string,
    title: PropTypes.func, // item => item.field
    subtitle: PropTypes.func // Item => item.field
  }).isRequired,
  noResultsMessage: PropTypes.string
}

Table.defaultProps = {
  onScrollEnd: null,
  onSort: null,
  sort: {},
  rowExpansionContent: null,
  expanded: [],
  noResultsMessage: null
}

const TableWithStyles = injectSheet(styles)(Table)
export default withUser(TableWithStyles)
