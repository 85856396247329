import { connect } from 'react-redux'
import { toggleSnackbar } from '../../redux/reducers/snackbar'
import CustomizedSnackbar from './Snackbar'

const mapStateToProps = state => ({
  ...state.snackbar
})

const mapDispatchToProps = dispatch => ({
  toggleSnackbar: bool => {
    dispatch(toggleSnackbar(bool))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedSnackbar)
