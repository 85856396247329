import React, { useState, useEffect, useRef } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Icon from '@material-ui/core/Icon'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Typography from '../../../components/Typography'
import Theme from '../../../helpers/Theme'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Row from '../../../components/Row'
import Modal from '../../../components/Modal'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import PropertyDetailsMobile from './PropertyDetailsMobile'
import NoResultsMessage from '../../../components/NoResultsMessage'
import LoadingSpinner from '../../../components/LoadingSpinner'
import PageContentHeader from '../../../components/PageContentHeader'
import UploadBlueprint from '../../../components/BlueprintTool/modals/UploadBlueprint'

const columnNames = ['name', 'items']

const styles = {
  paper: {
    backgroundColor: '#fff',
    zIndex: '999',
    marginBottom: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 3,
    boxSizing: 'border-box',
    border: '1px solid var(--color-middle-grey-2)',
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)'
  },
  rowStyle: {
    borderLeft: '6px solid rgba(0, 0, 0, 0.0)',
    cursor: 'pointer',
    '&:hover': {
      borderLeft: '6px solid #78DBD1'
    }
  }
}

const PropertyDetails = props => {
  const {
    match,
    classes,
    setSnackbar,
    location,
    history,
    breadcrumbItems,
    user
  } = props

  const { isMobile } = useWindowDimensions()
  const { propertyId } = match.params
  const [property, setProperty] = useState(null)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [isEditingName, setIsEditingName] = useState(false)
  const [isNameUpdated, setIsNameUpdated] = useState(false)
  const [address, setAddress] = useState('')
  const [isEditingAddress, setIsEditingAddress] = useState(false)
  const [isAddressUpdated, setIsAddressUpdated] = useState(false)
  const [expandedRows, setExpandedRows] = useState([])
  const [hoveredRow, setHoveredRow] = useState()
  const [filter, setFilter] = useState({})
  const [activeFilter, setActiveFilter] = useState()
  const [error, setError] = useState(null)
  const [showDeletePropertyModal, setShowDeletePropertyModal] = useState(false)
  const [showDeleteBlueprintModal, setShowDeleteBlueprintModal] = useState(null)
  const [showBlueprintUploadModal, setShowBlueprintUploadModal] =
    useState(false)
  const nameInputRef = useRef()
  const addressInputRef = useRef()

  useEffect(() => {
    ;(async () => {
      try {
        const { data: aProperty } = await axios.get(
          `/v1/properties/${propertyId}?blueprints=true`
        )
        setProperty(aProperty)
        setName(aProperty.name)
        setAddress(aProperty.address)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setSnackbar(global._(msg), 'error')
      }
    })()
  }, [])

  const onDeleteBlueprint = async blueprint => {
    try {
      await axios.delete(`/v1/blueprints/${blueprint.id}`)
      const aProperty = { ...property }
      aProperty.blueprints = aProperty.blueprints.filter(
        bp => bp.id !== blueprint.id
      )
      setProperty(aProperty)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setSnackbar(global._(msg), 'error')
    }
    setShowDeleteBlueprintModal(null)
  }

  const onDeleteProperty = async () => {
    try {
      await axios.delete(`/v1/properties/${property.id}`, {
        data: { organisationId: property.organisationId }
      })

      history.push('/properties')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setSnackbar(global._(msg), 'error')
    }
    setShowDeletePropertyModal(false)
  }

  const handleFilter = nameValue => e => {
    setActiveFilter(nameValue)
    const { value } = e.target
    const aFilter = { ...filter }
    if (value) aFilter[nameValue] = value
    else delete aFilter[nameValue]

    if (nameValue !== 'query') {
      aFilter.orderBy = nameValue
      aFilter.order = aFilter.order === 'asc' ? 'desc' : 'asc'
    }
    setFilter(aFilter)
    const cloneProperty = _.clone(property)
    const newSort = _.orderBy(
      cloneProperty.blueprints.map(bp => ({ ...bp, items: bp.objects.length })),
      nameValue,
      [aFilter.order]
    )
    cloneProperty.blueprints = newSort
    setProperty(cloneProperty)
  }

  const handleUpdate = async event => {
    event.preventDefault()
    event.stopPropagation()

    try {
      const data = {}
      if (name !== property.name) {
        data.name = name
      }
      if (address !== property.address) {
        data.address = address
      }

      data.organisationId = property.organisationId

      if (Object.keys(data).length > 0) {
        const res = await axios.patch(`/v1/properties/${property.id}`, data)
        setProperty({
          ...property,
          ...res.data
        })
        if (Object.prototype.hasOwnProperty.call(data, 'name')) {
          setIsNameUpdated(true)
          setTimeout(() => {
            setIsNameUpdated(false)
          }, 2000)
        }
        if (Object.prototype.hasOwnProperty.call(data, 'address')) {
          setIsAddressUpdated(true)
          setTimeout(() => {
            setIsAddressUpdated(false)
          }, 2000)
        }
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setTimeout(() => {
      setIsEditingAddress(false)

      if (!nameError) {
        setIsEditingName(false)
      }
    }, 200)
  }

  const canUpdateName = () => {
    if (isEditingName && name?.length < 3) {
      setNameError(true)
      return false
    }

    return true
  }

  const handleNameChange = e => {
    const { value } = e.target
    if (value.length > 2 && nameError) {
      setNameError(false)
    }

    setName(value)
  }

  if (!property) return <LoadingSpinner />

  let nameInputAdornment
  if (isEditingName) {
    nameInputAdornment = (
      <Button
        style={{
          position: 'absolute',
          top: 15,
          right: 10,
        }}
        variant='noHover'
        onClick={handleUpdate}>
        {global._('Common.Save')}
      </Button>
    )
  } else if (isNameUpdated) {
    nameInputAdornment = (
      <Icon
        style={{
          position: 'absolute',
          top: 25,
          right: 10,
          color: Theme.Colors.PRIMARY
        }}>
        check_circle
      </Icon>
    )
  } else if (!isEditingName) {
    nameInputAdornment = (
      <Button
        style={{ position: 'absolute', top: 25, right: 10 }}
        variant='icon'
        icon='edit'
        onClick={() => {
          setIsEditingName(true)
          setTimeout(() => {
            if (nameInputRef.current) {
              nameInputRef.current.focus()
            }
          }, 100)
        }}
      />
    )
  }

  let addressInputAdornment
  if (isEditingAddress) {
    addressInputAdornment = (
      <Button
        style={{ position: 'absolute', top: 15, right: 10 }}
        variant='noHover'
        onClick={handleUpdate}>
        {global._('Common.Save')}
      </Button>
    )
  } else if (isAddressUpdated) {
    addressInputAdornment = (
      <Icon
        style={{
          position: 'absolute',
          top: 25,
          right: 10,
          color: Theme.Colors.PRIMARY
        }}>
        check_circle
      </Icon>
    )
  } else if (!isEditingAddress) {
    addressInputAdornment = (
      <Button
        style={{ position: 'absolute', top: 25, right: 10 }}
        variant='icon'
        icon='edit'
        onClick={() => {
          setIsEditingAddress(true)
          setTimeout(() => {
            if (addressInputRef.current) {
              addressInputRef.current.focus()
            }
          }, 100)
        }}
      />
    )
  }

  const BlueprintUploadModal = (
    <UploadBlueprint
      isMobile={isMobile}
      setShowBlueprintUploadModal={setShowBlueprintUploadModal}
      propertyId={propertyId}
      history={history}
    />
  )

  if (isMobile) {
    return (
      <PropertyDetailsMobile
        {...props}
        user={user}
        onDeleteProperty={onDeleteProperty}
        setShowBlueprintUploadModal={setShowBlueprintUploadModal}
        showBlueprintUploadModal={showBlueprintUploadModal}
        BlueprintUploadModal={BlueprintUploadModal}
      />
    )
  }

  const renderExpandedRow = bp => (
    <TableRow style={{ backgroundColor: Theme.Colors.MATERIAL_GREY }}>
      <TableCell
        colSpan='5'
        style={{ textAlign: 'right', padding: '30px 20px' }}>
        <Button
          variant='outlined'
          style={{ marginRight: 20 }}
          onClick={() => setShowDeleteBlueprintModal(bp)}>
          {global._('Properties.RemoveBlueprintButtonTitle')}
        </Button>
        {showDeleteBlueprintModal === bp && (
          <Modal
            onClose={() => setShowDeleteBlueprintModal(null)}
            title={global._('Properties.RemoveBlueprintModalTitle')}
            content={
              <div style={{ textAlign: 'left' }}>
                <Typography
                  style={{ display: 'block', paddingBottom: 12 }}
                  variant='body'>
                  {global._('Properties.RemoveBlueprintModalConfirmText')}
                </Typography>
                <Typography
                  style={{ display: 'block', paddingBottom: 12 }}
                  variant='subtitleSmall'>
                  {bp.name}
                </Typography>
                <Typography
                  style={{ display: 'block', paddingBottom: 12 }}
                  variant='bodyItalic'>
                  {global._('Properties.RemoveBlueprintModalWarningText')}
                </Typography>
              </div>
            }
            buttons={[
              <Button
                variant='none'
                onClick={() => setShowDeleteBlueprintModal(null)}>
                {global._('Common.Cancel')}
              </Button>,
              <Button variant='secondary' onClick={() => onDeleteBlueprint(bp)}>
                {global._('Delete')}
              </Button>
            ]}
          />
        )}
        <Button
          variant='secondary'
          onClick={() => {
            history.push(`${location.pathname}/blueprints/${bp.id}/overview`)
          }}>
          {global._('Properties.ShowOverview')}
        </Button>
      </TableCell>
    </TableRow>
  )

  return (
    <div>
      <PageContentHeader
        breadcrumbItems={breadcrumbItems}
        actionButtons={
          <>
            <Button
              variant='outlined'
              style={{
                marginLeft: 'auto',
                maxWidth: 226,
                marginRight: 20
              }}
              onClick={() => setShowDeletePropertyModal(true)}
              type='danger'>
              {global._('Properties.RemovePropertyButtonTitle')}
            </Button>
            <Button
              variant='outlined'
              style={{
                marginRight: 20
              }}
              onClick={() =>
                property && history.push(`/properties/${property.id}/overview`)
              }>
              {global._('Properties.ShowOverview')}
            </Button>
            <Button
              data-cy='button-quote-overview'
              variant='primary'
              style={{ maxWidth: 194 }}
              onClick={() =>
                history.push(`${location.pathname}/quoteoverview`)
              }>
              {global._('Properties.RequestForQuotation')}
            </Button>
          </>
        }
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 10
        }}>
        {showBlueprintUploadModal && BlueprintUploadModal}
        {showDeletePropertyModal && (
          <Modal
            onClose={() => setShowDeletePropertyModal(false)}
            title={global._('Properties.RemovePropertyModalTitle')}
            content={
              <div style={{ textAlign: 'left' }}>
                <Typography
                  style={{ display: 'block', paddingBottom: 12 }}
                  variant='body'>
                  {global._('Properties.RemovePropertyModalConfirmText')}
                </Typography>
                <Typography
                  style={{ display: 'block', paddingBottom: 12 }}
                  variant='subtitleSmall'>
                  {property.name}
                </Typography>
                <Typography
                  style={{ display: 'block', paddingBottom: 12 }}
                  variant='bodyItalic'>
                  {global._('Properties.RemovePropertyModalWarningText')}
                </Typography>
              </div>
            }
            buttons={[
              <Button
                variant='none'
                onClick={() => setShowDeletePropertyModal(false)}>
                {global._('Common.Cancel')}
              </Button>,
              <Button variant='secondary' onClick={onDeleteProperty}>
                {global._('Delete')}
              </Button>
            ]}
          />
        )}
      </div>
      <div
        className={classes.paper}
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 30,
          textAlign: 'left'
        }}>
        <div style={{ flex: 3, marginRight: 10 }}>
          <Typography style={{ color: '#8E8E8E' }} variant='body'>
            {global._('Properties.Name')}
          </Typography>
          <Input
            error={nameError}
            style={{ paddingTop: 12 }}
            endAdornment={nameInputAdornment}
            disableHelpText
            disabled={!isEditingName}
            ref={nameInputRef}
            value={name}
            onChange={handleNameChange}
            onBlur={e => canUpdateName() && handleUpdate(e)}
            onKeyPress={e => {
              if (e.key === 'Enter') canUpdateName() && handleUpdate(e)
            }}
          />
          <div style={{ marginLeft: 15 }}>
            <Typography style={{ color: !nameError ? '#8E8E8E' : '#D74B4B' }} variant='small'>
              {!isEditingName ?
                `${global._('Properties.Edit')} ${global._('Properties.PropertyName')}` :
                `${global._('Properties.UpdatePropertyNameHelperText')}`
              }
            </Typography>
          </div>
        </div>
        <div style={{ flex: 2 }}>
          <Typography style={{ color: '#8E8E8E' }} variant='body'>
            {global._('Properties.Address')}
          </Typography>
          <Input
            style={{ paddingTop: 12 }}
            endAdornment={addressInputAdornment}
            disableHelpText
            disabled={!isEditingAddress}
            ref={addressInputRef}
            value={address}
            onChange={e => setAddress(e.target.value)}
            onBlur={e => handleUpdate(e)}
            onKeyPress={e => {
              if (e.key === 'Enter') handleUpdate(e)
            }}
          />
          {isEditingAddress && (
            <Button
              variant='none'
              style={{
                position: 'absolute',
                top: 10,
                right: 0,
                height: 56
              }}>
              {global._('Common.Save')}
            </Button>
          )}
          <div style={{ marginLeft: 15 }}>
            <Typography style={{ color: '#8E8E8E' }} variant='small'>
              {`${global._('Properties.Edit')} ${global._(
                'Properties.PropertyAddress'
              )}`}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.paper}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            margin: 'auto',
            justifyContent: 'flex-start',
            padding: 30
          }}>
          <Typography
            variant='title'
            style={{ flex: 7, marginRight: 15, fontWeight: 600 }}>
            {global._('PAGE_BLUEPRINTS_TITLE')}
          </Typography>
          <Button
            data-cy='button-new-blueprint'
            style={{ flex: 1, minWidth: 242 }}
            variant='secondary'
            onClick={() => setShowBlueprintUploadModal(true)}>
            {global._('Properties.NewBlueprintButtonTitle')}
          </Button>
        </div>
        {(!property.blueprints || property.blueprints.length === 0) && (
          <NoResultsMessage
            content={`${global._(
              'Properties.NoExistingBlueprintsText'
            )} <br /> ${global._('Properties.AddBlueprintsText')}`}
          />
        )}
        {property.blueprints && property.blueprints.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                {columnNames.map(key => (
                  <TableCell key={key}>
                    <TableSortLabel
                      active={filter.orderBy === key}
                      direction={filter.order}
                      onClick={handleFilter(key)}
                      style={{
                        color: activeFilter === key ? '#000' : '#8E8E8E',
                        fontSize: 14,
                        fontWeight: 'bold',
                        fontFamily: '"Open Sans", sans-serif'
                      }}>
                      {global._(key)}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell colSpan='3' />
              </TableRow>
            </TableHead>
            <TableBody>
              {property &&
                property.blueprints &&
                property.blueprints.map((bp, index) => {
                  const isExpanded = expandedRows.includes(bp.id)
                  return (
                    <>
                      <TableRow
                        onMouseEnter={() => setHoveredRow(bp.id)}
                        onMouseLeave={() => setHoveredRow(null)}
                        className={classes.rowStyle}
                        key={`blueprint-row-${bp.id}`}
                        style={{
                          backgroundColor:
                            isExpanded && Theme.Colors.MATERIAL_GREY
                        }}>
                        <TableCell
                          onClick={() =>
                            history.push(
                              `${location.pathname}/blueprints/${bp.id}`
                            )
                          }
                          data-cy={`table-cell-${index}`}>
                          <Typography
                            variant='body'
                            style={{
                              textShadow:
                                bp.id === hoveredRow &&
                                '0 0 .65px #333, 0 0 .65px #333'
                            }}>
                            {bp.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            history.push(
                              `${location.pathname}/blueprints/${bp.id}`
                            )
                          }>
                          {bp.objects.length}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell align='right'>
                          <Button
                            variant='icon'
                            icon={isExpanded ? 'expand_less' : 'expand_more'}
                            onClick={() =>
                              isExpanded
                                ? setExpandedRows(
                                    expandedRows.filter(id => id !== bp.id)
                                  )
                                : setExpandedRows([...expandedRows, bp.id])
                            }
                          />
                        </TableCell>
                      </TableRow>
                      {isExpanded && renderExpandedRow(bp)}
                    </>
                  )
                })}
              <TableRow key='final-row'>
                <TableCell colSpan='4' className='final-row' />
              </TableRow>
            </TableBody>
          </Table>
        )}
        <Row />
      </div>
    </div>
  )
}

PropertyDetails.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  user: PropTypes.object
}

PropertyDetails.defaultProps = {
  user: null
}

export default withStyles(styles)(PropertyDetails)
