/**
 * Display a success "card" with custom content
 * @constructor
 * @param {string} content - Defaults to GET
 */
import React from 'react'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = {
  successRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 80
  },
  successContainer: {
    width: 530,
    padding: '46px 96px 40px 96px',
    borderRadius: 3,
    boxShadow:
      '0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2)',
    border: 'solid 1px #e1e1e1',
    backgroundColor: '#fff',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& button:not(:last-child)': {
      marginBottom: 20
    }
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    borderRadius: 500,
    backgroundColor: 'var(--color-primary-tone-3)',
    marginBottom: 38
  },
  icon: {
    fontSize: 60,
    color: '#000'
  },
  '@media (max-width: 900px)': {
    successContainer: {
      width: '100%',
      padding: 'var(--section-padding-default)',
      boxShadow: 'none',
      border: 'none'
    }
  }
}

const Success = props => {
  const { classes, children } = props

  return (
    <div className={classes.successRoot}>
      <div className={classes.successContainer}>
        <div className={classes.iconContainer}>
          <Icon className={classes.icon}>checkmark</Icon>
        </div>
        {children}
      </div>
    </div>
  )
}

Success.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

Success.defaultProps = {}

const SuccessWithStyles = injectSheet(styles)(Success)
export default SuccessWithStyles
