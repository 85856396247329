import React from 'react'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '../../../../components/Button'
import { history } from '../../../../redux/store'

export default {
  doNotShowFilter: true,
  doNotShowTable: true,
  icon: () => 'person',
  identifier: user => user.id,
  noResultsMessage: () => global._('AccessGroups.NoAvailableUsers'),
  MobileTable: {
    icon: 'person',
    title: user => user.name,
    subtitle: user => (
      <>
        {user.organisation && user.organisation.name}
        <br />
        {user.email}
      </>
    ),
    onClick: item =>
      history.push(
        `/admin/accessgroups/accessbyitem/${item.organisation.id}/users/${item.id}`
      )
  },
  Columns: {
    single: [
      {
        key: 'icon',
        sortable: false,
        localizationKey: '',
        style: { width: 60 },
        transform: isExpanded => (
          <div
            className='tableRowIcon'
            style={{
              width: 38,
              height: 38,
              transition: 'background-color 200ms',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isExpanded ? 'white' : 'var(--color-light-grey)',
              color: 'var(--color-middle-grey)',
              borderRadius: 500
            }}
          >
            <Icon style={{ fontSize: 18 }}>person</Icon>
          </div>
        )
      },
      {
        key: 'name',
        sortable: true,
        sortKey: 'name',
        localizationKey: 'Name',
        style: { width: 250 }
      },
      {
        key: 'email',
        sortable: true,
        sortKey: 'email',
        localizationKey: 'Widgets.users.Attributes.email',
        style: { width: 300 }
      },
      {
        key: 'organisation',
        sortable: true,
        sortKey: 'organisation.name',
        localizationKey: 'Organisation',
        transform: value => value && value.name,
        style: { width: 300 }
      },
      {
        key: 'chevron',
        localizationKey: '',
        sortable: false,
        style: { width: 70 },
        transform: isExpanded => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton
              style={{
                transition: 'transform 150ms',
                marginLeft: 'auto',
                transform: `rotate(${isExpanded ? -90 : 90}deg)`
              }}
            >
              <Icon>chevron_right</Icon>
            </IconButton>
          </div>
        )
      }
    ]
  },
  rowExpansionContent: (item, caller, opts) => (
    <React.Fragment>
      <Button
        variant='outlined'
        onClick={() =>
          opts.history.push(`/admin/users/${item.organisation.id}/${item.id}`)
        }
        style={{ marginRight: 18 }}
      >
        {global._('Widgets.users.EditUser')}
      </Button>
      <Button
        variant='outlined'
        onClick={() =>
          opts.history.push(
            `/admin/accessgroups/accessbyitem/${item.organisation.id}/users/${item.id}`
          )
        }
        style={{ marginRight: 18 }}
      >
        {global._('AccessGroups.Access')}
      </Button>
    </React.Fragment>
  )
}
