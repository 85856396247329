import { connect } from 'react-redux'
import { setAuthenticationData } from '../../redux/reducers/login'
import Login from './Login'

const mapStateToProps = state => ({
  user: state.login.user,
  token: state.login.token,
  expires: state.login.expires,
  returnUrl: state.login.returnUrl
})

const mapDispatchToProps = dispatch => ({
  setAuthenticationData: data => dispatch(setAuthenticationData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
