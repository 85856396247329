import React, { useEffect, useState } from 'react'
import DigitalSigning from 'src/components/DigitalSigning'

interface IKeyReceiptAndInventoryRequestProps {
  history: any
  user: IUser
}

const KeyReceiptAndInventoryRequest = ({
  history,
  user
}: IKeyReceiptAndInventoryRequestProps) => {
  const idParams = new URLSearchParams(window.location.search)
  const ids = idParams
    .get('ids')
    ?.split(',')
    .map(id => ({ id }))
  const isInventory = window.location.pathname.includes(
    'inventoryapproval-request'
  )

  if (!ids) {
    return null
  }

  let signURL
  if (isInventory) {
    signURL = `/v1/organisations/${user.organisation.id}/keyinventories/sign`
  } else {
    signURL = '/v1/keys/sign'
  }
  return (
    <DigitalSigning
      signURL={signURL}
      requestData={ids}
      title={global._(
        isInventory
          ? 'MyKeys.KeyInventory.VerifyKeys'
          : 'MyKeys.Request.TitlePl'
      )}
      onCancel={() => {
        history.push('/my-keys')
      }}
      onCompletion={() => {
        localStorage.setItem(
          isInventory
            ? [
                ids.length > 1
                  ? 'keyInventoryApprovalsRequestedSuccessfully'
                  : 'keyInventoryApprovalRequestedSuccessfully'
              ]
            : 'keyReceiptRequestedSuccessfully',
          true
        )
        history.push('/my-keys')
      }}
      onFailure={e => {}}
    />
  )
}

export default KeyReceiptAndInventoryRequest
