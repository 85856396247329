import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from './Typography'

const styles = {
  container: {
    display: 'flex',
    margin: '10px 10px 10px 0 ',
    cursor: props => (props.onClick ? 'pointer' : undefined)
  },
  item: {
    padding: '6px 12px 7px',
    background: 'var(--color-material-grey)',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  }
}

const SummaryBadge = ({ classes, label, count, onClick, loading }) => (
  <div onClick={() => onClick && onClick()} className={classes.container}>
    <div className={classes.item} style={{ marginRight: 3 }}>
      <Typography variant='body'>{label}</Typography>
    </div>
    <div className={classes.item}>
      {loading ? (
        <div
          style={{
            width: '40px!important',
            height: 27,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress size={20} />
        </div>
      ) : (
        <>
          <Typography
            variant='subtitleBig'
            style={{ marginRight: 5, fontWeight: 700 }}>
            {count}
          </Typography>
          <Typography variant='body'>
            {global._('Common.QuantityPieces')}
          </Typography>
        </>
      )}
    </div>
  </div>
)

SummaryBadge.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
  onClick: PropTypes.func,
  loading: PropTypes.bool
}

SummaryBadge.defaultProps = {
  onClick: null,
  loading: false,
  count: undefined
}

const SummaryBadgeWithStyles = injectSheet(styles)(SummaryBadge)
export default SummaryBadgeWithStyles
