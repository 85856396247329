import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Grid, Icon, TextField } from '@material-ui/core'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import Notification from 'src/common/Notification'
import MobileContainer from 'src/components/MobileContainer'
import NavigationBar from 'src/components/NavigationBar'
import ScrollView from 'src/components/ScrollView'
import ObjectListMobile from 'src/components/ObjectListMobile'
import FullscreenModal from 'src/components/FullscreenModal'
import KeysNotice from 'src/components/Keys/KeysNotice'
import Alert from 'src/components/Alert'
import NavigationList from 'src/components/NavigationList'
import CheckboxList from 'src/components/CheckboxList'
import SuccessMessage from 'src/components/SuccessMessage'
import { parseReceiptTermsContent } from 'src/utils/helpers'
import { OrganisationTypes, ProductKeys, UserRoles } from 'src/utils/constants'
import axios from 'axios'

const styles = {
  root: {},
  section: {
    padding: 'var(--section-padding-default)',
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--divider-color)'
    }
  },
  sectionWrapper: {
    maxWidth: 500
  }
}

const MyKeysMobile = ({
  classes,
  history,
  user,
  error,
  setError,
  setIsLoading,
  keys,
  filterFields,
  updateKey,
  unsignedReceipts,
  unverifiedKeysPerOrganisation,
  verifiedKeys,
  selectedRequestKeys,
  setSelectedRequestKeys,
  requestKeys,
  setRequestKeys,
  showRequestModal,
  setShowRequestModal,
  receiptTerms,
  setReceiptTerms,
  keyIds,
  resolveSelectedRequestKeys,
  myKeysTitle,
  keyLimitReached,
  expectedReturnDateBadge,
  userHasAccessToKeyOrders,
  keyCopyOnCallModalContent,
  setShowKeyCopyOnCallModal,
  showKeyCopyOnCallModal,
  activeProduct,
  hasActivePaymentMethod
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [showKeyModal, setShowKeyModal] = useState(false)
  const [showKeyNameModal, setShowKeyNameModal] = useState(false)
  const [showReceiptTermsModal, setShowReceiptTermsModal] = useState(false)
  const [openKey, setOpenKey] = useState(false)
  const [nameFieldVal, setNameFieldVal] = useState('')
  const [isInventory, setIsInventory] = useState(false)
  const [openKeyProducts, setOpenKeyProducts] = useState(null)

  const nameStr = obj =>
    `${obj.personalName || obj.name || global._('Common.NoName')}`

  const getProducts = async () => {
    try {
      const partnerId =
        user.organisation.type === OrganisationTypes.Partner
          ? user.organisation.id
          : user.organisation.partnerId
      const { data: products } = await axios.get(
        `/v1/organisations/${partnerId}/products?variant=${openKey.type}`
      )

      setOpenKeyProducts(products)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (openKey) {
      getProducts()
    }
  }, [openKey])

  const columns = [
    {
      key: 'icon',
      format: () => <Icon style={{ color: 'var(--color-black)' }}>vpn_key</Icon>
    },
    {
      key: 'name',
      secondKey: 'marking',
      bold: true,
      format: (name, marking, obj) =>
        obj && (
          <>
            <Typography
              variant='body'
              ellipsis
              block
              dangerouslySetInnerHTML={{ __html: nameStr(obj) }}
            />
            {marking && (
              <Typography
                variant='body'
                faded
                block
                fontNormal
                dangerouslySetInnerHTML={{ __html: marking }}
                style={{
                  fontSize: 14,
                  lineHeight: 1.5,
                  display: 'inline-block',
                  marginBottom: 3
                }}
              />
            )}
          </>
        )
    }
  ]

  const supportListItem = (productKey, enabled, onClick) =>
    console.log('enabled', enabled) || {
      title: global._(
        `Keys.Order.Details.NavBarTitles.${productKey}.${openKey.type}`
      ),
      icon: 'shopping_cart',
      textColor: enabled ? 'var(--color-black)' : 'var(--color-middle-grey)',
      iconColor: enabled ? 'var(--color-black)' : 'var(--color-middle-grey)',
      smallerPadding: true,
      onClick:
        (enabled && onClick) ||
        (() =>
          enabled &&
          history.push(
            `/orders/new/details?keyId=${openKey.id}&product=${productKey}&variant=${openKey.type}`
          ))
    }

  const supportListItems = [
    supportListItem(
      ProductKeys.KEY_COPY,
      userHasAccessToKeyOrders &&
        activeProduct(openKey, ProductKeys.KEY_COPY) &&
        hasActivePaymentMethod &&
        !keyLimitReached
    ),
    supportListItem(
      ProductKeys.KEY_REPLACEMENT,
      userHasAccessToKeyOrders &&
        activeProduct(openKey, ProductKeys.KEY_REPLACEMENT) &&
        hasActivePaymentMethod &&
        !keyLimitReached
    ),
    supportListItem(
      ProductKeys.KEY_COPY_ON_CALL,
      userHasAccessToKeyOrders &&
        activeProduct(openKey, ProductKeys.KEY_COPY_ON_CALL),
      () => setShowKeyCopyOnCallModal(openKey)
    )
  ]

  const keyDataRow = (title, info) => (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant='body' faded>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='body' bold>
          {info}
        </Typography>
      </Grid>
    </Grid>
  )

  useEffect(() => {
    if (openKey) {
      setNameFieldVal(openKey.personalName || openKey.name)
    }
  }, [openKey])

  return (
    <MobileContainer background='var(--color-white)'>
      {showFilterModal && (
        <FullscreenModal
          title={global._('Common.Filter')}
          onClose={() => setShowFilterModal(false)}
          rightActionItem='close'
          content={
            <MobileContainer>
              <ScrollView style={{ padding: 'var(--section-padding-default)' }}>
                {filterFields()}
              </ScrollView>
            </MobileContainer>
          }
        />
      )}
      {showKeyModal && openKey && (
        <FullscreenModal
          title={nameStr(openKey)}
          onClose={() => setShowKeyModal(false)}
          rightActionItem={
            <div
              role='button'
              tabIndex='0'
              onClick={() => setShowKeyNameModal(true)}
            >
              <Icon>edit</Icon>
            </div>
          }
          higherZIndex
          content={
            <MobileContainer background='var(--color-white)'>
              <ScrollView>
                {showKeyCopyOnCallModal && (
                  <FullscreenModal
                    title={global._(
                      `Keys.Order.Details.NavBarTitles.${ProductKeys.KEY_COPY_ON_CALL}.${showKeyCopyOnCallModal.type}`
                    )}
                    onClose={() => setShowKeyCopyOnCallModal(false)}
                    rightActionItem='close'
                    higherZIndex
                    content={
                      <MobileContainer>
                        <ScrollView
                          style={{ padding: 'var(--section-padding-default)' }}
                        >
                          {keyCopyOnCallModalContent(showKeyCopyOnCallModal)}
                        </ScrollView>
                      </MobileContainer>
                    }
                  />
                )}
                <div className={classes.section}>
                  <div className={classes.sectionWrapper}>
                    {keyDataRow(global._('MyKeys.Marking'), openKey.marking)}
                    {keyDataRow(
                      global._('MyKeys.KeyRing'),
                      (openKey.keyRing && openKey.keyRing.name) || '-'
                    )}
                    {keyDataRow(
                      global._('MyKeys.ExpectedReturnDate'),
                      expectedReturnDateBadge(openKey.expectedReturnDate)
                    )}
                    {keyDataRow(
                      global._('MyKeys.Owner'),
                      (openKey.organisation && openKey.organisation.name) ||
                        openKey.organisationName
                    )}
                  </div>
                </div>
                {user.roles.includes(UserRoles.PartnerAdmin) &&
                user.organisation.type ===
                  OrganisationTypes.Partner ? undefined : (
                  <>
                    <div className={classes.section}>
                      <Typography variant='title'>
                        {global._('SUPPORT')}
                      </Typography>
                    </div>
                    {keyLimitReached && (
                      <Notification
                        type='information'
                        message={global._('Keys.KeyLimitReached')}
                      />
                    )}
                    <div style={{ paddingBottom: 100 }}>
                      <NavigationList itemList={supportListItems} />
                    </div>
                  </>
                )}
              </ScrollView>
            </MobileContainer>
          }
        />
      )}
      {showKeyNameModal && openKey && (
        <FullscreenModal
          title={global._('Common.ChangeName')}
          onClose={() => setShowKeyNameModal(false)}
          rightActionItem='close'
          higherZIndex
          content={
            <ScrollView style={{ padding: 'var(--section-padding-default)' }}>
              <TextField
                label={global._('MyKeys.PersonalName')}
                fullWidth
                onChange={e => setNameFieldVal(e.target.value)}
                helperText={global._('MyKeys.OnlyShownForYou')}
                value={nameFieldVal}
                name='name'
                variant='filled'
              />
            </ScrollView>
          }
          buttons={
            <Button
              variant='primary'
              onClick={() => {
                updateKey(openKey.id, { personalName: nameFieldVal })
                setOpenKey(prevKey => {
                  const prevKeyObj = { ...prevKey }
                  prevKeyObj.personalName = nameFieldVal

                  return prevKeyObj
                })
                setShowKeyNameModal(false)
              }}
            >
              {global._('Common.SaveAndClose')}
            </Button>
          }
        />
      )}
      {showRequestModal && (
        <FullscreenModal
          title={global._(
            isInventory
              ? 'MyKeys.KeyInventory.KeyVerification'
              : 'MyKeys.Request.KeySign'
          )}
          onClose={() => setShowRequestModal(false)}
          rightActionItem='close'
          higherZIndex
          content={
            <ScrollView>
              {!isInventory && (
                <NavigationList
                  itemList={[
                    {
                      visible: true,
                      title: global._('Common.ShowTerms'),
                      icon: 'assignment',
                      darkIcon: true,
                      fullBorder: true,
                      smallerPadding: true,
                      onClick: () => setShowReceiptTermsModal(true)
                    }
                  ]}
                />
              )}
              <div>
                <Typography
                  variant='body'
                  bold
                  block
                  style={{
                    padding: 20,
                    borderBottom: '1px solid var(--color-divider-light)'
                  }}
                >
                  {global._(
                    isInventory
                      ? 'MyKeys.KeyInventory.SelectKeysText'
                      : 'MyKeys.Request.SelectKeysText'
                  )}
                </Typography>
              </div>
              <div
                style={{
                  borderBottom: '1px solid var(--color-divider-light)',
                  marginBottom: 50
                }}
              >
                {requestKeys && requestKeys.length > 0 && (
                  <CheckboxList
                    checkboxNameKey='id'
                    objects={requestKeys}
                    columns={[
                      {
                        key: 'title',
                        format: obj => (
                          <>
                            <Typography variant='body' bold>
                              {nameStr(obj)}
                            </Typography>
                          </>
                        )
                      },
                      {
                        key: 'marking',
                        format: obj => {
                          let requestOwnership = false
                          let receiptTemplate
                          if (!obj.userId && obj.keyInventoryApprovals) {
                            for (const approval of obj.keyInventoryApprovals) {
                              if (
                                approval.keyInventory.receiptTemplateId &&
                                !obj.userId
                              ) {
                                requestOwnership = true
                                receiptTemplate =
                                  approval.keyInventory.keyReceiptTemplate
                              }
                            }
                          }
                          return (
                            <Typography variant='body2' faded>
                              {obj.marking}
                              {requestOwnership &&
                                ` - ${global._('MyKeys.Request.KeySign')} - `}
                              {requestOwnership && (
                                // eslint-disable-next-line
                                <a
                                  style={{
                                    textDecoration: 'underline',
                                    color: '#000'
                                  }}
                                  href='#'
                                  onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setReceiptTerms(receiptTemplate.content)
                                    setShowReceiptTermsModal(true)
                                  }}
                                >
                                  {global._('Common.ShowTerms')}
                                </a>
                              )}
                            </Typography>
                          )
                        }
                      }
                    ]}
                    selectedOptions={selectedRequestKeys}
                    setSelectedOptions={setSelectedRequestKeys}
                  />
                )}
              </div>
            </ScrollView>
          }
          buttons={
            <Button
              variant='bankid'
              disabled={
                (!isInventory && !keyIds) ||
                (isInventory && selectedRequestKeys.length === 0)
              }
              onClick={() => {
                let url

                if (isInventory) {
                  let keyInventoryApprovalIds = ''
                  keyInventoryApprovalIds = resolveSelectedRequestKeys()
                  url = `/my-keys/inventoryapproval-request?ids=${keyInventoryApprovalIds}`
                } else {
                  url = `/my-keys/receipt-request?ids=${keyIds}`
                }

                history.push(url)
              }}
            >
              {global._('Common.Sign')}
            </Button>
          }
        />
      )}
      {showReceiptTermsModal && (
        <FullscreenModal
          title={global._('Common.Terms')}
          onClose={() => setShowReceiptTermsModal(false)}
          rightActionItem='close'
          higherZIndex
          content={
            <MobileContainer>
              <ScrollView style={{ padding: 'var(--section-padding-default)' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: parseReceiptTermsContent(receiptTerms)
                  }}
                />
              </ScrollView>
            </MobileContainer>
          }
        />
      )}
      <NavigationBar
        title={myKeysTitle}
        backAction={() => history.push('/dashboard')}
        rightActionItem={
          <div
            onClick={() => setShowFilterModal(true)}
            style={{ color: 'var(--color-primary)' }}
          >
            <Icon>filter_list</Icon>
          </div>
        }
      />
      <ScrollView id='scrollable-container'>
        <div>
          {error && (
            <Notification
              type='error'
              message={error}
              style={{ margin: '20px 0' }}
            />
          )}
          <SuccessMessage
            subtitle={global._('MyKeys.Request.SuccessfullRequest')}
            localStorageProp='keyReceiptRequestedSuccessfully'
          />
          <SuccessMessage
            subtitle={global._('MyKeys.KeyInventory.SuccessfulRequest')}
            localStorageProp='keyInventoryApprovalRequestedSuccessfully'
          />
          <SuccessMessage
            subtitle={global._('MyKeys.KeyInventory.SuccessfulRequests')}
            localStorageProp='keyInventoryApprovalsRequestedSuccessfully'
          />
          {unsignedReceipts &&
            unsignedReceipts.length > 0 &&
            unsignedReceipts.map((receipts, index) => (
              <div
                style={{
                  padding: '10px 10px 0',
                  background: 'var(--color-material-grey)',
                  display: 'inline-block',
                  width: '100%',
                  paddingTop: index === 0 ? 10 : 0
                }}
              >
                <KeysNotice
                  icon='vpn_key'
                  title={global._('MyKeys.HasReceiptRequest')}
                  key={`receipts-${receipts[0].id}`}
                  user={user}
                  history={history}
                  actionButtons={
                    user &&
                    user.personalNumber && (
                      <Button
                        style={{ marginBottom: 10 }}
                        variant='bankid'
                        onClick={() => {
                          setIsInventory(false)
                          setRequestKeys(receipts.map(r => r.key))
                          setReceiptTerms(receipts[0].content)
                          setShowRequestModal(true)
                        }}
                      >
                        {global._('MyKeys.HasReceiptRequestButton')}
                      </Button>
                    )
                  }
                >
                  {user && !user.personalNumber && (
                    <Alert type='info'>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <span>
                          {global._('MyKeys.PersonalNumberAlertInfo')}
                        </span>
                        <Button
                          variant='secondary'
                          style={{ whiteSpace: 'nowrap', marginLeft: 20 }}
                          onClick={() => history.push('/myaccount')}
                        >
                          {global._('MyKeys.AddPersonalNumber')}
                        </Button>
                      </div>
                    </Alert>
                  )}
                </KeysNotice>
              </div>
            ))}
          {Object.keys(unverifiedKeysPerOrganisation).length === 0 &&
            verifiedKeys.length > 0 && (
              <div
                style={{
                  padding: '10px 10px 0',
                  background: 'var(--color-material-grey)',
                  display: 'inline-block',
                  width: '100%',
                  paddingTop: 10
                }}
              >
                <KeysNotice
                  icon='done'
                  subtitle={global._('MyKeys.KeyInventoryCompleteTitle')}
                  title={global._('MyKeys.KeyInventoryCompleteSubtitle')}
                  user={user}
                  history={history}
                />
              </div>
            )}
          {Object.values(unverifiedKeysPerOrganisation).map(unverifiedKeys => (
            <div
              style={{
                padding: '10px 10px 0',
                background: 'var(--color-material-grey)',
                display: 'inline-block',
                width: '100%',
                paddingTop: 10
              }}
            >
              <KeysNotice
                icon='beenhere'
                title={global._('MyKeys.KeyInventoryTime')}
                key={`unverified-keys-${unverifiedKeys[0].organisation.id}`}
                actionButtons={
                  user &&
                  user.personalNumber && (
                    <Button
                      style={{ marginBottom: 10 }}
                      variant='bankid'
                      onClick={() => {
                        setIsInventory(true)
                        setRequestKeys(unverifiedKeys)
                        setShowRequestModal(true)
                      }}
                    >
                      {global._('MyKeys.KeyInventoryButton')}
                    </Button>
                  )
                }
              >
                {user && !user.personalNumber && (
                  <Alert type='info'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      <span>{global._('MyKeys.PersonalNumberAlertInfo')}</span>
                      <Button
                        variant='secondary'
                        style={{ whiteSpace: 'nowrap', marginLeft: 20 }}
                        onClick={() => history.push('/myaccount')}
                      >
                        {global._('MyKeys.AddPersonalNumber')}
                      </Button>
                    </div>
                  </Alert>
                )}
              </KeysNotice>
            </div>
          ))}
          <ObjectListMobile
            objects={keys}
            user={user}
            history={history}
            rowOnClick={obj => {
              setOpenKey(obj)
              setNameFieldVal('')
              setShowKeyModal(true)
            }}
            rowsAreClickable={false}
            noResultsMessage={global._('MyKeys.NoKeysFound')}
            columns={columns}
          />
        </div>
      </ScrollView>
    </MobileContainer>
  )
}

MyKeysMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  keys: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  filterFields: PropTypes.func,
  updateKey: PropTypes.func,
  unsignedReceipts: PropTypes.array,
  unverifiedKeysPerOrganisation: PropTypes.array,
  verifiedKeys: PropTypes.array,
  selectedRequestKeys: PropTypes.array,
  setSelectedRequestKeys: PropTypes.func.isRequired,
  requestKeys: PropTypes.array,
  setRequestKeys: PropTypes.func.isRequired,
  showRequestModal: PropTypes.bool,
  setShowRequestModal: PropTypes.func.isRequired,
  receiptTerms: PropTypes.string,
  setReceiptTerms: PropTypes.func.isRequired,
  keyIds: PropTypes.array,
  resolveSelectedRequestKeys: PropTypes.func,
  myKeysTitle: PropTypes.string,
  keyLimitReached: PropTypes.bool,
  expectedReturnDateBadge: PropTypes.func.isRequired,
  activeProduct: PropTypes.func.isRequired,
  setShowKeyCopyOnCallModal: PropTypes.func.isRequired,
  keyCopyOnCallModalContent: PropTypes.any.isRequired,
  showKeyCopyOnCallModal: PropTypes.any.isRequired
}

MyKeysMobile.defaultProps = {
  user: null,
  error: null,
  keys: null,
  filterFields: null,
  updateKey: null,
  selectedRequestKeys: [],
  unsignedReceipts: [],
  unverifiedKeysPerOrganisation: {},
  verifiedKeys: [],
  requestKeys: null,
  showRequestModal: null,
  keyIds: null,
  receiptTerms: null,
  resolveSelectedRequestKeys: null,
  myKeysTitle: null,
  keyLimitReached: false
}

const MyKeysMobileWithStyles = injectSheet(styles)(MyKeysMobile)
export default MyKeysMobileWithStyles
