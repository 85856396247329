import axios from 'axios'
import FullscreenModal from 'src/components/FullscreenModal'
import Button from 'src/components/Button'
import Typography from 'src/components/Typography'
import Notification from 'src/common/Notification'
import React, { useState, useEffect } from 'react'
import injectSheet from 'react-jss'
import withUser from 'src/utils/withUser'
import { withRouter } from 'react-router'
import NavigationList from 'src/components/NavigationList'
import ConfirmationModal from 'src/components/ConfirmationModal'
import SelectLocks from './SelectLocks'
import SelectAccessTime from './SelectAccessTime'
import SelectRecipients from './SelectRecipient'

const styles = {
  root: {}
}

const Steps = {
  MENU: 'MENU',
  SELECT_LOCKS: 'SELECT_LOCKS',
  SELECT_ACCESS_TIME: 'SELECT_ACCESS_TIME',
  SELECT_DETAILS: 'SELECT_DETAILS',
  COMPLETE: 'COMPLETE'
}
const DelegatedAccessDetails = props => {
  const { delegatedAccessId } = props.match.params

  const [step, setStep] = useState(Steps.MENU)
  const [isLoading, setIsLoading] = useState(false)
  const [isResendingShareLink, setIsResendingShareLink] = useState(false)
  const [hasResentShareLink, setHasResentShareLink] = useState(false)
  const [error, setError] = useState(null)
  const [detailedError, setDetailedError] = useState(null)
  const [delegatedAccess, setDelegatedAccess] = useState(null)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [locks, setLocks] = useState([])
  const [accessTime, setAccessTime] = useState({
    unlimited: false,
    disabled: false,
    from: null,
    to: null
  })
  const [details, setDetails] = useState({
    name: '',
    phone: ''
  })

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(
          `/v1/locks/access/${delegatedAccessId}`
        )
        setDelegatedAccess(data)
        let phone = ''
        if (data.delegatedaccessuser) {
          ;({ phone } = data.delegatedaccessuser)
        }
        setDetails({
          name: data.name,
          phone
        })
        setAccessTime({
          unlimited: data.unlimited,
          disabled: data.disabled,
          from: data.from,
          to: data.to
        })
        setIsLoading(false)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    })()
  }, [])

  const resendShareLink = async () => {
    setDetailedError(null)
    setIsResendingShareLink(true)
    try {
      await axios.post(`/v1/locks/access/${delegatedAccess.id}/resend`)
      setHasResentShareLink(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setDetailedError(global._(msg))
    }
    setIsResendingShareLink(false)
  }

  const handleDelete = async () => {
    setError(null)
    try {
      await axios.delete(`/v1/locks/access/${delegatedAccess.id}`)
      props.history.push('/mylocks#shared')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleUpdate = async data => {
    setDetailedError(null)
    try {
      await axios.patch(`/v1/locks/access/${delegatedAccess.id}`, data)
      if (!data.lockIds) {
        setDelegatedAccess({
          ...delegatedAccess,
          ...data
        })
      }
      return true
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setDetailedError(global._(msg))
      return false
    }
  }
  const handleLockSelection = async selectedLocks => {
    let result = true
    if (
      delegatedAccess &&
      delegatedAccess.locks.length !== selectedLocks.length
    ) {
      result = await handleUpdate({
        lockIds: selectedLocks.map(lock => lock.id)
      })
      if (result) {
        setDelegatedAccess({
          ...delegatedAccess,
          locks: selectedLocks
        })
      }
    }
    if (result) {
      setLocks(selectedLocks)
    }
  }

  const handleAccessTimeChange = (key, value) => {
    if (value !== accessTime[key]) {
      setAccessTime({
        ...accessTime,
        [key]: value
      })

      handleUpdate({ [key]: value })
    }
  }

  const handleDetailsChange = async (key, value) => {
    setDetailedError(null)
    if (key === 'phone' && value !== details[key]) {
      setHasResentShareLink(false)
    }
    let result = true
    if (value !== details[key]) {
      result = await handleUpdate({ [key]: value })
      if (result) {
        setDetails({
          ...details,
          [key]: value
        })
      }
    }
    return result
  }

  let title
  let buttonDirection = 'row'
  if (step === Steps.MENU) {
    title = delegatedAccess ? delegatedAccess.name : '...'
  } else if (step === Steps.SELECT_LOCKS) {
    title = global._('Common.Locks')
  } else if (step === Steps.SELECT_ACCESS_TIME) {
    buttonDirection = 'column-reverse'
    title = global._('Locks.Share.Access')
  } else if (step === Steps.SELECT_DETAILS) {
    buttonDirection = 'column-reverse'
    title = global._('Locks.Share.Details')
  }

  const navigationItems = [
    {
      name: 'details',
      title: global._('Locks.Share.Details'),
      icon: 'info',
      darkIcon: true,
      onClick: () => setStep(Steps.SELECT_DETAILS)
    },
    {
      name: 'access',
      title: global._('Locks.Share.Access'),
      icon: 'access_time',
      darkIcon: true,
      onClick: () => setStep(Steps.SELECT_ACCESS_TIME)
    },
    {
      name: 'locks',
      title: global._('Common.Locks'),
      icon: 'lock',
      darkIcon: true,
      onClick: () => setStep(Steps.SELECT_LOCKS)
    }
  ]

  let preselectedLockIds = []
  if (delegatedAccess) {
    preselectedLockIds = delegatedAccess.locks.map(lock => lock.id)
  }
  return (
    <FullscreenModal
      title={title}
      withTabBar
      onClose={() => {
        if (step === Steps.MENU) {
          props.history.goBack()
        } else {
          setStep(Steps.MENU)
        }
      }}
      higherZIndex
      content={
        <>
          {error && <Notification type='error' message={error} />}
          {step === Steps.MENU && (
            <NavigationList
              itemList={navigationItems}
              skeletonize={isLoading}
            />
          )}
          {step === Steps.SELECT_LOCKS && (
            <SelectLocks
              selectOnLoad={preselectedLockIds}
              data={locks}
              onChange={selectedLocks => handleLockSelection(selectedLocks)}
              fullHeightScrollView
            />
          )}
          {step === Steps.SELECT_ACCESS_TIME && (
            <SelectAccessTime
              data={accessTime}
              onChange={(key, value) => handleAccessTimeChange(key, value)}
            />
          )}
          {step === Steps.SELECT_DETAILS && (
            <SelectRecipients
              editMode
              data={details}
              onChange={(key, value) => handleDetailsChange(key, value)}
            />
          )}
          {showDeleteConfirmation && (
            <ConfirmationModal
              onClose={() => setShowDeleteConfirmation(false)}
              onConfirm={handleDelete}
              message={global._('Locks.Share.DeleteConfirmationMessage')}
            />
          )}
        </>
      }
      buttonDirection={buttonDirection}
      boxShadow={step === Steps.MENU ? 'none' : undefined}
      buttons={
        step === Steps.SELECT_LOCKS ? undefined : (
          <>
            {step === Steps.MENU && (
              <Button
                variant='secondary'
                onClick={() => setShowDeleteConfirmation(true)}
              >
                {global._('Common.Delete')}
              </Button>
            )}
            {step === Steps.SELECT_DETAILS && (
              <>
                {hasResentShareLink && (
                  <Typography variant='body'>
                    {global._('Locks.Share.ShareLinkResent')}
                  </Typography>
                )}
                {!hasResentShareLink && (
                  <Button
                    variant='primary'
                    onClick={resendShareLink}
                    disabled={isResendingShareLink}
                  >
                    {isResendingShareLink
                      ? global._('Common.Sending')
                      : global._('Locks.Share.ResendLink')}
                  </Button>
                )}
              </>
            )}
            {step === Steps.SELECT_ACCESS_TIME && (
              <Button
                variant={accessTime.disabled ? 'primary' : 'secondary'}
                onClick={() =>
                  handleAccessTimeChange('disabled', !accessTime.disabled)
                }
              >
                {accessTime.disabled
                  ? global._('Locks.Share.EnableAccess')
                  : global._('Locks.Share.DisableAccess')}
              </Button>
            )}
            {step !== Steps.MENU && detailedError && (
              <Notification
                style={{ marginBottom: 20 }}
                type='error'
                message={detailedError}
              />
            )}
          </>
        )
      }
    />
  )
}

export default withRouter(withUser(injectSheet(styles)(DelegatedAccessDetails)))
