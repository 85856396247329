import React, { useState } from 'react'
import injectSheet from 'react-jss'
import { Checkbox, Grid, TextField } from '@material-ui/core'
import Row from 'src/components/Row'
import { AccessTime, ArrowForward, Today } from '@material-ui/icons'
import TimezoneSelect from 'react-timezone-select'
import useWindowDimensions from '../utils/useWindowDimensions'
import Typography from './Typography'
import Button from './Button'
import Paper from './Paper'

const styles = {
  section: {
    width: 'calc(100% + 40px)',
    padding: '20px 20px',
    borderBottom: '1px solid var(--color-divider-light)',
    marginLeft: '-20px',
    marginRight: '-20px',
    '&:first-child': {
      paddingTop: 10,
      paddingBottom: 30
    },
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  mobileContent: {},
  '@media (max-width: 900px)': {
    mobileContent: {
      backgroundColor: 'var(--color-white)',
      padding: '10px 20px'
    }
  }
}
const minutesToHHMM = minutesOfDay => {
  const totalHours = minutesOfDay / 60
  const hour = Math.floor(totalHours)
  const minute = Math.round((totalHours - hour) * 60)

  return `${hour.toString().padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')}`
}

const HHMMToMinutes = hhmm => {
  if (hhmm.includes(':')) {
    const [hour, minute] = hhmm.split(':')
    const nHour = parseInt(hour, 10)
    const nMinute = parseInt(minute, 10)
    return nHour * 60 + nMinute
  }
  return hhmm
}

const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const Wrapper = props => {
  if (props.isMobile) return <div {...props} />
  return <Paper {...props} />
}

const Scheduler = props => {
  const { classes, onSave, schedule, ...otherProps } = props
  const { isMobile } = useWindowDimensions()
  const [isActive, setIsActive] = useState(schedule?.active || false)
  const [timezone, setTimezone] = useState(
    schedule?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [errors, setErrors] = useState({
    monday: {
      from: false,
      to: false
    },
    tuesday: {
      from: false,
      to: false
    },
    wednesday: {
      from: false,
      to: false
    },
    thursday: {
      from: false,
      to: false
    },
    friday: {
      from: false,
      to: false
    },
    saturday: {
      from: false,
      to: false
    },
    sunday: {
      from: false,
      to: false
    }
  })

  let defaultData
  if (schedule) {
    defaultData = {}
    daysOfWeek.forEach(day => {
      defaultData[day] = {
        from: minutesToHHMM(schedule[`${day}From`]),
        to: minutesToHHMM(schedule[`${day}To`]),
        noAccess: schedule[`${day}NoAccess`]
      }
    })
  } else {
    defaultData = {
      monday: {
        from: minutesToHHMM(0),
        to: minutesToHHMM(60 * 24 - 1),
        noAccess: false
      },
      tuesday: {
        from: minutesToHHMM(0),
        to: minutesToHHMM(60 * 24 - 1),
        noAccess: false
      },
      wednesday: {
        from: minutesToHHMM(0),
        to: minutesToHHMM(60 * 24 - 1),
        noAccess: false
      },
      thursday: {
        from: minutesToHHMM(0),
        to: minutesToHHMM(60 * 24 - 1),
        noAccess: false
      },
      friday: {
        from: minutesToHHMM(0),
        to: minutesToHHMM(60 * 24 - 1),
        noAccess: false
      },
      saturday: {
        from: minutesToHHMM(0),
        to: minutesToHHMM(60 * 24 - 1),
        noAccess: false
      },
      sunday: {
        from: minutesToHHMM(0),
        to: minutesToHHMM(60 * 24 - 1),
        noAccess: false
      }
    }
  }
  const [data, setData] = useState(defaultData)
  let isValid = true
  Object.keys(errors).forEach(day => {
    if (errors[day].from || errors[day].to) {
      isValid = false
    }
  })

  const onChange = (day, key) => evt => {
    const { value } = evt.target
    const valid = key !== 'noAccess' && /^\d{2}:\d{2}$/.test(value)

    const _data = {
      ...data,
      [day]: {
        ...data[day],
        [key]: value
      }
    }
    setData(_data)
    setErrors({
      ...errors,
      [day]: {
        ...errors[day],
        [key]: !valid
      }
    })
  }

  const handleSave = () => {
    const formatted = {}
    Object.keys(data).forEach(day => {
      const fromKey = `${day}From`
      const toKey = `${day}To`
      const noAccessKey = `${day}NoAccess`
      formatted[fromKey] = HHMMToMinutes(data[day].from)
      formatted[toKey] = HHMMToMinutes(data[day].to)
      formatted[noAccessKey] = data[day].noAccess
    })

    formatted.active = isActive
    formatted.timezone = timezone

    onSave(formatted)
  }

  return (
    <Wrapper
      isMobile={isMobile}
      smallerPadding={isMobile ? undefined : true}
      alignItems={isMobile ? undefined : 'left'}
      className={isMobile ? classes.mobileContent : undefined}
      style={{ marginTop: isMobile ? 0 : 24 }}
    >
      <div className={classes.section}>
        <Row justify='flex-start'>
          {!isMobile && (
            <Typography variant='subtitleBig' bold>
              {global._('AccessGroups.Schedule')}
            </Typography>
          )}
          <Row
            align='center'
            style={{
              margin: isMobile ? '12px 0' : '0px',
              marginLeft: 'auto'
            }}
          >
            <Checkbox
              checked={isActive}
              onClick={() => setIsActive(!isActive)}
              style={{
                transform: isMobile ? 'scale(0.7)' : undefined,
                padding: 0
              }}
            />
            <Typography
              bold
              variant='body'
              style={{ marginLeft: 8, marginRight: 48 }}
            >
              {global._('Common.Activated')}
            </Typography>
          </Row>
          <Button
            style={{ maxWidth: 140, marginLeft: isMobile ? 'auto' : null }}
            minWidth
            variant='primary'
            disabled={!isValid}
            onClick={handleSave}
          >
            {global._('Common.Save')}
          </Button>
        </Row>
      </div>
      <div
        className={classes.section}
        style={{ opacity: isActive ? 1 : '0.5' }}
      >
        <Grid container direction='row' spacing={2}>
          <Grid item xs={12} style={{ zIndex: 99 }}>
            <div
              style={{
                margin: -22,
                marginBottom: 0,
                padding: 24,
                paddingBottom: 32,
                borderBottom: '1px solid #ccc'
              }}
            >
              <Typography variant='subtitle'>Tidszon</Typography>
              <TimezoneSelect
                value={timezone}
                onChange={_timezone => {
                  setTimezone(_timezone.value)
                }}
                isDisabled={!isActive}
              />
            </div>
          </Grid>
          {[
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday'
          ].map(day => {
            const title = '123'
            const { noAccess, from, to } = data[day]

            if (isMobile) {
              return (
                <Grid
                  item
                  xs={12}
                  key={day}
                  style={{
                    borderBottom: '1px solid #ccc',
                    marginBottom: 12,
                    paddingBottom: 22
                  }}
                >
                  <Row style={{ marginBottom: 16 }}>
                    <Today style={{ fontSize: 32, marginRight: 16 }} />
                    <Typography variant='subtitle' style={{ width: 80 }}>
                      {global._(`Common.${day}`)}
                    </Typography>
                    <Row
                      align='center'
                      style={{
                        margin: '0px',
                        marginLeft: 16
                      }}
                    >
                      <Checkbox
                        checked={noAccess}
                        disabled={!isActive}
                        onClick={() =>
                          onChange(
                            day,
                            'noAccess'
                          )({ target: { value: !noAccess } })
                        }
                        style={{
                          transform: isMobile ? 'scale(0.7)' : undefined,
                          padding: 0
                        }}
                      />
                      <Typography
                        bold
                        variant='body'
                        style={{ marginLeft: 8, marginRight: 4 }}
                      >
                        {global._('AccessGroups.NoAccess')}
                      </Typography>
                    </Row>
                  </Row>
                  <Row>
                    <AccessTime
                      style={{
                        fontSize: 32,
                        marginRight: 16,
                        marginTop: -16,
                        opacity: noAccess ? '0.5' : 1
                      }}
                    />
                    <TextField
                      disabled={!isActive || noAccess}
                      error={errors[day].from}
                      style={{
                        width: 155,
                        opacity: noAccess ? '0.5' : 1
                      }}
                      variant='filled'
                      label={global._('Common.From')}
                      value={from}
                      helperText={global._('Common.RequiredTime')}
                      onChange={onChange(day, 'from')}
                    />
                    <ArrowForward
                      style={{
                        fontSize: 32,
                        margin: '-16px 8px 0',
                        opacity: noAccess ? '0.5' : 1
                      }}
                    />
                    <TextField
                      disabled={!isActive || noAccess}
                      error={errors[day].to}
                      style={{
                        width: 155,
                        opacity: noAccess ? '0.5' : 1
                      }}
                      variant='filled'
                      label={global._('Common.To')}
                      value={to}
                      helperText={global._('Common.RequiredTime')}
                      onChange={onChange(day, 'to')}
                    />
                  </Row>
                </Grid>
              )
            }
            return (
              <Grid item xs={12} key={day}>
                <Row
                  justifyContent={isMobile ? 'flex-start' : undefined}
                  alignItems={isMobile ? 'flex-start' : undefined}
                  style={{
                    borderBottom: '1px solid #ccc',
                    padding: isMobile ? 'none' : '6px 20px 20px 20px',
                    margin: '0 -20px'
                  }}
                >
                  <Today
                    style={{ fontSize: 32, marginRight: 16, marginTop: -16 }}
                  />
                  <Typography
                    variant='subtitle'
                    style={{ width: 120, marginTop: -16 }}
                  >
                    {global._(`Common.${day}`)}
                  </Typography>
                  <AccessTime
                    style={{
                      fontSize: 32,
                      marginRight: 16,
                      marginTop: -16,
                      opacity: noAccess ? '0.5' : 1
                    }}
                  />
                  <TextField
                    disabled={!isActive || noAccess}
                    error={errors[day].from}
                    style={{
                      width: 155,
                      opacity: noAccess ? '0.5' : 1
                    }}
                    variant='filled'
                    label={global._('Common.From')}
                    value={from}
                    helperText={global._('Common.RequiredTime')}
                    onChange={onChange(day, 'from')}
                  />
                  <ArrowForward
                    style={{
                      fontSize: 32,
                      margin: '-16px 16px 0',
                      opacity: noAccess ? '0.5' : 1
                    }}
                  />
                  <TextField
                    disabled={!isActive || noAccess}
                    error={errors[day].to}
                    style={{
                      width: 155,
                      opacity: noAccess ? '0.5' : 1
                    }}
                    variant='filled'
                    label={global._('Common.To')}
                    value={to}
                    helperText={global._('Common.RequiredTime')}
                    onChange={onChange(day, 'to')}
                  />
                  <Row
                    align='center'
                    style={{
                      margin: isMobile ? '12px 0' : '0px',
                      marginLeft: 32
                    }}
                  >
                    <Checkbox
                      checked={noAccess}
                      disabled={!isActive}
                      onClick={() =>
                        onChange(
                          day,
                          'noAccess'
                        )({ target: { value: !noAccess } })
                      }
                      style={{
                        transform: isMobile ? 'scale(0.7)' : undefined,
                        padding: 0
                      }}
                    />
                    <Typography
                      bold
                      variant='body'
                      style={{ marginLeft: 8, marginRight: 20 }}
                    >
                      {global._('AccessGroups.NoAccess')}
                    </Typography>
                  </Row>
                </Row>
              </Grid>
            )
          })}
        </Grid>
        {isMobile && (
          <Button
            style={{ margin: '16px 0px 24px 0px' }}
            minWidth
            variant='primary'
            disabled={!isValid}
            onClick={handleSave}
          >
            {global._('Common.Save')}
          </Button>
        )}
      </div>
    </Wrapper>
  )
}

export default injectSheet(styles)(Scheduler)
