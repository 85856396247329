import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import * as Yup from 'yup'
import axios from 'axios'
import { Grid, TextField } from '@material-ui/core'
import { Form, Formik } from 'formik'
import Typography from '../../../components/Typography'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import Notification from '../../../common/Notification'
import { getSelectedOrg } from '../../../utils/helpers'

const styles = {
  root: {},
  input: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 20
    }
  }
}

const KeyRingDetails = props => {
  const {
    classes,
    setShowKeyRingModal,
    setEditKeyRingObj,
    keyRingObj,
    fetchKeyRings,
    currentOrg,
    isMobile,
    user
  } = props

  const [error, setError] = useState(null)

  const handleSaveOrAdd = async values => {
    const keyRing = { ...values }
    try {
      if (keyRingObj) {
        await axios.patch(
          `/v1/organisations/${currentOrg.id}/keyrings/${keyRingObj.id}`,
          keyRing
        )
        localStorage.setItem('keyRingEditedSuccessfully', true)
      } else {
        await axios.post(`/v1/organisations/${currentOrg.id}/keyrings`, keyRing)
        localStorage.setItem('keyRingAddedSuccessfully', true)
      }
      setEditKeyRingObj(null)
      fetchKeyRings()
      setShowKeyRingModal(false)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const formFields = (
    errors,
    touched,
    handleChange,
    values,
    handleBlur,
    setFieldValue
  ) => (
    <div style={{ textAlign: 'left' }}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '0 0 20px 0' }}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            value={values.name}
            name='name'
            fullWidth
            helperText={global._('Keys.KeyRings.HelpText.Name')}
            label={global._('Name')}
            variant='filled'
            error={errors.name}
            onChange={handleChange}
            onClick={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={values.notes}
            name='notes'
            fullWidth
            label={global._('Keys.KeyRings.Note')}
            multiline
            rows={4}
            variant='filled'
            error={errors.notes}
            onChange={handleChange}
            onClick={handleBlur}
          />
        </Grid>
      </Grid>

      <div style={{ marginTop: 20 }}>
        <Typography variant='body' italic>
          {global._('Keys.KeyRings.HelpText.Info')}
        </Typography>
      </div>
    </div>
  )

  return (
    <Formik
      initialValues={{
        organisationId: getSelectedOrg(user).id,
        name: keyRingObj && keyRingObj.name,
        notes: keyRingObj && keyRingObj.notes
      }}
      validationSchema={Yup.object().shape({
        organisationId: Yup.string().required(global._('Common.Required')),
        name: Yup.string().required(global._('Common.Required'))
      })}>
      {({
        errors,
        touched,
        handleChange,
        values,
        handleBlur,
        setFieldValue
      }) => (
        <Form>
          {
            // WIP
            // isMobile ? (
            //   <FullscreenModal
            //     title={global._(keyRingObj ? 'Keys.Repositories.Edit' : 'Common.Add')}
            //     onClose={() => setShowKeyRingModal(false)}
            //     rightActionItem='close'
            //     padding
            //     content={
            //       formFields(errors, touched, handleChange, values, handleBlur, setFieldValue)
            //     }
            //     buttons={(
            //       <Button
            //         variant='primary'
            //         disabled={Object.keys(touched).length === 0 || Object.keys(errors).length}
            //         onClick={() => {
            //           handleSaveOrAdd(values)
            //         }}
            //       >
            //         {global._(keyRingObj ? 'Common.Modify' : 'Common.Add')}
            //       </Button>
            //     )}
            //   />
            // ) : (
            <Modal
              onClose={() => setShowKeyRingModal(false)}
              title={global._(
                keyRingObj
                  ? 'Keys.New.KeyRing.ChangeRing'
                  : 'Keys.New.KeyRing.AddRing'
              )}
              content={formFields(
                errors,
                touched,
                handleChange,
                values,
                handleBlur,
                setFieldValue
              )}
              buttons={[
                <Button
                  variant='none'
                  onClick={() => setShowKeyRingModal(false)}>
                  {global._('Common.Cancel')}
                </Button>,
                <Button
                  variant='primary'
                  disabled={
                    !keyRingObj &&
                    (Object.keys(touched).length === 0 ||
                      Object.keys(errors).length)
                  }
                  onClick={() => {
                    handleSaveOrAdd(values)
                  }}>
                  {global._(keyRingObj ? 'Common.Modify' : 'Common.Add')}
                </Button>
              ]}
            />
            // )
          }
        </Form>
      )}
    </Formik>
  )
}

KeyRingDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  setShowKeyRingModal: PropTypes.func.isRequired,
  fetchKeyRings: PropTypes.func.isRequired,
  currentOrg: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  setEditKeyRingObj: PropTypes.func,
  keyRingObj: PropTypes.object,
  user: PropTypes.object
}

KeyRingDetails.defaultProps = {
  keyRingObj: null,
  setEditKeyRingObj: null,
  user: null
}

const KeyRingDetailsWithStyles = injectSheet(styles)(KeyRingDetails)
export default KeyRingDetailsWithStyles
