import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Notification from '../../common/Notification'
import Typography from '../../components/Typography'
import Column from '../../components/Column'
import Theme from '../../helpers/Theme'
import useWindowDimensions from '../../utils/useWindowDimensions'
import NavigationBar from '../../components/NavigationBar'
import Content from '../../components/Content'
import MobileLogo from '../../components/MobileLogo'
import Footer from '../../components/Footer'
import Paper from '../../components/Paper'

const styles = {
  root: {
    minHeight: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.08)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 50
  },
  paper: {},
  iconCompleteContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    borderRadius: 500,
    backgroundColor: Theme.Colors.PRIMARY_TONE
  },
  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */
  '@media (max-width: 900px)': {
    root: {
      padding: 16,
      marginTop: 64,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      backgroundColor: 'var(--color-white)'
    },
    paper: {
      margin: '30px auto 0 auto',
      padding: 0,
      width: '100%',
      backgroundColor: 'var(--color-white)',
      border: 'none',
      boxShadow: 'none'
    }
  }
}

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Reset = props => {
  const { classes, match, history } = props
  const { isMobile } = useWindowDimensions()
  const [email, setEmail] = useState(match.params.email || '')
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const updatePassword = async () => {
    if (!email) return

    setIsLoading(true)
    try {
      await axios.post('/v1/users/reset/password', { email })
      setIsComplete(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global.translate(msg))
    }
    setIsLoading(false)
  }

  const buttonDisabled = !email || !emailRegex.test(email)

  if (isMobile) {
    return (
      <Content>
        <NavigationBar
          title={global._('Reset.Title')}
          backAction={() => history.goBack()}
        />
        <div className={classes.root}>
          {!isComplete && <MobileLogo />}
          <Paper classes={classes} alignItems='center' fixedWidth>
            {error && !isComplete && (
              <Notification
                type='error'
                message={error}
                style={{ marginBottom: 25 }}
              />
            )}
            {!isComplete && (
              <>
                <Typography variant='title'>
                  {global._('Reset.Description')}
                </Typography>
                <form style={{ width: '100%', marginTop: 30 }}>
                  <Input
                    type='username'
                    placeholder={global.translate('Reset.EmailPlaceholder')}
                    value={email}
                    autoComplete='off'
                    onChange={e => setEmail(e.target.value)}
                  />
                </form>
                <Button
                  style={{ marginTop: 25 }}
                  variant='primary'
                  disabled={buttonDisabled}
                  loading={isLoading}
                  onClick={updatePassword}
                  primary
                >
                  {global.translate('Reset.ResetButton')}
                </Button>
              </>
            )}
            {isComplete && (
              <Column flex style={{ padding: '15px 10px' }}>
                <div className={classes.iconCompleteContainer}>
                  <Icon style={{ fontSize: 72 }}>done</Icon>
                </div>
                <Typography
                  variant='h5'
                  style={{ padding: '50px 0' }}
                  align='center'
                >
                  {global._('Reset.CompleteText')}
                </Typography>
                <Button
                  variant='primary'
                  onClick={() => history.push('/login')}
                  style={{ paddingRight: 60, paddingLeft: 60 }}
                >
                  {global._('Common.Login')}
                </Button>
              </Column>
            )}
          </Paper>
        </div>
      </Content>
    )
  }
  return (
    <div className={classes.root}>
      <Typography variant='h1' align='center' style={{ marginBottom: 50 }}>
        {global._('Reset.Title')}
      </Typography>
      <Paper alignItems='center' fixedWidth>
        {error && !isComplete && (
          <Notification
            type='error'
            message={error}
            style={{ marginBottom: 25 }}
          />
        )}
        {!isComplete && (
          <>
            <Typography
              variant='subtitle'
              style={{ fontSize: 20, lineHeight: 1.2, marginBottom: 30 }}
            >
              {global._('Reset.Description')}
            </Typography>
            <form style={{ width: '100%' }}>
              <Input
                type='username'
                placeholder={global.translate('Reset.EmailPlaceholder')}
                value={email}
                autoComplete='off'
                onChange={e => setEmail(e.target.value)}
              />
            </form>
            <Button
              style={{ marginTop: 30 }}
              variant='primary'
              disabled={buttonDisabled}
              loading={isLoading}
              onClick={updatePassword}
              primary
            >
              {global.translate('Reset.ResetButton')}
            </Button>
          </>
        )}
        {isComplete && (
          <Column flex style={{ padding: '15px 10px' }}>
            <div className={classes.iconCompleteContainer}>
              <Icon style={{ fontSize: 72 }}>done</Icon>
            </div>
            <Typography
              variant='h5'
              style={{ padding: '50px 0' }}
              align='center'
            >
              {global._('Reset.CompleteText')}
            </Typography>
            <Button
              variant='primary'
              onClick={() => history.push('/login')}
              style={{ paddingRight: 60, paddingLeft: 60 }}
            >
              {global._('Common.Login')}
            </Button>
          </Column>
        )}
      </Paper>
      {!isComplete && (
        <Button
          style={{ marginTop: 20 }}
          variant='none'
          onClick={() => history.goBack()}
        >
          {global._('Common.Back')}
        </Button>
      )}
      <Footer />
    </div>
  )
}

Reset.propTypes = {
  classes: PropTypes.object.isRequired
}

const ResetWithStyle = injectSheet(styles)(Reset)
export default ResetWithStyle
