import React from 'react'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import axios from 'axios'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import ImportModal from 'src/components/ImportModal'
import ssn from 'src/utils/ssn'
import InformationBar from 'src/components/InformationBar'
import Button from '../../../../components/Button'
import AsyncSelect from '../../../../components/AsyncSelect'
import SuccessMessage from '../../../../components/SuccessMessage'
import {
  ImportStorageKeys,
  UserRoles,
  UserAssignableRoles,
  OrganisationTypes
} from '../../../../utils/constants'
import { emailRegExp, mapRoles } from '../../../../utils/helpers'
import { history } from '../../../../redux/store'

export default {
  icon: () => 'face',
  attributes: (caller, opts) => {
    const attributes = {
      email: {
        size: 12,
        customHelpText: global._(
          'Widgets.users.EmailOrPersonalNumberIsRequired'
        ),
        required: opts.newItem && !opts.newItem.personalNumber,
        validation: {
          validate: str => str && emailRegExp.test(str),
          error: global._('OrganisationDetail.EmailNotValidError')
        }
      },
      gdprInfo: {
        // Place holder
        field: <div />
      },
      personalNumber: {
        size: 12,
        customHelpText: global._(
          'Widgets.users.PersonalNumberOrEmailIsRequired'
        ),
        required: opts.newItem && !opts.newItem.email,
        validation: {
          validate: str => str && ssn.valid(str)
        }
      }
    }
    if (
      caller.roles.includes(UserRoles.SystemAdmin) ||
      caller.roles.includes(UserRoles.PartnerAdmin)
    ) {
      attributes.organisationId = {
        required: true,
        field: (
          <AsyncSelect
            data-cy='input-select-name'
            isSearchable
            labelKey='name'
            defaultValue={
              opts.filter.organisationId
                ? {
                    value: opts.filter.organisationId,
                    label: opts.filter.organisationName
                  }
                : undefined
            }
            placeholder={global._('Common.SelectOrganisation')}
            onChange={opt =>
              opts.setNewItem({
                ...opts.newItem,
                organisationId: opt ? opt.value.id : null,
                organisationType: opt ? opt.value.type : null
              })
            }
            disabled={opts.isLoadingCreate}
            helpText={global._('Common.Required')}
            url='/v1/organisations?search={{input}}&orderBy=name&order=asc'
          />
        )
      }
    }

    const assignableRoles = []
    caller.roles.forEach(userRole => {
      const availableRoles = UserAssignableRoles[userRole]
      availableRoles.forEach(availableRole => {
        if (
          !assignableRoles.includes(availableRole) &&
          availableRole !== UserRoles.Support
        ) {
          assignableRoles.push(availableRole)
        }
      })
    })

    attributes.roles = {
      required: true,
      field: (
        <TextField
          data-cy='input-select-role'
          select
          style={{ width: '100%' }}
          variant='filled'
          label={global._('Widgets.users.SelectRole')}
          value={
            opts.newItem && opts.newItem.roles ? opts.newItem.roles[0] : ''
          }
          onChange={e =>
            opts.setNewItem({ ...opts.newItem, roles: [e.target.value] })
          }
          helperText={global._('Common.Required')}
        >
          <MenuItem value='' disabled>
            {global._('Widgets.users.SelectRole')}
          </MenuItem>
          {assignableRoles.map(role => {
            if (
              opts.newItem &&
              opts.newItem.organisationId &&
              role === UserRoles.PartnerAdmin &&
              opts.newItem.organisationType !== OrganisationTypes.Partner
            ) {
              return null
            }

            return (
              <MenuItem key={`Select-Role-${role}`} value={role}>
                {global._(`User.Roles.${role}`)}
              </MenuItem>
            )
          })}
        </TextField>
      )
    }

    attributes.message = {
      required: false,
      size: 12
    }

    attributes.sendInvitationLink = {
      field: (
        <FormGroup>
          <FormControlLabel
            style={{ display: 'inline-block', userSelect: 'none' }}
            control={
              <Checkbox
                checked={opts.newItem && opts.newItem.sendInvitationLink}
                disabled={!opts.newItem || !opts.newItem.email}
                onChange={() =>
                  opts.setNewItem({
                    ...opts.newItem,
                    sendInvitationLink:
                      !opts.newItem.sendInvitationLink || false
                  })
                }
                value='sendInvitationLink'
              />
            }
            label={global._('Import.Users.Preview.SendInvitationLink')}
          />
        </FormGroup>
      )
    }

    if (global._('Widgets.users.GDPRinfo').length) {
      attributes.gdprInfo.field = (
        <InformationBar
          message={global._('Widgets.users.GDPRinfo')}
          compact
          alignCenter
          noShadow
        />
      )
    }

    return attributes
  },
  modals: ({
    modals,
    setModals,
    user,
    modalFieldsData,
    setModalFieldsData
  }) => {
    const ret = []
    if (modals.import) {
      ret.push(
        <ImportModal
          storageKey={ImportStorageKeys.USERS}
          transformRow={row => ({
            firstName: row[0], // string
            lastName: row[1], // string
            personalNumber: row[2], // string
            email: row[3], // string
            apartmentRefId: row[4] // string
            // apartmentRefIds: row[4]
            //   .split(',')
            //   .map(refId => refId.trim())
            //   .filter(refId => !!refId) // string -> array -> map -> filter (check for empty)
          })}
          previewUrl='/admin/users/import'
          templateFilePath={`/templates/importmall_anvandare_${global.getLanguage()}.xlsx`}
          locKey='Users'
          key='admin.users.import.modal'
          onClose={() => setModals({ ...modals, import: false })}
          fieldsData={modalFieldsData}
          fields={() => {
            let { organisation } = user

            if (modalFieldsData && modalFieldsData.organisation) {
              ;({ organisation } = modalFieldsData)
            } else {
              setModalFieldsData({
                organisation
              })
            }

            return (
              <>
                <AsyncSelect
                  isSearchable
                  isClearable={false}
                  key='import-users-organisation-select'
                  dropdownIcon='search'
                  helpText={global._('Common.Required')}
                  value={{ label: organisation.name, value: organisation }}
                  placeholder={global._(
                    `Import.Users.Modal.AsyncFieldPlaceholder`
                  )}
                  onChange={opt => {
                    setModalFieldsData({
                      organisation: opt ? opt.value : null
                    })
                  }}
                  url='/v1/organisations?orderBy=name&order=asc&search={{input}}'
                />
              </>
            )
          }}
        />
      )
    }
    ret.push(
      <SuccessMessage
        storageKey={ImportStorageKeys.USERS}
        key='admin.users.import.successmessage.withoutinvite'
        subtitle={global._('Import.Users.Preview.SuccessMessageWithoutInvite')}
        localStorageProp='users.import.success.withoutInvite'
      />
    )
    ret.push(
      <SuccessMessage
        storageKey={ImportStorageKeys.USERS}
        key='admin.users.import.successmessage.withinvite'
        subtitle={global._('Import.Users.Preview.SuccessMessageWithInvite')}
        localStorageProp='users.import.success.withInvite'
      />
    )
    return ret
  },
  actionButtons: (caller, opts) => {
    const { filter, setNewItem, modals, setModals, single } = opts
    const buttons = []
    if (!caller.roles.includes(UserRoles.Support)) {
      buttons.push(
        <Button
          data-cy='button-add-user'
          key='widgets.users.button.add.new'
          variant='primary'
          onClick={() => setNewItem({ organisationId: filter.organisationId })}
        >
          {global._('Widgets.users.ActionButton.AddNew')}
        </Button>
      )
    }
    const hasPermission = caller.roles.find(
      role => !(role === UserRoles.SystemAdmin || role === UserRoles.Support)
    )
    if (single && hasPermission) {
      buttons.push(
        <Button
          key='widgets.users.button.import'
          variant='outlined'
          onClick={() => setModals({ ...modals, import: true })}
        >
          {global._('Common.Import')}
        </Button>
      )
    }
    return buttons
  },
  identifier: user =>
    `${user.id}-${user.organisation ? user.organisation.id : null}`,
  noResultsMessage: () => global._('Widgets.users.NoResultsMessage'),
  MobileTable: {
    icon: 'face',
    title: user => user.name,
    subtitle: user =>
      user.role && (
        <span
          style={{
            marginTop: 6,
            padding: '2px 8px',
            backgroundColor: 'var(--color-dark-grey)',
            color: 'white',
            borderRadius: 3,
            display: 'inline-block',
            fontSize: '0.75rem',
            fontWeight: 600,
            textTransform: !user.active && 'lowercase'
          }}
        >
          {!user.active && (
            <span style={{ textTransform: 'capitalize' }}>{`${global._(
              'Common.NotRegistered'
            )} `}</span>
          )}
          {global._(`User.Roles.${user.role}`)}
        </span>
      ),
    onClick: item =>
      history.push(`/admin/users/${item.organisation.id}/${item.id}`)
  },
  Columns: {
    single: [
      {
        key: 'icon',
        sortable: false,
        localizationKey: '',
        style: { width: 60 },
        transform: isExpanded => (
          <div
            className='tableRowIcon'
            style={{
              width: 38,
              height: 38,
              transition: 'background-color 200ms',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isExpanded ? 'white' : 'var(--color-light-grey)',
              color: 'var(--color-middle-grey)',
              borderRadius: 500
            }}
          >
            <Icon style={{ fontSize: 18 }}>face</Icon>
          </div>
        )
      },
      {
        key: 'name',
        sortable: true,
        transform: value => (
          <span
            style={{
              fontWeight: value ? 600 : undefined,
              color: value ? undefined : 'gray'
            }}
          >
            {value || global._('User.NoNamePlaceholder')}
          </span>
        ),
        localizationKey: 'Widgets.users.Attributes.name',
        style: { width: 200 }
      },
      {
        key: 'personalNumber',
        sortable: true,
        localizationKey: 'Widgets.users.Attributes.personalNumber',
        style: { width: 125 }
      },
      {
        key: 'email',
        sortable: true,
        localizationKey: 'Widgets.users.Attributes.email',
        style: { width: 250 },
        longText: true
      },
      {
        key: 'phone',
        sortable: true,
        localizationKey: 'Widgets.users.Attributes.phone',
        style: { width: 150 }
      },
      {
        key: 'roles',
        sortable: true,
        localizationKey: 'Widgets.users.Attributes.roles',
        transform: mapRoles,
        style: { width: 180 }
      },
      {
        key: 'organisation',
        sortable: true,
        sortKey: 'organisation.name',
        localizationKey: 'Widgets.users.Attributes.organisation',
        transform: value => value && value.name,
        style: { width: 240 }
      },
      {
        key: 'chevron',
        sortable: false,
        localizationKey: '',
        style: { width: 60 },
        transform: isExpanded => (
          <IconButton
            style={{
              transition: 'transform 150ms',
              marginLeft: 'auto',
              transform: `rotate(${isExpanded ? -90 : 90}deg)`
            }}
          >
            <Icon>chevron_right</Icon>
          </IconButton>
        )
      }
    ],
    /* Minified columns */
    widget: [
      {
        key: 'name',
        sortable: true,
        transform: value => (
          <span
            style={{
              color: value ? undefined : 'gray'
            }}
          >
            {value || global._('User.NoNamePlaceholder')}
          </span>
        ),
        localizationKey: 'Widgets.users.Attributes.name',
        width: 300
      },
      {
        key: 'createdAt',
        sortable: true,
        localizationKey: 'Widgets.users.Attributes.createdAt',
        transform: value => moment(value).calendar(),
        width: 200
      },
      {
        key: 'chevron',
        sortable: false,
        localizationKey: '',
        transform: () => (
          <Icon
            style={{ display: 'block', marginLeft: 'auto', marginRight: 5 }}
          >
            chevron_right
          </Icon>
        )
      }
    ]
  },
  rowExpansionContent: (item, caller, opts) => (
    <React.Fragment>
      <Button
        variant='outlined'
        onClick={() =>
          opts.history.push(`/admin/users/${item.organisation.id}/${item.id}`)
        }
        style={{ marginRight: 18 }}
      >
        {global._(
          caller.roles.includes(UserRoles.Support)
            ? 'Widgets.users.ViewUser'
            : 'Widgets.users.EditUser'
        )}
      </Button>
      {item.apartmentRefId && (
        <Button
          variant='outlined'
          onClick={() =>
            window.open(
              `/admin/apartments?search=${item.apartmentRefId}&organisationId=${
                item.organisation.id
              }&organisationName=${encodeURIComponent(item.organisation.name)}`,
              '_blank'
            )
          }
          style={{ marginRight: 18 }}
        >
          {global._('Widgets.users.ShowApartment')}
        </Button>
      )}
      {!caller.roles.includes(UserRoles.Support) && (
        <Button
          variant='outlined'
          style={{ marginRight: 18 }}
          onClick={() => {
            opts.setDeleteItem(item)
            opts.setDeleteError(null)
          }}
        >
          {global._('Widgets.users.Delete')}
        </Button>
      )}
      {!item.active && (
        <>
          <Button
            variant='outlined'
            style={{ marginRight: 18 }}
            onClick={async () => {
              opts.setError(null)
              opts.setShowSuccessMessage(false)
              opts.setSuccessMessage(global._('MyAccount.InvitationLinkSent'))

              try {
                await axios.post(`/v1/users/${item.id}/resend`, caller)

                opts.setShowSuccessMessage(true)
              } catch (e) {
                const msg = e.response ? e.response.data : e.message
                opts.setError(global._(msg))
              }
            }}
          >
            {global._('Widgets.users.ResendInvitationLink')}
          </Button>
        </>
      )}
      {caller.id !== item.id &&
        (caller.roles.includes(UserRoles.SystemAdmin) ||
          caller.roles.includes(UserRoles.PartnerAdmin)) &&
        caller.organisation.id !== item.organisation.id && (
          <Button
            variant='primary'
            onClick={async () => {
              opts.setError(null)
              try {
                const { data: authData } = await axios.get(
                  `/v1/impersonate/${item.organisation.id}/${item.id}`
                )
                // Update redux state with "impersonated" data
                opts.setAuthenticationData(authData)
                opts.history.push('/dashboard')
              } catch (e) {
                const msg = e.response ? e.response.data : e.message
                opts.setError(global._(msg))
              }
            }}
          >
            {global._('Widgets.users.Impersonate')}
          </Button>
        )}
    </React.Fragment>
  )
}
