import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import withToken from 'src/utils/withToken'
import useWindowDimensions from '../utils/useWindowDimensions'
import AttachmentModal from './AttachmentModal'
import Typography from './Typography'
import ItemBadge from './ItemBadge'

const styles = {
  root: {
    display: 'flex',
    backgroundColor: '#f0f0f0',
    border: '1px solid #8e8e8e',
    borderRadius: 3,
    padding: 13,
    width: '100%',
    '&:hover': {
      cursor: props => (props.src || props.data ? 'pointer' : 'cursor')
    }
  },
  clickableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    fontSize: '1rem',
    whiteSpace: 'nowrap'
  },
  fileName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  '@media (max-width: 900px)': {
    root: {
      padding: '6px 13px'
    },
    text: {
      fontWeight: 600
    }
  }
}

const fileTypes = ['image/jpeg', 'image/png', 'application/pdf']
const FileContainer = props => {
  const {
    classes,
    file,
    src,
    type,
    name,
    size,
    onDelete,
    token,
    buttons,
    openAttachmentModal,
    setOpenAttachmentModal,
    openAttachmentModalWithoutButtons,
    endIcon,
    onEndIconClick,
    data,
    disableClick
  } = props

  const url = `${window.env.API_BASE_URL}${src}?token=${token}`
  const { isMobile } = useWindowDimensions()
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(
    openAttachmentModal || openAttachmentModalWithoutButtons || false
  )

  const handleClick = () => {
    if (
      ((!data || !src) && (!file || !fileTypes.includes(type)) && isMobile) ||
      disableClick
    )
      return

    if (isMobile && type === 'application/pdf') {
      window.open(
        `https://docs.google.com/viewer?url=${url}&embedded=true`,
        '_blank',
        'noopener,noreferrer'
      )
    } else if (isMobile) {
      setAttachmentModalOpen(true)
    } else if (!isMobile && token) {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }

  /* Always show size at >= 0.1 mb */
  const sizeInMb = Math.max(size / 1000 / 1000, 0.1)
    .toFixed(1)
    .toString()
    .replace('.', ',')
  let fileName
  const extension = name.split('.').pop()
  const aName = name.replace(`.${extension}`, '')
  if (aName.length > 34) {
    fileName = `${aName.substr(0, 15)}...${aName.substr(
      aName.length - 16,
      16
    )}.${extension}`
  } else {
    fileName = name
  }

  let icon = 'web_asset'
  if (
    type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    icon = 'description'
  } else if (type === 'image/png' || type === 'image/jpeg') {
    icon = 'insert_photo'
  }

  if (attachmentModalOpen) {
    return (
      <AttachmentModal
        title={(file && file.name) || name}
        attachment={file}
        src={src}
        data={data}
        buttons={
          !openAttachmentModalWithoutButtons &&
          buttons &&
          (_url => buttons(_url))
        }
        onClose={() => {
          if (setOpenAttachmentModal) {
            setOpenAttachmentModal(null)
          }
          setAttachmentModalOpen(false)
        }}
      />
    )
  }

  return (
    <>
      <ItemBadge
        onClick={handleClick}
        icon={icon}
        onDelete={onDelete}
        src={src}
        data={data}
        endIcon={endIcon}
        onEndIconClick={onEndIconClick}
        hideDeleteBtn={!onDelete}
        label={
          <>
            <span className={classnames(classes.text, classes.fileName)}>
              {fileName}
            </span>
            <span
              style={{ marginLeft: 5, marginRight: 5 }}
              className={classes.text}
            >
              {`(${sizeInMb} mb)`}
            </span>
          </>
        }
      />
      {isMobile && !fileTypes.includes(type) && (
        <div style={{ marginLeft: 15 }}>
          <Typography
            style={{ color: 'var(--color-middle-grey)' }}
            variant='small'
          >
            {global._('FileContainer.CannotOpenFile')}
          </Typography>
        </div>
      )}
    </>
  )
}

FileContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  file: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  data: PropTypes.string,
  token: PropTypes.string,
  src: PropTypes.string,
  buttons: PropTypes.func
}

FileContainer.defaultProps = {
  token: null,
  src: null,
  data: null,
  buttons: null,
  onDelete: undefined
}

export default withToken(injectSheet(styles)(FileContainer))
