import { Icon } from '@material-ui/core'
import PropTypes from 'prop-types'
import axios from 'axios'
import Notification from 'src/common/Notification'
import Column from 'src/components/Column'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { getMaxLockShareLimit } from 'src/utils/helpers'
import Row from 'src/components/Row'
import ScrollView from 'src/components/ScrollView'
import Typography from 'src/components/Typography'
import React, { useState, useEffect } from 'react'
import injectSheet from 'react-jss'
import withUser from 'src/utils/withUser'

const styles = {
  root: {}
}

const SelectLocks = ({
  user,
  data,
  onChange,
  selectOnLoad,
  readOnly,
  fullHeightScrollView
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [locks, setLocks] = useState([])

  useEffect(() => {
    if (!readOnly) {
      ;(async () => {
        try {
          const response = await axios.get(
            `/v1/organisations/${user.organisation.id}/locks?accessible=true`
          )
          setLocks(response.data)
          const selected = response.data.filter(lock =>
            selectOnLoad.some(
              id =>
                id == lock.id &&
                lock.device.shareable &&
                lock.shares < getMaxLockShareLimit(lock)
            )
          )
          onChange(selected)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
        setIsLoading(false)
      })()
    }
  }, [])

  const toggle = lock => {
    if (data.some(l => l.id === lock.id)) {
      lock.shares -= 1
      onChange(data.filter(l => l.id !== lock.id))
    } else {
      lock.shares += 1
      onChange([...data, lock])
    }
  }

  return (
    <>
      <div>
        <Typography
          variant='body'
          bold
          block
          style={{
            padding: '26px 20px',
            borderBottom: '1px solid var(--color-divider-light)'
          }}
        >
          {global._('Locks.Share.SelectLocks')}
        </Typography>
      </div>
      {error && <Notification type='error' message={error} />}
      <ScrollView
        style={{ top: error ? 205 : 140 }}
        fullHeight={fullHeightScrollView}
      >
        <div style={{ marginBottom: 50 }}>
          {isLoading && <LoadingSpinner style={{ padding: 24 }} />}
          {!locks.length && (
            <Row
              align='center'
              justify='center'
              style={{ padding: '48px 78px' }}
            >
              <Typography variant='body' align='center' faded>
                {global._('Locks.Share.NoLocksToShare')}
              </Typography>
            </Row>
          )}
          {locks.map(lock => {
            const checked = data.some(l => l.id === lock.id)
            const maxShareLimit = getMaxLockShareLimit(lock)
            const canBeShared =
              checked || (lock.device.shareable && lock.shares < maxShareLimit)
            let checkboxColor
            if (!canBeShared) {
              checkboxColor = 'var(--color-divider-light)'
            } else if (checked) {
              checkboxColor = 'var(--color-primary)'
            } else {
              checkboxColor = 'black'
            }
            return (
              <Row
                key={lock.id}
                onClick={canBeShared ? () => toggle(lock) : undefined}
              >
                <Icon
                  style={{
                    margin: '20px 32px',
                    fontSize: 20,
                    color: checkboxColor
                  }}
                >
                  {checked ? 'check_box' : 'check_box_outline_blank'}
                </Icon>
                <Column
                  align='flex-start'
                  style={{
                    width: '100%',
                    padding: '18px 0px',
                    borderBottom: '1px solid var(--color-divider-light)'
                  }}
                >
                  <Typography
                    variant='body'
                    bold
                    style={{
                      color: canBeShared
                        ? undefined
                        : 'var(--color-middle-grey)'
                    }}
                  >
                    {lock.name || '-'}
                  </Typography>
                  <Typography variant='body2' faded>
                    {lock.propertyName || '-'}
                  </Typography>
                  {!lock.device.shareable && (
                    <Typography
                      variant='body2'
                      style={{ color: 'var(--color-error)' }}
                    >
                      {global._('Locks.Share.NotShareable')}
                    </Typography>
                  )}
                  {lock.device.shareable && !canBeShared && (
                    <Typography
                      variant='body2'
                      style={{ color: 'var(--color-error)' }}
                    >
                      {global._('Locks.Share.ShareLimitReached')}
                    </Typography>
                  )}
                </Column>
              </Row>
            )
          })}
        </div>
      </ScrollView>
    </>
  )
}

SelectLocks.propTypes = {
  fullHeightScrollView: PropTypes.bool
}

SelectLocks.defaultProps = {
  fullHeightScrollView: false
}

export default withUser(injectSheet(styles)(SelectLocks))
