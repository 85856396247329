import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import NavigationBar from 'src/components/NavigationBar'
import CenteredFloatingButton from 'src/components/CenteredFloatingButtonMobile'
import Typography from 'src/components/Typography'
import ScrollView from 'src/components/ScrollView'
import PullToRefresh from 'src/components/PullToRefresh'
import MobileContainer from 'src/components/MobileContainer'
import ObjectListMobile from 'src/components/ObjectListMobile'
import Notification from '../../common/Notification'
import MyLocksNavigator from './MyLocksNavigator'

const styles = {}

const DelegatedAccessList = props => {
  const { classes, history, user } = props

  /* State */
  const [accessLocks, setAccessLocks] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const getAccessLocks = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const { data } = await axios.get('/v1/locks/access')

      setAccessLocks(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setAccessLocks([])
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  /* Initial load */
  useEffect(() => {
    getAccessLocks()
  }, [])

  return (
    <MobileContainer>
      <NavigationBar
        title={global._('TabBar.Locks')}
        backAction={() => history.goBack()}
        rightActionItem={
          <div onClick={() => history.push('/mylocks/events')}>
            <Icon>history</Icon>
          </div>
        }
      />
      <ScrollView id='scrollable-container'>
        <MyLocksNavigator />
        {!isLoading && (
          <PullToRefresh
            onRefresh={getAccessLocks}
            loading={isLoading}
            helpTextStyle={{ marginTop: 50 }}
            containerStyles={{ background: 'var(--color-material-grey)' }}
          >
            <div className={classnames('noselect', classes.root)}>
              {error && <Notification type='error' message={error} />}
              <div>
                <ObjectListMobile
                  objectPermalink='mylocks/access'
                  objects={accessLocks}
                  user={user}
                  history={history}
                  noResultsMessage={global._('DelegatedAccess.NoSharedAccess')}
                  lessPadding
                  fullBorder
                  columns={[
                    {
                      key: 'icon',
                      style: {
                        width: 40,
                        height: 40,
                        borderRadius: '100%',
                        color: 'var(--color-white)',
                        background: 'var(--color-primary)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      },
                      format: () => <Icon>person</Icon>
                    },
                    {
                      key: 'name',
                      localizationKey: 'Name',
                      sortingKey: 'name',
                      clickable: true,
                      longText: true,
                      format: name => <Typography bold>{name}</Typography>
                    },
                    {
                      key: 'lockCount',
                      localizationKey: 'lockCount',
                      sortingKey: 'lockCount',
                      clickable: true,
                      longText: true,
                      format: (one, two, obj) => (
                        <Typography faded variant='body2'>{`${
                          obj.locks ? obj.locks.length : 0
                        } ${global._('Common.locks')}`}</Typography>
                      )
                    }
                  ]}
                  rowStyle={{ background: 'var(--color-white)' }}
                />
              </div>
            </div>
          </PullToRefresh>
        )}
        <CenteredFloatingButton
          onClick={() => history.push('/mylocks/share')}
        />
      </ScrollView>
    </MobileContainer>
  )
}

DelegatedAccessList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const DelegatedAccessListWithStyles = injectSheet(styles)(DelegatedAccessList)
export default DelegatedAccessListWithStyles
