import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios, { AxiosError } from 'axios'
import { Paper } from '@material-ui/core'
import useWindowDimensions from '../../utils/useWindowDimensions'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import MobileContainer from '../../components/MobileContainer'
import Theme from '../../helpers/Theme'
import LoadingSpinner from 'src/components/LoadingSpinner'
import Notification from 'src/common/Notification'
import { getSelectedOrg } from 'src/utils/helpers'

const styles = {
  root: {
    // zIndex: 1000,
    // position: 'fixed',
    // top: 80,
    // left: 'var(--menu-left-width)',
    margin: '-60px -49px',
    width: 'calc(100% + 98px)',
    height: 'calc(100% + 109px)',
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: -10,
    padding: 10,
    overflow: 'hidden'
  }
}

interface BookingProps {
  classes: any
  history: any
  user: IUser
}

const Booking: React.FC<BookingProps> = (props: BookingProps) => {
  const { classes, user, history } = props

  const isAdminView = window.location.pathname.includes('booking/admin')

  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [iframeURL, setIframeURL] = useState('')
  const [error, setError] = useState('')
  const [initialized, setInitialized] = useState(false)
  const { isMobile } = useWindowDimensions()

  const selectedOrg = getSelectedOrg(user)
  if (isAdminView && !selectedOrg && !initialized) {
    history.push('/booking/select-organisation')
  }

  useEffect(() => {
    ;(async () => {
      try {
        let url
        if (isAdminView) {
          url = `/v1/booking/admin/auth?organisationId=${selectedOrg.id}`
          setInitialized(true)
        } else {
          url = '/v1/booking/auth'
        }
        const { data } = await axios.get(url)
        const lang = global.getLanguage()
        if (data.body.IframeUrl && lang === 'NO') {
          // 1=Svenska 2=Engelska 3=Norska
          data.body.IframeUrl = data.body.IframeUrl.replace(
            /\&lang=\d{1}/,
            '&lang=3'
          )
        }
        setIframeURL(data.body.IframeUrl)
        setIsAuthenticating(false)
      } catch (err: Error | AxiosError) {
        const msg: string = err.response ? err.response.data : err.message
        console.log(msg)
        setError(global._(msg))
        setIsAuthenticating(false)
      }
    })()
  }, [])

  let content
  if (isAuthenticating) {
    content = <LoadingSpinner />
  } else if (error) {
    content = (
      <Paper
        elevation={1}
        style={{
          position: 'absolute',
          width: isMobile ? 300 : 500,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 20
        }}
      >
        <Notification type='error' message={error} />
      </Paper>
    )
  } else {
    content = (
      <iframe
        src={iframeURL}
        width='100%'
        height='100%'
        title='BTT'
        id='iframe-x'
        style={{ border: 0, overflow: 'hidden', height: '100%', width: '100%' }}
      />
    )
  }

  if (isMobile) {
    return (
      <MobileContainer>
        <NavigationBar
          title={global._('Booking.Title')}
          backAction={() => history.goBack()}
        />
        <ScrollView>{content}</ScrollView>
      </MobileContainer>
    )
  }

  return (
    <div className={classes.root} id='BookingRoot'>
      {content}
    </div>
  )
}

const BookingWithStyles = injectSheet(styles)(Booking)
export default BookingWithStyles
