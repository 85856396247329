import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  root: {},
  '@media (max-width: 900px)': {
    root: {
      position: 'absolute',
      backgroundColor: 'var(--color-material-grey)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      overflow: 'hidden'
      // width: '100%',
      // height: '100%'
    }
  }
}

const Content = props => {
  const { classes, ...otherProps } = props

  useEffect(() => {
    /* Stop iOS viewport zooming */
    const preventZoom = e => {
      if (typeof e.scale !== 'undefined' && e.scale !== 1) {
        e.preventDefault()
      }
    }

    window.document.addEventListener('touchmove', preventZoom, {
      passive: false
    })
    return () => {
      window.document.removeEventListener('touchmove', preventZoom, {
        passive: false
      })
    }
  }, [])

  return (
    <div className={classnames('noselect', classes.root)} {...otherProps} />
  )
}

Content.propTypes = {
  classes: PropTypes.object.isRequired
}
Content.defaultProps = {}

const ContentWithStyle = injectSheet(styles)(Content)
export default ContentWithStyle
