import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import ssn from 'src/utils/ssn'
import { UserRoles, ImportStorageKeys } from 'src/utils/constants'
import { emailRegExp, mapRoles } from 'src/utils/helpers'
import _ from 'lodash'
import IconColumn from 'src/components/IconColumn'
import ImportPreview from '../../../components/ImportPreview'
import Typography from '../../../components/Typography'
import { Check, CheckCircleOutline, WarningOutlined } from '@material-ui/icons'

const styles = {}

const UserImportPreview = ({ classes, history, breadcrumbItems }) => {
  const [items, setItems] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)
  const [sendInvitationLink, setSendInvitationLink] = useState(false)

  const handleImport = async () => {
    setIsLoading(true)
    try {
      /* Only send the data needed to create the item */
      const data = items
        .filter(u => u.errors.length === 0)
        .map(user => ({
          name: user.name,
          email: user.email,
          roles: user.roles,
          apartmentIds: user.apartmentIds,
          organisationId: user.organisationId,
          personalNumber: user.personalNumber,
          sendInvitationLink
        }))

      if (!data.length) {
        return setIsLoading(false)
      }

      await axios.post(
        `/v1/organisations/${data[0].organisationId}/users/import`,
        data
      )

      /* Show success message when returning to main view */
      if (sendInvitationLink) {
        localStorage.setItem('users.import.success.withInvite', '1')
      } else {
        localStorage.setItem('users.import.success.withoutInvite', '1')
      }

      /* Remove data used for this preview */
      localStorage.removeItem(ImportStorageKeys.USERS)

      history.push('/admin/users')
    } catch (e) {
      setIsLoading(false)
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const validate = async (users, validationData) => {
    try {
      const { data } = await axios.post(
        `/v1/organisations/${validationData.organisationId}/users/import/validate`,
        validationData
      )

      /* normalize ssn */
      data.users.forEach(user => {
        if (user.personalNumber && ssn.valid(user.personalNumber)) {
          user.personalNumber = ssn.format(user.personalNumber, false)
        }
      })

      /* Transform excel values to preview items in table */
      const arr = []

      users.forEach(user => {
        if (user.personalNumber && ssn.valid(user.personalNumber)) {
          user.personalNumber = ssn.format(user.personalNumber, false)
        }

        const errors = []
        const name = `${user.firstName || ''} ${user.lastName || ''}`.trim()

        const existingEmail = data.users.find(u => u.email === user.email)
        const existingPersonalNumber = data.users.find(
          u => u.personalNumber === user.personalNumber
        )

        /* Validate email */
        if (user.email) {
          if (!emailRegExp.test(user.email)) {
            errors.push('Import.Users.Preview.Errors.InvalidEmail')
          }
          if (existingEmail) {
            errors.push('Import.Users.Preview.Errors.ExistingEmail')
          }
          if (users.find(u => u.email === user.email) !== user) {
            errors.push('Import.Users.Preview.Errors.ExistingEmailInList')
          }
        }

        /* Validate SSN */
        if (user.personalNumber) {
          if (!ssn.valid(user.personalNumber)) {
            errors.push('Import.Users.Preview.Errors.InvalidPersonalNumber')
          }
          if (existingPersonalNumber) {
            errors.push('Import.Users.Preview.Errors.ExistingPersonalNumber')
          }
          if (
            users.find(u => u.personalNumber === user.personalNumber) !== user
          ) {
            errors.push(
              'Import.Users.Preview.Errors.ExistingPersonalNumberInList'
            )
          }
        }

        if (!user.email && !user.personalNumber) {
          errors.push('Import.Users.Preview.Errors.EmptyEmailOrPersonalNumber')
        }

        user.apartments = []
        user.apartmentIds = []
        /* Validate apartment existance */
        if (user.apartmentRefIds && user.apartmentRefIds.length > 0) {
          user.apartmentRefIds.forEach(refId => {
            const existingApartment = data.apartments.find(
              a => a.refId === refId
            )

            if (!existingApartment) {
              errors.push(
                'Import.Users.Preview.Errors.ApartmentNotFound|' + refId
              )
            } else {
              user.apartments.push(existingApartment)
              user.apartmentIds.push(existingApartment.id)
            }
          })
        }

        arr.push({
          ...user,
          name,
          email: user.email,
          roles: [UserRoles.Resident],
          personalNumber: user.personalNumber,
          organisationId: validationData.organisationId,
          errors
        })
      })

      setItems(arr)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    ;(async () => {
      const previewData = JSON.parse(
        localStorage.getItem(ImportStorageKeys.USERS)
      )

      const validationData = {
        organisationId: previewData.organisation.id,
        emails: [],
        personalNumbers: [],
        apartmentRefIds: []
      }

      // Merge rows that have the same email and personal number and name
      const mergedRows = []
      previewData.previewItems.forEach(row => {
        const existingRow = mergedRows.find(
          r =>
            r.email === row.email &&
            r.personalNumber === row.personalNumber &&
            r.firstName === row.firstName &&
            r.lastName === row.lastName
        )

        if (existingRow) {
          existingRow.apartmentRefIds.push(row.apartmentRefId)
        } else {
          mergedRows.push({
            ...row,
            apartmentRefIds: row.apartmentRefId ? [row.apartmentRefId] : []
          })
        }
      })

      mergedRows.forEach(user => {
        if (user.email) {
          validationData.emails.push(user.email)
        }
        if (user.personalNumber && ssn.valid(user.personalNumber)) {
          if (ssn.valid(user.personalNumber)) {
            validationData.personalNumbers.push(
              ssn.format(user.personalNumber, true)
            )
            validationData.personalNumbers.push(
              ssn.format(user.personalNumber, false)
            )
          }
        }
        if (user.apartmentRefIds) {
          validationData.apartmentRefIds.push(...user.apartmentRefIds)
        }
      })

      validationData.emails = _.uniq(validationData.emails)
      validationData.personalNumbers = _.uniq(validationData.personalNumbers)
      validationData.apartmentRefIds = _.uniq(validationData.apartmentRefIds)

      await validate(mergedRows, validationData)
    })()
  }, [])

  return (
    <ImportPreview
      items={items}
      error={error}
      setError={setError}
      handleImport={handleImport}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      locKey='Users'
      breadcrumbItems={breadcrumbItems}
      columns={[
        {
          key: 'icon',
          sortingDisabled: true,
          style: { width: 40 },
          format: (objVal, obj) =>
            obj && (
              <IconColumn
                icon='face'
                style={{
                  color:
                    obj.errors.length > 0
                      ? 'var(--color-error-red)'
                      : undefined /* turn icon red when type is invalid */,
                  backgroundColor:
                    obj.errors.length > 0
                      ? 'var(--color-error-red-bg)'
                      : undefined /* turn icon red when type is invalid */
                }}
              />
            )
        },
        {
          key: 'name',
          localizationKey: 'Widgets.users.Attributes.name',
          sortingKey: 'name',
          longText: true,
          sortingDisabled: true,
          style: { width: 100 }
        },
        {
          key: 'roles',
          localizationKey: 'Widgets.users.Attributes.roles',
          sortingKey: 'marking',
          sortingDisabled: true,
          style: { width: 50 },
          format: (roles, user) => mapRoles(roles, user, true)
        },
        {
          key: 'personalNumber',
          localizationKey: 'Widgets.users.Attributes.personalNumber',
          sortingDisabled: true,
          style: { width: 80 },
          format: (personalNumber, item) => {
            const relatedErrors = [
              'Import.Users.Preview.Errors.InvalidPersonalNumber',
              'Import.Users.Preview.Errors.ExistingPersonalNumber',
              'Import.Users.Preview.Errors.ExistingPersonalNumberInList'
            ]
            if (
              item.errors &&
              item.errors.some(err => relatedErrors.includes(err))
            ) {
              return (
                <Typography bold style={{ color: 'var(--color-error-red)' }}>
                  {personalNumber || '-'}
                </Typography>
              )
            }
            return <Typography>{personalNumber || '-'}</Typography>
          }
        },
        {
          key: 'email',
          localizationKey: 'Widgets.users.Attributes.email',
          sortingDisabled: true,
          longText: true,
          style: { width: 120 },
          format: (email, item) => {
            const relatedErrors = [
              'Import.Users.Preview.Errors.EmptyEmail',
              'Import.Users.Preview.Errors.InvalidEmail',
              'Import.Users.Preview.Errors.ExistingEmail',
              'Import.Users.Preview.Errors.ExistingEmailInList'
            ]
            if (
              item.errors &&
              item.errors.some(err => relatedErrors.includes(err))
            ) {
              return (
                <Typography bold style={{ color: 'var(--color-error-red)' }}>
                  {email || '-'}
                </Typography>
              )
            }
            return <Typography>{email || '-'}</Typography>
          }
        },
        {
          key: 'apartmentRefIds',
          localizationKey: 'Common.Apartment',
          sortingDisabled: true,
          style: { width: 100 },
          format: (refIds, item) => {
            if (!refIds) {
              return null
            }
            return refIds.map(refId => {
              let value
              const apartment = item.apartments.find(a => a.refId === refId)
              if (apartment) {
                value = apartment.refId
              } else if (refId) {
                value = refId
              } else {
                value = '-'
              }

              const hasError =
                item.errors &&
                item.errors.some(
                  err =>
                    err ===
                    `Import.Users.Preview.Errors.ApartmentNotFound|${refId}`
                )

              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    wordBreak: 'break-word',
                    padding: '6px 12px 6px',
                    margin: 2,
                    borderRadius: 3,
                    fontSize: 12,
                    fontWeight: 500,
                    backgroundColor: hasError
                      ? 'var(--color-error-red-bg)'
                      : 'var(--color-success-green-bg)'
                  }}
                >
                  {hasError ? (
                    <WarningOutlined color='error' style={{ fontSize: 16 }} />
                  ) : (
                    <CheckCircleOutline
                      style={{ color: 'green', fontSize: 16 }}
                    />
                  )}
                  <Typography
                    style={{ marginLeft: 6, fontSize: 14 }}
                    bold={hasError}
                  >
                    {value}
                  </Typography>
                </div>
              )
            })
          }
        }
      ]}
      additionalTitleContent={
        <div
          style={{
            borderBottom: '1px solid var(--color-divider-light)',
            margin: '0 -20px 0 -20px',
            padding: '11px 20px 2px'
          }}
        >
          <FormGroup>
            <FormControlLabel
              style={{ display: 'inline-block', userSelect: 'none' }}
              control={
                <Checkbox
                  checked={sendInvitationLink}
                  onChange={() => setSendInvitationLink(!sendInvitationLink)}
                  value='sendInvitationLink'
                />
              }
              label={global._('Import.Users.Preview.SendInvitationLink')}
            />
          </FormGroup>
        </div>
      }
    />
  )
}

UserImportPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

UserImportPreview.defaultProps = {}

const UserImportPreviewWithStyles = injectSheet(styles)(UserImportPreview)
export default UserImportPreviewWithStyles
