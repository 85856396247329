import axios from 'axios'
import FullscreenModal from 'src/components/FullscreenModal'
import Button from 'src/components/Button'
import Typography from 'src/components/Typography'
import Notification from 'src/common/Notification'
import React, { useState } from 'react'
import injectSheet from 'react-jss'
import withUser from 'src/utils/withUser'
import moment from 'moment'
import Success from 'src/components/Success'
import { withRouter } from 'react-router'
import SelectLocks from './SelectLocks'
import SelectAccessTime from './SelectAccessTime'
import SelectRecipients from './SelectRecipient'

const styles = {
  root: {}
}

const Steps = {
  SELECT_LOCKS: 'SELECT_LOCKS',
  SELECT_ACCESS_TIME: 'SELECT_ACCESS_TIME',
  SELECT_DETAILS: 'SELECT_DETAILS',
  COMPLETE: 'COMPLETE'
}
const CreateDelegatedAccess = props => {
  const { user } = props
  const { lockId } = props.match.params

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [locks, setLocks] = useState([])
  const [accessTime, setAccessTime] = useState({
    unlimited: false,
    from: moment(),
    to: moment().add(1, 'days')
  })
  const [recipient, setDetails] = useState({
    name: '',
    phone: ''
  })

  const [step, setStep] = useState(Steps.SELECT_LOCKS)

  const finish = async () => {
    setError(null)
    setIsLoading(true)
    try {
      await axios.post(
        `/v1/organisations/${user.organisation.id}/locks/share`,
        {
          lockIds: locks.map(lock => lock.id),
          ...accessTime,
          ...recipient
        }
      )

      setStep(Steps.COMPLETE)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  const prevStep = () => {
    if (step === Steps.SELECT_ACCESS_TIME) {
      setStep(Steps.SELECT_LOCKS)
    } else if (step === Steps.SELECT_DETAILS) {
      setStep(Steps.SELECT_ACCESS_TIME)
    }
  }
  const nextStep = () => {
    if (step === Steps.SELECT_LOCKS) {
      setStep(Steps.SELECT_ACCESS_TIME)
    } else if (step === Steps.SELECT_ACCESS_TIME) {
      setStep(Steps.SELECT_DETAILS)
    } else if (step === Steps.SELECT_DETAILS) {
      finish()
    }
  }

  const handleLockSelection = selectedLocks => {
    setLocks(selectedLocks)
  }

  const handleAccessTimeChange = (key, value) => {
    setAccessTime({
      ...accessTime,
      [key]: value
    })
  }

  const handleDetailsChange = (key, value) => {
    setError(null)
    setDetails({
      ...recipient,
      [key]: value
    })
  }

  let title
  let isPreviousButtonEnabled = false
  let isNextButtonDisabled = false
  let isModalButtonsEnabled = true
  if (step === Steps.SELECT_LOCKS) {
    title = global._('Locks.Share.Create.TitleStep1')
    isNextButtonDisabled = !locks.length
  } else if (step === Steps.SELECT_ACCESS_TIME) {
    title = global._('Locks.Share.Create.TitleStep2')
    isPreviousButtonEnabled = true
    isNextButtonDisabled =
      !accessTime.unlimited && (!accessTime.from || !accessTime.to)
  } else if (step === Steps.SELECT_DETAILS) {
    title = global._('Locks.Share.Create.TitleStep3')
    isPreviousButtonEnabled = true
    isNextButtonDisabled = !recipient.name || !recipient.phone
  } else if (step === Steps.COMPLETE) {
    title = global._('Locks.Share.Create.TitleComplete')
    isModalButtonsEnabled = false
  }

  return (
    <FullscreenModal
      title={title}
      onClose={() => props.history.goBack()}
      rightActionItem='close'
      higherZIndex
      content={
        <>
          {step === Steps.SELECT_LOCKS && (
            <SelectLocks
              selectOnLoad={[lockId]}
              data={locks}
              onChange={selectedLocks => handleLockSelection(selectedLocks)}
            />
          )}
          {step === Steps.SELECT_ACCESS_TIME && (
            <SelectAccessTime
              data={accessTime}
              onChange={(key, value) => handleAccessTimeChange(key, value)}
            />
          )}
          {step === Steps.SELECT_DETAILS && (
            <SelectRecipients
              data={recipient}
              onChange={(key, value) => handleDetailsChange(key, value)}
            />
          )}
          {step === Steps.COMPLETE && (
            <Success>
              <Typography
                variant='subtitleBig'
                align='center'
                style={{ padding: '0 50px' }}
                dangerouslySetInnerHTML={{
                  __html: global._('Locks.Share.Complete', [
                    locks.map(l => l.name).join(','),
                    recipient.name
                  ])
                }}
              />
              <Button
                variant='primary'
                style={{ margin: 48, maxWidth: 160 }}
                onClick={() => props.history.goBack()}
              >
                {global._('Common.Done')}
              </Button>
            </Success>
          )}
        </>
      }
      buttonDirection={step === Steps.SELECT_DETAILS ? 'column-reverse' : 'row'}
      buttons={
        isModalButtonsEnabled && (
          <>
            {isPreviousButtonEnabled && (
              <>
                <Button
                  variant='outlined'
                  disabled={isLoading}
                  onClick={prevStep}
                >
                  {global._('Common.Previous')}
                </Button>
                <div style={{ width: 40, height: 20 }} />
              </>
            )}
            <Button
              variant='primary'
              disabled={isNextButtonDisabled || isLoading}
              onClick={nextStep}
            >
              {step === Steps.SELECT_DETAILS
                ? global._('Locks.Share.ShareAccess')
                : global._('Common.Next')}
            </Button>
            {error && (
              <Notification
                style={{ marginBottom: 20 }}
                type='error'
                message={error}
              />
            )}
          </>
        )
      }
    />
  )
}

export default withRouter(withUser(injectSheet(styles)(CreateDelegatedAccess)))
