import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  TextField
} from '@material-ui/core'
import Link from '../../components/Link'
import Header from '../../components/Header'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import { phoneNumberRegex } from '../../utils/helpers'
import Notification from '../../common/Notification'
import Success from '../../components/Success'
import useWindowDimensions from '../../utils/useWindowDimensions'
import ScrollView from '../../components/ScrollView'
import Content from '../../components/Content'
import NavigationBar from '../../components/NavigationBar'
import SsnInput, { validate } from '../../components/SsnInput'
import Password from '../../components/Password'
import PhoneNumberField from '../../components/PhoneNumberField'

const styles = {
  root: {
    backgroundColor: '#fdfdfd',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: 'url("/bg-image.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundOrigin: 'padding-box',
    minHeight: '100vh'
  },
  contentContainer: {
    width: 760,
    maxWidth: '100%',
    backgroundColor: 'var(--color-white)'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: 22,
    backgroundColor: 'var(--color-logo-black)'
  },
  formContainer: {
    padding: '40px 47px',
    minHeight: 'calc(100vh - 151px)'
  },
  successRoot: {
    paddingTop: 0
  },
  successContainer: {
    boxShadow: 'none',
    border: 'none'
  },
  input: {
    width: '100%'
  },
  divider: {
    width: 'calc(100% + 94px)',
    height: 1,
    backgroundColor: 'var(--color-divider-light)',
    marginLeft: '-47px',
    marginRight: '-47px',
    marginTop: 8,
    marginBottom: 22
  },
  '@media (max-width: 1200px)': {
    contentContainer: {
      paddingTop: 20
    }
  },
  /* Mobile */
  '@media (max-width: 900px)': {
    root: {
      background: 'none',
      backgroundColor: 'var(--color-white)',
      paddingBottom: 10
    },
    contentContainer: {
      overflow: 'auto'
    },
    formContainer: {
      padding: 'var(--section-padding-default)'
    }
  }
}

const Register = ({ classes, setAuthenticationData, history, match }) => {
  const { token } = match.params
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [isTokenVerified, setIsTokenVerified] = useState(false)
  const [initialData, setInitialData] = useState({})
  const [isComplete, setIsComplete] = useState(false)
  const [organisationName, setOrganisationName] = useState('')
  const [email, setEmail] = useState('')
  const [personalNumber, setPersonalNumber] = useState(null)
  const [error, setError] = useState(null)

  const { isMobile } = useWindowDimensions()

  const checkToken = async () => {
    try {
      const { data } = await axios.post('/v1/users/signup/check', { token })

      setPersonalNumber(data.personalNumber)
      setEmail(data.email)
      setOrganisationName(data.organisationName)
      const _initialData = {}
      _initialData.email = data.email || ''
      if (data.name) {
        const [firstName, lastName] = data.name.split(' ')
        _initialData.firstName = firstName || ''
        _initialData.lastName = lastName || ''
      }
      _initialData.phone = data.phone || ''
      setInitialData(_initialData)
      setIsTokenVerified(true)
    } catch (e) {
      if (e.response.status === 404) {
        history.push('/')
      }

      const msg = e.response ? e.response.data : e.message
      setError(global.translate(msg))
    }
    setIsInitialLoading(false)
  }

  useEffect(() => {
    checkToken()
  }, [])

  const handleSignup = async values => {
    const user = {
      email: values.email,
      personalNumber: values.personalNumber || null,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      password: values.password
    }

    try {
      const { data } = await axios.post('/v1/users/signup', { user, token })
      setAuthenticationData(data)
      setIsComplete(true)
      setError(null)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global.translate(msg))
    }
  }

  const translateErrorMessage = err => {
    if (err === 'ERR_DUPLICATE_PERSONAL_NUMBER') {
      return global._('Errors.ERR_DUPLICATE_PERSONAL_NUMBER')
    }

    return err
  }

  const validationSchema = Yup.object().shape({
    email: !email && Yup.string().email().required(global._('Common.Required')),
    firstName: Yup.string().required(global._('Common.Required')),
    lastName: Yup.string().required(global._('Common.Required')),
    phone: Yup.string()
      .matches(phoneNumberRegex, global._('Common.PhoneNumberInvalid'))
      .required(global._('Common.Required')),
    password: Yup.string().min(8).required(global._('Common.Required')),
    repeatPassword: Yup.string().when('password', {
      is: val => val && val.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref('password')], global._('MyAccount.PasswordMismatch'))
        .required()
    }),
    terms: Yup.bool()
      .oneOf([true], global._('Common.Required'))
      .required(global._('Common.Required'))
  })

  const pageTitle = () =>
    isTokenVerified && !isInitialLoading
      ? `${global._('Register.InvitedTo')} ${organisationName}`
      : ''

  const content = (
    <>
      {!isMobile && isTokenVerified && !isInitialLoading && (
        <div className={classes.titleContainer}>
          <Typography variant='h2' style={{ color: 'var(--color-white)' }}>
            {pageTitle()}
          </Typography>
        </div>
      )}
      {isComplete ? (
        <div
          className={classes.formContainer}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Success classes={classes}>
            <div style={{ maxWidth: '100%' }}>
              <Typography variant='title' align='center'>
                {global._('Register.CompleteText')}
              </Typography>
            </div>
            <div style={{ marginTop: 50 }}>
              <Button variant='primary' onClick={() => history.push('/')}>
                {global._('Register.CompleteButtonTitle')}
              </Button>
            </div>
          </Success>
        </div>
      ) : (
        isTokenVerified && (
          <div className={classes.formContainer}>
            <Formik
              initialValues={{
                email: initialData.email,
                personalNumber,
                firstName: initialData.firstName,
                lastName: initialData.lastName,
                phone: initialData.phone,
                password: '',
                repeatPassword: '',
                terms: false
              }}
              validationSchema={validationSchema}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                setTouched,
                setFieldValue
              }) => (
                <Form style={{ width: '100%' }}>
                  <Grid container spacing={3} style={{ marginBottom: 1 }}>
                    <Grid item xs={12}>
                      <TextField
                        disabled={email}
                        value={email || values.email}
                        name='email'
                        className={classes.input}
                        label={global._('Register.EmailPlaceholder')}
                        variant='filled'
                        onChange={!email && handleChange}
                        onBlur={!email && handleBlur}
                        error={!!errors.email}
                        helperText={global._('Common.Required')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SsnInput
                        value={personalNumber || values.personalNumber}
                        name='personalNumber'
                        className={classes.input}
                        label={global._('PersonalNumber.Label')}
                        helperText={global._('Register.PersonalNumberUsage')}
                        disabled={personalNumber}
                        // onSsnValid={({ ssn }) =>
                        //   setFieldValue('personalNumber', ssn, false)
                        // }
                        onChange={e => {
                          setFieldValue('personalNumber', e.target.value, false)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.firstName}
                        value={values.firstName}
                        name='firstName'
                        className={classes.input}
                        label={global._('Register.FirstNamePlaceholder')}
                        helperText={global._('Common.Required')}
                        variant='filled'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.lastName}
                        value={values.lastName}
                        name='lastName'
                        className={classes.input}
                        label={global._('Register.LastNamePlaceholder')}
                        helperText={global._('Common.Required')}
                        variant='filled'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PhoneNumberField
                        onChange={value => setFieldValue('phone', value)}
                        onBlur={handleBlur}
                        error={!!errors.phone}
                        value={values.phone}
                        name='phone'
                        className={classes.input}
                        label={global._('Register.Telephone')}
                        helperText={
                          errors.phone
                            ? errors.phone
                            : global._('Common.Required')
                        }
                        variant='filled'
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Password
                        data-cy='password-new'
                        autoComplete='new-password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.password}
                        value={values.password}
                        name='password'
                        className={classes.input}
                        label={global._('Register.PasswordPlaceholder')}
                        helperText={global._('Register.PasswordHelpText')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Password
                        data-cy='password-repeat'
                        autoComplete='new-password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.repeatPassword}
                        value={values.repeatPassword}
                        name='repeatPassword'
                        className={classes.input}
                        label={global._('Register.PasswordRepeatPlaceholder')}
                      />
                    </Grid>
                    <div className={classes.divider} />
                    <Grid item xs={12}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value='accepted'
                              checked={values.terms}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name='terms'
                              color='primary'
                            />
                          }
                          style={{ userSelect: 'none' }}
                          label={
                            <span>
                              {global._('Register.TermsAndConditions')}{' '}
                              <Link to={global._('POLICY_URL')} blank>
                                {global._('Register.IntegrityPolicy')}
                              </Link>
                            </span>
                          }
                          className={classes.checkboxWrapper}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                      {error && (
                        <Notification
                          type='error'
                          message={translateErrorMessage(error)}
                          style={{ marginBottom: 20 }}
                        />
                      )}
                      <Button
                        variant='primary'
                        disabled={
                          (values.personalNumber &&
                            !validate(values.personalNumber)) ||
                          Object.keys(touched).length === 0 ||
                          Object.keys(errors).length
                        }
                        onClick={() => {
                          handleSignup(values)
                          setTouched({})
                        }}
                      >
                        {global._('Register.SignupButton')}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        )
      )}
    </>
  )

  if (isMobile) {
    return (
      <div className={classes.root}>
        <Header />
        <Content style={{ backgroundColor: 'var(--color-white)' }}>
          <NavigationBar
            title={pageTitle()}
            backAction={() => history.push('/')}
          />
          <ScrollView id='scrollable-container' fullHeight noBottomPadding>
            {content}
          </ScrollView>
        </Content>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.contentContainer}>{content}</div>
    </div>
  )
}

Register.propTypes = {
  classes: PropTypes.object.isRequired,
  setAuthenticationData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const RegisterWithStyle = injectSheet(styles)(Register)
export default RegisterWithStyle
