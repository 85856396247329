import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import useWindowDimensions from '../utils/useWindowDimensions'

const styles = {
  root: {}
}

const RadioButtonGroup = ({
  value,
  onChange,
  children,
  withoutBorder,
  removeLastBorder
}) => {
  const { isMobile } = useWindowDimensions()

  const mappedChildren = children.map((child, idx) => {
    const props = {
      ...child.props,
      onClick: () => onChange(child.props.value),
      checked: child.props.value === value
    }

    const isLastItem = idx === children.length - 1

    if (isMobile || !isLastItem) {
      let borderBottom = '1px solid var(--color-divider-light)'

      if (withoutBorder || (removeLastBorder && isLastItem)) {
        borderBottom = ''
      }

      props.columnStyle = {
        borderBottom
      }
    }

    return React.cloneElement(child, props)
  })

  return <div>{mappedChildren}</div>
}

RadioButtonGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired
}

RadioButtonGroup.defaultProps = {
  value: null
}

const RadioButtonGroupWithStyles = injectSheet(styles)(RadioButtonGroup)
export default RadioButtonGroupWithStyles
