import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import queryString from 'querystring'
import InfiniteScroll from './InfiniteScroll'
import Typography from './Typography'
import NoResultsMessage from './NoResultsMessage'
import Row from './Row'
import Column from './Column'
import { UserRoles } from '../utils/constants'

const styles = {
  root: {
    maxWidth: '100%',
    width: '100%'
  },
  containerMobile: {
    borderBottom: props =>
      props.fullBorder ? undefined : '1px solid var(--color-primary-tone-5)',
    width: '1%'
  },
  icon: {
    backgroundColor: 'transparent',
    color: 'var(--color-middle-grey)'
  },
  iconNew: {
    backgroundColor: 'transparent',
    color: 'var(--color-primary)'
  },
  column: {
    paddingTop: props => (props.largerPadding ? 17 : 14),
    paddingBottom: props => (props.largerPadding ? 17 : 11)
  }
}

const ObjectListMobile = ({
  classes,
  objects,
  isLoadingObjects,
  noResultsMessage,
  columns,
  history,
  filter,
  objectPermalink,
  canFetchMoreObjects,
  fetchMoreObjects,
  user,
  hasPadding,
  rowsAreClickable,
  rowOnClick,
  noIcon,
  singleColumn,
  hideArrow,
  rowStyle,
  lessPadding,
  fullBorder,
  identifier
}) => {
  if (!columns) {
    return null
  }
  let rowActionButton
  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)

  const columnIcon = obj => {
    const iconObj = columns.find(col => col.key === 'icon')

    return (
      <Icon
        className={`${classes.icon} columnIcon ${
          obj &&
          isPartnerAdmin &&
          !obj.isRead &&
          typeof obj.isRead === 'boolean'
            ? classes.iconNew
            : ''
        }`}
        style={{
          marginLeft: hasPadding && 'var(--section-padding-default)',
          ...iconObj.style
        }}>
        {iconObj.format(obj)}
      </Icon>
    )
  }

  const columnObject = obj => {
    const colObj = columns.find(col => col.key === 'object')

    return <>{colObj.format(obj)}</>
  }

  if (
    !isLoadingObjects &&
    noResultsMessage &&
    (!objects || !Array.isArray(objects) || !objects.length)
  ) {
    return <NoResultsMessage content={noResultsMessage} />
  }

  return objects && Array.isArray(objects) && objects.length > 0 ? (
    <InfiniteScroll
      fetchMore={fetchMoreObjects}
      hasMore={canFetchMoreObjects}
      loading={isLoadingObjects}
      elementId='scrollable-container'>
      <Column
        align='flex-start'
        justify='space-between'
        style={{ paddingBottom: 80, width: '100%' }}>
        {objects.map(obj => (
          <Row
            justify={singleColumn ? 'center' : 'flex-start'}
            key={
              identifier ? identifier(obj) : obj.identifier || `obj-${obj.id}`
            }
            style={{
              width: '100%',
              height: singleColumn ? 75 : undefined,
              borderBottom: fullBorder
                ? '1px solid var(--color-primary-tone-5)'
                : undefined,
              ...rowStyle
            }}>
            {noIcon ? (
              <Column>{columnObject(obj)}</Column>
            ) : (
              <Column
                style={{
                  padding: lessPadding
                    ? '5px 18px 5px 18px'
                    : '5px 28px 5px 28px'
                }}>
                {columnIcon(obj)}
              </Column>
            )}
            <Column
              justify={singleColumn ? 'center' : 'flex-start'}
              className={classes.containerMobile}
              // eslint-disable-next-line no-nested-ternary
              onClick={
                rowsAreClickable
                  ? () =>
                      history.push(
                        `/${obj.permalink || objectPermalink}/${obj.id}${
                          filter ? `?${queryString.stringify(filter)}` : ''
                        }`
                      )
                  : rowOnClick
                  ? () => rowOnClick(obj)
                  : undefined
              }
              style={{
                flexGrow: 1,
                paddingRight: hasPadding && 'var(--section-padding-default)',
                height: 'inherit'
              }}>
              <Row justify='space-between' style={{ width: '100%' }}>
                <Column
                  align='flex-start'
                  justify='flex-start'
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    wordBreak: 'break-all',
                    flexGrow: 1
                  }}
                  className={classes.column}>
                  {columns.map(col => {
                    rowActionButton = null
                    if (col.hiddenFor) return null

                    if (col.key === 'rowactionbutton') {
                      rowActionButton = col.format(obj)
                    } else if (
                      col.key !== 'icon' &&
                      col.key !== 'rowactionbutton'
                    ) {
                      let value

                      if (col.format) {
                        value = col.format(
                          obj[col.key],
                          col.secondKey ? obj[col.secondKey] : null,
                          obj
                        )
                      } else {
                        value = obj[col.key]
                      }

                      return (
                        <div
                          style={{
                            width: '100%',
                            lineHeight: 1.1,
                            display:
                              col.hideCol && col.hideCol(obj[col.key]) && 'none'
                          }}
                          key={`col-${col.key}`}>
                          <Typography
                            bold={col.bold ? 600 : false}
                            ellipsis
                            variant={col.variant ? col.variant : 'bodyFaded'}
                            style={{
                              lineHeight: 1.3,
                              display: 'inline-block',
                              width: '100%'
                            }}>
                            {value}
                          </Typography>
                        </div>
                      )
                    }
                  })}
                </Column>
                {(rowsAreClickable || rowOnClick) &&
                  (!hideArrow ||
                    (hideArrow &&
                      typeof hideArrow === 'function' &&
                      !hideArrow(obj))) && (
                    <Column className={classes.column}>
                      <Icon style={{ fontSize: 20, marginRight: 20 }}>
                        keyboard_arrow_right
                      </Icon>
                    </Column>
                  )}
                {rowActionButton && (
                  <Column
                    style={{ fontSize: 20, marginRight: 20 }}
                    className={classes.column}>
                    {rowActionButton}
                  </Column>
                )}
              </Row>
            </Column>
          </Row>
        ))}
      </Column>
    </InfiniteScroll>
  ) : null
}

ObjectListMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  objects: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  isLoadingObjects: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  columns: PropTypes.array.isRequired,
  objectPermalink: PropTypes.string,
  canFetchMoreObjects: PropTypes.bool,
  fetchMoreObjects: PropTypes.func,
  user: PropTypes.object,
  filter: PropTypes.object,
  rowsAreClickable: PropTypes.bool,
  rowOnClick: PropTypes.func,
  noIcon: PropTypes.bool,
  hasPadding: PropTypes.bool,
  singleColumn: PropTypes.bool,
  hideArrow: PropTypes.func
}

ObjectListMobile.defaultProps = {
  history: null,
  canFetchMoreObjects: false,
  fetchMoreObjects: null,
  objects: null,
  user: null,
  filter: {},
  rowsAreClickable: true,
  noIcon: false,
  hasPadding: false,
  isLoadingObjects: false,
  noResultsMessage: '',
  singleColumn: false,
  objectPermalink: null,
  rowOnClick: null,
  hideArrow: () => false
}

const ObjectListMobileWithStyles = injectSheet(styles)(ObjectListMobile)
export default ObjectListMobileWithStyles
