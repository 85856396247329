import { BreadcrumbItem } from 'types'

export const serviceRequestsBreadcrumbs: BreadcrumbItem[] = [
  {
    name: translate('Breadcrumbs.Home'),
    path: '/'
  },
  {
    name: translate('Breadcrumbs.Admin'),
    path: '/admin'
  },
  {
    name: translate('Breadcrumbs.Apartments'),
    path: '/admin/apartments'
  },
  {
    name: translate('Breadcrumbs.ServiceRequests'),
    path: '/admin/apartments/servicerequests'
  }
]
