import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'
import moment from 'moment'
import {
  IServiceRequest,
  IServiceRequestGroup,
  IServiceRequestStatus
} from './types'
import injectSheet, { WithStylesProps } from 'react-jss'
import React, { MouseEventHandler } from 'react'
import { usePersistState } from 'src/hooks/usePersistState'
import { ServiceRequestTable } from './ServiceRequestTable'
import { excel, newWindowPrint } from 'src/utils/export'
import { table, group } from 'console'
import { size } from 'lodash'
import { text } from 'stream/consumers'

interface TableProps extends WithStylesProps<typeof styles> {
  data: IServiceRequestGroup[]
}

const styles = {
  tableHeaderCell: {
    fontWeight: 'bold!important',
    fontSize: '14px!important'
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee'
    }
  }
}
export const ServiceRequestGroupTable = injectSheet(styles)(
  (props: TableProps) => {
    const { data, classes } = props
    const [expandedRowId, setExpandedRowId] = usePersistState(
      'ServiceRequest/List/ExpandedRowId',
      ''
    )

    const handlePrint = (group: IServiceRequestGroup) => (evt: any) => {
      evt?.stopPropagation()
      evt?.preventDefault()
      newWindowPrint(
        `Låsportalen - ${translate('ServiceRequests.ServiceRequest')}`,
        `
        <style>
        * {
          font-size: 11px;
          text-align: left;
          font-family: "Open Sans", sans-serif;
        }
        table {
          width: 100%;
        }
        th,td {
          padding: 6px 10px;
        }
        table, th, tr, td {
          border-collapse: collapse;
          border-bottom: 1px solid #ddd;
        }

        </style>
        <div style="padding:0;margin:0;display:flex;flex-direction:row;justify-content:space-between;">
          <h1>${translate('ServiceRequests.ServiceRequest')}</h1>
          <h1>${group.organisation?.name}</h1>
        </div>
        <h2>${group.message}</h2>
        <h3 style="margin-bottom:12px;padding-bottom:12px;border-bottom:2px solid black;">
          ${moment(group.from).format('YYYY-MM-DD HH:mm')}
            - 
          ${moment(group.to).format('YYYY-MM-DD HH:mm')}
        </h3>
        <table>
          <thead>
            <tr>
              <th>${translate('CaseDetails.ApartmentAddress')}</th>
              <th>${translate('Common.Apartment')}</th>
              <th>Tillträde</th>
              <th>${translate('ServiceRequests.Table.AnsweredBy')}</th>
              <th>${translate('Common.Message')}</th>
              <th style="width:30px;"/>
            </tr>
          </thead>
          <tbody>
            ${group.items
              .map(
                item => `
              <tr>
                <td>${item.apartment?.address ?? ''}</td>
                <td>${item.apartment?.refId ?? ''}</td>
                <td>${
                  item.status === IServiceRequestStatus.ACCEPTED
                    ? 'Godkänd'
                    : item.status === IServiceRequestStatus.DENIED
                    ? 'Nekad'
                    : 'Inväntar svar'
                }</td>
                <td>${item.user?.name ?? '(saknas)'}</td>
                <td>${item.userMessage ?? ''}</td>
                <td style="width:30px;"/>
              </tr>
            `
              )
              .join('')}
          </tbody>
        </table>
      `
      )
      return false
    }

    const handleExportToExcel = (group: IServiceRequestGroup) => (evt: any) => {
      evt?.stopPropagation()
      evt?.preventDefault()
      excel({
        filename: 'ServiceRequests.xlsx',
        sheets: [
          {
            name: 'ServiceRequests',
            columns: [
              {
                label: 'Adress',
                value: (item: IServiceRequest) => item.apartment?.address ?? ''
              },
              {
                label: 'Lägenhet',
                value: (item: IServiceRequest) => item.apartment?.refId ?? ''
              },
              {
                label: 'Tillträde',
                value: (item: IServiceRequest) =>
                  item.status === IServiceRequestStatus.ACCEPTED
                    ? 'Godkänd'
                    : item.status === IServiceRequestStatus.DENIED
                    ? 'Nekad'
                    : 'Inväntar svar'
              },
              {
                label: 'Svarades av',
                value: (item: IServiceRequest) => item.user?.name ?? '(saknas)'
              },
              {
                label: 'Meddelande',
                value: (item: IServiceRequest) => item.message ?? ''
              },
              {
                label: 'Tillträde från',
                value: (item: IServiceRequest) =>
                  moment(item.from).format('YYYY-MM-DD HH:mm')
              },
              {
                label: 'Tillträde till',
                value: (item: IServiceRequest) =>
                  moment(item.to).format('YYYY-MM-DD HH:mm')
              }
            ],
            data: group.items
          }
        ]
      })
      return false
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell} />
            <TableCell className={classes.tableHeaderCell}>
              {translate('Common.CreatedAt')}
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>
              {translate('ServiceRequests.GroupTable.Message')}
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>
              {translate('ServiceRequests.GroupTable.AccessTime')}
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              style={{ width: 200 }}
            >
              {translate('ServiceRequests.GroupTable.ApartmentCount')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((group: IServiceRequestGroup) => (
            <React.Fragment key={group.id}>
              <TableRow
                className={classes.tableRow}
                onClick={() => {
                  if (expandedRowId === group.id) {
                    setExpandedRowId('')
                  } else {
                    setExpandedRowId(group.id)
                  }
                  // history.push(`/service-requests/${group.id}`)
                }}
              >
                <TableCell
                  style={{ marginRight: 0, paddingRight: 0, width: 40 }}
                >
                  <div
                    style={{
                      width: 32,
                      height: 32,
                      marginRight: 8,
                      backgroundColor: 'var(--color-light-grey)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%'
                    }}
                  >
                    <Icon
                      style={{
                        color: 'var(--color-middle-grey)',
                        fontSize: 14
                      }}
                    >
                      build
                    </Icon>
                  </div>
                </TableCell>
                <TableCell
                  style={{ marginRight: 0, paddingRight: 0, width: 150 }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {moment(group.createdAt).calendar()}
                  </div>
                </TableCell>
                <TableCell
                  style={{ marginRight: 0, paddingRight: 0, width: 240 }}
                >
                  {group.message}
                </TableCell>
                <TableCell style={{ marginRight: 0, paddingRight: 0 }}>
                  {moment(group.from).format('D MMM HH:mm')}
                  <ArrowRight />
                  {moment(group.to).format('D MMM HH:mm')}
                </TableCell>
                <TableCell>{group.items.length}</TableCell>
                {/* <TableCell style={{ width: 40 }}>
                  <IconButton onClick={handleExportToExcel(group)}>
                    <Icon>print</Icon>
                  </IconButton>
                </TableCell> */}
                <TableCell style={{ width: 40 }}>
                  <IconButton onClick={handlePrint(group)}>
                    <Icon>print</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
              {expandedRowId === group.id && (
                <TableRow style={{ backgroundColor: '#f4f4f4' }}>
                  <TableCell colSpan={6}>
                    <ServiceRequestTable data={group.items} />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    )
  }
)
