import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import FormControl from '@material-ui/core/FormControl'
import FilledInput from '@material-ui/core/FilledInput'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withRouter } from 'react-router'
import { UserRoles } from '../../utils/constants'
import Notification from '../../common/Notification'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import AsyncSelect from '../../components/AsyncSelect'
import FullscreenModal from '../../components/FullscreenModal'
import withUser from '../../utils/withUser'

const ObjectModal = props => {
  const { onClose, fullscreen, defaultOrganisation, history, user } = props
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [organisationId, setOrganisationId] = useState(defaultOrganisation.id)
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')

  const isAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const defaultOrganisationOption = {
    value: defaultOrganisation.id,
    label: defaultOrganisation.name
  }
  const handleCreate = async () => {
    setError(null)
    setIsLoading(true)
    try {
      const data = {
        name,
        address,
        organisationId
      }
      const { data: property } = await axios.post('/v1/properties', data)
      history.push(`/properties/${property.id}`)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
      setIsLoading(false)
    }
  }

  let Wrapper
  if (fullscreen) {
    Wrapper = FullscreenModal
  } else {
    Wrapper = Modal
  }
  return (
    <Wrapper
      onClose={onClose}
      title={global._('Properties.CreateModal.Title')}
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: fullscreen ? 20 : undefined
          }}>
          {isAdmin && (
            <AsyncSelect
              isSearchable
              isClearable={false}
              labelKey='name'
              helpText={global._('Common.Required')}
              defaultValue={defaultOrganisationOption}
              placeholder={global._('Common.SelectOrganisation')}
              onChange={opt => setOrganisationId(opt.value.id)}
              url={'/v1/organisations?search={{input}}&orderBy=name&order=asc'}
              style={{ marginBottom: 24 }}
            />
          )}
          <FormControl
            variant='filled'
            style={{ width: '100%', marginTop: isAdmin ? 24 : undefined }}>
            <InputLabel>{global._('Properties.CreateModal.Name')}</InputLabel>
            <FilledInput
              type='text'
              data-cy='input-property-name'
              onChange={e => setName(e.target.value)}
              value={name}
            />
            <FormHelperText>{global._('Common.Required')}</FormHelperText>
          </FormControl>
          <FormControl
            variant='filled'
            style={{ width: '100%', marginTop: 24 }}>
            <InputLabel>
              {global._('Properties.CreateModal.Address')}
            </InputLabel>
            <FilledInput
              type='text'
              onChange={e => setAddress(e.target.value)}
              value={address}
            />
            <FormHelperText>{global._('Common.NotRequired')}</FormHelperText>
          </FormControl>
          <div style={{ height: 16 }} />
          {!fullscreen && error && (
            <Notification
              style={{ marginTop: 20 }}
              type='error'
              message={error}
            />
          )}
        </div>
      }
      buttons={[
        !fullscreen && (
          <Button
            key='createPropertyModal-btn-1'
            variant='none'
            onClick={onClose}
            style={{ marginRight: 10 }}>
            {global._('Common.Cancel')}
          </Button>
        ),
        fullscreen && error && (
          <Notification
            key='item-modal-error'
            style={{ marginBottom: 20 }}
            type='error'
            message={error}
          />
        ),
        <Button
          data-cy='button-create-property'
          key='createPropertyModal-btn-2'
          variant='primary'
          disabled={!name || !organisationId}
          loading={isLoading}
          onClick={handleCreate}>
          {global._('Common.Add')}
        </Button>
      ]}
    />
  )
}

ObjectModal.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  defaultOrganisation: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool
}

ObjectModal.defaultProps = {
  fullscreen: false
}

const ObjectModalWithUserAndRouter = withRouter(withUser(ObjectModal))
export default ObjectModalWithUserAndRouter
