import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon } from '@material-ui/core'
import Typography from 'src/components/Typography'
import VideoInstructionsModal from 'src/components/VideoInstructionsModal'
import { history } from 'src/redux/store'
import useWindowDimensions from 'src/utils/useWindowDimensions'

const styles = {
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid var(--color-divider-light)',
    width: '100%'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '-1px'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 24px',
    userSelect: 'none',
    cursor: 'pointer',
    '& .material-icons': {
      fontSize: 18
    }
  },
  label: {},
  '@media (max-width: 900px)': {
    root: {
      backgroundColor: props => (props.whiteBg ? 'white' : 'transparent')
    },
    container: {
      marginBottom: 0,
      flexWrap: 'nowrap'
    },
    item: {
      flex: 1,
      padding: 8,
      paddingTop: 0
    }
  },
  '@media (max-width: 375px)': {
    item: {
      '& > span:not(.material-icons)': {
        fontSize: props => (props.largeOnMobile ? undefined : 11)
      }
    }
  }
}

const TabNavigator = props => {
  const {
    classes,
    items,
    itemStyle,
    value,
    onChange,
    style,
    videoId,
    ...otherProps
  } = props

  const { isMobile } = useWindowDimensions()

  let containerStyles = {}

  if (!isMobile) {
    containerStyles = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  }

  return (
    <div
      className={classes.root}
      {...otherProps}
      style={{
        ...style,
        ...containerStyles
      }}
    >
      <div className={classes.container}>
        {items.map(item => {
          if (typeof item.visible !== 'undefined' && !item.visible) {
            return null
          }

          let activeItem = false
          let onClick
          if (typeof value !== 'undefined' && item.value === value) {
            activeItem = true
          } else if (
            window.location.pathname.replace(/\/+$/, '') === item.path
          ) {
            activeItem = true
          } else if (typeof onChange === 'function') {
            /* only trigger if tab is not active */
            onClick = () => onChange(item)
          }

          return (
            <div
              data-cy={`button-type-${item.key}`}
              className={classes.item}
              key={`nav-item-${item.label}`}
              style={{
                borderBottom: `2px solid ${
                  activeItem ? 'var(--color-primary)' : 'transparent'
                }`,
                ...itemStyle
              }}
              onClick={onClick}
            >
              <Icon
                style={{
                  marginBottom: 10,
                  color: activeItem
                    ? 'var(--color-primary)'
                    : 'var(--color-middle-grey)'
                }}
              >
                {item.icon}
              </Icon>
              <Typography
                variant='body2'
                bold
                style={{ textTransform: 'uppercase' }}
              >
                {item.label}
              </Typography>
            </div>
          )
        })}
      </div>
      {!isMobile && videoId && (
        <div style={{ marginRight: 20 }}>
          <VideoInstructionsModal videoId={videoId} />
        </div>
      )}
    </div>
  )
}

TabNavigator.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  itemStyle: PropTypes.object,
  style: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  videoId: PropTypes.string
}

TabNavigator.defaultProps = {
  itemStyle: {},
  value: undefined,
  style: {},
  videoId: undefined,
  onChange: navigationItem => {
    history.push(navigationItem.path)
  }
}

export default injectSheet(styles)(TabNavigator)
