import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import Icon from '@material-ui/core/Icon'
import Button from './Button'

const styles = {
  root: {
    display: 'flex',
    backgroundColor: '#f0f0f0',
    border: '1px solid #8e8e8e',
    borderRadius: 3,
    padding: '10px 15px',
    userSelect: 'none',
    width: '100%',
    alignItems: 'center',
    '&:hover': {
      cursor: props =>
        props.onClick || props.src || props.data ? 'pointer' : 'cursor'
    }
  },
  itemBadge: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  clickableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    fontWeight: 600
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  '@media (max-width: 900px)': {
    root: {
      padding: '9px 15px'
    },
    text: {
      fontWeight: 600
    }
  }
}

const ItemBadge = props => {
  const {
    classes,
    onDelete,
    onEndIconClick,
    icon,
    endIcon,
    label,
    onClick,
    hideDeleteBtn,
    containerStyle
  } = props

  return (
    <div className={classes.root} style={containerStyle} onClick={onClick}>
      <div className={classes.itemBadge}>
        <Icon style={{ fontSize: 28, marginRight: 15 }}>{icon}</Icon>
        <span className={classnames(classes.text, classes.label)}>{label}</span>
      </div>
      {typeof endIcon === 'string' && !hideDeleteBtn && (
        <Button
          variant='icon'
          icon={endIcon}
          style={{
            marginLeft: 'auto',
            maxWidth: 34,
            padding: 0
          }}
          onClick={e => {
            if (onDelete) {
              e.stopPropagation()
              onDelete()
            }

            if (onEndIconClick) {
              e.stopPropagation()
              onEndIconClick()
            }
          }}
        />
      )}
      {typeof endIcon === 'string' && endIcon !== 'delete' && hideDeleteBtn && (
        <Icon style={{ marginLeft: 'auto' }}>{endIcon}</Icon>
      )}
      {typeof endIcon === 'object' && endIcon}
    </div>
  )
}

ItemBadge.propTypes = {
  classes: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  endIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  containerStyle: PropTypes.object
}

ItemBadge.defaultProps = {
  endIcon: 'delete',
  onDelete: undefined,
  onClick: undefined,
  containerStyle: undefined
}

export default injectSheet(styles)(ItemBadge)
