import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Paper from 'src/components/Paper'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import Modal from 'src/components/Modal'
import Link from 'src/components/Link'
import Notification from 'src/common/Notification'
import Signing from './Signing'
import Icon from '@material-ui/core/Icon'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import axios from 'axios'
import { parseReceiptTermsContent } from 'src/utils/helpers'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import PublicTemporaryKeyLoanMobile from './TemporaryKeyLoanMobile'
import { Country } from '../../../../utils/constants'

const styles = {
  container: {
    backgroundImage: 'url("/bg-image.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundOrigin: 'padding-box',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 0
  }
}

const sectionPadding = 30

const PublicTemporaryKeyLoan = ({ classes, match }) => {
  const [error, setError] = useState(null)
  const [keyReceipts, setKeyReceipts] = useState([])
  const [organisation, setOrganisation] = useState(null)
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [isCollecting, setIsCollecting] = useState(false)
  const [checkedCheckboxes, setCheckedCheckboxes] = useState({
    terms: false,
    policy: false
  })

  const { token } = match.params

  const getKeys = async () => {
    try {
      const { data } = await axios.get(`/v1/flows/keyloan/keys/${token}`)

      setKeyReceipts(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    getKeys()
  }, [])

  useEffect(() => {
    if (keyReceipts.length > 0) {
      setOrganisation(keyReceipts[0].organisation)
    }
  }, [keyReceipts])

  const { isMobile } = useWindowDimensions()

  const pageTitle = (
    <Typography
      variant='title'
      bold
      style={{ marginTop: error || isMobile ? 0 : 80 }}
    >
      {global._('PublicTemporaryKeyLoan.Title')}
    </Typography>
  )

  const orgInfo = (
    <div
      style={{
        marginTop: isMobile ? 0 : 40,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <div>
        <span
          style={{
            width: 60,
            height: 60,
            background: 'var(--color-light-grey)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%'
          }}
        >
          <Icon style={{ fontSize: 30, color: '#8e8e8e' }}>store</Icon>
        </span>
      </div>
      {organisation && (
        <div style={{ marginLeft: 20 }}>
          <Typography block faded variant='body2'>
            {organisation.organisationNumber}
          </Typography>
          <Typography block variant='subtitle'>
            {organisation.name}
          </Typography>
        </div>
      )}
    </div>
  )

  const signButton = (
    <Button
      variant='bankid'
      onClick={() => setIsCollecting(true)}
      country={
        keyReceipts.length > 0 ? keyReceipts[0].user.country : Country.SE
      }
      disabled={Object.keys(checkedCheckboxes).find(
        checkbox => !checkedCheckboxes[checkbox]
      )}
    >
      {global._('PublicTemporaryKeyLoan.RequestButton')}
    </Button>
  )

  const checkboxes = (
    <FormGroup>
      <FormControlLabel
        style={{ userSelect: 'none' }}
        control={
          <Checkbox
            onChange={e => {
              const status = e.target.checked
              setCheckedCheckboxes(prevValues => {
                const val = { ...prevValues }
                val.terms = status
                return val
              })
            }}
            value='terms'
          />
        }
        label={global._('PublicTemporaryKeyLoan.AcceptTerms')}
      />
      <FormControlLabel
        style={{ userSelect: 'none' }}
        control={
          <Checkbox
            onChange={e => {
              const status = e.target.checked
              setCheckedCheckboxes(prevValues => {
                const val = { ...prevValues }
                val.policy = status
                return val
              })
            }}
            value='policy'
          />
        }
        label={
          <span>
            {global._('PublicTemporaryKeyLoan.AcceptIntegrityPolicy')}{' '}
            <Link to={global._('POLICY_URL')} blank>
              {global._('PublicTemporaryKeyLoan.IntegrityPolicy')}
            </Link>
          </span>
        }
      />
    </FormGroup>
  )

  if (isCollecting) {
    return (
      <Signing
        token={token}
        setError={setError}
        setIsCollecting={setIsCollecting}
      />
    )
  }

  if (isMobile) {
    return (
      <PublicTemporaryKeyLoanMobile
        signButton={signButton}
        error={error}
        checkboxes={checkboxes}
        receipts={keyReceipts}
        pageTitle={pageTitle}
        orgInfo={orgInfo}
        terms={
          keyReceipts.length > 0
            ? parseReceiptTermsContent(keyReceipts[0].content)
            : undefined
        }
      />
    )
  }

  return (
    <div className={classes.container}>
      {showTermsModal && keyReceipts.length > 0 && (
        <Modal
          title={global._('Common.Terms')}
          onClose={() => setShowTermsModal(false)}
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: parseReceiptTermsContent(keyReceipts[0].content)
              }}
            />
          }
          buttons={[
            <Button variant='none' onClick={() => setShowTermsModal(false)}>
              {global._('Common.Close')}
            </Button>
          ]}
        />
      )}
      <Paper fixedWidth='1024' style={{ paddingTop: 40, paddingBottom: 100 }}>
        {error && (
          <Notification
            style={{
              marginTop: keyReceipts.length > 0 ? 60 : 0,
              marginBottom: 20
            }}
            type='error'
            message={error}
          />
        )}

        {keyReceipts.length > 0 && (
          <>
            {pageTitle}
            {orgInfo}
            <div
              style={{
                marginTop: 30,
                borderTop: '1px solid var(--color-divider-light)',
                width: 'calc(100% + 60px)',
                marginLeft: '-30px',
                marginRight: '-30px'
              }}
            >
              <div style={{ padding: `15px ${sectionPadding}px` }}>
                <Typography block variant='subtitle'>
                  {global._('PublicTemporaryKeyLoan.Keys')}
                </Typography>
              </div>
              <Table style={{ background: 'var(--color-material-grey)' }}>
                <TableHead
                  style={{
                    paddingLeft: sectionPadding,
                    paddingRight: sectionPadding
                  }}
                >
                  <TableRow>
                    <TableCell style={{ width: '7%', paddingLeft: 30 }}>
                      <Typography
                        variant='body2'
                        style={{ textTransform: 'none' }}
                        bold={700}
                      >
                        {global._('PublicTemporaryKeyLoan.Marking')}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: '10%', paddingLeft: 30 }}>
                      <Typography
                        variant='body2'
                        style={{ textTransform: 'none' }}
                        bold={700}
                      >
                        {global._('PublicTemporaryKeyLoan.Name')}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: '20%', paddingLeft: 30 }}>
                      <Typography
                        variant='body2'
                        style={{ textTransform: 'none' }}
                        bold={700}
                      >
                        {global._('PublicTemporaryKeyLoan.ReturnDate')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keyReceipts.map(receipt => (
                    <TableRow>
                      <TableCell style={{ paddingLeft: 30 }}>
                        <Typography variant='body2'>
                          {receipt.key.marking}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ paddingLeft: 30 }}>
                        <Typography variant='body2'>
                          {receipt.key.name}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ paddingLeft: 30 }}>
                        <Typography variant='body2'>
                          {receipt.key.pendingExpectedReturnDate ||
                            global._('Common.UntilFurtherNotice')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <div style={{ marginTop: 40 }}>
              <Button
                variant='outlined'
                onClick={() => setShowTermsModal(true)}
              >
                {global._('PublicTemporaryKeyLoan.ShowTerms')}
              </Button>
            </div>
            <div style={{ marginTop: 20 }}>{checkboxes}</div>
            <div style={{ marginTop: 20 }}>{signButton}</div>
          </>
        )}
      </Paper>
    </div>
  )
}

PublicTemporaryKeyLoan.propTypes = {
  classes: PropTypes.object.isRequired
}

PublicTemporaryKeyLoan.defaultProps = {}

const PublicTemporaryKeyLoanWithStylesAndUser = injectSheet(styles)(
  PublicTemporaryKeyLoan
)
export default PublicTemporaryKeyLoanWithStylesAndUser
