import { connect } from 'react-redux'
import { impersonate } from '../../redux/reducers/users/list'
import ControlDetails from './ControlDetails'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  impersonate: (userId, orgId) => {
    dispatch(impersonate(userId, orgId))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ControlDetails)
