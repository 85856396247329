import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import * as Yup from 'yup'
import axios from 'axios'
import { TextField } from '@material-ui/core'
import { Form, Formik } from 'formik'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import FullscreenModal from '../../components/FullscreenModal'
import Notification from '../../common/Notification'

const styles = {
  root: {},
  input: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 20
    }
  }
}

const LockSystemDetails = props => {
  const {
    classes,
    setShowLockSystemModal,
    setEditLockSystemObj,
    lockSystemObj,
    fetchLockSystems,
    currentOrg,
    isMobile
  } = props

  const [error, setError] = useState(null)

  const handleSaveOrAdd = async values => {
    const lockSystem = { ...values }
    try {
      if (lockSystemObj) {
        await axios.patch(
          `/v1/organisations/${currentOrg.id}/locksystems/${lockSystemObj.id}`,
          lockSystem
        )
        localStorage.setItem('lockSystemEditedSuccessfully', true)
      } else {
        await axios.post(
          `/v1/organisations/${currentOrg.id}/locksystems`,
          lockSystem
        )
        localStorage.setItem('lockSystemAddedSuccessfully', true)
      }
      setEditLockSystemObj(null)
      fetchLockSystems()
      setShowLockSystemModal(false)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const formFields = (
    errors,
    touched,
    handleChange,
    values,
    handleBlur,
    setFieldValue
  ) => (
    <div style={{ textAlign: 'left' }}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '0 0 20px 0' }}
        />
      )}
      <TextField
        value={values.name}
        name='name'
        helperText={global._('Keys.LockSystems.HelpText.Name')}
        className={classes.input}
        label={global._('Name')}
        variant='filled'
        error={errors.name}
        onChange={handleChange}
        onClick={handleBlur}
      />
      <TextField
        value={values.notes}
        name='notes'
        className={classes.input}
        label={global._('Common.Note')}
        variant='filled'
        multiline
        rows={4}
        error={errors.notes}
        onChange={handleChange}
        onClick={handleBlur}
      />
    </div>
  )

  const Wrapper = isMobile ? FullscreenModal : Modal

  const printModalButtons = (values, touched, errors) => {
    const modalButtons = [
      <Button
        variant='primary'
        disabled={
          (!lockSystemObj && Object.keys(touched).length === 0) ||
          Object.keys(errors).length
        }
        onClick={() => {
          handleSaveOrAdd(values)
        }}>
        {global._(lockSystemObj ? 'Keys.LockSystems.EditButton' : 'Common.Add')}
      </Button>
    ]

    if (!isMobile) {
      modalButtons.unshift(
        <Button variant='none' onClick={() => setShowLockSystemModal(false)}>
          {global._('Common.Cancel')}
        </Button>
      )
    }

    return modalButtons
  }

  return (
    <Formik
      initialValues={{
        name: (lockSystemObj && lockSystemObj.name) || '',
        notes: (lockSystemObj && lockSystemObj.notes) || ''
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(global._('Common.Required'))
      })}>
      {({
        errors,
        touched,
        handleChange,
        values,
        handleBlur,
        setFieldValue
      }) => (
        <Form>
          <Wrapper
            title={global._(
              lockSystemObj
                ? 'Keys.LockSystems.EditLockSystem'
                : 'Keys.LockSystems.AddLockSystem'
            )}
            onClose={() => setShowLockSystemModal(false)}
            rightActionItem='close'
            padding
            content={formFields(
              errors,
              touched,
              handleChange,
              values,
              handleBlur,
              setFieldValue
            )}
            buttons={printModalButtons(values, touched, errors)}
          />
        </Form>
      )}
    </Formik>
  )
}

LockSystemDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  setShowLockSystemModal: PropTypes.func.isRequired,
  fetchLockSystems: PropTypes.func.isRequired,
  currentOrg: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  setEditLockSystemObj: PropTypes.func,
  lockSystemObj: PropTypes.object
}

LockSystemDetails.defaultProps = {
  lockSystemObj: null,
  setEditLockSystemObj: null
}

const LockSystemDetailsWithStyles = injectSheet(styles)(LockSystemDetails)
export default LockSystemDetailsWithStyles
