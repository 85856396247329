import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { Grid, Tooltip, Icon } from '@material-ui/core'
import qs from 'qs'
import moment from 'moment'
import IconColumn from 'src/components/IconColumn'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import Notification from '../../common/Notification'
import ObjectList from '../../components/ObjectList'
import KeysNavigation from '../../components/Keys/KeysNavigation'
import SuccessMessage from '../../components/SuccessMessage'
import SearchField from '../../components/SearchField'
import { formatShallowDate, getSelectedOrg } from '../../utils/helpers'

const styles = {
  root: {},
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  }
}

const ReceiptTemplatesList = props => {
  const { classes, history, breadcrumbItems, user } = props

  const selectedOrg = getSelectedOrg(user)

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const [error, setError] = useState(null)
  const [items, setItems] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({
    orderBy: 'name',
    order: 'asc'
  })

  useEffect(() => {
    if (selectedOrg) {
      ;(async () => {
        try {
          setIsLoading(true)
          const { data: receiptTemplates } = await axios.get(
            `/v1/organisations/${
              selectedOrg.id
            }/keyreceipttemplates?${qs.stringify(filter)}`
          )
          setItems(receiptTemplates)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
      })()
      setIsLoading(false)
    }
  }, [filter])

  const handleFilter = name => e => {
    const aFilter = { ...filter }
    const { value } = e.target
    aFilter[name] = value
    setFilter(aFilter)
  }

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      clickable: false,
      style: { width: 70 },
      format: (objVal, obj) => obj && <IconColumn icon='assignment' />
    },
    {
      key: 'id',
      localizationKey: 'Keys.ReceiptTemplates.Id',
      clickable: true,
      sortable: true,
      style: { width: 110 }
    },
    {
      key: 'name',
      localizationKey: 'Keys.ReceiptTemplates.Name',
      clickable: true,
      longText: true
    },
    {
      key: 'updatedAt',
      localizationKey: 'Keys.ReceiptTemplates.UpdatedAt',
      clickable: true,
      sortable: true,
      sortingKey: 'updatedAt',
      format: objDate => (
        <Tooltip
          title={moment(objDate).calendar(null, {
            sameElse: 'YYYY-MM-DD HH:mm'
          })}
          placement='right'
        >
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatShallowDate(objDate)}
          </span>
        </Tooltip>
      )
    },
    {
      key: 'createdBy',
      localizationKey: 'Keys.ReceiptTemplates.CreatedBy',
      clickable: true,
      sortable: true,
      longText: true
    },
    {
      key: 'organisationName',
      localizationKey: 'Keys.ReceiptTemplates.Organisation',
      clickable: true,
      sortable: true,
      longText: true
    },
    {
      key: '',
      clickable: true,
      sortable: false,
      format: () => <Icon>chevron_right</Icon>,
      style: { width: 70 }
    }
  ]

  const Filter = (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SearchField
          style={{ width: '100%' }}
          label={global._('Keys.ReceiptTemplates.SearchId')}
          variant='filled'
          value={filter.search || ''}
          onChange={handleFilter('search')}
        />
      </Grid>
    </Grid>
  )

  const TitleContent = (
    <div style={{ marginLeft: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant='primary'
            onClick={() => history.push('/keys/receipt-templates/new')}
            style={{ width: 130 }}
          >
            {global._('Keys.Common.Add')}
          </Button>
        </Grid>
      </Grid>
    </div>
  )

  return (
    <div className={classes.root}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      <KeysNavigation
        breadcrumbItems={breadcrumbItems}
        user={user}
        error={error}
      />
      <SuccessMessage
        subtitle={global._(
          'Keys.ReceiptTemplates.ReceiptTemplateAddedSuccessfully'
        )}
        localStorageProp='receiptTemplateCreatedSuccessfully'
      />
      <SuccessMessage
        subtitle={global._(
          'Keys.ReceiptTemplates.ReceiptTemplateDeletedSuccessfully'
        )}
        localStorageProp='receiptTemplateDeletedSuccessfully'
      />
      <ObjectList
        sectionTitle={global._('Keys.ReceiptTemplates.Title')}
        infoDialogTitle={global._('Keys.Common.Information')}
        infoDialogContent={
          <Typography variant='body'>
            {global._('Keys.ReceiptTemplates.InfoDialogContent')}
          </Typography>
        }
        titleContent={TitleContent}
        expandable={false}
        objectPermalink='keys/receipt-templates'
        filterFields={Filter}
        history={history}
        columns={columns}
        objects={items}
        isLoadingObjects={isLoading}
        noResultsMessage={global._(
          filter.search
            ? 'Keys.ReceiptTemplates.SearchNoResultsMessage'
            : 'Keys.ReceiptTemplates.NoResultsMessage'
        )}
        handleFilter={handleFilter}
        setFilter={setFilter}
        filter={filter}
      />
    </div>
  )
}

ReceiptTemplatesList.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired
}

const ReceiptTemplatesListWithStyles = injectSheet(styles)(ReceiptTemplatesList)
export default ReceiptTemplatesListWithStyles
