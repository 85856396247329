import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import TabNavigator from 'src/components/TabNavigator'
import Paper from '../Paper'
import { history } from '../../redux/store'
import KeysNavigationHeader from './KeysNavigationHeader'
import { clearSelectedOrg, getSelectedOrg } from '../../utils/helpers'
import { UserRoles } from '../../utils/constants'

const styles = {
  root: {}
}

const KeysNavigation = ({
  classes,
  breadcrumbItems,
  user,
  error,
  hideNav,
  disableOrgChange
}) => {
  const selectedOrg = getSelectedOrg(user)

  if (error && error === 'ERR_ORGANISATION_NOT_FOUND') {
    clearSelectedOrg()
    history.push('/keys/select-organisation')
  }

  const isCustomerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.CustomerAdmin)

  const navigationItems = [
    {
      label: global._('Keys.Navigation.KeyManagement'),
      icon: 'network_locked',
      path: '/keys',
      key: 'key-management'
    },
    {
      label: global._('Keys.Navigation.LockSystems'),
      icon: 'lock',
      path: '/keys/systems',
      key: 'lock-system'
    },
    {
      label: global._('Keys.Navigation.Keys'),
      icon: 'vpn_key',
      path: '/keys/list',
      key: 'keys'
    },
    {
      label: global._('Keys.Navigation.KeyRings'),
      icon: 'group_work',
      path: '/keys/keyrings',
      key: 'key-rings'
    },
    {
      label: global._('Keys.Navigation.Repositories'),
      icon: 'archive',
      path: '/keys/repositories',
      key: 'repositories'
    },
    {
      label: global._('Keys.Navigation.ReceiptTemplates'),
      icon: 'assignment',
      path: '/keys/receipt-templates',
      key: 'receipt-templates'
    },
    {
      label: global._('Keys.Navigation.Inventory'),
      icon: 'beenhere',
      path: '/keys/inventory',
      key: 'inventory'
    },
    {
      label: global._('Keys.Navigation.Settings'),
      icon: 'settings',
      path: '/keys/settings',
      key: 'settings'
    }
  ]

  return (
    <>
      <KeysNavigationHeader
        breadcrumbItems={breadcrumbItems}
        selectedOrg={selectedOrg}
        isCustomerAdmin={isCustomerAdmin}
        disableOrgChange={disableOrgChange}
      />
      {!hideNav && (
        <Paper topPart noPadding alignItems='left'>
          <TabNavigator
            items={navigationItems}
            style={{ borderBottom: 'none' }}
            itemStyle={{ padding: '8px 10px' }}
          />
        </Paper>
      )}
    </>
  )
}

KeysNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  user: PropTypes.object,
  disableOrgChange: PropTypes.bool,
  hideNav: PropTypes.bool
}

KeysNavigation.defaultProps = {
  user: null,
  disableOrgChange: undefined,
  hideNav: false
}

const KeysNavigationWithStyles = injectSheet(styles)(KeysNavigation)
export default KeysNavigationWithStyles
