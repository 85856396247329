import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import PdfJsLib from 'pdfjs-dist'
import CircularProgress from '@material-ui/core/CircularProgress'
import Theme from '../../helpers/Theme'
import Pdf from './Pdf'
import Image from './Image'
import ZoomView from '../ZoomView'
import useWindowDimensions from '../../utils/useWindowDimensions'
import { getPointFromTouch, getDistanceBetweenPoints } from '../../utils/math'

const MARKER_SIZE = 30
const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#fff',
    maxHeight: 'calc(100vh - 80px)',
    position: 'relative',
    overflow: 'hidden',
    touchAction: 'none'
  },
  container: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    overflow: 'hidden'
  },
  objectMarkerContainer: {
    position: 'absolute',
    width: MARKER_SIZE,
    height: MARKER_SIZE,
    cursor: 'pointer'
  },
  objectMarkerContainerLowlight: {
    opacity: 0.2
  },
  objectMarkerRing: {
    boxSizing: 'border-box',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  objectMarkerRing1: {
    width: MARKER_SIZE,
    height: MARKER_SIZE,
    borderRadius: 500,
    border: '5px solid rgba(27,26,26,0.8)',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)'
  },
  objectMarkerRing2: {
    width: 20,
    height: 20,
    borderRadius: 500,
    border: '2px solid rgba(0,0,0,0)'
  },
  objectMarkerRing2Highlighted: {
    borderColor: Theme.Colors.PRIMARY
  },
  objectMarkerRing3: {
    width: 16,
    height: 16,
    borderRadius: 500,
    backgroundColor: 'rgba(27,26,26,0.8)'
  },
  objectMarkerRing3Highlighted: {},
  objectMarkerRing4: {
    width: 10,
    height: 10,
    borderRadius: 500
  },
  objectMarkerRing4Highlighted: {
    backgroundColor: Theme.Colors.PRIMARY
  },
  zoomButtonContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: 38,
    bottom: 30
  },
  zoomButton: {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 500,
    boxShadow:
      '0 2px 4px 0 rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.14) !important',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f9f9f9'
    }
  },
  zoomButtonIcon: {
    fontSize: 32,
    color: '#1c1c1c'
  },
  zoomLevel: {
    userSelect: 'none',
    pointerEvents: 'none',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    top: 15,
    right: 10,
    padding: '2px',
    paddingRight: 10,
    fontWeight: 300,
    color: '#8e8e8e',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: 18,
    fontSize: '18px!important'
  },
  zoomLevelHidden: {
    transition: 'opacity 1s',
    opacity: 0
  },
  zoomLevelIcon: {
    fontSize: 22,
    color: '#ccc',
    marginRight: 5,
    marginLeft: 5
  },
  zoomDisabled: {
    opacity: 0.5,
    cursor: 'default'
  },

  /* Mobile */
  '@media (max-width: 900px)': {
    objectMarkerContainerLowlight: {
      opacity: 0.4
    },
    zoomButton: {
      width: 48,
      height: 48,
      zIndex: 100
    }
  },
  objectGrown: {
    transform: 'scale(3)'
  }
}

const BlueprintContainer = props => {
  const {
    classes,
    blueprint,
    highlightedObject,
    selectedObject,
    setCurrentObject,
    setSelectedObject,
    setHighlightedObject,
    isChangingObjectPosition,
    saveObjectPosition,
    token,
    rotateBlueprint,
    rotationDegrees
  } = props

  const { isMobile, windowDimensions } = useWindowDimensions()
  const initialPoint = { x: 0, y: 0 }
  const containerRef = useRef()
  const imageRef = useRef()
  const zoomTimeoutRef = useRef()
  const currentObjectRef = useRef()
  const [zoom, setZoom] = useState(1)
  const [isDragging, setIsDragging] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [showZoom, setShowZoom] = useState(false)
  const [changedObjectPosition, setChangedObjectPosition] = useState({})
  const [, setRedraw] = useState({})
  const [delta, setDelta] = useState(initialPoint)
  const [initialTouchDistance, setInitialTouchDistance] = useState(0)
  const [lastTouchPoint, setLastTouchPoint] = useState(null)
  const [zoomAction, setZoomAction] = useState(null)

  useEffect(() => {
    if (isChangingObjectPosition && selectedObject) {
      const x = parseFloat(selectedObject.x)
      const y = parseFloat(selectedObject.y)
      setChangedObjectPosition({ x, y })
    }
  }, [isChangingObjectPosition, selectedObject])

  const displayZoomLevel = () => {
    setShowZoom(true)
    if (zoomTimeoutRef.current) {
      clearTimeout(zoomTimeoutRef.current)
    }
    zoomTimeoutRef.current = setTimeout(() => {
      setShowZoom(false)
    }, 1000)
  }

  const handleChangedObjectPosition = event => {
    if (!imageRef.current) return
    const { current: image } = imageRef
    const { clientX, clientY } = event
    const bounds = image.getBoundingClientRect()

    const relativeX = Math.max(
      0,
      Math.min(1, (clientX - bounds.left) / bounds.width)
    )
    const relativeY = Math.max(
      0,
      Math.min(1, (clientY - bounds.top) / bounds.height)
    )
    setChangedObjectPosition({ x: relativeX, y: relativeY })
  }

  const handleResize = () => {
    setRedraw({})
  }

  const zoomIn = () => {
    setZoom(zoom + 0.5)
    displayZoomLevel()
  }

  const zoomOut = () => {
    if (zoom <= 0.5) {
      return
    }
    setZoom(zoom - 0.5)
    displayZoomLevel()
  }

  const onClick = evt => {
    if (!imageRef.current || isChangingObjectPosition) return
    if (evt && evt.target && !evt.target.contains(imageRef.current)) {
      return
    }

    const imageRect = imageRef.current.getBoundingClientRect()
    const x = evt.clientX - imageRect.left
    const y = evt.clientY - imageRect.top
    if (isMobile) {
      /* Discard clicks outside the blueprint */
      if (
        evt.clientX < imageRect.left ||
        evt.clientX > imageRect.left + imageRect.width ||
        evt.clientY < imageRect.top ||
        evt.clientY > imageRect.top + imageRect.height
      ) {
        return
      }
    }

    const relativeX = x / imageRect.width
    const relativeY = y / imageRect.height
    if (typeof setCurrentObject === 'function') {
      setCurrentObject({
        x: relativeX,
        y: relativeY
      })
    }
  }

  const onMouseDown = evt => {
    if (evt.touches && evt.touches.length > 0 && containerRef.current) {
      if (evt.touches.length === 2) {
        const point1 = getPointFromTouch(evt.touches[0], containerRef.current)
        const point2 = getPointFromTouch(evt.touches[1], containerRef.current)
        setInitialTouchDistance(getDistanceBetweenPoints(point1, point2))
        setLastTouchPoint(initialPoint)
      } else {
        setInitialTouchDistance(0)
        setLastTouchPoint(
          getPointFromTouch(evt.touches[0], containerRef.current)
        )
      }
    }
    setIsDragging(false)
    if (isChangingObjectPosition && currentObjectRef.current) {
      if (currentObjectRef.current.contains(evt.target)) {
        setIsMouseDown(true)
      }
    } else {
      setIsMouseDown(true)
    }
  }

  const onMouseMove = evt => {
    if (!containerRef.current) return
    evt.persist()
    const DELTA = 1
    if (isMouseDown) {
      if (!isDragging) {
        if (evt.touches && evt.touches.length > 0) {
          const point = getPointFromTouch(evt.touches[0], containerRef.current)
          const distance = getDistanceBetweenPoints(lastTouchPoint, point)
          if (distance >= DELTA) {
            setIsDragging(true)
          }
        } else if (
          Math.abs(evt.movementX) >= DELTA ||
          Math.abs(evt.movementY) >= DELTA
        ) {
          containerRef.current.style.cursor = 'grab'
          setIsDragging(true)
        }
      } else if (isChangingObjectPosition) {
        handleChangedObjectPosition(
          evt.touches && evt.touches.length > 0 ? evt.touches[0] : evt
        )
      } else if (containerRef.current) {
        if (
          (evt.movementX && evt.movementY) ||
          (evt.touches && evt.touches.length === 1)
        ) {
          /* Click & Single touch */
          let movX = evt.movementX
          let movY = evt.movementY
          if (evt.touches && evt.touches.length > 0) {
            const point = getPointFromTouch(
              evt.touches[0],
              containerRef.current
            )
            movX = point.x - lastTouchPoint.x
            movY = point.y - lastTouchPoint.y
            setLastTouchPoint(point)
          }
          if (movX && movY) {
            setDelta(prevDelta => ({
              x: prevDelta.x + movX * window.devicePixelRatio,
              y: prevDelta.y + movY * window.devicePixelRatio
            }))
          }
        } else if (evt.touches && evt.touches.length === 2) {
          /* Pinch gesture on touchscreens or mobile */
          const point1 = getPointFromTouch(evt.touches[0], containerRef.current)
          const point2 = getPointFromTouch(evt.touches[1], containerRef.current)
          const distance = getDistanceBetweenPoints(point1, point2)
          if (distance > initialTouchDistance) {
            setZoomAction('IN')
          } else if (distance < initialTouchDistance) {
            setZoomAction('OUT')
          }
        }
      }
    }
  }

  const onMouseUp = evt => {
    setIsMouseDown(false)
    if (!containerRef.current) return
    containerRef.current.style.cursor = ''
    if (isDragging) {
      if (isChangingObjectPosition) {
        saveObjectPosition(changedObjectPosition)
      } else if (zoomAction === 'IN') {
        zoomIn()
      } else if (zoomAction === 'OUT') {
        zoomOut()
      }
      setInitialTouchDistance(0)
      setZoomAction(null)
      setIsDragging(false)
    } else if (imageRef.current) {
      const { current: image } = imageRef
      const bounds = image.getBoundingClientRect()

      /* Stop clicks on object markers */
      if (
        evt &&
        evt.target &&
        typeof evt.target.id === 'string' &&
        evt.target.id.startsWith('object-marker-')
      ) {
        return
      }

      /* Only allow clicks inside image */
      const { clientX, clientY } = evt
      if (
        clientX >= bounds.left &&
        clientX <= bounds.left + bounds.width &&
        clientY >= bounds.top &&
        clientY <= bounds.top + bounds.height
      ) {
        if (!isDragging) {
          evt.persist()
          onClick(evt)
        }
      }
    }
  }

  // Relocate markers on resize
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // img onload or pdf on render completion or resizing
  const onImageUpdate = () => {
    handleResize()
  }

  const onObjectClick = object => {
    if (isDragging) return
    if (typeof setSelectedObject === 'function') {
      setSelectedObject(object)
    }
  }

  const handleClickEvt = evt => {
    if (
      evt &&
      evt.target &&
      typeof evt.target.id === 'string' &&
      evt.target.id.startsWith('object-marker-')
    ) {
      onMouseDown(evt)
    } else {
      onClick(evt)
    }
  }

  const renderObjects = () => {
    const mappedObjects = []
    if (imageRef.current && containerRef.current && blueprint.objects.length) {
      const { current: image } = imageRef
      const { current: container } = containerRef
      const imageBounds = image.getBoundingClientRect()
      if (imageBounds.width) {
        const containerBounds = container.getBoundingClientRect()
        const { width: imageWidth, height: imageHeight } = imageBounds
        const offsetX = imageBounds.left - containerBounds.left
        const offsetY = imageBounds.top - containerBounds.top
        blueprint.objects.forEach(object => {
          const isSelected = selectedObject && selectedObject.id === object.id
          const isHighlighted =
            isSelected ||
            (highlightedObject && highlightedObject.id === object.id)
          const highlight = isSelected || isHighlighted
          const lowlight = isChangingObjectPosition && !isSelected

          const { x: relativeX, y: relativeY } =
            isSelected && isChangingObjectPosition
              ? changedObjectPosition
              : object
          const x = offsetX + relativeX * imageWidth - MARKER_SIZE / 2
          const y = offsetY + relativeY * imageHeight - MARKER_SIZE / 2
          const top = `${y}px`
          const left = `${x}px`

          const objectClasses = classnames(classes.objectMarkerContainer, {
            [classes.objectMarkerContainerLowlight]: lowlight,
            [classes.objectGrown]:
              isMobile && isSelected && isChangingObjectPosition && isDragging
          })
          mappedObjects.push(
            <div
              id={`object-marker-${object.id}`}
              ref={
                isSelected && isChangingObjectPosition
                  ? currentObjectRef
                  : undefined
              }
              className={objectClasses}
              style={{ top, left }}
              key={`object-marker-${object.id}`}
              role='button'
              tabIndex='0'
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onObjectClick(object)
                return false
              }}
              onKeyPress={e => {
                e.preventDefault()
                e.stopPropagation()
                onObjectClick(object)
                return false
              }}
              onMouseEnter={() => setHighlightedObject(object)}
              onMouseLeave={() => setHighlightedObject(null)}>
              <div
                id={`object-marker-ring-1-${object.id}`}
                className={classnames(
                  classes.objectMarkerRing,
                  classes.objectMarkerRing1
                )}
              />
              <div
                id={`object-marker-ring-2-${object.id}`}
                className={classnames(
                  classes.objectMarkerRing,
                  classes.objectMarkerRing2,
                  highlight && classes.objectMarkerRing2Highlighted
                )}
              />
              <div
                id={`object-marker-ring-3-${object.id}`}
                className={classnames(
                  classes.objectMarkerRing,
                  classes.objectMarkerRing3,
                  highlight && classes.objectMarkerRing3Highlighted
                )}
              />
              <div
                id={`object-marker-ring-4-${object.id}`}
                className={classnames(
                  classes.objectMarkerRing,
                  classes.objectMarkerRing4,
                  highlight && classes.objectMarkerRing4Highlighted
                )}
              />
            </div>
          )
        })
      }
    }
    return mappedObjects
  }

  // const { type } = blueprint.file
  const type = 'image/png'
  const src = `${window.env.API_BASE_URL}/v1/blueprints/${blueprint.id}/file`

  const [defaultScale, setDefaultScale] = useState(1)
  const [isLoaded, setIsLoaded] = useState(false)
  const [originalWidth, setOriginalWidth] = useState(0)
  const [originalHeight, setOriginalHeight] = useState(0)
  const [pdfPage, setPdfPage] = useState(null)
  if (isMobile) {
    /* Pdf rendering */
    useEffect(() => {
      if (!pdfPage || !imageRef.current) return
      ;(async () => {
        const { current: canvas } = imageRef
        const scaledViewport = pdfPage.getViewport({ scale: 2 })
        const renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: scaledViewport
        }
        canvas.width = scaledViewport.width
        canvas.height = scaledViewport.height
        setOriginalWidth(scaledViewport.width)
        setOriginalHeight(scaledViewport.height)
        await pdfPage.render(renderContext).promise
        setIsLoaded(true)
      })()
    }, [pdfPage])

    /* Loading */
    useEffect(() => {
      ;(async () => {
        if (type === 'application/pdf') {
          PdfJsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'
          const pdfDocument = await PdfJsLib.getDocument({
            url: src,
            httpHeaders: {
              Authorization: `Bearer ${token}`
            }
          }).promise
          const page = await pdfDocument.getPage(1)
          setPdfPage(page)
        } else {
          const img = new window.Image()
          img.onload = () => {
            // TODO: Replace window width with container width
            let scale = 1
            if (img.naturalWidth > windowDimensions.width) {
              scale = windowDimensions.width / img.naturalWidth
            }
            setOriginalWidth(img.naturalWidth)
            setOriginalHeight(img.naturalHeight)
            setDefaultScale(scale)
            setIsLoaded(true)
          }
          img.src = `${src}?clientWidth=${window.innerWidth}&clientHeight=${window.innerHeight}&token=${token}`
        }
      })()
    }, [])

    return (
      <div
        data-cy='blueprint-container'
        ref={containerRef}
        onTouchStart={onMouseDown}
        onTouchMove={isMouseDown ? onMouseMove : undefined}
        onTouchEnd={onMouseUp}
        onMouseDown={handleClickEvt}
        className='noselect'
        role='button'
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden'
        }}
        tabIndex='0'>
        {blueprint && blueprint.objects && blueprint.objects.length === 0 && (
          <div
            data-cy='button-rotate-blueprint'
            className={classes.zoomButton}
            onClick={rotateBlueprint}
            onKeyPress={rotateBlueprint}
            role='button'
            tabIndex='0'
            style={{
              marginLeft: 20,
              position: 'absolute',
              top: 14
            }}>
            <Icon className={classes.zoomButtonIcon}>rotate_right</Icon>
          </div>
        )}
        <ZoomView
          disabled={isChangingObjectPosition}
          width={originalWidth}
          height={originalHeight}
          defaultScale={defaultScale}
          imageRef={imageRef}>
          {(x, y, scale) => (
            <>
              <div
                style={{
                  visibility: isLoaded ? undefined : 'hidden',
                  pointerEvents: 'none',
                  userSelect: 'none',
                  transformOrigin: '0 0',
                  transform: `translate(${x}px, ${y}px) scale(${scale})`
                }}>
                {type === 'application/pdf' ? (
                  <canvas
                    ref={imageRef}
                    className='noselect'
                    style={{
                      transform: `rotate(${rotationDegrees}deg)`,
                      transformOrigin: 'center center'
                    }}
                  />
                ) : (
                  <img
                    alt=''
                    ref={imageRef}
                    src={`${src}?clientWidth=${window.innerWidth}&clientHeight=${window.innerHeight}&token=${token}`}
                    style={{
                      transform: `rotate(${rotationDegrees}deg)`,
                      transformOrigin: 'center center'
                    }}
                  />
                )}
              </div>
              {renderObjects()}
            </>
          )}
        </ZoomView>
        {!isLoaded && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CircularProgress style={{ color: '#1b1b1b' }} />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div
        data-cy='blueprint-container'
        className={classes.container}
        ref={containerRef}
        role='button'
        tabIndex='0'
        onTouchStart={onMouseDown}
        onTouchMove={isMouseDown ? onMouseMove : undefined}
        onTouchEnd={onMouseUp}
        onMouseDown={onMouseDown}
        onMouseMove={isMouseDown ? onMouseMove : undefined}
        onMouseUp={onMouseUp}>
        {type === 'application/pdf' ? (
          <Pdf
            delta={delta}
            zoom={zoom}
            rotationDegrees={rotationDegrees}
            src={src}
            token={token}
            containerRef={containerRef}
            ref={imageRef}
            onUpdate={onImageUpdate}
          />
        ) : (
          <Image
            alt=''
            delta={delta}
            zoom={zoom}
            rotationDegrees={rotationDegrees}
            src={src}
            token={token}
            containerRef={containerRef}
            ref={imageRef}
            onUpdate={onImageUpdate}
          />
        )}
        {renderObjects()}
      </div>
      {!isMobile && (
        <>
          <div className={classes.zoomButtonContainer}>
            <div
              className={classnames(
                classes.zoomButton,
                zoom <= 0.5 && classes.zoomDisabled
              )}
              onClick={zoomOut}
              onKeyPress={zoomOut}
              role='button'
              tabIndex='0'
              style={{ marginRight: 20 }}>
              <Icon className={classes.zoomButtonIcon}>remove</Icon>
            </div>
            <div
              data-cy='button-zoom-blueprint'
              className={classes.zoomButton}
              onClick={zoomIn}
              onKeyPress={zoomIn}
              role='button'
              tabIndex='0'>
              <Icon className={classes.zoomButtonIcon}>add</Icon>
            </div>
            {blueprint && blueprint.objects && blueprint.objects.length === 0 && (
              <div
                data-cy='button-rotate-blueprint'
                className={classes.zoomButton}
                onClick={rotateBlueprint}
                onKeyPress={rotateBlueprint}
                role='button'
                tabIndex='0'
                style={{ marginLeft: 20 }}>
                <Icon className={classes.zoomButtonIcon}>rotate_right</Icon>
              </div>
            )}
          </div>
          <div
            data-cy='zoom-value'
            className={classnames(
              classes.zoomLevel,
              !showZoom && classes.zoomLevelHidden
            )}>
            <Icon className={classes.zoomLevelIcon}>zoom_in</Icon>
            {`${zoom * 100}%`}
          </div>
        </>
      )}
    </div>
  )
}

BlueprintContainer.propTypes = {
  token: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  blueprint: PropTypes.object.isRequired,
  highlightedObject: PropTypes.object,
  selectedObject: PropTypes.object,
  setCurrentObject: PropTypes.func.isRequired,
  setSelectedObject: PropTypes.func.isRequired,
  setHighlightedObject: PropTypes.func.isRequired,
  isChangingObjectPosition: PropTypes.bool.isRequired,
  saveObjectPosition: PropTypes.func.isRequired
}

BlueprintContainer.defaultProps = {
  highlightedObject: null,
  selectedObject: null
}

const BlueprintContainerWithStyles = injectSheet(styles)(BlueprintContainer)
export default BlueprintContainerWithStyles
