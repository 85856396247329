import React from 'react'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import SearchIcon from '@material-ui/icons/Search'
import qs from 'qs'
import moment from 'moment-timezone'
import Button from '../../../../components/Button'
import { history } from '../../../../redux/store'
import Typography from '../../../../components/Typography'
import CenteredFloatingButtonMobile from '../../../../components/CenteredFloatingButtonMobile'
import AsyncSelect from '../../../../components/AsyncSelect'
import { CustomInput } from '../Entity'

export default {
  fetchUrl: filter =>
    `/v1/organisations/${filter.organisationId}/accessgroups?${qs.stringify(
      filter
    )}`,
  doNotShowFilter: true,
  doNotShowTable: true,
  infoDialog: {
    title: () => global._('AccessGroups.InfoDialogTitle'),
    content: () => (
      <div style={{ textAlign: 'left' }}>
        <Typography
          style={{ display: 'block', paddingBottom: 12 }}
          variant='body'
        >
          {global._('AccessGroups.InfoDialogContent')}
        </Typography>
      </div>
    )
  },
  filter: ({
    isMobile,
    single,
    handleSearchQueryChange,
    isLoading,
    filter,
    setFilter,
    buttons
  }) => (
    <Grid
      container
      spacing={isMobile ? 1 : 4}
      direction='row'
      alignItems='center'
      justify='space-between'
    >
      <Grid item xs={12} sm={12} md={single ? 5 : undefined}>
        <CustomInput
          label={global._('AccessGroups.SearchName')}
          onChange={handleSearchQueryChange}
          endAdornment={
            <InputAdornment position='end'>
              {isLoading ? (
                <CircularProgress style={{ color: 'black' }} size={20} />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          }
          helperText=' '
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <AsyncSelect
          isSearchable
          isClearable={false}
          labelKey='name'
          value={{
            value: filter.organisationId,
            label: filter.organisationName
          }}
          placeholder={global._('Common.SelectOrganisation')}
          disabled={isLoading}
          helpText={global._('Common.Required')}
          url='/v1/organisations?search={{input}}&orderBy=name&order=asc&ifHas=accessgroups'
          onChange={opt =>
            opt &&
            setFilter({
              ...filter,
              offset: 0,
              organisationId: opt ? opt.value.id : null,
              organisationName: opt ? opt.value.name : null
            })
          }
        />
      </Grid>
      {single && (
        <Grid item xs={12} sm={12} md={4} style={{ marginBottom: 18 }}>
          <Grid container spacing={2} direction='row' justify='flex-end'>
            {buttons &&
              buttons.map(btn => (
                <Grid key={`${btn.key}.grid`} item>
                  {btn}
                </Grid>
              ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  ),
  linkButtons: opts => {
    const { classes, user } = opts
    return (
      <>
        <div
          className={classes.linkContainer}
          role='button'
          tabIndex='0'
          onClick={() =>
            history.push(`/admin/accessgroups/${user.organisation.id}`)
          }
          onKeyPress={() =>
            history.push(`/admin/accessgroups/${user.organisation.id}`)
          }
        >
          <span>{global._('AccessGroups.AccessGroupsButton')}</span>
          <ChevronRightIcon style={{ marginLeft: 'auto' }} />
        </div>
        <div
          className={classes.linkContainer}
          role='button'
          tabIndex='0'
          onClick={() =>
            history.push(`/admin/accessgroups/${user.organisation.id}/locks`)
          }
          onKeyPress={() =>
            history.push(`/admin/accessgroups/${user.organisation.id}/locks`)
          }
        >
          <span>{global._('AccessGroups.AccessLocksButton')}</span>
          <ChevronRightIcon style={{ marginLeft: 'auto' }} />
        </div>
        <div
          className={classes.linkContainer}
          role='button'
          tabIndex='0'
          onClick={() =>
            history.push(`/admin/accessgroups/${user.organisation.id}/users`)
          }
          onKeyPress={() =>
            history.push(`/admin/accessgroups/${user.organisation.id}/users`)
          }
        >
          <span>{global._('AccessGroups.AccessUsersButton')}</span>
          <ChevronRightIcon style={{ marginLeft: 'auto' }} />
        </div>
      </>
    )
  },
  icon: () => 'verified_user',
  actionButtons: (caller, opts) => {
    const { type, single, isMobile, filter } = opts

    const historyPushObj = {
      pathname: '/admin/accessgroups/new',
      state: {
        organisation: {
          id: filter.organisationId,
          name: filter.organisationName
        }
      }
    }

    const buttons = []
    if (single) {
      buttons.push(
        isMobile ? (
          <CenteredFloatingButtonMobile
            onClick={() => history.push(historyPushObj)}
          />
        ) : (
          <Button
            key={`widgets.organisations.${type}.button.add.existing`}
            variant='primary'
            onClick={() => history.push(historyPushObj)}
          >
            {global._('AccessGroups.CreateAccessGroup')}
          </Button>
        )
      )
    }
    return buttons
  },
  identifier: accessgroup => accessgroup.id,
  noResultsMessage: () => global._('Widgets.accessgroups.NoResultsMessage'),
  MobileTable: {
    icon: 'group_work',
    title: accessGroup => accessGroup.name,
    subtitle: accessGroup => accessGroup.organisation.name,
    onClick: item =>
      history.push(`/admin/accessgroups/${item.organisationId}/mob/${item.id}`)
  },
  Columns: {
    single: [
      {
        key: 'icon',
        sortable: false,
        localizationKey: '',
        style: { width: 60 },
        transform: (isExpanded, value, item) => (
          <div
            className='tableRowIcon'
            style={{
              width: 38,
              height: 38,
              transition: 'background-color 200ms',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isExpanded ? 'white' : 'var(--color-light-grey)',
              color: 'var(--color-middle-grey)',
              borderRadius: 500
            }}
          >
            <Icon style={{ fontSize: 18 }}>
              {item.type === 'TEMPORARY' ? 'event' : 'group_work'}
            </Icon>
          </div>
        )
      },
      {
        key: 'name',
        sortable: true,
        sortKey: 'name',
        localizationKey: 'AccessGroups.Name',
        style: { width: 400 },
        transform: (value, item) => {
          if (item.type === 'TEMPORARY') {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: 'var(--color-middle-grey)' }}>
                  {global._('AccessGroups.TemporaryGroupTitle')}
                </span>
                <span
                  style={{ color: 'var(--color-middle-grey)', fontSize: 14 }}
                >
                  {value}
                </span>
                <span
                  style={{ color: 'var(--color-middle-grey)', fontSize: 14 }}
                >
                  {`${moment(item.specStartUTC).format(
                    'YYYY-MM-DD HH:mm'
                  )} - ${moment(item.specEndUTC).format('YYYY-MM-DD HH:mm')}`}
                </span>
              </div>
            )
          }
          return value
        }
      },
      {
        key: 'userCount',
        sortable: true,
        sortKey: 'userCount',
        localizationKey: 'AccessGroups.UsersCount',
        style: { width: 200 }
      },
      {
        key: 'lockCount',
        sortable: true,
        sortKey: 'lockCount',
        localizationKey: 'AccessGroups.LocksCount',
        style: { width: 200 }
      },
      {
        key: 'chevron',
        localizationKey: '',
        sortable: false,
        style: { width: 70 },
        transform: isExpanded => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton
              style={{
                transition: 'transform 150ms',
                marginLeft: 'auto',
                transform: `rotate(${isExpanded ? -90 : 90}deg)`
              }}
            >
              <Icon>chevron_right</Icon>
            </IconButton>
          </div>
        )
      }
    ]
  },
  rowExpansionContent: (item, caller, opts) => (
    <React.Fragment>
      <Button
        variant='outlined'
        disabled={item.type === 'TEMPORARY'}
        onClick={() =>
          opts.history.push(
            `/admin/accessgroups/details/${item.organisationId}/${item.id}`
          )
        }
        style={{ marginRight: 18 }}
        minWidth
      >
        {global._('AccessGroups.DetailsTitle')}
      </Button>
      <Button
        variant='outlined'
        disabled={item.type === 'TEMPORARY'}
        onClick={() =>
          opts.history.push(
            `/admin/accessgroups/${item.organisationId}/${item.id}/users`
          )
        }
        style={{ marginRight: 18 }}
        minWidth
      >
        {global._('AccessGroups.AssignUsers')}
      </Button>
      <Button
        variant='outlined'
        disabled={item.type === 'TEMPORARY'}
        onClick={() =>
          opts.history.push(
            `/admin/accessgroups/${item.organisationId}/${item.id}/locks`
          )
        }
        style={{ marginRight: 18 }}
        minWidth
      >
        {global._('AccessGroups.AssignLocks')}
      </Button>
      <Button
        variant='secondary'
        disabled={item.type === 'TEMPORARY'}
        style={{ marginRight: 18 }}
        minWidth
        onClick={() => {
          opts.setDeleteItem(item)
          opts.setDeleteError(null)
        }}
      >
        {global._('AccessGroups.Delete')}
      </Button>
    </React.Fragment>
  )
}
