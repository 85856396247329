import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import MobileContainer from 'src/components/MobileContainer'
import NavigationBar from 'src/components/NavigationBar'
import ScrollView from 'src/components/ScrollView'
import IdControlForm from 'src/components/IdControlForm'
import { Icon } from '@material-ui/core'

const styles = {
  root: {}
}

const IdControlMobile = props => {
  const {
    classes,
    history,
    cancelAuth,
    personalNumber,
    setPersonalNumber,
    sendRequest,
    error,
    isLoading,
    mobileNumber,
    setMobileNumber
  } = props

  return (
    <MobileContainer background='white'>
      <NavigationBar
        title={`${global._('IdControl.Title')}`}
        backAction={() => history.push('/dashboard')}
      />
      <ScrollView id='scrollable-container'>
        <IdControlForm
          isLoading={isLoading}
          cancel={cancelAuth}
          personalNumber={personalNumber}
          setPersonalNumber={setPersonalNumber}
          sendRequest={sendRequest}
          error={error}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
        />
      </ScrollView>
    </MobileContainer>
  )
}

IdControlMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

IdControlMobile.defaultProps = {}

const IdControlMobileWithStyles = injectSheet(styles)(IdControlMobile)
export default IdControlMobileWithStyles
