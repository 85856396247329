import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core'
import withUser from 'src/utils/withUser'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import FullscreenModal from '../../components/FullscreenModal'
import FileContainer from '../../components/FileContainer'
import DragZone from '../../components/DragAndDrop/DragZone'
import AsyncSelect from '../../components/AsyncSelect'
import { DocumentTypes, UserRoles } from '../../utils/constants'
import Notification from '../../common/Notification'
import Typography from '../../components/Typography'
import { detectMimeType } from '../../utils/mime'

const styles = {
  root: {},
  input: {
    width: '100%'
  }
}

const AddDocumentModal = ({
  classes,
  setShowAddDocumentModal,
  handleUpload,
  isMobile,
  user
}) => {
  const [error, setError] = useState(null)

  const isPartnerAdmin = user.roles.includes(UserRoles.PartnerAdmin)
  const isCustomerAdmin = user.roles.includes(UserRoles.CustomerAdmin)
  const AllowedFileSize = 50 * 1000 * 1000 // 50 MB
  const AllowedFileTypes = [
    // https://www.sitepoint.com/mime-types-complete-list/
    '.dwg',
    'image/jpeg',
    'image/jpg',
    'image/tiff', // .tiff
    'image/x-tiff', // .tiff
    'image/png',
    'image/vnd.dwg', // .dwg
    'image/x-dwg', // .dwg
    'application/acad', // .dwg
    'application/pdf',
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/vnd.ms-excel', // .xls, .xlt, .xla
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template', // .xltx
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'video/mp4', // .mp4
    'video/quicktime' // .mov
  ]

  const formikRef = useRef()

  const onFileSelected = selectedFile => {
    const { name, size } = selectedFile
    let errorCode

    if (!selectedFile.type) {
      // eslint-disable-next-line
      selectedFile.type = detectMimeType(name)
    }

    if (!AllowedFileTypes.includes(selectedFile.type)) {
      errorCode = 'ERR_FILE_TYPE'
      setError(errorCode)
    } else if (size > AllowedFileSize) {
      errorCode = 'ERR_FILE_SIZE'
      setError(errorCode)
    } else {
      try {
        formikRef.current.setFieldValue('file', selectedFile)
        setError(null)
      } catch (e) {
        errorCode = e.response ? e.response.data : e.message
        setError(global._(errorCode))
      }
    }
  }

  const onRemoveFile = () => {
    try {
      formikRef.current.setFieldValue('file', {})
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const addDocumentFormFields = (
    handleChange,
    errors,
    values,
    handleBlur,
    setFieldValue
  ) => (
    <Grid container spacing={3} style={{ marginBottom: 1 }}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      {values.file && values.file.name && (
        <Grid item xs={12} key={values.file.name}>
          <FileContainer
            file={values.file}
            name={values.file.name}
            size={values.file.size}
            type={values.file.type}
            data={values.file.data}
            onDelete={() => onRemoveFile(values.file)}
          />
        </Grid>
      )}
      {(!values.file || !values.file.name) && (
        <Grid item>
          <div style={{ marginBottom: 12 }}>
            <DragZone
              onFileSelected={onFileSelected}
              buttonOnly
              buttonVariant='outlined'
            />
          </div>
          <Typography
            variant='bodyFaded'
            style={{ fontStyle: 'italic', marginTop: 15 }}
          >
            {global._('Documents.AddDocumentFileInfo')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          data-cy='modal-input-textfield-document-name'
          onChange={handleChange}
          error={errors.name}
          onClick={handleBlur}
          value={values.name}
          name='name'
          className={classes.input}
          label={global._('Documents.Name')}
          helperText={global._('Common.Required')}
          variant='filled'
        />
      </Grid>
      {isPartnerAdmin && (
        <Grid item xs={12}>
          <AsyncSelect
            isSearchable
            helpText={global._('Common.Required')}
            error={errors.organisation}
            labelKey='name'
            value={values.organisation}
            defaultValue={values.organisation}
            name='organisation'
            placeholder={global._('Common.SelectOrganisation')}
            onChange={e => setFieldValue('organisation', e)}
            url='/v1/organisations?search={{input}}&orderBy=name&order=asc'
          />
        </Grid>
      )}
      {!isCustomerAdmin && (
        <Grid item xs={12}>
          <TextField
            data-cy='modal-input-select-document-type'
            onChange={handleChange}
            error={errors.type}
            onBlur={handleBlur}
            value={values.type}
            name='type'
            select
            className={classes.input}
            label={global._('Documents.Type')}
            helperText={global._('Common.Required')}
            variant='filled'
          >
            {DocumentTypes &&
              Object.values(DocumentTypes).map(
                type =>
                  type !== DocumentTypes.CustomerDocument && (
                    <MenuItem value={type} key={`document-types-${type}`}>
                      {global._(`Document.Types.${type}`)}
                    </MenuItem>
                  )
              )}
          </TextField>
        </Grid>
      )}
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <FormControlLabel
          style={{ marginBottom: '-11px' }}
          label={global._(
            isPartnerAdmin
              ? 'Documents.PublicForAllWithinOrg'
              : 'Documents.PublicForAllWithinOrgCustomerAdmin'
          )}
          control={
            <Checkbox
              data-cy='input-checkbox-toggle-publish-document'
              checked={values.isPublic}
              color='primary'
              name='isPublic'
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
        />
      </Grid>
      {isPartnerAdmin && (
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <FormControlLabel
            style={{ marginBottom: '-11px' }}
            label={global._('Documents.HasToBeApproved')}
            control={
              <Checkbox
                checked={values.wantsApproval}
                color='primary'
                name='wantsApproval'
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
          />
        </Grid>
      )}
    </Grid>
  )

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        name: '',
        organisation: isPartnerAdmin ? '' : user.organisation,
        type: isCustomerAdmin ? DocumentTypes.CustomerDocument : '',
        isPublic: true,
        wantsApproval: isPartnerAdmin,
        file: {}
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(global._('Common.Required')),
        organisation: Yup.object().required(global._('Common.Required')),
        type: Yup.string().required(global._('Common.Required')),
        file: Yup.object().shape({
          name: Yup.string().required(global._('Common.Required'))
        })
      })}
    >
      {({
        errors,
        touched,
        handleChange,
        values,
        handleBlur,
        setFieldValue,
        resetForm
      }) => (
        <Form>
          {isMobile ? (
            <FullscreenModal
              title={global._('Documents.AddDocument')}
              onClose={() => setShowAddDocumentModal(false)}
              rightActionItem='close'
              padding
              content={addDocumentFormFields(
                handleChange,
                errors,
                values,
                handleBlur,
                setFieldValue
              )}
              buttons={
                <Button
                  variant='primary'
                  onClick={() => handleUpload(values, resetForm)}
                  disabled={
                    Object.keys(touched).length === 0 ||
                    Object.keys(errors).length
                  }
                >
                  {global._('Common.Upload')}
                </Button>
              }
            />
          ) : (
            <Modal
              onClose={() => setShowAddDocumentModal(false)}
              title={global._('Documents.UploadDocument')}
              content={
                <div style={{ textAlign: 'left' }}>
                  {addDocumentFormFields(
                    handleChange,
                    errors,
                    values,
                    handleBlur,
                    setFieldValue
                  )}
                </div>
              }
              buttons={[
                <Button
                  variant='none'
                  onClick={() => setShowAddDocumentModal(false)}
                >
                  {global._('Common.Cancel')}
                </Button>,
                <Button
                  data-cy='modal-button-confirm-upload-document'
                  variant='primary'
                  onClick={() => {
                    handleUpload(values, resetForm)
                  }}
                  disabled={
                    Object.keys(touched).length === 0 ||
                    Object.keys(errors).length
                  }
                >
                  {global._('Common.Upload')}
                </Button>
              ]}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

AddDocumentModal.propTypes = {
  classes: PropTypes.object.isRequired,
  setShowAddDocumentModal: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired
}

AddDocumentModal.defaultProps = {}

const AddDocumentModalWithStyles = withUser(
  injectSheet(styles)(AddDocumentModal)
)
export default AddDocumentModalWithStyles
