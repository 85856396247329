import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core'
import { Clear, Today } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import Paper from '../../components/Paper'
import { CaseStatus, OrderStatus, UserRoles } from '../../utils/constants'
import Button from '../../components/Button'
import useWindowDimensions from '../../utils/useWindowDimensions'
import AsyncSelect from '../../components/AsyncSelect'
import SearchField from '../../components/SearchField'

const styles = {
  paper: {
    marginBottom: props =>
      props.noMargin ? '' : 'var(--section-padding-d-ext-v)',
    paddingBottom: 0
  },
  checkboxWrapper: {
    width: '100%',
    marginBottom: 0,
    paddingTop: '0 !important',
    paddingBottom: '0 !important'
  },
  input: {
    width: '100%'
  },
  searchField: {},
  '@media (max-width: 900px)': {
    searchField: {
      display: 'none'
    }
  }
}

const Filter = ({
  classes,
  handleFilter,
  filter,
  caseTypes,
  intrinsicTypes,
  caseInvoiceStatus,
  allCaseManagers,
  user,
  caseUrlType
}) => {
  const isAdmin =
    user &&
    Array.isArray(user.roles) &&
    (user.roles.includes(UserRoles.PartnerAdmin) ||
      user.roles.includes(UserRoles.Support))

  const { isMobile } = useWindowDimensions()

  const statuses =
    caseUrlType === 'orders' ? { ...OrderStatus } : { ...CaseStatus }

  return (
    <Paper smallerPadding classes={classes}>
      <Grid container spacing={2}>
        <Grid
          item
          md={isAdmin ? 6 : 3}
          className={`${classes.input} ${classes.searchField}`}
        >
          <FormControl fullWidth>
            <SearchField
              data-cy='filter-input-textfield-search'
              style={{ width: '100%' }}
              name='title-search'
              label={global._('Search')}
              variant='filled'
              value={filter && filter.query}
              onChange={handleFilter('query')}
              className={classes.notchedOutline}
              helperText={global._('Filter.CaseSearch')}
            />
          </FormControl>
        </Grid>
        <Grid item md={3} className={classes.input}>
          <FormControl fullWidth>
            <DatePicker
              InputProps={{
                endAdornment:
                  filter && filter.createdAtFrom ? (
                    <Clear
                      style={{
                        cursor: 'pointer',
                        marginRight: 10,
                        fontSize: 14
                      }}
                      onClick={() => handleFilter('createdAtFrom')('')}
                    />
                  ) : (
                    <Today style={{ cursor: 'pointer' }} />
                  )
              }}
              data-cy='filter-input-textfield-date-from'
              inputVariant='filled'
              label={global._('Choose')}
              format='YYYY/MM/DD'
              name='dateFrom-select'
              helperText={global._('Filter.DateFrom')}
              value={(filter && filter.createdAtFrom) || null}
              cancelLabel={null}
              okLabel={null}
              disableFuture
              maxDate={
                filter && filter.createdAtTo
                  ? filter.createdAtTo
                  : moment().format('YYYY-MM-DD')
              }
              autoOk
              onChange={date =>
                handleFilter('createdAtFrom')(date.format('YYYY-MM-DD'))
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={3} className={classes.input}>
          <FormControl fullWidth>
            <DatePicker
              InputProps={{
                endAdornment:
                  filter && filter.createdAtTo ? (
                    <Clear
                      style={{
                        cursor: 'pointer',
                        marginRight: 10,
                        fontSize: 14
                      }}
                      onClick={() => handleFilter('createdAtTo')('')}
                    />
                  ) : (
                    <Today style={{ cursor: 'pointer' }} />
                  )
              }}
              data-cy='filter-input-textfield-date-to'
              inputVariant='filled'
              label={global._('Choose')}
              format='YYYY/MM/DD'
              name='dateTo-select'
              helperText={global._('Filter.DateTo')}
              value={(filter && filter.createdAtTo) || null}
              cancelLabel={null}
              okLabel={null}
              disableFuture
              minDate={
                filter && filter.createdAtFrom
                  ? filter.createdAtFrom
                  : moment().format('YYYY-MM-DD')
              }
              autoOk
              onChange={date =>
                handleFilter('createdAtTo')(date.format('YYYY-MM-DD'))
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={3} className={classes.input}>
          <FormControl fullWidth>
            <TextField
              data-cy='filter-input-select-case-type'
              variant='filled'
              select
              name='casetype-select'
              label={global._('Cases.CaseType')}
              helperText={global._('Filter.ByCaseType')}
              value={(filter && filter.type) || 'all'}
              onChange={handleFilter('type')}
            >
              <MenuItem key='all' value='all'>
                {global._('Cases.AllCaseTypes')}
              </MenuItem>
              {caseTypes &&
                Object.keys(caseTypes).map(key => {
                  const caseType = caseTypes[key]
                  if (intrinsicTypes.includes(caseType)) {
                    return (
                      <MenuItem key={caseType} value={caseType}>
                        {global._(`CaseTypes.${caseType}`)}
                      </MenuItem>
                    )
                  }
                  return null
                })}
            </TextField>
          </FormControl>
        </Grid>
        {isAdmin ? (
          <>
            <Grid item md={3} className={classes.input}>
              <FormControl fullWidth>
                <TextField
                  data-cy='filter-input-select-invoice-status'
                  variant='filled'
                  select
                  name='invoicestatus-select'
                  label={global._('Cases.Payment')}
                  helperText={global._('Filter.ByInvoiceStatus')}
                  value={(filter && filter.invoiced) || 'all'}
                  onChange={handleFilter('invoiced')}
                >
                  <MenuItem key='all' value='all'>
                    {global._('All')}
                  </MenuItem>
                  {caseInvoiceStatus &&
                    Object.keys(caseInvoiceStatus).map(key => {
                      const status = caseInvoiceStatus[key]

                      return (
                        <MenuItem key={status} value={status}>
                          {global._(`Case.InvoiceStatus.${status}`)}
                        </MenuItem>
                      )
                    })}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item md={3} className={classes.input}>
              <AsyncSelect
                data-cy='filter-input-async-select-organisation-name'
                isSearchable
                labelKey='name'
                value={(filter && filter.organisation) || ''}
                helpText={global._('Filter.ByCompany')}
                placeholder={global._('Cases.Organisation')}
                onChange={handleFilter('organisation')}
                url='/v1/organisations?search={{input}}&orderBy=name&order=asc&ifHas=cases'
              />
            </Grid>
            <Grid item md={3} className={classes.input}>
              <FormControl fullWidth>
                <TextField
                  data-cy='filter-input-select-case-manager'
                  variant='filled'
                  select
                  name='casemanager-select'
                  label={global._('Cases.CaseManagers')}
                  helperText={global._('Filter.ByCaseManager')}
                  value={(filter && filter.managerUserId) || 'all'}
                  onChange={handleFilter('managerUserId')}
                >
                  <MenuItem key='all' value='all'>
                    {global._('All')}
                  </MenuItem>
                  {allCaseManagers && allCaseManagers.length > 0
                    ? allCaseManagers.map(manager => (
                        <MenuItem key={manager.id} value={manager.id}>{`${
                          manager.name || global._('Common.NoName')
                        }${
                          manager.servicedesk ? ' (Servicedesk)' : ''
                        }`}</MenuItem>
                      ))
                    : null}
                </TextField>
              </FormControl>
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginTop: isMobile ? 10 : 'var(--section-padding-default)' }}
      />
      <Grid container spacing={2} style={{ marginTop: 10, paddingBottom: 20 }}>
        <Grid item md={3} className={classes.checkboxWrapper}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy='filter-input-checkbox-new'
                  value={statuses.New}
                  checked={
                    filter &&
                    filter.status &&
                    filter.status.includes &&
                    filter.status.includes(statuses.New)
                  }
                  name={statuses.New}
                  color='primary'
                  onChange={handleFilter('status')}
                />
              }
              label={global._('Cases.ShowNewCases')}
              className={classes.checkboxWrapper}
            />
          </FormControl>
        </Grid>
        <Grid item md={3} className={classes.checkboxWrapper}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy='filter-input-checkbox-started'
                  value={statuses.Started}
                  checked={
                    filter &&
                    filter.status &&
                    filter.status.includes &&
                    filter.status.includes(statuses.Started)
                  }
                  name={statuses.Started}
                  color='primary'
                  onChange={handleFilter('status')}
                />
              }
              label={global._('Cases.ShowCasesInProgress')}
              className={classes.checkboxWrapper}
            />
          </FormControl>
        </Grid>
        <Grid item md={3} className={classes.checkboxWrapper}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy='filter-input-checkbox-closed'
                  value={statuses.Closed}
                  checked={
                    filter &&
                    filter.status &&
                    filter.status.includes &&
                    filter.status.includes(statuses.Closed)
                  }
                  name={statuses.Closed}
                  color='primary'
                  onChange={handleFilter('status')}
                />
              }
              label={global._('Cases.ShowClosedCases')}
              className={classes.checkboxWrapper}
            />
          </FormControl>
        </Grid>
        {isAdmin && caseUrlType === 'orders' && (
          <Grid item md={3} className={classes.checkboxWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy='filter-input-checkbox-read'
                  checked={filter && filter.isRead === 'false'}
                  name='onlyUnread'
                  color='primary'
                  onChange={handleFilter('onlyUnread')}
                />
              }
              label={global._('Cases.ShowUnreadCases')}
              className={classes.checkboxWrapper}
            />
          </Grid>
        )}
        {isAdmin && caseUrlType !== 'orders' && (
          <Grid item md={3} className={classes.checkboxWrapper}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    data-cy='filter-input-checkbox-resting'
                    value={statuses.Resting}
                    checked={
                      filter &&
                      filter.status &&
                      filter.status.includes &&
                      filter.status.includes(statuses.Resting)
                    }
                    name={statuses.Resting}
                    color='primary'
                    onChange={handleFilter('status')}
                  />
                }
                label={global._('Cases.ShowRestingCases')}
                className={classes.checkboxWrapper}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderTop: '1px solid var(--divider-color)',
          marginLeft: '-25px',
          marginRight: '-25px',
          width: 'calc(100% + 42px)',
          padding: '10px 0'
        }}
      >
        <Grid item>
          <Button
            data-cy='filter-button-clear'
            variant='none'
            onClick={handleFilter('clearFilter')}
          >
            {global._('Cases.ClearFilter')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

Filter.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  handleFilter: PropTypes.func.isRequired,
  filter: PropTypes.object,
  caseTypes: PropTypes.object,
  caseInvoiceStatus: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  allCaseManagers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  intrinsicTypes: PropTypes.array
}

Filter.defaultProps = {
  filter: {},
  caseTypes: {},
  caseInvoiceStatus: [],
  allCaseManagers: {},
  intrinsicTypes: []
}
const FilterWithStyles = injectSheet(styles)(Filter)
export default FilterWithStyles
