import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import FullscreenModal from 'src/components/FullscreenModal'
import Notification from 'src/common/Notification'
import NavigationList from '../../../../components/NavigationList'
import Typography from '../../../../components/Typography'

const styles = {
  section: {
    padding: 20,
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--color-divider-light)'
    }
  }
}

const PublicTemporaryKeyLoanMobile = ({
  classes,
  error,
  signButton,
  checkboxes,
  pageTitle,
  orgInfo,
  receipts,
  terms
}) => {
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [showKeysModal, setShowKeysModal] = useState(false)

  return (
    <FullscreenModal
      withNavigationBar={false}
      content={
        <div>
          {showTermsModal && terms && (
            <FullscreenModal
              title={global._('Common.Terms')}
              onClose={() => setShowTermsModal(false)}
              rightActionItem='close'
              backgroundColor='var(--color-light-grey)'
              content={
                <div style={{ padding: 20 }}>
                  <div dangerouslySetInnerHTML={{ __html: terms }} />
                </div>
              }
            />
          )}
          {showKeysModal && receipts.length > 0 && receipts && (
            <FullscreenModal
              title={global._('Common.Keys')}
              onClose={() => setShowKeysModal(false)}
              rightActionItem='close'
              backgroundColor='var(--color-light-grey)'
              content={
                <div>
                  <NavigationList
                    itemList={receipts.map(receipt => ({
                      title: receipt.key.name || '-',
                      subTitle: (
                        <>
                          <Typography variant='body2' faded>
                            {receipt.key.marking}
                          </Typography>
                          <div style={{ marginTop: 5 }}>
                            <span className='status black'>
                              {`${global._(
                                'PublicTemporaryKeyLoan.ReturnDate'
                              )} ${
                                receipt.key.pendingExpectedReturnDate ||
                                global
                                  ._('Common.UntilFurtherNotice')
                                  .toLowerCase()
                              }`}
                            </span>
                          </div>
                        </>
                      ),
                      icon: 'vpn_key',
                      rightIcon: 'none',
                      smallerPadding: true,
                      darkIcon: true
                    }))}
                  />
                </div>
              }
            />
          )}
          <div className={classes.section}>
            <img src='/logos/logo-text-512.png' width={130} />
          </div>
          {error && (
            <div className={classes.section}>
              <Notification type='error' message={error} />
            </div>
          )}
          {receipts.length > 0 && (
            <>
              <div className={classes.section}>{pageTitle}</div>
              <div className={classes.section}>{orgInfo}</div>
              <div>
                <NavigationList
                  itemList={[
                    {
                      title: global._(
                        'PublicTemporaryKeyLoan.Mobile.ShowTerms'
                      ),
                      icon: 'assignment',
                      fullBorder: true,
                      darkIcon: true,
                      smallerPadding: true,
                      onClick: () => setShowTermsModal(true)
                    },
                    {
                      title: global._('PublicTemporaryKeyLoan.Mobile.ShowKeys'),
                      icon: 'vpn_key',
                      fullBorder: true,
                      darkIcon: true,
                      smallerPadding: true,
                      hideRightIcon: true,
                      onClick: () => setShowKeysModal(true)
                    }
                  ]}
                />
              </div>
              <div className={classes.section}>{checkboxes}</div>
            </>
          )}
        </div>
      }
      buttons={receipts.length > 0 && <>{signButton}</>}
    />
  )
}

PublicTemporaryKeyLoanMobile.propTypes = {
  classes: PropTypes.object.isRequired
}

PublicTemporaryKeyLoanMobile.defaultProps = {}

const PublicTemporaryKeyLoanMobileWithStylesAndUser = injectSheet(styles)(
  PublicTemporaryKeyLoanMobile
)
export default PublicTemporaryKeyLoanMobileWithStylesAndUser
