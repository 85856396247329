import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Grid } from '@material-ui/core'
import axios from 'axios'
import qs from 'qs'
import IconColumn from 'src/components/IconColumn'
import KeysNavigation from '../../components/Keys/KeysNavigation'
import Notification from '../../common/Notification'
import Button from '../../components/Button'
import ObjectList from '../../components/ObjectList'
import Typography from '../../components/Typography'
import RepositoryDetails from './RepositoryDetails'
import SuccessMessage from '../../components/SuccessMessage'
import Modal from '../../components/Modal'
import SearchField from '../../components/SearchField'
import { getSelectedOrg } from '../../utils/helpers'
import { InstructionsVideoIds } from '../../utils/constants'

const styles = {
  root: {}
}

const Repositories = props => {
  const { classes, breadcrumbItems, history, user } = props

  const [error, setError] = useState(null)
  const [deleteError, setDeleteError] = useState(null)
  const [items, setItems] = useState(null)
  const [expandedRows, setExpandedRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showRepositoryModal, setShowRepositoryModal] = useState(false)
  const [editRepositoryObj, setEditRepositoryObj] = useState(null)
  const [deleteRepositoryObj, setDeleteRepositoryObj] = useState(null)
  const [showDeleteSuccessMessage, setShowDeleteSuccessMessage] = useState(null)
  const [filter, setFilter] = useState({
    orderBy: 'id',
    order: 'asc'
  })

  const selectedOrg = getSelectedOrg(user)

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const fetchRepositories = async () => {
    if (selectedOrg) {
      try {
        setIsLoading(true)
        const { data } = await axios.get(
          `/v1/organisations/${selectedOrg.id}/keystorages?${qs.stringify(
            filter
          )}`
        )
        setItems(data)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
      }

      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchRepositories()
  }, [filter])

  const handleDeleteRepository = async () => {
    if (deleteRepositoryObj) {
      try {
        setDeleteError(null)
        await axios.delete(
          `/v1/organisations/${selectedOrg.id}/keystorages/${deleteRepositoryObj.id}`
        )

        setShowDeleteSuccessMessage(true)

        const filteredItems = items.filter(
          item => item.id !== deleteRepositoryObj.id
        )
        setItems(filteredItems)

        setDeleteRepositoryObj(null)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setDeleteError(msg)
      }
    }
  }

  const handleFilter = name => e => {
    const aFilter = { ...filter }
    const { value } = e.target
    aFilter[name] = value
    setFilter(aFilter)
  }

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      clickable: false,
      style: { width: 70 },
      format: (objVal, obj) => obj && <IconColumn icon='archive' />
    },
    {
      key: 'name',
      localizationKey: 'Keys.Repositories.Name',
      sortingKey: 'name',
      clickable: true,
      longText: true
    },
    {
      key: 'id',
      localizationKey: 'Keys.Repositories.Id',
      sortingKey: 'id',
      clickable: true,
      style: { width: 120 }
    },
    {
      key: 'keyCount',
      localizationKey: 'Keys.Repositories.KeyCount',
      sortingDisabled: true,
      clickable: true,
      style: { width: 150 }
    },
    {
      key: 'responsible',
      localizationKey: 'Keys.Repositories.Responsible',
      sortingKey: 'responsible',
      clickable: true
    },
    {
      key: 'space',
      localizationKey: 'Keys.Repositories.SpaceDescription',
      sortingKey: 'space',
      clickable: true
    },
    {
      key: 'propertyName',
      localizationKey: 'Keys.Repositories.Property',
      sortingKey: 'propertyName',
      clickable: true,
      longText: true
    },
    {
      key: 'organisationName',
      localizationKey: 'Keys.Repositories.Owner',
      sortingKey: 'organisationName',
      clickable: true,
      longText: true
    }
  ]

  const Filter = (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SearchField
          style={{ width: '100%' }}
          label={global._('Keys.Repositories.Search')}
          variant='filled'
          value={filter.search || ''}
          onChange={handleFilter('search')}
        />
      </Grid>
    </Grid>
  )

  const TitleContent = (
    <div style={{ marginLeft: 'auto' }}>
      <Button
        variant='primary'
        style={{ width: 130 }}
        onClick={() => {
          setEditRepositoryObj(null)
          setShowRepositoryModal(true)
        }}
      >
        {global._('Keys.Common.Add')}
      </Button>
    </div>
  )

  const actionButtons = (objId, obj) => (
    <>
      <Button
        variant='outlined'
        style={{ marginLeft: 18 }}
        onClick={() => history.push(`/keys/repositories/${objId}`)}
      >
        {global._('Keys.Repositories.ShowKeys')}
      </Button>
      <Button
        variant='secondary'
        style={{ marginLeft: 18 }}
        onClick={() => {
          setEditRepositoryObj(obj)
          setShowRepositoryModal(true)
        }}
      >
        {global._('Keys.Repositories.Edit')}
      </Button>
      <Button
        variant='secondary'
        disabled={obj.keyCount > 0}
        onClick={() => {
          setDeleteRepositoryObj(obj)
        }}
        style={{ marginLeft: 18 }}
      >
        {global._('Keys.Common.Remove')}
      </Button>
    </>
  )

  const lang = global.getLanguage()

  return (
    <div className={classes.root}>
      {deleteRepositoryObj && (
        <Modal
          onClose={() => {
            setDeleteRepositoryObj(null)
          }}
          title={global._('Keys.Repositories.DeleteRepository')}
          content={
            <div style={{ textAlign: 'left' }}>
              {deleteError && (
                <Notification
                  type='error'
                  message={deleteError}
                  style={{ margin: '20px 0' }}
                />
              )}
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='body'
              >
                {global._('Keys.Repositories.DeleteInfo')}
              </Typography>
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='subtitleSmall'
              >
                {deleteRepositoryObj && deleteRepositoryObj.name}
              </Typography>
            </div>
          }
          buttons={[
            <Button
              variant='none'
              onClick={() => {
                setDeleteRepositoryObj(null)
              }}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              variant='secondary'
              onClick={() => handleDeleteRepository()}
            >
              {global._('Delete')}
            </Button>
          ]}
        />
      )}
      {showRepositoryModal && (
        <RepositoryDetails
          classes={classes}
          currentOrg={selectedOrg}
          setShowRepositoryModal={setShowRepositoryModal}
          fetchRepositories={fetchRepositories}
          setEditRepositoryObj={setEditRepositoryObj}
          repositoryObj={editRepositoryObj || undefined}
          user={user}
          isMobile={false}
        />
      )}
      <SuccessMessage
        subtitle={global._('Keys.Repositories.RepositoryAddedSuccessfully')}
        localStorageProp='repositoryAddedSuccessfully'
      />
      <SuccessMessage
        subtitle={global._('Keys.Repositories.RepositoryEditedSuccessfully')}
        localStorageProp='repositoryEditedSuccessfully'
      />
      {showDeleteSuccessMessage && (
        <SuccessMessage
          subtitle={global._('Keys.Repositories.RepositoryDeletedSuccessfully')}
        />
      )}
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}

      <KeysNavigation
        breadcrumbItems={breadcrumbItems}
        user={user}
        error={error}
      />
      <ObjectList
        sectionTitle={global._('Keys.Repositories.StoragePlaces')}
        infoDialogTitle={global._('Keys.Common.Information')}
        infoDialogContent={
          <Typography variant='body'>
            {global._('Keys.Repositories.InfoDialogContent')}
          </Typography>
        }
        titleContent={TitleContent}
        expandable
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        actionButtons={actionButtons}
        filterFields={Filter}
        history={history}
        columns={columns}
        objects={items}
        instructionsVideoId={
          InstructionsVideoIds[lang].KEY_MANAGEMENT.REPOSITORIES
        }
        isLoadingObjects={isLoading}
        noResultsMessage={global._(
          filter.search
            ? 'Keys.Repositories.SearchNoResultsMessage'
            : 'Keys.Repositories.NoResultsMessage'
        )}
        handleFilter={handleFilter}
        setFilter={setFilter}
        filter={filter}
      />
    </div>
  )
}

Repositories.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const RepositoriesWithStyles = injectSheet(styles)(Repositories)
export default RepositoriesWithStyles
