import { Icon } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = {
  root: {
    fontSize: 18,
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  }
}

const IconColumn = ({ classes, icon, ...otherProps }) => (
  <Icon className={`${classes.root} columnIcon`} {...otherProps}>
    {icon}
  </Icon>
)

IconColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired
}

export default injectSheet(styles)(IconColumn)
