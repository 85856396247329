import { AsyncSelectOrganisation } from 'src/components/AsyncSelectOrganisation'
import { useUser } from 'src/hooks/useUser'
import { UserRoles } from 'src/utils/constants'
import { ServiceRequestFilter } from './types'

interface ServiceRequestListFilterProps {
  filter: ServiceRequestFilter
  onChange: (filter: ServiceRequestFilter) => void
}

export const ServiceRequestListFilter = (
  props: ServiceRequestListFilterProps
) => {
  const { filter, onChange } = props
  const user = useUser()

  return (
    <>
      {user?.roles?.includes(UserRoles.PartnerAdmin) && (
        <AsyncSelectOrganisation
          onChange={opt => onChange({ organisationOption: opt })}
          value={filter.organisationOption}
        />
      )}
    </>
  )
}
