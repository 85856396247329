import React, { useState, useRef } from 'react'
import axios from 'axios'
import { DeviceConfig, DeviceOperation } from 'src/utils/constants'
import { publicRequest } from 'src/utils/helpers'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import moment from 'moment-timezone'
import classnames from 'classnames'
import Typography from 'src/components/Typography'
import Icon from '@material-ui/core/Icon'
import Notification from 'src/common/Notification'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AccessTime } from '@material-ui/icons'
import PullToRefresh from './PullToRefresh'

const DELAY = 750
const LOCK_TIMER = 8000

const styles = {
  root: {
    backgroundColor: 'var(--color-material-grey)',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  locksContainer: {
    width: '100%'
  },
  lockRow: {
    display: 'flex',
    width: '100%',
    padding: '14px 18px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'var(--color-white)',
    background:
      'linear-gradient(to right, var(--color-primary-tone-4), var(--color-primary-tone-4)) no-repeat',
    backgroundSize: '0% 100%',
    transition: `background-size ${DELAY}ms ease-in-out`,
    borderBottom: '1px solid var(--color-divider-light)'
  },
  lockRowError: {
    backgroundColor: 'var(--color-white)',
    background:
      'linear-gradient(to right, var(--color-error-tone-1), var(--color-error-tone-1)) no-repeat'
  },
  lockIconContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--color-primary)',
    width: 40,
    minWidth: 40,
    height: 40,
    minHeight: 40,
    borderRadius: 500,
    marginRight: 20
  },
  lockIconContainerOpen: {
    backgroundColor: 'transparent'
  },
  lockIconContainerError: {
    backgroundColor: 'var(--color-error-text)'
  },
  lockIconContainerNoAccess: {
    backgroundColor: 'var(--color-middle-grey) !important'
  },
  lockIcon: {
    color: 'var(--color-white)',
    fontSize: 24
  },
  lockIconOpen: {
    color: 'black'
  },
  countdown: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: 'var(--color-primary)'
  },
  lockContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '90%'
  },

  /* Mobile */
  '@media (max-width: 900px)': {
    root: {
      padding: 0,
      paddingBottom: 30
    }
  }
}

const dayMap = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday'
}

const reviewAccess = schedule => {
  const now = moment().tz(schedule.timezone)
  const midnight = now.clone().startOf('day')
  const minutesSinceMidnight = now.diff(midnight, 'minutes')
  const nDay = now.isoWeekday()
  const dayKey = dayMap[nDay]
  const from = schedule[`${dayKey}From`]
  const to = schedule[`${dayKey}To`]
  const noAccess = schedule[`${dayKey}NoAccess`]

  if (noAccess) {
    return false
  }
  if (minutesSinceMidnight >= from && minutesSinceMidnight <= to) {
    return true
  }
}
const reviewTemporyAccess = group => {
  const now = moment()
  const from = moment(group.specStartUTC)
  const to = moment(group.specEndUTC)
  if (now.isBetween(from, to)) {
    return true
  }
  return false
}
export const isLockAccessible = lock => {
  let isAccessible = false
  if (!lock || !lock.accessgroups) {
    return false
  }
  lock.accessgroups.forEach(group => {
    if (group.schedule && group.schedule.active) {
      if (reviewAccess(group.schedule)) {
        isAccessible = true
      }
    } else if (lock.schedule && lock.schedule.active) {
      if (reviewAccess(lock.schedule)) {
        isAccessible = true
      }
    } else if (group.type === 'TEMPORARY') {
      if (reviewTemporyAccess(group)) {
        isAccessible = true
      }
    } else {
      isAccessible = true
    }
  })

  return isAccessible
}

const LocksList = props => {
  const {
    classes,
    shared,
    user,
    isLoading,
    error,
    setError,
    locks,
    getLocks,
    setLocks,
    lockOnClick,
    noAccess
  } = props

  const [isOpeningLocks, setIsOpeningLocks] = useState([])
  const [lockErrors, setLockErrors] = useState({})
  const [activatedLock, setActivatedLock] = useState(null)
  const [, setRedraw] = useState({})

  const lockProgressRef = useRef({})
  const timeoutRef = useRef(null)

  const handleOpenLock = async lock => {
    setError(null)
    try {
      const { config, stats } = lock.device
      /* PULSE ACTIVATION OPERATION */
      if (config === DeviceConfig.PULSE) {
        setIsOpeningLocks(prev => [...prev, lock])
        if (shared) {
          await publicRequest(
            'put',
            `/v1/access/public/locks/${lock.id}/action/${DeviceOperation.PULSE}?response=true`
          )
        } else {
          await axios.put(
            `/v1/organisations/${lock.organisationId}/locks/${lock.id}/action/${DeviceOperation.PULSE}?response=true`
          )
        }
        lockProgressRef.current[lock.id] =
          config === DeviceConfig.PULSE ? 1 : 100
        const interval = setInterval(() => {
          const progress = lockProgressRef.current[lock.id]
          if (progress > 100) {
            clearInterval(interval)
            delete lockProgressRef.current[lock.id]
          } else {
            lockProgressRef.current[lock.id] += 1
          }
          setRedraw({})
        }, lock.pulseTimeMs / 100)
      } else if (config === DeviceConfig.SAFETRON3600) {
        const updatedLock = { ...lock }
        const updatedLocks = [...locks]
        locks.splice(locks.indexOf(lock), 1, updatedLock)
        let lockRequest
        let unlockRequest
        if (shared) {
          lockRequest = () =>
            publicRequest(
              'put',
              `/v1/access/public/locks/${lock.id}/action/${DeviceOperation.LOCK}?response=true`
            )
          unlockRequest = () =>
            publicRequest(
              'put',
              `/v1/access/public/locks/${lock.id}/action/${DeviceOperation.UNLOCK}?response=true`
            )
        } else {
          lockRequest = () =>
            axios.put(
              `/v1/organisations/${lock.organisationId}/locks/${lock.id}/action/${DeviceOperation.LOCK}?response=true`
            )
          unlockRequest = () =>
            axios.put(
              `/v1/organisations/${lock.organisationId}/locks/${lock.id}/action/${DeviceOperation.UNLOCK}?response=true`
            )
        }
        /* OPERATIONS */
        if (stats && stats.status.input1) {
          /* check that bolt is in */
          /* LOCK OPERATION */
          const { data } = await lockRequest()
          if (!data.success) {
            if (data.errorCode === 'ERR_LOCK_FAILED_DOOR_OPEN') {
              return setLockErrors(prev => ({
                ...prev,
                [lock.id]: global._('MyLocks.Status.FailedToLockDoorOpen')
              }))
            }
            return setLockErrors(prev => ({
              ...prev,
              [lock.id]: data.errorMessage || data.errorCode || data.message
            }))
          }
          updatedLock.device.stats.status.input1 = 0 /* bolt is not in */
          updatedLock.device.stats.status.input2 = 1 /* bolt is out */
        } else if (stats && stats.status.input2) {
          /* check that bolt is out */
          /* UNLOCK OPERATION */
          const { data } = await unlockRequest()
          if (!data.success) {
            return setLockErrors(prev => ({
              ...prev,
              [lock.id]: data.errorMessage || data.errorCode
            }))
          }

          updatedLock.device.stats.status.input1 = 1
          updatedLock.device.stats.status.input2 = 0
        } else {
          /* RECOVERY ATTEMPT -> UNLOCK */
          const { data } = await unlockRequest()
          if (!data.success) {
            return setLockErrors(prev => ({
              ...prev,
              [lock.id]: data.errorMessage || data.errorCode
            }))
          }

          updatedLock.device.stats.status.input1 = 1
          updatedLock.device.stats.status.input2 = 0
        }
        setLocks(updatedLocks)
      }
    } catch (e) {
      setLockErrors(prev => ({
        ...prev,
        [lock.id]: global._('MyLocks.ActionError')
      }))
    }
    setIsOpeningLocks(prev => prev.filter(l => l !== lock))
  }

  const handleMouseDown = (evt, lock) => {
    setLockErrors(prev => {
      const updated = { ...prev }
      delete updated[lock.id]
      return updated
    })
    evt.preventDefault()
    /* Don't handle open locks */
    if (lockProgressRef.current[lock.id] || noAccess(lock)) return

    setActivatedLock(lock)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setActivatedLock(null)
      handleOpenLock(lock)
    }, DELAY)
    return false
  }

  const handleMouseUp = () => {
    setActivatedLock(null)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  return (
    <>
      {!isLoading && (
        <PullToRefresh
          onRefresh={getLocks}
          loading={isLoading}
          helpTextStyle={{ marginTop: 70 }}
        >
          <div className={classnames('noselect', classes.root)}>
            {error && <Notification type='error' message={error} />}
            {locks.length > 0 && (
              <div
                style={{
                  width: '100%',
                  paddingTop: 15,
                  paddingBottom: 15,
                  textAlign: 'center'
                }}
              >
                <Typography italic variant='body2'>
                  {global._('DelegatedLocks.PressToOpen')}
                </Typography>
              </div>
            )}
            {!isLoading && locks.length === 0 && (
              <div style={{ padding: 40, width: '100%' }}>
                <Typography small italic block faded align='center'>
                  {global._('MyLocks.NoLocksAvailable')}
                </Typography>
              </div>
            )}
            <div className={classes.locksContainer}>
              {locks.map(lock => {
                const { config, stats } = lock.device
                const activated = activatedLock === lock /* Mouse/touch down */
                const isOpening =
                  isOpeningLocks.includes(lock) /* Pulse operation */
                const progress =
                  lockProgressRef.current[lock.id] /* Pulse operation */

                const isAccessible = isLockAccessible(lock)

                const lockError = lockErrors[lock.id]
                const iconContainerClasses = classnames(
                  classes.lockIconContainer,
                  progress && classes.lockIconContainerOpen,
                  lockError && classes.lockIconContainerError,
                  (noAccess(lock) || !isAccessible) &&
                    classes.lockIconContainerNoAccess
                )
                const iconClasses = classnames(
                  classes.lockIcon,
                  progress && classes.lockIconOpen
                )
                let iconName = 'lock'
                if (lock?.device?.bookable) {
                  iconName = 'event'
                }
                if (progress) {
                  iconName = 'lock_open'
                }

                const highlighted = activated || progress || isOpening
                let statusText
                let statusText2
                let statusColor
                if (
                  lock.accessgroups.length === 1 &&
                  lock.accessgroups[0].type === 'TEMPORARY'
                ) {
                  const group = lock.accessgroups[0]
                  statusText = lock.accessgroups[0].specInfo
                  const ends = moment(group.specEndUTC).format('HH:mm')
                  // if (moment(group.specStartUTC).isAfter(moment().add(1, 'day'))) {

                  // } else {

                  // }
                  statusText2 = `${moment(
                    group.specStartUTC
                  ).calendar()} - ${ends}`
                } else if (noAccess(lock) || !isAccessible) {
                  statusText = global._('DelegatedLocks.NoAccess')
                  statusColor = 'var(--color-error-red)'
                } else if (lockError) {
                  /* Errors */
                  statusText = lockError
                } else if (config === DeviceConfig.PULSE) {
                  /* Pulse operations */
                  if (isOpening) {
                    statusText = global._('MyLocks.Status.Opening')
                  } else if (progress) {
                    statusText = global._('MyLocks.Status.Unlocked')
                  } else {
                    statusText = global._('MyLocks.Status.Locked')
                  }
                } else if (config === DeviceConfig.SAFETRON3600) {
                  /* SAFETRON operations */
                  if (stats && stats.status.input2 && stats.status.input3) {
                    statusText = global._('MyLocks.Status.Locked')
                    iconName = 'lock'
                  } else if (
                    stats &&
                    (stats.status.input1 || stats.status.input3)
                  ) {
                    statusText = global._('MyLocks.Status.Unlocked')
                    iconName = 'lock_open'
                  } else {
                    statusText = global._('MyLocks.Status.Unknown')
                  }
                }

                return (
                  <div
                    key={`mylocks-lock-${lock.id}`}
                    className={classnames(
                      classes.lockRow,
                      lockError && classes.lockRowError
                    )}
                    style={{
                      backgroundSize: highlighted ? '100% 100%' : '0% 100%'
                    }}
                  >
                    <div
                      className={iconContainerClasses}
                      onTouchStart={
                        isAccessible
                          ? evt => handleMouseDown(evt, lock)
                          : undefined
                      }
                      onTouchEnd={isAccessible ? handleMouseUp : undefined}
                      onTouchCancel={isAccessible ? handleMouseUp : undefined}
                      onMouseDown={
                        isAccessible
                          ? evt => handleMouseDown(evt, lock)
                          : undefined
                      }
                      onMouseUp={isAccessible ? handleMouseUp : undefined}
                      onMouseLeave={isAccessible ? handleMouseUp : undefined}
                    >
                      {progress && (
                        <CircularProgress
                          className={classes.countdown}
                          variant='static'
                          thickness={4}
                          size={40}
                          value={progress}
                        />
                      )}
                      <Icon className={iconClasses}>{iconName}</Icon>
                    </div>
                    <div
                      style={{
                        width: 'calc(100% - 80px)',
                        display: 'flex',
                        alignItems: 'center',
                        flex: 1,
                        justifyContent: 'space-between'
                      }}
                      onClick={() => lockOnClick && lockOnClick(lock)}
                    >
                      <div className={classes.lockContentContainer}>
                        <div
                          style={{
                            width: '95%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          <Typography bold ellipsis>
                            {lock.name}
                          </Typography>
                          <Typography faded ellipsis>
                            {' '}
                            {(lock.device &&
                              lock.device.property &&
                              lock.device.property.name) ||
                              '-'}
                          </Typography>
                        </div>
                        <Typography
                          bold={lockError || statusColor}
                          variant='body2'
                          style={{
                            color:
                              statusColor ||
                              (lockError
                                ? 'var(--color-error-text)'
                                : 'rgba(0,0,0,0.5)')
                          }}
                        >
                          {!isAccessible && (
                            <AccessTime style={{ marginRight: 5 }} />
                          )}
                          {statusText}
                          {statusText2 && (
                            <>
                              <br />
                              {statusText2}
                            </>
                          )}
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: 24,
                          float: 'right',
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Icon style={{ fontSize: 20 }}>chevron_right</Icon>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </PullToRefresh>
      )}
    </>
  )
}

LocksList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  noAccess: PropTypes.func,
  shared: PropTypes.bool
}

LocksList.defaultProps = {
  noAccess: () => false,
  shared: false
}

export default injectSheet(styles)(LocksList)
