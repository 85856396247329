import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import deferred from 'src/utils/deferBeforeInstallPromptEvent'
import { Variants as PWAVariants } from 'src/utils/PWASelector'
import { toggleInstallationModal } from '../redux/reducers/login'
import FullscreenModal from './FullscreenModal'
import Typography from './Typography'
import Button from './Button'
import Column from './Column'
import Row from './Row'

const styles = {
  bannerRoot: {
    border: '1px solid var(--color-grey)',
    backgroundColor: 'var(--color-primary-tone-4)',
    padding: '1rem',
    width: '100%',
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 24
  },
  bannerIcon: {
    color: 'var(--color-primary)',
    marginRight: '1rem'
  },
  iconContainer: {
    borderRadius: 8,
    overflow: 'hidden'
  },
  imgIcon: {
    width: 84,
    height: 84
  },

  openRoot: {
    backgroundColor: 'var(--color-material-grey)',
    minHeight: '100%'
  },
  addBoxContainer: {
    position: 'relative',
    minWidth: 30,
    maxWidth: 30,
    minHeight: 30,
    maxHeight: 30,
    border: '1.5px solid #1C1C1C',
    borderRadius: 4
  },
  addBox1: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: 18,
    width: 1.5,
    borderRadius: 1,
    backgroundColor: '#1C1C1C',
    transform: 'translate(-50%, -50%)'
  },
  addBox2: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: 1.5,
    width: 18,
    borderRadius: 1,
    backgroundColor: '#1C1C1C',
    transform: 'translate(-50%, -50%)'
  },
  bulletBlack: {
    width: '30px',
    height: '30px',
    margin: '18px 18px 25px',
    padding: '0px 10px',
    backgroundColor: '#1c1c1c',
    borderRadius: '50%'
  }
}

const shareIcon = (
  <svg
    style={{ height: 42, width: 52 }}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 1000 1000'
    enableBackground='new 0 0 1000 1000'
    xmlSpace='preserve'
  >
    <g>
      <path d='M780,290H640v35h140c19.3,0,35,15.7,35,35v560c0,19.3-15.7,35-35,35H220c-19.2,0-35-15.7-35-35V360c0-19.2,15.7-35,35-35h140v-35H220c-38.7,0-70,31.3-70,70v560c0,38.7,31.3,70,70,70h560c38.7,0,70-31.3,70-70V360C850,321.3,818.7,290,780,290z M372.5,180l110-110.2v552.7c0,9.6,7.9,17.5,17.5,17.5c9.6,0,17.5-7.9,17.5-17.5V69.8l110,110c3.5,3.5,7.9,5,12.5,5s9-1.7,12.5-5c6.8-6.8,6.8-17.9,0-24.7l-140-140c-6.8-6.8-17.9-6.8-24.7,0l-140,140c-6.8,6.8-6.8,17.9,0,24.7C354.5,186.8,365.5,186.8,372.5,180z' />
    </g>
  </svg>
)

const AddToHomescreen = props => {
  const { classes, open, toggleModal, variant } = props
  const [canInstall, setCanInstall] = useState(global.__bip_event__)
  const [hasClickedInstall, setHasClickedInstall] = useState(false)
  const [platform, setPlatform] = useState()
  // console.log('AddToHomescreen')
  // console.log('open', open)
  // console.log('hasClickedInstall', hasClickedInstall)
  // console.log('canInstall', canInstall)
  // console.log('global.__bip_event__', global.__bip_event__)
  // console.log('variant', variant)
  // console.log('platform', platform)

  useEffect(() => {
    const { userAgent } = window.navigator

    const currentPlatform = {}
    currentPlatform.isIDevice = /iphone|ipod|ipad/i.test(userAgent)
    currentPlatform.isSamsung = /Samsung/i.test(userAgent)
    currentPlatform.isFireFox = /Firefox/i.test(userAgent)
    currentPlatform.isOpera = /opr/i.test(userAgent)
    currentPlatform.isEdge = /edg/i.test(userAgent)

    if (currentPlatform.isFireFox) {
      currentPlatform.isFireFox = /android/i.test(userAgent)
    }

    if (currentPlatform.isOpera) {
      currentPlatform.isOpera = /android/i.test(userAgent)
    }

    currentPlatform.isChromium = 'onbeforeinstallprompt' in window
    currentPlatform.isInWebAppiOS = window.navigator.standalone === true
    currentPlatform.isInWebAppChrome = window.matchMedia(
      '(display-mode: standalone)'
    ).matches
    currentPlatform.isMobileSafari =
      currentPlatform.isIDevice &&
      userAgent.indexOf('Safari') > -1 &&
      userAgent.indexOf('CriOS') < 0
    currentPlatform.isStandalone =
      currentPlatform.isInWebAppiOS || currentPlatform.isInWebAppChrome
    currentPlatform.isiPad =
      currentPlatform.isMobileSafari && userAgent.indexOf('iPad') > -1
    currentPlatform.isiPhone =
      currentPlatform.isMobileSafari && userAgent.indexOf('iPad') === -1
    currentPlatform.isCompatible =
      currentPlatform.isChromium ||
      currentPlatform.isMobileSafari ||
      currentPlatform.isSamsung ||
      currentPlatform.isFireFox ||
      currentPlatform.isOpera
    setPlatform(currentPlatform)
  }, [open])

  useEffect(() => {
    const listener = () => {
      toggleModal(false)
    }
    window.addEventListener('appinstalled', listener)
    return () => window.removeEventListener('appinstalled', listener)
  }, [])

  useEffect(() => {
    if (global.__bip_event__) {
      setCanInstall(true)
    }
    const listener = () => {
      if (global.__bip_event__) {
        setCanInstall(true)
      } else {
        setCanInstall(false)
      }
    }
    window.addEventListener('__bip_event__', listener)
    return () => {
      window.removeEventListener('__bip_event__', listener)
    }
  }, [])

  const install = async () => {
    if (global.__bip_event__) {
      setHasClickedInstall(true)
      global.__bip_event__.prompt()

      const { outcome } = await global.__bip_event__.userChoice
      global.__bip_event__ = null
    }
  }

  if (
    !platform ||
    platform.isStandalone ||
    (!platform.isChromium && !platform.isMobileSafari) ||
    (platform.isChromium && !global.__bip_event__)
  ) {
    // if (!platform) {
    //   console.log('[AddToHomescreen] platform = null')
    // } else {
    //   console.log('[AddToHomescreen] isChromium', platform.isChromium)
    // }
    // console.log('[AddToHomescreen] global.__bip_event__', deferred)
    return null
  }

  /* Keep for eventual future use */
  // if (banner) {
  //   // return <Button variant='primary' style={{ marginBottom: 24 }} onClick={onClick}>{global._('AddToHomescreen.Banner.Title')}</Button>
  //   return (
  //     <div className={classes.bannerRoot} onClick={onClick}>
  //       <Icon className={classes.bannerIcon}>info</Icon>
  //       <div style={{ display: 'flex', flexDirection: 'column' }}>
  //         <Typography style={{ fontSize: '1.25rem', marginBottom: '0.25rem' }} bold={600}>
  //           {global._('AddToHomescreen.Banner.Title')}
  //         </Typography>
  //         <Typography style={{ fontSize: '1rem' }}>
  //           {global._('AddToHomescreen.Banner.Text')}
  //         </Typography>
  //         <Button variant='none'>
  //           Lägg till på hemskärm
  //         </Button>
  //       </div>
  //
  //     </div>
  //   )
  // }

  if (open) {
    const src =
      variant === PWAVariants.ACCESS
        ? '/icons/access-256.png'
        : '/icons/icon-256.png'
    const iconStyle =
      variant === PWAVariants.ACCESS
        ? { border: '1px solid var(--color-divider-light)' }
        : undefined
    return (
      <FullscreenModal
        noPadding
        title={global._(
          `AddToHomescreen.Title.${
            variant === PWAVariants.ACCESS ? 'Access' : 'Default'
          }`
        )}
        backgroundColor='var(--color-material-grey)'
        content={
          <div
            className={classes.openRoot}
            style={{ backgroundColor: canInstall ? '#fff' : undefined }}
          >
            <Column style={{ backgroundColor: '#fff', padding: '36px' }}>
              <Typography variant='h2' align='center'>
                {global._('AddToHomescreen.InstallText')}
              </Typography>
              {canInstall && (
                <>
                  <div
                    className={classes.iconContainer}
                    style={{ marginTop: 40, marginBottom: 10, ...iconStyle }}
                  >
                    <img
                      className={classes.imgIcon}
                      src={src}
                      alt='Digital Låssmed'
                    />
                  </div>

                  <div style={{ margin: '0 -36px', padding: '32px 18px' }}>
                    {!hasClickedInstall && (
                      <Button variant='primary' onClick={install}>
                        {global._('AddToHomescreen.Install')}
                      </Button>
                    )}
                    <Button
                      style={{ marginTop: 20 }}
                      variant='outlined'
                      onClick={() => toggleModal(false)}
                    >
                      {global._('AddToHomescreen.ContinueWithoutInstallation')}
                    </Button>
                  </div>
                </>
              )}
            </Column>
            {!canInstall && (
              <>
                <Row
                  justify='center'
                  style={{
                    borderBottom: '1px solid var(--color-divider-light)',
                    marginTop: 10,
                    padding: 10
                  }}
                >
                  <div className={classes.bulletBlack}>
                    <Typography
                      variant='subtitle'
                      style={{ color: '#fff', lineHeight: '30px' }}
                    >
                      1
                    </Typography>
                  </div>

                  <Typography bold={700}>
                    {global._('AddToHomescreen.Guidance.Bullet1')}
                  </Typography>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 20,
                      transform: 'scale(0.8)'
                    }}
                  >
                    {shareIcon}
                  </div>
                </Row>

                <Row
                  justify='center'
                  style={{
                    borderBottom: '1px solid var(--color-divider-light)',
                    marginTop: 10,
                    padding: 10
                  }}
                >
                  <div className={classes.bulletBlack}>
                    <Typography
                      variant='subtitle'
                      style={{ color: '#fff', lineHeight: '30px' }}
                    >
                      2
                    </Typography>
                  </div>

                  <Typography bold={700}>
                    {global._('AddToHomescreen.Guidance.Bullet2')}
                  </Typography>
                  <div
                    className={classes.addBoxContainer}
                    style={{ marginLeft: 20, marginRight: 10 }}
                  >
                    <div className={classes.addBox1} />
                    <div className={classes.addBox2} />
                  </div>
                </Row>

                <div style={{ padding: '32px 18px' }}>
                  <Button variant='outlined' onClick={() => toggleModal(false)}>
                    {global._('AddToHomescreen.DoNotAdd')}
                  </Button>
                </div>
              </>
            )}
          </div>
        }
      />
    )
  }
  return null
}

AddToHomescreen.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  variant: PropTypes.string
}

AddToHomescreen.defaultProps = {
  variant: PWAVariants.DEFAULT
}

const AddToHomescreenWithStyles = injectSheet(styles)(AddToHomescreen)

/* connect to redux store */
const mapStateToProps = state => ({
  open: state.login.showInstall
})
const mapDispatchToProps = dispatch => ({
  toggleModal: show => dispatch(toggleInstallationModal(show))
})
const ConnectedAddToHomescreenWithStyles = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToHomescreenWithStyles)
export default ConnectedAddToHomescreenWithStyles
