import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import axios from 'axios'
import IconColumn from 'src/components/IconColumn'
import KeysNavigation from '../../../components/Keys/KeysNavigation'
import Notification from '../../../common/Notification'
import ObjectList from '../../../components/ObjectList'
import LoadingSpinner from '../../../components/LoadingSpinner'
import {
  getSelectedOrg,
  mapKeyStatus,
  signedKeyFormat
} from '../../../utils/helpers'

const styles = {
  root: {},
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  }
}

const KeyRingKeys = props => {
  const { classes, breadcrumbItems, history, match, user } = props

  const { keyRingId } = match.params

  const [error, setError] = useState(null)
  const [ring, setRing] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const selectedOrg = getSelectedOrg(user)

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const fetchKeyRing = async () => {
    if (selectedOrg) {
      ;(async () => {
        try {
          setIsLoading(true)
          const { data } = await axios.get(
            `/v1/organisations/${selectedOrg.id}/keyrings/${keyRingId}`
          )
          setRing(data)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(msg)
        }
      })()
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchKeyRing()
  }, [])

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      clickable: false,
      style: { width: 70 },
      format: (objVal, obj) => obj && <IconColumn icon='vpn_key' />
    },
    {
      key: 'name',
      localizationKey: 'Keys.KeyRings.KeyRingKeys.Name',
      clickable: true,
      longText: true,
      sortingDisabled: true,
      style: { width: 200 }
    },
    {
      key: 'id',
      localizationKey: 'Keys.KeyRings.Id',
      sortingKey: 'id',
      clickable: true,
      sortingDisabled: true,
      style: { width: 100 }
    },
    {
      key: 'marking',
      localizationKey: 'Keys.KeyRings.KeyRingKeys.Marking',
      clickable: true,
      sortingDisabled: true
    },
    {
      key: 'status',
      localizationKey: 'Common.Status',
      clickable: true,
      sortingDisabled: true,
      format: status =>
        status && (
          <>
            <span className={`status ${mapKeyStatus(status)}`}>
              {global._(`Keys.Statuses.${status}`)}
            </span>
          </>
        )
    },
    {
      key: 'type',
      localizationKey: 'Common.Type',
      clickable: true,
      sortingDisabled: true,
      format: (objVal, obj) => global._(`Keys.Types.${obj.type}`)
    },
    {
      key: 'signed',
      localizationKey: 'Keys.KeyRings.KeyRingKeys.Receipt',
      clickable: true,
      sortingDisabled: true,
      format: (objVal, obj) => signedKeyFormat(obj)
    },
    {
      key: 'holderName',
      localizationKey: 'Keys.KeyRings.Holder',
      clickable: true,
      sortingDisabled: true
    },
    {
      key: 'organisationName',
      localizationKey: 'Keys.KeyRings.Owner',
      clickable: true,
      sortingDisabled: true
    },
    {
      key: '',
      clickable: false,
      sortable: false,
      format: () => <Icon>chevron_right</Icon>,
      style: { width: 70 }
    }
  ]

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className={classes.root}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      <KeysNavigation
        breadcrumbItems={breadcrumbItems}
        user={user}
        error={error}
      />
      <ObjectList
        sectionTitle={`${global._('Keys.KeyRings.KeyRingKeys.Title')} ${
          ring && ring.name
        }`}
        history={history}
        columns={columns}
        objects={ring && ring.keys}
        expandable={false}
        objectPermalink='keys/list'
        noResultsMessage={global._(
          'Keys.KeyRings.KeyRingKeys.NoResultsMessage'
        )}
      />
    </div>
  )
}

KeyRingKeys.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const KeyRingKeysWithStyles = injectSheet(styles)(KeyRingKeys)
export default KeyRingKeysWithStyles
