import AsyncSelect from './AsyncSelect'

interface AsyncSelectProps {
  url?: string
  onChange: (opt: any) => void
  preselectedId?: string
  [key: string]: any
}

interface AsyncSelectOrganisationProps extends AsyncSelectProps {
  onChange: (opt: OrganisationOption) => void
}
export interface OrganisationOption {
  label: string
  value: IOrganisation
}

export const AsyncSelectOrganisation = (
  props: AsyncSelectOrganisationProps
) => {
  const { onChange, ...rest } = props
  return (
    // @ts-ignore
    <AsyncSelect
      isSearchable
      labelKey='name'
      placeholder={translate('Common.Organisation')}
      onChange={(opt: OrganisationOption) => props.onChange(opt)}
      url='/v1/organisations?search={{input}}&orderBy=name&order=asc'
      {...rest}
    />
  )
}
