import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Typography from './Typography'
import Theme from '../helpers/Theme'

const styles = {
  root: {
    position: 'absolute',
    height: 48,
    top: 140,
    display: 'flex',
    flexDirection: 'row',
    padding: '13px 0px',
    fontSize: 16
  },
  boxed: {
    position: 'fixed',
    left: 129,
    height: 48,
    top: 140,
    display: 'flex',
    flexDirection: 'row',
    padding: '13px 20px',
    fontSize: 16,
    zIndex: 99,
    boxSizing: 'borderBox',
    border: '1px solid #E1E1E1',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)'
  },
  link: {
    marginRight: 5,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  item: {
    marginRight: 5,
    fontSize: 16,
    letterSpacing: '0.2px'
  }
}

export interface BreadcrumbItem {
  name: string
  path: string
  onClick?: () => void
}

type BreadcrumbsProps = {
  classes: any
  breadcrumbItems: BreadcrumbItem[]
  boxed?: boolean
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { classes, boxed } = props
  const breadcrumbItems = props.breadcrumbItems ?? []
  const [localBreadcrumbItems, setLocalBreadcrumbItems] = useState(
    breadcrumbItems.filter(bci => typeof bci !== 'function')
  )

  // Resolve custom breadcrumbs
  useEffect(() => {
    Promise.all(breadcrumbItems).then(resolved => {
      setLocalBreadcrumbItems(resolved)
    })
  }, [breadcrumbItems])

  return (
    <div className={boxed ? classes.boxed : classes.breadcrumbs}>
      {localBreadcrumbItems &&
        localBreadcrumbItems.map(({ name, path, onClick }, idx) => {
          const isLast = idx + 1 === localBreadcrumbItems.length
          const key = `breadcrumbs.${path}`

          return isLast ? (
            <Typography
              key={key}
              variant='body'
              bold
              className={classes.item}
              style={{ color: Theme.Colors.BLACK }}
            >
              {name}
            </Typography>
          ) : (
            <React.Fragment key={key}>
              <Link className={classes.link} to={path} onClick={onClick}>
                <Typography
                  variant='body'
                  style={{ color: Theme.Colors.MIDDLE_GREY }}
                >
                  {name}
                </Typography>
              </Link>
              <Typography
                variant='body'
                style={{ color: Theme.Colors.MIDDLE_GREY }}
                className={classes.item}
              >
                /
              </Typography>
            </React.Fragment>
          )
        })}
    </div>
  )
}

export default injectSheet(styles)(Breadcrumbs)
