import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  getDistanceBetweenPoints,
  getMidpoint,
  between,
  inverse,
  getPointFromTouch
} from '../utils/math'

const ZoomView = props => {
  const containerRef = useRef()
  const lastDistance = useRef(0)
  const lastMidpoint = useRef({ x: 0, y: 0 })

  const [isPinched, setIsPinched] = useState(false)
  const [scale, setScale] = useState(props.defaultScale || 1)
  const [minScale, setMinScale] = useState(0.5)
  const [maxScale, setMaxScale] = useState(10)
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [width, setWidth] = useState(props.width)
  const [height, setHeight] = useState(props.height)
  const [lastPanPoint, setLastPanPoint] = useState({ x: 0, y: 0 })
  const [isPinching, setIsPinching] = useState(false)
  const [log, setLog] = useState(null)

  useEffect(() => {
    if (!containerRef.current) return
    /* Scale to fit container */
    const { current: container } = containerRef
    const rect = container.getBoundingClientRect()
    let scale = 1
    if (props.width > rect.width) {
      scale = rect.width / props.width
    }
    if (props.height * scale > rect.height) {
      scale *= rect.height / (props.height * scale)
    }

    /* Center */
    if (!props.doNotCenter) {
      const x = rect.width / 2.0 - (props.width * scale) / 2.0
      const y = rect.height / 2.0 - (props.height * scale) / 2.0
      setX(x)
      setY(y)
    }

    setMinScale(rect.width / 2.0 / props.width)
    setWidth(props.width * scale)
    setHeight(props.height * scale)
    setScale(scale)
  }, [props.defaultScale, props.width, props.height])
  const handleTouchStart = event => {
    /* Hax to debug pinch gesture on desktop using shift key */
    if (event.shiftKey && event.touches.length === 1) {
      event.touches = [{ clientX: 30, clientY: 30 }, ...event.touches]
      handlePinchStart(event)
    } else if (event.touches.length == 2) handlePinchStart(event)
    else if (event.touches.length == 1) handleTapStart(event)
  }

  const handleTouchMove = event => {
    /* Hax to debug pinch gesture on desktop using shift key */
    if (event.shiftKey && event.touches.length === 1) {
      event.touches = [
        {
          clientX: containerRef.current.getBoundingClientRect().width - 10,
          clientY: 0
        },
        ...event.touches
      ]
      handlePinchMove(event)
    } else if (event.touches.length == 2) handlePinchMove(event)
    else if (event.touches.length == 1 && !isPinching) handlePanMove(event)
  }

  const handleTouchEnd = event => {
    if (isPinching) {
      event.preventDefault()
      event.stopPropagation()
      if (typeof props.onZoomEnd === 'function') {
        props.onZoomEnd(scale)
      }
    }

    if (event.touches.length === 0) {
      setIsPinching(false)
    }
  }

  const handleTapStart = event => {
    const point = getPointFromTouch(event.touches[0], containerRef.current)
    setLastPanPoint(point)
  }

  const handlePanMove = event => {
    event.preventDefault()

    const point = getPointFromTouch(event.touches[0], containerRef.current)
    const nextX = x + point.x - lastPanPoint.x
    const nextY = y + point.y - lastPanPoint.y

    setX(nextX)
    setY(nextY)
    setLastPanPoint(point)
    if (typeof props.onPan === 'function') {
      props.onPan(nextX, nextY)
    }
  }

  const handlePinchStart = event => {
    const pointA = getPointFromTouch(event.touches[0], containerRef.current)
    const pointB = getPointFromTouch(event.touches[1], containerRef.current)
    lastDistance.current = getDistanceBetweenPoints(pointA, pointB)
    setIsPinching(true)
    setIsPinched(true)
  }

  const handlePinchMove = event => {
    event.preventDefault()
    event.stopPropagation()
    const pointA = getPointFromTouch(event.touches[0], props.imageRef.current)
    const pointB = getPointFromTouch(event.touches[1], props.imageRef.current)
    const distance = getDistanceBetweenPoints(pointA, pointB)
    const midpoint = getMidpoint(pointA, pointB)
    /* Limit jumps */
    let distanceChange = distance / lastDistance.current
    if (
      distance === Infinity ||
      distance === -Infinity ||
      distanceChange === Infinity ||
      distanceChange === -Infinity ||
      Number.isNaN(distance) ||
      Number.isNaN(distanceChange)
    )
      return
    const range = 0.1
    if (distanceChange > 1.0 + range) {
      distanceChange = 1.0 + range
    } else if (distanceChange < 1.0 - range) {
      distanceChange = 1.0 - range
    }

    /* Min & max scale */
    let nextScale = scale * distanceChange
    if (nextScale < minScale) {
      nextScale = minScale
    } else if (nextScale > maxScale) {
      nextScale = maxScale
    }

    if (Number.isNaN(nextScale)) {
      nextScale = scale
    }
    zoom(nextScale, midpoint)

    lastMidpoint.current = midpoint
    lastDistance.current = distance
  }

  const zoom = (nextScale, midpoint) => {
    const nextWidth = props.width * nextScale
    const nextHeight = props.height * nextScale

    const nextX = x + midpoint.x * -1.1 * ((nextWidth - width) / nextWidth)
    const nextY = y + midpoint.y * -1.1 * ((nextHeight - height) / nextHeight)

    setWidth(nextWidth)
    setHeight(nextHeight)
    setX(nextX)
    setY(nextY)
    setScale(nextScale)
    if (typeof props.onScaleChange === 'function') {
      props.onScaleChange(nextScale)
    }
  }
  return (
    <div
      ref={containerRef}
      onTouchStart={props.disabled ? undefined : handleTouchStart}
      onTouchMove={props.disabled ? undefined : handleTouchMove}
      onTouchEnd={props.disabled ? undefined : handleTouchEnd}
      style={{
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        userSelect: 'none'
      }}>
      {props.children(x, y, scale, isPinching)}
    </div>
  )
}

ZoomView.propTypes = {
  children: PropTypes.func.isRequired,
  onScaleChange: PropTypes.func,
  onPan: PropTypes.func,
  onZoomEnd: PropTypes.func
}

ZoomView.defaultProps = {
  onScaleChange: null,
  onPan: null,
  onZoomEnd: null
}

export default ZoomView
