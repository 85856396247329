import { connect } from 'react-redux'
import { setAuthenticationData } from '../../redux/reducers/login'
import Register from './Register'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  setAuthenticationData: data => dispatch(setAuthenticationData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
