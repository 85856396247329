import React from 'react'
import 'moment'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Clear from '@material-ui/icons/DeleteForever'
import { DatePicker } from '@material-ui/pickers'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { UserRoles } from '../../utils/constants'

const statuses = ['NEW', 'PENDING', 'AWAITING_RESPONSE', 'CLOSED']
const CASE_STATUSES = ['UNREAD', 'UNSTARTED', 'STARTED', 'CLOSED']

class Filter extends React.PureComponent {
  render() {
    const {
      handleFilter,
      handleFilterDate,
      handleClearDates,
      areas,
      organisations,
      user,
      placeholder,
      filter,
      types,
      hasDate,
      hasStatuses
    } = this.props
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <FormControl fullWidth>
              <TextField
                style={{ ...this.props.style }}
                label={placeholder}
                variant='filled'
                name='query'
                onChange={handleFilter('query')}
              />
            </FormControl>
          </Grid>
          {user && user.roles.includes(UserRoles.InternalAdmin) && (
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  variant='filled'
                  select
                  label={global._('Filter.Organisation')}
                  name='organisation-select'
                  value={
                    (filter && filter.organisationId) || user.organisation.id
                  }
                  onChange={handleFilter('organisationId')}>
                  {/* <MenuItem value='all'>Alla företag</MenuItem> */}
                  {organisations.map(organisation => (
                    <MenuItem key={organisation.id} value={organisation.id}>
                      {organisation.name || '(inget namn)'}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          )}
          {types && (
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  variant='filled'
                  label='Dokumenttyp'
                  select
                  name='document-select'
                  value={(filter && filter.type) || 'all'}
                  onChange={handleFilter('type')}>
                  <MenuItem value='all'>Alla</MenuItem>
                  {types.map(type => (
                    <MenuItem key={type} value={type}>
                      {global._(`Document.Types.${type}`)}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          )}
          {hasStatuses && (
            <Grid item xs={2}>
              <FormControl fullWidth>
                <TextField
                  variant='filled'
                  label='Status'
                  select
                  name='case-status'
                  value={(filter && filter.status) || 'all'}
                  onChange={handleFilter('status')}>
                  <MenuItem value='all'>Alla</MenuItem>
                  {CASE_STATUSES.map(status => (
                    <MenuItem key={status} value={status}>
                      {global._(status)}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          )}
          {hasDate && (
            <React.Fragment>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <DatePicker
                    InputProps={{
                      endAdornment: filter && filter.dateFrom && (
                        <Clear
                          onClick={handleClearDates('dateFrom')}
                          style={{
                            cursor: 'pointer',
                            marginRight: 10,
                            fontSize: 14
                          }}
                        />
                      )
                    }}
                    inputVariant='outlined'
                    label='Datum från'
                    format='YYYY/MM/DD'
                    value={filter.dateFrom || null}
                    cancelLabel={null}
                    okLabel={null}
                    autoOk
                    onChange={handleFilterDate('dateFrom')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <DatePicker
                    InputProps={{
                      endAdornment: filter && filter.dateTo && (
                        <Clear
                          onClick={handleClearDates('dateTo')}
                          style={{
                            cursor: 'pointer',
                            marginRight: 10,
                            fontSize: 14
                          }}
                        />
                      )
                    }}
                    label='Till'
                    inputVariant='outlined'
                    format='YYYY/MM/DD'
                    cancelLabel={null}
                    okLabel={null}
                    autoOk
                    value={filter.dateTo || null}
                    onChange={handleFilterDate('dateTo')}
                  />
                </FormControl>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </div>
    )
  }
}

Filter.defaultProps = {
  areas: null,
  organisations: null,
  user: null,
  placeholder: null,
  filter: null,
  types: null,
  hasDate: false,
  hasStatuses: false,
  handleFilterDate: null,
  onFilterAccept: null,
  handleClearDates: null
}

Filter.propTypes = {
  hasStatuses: PropTypes.bool,
  hasDate: PropTypes.bool,
  areas: PropTypes.array,
  organisations: PropTypes.array,
  user: PropTypes.object,
  handleFilter: PropTypes.func.isRequired,
  handleFilterDate: PropTypes.func,
  handleClearDates: PropTypes.func,
  onFilterAccept: PropTypes.func,
  placeholder: PropTypes.string,
  filter: PropTypes.object,
  types: PropTypes.array
}

export default Filter
