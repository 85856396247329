import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const styles = {}

const CenteredFloatingButtonMobile = ({ onClick }) => (
  <Fab
    color='primary'
    aria-label='add'
    onClick={onClick}
    style={{
      position: 'fixed',
      bottom: 110,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 99
    }}>
    <AddIcon />
  </Fab>
)

CenteredFloatingButtonMobile.propTypes = {
  onClick: PropTypes.func.isRequired
}

const CenteredFloatingButtonMobileWithStyles = injectSheet(styles)(
  CenteredFloatingButtonMobile
)
export default CenteredFloatingButtonMobileWithStyles
