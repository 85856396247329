import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const styles = {
  root: {
    padding: props =>
      props.padding ? props.padding : 'var(--section-padding-default)'
  },
  editor: {
    minHeight: 130,
    backgroundColor: 'var(--color-input)',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: 20,
    borderBottom: '2px solid var(--color-black)',
    '&:hover': {
      background: 'var(--color-input-hover)'
    }
  },
  editorFocus: {
    borderBottom: '2px solid var(--color-primary)',
    minHeight: 130,
    backgroundColor: 'var(--color-input)',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    transition: 'all 0.3s',
    padding: 20,
    '&:hover': {
      background: 'var(--color-input-hover)'
    }
  }
}

const toolbarConfig = {
  options: ['inline', 'blockType', 'list'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough']
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
  }
}

const ContentEditor = ({
  classes,
  content,
  setContent,
  parentStateChanged,
  onChange
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [isFocused, setIsFocused] = useState(false)

  const convertContentToRawFormat = () =>
    convertToRaw(editorState.getCurrentContent())

  const convertContentToEditorState = () =>
    EditorState.createWithContent(
      convertFromRaw(
        typeof content === 'string' ? JSON.parse(content) : content
      )
    )

  useEffect(() => {
    if (content) {
      setContent(convertContentToRawFormat())
    }
  }, [editorState])

  useEffect(() => {
    if (content) {
      setEditorState(convertContentToEditorState())
    }
  }, [parentStateChanged])

  const onEditorStateChange = editorStateObj => {
    setEditorState(editorStateObj)
    setContent(convertContentToRawFormat())
  }

  return (
    <div className={classes.root}>
      <Editor
        toolbar={toolbarConfig}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={() => onChange && onChange()}
        editorClassName={isFocused ? classes.editorFocus : classes.editor}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        handlePastedText={() => false}
        stripPastedStyles
      />
    </div>
  )
}

ContentEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.any,
  setContent: PropTypes.func.isRequired,
  parentStateChanged: PropTypes.bool,
  onChange: PropTypes.func
}

ContentEditor.defaultProps = {
  content: null,
  parentStateChanged: undefined,
  onChange: undefined
}

const ContentEditorWithStyles = injectSheet(styles)(ContentEditor)
export default ContentEditorWithStyles
