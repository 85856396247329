import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const ITEM_HEIGHT = 48

class ActionMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      isOpen: false
    }
  }

  render() {
    const { object, disabled, options } = this.props
    const currentUser = JSON.parse(localStorage.getItem('user'))

    return (
      <React.Fragment>
        <IconButton
          disabled={disabled}
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={e =>
            this.setState({ anchorEl: e.currentTarget, isOpen: true })
          }>
          <MoreVertIcon />
        </IconButton>
        {this.state.isOpen && (
          <Menu
            id='long-menu'
            anchorEl={this.state.anchorEl}
            open={this.state.isOpen}
            onClose={() => this.setState({ anchorEl: null, isOpen: false })}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200
              }
            }}>
            {options.map(option => {
              if (
                option &&
                option.permissions &&
                !option.permissions.some(permission =>
                  currentUser.roles.includes(permission)
                )
              )
                return null
              return (
                <MenuItem
                  key={option.action}
                  onClick={() => {
                    option.onClick(object)
                    this.setState({ isOpen: false, anchorEl: null })
                  }}>
                  {option.component
                    ? option.component(object)
                    : global._(option.action)}
                </MenuItem>
              )
            })}
          </Menu>
        )}
      </React.Fragment>
    )
  }
}

ActionMenu.defaultProps = {
  disabled: false
}

ActionMenu.propTypes = {
  object: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired
}

export default ActionMenu
