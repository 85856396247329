import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Grid, Icon } from '@material-ui/core'
import moment from 'moment'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import Notification from '../../common/Notification'
import FullscreenModal from '../../components/FullscreenModal'
import ObjectListMobile from '../../components/ObjectListMobile'
import Typography from '../../components/Typography'
import MobileContainer from '../../components/MobileContainer'
import { LogTargetTypes } from '../../utils/constants'

const styles = {
  root: {},
  section: {
    padding: 'var(--section-padding-default)'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    minWidth: 60,
    borderRadius: 500,
    backgroundColor: 'var(--color-light-grey)',
    marginRight: 20
  }
}

const KeeHistoryMobile = props => {
  const {
    classes,
    history,
    error,
    events,
    filterFields,
    user,
    filter,
    noResultsMessage,
    fetchMoreEvents,
    canFetchMoreEvents,
    isLoadingEvents,
    searchField
  } = props

  const [showMobileFilter, setShowMobileFilter] = useState(false)
  const [eventObj, setEventObj] = useState(null)

  const columns = [
    {
      key: 'icon',
      format: obj => (
        <Icon style={{ color: 'var(--color-middle-grey)' }}>history</Icon>
      )
    },
    {
      key: 'operation',
      bold: true,
      format: operation =>
        operation && (
          <Typography
            variant='body'
            ellipsis
            dangerouslySetInnerHTML={{
              __html: global._(`KeeHistory.Operations.${operation}`)
            }}
          />
        )
    },
    {
      key: 'targetName',
      bold: true,
      format: (firstKey, secondKey, obj) => (
        <Typography variant='body2' faded fontNormal ellipsis>
          {obj.targetType === LogTargetTypes.DEVICE
            ? obj.deviceName
            : `${obj.lockName || global._('Common.NoName')} - ${
                obj.userName || global._('Common.NoName')
              }`}
        </Typography>
      )
    }
  ]

  const dataRow = (title, info) => (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant='body' faded>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        <Typography variant='body' bold>
          {info}
        </Typography>
      </Grid>
    </Grid>
  )

  let subtitle
  if (eventObj && eventObj.targetType === LogTargetTypes.LOCK) {
    if (eventObj.agentType === 'DELEGATED_ACCESS') {
      subtitle = (
        <>
          <span>{eventObj.userName}</span>
          <br />
          <span style={{ color: '#888', fontSize: 14 }}>
            {global._('KeeHistory.SharedBy', [eventObj.delegatorName])}
          </span>
        </>
      )
    } else {
      subtitle = eventObj.userName
    }
  } else if (eventObj) {
    subtitle = eventObj.deviceName
  }
  return (
    <>
      <MobileContainer background='white'>
        {showMobileFilter && (
          <FullscreenModal
            title='Filter'
            onClose={() => setShowMobileFilter(false)}
            rightActionItem='close'
            padding
            content={filterFields()}
          />
        )}
        {eventObj && (
          <FullscreenModal
            title={global._(`KeeHistory.Operations.${eventObj.operation}`)}
            onClose={() => setEventObj(null)}
            padding
            content={
              <MobileContainer background='white'>
                <ScrollView id='scrollable-container'>
                  <div>
                    <div style={{ padding: 'var(--section-padding-default)' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.iconContainer}>
                          <Icon
                            style={{
                              fontSize: 30,
                              color: 'var(--color-middle-grey)'
                            }}
                          >
                            history
                          </Icon>
                        </div>
                        <div>
                          <Typography
                            variant='body2'
                            style={{ color: 'var(--color-middle-grey)' }}
                          >
                            {eventObj.targetType === LogTargetTypes.LOCK
                              ? eventObj.lockName || '-'
                              : global._('Common.Device')}
                          </Typography>
                          <Typography
                            variant='subtitleBig'
                            bold
                            style={{ wordBreak: 'break-word', lineHeight: 1 }}
                          >
                            {subtitle}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        borderTop: '1px solid var(--color-divider-light)',
                        borderBottom: '1px solid var(--color-divider-light)'
                      }}
                    >
                      <div
                        style={{ padding: '0 var(--section-padding-default)' }}
                      >
                        <div
                          style={{
                            padding: 'var(--section-padding-default) 0'
                          }}
                        >
                          {dataRow(
                            global._('Common.Timestamp'),
                            moment(eventObj.createdAt).format(
                              'YYYY-MM-DD HH:mm'
                            )
                          )}
                          {eventObj.targetType === LogTargetTypes.LOCK &&
                            dataRow(
                              global._('Common.Device'),
                              eventObj.deviceName || '-'
                            )}
                          {dataRow(
                            global._('Common.Property'),
                            eventObj.propertyName || '-'
                          )}
                          {dataRow(
                            global._('Common.Organisation'),
                            eventObj.organisation.name || '-'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollView>
              </MobileContainer>
            }
          />
        )}
        <NavigationBar
          title={global._('KeeHistory.Title')}
          backAction={() => history.push('/admin')}
          rightActionItem={
            <div
              onClick={() => setShowMobileFilter(true)}
              style={{ color: 'var(--color-primary)' }}
            >
              <Icon>filter_list</Icon>
            </div>
          }
        />
        <ScrollView id='scrollable-container'>
          <div>
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ margin: '20px 0' }}
              />
            )}
            <div style={{ paddingBottom: 20 }}>
              <>
                <Grid
                  container
                  style={{ padding: 'var(--section-padding-default)' }}
                >
                  <Grid item xs={12}>
                    {searchField()}
                  </Grid>
                </Grid>
                <ObjectListMobile
                  objects={events}
                  user={user}
                  history={history}
                  filter={filter}
                  rowsAreClickable={false}
                  rowOnClick={obj => {
                    setEventObj(obj)
                  }}
                  noResultsMessage={noResultsMessage}
                  columns={columns}
                  fetchMoreObjects={fetchMoreEvents}
                  canFetchMoreObjects={canFetchMoreEvents}
                  isLoadingObjects={isLoadingEvents}
                />
              </>
            </div>
          </div>
        </ScrollView>
      </MobileContainer>
    </>
  )
}

KeeHistoryMobile.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  events: PropTypes.array,
  searchField: PropTypes.func.isRequired,
  filterFields: PropTypes.func.isRequired,
  canFetchMoreEvents: PropTypes.bool,
  fetchMoreEvents: PropTypes.func.isRequired,
  isLoadingEvents: PropTypes.bool,
  user: PropTypes.object,
  filter: PropTypes.object,
  noResultsMessage: PropTypes.string
}

KeeHistoryMobile.defaultProps = {
  events: [],
  canFetchMoreEvents: false,
  isLoadingEvents: false,
  user: null,
  filter: null,
  noResultsMessage: ''
}

const KeeHistoryMobileWithStyles = injectSheet(styles)(KeeHistoryMobile)
export default KeeHistoryMobileWithStyles
