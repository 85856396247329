import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Checkbox, Grid, TextField } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import axios from 'axios'
import Row from 'src/components/Row'
import Column from 'src/components/Column'
import { AccessTime, ArrowForward, ArrowRight, Today } from '@material-ui/icons'
import TimezoneSelect from 'react-timezone-select'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import PageContentHeader from '../../../components/PageContentHeader'
import Paper from '../../../components/Paper'
import Typography from '../../../components/Typography'
import Button from '../../../components/Button'
import FullscreenModal from '../../../components/FullscreenModal'
import TabBar from '../../../components/TabBar'
import Success from '../../../components/Success'
import Notification from '../../../common/Notification'
import FloatingAlert from '../../../components/FloatingAlert'
import AsyncSelect from '../../../components/AsyncSelect'
import { UserRoles } from '../../../utils/constants'
import AccessGroupItems from './AccessGroupItems'
import MobileTabBar from '../../../components/MobileTabBar'
import Scheduler from '../../../components/Scheduler'

const styles = {
  root: {},
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
  },
  section: {
    width: 'calc(100% + 40px)',
    padding: '20px 20px',
    borderBottom: '1px solid var(--color-divider-light)',
    marginLeft: '-20px',
    marginRight: '-20px',
    '&:first-child': {
      paddingTop: 10,
      paddingBottom: 30
    },
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  headerSectionMobile: {
    display: 'flex',
    flexDirection: 'column'
  },
  mobileContent: {},
  '@media (max-width: 900px)': {
    contentHeader: {
      marginBottom: 0,
      padding: 20,
      backgroundColor: 'var(--color-white)',
      borderBottom: '1px solid var(--color-divider-light)'
    },
    mobileContent: {
      backgroundColor: 'var(--color-white)',
      padding: '10px 20px'
    }
  }
}

const Wrapper = props => {
  if (props.isMobile) return <div {...props} />
  return <Paper {...props} />
}

const AccessGroup = props => {
  const { match, classes, user, history } = props

  const isMobileSchedule = history.location.hash.includes('schedule')

  const isPartnerAdmin = user.roles.includes(UserRoles.PartnerAdmin)
  const { organisationId, groupId } = match.params
  const selectedOrgFromPrevPage =
    history.location.state && history.location.state.organisation
  const { isMobile } = useWindowDimensions()
  const [createSuccess, setCreateSuccess] = useState(false)
  const [newAccessgroup, setNewAccessgroup] = useState({ locks: [], users: [] })
  const [groupName, setGroupName] = useState()
  const [shareLimit, setShareLimit] = useState(0)
  const [error, setError] = useState(null)
  const [organisation, setOrganisation] = useState(
    selectedOrgFromPrevPage || (organisationId ? {} : user.organisation)
  )
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [stepTwo, setStepTwo] = useState(false) // Step two is add users
  const [stepThree, setStepThree] = useState(false) // Step three is add locks (last step)

  const baseCrumbs = [
    { path: '/admin', name: 'Admin' },
    {
      path: `/admin/accessgroups/${organisationId || user.organisation.id}`,
      name: 'Behörighetsgrupper'
    }
  ]
  const [crumbs, setCrumbs] = useState(baseCrumbs)

  const handleNextOrSave = async () => {
    if (!organisation) {
      return
    }
    try {
      if (groupId) {
        const { data } = await axios.patch(
          `/v1/organisations/${organisation.id}/accessgroups/${groupId}`,
          { name: groupName.trim(), shareLimit }
        )
        setSaveSuccess(true)
        setNewAccessgroup(data)
        setGroupName(data.name)
      } else {
        setNewAccessgroup({
          ...newAccessgroup,
          organisation,
          name: groupName,
          shareLimit
        })
        setStepTwo(true)
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    if (groupId) {
      ;(async () => {
        const { data } = await axios.get(
          `/v1/organisations/${
            organisation.id || organisationId
          }/accessgroups/${groupId}`
        )
        setGroupName(data.name)
        setOrganisation(data.organisation)
        setShareLimit(data.shareLimit || 0)
        setNewAccessgroup(data)
        setCrumbs([
          ...crumbs,
          {
            name: `${data.name}`,
            path: `/admin/accessgroups/details/${
              organisation.id || organisationId
            }/${data.id}`
          }
        ])
      })()
    } else {
      setCrumbs([
        ...crumbs,
        {
          name: 'Skapa behörighetsgrupp steg 1/3',
          path: '/admin/accessgroups/new'
        }
      ])
    }
  }, [groupId])

  let canSave = false
  if (groupId && newAccessgroup) {
    if (groupName !== newAccessgroup.name && groupName) {
      canSave = true
    } else if (shareLimit !== newAccessgroup.shareLimit) {
      canSave = true
    }
  } else if (groupName && groupName.trim()) {
    canSave = true
  }

  const handleSaveSchedule = async scheduleData => {
    try {
      const { data } = await axios.put(
        `/v1/organisations/${organisationId}/accessgroups/${groupId}/schedule`,
        scheduleData
      )
      setNewAccessgroup({
        ...newAccessgroup,
        schedule: data
      })
      setSaveSuccess(true)
      setTimeout(() => {
        setSaveSuccess(false)
      }, 4000)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const _scheduler = (
    <Scheduler
      {...props}
      schedule={newAccessgroup.schedule}
      onSave={scheduleData => {
        handleSaveSchedule(scheduleData)
      }}
      style={{ marginTop: 32 }}
    />
  )

  const Content = (
    <>
      {!isMobile && <PageContentHeader breadcrumbItems={crumbs} />}
      <div className={classes.contentHeader}>
        <div className={classes.headerSectionMobile}>
          {isMobile && (
            <Typography
              variant='body2'
              style={{ color: 'var(--color-middle-grey)' }}
            >
              {global._(
                groupId ? 'AccessGroups.Edit' : 'AccessGroups.StepOneMobile'
              )}
            </Typography>
          )}
        </div>
        {!isMobile && (
          <Button
            variant='outlined'
            onClick={() =>
              history.push(
                `/admin/accessgroups/${organisationId || user.organisation.id}`
              )
            }
            style={{ marginLeft: 'auto', marginRight: 20 }}
            minWidth
          >
            {global._('Common.Cancel')}
          </Button>
        )}
        <Button
          style={{ maxWidth: 140, marginLeft: isMobile ? 'auto' : null }}
          minWidth
          variant='primary'
          disabled={!canSave}
          onClick={() => handleNextOrSave()}
        >
          {global._(groupId ? 'Common.Save' : 'Next')}
        </Button>
      </div>
      <Wrapper
        isMobile={isMobile}
        smallerPadding={isMobile ? undefined : true}
        alignItems={isMobile ? undefined : 'left'}
        className={isMobile ? classes.mobileContent : undefined}
      >
        {!isMobile && (
          <div className={classes.section}>
            <Typography variant='subtitleBig' bold>
              {global._('AccessGroups.DetailsTitle')}
            </Typography>
          </div>
        )}
        <div className={classes.section}>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                style={{
                  width: '100%'
                }}
                variant='filled'
                label={global._('AccessGroups.GroupName')}
                value={groupName || ''}
                helperText={global._('Common.Required')}
                onChange={e => setGroupName(e.target.value)}
              />
            </Grid>
            {isPartnerAdmin && (
              <Grid item xs={12} md={6}>
                <AsyncSelect
                  isDisabled={newAccessgroup && newAccessgroup.id}
                  isSearchable
                  isClearable={false}
                  helpText={global._('Common.Required')}
                  value={{ label: organisation.name, value: organisation }}
                  labelKey='name'
                  name='organisation'
                  style={isMobile ? { marginTop: 10 } : undefined}
                  placeholder={global._('Common.SelectOrganisation')}
                  onChange={opt => setOrganisation(opt.value)}
                  url='/v1/organisations?search={{input}}&orderBy=name&order=asc'
                />
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <TextField
                style={{
                  width: '100%',
                  marginTop: isMobile ? 10 : 0
                }}
                disabled={shareLimit === 0}
                variant='filled'
                label={global._('AccessGroups.ShareLimit')}
                value={shareLimit}
                helperText={global._('AccessGroups.ShareLimitHelperText')}
                onChange={e => {
                  const { value } = e.target
                  const nValue = Number.parseInt(value, 10)
                  if (!value) {
                    setShareLimit('')
                  } else if (Number.isNaN(nValue) || nValue < 0) {
                    setShareLimit(1)
                  } else {
                    setShareLimit(nValue)
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Row
                align='center'
                style={{ margin: isMobile ? '12px 0' : '14px 0px 12px 16px' }}
              >
                <Checkbox
                  checked={shareLimit === 0}
                  onClick={() => setShareLimit(shareLimit === 0 ? 1 : 0)}
                  style={{
                    transform: isMobile ? 'scale(0.7)' : undefined,
                    padding: 0
                  }}
                />
                <Typography bold variant='body' style={{ marginLeft: 16 }}>
                  {global._('AccessGroups.NotShareable')}
                </Typography>
              </Row>
            </Grid>
          </Grid>
          <Row align='center' style={{ marginTop: 16 }} />
          {error && <Notification type='error' message={error} />}
          {saveSuccess && (
            <FloatingAlert
              rightBottom={!isMobile}
              small={!isMobile}
              variant='info'
              message={global._('AccessGroups.SaveSuccess')}
            />
          )}
        </div>
      </Wrapper>
      {newAccessgroup.id && !isMobile && _scheduler}
    </>
  )

  const CreateSuccesful = (
    <Success>
      <Typography
        variant='subtitleBig'
        style={{
          marginBottom: 28
        }}
      >
        {global._('AccessGroups.CreateSuccess')}
      </Typography>
      <Button
        style={{ width: '100%' }}
        variant='primary'
        onClick={() => {
          setCreateSuccess(false)
          history.replace(
            isMobile
              ? `/admin/accessgroups/${
                  newAccessgroup && newAccessgroup.organisationId
                }/mob/${newAccessgroup && newAccessgroup.id}`
              : `/admin/accessgroups/${
                  newAccessgroup && newAccessgroup.organisationId
                }`
          )
        }}
      >
        {global._(
          isMobile ? 'AccessGroups.ToAccessGroup' : 'AccessGroups.ToList'
        )}
      </Button>
      <Button
        style={{ width: '100%' }}
        variant='outlined'
        onClick={() => {
          setNewAccessgroup({ locks: [], users: [] })
          setCreateSuccess(false)
          setGroupName('')
        }}
      >
        {global._('AccessGroups.ToNewAccessGroup')}
      </Button>
      {!isMobile && (
        <Button
          variant='none'
          onClick={() =>
            history.push(
              `/admin/accessgroups/${
                newAccessgroup && newAccessgroup.organisationId
              }`
            )
          }
        >
          {global._('Common.Back')}
        </Button>
      )}
    </Success>
  )

  if (newAccessgroup && (stepTwo || stepThree)) {
    return (
      <AccessGroupItems
        setGoToStep={stepTwo ? setStepThree : setStepTwo}
        setCurrentStep={stepTwo ? setStepTwo : setStepThree}
        newAccessgroup={newAccessgroup}
        setNewAccessgroup={setNewAccessgroup}
        history={history}
        newType={stepTwo ? 'users' : 'locks'}
        newCrumbs={[
          ...baseCrumbs,
          {
            name: `Skapa behörighetsgrupp steg ${stepTwo ? '2/3' : '3/3'}`,
            path: '/admin/accessgroups/new'
          }
        ]}
        user={user}
        setCreateSuccess={stepTwo ? undefined : setCreateSuccess}
      />
    )
  }

  if (isMobile) {
    return (
      <>
        {createSuccess && !stepTwo && !stepThree ? (
          <FullscreenModal
            rightActionItem={
              <div
                onClick={() =>
                  history.push(`/admin/accessgroups/${organisationId}`)
                }
              >
                <Icon>close</Icon>
              </div>
            }
            title={global._('AccessGroups.CreateAccessGroup')}
            content={CreateSuccesful}
          />
        ) : (
          <FullscreenModal
            onClose={() => history.goBack()}
            title={
              groupId && newAccessgroup
                ? newAccessgroup.name
                : global._('AccessGroups.CreateAccessGroup')
            }
            content={
              <>
                {isMobileSchedule ? _scheduler : Content}
                <TabBar>
                  <MobileTabBar />
                </TabBar>
              </>
            }
          />
        )}
      </>
    )
  }

  return (
    <>{createSuccess && !stepTwo && !stepThree ? CreateSuccesful : Content}</>
  )
}

AccessGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const AccessGroupWithStyles = injectSheet(styles)(AccessGroup)
export default AccessGroupWithStyles
