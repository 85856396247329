import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import axios from 'axios'
import _ from 'lodash'
import Button from 'src/components/Button'
import { history } from 'src/redux/store'
import withUser from 'src/utils/withUser'
import Row from 'src/components/Row'
import Alert from 'src/components/Alert'
import Typography from '../../../components/Typography'
import {
  CaseTypes,
  CaseIcons,
  DocumentTypes,
  UserRoles,
  CaseStatus,
  OrderStatus,
  OrganisationServices,
  ShippingTypes
} from '../../../utils/constants'
import {
  mapCaseStatus,
  formatDate,
  mapCaseInvoiceStatus,
  checkIfOrgHasAccessToScope
} from '../../../utils/helpers'
import useWindowDimensions from '../../../utils/useWindowDimensions'

const styles = {
  root: {},
  section: {
    borderBottom: '1px solid var(--color-divider-light)',
    display: 'flex',
    padding: '20px 20px'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    minWidth: 60,
    borderRadius: 500,
    backgroundColor: 'var(--color-light-grey)',
    marginRight: 20
  },
  gridItem: {
    padding: 5
  },
  alignRightOnMobile: {},
  '@media (max-width: 900px)': {
    alignRightOnMobile: {
      textAlign: 'right'
    }
  }
}

const keyData = {
  header: [
    { key: 'createdAt', localization: 'CaseDetails.CreatedAt' },
    { key: 'updatedAt', localization: 'CaseDetails.UpdatedAt' },
    { key: 'createdBy', localization: 'CaseDetails.CreatedBy' },
    { key: 'updatedBy', localization: 'CaseDetails.UpdatedBy' }
  ],
  body: [
    { key: 'type', localization: 'CaseDetails.CaseType' },
    { key: 'status', localization: 'CaseDetails.Status' },
    {
      key: 'action',
      localization: 'CaseDetails.CaseCategory',
      transform: caseItem => {
        let value = global._(`CaseTypeActions.${caseItem.action}`)
        if (caseItem.subcategory) {
          value += ` (${global._(
            `CaseTypeActionSubcategories.${caseItem.subcategory}`
          )})`
        }
        return value
      }
    },
    // { key: 'contact', localization: 'CaseDetails.CaseContact' },
    // { key: 'urgent', localization: 'CaseDetails.CaseUrgent' },
    { key: 'reference', localization: 'CaseDetails.CaseReference' },
    { key: 'onCall', localization: 'CaseDetails.CaseOnCall' },
    { key: 'organisation', localization: 'CaseDetails.CaseOrganisation' },
    {
      key: 'marking',
      localization: caseItem =>
        global._(
          `CaseTypeActionSubcategory_Marking.KEY.${caseItem.action}.${caseItem.subcategory}`
        )
    },
    { key: 'address', localization: 'CaseDetails.CaseAddress' },
    {
      key: 'systemNumber',
      if: caseItem => caseItem.subcategory === 'MECHANICAL',
      localization: 'Cases.NewCase.SystemNumber'
    },
    {
      key: 'priority',
      localization: 'Cases.Priority',
      transform: caseItem =>
        caseItem.priority
          ? global._(`Cases.Priority.Details.${caseItem.priority}`)
          : '-'
    }
  ]
}
const serviceData = {
  header: [
    { key: 'createdAt', localization: 'CaseDetails.CreatedAt' },
    { key: 'updatedAt', localization: 'CaseDetails.UpdatedAt' },
    { key: 'createdBy', localization: 'CaseDetails.CreatedBy' },
    { key: 'updatedBy', localization: 'CaseDetails.UpdatedBy' }
  ],
  body: [
    { key: 'type', localization: 'CaseDetails.CaseType' },
    { key: 'status', localization: 'CaseDetails.Status' },
    { key: 'action', localization: 'CaseDetails.CaseCategory' },
    // { key: 'contact', localization: 'CaseDetails.CaseContact' },
    // { key: 'urgent', localization: 'CaseDetails.CaseUrgent' },
    { key: 'reference', localization: 'CaseDetails.CaseReference' },
    {
      key: 'onCall',
      if: caseItem => caseItem.action === 'TROUBLESHOOTING',
      localization: 'CaseDetails.CaseOnCall'
    },
    { key: 'organisation', localization: 'CaseDetails.CaseOrganisation' },
    {
      key: 'productType',
      if: caseItem => caseItem.action !== 'OTHER',
      localization: 'CaseDetails.CaseProduct'
    },
    // { key: 'marking', localization: 'CaseDetails.CaseMarking' },
    { key: 'system', localization: 'CaseDetails.CaseProperty' },
    { key: 'address', localization: 'CaseDetails.CaseAddress' },
    { key: 'space', localization: 'CaseDetails.CaseSpace' },
    {
      key: 'priority',
      if: caseItem => caseItem.action !== 'OTHER',
      localization: 'Cases.Priority',
      transform: caseItem =>
        caseItem.priority
          ? global._(`Cases.Priority.Details.${caseItem.priority}`)
          : '-'
    }
  ]
}
const supportData = {
  header: [
    { key: 'createdAt', localization: 'CaseDetails.CreatedAt' },
    { key: 'updatedAt', localization: 'CaseDetails.UpdatedAt' },
    { key: 'createdBy', localization: 'CaseDetails.CreatedBy' },
    { key: 'updatedBy', localization: 'CaseDetails.UpdatedBy' }
  ],
  body: [
    { key: 'type', localization: 'CaseDetails.CaseType' },
    { key: 'status', localization: 'CaseDetails.Status' },
    { key: 'action', localization: 'CaseDetails.CaseCategory' },
    {
      key: 'system',
      if: caseItem =>
        ['SOFTWARE', 'LOCK_SYSTEM', 'OTHER'].includes(caseItem.action),
      localization: 'CaseDetails.CaseProperty'
    },
    {
      key: 'address',
      if: caseItem =>
        ['SOFTWARE', 'LOCK_SYSTEM', 'OTHER'].includes(caseItem.action),
      localization: 'CaseDetails.CaseAddress'
    },
    {
      key: 'space',
      if: caseItem =>
        ['SOFTWARE', 'LOCK_SYSTEM', 'OTHER'].includes(caseItem.action),
      localization: 'CaseDetails.CaseSpace'
    },
    {
      key: 'productType',
      if: caseItem => ['SOFTWARE', 'OTHER'].includes(caseItem.action),
      localization: caseItem =>
        global._(`Cases.NewCase.ProductType.${caseItem.action}`)
    },
    {
      key: 'systemNumber',
      if: caseItem => caseItem.action === 'LOCK_SYSTEM',
      localization: 'Cases.NewCase.SystemNumber'
    },
    { key: 'contact', localization: 'CaseDetails.CaseContact' },
    // { key: 'urgent', localization: 'CaseDetails.CaseUrgent' },
    { key: 'reference', localization: 'CaseDetails.CaseReference2' },
    {
      key: 'onCall',
      if: caseItem => ['SOFTWARE', 'OTHER'].includes(caseItem.action),
      localization: 'CaseDetails.CaseOnCall'
    },
    { key: 'organisation', localization: 'CaseDetails.CaseOrganisation' },
    {
      key: 'priority',
      if: caseItem => caseItem.action !== 'PORTAL',
      localization: 'Cases.Priority',
      transform: caseItem =>
        caseItem.priority
          ? global._(`Cases.Priority.Details.${caseItem.priority}`)
          : '-'
    }
  ]
}
const claimData = {
  header: [
    { key: 'createdAt', localization: 'CaseDetails.CreatedAt' },
    { key: 'updatedAt', localization: 'CaseDetails.UpdatedAt' },
    { key: 'createdBy', localization: 'CaseDetails.CreatedBy' },
    { key: 'updatedBy', localization: 'CaseDetails.UpdatedBy' }
  ],
  body: [
    { key: 'type', localization: 'CaseDetails.CaseType' },
    { key: 'status', localization: 'CaseDetails.Status' },
    { key: 'system', localization: 'CaseDetails.CaseProperty' },
    { key: 'serialNumber', localization: 'CaseDetails.CaseSerialNumber' },
    { key: 'productType', localization: 'CaseDetails.CaseProduct' },
    { key: 'replacement', localization: 'CaseDetails.CaseReplacement' },
    { key: 'returnTo', localization: 'CaseDetails.CaseDelivery' },
    { key: 'marking', localization: 'CaseDetails.CaseMarking' },
    { key: 'newProductWanted', localization: 'CaseDetails.CasePostalDelivery' },
    { key: 'factoryReset', localization: 'CaseDetails.CaseFactoryReset' },
    { key: 'organisation', localization: 'CaseDetails.CaseOrganisation' }
  ]
}
const feedbackData = {
  header: [
    { key: 'createdAt', localization: 'CaseDetails.CreatedAt' },
    { key: 'updatedAt', localization: 'CaseDetails.UpdatedAt' }
  ],
  body: [
    { key: 'type', localization: 'CaseDetails.CaseType' },
    { key: 'status', localization: 'CaseDetails.Status' },
    { key: 'createdBy', localization: 'CaseDetails.CreatedBy' },
    { key: 'organisation', localization: 'CaseDetails.CaseOrganisation' }
  ]
}
const relocationData = {
  header: [
    { key: 'createdAt', localization: 'CaseDetails.CreatedAt' },
    { key: 'updatedAt', localization: 'CaseDetails.UpdatedAt' },
    { key: 'createdBy', localization: 'CaseDetails.CreatedBy' },
    { key: 'updatedBy', localization: 'CaseDetails.UpdatedBy' }
  ],
  body: [
    { key: 'transferDate', localization: 'Case.TransferDate' },
    { key: 'apartmentNumber', localization: 'Case.ApartmentNumber' },
    { key: 'onCall', localization: 'CaseDetails.CaseOnCall' },
    { key: 'reference', localization: 'CaseDetails.CaseReference' },
    {
      key: 'priority',
      localization: 'Cases.Priority',
      transform: caseItem =>
        caseItem.priority
          ? global._(`Cases.Priority.Details.${caseItem.priority}`)
          : '-'
    }
  ]
}
const otherData = {
  header: [
    { key: 'createdAt', localization: 'CaseDetails.CreatedAt' },
    { key: 'updatedAt', localization: 'CaseDetails.UpdatedAt' },
    { key: 'createdBy', localization: 'CaseDetails.CreatedBy' },
    { key: 'updatedBy', localization: 'CaseDetails.UpdatedBy' }
  ],
  body: [
    { key: 'onCall', localization: 'CaseDetails.CaseOnCall' },
    { key: 'reference', localization: 'CaseDetails.CaseReference' },
    {
      key: 'priority',
      localization: 'Cases.Priority',
      transform: caseItem =>
        caseItem.priority
          ? global._(`Cases.Priority.Details.${caseItem.priority}`)
          : '-'
    }
  ]
}
const orderData = caseObj => {
  const data = {
    header: [
      { key: 'createdAt', localization: 'CaseDetails.CreatedAt' },
      { key: 'updatedAt', localization: 'CaseDetails.UpdatedAt' },
      { key: 'createdBy', localization: 'CaseDetails.CreatedBy' },
      { key: 'updatedBy', localization: 'CaseDetails.UpdatedBy' },
      { key: 'apartmentNumber', localization: 'CaseDetails.ApartmentNumber' },
      { key: 'apartmentAddress', localization: 'CaseDetails.ApartmentAddress' }
    ],
    body: [
      { key: 'type', localization: 'CaseDetails.CaseType' },
      { key: 'status', localization: 'CaseDetails.Status' },
      { key: 'header', localization: 'CaseDetails.Category' },
      { key: 'organisation', localization: 'CaseDetails.Company' }
    ]
  }
  console.log('caseObj', caseObj)

  if (caseObj.key) {
    data.body.push(
      ...[
        { key: 'keyType', localization: 'CaseDetails.KeyVariant' },
        { key: 'keyMarking', localization: 'CaseDetails.KeyMarking' }
      ]
    )
  }

  data.body.push({
    key: 'shippingType',
    localization: 'Admin.Settings.Orders.Navigation.ShippingOptions'
  })

  return data
}

const objects = {
  organisation: 'organisation',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy'
}

const dateValues = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
}

const CaseData = props => {
  const { classes, caseObj, user, caseUrlType, selectedOrganisation } = props

  if (!caseObj) {
    return null
  }
  const canViewAgreements =
    user &&
    [
      UserRoles.SystemAdmin,
      UserRoles.PartnerAdmin,
      UserRoles.CustomerAdmin,
      UserRoles.Support
    ].some(role => user.roles.includes(role))
  const [property, setProperty] = useState()

  const statuses =
    caseUrlType === 'orders' ? { ...OrderStatus } : { ...CaseStatus }

  let userHasAccessToKeyManagement

  if (selectedOrganisation) {
    userHasAccessToKeyManagement =
      checkIfOrgHasAccessToScope(
        user,
        selectedOrganisation,
        OrganisationServices.KEY_MANAGEMENT
      ) &&
      user &&
      (user.roles.includes(UserRoles.PartnerAdmin) ||
        user.roles.includes(UserRoles.CustomerAdmin) ||
        user.roles.includes(UserRoles.Support))
  }

  useEffect(() => {
    if (caseObj.system) {
      setProperty({ name: caseObj.system.trim() })
    }
  }, [caseObj])

  const { isMobile } = useWindowDimensions()

  let CaseDetailsData
  switch (caseObj.type) {
    case CaseTypes.Key:
      CaseDetailsData = { ...keyData }
      break
    case CaseTypes.Service:
      CaseDetailsData = { ...serviceData }
      break
    case CaseTypes.Support:
      CaseDetailsData = { ...supportData }
      break
    case CaseTypes.Relocation:
      CaseDetailsData = { ...relocationData }
      break
    case CaseTypes.Other:
      CaseDetailsData = { ...otherData }
      break
    case CaseTypes.Claim:
      CaseDetailsData = { ...claimData }
      break
    case CaseTypes.Feedback:
      CaseDetailsData = { ...feedbackData }
      break
    case CaseTypes.Order:
      CaseDetailsData = orderData(caseObj)
      break
    default:
      CaseDetailsData = null
  }

  const renderValue = (key, value) => {
    const fieldDescriptor = CaseDetailsData?.body?.find(fd => fd.key === key)
    if (fieldDescriptor?.transform) {
      return fieldDescriptor.transform(caseObj || {})
    }
    if (key === 'createdBy') {
      return (
        <span>
          {value ? value.name || '-' : '-'}
          {caseObj && caseObj.createdByServiceDesk && (
            <span
              style={{ color: 'var(--color-middle-grey)', fontWeight: 400 }}
            >
              &nbsp;(Servicedesk)
            </span>
          )}
        </span>
      )
    }

    if (key === 'updatedBy') {
      return (
        <span>
          {value ? value.name || '-' : '-'}
          {caseObj && caseObj.updatedByServiceDesk && (
            <span
              style={{ color: 'var(--color-middle-grey)', fontWeight: 400 }}
            >
              &nbsp;(Servicedesk)
            </span>
          )}
        </span>
      )
    }

    if (key === 'system') {
      if (property) {
        return property.name || global._('Common.NoName')
      }

      return '-'
    }

    if (key === 'address') {
      if (property && property.address && caseObj.type === CaseTypes.Service) {
        return property.address
      }
      return caseObj.address || '-'
    }

    if (key === 'serviceAgreement') {
      if (
        caseObj &&
        caseObj.organisation &&
        caseObj.organisation.hasServiceAgreement
      ) {
        return global._('OrganisationDetail.HasServiceAgreement')
      }
      return '-'
    }

    if (key === 'dutyAgreement') {
      if (
        caseObj &&
        caseObj.organisation &&
        caseObj.organisation.hasDutyAgreement
      ) {
        return global._('OrganisationDetail.HasDutyAgreement')
      }
      return '-'
    }

    if (caseObj.key) {
      if (key === 'keyType') {
        return global._(`Keys.Types.${caseObj.key.type}`)
      }

      if (key === 'keyMarking') {
        return caseObj.key.marking
      }
    }

    if (key === 'shippingType' && caseObj.payments) {
      const [payment] = caseObj.payments
      if (payment) {
        try {
          caseObj.details = JSON.parse(payment.serializedDetails)
          if (caseObj.details?.shipping?.type === ShippingTypes.SEND) {
            const { type, address, postalCode, city } = caseObj.details.shipping
            return (
              <div>
                <div>
                  {global._(`Constants.ShippingTypes.CaseDetails.${type}`)}{' '}
                  {global._('Common.to')}:
                </div>
                <div style={{ marginTop: 10 }}>{address}</div>
                <div>
                  {postalCode} {city}
                </div>
              </div>
            )
          }
          return global._(
            `Constants.ShippingTypes.CaseDetails.${caseObj.details.shipping.type}`
          )
        } catch (e) {
          return '-'
        }
      }
    }

    if (typeof value === 'boolean') {
      return global._(`CaseDetails.BoolConversion.${value}`)
    }
    if (!value) {
      return '-'
    }
    if (Object.values(objects).includes(key)) {
      return value.name
    }
    if (Object.values(dateValues).includes(key)) {
      return formatDate(value)
    }

    switch (key) {
      case 'status':
        return (
          <span className={`status ${mapCaseStatus(value)}`}>
            {global._(`Case.Status.${value}`)}
          </span>
        )
      case 'invoiced':
        return (
          <span className={`status ${mapCaseInvoiceStatus(value)}`}>
            {global._(`Case.InvoiceStatus.${value}`)}
          </span>
        )
      case 'type':
        return global._(`CaseTypes.${value}`)
      case 'action':
        return global._(`CaseTypeActions.${value}`)
      case 'replacement':
        return global._(`ClaimReplacement.${value}`)
      case 'factoryReset':
        return global._(`ClaimFactoryReset.${value}`)
      case 'returnTo':
        return global._(`ClaimReturnTo.${value}`)
      case 'category':
        return global._(`ClaimReturnTo.${value}`)
      default:
        return value
    }
  }

  const hasDutyAgreement =
    caseObj && caseObj.organisation && caseObj.organisation.hasDutyAgreement
  const hasServiceAgreement =
    caseObj && caseObj.organisation && caseObj.organisation.hasServiceAgreement
  const hasServiceAgreementDocuments =
    hasServiceAgreement &&
    caseObj.organisation.documents &&
    caseObj.organisation.documents.length > 0

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {isMobile && (
        <div className={classes.section}>
          <div className={classes.iconContainer}>
            <Icon
              style={{
                fontSize: 30,
                color: 'var(--color-middle-grey)'
              }}
            >
              {CaseIcons[caseObj.type]}
            </Icon>
          </div>
          <div>
            <Typography
              ariant='small'
              style={{ color: 'var(--color-middle-grey)' }}
            >
              {`${global._(`CaseDetailsTypes.${caseObj.type}`)} #${caseObj.id}`}
            </Typography>
            <Typography
              variant='subtitleBig'
              bold
              style={{ wordBreak: 'break-word' }}
            >
              {caseObj.header}
            </Typography>
          </div>
        </div>
      )}
      <div className={classes.section}>
        <Grid container>
          {CaseDetailsData &&
            CaseDetailsData.header.map(item => {
              const value = caseObj[item.key]
              return (
                <React.Fragment
                  key={`case-detail-item-${item.key}-${Date.now()}`}
                >
                  <Grid item xs={6} md={3} xl={2} className={classes.gridItem}>
                    <Typography variant='bodyFaded'>
                      {global._(item.localization)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={3}
                    xl={4}
                    className={`${classes.gridItem} ${classes.alignRightOnMobile}`}
                  >
                    <Typography variant='body' bold>
                      {renderValue(item.key, value)}
                    </Typography>
                  </Grid>
                </React.Fragment>
              )
            })}
        </Grid>
      </div>
      <div className={classes.section}>
        <Grid container>
          {CaseDetailsData &&
            CaseDetailsData.body.map(item => {
              if (item.if && !item.if(caseObj)) {
                // Check if it should render
                return null
              }
              const value = caseObj[item.key]
              let { localization } = item
              if (typeof localization === 'function') {
                localization = localization(caseObj)
              } else {
                localization = global._(localization)
              }
              return (
                <React.Fragment
                  key={`case-detail-item-${item.key}-${Date.now()}`}
                >
                  <Grid item xs={6} md={3} xl={2} className={classes.gridItem}>
                    <Typography variant='bodyFaded'>{localization}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={3}
                    xl={4}
                    className={`${classes.gridItem} ${classes.alignRightOnMobile}`}
                  >
                    <Typography variant='body' bold>
                      {renderValue(item.key, value)}
                    </Typography>
                  </Grid>
                </React.Fragment>
              )
            })}
        </Grid>
      </div>
      {!isMobile && caseObj.keyId && userHasAccessToKeyManagement && (
        <div className={classes.section} style={{ flexDirection: 'column' }}>
          <Typography variant='subtitleBig' bold>
            {global._('Case.Key')}
          </Typography>
          <Button
            variant='outlined'
            style={{ marginTop: 16, width: isMobile ? '100%' : 200 }}
            onClick={() => {
              const url = `/keys/list/${caseObj.keyId}`

              window.sessionStorage.setItem(
                'selected_org',
                JSON.stringify(caseObj.organisation)
              )

              window.open(url)
            }}
          >
            {global._('Case.ShowKey')}
          </Button>
        </div>
      )}
      {canViewAgreements && caseObj.type !== CaseTypes.Order && (
        <div className={classes.section} style={{ flexDirection: 'column' }}>
          <Typography variant='subtitleBig' bold style={{ marginBottom: 12 }}>
            {global._('Case.Agreements')}
          </Typography>
          {hasDutyAgreement && (
            <Row align='center' style={{ marginBottom: 8 }}>
              <Icon style={{ marginRight: 8 }}>checkmark</Icon>
              <Typography variant='body'>
                {global._('OrganisationDetail.HasDutyAgreement')}
              </Typography>
            </Row>
          )}
          {hasServiceAgreement && (
            <Row align='center' style={{ marginBottom: 8 }}>
              <Icon style={{ marginRight: 8 }}>checkmark</Icon>
              <Typography variant='body'>
                {global._('OrganisationDetail.HasServiceAgreement')}
              </Typography>
            </Row>
          )}
          {!hasDutyAgreement && !hasServiceAgreement && '-'}
          {hasServiceAgreementDocuments && (
            <Button
              variant='outlined'
              style={{ marginTop: 16, width: isMobile ? '100%' : 200 }}
              onClick={() => {
                const url = `/documents?organisationId=${caseObj.organisation.id}&type=${DocumentTypes.ServiceAgreement}`
                if (isMobile) {
                  history.push(url)
                } else {
                  window.open(url)
                }
              }}
            >
              {global._('OrganisationDetail.ShowServiceAgreements')}
            </Button>
          )}
        </div>
      )}
      {caseObj.body && (
        <div className={classes.section} style={{ flexDirection: 'column' }}>
          <Typography variant='subtitleBig' bold>
            {global._('Case.ContentBody')}
          </Typography>
          <Typography
            variant='body'
            lineBreaks
            dangerouslySetInnerHTML={{ __html: caseObj.body }}
          />
        </div>
      )}
      {caseObj.type === CaseTypes.Order &&
        caseObj.status === statuses.Started &&
        user.id === caseObj.userId &&
        caseObj.details?.shipping?.type === ShippingTypes.COLLECT && (
          <div className={classes.section} style={{ flexDirection: 'column' }}>
            <Alert>{global._('Case.PaidPendingOrderInfo')}</Alert>
          </div>
        )}
    </div>
  )
}

CaseData.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  caseObj: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired
}

const CaseDataWithStyles = withUser(injectSheet(styles)(CaseData))
export default CaseDataWithStyles
