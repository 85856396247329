import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core'
import ExpectedReturnDate from 'src/components/ExpectedReturnDate'
import ItemBadge from 'src/components/ItemBadge'
import AsyncSelect from 'src/components/AsyncSelect'
import Paper from 'src/components/Paper'
import Typography from 'src/components/Typography'
import { emailRegExp, getSelectedOrg } from 'src/utils/helpers'
import withUser from 'src/utils/withUser'
import ssn from 'src/utils/ssn'
import useKeyLimitValidator from 'src/utils/validators/useKeyLimitValidator'
import PhoneNumberField from '../../../components/PhoneNumberField'
import { phoneNumberRegex } from '../../../utils/helpers'

const styles = {
  root: {
    marginTop: 21
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'var(--color-divider-light)'
  },
  singleRadioButton: {
    borderBottom: '1px solid var(--color-divider-light)',
    marginRight: '70px !important'
  }
}

export const STORAGE_KEY_LOAN_USER_DATA = 'keys.loan.user'

const UserSelect = ({ classes, data, onDataChange, ...otherProps }) => {
  const selectedOrg = getSelectedOrg(otherProps.user)
  const [expectedReturnDate, setExpectedReturnDate] = useState(
    data.expectedReturnDate
  )
  const [untilFurtherNotice, setUntilFurtherNotice] = useState(
    data.untilFurtherNotice
  )
  const [user, setUser] = useState(data.user || {})
  const [isNewUser, setIsNewUser] = useState(data.user && !data.user.id)

  const {
    isValidated,
    isKeyLimitReached,
    limit: keyLimit,
    count: keyCount
  } = useKeyLimitValidator({
    user: user || data.user,
    organisation: selectedOrg
  })

  const update = _data => {
    onDataChange({ ...data, ..._data })
  }
  const updateUser = _user => {
    if (_user.firstName && _user.lastName) {
      _user.name = `${_user.firstName} ${_user.lastName}`
    }
    setUser(_user)
    update({ user: _user })
  }

  React.useEffect(() => {
    update({
      validation: {
        isValidated,
        isKeyLimitReached,
        keyLimit,
        keyCount
      }
    })
  }, [isValidated, isKeyLimitReached, keyLimit, keyCount])

  return (
    <div className={classes.root}>
      <Paper noPadding>
        <Typography
          variant='subtitleBig'
          bold
          style={{ padding: '32px 20px 10px' }}
        >
          {global._('Keys.Loan.UserSelect.SectionTitle.Return')}
        </Typography>
        <ExpectedReturnDate
          isModal={false}
          update={update}
          setUntilFurtherNotice={setUntilFurtherNotice}
          setExpectedReturnDate={setExpectedReturnDate}
          expectedReturnDate={expectedReturnDate}
          untilFurtherNotice={untilFurtherNotice}
        />
        <div className={classes.divider} />
        <Typography
          variant='subtitleBig'
          bold
          style={{ padding: '32px 20px 0' }}
        >
          {global._('Keys.Loan.UserSelect.SectionTitle.UserSelect')}
        </Typography>
        <FormControl
          component='fieldset'
          fullWidth
          style={{ padding: '30px 20px' }}
        >
          <RadioGroup
            name='holderType'
            onChange={e => {
              const _isNewUser = e.target.value === 'newUser'
              setIsNewUser(_isNewUser)
              setUser({})
              update({ user: {}, isNewUser: _isNewUser })
            }}
            value={isNewUser ? 'newUser' : 'existingUser'}
            style={{ flexDirection: 'row' }}
          >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControlLabel
                  value='newUser'
                  labelPlacement='start'
                  control={
                    <Radio
                      style={{ color: 'var(--color-black)', marginLeft: 50 }}
                    />
                  }
                  label={global._('Keys.Loan.UserSelect.NewUser')}
                  className={classes.singleRadioButton}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value='existingUser'
                  labelPlacement='start'
                  control={
                    <Radio
                      style={{ color: 'var(--color-black)', marginLeft: 50 }}
                    />
                  }
                  label={global._('Keys.Loan.UserSelect.ExistingUser')}
                  className={classes.singleRadioButton}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
        {!isNewUser && (
          <>
            <div className={classes.divider} />
            <div style={{ padding: 20, width: '100%' }}>
              <Grid container spacing={3} alignItems='flex-start'>
                <Grid item xs={6}>
                  <AsyncSelect
                    isSearchable
                    value={null}
                    key='search-users'
                    dropdownIcon='search'
                    helpText={global._('Keys.New.Holder.PersonInputHelpText')}
                    label={obj =>
                      `${obj.name || global._('Common.NoName')} (${obj.email})`
                    }
                    placeholder={global._(
                      'Keys.New.Holder.PersonInputPlaceholder'
                    )}
                    onChange={e => updateUser(e.value)}
                    url={`/v1/organisations/${selectedOrg.id}/users?orderBy=createdAt&order=desc&search={{input}}&activeOnly=true`}
                  />
                </Grid>
                {user && user.id && (
                  <Grid item xs={6}>
                    <ItemBadge
                      onDelete={() => updateUser({})}
                      label={`${user.name || global._('Common.NoName')} (${
                        user.email
                      })`}
                      icon='face'
                    />
                    {isValidated && isKeyLimitReached && (
                      <Typography
                        variant='body2'
                        style={{
                          marginLeft: 20,
                          color: 'var(--color-error)'
                        }}
                      >
                        {global._('Keys.Settings.MaxNumberOfKeysReached.user')}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            </div>
          </>
        )}
        <div className={classes.divider} />
        <Grid container spacing={3} style={{ padding: '20px 20px' }}>
          {[
            {
              value: user.personalNumber,
              disabled: !isNewUser,
              set: e => updateUser({ ...user, personalNumber: e.target.value }),
              label: global._('User.PersonalNumber'),
              helperText: global._(
                'Keys.Loan.UserSelect.HelperText.PersonalNumber'
              ),
              blur: e => {
                if (ssn.valid(e.target.value)) {
                  updateUser({
                    ...user,
                    personalNumber: ssn.format(e.target.value, true)
                  })
                }
              },
              error:
                isNewUser &&
                user.personalNumber &&
                !ssn.valid(user.personalNumber) &&
                global._('Register.Errors.InvalidPersonalNumber')
            },
            {
              value: user.email,
              disabled: !isNewUser,
              set: e => updateUser({ ...user, email: e.target.value }),
              label: global._('User.Email'),
              helperText: global._('Keys.Loan.UserSelect.HelperText.Email'),
              error:
                isNewUser &&
                user.email &&
                !emailRegExp.test(user.email) &&
                global._('Import.Users.Preview.Errors.InvalidEmail')
            },
            {
              value:
                user.firstName ||
                (user && user.name && user.name.split(' ')[0]),
              set: e => updateUser({ ...user, firstName: e.target.value }),
              disabled: !isNewUser,
              label: global._('User.FirstName'),
              helperText: global._('Common.Required')
            },
            {
              value:
                user.lastName ||
                (user.name &&
                  user.name.substring(
                    user.name.indexOf(' '),
                    user.name.length
                  )),
              set: e => updateUser({ ...user, lastName: e.target.value }),
              disabled: !isNewUser,
              label: global._('User.LastName'),
              helperText: global._('Common.Required')
            },
            {
              value: user.phone,
              set: val => updateUser({ ...user, phone: val }),
              disabled: !isNewUser,
              label: global._('User.Phone'),
              helperText: global._('Common.Required'),
              fieldName: 'phone',
              error:
                user.phone &&
                !phoneNumberRegex.test(user.phone) &&
                global._('Common.PhoneNumberInvalid')
            },
            {
              label: global._('Common.Organisation'),
              value: selectedOrg.name,
              disabled: true
            }
          ].map(field => {
            const InputComponent =
              field.fieldName === 'phone' ? PhoneNumberField : TextField

            return (
              <Grid item xs={12} sm={6}>
                <InputComponent
                  key={field.label}
                  variant='filled'
                  label={field.label}
                  value={field.value || ''}
                  disabled={field.disabled}
                  onChange={field.set}
                  onBlur={field.blur}
                  error={!!field.error}
                  style={{ width: '100%' }}
                  helperText={field.error || field.helperText}
                />
              </Grid>
            )
          })}
        </Grid>
      </Paper>
    </div>
  )
}

UserSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onDataChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

UserSelect.defaultProps = {}

const UserSelectWithStylesAndUser = withUser(injectSheet(styles)(UserSelect))
export default UserSelectWithStylesAndUser
