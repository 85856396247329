export const getDistanceBetweenPoints = (a, b) =>
  Math.sqrt((a.y - b.y) * (a.y - b.y) + (a.x - b.x) * (a.x - b.x))
export const between = (min, max, value) => Math.min(max, Math.max(min, value))
export const getMidpoint = (a, b) => ({
  x: (a.x + b.x) / 2.0,
  y: (a.y + b.y) / 2.0
})
export const inverse = x => x * -1
export const getPointFromTouch = (touch, element) => {
  if (!touch) {
    return { x: 0, y: 0 }
  }
  if (!element) {
    return { x: touch.clientX, y: touch.clientY }
  }
  const rect = element.getBoundingClientRect()
  return {
    x: touch.clientX - rect.left,
    y: touch.clientY - rect.top
  }
}
