import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { FormControl, Grid, Icon, TextField } from '@material-ui/core'
import axios from 'axios'
import queryString from 'querystring'
import _ from 'lodash'
import moment from 'moment'
import {
  OrganisationServices,
  ProductKeys,
  UserRoles,
  OrganisationTypes,
  InstructionsVideoIds
} from 'src/utils/constants'
import {
  checkIfOrgHasAccessToScope,
  hasActivePaymentMethod
} from 'src/utils/helpers'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import AsyncSelect from '../../components/AsyncSelect'
import ObjectList from '../../components/ObjectList'
import Paper from '../../components/Paper'
import Modal from '../../components/Modal'
import SuccessMessage from '../../components/SuccessMessage'
import Notification from '../../common/Notification'
import PageContentHeader from '../../components/PageContentHeader'
import KeysNotice from '../../components/Keys/KeysNotice'
import CheckboxList from '../../components/CheckboxList'
import KeyReceiptTermsModal from '../../components/Keys/KeyReceiptTermsModal'
import useWindowDimensions from '../../utils/useWindowDimensions'
import MyKeysMobile from './MyKeysMobile'
import SearchField from '../../components/SearchField'
import Badge from '../../components/Badge'

const styles = {
  root: {},
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)',
    cursor: 'pointer'
  }
}

const MyKeys = ({
  classes,
  history,
  user,
  breadcrumbItems,
  scope,
  organisationServices,
  selectedOrganisation
}) => {
  const [error, setError] = useState(false)
  const [keys, setKeys] = useState([])
  const [myKeysCount, setMyKeysCount] = useState(null)
  const [expandedRows, setExpandedRows] = useState([])
  const [organisation, setOrganisation] = useState(user.organisation)
  const [isLoading, setIsLoading] = useState(null)
  const [showNameChangeModal, setShowNameChangeModal] = useState(null)
  const [showKeySavedSuccessMessage, setShowKeySavedSuccessMessage] =
    useState(null)
  const [nameFieldVal, setNameFieldVal] = useState('')
  const [editingKeyObj, setEditingKeyObj] = useState(null)
  const [unsignedReceipts, setUnsignedReceipts] = useState([])
  const [showReceiptTermsModal, setShowReceiptTermsModal] = useState(null)
  const [receiptTerms, setReceiptTerms] = useState(null)
  const [showRequestModal, setShowRequestModal] = useState(null)
  const [requestKeys, setRequestKeys] = useState(null)
  const [selectedRequestKeys, setSelectedRequestKeys] = useState([])
  const [unverifiedKeysPerOrganisation, setUnverifiedKeysPerOrganisation] =
    useState({})
  const [verifiedKeys, setVerifiedKeys] = useState([])
  const [isInventory, setIsInventory] = useState(false)
  const [showKeyCopyOnCallModal, setShowKeyCopyOnCallModal] = useState(false)

  const keyLimitPerUser = user.organisation?.attributes?.find(
    attr => attr.key === 'keys.settings.maxKeyReceiptsPerUser'
  )
  const keyLimitReached = keyLimitPerUser && keys.length > keyLimitPerUser.value
  const keyLimitPerApartment = user.organisation?.attributes?.find(
    attr => attr.key === 'keys.settings.maxKeyReceiptsPerApartment'
  )
  const defaultFilter = {
    self: true,
    order: 'asc',
    orderBy: 'name',
    archived: false
  }
  const [filter, setFilter] = useState(defaultFilter)

  const userHasAccessToKeyOrders = checkIfOrgHasAccessToScope(
    user,
    selectedOrganisation,
    OrganisationServices.KEY_ORDERS
  )

  const lang = global.getLanguage()

  const expectedReturnDateBadge = date => {
    const returnDate = moment(date).format('YYYY-MM-DD')

    if (date) {
      return (
        <Badge
          label={returnDate}
          variant={moment().isAfter(date, 'day') ? 'error' : 'grey'}
        />
      )
    }

    return '-'
  }

  const columns = [
    {
      key: 'icon',
      localizationKey: 'Cases.Blank',
      sortingDisabled: true,
      style: { width: 70 },
      format: () => <Icon className={classes.icon}>vpn_key</Icon>
    },
    {
      key: 'name',
      localizationKey: 'MyKeys.Name',
      clickable: true,
      style: { width: '20%' },
      sortingDisabled: true,
      format: (objVal, obj) =>
        obj.personalName || objVal || global._('Common.NoName')
    },
    {
      key: 'keyRing',
      localizationKey: 'MyKeys.KeyRing',
      clickable: true,
      style: { width: '20%' },
      sortingDisabled: true,
      format: objVal => (objVal ? objVal.name : '-')
    },
    {
      key: 'marking',
      localizationKey: 'MyKeys.Marking',
      clickable: true,
      style: { width: '15%' },
      sortingDisabled: true,
      format: objVal => objVal || '-'
    },
    {
      key: 'expectedReturnDate',
      localizationKey: 'MyKeys.ExpectedReturnDate',
      clickable: true,
      style: { width: '28%' },
      sortingDisabled: true,
      format: objVal => expectedReturnDateBadge(objVal)
    },
    {
      key: 'organisationName',
      localizationKey: 'MyKeys.Owner',
      clickable: true,
      style: { width: '20%' },
      sortingDisabled: true,
      format: (objVal, obj) =>
        objVal || (obj && obj.organisation && obj.organisation.name)
    }
  ]

  const fetchKeys = async newFilter => {
    if (organisation) {
      const selectedFilter = newFilter || defaultFilter

      setIsLoading(true)

      try {
        const query = queryString.stringify(selectedFilter)

        const { data } = await axios.get(
          `v1/organisations/${organisation.id}/keys?${query}`
        )

        const keysArr = data.filter(key => key.signed === 1 || key.apartmentId)

        setKeys(keysArr)

        if (!newFilter) {
          setFilter(defaultFilter)
          setMyKeysCount(keysArr.length)
        }

        setIsLoading(false)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
        setIsLoading(false)
      }
    }
  }

  const updateKey = async (keyId, values, showSuccessMessage) => {
    setError(null)
    try {
      if (showSuccessMessage) {
        showSuccessMessage(false)
      }

      if (
        Object.keys(values).length === 1 &&
        values.personalName !== undefined
      ) {
        await axios.patch(
          `v1/organisations/${
            organisation && organisation.id
          }/keys/${keyId}/personalname`,
          values
        )
      } else {
        await axios.patch(
          `v1/organisations/${organisation && organisation.id}/keys/${keyId}`,
          values
        )
      }

      fetchKeys()

      if (showSuccessMessage) {
        showSuccessMessage(true)
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  const fetchUnsignedReceipts = async () => {
    try {
      const { data: receipts } = await axios.get(
        'v1/keys/receipts?signed=false'
      )

      const allUnsignedReceipts = []

      receipts.forEach(receipt => {
        allUnsignedReceipts.push(
          receipts.filter(r => r.content === receipt.content)
        )
      })

      setUnsignedReceipts(_.uniqWith(allUnsignedReceipts, _.isEqual))
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  const fetchVerifiedKeyInventoryApprovals = async () => {
    const { data: keysWithApprovals } = await axios.get(
      'v1/keys/inventoryapprovals?status=VERIFIED'
    )
    setVerifiedKeys(keysWithApprovals)
  }
  const fetchPendingKeyInventoryApprovals = async () => {
    try {
      /* Gets a list of keys with PENDING KeyInventoryApprovals for ACTIVE KeyInventories */
      const { data: keysWithApprovals } = await axios.get(
        'v1/keys/inventoryapprovals'
      )
      const pendingInventoryKeysPerOrganisation = {}
      keysWithApprovals.forEach(key => {
        if (!pendingInventoryKeysPerOrganisation[key.organisationId]) {
          pendingInventoryKeysPerOrganisation[key.organisationId] = []
        }
        pendingInventoryKeysPerOrganisation[key.organisationId].push(key)
      })
      setUnverifiedKeysPerOrganisation(pendingInventoryKeysPerOrganisation)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  const resolveSelectedRequestKeys = () => {
    if (requestKeys && requestKeys.length > 0) {
      const inventoryApprovalIds = []

      selectedRequestKeys.forEach(keyId => {
        const tempKey = requestKeys.find(key => key.id === keyId)

        tempKey.keyInventoryApprovals.forEach(inventoryApproval => {
          inventoryApprovalIds.push(inventoryApproval.id)
        })
      })

      return inventoryApprovalIds.join(',')
    }
  }

  useEffect(() => {
    fetchUnsignedReceipts()
    fetchPendingKeyInventoryApprovals()
    fetchVerifiedKeyInventoryApprovals()
  }, [])

  useEffect(() => {
    fetchKeys()
  }, [organisation])

  useEffect(() => {
    fetchKeys(filter)
  }, [filter])

  useEffect(() => {
    if (requestKeys && requestKeys.length > 0) {
      setSelectedRequestKeys(requestKeys.map(r => r.id))
    }
  }, [requestKeys])

  const keyCopyOnCallModalContent = obj => {
    if (!obj) {
      return null
    }

    let phone
    if (obj.organisation.partner && obj.organisation.partner.dutyPhone) {
      phone = obj.organisation.partner.dutyPhone
    } else {
      phone = obj.organisation.dutyPhone
    }

    const product =
      obj.organisation.products &&
      obj.organisation.products.length > 0 &&
      obj.organisation.products.find(
        p => p.key === ProductKeys.KEY_COPY_ON_CALL && p.variant === obj.type
      )

    if (product && product.active) {
      const { price } = product

      return (
        <div>
          <Typography variant='body'>
            {global._(
              `Products.Descriptions.${product.variant}.${ProductKeys.KEY_COPY_ON_CALL}`
            )}
          </Typography>
          <div style={{ marginTop: 20 }}>
            <Typography
              variant='subtitleBig'
              style={{ display: 'inline-block', marginRight: 6 }}
              bold
            >{`${price || '-'} ${global._('Common.Currency')}`}</Typography>
            <Typography faded>{global._('Products.IncludingTaxes')}</Typography>
          </div>
          {isMobile && (
            <Button
              variant='primary'
              style={{ margin: '20px 0 10px' }}
              disabled={!phone}
              onClick={() => {
                window.open(`tel:${phone.replace(/\s|-/g, '')}`, '_self')
              }}
            >
              {global._('Common.CallNumber', [phone])}
            </Button>
          )}
          {!isMobile && (
            <Typography
              variant='subtitle'
              style={{ marginTop: 20, lineHeight: 1 }}
            >
              <span style={{ color: 'var(--color-error-red)' }}>{`${global._(
                'Products.OnCallPhoneNumber'
              )}:`}</span>{' '}
              {phone || '-'}
            </Typography>
          )}
        </div>
      )
    }
  }

  const activeProduct = (obj, productKey) => {
    const product =
      obj.organisation &&
      obj.organisation.products &&
      obj.organisation.products.length > 0 &&
      obj.organisation.products.find(
        p => p.key === productKey && p.variant === obj.type
      )

    if (!product || !product.active) {
      return null
    }

    return product
  }

  const _hasActivePaymentMethod = hasActivePaymentMethod(selectedOrganisation)

  const actionButtons = (objectID, obj) => (
    <>
      {user.roles.includes(UserRoles.PartnerAdmin) &&
      user.organisation.type === OrganisationTypes.Partner ? undefined : (
        <>
          <Button
            variant='outlined'
            onClick={() => {
              history.push(
                `/orders/new/details?keyId=${obj.id}&product=${ProductKeys.KEY_COPY}&variant=${obj.type}`
              )
            }}
            disabled={
              !userHasAccessToKeyOrders ||
              (obj.organisation && !obj.organisation.products) ||
              !activeProduct(obj, ProductKeys.KEY_COPY) ||
              !_hasActivePaymentMethod ||
              keyLimitReached
            }
            style={{
              marginRight: 20
            }}
          >
            {global._(
              `Keys.Order.Details.NavBarTitles.${ProductKeys.KEY_COPY}.${obj.type}`
            )}
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              history.push(
                `/orders/new/details?keyId=${obj.id}&product=${ProductKeys.KEY_REPLACEMENT}&variant=${obj.type}`
              )
            }}
            disabled={
              !userHasAccessToKeyOrders ||
              (obj.organisation && !obj.organisation.products) ||
              !activeProduct(obj, ProductKeys.KEY_REPLACEMENT) ||
              !_hasActivePaymentMethod ||
              keyLimitReached
            }
            style={{
              marginRight: 20
            }}
          >
            {global._(
              `Keys.Order.Details.NavBarTitles.${ProductKeys.KEY_REPLACEMENT}.${obj.type}`
            )}
          </Button>
          <Button
            variant='outlined'
            onClick={() => setShowKeyCopyOnCallModal(obj)}
            disabled={
              !userHasAccessToKeyOrders ||
              (obj.organisation && !obj.organisation.products) ||
              !activeProduct(obj, ProductKeys.KEY_COPY_ON_CALL)
            }
            style={{
              marginRight: 20
            }}
          >
            {global._(
              `Keys.Order.Details.NavBarTitles.${ProductKeys.KEY_COPY_ON_CALL}.${obj.type}`
            )}
          </Button>
        </>
      )}
      {!obj.apartmentId && (
        <Button
          variant='outlined'
          onClick={() => {
            setReceiptTerms(
              obj.keyReceipts.length > 0 &&
                (obj.keyReceipts[obj.keyReceipts.length - 1].content || null)
            )
            setShowReceiptTermsModal(true)
          }}
          style={{
            marginRight: 20
          }}
        >
          {global._('MyKeys.ShowReceipt')}
        </Button>
      )}
      <Button
        variant='secondary'
        onClick={() => {
          setShowNameChangeModal(true)
          setEditingKeyObj(obj)
        }}
      >
        {global._('Common.ChangeName')}
      </Button>
    </>
  )

  const handleFilter = name => e => {
    const aFilter = { ...filter }
    const { value } = e.target
    if (!value || value === 'all') delete aFilter[name]
    else aFilter[name] = value

    setExpandedRows([])
    setFilter(aFilter)
  }

  const filterFields = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <SearchField
            label={global._('MyKeys.SearchPlaceholder')}
            variant='filled'
            name='search'
            onChange={handleFilter('search')}
            value={filter && filter.search}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <AsyncSelect
            isSearchable
            isClearable={false}
            labelKey='name'
            placeholder={global._('Organisations.Name')}
            value={{ value: organisation, label: organisation.name }}
            helpText={global._('Filter.ByOrganisation')}
            url='/v1/organisations?search={{input}}&orderBy=name&order=asc&ifHas=my-keys'
            onChange={opt => opt && setOrganisation(opt.value)}
          />
        </FormControl>
      </Grid>
    </Grid>
  )

  useEffect(() => {
    if (editingKeyObj) {
      setNameFieldVal(editingKeyObj.personalName || editingKeyObj.name)
    }
  }, [editingKeyObj])

  const { isMobile } = useWindowDimensions()

  const keyIds =
    !isInventory && selectedRequestKeys && selectedRequestKeys.length > 0
      ? selectedRequestKeys.join(',')
      : ''

  const myKeysTitle = `${global._('MyKeys.MyKeys')} ${
    myKeysCount > 0
      ? ` - ${myKeysCount} ${global._('MyKeys.QuantityPieces')}`
      : ''
  }`

  if (isMobile) {
    return (
      <MyKeysMobile
        user={user}
        keys={keys}
        history={history}
        filterFields={filterFields}
        updateKey={updateKey}
        unsignedReceipts={unsignedReceipts}
        unverifiedKeysPerOrganisation={unverifiedKeysPerOrganisation}
        verifiedKeys={verifiedKeys}
        setRequestKeys={setRequestKeys}
        requestKeys={requestKeys}
        selectedRequestKeys={selectedRequestKeys}
        setSelectedRequestKeys={setSelectedRequestKeys}
        showRequestModal={showRequestModal}
        setShowRequestModal={setShowRequestModal}
        showReceiptTermsModal={showReceiptTermsModal}
        setShowReceiptTermsModal={setShowReceiptTermsModal}
        receiptTerms={receiptTerms}
        setReceiptTerms={setReceiptTerms}
        keyIds={keyIds}
        resolveSelectedRequestKeys={resolveSelectedRequestKeys}
        myKeysTitle={myKeysTitle}
        keyLimitReached={keyLimitReached}
        expectedReturnDateBadge={expectedReturnDateBadge}
        setError={setError}
        setIsLoading={setIsLoading}
        userHasAccessToKeyOrders={userHasAccessToKeyOrders}
        setShowKeyCopyOnCallModal={setShowKeyCopyOnCallModal}
        showKeyCopyOnCallModal={showKeyCopyOnCallModal}
        keyCopyOnCallModalContent={keyCopyOnCallModalContent}
        activeProduct={activeProduct}
        hasActivePaymentMethod={_hasActivePaymentMethod}
      />
    )
  }

  return (
    <>
      {showNameChangeModal && (
        <Modal
          onClose={() => setShowNameChangeModal(false)}
          title={global._('Common.ChangeName')}
          content={
            <div>
              <TextField
                label={global._('MyKeys.PersonalName')}
                fullWidth
                onChange={e => setNameFieldVal(e.target.value)}
                helperText={global._('MyKeys.OnlyShownForYou')}
                value={nameFieldVal}
                name='name'
                variant='filled'
              />
            </div>
          }
          buttons={[
            <Button
              variant='none'
              onClick={() => {
                setShowNameChangeModal(false)
                setEditingKeyObj(null)
              }}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              variant='primary'
              onClick={() => {
                setShowNameChangeModal(false)
                updateKey(
                  editingKeyObj.id,
                  { personalName: nameFieldVal },
                  setShowKeySavedSuccessMessage
                )
                setEditingKeyObj(null)
              }}
            >
              {global._('Common.Save')}
            </Button>
          ]}
        />
      )}
      {showKeyCopyOnCallModal && (
        <Modal
          onClose={() => setShowKeyCopyOnCallModal(false)}
          title={global._(
            `Keys.Order.Details.NavBarTitles.${ProductKeys.KEY_COPY_ON_CALL}.${showKeyCopyOnCallModal.type}`
          )}
          content={keyCopyOnCallModalContent(showKeyCopyOnCallModal)}
          buttons={[
            <Button
              variant='none'
              onClick={() => {
                setShowKeyCopyOnCallModal(false)
              }}
            >
              {global._('Common.Close')}
            </Button>
          ]}
        />
      )}
      {showRequestModal && (
        <Modal
          onClose={() => setShowRequestModal(false)}
          title={global._(
            isInventory
              ? 'MyKeys.KeyInventory.VerifyKeys'
              : 'MyKeys.Request.TitlePl'
          )}
          noContentPadding
          content={
            <div>
              {requestKeys && requestKeys.length > 0 && (
                <CheckboxList
                  checkboxNameKey='id'
                  objects={requestKeys}
                  columns={[
                    {
                      key: 'title',
                      format: obj => (
                        <>
                          <Typography variant='body' bold>
                            {isInventory &&
                              `${
                                obj.personalName ||
                                obj.name ||
                                global._('Common.NoName')
                              }`}
                            {!isInventory && obj.marking}
                          </Typography>
                          <Typography variant='body' bold faded>
                            {' '}
                            {global._(`Keys.Types.${obj.type}`)}{' '}
                            {global._('MyKeys.Request.key')}
                          </Typography>
                        </>
                      )
                    },
                    {
                      key: 'orgId',
                      format: obj => {
                        // If the key is not owned by anyone
                        // and only related by apartment id
                        // and the inventory request has a receipt template set
                        // the signer should become the owner
                        let requestOwnership = false
                        let receiptTemplate
                        if (!obj.userId && obj.keyInventoryApprovals) {
                          for (const approval of obj.keyInventoryApprovals) {
                            if (
                              approval.keyInventory.receiptTemplateId &&
                              !obj.userId
                            ) {
                              requestOwnership = true
                              receiptTemplate =
                                approval.keyInventory.keyReceiptTemplate
                            }
                          }
                        }
                        return (
                          <>
                            <Typography variant='body2' faded>
                              {obj.organisationName || obj.marking || ''}
                              {requestOwnership &&
                                ` - ${global._('MyKeys.Request.KeySign')} - `}
                              {requestOwnership && (
                                // eslint-disable-next-line
                                <a
                                  style={{
                                    textDecoration: 'underline',
                                    color: '#000'
                                  }}
                                  href='#'
                                  onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setReceiptTerms(receiptTemplate.content)
                                    setShowReceiptTermsModal(true)
                                  }}
                                >
                                  {global._('Common.ShowTerms')}
                                </a>
                              )}
                            </Typography>
                          </>
                        )
                      }
                    }
                  ]}
                  selectedOptions={selectedRequestKeys}
                  setSelectedOptions={setSelectedRequestKeys}
                />
              )}
            </div>
          }
          buttons={[
            <Button
              variant='none'
              onClick={() => {
                setShowRequestModal(false)
              }}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              variant='primary'
              disabled={
                (!isInventory && !keyIds) ||
                (isInventory && selectedRequestKeys.length === 0)
              }
              onClick={() => {
                let url

                if (isInventory) {
                  let keyInventoryApprovalIds = ''
                  keyInventoryApprovalIds = resolveSelectedRequestKeys()
                  url = `/my-keys/inventoryapproval-request?ids=${keyInventoryApprovalIds}`
                } else {
                  url = `/my-keys/receipt-request?ids=${keyIds}`
                }

                history.push(url)
              }}
            >
              {global._('Common.Sign')}
            </Button>
          ]}
        />
      )}
      {showReceiptTermsModal && (
        <KeyReceiptTermsModal
          title={global._('Common.Terms')}
          setShowModal={setShowReceiptTermsModal}
          cancelBtnLabel={global._('Common.Close')}
          content={receiptTerms}
          hidePrintBtn
        />
      )}
      {showKeySavedSuccessMessage && (
        <SuccessMessage subtitle={global._('MyKeys.NameUpdated')} />
      )}
      <SuccessMessage
        subtitle={global._('MyKeys.Request.SuccessfullRequest')}
        localStorageProp='keyReceiptRequestedSuccessfully'
      />
      <SuccessMessage
        subtitle={global._('MyKeys.KeyInventory.SuccessfulRequest')}
        localStorageProp='keyInventoryApprovalRequestedSuccessfully'
      />
      <SuccessMessage
        subtitle={global._('MyKeys.KeyInventory.SuccessfulRequests')}
        localStorageProp='keyInventoryApprovalsRequestedSuccessfully'
      />
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      <PageContentHeader breadcrumbItems={breadcrumbItems} />
      {unsignedReceipts &&
        unsignedReceipts.length > 0 &&
        unsignedReceipts.map(receipts => (
          <KeysNotice
            icon='vpn_key'
            title={global._('MyKeys.HasReceiptRequest')}
            subtitle={global._('MyKeys.ReceiptRequest')}
            key={`receipts-${receipts[0].id}`}
            user={user}
            history={history}
            actionButtons={
              <div style={{ display: 'flex' }}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setReceiptTerms(receipts[0].content)
                    setShowReceiptTermsModal(true)
                  }}
                >
                  {global._('Common.ShowTerms')}
                </Button>
                {user && user.personalNumber && (
                  <div style={{ marginLeft: 20 }}>
                    <Button
                      variant='bankid'
                      onClick={() => {
                        setIsInventory(false)
                        setRequestKeys(receipts.map(r => r.key))
                        setShowRequestModal(true)
                      }}
                    >
                      {global._('MyKeys.HasReceiptRequestButton')}
                    </Button>
                  </div>
                )}
              </div>
            }
          />
        ))}
      {Object.keys(unverifiedKeysPerOrganisation).length === 0 &&
        verifiedKeys.length > 0 && (
          <KeysNotice
            icon='done'
            subtitle={global._('MyKeys.KeyInventoryCompleteTitle')}
            title={global._('MyKeys.KeyInventoryCompleteSubtitle')}
            user={user}
            history={history}
          />
        )}
      {Object.values(unverifiedKeysPerOrganisation).map(unverifiedKeys => (
        <KeysNotice
          icon='beenhere'
          title={global._('MyKeys.KeyInventoryTime')}
          /* there's always at least one key in the array */
          subtitle={unverifiedKeys[0].organisation.name}
          key={`unverified-keys-${unverifiedKeys[0].organisation.id}`}
          user={user}
          history={history}
          actionButtons={
            <div style={{ display: 'flex' }}>
              {user && user.personalNumber && (
                <div style={{ marginLeft: 20 }}>
                  <Button
                    variant='bankid'
                    onClick={() => {
                      setIsInventory(true)
                      setRequestKeys(unverifiedKeys)
                      setShowRequestModal(true)
                    }}
                  >
                    {global._('MyKeys.KeyInventoryButton')}
                  </Button>
                </div>
              )}
            </div>
          }
        />
      ))}
      <Paper smallerPadding style={{ marginBottom: 10 }}>
        {filterFields()}
      </Paper>
      {keyLimitReached && (
        <Notification
          type='information'
          message={global._('Keys.KeyLimitReached')}
        />
      )}
      <ObjectList
        sectionTitle={myKeysTitle}
        infoDialogTitle={global._('MyKeys.MyKeys')}
        infoDialogContent={
          <div style={{ textAlign: 'left' }}>
            <Typography
              style={{ display: 'block', paddingBottom: 12 }}
              variant='body'
            >
              {global._('MyKeys.InfoDialogTitle')}
            </Typography>
          </div>
        }
        user={user}
        history={history}
        columns={columns}
        objects={keys}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        isLoadingObjects={isLoading}
        actionButtons={actionButtons}
        noResultsMessage={`${global._('MyKeys.NoKeysFound')}`}
        instructionsVideoId={InstructionsVideoIds[lang].MY_KEYS}
      />
    </>
  )
}

MyKeys.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  breadcrumbItems: PropTypes.array
}

MyKeys.defaultProps = {
  user: null,
  breadcrumbItems: []
}

const MyKeysWithStyles = injectSheet(styles)(MyKeys)
export default MyKeysWithStyles
