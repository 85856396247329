import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import {
  Grid,
  Icon,
  Tooltip,
  FormControl,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import IconColumn from 'src/components/IconColumn'
import KeysNavigation from '../../../components/Keys/KeysNavigation'
import Notification from '../../../common/Notification'
import Button from '../../../components/Button'
import ObjectList from '../../../components/ObjectList'
import Typography from '../../../components/Typography'
import SuccessMessage from '../../../components/SuccessMessage'
import SearchField from '../../../components/SearchField'
import {
  getSelectedOrg,
  mapKeyInventoryStatus,
  formatShallowDate
} from '../../../utils/helpers'
import { STORAGE_KEY_INVENTORY_CREATED } from './NewKeyInventoryView'
import { InstructionsVideoIds } from '../../../utils/constants'

const styles = {
  root: {},
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  }
}

const Inventory = props => {
  const { classes, breadcrumbItems, history, user } = props

  const [error, setError] = useState(null)
  const [items, setItems] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({
    orderBy: 'id',
    order: 'desc',
    showEnded: false
  })

  const selectedOrg = getSelectedOrg(user)

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const fetchInventories = async () => {
    if (selectedOrg) {
      ;(async () => {
        try {
          setIsLoading(true)
          const { data } = await axios.get(
            `/v1/organisations/${selectedOrg.id}/keyInventories?${qs.stringify(
              filter
            )}`
          )
          setItems(data)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(msg)
        }
      })()
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchInventories()
  }, [filter])

  const handleFilter = name => e => {
    const aFilter = { ...filter }
    const { value } = e.target
    aFilter[name] = name === 'showEnded' ? e.target.checked : value
    setFilter(aFilter)
  }

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      clickable: false,
      style: { width: 70 },
      format: (objVal, obj) => obj && <IconColumn icon='beenhere' />
    },
    {
      key: 'id',
      localizationKey: 'Keys.Inventory.List.Id',
      sortingKey: 'id',
      clickable: true,
      style: { width: 120 }
    },
    {
      key: 'startDate',
      localizationKey: 'Keys.Inventory.List.StartDate',
      sortingKey: 'startDate',
      format: objDate => (
        <Tooltip
          title={moment(objDate).calendar(null, {
            sameElse: 'YYYY-MM-DD HH:mm'
          })}
          placement='right'
        >
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatShallowDate(objDate, { withoutTime: true })}
          </span>
        </Tooltip>
      ),
      clickable: true
    },
    {
      key: 'status',
      localizationKey: 'Keys.Inventory.List.Status',
      format: status =>
        status && (
          <>
            <span className={`status ${mapKeyInventoryStatus(status)}`}>
              {global._(`Keys.Inventory.List.Statuses.${status}`)}
            </span>
          </>
        ),
      sortingKey: 'status',
      clickable: true
    },
    {
      key: 'keyCount',
      localizationKey: 'Keys.Inventory.List.KeyCount',
      sortingKey: 'keyCount',
      clickable: true
    },
    {
      key: 'pendingKeyCount',
      localizationKey: 'Keys.Inventory.List.PendingKeyCount',
      sortingKey: 'pendingKeyCount',
      clickable: true
    },
    {
      key: 'holderCount',
      localizationKey: 'Keys.Inventory.List.HolderCount',
      sortingKey: 'holderCount',
      clickable: true
    },
    {
      key: 'organisationName',
      localizationKey: 'Keys.Inventory.List.OrganisationName',
      sortingKey: 'organisationName',
      clickable: true,
      longText: true
    },
    {
      key: '',
      clickable: true,
      sortingDisabled: true,
      format: () => <Icon>chevron_right</Icon>,
      style: { width: 70 }
    }
  ]

  const Filter = (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SearchField
          style={{ width: '100%' }}
          label={global._('Keys.Inventory.List.FilterSearch')}
          variant='filled'
          value={filter.search || ''}
          onChange={handleFilter('search')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                value={filter.showEnded}
                name={filter.showEnded}
                checked={filter.showEnded}
                color='primary'
                onChange={handleFilter('showEnded')}
              />
            }
            label={global._('Keys.Inventory.List.ShowEnded')}
            className={classes.checkboxWrapper}
          />
        </FormControl>
      </Grid>
    </Grid>
  )

  const TitleContent = (
    <div style={{ marginLeft: 'auto' }}>
      <Button
        variant='primary'
        style={{ width: 130 }}
        onClick={() => history.push('/keys/inventory/new')}
      >
        {global._('Keys.Common.Add')}
      </Button>
    </div>
  )

  const lang = global.getLanguage()

  return (
    <div className={classes.root}>
      <SuccessMessage
        subtitle={global._('Keys.Inventory.List.InventoryCreatedSuccessfully')}
        localStorageProp={STORAGE_KEY_INVENTORY_CREATED}
      />
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      <KeysNavigation
        breadcrumbItems={breadcrumbItems}
        user={user}
        error={error}
      />
      <ObjectList
        sectionTitle={global._('Keys.Inventory.List.Title')}
        infoDialogTitle={global._('Keys.Common.Information')}
        infoDialogContent={
          <Typography variant='body'>
            {global._('Keys.Inventory.List.InfoText')}
          </Typography>
        }
        titleContent={TitleContent}
        expandable={false}
        filterFields={Filter}
        history={history}
        columns={columns}
        objects={items}
        instructionsVideoId={
          InstructionsVideoIds[lang].KEY_MANAGEMENT.INVENTORY
        }
        isLoadingObjects={isLoading}
        objectPermalink='keys/inventory'
        noResultsMessage={global._(
          filter.search
            ? 'Keys.Inventory.List.SearchNoResultsMessage'
            : 'Keys.Inventory.List.NoResultsMessage'
        )}
        handleFilter={handleFilter}
        setFilter={setFilter}
        filter={filter}
      />
    </div>
  )
}

Inventory.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const InventoryWithStyles = injectSheet(styles)(Inventory)
export default InventoryWithStyles
