import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { styles } from '../../layouts/Styles'
import { UserRoles, UserAssignableRoles } from '../../utils/constants'

function NoOptionsMessage(props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.className.noOptionsMessage}>
      Inga organisationer hittades
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div style={{ height: 40 }} ref={inputRef} {...props} />
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.className.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}>
      {props.children}
    </MenuItem>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.className.singleValue}
      {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.className.valueContainer}>
      {props.children}
    </div>
  )
}

const CreateUserModal = ({
  handleChange,
  modal,
  onHandleUser,
  toggle,
  user,
  isLoadingCreateOrUpdate
}) => {
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const isUserInternalAdmin = currentUser.roles.includes(
    UserRoles.InternalAdmin
  )
  const canUserBeInternalAdmin =
    isUserInternalAdmin &&
    currentUser.organisation.id === (user && user.organisationId)
  // const options = organisations.map(organisation => ({ value: organisation.id, label: organisation.name }))
  // console.log('options', options)
  const availableRoles = []
  currentUser.roles.forEach(role => {
    UserAssignableRoles[role].forEach(r => {
      if (!availableRoles.includes(r)) {
        availableRoles.push(r)
      }
    })
  })

  const isEditing = user && user.id ? 'Uppdatera användare' : 'Skapa användare'
  return (
    <Dialog
      open={modal}
      onClose={toggle}
      aria-labelledby='form-dialog-title'
      fullWidth>
      <DialogTitle id='form-dialog-title'>{isEditing}</DialogTitle>
      <FormControl>
        <DialogContent>
          {user.id && (
            <FormControl fullWidth>
              <TextField
                label='Namn'
                onChange={handleChange('name')}
                value={(user && user.name) || ''}
                fullWidth
                variant='filled'
                margin='normal'
                required={!user.id}
              />
            </FormControl>
          )}
          <FormControl fullWidth>
            <TextField
              label='Email'
              onChange={handleChange('email')}
              value={(user && user.email) || ''}
              fullWidth
              variant='filled'
              margin='normal'
              required={!user.id}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <InputLabel>Roll</InputLabel>
            <TextField
              select
              label='Roll'
              value={(user && user.role) || ''}
              onChange={handleChange('role')}
              variant='filled'>
              {availableRoles.map(role => {
                if (!canUserBeInternalAdmin && role === UserRoles.InternalAdmin)
                  return null
                return (
                  <MenuItem key={role} value={role}>
                    {global._(`User.Roles.${role}`)}
                  </MenuItem>
                )
              })}
            </TextField>
          </FormControl>
          {!user.id ? (
            <FormControl fullWidth margin='normal'>
              <TextField
                label='Meddelande'
                onChange={handleChange('message')}
                value={(user && user.message) || ''}
                fullWidth
                variant='filled'
                margin='normal'
                multiline
              />
            </FormControl>
          ) : (
            <FormControl fullWidth margin='normal'>
              <TextField
                label='Telefon'
                onChange={handleChange('phone')}
                value={(user && user.phone) || ''}
                fullWidth
                variant='filled'
                margin='normal'
                multiline
              />
            </FormControl>
          )}
          {/* {isUserInternalAdmin
            && (
              <FormControl fullWidth margin='normal'>
                <ReactSelect
                  className={classes}
                  isClearable
                  isSearchable
                  menuPlacement='top'
                  maxMenuHeight={220}
                  onChange={handleChange2('organisationId')(handleChange)}
                  components={components}
                  defaultValue={
                    user.organisationId
                    && {
                      value: user.organisationId,
                      label: user.organisation && user.organisation.name
                    }
                  }
                  placeholder='Företag'
                  options={options}
                />
              </FormControl>
            )} */}
        </DialogContent>
      </FormControl>
      <DialogActions>
        <Button color='primary' onClick={toggle}>
          Avbryt
        </Button>
        <Button
          color='primary'
          disabled={isLoadingCreateOrUpdate}
          onClick={onHandleUser}>
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateUserModal.defaultProps = {
  user: {}
}

CreateUserModal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  isLoadingCreateOrUpdate: PropTypes.bool.isRequired,
  onHandleUser: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  user: PropTypes.object,
  organisations: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
}

ValueContainer.propTypes = {
  selectProps: PropTypes.object.isRequired
}
NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object.isRequired
}

export default withStyles(styles)(CreateUserModal)
