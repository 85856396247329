import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import {
  Grid,
  Icon,
  MenuItem,
  Switch,
  TextField,
  IconButton,
  Tooltip
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import { Sync } from '@material-ui/icons'
import Button from '../../../components/Button'
import PageContentHeader from '../../../components/PageContentHeader'
import Paper from '../../../components/Paper'
import Typography from '../../../components/Typography'
import ObjectList from '../../../components/ObjectList'
import Modal from '../../../components/Modal'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import DeviceDetailMobile from './DeviceDetailMobile'
import { nameInputAdornment } from '../../../utils/helpers'
import Notification from '../../../common/Notification'
import AddLockModal from './AddLockModal'
import Input from '../../../components/Input'
import { UserRoles, DeviceConfig } from '../../../utils/constants'
import SuccessMessage from '../../../components/SuccessMessage'
import AsyncSelect from '../../../components/AsyncSelect'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Scheduler from '../../../components/Scheduler'

const styles = {
  root: {},
  input: {
    width: '100%'
  },
  submitBtnContainer: {
    marginTop: 20
  },
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)',
    cursor: 'pointer'
  },
  iconEmpty: {
    backgroundColor: 'var(--color-white)'
  }
}

const DeviceDetail = props => {
  const { classes, history, match, breadcrumbItems, signout, user } = props

  const { id } = match.params

  const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState(false)
  const [showReplaceDeviceModal, setShowReplaceDeviceModal] = useState(false)
  const [showUpdateClientModal, setShowUpdateClientModal] = useState(false)
  const [showCreateLockModal, setShowCreateLockModal] = useState(false)
  const [currentRelay, setCurrentRelay] = useState(1)
  const [device, setDevice] = useState(null)
  const [isLoadingDevice, setIsLoadingDevice] = useState(null)
  const [deviceFetched, setDeviceFetched] = useState(null)
  const [error, setError] = useState(null)
  const [selectedOrganisation, setSelectedOrganisation] = useState('')
  const [replaceDeviceId, setReplaceDeviceId] = useState('')
  const [tempOrganisation, setTempOrganisation] = useState(null)
  const [showOrganisationUpdateModal, setShowOrganisationUpdateModal] =
    useState('')
  const [selectedProperty, setSelectedProperty] = useState('')
  const [tempProperty, setTempProperty] = useState(null)
  const [showPropertyUpdateModal, setShowPropertyUpdateModal] = useState('')
  const [isEditingName, setIsEditingName] = useState(false)
  const [isNameUpdated, setIsNameUpdated] = useState(false)
  const [deviceName, setDeviceName] = useState('')
  const [isEditingDescription, setIsEditingDescription] = useState(false)
  const [isDescriptionUpdated, setIsDescriptionUpdated] = useState(false)
  const [description, setDescription] = useState('')
  const [newDeviceClient, setNewDeviceClient] = useState(false)
  const [showLockSuccessMessage, setShowLockSuccessMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [showReplaceDeviceSuccessMessage, setShowReplaceDeviceSuccessMessage] =
    useState(false)
  const [showMobileReplaceDeviceModal, setShowMobileReplaceDeviceModal] =
    useState(false)
  const [isUpdatingDevice, setIsUpdatingDevice] = useState(false)
  const [isSendingTestCommand, setIsSendingTestCommand] = useState(null)
  const [deviceVersions, setDeviceVersions] = useState([])
  const [isLoadingDeviceUpdate, setIsLoadingDeviceUpdate] = useState(false)
  const [deviceUpdateError, setDeviceUpdateError] = useState(null)

  const deviceExtraInfo = device && JSON.parse(device.extra)
  if (deviceExtraInfo) {
    deviceExtraInfo.signal = Number.parseInt(deviceExtraInfo.signal, 10)
  }
  const nameInputRef = useRef()
  const descriptionInputRef = useRef()

  let isPartnerAdmin = false
  let isSystemAdmin = false
  if (user && Array.isArray(user.roles)) {
    if (user.roles.includes(UserRoles.PartnerAdmin)) {
      isPartnerAdmin = true
    }
    if (user.roles.includes(UserRoles.SystemAdmin)) {
      isSystemAdmin = true
    }
  }

  if (!isPartnerAdmin && !isSystemAdmin) {
    history.push('/')
  }

  const fetchDevice = async () => {
    setIsLoadingDevice(true)
    setDeviceFetched(true)
    try {
      const { data } = await axios.get(`/v1/devices/${id}`)
      setDevice(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoadingDevice(false)
  }

  const fetchVersions = async () => {
    try {
      const { data } = await axios.get(`/v1/devices/versions`)
      setDeviceVersions(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    fetchDevice()
    fetchVersions()
  }, [])

  useEffect(() => {
    if (device && device.id) {
      setSelectedOrganisation(
        device && {
          label: device.organisationName,
          value: { id: device.organisationId }
        }
      )
      setSelectedProperty(
        device && {
          label: device.propertyName,
          value: { id: device.propertyId }
        }
      )
      setDeviceName(device.name)
      setDescription(device.description)
    }
  }, [device])

  const handleCreateLock = async values => {
    try {
      setShowLockSuccessMessage(false)
      await axios.post(
        `/v1/organisations/${device.organisationId}/devices/${device.id}/locks`,
        values
      )

      setShowCreateLockModal(false)
      setShowLockSuccessMessage(true)
      await fetchDevice()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleUpdateDeviceClient = async () => {
    setIsLoadingDeviceUpdate(true)
    setDeviceUpdateError(null)
    try {
      await axios.put(`/v1/devices/${id}/command`, {
        name: 'update',
        parameters: {
          version: newDeviceClient
        }
      })
      setTimeout(() => {
        window.location.reload()
      }, 5000)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setDeviceUpdateError(global._(msg))
      setIsLoadingDeviceUpdate(false)
    }
  }

  const handleGetDeviceInfo = async () => {
    setError(null)
    try {
      await axios.put(`/v1/devices/${id}/command`, {
        name: 'send_device_info',
        parameters: []
      })
      setTimeout(fetchDevice, 4000)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleDeleteDevice = async () => {
    try {
      await axios.delete(`/v1/devices/${id}`)

      localStorage.setItem('deviceDeleted', true)

      history.push('/admin/devices')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleReplaceDevice = async newUuid => {
    try {
      setShowReplaceDeviceSuccessMessage(false)
      const { data: targetDeviceData } = await axios.post(
        `/v1/devices/${device.id}/clone/${newUuid}`
      )

      history.push(`/admin/devices/${targetDeviceData.id}`)

      setDevice({ ...device, ...targetDeviceData })

      setShowReplaceDeviceSuccessMessage(true)
      setReplaceDeviceId(null)
      setShowMobileReplaceDeviceModal(false)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleTestCommand = async command => {
    setSuccessMessage(null)
    setError(null)
    setIsSendingTestCommand(true)
    try {
      await axios.put(`/v1/devices/${device.id}/command`, command)
      setSuccessMessage(global._('Device.TestCommandSuccess'))
      await fetchDevice()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsSendingTestCommand(false)
  }

  const deviceStatus = () =>
    device ? (
      <Typography
        variant='body'
        bold
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div
          style={{
            width: 34,
            height: 34,
            backgroundColor: device.stats
              ? 'var(--color-success-opacity)'
              : 'var(--color-error-opacity)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '9999px',
            marginRight: 10
          }}
        >
          <Icon
            style={{
              color: device.stats
                ? 'var(--color-success-green)'
                : 'var(--color-error)',
              fontSize: 30
            }}
          >
            fiber_manual_record
          </Icon>
        </div>
        <div>
          {device.stats
            ? global._('Device.DeviceIsOnline')
            : global._('Device.DeviceIsOffline')}
          <Typography block fontNormal faded small variant='body'>
            {device.uuid || '-'}
          </Typography>
          {device && device.version === 2 && (
            <Typography block fontNormal faded small variant='body'>
              {device.serial || '-'}
            </Typography>
          )}
        </div>
      </Typography>
    ) : (
      <LoadingSpinner />
    )

  const showDeviceVersions = margin => (
    <>
      {device && device.version !== 2 && (
        <Typography variant='body' block style={{ marginBottom: margin && 10 }}>
          Manager version:
          <span style={{ fontWeight: 600, marginLeft: 8 }}>
            {device && device.stats ? device.stats.managerVersion : '-'}
          </span>
        </Typography>
      )}
      <Typography variant='body' block>
        Client version:
        <span style={{ fontWeight: 600, marginLeft: 8 }}>
          {device && device.stats ? device.stats.clientVersion : '-'}
        </span>
      </Typography>
    </>
  )

  const handleDeviceUpdate = async (val, field) => {
    const obj = {
      [field]: val && ((val.value && val.value.id) || val)
    }

    setIsUpdatingDevice(true)
    try {
      await axios.patch(`/v1/devices/${id}`, obj)

      if (field === 'organisationId') {
        setSelectedOrganisation(val)
      }

      if (field === 'propertyId') {
        setSelectedProperty(val)
      }

      setShowMobileReplaceDeviceModal(false)

      await fetchDevice()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsUpdatingDevice(false)
  }

  const handleLockUpdate = async (lock, obj) => {
    if (obj && obj.pulseTimeMs && obj.pulseTimeMs == lock.pulseTimeMs) {
      return
    }
    setSuccessMessage(null)
    setError(null)
    try {
      await axios.patch(
        `/v1/organisations/${lock.organisationId}/locks/${lock.id}`,
        obj
      )
      setSuccessMessage(global._('Pulstiden har ändrats.'))
      await fetchDevice()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleNameUpdate = async () => {
    handleDeviceUpdate(deviceName, 'name')

    setIsNameUpdated(true)

    setTimeout(() => {
      setIsNameUpdated(false)
    }, 2000)

    setTimeout(() => {
      setIsEditingName(false)
    }, 200)
  }

  const handleDescriptionUpdate = async () => {
    handleDeviceUpdate(description, 'description')

    setIsDescriptionUpdated(true)

    setTimeout(() => {
      setIsDescriptionUpdated(false)
    }, 2000)

    setTimeout(() => {
      setIsEditingDescription(false)
    }, 200)
  }

  const handleSaveSchedule = async scheduleData => {
    setSuccessMessage(null)
    setError(null)
    try {
      const { data } = await axios.put(
        `/v1/organisations/${device.organisationId}/locks/${device.locks[0].id}/schedule`,
        scheduleData
      )
      const _device = {
        ...device,
        locks: [{ ...device.locks[0], schedule: data }]
      }
      setDevice(_device)
      setSuccessMessage('Schemat har sparats.')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const detailsForm = () => (
    <div>
      <Grid container spacing={3} style={{ marginBottom: 1 }}>
        <Grid item xs={12} md={3}>
          <Input
            endAdornment={nameInputAdornment(
              isEditingName,
              handleNameUpdate,
              isNameUpdated,
              setIsEditingName,
              nameInputRef
            )}
            disabled={!isEditingName}
            ref={nameInputRef}
            helpText={global._('Device.ClickToEditHelpText')}
            placeholder={global._('User.Name')}
            defaultValue={device && device.name}
            onChange={e => setDeviceName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            endAdornment={nameInputAdornment(
              isEditingDescription,
              handleDescriptionUpdate,
              isDescriptionUpdated,
              setIsEditingDescription,
              nameInputRef
            )}
            disabled={!isEditingDescription}
            ref={descriptionInputRef}
            helpText={global._('Device.ClickToEditHelpText')}
            placeholder={global._('Device.Description')}
            defaultValue={device && device.description}
            onChange={e => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AsyncSelect
            isSearchable
            isClearable={false}
            helpText={global._('Device.PickAnOrganisationHelpText')}
            value={selectedOrganisation}
            labelKey='name'
            name='organisation'
            placeholder={global._('Filter.Customer')}
            onChange={e => {
              if (e !== selectedOrganisation) {
                setTempOrganisation(e)
                setShowOrganisationUpdateModal(true)
              }
            }}
            url='/v1/organisations?search={{input}}&orderBy=name&order=asc'
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AsyncSelect
            isSearchable
            isClearable={false}
            key={`properties-${
              selectedOrganisation.value && selectedOrganisation.value.id
            }`}
            helpText={global._('Device.PickAProperty')}
            value={selectedProperty}
            labelKey='name'
            name='property'
            placeholder={global._('Filter.Property')}
            onChange={e => {
              if (e !== selectedProperty) {
                setTempProperty(e)
                setShowPropertyUpdateModal(true)
              }
            }}
            cacheOptions={false}
            url={`/v1/properties?query={{input}}&orderBy=name&order=asc&organisationId=${
              (selectedOrganisation.value && selectedOrganisation.value.id) ||
              ''
            }`}
          />
        </Grid>
      </Grid>

      {/* Device configuration */}
      {device && (
        <>
          <Grid container spacing={3} style={{ marginBottom: 1 }}>
            <Grid item xs={12} md={3}>
              <TextField
                style={{ width: '100%' }}
                variant='filled'
                select
                disabled={device && device.version === 2}
                label={global._('Device.Config')}
                onChange={e => handleDeviceUpdate(e.target.value, 'config')}
                value={device && device.config}
              >
                {Object.values(DeviceConfig).map(val => (
                  <MenuItem value={val}>
                    {global._(`Device.Config.${val}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {device.config === DeviceConfig.PULSE && !!device.locks.length && (
              <Grid item xs={12} md={3}>
                <Input
                  helpText={global._('Device.PulseTimeMsHelpText')}
                  placeholder={global._('Device.PulseTimeMs')}
                  defaultValue={device.locks[0].pulseTimeMs}
                  onBlur={e =>
                    handleLockUpdate(device.locks[0], {
                      pulseTimeMs: e.target.value
                    })
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0px 10px'
                }}
              >
                <span style={{ fontSize: 16, fontWeight: 600 }}>
                  {global._('Device.AllowSharing')}
                </span>
                <Switch
                  checked={device.shareable}
                  onChange={e =>
                    handleDeviceUpdate(e.target.checked, 'shareable')
                  }
                />
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    marginTop: 2,
                    color: '#888'
                  }}
                >
                  {global._('Device.AllowSharingHelpText')}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0px 10px'
                }}
              >
                <span style={{ fontSize: 16, fontWeight: 600 }}>
                  {global._('Device.AllowBooking')}
                </span>
                <Switch
                  checked={device.bookable}
                  onChange={e =>
                    handleDeviceUpdate(e.target.checked, 'bookable')
                  }
                />
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    marginTop: 2,
                    color: '#888'
                  }}
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: global._('Device.AllowBookingHelpText', [
                      device?.locks.length ? device.locks[0].id : '-'
                    ])
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <div
            style={{
              display: 'inline-flex',
              flexDirection: 'row',
              border: '1px solid #ccc',
              width: 'auto',
              borderRadius: 4
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 12,
                borderRight: '1px solid #ccc'
              }}
            >
              <span style={{ fontSize: 16, fontWeight: 600, marginBottom: 4 }}>
                {global._(`Device.Config.${device.config}.INPUT1`)}
              </span>
              <Switch
                disabled={
                  !device.stats || isUpdatingDevice || device.version === 2
                }
                checked={device.input1}
                onChange={e => handleDeviceUpdate(e.target.checked, 'input1')}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 12,
                borderRight: '1px solid #ccc'
              }}
            >
              <span style={{ fontSize: 16, fontWeight: 600, marginBottom: 4 }}>
                {global._(`Device.Config.${device.config}.INPUT2`)}
              </span>
              <Switch
                disabled={
                  !device.stats || isUpdatingDevice || device.version === 2
                }
                checked={device.input2}
                onChange={e => handleDeviceUpdate(e.target.checked, 'input2')}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', padding: 12 }}
            >
              <span style={{ fontSize: 16, fontWeight: 600, marginBottom: 4 }}>
                {global._(`Device.Config.${device.config}.INPUT3`)}
              </span>
              <Switch
                disabled={
                  !device.stats || isUpdatingDevice || device.version === 2
                }
                checked={device.input3}
                onChange={e => handleDeviceUpdate(e.target.checked, 'input3')}
              />
            </div>
          </div>
          {/* input status */}
          {((device.config === DeviceConfig.SAFETRON3600 && device.input1) ||
            device.input2 ||
            device.input3) &&
            device.stats && (
              <div
                style={{
                  marginLeft: 24,
                  marginTop: 10,
                  display: 'inline-flex',
                  flexDirection: 'row',
                  border: '1px solid #ccc',
                  width: 'auto',
                  borderRadius: 4
                }}
              >
                {device.config === DeviceConfig.SAFETRON3600 && device.input1 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 12,
                      borderRight: '1px solid #ccc'
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            marginBottom: 4
                          }}
                        >
                          {global._(`Device.Config.${device.config}.INPUT1`)}
                        </span>
                        <Tooltip title={global._('Common.Refresh')}>
                          <IconButton onClick={fetchDevice}>
                            <Icon>cached</Icon>
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          textTransform: 'uppercase',
                          paddingBottom: 10
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: '#fff',
                            padding: '2px 12px',
                            borderRadius: 4,
                            backgroundColor: device.stats.status.input1
                              ? '#10BC20'
                              : '#F85B5B'
                          }}
                        >
                          {global._(
                            `Device.Config.${device.config}.INPUT1.${device.stats.status.input1}`
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {device.input2 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 12,
                      borderRight: '1px solid #ccc'
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            marginBottom: 4
                          }}
                        >
                          {global._(`Device.Config.${device.config}.INPUT2`)}
                        </span>
                        <Tooltip title={global._('Common.Refresh')}>
                          <IconButton onClick={fetchDevice}>
                            <Icon>cached</Icon>
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          textTransform: 'uppercase',
                          paddingBottom: 10
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: '#fff',
                            padding: '2px 12px',
                            borderRadius: 4,
                            backgroundColor: device.stats.status.input2
                              ? '#10BC20'
                              : '#F85B5B'
                          }}
                        >
                          {global._(
                            `Device.Config.${device.config}.INPUT2.${device.stats.status.input2}`
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {device.input3 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 12
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            marginBottom: 4
                          }}
                        >
                          {global._(`Device.Config.${device.config}.INPUT3`)}
                        </span>
                        <Tooltip title={global._('Common.Refresh')}>
                          <IconButton onClick={fetchDevice}>
                            <Icon>cached</Icon>
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          textTransform: 'uppercase',
                          paddingBottom: 10
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: '#fff',
                            padding: '2px 12px',
                            borderRadius: 4,
                            backgroundColor:
                              device.stats.status.input3 === 'OPEN'
                                ? '#10BC20'
                                : '#F85B5B'
                          }}
                        >
                          {global._(
                            `Device.Config.${device.config}.INPUT3.${device.stats.status.input3}`
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          <div style={{ marginTop: 10 }} />
          <Grid container>
            {device.config === DeviceConfig.PULSE && (
              <Button
                disabled={!device.stats}
                loading={isSendingTestCommand}
                variant='outlined'
                onClick={() =>
                  handleTestCommand({
                    name: 'pulse',
                    parameters: {
                      relay: 1,
                      pulseTimeMs: device.locks.length
                        ? device.locks[0].pulseTimeMs
                        : undefined
                    }
                  })
                }
              >
                Testa: Puls
              </Button>
            )}
            {device.config === DeviceConfig.SAFETRON3600 && (
              <>
                <Button
                  disabled={!device.stats}
                  loading={isSendingTestCommand}
                  variant='outlined'
                  onClick={() =>
                    handleTestCommand({
                      name: 'lock',
                      parameters: { relay: 1 }
                    })
                  }
                >
                  Testa: Lås
                </Button>
                <Button
                  disabled={!device.stats}
                  loading={isSendingTestCommand}
                  variant='outlined'
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    handleTestCommand({
                      name: 'unlock',
                      parameters: { relay: 1 }
                    })
                  }
                >
                  Testa: Lås upp
                </Button>
              </>
            )}
          </Grid>
        </>
      )}
    </div>
  )

  const columns = [
    {
      key: 'icon',
      localizationKey: 'Blank',
      sortingDisabled: true,
      style: { width: 70 },
      format: (objVal, obj) =>
        obj.name && obj.createdAt ? (
          <Icon
            className={`${classes.icon} ${
              obj.darkBG ? classes.iconEmpty : null
            }`}
          >
            lock
          </Icon>
        ) : (
          <div className={`${classes.iconEmpty} ${classes.icon}`} />
        ),
      clickable: true
    },
    {
      key: 'name',
      localizationKey: 'Name',
      sortingDisabled: true,
      longText: true,
      clickable: true,
      style: { width: 250 }
    },
    {
      key: 'relay',
      localizationKey: 'Device.RelayNumber',
      sortingDisabled: true,
      clickable: true
    },
    {
      key: 'createdAt',
      localizationKey: 'Device.CreatedAt',
      sortingDisabled: true,
      style: { width: 400 },
      hiddenOnSm: true,
      clickable: true,
      format: objDate => objDate && moment(objDate).format('YYYY-MM-DD HH:mm')
    },
    {
      key: '',
      localizationKey: 'Blank',
      clickable: true,
      style: { width: 200 },
      format: (objVal, obj) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {obj.name && obj.createdAt ? (
            <Icon>navigate_next</Icon>
          ) : (
            <Button
              variant='none'
              style={{ textTransform: 'uppercase' }}
              onClick={() => {
                setCurrentRelay(obj.relay)
                setShowCreateLockModal(true)
              }}
            >
              {global._('Device.AddLock')}
            </Button>
          )}
        </div>
      )
    }
  ]

  const availableSlots = [1]

  const slots = availableSlots.map(relayId => ({
    name: global._('Device.EmptyRelay'),
    relay: relayId,
    darkBG: true
  }))

  const availableRelays =
    device && device.locks
      ? slots.map(slot => {
          const slotObj = {
            ..._.merge(slot, _.find(device.locks, { relay: slot.relay }))
          }

          if (slotObj.id) {
            slotObj.darkBG = false
            slotObj.permalink = `admin/devices/${slotObj.organisationId}/locks`
          }

          return slotObj
        })
      : []

  const { isMobile } = useWindowDimensions()

  const deleteDeviceModal = () =>
    showDeleteDeviceModal && (
      <Modal
        onClose={() => setShowDeleteDeviceModal(false)}
        title={global._('Device.DeleteDevice')}
        content={
          <div style={{ textAlign: 'left' }}>
            <Typography variant='body' block style={{ marginBottom: 15 }}>
              {global._('Device.DeleteDeviceModalConfirmText')}
            </Typography>
            <Typography variant='body' bold block style={{ marginBottom: 15 }}>
              {device.name}
            </Typography>
            <Typography
              variant='body'
              italic
              block
              style={{ marginBottom: 30 }}
            >
              {global._('Device.DeleteDeviceModalInfo')}
            </Typography>
          </div>
        }
        buttons={[
          <Button
            variant='none'
            onClick={() => setShowDeleteDeviceModal(false)}
          >
            {global._('Common.Cancel')}
          </Button>,
          <Button
            variant='secondary'
            data-cy='device-detail-modal-button-confirm'
            onClick={() => {
              handleDeleteDevice()
              setShowDeleteDeviceModal(false)
            }}
          >
            {global._('Delete')}
          </Button>
        ]}
      />
    )

  const replaceDeviceModal = () =>
    showReplaceDeviceModal && (
      <Modal
        onClose={() => setShowReplaceDeviceModal(false)}
        title={global._('Device.ReplaceDevice')}
        content={
          <div style={{ textAlign: 'left' }}>
            <TextField
              value={replaceDeviceId}
              name='uuid'
              helperText={global._('Common.Required')}
              className={classes.input}
              label={global._('Device.ReplaceDeviceNewId')}
              variant='filled'
              onChange={e => setReplaceDeviceId(e.target.value)}
              style={{ marginBottom: 20 }}
            />
            <Typography
              variant='body'
              italic
              block
              style={{ marginBottom: 30 }}
            >
              {global._('Device.ReplaceDeviceModalInfo')}
            </Typography>
          </div>
        }
        buttons={[
          <Button
            variant='none'
            onClick={() => setShowReplaceDeviceModal(false)}
          >
            {global._('Common.Cancel')}
          </Button>,
          <Button
            variant='primary'
            disabled={!replaceDeviceId}
            onClick={() => {
              handleReplaceDevice(replaceDeviceId)
              setShowReplaceDeviceModal(false)
            }}
          >
            {global._('Common.Replace')}
          </Button>
        ]}
      />
    )

  const updatePropertyModal = () =>
    showPropertyUpdateModal && (
      <Modal
        onClose={() => setShowPropertyUpdateModal(false)}
        title={global._('Devices.PropertyUpdateModalTitle')}
        // content={(
        //   <div style={{ textAlign: 'left' }}>
        //     <Typography variant='body' italic block style={{ marginBottom: 30 }}>
        //       {global._('Devices.PropertyUpdateModalInfo')}
        //     </Typography>
        //   </div>
        // )}
        buttons={[
          <Button
            variant='none'
            onClick={() => setShowPropertyUpdateModal(false)}
          >
            {global._('Common.Cancel')}
          </Button>,
          <Button
            variant='secondary'
            onClick={() => {
              setShowPropertyUpdateModal(false)
              handleDeviceUpdate(tempProperty, 'propertyId')
              setTempProperty(null)
            }}
          >
            {global._('Common.Change')}
          </Button>
        ]}
      />
    )

  const updateOrganisationModal = () =>
    showOrganisationUpdateModal && (
      <Modal
        onClose={() => setShowOrganisationUpdateModal(false)}
        title={global._('Devices.OrganisationUpdateModalTitle')}
        content={
          <div style={{ textAlign: 'left' }}>
            <Typography
              variant='body'
              italic
              block
              style={{ marginBottom: 30 }}
            >
              {global._('Devices.OrganisationUpdateModalInfo')}
            </Typography>
          </div>
        }
        buttons={[
          <Button
            variant='none'
            onClick={() => setShowOrganisationUpdateModal(false)}
          >
            {global._('Common.Cancel')}
          </Button>,
          <Button
            variant='secondary'
            onClick={() => {
              setShowOrganisationUpdateModal(false)
              handleDeviceUpdate(tempOrganisation, 'organisationId')
              setTempOrganisation(null)
            }}
          >
            {global._('Common.Change')}
          </Button>
        ]}
      />
    )

  if (!isLoadingDevice && !device && deviceFetched) {
    history.push('/admin/devices')
  }

  const successMessages = (
    <>
      {successMessage ? <SuccessMessage subtitle={successMessage} /> : null}
      {showLockSuccessMessage ? (
        <SuccessMessage subtitle={global._('Device.LockAdded')} />
      ) : null}
      {showReplaceDeviceSuccessMessage ? (
        <SuccessMessage subtitle={global._('Device.DeviceReplaced')} />
      ) : null}
    </>
  )
  if (isMobile) {
    return (
      <>
        {successMessages}
        {showCreateLockModal && (
          <AddLockModal
            error={error}
            isMobile={isMobile}
            handleCreateLock={handleCreateLock}
            availableRelays={availableRelays}
            device={device}
            setShowCreateLockModal={setShowCreateLockModal}
            currentRelay={currentRelay}
          />
        )}
        {updatePropertyModal()}
        {updateOrganisationModal()}
        <DeviceDetailMobile
          setError={setError}
          showMobileReplaceDeviceModal={showMobileReplaceDeviceModal}
          setShowMobileReplaceDeviceModal={setShowMobileReplaceDeviceModal}
          handleReplaceDevice={handleReplaceDevice}
          device={device}
          signout={signout}
          history={history}
          error={error}
          setShowCreateLockModal={setShowCreateLockModal}
          setShowDeleteDeviceModal={setShowDeleteDeviceModal}
          deleteDeviceModal={deleteDeviceModal}
          availableRelays={availableRelays}
          deviceStatus={deviceStatus}
          showDeviceVersions={showDeviceVersions}
          detailsForm={detailsForm}
        />
      </>
    )
  }

  return (
    <>
      {successMessages}
      {deleteDeviceModal()}
      {replaceDeviceModal()}
      {updatePropertyModal()}
      {updateOrganisationModal()}
      {showUpdateClientModal && (
        <Modal
          onClose={() => setShowUpdateClientModal(false)}
          title={global._('Device.ClientUpdate')}
          content={
            <div style={{ textAlign: 'left' }}>
              <TextField
                value={newDeviceClient}
                name='newDeviceClient'
                helperText={global._('Device.AvailableVersions')}
                className={classes.input}
                label={global._('Device.PickAClientVersion')}
                variant='filled'
                onChange={e => setNewDeviceClient(e.target.value)}
                select
              >
                {deviceVersions.map(version => (
                  <MenuItem key={version} value={version}>
                    {version}
                  </MenuItem>
                ))}
              </TextField>
              {deviceUpdateError && (
                <Notification
                  type='error'
                  message={deviceUpdateError}
                  style={{ margin: '20px 0' }}
                />
              )}
            </div>
          }
          buttons={[
            <Button
              disabled={isLoadingDeviceUpdate}
              variant='none'
              onClick={() => setShowUpdateClientModal(false)}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              loading={isLoadingDeviceUpdate}
              variant='primary'
              onClick={() => {
                handleUpdateDeviceClient()
                // setShowUpdateClientModal(false)
              }}
              disabled={!newDeviceClient}
            >
              {global._('Common.Update')}
            </Button>
          ]}
        />
      )}
      {showCreateLockModal && (
        <AddLockModal
          error={error}
          isMobile={isMobile}
          handleCreateLock={handleCreateLock}
          availableRelays={availableRelays}
          device={device}
          setShowCreateLockModal={setShowCreateLockModal}
          currentRelay={currentRelay}
        />
      )}
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      <PageContentHeader
        breadcrumbItems={breadcrumbItems}
        actionButtons={
          <div style={{ marginLeft: 'auto' }}>
            <Button
              variant='outlined'
              onClick={() => setShowReplaceDeviceModal(true)}
              style={{ marginRight: 20 }}
            >
              {global._('Device.ReplaceDevice')}
            </Button>
            <Button
              data-cy='device-detail-button-delete'
              variant='secondary'
              onClick={() => setShowDeleteDeviceModal(true)}
            >
              {global._('Device.DeleteDevice')}
            </Button>
          </div>
        }
      />
      <Paper
        smallerPadding
        alignItems='left'
        style={{ marginBottom: 20, paddingBottom: 7 }}
      >
        {detailsForm()}
        <div
          style={{
            borderBottom: '1px solid var(--color-divider-light)',
            width: 'calc(100% + 44px)',
            display: 'inline-block',
            margin: '0 -22px 13px',
            paddingTop: '17px'
          }}
        />
        <Grid
          container
          spacing={3}
          alignItems='center'
          // style={{ marginBottom: 1 }}
        >
          <Grid
            item
            xs={12}
            md={4}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {deviceStatus()}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            {showDeviceVersions()}
          </Grid>
          {device && device.version === 2 && (
            <Grid item xs={12} md={4} style={{ textAlign: 'right' }}>
              <Button
                // disabled={!device.stats}
                variant='outlined'
                style={{ marginLeft: 10 }}
                onClick={() => setShowUpdateClientModal(true)}
              >
                {global._('Device.ClientUpdate')}
              </Button>
            </Grid>
          )}
        </Grid>
        <div
          style={{
            borderBottom: '1px solid var(--color-divider-light)',
            width: 'calc(100% + 44px)',
            display: 'inline-block',
            margin: '0 -22px 13px',
            paddingTop: '17px'
          }}
        />
        {device && device.version === 2 && (
          <Grid
            container
            spacing={3}
            alignItems='center'
            style={{ marginBottom: 1 }}
          >
            <Grid item xs={12}>
              <Typography variant='body' style={{ fontWeight: 600 }}>
                {global._('Common.Information')}
              </Typography>
              <Typography
                variant='body2'
                style={{ marginLeft: 16, color: '#777' }}
              >
                {deviceExtraInfo.timestamp
                  ? moment(deviceExtraInfo.timestamp).calendar()
                  : '-'}
              </Typography>
              <Tooltip title={global._('Common.Refresh')}>
                <IconButton
                  // disabled={!device.stats}
                  variant='outlined'
                  style={{ marginLeft: 12 }}
                  onClick={() => handleGetDeviceInfo()}
                >
                  <Sync />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle'>Connection type</Typography>
              <Typography variant='body2'>
                {deviceExtraInfo.conntype}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle'>Signal</Typography>
              {deviceExtraInfo.conntype === 'LTE' ? (
                <>
                  {deviceExtraInfo.signal > -65 && global._('Signal.Excellent')}
                  {deviceExtraInfo.signal <= -65 &&
                    deviceExtraInfo.signal >= -75 &&
                    global._('Signal.Good')}
                  {deviceExtraInfo.signal <= -74 &&
                    deviceExtraInfo.signal >= -85 &&
                    global._('Signal.Fair')}
                  {deviceExtraInfo.signal <= -84 &&
                    deviceExtraInfo.signal >= -95 &&
                    global._('Signal.Poor')}
                  {deviceExtraInfo.signal < -95 && global._('Signal.NoSignal')}
                  {` (${deviceExtraInfo.signal} dBm)`}
                </>
              ) : (
                <Typography variant='body2'>
                  {deviceExtraInfo.signal}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle'>IMEI</Typography>
              <Typography variant='body2'>{deviceExtraInfo.imei}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle'>SIM IMSI</Typography>
              <Typography variant='body2'>
                {deviceExtraInfo.sim_imsi}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle'>SIM ICCID</Typography>
              <Typography variant='body2'>
                {deviceExtraInfo.sim_iccid}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle'>Operator</Typography>
              <Typography variant='body2'>
                {deviceExtraInfo.operator}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle'>Band</Typography>
              <Typography variant='body2'>{deviceExtraInfo.band}</Typography>
            </Grid>
          </Grid>
        )}
      </Paper>
      <ObjectList
        sectionTitle={`${global._('Device.ConnectedLocks')} ${
          device && device.locks ? device.locks.length : 0
        } av ${availableSlots.length}`}
        columns={columns}
        objects={availableRelays}
        history={history}
        expandable={false}
        objectPermalink={null}
        forwardArrow
      />
      {device?.locks?.length > 0 && (
        <Scheduler
          {...props}
          schedule={device.locks[0].schedule}
          onSave={scheduleData => {
            handleSaveSchedule(scheduleData)
          }}
          style={{ marginTop: 32 }}
        />
      )}
    </>
  )
}

DeviceDetail.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array,
  signout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

DeviceDetail.defaultProps = {
  breadcrumbItems: []
}

const DeviceDetailWithStyles = injectSheet(styles)(DeviceDetail)
export default DeviceDetailWithStyles
