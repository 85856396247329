import React from 'react'
import PropTypes from 'prop-types'
import useWindowDimensions from 'src/utils/useWindowDimensions'

const Indicator = ({ variant, ...rest }) => {
  const { isMobile } = useWindowDimensions()

  let background
  if (variant === 'green') {
    background = 'var(--color-success-green'
  } else if (variant === 'grey') {
    background = 'var(--color-middle-grey'
  }

  const containerStyle = Object.assign(
    {
      marginRight: isMobile ? 10 : 20,
      position: 'relative',
      width: 34,
      height: 34
    },
    rest.style
  )

  return (
    <div style={containerStyle}>
      <span
        style={{
          position: 'absolute',
          width: 34,
          height: 34,
          background,
          left: 0,
          top: 0,
          borderRadius: '100%',
          opacity: 0.5
        }}
      />
      <span
        style={{
          position: 'absolute',
          width: 22,
          height: 22,
          background,
          left: '50%',
          top: '50%',
          borderRadius: '100%',
          transform: 'translate(-50%, -50%)'
        }}
      />
    </div>
  )
}

Indicator.propTypes = {
  variant: PropTypes.oneOf(['grey', 'green'])
}

Indicator.defaultProps = {
  variant: 'grey'
}

export default Indicator
