import React from 'react'
import injectSheet from 'react-jss'
import TabNavigator from '../../components/TabNavigator'

const styles = {}

const MyLocksNavigator = () => {
  const navigationItems = [
    {
      label: global._('MyLocks.MyLocks'),
      icon: 'person',
      path: '/mylocks',
      key: 'my-locks'
    },
    {
      label: global._('MyLocks.DelegatedLocks'),
      icon: 'person_add',
      path: '/mylocks/share/list',
      key: 'delegated-locks'
    }
  ]

  return (
    <div style={{ position: 'relative', zIndex: 100 }}>
      <TabNavigator
        items={navigationItems}
        largeOnMobile
        whiteBg
        itemStyle={{ paddingTop: 15 }}
      />
    </div>
  )
}

const MyLocksNavigatorWithStyles = injectSheet(styles)(MyLocksNavigator)
export default MyLocksNavigatorWithStyles
