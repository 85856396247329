import { connect } from 'react-redux'
import Properties from './Properties'

const mapStateToProps = state => ({
  user: state.login.user
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Properties)
