/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon } from '@material-ui/core'
import Typography from './Typography'
import Paper from './Paper'
import Button from './Button'
import Modal from './Modal'

const styles = {
  header: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    width: '100%'
  },
  headerTitle: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  infoIcon: {
    fontSize: 30,
    transition: 'all 0.3s',
    cursor: 'pointer',
    marginLeft: 7,
    '&:hover': {
      color: 'var(--color-primary)'
    }
  },
  objRow: {
    width: 'calc(100% + 40px)',
    transition: 'background-color 0.3s',
    marginLeft: '-20px',
    marginRight: '-20px',
    paddingLeft: 'var(--section-padding-default)',
    paddingRight: 'var(--section-padding-default)',
    '&:not(:first-child)': {
      borderTop: '1px solid var(--color-divider-light)'
    },
    '&:first-child': {
      borderBottom: 'none',
      marginBottom: 0
    }
  },
  footer: {
    width: '100%'
  },
  footerActionButtons: {
    width: '100%',
    padding: '20px 0 30px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}

const Divider = () => (
  <div
    style={{
      width: 'calc(100% + 40px)',
      height: 1,
      backgroundColor: 'var(--color-divider-light)',
      margin: '0 -20px'
    }}
  />
)

const ObjectSection = ({
  classes,
  sectionTitle,
  infoDialogTitle,
  infoDialogContent,
  headerActionButtons,
  footerActionButtons,
  objects,
  columns,
  addObjectsContent,
  addObjectsActionButtons
}) => {
  const [showInfoDialog, setShowInfoDialog] = useState(null)

  if (
    (!objects || objects.length === 0) &&
    addObjectsContent &&
    addObjectsActionButtons
  ) {
    return (
      <Paper
        mediumPadding
        style={{
          marginTop: 10,
          justifyContent: 'space-between',
          flexDirection: 'row'
        }}>
        <div>{addObjectsContent}</div>
        <div>{addObjectsActionButtons}</div>
      </Paper>
    )
  }

  return (
    <Paper
      style={{
        marginTop: 10,
        padding:
          'var(--section-padding-default) var(--section-padding-default) 0'
      }}>
      {sectionTitle && (
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            {
              <Typography variant='subtitleBig' bold>
                {sectionTitle}
              </Typography>
            }
            {infoDialogTitle && infoDialogContent && (
              <Icon
                className={classes.infoIcon}
                onClick={() => setShowInfoDialog(true)}>
                info
              </Icon>
            )}
            {infoDialogTitle && infoDialogContent && showInfoDialog && (
              <Modal
                title={infoDialogTitle}
                onClose={() => setShowInfoDialog(false)}
                content={infoDialogContent}
                buttons={[
                  <Button
                    variant='none'
                    onClick={() => setShowInfoDialog(false)}>
                    {global._('Common.Close')}
                  </Button>
                ]}
              />
            )}
          </div>
          {headerActionButtons && <div>{headerActionButtons}</div>}
        </div>
      )}
      {objects && columns && (
        <>
          <Divider />
          <div style={{ width: '100%' }}>
            {objects.length > 0 &&
              Array.isArray(objects) &&
              objects.map((obj, index) => {
                if (!obj) return null

                if (typeof obj === 'string') {
                  return (
                    <div
                      style={{ paddingTop: 19, paddingBottom: 15 }}
                      className={classes.objRow}>
                      <Typography variant='h3' bold>
                        {obj}
                      </Typography>
                    </div>
                  )
                }

                return (
                  <div
                    style={{
                      paddingTop: 15,
                      paddingBottom: 15,
                      display: 'flex'
                    }}
                    className={classes.objRow}
                    key={`obj-${obj.id || index}-${index}`}>
                    {columns &&
                      Array.isArray(columns) &&
                      columns.length > 0 &&
                      columns.map((col, colIndex) => {
                        let value

                        if (obj) {
                          if (obj[col.key] && col.valueKey) {
                            value = obj[col.key][col.valueKey]
                          } else if (!obj[col.key] && col.defaultValue) {
                            value = col.defaultValue
                          } else if (col.format) {
                            value = col.format(obj[col.key], obj)
                          } else {
                            value = obj[col.key]
                          }

                          if (
                            (typeof col.hiddenFor !== 'function' &&
                              col.hiddenFor) ||
                            (typeof col.hiddenFor === 'function' &&
                              col.hiddenFor(obj))
                          )
                            return

                          return (
                            <div
                              style={{
                                padding: '0 10px',
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: col.alignEnd && 'auto',
                                cursor: col.clickable ? 'pointer' : undefined
                              }}
                              onClick={() =>
                                (col.onClick && col.onClick(obj)) || undefined
                              }
                              key={`col-${col.key}-${colIndex}`}>
                              {value}
                            </div>
                          )
                        }
                      })}
                  </div>
                )
              })}
          </div>
        </>
      )}
      {footerActionButtons && (
        <div className={classes.footer}>
          <Divider />
          <div className={classes.footerActionButtons}>
            {footerActionButtons}
          </div>
        </div>
      )}
    </Paper>
  )
}

ObjectSection.propTypes = {
  classes: PropTypes.object.isRequired,
  sectionTitle: PropTypes.string,
  infoDialogTitle: PropTypes.string || PropTypes.object,
  infoDialogContent: PropTypes.any,
  objects: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  columns: PropTypes.array.isRequired,
  headerActionButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  footerActionButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  addObjectsContent: PropTypes.object,
  addObjectsActionButtons: PropTypes.object
}

ObjectSection.defaultProps = {
  footerActionButtons: false
}

const ObjectSectionWithStyles = injectSheet(styles)(ObjectSection)
export default ObjectSectionWithStyles
