import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import MobileTabBar from './MobileTabBar'

const MobileContainer = ({ children, background, showTabBar, ...rest }) => (
  <Content style={{ backgroundColor: background, ...rest.style }}>
    {children}
    {showTabBar && <MobileTabBar />}
  </Content>
)

MobileContainer.propTypes = {
  children: PropTypes.node,
  background: PropTypes.string,
  showTabBar: PropTypes.bool
}

MobileContainer.defaultProps = {
  children: null,
  background: false,
  showTabBar: true
}

export default MobileContainer
