import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import axios from 'axios'
import PropTypes from 'prop-types'
import {
  TextField,
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch,
  Icon,
  FormGroup
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import TabNavigator from 'src/components/TabNavigator'
import Row from 'src/components/Row'
import NavigationList from 'src/components/NavigationList'
import MobileContainer from 'src/components/MobileContainer'
import ScrollView from 'src/components/ScrollView'
import NavigationBar from 'src/components/NavigationBar'
import FullscreenModal from 'src/components/FullscreenModal'
import Column from 'src/components/Column'
import Products from 'src/components/Products'
import Indicator from 'src/components/Indicator'
import ShippingOptions from 'src/components/ShippingOptions'
import AsyncSelect from 'src/components/AsyncSelect'
import Paper from '../../components/Paper'
import PageContentHeader from '../../components/PageContentHeader'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import Alert from '../../components/Alert'
import {
  phoneNumberRegex,
  organisationNumberRegExp,
  checkIfOrgHasAccessToScope
} from '../../utils/helpers'
import Notification from '../../common/Notification'
import useWindowDimensions from '../../utils/useWindowDimensions'
import UploadLogoType from './UploadLogoType'
import {
  DocumentTypes,
  OrganisationTypes,
  UserRoles,
  OrganisationServices,
  ContactPageURL,
  ServiceURLs
} from '../../utils/constants'
import Modal from '../../components/Modal'
import SuccessMessage from '../../components/SuccessMessage'
import AddressFields from './AddressFields'
import PhoneNumberField from '../../components/PhoneNumberField'

const styles = {
  root: {},
  input: {
    width: '100%'
  },
  section: {
    width: 'calc(100% + 40px)',
    padding: 20,
    borderBottom: '1px solid var(--color-divider-light)',
    marginLeft: '-20px',
    marginRight: '-20px'
  },
  infoIcon: {
    fontSize: 30,
    transition: 'all 0.3s',
    cursor: 'pointer',
    marginLeft: 10,
    marginTop: 3,
    '&:hover': {
      color: 'var(--color-primary)'
    }
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 'var(--tab-bar-height)',
    padding: '28px 18px',
    minWidth: '100%',
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px -4px 10px 0px var(--color-middle-grey-2)',
    background: 'var(--color-white)'
  }
}

const OrganisationNavigation = {
  OVERVIEW: 'OVERVIEW',
  INFORMATION: 'INFORMATION',
  LOGOTYPE: 'LOGOTYPE',
  NOTES: 'NOTES',
  AGREEMENTS: 'AGREEMENTS',
  SERVICES: 'SERVICES',
  PRODUCTS: 'PRODUCTS',
  SHIPPING: 'SHIPPING'
}

const MyOrganisation = props => {
  const {
    user,
    match,
    history,
    classes,
    breadcrumbItems,
    fetchUserOrganisations,
    selectedOrganisation
  } = props

  let calculatedCrumbs = []
  if (breadcrumbItems != null && breadcrumbItems[1].name == 'Organisation') {
    calculatedCrumbs = [
      { name: 'Startsida', path: '/' },
      { name: 'Admin', path: '/admin' }
    ]
    calculatedCrumbs.push({ name: 'Organisation', path: '/organisations' })
  } else {
    calculatedCrumbs = breadcrumbItems
  }
  const { isMobile } = useWindowDimensions()

  const isEligibleToEdit =
    user &&
    Array.isArray(user.roles) &&
    (user.roles.includes(UserRoles.PartnerAdmin) ||
      user.roles.includes(UserRoles.CustomerAdmin))
  const editAgreementsAndExtraInfo =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isPartner =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isSystemAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.SystemAdmin)
  const isCustomerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.CustomerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)
  const canViewMenu = isPartner || isSystemAdmin || isCustomerAdmin
  let defaultNavigation = OrganisationNavigation.OVERVIEW
  if (isMobile && canViewMenu) {
    defaultNavigation = OrganisationNavigation.MENU
  }

  const isMyOrganisation =
    window.location.href && window.location.href.endsWith('/my-organisation')
  const id = match.params.id || user.organisation.id
  const [error, setError] = useState()
  const [saved, setSaved] = useState(false)
  const [organisation, setOrganisation] = useState()
  const [bookingBuilding, setBookingBuilding] = useState(null)
  const [showDeleteOrganisationModal, setShowDeleteOrganisationModal] =
    useState(false)
  const [deleteErrorMsg, setDeleteErrorMsg] = useState(null)
  const [navigation, setNavigation] = useState(defaultNavigation)
  const [
    isServiceAgreementInformationModalOpen,
    setIsServiceAgreementInformationModalOpen
  ] = useState(false)
  const [
    isDutyAgreementInformationModalOpen,
    setIsDutyAgreementInformationModalOpen
  ] = useState(false)
  const [servicesInfoModalOpen, setServicesInfoModalOpen] = useState(false)

  const checkedServicesByDefault = {}

  Object.keys(OrganisationServices).forEach(s => {
    checkedServicesByDefault[s] = false
  })

  const [infoModalObject, setInfoModalObject] = useState(null)
  const [checkedServices, setCheckedServices] = useState(
    checkedServicesByDefault
  )

  const canRemoveOrganisation =
    (isPartner || isSystemAdmin) &&
    organisation &&
    user &&
    organisation.id !== user.organisation.id

  const userHasAccessToOrders = checkIfOrgHasAccessToScope(
    user,
    selectedOrganisation,
    OrganisationServices.KEY_ORDERS
  )

  const partnerOrg =
    organisation && organisation.type === OrganisationTypes.Partner

  const {
    name,
    organisationNumber,
    city,
    address,
    postalCode,
    country,
    reference,
    phone,
    dutyPhone,
    email,
    website,
    directionsURL,
    additionalInfo,
    caseNotificationEmail,
    quoteRequestEmail,
    hasSeparateInvoiceAddress,
    hasSeparateVisitingAddress,
    invoiceCity,
    invoiceAddress,
    invoicePostalCode,
    visitingCity,
    visitingAddress,
    visitingPostalCode
  } = organisation || {}

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await axios.get(
          `/v1/organisations/${id}?include=Booking`
        )
        setOrganisation(data)
        if (data?.attributes?.find(attr => attr.key === 'bookingBuildingId')) {
          setBookingBuilding({
            id: data.attributes.find(attr => attr.key === 'bookingBuildingId')
              ?.value,
            label: data.attributes.find(
              attr => attr.key === 'bookingBuildingName'
            )?.value
          })
        }
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    })()
  }, [])

  const updateCheckedServices = async _checkedServices => {
    setError(null)
    setSaved(false)
    try {
      await axios.patch(`/v1/organisations/${id}`, {
        services: _checkedServices
      })
      setCheckedServices(_checkedServices)
      setSaved(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    if (
      organisation &&
      organisation.services &&
      organisation.services.length > 0 &&
      !partnerOrg
    ) {
      const services = organisation.services[0]

      const currentServices = { ...checkedServicesByDefault }
      Object.keys(OrganisationServices).forEach(s => {
        currentServices[s] = services[s]
      })

      setCheckedServices(currentServices)
    }
  }, [organisation])

  const handleSave = async values => {
    if (!values) return
    setError(null)

    setSaved(false)

    try {
      const { data } = await axios.patch(`/v1/organisations/${id}`, values)
      setSaved(true)
      setOrganisation(data)
      fetchUserOrganisations()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleDeleteOrganisation = async () => {
    setDeleteErrorMsg(null)
    try {
      await axios.delete(`/v1/organisations/${id}`)
      setShowDeleteOrganisationModal(false)
      history.push('/admin/customers')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setDeleteErrorMsg(msg)
    }
  }

  const unboundOrganisation = async () => {
    setError(null)
    try {
      await axios.patch(`/v1/organisations/${id}`, {
        type: OrganisationTypes.Unbound
      })

      const orgObj = { ...organisation }

      orgObj.type = OrganisationTypes.Unbound

      setOrganisation(orgObj)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(global._('Common.Required')),
    caseNotificationEmail:
      (isPartner && !isMyOrganisation) || isCustomerAdmin
        ? undefined
        : Yup.string()
            .nullable()
            .email(global._('OrganisationDetail.EmailNotValidError'))
            .required(global._('Common.Required')),
    quoteRequestEmail:
      (isPartner && !isMyOrganisation) || isCustomerAdmin
        ? undefined
        : Yup.string()
            .nullable()
            .email(global._('OrganisationDetail.EmailNotValidError'))
            .required(global._('Common.Required')),
    reference: Yup.string().required(global._('Common.Required')),
    phone: Yup.string()
      .nullable()
      .matches(phoneNumberRegex, global._('Common.PhoneNumberInvalid')),
    // .required(global._('Common.Required')),
    dutyPhone: Yup.string()
      .nullable()
      .matches(phoneNumberRegex, global._('Common.PhoneNumberInvalid')),
    email:
      partnerOrg &&
      Yup.string()
        .nullable()
        .email(global._('OrganisationDetail.EmailNotValidError'))
        .required(global._('Common.Required')),
    website:
      partnerOrg &&
      Yup.string()
        .nullable()
        .url(global._('OrganisationDetail.InvalidWebsiteError')),
    directionsURL:
      partnerOrg &&
      Yup.string(global._('OrganisationDetail.InvalidWebsiteError'))
        .url(global._('OrganisationDetail.InvalidWebsiteError'))
        .nullable(),
    organisationNumber: Yup.string()
      .nullable()
      .matches(
        organisationNumberRegExp,
        global._('OrganisationDetailOrgNumberNotValid')
      )
  })

  const tabNavigationItems = [
    {
      label: global._('OrganisationDetail.Navigation.Overview'),
      icon: 'store',
      value: OrganisationNavigation.OVERVIEW,
      key: 'overview'
    }
  ]

  if (
    [
      UserRoles.SystemAdmin,
      UserRoles.PartnerAdmin,
      UserRoles.CustomerAdmin
    ].some(r => user.roles.includes(r))
  ) {
    tabNavigationItems.push({
      label: global._('OrganisationDetail.Navigation.Information'),
      icon: 'info',
      value: OrganisationNavigation.INFORMATION,
      key: 'information'
    })
  }

  if (partnerOrg) {
    tabNavigationItems.push({
      label: global._('OrganisationDetail.Navigation.Logotype'),
      icon: 'branding_watermark',
      value: OrganisationNavigation.LOGOTYPE,
      key: 'logotype'
    })
  }

  if (
    [UserRoles.SystemAdmin, UserRoles.PartnerAdmin].some(r =>
      user.roles.includes(r)
    )
  ) {
    tabNavigationItems.push({
      label: global._('OrganisationDetail.Navigation.Notes'),
      icon: 'subject',
      value: OrganisationNavigation.NOTES,
      key: 'notes'
    })
  }

  if (!partnerOrg) {
    /* viewable for all, but can only be changed by partner admin */
    tabNavigationItems.push({
      label: global._('OrganisationDetail.Navigation.Agreements'),
      icon: 'assignment',
      value: OrganisationNavigation.AGREEMENTS,
      key: 'agreements'
    })
  }

  if (
    [UserRoles.PartnerAdmin, UserRoles.CustomerAdmin, UserRoles.Support].some(
      r => user.roles.includes(r)
    ) &&
    !partnerOrg
  ) {
    tabNavigationItems.push({
      label: global._('OrganisationDetail.Navigation.Services'),
      icon: 'work',
      value: OrganisationNavigation.SERVICES,
      key: 'services'
    })
  }

  if (
    !isMobile &&
    userHasAccessToOrders &&
    organisation &&
    organisation.type === OrganisationTypes.Customer &&
    [UserRoles.PartnerAdmin, UserRoles.CustomerAdmin, UserRoles.Support].some(
      r => user.roles.includes(r)
    )
  ) {
    tabNavigationItems.push({
      label: global._('OrganisationDetail.Navigation.Products'),
      icon: 'local_offer',
      value: OrganisationNavigation.PRODUCTS,
      key: 'products'
    })
    tabNavigationItems.push({
      label: global._('Admin.Settings.Orders.Navigation.ShippingOptions'),
      icon: 'local_shipping',
      value: OrganisationNavigation.SHIPPING,
      key: 'shipping'
    })
  }

  let navigationContent
  if (navigation === OrganisationNavigation.OVERVIEW) {
    navigationContent = (
      <div>
        {/* name */}
        {organisation && (
          <Typography variant='title' bold>
            {organisation.name || global._('Common.NoName')}
          </Typography>
        )}
        {!organisation && <Skeleton variant='rect' width={300} height={24} />}
        {/* org. nr */}
        <Row style={{ marginTop: 24 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 160 : 180, flexShrink: 0 }}
          >
            {global._('OrganisationDetail.Overview.OrganisationNumber')}
          </Typography>
          {organisation && (
            <Typography variant='body' bold>
              {organisation.organisationNumber || '-'}
            </Typography>
          )}
          {!organisation && <Skeleton variant='rect' width={200} height={16} />}
        </Row>
        {/* country */}
        {country && (
          <Row style={{ marginTop: 11 }}>
            <Typography
              variant='body'
              faded
              style={{ width: isMobile ? 160 : 180, flexShrink: 0 }}
            >
              {global._('Common.Country')}
            </Typography>
            {organisation && (
              <Typography variant='body' bold>
                {global._(`Countries.${country}`) || '-'}
              </Typography>
            )}
            {!organisation && (
              <Skeleton variant='rect' width={150} height={16} />
            )}
          </Row>
        )}
        {/* address */}
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 160 : 180, flexShrink: 0 }}
          >
            {global._('OrganisationDetail.Address')}
          </Typography>
          {organisation && (
            <Typography variant='body' bold>
              {organisation.address || '-'}
            </Typography>
          )}
          {!organisation && <Skeleton variant='rect' width={200} height={16} />}
        </Row>
        {/* city */}
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 160 : 180, flexShrink: 0 }}
          >
            {global._('OrganisationDetail.City')}
          </Typography>
          {organisation && (
            <Typography variant='body' bold>
              {organisation.city || '-'}
            </Typography>
          )}
          {!organisation && <Skeleton variant='rect' width={150} height={16} />}
        </Row>
        {/* postalCode */}
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 160 : 180, flexShrink: 0 }}
          >
            {global._('OrganisationDetail.PostalCode')}
          </Typography>
          {organisation && (
            <Typography variant='body' bold>
              {organisation.postalCode || '-'}
            </Typography>
          )}
          {!organisation && <Skeleton variant='rect' width={100} height={16} />}
        </Row>
        {/* dutyPhone */}
        {partnerOrg && (
          <Row style={{ marginTop: 11 }}>
            <Typography
              variant='body'
              faded
              style={{ width: isMobile ? 160 : 180, flexShrink: 0 }}
            >
              {global._('OrganisationDetail.DutyPhone')}
            </Typography>
            <Typography variant='body' bold>
              {organisation.dutyPhone || '-'}
            </Typography>
          </Row>
        )}
        {/* email */}
        {partnerOrg && (
          <Row style={{ marginTop: 11 }}>
            <Typography
              variant='body'
              faded
              style={{ width: isMobile ? 160 : 180, flexShrink: 0 }}
            >
              {global._('Common.Email')}
            </Typography>
            <Typography variant='body' bold>
              {organisation.email || '-'}
            </Typography>
          </Row>
        )}
        {/* website */}
        {partnerOrg && (
          <Row style={{ marginTop: 11 }}>
            <Typography
              variant='body'
              faded
              style={{ width: isMobile ? 160 : 180, flexShrink: 0 }}
            >
              {global._('Common.Website')}
            </Typography>
            <Typography
              variant='body'
              bold
              style={{ cursor: 'pointer' }}
              onClick={() => window.open(organisation.website, '_blank')}
            >
              {organisation.website || '-'}
            </Typography>
          </Row>
        )}
        {/* phone */}
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 160 : 180, flexShrink: 0 }}
          >
            {global._('OrganisationDetail.Overview.Phone')}
          </Typography>
          {organisation && (
            <Typography variant='body' bold>
              {organisation.phone || '-'}
            </Typography>
          )}
          {!organisation && <Skeleton variant='rect' width={100} height={16} />}
        </Row>
        {/* reference */}
        <Row style={{ marginTop: 24 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 160 : 180, flexShrink: 0 }}
          >
            {global._('OrganisationDetail.Reference')}
          </Typography>
          {organisation && (
            <Typography variant='body' bold>
              {organisation.reference || '-'}
            </Typography>
          )}
          {!organisation && <Skeleton variant='rect' width={100} height={16} />}
        </Row>
      </div>
    )
  } else if (navigation === OrganisationNavigation.INFORMATION) {
    navigationContent = (
      <div style={{ marginTop: -16, width: '100%' }}>
        <Formik
          initialValues={{
            caseNotificationEmail,
            quoteRequestEmail,
            name,
            organisationNumber,
            address,
            postalCode,
            city,
            reference,
            phone,
            dutyPhone,
            email,
            website,
            directionsURL,
            additionalInfo,
            invoiceAddress: hasSeparateInvoiceAddress
              ? invoiceAddress
              : address,
            invoicePostalCode: hasSeparateInvoiceAddress
              ? invoicePostalCode
              : postalCode,
            invoiceCity: hasSeparateInvoiceAddress ? invoiceCity : city,
            hasSeparateInvoiceAddress,
            visitingAddress: hasSeparateVisitingAddress
              ? visitingAddress
              : address,
            visitingPostalCode: hasSeparateVisitingAddress
              ? visitingPostalCode
              : postalCode,
            visitingCity: hasSeparateVisitingAddress ? visitingCity : city,
            hasSeparateVisitingAddress
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setTouched,
            setFieldValue
          }) => (
            <Form style={{ width: '100%' }}>
              {isPartner && isMyOrganisation && (
                <div className={classes.section}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant='filled'
                          name='caseNotificationEmail'
                          label={global._(
                            'OrganisationDetail.CaseNotificationEmail'
                          )}
                          value={values.caseNotificationEmail}
                          error={errors.caseNotificationEmail}
                          helperText={
                            errors.caseNotificationEmail
                              ? errors.caseNotificationEmail
                              : global._('Common.Required')
                          }
                          onClick={handleBlur}
                          onChange={handleChange}
                          disabled={!isEligibleToEdit}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant='filled'
                          name='quoteRequestEmail'
                          label={global._(
                            'OrganisationDetail.QuoteNotificationEmail'
                          )}
                          value={values.quoteRequestEmail}
                          error={errors.quoteRequestEmail}
                          helperText={
                            errors.quoteRequestEmail
                              ? errors.quoteRequestEmail
                              : global._('Common.Required')
                          }
                          onClick={handleBlur}
                          onChange={handleChange}
                          disabled={!isEligibleToEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              )}
              <div className={classes.section}>
                {partnerOrg && (
                  <Alert style={{ marginBottom: 20 }}>
                    {global._('OrganisationDetail.DataVisibilityInfo')}
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        variant='filled'
                        label={global._('OrganisationDetail.Name')}
                        value={values.name}
                        name='name'
                        error={errors.name}
                        helperText={
                          errors.name
                            ? errors.name
                            : global._('Common.Required')
                        }
                        onClick={handleBlur}
                        onChange={handleChange}
                        disabled={!isEligibleToEdit}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        variant='filled'
                        label={global._(
                          'OrganisationDetail.OrganisationNumber'
                        )}
                        value={values.organisationNumber}
                        name='organisationNumber'
                        error={errors.organisationNumber}
                        helperText={
                          errors.organisationNumber
                            ? global._('OrganisationDetail.OrgNumberNotValid')
                            : undefined // global._('Common.NotRequired')
                        }
                        onClick={handleBlur}
                        onChange={handleChange}
                        disabled={!isEligibleToEdit}
                      />
                    </FormControl>
                  </Grid>
                  {!isPartner && (
                    <>
                      <Grid item md={12} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            variant='filled'
                            label={global._('OrganisationDetail.Address')}
                            value={values.address}
                            name='address'
                            helperText={global._('Common.NotRequired')}
                            onClick={handleBlur}
                            onChange={handleChange}
                            disabled={!isEligibleToEdit}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            variant='filled'
                            label={global._('OrganisationDetail.PostalCode')}
                            name='postalCode'
                            value={values.postalCode}
                            helperText={global._('Common.NotRequired')}
                            onClick={handleBlur}
                            onChange={handleChange}
                            disabled={!isEligibleToEdit}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            variant='filled'
                            label={global._('OrganisationDetail.City')}
                            name='city'
                            value={values.city}
                            helperText={global._('Common.NotRequired')}
                            onClick={handleBlur}
                            onChange={handleChange}
                            disabled={!isEligibleToEdit}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        variant='filled'
                        label={global._('OrganisationDetail.Reference')}
                        name='reference'
                        value={values.reference}
                        error={errors.reference}
                        helperText={global._('Common.Required')}
                        onClick={handleBlur}
                        onChange={handleChange}
                        disabled={!isEligibleToEdit}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <PhoneNumberField
                        variant='filled'
                        label={global._('OrganisationDetail.Phone')}
                        name='phone'
                        value={values.phone}
                        error={errors.phone}
                        helperText={
                          errors.phone ? errors.phone : undefined // global._('Common.Required')
                        }
                        onClick={handleBlur}
                        onChange={value => setFieldValue('phone', value)}
                        disabled={!isEligibleToEdit}
                      />
                    </FormControl>
                  </Grid>
                  {partnerOrg && (
                    <>
                      <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                          <PhoneNumberField
                            variant='filled'
                            label={global._('OrganisationDetail.DutyPhone')}
                            name='dutyPhone'
                            value={values.dutyPhone}
                            error={errors.dutyPhone}
                            helperText={
                              errors.dutyPhone
                                ? errors.dutyPhone
                                : global._(
                                    'OrganisationDetail.DutyPhoneHelpText'
                                  )
                            }
                            onClick={handleBlur}
                            onChange={value =>
                              setFieldValue('dutyPhone', value)
                            }
                            disabled={!isEligibleToEdit}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            variant='filled'
                            name='email'
                            label={global._('Common.Email')}
                            value={values.email}
                            error={errors.email}
                            helperText={
                              errors.email
                                ? errors.email
                                : global._('Common.Required')
                            }
                            onClick={handleBlur}
                            onChange={handleChange}
                            disabled={!isEligibleToEdit}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            variant='filled'
                            name='website'
                            label={global._('Common.Website')}
                            value={values.website}
                            error={errors.website}
                            helperText={
                              errors.website ? errors.website : undefined // global._('Common.NotRequired')
                            }
                            onClick={handleBlur}
                            onChange={handleChange}
                            disabled={!isEligibleToEdit}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            variant='filled'
                            name='directionsURL'
                            label={global._('OrganisationDetail.DirectionsURL')}
                            value={values.directionsURL}
                            error={errors.directionsURL}
                            helperText={
                              errors.directionsURL
                                ? errors.directionsURL
                                : undefined // global._('Common.NotRequired')
                            }
                            onClick={handleBlur}
                            onChange={handleChange}
                            disabled={!isEligibleToEdit}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
              <div className={classes.section}>
                {isPartner && (
                  <div>
                    <AddressFields
                      classes={classes}
                      title={global._('OrganisationDetail.PostalAddress')}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isEligibleToEdit={isEligibleToEdit}
                      handleChange={handleChange}
                      address={address}
                      city={city}
                      postalCode={postalCode}
                      addressFieldName='address'
                      cityFieldName='city'
                      postalCodeFieldName='postalCode'
                    />
                    <AddressFields
                      classes={classes}
                      dependencyKey='hasSeparateVisitingAddress'
                      title={global._('OrganisationDetail.VisitingAddress')}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isEligibleToEdit={isEligibleToEdit}
                      handleChange={handleChange}
                      address={visitingAddress}
                      city={visitingCity}
                      postalCode={visitingPostalCode}
                      addressFieldName='visitingAddress'
                      cityFieldName='visitingCity'
                      postalCodeFieldName='visitingPostalCode'
                    />
                  </div>
                )}
                <AddressFields
                  classes={classes}
                  dependencyKey='hasSeparateInvoiceAddress'
                  title={global._('OrganisationDetail.InvoiceAdress')}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isEligibleToEdit={isEligibleToEdit}
                  handleChange={handleChange}
                  address={invoiceAddress}
                  city={invoiceCity}
                  postalCode={invoicePostalCode}
                  addressFieldName='invoiceAddress'
                  cityFieldName='invoiceCity'
                  postalCodeFieldName='invoicePostalCode'
                />
                {isPartner && (
                  <Grid item xs={12}>
                    <Typography
                      bold
                      variant='title'
                      style={{ marginBottom: 15 }}
                    >
                      {global._('OrganisationDetail.AdditionalInformation')}
                    </Typography>
                    <FormControl fullWidth>
                      <TextField
                        variant='filled'
                        name='additionalInfo'
                        value={values.additionalInfo || ''}
                        onChange={handleChange}
                        onClick={handleBlur}
                        multiline
                        rows={4}
                        helperText={global._(
                          'OrganisationDetail.AdditionalInformationHelpText'
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
              </div>
              <Grid item container md={12} xs={12} direction='row-reverse'>
                {isEligibleToEdit && (
                  <Button
                    variant='primary'
                    style={{ minWidth: 136, marginTop: 20 }}
                    disabled={
                      Object.keys(touched).length === 0 ||
                      Object.keys(errors).length ||
                      !isEligibleToEdit
                    }
                    onClick={() => {
                      handleSave(values)
                      setTouched({})
                    }}
                  >
                    {global._('Common.Save')}
                  </Button>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    )
  } else if (navigation === OrganisationNavigation.LOGOTYPE) {
    navigationContent = (
      <UploadLogoType
        user={user}
        uploadText={global._('OrganisationDetail.UploadLogotype')}
        setError={setError}
      />
    )
  } else if (navigation === OrganisationNavigation.NOTES) {
    navigationContent = (
      <TextField
        multiline
        style={{
          width: isMobile ? '100%' : '50%'
        }}
        rows={10}
        id='extraInfo'
        variant='filled'
        defaultValue={organisation ? organisation.extraInfo : ''}
        onBlur={e => {
          if (!isMobile && e.target.value !== organisation.extraInfo) {
            handleSave({ extraInfo: e.target.value })
          }
        }}
        helperText={global._('OrganisationDetail.ExtraInfoHelperText')}
      />
    )
  } else if (navigation === OrganisationNavigation.AGREEMENTS) {
    navigationContent = (
      <Column align='flex-start' style={{ margin: '-20px' }}>
        <Row
          flex
          style={{
            width: '100%',
            padding: 20,
            borderBottom: '1px solid var(--color-divider-light)'
          }}
        >
          <Modal
            open={isServiceAgreementInformationModalOpen}
            title={global._('OrganisationDetail.ServiceAgreement')}
            onClose={() => setIsServiceAgreementInformationModalOpen(false)}
            content={global._(
              'OrganisationDetail.ServiceAgreementInformationModalContent'
            )}
            buttons={
              <Button
                onClick={() => setIsServiceAgreementInformationModalOpen(false)}
              >
                {global._('Common.Close')}
              </Button>
            }
          />
          <Typography
            variant='subtitleSmall'
            style={{
              width: 120,
              marginRight: 90
            }}
          >
            {global._('OrganisationDetail.ServiceAgreement')}
          </Typography>
          <Switch
            disableRipple={!editAgreementsAndExtraInfo}
            color='default'
            checked={organisation && organisation.hasServiceAgreement}
            onChange={e => {
              if (editAgreementsAndExtraInfo) {
                handleSave({ hasServiceAgreement: e.target.checked })
              }
            }}
          />
          {!isMobile && (
            <Icon
              style={{ marginLeft: 'auto' }}
              className={classes.infoIcon}
              onClick={() => setIsServiceAgreementInformationModalOpen(true)}
            >
              info
            </Icon>
          )}
        </Row>
        <Row
          flex
          style={{
            width: '100%',
            padding: 20,
            borderBottom: '1px solid var(--color-divider-light)'
          }}
        >
          <Modal
            open={isDutyAgreementInformationModalOpen}
            title={global._('OrganisationDetail.DutyAgreement')}
            onClose={() => setIsDutyAgreementInformationModalOpen(false)}
            content={global._(
              'OrganisationDetail.DutyAgreementInformationModalContent'
            )}
            buttons={
              <Button
                onClick={() => setIsDutyAgreementInformationModalOpen(false)}
              >
                {global._('Common.Close')}
              </Button>
            }
          />
          <Typography
            variant='subtitleSmall'
            style={{
              width: 120,
              marginRight: 90
            }}
          >
            {global._('OrganisationDetail.DutyAgreement')}
          </Typography>
          <Switch
            disableRipple={!editAgreementsAndExtraInfo}
            color='default'
            checked={organisation && organisation.hasDutyAgreement}
            onChange={e => {
              if (editAgreementsAndExtraInfo) {
                handleSave({ hasDutyAgreement: e.target.checked })
              }
            }}
          />
          {!isMobile && (
            <Icon
              style={{ marginLeft: 'auto' }}
              className={classes.infoIcon}
              onClick={() => setIsDutyAgreementInformationModalOpen(true)}
            >
              info
            </Icon>
          )}
        </Row>
        <Row style={{ padding: 20, width: '100%' }}>
          <Button
            variant='outlined'
            style={{ width: isMobile ? '100%' : undefined }}
            onClick={() => {
              const url = `/documents?organisationId=${organisation.id}&type=${DocumentTypes.ServiceAgreement}`
              if (isMobile) {
                history.push(url)
              } else {
                window.open(url)
              }
            }}
          >
            {global._('OrganisationDetail.ShowServiceAgreements')}
          </Button>
        </Row>
      </Column>
    )
  } else if (navigation === OrganisationNavigation.SERVICES) {
    const serviceRows = Object.keys(OrganisationServices).map(service => {
      const locKey = `OrganisationDetail.Services.ModalContent.${service}.${user.roles[0]}`

      return {
        key: service,
        title: global._(`Services.${service}`),
        url: ServiceURLs[service],
        modalContent: global._(locKey) !== locKey ? global._(locKey) : false
      }
    })

    navigationContent = (
      <div>
        {serviceRows.map((service, index) => {
          const isServiceActive = checkedServices[service.key]

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: isMobile ? '13px 10px 13px 20px' : '13px 20px',
                borderBottom:
                  serviceRows.length - 1 === index && !isMobile
                    ? ''
                    : '1px solid var(--color-divider-light)'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isMobile ? 'space-between' : undefined,
                  flexDirection:
                    isCustomerAdmin && isMobile ? 'row-reverse' : undefined
                }}
              >
                {(isCustomerAdmin || isSupport) && (
                  <Indicator variant={isServiceActive ? 'green' : 'grey'} />
                )}
                <div style={{ width: 220 }}>
                  <Typography variant='subtitleSmall'>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => service.url && history.push(service.url)}
                    >
                      {service.title}
                    </span>
                  </Typography>
                </div>
                {isCustomerAdmin && !isMobile && (
                  <Typography
                    variant='body'
                    faded
                    dangerouslySetInnerHTML={{
                      __html: isServiceActive
                        ? global._(
                            'OrganisationDetail.Services.ServiceActivated'
                          )
                        : global._(
                            'OrganisationDetail.Services.ServiceDeactivated',
                            [ContactPageURL]
                          )
                    }}
                  />
                )}
                {user.roles.includes(UserRoles.PartnerAdmin) && (
                  <FormGroup>
                    <FormControlLabel
                      style={{ margin: 0 }}
                      control={
                        <Switch
                          style={{ margin: 0 }}
                          checked={isServiceActive}
                          onChange={() => {
                            updateCheckedServices({
                              ...checkedServices,
                              [service.key]: !isServiceActive
                            })
                          }}
                        />
                      }
                    />
                  </FormGroup>
                )}
                {service.key === 'BOOKING' && isServiceActive && (
                  <AsyncSelect
                    isSearchable
                    isClearable={false}
                    value={bookingBuilding}
                    style={{ marginLeft: 50, width: isMobile ? 200 : 500 }}
                    labelKey='label'
                    name='organisation'
                    placeholder='Förening/Fastighet/Gata i Bokatvattid.se'
                    onChange={async opt => {
                      if (opt) {
                        await axios.post(`/v1/booking/setbuilding`, {
                          organisationId: organisation.id,
                          buildingId: opt.value.id,
                          buildingName: opt.label
                        })
                        setBookingBuilding({
                          id: opt.value.id,
                          label: opt.label
                        })
                      }
                    }}
                    url='/v1/booking/propertysearch?term={{input}}'
                  />
                )}
              </div>
              {service.modalContent && !isMobile && (
                <div>
                  <Icon
                    className={classes.infoIcon}
                    onClick={() => setInfoModalObject(service)}
                  >
                    info
                  </Icon>
                  {infoModalObject && (
                    <Modal
                      onClose={() => setInfoModalObject(null)}
                      title={infoModalObject.title}
                      content={<div>{infoModalObject.modalContent}</div>}
                      buttons={
                        <Button
                          variant='none'
                          onClick={() => setInfoModalObject(null)}
                        >
                          {global._('Common.Close')}
                        </Button>
                      }
                    />
                  )}
                </div>
              )}
            </div>
          )
        })}
        {isCustomerAdmin && isMobile && (
          <Alert style={{ margin: 20, alignItems: 'center' }}>
            <Typography
              variant='body'
              dangerouslySetInnerHTML={{
                __html: global._(
                  'OrganisationDetail.Services.ServiceInfoMobile',
                  [ContactPageURL]
                )
              }}
            />
          </Alert>
        )}
      </div>
    )
  } else if (navigation === OrganisationNavigation.PRODUCTS) {
    navigationContent = (
      <div style={{ margin: -20 }}>
        <Products organisationId={id} />
      </div>
    )
  } else if (navigation === OrganisationNavigation.SHIPPING) {
    navigationContent = (
      <div style={{ margin: -20 }}>
        <ShippingOptions organisationId={id} />
      </div>
    )
  }

  const Content = (
    <>
      {showDeleteOrganisationModal && (
        <Modal
          onClose={() => setShowDeleteOrganisationModal(false)}
          title={global._('OrganisationDetail.RemoveOrganisation')}
          content={
            <div style={{ textAlign: 'left' }}>
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='body'
              >
                {global._('OrganisationDetail.ConfirmRemoveOrganisation')}
              </Typography>
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='subtitleSmall'
              >
                {global._('OrganisationDetail.RemoveOrganisationInfo')}
              </Typography>
              {deleteErrorMsg && (
                <Notification type='error' message={deleteErrorMsg} />
              )}
            </div>
          }
          buttons={[
            <Button
              variant='secondary'
              onClick={() => handleDeleteOrganisation()}
            >
              {global._('Delete')}
            </Button>,
            <Button
              variant='none'
              onClick={() => setShowDeleteOrganisationModal(false)}
            >
              {global._('Common.Cancel')}
            </Button>
          ]}
        />
      )}
      {saved && <SuccessMessage subtitle={global._('Common.ChangesSaved')} />}
      {navigationContent}
    </>
  )

  if (isMobile) {
    let detailButtons
    let infoButton
    if (navigation === OrganisationNavigation.NOTES) {
      detailButtons = (
        <Button
          variant='primary'
          onClick={() => {
            const { value } = document.getElementById('extraInfo')
            handleSave({ extraInfo: value })
          }}
        >
          {global._('Common.Save')}
        </Button>
      )
    } else if (navigation === OrganisationNavigation.AGREEMENTS) {
      infoButton = (
        <div onClick={() => setIsServiceAgreementInformationModalOpen(true)}>
          <Icon>info</Icon>
        </div>
      )
    } else if (
      navigation === OrganisationNavigation.SERVICES &&
      isCustomerAdmin
    ) {
      infoButton = (
        <div onClick={() => setServicesInfoModalOpen(true)}>
          <Icon>info</Icon>
        </div>
      )
    }
    const itemList = tabNavigationItems.map(item => ({
      title: item.label,
      icon: item.icon,
      onClick: () => {
        setNavigation(item.value)
      }
    }))

    const selectedNavigationItem = tabNavigationItems.find(
      item => item.value === navigation
    )
    if (!canViewMenu) {
      selectedNavigationItem.label = global._(
        'OrganisationDetail.OrganisationDetailTitle'
      )
    }

    return (
      <MobileContainer background='white'>
        {saved && <SuccessMessage subtitle={global._('Common.ChangesSaved')} />}
        <NavigationBar
          hidden={!!selectedNavigationItem}
          title={global._('OrganisationDetail.OrganisationDetailTitle')}
          backAction={() => history.push('/')}
        />
        <ScrollView id='scrollable-container' style={{ paddingBottom: 104 }}>
          <div style={{ padding: '0 20px' }}>
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ margin: '20px 0' }}
              />
            )}
            <div style={{ margin: '0 -20px' }}>
              {selectedNavigationItem && (
                <FullscreenModal
                  title={selectedNavigationItem.label}
                  rightActionItem={infoButton}
                  onClose={() =>
                    canViewMenu
                      ? setNavigation(OrganisationNavigation.MENU)
                      : history.push('/')
                  }
                  content={
                    <div
                      style={{
                        padding:
                          navigation === OrganisationNavigation.SERVICES
                            ? 0
                            : 20
                      }}
                    >
                      {navigationContent}
                    </div>
                  }
                  withTabBar
                  buttons={detailButtons}
                />
              )}
              <NavigationList itemList={itemList} />
            </div>
          </div>
        </ScrollView>
        {canRemoveOrganisation && (
          <div className={classes.buttonContainer}>
            <Button
              variant='secondary'
              onClick={() => setShowDeleteOrganisationModal(true)}
            >
              {global._('OrganisationDetail.RemoveOrganisation')}
            </Button>
          </div>
        )}
        <Modal
          open={showDeleteOrganisationModal}
          onClose={() => setShowDeleteOrganisationModal(false)}
          title={global._('OrganisationDetail.RemoveOrganisation')}
          content={
            <div style={{ textAlign: 'left' }}>
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='body'
              >
                {global._('OrganisationDetail.ConfirmRemoveOrganisation')}
              </Typography>
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='subtitleSmall'
              >
                {global._('OrganisationDetail.RemoveOrganisationInfo')}
              </Typography>
              {deleteErrorMsg && (
                <Notification type='error' message={deleteErrorMsg} />
              )}
            </div>
          }
          buttons={[
            <Button
              variant='secondary'
              onClick={() => handleDeleteOrganisation()}
            >
              {global._('Delete')}
            </Button>,
            <Button
              variant='none'
              onClick={() => setShowDeleteOrganisationModal(false)}
            >
              {global._('Common.Cancel')}
            </Button>
          ]}
        />
        {servicesInfoModalOpen && isCustomerAdmin && (
          <FullscreenModal
            title={global._('Common.Services')}
            higherZIndex
            padding
            onClose={() => setServicesInfoModalOpen(false)}
            content={
              <div>
                {isCustomerAdmin
                  ? global._(
                      'OrganisationDetail.Services.InfoModal.CUSTOMER_ADMIN'
                    )
                  : global._(
                      'OrganisationDetail.Services.InfoModal.PARTNER_ADMIN'
                    )}
              </div>
            }
          />
        )}
      </MobileContainer>
    )
  }

  return (
    <>
      <PageContentHeader
        breadcrumbItems={calculatedCrumbs}
        actionButtons={
          <>
            {canRemoveOrganisation && (
              <Button
                variant='secondary'
                style={{ marginLeft: 'auto', marginRight: 18 }}
                onClick={() => setShowDeleteOrganisationModal(true)}
              >
                {global._('OrganisationDetail.RemoveOrganisation')}
              </Button>
            )}
            {isSystemAdmin && !isMyOrganisation && (
              <div>
                <Button
                  variant='outlined'
                  onClick={() => history.push(`/admin/users/${id}`)}
                  style={{
                    marginRight:
                      organisation &&
                      organisation.type !== OrganisationTypes.Unbound
                        ? 18
                        : undefined
                  }}
                >
                  {global._('Widgets.organisations.Users')}
                </Button>
                {partnerOrg && (
                  <>
                    <Button
                      variant='outlined'
                      onClick={() => history.push(`/admin/partners/${id}`)}
                      style={{ marginRight: 18 }}
                    >
                      {global._('Widgets.organisations.Customers')}
                    </Button>
                    <Button
                      variant='secondary'
                      onClick={() => unboundOrganisation()}
                    >
                      {global._(
                        `Widgets.organisations.${
                          partnerOrg
                            ? 'RemovePartnerStatus'
                            : 'PartnerStatusRemoved'
                        }`
                      )}
                    </Button>
                  </>
                )}
              </div>
            )}
          </>
        }
      />
      {error && <Notification type='error' message={error} />}
      <Paper noPadding>
        {[
          UserRoles.SystemAdmin,
          UserRoles.PartnerAdmin,
          UserRoles.CustomerAdmin,
          UserRoles.Support
        ].some(r => user.roles.includes(r)) && (
          <TabNavigator
            items={tabNavigationItems}
            value={navigation}
            onChange={navigationItem => setNavigation(navigationItem.value)}
            itemStyle={{ padding: '8px 12px' }}
          />
        )}
        <div
          style={{
            padding:
              navigation === OrganisationNavigation.SERVICES ? '' : '24px 20px',
            width: '100%'
          }}
        >
          {Content}
        </div>
      </Paper>
    </>
  )
}

MyOrganisation.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired
}

const MyOrganisationWithStyles = injectSheet(styles)(MyOrganisation)
export default MyOrganisationWithStyles
