import React from 'react'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Skeleton } from '@material-ui/lab'
import Row from './Row'
import Column from './Column'
import Typography from './Typography'

const styles = {
  root: {
    paddingBottom: props => (props.hasAddButton ? 100 : 0),
    width: '100%'
  },
  row: {
    '&:last-child': {
      borderBottom: props =>
        props.removeLastBorder ? '0 !important' : undefined
    }
  },
  tr: {
    flexGrow: 1
  }
}

const NavigationList = props => {
  const { classes, itemList, skeletonize } = props

  return (
    <Column align='flex-start' justify='space-between' className={classes.root}>
      {itemList &&
        itemList.map((item, index) => {
          if (typeof item.visible !== 'undefined' && !item.visible) {
            return null
          }
          const key = `${item.name}-key-${index}`
          if (skeletonize) {
            return (
              <Row
                key={key}
                className={classes.row}
                style={{
                  width: '100%',
                  borderBottom: '1px solid var(--color-divider-light)',
                  padding: 20
                }}>
                <Skeleton
                  variant='circle'
                  width={20}
                  height={20}
                  style={{ flexShrink: 0 }}
                />
                <Skeleton
                  variant='rect'
                  width={Math.random() * 100 + 100}
                  height={20}
                  style={{ marginLeft: 24 }}
                />
                <Skeleton
                  variant='rect'
                  width={20}
                  height={20}
                  style={{ marginLeft: 'auto' }}
                />
              </Row>
            )
          }
          if (typeof item.visible !== 'undefined' && !item.visible) {
            return null
          }
          const RowContent = item.subTitle ? Column : Row
          if (item) {
            return (
              <Row
                key={key}
                align='center'
                justify='center'
                className={classes.row}
                style={{
                  width: '100%',
                  cursor: 'pointer',
                  borderBottom: item.fullBorder
                    ? '1px solid var(--color-divider-light)'
                    : undefined
                }}>
                <Column justify='center'>
                  <Icon
                    style={{
                      marginLeft: 20,
                      marginRight: 20,
                      fontSize: 22,
                      color: item.iconColor
                        ? item.iconColor
                        : [
                            item.darkIcon
                              ? 'var(--color-black)'
                              : 'var(--color-middle-grey)'
                          ]
                    }}>
                    {item.icon}
                  </Icon>
                </Column>
                <RowContent
                  className={classes.tr}
                  onClick={item.onClick}
                  style={{
                    borderBottom: item.fullBorder
                      ? undefined
                      : '1px solid var(--color-divider-light)',
                    paddingTop: item.smallerPadding ? 17 : 25,
                    paddingBottom: item.smallerPadding ? 17 : 25
                  }}>
                  <Row justify='space-between' style={{ width: '100%' }}>
                    <Column align='flex-start'>
                      <Row>
                        <Typography
                          variant='body'
                          bold
                          style={{ color: item.textColor || undefined }}>
                          {item.title}
                        </Typography>
                      </Row>
                      <Row>
                        {item.subTitle && (
                          <Typography
                            variant='body2'
                            style={{ color: '#A3A3A3' }}>
                            {item.subTitle ? item.subTitle : ''}
                          </Typography>
                        )}
                      </Row>
                    </Column>
                    <Column>
                      <Icon
                        style={{
                          fontSize: 20,
                          marginRight: 20,
                          color: item.textColor || undefined
                        }}>
                        {item.rightIcon || 'keyboard_arrow_right'}
                      </Icon>
                    </Column>
                  </Row>
                </RowContent>
              </Row>
            )
          }
        })}
    </Column>
  )
}

NavigationList.propTypes = {
  classes: PropTypes.object.isRequired,
  itemList: PropTypes.array.isRequired,
  skeletonize: PropTypes.bool
}
NavigationList.defaultProps = {
  skeletonize: false
}

const NavigationListWithStyles = injectSheet(styles)(NavigationList)
export default NavigationListWithStyles
