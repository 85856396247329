import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { Grid, Icon, TextField } from '@material-ui/core'
import queryString from 'querystring'
import { Search } from '@material-ui/icons'
import moment from 'moment'
import PageContentHeader from '../../components/PageContentHeader'
import Typography from '../../components/Typography'
import useWindowDimensions from '../../utils/useWindowDimensions'
import Button from '../../components/Button'
import ObjectList from '../../components/ObjectList'
import {
  DeviceTypes,
  InstructionsVideoIds,
  UserRoles
} from '../../utils/constants'
import DevicesMobile from './DevicesMobile'
import AsyncSelect from '../../components/AsyncSelect'
import Notification from '../../common/Notification'
import AddDeviceModal from './AddDeviceModal'
import SuccessMessage from '../../components/SuccessMessage'

const styles = {
  root: {},
  input: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 20
    }
  },
  submitBtnContainer: {
    marginTop: 20
  },
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)',
    cursor: 'pointer'
  },
  iconEmpty: {
    backgroundColor: 'var(--color-white)'
  }
}

const Devices = props => {
  const { classes, history, breadcrumbItems, signout, user } = props

  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false)
  const [error, setError] = useState(false)
  const [devices, setDevices] = useState(false)
  const [canFetchMoreDevices, setCanFetchMoreDevices] = useState(false)
  const [selectedOrganisation, setSelectedOrganisation] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState(false)
  const [showAddDeviceSuccessMessage, setShowAddDeviceSuccessMessage] =
    useState(false)
  const [showDeleteDeviceSuccessMessage, setShowDeleteDeviceSuccessMessage] =
    useState(false)
  const [isLoadingDevices, setIsLoadingDevices] = useState(false)
  const [expandedRows, setExpandedRows] = useState([])
  const [filter, setFilter] = useState({
    orderBy: 'name',
    order: 'asc',
    search: history.location.state && (history.location.state.search || ''),
    limit: 25,
    offset: 0,
    organisationId: '',
    propertyId: ''
  })
  const [activeFilter, setActiveFilter] = useState(false)

  let isPartnerAdmin = false
  let isSystemAdmin = false
  if (user && Array.isArray(user.roles)) {
    if (user.roles.includes(UserRoles.PartnerAdmin)) {
      isPartnerAdmin = true
    }
    if (user.roles.includes(UserRoles.SystemAdmin)) {
      isSystemAdmin = true
    }
  }

  const fetchDevices = async () => {
    setIsLoadingDevices(true)

    const selectedFilter = { ...filter }

    const query = queryString.stringify(selectedFilter)

    try {
      const { data } = await axios.get(`/v1/devices?${query}`)
      setCanFetchMoreDevices(data.length === filter.limit)

      if (data) {
        if (filter.offset === 0) {
          setDevices(data)
        } else {
          setDevices([...devices, ...data])
        }
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }

    setIsLoadingDevices(false)
  }

  const fetchMoreDevices = () => {
    const offset = filter.offset + filter.limit
    const updatedFilter = { ...filter, offset }

    setIsLoadingDevices(true)

    setFilter(updatedFilter)
    setActiveFilter(true)
  }

  useEffect(() => {
    if (
      localStorage.getItem('deviceDeleted') &&
      localStorage.getItem('deviceDeleted') !== 'null'
    ) {
      setShowDeleteDeviceSuccessMessage(true)
    }
  }, [])

  useEffect(() => {
    fetchDevices()
  }, [filter])

  useEffect(() => {
    history.replace()
  }, [])

  const handleFilter = name => e => {
    const aFilter = { ...filter }

    let value

    if (name === 'organisationId') {
      value = (e && e.value && e.value.id) || ''
      setSelectedOrganisation(e && e.value ? e : '')
    } else if (name === 'propertyId') {
      value = (e && e.value && e.value.id) || ''
      setSelectedProperty(e && e.value ? e : '')
    } else {
      value = e.target.value
    }

    aFilter[name] = value

    aFilter.offset = 0

    setFilter(aFilter)
    setActiveFilter(true)
  }

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem('deviceDeleted', null)
    }, 3000)
  }, [showDeleteDeviceSuccessMessage])

  const { isMobile } = useWindowDimensions()

  const columns = [
    {
      key: 'icon',
      localizationKey: 'Blank',
      sortingDisabled: true,
      style: { width: 70 },
      format: (objVal, obj) => (
        <Icon className={`${classes.icon}`}>
          {obj.uuid ? 'router' : 'lock'}
        </Icon>
      ),
      clickable: true
    },
    {
      key: 'type',
      localizationKey: 'Type',
      sortingDisabled: true,
      style: { width: 110 },
      hiddenOnSm: true,
      clickable: true,
      format: (objVal, obj) =>
        obj.uuid
          ? global._(`Device.DeviceTypes.${DeviceTypes.Device}`)
          : global._(`Device.DeviceTypes.${DeviceTypes.Lock}`)
    },
    {
      key: 'name',
      localizationKey: 'Name',
      clickable: true
    },
    {
      key: 'uuid',
      localizationKey: 'ID',
      clickable: true,
      format: val => (
        <Typography block ellipsis>
          {val || '-'}
        </Typography>
      )
    },
    {
      key: 'stats',
      sortingDisabled: true,
      localizationKey: 'Common.Status',
      clickable: true,
      style: { width: 120 },
      format: (stats, obj) =>
        obj.uuid ? global._(stats ? 'Device.Online' : 'Device.Offline') : '-'
    },
    {
      key: 'organisationName',
      localizationKey: 'Common.Organisation',
      clickable: true,
      sortingKey: 'organisationName',
      style: { width: 200 },
      longText: true,
      format: (objVal, obj, parentObj) =>
        (parentObj && parentObj.organisationName) || (objVal && objVal)
    },
    isSystemAdmin
      ? {
          key: 'partnerName',
          localizationKey: 'Common.Partner',
          clickable: true,
          sortingKey: 'partnerName',
          style: { width: 200 },
          longText: true,
          format: (objVal, obj, parentObj) =>
            (parentObj && parentObj.partnerName) || (objVal && objVal)
        }
      : null,
    {
      key: 'propertyName',
      localizationKey: 'Common.Property',
      clickable: true,
      sortingKey: 'propertyName',
      style: { width: 200 },
      hiddenOnSm: true,
      longText: true,
      format: (objVal, obj, parentObj) =>
        (parentObj && parentObj.propertyName) || (objVal && objVal)
    },
    {
      key: 'createdAt',
      localizationKey: 'Device.CreatedAtSingle',
      clickable: true,
      style: { width: 180 },
      format: date => date && moment(date).format('YYYY-MM-DD HH:mm')
    },
    {
      key: '2',
      localizationKey: 'Blank',
      hiddenFor: obj => obj.locks && obj.locks.length > 0 && obj.uuid,
      clickable: true,
      style: { width: 50 },
      format: (objVal, obj) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Icon>navigate_next</Icon>
        </div>
      )
    }
  ]

  const nameFilterField = () => (
    <Grid item xs={12} md={4}>
      <TextField
        name='name'
        className={classes.input}
        value={filter.search}
        label={global._('Filter.ByNameAndId')}
        variant='filled'
        onChange={handleFilter('search')}
        InputProps={{
          endAdornment: <Search style={{ cursor: 'pointer' }} />
        }}
      />
    </Grid>
  )

  const filterFields = () => (
    <Grid container spacing={3} style={{ marginBottom: 1 }}>
      {!isMobile && nameFilterField()}
      <Grid item xs={12} md={4}>
        <AsyncSelect
          isSearchable
          helpText={global._('Filter.ByOrganisation')}
          value={selectedOrganisation}
          labelKey='name'
          name='organisation'
          placeholder={global._('Common.SelectOrganisation')}
          onChange={handleFilter('organisationId')}
          url='/v1/organisations?search={{input}}&orderBy=name&order=asc&ifHas=devices'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <AsyncSelect
          isSearchable
          key={`properties-${
            selectedOrganisation &&
            selectedOrganisation.value &&
            selectedOrganisation.value.id
          }`}
          helpText={global._('Filter.ByProperty')}
          value={selectedProperty}
          labelKey='name'
          name='property'
          isClearable
          placeholder={global._('Filter.Property')}
          onChange={handleFilter('propertyId')}
          cacheOptions={false}
          url={`/v1/properties?query={{input}}&orderBy=name&order=asc&organisationId=${
            (selectedOrganisation && selectedOrganisation.value.id) || ''
          }`}
        />
      </Grid>
    </Grid>
  )

  if (!isPartnerAdmin && !isSystemAdmin) {
    history.push('/')
  }

  const lang = global.getLanguage()

  return (
    <>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      {showAddDeviceModal && (
        <AddDeviceModal
          setShowAddDeviceSuccessMessage={setShowAddDeviceSuccessMessage}
          fetchDevices={fetchDevices}
          setShowAddDeviceModal={setShowAddDeviceModal}
          isMobile={isMobile}
        />
      )}
      {showAddDeviceSuccessMessage ? (
        <SuccessMessage subtitle={global._('Devices.DeviceAdded')} />
      ) : null}
      {showDeleteDeviceSuccessMessage ? (
        <SuccessMessage subtitle={global._('Devices.DeviceDeleted')} />
      ) : null}
      {isMobile ? (
        <DevicesMobile
          error={error}
          signout={signout}
          history={history}
          user={user}
          filterFields={filterFields}
          devices={devices}
          filter={filter}
          noResultsMessage={
            activeFilter
              ? global._('Devices.NoDevicesMatchFilter')
              : global._('Devices.NoDevicesFound')
          }
          nameFilterField={nameFilterField}
          setShowAddDeviceModal={setShowAddDeviceModal}
          canFetchMoreDevices={canFetchMoreDevices}
          fetchMoreDevices={fetchMoreDevices}
          isLoadingDevices={isLoadingDevices}
        />
      ) : (
        <div className={classes.root}>
          <PageContentHeader
            breadcrumbItems={breadcrumbItems}
            actionButtons={
              <Button
                variant='primary'
                onClick={() => setShowAddDeviceModal(true)}
                style={{ marginLeft: 'auto' }}
              >
                {global._('Device.AddDevice')}
              </Button>
            }
          />
          <ObjectList
            sectionTitle={global._('Device.DevicesAndLocks')}
            infoDialogTitle={global._('Devices.InfoDialogTitle')}
            infoDialogContent={
              <div style={{ textAlign: 'left' }}>
                <Typography
                  style={{ display: 'block', paddingBottom: 12 }}
                  variant='body'
                >
                  {global._('Devices.InfoDialogContent')}
                </Typography>
              </div>
            }
            instructionsVideoId={InstructionsVideoIds[lang].ADMIN.DEVICES}
            noResultsMessage={
              activeFilter
                ? global._('Devices.NoDevicesMatchFilter')
                : global._('Devices.NoDevicesFound')
            }
            columns={columns}
            objects={devices}
            history={history}
            forwardArrow
            expandable
            expandableObjectsSlug='locks'
            expandableObjectsPermalink={obj =>
              `admin/devices/${obj.organisationId}/locks`
            }
            filterFields={filterFields()}
            objectPermalink='admin/devices'
            setFilter={setFilter}
            filter={filter}
            fetchMoreObjects={fetchMoreDevices}
            canFetchMoreObjects={canFetchMoreDevices}
            isLoadingObjects={isLoadingDevices}
            expandedRows={expandedRows}
            setExpandedRows={setExpandedRows}
            actionButtons={(objId, obj) => (
              <Button
                variant='secondary'
                onClick={() => history.push(`/admin/devices/${objId}`)}
                style={{ marginLeft: 'auto' }}
              >
                {global._('Devices.ToDevice')}
              </Button>
            )}
          />
        </div>
      )}
    </>
  )
}

Devices.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array,
  signout: PropTypes.func.isRequired
}

Devices.defaultProps = {
  breadcrumbItems: []
}

const DevicesWithStyles = injectSheet(styles)(Devices)
export default DevicesWithStyles
