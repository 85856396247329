import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'querystring'
import Icon from '@material-ui/core/Icon'
import { Tooltip } from '@material-ui/core'
import { useLastLocation } from 'react-router-last-location'
import TabNavigator from 'src/components/TabNavigator'
import UserNameTooltip from 'src/components/UserNameTooltip'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import FullscreenModal from 'src/components/FullscreenModal'
import PageContentHeader from 'src/components/PageContentHeader'
import ObjectList from 'src/components/ObjectList'
import {
  CaseInvoiceStatus,
  CaseListUrlTypes,
  CaseStatus,
  OrderStatus,
  CaseTypes,
  UserRoles,
  OrganisationTypes,
  OrganisationServices,
  InstructionsVideoIds
} from 'src/utils/constants'
import Button from 'src/components/Button'
import Notification from 'src/common/Notification'
import {
  mapCaseStatus,
  mapCaseInvoiceStatus,
  mapCaseIcon,
  defaultCasesFilter,
  formatShallowDate,
  getLocalFilter,
  setLocalFilter,
  clearSelectedOrg,
  checkIfOrgHasAccessToScope,
  hasActivePaymentMethod
} from 'src/utils/helpers'
import Paper from 'src/components/Paper'
import { Clock } from '@material-ui/pickers'
import Filter from './Filter'
import CasesMobile from './CasesMobile'
import SelectAKeyModal from '../Keys/Order/SelectAKeyModal'

const styles = {
  root: {},
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)',
    cursor: 'pointer'
  },
  iconNew: {
    backgroundColor: 'var(--color-primary-tone-3) !important',
    color: 'var(--color-text)'
  }
}

const LIMIT = 25

/* Map url /cases/:type to case types */
const CaseTypeMap = {
  default: [
    CaseTypes.Key,
    CaseTypes.Service,
    CaseTypes.Support,
    CaseTypes.Relocation,
    CaseTypes.Other
  ],
  claims: [CaseTypes.Claim],
  feedback: [CaseTypes.Feedback],
  orders: [CaseTypes.Order]
}

/* used in CaseDetails(Mobile) to get "Go back" or breadcrumbs url */
export const caseTypeUrlMap = () => {
  let urlParams = window.location.search

  if (window.location.pathname.startsWith('/orders')) {
    return `/orders${window.location.search}`
  }

  urlParams = urlParams && urlParams.replace('?', '')

  const urlParamsObj = queryString.parse(urlParams)

  if (Object.keys(urlParamsObj).length !== 0) {
    if (urlParamsObj.intrinsicType) {
      if (Array.isArray(urlParamsObj.intrinsicType)) {
        return CaseListUrlTypes.DEFAULT
      }
      return CaseListUrlTypes[urlParamsObj.intrinsicType]
    }
    return CaseListUrlTypes.UNREAD
  }

  return '/cases'
}

const Cases = props => {
  const {
    classes,
    user,
    history,
    breadcrumbItems,
    location,
    match,
    selectedOrganisation
  } = props
  const lastLocation = useLastLocation()

  const caseListType = match.params.type && match.params.type.toLowerCase()
  const intrinsicTypes = CaseTypeMap[caseListType || 'default']
  const [cases, setCases] = useState([])
  const [error, setError] = useState(null)
  const [isLoadingCases, setIsLoadingCases] = useState(false)
  const [canFetchMoreCases, setCanFetchMoreCases] = useState(false)
  const [organisations, setOrganisations] = useState([])
  const [allCaseManagers, setAllCaseManagers] = useState([])
  const [showMobileFilter, setShowMobileFilter] = useState(false)
  const [showKeySelectionModal, setShowKeySelectionModal] = useState(false)

  const defaultFilter = defaultCasesFilter({ location, lastLocation })

  const [filter, setFilter] = useState(
    getLocalFilter('caseFilter') || defaultFilter
  )

  const caseUrlType = match.url.includes('/orders') ? 'orders' : 'cases'

  const statuses =
    caseUrlType === 'orders' ? { ...OrderStatus } : { ...CaseStatus }

  useEffect(() => {
    let filterObj
    if (caseListType === 'orders') {
      filterObj = {
        ...defaultFilter,
        status: [
          statuses.New,
          statuses.Started,
          statuses.Collected,
          statuses.Closed
        ]
      }
    } else if (
      !lastLocation ||
      (lastLocation &&
        lastLocation.pathname &&
        !lastLocation.pathname.includes('cases'))
    ) {
      filterObj = { ...defaultFilter }
    } else {
      // Keep filter if coming from a case-related page
      filterObj = { ...getLocalFilter('caseFilter') }
    }

    if (caseListType === 'unread') {
      filterObj = defaultFilter
      filterObj.isRead = 'false'
      filterObj.status = [statuses.New, statuses.Started, statuses.Closed]
    }

    // reset filter if switching from /cases/unread to /cases/*
    if (lastLocation && lastLocation.pathname === CaseListUrlTypes.UNREAD) {
      filterObj = defaultFilter
    }

    const urlParams = new URLSearchParams(window.location.search)

    filterObj.userId = urlParams.get('userId') || null

    filterObj.intrinsicType =
      caseListType === 'unread' ? undefined : intrinsicTypes

    setLocalFilter(filterObj, true, 'caseFilter', setFilter)
  }, [history, match])

  const [activeFilter, setActiveFilter] = useState(false)

  const isAdmin =
    user &&
    Array.isArray(user.roles) &&
    (user.roles.includes(UserRoles.PartnerAdmin) ||
      user.roles.includes(UserRoles.Support))

  const fetchCases = async newFilter => {
    const selectedFilter = newFilter || getLocalFilter('caseFilter')

    if (!isLoadingCases) {
      setIsLoadingCases(true)
      try {
        const query = queryString.stringify({
          ...selectedFilter
        })

        const filterHasStatus = selectedFilter.status.length

        const { data } = await axios.get(`/v1/${caseUrlType}?${query}`)
        setCanFetchMoreCases(data.length === LIMIT)

        if (data && filterHasStatus) {
          if (selectedFilter.offset === 0) {
            setCases(data)
          } else {
            setCases([...cases, ...data])
          }
        } else {
          setCases([])
        }
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
      setIsLoadingCases(false)
    }
  }

  const fetchMoreCases = () => {
    const offset =
      getLocalFilter('caseFilter').offset + getLocalFilter('caseFilter').limit
    const updatedFilter = { ...getLocalFilter('caseFilter'), offset }

    setIsLoadingCases(true)

    setLocalFilter(updatedFilter, false, 'caseFilter', setFilter)
    setActiveFilter(true)
    fetchCases(updatedFilter)
  }

  const fetchOrganisations = async () => {
    try {
      const { data } = await axios.get(
        '/v1/organisations/?&orderBy=name&order=asc'
      )
      setOrganisations(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const fetchAllCaseManagers = async () => {
    try {
      const { data } = await axios.get(
        `/v1/users?role=${UserRoles.PartnerAdmin}&role=${UserRoles.Support}&orderBy=name&order=asc&type=managers`
      )
      data.forEach(usr => {
        usr.servicedesk = usr.roles.includes(UserRoles.Support)
      })
      setAllCaseManagers(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    fetchCases()
  }, [filter])

  const handleFilter = name => e => {
    let value

    const aFilter = { ...getLocalFilter('caseFilter') }

    if (name === 'organisation') {
      value = e || ''
      aFilter.organisationId = e && e.value ? e.value.id : ''
    } else if (name !== 'createdAtFrom' && name !== 'createdAtTo') {
      ;({ value } = e.target)
    } else {
      value = e
    }

    if (name === 'status') {
      if (e.target.checked) {
        aFilter.status.push(value)
      } else {
        _.remove(aFilter.status, s => s === value || s === statuses.Collected)
      }
    } else if (name === 'onlyUnread') {
      if (e.target.checked) {
        aFilter.isRead = 'false'
      } else {
        aFilter.isRead = ''
      }
    } else if (!value || value === 'all') {
      delete aFilter[name]
    } else {
      aFilter[name] = value
    }

    aFilter.offset = 0

    if (name && name === 'clearFilter') {
      defaultFilter.isRead = ''
      defaultFilter.intrinsicType =
        caseListType === 'unread' ? undefined : intrinsicTypes
      setLocalFilter(defaultFilter, true, 'caseFilter', setFilter)
      setActiveFilter(false)
    } else {
      setLocalFilter(aFilter, false, 'caseFilter', setFilter)
      setActiveFilter(true)
    }
  }

  const updateCaseReadStatusLocally = (caseObj, status) => {
    const tempCases = cases.map(tempCase => {
      if (tempCase.id === caseObj.id) {
        const temp = { ...tempCase }

        temp.isRead = status || !tempCase.isRead

        return temp
      }
      return tempCase
    })

    setCases(tempCases)
  }

  const updateUnreadStatusOnClick = async caseObj => {
    if (isAdmin) {
      try {
        const updateObj = {
          isRead: !caseObj.isRead
        }

        await axios.put(`/v1/${caseUrlType}/${caseObj.id}`, updateObj)

        updateCaseReadStatusLocally(caseObj)
      } catch (err) {
        const msg = err.response ? err.response.data : err.message
        setError(msg)
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      if (
        user.roles.includes(UserRoles.PartnerAdmin) ||
        user.roles.includes(UserRoles.Support)
      ) {
        await fetchOrganisations()
        await fetchAllCaseManagers()
      }
    })()

    clearSelectedOrg()

    if (!getLocalFilter('caseFilter')) {
      // Set default filter on load
      setLocalFilter(defaultFilter, true, 'caseFilter', setFilter)
    }
  }, [])

  const statusFormat = (status, obj) => {
    if (status) {
      let extraInfo = ''

      if (status === CaseStatus.Resting && obj.restingUntilDate && isAdmin) {
        extraInfo = ` ${global._('Common.Until')} ${moment(
          obj.restingUntilDate
        ).format('YYYY-MM-DD')}`
      }

      return (
        <div style={{ display: 'flex' }}>
          <span className={`status ${mapCaseStatus(status)}`}>{`${global._(
            `Case.Status.${status}`
          )}${extraInfo}`}</span>
          {isAdmin && moment().isAfter(obj.restingUntilDate) && (
            <span
              className='status red'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Icon style={{ fontSize: 12 }}>access_time</Icon>
            </span>
          )}
        </div>
      )
    }
  }

  const columns = [
    {
      key: 'icon',
      localizationKey: 'Cases.Blank',
      sortingDisabled: true,
      style: { width: 70 },
      format: (objVal, obj) =>
        obj && (
          <Tooltip
            title={
              isAdmin
                ? [
                    obj.isRead
                      ? global._('Cases.MarkAsUnreadTooltip')
                      : global._('Cases.MarkAsReadTooltip')
                  ]
                : ''
            }
            placement='right'
          >
            <Icon
              className={`${classes.icon} columnIcon ${
                obj.isRead ? '' : isAdmin && classes.iconNew
              }`}
              onClick={() =>
                updateUnreadStatusOnClick && updateUnreadStatusOnClick(obj)
              }
            >
              {mapCaseIcon(obj.type)}
            </Icon>
          </Tooltip>
        )
    },
    {
      key: 'type',
      localizationKey: 'Cases.Type',
      hiddenOnSm: true,
      clickable: true,
      style: { width: 120 },
      format: objType => objType && global._(`CaseTypes.${objType}`)
    },
    {
      key: 'id',
      localizationKey: 'Cases.Number',
      clickable: true,
      style: { width: 70 }
    },
    {
      key: 'header',
      localizationKey: 'Cases.Title',
      sortingKey: 'header',
      bold: true,
      longText: true,
      clickable: true
    },
    {
      key: 'status',
      localizationKey: 'Cases.Status',
      clickable: true,
      sortingDisabled: true,
      style: { width: 150 },
      format: (status, obj) => statusFormat(status, obj)
    },
    {
      key: 'invoiced',
      localizationKey: 'Cases.Payment',
      clickable: true,
      sortingDisabled: true,
      style: { width: 120 },
      hiddenFor: !isAdmin,
      format: status =>
        status && (
          <span className={`status ${mapCaseInvoiceStatus(status)}`}>
            {global._(`Case.InvoiceStatus.${status}`)}
          </span>
        )
    },
    {
      key: 'organisationName',
      localizationKey: 'Cases.Organisation',
      style: { width: 220 },
      sortingKey: 'organisationName',
      clickable: true,
      hiddenFor: !isAdmin
    },
    {
      key: 'managers',
      localizationKey: 'Cases.CaseManagers',
      sortingKey: 'managers',
      clickable: true,
      sortingDisabled: true,
      style: { width: 150 },
      hiddenOnSm: true,
      format: caseManagersArray =>
        caseManagersArray &&
        Array.isArray(caseManagersArray) &&
        caseManagersArray.length > 0 &&
        caseManagersArray.map(
          ({ user: manager }) =>
            manager && <UserNameTooltip key={manager.userId} user={manager} />
        )
    },
    {
      key: 'createdBy',
      localizationKey: 'Cases.CreatedBy',
      sortingKey: 'createdByName',
      hiddenOnSm: isAdmin,
      clickable: true,
      style: { width: 122 },
      format: userObj => <UserNameTooltip user={userObj} />
    },
    {
      key: 'createdAt',
      localizationKey: 'Cases.CreatedAt',
      hiddenOnSm: isAdmin,
      clickable: true,
      style: { width: 120 },
      format: objDate => (
        <Tooltip
          title={moment(objDate).calendar(null, {
            sameElse: 'YYYY-MM-DD HH:mm'
          })}
          placement='right'
        >
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatShallowDate(objDate, { withoutTime: true })}
          </span>
        </Tooltip>
      )
    }
  ]

  const tabNavigationItems = [
    {
      label: global._('Cases.Navigation.Cases'),
      icon: 'help',
      path: '/cases',
      key: 'cases'
    }
    // {
    //   label: global._('Cases.Navigation.Claims'),
    //   icon: 'phonelink_off',
    //   path: '/cases/claims',
    //   key: 'claims'
    // }
    // {
    //   label: global._('Cases.Navigation.Feedback'),
    //   icon: 'feedback',
    //   path: '/cases/feedback',
    //   key: 'feedback'
    // }
  ]

  if (
    user.roles.includes(UserRoles.PartnerAdmin) ||
    user.roles.includes(UserRoles.Support)
  ) {
    tabNavigationItems.push({
      label: global._('Cases.Navigation.Unread'),
      icon: 'markunread_mailbox',
      path: '/cases/unread',
      key: 'unread'
    })
  }

  const { isMobile } = useWindowDimensions()

  let noResultsMessage
  if (caseListType === 'orders' && !activeFilter) {
    noResultsMessage = {
      text: `${global._('Cases.Orders.NoResults')}`,
      helpText: undefined
    }
  } else if (activeFilter || caseListType === 'unread') {
    noResultsMessage = global._('Cases.NoCasesMatchFilter')
  } else {
    noResultsMessage = global._('Cases.NoExistingCasesText')
  }

  const actionButtonOnClick = () => {
    if (caseListType === 'orders') {
      if (
        checkIfOrgHasAccessToScope(
          user,
          selectedOrganisation,
          OrganisationServices.KEY_MANAGEMENT
        )
      ) {
        setShowKeySelectionModal(true)

        return
      }
      return history.push('/orders/new')
    }

    return history.push('/cases/new')
  }

  const _hasActivePaymentMethod = hasActivePaymentMethod(selectedOrganisation)

  if (isMobile) {
    return (
      <>
        {showKeySelectionModal && (
          <SelectAKeyModal
            user={user}
            history={history}
            setShowKeySelectionModal={setShowKeySelectionModal}
          />
        )}
        {showMobileFilter && (
          <FullscreenModal
            title='Filter'
            onClose={() => setShowMobileFilter(false)}
            rightActionItem='close'
            noPadding
            content={
              caseListType !== 'unread' && (
                <Filter
                  filter={getLocalFilter('caseFilter')}
                  handleFilter={handleFilter}
                  caseTypes={CaseTypes}
                  intrinsicTypes={intrinsicTypes}
                  caseInvoiceStatus={CaseInvoiceStatus}
                  organisations={organisations}
                  allCaseManagers={allCaseManagers}
                  user={user}
                  noMargin
                  caseUrlType={caseUrlType}
                />
              )
            }
          />
        )}
        <CasesMobile
          {...props}
          cases={cases}
          user={user}
          tabNavigationItems={tabNavigationItems}
          error={error}
          handleFilter={handleFilter}
          filter={getLocalFilter('caseFilter')}
          isLoadingCases={isLoadingCases}
          canFetchMoreCases={canFetchMoreCases}
          fetchMoreCases={fetchMoreCases}
          showMobileFilter={showMobileFilter}
          caseListType={caseListType}
          updateUnreadStatusOnClick={updateUnreadStatusOnClick}
          setShowMobileFilter={setShowMobileFilter}
          noResultsMessage={noResultsMessage}
          caseUrlType={caseUrlType}
          actionButtonOnClick={actionButtonOnClick}
          statusFormat={statusFormat}
          hasActivePaymentMethod={_hasActivePaymentMethod}
        />
      </>
    )
  }

  const breadcrumbs = breadcrumbItems
  if (caseUrlType === 'orders') {
    breadcrumbs.push({
      name: global._('Cases.Orders.Keys'),
      path: '/orders'
    })
  }

  const newItemButton = () => {
    if (
      caseListType === 'orders' &&
      user.roles.includes(UserRoles.PartnerAdmin) &&
      user.organisation.type === OrganisationTypes.Partner
    ) {
      return
    }

    return (
      <Button
        data-cy='button-add-new-case'
        variant='primary'
        disabled={caseListType === 'orders' && !_hasActivePaymentMethod}
        onClick={() => actionButtonOnClick()}
        style={{ marginLeft: 'auto' }}
      >
        {caseListType === 'orders'
          ? global._('Cases.Orders.New')
          : global._('Cases.NewCase.Title')}
      </Button>
    )
  }

  const lang = global.getLanguage()

  return (
    <div className={classes.root}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      {showKeySelectionModal && (
        <SelectAKeyModal
          user={user}
          history={history}
          setShowKeySelectionModal={setShowKeySelectionModal}
        />
      )}
      <PageContentHeader
        breadcrumbItems={breadcrumbs}
        actionButtons={newItemButton()}
      />
      {caseListType !== 'unread' && filter && (
        <Filter
          filter={getLocalFilter('caseFilter')}
          handleFilter={handleFilter}
          caseTypes={CaseTypes}
          intrinsicTypes={intrinsicTypes}
          caseInvoiceStatus={CaseInvoiceStatus}
          organisations={organisations}
          allCaseManagers={allCaseManagers}
          user={user}
          caseUrlType={caseUrlType}
        />
      )}
      <Paper noPadding alignItems='left'>
        {caseListType !== 'orders' && (
          <TabNavigator
            items={tabNavigationItems}
            videoId={InstructionsVideoIds[lang].CASES}
          />
        )}
        <div style={{ padding: '30px 20px 20px' }}>
          <ObjectList
            wrapper={false}
            noResultsMessage={noResultsMessage}
            columns={columns}
            user={user}
            objects={cases}
            history={history}
            objectPermalink={caseUrlType}
            filter={getLocalFilter('caseFilter')}
            handleFilter={handleFilter}
            setFilter={setFilter}
            setLocalFilter={setLocalFilter}
            localFilterName='caseFilter'
            expandable={false}
            isLoadingObjects={isLoadingCases}
            canFetchMoreObjects={canFetchMoreCases}
            fetchMoreObjects={fetchMoreCases}
            updateUnreadStatusOnClick={updateUnreadStatusOnClick}
            setObjReadStatusLocally={updateCaseReadStatusLocally}
          />
        </div>
      </Paper>
    </div>
  )
}

Cases.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array,
  location: PropTypes.object.isRequired
}

Cases.defaultProps = {
  breadcrumbItems: []
}

const CasesWithStyles = injectSheet(styles)(Cases)
export default CasesWithStyles
