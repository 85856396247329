/* eslint-disable */
const NorwegianSSN = require('./ssnNorwegian')
/**
 * Compare the two dates and return -1, 0 or 1.
 *
 * @param {Date} dateLeft
 * @param {Date} dateRight
 *
 * @return {numbers}
 */
const compareAsc = (dateLeft, dateRight) => {
  const diff = dateLeft.getTime() - dateRight.getTime()
  if (diff < 0) return -1
  else if (diff > 0) return 1
  return diff
}

/**
 * Get the number of full years between the given dates.
 *
 * @param {Date} dateLeft
 * @param {Date} dateRight
 *
 * @return {numbers}
 */
const diffInYears = (dateLeft, dateRight) => {
  const sign = compareAsc(dateLeft, dateRight)
  const yearDiff = Math.abs(dateLeft.getFullYear() - dateRight.getFullYear())

  dateLeft.setFullYear(dateLeft.getFullYear() - sign * yearDiff)

  const isLastYearNotFull = compareAsc(dateLeft, dateRight) === -sign
  const result = sign * (yearDiff - isLastYearNotFull)

  return result === 0 ? 0 : result
}

/**
 * Calculates the Luhn checksum of a string of digits.
 *
 * @param {string|number} str
 *
 * @return {number}
 */
const luhn = str => {
  let v = 0
  let sum = 0

  str += ''

  for (let i = 0, l = str.length; i < l; i += 1) {
    v = str[i]
    v *= 2 - (i % 2)
    if (v > 9) {
      v -= 9
    }
    sum += v
  }

  return Math.ceil(sum / 10) * 10 - sum
}

/**
 * Test if the input parameters are a valid date or not.
 *
 * @param {int} year
 * @param {int} month
 * @param {int} day
 *
 * @return {boolean}
 */
const testDate = (year, month, day) => {
  month -= 1
  const date = new Date(year, month, day)
  return !(
    `${date.getFullYear()}` !== year ||
    date.getMonth() !== month ||
    date.getDate() !== day
  )
}

/**
 * Parse a Swedish social security number and get the parts.
 *
 * @param {string|int} ssn
 *
 * @return {object}
 */
const getParts = ssn => {
  ssn += ''

  const reg = /^(\d{2}){0,1}(\d{2})(\d{2})(\d{2})([-|+]{0,1})?(\d{3})(\d{0,1})$/
  const match = reg.exec(ssn)

  if (!match) {
    return {}
  }

  let century = match[1]
  const year = match[2]
  const month = match[3]
  const day = match[4]
  let sep = match[5]
  const num = match[6]
  const check = match[7]

  if (typeof century === 'undefined' || !century.length) {
    const d = new Date()
    let baseYear = 0

    if (sep === '+') {
      baseYear = d.getFullYear() - 100
    } else {
      sep = '-'
      baseYear = d.getFullYear()
    }

    century = `${baseYear - ((baseYear - year) % 100)}`.substr(0, 2)
  } else if (new Date().getFullYear() - parseInt(century + year, 10) < 100) {
    sep = '-'
  } else {
    sep = '+'
  }

  return {
    century,
    year,
    month,
    day,
    sep,
    num,
    check
  }
}

module.exports = {
  /**
   * Format a Swedish social security number as one of the official formats,
   * A long format or a short format.
   *
   * If the input number could not be parsed a empty string will be returned.
   *
   * @param {string} ssn
   * @param {boolean} longFormat
   * @param {boolean} withHypen
   *
   * @return {string}
   */
  format(ssn, longFormat, withHyphen = false) {
    if (!this.valid(ssn)) {
      return ''
    }

    if (NorwegianSSN.validateNorwegianIdNumber(ssn)) {
      return ssn
    }

    const parts = getParts(ssn)

    if (withHyphen) {
      if (longFormat) {
        return `${parts.century}${parts.year}${parts.month}${parts.day}-${parts.num}${parts.check}`
      }

      return `${parts.year}${parts.month}${parts.day}-${parts.sep}${parts.num}${parts.check}`
    }

    if (longFormat) {
      return `${parts.century}${parts.year}${parts.month}${parts.day}${parts.num}${parts.check}`
    }

    return `${parts.year}${parts.month}${parts.day}${parts.sep}${parts.num}${parts.check}`
  },

  /**
   * Get age from a Swedish social security number.
   *
   * @param {string|int} ssn
   * @param {boolean} includeCoordinationNumber
   *
   * @return {int}
   */
  getAge(ssn, includeCoordinationNumber = true) {
    if (!this.valid(ssn, includeCoordinationNumber)) {
      return 0
    }

    const parts = getParts(ssn)
    let day = +parts.day

    if (includeCoordinationNumber && day >= 61 && day < 91) {
      day -= 60
    }

    const input = `${parts.century + parts.year}-${parts.month}-${day}`

    return diffInYears(new Date(Date.now()), new Date(input))
  },

  /**
   * Check if a Swedish social security number is for a female.
   *
   * @param {string|number} ssn
   * @param {boolean} includeCoordinationNumber
   *
   * @throws Error when input value is not valid.
   *
   * @return {boolean}
   */
  isFemale(ssn, includeCoordinationNumber = true) {
    return !this.isMale(ssn, includeCoordinationNumber)
  },

  /**
   * Check if a Swedish social security number is for a male.
   *
   * @param {string|number} ssn
   * @param {boolean} includeCoordinationNumber
   *
   * @throws Error when input value is not valid.
   *
   * @return {boolean}
   */
  isMale(ssn, includeCoordinationNumber = true) {
    if (!this.valid(ssn, includeCoordinationNumber)) {
      throw new Error('Invalid swedish social security number')
    }

    const parts = getParts(ssn)
    const sexDigit = parts.num.substr(-1)

    return sexDigit % 2 === 1
  },

  /**
   * Validate a Swedish social security number.
   *
   * @param {string|number} str
   * @param {boolean} includeCoordinationNumber
   *
   * @return {boolean}
   */
  valid(ssn, includeCoordinationNumber = true) {
    if (typeof ssn !== 'number' && typeof ssn !== 'string') {
      return false
    }

    if (NorwegianSSN.validateNorwegianIdNumber(ssn)) {
      return true
    }

    const parts = getParts(ssn)
    if (!Object.keys(parts).length) {
      return false
    }

    // eslint-disable-next-line
    const nLuhn = luhn(parts.year + parts.month + parts.day + parts.num)
    const valid = nLuhn === +parts.check && !!parts.check
    if (
      valid &&
      testDate(parts.century + parts.year, +parts.month, +parts.day)
    ) {
      return valid
    }

    if (!includeCoordinationNumber) {
      return false
    }

    return (
      valid &&
      testDate(parts.century + parts.year, +parts.month, +parts.day - 60)
    )
  },

  getCountry(ssn) {
    if (typeof ssn !== 'number' && typeof ssn !== 'string') {
      return null
    }

    if (NorwegianSSN.validateNorwegianIdNumber(ssn)) {
      return 'NO'
    }

    const parts = getParts(ssn)
    if (!Object.keys(parts).length) {
      return null
    }

    // eslint-disable-next-line
    const nLuhn = luhn(parts.year + parts.month + parts.day + parts.num)
    const valid = nLuhn === +parts.check && !!parts.check
    if (
      valid &&
      testDate(parts.century + parts.year, +parts.month, +parts.day)
    ) {
      return 'SE'
    }

    if (!includeCoordinationNumber) {
      return null
    }

    if (
      valid &&
      testDate(parts.century + parts.year, +parts.month, +parts.day - 60)
    ) {
      return 'SE'
    }
    return null
  }
}
