import React from 'react'
import PropTypes from 'prop-types'

const Row = props => {
  const { justify, align, flex, style, ...rest } = props
  const customStyle = props.style || {}
  customStyle.display = 'flex'
  customStyle.flexDirection = 'row'
  if (justify) customStyle.justifyContent = justify
  if (align) customStyle.alignItems = align
  if (flex) customStyle.flex = 1
  return <div style={customStyle} {...rest} />
}

Row.propTypes = {
  children: PropTypes.node,
  justify: PropTypes.string,
  align: PropTypes.string,
  flex: PropTypes.bool,
  style: PropTypes.object,
  spacing: PropTypes.number
}

Row.defaultProps = {
  justify: 'flex-start',
  align: 'center',
  flex: false,
  style: null
}

export default Row
