import React from 'react'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import axios from 'axios'
import ItemBadge from 'src/components/ItemBadge'
import Button from '../../../../components/Button'
import SuccessMessage from '../../../../components/SuccessMessage'
import { UserRoles } from '../../../../utils/constants'
import { mapRoles } from '../../../../utils/helpers'
import { history } from '../../../../redux/store'

export default {
  icon: () => 'face',
  attributes: (caller, opts) => {
    const attributes = {
      email: {
        required: true,
        size: 12
      },
      organisationId: {
        required: true,
        size: 12,
        field: (
          <TextField
            variant='filled'
            disabled
            style={{
              width: '100%'
            }}
            InputLabelProps={{
              shrink: false
            }}
            label={opts.filter.organisationName}
            helperText={global._('Widgets.ServiceDesk.ServiceDeskHelperText')}
          />
        )
      },
      roles: {
        required: true,
        field: (
          <ItemBadge
            icon='face'
            label={global._(`User.Roles.${UserRoles.Support}`)}
            hideDeleteBtn
          />
        )
      },
      message: {
        required: false,
        size: 12
      }
    }

    return attributes
  },
  modals: ({ modals, setModals }) => (
    <SuccessMessage
      key='admin.users.import.successmessage'
      subtitle={global._('Import.Users.Preview.SuccessMessage')}
      localStorageProp='users.import.success'
    />
  ),
  actionButtons: (caller, opts) => {
    const { filter, setNewItem, modals, setModals, single } = opts
    const buttons = []
    buttons.push(
      <Button
        key='widgets.support.button.add.new'
        variant='primary'
        onClick={() =>
          setNewItem({
            organisationId: filter.organisationId,
            roles: [UserRoles.Support]
          })
        }
      >
        {global._('Widgets.users.ActionButton.AddNew')}
      </Button>
    )
    return buttons
  },
  identifier: user =>
    `${user.id}-${user.organisation ? user.organisation.id : null}`,
  noResultsMessage: () => global._('Widgets.users.NoResultsMessage'),
  MobileTable: {
    icon: 'face',
    title: user => user.name,
    subtitle: user =>
      user.role && (
        <span
          style={{
            marginTop: 6,
            padding: '2px 8px',
            backgroundColor: 'var(--color-dark-grey)',
            color: 'white',
            borderRadius: 3,
            display: 'inline-block',
            fontSize: '0.75rem',
            fontWeight: 600,
            textTransform: !user.active && 'lowercase'
          }}
        >
          {!user.active && (
            <span style={{ textTransform: 'capitalize' }}>{`${global._(
              'Common.NotRegistered'
            )} `}</span>
          )}
          {global._(`User.Roles.${user.role}`)}
        </span>
      ),
    onClick: item =>
      history.push(`/admin/users/${item.organisation.id}/${item.id}`)
  },
  Columns: {
    single: [
      {
        key: 'icon',
        sortable: false,
        localizationKey: '',
        style: { width: 60 },
        transform: isExpanded => (
          <div
            className='tableRowIcon'
            style={{
              width: 38,
              height: 38,
              transition: 'background-color 200ms',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isExpanded ? 'white' : 'var(--color-light-grey)',
              color: 'var(--color-middle-grey)',
              borderRadius: 500
            }}
          >
            <Icon style={{ fontSize: 18 }}>face</Icon>
          </div>
        )
      },
      {
        key: 'name',
        sortable: true,
        style: { width: 200 },
        transform: value => (
          <span
            style={{
              fontWeight: value ? 600 : undefined,
              color: value ? undefined : 'gray'
            }}
          >
            {value || global._('User.NoNamePlaceholder')}
          </span>
        ),
        localizationKey: 'Widgets.users.Attributes.name'
      },
      {
        key: 'personalNumber',
        sortable: true,
        localizationKey: 'Widgets.users.Attributes.personalNumber',
        style: { width: 125 }
      },
      {
        key: 'email',
        sortable: true,
        localizationKey: 'Widgets.users.Attributes.email',
        style: { width: 250 }
      },
      {
        key: 'phone',
        sortable: true,
        localizationKey: 'Widgets.users.Attributes.phone',
        style: { width: 150 }
      },
      {
        key: 'roles',
        sortable: true,
        localizationKey: 'Widgets.users.Attributes.roles',
        transform: mapRoles,
        style: { width: 120 }
      },
      {
        key: 'organisation',
        sortable: true,
        sortKey: 'organisation.name',
        localizationKey: 'Widgets.users.Attributes.organisation',
        transform: value => value && value.name,
        style: { width: 240 }
      },
      {
        key: 'chevron',
        sortable: false,
        localizationKey: '',
        style: { width: 60 },
        transform: isExpanded => (
          <IconButton
            style={{
              transition: 'transform 150ms',
              marginLeft: 'auto',
              transform: `rotate(${isExpanded ? -90 : 90}deg)`
            }}
          >
            <Icon>chevron_right</Icon>
          </IconButton>
        )
      }
    ],
    /* Minified columns */
    widget: [
      {
        key: 'name',
        sortable: true,
        transform: value => (
          <span
            style={{
              color: value ? undefined : 'gray'
            }}
          >
            {value || global._('User.NoNamePlaceholder')}
          </span>
        ),
        localizationKey: 'Widgets.users.Attributes.name',
        style: { width: 300 }
      },
      {
        key: 'createdAt',
        sortable: true,
        localizationKey: 'Widgets.users.Attributes.createdAt',
        transform: value => moment(value).calendar()
      },
      {
        key: 'chevron',
        sortable: false,
        localizationKey: '',
        style: { width: 50 },
        transform: () => (
          <Icon
            style={{ display: 'block', marginLeft: 'auto', marginRight: 5 }}
          >
            chevron_right
          </Icon>
        )
      }
    ]
  },
  rowExpansionContent: (item, caller, opts) => (
    <React.Fragment>
      <Button
        variant='outlined'
        onClick={() =>
          opts.history.push(`/admin/users/${item.organisation.id}/${item.id}`)
        }
        style={{ marginRight: 18 }}
      >
        {global._('Widgets.users.EditUser')}
      </Button>
      <Button
        variant='outlined'
        style={{ marginRight: 18 }}
        onClick={() => {
          opts.setDeleteItem(item)
          opts.setDeleteError(null)
        }}
      >
        {global._('Widgets.users.Delete')}
      </Button>
      {!item.active && (
        <>
          <Button
            variant='outlined'
            style={{ marginRight: 18 }}
            onClick={async () => {
              opts.setError(null)
              opts.setShowSuccessMessage(false)
              opts.setSuccessMessage(global._('MyAccount.InvitationLinkSent'))

              try {
                await axios.post(`/v1/users/${item.id}/resend`, caller)

                opts.setShowSuccessMessage(true)
              } catch (e) {
                const msg = e.response ? e.response.data : e.message
                opts.setError(global._(msg))
              }
            }}
          >
            {global._('Widgets.users.ResendInvitationLink')}
          </Button>
        </>
      )}
      {caller.id !== item.id &&
        (caller.roles.includes(UserRoles.SystemAdmin) ||
          caller.roles.includes(UserRoles.PartnerAdmin)) &&
        caller.organisation.id !== item.organisation.id && (
          <Button
            variant='primary'
            onClick={async () => {
              opts.setError(null)
              try {
                const { data: authData } = await axios.get(
                  `/v1/impersonate/${item.organisation.id}/${item.id}`
                )
                // Update redux state with "impersonated" data
                opts.setAuthenticationData(authData)
                opts.history.push('/dashboard')
              } catch (e) {
                const msg = e.response ? e.response.data : e.message
                opts.setError(global._(msg))
              }
            }}
          >
            {global._('Widgets.users.Impersonate')}
          </Button>
        )}
    </React.Fragment>
  )
}
