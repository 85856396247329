import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import { Badge } from '@material-ui/core'
import Theme from '../helpers/Theme'
import Typography from './Typography'
import Button from './Button'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    height: '100%',
    paddingBottom: 10,
    borderRadius: 3,
    cursor: 'pointer',
    border: '1px solid #e8e8e8',
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
    '&:focus': {
      borderColor: Theme.Colors.PRIMARY,
      outline: 'none',
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)!important'
    },
    '&:active': {
      borderColor: Theme.Colors.PRIMARY,
      outline: 'none',
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)!important'
    }
  },
  rootHover: {
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.3), 0 3px 4px 0 rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.2)'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    borderBottom: `2px solid ${Theme.Colors.PRIMARY_TONE_3}`
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    borderRadius: 500,
    backgroundColor: '#E8E8E8',
    color: Theme.Colors.MIDDLE_GREY
  },
  icon: {
    fontSize: 24
  },
  iconContainerHover: {
    backgroundColor: Theme.Colors.PRIMARY_TONE_3,
    color: Theme.Colors.DARK_GREY
  },
  iconContainerFocused: {
    backgroundColor: Theme.Colors.PRIMARY_TONE_2,
    color: Theme.Colors.DARK_GREY
  },
  headerTitle: {
    marginLeft: 20
  },
  body: {
    padding: '1rem',
    textAlign: 'left'
  },
  unreadContainer: {
    marginTop: 'var(--section-padding-d-ext-v)'
  },
  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */

  '@media (max-width: 900px)': {
    root: {
      height: 'auto',
      paddingBottom: 0
    },
    header: {
      padding: '0.6rem',
      borderBottomColor: Theme.Colors.PRIMARY
    },
    iconContainer: {
      width: 44,
      height: 44
    },
    icon: {
      fontSize: 20
    },
    headerTitle: {
      marginLeft: 16
    },
    body: {
      padding: '0.6rem',
      color: Theme.Colors.MIDDLE_GREY
    },
    unreadContainer: {
      marginTop: 'var(--section-padding-m-ext-v)'
    }
  }
}

const Card = props => {
  const { classes, title, subtitle, link, icon, history, badges, buttons } =
    props

  const [isHovering, setIsHovering] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const onClick = () => {
    if (link.startsWith('http')) {
      window.open(link, '_blank')
    } else if (history) {
      history.push(link)
    } else {
      window.location.href = link
    }
  }

  const rootClasses = classnames(classes.root, {
    [classes.rootHover]: isHovering
  })

  const iconContainerClasses = classnames(classes.iconContainer, {
    [classes.iconContainerHover]: isHovering,
    [classes.iconContainerFocused]: isFocused
  })

  return (
    <div
      className={rootClasses}
      onClick={onClick}
      onKeyPress={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      role='button'
      tabIndex={0}
    >
      <div className={classes.header}>
        <div className={iconContainerClasses}>
          {icon && <Icon className={classes.icon}>{icon}</Icon>}
        </div>
        <div className={classes.headerTitle}>
          <Typography bold={600} variant='title'>
            {title}
          </Typography>
        </div>
      </div>
      <div className={classes.body}>
        {subtitle}
        {badges
          .filter(badge => badge && badge.count && badge.count != '0')
          .map(badge => (
            <div className={classes.unreadContainer} key={badge.label}>
              <Button
                variant='outlined'
                onClick={e => {
                  e.stopPropagation()
                  if (typeof badge.onClick === 'function') {
                    badge.onClick()
                  } else {
                    history.push(link)
                  }
                }}
                style={{
                  width: '100%',
                  textAlign: 'left',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                {badge.label}
                <Badge
                  badgeContent={badge.count}
                  color='error'
                  style={{ marginRight: 10 }}
                />
              </Button>
            </div>
          ))}
        {buttons}
      </div>
    </div>
  )
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  history: PropTypes.object,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      label: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  buttons: PropTypes.array
}

Card.defaultProps = {
  history: null,
  badges: [],
  buttons: []
}

export default injectSheet(styles)(Card)
