import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon } from '@material-ui/core'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import Notification from '../../common/Notification'
import CenteredFloatingButtonMobileWithStyles from '../../components/CenteredFloatingButtonMobile'
import FullscreenModal from '../../components/FullscreenModal'
import ObjectListMobile from '../../components/ObjectListMobile'
import Typography from '../../components/Typography'
import MobileContainer from '../../components/MobileContainer'

const styles = {
  root: {},
  section: {
    padding: 'var(--section-padding-default)'
  }
}

const DevicesMobile = props => {
  const {
    classes,
    history,
    error,
    devices,
    nameFilterField,
    filterFields,
    setShowAddDeviceModal,
    canFetchMoreDevices,
    fetchMoreDevices,
    isLoadingDevices,
    user,
    filter,
    noResultsMessage
  } = props

  const [showMobileFilter, setShowMobileFilter] = useState(false)

  const columns = [
    {
      key: 'icon',
      format: obj => (
        <Icon
          style={{
            color: obj.stats
              ? 'var(--color-success-green)'
              : 'var(--color-black)'
          }}
        >
          router
        </Icon>
      )
    },
    {
      key: 'name',
      bold: true,
      format: title =>
        title && (
          <Typography
            variant='body'
            ellipsis
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )
    },
    {
      key: 'uuid',
      bold: true,
      format: uuid => (
        <Typography variant='body2' faded fontNormal ellipsis>
          {uuid}
        </Typography>
      )
    }
  ]

  return (
    <>
      <MobileContainer background='white'>
        {showMobileFilter && (
          <FullscreenModal
            title='Filter'
            onClose={() => setShowMobileFilter(false)}
            rightActionItem='close'
            padding
            content={filterFields()}
          />
        )}
        <NavigationBar
          title={global._('Device.DevicesAndLocks')}
          backAction={() => history.push('/admin')}
          rightActionItem={
            <div
              onClick={() => setShowMobileFilter(true)}
              style={{ color: 'var(--color-primary)' }}
            >
              <Icon>filter_list</Icon>
            </div>
          }
        />
        <ScrollView id='scrollable-container'>
          <div className={classes.rootMobile}>
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ margin: '20px 0' }}
              />
            )}
            <div className={classes.section}>{nameFilterField()}</div>
            <div style={{ paddingBottom: 20 }}>
              <ObjectListMobile
                objects={devices}
                user={user}
                history={history}
                filter={filter}
                objectPermalink='admin/devices'
                noResultsMessage={noResultsMessage}
                columns={columns}
                rowsAreClickable
                fetchMoreObjects={fetchMoreDevices}
                canFetchMoreObjects={canFetchMoreDevices}
                isLoadingObjects={isLoadingDevices}
              />
            </div>
          </div>
          <CenteredFloatingButtonMobileWithStyles
            onClick={() => setShowAddDeviceModal(true)}
          />
        </ScrollView>
      </MobileContainer>
    </>
  )
}

DevicesMobile.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  devices: PropTypes.object,
  nameFilterField: PropTypes.func.isRequired,
  filterFields: PropTypes.func.isRequired,
  setShowAddDeviceModal: PropTypes.func.isRequired,
  canFetchMoreDevices: PropTypes.bool,
  fetchMoreDevices: PropTypes.func.isRequired,
  isLoadingDevices: PropTypes.bool,
  user: PropTypes.object,
  filter: PropTypes.object,
  noResultsMessage: PropTypes.string
}

DevicesMobile.defaultProps = {
  devices: [],
  canFetchMoreDevices: false,
  isLoadingDevices: false,
  user: null,
  filter: null,
  noResultsMessage: ''
}

const DevicesMobileWithStyles = injectSheet(styles)(DevicesMobile)
export default DevicesMobileWithStyles
