import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import * as Yup from 'yup'
import axios from 'axios'
import { TextField } from '@material-ui/core'
import { Form, Formik } from 'formik'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import FullscreenModal from '../../components/FullscreenModal'
import AsyncSelect from '../../components/AsyncSelect'
import Notification from '../../common/Notification'

const styles = {
  root: {},
  input: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 20
    }
  }
}

const AddDeviceModal = props => {
  const {
    classes,
    isMobile,
    setShowAddDeviceModal,
    fetchDevices,
    setShowAddDeviceSuccessMessage
  } = props
  const [selectedOrganisation, setSelectedOrganisation] = useState(null)
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [error, setError] = useState(null)

  const closeModal = () => {
    setError(null)
    setShowAddDeviceModal(false)
  }

  const handleAddDevice = async (values, resetForm) => {
    const aDevice = { ...values }

    try {
      setShowAddDeviceSuccessMessage(false)

      await axios.post(`/v1/devices/${aDevice.uuid}/claim`, aDevice)

      setShowAddDeviceSuccessMessage(true)
      setShowAddDeviceModal(false)

      resetForm({})
      closeModal()

      fetchDevices()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const addDeviceFields = (
    errors,
    touched,
    handleChange,
    values,
    handleBlur,
    setFieldValue,
    resetForm
  ) => (
    <div style={{ textAlign: 'left' }}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '0 0 20px 0' }}
        />
      )}
      <TextField
        value={values.uuid}
        name='uuid'
        helperText={global._('Common.Required')}
        className={classes.input}
        label={global._('Device.DeviceIDLabel')}
        variant='filled'
        error={errors.uuid}
        onChange={handleChange}
        onClick={handleBlur}
      />
      <TextField
        value={values.name}
        name='name'
        helperText={global._('Common.Required')}
        className={classes.input}
        label={global._('Name')}
        variant='filled'
        error={errors.name}
        onChange={handleChange}
        onClick={handleBlur}
      />
      <AsyncSelect
        isSearchable
        helpText={global._('Device.SelectClientHelpText')}
        value={selectedOrganisation}
        error={errors.organisationId}
        labelKey='name'
        name='organisation'
        placeholder={global._('Device.SelectClient')}
        onChange={e => {
          setFieldValue('organisationId', (e && e.value && e.value.id) || '')
          setSelectedOrganisation(e || null)
        }}
        url={'/v1/organisations?search={{input}}&orderBy=name&order=asc'}
      />
      <AsyncSelect
        isSearchable
        key={`properties-${
          selectedOrganisation &&
          selectedOrganisation.value &&
          selectedOrganisation.value.id
        }`}
        helpText={global._('Device.SelectPropertyHelpText')}
        value={selectedProperty}
        labelKey='name'
        name='propertyId'
        isClearable
        placeholder={global._('Device.SelectProperty')}
        onChange={e => {
          setFieldValue('propertyId', (e && e.value && e.value.id) || '')
          setSelectedProperty(e || null)
        }}
        cacheOptions={false}
        error={errors.propertyId}
        style={{ marginTop: 20 }}
        url={`/v1/properties?query={{input}}&orderBy=name&order=asc&organisationId=${
          (selectedOrganisation && selectedOrganisation.value.id) || ''
        }`}
      />
    </div>
  )

  return (
    <Formik
      initialValues={{
        uuid: '',
        name: '',
        organisationId: '',
        propertyId: ''
      }}
      validationSchema={Yup.object().shape({
        uuid: Yup.string().required(global._('Common.Required')),
        name: Yup.string().required(global._('Common.Required')),
        organisationId: Yup.number().required(global._('Common.Required')),
        propertyId: Yup.string().required(global._('Common.Required'))
      })}>
      {({
        errors,
        touched,
        handleChange,
        values,
        handleBlur,
        setFieldValue,
        resetForm
      }) => (
        <Form>
          {isMobile ? (
            <FullscreenModal
              title={global._('Common.Add')}
              onClose={() => closeModal()}
              rightActionItem='close'
              padding
              content={addDeviceFields(
                errors,
                touched,
                handleChange,
                values,
                handleBlur,
                setFieldValue
              )}
              buttons={
                <Button
                  variant='primary'
                  disabled={
                    Object.keys(touched).length === 0 ||
                    Object.keys(errors).length
                  }
                  onClick={() => {
                    handleAddDevice(values, resetForm)
                  }}>
                  {global._('Common.Add')}
                </Button>
              }
            />
          ) : (
            <Modal
              onClose={() => closeModal()}
              title={global._('Device.AddDevice')}
              content={addDeviceFields(
                errors,
                touched,
                handleChange,
                values,
                handleBlur,
                setFieldValue
              )}
              buttons={[
                <Button variant='none' onClick={() => closeModal()}>
                  {global._('Common.Cancel')}
                </Button>,
                <Button
                  variant='primary'
                  disabled={
                    Object.keys(touched).length === 0 ||
                    Object.keys(errors).length
                  }
                  onClick={() => {
                    handleAddDevice(values, resetForm)
                  }}>
                  {global._('Common.Add')}
                </Button>
              ]}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

AddDeviceModal.propTypes = {
  classes: PropTypes.object.isRequired,
  setShowAddDeviceModal: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  fetchDevices: PropTypes.func.isRequired,
  setShowAddDeviceSuccessMessage: PropTypes.func.isRequired
}

AddDeviceModal.defaultProps = {
  isMobile: false
}

const AddDeviceModalWithStyles = injectSheet(styles)(AddDeviceModal)
export default AddDeviceModalWithStyles
