import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import MobileContainer from 'src/components/MobileContainer'
import NavigationBar from 'src/components/NavigationBar'
import ScrollView from 'src/components/ScrollView'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import { Icon } from '@material-ui/core'
import Typography from 'src/components/Typography'
import Paper from 'src/components/Paper'
import Button from 'src/components/Button'
import AsyncSelect from 'src/components/AsyncSelect'
import { getSelectedOrg } from '../utils/helpers'
import { UserRoles } from '../utils/constants'

const styles = {
  root: {}
}

const OrgWrapper = ({ children, isMobile }) =>
  isMobile ? (
    <div>{children}</div>
  ) : (
    <Paper fixedWidth={!isMobile} biggerPadding>
      {children}
    </Paper>
  )

const Content = ({
  isMobile,
  organisation,
  setOrganisation,
  history,
  linkTo,
  scope,
  scopeRequiredToSelect
}) => {
  let helpText = global._('Common.Required')

  let hasActiveScope = false
  if (scope && organisation && organisation.value.services) {
    if (
      organisation.value.services[0] &&
      !organisation.value.services[0][scope]
    ) {
      helpText = global._('Common.SelectOrganisationForm.InactiveService')
    } else {
      helpText = global._('Common.SelectOrganisationForm.ActiveService')
      hasActiveScope = true
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: isMobile ? undefined : 'center',
        flexDirection: 'column',
        paddingLeft: 'var(--section-padding-default)',
        paddingRight: 'var(--section-padding-default)'
      }}
    >
      <Typography
        variant={isMobile ? 'subtitleBig' : 'h1'}
        bold={isMobile}
        align={isMobile ? undefined : 'center'}
        style={{ marginBottom: isMobile ? 20 : 30 }}
      >
        {global._('Keys.Common.PickACustomer')}
      </Typography>
      <OrgWrapper isMobile={isMobile}>
        <AsyncSelect
          isSearchable
          isClearable={false}
          value={organisation}
          helpText={helpText}
          labelKey='name'
          name='organisation'
          placeholder={global._('Common.SelectOrganisation')}
          onChange={opt => setOrganisation(opt)}
          scope={scope}
          url='/v1/organisations?search={{input}}&orderBy=name&order=asc'
        />
        <Button
          disabled={!organisation || (scopeRequiredToSelect && !hasActiveScope)}
          onClick={() => {
            window.sessionStorage.setItem(
              'selected_org',
              JSON.stringify(organisation.value)
            )
            history.push(linkTo)
          }}
          variant='primary'
          style={{ marginTop: 20, width: '100%' }}
        >
          {global._('Common.Continue')}
        </Button>
      </OrgWrapper>
      {!isMobile && (
        <Button
          variant='none'
          style={{ marginTop: 15 }}
          onClick={() => history.push('/dashboard')}
        >
          {global._('Common.Back')}
        </Button>
      )}
    </div>
  )
}

const _SelectOrganisation = ({ classes, history, user, match, scope }) => {
  const [organisation, setOrganisation] = useState(null)

  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)

  const selectedOrg = getSelectedOrg(user)

  let linkTo
  let backTo
  let scopeRequiredToSelect = false

  if (match && match.url.includes('/keys/')) {
    linkTo = '/keys'
    backTo = '/dashboard'
  } else if (match && match.url.includes('/cases/')) {
    linkTo = '/cases/new'
    backTo = '/cases'
  } else if (match && match.url.includes('/booking/')) {
    linkTo = '/booking/admin'
    backTo = '/dashboard'
    scopeRequiredToSelect = true
  }

  if (selectedOrg || (!isPartnerAdmin && !isSupport)) {
    history.push(linkTo)
  }

  const { isMobile } = useWindowDimensions()

  if (isMobile) {
    return (
      <MobileContainer>
        <NavigationBar
          title={global._('Keys.Common.PickACustomer')}
          backAction={() => history.push(backTo)}
        />
        <ScrollView
          id='scrollable-container'
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          <Content
            organisation={organisation}
            scope={scope}
            setOrganisation={setOrganisation}
            history={history}
            linkTo={linkTo}
          />
        </ScrollView>
      </MobileContainer>
    )
  }

  return (
    <div className={classes.root}>
      <Content
        organisation={organisation}
        scope={scope}
        setOrganisation={setOrganisation}
        history={history}
        linkTo={linkTo}
        scopeRequiredToSelect={scopeRequiredToSelect}
      />
    </div>
  )
}

OrgWrapper.propTypes = {
  isMobile: PropTypes.bool,
  children: PropTypes.node
}

OrgWrapper.defaultProps = {
  isMobile: undefined,
  children: null
}

Content.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  organisation: PropTypes.object,
  setOrganisation: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  linkTo: PropTypes.string.isRequired
}

Content.defaultProps = {
  organisation: null
}

_SelectOrganisation.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object
}

_SelectOrganisation.defaultProps = {
  user: null
}

const SelectOrganisation = injectSheet(styles)(_SelectOrganisation)
export default SelectOrganisation
