import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import axios from 'axios'
import PropTypes from 'prop-types'
import { TextField, Switch } from '@material-ui/core'
import Row from 'src/components/Row'
import Column from 'src/components/Column'
import Button from 'src/components/Button'
import Typography from 'src/components/Typography'
import Notification from 'src/common/Notification'
import { UserRoles, ContactPageURL, ShippingTypes } from 'src/utils/constants'
import SuccessMessage from 'src/components/SuccessMessage'
import withUser from 'src/utils/withUser'
import { withRouter } from 'react-router'
import Indicator from './Indicator'

const ShippingOptions = props => {
  const { user, history, organisationId } = props

  const isPartner =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isCustomerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.CustomerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)
  const readOnly = !isPartner
  if (!isPartner && !isCustomerAdmin && !isSupport) {
    return history.push('/')
  }

  const [saved, setSaved] = useState(false)
  const [error, setError] = useState(null)
  const [shippingOptions, setShippingOptions] = useState([])
  const [pendingShippingOptions, setPendingShippingOptions] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    ;(async () => {
      /* load options */
      setIsLoading(true)
      try {
        const { data: _options } = await axios.get(
          `/v1/organisations/${organisationId}/shipping`
        )
        if (!_options.length) {
          _options.push({
            type: ShippingTypes.COLLECT,
            active: true,
            price: '0'
          })
        }
        // Add non-existing options
        Object.values(ShippingTypes).forEach(type => {
          if (!_options.find(opt => opt.type === type)) {
            _options.push({
              type,
              active: false,
              price: '0'
            })
          }
        })
        setShippingOptions(_options)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
      setIsLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false)
      }, 2000)
    }
  }, [saved])

  const updateOption = async (option, values = {}) => {
    setError(null)
    try {
      const { data: _option } = await axios.put(
        `/v1/organisations/${organisationId}/shipping/${option.type}`,
        { ...option, ...values }
      )
      setShippingOptions(prevOptions => {
        const _options = [...prevOptions]
        const idx = _options.findIndex(opt => opt.type === option.type)
        _options.splice(idx, 1, _option)
        return _options
      })
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handlePendingUpdate = (option, values) => {
    setPendingShippingOptions({
      ...pendingShippingOptions,
      [option.type]: {
        ...option,
        ...values
      }
    })
  }

  const handleCommitPendingUpdates = async () => {
    setIsSaving(true)
    await Promise.all(
      Object.values(pendingShippingOptions).map(option => updateOption(option))
    )

    setIsSaving(false)
    setSaved(true)
    setPendingShippingOptions({})
  }

  return (
    <Column align='flex-start' style={{ width: '100%' }}>
      {saved && <SuccessMessage subtitle={global._('Common.ChangesSaved')} />}
      {error && <Notification type='error' message={error} />}
      <div
        style={{
          width: '100%',
          padding: '20px 20px 10px 20px',
          borderBottom: '1px solid var(--color-divider-light)'
        }}
      >
        <Typography bold variant='title'>
          {global._('Admin.Settings.Orders.Navigation.ShippingOptions')}
        </Typography>
        {Object.values(ShippingTypes).map((type, idx) => {
          const label = global._(`Constants.ShippingTypes.${type}`)
          const isLast = idx === Object.values(ShippingTypes).length - 1
          let option = shippingOptions.find(opt => opt.type === type)
          const pendingOption = pendingShippingOptions[type]
          if (pendingOption) {
            option = pendingOption
          }
          if (!option) return null
          if (readOnly) {
            return (
              <Row
                key={type}
                style={{
                  margin: '40px 0',
                  marginBottom: isLast ? 20 : undefined
                }}
              >
                <Indicator variant={option.active ? 'green' : 'grey'} />
                <Typography bold variant='subtitle' style={{ width: 250 }}>
                  {label}
                </Typography>
                <Typography
                  variant='body'
                  style={{ color: 'var(--color-middle-grey' }}
                  dangerouslySetInnerHTML={{
                    __html: global._(
                      `ShippingOptions.${
                        option.active ? 'Active' : 'Inactive'
                      }`,
                      [ContactPageURL]
                    )
                  }}
                />
                {option.active && (
                  <Typography
                    bold
                    variant='subtitle'
                    style={{
                      marginLeft: 'auto',
                      marginRight: 12
                    }}
                  >{`${option.price} ${global._(
                    'Common.Currency'
                  )}`}</Typography>
                )}
              </Row>
            )
          }
          return (
            <Row key={type} style={{ margin: '20px 0' }}>
              <Typography bold variant='subtitle' style={{ width: 250 }}>
                {label}
              </Typography>
              <Switch
                checked={option.active}
                onChange={e => {
                  // Deactivate only if another option is active
                  const pendingShippingOption = Object.keys(
                    pendingShippingOptions
                  ).find(
                    pso => pendingShippingOptions[pso].type === option.type
                  )
                  const activeAlternativePendingShippingOption = Object.keys(
                    pendingShippingOptions
                  ).find(
                    pso =>
                      pendingShippingOptions[pso].active &&
                      pendingShippingOptions[pso].type !== option.type
                  )
                  const activeAlternativeShippingOption = shippingOptions.find(
                    so => so.active && so.type !== option.type
                  )

                  const activeOption =
                    (Object.keys(pendingShippingOptions).length < 2 &&
                      pendingShippingOption &&
                      activeAlternativeShippingOption) ||
                    activeAlternativePendingShippingOption

                  if (activeOption) {
                    handlePendingUpdate(option, {
                      active: !option.active
                    })
                  } else if (
                    activeAlternativeShippingOption &&
                    Object.keys(pendingShippingOptions).length === 0
                  ) {
                    handlePendingUpdate(option, {
                      active: !option.active
                    })
                  } else {
                    handlePendingUpdate(option, {
                      active: true
                    })
                  }
                }}
              />
              <TextField
                variant='filled'
                style={{
                  width: 200,
                  marginLeft: 'auto'
                }}
                label={global._('Products.PriceIncludingTaxes')}
                value={option.price || ''}
                onChange={e => {
                  const price = e.target.value.replace(/\D/g, '').substr(0, 5)
                  handlePendingUpdate(option, { price })
                }}
              />
            </Row>
          )
        })}
      </div>
      <Row justify='flex-end' style={{ width: '100%', padding: '20px' }}>
        {!readOnly && (
          <Button
            variant='primary'
            style={{ paddingLeft: 48, paddingRight: 48 }}
            loading={isSaving || isLoading}
            disabled={
              !Object.keys(pendingShippingOptions).length ||
              isSaving ||
              isLoading
            }
            onClick={handleCommitPendingUpdates}
          >
            {global._('Common.Save')}
          </Button>
        )}
      </Row>
    </Column>
  )
}

ShippingOptions.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organisationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
}

export default withUser(withRouter(ShippingOptions))
