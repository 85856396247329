import { connect } from 'react-redux'
import {
  handleChange,
  handleSafetyLevel,
  recover
} from '../../redux/reducers/recover'
import { setSnackbar } from '../../redux/reducers/snackbar'
import Recover from './Recover'

const mapStateToProps = state => ({
  user: state.recover.user,
  backgroundColor: state.recover.backgroundColor,
  pwStrength: state.recover.pwStrength,
  readOnly: state.recover.readOnly,
  isLoadingRecover: state.recover.isLoadingRecover,
  error: state.recover.error
})

const mapDispatchToProps = dispatch => ({
  handleChange: user => {
    dispatch(handleChange(user))
  },
  handleSafetyLevel: (backgroundColor, pwStrength) => {
    dispatch(handleSafetyLevel(backgroundColor, pwStrength))
  },
  recover: (password, token) => {
    dispatch(recover(password, token))
  },
  setSnackbar: (message, variant) => {
    dispatch(setSnackbar(message, variant))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Recover)
