import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import NavigationBar from 'src/components/NavigationBar'
import MobileContainer from 'src/components/MobileContainer'
import IdControlForm from 'src/components/IdControlForm'
import Typography from 'src/components/Typography'
import Breadcrumbs from 'src/components/Breadcrumbs'
import FullscreenModal from 'src/components/FullscreenModal'
import Button from 'src/components/Button'
import ssn from 'src/utils/ssn'
import { Icon } from '@material-ui/core'
import ScrollView from 'src/components/ScrollView'
import axios from 'axios'
import {
  PaymentMethods,
  PaymentStatus,
  Country,
  ElectronicIdentityServices
} from 'src/utils/constants'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import Paper from 'src/components/Paper'
import Column from 'src/components/Column'
import Payment from 'src/components/Payment'
import DigitalIdentification, {
  IdentificationAction
} from 'src/components/DigitalIdentification'

const styles = {
  root: {},
  section: {
    padding: 'var(--section-padding-default)',
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--divider-color)'
    }
  }
}

const Steps = {
  IDENTIFICATION: 'IDENTIFICATION',
  PAYMENT: 'PAYMENT',
  COMPLETE: 'COMPLETE'
}

const OrderCheckout = ({ classes, history, user }) => {
  const { isMobile } = useWindowDimensions()

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isPaid, setIsPaid] = useState(false)
  const [personalNumber, setPersonalNumber] = useState(null)
  const [paymentId, setPaymentId] = useState(null)
  const [paymentInitiated, setPaymentInitiated] = useState(false)
  const [orderCase, setOrderCase] = useState(null)
  const [paymentCounter, setPaymentCounter] = useState(0)

  const urlParams = new URLSearchParams(window.location.search)
  const productKey = urlParams.get('product')
  const variant = urlParams.get('variant')
  const quantity = urlParams.get('quantity')
  let keyId = urlParams.get('keyId')
  const description = decodeURI(urlParams.get('description'))
  const color = decodeURI(urlParams.get('color'))
  const paymentMethod = urlParams.get('paymentMethod')
  const identify = urlParams.get('identify')
  const verified = urlParams.get('verified')
  const uid = urlParams.get('uid')
  const token = urlParams.get('token')
  const shipping = {
    type: urlParams.get('shippingType'),
    address: urlParams.get('shippingAddress'),
    postalCode: urlParams.get('shippingPostalCode'),
    city: urlParams.get('shippingCity'),
    phone: urlParams.get('shippingPhone')
  }

  let defaultStartStep = Steps.IDENTIFICATION
  if (
    paymentMethod === PaymentMethods.NETS_EASY &&
    identify === '1' &&
    verified === '1'
  ) {
    defaultStartStep = Steps.IDENTIFICATION_COMPLETE
  }

  const [step, setStep] = useState(defaultStartStep)

  if (keyId && (keyId === 'null' || keyId === 'undefined')) {
    keyId = undefined
  }

  if (
    !productKey ||
    !variant ||
    !quantity ||
    !paymentMethod ||
    (!description && !color)
  ) {
    history.push('/dashboard')
  }

  const cancel = async () => {
    setIsLoading(false)

    try {
      await axios.delete(`/v1/orders/payments/${paymentId}`)

      setIsLoading(false)
      setPaymentId(null)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setIsLoading(false)
      setError(global._(msg))
    }
  }

  const initiatePayment = async () => {
    setError(null)
    setIsLoading(true)
    setPaymentInitiated(false)
    try {
      const product = {
        key: productKey,
        variant,
        quantity
      }
      if (description && description !== 'null') {
        product.description = description
      }
      if (color && color !== 'null') {
        product.color = color
      }

      const { data: payment } = await axios.post('/v1/orders/payments', {
        paymentMethod,
        personalNumber,
        shipping,
        keyId,
        products: [product]
      })

      setPaymentId(payment.id)

      setPaymentInitiated(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (paymentInitiated && isMobile) {
      window.open('swish://', '_self')
    }
  }, [paymentInitiated])

  const completeCheckout = async ({ force }) => {
    if (isPaid || force) {
      try {
        const { data } = await axios.post(
          `/v1/orders/checkout?paymentId=${paymentId}&keyId=${keyId}`
        )
        setIsLoading(false)
        setOrderCase(data.caseObj)
        setStep(Steps.COMPLETE)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    }
  }
  const getPaymentStatus = async () => {
    setError(null)
    try {
      const { data: payment } = await axios.get(
        `/v1/orders/payments/${paymentId}`
      )
      if (payment.status === PaymentStatus.PAID) {
        setIsPaid(true)
        completeCheckout({ force: true })
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setPaymentCounter(paymentCounter + 1)
  }

  useEffect(() => {
    if (isLoading && paymentId && !isPaid) {
      const interval = setInterval(() => {
        getPaymentStatus()
      }, 2000)
      return () => clearInterval(interval)
    }
  }, [isLoading, paymentCounter, paymentId, isPaid])

  useEffect(() => {
    if (user.personalNumber) {
      setPersonalNumber(ssn.format(user.personalNumber, true))
    }
  }, [user])

  const product = {
    key: productKey,
    variant,
    quantity
  }
  if (description && description !== 'null') {
    product.description = description
  }
  if (color && color !== 'null') {
    product.color = color
  }

  const sectionWithCheck = text => (
    <div
      className={classes.section}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <span
        style={{
          width: 30,
          height: 30,
          background: 'var(--color-black)',
          color: 'var(--color-white)',
          borderRadius: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 20
        }}
      >
        <Icon style={{ fontSize: 20 }}>check</Icon>
      </span>
      <Typography variant='body' bold>
        {text}
      </Typography>
    </div>
  )

  let content
  if (step === Steps.IDENTIFICATION) {
    if (paymentMethod === PaymentMethods.NETS_EASY) {
      content = (
        <DigitalIdentification
          services={[ElectronicIdentityServices.NO_BANKID]}
          onCancel={() => history.goBack()}
          state={{
            action: IdentificationAction.VERIFY,
            redirect: `${window.location.pathname}${window.location.search}&verified=1`
          }}
        />
      )
    } else {
      content = (
        <div>
          <IdControlForm
            title={global._('Keys.Order.Checkout.AuthAndPay')}
            isLoading={isLoading}
            locKey='Keys.Order.'
            cancel={cancel}
            goBack={() => history.push('/orders')}
            personalNumber={personalNumber}
            setPersonalNumber={setPersonalNumber}
            sendRequest={initiatePayment}
            error={error}
            showCancelButtonByDefault
          />
        </div>
      )
    }
  } else if (step === Steps.IDENTIFICATION_COMPLETE) {
    if (identify === '1' && verified === '1' && uid != user.id) {
      content = (
        <div style={{ padding: 20 }}>
          <Typography variant='body'>
            {global._('Keys.Order.Checkout.IdentityMismatch')}
          </Typography>
        </div>
      )
    } else {
      content = (
        <div>
          {sectionWithCheck(global._('Keys.Order.Checkout.IdentityVerified'))}
          <div
            className={classes.section}
            style={{ padding: isMobile ? undefined : '32px 68px 68px' }}
          >
            <Typography variant='subtitleBig' normalLineHeight>
              {global._(`Keys.Order.Checkout.IdentityVerifiedText`)}
            </Typography>
            <Column>
              <Button
                variant='primary'
                style={{ marginTop: 20, width: '100%' }}
                onClick={() => setStep(Steps.PAYMENT)}
              >
                {global._('Common.Pay')}
              </Button>
            </Column>
          </div>
        </div>
      )
    }
  } else if (
    user.organisation.country === Country.NO &&
    step === Steps.PAYMENT
  ) {
    content = (
      <Payment
        method={PaymentMethods.NETS_EASY}
        products={[product]}
        shipping={shipping}
        token={token}
        onCompletion={payment => {
          console.log('payment complete', payment)
          setIsLoading(true)
          setPaymentId(payment.id)
        }}
      />
    )
  } else if (step === Steps.COMPLETE) {
    content = (
      <div>
        {sectionWithCheck(global._('Keys.Order.Checkout.IdentityVerified'))}
        {sectionWithCheck(global._('Keys.Order.Checkout.PaymentCompleted'))}
        <div
          className={classes.section}
          style={{ padding: isMobile ? undefined : '32px 68px 68px' }}
        >
          <Typography variant='subtitleBig' normalLineHeight>
            {global._(`Keys.Order.Checkout.ConfirmationInfo.${variant}`)}
          </Typography>
          <Column>
            <Button
              variant='primary'
              style={{ marginTop: 20, width: '100%' }}
              onClick={() => history.push(`/orders/${orderCase.id}`)}
            >
              {global._('Keys.Order.Checkout.ToTheOrderCase')}
            </Button>
            <Button
              variant='outlined'
              style={{ marginTop: 20, width: '100%' }}
              onClick={() => history.push('/dashboard')}
            >
              {global._('Common.Done')}
            </Button>
          </Column>
        </div>
      </div>
    )
  }
  if (isMobile) {
    return (
      <MobileContainer background='var(--color-white)'>
        <NavigationBar
          title={global._('Keys.Order.Checkout.AuthAndPay')}
          backAction={() => history.goBack()}
        />
        <ScrollView id='scrollable-container'>
          {step === Steps.COMPLETE ? (
            <FullscreenModal
              title={global._('Keys.Order.Checkout.Confirmation')}
              onClose={() => history.push('/orders')}
              rightActionItem='close'
              higherZIndex
              content={content}
            />
          ) : (
            content
          )}
        </ScrollView>
      </MobileContainer>
    )
  }

  const crumbs = [
    {
      name: global._('Breadcrumbs.Dashboard'),
      path: '/'
    },
    {
      name: global._('Breadcrumbs.OrderKey'),
      path: '/orders'
    },
    {
      name: global._(`Products.Variants.Keys.${variant}`),
      path: `/orders/new?variant=${variant}&keyId=${keyId}`
    },
    {
      name: global._('Breadcrumbs.OrderDetails'),
      path: `/orders/new/details${window.location.search.replace(
        /(&identify=\d)|(&verified=\d)|(&uid=(\d|undefined))/g,
        ''
      )}`
    }
  ]

  if (
    paymentMethod === PaymentMethods.NETS_EASY &&
    [Steps.IDENTIFICATION, Steps.IDENTIFICATION_COMPLETE].includes(step)
  ) {
    crumbs.push({
      name: global._('Keys.Order.Checkout.VerifyIdentity')
    })
  } else {
    crumbs.push({
      name: global._('Common.Pay')
    })
  }

  return (
    <div>
      <Breadcrumbs breadcrumbItems={crumbs} />
      <div style={{ height: 40 }} />
      {[Steps.IDENTIFICATION_COMPLETE, Steps.COMPLETE].includes(step) ? (
        <Column flex align='flex-start' justify='flex-start'>
          {step === Steps.COMPLETE && (
            <Typography
              variant='h1'
              align='center'
              style={{ marginBottom: 30 }}
            >
              {global._('Keys.Order.Checkout.Confirmation')}
            </Typography>
          )}
          <Paper noPadding style={{ maxWidth: 500 }}>
            {content}
          </Paper>
        </Column>
      ) : (
        content
      )}
    </div>
  )
}

OrderCheckout.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

export default injectSheet(styles)(OrderCheckout)
