/* eslint-disable no-nested-ternary */
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FaceIcon from '@material-ui/icons/Face'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Divider from '@material-ui/core/Divider'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'
import { styles } from '../../layouts/Styles'
import Filter from '../../common/Filter'
import UserModal from './UserModal'
import Excel from './components/Excel'
import { UserRoles } from '../../utils/constants'
import ActionMenu from '../../common/TableActionMenu/ActionMenu'
import PageContentHeader from '../../components/PageContentHeader'

const columnNames = ['Name', 'Email', 'Phone', 'Role', 'organisationId']

const COLOR = '#E8F1FA'
const ICON = <PeopleIcon style={{ color: '#A2B7CA' }} />

class Users extends React.Component {
  componentDidMount() {
    const { fetchAll, fetchOrganisations, filter } = this.props
    fetchAll(filter)
    if (
      JSON.parse(localStorage.getItem('user')).roles.includes(
        UserRoles.InternalAdmin
      )
    ) {
      fetchOrganisations()
    }
  }

  onCreateUser = () => {
    const currentUser = JSON.parse(localStorage.getItem('user'))
    const user = { ...this.props.user }
    if (user && !user.organisationId) {
      user.organisationId = currentUser.organisationId
    }
    this.props.create(user)
  }

  onUpdateUser = () => {
    const user = { ...this.props.user }
    this.props.update(user)
  }

  resentInvitationLink = user => this.props.resendInvitationLink(user)

  handleUpdate = user => {
    this.props.toggle(user)
  }

  handleCreate = () => {
    const currentUser = JSON.parse(localStorage.getItem('user'))
    let organisationId = currentUser.organisation.id
    if (this.props.filter && this.props.filter.organisationId) {
      organisationId = this.props.filter.organisationId
    }
    this.props.toggle({ organisationId })
  }

  onDeleteUser = user => {
    this.props.toggleDeleteConfirmation({
      content: (
        <p>
          Bekräfta att du vill ta bort användaren <b>{user.name}</b> (
          {user.email}
          ).
        </p>
      ),
      onConfirm: this.props.remove,
      deleteConfirmationObject: user
    })
  }

  handleChange = name => e => {
    const { value } = e.target
    const user = { ...this.props.user }
    if (value) user[name] = value
    else delete user[name]
    this.props.handleChange(user)
  }

  handleFilter = name => e => {
    const { value } = e.target
    const filter = { ...this.props.filter }
    if (value) filter[name] = value
    else delete filter[name]

    this.props.fetchAll(filter)
  }

  handleSort = key => () => {
    const filter = { ...this.props.filter }
    filter.orderBy = key
    filter.order = filter.order === 'asc' ? 'desc' : 'asc'
    this.props.fetchAll(filter)
  }

  render() {
    const {
      list,
      user,
      filter,
      isOpen,
      toggle,
      organisations,
      breadcrumbItems
    } = this.props
    const { classes, match } = this.props
    const currentUser = JSON.parse(localStorage.getItem('user'))
    const isUserMember = currentUser.roles.includes(UserRoles.Resident)

    const options = [
      {
        action: 'Impersonate',
        permissions: [UserRoles.InternalAdmin],
        onClick: evUser => this.props.impersonate(evUser.id)
      },
      {
        action: 'Delete',
        permissions: [UserRoles.InternalAdmin],
        onClick: evUser => this.onDeleteUser(evUser)
      },
      {
        action: 'Resend_invitation_link',
        permissions: [UserRoles.InternalAdmin],
        onClick: evUser => this.resentInvitationLink(evUser)
      }
    ]

    return (
      <div>
        <PageContentHeader breadcrumbItems={breadcrumbItems} />
        <Paper className={classes.paperFirst}>
          <div>
            {!isUserMember && (
              <React.Fragment>
                <Button
                  onClick={this.handleCreate}
                  color='primary'
                  variant='contained'
                  style={{ float: 'right' }}>
                  Lägg till
                </Button>
                <Excel users={list} currentUser={currentUser} />
              </React.Fragment>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 10
              }}>
              <div
                style={{
                  width: '40px!important',
                  height: '40px!important',
                  borderRadius: 20,
                  backgroundColor: COLOR,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                {ICON}
              </div>
              <Typography
                variant='h6'
                style={{ marginLeft: 10, color: '#444', fontSize: 15 }}>
                Användare
              </Typography>
            </div>
            <Divider />
            <div style={{ paddingTop: 10 }} />
            <Typography
              style={{ marginBottom: 16 }}
              variant='body2'
              className='grey'>
              Här kan du som är administratör se och redigera era användare.
            </Typography>
            <Typography variant='body2' className='grey'>
              <strong>Klicka på pennan</strong> för att redigera eller ändra en
              befintlig användare.
              <br />
              <strong>Klicka på papperskorgen</strong> för att radera en
              användare.
              <br />
              <strong>Klicka på knappen Lägg till</strong> för att lägga till en
              användare*.
              <br />
              <br />
              <i className='fs-12'>
                *Nya användare får ett mail med länk och
                inloggningsinstruktioner.
              </i>
              <br />
              <i className='fs-12'>*Användare kan endast skapa ärenden.</i>
            </Typography>
            <div style={{ padding: 5 }} />
            <Divider />
            <div style={{ padding: 10 }} />
            <Filter
              user={currentUser}
              filter={filter}
              organisations={organisations}
              handleFilter={this.handleFilter}
              placeholder='Namn eller email'
            />
          </div>
        </Paper>
        <Paper className={classes.paperTable}>
          <Table>
            <TableHead>
              <TableRow>
                {columnNames.map(key => {
                  if (
                    !currentUser.roles.includes(UserRoles.InternalAdmin) &&
                    key === 'organisationId'
                  )
                    return <TableCell key={key} />
                  return (
                    <TableCell key={key}>
                      <TableSortLabel
                        active={filter.orderBy === key}
                        direction={filter.order}
                        onClick={this.handleSort(key)}>
                        {global._(key)}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map(listUser => (
                <TableRow key={listUser.id}>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 28,
                          height: 28,
                          borderRadius: 14,
                          backgroundColor: '#E8F1FA',
                          marginRight: 12
                        }}>
                        <FaceIcon style={{ color: '#A2B7CA', fontSize: 20 }} />
                      </div>
                      <Link
                        to={`/admin/users/${listUser.organisation.id}/${listUser.id}`}>
                        <span style={{ fontWeight: 400 }}>{listUser.name}</span>
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>{listUser.email}</TableCell>
                  <TableCell>{listUser.phone}</TableCell>
                  <TableCell>
                    {listUser.roles.map(role => (
                      <span
                        key={`user-${listUser.id}-role-${role}`}
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                          borderRadius: 4,
                          padding: 5,
                          backgroundColor: '#eee',
                          marginRight: 3
                        }}>
                        {global._(`User.Roles.${role}`)}
                      </span>
                    ))}
                  </TableCell>
                  <TableCell>
                    {listUser.organisation && listUser.organisation.name}
                  </TableCell>
                  <TableCell align='right'>
                    <ActionMenu
                      options={options}
                      object={listUser}
                      disabled={listUser.id === currentUser.id}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {isOpen && (
          <UserModal
            handleChange={this.handleChange}
            isLoadingCreateOrUpdate={this.props.isLoadingCreateOrUpdate}
            modal={isOpen}
            onHandleUser={
              user && user.id ? this.onUpdateUser : this.onCreateUser
            }
            toggle={toggle}
            user={user}
          />
        )}
      </div>
    )
  }
}

Users.defaultProps = {
  organisations: [],
  user: null
}

Users.propTypes = {
  breadcrumbItems: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object,
  filter: PropTypes.object.isRequired,
  fetchAll: PropTypes.func.isRequired,
  toggleDeleteConfirmation: PropTypes.func.isRequired,
  fetchOrganisations: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  impersonate: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  resendInvitationLink: PropTypes.func.isRequired,
  organisations: PropTypes.array,
  list: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoadingCreateOrUpdate: PropTypes.bool.isRequired
}

export default withStyles(styles)(Users)
