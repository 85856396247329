import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import {
  ArrowRight,
  ArrowRightAltOutlined,
  ArrowRightRounded
} from '@material-ui/icons'
import moment from 'moment'
import { IServiceRequest, IServiceRequestStatus } from './types'
import injectSheet, { WithStylesProps } from 'react-jss'
import { history } from 'src/redux/store'
import { ServiceRequestStatusBadge } from './ServiceRequestStatusBadge'

interface TableProps extends WithStylesProps<typeof styles> {
  data: IServiceRequest[]
}

const styles = {
  tableHeaderCell: {
    fontWeight: 'bold!important',
    fontSize: '14px!important'
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee'
    }
  }
}
export const ServiceRequestTable = injectSheet(styles)((props: TableProps) => {
  const { data, classes } = props

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            className={classes.tableHeaderCell}
            style={{ width: 50, minWidth: 50, maxWidth: 50 }}
          >
            ID
          </TableCell>
          <TableCell
            className={classes.tableHeaderCell}
            style={{ minWidth: 130, maxWidth: 130 }}
          >
            {translate('ServiceRequests.Table.Apartment')}
          </TableCell>
          <TableCell
            className={classes.tableHeaderCell}
            style={{ minWidth: 150, maxWidth: 150 }}
          >
            {translate('ServiceRequests.Table.Status')}
          </TableCell>
          <TableCell
            className={classes.tableHeaderCell}
            style={{ minWidth: 200, maxWidth: 200 }}
          >
            {translate('ServiceRequests.Table.AnsweredBy')}
          </TableCell>
          <TableCell
            className={classes.tableHeaderCell}
            style={{ width: 300, minWidth: 300, maxWidth: 300 }}
          >
            {translate('ServiceRequests.Table.UserMessage')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((serviceRequest: IServiceRequest) => (
          <TableRow
            key={serviceRequest.id}
            className={classes.tableRow}
            onClick={() => {
              history.push(`/service-requests/${serviceRequest.id}`)
            }}
          >
            <TableCell style={{ width: 50, minWidth: 50, maxWidth: 50 }}>
              {serviceRequest.id}
            </TableCell>
            <TableCell style={{ width: 130, minWidth: 130, maxWidth: 130 }}>
              {serviceRequest.apartment?.refId}
            </TableCell>
            <TableCell style={{ width: 150, minWidth: 150, maxWidth: 150 }}>
              <ServiceRequestStatusBadge request={serviceRequest} />
            </TableCell>
            <TableCell style={{ minWidth: 200, maxWidth: 200 }}>
              {serviceRequest.user?.name ?? ''}
            </TableCell>
            <TableCell style={{ minWidth: 140, maxWidth: 140 }}>
              {serviceRequest.userMessage ?? ''}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})
