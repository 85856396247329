import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import axios from 'axios'
import { Grid, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import queryString from 'querystring'
import IconColumn from 'src/components/IconColumn'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import PageContentHeader from '../../../components/PageContentHeader'
import Typography from '../../../components/Typography'
import Button from '../../../components/Button'
import Notification from '../../../common/Notification'
import ObjectList from '../../../components/ObjectList'
import ObjectListMobile from '../../../components/ObjectListMobile'
import AsyncSelect from '../../../components/AsyncSelect'
import NavigationBar from '../../../components/NavigationBar'
import ScrollView from '../../../components/ScrollView'
import Modal from '../../../components/Modal'
import MobileContainer from '../../../components/MobileContainer'

const styles = {
  root: {},
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  }
}

const AccessByItem = props => {
  const { match, classes, user, history } = props

  const { id, type } = match.params
  const { isMobile } = useWindowDimensions()

  const [error, setError] = useState(null)
  const [item, setItem] = useState(null)
  const [items, setItems] = useState(null)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [expandedRows, setExpandedRows] = useState([])
  const [filter, setFilter] = useState({
    orderBy: 'name',
    order: 'asc',
    type: '',
    limit: 25,
    offset: 0,
    search: ''
  })
  const organisationId = filter.organisationId || match.params.organisationId
  const isUsers = type === 'users'
  const isLocks = type === 'locks'

  useEffect(() => {
    try {
      if (organisationId && id && type) {
        ;(async () => {
          if (isLocks) {
            const { data: lockItem } = await axios.get(
              `/v1/organisations/${organisationId}/${type}/${id}`
            )
            const { data: locks } = await axios.get(
              `/v1/organisations/${organisationId}/accessgroups/${type}/${id}/users`
            )
            setItem(lockItem)
            setItems(locks)
          } else if (isUsers) {
            const { data: userItem } = await axios.get(
              `/v1/organisations/${organisationId}/${type}/${id}`
            )
            const { data: users } = await axios.get(
              `/v1/organisations/${organisationId}/accessgroups/${type}/${id}/locks`
            )
            setItem(userItem)
            setItems(users)
          }
        })()
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }, [id, type])

  const refreshItems = async () => {
    try {
      const selectedFilter = { ...filter }

      selectedFilter.organisation = ''

      const search = queryString.stringify(selectedFilter)

      if (id && type) {
        if (id && !isUsers) {
          const { data: locks } = await axios.get(
            `/v1/organisations/${organisationId}/accessgroups/${type}/${id}/users?${search}`
          )
          setItems(locks)
        } else if (id && isUsers) {
          const { data: users } = await axios.get(
            `/v1/organisations/${organisationId}/accessgroups/${type}/${id}/locks?${search}`
          )
          setItems(users)
        }
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
  }

  useEffect(() => {
    refreshItems()
  }, [filter])

  const handleFilter = name => e => {
    const aFilter = { ...filter }
    if (name === 'organisation') {
      aFilter.organisationId = e && e.value && e.value.id
      aFilter.organisation = e
    } else if (name === 'property') {
      aFilter.propertyId = e && e.value ? e.value.id : ''
      aFilter.property = e
    } else {
      const { value } = e.target
      aFilter[name] = value
    }

    aFilter.offset = 0
    setFilter(aFilter)
  }

  const actionButtons = (objId, obj) => (
    <Button
      variant='outlined'
      onClick={() =>
        history.push(
          `/admin/users/${obj && obj.organisation.id}/${obj && obj.id}`
        )
      }
      style={{ marginRight: 18 }}
    >
      {global._('AccessGroups.ShowProfile')}
    </Button>
  )

  const crumbs = [
    { name: 'Startsida', path: '/' },
    { name: 'Admin', path: '/admin' },
    {
      name: `Behörighet per ${global._(`AccessGroups.${type}`)}`,
      path: `/admin/accessgroups/${organisationId}/${type}`
    },
    {
      name: isUsers ? 'Behörighet' : 'Behöriga användare',
      path: `/admin/accessgroups/accessbyitem/${type}/:id`
    }
  ]

  const columnIcon = isMobile
    ? {
        key: 'icon',
        format: () => (isUsers ? 'lock' : 'face')
      }
    : {
        key: 'icon',
        sortingDisabled: true,
        clickable: false,
        style: { width: 70 },
        format: (objVal, obj) =>
          obj && <IconColumn icon={isUsers ? 'lock' : 'person'} />
      }

  const columns = [
    {
      key: 'name',
      localizationKey: 'Name',
      sortingKey: 'name',
      clickable: true,
      longText: true,
      style: { width: '40%' }
    },
    {
      key: 'email',
      localizationKey: 'AccessGroups.Email',
      sortingKey: 'email',
      clickable: true,
      longText: false,
      hiddenFor: isUsers,
      style: { width: '40%' }
    },
    {
      key: 'organisation',
      localizationKey: 'Common.Organisation',
      clickable: true,
      sortable: false,
      format: (val, itm) => itm && itm.organisation && itm.organisation.name,
      hiddenFor: isUsers,
      longText: true,
      style: { width: '40%' }
    },
    {
      key: 'property',
      localizationKey: 'Common.Property',
      clickable: true,
      sortingKey: 'propertyName',
      format: (val, itm) => {
        if (itm && itm.device && itm.device.property) {
          return itm.device.property.name
        }
        return '-'
      },
      hiddenFor: isLocks,
      longText: true,
      style: { width: '40%' }
    }
  ]

  const Filter = (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <TextField
          style={{ width: '100%' }}
          name='name'
          label={
            isUsers
              ? global._('AccessGroups.SearchName')
              : global._('AccessGroups.SearchUser')
          }
          variant='filled'
          value={filter.search}
          onChange={handleFilter('search')}
          InputProps={{
            endAdornment: <Search style={{ cursor: 'pointer' }} />
          }}
        />
      </Grid>
      {type === 'users' && (
        <Grid item md={3} xs={12}>
          <AsyncSelect
            isSearchable
            helpText={global._('AccessGroups.FilterOnProperty')}
            value={filter.property}
            labelKey='name'
            name='property'
            placeholder={global._('AccessGroups.SelectProperty')}
            onChange={handleFilter('property')}
            url='/v1/properties?query={{input}}&orderBy=name&order=asc'
          />
        </Grid>
      )}
      {/* Disable until requirements are clear */}
      {/* {isPartnerAdmin && (
      <Grid item md={3} xs={12}>
        <AsyncSelect
          isSearchable
          helpText={global._('AccessGroups.FilterOnOrganisation')}
          value={filter.organisation}
          labelKey='name'
          name='organisation'
          placeholder={global._('Common.SelectOrganisation')}
          onChange={handleFilter('organisation')}
          url={'/v1/organisations?search={{input}}&orderBy=name&order=asc'}
        />
      </Grid>
      )} */}
    </Grid>
  )

  if (isMobile) {
    return (
      <MobileContainer background='white'>
        {showInfoModal && (
          <Modal
            title={global._('AccessGroups.InfoDialogTitle')}
            onClose={() => setShowInfoModal(false)}
            content={
              <div style={{ textAlign: 'left' }}>
                <Typography
                  style={{ display: 'block', paddingBottom: 12 }}
                  variant='body'
                >
                  Hej
                </Typography>
              </div>
            }
            buttons={[
              <Button variant='none' onClick={() => setShowInfoModal(false)}>
                {global._('Common.Close')}
              </Button>
            ]}
          />
        )}
        <NavigationBar
          title={item && item.name}
          backAction={() => history.goBack()}
        />
        <ScrollView id='scrollable-container'>
          <div style={{ paddingBottom: 20 }}>
            <div style={{ padding: 'var(--section-padding-default)' }}>
              {error && (
                <Notification
                  type='error'
                  message={error}
                  style={{ margin: '20px 0' }}
                />
              )}
              {Filter}
            </div>
            <ObjectListMobile
              rowsAreClickable={false}
              objectPermalink={`organisations/${organisationId}/users`}
              objects={items}
              user={user}
              history={history}
              noResultsMessage={
                isUsers
                  ? global._('AccessGroups.NoAvailableLocks')
                  : global._('AccessGroups.NoAvailableUsers')
              }
              columns={[columnIcon, ...columns]}
              hasPadding
            />
            {}
          </div>
        </ScrollView>
      </MobileContainer>
    )
  }

  return (
    <div className={classes.root}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      <PageContentHeader breadcrumbItems={crumbs} />
      <ObjectList
        filterFields={Filter}
        sectionTitle={item && item.name}
        // infoDialogTitle={global._('AccessGroups.InfoDialogTitle')}
        // infoDialogContent={(
        //   <div style={{ textAlign: 'left' }}>
        //     <Typography style={{ display: 'block', paddingBottom: 12 }} variant='body'>
        //       Hej
        //     </Typography>
        //   </div>
        // )}
        history={history}
        columns={[columnIcon, ...columns]}
        objects={items}
        noResultsMessage={
          isUsers
            ? global._('AccessGroups.NoAvailableLocks')
            : global._('AccessGroups.NoAvailableUsers')
        }
        expandable={isLocks}
        expandedRows={isLocks ? expandedRows : undefined}
        setExpandedRows={isLocks ? setExpandedRows : undefined}
        actionButtons={isLocks ? actionButtons : undefined}
        handleFilter={handleFilter}
        setFilter={setFilter}
        filter={filter}
      />
    </div>
  )
}

AccessByItem.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const AccessByItemWithStyles = injectSheet(styles)(AccessByItem)
export default AccessByItemWithStyles
