import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import { Languages, PaymentMethods } from 'src/utils/constants'
import axios from 'axios'
import Notification from 'src/common/Notification'
import LoadingSpinner from './LoadingSpinner'
import Paper from './Paper'

const styles = {
  root: {},
  externalCheckoutContainer: {
    maxWidth: 440,
    overflow: 'hidden',
    margin: '0 auto',
    borderRadius: 12,
    maxHeight: 686,
    overflowY: 'scroll',
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
    backgroundColor: 'var(--color-white)',
    border: '1px solid var(--color-ultra-light-grey)',
    padding: 10
  }
}

const Payment = props => {
  const { classes, method, products, shipping, ssn, onCompletion, token } =
    props

  const checkoutRef = useRef(null)

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [configuration, setConfiguration] = useState({})
  const [payment, setPayment] = useState(null)
  const [externalDependenciesLoaded, setExternalDependenciesLoaded] =
    useState(false)

  useEffect(() => {
    let checkoutScript
    setIsLoading(true)
    ;(async () => {
      try {
        const payload = {
          products,
          shipping,
          paymentMethod: method
        }
        if (ssn) {
          payload.personalNumber = ssn
        }
        if (token) {
          payload.token = token
        }
        const { data: _payment } = await axios.post(
          '/v1/orders/payments',
          payload
        )
        setPayment(_payment)
        if (method === PaymentMethods.NETS_EASY) {
          const { data: config } = await axios.get(
            `/v1/orders/payments/configuration?paymentMethod=${method}`
          )
          checkoutScript = document.createElement('script')
          checkoutScript.src = config.checkoutScriptSrc
          checkoutScript.onload = () => {
            checkoutScript.onload = null
            setExternalDependenciesLoaded(true)
          }
          document.body.appendChild(checkoutScript)
          setConfiguration(config)
        }
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
      setIsLoading(false)
    })()
    return () => {
      if (checkoutScript) {
        document.body.removeChild(checkoutScript)
      }
    }
  }, [])

  useEffect(() => {
    /* load nets checkout */
    if (
      payment &&
      method === PaymentMethods.NETS_EASY &&
      configuration &&
      !checkoutRef.current &&
      externalDependenciesLoaded
    ) {
      const opts = {
        checkoutKey: configuration.checkoutKey,
        paymentId: payment.externalId,
        containerId: 'checkout-container'
      }
      const language = Languages[global.getLanguage()]
      if (language) {
        opts.language = language
      }

      checkoutRef.current = new Dibs.Checkout(opts)
      checkoutRef.current.on('payment-completed', () => {
        if (onCompletion) {
          onCompletion(payment)
        }
      })
    }
  }, [payment, configuration, externalDependenciesLoaded])

  if (method === PaymentMethods.NETS_EASY) {
    const content = []

    if (error) {
      content.push(
        <Notification
          key='checkout-notifications'
          type='error'
          message={error}
        />
      )
    }

    if (payment) {
      content.push(
        <div
          key='checkout-container'
          className={classes.externalCheckoutContainer}
        >
          <div id='checkout-container' />
        </div>
      )
    } else {
      content.push(<LoadingSpinner />)
    }
    return <div className={classes.root}>{content}</div>
  }
  return <div className={classes.root} />
}

Payment.propTypes = {
  classes: PropTypes.object.isRequired,
  method: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  ssn: PropTypes.string,
  onCompletion: PropTypes.func.isRequired
}

Payment.defaultProps = {
  ssn: null
}

export default injectSheet(styles)(Payment)
