import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import injectSheet from 'react-jss'
import Typography from './Typography'
import Modal from './Modal'
import Button from './Button'
import VideoInstructionsModal from './VideoInstructionsModal'

const styles = {
  listHeader: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: props => (props.noTitleMargin ? undefined : 30),
    alignItems: 'center'
  },
  infoIcon: {
    fontSize: 30,
    transition: 'all 0.3s',
    cursor: 'pointer',
    marginTop: 3,
    marginLeft: 10,
    '&:hover': {
      color: 'var(--color-primary)'
    }
  }
}

const SectionTitle = ({
  classes,
  title,
  infoDialogTitle,
  infoDialogContent,
  titleContent,
  instructionsVideoId
}) => {
  const [showInfoDialog, setShowInfoDialog] = useState(false)

  return (
    <div className={classes.listHeader}>
      <Typography variant='title' bold>
        {title}
      </Typography>
      {infoDialogTitle && infoDialogContent ? (
        <Icon
          className={classes.infoIcon}
          onClick={() => setShowInfoDialog(true)}>
          info
        </Icon>
      ) : null}
      {
        instructionsVideoId && <VideoInstructionsModal videoId={instructionsVideoId} />
      }
      {infoDialogTitle && infoDialogContent && showInfoDialog ? (
        <Modal
          title={infoDialogTitle}
          onClose={() => setShowInfoDialog(false)}
          content={infoDialogContent}
          buttons={[
            <Button variant='none' onClick={() => setShowInfoDialog(false)}>
              {global._('Common.Close')}
            </Button>
          ]}
        />
      ) : null}
      {titleContent ? <>{titleContent}</> : null}
    </div>
  )
}

SectionTitle.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  infoDialogTitle: PropTypes.string,
  infoDialogContent: PropTypes.any,
  titleContent: PropTypes.any
}

SectionTitle.defaultProps = {
  classes: {},
  title: null,
  infoDialogTitle: null,
  infoDialogContent: null,
  titleContent: null
}

const SectionTitleWithStyles = injectSheet(styles)(SectionTitle)
export default SectionTitleWithStyles
