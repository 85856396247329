import { connect } from 'react-redux'
import {
  handleChange,
  handleFile,
  fetch,
  filterFetch,
  editBlueprint,
  toggle,
  createBlueprint,
  remove
} from '../../../redux/reducers/realestates/detail'
import { setSnackbar } from '../../../redux/reducers/snackbar'
import Detail from './PropertyDetails'

const mapStateToProps = state => ({
  realEstate: state.realEstateDetail.realEstate,
  blueprint: state.realEstateDetail.blueprint,
  isOpen: state.realEstateDetail.isOpen,
  filter: state.realEstateDetail.filter,
  isLoadingFetch: state.realEstateDetail.isLoadingFetch,
  isLoadingFilterFetch: state.realEstateDetail.isLoadingFilterFetch,
  isLoadingEdit: state.realEstateDetail.isLoadingEdit,
  isLoadingCreateOrUpdate: state.realEstateDetail.isLoadingCreateOrUpdate,
  isLoadingRemove: state.realEstateDetail.isLoadingRemove
})

const mapDispatchToProps = dispatch => ({
  handleChange: blueprint => {
    dispatch(handleChange(blueprint))
  },
  handleFile: blueprint => {
    dispatch(handleFile(blueprint))
  },
  fetch: id => {
    dispatch(fetch(id))
  },
  filterFetch: filter => {
    dispatch(filterFetch(filter))
  },
  editBlueprint: blueprint => {
    dispatch(editBlueprint(blueprint))
  },
  toggle: blueprint => {
    dispatch(toggle(blueprint))
  },
  createBlueprint: blueprint => {
    dispatch(createBlueprint(blueprint))
  },
  remove: blueprint => {
    dispatch(remove(blueprint))
  },
  setSnackbar: (message, variant) => {
    dispatch(setSnackbar(message, variant))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
