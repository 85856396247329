import React, { useRef, useState, useEffect } from 'react'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import { getPointFromTouch } from '../utils/math'

const TRIGGER_OFFSET = 140

const styles = {
  root: {},
  container: {
    overflow: 'auto',
    zIndex: 10,
    position: 'relative'
  },
  content: {
    position: 'absolute',
    top: -5,
    left: 0,
    right: 0,
    height: TRIGGER_OFFSET,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '0.85rem'
  },
  icon: {
    color: 'rgba(0,0,0,0.65)'
  }
}

const resistance = x => x * (x * 0.002) ** 2
const withinRange = (val, target, range) =>
  val <= target + range && val >= target - range
const pullbackTime = 350
const PullToRefresh = props => {
  const {
    classes,
    onRefresh,
    loading,
    helpTextStyle,
    containerStyles,
    ...otherProps
  } = props

  /* refs */
  const rootRef = useRef()
  const containerRef = useRef()
  const contentRef = useRef()
  const startPointRef = useRef()
  const topRef = useRef(0)
  const lastDistanceRef = useRef(0)
  const initialTopRef = useRef(0)
  const animationRef = useRef()

  /* forced re-renders */
  const [, setRedraw] = useState({})

  /* pull-back animation */
  const pullbackAnimation = (
    time,
    startTime = time,
    initialValue = topRef.current
  ) => {
    const runtime = time - startTime
    const progress = Math.min(1, runtime / pullbackTime)
    topRef.current = initialValue - initialValue * progress

    if (containerRef.current) {
      containerRef.current.style.transform = `translate3d(0, ${topRef.current}px, 0)`
    }

    if (runtime < pullbackTime) {
      animationRef.current = requestAnimationFrame(t =>
        pullbackAnimation(t, startTime, initialValue)
      )
    }
    setRedraw({})
  }

  useEffect(() => {
    initialTopRef.current = rootRef.current.getBoundingClientRect().top
  }, [])

  useEffect(() => {
    const { current: container } = containerRef

    const handleTouchStart = event => {
      if (props.loading) return

      /* only trigger pull to refresh if drag was started at top */
      if (
        event.touches.length === 1 &&
        withinRange(
          containerRef.current.getBoundingClientRect().top,
          initialTopRef.current,
          4
        )
      ) {
        startPointRef.current = getPointFromTouch(event.touches[0])
      }
    }
    const handleTouchMove = event => {
      if (props.loading) return

      const { current: startPoint } = startPointRef
      if (event.touches.length === 1 && startPoint) {
        if (topRef.current >= TRIGGER_OFFSET) {
          event.preventDefault()
          event.stopPropagation()
        }
        const point = getPointFromTouch(event.touches[0])
        const verticalDistance = point.y - startPoint.y
        const delta = verticalDistance - lastDistanceRef.current

        if (Math.abs(delta) >= 0) {
          const { current: top } = topRef
          let moveY = verticalDistance - resistance(verticalDistance)
          if (delta > 0) {
            /* prevent other views from scrolling extra (double distance) */
            event.preventDefault()
            event.stopPropagation()
            /* Disallow moving up when dragging down due to resistance */
            moveY = Math.max(moveY, top)
          }
          const nextY = Math.max(0, Math.min(TRIGGER_OFFSET, moveY))
          animationRef.current = requestAnimationFrame(() => {
            topRef.current = nextY

            if (containerRef.current) {
              containerRef.current.style.transform = `translate3d(0, ${nextY}px, 0)`
            }

            if (contentRef.current) {
              contentRef.current.style.opacity =
                topRef.current / TRIGGER_OFFSET - 0.4
            }

            lastDistanceRef.current = verticalDistance
            setRedraw({})
          })
        }
      }
    }
    const handleTouchEnd = () => {
      if (props.loading) return
      /* Trigger handler */
      if (topRef.current >= TRIGGER_OFFSET) {
        if (typeof onRefresh === 'function') {
          onRefresh()
        }
      } else {
        /* Pull back to initial position */
        requestAnimationFrame(pullbackAnimation)
      }
      /* Reset refs */
      lastDistanceRef.current = 0
      startPointRef.current = null
    }
    container.addEventListener('touchstart', handleTouchStart)
    container.addEventListener('touchmove', handleTouchMove)
    container.addEventListener('touchend', handleTouchEnd)
    return () => {
      container.removeEventListener('touchstart', handleTouchStart)
      container.removeEventListener('touchmove', handleTouchMove)
      container.removeEventListener('touchend', handleTouchEnd)
    }
  }, [props.loading])

  useEffect(() => {
    if (props.loading) {
      containerRef.current.style.transform = `translate3d(0, ${TRIGGER_OFFSET}px, 0)`
    } else {
      cancelAnimationFrame(animationRef.current)
      requestAnimationFrame(pullbackAnimation)
    }
  }, [props.loading])

  let text
  let icon
  if (loading) {
    text = global._('PullToRefresh.Loading')
    icon = (
      <CircularProgress
        style={{ color: 'var(--color-logo-black)' }}
        size={24}
      />
    )
  } else if (topRef.current >= TRIGGER_OFFSET) {
    text = global._('PullToRefresh.ReleaseToRefresh')
    icon = <Icon className={classes.icon}>arrow_upward</Icon>
  } else {
    text = global._('PullToRefresh.PullDownToRefresh')
    icon = <Icon className={classes.icon}>arrow_downward</Icon>
  }

  return (
    <div ref={rootRef} className={classes.root}>
      <div ref={contentRef} className={classes.content} style={helpTextStyle}>
        <span className={classes.text} style={{ marginBottom: 10 }}>
          {text}
        </span>
        {icon}
      </div>
      <div
        className={classes.container}
        ref={containerRef}
        style={containerStyles}>
        {otherProps.children}
      </div>
    </div>
  )
}

PullToRefresh.propTypes = {
  classes: PropTypes.object.isRequired,
  onRefresh: PropTypes.func,
  loading: PropTypes.bool,
  helpTextStyle: PropTypes.object,
  containerStyles: PropTypes.object
}

PullToRefresh.defaultProps = {
  onRefresh: null,
  loading: false,
  helpTextStyle: {},
  containerStyles: {}
}

export default injectSheet(styles)(PullToRefresh)
