import React from 'react'
import Icon from '@material-ui/core/Icon'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import moment from 'moment'
import { UserRoles } from '../../../../utils/constants'
import Button from '../../../../components/Button'
import AsyncSelect from '../../../../components/AsyncSelect'
import { history } from '../../../../redux/store'

export default {
  containerDataCy: 'device-widget-container',
  icon: () => 'lock',
  linkButtons: opts => {
    const { classes, items, filter } = opts

    const searchButtonLocation = () =>
      history.push({
        pathname: '/admin/devices',
        state: {
          search: filter.search || ''
        }
      })

    return (
      <>
        <div
          className={classes.linkContainer}
          role='button'
          tabIndex='0'
          onClick={() => searchButtonLocation()}
          onKeyPress={() => searchButtonLocation()}
        >
          <span style={{ fontWeight: items.length ? 600 : undefined }}>
            {global._(
              filter.search
                ? 'Widgets.ShowSearchResults'
                : 'Widgets.devices.AllDevicesAndLocks'
            )}
          </span>
          <ChevronRightIcon style={{ marginLeft: 'auto' }} />
        </div>
      </>
    )
  },
  attributes: (caller, opts) => {
    const attributes = {
      uuid: {
        required: true,
        size: 12
      }
    }

    attributes.name = {
      required: true,
      size: 12
    }

    attributes.organisationId = {
      required: true,
      field: (
        <AsyncSelect
          inputId='widget-devices-modal-async-select-organisation-input'
          isSearchable
          labelKey='name'
          defaultValue=''
          placeholder={global._('Common.SelectOrganisation')}
          onChange={opt =>
            opts.setNewItem({
              ...opts.newItem,
              organisationId: opt ? opt.value.id : null
            })
          }
          disabled={opts.isLoadingCreate}
          helpText={global._('Common.Required')}
          url='/v1/organisations?search={{input}}&orderBy=name&order=asc'
        />
      )
    }

    if (opts.newItem && opts.newItem.organisationId) {
      attributes.propertyId = {
        required: true,
        field: (
          <AsyncSelect
            inputId='widget-devices-modal-async-select-property-input'
            isSearchable
            helpText={global._('AccessGroups.FilterOnProperty')}
            labelKey='name'
            isClearable
            cacheOptions={false}
            defaultValue=''
            placeholder={global._('AccessGroups.SelectProperty')}
            onChange={opt =>
              opts.setNewItem({
                ...opts.newItem,
                propertyId: opt ? opt.value.id : null
              })
            }
            url={`/v1/properties?query={{input}}&orderBy=name&order=asc&organisationId=${
              (opts.newItem && opts.newItem.organisationId) || ''
            }`}
          />
        )
      }
    }

    return attributes
  },
  actionButtons: (caller, opts) => {
    const buttons = []
    if (caller?.roles?.includes(UserRoles.PartnerAdmin)) {
      const { filter, setNewItem } = opts
      buttons.push(
        <Button
          data-cy='widget-devices-add-new-button'
          key='widgets.devices.button.add.new'
          variant='primary'
          onClick={() =>
            setNewItem({
              organisationId: filter.organisationId,
              propertyId: filter.propertyId
            })
          }
        >
          {global._('Widgets.devices.ActionButton.AddNew')}
        </Button>
      )
    }
    return buttons
  },
  identifier: device => `device-${device.id}`,
  noResultsMessage: () => global._('Widgets.devices.NoResultsMessage'),
  Columns: {
    /* Minified columns */
    widget: [
      {
        key: 'name',
        sortable: true,
        localizationKey: 'Widgets.devices.Attributes.name'
      },
      {
        key: 'createdAt',
        sortable: true,
        localizationKey: 'Widgets.devices.Attributes.createdAt',
        transform: value => moment(value).calendar()
      },
      {
        key: 'chevron',
        sortable: false,
        localizationKey: '',
        style: { width: 50 },
        transform: () => (
          <Icon
            style={{ display: 'block', marginLeft: 'auto', marginRight: 5 }}
          >
            chevron_right
          </Icon>
        )
      }
    ]
  }
}
