import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import { truncate } from 'lodash'
import Theme from '../../helpers/Theme'
import Typography from '../Typography'
import Column from '../Column'
import Row from '../Row'
import Button from '../Button'
import Notification from '../../common/Notification'
import { excel as excelExport } from '../../utils/export'
import useWindowDimensions from '../../utils/useWindowDimensions'
import BlueprintObjectListMobile from './BlueprintObjectListMobile'
import { history } from '../../redux/store'

const styles = {
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid var(--color-divider-light)',
    padding: '22px 47px',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    borderRadius: 500,
    backgroundColor: Theme.Colors.LIGHT_GREY,
    marginRight: 20
  },
  quantityContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  },
  quantityLeftContainer: {
    padding: 10,
    paddingRight: 15,
    backgroundColor: Theme.Colors.MATERIAL_GREY,
    borderBottomLeftRadius: 3,
    borderTopLeftRadius: 3
  },
  quantityRightContainer: {
    padding: '9px 10px 10px 12px',
    backgroundColor: Theme.Colors.MATERIAL_GREY,
    marginLeft: 3,
    borderBottomRightRadius: 3,
    borderTopRightRadius: 3
  },
  objectListContainer: {
    marginTop: 32
  },
  objectListTitleContainer: {
    padding: '0 47px',
    marginBottom: 8
  },
  objectListContentContainer: {
    borderTop: `1px solid ${Theme.Colors.DIVIDER_LIGHT}`
  },
  objectListObjectContainer: {
    padding: '10px 47px',
    width: '100%',
    borderBottom: `1px solid ${Theme.Colors.DIVIDER_LIGHT}`
  },
  table: {
    width: '100%'
  },
  tbody: {
    width: '100%'
  },
  tr: {
    borderBottom: `1px solid ${Theme.Colors.DIVIDER_LIGHT}`
  },
  trObject: {},
  trSelected: {
    backgroundColor: Theme.Colors.MATERIAL_GREY,
    borderBottom: 'none'
  },
  trSelectable: {
    '&:hover': {
      backgroundColor: Theme.Colors.MATERIAL_GREY,
      cursor: 'pointer'
    }
  },
  trItem: {
    backgroundColor: Theme.Colors.MATERIAL_GREY
  },
  td: {
    padding: '14px 50px 14px 0px',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    '&:first-child': {
      paddingLeft: 47
    },
    '&:last-child': {
      paddingRight: 47
    }
  },
  tdSelected: {
    fontWeight: 700
  },
  tdId: {
    minWidth: 50,
    width: 127
  },
  tdType: {
    minWidth: 150,
    width: 150
  },
  tdSpace: {
    width: 200
  },
  tdPropertyName: {
    width: 150
  },
  objectListColumn1: {
    width: 77
  },

  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */

  '@media (max-width: 900px)': {
    root: {},
    tr: {
      flexGrow: 1,
      paddingTop: 14,
      paddingBottom: 14,
      borderBottom: `1px solid ${Theme.Colors.DIVIDER_LIGHT}`
    },
    quantityContainer: {
      width: '100%',
      marginBottom: 7,
      height: 40
    },
    quantityLeftContainer: {
      height: '100%',
      flexGrow: 4,
      backgroundColor: Theme.Colors.MATERIAL_GREY,
      marginRight: 5,
      paddingLeft: 14
    },
    quantityRightContainer: {
      backgroundColor: Theme.Colors.MATERIAL_GREY,
      height: '100%',
      minWidth: 60
    }
  }
}

const BlueprintObjectList = props => {
  const {
    classes,
    title,
    actionButton,
    exportable,
    propertyId,
    blueprintId,
    organisationId
  } = props

  const { isMobile } = useWindowDimensions()
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedObject, setSelectedObject] = useState(null)

  /* Initial data needed */
  const [properties, setProperties] = useState([])
  const [objectTypesMap, setObjectTypesMap] = useState(null)
  const [itemTypesMap, setItemTypesMap] = useState(null)

  const isSingleBlueprint = propertyId && blueprintId
  const isMultiProperty = !propertyId && !blueprintId

  useEffect(() => {
    ;(async function fetch() {
      setIsLoading(true)
      try {
        let _properties = []
        let url = '/v1/properties'
        if (propertyId) {
          url += `/${propertyId}`
        }
        if (!blueprintId) {
          url += '?blueprints=true'
        }
        if (isMultiProperty) {
          url += `&organisationId=${organisationId}`
        }

        const { data: propertyData } = await axios.get(url)
        if (propertyId) {
          _properties.push(propertyData)
        } else {
          _properties = propertyData
        }

        if (blueprintId) {
          const { data: blueprint } = await axios.get(
            `/v1/blueprints/${blueprintId}`
          )
          _properties[0].blueprints = [blueprint]
        }

        const objectMap = {}
        const itemMap = {}
        _properties.forEach(property => {
          property.blueprints.forEach(blueprint => {
            blueprint.objects.forEach(object => {
              const { type: objectType } = object
              if (!objectMap[objectType]) {
                objectMap[objectType] = 0
              }
              objectMap[objectType] += 1
              object.items.forEach(item => {
                const { type: itemType } = item
                if (!itemMap[itemType]) {
                  itemMap[itemType] = 0
                }
                itemMap[itemType] += 1
              })
            })
          })
        })
        setObjectTypesMap(objectMap)
        setItemTypesMap(itemMap)
        setProperties(_properties)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
      setIsLoading(false)
    })()
  }, [])

  const handleExport = () => {
    const data = []

    /* Create data for export */
    properties.forEach(property => {
      property.blueprints.forEach(blueprint => {
        blueprint.objects.forEach(object => {
          data.push({
            type: global._(`BlueprintObjectTypes.${object.type}`),
            mark: object.id,
            blueprintName: blueprint.name,
            space: object.space,
            propertyName: property.name,
            placement: '',
            cylinderHeight: '',
            notes: object.notes,
            hasChain: '',
            bracketHeight: ''
          })
          object.items.forEach((item, itemIdx) => {
            const attributes = {}
            item.attributes.forEach(attr => {
              attributes[attr.key] = attr.value
            })

            data.push({
              type: global._(`BlueprintItemTypes.${item.type}`),
              mark: `${object.id}-${itemIdx + 1}`,
              blueprintName: blueprint.name,
              space: object.space,
              propertyName: property.name,
              placement: attributes.placement
                ? global._(
                    `AttributeFields.Values.placement.${attributes.placement}`
                  )
                : '',
              cylinderHeight: attributes.cylinderHeight
                ? global._(
                    `AttributeFields.Values.cylinderHeight.${attributes.cylinderHeight}`
                  )
                : '',
              notes: attributes.notes || '',
              hasChain: attributes.hasChain
                ? global._(
                    `AttributeFields.Values.hasChain.${attributes.hasChain}`
                  )
                : '',
              bracketHeight: attributes.bracketHeight
                ? global._(
                    `AttributeFields.Values.bracketHeight.${attributes.bracketHeight}`
                  )
                : '',
              padlockClass: attributes.padlockClass
                ? global._(
                    `AttributeFields.Values.padlockClass.${attributes.padlockClass}`
                  )
                : ''
            })
          })
        })
      })
    })
    excelExport({
      filename: 'export.xlsx',
      sheets: [
        {
          name: 'Sheet 1',
          columns: [
            {
              label: global._('Export.Type'),
              value: row => row.type
            },
            {
              label: global._('Export.Marking'),
              value: row => row.mark
            },
            {
              label: global._('Export.Blueprint'),
              value: row => row.blueprintName
            },
            {
              label: global._('Export.Space'),
              value: row => row.space
            },
            {
              label: global._('Export.Property'),
              value: row => row.propertyName
            },
            {
              label: global._('AttributeFields.Title.placement'),
              value: row => row.placement
            },
            {
              label: global._('AttributeFields.Title.cylinderHeight'),
              value: row => row.cylinderHeight
            },
            {
              label: global._('AttributeFields.Title.notes'),
              value: row => row.notes
            },
            {
              label: global._('AttributeFields.Title.hasChain'),
              value: row => row.hasChain
            },
            {
              label: global._('AttributeFields.Title.bracketHeight'),
              value: row => row.bracketHeight
            },
            {
              label: global._('AttributeFields.Title.padlockClass'),
              value: row => row.padlockClass
            }
          ],
          data
        }
      ]
    })
  }

  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Notification type='error' message={error} />
      </div>
    )
  }

  if (isMobile) {
    return <BlueprintObjectListMobile {...props} />
  }

  return (
    <div className={classes.root}>
      <div
        className={classes.container}
        style={{ paddingLeft: 30, paddingRight: 30 }}
      >
        <Column flex align='flex-start'>
          {/* List object types and quantities */}
          <Row style={{ flexWrap: 'wrap', width: '100%' }}>
            <Button
              variant='none'
              onClick={() =>
                history.length > 2
                  ? history.goBack()
                  : history.push(`/properties/${propertyId}`)
              }
            >
              {global._('Common.Back')}
            </Button>
          </Row>
        </Column>
      </div>
      {/* Header container */}
      <div className={classes.container} style={{ paddingBottom: 10 }}>
        <Column align='flex-start' style={{ paddingTop: 10 }}>
          <Row style={{ paddingBottom: 25 }}>
            <Typography variant='title'>{title}</Typography>
          </Row>
          <Row
            justify='space-between'
            align='center'
            style={{ paddingBottom: 16, width: '100%' }}
          >
            <Row>
              <div className={classes.iconContainer}>
                {isLoading ? (
                  <CircularProgress
                    style={{ color: Theme.Colors.PRIMARY }}
                    size={18}
                    thickness={6}
                  />
                ) : (
                  <Icon style={{ fontSize: 30, color: '#8e8e8e' }}>
                    business
                  </Icon>
                )}
              </div>
              <Column align='flex-start' justify='flex-start'>
                <Typography variant='small' style={{ fontSize: 14 }}>
                  {isMultiProperty && global._('Common.All')}
                  {!isMultiProperty && global._('BlueprintObjectList.Property')}
                </Typography>
                <Typography bold={600} variant='body' style={{ fontSize: 18 }}>
                  {isMultiProperty && global._('Properties.Title')}
                  {!isMultiProperty &&
                    properties[0] &&
                    truncate(properties[0].name, { length: 40 })}
                </Typography>
                {isSingleBlueprint && (
                  <>
                    <Typography
                      variant='small'
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      {global._('Planritning')}
                    </Typography>
                    <Typography
                      bold={600}
                      variant='body'
                      style={{ fontSize: 18 }}
                    >
                      {truncate(
                        properties[0] &&
                          properties[0].blueprints[0] &&
                          properties[0].blueprints[0].name,
                        { length: 40 }
                      )}
                    </Typography>
                  </>
                )}
              </Column>
            </Row>
            {actionButton}
            {exportable && (
              <Button variant='primary' onClick={handleExport}>
                {global._('Common.Export')}
              </Button>
            )}
          </Row>
        </Column>
      </div>
      {/* Quantity container */}
      <div
        className={classes.container}
        style={{ borderBottom: 'none', marginTop: 10, paddingBottom: 0 }}
      >
        <Column flex align='flex-start'>
          {/* List object types and quantities */}
          <Row style={{ flexWrap: 'wrap', width: '100%' }}>
            {objectTypesMap &&
              Object.keys(objectTypesMap).map(type => {
                const quantity = objectTypesMap[type]
                return (
                  <div
                    key={`object-type-map-${type}`}
                    className={classes.quantityContainer}
                    style={{ marginBottom: 20 }}
                  >
                    <div className={classes.quantityLeftContainer}>
                      <Typography>
                        {global._(`BlueprintObjectTypes.Plural.${type}`)}
                      </Typography>
                    </div>
                    <div className={classes.quantityRightContainer}>
                      <Typography style={{ fontSize: 18, marginRight: 5 }} bold>
                        {quantity}
                      </Typography>
                      <Typography>
                        {global._('BlueprintObjectList.QuantityPieces')}
                      </Typography>
                    </div>
                  </div>
                )
              })}
            {/* List item types and quantities */}
            {itemTypesMap &&
              Object.keys(itemTypesMap).map(type => {
                const quantity = itemTypesMap[type]
                return (
                  <div
                    key={`object-type-map-${type}`}
                    className={classes.quantityContainer}
                    style={{ marginBottom: 20 }}
                  >
                    <div className={classes.quantityLeftContainer}>
                      <Typography>
                        {global._(`BlueprintItemTypes.Plural.${type}`)}
                      </Typography>
                    </div>
                    <div className={classes.quantityRightContainer}>
                      <Typography style={{ fontSize: 18, marginRight: 5 }} bold>
                        {quantity}
                      </Typography>
                      <Typography>
                        {global._('BlueprintObjectList.QuantityPieces')}
                      </Typography>
                    </div>
                  </div>
                )
              })}
          </Row>
        </Column>
      </div>
      {/* Expansion list of objects */}
      {properties.length > 0 &&
        objectTypesMap &&
        Object.keys(objectTypesMap).map(objectType => {
          const objects = []
          properties.forEach(property => {
            property.blueprints.forEach(blueprint => {
              objects.push(
                ...blueprint.objects
                  .filter(o => o.type === objectType)
                  .map(o => ({ ...o, property, blueprint }))
              )
            })
          })

          return (
            <div
              key={`objects-list-${objectType}`}
              className={classes.objectListContainer}
            >
              <div className={classes.objectListTitleContainer}>
                <Typography variant='subtitle'>
                  {global._(`BlueprintObjectTypes.Plural.${objectType}`)}
                </Typography>
              </div>
              <div className={classes.objectListContentContainer}>
                <table className={classes.table}>
                  <tbody className={classes.tbody}>
                    {objects.map(object => {
                      const isSelected =
                        selectedObject && selectedObject.id === object.id
                      const hasItems = object.items.length > 0
                      let onClick
                      if (hasItems) {
                        onClick = () =>
                          setSelectedObject(isSelected ? null : object)
                      }
                      return (
                        <React.Fragment key={`object-list-object-${object.id}`}>
                          <tr
                            className={classnames(
                              classes.tr,
                              classes.trObject,
                              hasItems && classes.trSelectable,
                              isSelected && hasItems && classes.trSelected
                            )}
                            onClick={onClick}
                          >
                            <td
                              className={classnames(
                                classes.td,
                                classes.tdId,
                                isSelected && hasItems && classes.tdSelected
                              )}
                            >
                              <Typography variant='body2'>
                                {object.id}
                              </Typography>
                            </td>
                            <td
                              className={classnames(
                                classes.td,
                                classes.tdType,
                                isSelected && hasItems && classes.tdSelected
                              )}
                            >
                              <Typography variant='body2'>
                                {global._(
                                  `BlueprintObjectTypes.${object.type}`
                                )}
                              </Typography>
                            </td>
                            <td
                              className={classnames(
                                classes.td,
                                classes.tdSpace,
                                isSelected && hasItems && classes.tdSelected
                              )}
                            >
                              <Typography variant='body2'>
                                {truncate(object.space, { length: 30 })}
                              </Typography>
                            </td>
                            {isMultiProperty && (
                              <td
                                className={classnames(
                                  classes.td,
                                  classes.tdPropertyName,
                                  isSelected && hasItems && classes.tdSelected
                                )}
                              >
                                <Typography variant='body2'>
                                  {truncate(object.property.name, {
                                    length: 30
                                  })}
                                </Typography>
                              </td>
                            )}
                            <td
                              className={classnames(classes.td)}
                              colSpan={100}
                            >
                              <Row justify='flex-end' align='center'>
                                {hasItems && (
                                  <Icon>
                                    {isSelected ? 'expand_less' : 'expand_more'}
                                  </Icon>
                                )}
                              </Row>
                            </td>
                          </tr>
                          {isSelected &&
                            object.items.map((item, idx) => {
                              const { type: itemType } = item
                              return (
                                <tr
                                  key={`object-list-item-${item.id}`}
                                  className={classnames(
                                    classes.tr,
                                    classes.trItem
                                  )}
                                >
                                  <td
                                    className={classnames(
                                      classes.td,
                                      classes.tdId
                                    )}
                                  >
                                    <Typography variant='body2'>{`${
                                      object.id
                                    }-${idx + 1}`}</Typography>
                                  </td>
                                  <td
                                    className={classnames(
                                      classes.td,
                                      classes.tdType
                                    )}
                                  >
                                    <Typography variant='body2'>
                                      {global._(
                                        `BlueprintItemTypes.${itemType}`
                                      )}
                                    </Typography>
                                  </td>
                                  <td colSpan={100} />
                                </tr>
                              )
                            })}
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )
        })}
    </div>
  )
}

BlueprintObjectList.propTypes = {
  classes: PropTypes.object.isRequired,
  propertyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string.isRequired,
  actionButton: PropTypes.node,
  blueprintId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  exportable: PropTypes.bool
}

BlueprintObjectList.defaultProps = {
  actionButton: null,
  blueprintId: null,
  propertyId: null,
  exportable: false
}

const RegisterWithStyle = injectSheet(styles)(BlueprintObjectList)
export default RegisterWithStyle
