/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import axios from 'axios'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid
} from '@material-ui/core'
import qs from 'qs'
import IconColumn from 'src/components/IconColumn'
import moment from 'moment'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import Notification from '../../common/Notification'
import ObjectList from '../../components/ObjectList'
import KeysNavigation from '../../components/Keys/KeysNavigation'
import {
  getSelectedOrg,
  mapKeyStatus,
  signedKeyFormat
} from '../../utils/helpers'
import SuccessMessage from '../../components/SuccessMessage'
import ImportKeysModal from './Import/Keys/Modal'
import SearchField from '../../components/SearchField'
import { InstructionsVideoIds } from '../../utils/constants'

const styles = {
  root: {}
}

const KeysList = props => {
  const { classes, history, breadcrumbItems, match, user } = props

  const selectedOrg = getSelectedOrg(user)

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  let repositoryId = null
  let lockSystemId = null

  const isRepositoryKeys =
    window.location.pathname.includes('/keys/repositories')
  const isLockSystemKeys = window.location.pathname.includes('/keys/systems')

  if (match && match.params) {
    repositoryId = match.params.repositoryId
    lockSystemId = match.params.lockSystemId
  }

  const [error, setError] = useState(null)
  const [items, setItems] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isImportKeysModalVisible, setIsImportKeysModalVisible] =
    useState(false)
  const [canFetchMoreItems, setCanFetchMoreItems] = useState(false)
  const [repository, setRepository] = useState(null)
  const [lockSystem, setLockSystem] = useState(null)

  const urlParams = new URLSearchParams(window.location.search)

  const defaultFilter = {
    orderBy: 'name',
    order: 'asc',
    type: '',
    archived: false,
    passedReturnDate:
      urlParams.get('returnDatePassed') &&
      urlParams.get('returnDatePassed') === 'true',
    self: false,
    limit: 25,
    offset: 0,
    userId: urlParams.get('userId') || null,
    search: urlParams.get('search') || null,
    storageId: repositoryId || undefined,
    lockSystemId: lockSystemId || undefined
  }

  const [filter, setFilter] = useState(defaultFilter)

  useEffect(() => {
    if (selectedOrg && !isLoading) {
      ;(async () => {
        try {
          setIsLoading(true)
          const { data: keys } = await axios.get(
            `/v1/organisations/${selectedOrg.id}/keys?${qs.stringify(filter)}`
          )

          setCanFetchMoreItems(keys.length === filter.limit)

          if (keys) {
            if (filter.offset === 0) {
              setItems(keys)
            } else {
              setItems([...items, ...keys])
            }
          }
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
        setIsLoading(false)
      })()
    }
  }, [filter])

  const fetchMoreItems = () => {
    const offset = filter.offset + filter.limit
    const updatedFilter = { ...filter, offset }

    console.log('fetchMoreItems', offset)
    setFilter(updatedFilter)
  }

  /* Reset filter when going from repositories/:id to keys/list */
  useEffect(() => {
    setFilter(defaultFilter)
  }, [isRepositoryKeys, isLockSystemKeys])

  useEffect(() => {
    if (isRepositoryKeys && repositoryId) {
      ;(async () => {
        try {
          setIsLoading(true)
          const { data } = await axios.get(
            `/v1/organisations/${selectedOrg.id}/keystorages/${repositoryId}`
          )
          setRepository(data)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
        setIsLoading(false)
      })()
    }

    if (isLockSystemKeys && lockSystemId) {
      ;(async () => {
        try {
          setIsLoading(true)
          const { data } = await axios.get(
            `/v1/organisations/${selectedOrg.id}/locksystems/${lockSystemId}`
          )
          setLockSystem(data)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
        setIsLoading(false)
      })()
    }
  }, [])

  const handleFilter = name => e => {
    const aFilter = { ...filter }
    const { value } = e.target

    aFilter.offset = 0

    if (name === 'archived') {
      aFilter[name] = e.target.checked
      aFilter.passedReturnDate = false
    } else if (name === 'passedReturnDate') {
      aFilter[name] = e.target.checked
      aFilter.archived = false
    } else {
      aFilter[name] = value
    }

    setFilter(aFilter)
  }

  const columns = [
    {
      key: 'icon',
      sortingDisabled: true,
      clickable: true,
      style: { width: 70 },
      format: (objVal, obj) => obj && <IconColumn icon='vpn_key' />
    },
    {
      key: 'name',
      localizationKey: 'Keys.List.KeyName',
      sortingKey: 'name',
      clickable: true,
      longText: true,
      format: objVal => objVal || '-'
    },
    {
      key: 'marking',
      localizationKey: 'Keys.List.Marking',
      sortingKey: 'marking',
      clickable: true,
      style: { width: '10%' }
    },
    {
      key: 'status',
      localizationKey: 'Keys.List.Status',
      format: (status, obj) => (
        <>
          {status && (
            <span className={`status ${mapKeyStatus(status)}`}>
              {global._(`Keys.Statuses.${status}`)}
            </span>
          )}
          {obj.archived && <span className='status black'>Arkiverad</span>}
          {obj.expectedReturnDate &&
            moment().isAfter(obj.expectedReturnDate, 'day') && (
              <span className='status red with-icon'>
                <Icon style={{ fontSize: 12 }}>access_time</Icon>
              </span>
            )}
        </>
      ),
      clickable: true,
      sortingKey: 'status',
      style: { width: '10%' }
    },
    {
      key: 'type',
      localizationKey: 'Keys.List.Type',
      format: objType => objType && global._(`Keys.Types.${objType}`),
      sortingKey: 'type',
      clickable: true,
      style: { width: '10%' }
    },
    {
      key: 'signed',
      localizationKey: 'Keys.List.Acknowledged',
      clickable: true,
      sortingDisabled: true,
      style: { width: '10%' },
      format: (objVal, obj) => signedKeyFormat(obj)
    },
    {
      key: 'holderName',
      localizationKey: 'Keys.List.Holder',
      clickable: true,
      sortingDisabled: true,
      longText: true,
      style: { width: '10%' }
    },
    {
      key: 'lockSystemName',
      localizationKey: 'Keys.List.LockSystemName',
      clickable: true,
      sortingDisabled: true,
      longText: true,
      hiddenOnSm: true,
      style: { width: '10%' }
    },
    {
      key: '',
      clickable: true,
      sortingDisabled: true,
      format: () => <Icon>chevron_right</Icon>,
      style: { width: 40 }
    }
  ]

  const Filter = (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SearchField
          style={{ width: '100%' }}
          name='name'
          label={global._('Keys.List.SearchPlaceholder')}
          variant='filled'
          value={filter.search || ''}
          onChange={handleFilter('search')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                value={filter.archived}
                checked={filter.archived}
                color='primary'
                onChange={handleFilter('archived')}
              />
            }
            label={global._('Keys.List.ShowArchived')}
            className={classes.checkboxWrapper}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                value={filter.passedReturnDate}
                checked={filter.passedReturnDate}
                color='primary'
                onChange={handleFilter('passedReturnDate')}
              />
            }
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {`${global._('Keys.List.ShowReturnDatePassedKeys')} `}(
                <Icon
                  style={{
                    color: 'var(--color-error)',
                    fontSize: 16,
                    marginTop: 2
                  }}
                >
                  access_time
                </Icon>
                )
              </div>
            }
            className={classes.checkboxWrapper}
          />
        </FormControl>
      </Grid>
    </Grid>
  )

  const TitleContent = (
    <div style={{ marginLeft: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant='outlined'
            style={{ width: 130 }}
            onClick={() => setIsImportKeysModalVisible(true)}
          >
            {global._('Keys.List.Import')}
          </Button>
        </Grid>
        {/* <Grid item><Button variant='outlined' style={{ width: 130 }}>{global._('Keys.List.Export')}</Button></Grid> */}
        <Grid item>
          <Button
            variant='primary'
            style={{ width: 130 }}
            onClick={() => history.push('/keys/list/new')}
          >
            {global._('Keys.Common.Add')}
          </Button>
        </Grid>
      </Grid>
    </div>
  )

  let objectListTitle = global._('Keys.List.Title')
  let infoDialogTitle = global._('Keys.Common.Information')
  let infoDialogContent = (
    <Typography variant='body'>
      {global._('Keys.List.InfoDialogContent')}
    </Typography>
  )
  let titleContent = TitleContent
  let objectPermalink = 'keys/list'
  let filterFields = Filter

  if (isRepositoryKeys) {
    objectListTitle = `${global._('Keys.List.RepositoryKeys.Title')} ${
      repository && repository.name
    }`
    infoDialogTitle = undefined
    infoDialogContent = undefined
    titleContent = undefined
    objectPermalink = `keys/repositories/${repository && repository.id}/keys`
    filterFields = undefined
  }

  if (isLockSystemKeys) {
    objectListTitle = `${global._('Keys.List.LockSystemKeys.Title')} ${
      lockSystem && lockSystem.name
    }`
    infoDialogTitle = undefined
    infoDialogContent = undefined
    titleContent = undefined
    objectPermalink = `keys/systems/${lockSystem && lockSystem.id}/keys`
    filterFields = undefined
  }

  const lang = global.getLanguage()

  return (
    <div className={classes.root}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      {isImportKeysModalVisible && (
        <ImportKeysModal onClose={() => setIsImportKeysModalVisible(false)} />
      )}
      <SuccessMessage
        subtitle={global._('Keys.Import.ImportSuccessful')}
        localStorageProp='keys.import.success'
      />
      <SuccessMessage
        subtitle={global._('Keys.New.KeyAddedSuccessfully')}
        localStorageProp='keyAddedSuccessfully'
      />
      <KeysNavigation
        breadcrumbItems={breadcrumbItems}
        user={user}
        error={error}
      />
      <ObjectList
        sectionTitle={objectListTitle}
        infoDialogTitle={infoDialogTitle}
        infoDialogContent={infoDialogContent}
        titleContent={titleContent}
        expandable={false}
        objectPermalink={objectPermalink}
        filterFields={filterFields}
        history={history}
        columns={columns}
        objects={items}
        instructionsVideoId={InstructionsVideoIds[lang].KEY_MANAGEMENT.KEYS}
        fetchMoreObjects={fetchMoreItems}
        canFetchMoreObjects={canFetchMoreItems}
        isLoadingObjects={isLoading}
        noResultsMessage={global._(
          filter.search
            ? 'Keys.List.SearchNoResultsMessage'
            : 'Keys.List.NoResultsMessage'
        )}
        handleFilter={handleFilter}
        setFilter={setFilter}
        filter={filter}
      />
    </div>
  )
}

KeysList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  match: PropTypes.object,
  user: PropTypes.object
}

KeysList.defaultProps = {
  match: null,
  user: null
}

const KeysListWithStyles = injectSheet(styles)(KeysList)
export default KeysListWithStyles
