import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from '@material-ui/core'
import useWindowDimensions from '../utils/useWindowDimensions'

const styles = {
  root: {},
  itemWrapper: {
    paddingTop: 18,
    paddingBottom: 18,
    display: 'flex',
    alignItems: props => (props.checkboxAlignStart ? 'start' : 'center'),
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--color-divider-light)'
    }
  }
}

const CheckboxList = ({
  classes,
  objects,
  columns,
  selectedOptions,
  setSelectedOptions,
  checkboxNameKey
}) => {
  const { isMobile } = useWindowDimensions()

  return (
    <div className={classes.root}>
      <FormGroup>
        {objects &&
          objects.length > 0 &&
          objects.map(obj => {
            const isOptChecked = selectedOptions.includes(obj[checkboxNameKey])

            return (
              <FormControlLabel
                style={{
                  width: '100%',
                  margin: 0,
                  userSelect: 'none',
                  padding: isMobile ? '12px 0' : '18px 0'
                }}
                className={classes.itemWrapper}
                key={`checkbox-wrapper-${obj[checkboxNameKey]}`}
                onChange={e => {
                  let value

                  if (e.target.checked) {
                    value = [...selectedOptions, obj[checkboxNameKey]]
                  } else {
                    value = selectedOptions.filter(
                      opt => opt !== obj[checkboxNameKey]
                    )
                  }

                  setSelectedOptions(value)
                }}
                label={columns.map(col => {
                  const value = col.format(obj)

                  return (
                    <div
                      key={`row-checkbox-${col.key}`}
                      style={{ lineHeight: isMobile ? 1.2 : undefined }}>
                      <Typography variant='body'>{value}</Typography>
                    </div>
                  )
                })}
                control={
                  <div
                    style={{
                      minWidth: 72,
                      textAlign: 'center',
                      position: 'relative'
                    }}>
                    <Checkbox
                      checked={isOptChecked}
                      name={obj[checkboxNameKey]}
                      style={{
                        transform: isMobile ? 'scale(0.7)' : undefined,
                        padding: 0
                      }}
                    />
                    <span
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        position: 'absolute',
                        bottom: '-13px',
                        left: 0,
                        background: 'var(--color-white)',
                        height: 3
                      }}
                    />
                  </div>
                }
              />
            )
          })}
      </FormGroup>
    </div>
  )
}

CheckboxList.propTypes = {
  classes: PropTypes.object.isRequired,
  objects: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  checkboxNameKey: PropTypes.string.isRequired
}

const CheckboxListWithStyles = injectSheet(styles)(CheckboxList)
export default CheckboxListWithStyles
