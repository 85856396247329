import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { CircularProgress } from '@material-ui/core'

const styles = {
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    flex: props => (props.flex ? 1 : 0)
  }
}

const LoadingSpinner = ({ classes, ...otherProps }) => (
  <div className={classes.root} {...otherProps}>
    <CircularProgress style={{ color: 'var(--color-black)' }} />
  </div>
)

LoadingSpinner.propTypes = {
  classes: PropTypes.object.isRequired
}

const LoadingSpinnerWithStyles = injectSheet(styles)(LoadingSpinner)
export default LoadingSpinnerWithStyles
