import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import axios from 'axios'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import TabNavigator from 'src/components/TabNavigator'
import { Checkbox, Divider, Icon, ListItemText } from '@material-ui/core'
import Button from 'src/components/Button'
import Notification from 'src/common/Notification'
import {
  UserRoles,
  UserAssignableRoles,
  OrganisationTypes
} from 'src/utils/constants'
import 'moment/locale/sv'
import Paper from 'src/components/Paper'
import Typography from 'src/components/Typography'
import { phoneNumberRegex } from 'src/utils/helpers'
import ItemBadge from 'src/components/ItemBadge'
import PageContentHeader from 'src/components/PageContentHeader'
import Modal from 'src/components/Modal'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import FloatingAlert from 'src/components/FloatingAlert'
import SuccessMessage from 'src/components/SuccessMessage'
import { setAuthenticationData } from 'src/redux/reducers/login'
import { Skeleton } from '@material-ui/lab'
import Row from 'src/components/Row'
import Column from 'src/components/Column'
import NavigationList from 'src/components/NavigationList'
import FullscreenModal from 'src/components/FullscreenModal'
import ScrollView from 'src/components/ScrollView'
import NavigationBar from 'src/components/NavigationBar'
import MobileContainer from 'src/components/MobileContainer'
import PhoneNumberField from 'src/components/PhoneNumberField'
import ssn from 'src/utils/ssn'
import SsnInput from '../../components/SsnInput'
import Password from '../../components/Password'

const colorCodes = [
  '#E8E8E8',
  '#8AC1FF',
  '#95C1D7',
  '#FFAFF1',
  '#C2B4FE',
  '#9EE19D',
  '#B9D2B1',
  '#FFAAAA',
  '#E6BC85'
]

const styles = {
  root: {
    '& .rolePicker .MuiSelect-select': {
      padding: 0,
      '& .MuiListItem-root': {
        minHeight: 56
      }
    }
  },
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    marginTop: 1
  },
  infoIcon: {
    fontSize: 30,
    transition: 'all 0.3s',
    cursor: 'pointer',
    marginLeft: 10,
    '&:hover': {
      color: 'var(--color-primary)'
    }
  },
  roleDescription: {
    marginBottom: 16
  },
  roleDivider: {
    margin: '16px 0'
  },
  roleLabel: {
    display: 'block',
    fontWeight: 700,
    marginBottom: 8
  },
  roleTitle: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 20
  },
  paper: {},
  section: {
    width: '100%',
    padding: '20px 20px 30px 20px',
    borderBottom: '1px solid var(--color-divider-light)',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  input: {
    width: '100%'
  },
  submitBtnContainer: {
    marginTop: 8
  },
  '@media (max-width: 900px)': {
    section: {
      padding: 20
    }
  }
}

const Navigation = {
  MOBILE_MENU: 'MOBILE_MENU',
  OVERVIEW: 'OVERVIEW',
  INFORMATION: 'INFORMATION',
  ROLE: 'ROLE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SETTINGS: 'SETTINGS'
}

const UserDetail = props => {
  const {
    classes,
    history,
    signout,
    breadcrumbItems,
    updateOwnUser,
    impersonate,
    location,
    match,
    user,
    dispatch
  } = props

  const { isMobile } = useWindowDimensions()

  const emptyRole = { description: '', label: '', value: '' }
  const [viewedUser, setViewedUser] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState(false)
  const [userColorCode, setUserColorCode] = useState(null)
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showInvitationSuccessMessage, setShowInvitationSuccessMessage] =
    useState(false)
  const [showRoleInfoDialog, setShowRoleInfoDialog] = useState(false)
  const [newRole, setNewRole] = useState(emptyRole)
  const [showNewRoleAlert, setShowNewRoleAlert] = useState(false)
  const [showNotificationsInfoModal, setShowNotificationsInfoModal] =
    useState(false)

  const [navigation, setNavigation] = useState(
    isMobile ? Navigation.MOBILE_MENU : Navigation.OVERVIEW
  )

  const viewedUserOrganisationId = match.params.organisationId
  const viewedUserId = match.params.userId

  const loggedInUserOrgId = user.organisation.id
  const loggedInUserId = user.id

  const isMyAccount = location && location.pathname.includes('/myaccount')
  let ownAccount = false
  if (
    viewedUserOrganisationId == loggedInUserOrgId &&
    viewedUserId == loggedInUserId
  ) {
    ownAccount = true
  } else if (isMyAccount) {
    ownAccount = true
  }

  const isLoggedInUserSystemAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.SystemAdmin)
  const isLoggedInUserSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)
  const isLoggedInUserPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isLoggedInUserCustomerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.CustomerAdmin)
  const viewedUserIsAdmin =
    viewedUser &&
    Array.isArray(viewedUser.roles) &&
    viewedUser.roles.includes(UserRoles.PartnerAdmin)

  const canResendInvitationLink =
    !ownAccount &&
    viewedUser &&
    !viewedUser.active &&
    (isLoggedInUserSystemAdmin || isLoggedInUserPartnerAdmin)
  const canImpersonatePartnerAdmin =
    isLoggedInUserSystemAdmin &&
    viewedUser &&
    viewedUser.roles.includes(UserRoles.PartnerAdmin)
  const canImpersonate =
    !ownAccount &&
    (isLoggedInUserSystemAdmin || isLoggedInUserPartnerAdmin) &&
    (!viewedUserIsAdmin || canImpersonatePartnerAdmin)

  const isAdmin =
    isLoggedInUserSystemAdmin ||
    isLoggedInUserPartnerAdmin ||
    isLoggedInUserCustomerAdmin

  let currentColorCode

  if (viewedUser) {
    currentColorCode = userColorCode || viewedUser.colorCode
  }

  /* Fetch user */
  useEffect(() => {
    ;(async () => {
      setError(null)
      try {
        const { data } = await axios.get(
          `/v1/organisations/${
            viewedUserOrganisationId || loggedInUserOrgId
          }/users/${viewedUserId || loggedInUserId}`
        )
        if (user.id === viewedUserId) {
          setViewedUser({ ...user, ...data })
        } else {
          setViewedUser(data)
        }
        console.log('data', data)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
      }
    })()
  }, [match])

  const handleVisbilityNewRoleAlert = role => {
    if (role !== '') {
      const displayRole = {
        description: global._(`User.RolesInformation.${role}`),
        label: global._(`User.Roles.${role}`),
        value: role
      }
      setNewRole(displayRole)
      setShowNewRoleAlert(true)
    }
  }

  const updateUserRoles = async roles => {
    try {
      await axios.put(
        `/v1/organisations/${viewedUser.organisation.id}/users/${viewedUser.id}/roles`,
        roles
      )

      const userObj = { ...viewedUser }
      userObj.roles = roles

      setViewedUser(userObj)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleUserRoleUpdate = role => {
    if (!viewedUser) {
      return
    }
    if (role === 'none') {
      updateUserRoles([])
    } else if (role) {
      const roleToAlert = viewedUser.roles.includes(role) ? '' : role
      const currentRoles = viewedUser.roles.includes(role)
        ? viewedUser.roles.filter(r => r !== role)
        : [role]

      updateUserRoles(currentRoles).then(() => {
        handleVisbilityNewRoleAlert(roleToAlert)
      })
    }
  }

  const resendInvitationLink = async user => {
    try {
      setShowInvitationSuccessMessage(false)
      await axios.post(`/v1/users/${user.id}/resend`, user)
      setShowInvitationSuccessMessage(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const updateUserColor = async color => {
    const userUpdateObj = { colorCode: color }

    try {
      await axios.patch(
        `/v1/organisations/${viewedUser.organisation.id}/users/${viewedUser.id}`,
        userUpdateObj
      )
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleUserColorUpdate = color => {
    if (color) {
      setUserColorCode(color)
      updateUserColor(color)
    }
  }

  const handleDeleteUser = async () => {
    try {
      await axios.delete(
        `/v1/organisations/${viewedUser.organisation.id}/users/${viewedUser.id}`
      )

      history.push('/admin/users')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleSave = async values => {
    setError(null)
    setIsUpdating(true)

    const valObj = { ...values }

    try {
      setShowSuccessMessage(false)

      if (!ownAccount) {
        delete valObj.personalNumber
      }

      await axios.patch(
        `/v1/organisations/${viewedUser.organisation.id}/users/${viewedUser.id}`,
        valObj
      )

      if (ownAccount) {
        // Refresh token after user data update
        const { data: authData } = await axios.get('/v1/auth/refresh')
        dispatch(setAuthenticationData(authData))
      }

      const updatedUser = { ...viewedUser, ...valObj }
      setViewedUser(updatedUser)

      if (loggedInUserId == viewedUserId) {
        updateOwnUser(updatedUser)
      }

      const msg = global._(
        ownAccount ? 'MyAccount.UpdatedInformation' : 'Common.ChangesSaved'
      )
      setShowSuccessMessage(msg)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsUpdating(false)
  }

  const getAvailableRoles = () => {
    const availableRoles = []

    user.roles.forEach(role => {
      UserAssignableRoles[role].forEach(r => {
        if (!availableRoles.includes(r)) {
          if (
            r === UserRoles.SystemAdmin &&
            viewedUser.organisation.type !== OrganisationTypes.SystemAdmin
          ) {
            return
          }

          if (
            r === UserRoles.PartnerAdmin &&
            viewedUser.organisation.type !== OrganisationTypes.Partner
          ) {
            return
          }

          /* remove support role when target organisation is not service desk */
          if (
            r === UserRoles.Support &&
            viewedUser.organisation.type !== OrganisationTypes.ServiceDesk
          ) {
            return
          }

          /* remove all other roles when target organisation is service desk */
          if (
            r !== UserRoles.Support &&
            viewedUser.organisation.type === OrganisationTypes.ServiceDesk
          ) {
            return
          }

          availableRoles.push(r)
        }
      })
    })

    const roleOptions = availableRoles.map(role => ({
      description: global._(`User.RolesInformation.${role}`),
      label: global._(`User.Roles.${role}`),
      value: role
    }))

    return roleOptions
  }

  const userInfo = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!viewedUser && (
        <>
          <Skeleton variant='circle' width={60} height={60} />
          <Column style={{ marginLeft: 20 }} align='flex-start'>
            <Skeleton variant='rect' width={200} height={16} />
            <Skeleton
              variant='rect'
              width={140}
              height={16}
              style={{ marginTop: 8 }}
            />
          </Column>
        </>
      )}
      {viewedUser && (
        <>
          <div>
            <div
              style={{
                backgroundColor: currentColorCode || 'var(--color-light-grey)',
                width: 60,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '100%'
              }}
            >
              <Icon style={{ fontSize: 30 }}>account_circle</Icon>
            </div>
          </div>
          <div style={{ marginLeft: 20 }}>
            <Typography variant='subtitle' normalLineHeight block>
              {viewedUser.name}
            </Typography>
            <Typography variant='body2' faded block>
              {viewedUser.organisation.name}
            </Typography>
          </div>
        </>
      )}
    </div>
  )

  const tabNavigationItems = [
    {
      label: global._('UserDetail.Navigation.Overview'),
      icon: 'account_circle',
      value: Navigation.OVERVIEW,
      key: 'overview'
    },
    {
      label: global._('UserDetail.Navigation.Information'),
      icon: 'store',
      value: Navigation.INFORMATION,
      visible:
        ownAccount ||
        user.roles.includes(UserRoles.SystemAdmin) ||
        user.roles.includes(UserRoles.PartnerAdmin) ||
        user.roles.includes(UserRoles.CustomerAdmin) ||
        user.roles.includes(UserRoles.CustomerAdminGuest),
      key: 'information'
    },
    {
      label: global._('UserDetail.Navigation.Role'),
      icon: 'face',
      value: Navigation.ROLE,
      key: 'role'
    },
    {
      label: global._('UserDetail.Navigation.ChangePassword'),
      icon: 'phonelink_lock',
      value: Navigation.CHANGE_PASSWORD,
      visible: ownAccount,
      key: 'change-password'
    },
    {
      label: global._('UserDetail.Navigation.Notifications'),
      icon: 'notifications',
      value: Navigation.NOTIFICATIONS,
      visible: !(
        user.roles.includes(UserRoles.Guest) ||
        user.roles.includes(UserRoles.CustomerAdminGuest)
      ),
      key: 'notifications'
    },
    {
      label: global._('UserDetail.Navigation.Settings'),
      icon: 'settings',
      value: Navigation.SETTINGS,
      visible:
        (user.roles.includes(UserRoles.PartnerAdmin) ||
          user.roles.includes(UserRoles.Support)) &&
        viewedUser &&
        (viewedUser.roles.includes(UserRoles.PartnerAdmin) ||
          viewedUser.roles.includes(UserRoles.Support)),
      key: 'settings'
    }
  ]

  let navigationContent
  if (navigation === Navigation.OVERVIEW) {
    let personalNumber
    if (viewedUser) {
      if (ssn.valid(viewedUser.personalNumber)) {
        personalNumber = ssn.format(viewedUser.personalNumber, true)
      } else {
        personalNumber = viewedUser.personalNumber || '-'
      }
    }
    navigationContent = (
      <div>
        {isMobile && (
          <div
            style={{
              margin: -20,
              marginBottom: 20,
              padding: '24px 20px',
              borderBottom: '1px solid var(--color-divider-light)'
            }}
          >
            {userInfo()}
          </div>
        )}
        <Row style={{ marginTop: 0 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 105 : 180, flexShrink: 0 }}
          >
            {global._('User.Email')}
          </Typography>
          {viewedUser && (
            <Typography variant='body' bold>
              {viewedUser.email ? viewedUser.email.toLowerCase() : '-'}
            </Typography>
          )}
          {!viewedUser && <Skeleton variant='rect' width={200} height={16} />}
        </Row>
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 105 : 180, flexShrink: 0 }}
          >
            {global._('UserDetail.PersonalNumber')}
          </Typography>
          {viewedUser && (
            <Typography variant='body' bold>
              {personalNumber}
            </Typography>
          )}
          {!viewedUser && <Skeleton variant='rect' width={200} height={16} />}
        </Row>
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 105 : 180, flexShrink: 0 }}
          >
            {global._('UserDetail.Phone')}
          </Typography>
          {viewedUser && (
            <Typography variant='body' bold>
              {viewedUser.phone || '-'}
            </Typography>
          )}
          {!viewedUser && <Skeleton variant='rect' width={150} height={16} />}
        </Row>
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 105 : 180, flexShrink: 0 }}
          >
            {global._('User.AddressOne')}
          </Typography>
          {viewedUser && (
            <Typography variant='body' bold>
              {viewedUser.addressOne || '-'}
            </Typography>
          )}
          {!viewedUser && <Skeleton variant='rect' width={100} height={16} />}
        </Row>
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 105 : 180, flexShrink: 0 }}
          >
            {global._('User.AddressTwo')}
          </Typography>
          {viewedUser && (
            <Typography variant='body' bold>
              {viewedUser.addressTwo || '-'}
            </Typography>
          )}
          {!viewedUser && <Skeleton variant='rect' width={100} height={16} />}
        </Row>
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 105 : 180, flexShrink: 0 }}
          >
            {global._('UserDetail.PostalCode')}
          </Typography>
          {viewedUser && (
            <Typography variant='body' bold>
              {viewedUser.zipCode || '-'}
            </Typography>
          )}
          {!viewedUser && <Skeleton variant='rect' width={100} height={16} />}
        </Row>
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 105 : 180, flexShrink: 0 }}
          >
            {global._('User.Town')}
          </Typography>
          {viewedUser && (
            <Typography variant='body' bold>
              {viewedUser.town || '-'}
            </Typography>
          )}
          {!viewedUser && <Skeleton variant='rect' width={100} height={16} />}
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 105 : 180, flexShrink: 0 }}
          >
            {global._('Common.Apartment')}
          </Typography>
          {viewedUser && (
            <Typography variant='body' bold>
              {viewedUser.apartments
                ? viewedUser.apartments.map(apartment => (
                    <span
                      style={{
                        padding: '4px 8px',
                        margin: 2,
                        backgroundColor: '#ddd',
                        borderRadius: 6,
                        fontSize: 14
                      }}
                    >
                      {apartment.number}, {apartment.address}
                    </span>
                  ))
                : '-'}
            </Typography>
          )}
          {!viewedUser && <Skeleton variant='rect' width={100} height={16} />}
        </Row>
        <Row style={{ marginTop: 11 }}>
          <Typography
            variant='body'
            faded
            style={{ width: isMobile ? 105 : 180, flexShrink: 0 }}
          >
            {global._('UserDetail.SystemRole')}
          </Typography>
          {viewedUser && (
            <Typography variant='body' bold>
              {viewedUser.roles
                ? viewedUser.roles.map(role => (
                    <span
                      style={{
                        padding: '4px 8px',
                        margin: 2,
                        backgroundColor: '#ddd',
                        borderRadius: 6,
                        fontSize: 14
                      }}
                    >
                      {global._(`User.Roles.${role}`)}
                    </span>
                  ))
                : '-'}
            </Typography>
          )}
          {!viewedUser && <Skeleton variant='rect' width={100} height={16} />}
        </Row>
      </div>
    )
  } else if (navigation === Navigation.INFORMATION) {
    if (viewedUser) {
      const name = viewedUser.name || ''
      const phone = viewedUser.phone || ''
      let personalNumber
      if (ssn.valid(viewedUser.personalNumber)) {
        personalNumber = ssn.format(viewedUser.personalNumber, true)
      } else {
        personalNumber = viewedUser.personalNumber || ''
      }
      const addressOne = viewedUser.addressOne || ''
      const addressTwo = viewedUser.addressTwo || ''
      const zipCode = viewedUser.zipCode || ''
      const town = viewedUser.town || ''

      const validationSchema = Yup.object().shape({
        name: Yup.string().required(global._('Common.Required')),
        phone: Yup.string()
          .matches(phoneNumberRegex, global._('Common.PhoneNumberInvalid'))
          .required(global._('Common.Required'))
      })

      navigationContent = (
        <Formik
          initialValues={{
            name,
            personalNumber,
            phone,
            addressOne,
            addressTwo,
            zipCode,
            town
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            handleChange,
            values,
            handleBlur,
            setTouched,
            setFieldValue
          }) => (
            <Form style={{ width: '100%' }}>
              {!isMobile && (
                <Typography variant='title' bold style={{ marginBottom: 12 }}>
                  {global._('UserDetail.UserDetails')}
                </Typography>
              )}
              <Grid container spacing={3} style={{ marginBottom: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={!viewedUser.active}
                    onChange={e => {
                      handleChange(e)
                      handleBlur(e)
                    }}
                    error={!!errors.name}
                    onClick={viewedUser.active && handleBlur}
                    value={values.name}
                    name='name'
                    className={classes.input}
                    label={global._('User.Name')}
                    variant='filled'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={viewedUser.email}
                    name='email'
                    className={classes.input}
                    label={global._('UserDetail.Email')}
                    helperText={global._('UserDetail.CantEditEmail')}
                    variant='filled'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SsnInput
                    disabled={!viewedUser.active || !ownAccount}
                    label={global._('PersonalNumber.Label')}
                    helperText={global._('UserDetail.PersonalNumberHelpText')}
                    value={values.personalNumber}
                    name='personalNumber'
                    onSsnValid={({ ssn }) =>
                      setFieldValue('personalNumber', ssn, false)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PhoneNumberField
                    disabled={!viewedUser.active}
                    onChange={value => setFieldValue('phone', value)}
                    onBlur={handleBlur}
                    error={errors.phone}
                    onClick={viewedUser.active && handleBlur}
                    value={values.phone}
                    name='phone'
                    helperText={
                      errors.phone ? errors.phone : global._('Common.Required')
                    }
                    label={global._('User.Phone')}
                    variant='filled'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={!viewedUser.active}
                    onChange={e => {
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    error={!!errors.addressOne}
                    onClick={viewedUser.active && handleBlur}
                    value={values.addressOne}
                    name='addressOne'
                    helperText={global._('User.AddressOneHelperText')}
                    className={classes.input}
                    label={global._('User.AddressOne')}
                    variant='filled'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={!viewedUser.active}
                    onChange={e => {
                      handleChange(e)
                      setTouched({ personalNumber: true })
                    }}
                    onBlur={handleBlur}
                    error={!!errors.addressTwo}
                    onClick={viewedUser.active && handleBlur}
                    value={values.addressTwo}
                    name='addressTwo'
                    helperText={global._('User.AddressTwoHelperText')}
                    className={classes.input}
                    label={global._('User.AddressTwo')}
                    variant='filled'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={!viewedUser.active}
                    onChange={e => {
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    error={!!errors.zipCode}
                    onClick={viewedUser.active && handleBlur}
                    value={values.zipCode}
                    name='zipCode'
                    className={classes.input}
                    label={global._('User.ZipCode')}
                    variant='filled'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={!viewedUser.active}
                    onChange={e => {
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    error={!!errors.town}
                    onClick={viewedUser.active && handleBlur}
                    value={values.town}
                    name='town'
                    className={classes.input}
                    label={global._('User.Town')}
                    variant='filled'
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction='row-reverse'
                className={classes.submitBtnContainer}
              >
                <Button
                  variant='primary'
                  disabled={
                    !values.personalNumber ||
                    Object.keys(errors).length ||
                    Object.keys(touched).length === 0
                  }
                  loading={isUpdating}
                  style={{ margin: '20px 0', minWidth: 140 }}
                  onClick={() => {
                    handleSave(values)
                    setTouched({})
                  }}
                >
                  {global._('Common.Save')}
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      )
    }
  } else if (navigation === Navigation.ROLE) {
    const ModalWrapper = isMobile ? FullscreenModal : Modal
    navigationContent = (
      <>
        {!isMobile && (
          <div className={classes.roleTitle}>
            <Typography variant='title' bold>
              {global._('UserDetail.SystemRole')}
            </Typography>
            <Icon
              className={classes.infoIcon}
              onClick={() => setShowRoleInfoDialog(true)}
            >
              info
            </Icon>
          </div>
        )}
        {showRoleInfoDialog && (
          <ModalWrapper
            title={global._('MyAccount.Role')}
            onClose={() => setShowRoleInfoDialog(false)}
            rightActionItem='close'
            content={
              <div style={{ padding: isMobile ? 20 : undefined }}>
                {getAvailableRoles().map((role, index) => (
                  <div key={`role-${role.value}-info`}>
                    {index > 0 && <Divider className={classes.roleDivider} />}
                    <Typography variant='body' className={classes.roleLabel}>
                      {role.label}
                    </Typography>
                    <Typography
                      variant='body'
                      className={classes.roleDescription}
                    >
                      {role.description}
                    </Typography>
                  </div>
                ))}
                {!getAvailableRoles().length &&
                  viewedUser &&
                  viewedUser.roles &&
                  viewedUser.roles.length > 0 && (
                    <div key='user-role-info'>
                      <Typography variant='body' className={classes.roleLabel}>
                        {global._(`User.Roles.${viewedUser.roles[0]}`)}
                      </Typography>
                      <Typography
                        variant='body'
                        className={classes.roleDescription}
                      >
                        {global._(
                          `User.RolesInformation.${viewedUser.roles[0]}`
                        )}
                      </Typography>
                    </div>
                  )}
              </div>
            }
            buttons={
              isMobile ? null : (
                <Button
                  key='cancel-role-modal'
                  variant='outlined'
                  onClick={() => setShowRoleInfoDialog(false)}
                >
                  {global._('Common.Close')}
                </Button>
              )
            }
          />
        )}
        {!isMyAccount && !isLoggedInUserSupport && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                name='updateUserRole'
                select
                label={global._('MyAccount.SelectRole')}
                value={
                  viewedUser && viewedUser.roles && viewedUser.roles.length
                    ? viewedUser.roles[0]
                    : 'none'
                }
                variant='filled'
                style={{ width: '100%' }}
                onChange={evt => handleUserRoleUpdate(evt.target.value)}
              >
                {getAvailableRoles() &&
                  getAvailableRoles().map(role => (
                    <MenuItem
                      value={role.value}
                      disabled={
                        !viewedUser || viewedUser.roles.includes(role.value)
                      }
                      key={`user-role-${role.value}`}
                    >
                      <ListItemText primary={role.label} />
                    </MenuItem>
                  ))}
                {viewedUser.organisation.type !==
                  OrganisationTypes.ServiceDesk && (
                  <MenuItem value={UserRoles.Temporary} disabled>
                    <ListItemText
                      primary={global._(`User.Roles.${UserRoles.Temporary}`)}
                    />
                  </MenuItem>
                )}
              </TextField>
            </Grid>
          </Grid>
        )}
        {(isMyAccount ||
          isLoggedInUserSupport ||
          (viewedUser && viewedUser.roles && viewedUser.roles.length > 1)) && (
          <Grid container spacing={3}>
            {viewedUser &&
              viewedUser.roles &&
              viewedUser.roles.map(r => (
                <Grid key={`role-${r}-badge`} item xs={12} md={6}>
                  <ItemBadge
                    label={global._(`User.Roles.${r}`)}
                    icon='face'
                    hideDeleteBtn={isMyAccount || viewedUser.roles.length < 2}
                    onDelete={() => handleUserRoleUpdate(r)}
                  />
                </Grid>
              ))}
          </Grid>
        )}
        {showNewRoleAlert && (
          <FloatingAlert
            icon='face'
            rightBottom
            small
            title={newRole.label}
            message={newRole.description}
            buttonLabel={global._('MyAccount.RoleAlertButtonLabel')}
            buttonVariant='secondary'
            buttonAction={() => setShowNewRoleAlert(false)}
          />
        )}
        <div style={{ height: 14 }} />
      </>
    )
  } else if (navigation === Navigation.CHANGE_PASSWORD) {
    const updatePassword = async ({
      currentPassword,
      newPassword,
      resetForm
    }) => {
      setError(null)
      try {
        await axios.post('/v1/users/password', { currentPassword, newPassword })

        localStorage.setItem('user.password.updated', true)
        if (isMobile) {
          setNavigation(Navigation.MOBILE_MENU)
        }
        setShowSuccessMessage(global._('MyAccount.UpdatedPassword'))
        resetForm()
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global.translate(msg))
      }
    }

    const validationSchema = Yup.object().shape({
      currentPassword: Yup.string().required(global._('Common.Required')),
      newPassword: Yup.string().min(8).required(global._('Common.Required')),
      repeatPassword: Yup.string().when('newPassword', {
        is: val => val && val.length > 0,
        then: Yup.string()
          .oneOf(
            [Yup.ref('newPassword')],
            global._('MyAccount.PasswordMismatch')
          )
          .required()
      })
    })

    navigationContent = (
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          repeatPassword: ''
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          updatePassword({
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
            resetForm
          })
          setSubmitting(false)
        }}
      >
        {({
          submitForm,
          errors,
          touched,
          handleChange,
          values,
          handleBlur,
          setTouched
        }) => (
          <Form style={{ width: '100%' }} autoComplete='off'>
            {!isMobile && (
              <Typography variant='title' bold>
                {global._('MyAccount.UpdatePassword')}
              </Typography>
            )}
            <Grid
              container
              spacing={6}
              style={{ marginBottom: 1, marginTop: isMobile ? undefined : 0 }}
            >
              <Grid item xs={12}>
                <Password
                  data-cy='password-current'
                  onChange={handleChange}
                  error={errors.currentPassword}
                  onClick={handleBlur}
                  value={values.currentPassword}
                  name='currentPassword'
                  className={classes.input}
                  label={global._('MyAccount.CurrentPassword')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 1 }}>
              <Grid item xs={12} md={6}>
                <Password
                  data-cy='password-new'
                  onChange={handleChange}
                  error={errors.newPassword}
                  onClick={handleBlur}
                  helperText={global._('MyAccount.PasswordHelpText')}
                  value={values.newPassword}
                  name='newPassword'
                  className={classes.input}
                  label={global._('MyAccount.NewPassword')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Password
                  data-cy='password-repeat'
                  onChange={handleChange}
                  error={errors.repeatPassword}
                  onClick={handleBlur}
                  value={values.repeatPassword}
                  name='repeatPassword'
                  className={classes.input}
                  label={global._('MyAccount.RepeatPassword')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container direction='row-reverse'>
              <Button
                variant='primary'
                disabled={
                  Object.keys(touched).length === 0 ||
                  Object.keys(errors).length
                }
                onClick={() => {
                  submitForm(values)
                  setTouched({})
                }}
                style={{ marginTop: isMobile ? 25 : 15, minWidth: 140 }}
              >
                {global._('Common.Update')}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    )
  } else if (navigation === Navigation.NOTIFICATIONS) {
    if (viewedUser) {
      const caseNotifications = ['notifyCaseUpdate']
      const keyNotifications = ['notifyKeyReturned']
      const documentNotifications = []

      if (viewedUser.roles.includes(UserRoles.PartnerAdmin)) {
        caseNotifications.unshift('notifyCaseNew', 'notifyCaseAssigned')
        keyNotifications.push('notifyKeyStorageChange')
        keyNotifications.push('notifyKeyLoanRequestAcceptance')
        documentNotifications.push('notifyDocumentStatus')
      } else if (viewedUser.roles.includes(UserRoles.Support)) {
        caseNotifications.unshift('notifyCaseAssigned')
      } else if (viewedUser.roles.includes(UserRoles.CustomerAdmin)) {
        keyNotifications.push('notifyKeyStorageChange')
        documentNotifications.push('notifyDocumentAwaitingApproval')
      }

      const section = (title, subtitle, keys, first) => (
        <Column
          align='flex-start'
          style={{
            width: 'calc(100% + 40px)',
            margin: '0 -20px',
            padding: '0 20px',
            paddingBottom: 20,
            borderTop:
              first && isMobile
                ? 'none'
                : '1px solid var(--color-divider-light)'
          }}
        >
          <Typography
            variant='subtitleBig'
            bold
            style={{ margin: '20px 0', marginBottom: isMobile ? 14 : 20 }}
          >
            {title}
          </Typography>
          <Typography
            variant='body'
            bold
            dark
            style={{ marginBottom: 14, color: 'var(--color-dark-grey)' }}
          >
            {subtitle}
          </Typography>
          {keys.map(notificationKey => (
            <Row
              key={notificationKey}
              align='flex-start'
              style={{ marginBottom: isMobile ? 16 : 0 }}
            >
              <Checkbox
                checked={viewedUser[notificationKey]}
                onClick={() =>
                  handleSave({
                    [notificationKey]: !viewedUser[notificationKey]
                  })
                }
                color='primary'
                style={{ marginLeft: -12, marginTop: -12 }}
              />
              <Typography>
                {global._(
                  `UserDetail.Notifications.Attributes.${notificationKey}`
                )}
              </Typography>
            </Row>
          ))}
        </Column>
      )
      const ModalWrapper = isMobile ? FullscreenModal : Modal
      navigationContent = (
        <div
          style={{
            margin: -20,
            padding: 20,
            paddingTop: isMobile ? 0 : 20,
            width: 'calc(100% + 40px)'
          }}
        >
          {!isMobile && (
            <Row style={{ marginBottom: 20 }}>
              <Typography variant='title' bold>
                {global._('Common.Notifications')}
              </Typography>
              <Icon
                className={classes.infoIcon}
                style={{ marginLeft: 15 }}
                onClick={() => setShowNotificationsInfoModal(true)}
              >
                info
              </Icon>
            </Row>
          )}
          {showNotificationsInfoModal && (
            <ModalWrapper
              style={{ padding: 0 }}
              title={global._('Common.Notifications')}
              onClose={() => setShowNotificationsInfoModal(false)}
              rightActionItem='close'
              content={
                <div style={{ padding: isMobile ? 20 : 0 }}>
                  {global._('UserDetail.Notifications.InfoModalContent')}
                </div>
              }
              buttons={
                isMobile ? null : (
                  <Button
                    variant='outlined'
                    onClick={() => setShowNotificationsInfoModal(false)}
                  >
                    {global._('Common.Close')}
                  </Button>
                )
              }
            />
          )}
          <Column align='flex-start'>
            {!!caseNotifications.length &&
              section(
                global._('UserDetail.Notifications.Cases'),
                global._('UserDetail.Notifications.SentWhen'),
                caseNotifications,
                true
              )}
            {!!keyNotifications.length &&
              section(
                global._('UserDetail.Notifications.Keys'),
                global._('UserDetail.Notifications.SentWhen'),
                keyNotifications
              )}
            {!!documentNotifications.length &&
              section(
                global._('UserDetail.Notifications.Documents'),
                global._('UserDetail.Notifications.SentWhen'),
                documentNotifications
              )}
          </Column>
        </div>
      )
    }
  } else if (navigation === Navigation.SETTINGS) {
    navigationContent = (
      <>
        {!isMobile && (
          <Typography variant='title' bold>
            {global._('Common.Settings')}
          </Typography>
        )}
        <Grid
          container
          spacing={3}
          style={{ marginBottom: 1, marginTop: isMobile ? undefined : 8 }}
        >
          <Grid item xs={12} md={6}>
            <TextField
              select
              className={classes.textField}
              style={{ width: '100%' }}
              variant='filled'
              name='colorCode'
              helperText={global._('MyAccount.ColorCodeHelperText')}
              label={global._('Common.ColorCode')}
              value={currentColorCode || colorCodes[0]}
              onChange={evt => {
                const color = evt.target.value

                handleUserColorUpdate(color)
              }}
            >
              {colorCodes.map(code => (
                <MenuItem
                  style={{ backgroundColor: code }}
                  key={code}
                  value={code}
                >
                  {code.toUpperCase()}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </>
    )
  }

  let deleteUserModal = null
  if (isAdmin && showDeleteUserModal) {
    deleteUserModal = (
      <Modal
        onClose={() => setShowDeleteUserModal(false)}
        title={global._('MyAccount.DeleteUser')}
        content={
          <div style={{ textAlign: 'left' }}>
            <Typography variant='body' block style={{ marginBottom: 15 }}>
              {global._('MyAccount.DeleteUserModalConfirmText')}
            </Typography>
            <Typography variant='body' bold block style={{ marginBottom: 15 }}>
              {viewedUser.name}
            </Typography>
            <Typography
              variant='body'
              italic
              block
              style={{ marginBottom: 30 }}
            >
              {global._('MyAccount.DeleteUserModalInfo')}
            </Typography>
          </div>
        }
        buttons={[
          <Button variant='none' onClick={() => setShowDeleteUserModal(false)}>
            {global._('Common.Cancel')}
          </Button>,
          <Button
            variant='secondary'
            onClick={() => {
              handleDeleteUser()
              setShowDeleteUserModal(false)
            }}
          >
            {global._('Delete')}
          </Button>
        ]}
      />
    )
  }

  if (isMobile) {
    let detailButtons
    let onInfoTap
    if (navigation === Navigation.ROLE) {
      onInfoTap = () => setShowRoleInfoDialog(true)
    } else if (navigation === Navigation.NOTIFICATIONS) {
      onInfoTap = () => setShowNotificationsInfoModal(true)
    }

    let infoButton
    if (onInfoTap) {
      infoButton = (
        <div onClick={onInfoTap}>
          <Icon>info</Icon>
        </div>
      )
    }
    const itemList = tabNavigationItems.map(item => ({
      title: item.label,
      icon: item.icon,
      visible: item.visible,
      key: item.key,
      onClick: () => {
        setNavigation(item.value)
      }
    }))

    itemList.push({
      title: global._('Common.Logout'),
      icon: 'logout',
      visible: true,
      key: 'logout',
      onClick: () => {
        signout()
      }
    })

    const selectedNavigationItem = tabNavigationItems.find(
      item => item.value === navigation
    )

    return (
      <MobileContainer background='white'>
        {showSuccessMessage && <SuccessMessage subtitle={showSuccessMessage} />}
        <NavigationBar
          hidden={!!selectedNavigationItem}
          title={global._(
            `UserDetail.Title.${ownAccount ? 'MyAccount' : 'OtherUser'}`
          )}
          backAction={() => history.push('/')}
        />
        {selectedNavigationItem && (
          <FullscreenModal
            title={selectedNavigationItem.label}
            rightActionItem={infoButton}
            onClose={() => setNavigation(Navigation.MOBILE_MENU)}
            content={<div style={{ padding: 20 }}>{navigationContent}</div>}
            withTabBar
            buttons={detailButtons}
          />
        )}
        <ScrollView id='scrollable-container' style={{ paddingBottom: 104 }}>
          <div style={{ padding: '0 20px' }}>
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ margin: '20px 0' }}
              />
            )}
            <div style={{ margin: '0 -20px' }}>
              <div
                style={{
                  padding: '24px 20px',
                  borderBottom: '1px solid var(--color-divider-light)'
                }}
              >
                {userInfo()}
              </div>
              <NavigationList itemList={itemList} skeletonize={!viewedUser} />
            </div>
          </div>
        </ScrollView>
        {deleteUserModal}
      </MobileContainer>
    )
  }

  return (
    <div className={classes.root}>
      {showSuccessMessage ? (
        <SuccessMessage subtitle={showSuccessMessage} />
      ) : null}
      {showInvitationSuccessMessage ? (
        <SuccessMessage subtitle={global._('MyAccount.InvitationLinkSent')} />
      ) : null}
      <PageContentHeader
        breadcrumbItems={breadcrumbItems}
        actionButtons={
          ownAccount
            ? ''
            : [
                isAdmin ? (
                  <Button
                    variant='secondary'
                    onClick={() => setShowDeleteUserModal(true)}
                    style={{ marginLeft: 'auto' }}
                  >
                    {global._('MyAccount.DeleteUser')}
                  </Button>
                ) : null
              ]
        }
      />
      {deleteUserModal}
      {error && <Notification type='error' message={error} />}
      <Paper noPadding>
        <div
          className={classes.section}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {userInfo()}
          <div>
            {canResendInvitationLink && (
              <Button
                style={{ marginLeft: 20 }}
                variant='outlined'
                onClick={() => resendInvitationLink(viewedUser)}
              >
                {global._('MyAccount.SendInviteLink')}
              </Button>
            )}
            {canImpersonate && (
              <Button
                style={{ marginLeft: 20 }}
                variant='primary'
                onClick={() =>
                  impersonate(viewedUser.id, viewedUser.organisation.id)
                }
              >
                {global._('MyAccount.Impersonate')}
              </Button>
            )}
          </div>
        </div>
        <TabNavigator
          items={tabNavigationItems}
          value={navigation}
          onChange={item => setNavigation(item.value)}
        />
        <div className={classes.section}>{navigationContent}</div>
      </Paper>
    </div>
  )
}

UserDetail.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    organisation: PropTypes.object,
    roles: PropTypes.array
  }).isRequired,
  signout: PropTypes.func.isRequired,
  breadcrumbItems: PropTypes.arrayOf(PropTypes.object),
  impersonate: PropTypes.func.isRequired,
  updateOwnUser: PropTypes.func,
  dispatch: PropTypes.func.isRequired
}

UserDetail.defaultProps = {
  breadcrumbItems: [],
  updateOwnUser: null
}

const UserDetailWithStyles = injectSheet(styles)(UserDetail)
const ConnectedUserDetailWithStyles = connect()(UserDetailWithStyles)
export default ConnectedUserDetailWithStyles
