import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import { truncate } from 'lodash'
import Theme from '../../helpers/Theme'
import Typography from '../Typography'
import Column from '../Column'
import Row from '../Row'
import Button from '../Button'
import Notification from '../../common/Notification'
import { excel as excelExport } from '../../utils/export'
import { BlueprintObjectTypes, UserRoles } from '../../utils/constants'
import ScrollView from '../ScrollView'
import NavigationBar from '../NavigationBar'
import TabBar from '../TabBar'
import TabBarItem from '../TabBarItem'
import Table from '../Table'
import FullscreenModal from '../FullscreenModal'
import UploadBlueprint from '../BlueprintTool/modals/UploadBlueprint'

const styles = {
  root: {},
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    borderRadius: 500,
    backgroundColor: Theme.Colors.LIGHT_GREY,
    marginRight: 20
  },
  tr: {
    flexGrow: 1,
    paddingTop: 14,
    paddingBottom: 14,
    borderBottom: `1px solid ${Theme.Colors.DIVIDER_LIGHT}`
  },
  quantityContainer: {
    width: '100%',
    marginBottom: 7,
    height: 40
  },
  quantityLeftContainer: {
    height: '100%',
    flexGrow: 4,
    backgroundColor: Theme.Colors.MATERIAL_GREY,
    marginRight: 5,
    paddingLeft: 14
  },
  quantityRightContainer: {
    backgroundColor: Theme.Colors.MATERIAL_GREY,
    height: '100%',
    minWidth: 60
  }
}

const BlueprintObjectListMobile = props => {
  const {
    classes,
    user,
    actionButton,
    exportable,
    propertyId,
    blueprintId,
    history,
    signout
  } = props

  const isOnboarding =
    user &&
    user.roles &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.Guest)
  const isQuoteRequest = new RegExp('/quoteoverview').test(
    history.location.pathname
  )
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  /* Initial data needed */
  const [property, setProperty] = useState(null)
  const [blueprints, setBlueprints] = useState(null)
  const [objectTypesMap, setObjectTypesMap] = useState(null)
  const [itemTypesMap, setItemTypesMap] = useState(null)
  const [activeObject, setActiveObject] = useState(null)
  const [showObjectModal, setShowObjectModal] = useState(null)
  const [showBlueprintUploadModal, setShowBlueprintUploadModal] =
    useState(false)

  useEffect(() => {
    ;(async function fetchBlueprint() {
      setIsLoading(true)
      try {
        const { data: propertyData } = await axios.get(
          `/v1/properties/${propertyId}`
        )
        setProperty(propertyData)
        let fetchedBlueprints
        if (blueprintId) {
          /* Fetching a single blueprint includes objects, items and attributes by default */
          const { data: blueprintData } = await axios.get(
            `/v1/blueprints/${blueprintId}`
          )
          fetchedBlueprints = [blueprintData]
        } else {
          /* Fetching multiple blueprints does not include objects, items and attributes by default, so we ask for it */
          const { data: blueprintsData } = await axios.get(
            `/v1/properties/${propertyId}/blueprints?include=objects&include=items&include=attributes`
          )
          fetchedBlueprints = blueprintsData
        }
        const objectMap = {}
        const itemMap = {}
        fetchedBlueprints.forEach(blueprint => {
          blueprint.objects.forEach(object => {
            const { type: objectType } = object
            if (!objectMap[objectType]) {
              objectMap[objectType] = 0
            }
            objectMap[objectType] += 1
            object.items.forEach(item => {
              const { type: itemType } = item
              if (!itemMap[itemType]) {
                itemMap[itemType] = 0
              }
              itemMap[itemType] += 1
            })
          })
        })
        setObjectTypesMap(objectMap)
        setItemTypesMap(itemMap)
        setBlueprints(fetchedBlueprints)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
      setIsLoading(false)
    })()
  }, [])

  const handleExport = () => {
    const data = []

    /* Create data for export */
    blueprints.forEach(blueprint => {
      blueprint.objects.forEach(object => {
        data.push({
          type: global._(`BlueprintObjectTypes.${object.type}`),
          mark: object.id,
          blueprintName: blueprint.name,
          space: object.space,
          propertyName: property.name,
          placement: '',
          cylinderHeight: '',
          notes: object.notes,
          hasChain: '',
          bracketHeight: ''
        })
        object.items.forEach((item, itemIdx) => {
          const attributes = {}
          item.attributes.forEach(attr => {
            attributes[attr.key] = attr.value
          })

          data.push({
            type: global._(`BlueprintItemTypes.${item.type}`),
            mark: `${object.id}-${itemIdx + 1}`,
            blueprintName: blueprint.name,
            space: object.space,
            propertyName: property.name,
            placement: attributes.placement
              ? global._(
                  `AttributeFields.Values.placement.${attributes.placement}`
                )
              : '',
            cylinderHeight: attributes.cylinderHeight
              ? global._(
                  `AttributeFields.Values.cylinderHeight.${attributes.cylinderHeight}`
                )
              : '',
            notes: attributes.notes || '',
            hasChain: attributes.hasChain
              ? global._(
                  `AttributeFields.Values.hasChain.${attributes.hasChain}`
                )
              : '',
            bracketHeight: attributes.bracketHeight
              ? global._(
                  `AttributeFields.Values.bracketHeight.${attributes.bracketHeight}`
                )
              : '',
            padlockClass: attributes.padlockClass
              ? global._(
                  `AttributeFields.Values.padlockClass.${attributes.padlockClass}`
                )
              : ''
          })
        })
      })
    })
    excelExport({
      filename: 'export.xlsx',
      sheets: [
        {
          name: 'Sheet 1',
          columns: [
            {
              label: global._('Export.Type'),
              value: row => row.type
            },
            {
              label: global._('Export.Marking'),
              value: row => row.mark
            },
            {
              label: global._('Export.Blueprint'),
              value: row => row.blueprintName
            },
            {
              label: global._('Export.Space'),
              value: row => row.space
            },
            {
              label: global._('Export.Property'),
              value: row => row.propertyName
            },
            {
              label: global._('AttributeFields.Title.placement'),
              value: row => row.placement
            },
            {
              label: global._('AttributeFields.Title.cylinderHeight'),
              value: row => row.cylinderHeight
            },
            {
              label: global._('AttributeFields.Title.notes'),
              value: row => row.notes
            },
            {
              label: global._('AttributeFields.Title.hasChain'),
              value: row => row.hasChain
            },
            {
              label: global._('AttributeFields.Title.bracketHeight'),
              value: row => row.bracketHeight
            },
            {
              label: global._('AttributeFields.Title.padlockClass'),
              value: row => row.padlockClass
            }
          ],
          data
        }
      ]
    })
  }

  const handleCreateNewBlueprint = () => {
    if (isOnboarding) {
      history.push(
        `/onboarding/properties/${propertyId}/blueprints/${blueprintId}/register`
      )
    } else {
      setShowBlueprintUploadModal(true)
    }
  }

  const handleSaveBlueprint = () => {
    history.push(
      `/onboarding/properties/${propertyId}/blueprints/${blueprintId}/register`
    )
  }

  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Notification type='error' message={error} />
      </div>
    )
  }

  return (
    <div style={{ backgroundColor: '#FFF' }}>
      {showBlueprintUploadModal && (
        <UploadBlueprint
          isMobile
          setShowBlueprintUploadModal={setShowBlueprintUploadModal}
          propertyId={propertyId}
          history={history}
        />
      )}
      {showObjectModal && (
        <FullscreenModal
          object={activeObject}
          title={`${global._(`BlueprintObjectTypes.${activeObject.type}`)} ${
            activeObject.id
          }`}
          content={
            !activeObject.items || activeObject.items.length === 0 ? (
              <Typography
                variant='bodyFaded'
                align='center'
                style={{
                  display: 'block',
                  padding: '80px 60px 0px 60px'
                }}
              >
                {global._('ItemListModal.NoItems')}
              </Typography>
            ) : (
              <div>
                <Table
                  identifier='item'
                  items={activeObject.items}
                  icon={
                    <Icon style={{ color: Theme.Colors.PRIMARY, fontSize: 22 }}>
                      radio_button_checked
                    </Icon>
                  }
                  rowContent={item => (
                    <Column align='flex-start'>
                      <Row justify='flex-start'>
                        <Typography bold={600}>
                          {global._(`BlueprintItemTypes.${item.type}`)}
                        </Typography>
                        <Typography
                          bold={600}
                          style={{
                            marginLeft: 5,
                            color: Theme.Colors.MIDDLE_GREY
                          }}
                        >{`${activeObject.id}-${item.id}`}</Typography>
                      </Row>
                      <Typography
                        style={{
                          color: Theme.Colors.MIDDLE_GREY,
                          fontSize: '0.875rem'
                        }}
                      >
                        {item.attributes.find(
                          attribute => attribute.key === 'notes'
                        )
                          ? truncate(
                              item.attributes.find(
                                attribute => attribute.key === 'notes'
                              ).value,
                              { length: 50 }
                            )
                          : global._('BlueprintItemList.NoNotesPlaceholder')}
                      </Typography>
                    </Column>
                  )}
                />
              </div>
            )
          }
          onClose={() => {
            setActiveObject(null)
            setShowObjectModal(false)
          }}
          buttons={null}
        />
      )}
      <NavigationBar
        title={
          isQuoteRequest
            ? global._('Properties.RequestForQuotation')
            : global._('BlueprintObjectList.Title')
        }
        backAction={() => history.goBack()}
      />
      <ScrollView style={{ backgroundColor: Theme.Colors.WHITE }}>
        <div>
          <Column align='flex-start' style={{ padding: 20 }}>
            <Row>
              <Row>
                <div className={classes.iconContainer}>
                  {isLoading ? (
                    <CircularProgress
                      style={{ color: Theme.Colors.PRIMARY }}
                      size={18}
                      thickness={6}
                    />
                  ) : (
                    <Icon style={{ fontSize: 30, color: '#8e8e8e' }}>
                      business
                    </Icon>
                  )}
                </div>
                <Column align='flex-start' justify='flex-start'>
                  <Typography variant='small' style={{ fontSize: 14 }}>
                    {global._('BlueprintObjectList.Property')}
                  </Typography>
                  <Typography
                    bold={600}
                    variant='body'
                    style={{ fontSize: 18 }}
                  >
                    {property && truncate(property.name, { length: 20 })}
                  </Typography>
                  {blueprints && blueprints.length === 1 && (
                    <>
                      <Typography
                        variant='small'
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        {global._('Planritning')}
                      </Typography>
                      <Typography
                        bold={600}
                        variant='body'
                        style={{ fontSize: 18 }}
                      >
                        {truncate(blueprints[0].name, { length: 20 })}
                      </Typography>
                    </>
                  )}
                </Column>
              </Row>
            </Row>
          </Column>
        </div>
        <div
          style={{
            borderBottom: `1px solid ${Theme.Colors.DIVIDER_LIGHT}`,
            paddingBottom: 20
          }}
        >
          <Column align='flex-start' style={{ padding: '0 20px' }}>
            {/* List object types and quantities */}
            <Column
              style={{ width: '100%' }}
              align='flex-start'
              justify='center'
            >
              {objectTypesMap &&
                Object.keys(objectTypesMap).map(type => {
                  const quantity = objectTypesMap[type]
                  return (
                    <Row
                      key={`object-type-map-${type}`}
                      className={classes.quantityContainer}
                      align='center'
                      justify='space-between'
                    >
                      <Column
                        align='flex-start'
                        justify='center'
                        className={classes.quantityLeftContainer}
                      >
                        <Typography>
                          {global._(`BlueprintObjectTypes.Plural.${type}`)}
                        </Typography>
                      </Column>
                      <Column
                        className={classes.quantityRightContainer}
                        align='center'
                        justify='center'
                      >
                        <Row>
                          <Typography
                            style={{ fontSize: 18, marginRight: 5 }}
                            bold
                          >
                            {quantity}
                          </Typography>
                          <Typography>
                            {global._('BlueprintObjectList.QuantityPieces')}
                          </Typography>
                        </Row>
                      </Column>
                    </Row>
                  )
                })}
            </Column>
            {/* List item types and quantities */}
            <Column
              style={{ width: '100%' }}
              align='flex-start'
              justify='center'
            >
              {itemTypesMap &&
                Object.keys(itemTypesMap).map(type => {
                  const quantity = itemTypesMap[type]
                  return (
                    <Row
                      key={`object-type-map-${type}`}
                      className={classes.quantityContainer}
                      align='center'
                      justify='space-between'
                    >
                      <Column
                        align='flex-start'
                        justify='center'
                        className={classes.quantityLeftContainer}
                      >
                        <Typography>
                          {global._(`BlueprintItemTypes.Plural.${type}`)}
                        </Typography>
                      </Column>
                      <Column
                        className={classes.quantityRightContainer}
                        align='center'
                        justify='center'
                      >
                        <Row>
                          <Typography
                            style={{
                              fontSize: 18,
                              marginRight: 5
                            }}
                            bold
                          >
                            {quantity}
                          </Typography>
                          <Typography>
                            {global._('BlueprintObjectList.QuantityPieces')}
                          </Typography>
                        </Row>
                      </Column>
                    </Row>
                  )
                })}
            </Column>
          </Column>
        </div>
        {/* list of objects */}
        {blueprints &&
          objectTypesMap &&
          Object.keys(objectTypesMap).map(objectType => {
            const objects = blueprints.reduce((arr, blueprint) => {
              arr.push(...blueprint.objects.filter(o => o.type === objectType))
              return arr
            }, [])

            return (
              <Column
                key={`objects-list-${objectType}`}
                style={{ paddingLeft: 15 }}
                align='flex-start'
                justify='space-between'
              >
                {objects.map(object => (
                  <Row
                    key={`object-list-object-${object.id}`}
                    align='center'
                    justify='center'
                    style={{
                      width: '100%'
                    }}
                  >
                    <Column justify='center' style={{ padding: 15 }}>
                      <Icon
                        style={{ fontSize: 22, color: Theme.Colors.PRIMARY }}
                      >
                        radio_button_checked
                      </Icon>
                    </Column>
                    <Column className={classes.tr}>
                      <Row justify='space-between' style={{ width: '100%' }}>
                        <Column
                          align='flex-start'
                          justify='flex-start'
                          onClick={
                            object.type === BlueprintObjectTypes.Hotspot ||
                            object.type === BlueprintObjectTypes.CCTVCamera ||
                            object.type === BlueprintObjectTypes.CCTVCentral ||
                            object.type === BlueprintObjectTypes.CCTVSwitch
                              ? null
                              : () => {
                                  setActiveObject(object)
                                  setShowObjectModal(true)
                                }
                          }
                        >
                          <Row>
                            <Typography variant='body' bold>
                              {global._(`BlueprintObjectTypes.${object.type}`)}
                            </Typography>
                            <Typography
                              variant='body'
                              bold
                              style={{ color: '#999999', marginLeft: 3 }}
                            >
                              {object.id}
                            </Typography>
                          </Row>
                          <Row>
                            <Typography
                              variant='body2'
                              style={{ color: '#A3A3A3' }}
                            >
                              {truncate(object.space, { length: 40 })}
                            </Typography>
                          </Row>
                        </Column>
                        {object.type !== BlueprintObjectTypes.Hotspot &&
                          object.type !== BlueprintObjectTypes.CCTVCamera &&
                          object.type !== BlueprintObjectTypes.CCTVCentral &&
                          object.type !== BlueprintObjectTypes.CCTVSwitch && (
                            <Column
                              onClick={() => {
                                setActiveObject(object)
                                setShowObjectModal(true)
                              }}
                              style={{ padding: '10px 0' }}
                            >
                              <Icon style={{ fontSize: 15, marginRight: 20 }}>
                                keyboard_arrow_right
                              </Icon>
                            </Column>
                          )}
                      </Row>
                    </Column>
                  </Row>
                ))}
              </Column>
            )
          })}
        {actionButton}
        {exportable && (
          <div style={{ padding: 20 }}>
            <Button variant='primary' onClick={handleExport}>
              {global._('Common.Export')}
            </Button>
          </div>
        )}
        {isQuoteRequest && (
          <Row style={{ padding: 20, backgroundColor: Theme.Colors.WHITE }}>
            <Button
              variant='primary'
              onClick={() =>
                history.push(`${history.location.pathname}/quoterequest`)
              }
            >
              {global._('Next')}
            </Button>
          </Row>
        )}
      </ScrollView>
      <TabBar>
        {!isOnboarding && (
          <TabBarItem
            title={global._('TabBar.Home')}
            icon='home'
            onClick={() => history.push('/')}
          />
        )}
        {!isOnboarding && isQuoteRequest && (
          <TabBarItem
            title={global._('TabBar.Locks')}
            icon='vpn_key'
            onClick={() => history.push('/mylocks')}
          />
        )}
        {!isQuoteRequest && (
          <TabBarItem
            title={global._('TabBar.CreateNew')}
            icon='note_add'
            onClick={handleCreateNewBlueprint}
          />
        )}
        {!isQuoteRequest && (
          <TabBarItem
            selected
            title={global._('TabBar.Overview')}
            icon='assignment'
          />
        )}
        {isOnboarding && !isQuoteRequest && (
          <TabBarItem
            title={global._('TabBar.Save')}
            icon='save'
            onClick={handleSaveBlueprint}
          />
        )}
        {!isOnboarding && (
          <TabBarItem
            title={global._('TabBar.SignOut')}
            icon='account_circle'
            onClick={signout}
          />
        )}
      </TabBar>
    </div>
  )
}

BlueprintObjectListMobile.propTypes = {
  history: PropTypes.object.isRequired, // Inherited from Public/PrivateLayout
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  propertyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  actionButton: PropTypes.node,
  blueprintId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  exportable: PropTypes.bool,
  signout: PropTypes.func
}

BlueprintObjectListMobile.defaultProps = {
  actionButton: null,
  blueprintId: null,
  exportable: false,
  signout: null
}

const RegisterWithStyle = injectSheet(styles)(BlueprintObjectListMobile)
export default RegisterWithStyle
