import React from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Country } from 'src/utils/constants'
import { history } from '../../../../redux/store'

export default {
  doNotShowFilter: true,
  doNotShowTable: true,
  icon: () => 'shopping_cart',
  linkButtons: ({ classes, user }) => (
    <>
      <div
        className={classes.linkContainer}
        role='button'
        tabIndex='0'
        onClick={() => history.push('/admin/settings/orders#products')}
        onKeyPress={() => history.push('/admin/settings/orders#products')}
      >
        <span>{global._('Admin.Settings.Orders.Navigation.Products')}</span>
        <ChevronRightIcon style={{ marginLeft: 'auto' }} />
      </div>
      <div
        className={classes.linkContainer}
        role='button'
        tabIndex='0'
        onClick={() => history.push('/admin/settings/orders#shipping')}
        onKeyPress={() => history.push('/admin/settings/orders#shipping')}
      >
        <span>
          {global._('Admin.Settings.Orders.Navigation.ShippingOptions')}
        </span>
        <ChevronRightIcon style={{ marginLeft: 'auto' }} />
      </div>
      <div
        className={classes.linkContainer}
        role='button'
        tabIndex='0'
        onClick={() => history.push('/admin/settings/orders#notifications')}
        onKeyPress={() => history.push('/admin/settings/orders#notifications')}
      >
        <span>
          {global._('Admin.Settings.Orders.Navigation.Notifications')}
        </span>
        <ChevronRightIcon style={{ marginLeft: 'auto' }} />
      </div>
      {user.organisation.country === Country.NO ? (
        <div
          className={classes.linkContainer}
          role='button'
          tabIndex='0'
          onClick={() => history.push('/admin/settings/orders#nets')}
          onKeyPress={() => history.push('/admin/settings/orders#nets')}
        >
          <span>{global._('Admin.Settings.Orders.Navigation.Nets')}</span>
          <ChevronRightIcon style={{ marginLeft: 'auto' }} />
        </div>
      ) : (
        <div
          className={classes.linkContainer}
          role='button'
          tabIndex='0'
          onClick={() => history.push('/admin/settings/orders#swish')}
          onKeyPress={() => history.push('/admin/settings/orders#swish')}
        >
          <span>{global._('Admin.Settings.Orders.Navigation.Swish')}</span>
          <ChevronRightIcon style={{ marginLeft: 'auto' }} />
        </div>
      )}
    </>
  )
}
