import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  text: {
    fontSize: 13,
    color: '#4d4d4d',
    textAlign: 'left',
    marginBottom: 8
  },
  indicatorContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  indicator: {
    height: 20,
    borderRadius: 600,
    backgroundColor: '#DADADA',
    flexGrow: 1,
    border: '1px solid #ccc'
  },
  indicatorActive: {
    backgroundColor: '#8ad0e4',
    borderColor: '#6eb1ce'
  }
}

// eslint-disable-next-line
const strongRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
)
// eslint-disable-next-line
const mediumRegex = new RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
)

export const Strength = { Weak: 0, Medium: 1, Strong: 2 }
export const testPasswordStrength = password => {
  let strength
  if (!password || password.length < 8) strength = Strength.Weak
  else if (strongRegex.test(password)) strength = Strength.Strong
  else if (mediumRegex.test(password)) strength = Strength.Medium
  else strength = Strength.Weak

  return strength
}

const PasswordStrength = props => {
  const { classes, password, ...rest } = props

  const strength = testPasswordStrength(password)

  return (
    <div className={classes.root} {...rest}>
      <span
        className={classes.text}
        style={{ color: strength === 0 ? '#F17B7B' : null }}>
        {global.translate(`PasswordStrength.${strength}`)}{' '}
        {global.translate('PasswordStrength.Extend')}
      </span>
      <div className={classes.indicatorContainer}>
        <div
          className={classnames(
            classes.indicator,
            strength >= 0 && classes.indicatorActive
          )}
          style={{
            marginRight: 3,
            backgroundColor: strength === Strength.Weak ? '#F17B7B' : null,
            borderColor: strength === Strength.Weak ? '#D65F5F' : null
          }}
        />
        <div
          className={classnames(
            classes.indicator,
            strength >= 1 && classes.indicatorActive
          )}
          style={{ marginRight: 3 }}
        />
        <div
          className={classnames(
            classes.indicator,
            strength >= 2 && classes.indicatorActive
          )}
        />
      </div>
    </div>
  )
}

PasswordStrength.propTypes = {
  classes: PropTypes.object.isRequired,
  password: PropTypes.string
}

PasswordStrength.defaultProps = {
  password: ''
}

const PasswordStrengthWithStyle = injectSheet(styles)(PasswordStrength)
export default PasswordStrengthWithStyle
