import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon } from '@material-ui/core'
import VideoInstructionsModal from 'src/components/VideoInstructionsModal'
import Button from '../../components/Button'
import { mapDocumentStatus } from '../../utils/helpers'
import Modal from '../../components/Modal'
import Typography from '../../components/Typography'
import Notification from '../../common/Notification'
import Filter from './Filter'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import CenteredFloatingButtonMobile from '../../components/CenteredFloatingButtonMobile'
import ObjectListMobile from '../../components/ObjectListMobile'
import { DocumentStatus } from '../../utils/constants'
import MobileContainer from '../../components/MobileContainer'

const styles = {
  root: {},
  input: {
    width: '100%'
  }
}

const DocumentsMobile = ({
  history,
  user,
  error,
  noResultsMessage,
  canFetchMoreDocuments,
  fetchMoreDocuments,
  isLoadingDocuments,
  documents,
  setShowAddDocumentModal,
  isPartnerAdmin,
  isSupport,
  isCustomerAdmin,
  setError,
  handleFilter,
  filter,
  isMobile,
  instructionsVideoId
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false)

  const columns = [
    {
      key: 'icon',
      format: () => <Icon>assignment</Icon>
    },
    {
      key: 'name',
      bold: true,
      format: name =>
        name && (
          <Typography
            variant='body'
            ellipsis
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )
    },
    {
      key: 'organisation',
      hiddenFor: !isPartnerAdmin,
      format: org => (
        <>
          <Typography
            variant='bodyFaded'
            style={{
              fontSize: 14,
              lineHeight: 1.5,
              display: 'inline-block',
              marginBottom: 3
            }}
          >
            {org.name}
          </Typography>
        </>
      )
    },
    {
      key: 'status',
      hideCol: status => status === DocumentStatus.None,
      hiddenFor: !isPartnerAdmin && !isCustomerAdmin,
      format: status =>
        status && (
          <span className={`status ${mapDocumentStatus(status)}`}>
            {global._(`Document.Statuses.${status}`)}
          </span>
        )
    }
  ]

  return (
    <MobileContainer background='white'>
      {showInfoModal && (
        <Modal
          title={global._('Properties.InformationDialogTitle')}
          onClose={() => setShowInfoModal(false)}
          content={
            <div style={{ textAlign: 'left' }}>
              <Typography
                style={{ display: 'block', paddingBottom: 12 }}
                variant='body'
              >
                {isPartnerAdmin
                  ? global._('Documents.PartnerAdminInfoDialogContent')
                  : [
                      isCustomerAdmin
                        ? global._('Documents.CustomerAdminInfoDialogContent')
                        : global._('Documents.CustomerInfoDialogContent')
                    ]}
              </Typography>
              <VideoInstructionsModal videoId={instructionsVideoId} />
            </div>
          }
          buttons={[
            <Button variant='none' onClick={() => setShowInfoModal(false)}>
              {global._('Common.Close')}
            </Button>
          ]}
        />
      )}
      <NavigationBar
        title={global._('Documents.Document')}
        backAction={() => {
          if (history.length > 0) {
            history.goBack()
          } else {
            history.push('/dashboard')
          }
        }}
        rightActionItem={
          <div
            onClick={() => setShowInfoModal(true)}
            style={{ color: 'var(--color-primary)' }}
          >
            <Icon>info</Icon>
          </div>
        }
      />
      <ScrollView id='scrollable-container'>
        <div style={{ paddingBottom: 20 }}>
          {error && (
            <Notification
              type='error'
              message={error}
              style={{ margin: '20px 0' }}
            />
          )}
          <Filter
            user={user}
            setError={setError}
            handleFilter={handleFilter}
            filter={filter}
            isMobile={isMobile}
          />
          <ObjectListMobile
            objects={documents}
            user={user}
            history={history}
            objectPermalink='documents'
            noResultsMessage={noResultsMessage}
            columns={columns}
            canFetchMoreObjects={canFetchMoreDocuments}
            fetchMoreObjects={fetchMoreDocuments}
            isLoadingObjects={isLoadingDocuments}
          />
          {(isPartnerAdmin || isCustomerAdmin) && (
            <CenteredFloatingButtonMobile
              onClick={() => setShowAddDocumentModal(true)}
            />
          )}
        </div>
      </ScrollView>
    </MobileContainer>
  )
}

DocumentsMobile.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  canFetchMoreDocuments: PropTypes.bool.isRequired,
  fetchMoreDocuments: PropTypes.func.isRequired,
  isLoadingDocuments: PropTypes.bool.isRequired,
  documents: PropTypes.array.isRequired,
  setShowAddDocumentModal: PropTypes.func.isRequired,
  isPartnerAdmin: PropTypes.bool.isRequired,
  isCustomerAdmin: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
}

DocumentsMobile.defaultProps = {}

const DocumentsMobileWithStyles = injectSheet(styles)(DocumentsMobile)
export default DocumentsMobileWithStyles
