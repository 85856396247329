import React from 'react'
import Icon from '@material-ui/core/Icon'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import Theme from '../../helpers/Theme'
import Row from '../../components/Row'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import Column from '../../components/Column'
import useWindowDimensions from '../../utils/useWindowDimensions'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import MobileContainer from '../../components/MobileContainer'

const styles = {
  root: {
    backgroundColor: '#fdfdfd',
    display: 'flex',
    flex: 1,
    minHeight: '100%',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: 'url("/bg-image.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundOrigin: 'padding-box'
  },
  contentContainer: {
    width: 760,
    flex: 1,
    minHeight: '100%',
    maxWidth: '100%',
    height: '100%',
    backgroundColor: Theme.Colors.WHITE,
    overflow: 'hidden'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '55px 47px'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    borderRadius: 500,
    backgroundColor: Theme.Colors.PRIMARY_TONE_3
  },
  '@media (max-width: 1200px)': {
    contentContainer: {
      paddingTop: 20
    }
  },

  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */
  '@media (max-width: 900px)': {
    contentContainer: {
      padding: 20
    }
  }
}

const QuoteRequestSuccess = props => {
  const {
    classes,
    computedMatch: {
      params: { propertyId }
    },
    history
  } = props

  const { isMobile } = useWindowDimensions()

  if (isMobile) {
    return (
      <MobileContainer
        background='white'
        data-cy='container-quote-request-success'
      >
        <NavigationBar
          title={global._('Properties.RequestForQuotation')}
          backAction={() => history.goBack()}
        />
        <ScrollView>
          <div className={classes.contentContainer}>
            <Column align='center'>
              <div className={classes.iconContainer}>
                <Icon
                  style={{
                    fontSize: 68
                  }}
                >
                  check
                </Icon>
              </div>
              <Row>
                <Typography
                  variant='title'
                  style={{
                    display: 'inline-block',
                    textAlign: 'center',
                    marginTop: 20
                  }}
                >
                  {`${global._('Common.Your')} `}
                  <Typography
                    bold
                    variant='title'
                    style={{ display: 'inline-block' }}
                  >
                    {`${global._('QuoteRequest.RequestForQuotation')}`}
                  </Typography>
                  {` ${global._('Common.HasNowBeenSent')}`}
                </Typography>
              </Row>
              <Row>
                <Typography
                  align='center'
                  variant='h3'
                  style={{
                    marginTop: 20
                  }}
                >
                  {global._('QuoteRequest.QuoteRequestSuccess')}
                </Typography>
              </Row>
            </Column>
            <Column />
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 20,
              width: '100%',
              padding: 20
            }}
          >
            <Button
              variant='primary'
              onClick={() => history.push(`/properties/${propertyId}`)}
            >
              {global._('QuoteRequest.BackToButton')}
            </Button>
          </div>
        </ScrollView>
      </MobileContainer>
    )
  }

  return (
    <div data-cy='container-quote-request-success' className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.container}>
          <Column align='center'>
            <div className={classes.iconContainer}>
              <Icon
                style={{
                  fontSize: 68
                }}
              >
                check
              </Icon>
            </div>
          </Column>
          <div className={classes.container}>
            <Column align='center'>
              <Row>
                <Typography variant='title' style={{ display: 'inline-block' }}>
                  {`${global._('Common.Your')} `}
                  <Typography
                    bold
                    variant='title'
                    style={{ display: 'inline-block' }}
                  >
                    {`${global._('QuoteRequest.RequestForQuotation')}`}
                  </Typography>
                  {` ${global._('Common.HasNowBeenSent')}`}
                </Typography>
              </Row>
              <Row>
                <Typography
                  align='center'
                  variant='h3'
                  style={{
                    marginTop: 20,
                    padding: '0 100px 0 100px'
                  }}
                >
                  {global._('QuoteRequest.QuoteRequestSuccess')}
                </Typography>
              </Row>
            </Column>
          </div>
          <Column align='center'>
            <div className={classes.container}>
              <Button
                data-cy='button-navigate-to-property'
                variant='primary'
                style={{
                  maxWidth: 194,
                  padding: '10px 19px'
                }}
                onClick={() => history.push(`/properties/${propertyId}`)}
              >
                {global._('QuoteRequest.BackToButton')}
              </Button>
            </div>
          </Column>
          <Column />
        </div>
      </div>
    </div>
  )
}

QuoteRequestSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  computedMatch: PropTypes.object.isRequired
}

const RegisterWithStyle = injectSheet(styles)(QuoteRequestSuccess)
export default RegisterWithStyle
