import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core'
import moment from 'moment'
import { Today } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import Typography from './Typography'

const styles = {
  root: {},
  returndateModalSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    borderTop: '1px solid var(--color-divider-light)',
    borderBottom: props =>
      props.borderBottom ? '1px solid var(--color-divider-light)' : undefined,
    marginLeft: '-20px',
    width: 'calc(100% + 40px)',
    padding: props => (props.borderBottom ? 20 : '20px 20px 0px 20px')
  }
}

const ExpectedReturnDate = props => {
  const {
    classes,
    isModal,
    update,
    setExpectedReturnDate,
    expectedReturnDate,
    setUntilFurtherNotice,
    untilFurtherNotice
  } = props

  const DatePickerContent = (
    <DatePicker
      style={{ width: '100%' }}
      InputProps={{
        endAdornment: <Today style={{ cursor: 'pointer' }} />
      }}
      inputVariant='filled'
      label={global._('Keys.Loan.UserSelect.ExpectedReturnDate')}
      format='YYYY-MM-DD'
      helperText={global._('Common.Required')}
      value={expectedReturnDate || null}
      cancelLabel={null}
      okLabel={null}
      minDate={moment()}
      autoOk
      disabled={untilFurtherNotice}
      onChange={d => {
        const _expectedReturnDate = d ? d.format('YYYY-MM-DD') : null
        setExpectedReturnDate(_expectedReturnDate)
        if (update) {
          update({ expectedReturnDate: _expectedReturnDate })
        }
      }}
    />
  )

  const FormControlLabelContent = (
    <FormControlLabel
      onChange={e => {
        const _untilFurtherNotice = e.target.checked
        setUntilFurtherNotice(_untilFurtherNotice)
        setExpectedReturnDate(null)
        if (update) {
          update({
            untilFurtherNotice: _untilFurtherNotice,
            expectedReturnDate: null
          })
        }
      }}
      style={{
        margin: '0 0 20px',
        marginRight: 0,
        minWidth: 155
      }}
      label={
        <div>
          <Typography variant='body' bold>
            {global._('Common.UntilFurtherNotice')}
          </Typography>
        </div>
      }
      control={
        <div
          style={{
            minWidth: 60,
            textAlign: 'center',
            position: 'relative'
          }}>
          <Checkbox checked={untilFurtherNotice} />
        </div>
      }
    />
  )

  return (
    <>
      {isModal ? (
        <div className={classes.returndateModalSection}>
          {DatePickerContent}
          {FormControlLabelContent}
        </div>
      ) : (
        <Grid
          container
          style={{ padding: '22px 20px', marginBottom: 6 }}
          spacing={3}
          alignItems='center'>
          <Grid item xs={6}>
            {DatePickerContent}
          </Grid>
          <Grid item xs={6}>
            {FormControlLabelContent}
          </Grid>
        </Grid>
      )}
    </>
  )
}

ExpectedReturnDate.propTypes = {
  classes: PropTypes.object.isRequired,
  setExpectedReturnDate: PropTypes.func.isRequired,
  setUntilFurtherNotice: PropTypes.func.isRequired,
  update: PropTypes.func,
  expectedReturnDate: PropTypes.string,
  untilFurtherNotice: PropTypes.bool,
  isModal: PropTypes.bool
}

ExpectedReturnDate.defaultProps = {
  update: undefined,
  expectedReturnDate: null,
  untilFurtherNotice: false,
  isModal: true
}

const ExpectedReturnDateWithStyles = injectSheet(styles)(ExpectedReturnDate)
export default ExpectedReturnDateWithStyles
