/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import FaceIcon from '@material-ui/icons/Face'
import InfoIcon from '@material-ui/icons/Info'
import HelpIcon from '@material-ui/icons/Help'
import { Icon } from '@material-ui/core'
import Typography from './Typography'
import Paper from './Paper'
import Button from './Button'

const styles = {
  paper: {
    padding: '30px 40px 40px',
    backgroundColor: 'var(--color-primary-tone)',
    flexDirection: 'column',
    alignItems: 'center',
    border: 0,
    position: 'relative'
  },
  icon: {
    fontSize: '36px !important',
    color: 'var(--color-primary-tone-2)',
    marginBottom: 17
  },
  alertTitle: {
    marginBottom: 20
  },
  rightBottom: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    zIndex: 9999,
    width: '100%'
  },
  small: {
    maxWidth: 390
  },
  closeIcon: {
    position: 'absolute',
    top: 13,
    right: 13,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.85
    }
  },
  '@media (max-width: 900px)': {
    paper: {
      padding: '30px var(--section-padding-m-ext-h) 50px'
    },
    alertTitle: {
      marginBottom: 15
    },
    rightBottom: {
      position: 'absolute',
      right: 0,
      left: 0,
      bottom: 0
    }
  }
}

const FloatingAlert = ({
  classes,
  icon,
  title,
  subtitle,
  message,
  buttonLabel,
  buttonAction,
  buttonVariant,
  rightBottom,
  small,
  closeBtn,
  closeBtnAction
}) => {
  let IconComponent

  if (icon === 'face') IconComponent = FaceIcon
  if (icon === 'info') IconComponent = InfoIcon
  if (icon === 'help') IconComponent = HelpIcon

  return (
    <div
      className={`${classes.floatingAlertContainer} ${
        rightBottom && classes.rightBottom
      } ${small && classes.small}`}>
      <Paper classes={classes}>
        <IconComponent className={classes.icon} />
        {title ? (
          <Typography variant='alertTitle' align='center' classes={classes}>
            {title}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography
            variant='subtitleBig'
            bold
            align='center'
            classes={classes}>
            {subtitle}
          </Typography>
        ) : null}
        {message ? (
          <Typography variant='body' align='center'>
            {message}
          </Typography>
        ) : null}
        {buttonLabel ? (
          <Button
            variant={buttonVariant}
            style={{ marginTop: 30 }}
            onClick={buttonAction}>
            {buttonLabel}
          </Button>
        ) : null}
        {closeBtn ? (
          <div onClick={() => closeBtnAction()} className={classes.closeIcon}>
            <Icon>clear</Icon>
          </div>
        ) : null}
      </Paper>
    </div>
  )
}

FloatingAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  rightBottom: PropTypes.bool,
  small: PropTypes.bool,
  subtitle: PropTypes.string,
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonAction: PropTypes.func,
  buttonVariant: PropTypes.string,
  closeBtn: PropTypes.bool,
  closeBtnAction: PropTypes.func
}

FloatingAlert.defaultProps = {
  icon: 'info',
  title: '',
  rightBottom: false,
  small: false,
  subtitle: null,
  message: '',
  buttonAction: () => '',
  closeBtn: false,
  closeBtnAction: () => ''
}

const FloatingAlertWithStyles = injectSheet(styles)(FloatingAlert)
export default FloatingAlertWithStyles
