import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { withRouter } from 'react-router-dom'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import useWindowDimensions from '../../utils/useWindowDimensions'
import NavigationBar from '../../components/NavigationBar'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '100px 0',
    minHeight: '100%'
  },
  paper: {
    backgroundColor: 'var(--color-white)',
    maxWidth: 800,
    width: '100%',
    position: 'relative',
    padding: '110px var(--section-padding-default) 72px',
    boxShadow: 'var(--paper-shadow)',
    borderRadius: 'var(--paper-border-radius)'
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 115
  },
  subtitleContainer: {
    maxWidth: '60%',
    margin: '0 auto 53px'
  },
  '@media (max-width: 900px)': {
    root: {
      padding: 0
    },
    paper: {
      padding: '80px var(--section-padding-m-ext-h)',
      boxShadow: 'none',
      maxWidth: '100%'
    },
    subtitleContainer: {
      maxWidth: 500
    }
  }
}

const NotFound = ({ classes, history }) => {
  const { isMobile } = useWindowDimensions()

  if (!isMobile) {
    document.body.style.backgroundColor = 'var(--color-light-grey)'
  } else {
    document.body.style.backgroundColor = 'var(--white)'
  }

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        {!isMobile ? (
          <img src='/logos/HeaderLogo-230.png' className={classes.logo} />
        ) : (
          <NavigationBar title={global._('NotFound.PageTitle')} />
        )}
        <div className={classes.content} style={{ textAlign: 'center' }}>
          {!isMobile ? (
            <Typography
              variant='h3'
              align='center'
              style={{ fontSize: 46, marginTop: 50, marginBottom: 8 }}>
              {global._('NotFound.PageTitle')}
            </Typography>
          ) : null}
          <Typography variant='h1' align='center' style={{ marginBottom: 25 }}>
            {global._('NotFound.PageNotFound')}
          </Typography>
          <div className={classes.subtitleContainer}>
            <Typography variant='subtitleBig' align='center'>
              {global._('NotFound.Reason')}
            </Typography>
          </div>
          <Button
            variant='primary'
            style={{ width: 'auto' }}
            onClick={() => history.push('/')}>
            {global._('NotFound.ButtonText')}
          </Button>
        </div>
      </div>
    </div>
  )
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

const NotFoundWithStyles = injectSheet(styles)(NotFound)
export default withRouter(NotFoundWithStyles)
