import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon } from '@material-ui/core'
import Typography from './Typography'

const styles = {
  root: {
    position: 'fixed',
    zIndex: 1010,
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'var(--navigation-bar-height)',
    padding: 10,
    backgroundColor: 'var(--color-black)',
    color: 'var(--color-white)'
  },
  title: {
    width: 'calc(100% - 80px)'
  },
  leftActionItem: {
    overflow: 'hidden',
    marginRight: 'auto',
    flex: '0 40px',
    color: 'var(--color-primary)',
    paddingRight: 20
  },
  rightActionItem: {
    marginLeft: 'auto',
    display: 'flex',
    flex: '0 40px',
    color: 'var(--color-primary)'
  }
}

const NavigationBar = props => {
  const {
    classes,
    title,
    backAction,
    leftActionItem,
    rightActionItem,
    hidden
  } = props

  if (hidden) {
    return null
  }
  return (
    <div className={classes.root}>
      <div className={classes.leftActionItem}>
        {leftActionItem}
        {backAction && !leftActionItem && (
          <div
            role='button'
            style={{ width: 40, height: 40 }}
            tabIndex='0'
            onClick={backAction}
          >
            <Icon style={{ fontSize: 40 }}>chevron_left</Icon>
          </div>
        )}
      </div>
      <Typography
        ellipsis
        style={{ color: 'var(--color-white)', fontSize: 17 }}
        bold={600}
      >
        {title}
      </Typography>
      <div className={classes.rightActionItem}>{rightActionItem}</div>
    </div>
  )
}

NavigationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  backAction: PropTypes.func,
  leftActionItem: PropTypes.node,
  rightActionItem: PropTypes.node,
  hidden: PropTypes.bool
}

NavigationBar.defaultProps = {
  title: '',
  backAction: null,
  leftActionItem: null,
  rightActionItem: null,
  hidden: false
}

const NavigationBarWithStyle = injectSheet(styles)(NavigationBar)
export default NavigationBarWithStyle
