export const standardTermsJsonNO = {
	"blocks": [
		{
			"key": "dnkmq",
			"text": "Følgende gjelder for den kvitterte nøkkelen:",
			"type": "unstyled",
			"depth": 0,
			"inlineStyleRanges": [
				{
					"offset": 0,
					"length": 44,
					"style": "BOLD"
				}
			],
			"entityRanges": [],
			"data": {}
		},
        {
            "key": "S8tgfn",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        },
        {
			"key": "72r0t",
			"text": "Nøkkelen er et verdiobjekt.",
			"type": "unordered-list-item",
			"depth": 0,
			"inlineStyleRanges": [],
			"entityRanges": [],
			"data": {}
		},
		{
			"key": "c5ro0",
			"text": "Nøkkelen skal oppbevares på en sikker måte.",
			"type": "unordered-list-item",
			"depth": 0,
			"inlineStyleRanges": [],
			"entityRanges": [],
			"data": {}
		},
		{
			"key": "2ofe5",
			"text": "Nøkkelen kan ikke lånes ut.",
			"type": "unordered-list-item",
			"depth": 0,
			"inlineStyleRanges": [],
			"entityRanges": [],
			"data": {}
		},
		{
			"key": "e68dq",
			"text": "Ved tap av nøkkel skal dette varsles umiddelbart.",
			"type": "unordered-list-item",
			"depth": 0,
			"inlineStyleRanges": [],
			"entityRanges": [],
			"data": {}
		},
		{
			"key": "bja62",
			"text": "Ved ev. nøkkelrevisjon må nøkkelinnehaver bekrefte at nøkkelen er under kontroll.",
			"type": "unordered-list-item",
			"depth": 0,
			"inlineStyleRanges": [],
			"entityRanges": [],
			"data": {}
		}
	],
	"entityMap": {}
}

const privacyPolicyJson = [
    {
        "key": "S8tgfndz",
        "text": "",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "58s7j",
        "text": "Hvilke personopplysninger vi samler inn og hvorfor vi samler de inn",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [
            {
                "offset": 0,
                "length": 67,
                "style": "BOLD"
            }
        ],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "S8tgfnz",
        "text": "",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
      },
        {
        "key": "2qo66",
        "text": "Kontaktskjema",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [
            {
                "offset": 0,
                "length": 13,
                "style": "BOLD"
            }
        ],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "a20pt",
        "text": "Brukeropplysninger som samles inn gjennom kontaktskjemaer (navn, e-postadresse, telefonnummer) lagres på ubestemt tid for å kunne komme tilbake til deg.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "S8tgfn1",
        "text": "",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "ecsb8",
        "text": "Informasjonskapsler",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [
            {
                "offset": 0,
                "length": 19,
                "style": "BOLD"
            }
        ],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "bdle3",
        "text": "En informasjonskapsel er en liten tekstfil som lagres på datamaskinen din og inneholder informasjon. Den brukes for å gjøre det enklere for besøkende på nettstedet. Vi lagrer ingen personopplysninger gjennom informasjonskapsler. Du kan endre innstillingene i nettleseren din dersom du ikke ønsker at informasjonskapsler skal brukes.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "em2k9",
        "text": "På digitallassmed.se bruker informasjonskapsler vi for å gjøre nettstedet mer brukervennlig for deg. Hvis du gjør endringer på nettstedet, lagres disse i en informasjonskapsel. Neste gang du besøker siden, slipper du å gjøre den samme endringen på nytt. Informasjonskapselen informerer nettstedet om at du har besøkt det før og hvilke endringer som er gjort. Hvis du endrer disse innstillingene igjen, vil den gamle informasjonskapselen bli erstattet med en ny. Informasjonskapselen brukes på denne måten for å gjøre det enklere for deg som besøkende på nettstedet.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "S8tgfn2",
        "text": "",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "bvre8",
        "text": "Innebygd innhold fra andre nettsteder",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [
            {
                "offset": 0,
                "length": 37,
                "style": "BOLD"
            }
        ],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "bdq4o",
        "text": "Artikler på dette nettstedet kan inneholde innebygd innhold (som videoer, bilder, artikler osv.). Innebygd innhold fra andre nettsider oppfører seg på nøyaktig samme måte som om den besøkende har besøkt den andre nettsiden. Disse nettstedene kan samle inn informasjon om deg, bruke informasjonskapsler, legge inn ytterligere sporing fra tredjeparter og overvåke din interaksjon med det innebygde innholdet, inkludert sporing av interaksjonen din med dette innebygde innholdet hvis du har en konto og er logget inn på det aktuelle nettstedet.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "S8tgfn3",
        "text": "",
        "type": "unstyled",
       "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "4h5ca",
        "text": "Hvor lenge vi beholder opplysningene dine",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [
            {
                "offset": 0,
                "length": 41,
                "style": "BOLD"
            }
        ],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "4ptg4",
        "text": "Hvis du registrerer deg som bruker på nettstedet vårt, lagrer vi også personopplysningene som er spesifisert i din. Du kan se, redigere eller slette personopplysningene dine når som helst (bortsett fra at du ikke kan endre brukernavnet ditt). Nettstedets administratorer kan også se og redigere denne informasjonen.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "S8tgfn4",
        "text": "",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "ahu84",
        "text": "Hvilke rettigheter du har vedrørende opplysningene dine",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [
            {
                "offset": 0,
                "length": 55,
                "style": "BOLD"
            }
        ],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "854c8",
        "text": "Hvis du har en konto eller har skrevet kommentarer på dette nettstedet, kan du be om en eksportfil med personopplysningene vi har om deg, inkludert all informasjon du har gitt oss. Du kan også be om at vi sletter alle personopplysningene vi har samlet inn om deg. Dette inkluderer ikke informasjon som vi må lagre av administrative, juridiske eller sikkerhetsmessige årsaker.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    },
    {
        "key": "am9kj",
        "text": "Personansvarlig for personopplysninger er Rosie Samuelsson. Den enkleste måten å kontakte henne på er via e-post: info@digiallassmed.se.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    }
]

const genStandardTermsWithPrivacyPolicyJson = () => {
    const terms = JSON.parse(JSON.stringify(standardTermsJsonNO))

    const blocks = terms.blocks.concat(privacyPolicyJson)

    terms.blocks = blocks

    return terms
}
  
export const standardTermsWithPrivacyPolicyJsonNO = genStandardTermsWithPrivacyPolicyJson()
