import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Typography from 'src/components/Typography'
import Paper from 'src/components/Paper'
import Button from 'src/components/Button'
import SuccessIcon from 'src/components/SuccessIcon'
import Notification from 'src/common/Notification'
import FullscreenModal from 'src/components/FullscreenModal'
import { TextField } from '@material-ui/core'
import { emailRegExp } from 'src/utils/helpers'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import axios from 'axios'

const styles = {
  root: {
    minHeight: '100vh',
    background: 'var(--color-material-grey)',
    width: '100%',
    padding: '144px 0 60px',
    display: 'flex',
    alignItems: 'center'
  }
}

const isEmailValid = email => emailRegExp.test(email)

const ActivateAccount = props => {
  const { classes, history } = props

  const { isMobile } = useWindowDimensions()

  const [email, setEmail] = useState(null)
  const [activated, setActivated] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setError(null)
  }, [email])

  const sendRequest = async () => {
    if (!email) {
      return
    }

    try {
      setError(null)

      await axios.post('/v1/users/activate', { email })

      setActivated(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  let content = (
    <>
      {error && (
        <Notification
          style={{ marginBottom: 20 }}
          type='error'
          message={error}
        />
      )}
      <TextField
        data-cy='input-textfield-activation-email'
        fullWidth
        label={global._('Common.Email')}
        variant='filled'
        name='query'
        onChange={e => setEmail(e.target.value)}
      />
      <Button
        data-cy='button-activate-account'
        variant='primary'
        disabled={!email || !isEmailValid(email)}
        onClick={() => sendRequest()}
        style={{ marginTop: 40 }}
      >
        {global._('ActivateAccount.ButtonLabel')}
      </Button>
    </>
  )

  if (activated) {
    content = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: isMobile ? 40 : ''
        }}
      >
        <SuccessIcon containerStyle={{ marginBottom: 38 }} />
        <Typography variant='subtitleBig' align='center'>
          {global._('ActivateAccount.ActivatedMessage')}
        </Typography>
        {isMobile && (
          <Button
            variant='none'
            style={{ marginTop: 40 }}
            onClick={() => history.push('/')}
          >
            {global._('Common.Back')}
          </Button>
        )}
      </div>
    )
  }

  if (isMobile) {
    return (
      <FullscreenModal
        title={global._('ActivateAccount.Title')}
        onClose={() => history.push('/')}
        padding
        content={content}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Typography variant='h1' style={{ marginBottom: 50 }}>
        {global._('ActivateAccount.Title')}
      </Typography>
      <Paper fixedWidth alignItems='center'>
        {content}
      </Paper>
      <Button
        variant='none'
        style={{ marginTop: 20 }}
        onClick={() => history.push('/')}
      >
        {global._('Common.Back')}
      </Button>
    </div>
  )
}

ActivateAccount.propTypes = {
  classes: PropTypes.object.isRequired
}

export default injectSheet(styles)(ActivateAccount)
