import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import Typography from './Typography'

const styles = {
  paper: {
    borderRadius: 3,
    borderBottomLeftRadius: props => (props.topPart || props.middlePart) && 0,
    borderBottomRightRadius: props => (props.topPart || props.middlePart) && 0,
    borderTopLeftRadius: props => (props.bottomPart || props.middlePart) && 0,
    borderTopRightRadius: props => (props.bottomPart || props.middlePart) && 0,
    boxShadow: props =>
      !props.noShadow &&
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
    backgroundColor: 'var(--color-white)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: props => (props.alignItems ? props.alignItems : 'flex-start'),
    border: props =>
      !props.noShadow && '1px solid var(--color-ultra-light-grey)',
    // zIndex: 999,
    padding: props =>
      props.smallerPadding
        ? 20
        : [
            props.biggerPadding
              ? '45px 80px'
              : [
                  props.mediumPadding
                    ? '30px 20px'
                    : [
                        props.noPadding
                          ? 0
                          : '45px var(--section-padding-d-int-h)'
                      ]
                ]
          ],
    width: props => (props.fixedWidth ? '100%' : null),
    maxWidth: props => {
      const { fixedWidth } = props

      if (fixedWidth) {
        if (typeof fixedWidth === 'string') {
          return parseInt(fixedWidth, 10)
        }

        return 500
      }

      return null
    },
    position: props => (props.topPart || props.middlePart ? 'relative' : null)
  },
  hideShadow: {
    '&:after': {
      content: '""',
      width: 'calc(100% + 2px)',
      height: 10,
      background: 'var(--color-white)',
      position: 'absolute',
      left: '-1px',
      bottom: '-11px',
      display: 'block',
      borderTop: '1px solid var(--color-divider-light)'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '22px 20px',
    borderBottom: '1px solid var(--color-divider-light)'
  },
  headerIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    flex: '0 0 auto',
    backgroundColor: 'var(--color-light-grey)',
    borderRadius: 500,
    '& span': {
      fontSize: 30,
      color: 'var(--color-middle-grey)'
    }
  },
  headerTitleContainer: {
    display: 'flex',
    marginLeft: 20,
    flexDirection: 'column'
  },
  '@media (max-width: 450px)': {
    paper: {
      paddingLeft: '30px !important',
      paddingRight: '30px !important'
    }
  }
}

const Paper = ({
  classes,
  children,
  style,
  topPart,
  middlePart,
  onClick,
  icon,
  title,
  subtitle,
  buttons
}) => (
  <div
    className={`${classes.paper} ${
      topPart || middlePart ? classes.hideShadow : ''
    }`}
    style={style}
    onClick={onClick}>
    {(icon || title || subtitle) && (
      <div className={classes.header}>
        {icon && (
          <div className={classes.headerIcon}>
            <Icon>{icon}</Icon>
          </div>
        )}
        <div className={classes.headerTitleContainer}>
          {title && (
            <Typography variant='body2' faded>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant='subtitleBig' bold>
              {subtitle}
            </Typography>
          )}
        </div>
        {buttons && <div style={{ marginLeft: 'auto' }}>{buttons}</div>}
      </div>
    )}
    {children}
  </div>
)

Paper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.node
}

Paper.defaultProps = {
  icon: null,
  title: null,
  subtitle: null,
  buttons: null
}

export default injectSheet(styles)(Paper)
