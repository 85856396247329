import React from 'react'
import { Tooltip } from '@material-ui/core'
import injectSheet from 'react-jss'

const styles = {}

const UserNameTooltip = ({ user, classes }) => {
  if (!user || !user.name) {
    return null
  }

  const upperCaseName = user.name.charAt(0).toUpperCase() + user.name.slice(1)

  return (
    <Tooltip title={user && upperCaseName} placement='right' key={user.name}>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 40,
          height: 40,
          borderRadius: 100,
          lineHeight: '49px',
          fontSize: 16,
          float: 'left',
          fontWeight: 600,
          marginRight: 10,
          marginTop: 2,
          marginBottom: 2,
          color: 'var(--color-text)',
          textAlign: 'center',
          backgroundColor:
            user && user.colorCode ? user.colorCode : 'var(--color-light-grey)'
        }}>
        {upperCaseName
          .replace(/-/g, ' ')
          .match(/^[A-ZÅÄÖ]|[A-ZÅÄÖ](?=\S+$)/g)
          .join('')}
      </span>
    </Tooltip>
  )
}

const UserNameTooltipWithStyles = injectSheet(styles)(UserNameTooltip)
export default UserNameTooltipWithStyles
