import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Row from '../../../components/Row'
import Button from '../../../components/Button'
import Notification from '../../../common/Notification'
import Typography from '../../../components/Typography'
import Success from '../../../components/Success'
import { getSelectedOrg } from '../../../utils/helpers'
import withUser from '../../../utils/withUser'
import { history } from '../../../redux/store'
import KeysNavigation from '../../../components/Keys/KeysNavigation'
import GuideViewKeySelect from './KeySelect'
import GuideViewUserSelect from './UserSelect'
import GuideViewSign from './Signing'

const styles = {
  root: {}
}

export const STORAGE_KEY = 'keys.loan.data'
const GuideSteps = {
  UserSelect: 1,
  KeySelect: 2,
  Sign: 3
}

const KeyLoanGuide = ({ classes, breadcrumbItems, match, user }) => {
  const selectedOrg = getSelectedOrg(user)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isCompleting, setIsCompleting] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [keys, setKeys] = useState([])
  const [userData, setUserData] = useState({})
  const [validation, setValidation] = useState({})
  const [expectedReturnDate, setExpectedReturnDate] = useState(null)
  const [untilFurtherNotice, setUntilFurtherNotice] = useState(null)
  const [signData, setSignData] = useState({
    type: 'digital',
    digitalSignType: 'direct',
    template: null,
    file: null,
    completionData: null
  })

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const step = +match.params.step
  const onViewDataChange = (data, skipStorage) => {
    if (step === GuideSteps.KeySelect) {
      setKeys(data.keys)
    } else if (step === GuideSteps.UserSelect) {
      setUserData(data.user)
      setExpectedReturnDate(data.expectedReturnDate)
      setUntilFurtherNotice(data.untilFurtherNotice)
      setValidation(data.validation)
    } else if (step === GuideSteps.Sign) {
      return setSignData({ ...signData, ...data })
    }
    if (!skipStorage) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
    }
  }

  useEffect(() => {
    /* load saved data */
    try {
      const data = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {}
      setKeys(data.keys || [])
      setUserData(data.user || {})
      setValidation(data.validation || {})
      setExpectedReturnDate(data.expectedReturnDate || null)
      setUntilFurtherNotice(data.untilFurtherNotice || false)
    } catch (e) {}
    setIsLoading(false)
  }, [])

  const handleCancel = () => {
    localStorage.removeItem(STORAGE_KEY)
    history.push('/keys')
  }

  const checkIfUserExists = async () => {
    setError(null)
    try {
      if (userData) {
        let exists = false

        if (userData.personalNumber) {
          const { data } = await axios.get(
            `/v1/users?search=${userData.personalNumber}&exactMatch=true`
          )

          if (data && data.length > 0) {
            exists = true
          }
        }

        if (userData.email && !exists) {
          const { data } = await axios.get(
            `/v1/users?search=${userData.email}&exactMatch=true`
          )

          if (data && data.length > 0) {
            exists = true
          }
        }

        if (exists) {
          setError(global._('Errors.ERR_USER_EXISTS'))
          return true
        }

        return false
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleNext = async () => {
    setError(null)
    const nextStep = () => history.push(`${step + 1}`)

    if (step === GuideSteps.UserSelect) {
      if (userData && userData.active) {
        nextStep()
      } else {
        const userExists = await checkIfUserExists()

        if (!userExists) {
          nextStep()
        }
      }
    } else {
      nextStep()
    }
  }
  const handlePrevious = () => {
    setError(null)

    history.push(`${step - 1}`)
  }
  const handleFinish = async () => {
    setError(null)
    setIsCompleting(true)

    if (signData.postponedSignRequestSent) {
      localStorage.removeItem(STORAGE_KEY)
      setIsComplete(true)
    } else {
      try {
        const data = {
          expectedReturnDate,
          untilFurtherNotice,
          keys,
          user: userData,
          sign: {
            templateId: signData.template.id
          }
        }

        if (signData.type === 'analog') {
          data.sign.type = 'file'
          data.sign.file = signData.file
        } else if (signData.type === 'digital') {
          data.sign.type = 'bankid'
          data.sign.completionData = signData.completionData
        }
        await axios.post(
          `/v1/organisations/${selectedOrg.id}/flows/keyloan`,
          data
        )

        localStorage.removeItem(STORAGE_KEY)
        setIsComplete(true)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    }
    setIsCompleting(false)
  }

  useEffect(() => {
    if (signData.type === 'digital' && signData.completionData) {
      handleFinish()
    }
  }, [signData])

  useEffect(() => {
    setSignData(prevValues => {
      const newValues = { ...prevValues }
      if (userData.personalNumber) {
        newValues.type = 'digital'
      } else {
        newValues.type = 'analog'
      }

      return newValues
    })
  }, [userData])

  const stepsLength = Object.keys(GuideSteps).length
  let title
  let View
  const viewData = {
    keys,
    user: userData,
    validation,
    untilFurtherNotice,
    expectedReturnDate,
    sign: signData
  }
  let isNextButtonDisabled = false
  if (step === GuideSteps.KeySelect) {
    title = global._('Keys.Loan.KeySelect.Title')
    View = GuideViewKeySelect
    isNextButtonDisabled = !keys || !keys.length
  } else if (step === GuideSteps.UserSelect) {
    title = global._('Keys.Loan.UserSelect.Title')
    View = GuideViewUserSelect
    if (!userData) {
      isNextButtonDisabled = true
    } else if (!userData.id) {
      isNextButtonDisabled =
        (!expectedReturnDate && !untilFurtherNotice) ||
        (!userData.email && !userData.personalNumber) ||
        !userData.firstName ||
        !userData.lastName ||
        !userData.phone
    } else {
      isNextButtonDisabled =
        (!expectedReturnDate && !untilFurtherNotice) ||
        (validation.isValidated && validation.isKeyLimitReached)
    }
  } else if (step === GuideSteps.Sign) {
    title = global._('Keys.Loan.Signing.Title')
    View = GuideViewSign
    isNextButtonDisabled =
      (signData.type === 'analog' && (!signData.template || !signData.file)) ||
      (signData.type === 'digital' &&
        !signData.completionData &&
        !signData.postponedSignRequestSent)
  }

  return (
    <div className={classes.root}>
      {!isComplete && (
        <>
          <KeysNavigation
            breadcrumbItems={[
              ...breadcrumbItems,
              {
                name: `${global._(
                  'Keys.Loan.Breadcrumb'
                )} ${step}/${stepsLength}`
              }
            ]}
            user={user}
            error={error}
            hideNav
            disableOrgChange
          />
          <Row spacing={21}>
            <Typography variant='title' bold>
              {title}
            </Typography>
            <Button
              variant='outlined'
              onClick={handleCancel}
              disabled={signData.postponedSignRequestSent}
              style={{ marginLeft: 'auto' }}
            >
              {global._('Common.Cancel')}
            </Button>
            {step > 1 && (
              <Button
                variant='outlined'
                onClick={handlePrevious}
                disabled={signData.postponedSignRequestSent}
                style={{ marginLeft: 21 }}
              >
                {global._('Common.Previous')}
              </Button>
            )}
            {step < stepsLength && (
              <Button
                variant='primary'
                disabled={isNextButtonDisabled}
                onClick={handleNext}
                style={{ marginLeft: 21 }}
              >
                {global._('Common.Next')}
              </Button>
            )}
            {step === stepsLength && (
              <Button
                variant='primary'
                disabled={isCompleting || isNextButtonDisabled}
                onClick={handleFinish}
                loading={isCompleting}
                style={{ marginLeft: 21 }}
              >
                {global._('Common.Finish')}
              </Button>
            )}
          </Row>
          {error && <Notification type='error' message={error} />}
          {!isLoading && (
            <View data={viewData} onDataChange={onViewDataChange} />
          )}
        </>
      )}
      {isComplete && (
        <Success>
          <Typography variant='subtitleBig' align='center'>
            {global._('Keys.Loan.CompleteText')}
          </Typography>
          <Button
            variant='none'
            style={{ marginTop: 32 }}
            onClick={() => history.push('/keys')}
          >
            {global._('Common.Done')}
          </Button>
        </Success>
      )}
    </div>
  )
}

KeyLoanGuide.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired
}

KeyLoanGuide.defaultProps = {}

const KeyLoanGuideWithStylesAndUser = withUser(
  injectSheet(styles)(KeyLoanGuide)
)
export default KeyLoanGuideWithStylesAndUser
