import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Typography from './Typography'

const styles = {
  root: {
    width: '100%',
    padding: '19px 18px 26px',
    backgroundColor: '#fff',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    textAlign: 'center'
  },
  a: {
    color: 'inherit',
    textDecoration: 'underline',
    fontWeight: 'bold',
    '&:hover': {
      color: 'inherit'
    }
  }
}

const Footer = props => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <Typography
        bold
        variant='body'
        style={{ display: 'inline-block', marginTop: 13, marginBottom: 13 }}>
        {global._('Login.BackTo')}{' '}
        <a className={classes.a} href='https://digitallassmed.se'>
          digitallassmed.se
        </a>
      </Typography>
    </div>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
}

const FooterWithStyles = injectSheet(styles)(Footer)
export default FooterWithStyles
