import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon, TextField, MenuItem } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import moment from 'moment'
import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import PageContentHeader from '../../../components/PageContentHeader'
import Button from '../../../components/Button'
import Paper from '../../../components/Paper'
import Typography from '../../../components/Typography'
import { KeyStatus, UserRoles } from '../../../utils/constants'
import Modal from '../../../components/Modal'
import AsyncSelect from '../../../components/AsyncSelect'
import ObjectSection from '../../../components/ObjectSection'
import Notification from '../../../common/Notification'
import SuccessMessage from '../../../components/SuccessMessage'
import Row from '../../../components/Row'
import DragZone from '../../../components/DragAndDrop/DragZone'
import ItemBadge from '../../../components/ItemBadge'
import {
  getSelectedOrg,
  sizeInMb,
  convertDraftJsToPdfMakeObj
} from '../../../utils/helpers'
import KeyDetailsForm from './KeyDetailsForm'
import HolderModal from './HolderModal'
import KeyReceiptTermsModal from '../../../components/Keys/KeyReceiptTermsModal'
import { defaultContentFieldsJson } from '../../../utils/receiptTemplates'
import FileContainer from '../../../components/FileContainer'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const styles = {
  root: {},
  section: {
    width: 'calc(100% + 40px)',
    marginLeft: '-20px',
    marginRight: '-20px',
    padding: '0 var(--section-padding-default) var(--section-padding-default)',
    borderBottom: '1px solid var(--color-divider-light)',
    marginBottom: 'var(--section-padding-default)'
  }
}

const KeyDetails = ({ classes, breadcrumbItems, history, match, user }) => {
  const newKey = window.location.pathname.replace(/\/+$/, '').includes('/new')

  const selectedOrg = getSelectedOrg(user)

  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const [error, setError] = useState(false)
  const [fileError, setFileError] = useState(false)
  const [lastSelectedReceipt, setLastSelectedReceipt] = useState('')
  const [showHolderModal, setShowHolderModal] = useState(false)
  const [showKeyRingModal, setShowKeyRingModal] = useState(false)
  const [showReceiptUploadModal, setShowReceiptUploadModal] = useState(false)
  const [holder, setHolder] = useState(null)
  const [pendingHolder, setPendingHolder] = useState(null)
  const [tempPendingHolder, setTempPendingHolder] = useState(null)
  const [tempHolder, setTempHolder] = useState(null)
  const [keyring, setKeyring] = useState(null)
  const [receiptRequest, setReceiptRequest] = useState(null)
  const [tempReceiptRequest, setTempReceiptRequest] = useState(null)
  const [pendingReceiptRequest, setPendingReceiptRequest] = useState(null)
  const [keyObj, setKeyObj] = useState(null)
  const [showKeySavedSuccessMessage, setShowKeySavedSuccessMessage] =
    useState(null)
  const [showReceiptTemplateModal, setShowReceiptTemplateModal] =
    useState(false)
  const [showReceiptTermsModal, setShowReceiptTermsModal] = useState(false)
  const [receiptTerms, setReceiptTerms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedReceiptTemplate, setSelectedReceiptTemplate] = useState(null)
  const [
    showKeyHolderCancelledSuccessMessage,
    setShowKeyHolderCancelledSuccessMessage
  ] = useState(null)
  const [expectedReturnDate, setExpectedReturnDate] = useState()
  const [untilFurtherNotice, setUntilFurtherNotice] = useState()
  const [showDeletionModal, setShowDeletionModal] = useState(false)
  const [deleteError, setDeleteError] = useState('')

  const receiptPdfFileName = `${global._('Keys.New.Receipt.Receipt')}-${
    selectedReceiptTemplate && selectedReceiptTemplate.value.name
  }-${
    (tempHolder && tempHolder.item.name) || global._('Common.NoName')
  }-${moment(new Date()).format('YYYY-MM-DD')}`

  if (!selectedOrg) {
    history.push('/keys/list')
  }

  let editingHolder = holder

  if (pendingHolder) {
    editingHolder = pendingHolder
  }

  useEffect(() => {
    /* Used primarily for key copying */
    if (keyObj && keyObj.marking) {
      const prevKeyObj = { ...keyObj }

      setKeyObj({
        name: prevKeyObj.name,
        status: prevKeyObj.status,
        type: prevKeyObj.type,
        make: prevKeyObj.make,
        notes: prevKeyObj.notes,
        lockSystemId: prevKeyObj.lockSystemId,
        lockSystemName: prevKeyObj.lockSystemName
      })
    } else {
      setKeyObj(null)
    }

    setTempHolder(null)
    if (!(holder?.type === 'repository' || holder?.type === 'apartment')) {
      setHolder(null)
    }
    setTempPendingHolder(null)
    setPendingHolder(null)
    setTempReceiptRequest(null)
    setReceiptRequest(null)
    setPendingReceiptRequest(null)
    setKeyring(null)
    setExpectedReturnDate(null)
    setUntilFurtherNotice(null)
  }, [history.location.pathname])

  useEffect(() => {
    if (
      editingHolder &&
      (editingHolder.type === 'repository' ||
        editingHolder.type === 'apartment')
    ) {
      setTempReceiptRequest(null)
      setReceiptRequest(null)
      setPendingReceiptRequest(null)
    }

    setKeyring(null)
  }, [holder, pendingHolder])

  const fetchKey = async () => {
    if (!newKey) {
      try {
        const { data: key } = await axios.get(
          `v1/organisations/${selectedOrg.id}/keys/${match.params.keyId}?archived=true`
        )
        setKeyObj(key)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
    }
  }

  useEffect(() => {
    fetchKey()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!newKey && keyObj && (keyObj.userId || keyObj.pendingUserId)) {
        // If new holder is a user
        try {
          if (keyObj && keyObj.keyReceipts && keyObj.keyReceipts.length > 0) {
            const lastReceipt =
              keyObj.keyReceipts[keyObj.keyReceipts.length - 1]
            const lastSignedReceipt = keyObj.keyReceipts
              .reverse()
              .find(r => r.signed)

            if (keyObj.pendingUserId) {
              setPendingHolder({
                item: lastReceipt.user,
                type: 'user'
              })

              setPendingReceiptRequest({
                item: lastReceipt,
                type: lastReceipt.file ? 'file' : 'bankid',
                request: true
              })
            }

            if (!keyObj.pendingUserId) {
              // Remove pending holder
              setPendingHolder(null)
              setPendingReceiptRequest(null)
            }

            if (keyObj.userId) {
              setHolder({
                item: lastSignedReceipt.user,
                type: 'user'
              })

              setReceiptRequest({
                item: lastSignedReceipt,
                type: lastSignedReceipt.file ? 'file' : 'bankid',
                request: true
              })
            }
          }
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
      } else if (!newKey && keyObj && keyObj.storageId) {
        try {
          const { data: storage } = await axios.get(
            `v1/organisations/${selectedOrg.id}/keyStorages/${keyObj.storageId}`
          )
          setHolder({
            item: storage,
            type: 'repository'
          })

          if (!keyObj.pendingUserId) {
            // Remove pending holder
            setPendingHolder(null)
            setPendingReceiptRequest(null)
          }
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
      } else if (!newKey && keyObj && keyObj.apartmentId) {
        try {
          const { data: apartment } = await axios.get(
            `v1/apartments/${keyObj.apartmentId}`
          )
          setHolder({
            item: apartment,
            type: 'apartment'
          })

          if (!keyObj.pendingUserId) {
            // Remove pending holder
            setPendingHolder(null)
            setPendingReceiptRequest(null)
          }
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
      }

      if (!newKey && keyObj && keyObj.keyringId) {
        try {
          const { data: keyringObj } = await axios.get(
            `v1/organisations/${selectedOrg.id}/keyrings/${
              keyObj && keyObj.keyringId
            }`
          )
          setKeyring(keyringObj)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
      }

      if (keyObj && !keyObj.keyringId) {
        setKeyring(null)
      }
    })()
  }, [keyObj])

  const handleKeyAdd = async values => {
    setError(null)

    const valuesObj = { ...values }

    valuesObj.userId =
      editingHolder.type === 'user' ? editingHolder.item.id : null
    valuesObj.storageId =
      editingHolder.type === 'repository' ? editingHolder.item.id : null
    valuesObj.apartmentId =
      editingHolder.type === 'apartment' ? editingHolder.item.id : null

    if (receiptRequest && receiptRequest.type === 'bankid') {
      valuesObj.userId = null
    }

    try {
      const { data: newKeyObj } = await axios.post(
        `/v1/organisations/${selectedOrg.id}/keys`,
        valuesObj
      )

      localStorage.setItem('keyAddedSuccessfully', true)

      if (receiptRequest && receiptRequest.type === 'bankid') {
        // Send a receipt request - BankID
        await axios.post(
          `/v1/organisations/${selectedOrg.id}/keys/${newKeyObj.id}/receipts`,
          {
            templateId: receiptRequest.item.id,
            userId: editingHolder.item.id
          }
        )
      }

      if (receiptRequest && receiptRequest.type === 'file') {
        // Send a receipt request - File
        await axios.post(
          `/v1/organisations/${selectedOrg.id}/keys/${newKeyObj.id}/receipts`,
          {
            userId: editingHolder.item.id,
            file: receiptRequest.item.file
          }
        )
      }

      history.push('/keys/list')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleKeySave = async values => {
    setError(null)
    setShowKeySavedSuccessMessage(false)

    let returnDateObj = {} // If change comes from KeyDetailsForm, these are already stored in values-obj
    if (!newKey && (expectedReturnDate || untilFurtherNotice)) {
      returnDateObj = { expectedReturnDate, untilFurtherNotice } // New values from modal, use these
    }

    const valuesObj = { ...values, ...returnDateObj }

    if (tempReceiptRequest && tempReceiptRequest.type === 'bankid') {
      delete valuesObj.userId

      valuesObj.keyringId = null
    }

    try {
      setIsLoading(true)
      await axios.patch(
        `/v1/organisations/${selectedOrg.id}/keys/${keyObj.id}`,
        valuesObj
      )

      if (tempReceiptRequest && tempReceiptRequest.type === 'bankid') {
        // Send a receipt request - BankID
        await axios.post(
          `/v1/organisations/${selectedOrg.id}/keys/${keyObj.id}/receipts`,
          {
            templateId: tempReceiptRequest.item.id,
            userId: editingHolder.item.id
          }
        )
      }

      if (tempReceiptRequest && tempReceiptRequest.type === 'file') {
        // Send a receipt request - File
        const { data: receiptData } = await axios.post(
          `/v1/organisations/${selectedOrg.id}/keys/${keyObj.id}/receipts`,
          {
            userId: editingHolder.item.id,
            file: tempReceiptRequest.item.file
          }
        )

        const tempReceiptObj = { ...tempReceiptRequest }

        tempReceiptObj.item.id = receiptData.id

        setTempReceiptRequest(tempReceiptObj)
        setPendingHolder(null)
      }

      fetchKey()

      setTempReceiptRequest(null)
      setShowReceiptTemplateModal(false)
      setShowHolderModal(false)
      setShowKeySavedSuccessMessage(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }

    setIsLoading(false)
  }

  const cancelPendingHolder = async () => {
    try {
      await axios.delete(
        `v1/organisations/${selectedOrg.id}/keys/${keyObj.id}/receipts`
      )

      setShowKeyHolderCancelledSuccessMessage(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }

    fetchKey()
  }

  const AllowedFileSize = 10 * 1000 * 1000 // 10 MB
  const AllowedFileTypes = ['application/pdf', 'image/jpeg', 'image/png']

  const onFileSelected = selectedFile => {
    const { name, size } = selectedFile
    let errorCode

    if (!selectedFile.type) {
      // eslint-disable-next-line
      selectedFile.type = detectMimeType(name)
    }

    if (!AllowedFileTypes.includes(selectedFile.type)) {
      errorCode = 'ERR_FILE_TYPE'
      setFileError(errorCode)
    } else if (size > AllowedFileSize) {
      errorCode = 'ERR_FILE_SIZE'
      setFileError(errorCode)
    } else {
      try {
        setTempReceiptRequest({
          item: {
            file: selectedFile
          },
          type: 'file',
          request: true
        })
        setError(null)
      } catch (e) {
        errorCode = e.response ? e.response.data : e.message
        setFileError(global._(errorCode))
      }
    }
  }

  const onRemoveFile = () => {
    try {
      setTempReceiptRequest(null)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setFileError(global._(msg))
    }
  }

  const mergePdfContentParts = (values, digitalSignatureReceipt) => {
    let pdfContent = { blocks: [] }
    pdfContent = {
      ...defaultContentFieldsJson,
      blocks: [...defaultContentFieldsJson.blocks]
    }

    let _holder
    if (digitalSignatureReceipt) {
      _holder = digitalSignatureReceipt.user
    } else {
      _holder = tempHolder && tempHolder.item
    }

    const key = values ? { ...values } : { ...keyObj }

    pdfContent.blocks[4].text = selectedOrg.name
    pdfContent.blocks[7].text = _holder && _holder.name ? _holder.name : ''
    pdfContent.blocks[13].text = key && key.marking ? key.marking : ''
    pdfContent.blocks[16].text = global._(`Keys.Types.${key && key.type}`)
    pdfContent.blocks[19].text = key && key.make ? key.make : ''
    pdfContent.blocks[26].text = moment(new Date()).format('YYYY-MM-DD')
    pdfContent.blocks[35].text = _holder && _holder.name ? _holder.name : ''

    if (digitalSignatureReceipt) {
      const signatureBlocksStartIndex = pdfContent.blocks.findIndex(
        b => b.key === 'D157r5'
      )
      if (signatureBlocksStartIndex > -1) {
        pdfContent.blocks.splice(
          signatureBlocksStartIndex,
          pdfContent.blocks.length - signatureBlocksStartIndex
        )
        const text = global._('Keys.New.DigitalSign', [
          digitalSignatureReceipt.user.name,
          moment(digitalSignatureReceipt.signedAt).format('YYYY-MM-DD'),
          digitalSignatureReceipt.id
        ])
        const digitalSignatureBlock = {
          key: 'digitalSignatureBlockKey',
          text,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: text.indexOf('\n'), style: 'BOLD' }
          ],
          entityRanges: [],
          data: {}
        }
        pdfContent.blocks.push(digitalSignatureBlock)
      }
    }

    const content = digitalSignatureReceipt
      ? digitalSignatureReceipt.content
      : selectedReceiptTemplate.value.content

    const selectedTemplateObj = JSON.parse(content)
    pdfContent.blocks.splice(22, 0, ...selectedTemplateObj.blocks)

    return pdfContent
  }

  const printPdfReceipt = (values, keyReceipt) => {
    const pdfContent = mergePdfContentParts(values, keyReceipt)
    let docDefinition = convertDraftJsToPdfMakeObj(pdfContent)

    let title = receiptPdfFileName
    if (keyReceipt) {
      title = `${global._('Keys.New.Receipt.Receipt')}-${keyReceipt.user.name}`
    }
    docDefinition = {
      ...docDefinition,
      info: {
        title
      }
    }

    pdfMake.createPdf(docDefinition).print()
  }

  const token = localStorage.getItem('token')
  const receipt = receiptRequest || tempReceiptRequest
  const holderSectionObjects = editingHolder
    ? [
        pendingHolder && holder && global._('Common.Current'),
        holder,
        receiptRequest || null,
        pendingHolder && holder && global._('Common.Coming'),
        pendingHolder || null,
        pendingReceiptRequest || null
      ]
    : null
  const keyringsSectionObjects = keyring ? [keyring] : null

  const holderSectionIconOnClick = obj => {
    if (obj && obj.type === 'file') {
      return window.open(
        `${axios.defaults.baseURL}/v1/keys/receipts/${obj.item.id}/download?token=${token}`
      )
    }

    if (obj && obj.type === 'bankid') {
      setReceiptTerms(obj.item.content || null)
      setShowReceiptTermsModal(true)
    } else {
      return (
        obj &&
        obj.item &&
        window.open(
          `/admin/users/${
            obj.item.organisation
              ? obj.item.organisation.id
              : obj.item.organisationId || selectedOrg.id
          }/${obj.item.id}`,
          '_blank'
        )
      )
    }
  }

  const resolveObjectType = obj => {
    if (obj.request) {
      return global._('Keys.New.Receipt.Receipt')
    }

    if (obj.type === 'repository') {
      return (
        obj.item.space || global._('Keys.Repositories.NoSpaceDescriptionAdded')
      )
    }

    if (obj.type === 'apartment') {
      return `${obj.item.number || ''} ${obj.item.address}`
    }

    if (obj.item.organisation) {
      return obj.item.organisation.name
    }

    return obj.item.organisationName || selectedOrg.name || ''
  }

  const holderSectionColumns = [
    {
      key: 'icon',
      format: (objVal, obj) => {
        let holderSectionIcon

        if (!obj) return

        if (obj.type === 'user') {
          holderSectionIcon = 'face'
        } else if (obj.request) {
          holderSectionIcon = 'assignment'
        } else if (obj.type === 'repository') {
          holderSectionIcon = 'archive'
        } else if (obj.type === 'apartment') {
          holderSectionIcon = 'location_city'
        }

        return (
          <Icon
            style={{
              color: obj.request
                ? 'var(--color-middle-grey)'
                : 'var(--color-primary)'
            }}
          >
            {holderSectionIcon}
          </Icon>
        )
      }
    },
    {
      key: 'content',
      format: (objVal, obj) => {
        let itemName = ''

        if (obj.item) {
          if (obj.type === 'apartment') {
            itemName = obj.item.refId
          } else {
            itemName = obj.item.name
          }

          if (!itemName) {
            itemName = global._('Common.NoName')
          }
        }

        return (
          <div>
            <Typography variant='h4' bold>
              {receipt && obj.request && obj.type === 'file' ? (
                global._('Keys.New.Receipt.SignedReceipt')
              ) : (
                <>
                  {itemName}{' '}
                  {obj.item && obj.item.email ? `(${obj.item.email})` : ''}
                </>
              )}
            </Typography>
            <Typography variant='body2' faded>
              {obj.request && obj.type === 'file' ? (
                <>
                  {receipt && receipt.item.file.name}{' '}
                  {`(${sizeInMb(receipt && receipt.item.file.size)} mb)`}
                  {' - '}
                  {`${global._('Keys.New.Receipt.Uploaded')} ${moment(
                    (receiptRequest &&
                      receiptRequest.item.file &&
                      receiptRequest.item.file.createdAt) ||
                      new Date()
                  ).format('YYYY-MM-DD [kl] HH:mm')}`}
                </>
              ) : (
                <>{resolveObjectType(obj)}</>
              )}
            </Typography>
          </div>
        )
      }
    },
    {
      key: 'status',
      hiddenFor: obj => !obj.request || obj.type === 'file',
      format: (objVal, obj) =>
        obj && (
          <div>
            <span
              className={`status ${
                newKey || !obj.item.signedAt ? 'primary' : 'black'
              }`}
              style={{ marginLeft: 20 }}
            >
              {global._(
                `Keys.New.Receipt.${
                  newKey
                    ? 'RequestWillBeSent'
                    : [obj.item.signedAt ? 'Signed' : 'RequestHasBeenSent']
                }`
              )}{' '}
              {!newKey &&
                obj.item.signedAt &&
                moment(obj.item.signedAt).format('YYYY-MM-DD [kl] HH:mm')}
              {!newKey &&
                !obj.item.signedAt &&
                (tempHolder
                  ? moment().format('YYYY-MM-DD')
                  : moment(obj.item.createdAt).format('YYYY-MM-DD'))}
            </span>
          </div>
        )
    },
    {
      key: 'endIcon',
      format: (objVal, obj) =>
        obj && <Icon>{obj.type === 'file' ? 'get_app' : 'visibility'}</Icon>,
      alignEnd: true,
      clickable: true,
      hiddenFor: obj =>
        obj.type === 'repository' ||
        obj.type === 'apartment' ||
        (obj.type === 'file' && newKey),
      onClick: obj => holderSectionIconOnClick(obj)
    }
  ]

  const keyringSectionColumns = [
    {
      key: 'icon',
      format: (objVal, obj) =>
        obj && <Icon style={{ color: 'var(--color-primary)' }}>group_work</Icon>
    },
    {
      key: 'content',
      format: (objVal, obj) =>
        obj && (
          <div>
            <Typography variant='h4' bold>
              {obj && obj.name}
            </Typography>
            <Typography variant='body2' faded>
              {obj && obj.id}
            </Typography>
          </div>
        )
    },
    {
      key: 'endIcon',
      format: () => <Icon>delete</Icon>,
      hiddenFor: keyObj && keyObj.archived,
      alignEnd: true,
      clickable: true,
      onClick: () => {
        if (newKey) {
          setKeyring(null)
        } else {
          handleKeySave({ keyringId: null })
        }
      }
    }
  ]

  const validationSchema = Yup.object().shape({
    lockSystemId: Yup.string().required(global._('Common.Required')),
    status: Yup.string().required(global._('Common.Required')),
    type: Yup.string().required(global._('Common.Required')),
    marking: Yup.string()
      .required(global._('Common.Required'))
      .when('duplicate', (duplicate, schema) =>
        schema.test(
          'duplicate',
          global._('Keys.New.DuplicateKey'),
          () => !duplicate
        )
      )
  })

  let initialHolderType = 'user'

  if (keyObj) {
    if (keyObj.storageId) {
      initialHolderType = 'repository'
    } else if (keyObj.apartmentId) {
      initialHolderType = 'apartment'
    }
  }

  return (
    <div className={classes.root}>
      <Formik
        enableReinitialize
        initialValues={{
          lockSystemId: (keyObj && keyObj.lockSystemId) || '',
          name: (keyObj && keyObj.name) || '',
          status: (keyObj && keyObj.status) || KeyStatus.ACTIVE,
          type: (keyObj && keyObj.type) || '',
          make: (keyObj && keyObj.make) || '',
          marking: (keyObj && keyObj.marking) || '',
          duplicate: false,
          identifier: (keyObj && keyObj.identifier) || '',
          notes: (keyObj && keyObj.notes) || '',
          holderType: initialHolderType,
          user: '',
          expectedReturnDate: (keyObj && keyObj.expectedReturnDate) || null,
          untilFurtherNotice: (keyObj && keyObj.untilFurtherNotice) || false
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleBlur,
          setFieldValue,
          setTouched,
          validateField
        }) => (
          <div>
            {showKeySavedSuccessMessage ? (
              <SuccessMessage
                subtitle={global._('Keys.KeySavedSuccessMessage')}
              />
            ) : null}
            {showKeyHolderCancelledSuccessMessage ? (
              <SuccessMessage
                subtitle={global._('Keys.KeyHolderCancelledSuccessMessage')}
              />
            ) : null}
            {showReceiptTermsModal && (
              <KeyReceiptTermsModal
                title={global._('Common.Terms')}
                setShowModal={setShowReceiptTermsModal}
                content={receiptTerms}
              />
            )}
            {showHolderModal && (
              <HolderModal
                setShowHolderModal={setShowHolderModal}
                holder={editingHolder}
                setTempHolder={setTempHolder}
                setTempReceiptRequest={setTempReceiptRequest}
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                tempHolder={tempHolder}
                setHolder={setHolder}
                handleKeySave={handleKeySave}
                selectedOrg={selectedOrg}
                setReceiptRequest={setReceiptRequest}
                newKey={newKey}
                setPendingHolder={setPendingHolder}
                pendingHolder={pendingHolder}
                tempPendingHolder={tempPendingHolder}
                setTempPendingHolder={setTempPendingHolder}
                setPendingReceiptRequest={setPendingReceiptRequest}
                untilFurtherNotice={untilFurtherNotice}
                setUntilFurtherNotice={setUntilFurtherNotice}
                expectedReturnDate={expectedReturnDate}
                setExpectedReturnDate={setExpectedReturnDate}
                isLoading={isLoading}
              />
            )}
            {showReceiptUploadModal && (
              <Modal
                onClose={() => setShowReceiptUploadModal(null)}
                title={global._('Keys.New.Holder.UploadSignedReceipt')}
                content={
                  <div>
                    {fileError && (
                      <Notification
                        type='error'
                        message={fileError}
                        style={{ marginBottom: 20 }}
                      />
                    )}
                    <AsyncSelect
                      isSearchable
                      key={`receipttemplates-${
                        selectedReceiptTemplate &&
                        selectedReceiptTemplate.value &&
                        selectedReceiptTemplate.value.id
                      }`}
                      helpText={global._(
                        'Keys.New.Receipt.PrintReceiptTemplate'
                      )}
                      value={selectedReceiptTemplate}
                      labelKey='name'
                      name='receiptTemplateId'
                      isClearable
                      placeholder={global._('Keys.New.Receipt.ReceiptTemplate')}
                      onClick={handleBlur}
                      onChange={option => {
                        if (option && option.value && option.value.id) {
                          setFieldValue('receiptTemplateId', option.value.id)
                        } else {
                          setFieldValue('receiptTemplateId', '')
                        }
                        setSelectedReceiptTemplate(option)
                      }}
                      cacheOptions={false}
                      error={errors.receiptTemplateId}
                      url={`/v1/organisations/${selectedOrg.id}/keyreceipttemplates`}
                    />
                    {selectedReceiptTemplate && (
                      <div style={{ padding: '20px 0' }}>
                        <ItemBadge
                          style={{ marginTop: 20 }}
                          onClick={() =>
                            printPdfReceipt(newKey ? values : null)
                          }
                          icon='description'
                          endIcon='print'
                          onEndIconClick={() =>
                            printPdfReceipt(newKey ? values : null)
                          }
                          label={
                            <>
                              <span
                                style={{ marginLeft: 5, marginRight: 5 }}
                                className={classes.text}
                              >
                                {`${global._('Keys.New.Receipt.Receipt')}-${
                                  selectedReceiptTemplate.value.name
                                }-${
                                  tempHolder.item.name ||
                                  global._('Common.NoName')
                                }-${moment().format('YYYY-MM-DD')}`}
                              </span>
                            </>
                          }
                        />
                      </div>
                    )}
                    <div style={{ padding: '20px 0' }}>
                      <Typography
                        variant='body'
                        dangerouslySetInnerHTML={{
                          __html: global._(
                            'Keys.New.Holder.UploadReceiptStepInstructions'
                          )
                        }}
                      />
                    </div>
                    <div style={{ marginBottom: 10 }}>
                      {tempReceiptRequest && tempReceiptRequest.item.file ? (
                        <FileContainer
                          file={tempReceiptRequest.item.file}
                          name={tempReceiptRequest.item.file.name}
                          size={tempReceiptRequest.item.file.size}
                          type={tempReceiptRequest.item.file.type}
                          data={tempReceiptRequest.item.file.data}
                          onDelete={() => onRemoveFile(tempReceiptRequest.item)}
                        />
                      ) : (
                        <DragZone
                          onFileSelected={onFileSelected}
                          buttonOnly
                          buttonVariant='outlined'
                        />
                      )}
                    </div>
                    <Typography
                      variant='bodyFaded'
                      style={{ fontStyle: 'italic', marginTop: 20 }}
                    >
                      {global._('Keys.New.Holder.ReceiptFileInfo')}
                    </Typography>
                  </div>
                }
                buttons={[
                  <Button
                    variant='none'
                    onClick={() => {
                      setTempReceiptRequest(null)
                      setReceiptRequest(null)
                      setShowReceiptUploadModal(false)
                    }}
                  >
                    {global._('Common.Cancel')}
                  </Button>,
                  <Button
                    variant='primary'
                    disabled={!tempReceiptRequest}
                    onClick={() => {
                      setShowReceiptUploadModal(false)
                      setFileError(null)

                      if (newKey) {
                        setReceiptRequest(tempReceiptRequest)
                      } else {
                        handleKeySave({
                          userId: editingHolder.item.id || null,
                          storageId: null,
                          file: tempReceiptRequest.item.file,
                          keyringId: null
                        })
                      }
                    }}
                  >
                    {global._('Common.Upload')}
                  </Button>
                ]}
              />
            )}
            {showReceiptTemplateModal && (
              <Modal
                onClose={() => setShowReceiptTemplateModal(false)}
                title={global._('Keys.New.Holder.RequestBankIDReceipt')}
                content={
                  <div>
                    <AsyncSelect
                      isSearchable
                      helpText={global._('Common.Required')}
                      style={{ marginBottom: 20 }}
                      labelKey='name'
                      name='receiptTemplates'
                      onChange={e => {
                        setTempReceiptRequest(
                          e && e.value
                            ? {
                                item: e.value,
                                type: 'bankid',
                                request: true
                              }
                            : null
                        )
                      }}
                      placeholder={global._('Keys.New.Receipt.ReceiptTemplate')}
                      url={`/v1/organisations/${selectedOrg.id}/keyreceipttemplates?orderBy=name&order=asc&search={{input}}`}
                    />
                    <Typography
                      variant='body'
                      style={{ whiteSpace: 'pre-line' }}
                      italic
                    >
                      {global._('Keys.New.Receipt.ModalInfo')}
                    </Typography>
                  </div>
                }
                buttons={[
                  <Button
                    variant='none'
                    onClick={() => {
                      setShowReceiptTemplateModal(false)
                      setTempReceiptRequest(null)
                    }}
                  >
                    {global._('Common.Cancel')}
                  </Button>,
                  <Button
                    variant='primary'
                    disabled={!tempReceiptRequest}
                    loading={isLoading}
                    onClick={() => {
                      if (newKey) {
                        setReceiptRequest(tempReceiptRequest)
                        setShowReceiptTemplateModal(false)
                      } else {
                        handleKeySave({
                          userId: editingHolder.item.id || null,
                          templateId: tempReceiptRequest.item
                        })
                      }
                    }}
                  >
                    {!newKey
                      ? global._('Common.SendRequest')
                      : global._('Common.Choose')}
                  </Button>
                ]}
              />
            )}
            {showKeyRingModal && (
              <Modal
                onClose={() => setShowKeyRingModal(null)}
                title={
                  keyring
                    ? global._('Keys.New.KeyRing.ChangeRing')
                    : global._('Keys.New.KeyRing.AddRing')
                }
                content={
                  <div>
                    <Typography
                      variant='body'
                      italic
                      dangerouslySetInnerHTML={{
                        __html: global._('Keys.New.KeyRing.KeyRingInfo')
                      }}
                    />
                    <AsyncSelect
                      isSearchable
                      key={`keyring-${
                        (editingHolder && editingHolder.id) ||
                        (tempHolder && tempHolder.id)
                      }`}
                      style={{ marginTop: 20 }}
                      helpText={global._('Common.Required')}
                      value={values.keyring}
                      labelKey='name'
                      name='keyring'
                      label={obj =>
                        `${obj.name} (${
                          obj.holderName
                            ? obj.holderName
                            : global._('Keys.KeyRings.EmptyKeyRing')
                        })`
                      }
                      placeholder={global._('Keys.New.KeyRing.Title')}
                      onChange={e => {
                        setFieldValue('keyring', e)
                        setFieldValue(
                          'keyringId',
                          (e && e.value && e.value.id) || ''
                        )
                      }}
                      url={`/v1/organisations/${selectedOrg.id}/keyrings?orderBy=createdAt&order=desc&search={{input}}`}
                    />
                  </div>
                }
                buttons={[
                  <Button
                    variant='none'
                    onClick={() => {
                      setShowKeyRingModal(false)
                    }}
                  >
                    {global._('Common.Cancel')}
                  </Button>,
                  <Button
                    variant='primary'
                    onClick={() => {
                      setShowKeyRingModal(false)

                      if (!newKey) {
                        handleKeySave({ keyringId: values.keyringId || null })
                      } else {
                        setKeyring(
                          (values.keyring && values.keyring.value) || null
                        )
                      }
                    }}
                  >
                    {keyring
                      ? global._('Common.Modify')
                      : global._('Common.Add')}
                  </Button>
                ]}
              />
            )}
            <PageContentHeader
              breadcrumbItems={breadcrumbItems}
              actionButtons={
                <div>
                  {!newKey && (
                    <>
                      {/* <Button variant='outlined' style={{ marginRight: 'var(--section-padding-default)' }}>{global._('Keys.New.DownloadLog')}</Button> */}
                      <Button
                        variant='outlined'
                        style={{
                          marginRight: 'var(--section-padding-default)'
                        }}
                        onClick={() => history.push('/keys/list/new')}
                      >
                        {global._('Keys.New.CopyKey')}
                      </Button>
                      {keyObj && keyObj.archived ? (
                        <Button
                          variant='primary'
                          onClick={() => handleKeySave({ archived: false })}
                        >
                          {global._('Keys.New.ActivateKey')}
                        </Button>
                      ) : (
                        <Button
                          variant='secondary'
                          onClick={() => handleKeySave({ archived: true })}
                        >
                          {global._('Keys.New.ArchiveKey')}
                        </Button>
                      )}
                      {keyObj &&
                        user.roles.includes(UserRoles.PartnerAdmin) && (
                          <Button
                            variant='outlined'
                            color='danger'
                            onClick={() => setShowDeletionModal(true)}
                            style={{
                              marginLeft: 'var(--section-padding-default)'
                            }}
                          >
                            {global._('Common.Delete')}
                          </Button>
                        )}
                    </>
                  )}
                </div>
              }
            />
            {showDeletionModal && (
              <Modal
                onClose={() => setShowDeletionModal(false)}
                title={global._(`Common.ConfirmDeletion`)}
                content={
                  <div className={classes.deleteModal}>
                    <Typography variant='body'>
                      {global._(`Keys.DeleteItemConfirm`)}
                    </Typography>
                    {deleteError && (
                      <Notification
                        type='error'
                        message={deleteError}
                        style={{ marginTop: 20 }}
                      />
                    )}
                  </div>
                }
                buttons={[
                  <Button
                    key='Common.Cancel'
                    variant='none'
                    onClick={() => setShowDeletionModal(false)}
                    style={{ marginRight: 10 }}
                  >
                    {global._('Common.Cancel')}
                  </Button>,
                  <Button
                    key='Common.Remove'
                    variant='secondary'
                    onClick={async () => {
                      setDeleteError('')
                      try {
                        await axios.delete(
                          `/v1/organisations/${keyObj.organisationId}/keys/${keyObj.id}`
                        )
                        history.goBack()
                      } catch (e) {
                        const msg = e.response ? e.response.data : e.message
                        setDeleteError(msg)
                      }
                    }}
                  >
                    {global._('AccessGroups.Delete')}
                  </Button>
                ]}
              />
            )}
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ marginBottom: 20 }}
              />
            )}
            <Paper smallerPadding>
              <div
                className={classes.section}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <div
                      style={{
                        backgroundColor: 'var(--color-light-grey)',
                        width: 60,
                        height: 60,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '100%'
                      }}
                    >
                      <Icon
                        style={{
                          fontSize: 30,
                          color: 'var(--color-middle-grey)'
                        }}
                      >
                        vpn_key
                      </Icon>
                    </div>
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <Typography variant='body2' faded block>
                      {newKey
                        ? global._('Keys.New.Add')
                        : keyObj && global._(`Keys.Types.${keyObj.type}`)}
                    </Typography>
                    <Typography variant='subtitle' normalLineHeight block>
                      {newKey
                        ? global._('Keys.New.NewKey')
                        : keyObj && keyObj.name}
                    </Typography>
                  </div>
                </div>
                {keyObj && keyObj.archived && (
                  <div>
                    <span className='status black'>
                      {global._('Keys.Archived')}
                    </span>
                  </div>
                )}
              </div>
              <KeyDetailsForm
                handleChange={handleChange}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                keyObj={keyObj}
                newKey={newKey}
                handleKeySave={handleKeySave}
                touched={touched}
                setFieldValue={setFieldValue}
                selectedOrg={selectedOrg}
                setTouched={setTouched}
                validateField={validateField}
              />
            </Paper>
            <ObjectSection
              sectionTitle={global._('Keys.New.Holder.Title')}
              infoDialogTitle={global._('Keys.New.Holder.Title')}
              infoDialogContent={
                <div style={{ textAlign: 'left' }}>
                  <Typography
                    style={{ display: 'block', paddingBottom: 12 }}
                    variant='body'
                  >
                    {global._('Keys.New.Holder.Description')}
                  </Typography>
                </div>
              }
              headerActionButtons={
                <div>
                  {keyObj &&
                    keyObj.pendingUserId &&
                    (keyObj.userId ||
                      keyObj.storageId ||
                      keyObj.apartmentId) && (
                      <Button
                        variant='none'
                        style={{
                          marginRight: 'var(--section-padding-default)'
                        }}
                        disabled={keyObj && keyObj.archived}
                        onClick={() => cancelPendingHolder()}
                      >
                        {global._('Keys.KeyCancelPendingHolderButton')}
                      </Button>
                    )}
                  <Button
                    variant='outlined'
                    disabled={keyObj && keyObj.archived}
                    onClick={() => setShowHolderModal(true)}
                  >
                    {global._('Keys.New.Holder.ChangeHolder')}
                  </Button>
                </div>
              }
              footerActionButtons={
                editingHolder &&
                editingHolder.type === 'user' &&
                ((!receiptRequest && !pendingHolder) ||
                  (pendingHolder && !pendingReceiptRequest)) && (
                  <div>
                    <Button
                      variant='outlined'
                      disabled={keyObj && keyObj.archived}
                      onClick={() => {
                        setShowReceiptUploadModal(true)
                      }}
                    >
                      {global._('Keys.New.Holder.UploadSignedReceipt')}
                    </Button>
                    <Button
                      variant='secondary'
                      disabled={keyObj && keyObj.archived}
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        setShowReceiptTemplateModal(true)
                      }}
                    >
                      {global._('Keys.New.Holder.RequestBankIDReceipt')}
                    </Button>
                  </div>
                )
              }
              columns={holderSectionColumns}
              objects={holderSectionObjects}
              addObjectsContent={
                <>
                  <Typography
                    variant='subtitleBig'
                    bold
                    style={{ marginBottom: 5 }}
                  >
                    {global._('Keys.New.Holder.Title')}
                  </Typography>
                  <Typography variant='body2'>
                    {global._('Keys.New.Holder.Description')}
                  </Typography>
                </>
              }
              addObjectsActionButtons={
                <>
                  <Button
                    variant='primary'
                    onClick={() => setShowHolderModal(true)}
                  >
                    {global._('Common.Add')}
                  </Button>
                </>
              }
            />
            {keyObj && keyObj.keyReceipts && (
              <ObjectSection
                sectionTitle={global._('Keys.New.ReceiptHistory')}
                headerActionButtons={
                  <div>
                    <TextField
                      label={global._('Keys.New.ReceiptHistorySelectLabel')}
                      fullWidth
                      value=''
                      onChange={e => {
                        const keyReceipt = e.target.value
                        if (keyReceipt.fileId) {
                          window.open(
                            `${axios.defaults.baseURL}/v1/keys/receipts/${keyReceipt.id}/download?token=${token}`
                          )
                        } else {
                          printPdfReceipt(null, keyReceipt)
                        }
                        setLastSelectedReceipt(keyReceipt)
                      }}
                      name='status'
                      className={classes.input}
                      variant='filled'
                      style={{ width: 400 }}
                      select
                    >
                      <MenuItem value='' />
                      {keyObj.keyReceipts
                        .filter(r => r.signed)
                        .map(rec => (
                          <MenuItem value={rec}>
                            <strong>
                              {moment(rec.signedAt).format('YYYY-MM-DD HH:mm')}
                            </strong>
                            &nbsp;-{' '}
                            {rec.user
                              ? rec.user.name
                              : global._('Common.Removed')}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                }
              />
            )}
            <ObjectSection
              sectionTitle={global._('Keys.New.KeyRing.Title')}
              infoDialogTitle={global._('Keys.New.KeyRing.Title')}
              infoDialogContent={
                <div style={{ textAlign: 'left' }}>
                  <Typography
                    style={{ display: 'block', paddingBottom: 12 }}
                    variant='body'
                  >
                    {global._('Keys.New.KeyRing.Description')}
                  </Typography>
                </div>
              }
              headerActionButtons={
                <div>
                  <Button
                    variant='outlined'
                    disabled={keyObj && keyObj.archived}
                    onClick={() => {
                      setShowKeyRingModal(true)
                      setFieldValue('keyring', null)
                    }}
                  >
                    {global._('Keys.New.KeyRing.ChangeRing')}
                  </Button>
                </div>
              }
              columns={keyringSectionColumns}
              objects={keyringsSectionObjects}
              addObjectsContent={
                <>
                  <Typography
                    variant='subtitleBig'
                    bold
                    style={{ marginBottom: 5 }}
                  >
                    {global._('Keys.New.KeyRing.Title')}
                  </Typography>
                  <Typography variant='body2'>
                    {global._('Keys.New.KeyRing.Description')}
                  </Typography>
                </>
              }
              addObjectsActionButtons={
                <>
                  <Button
                    variant='primary'
                    disabled={keyObj && keyObj.archived}
                    onClick={() => {
                      setShowKeyRingModal(true)
                      setFieldValue('keyring', null)
                    }}
                  >
                    {global._('Common.Add')}
                  </Button>
                </>
              }
            />
            {newKey && (
              <Row justify='flex-end' style={{ marginTop: 20 }}>
                <Button
                  variant='primary'
                  onClick={() => handleKeyAdd(values)}
                  disabled={
                    Object.keys(touched).length === 0 ||
                    Object.keys(errors).length ||
                    !editingHolder ||
                    (editingHolder &&
                      editingHolder.type === 'user' &&
                      !receiptRequest)
                  }
                >
                  {global._('Keys.New.AddKey')}
                </Button>
              </Row>
            )}
          </div>
        )}
      </Formik>
    </div>
  )
}

KeyDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object
}

KeyDetails.defaultProps = {
  breadcrumbItems: [],
  user: null
}

const KeyDetailsWithStyles = injectSheet(styles)(KeyDetails)
export default KeyDetailsWithStyles
