import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { history } from '../../redux/store'
import PageContentHeader from '../PageContentHeader'
import SelectedOrganisation from '../SelectedOrganisation'

const styles = {
  root: {}
}

const KeysNavigationHeader = ({
  classes,
  breadcrumbItems,
  selectedOrg,
  isCustomerAdmin,
  disableOrgChange
}) => (
  <>
    <PageContentHeader
      breadcrumbItems={breadcrumbItems}
      actionButtons={
        !isCustomerAdmin && (
          <SelectedOrganisation
            selectedOrg={selectedOrg}
            history={history}
            disableOrgChange={disableOrgChange}
            linkTo='/keys/select-organisation'
          />
        )
      }
    />
  </>
)

KeysNavigationHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  selectedOrg: PropTypes.object.isRequired,
  isCustomerAdmin: PropTypes.bool.isRequired,
  disableOrgChange: PropTypes.bool
}

KeysNavigationHeader.defaultProps = {
  disableOrgChange: undefined
}

const KeysNavigationHeaderWithStyles = injectSheet(styles)(KeysNavigationHeader)
export default KeysNavigationHeaderWithStyles
