/* eslint-disable import/prefer-default-export */
import React from 'react'
import {
  Assignment,
  Domain,
  Home,
  Phone,
  Store,
  NetworkLocked,
  VpnKey,
  VerifiedUser,
  ShoppingCart,
  InsertChart,
  Event,
  EventAvailable
} from '@material-ui/icons'
import AdminIcon from '@material-ui/icons/Security'
import ConnectWithoutContactIcon from '../../components/ConnectWithoutContactIcon'
import { UserRoles, Country } from '../../utils/constants'

export const items = [
  {
    access: [
      UserRoles.SystemAdmin,
      UserRoles.Support,
      UserRoles.PartnerAdmin,
      UserRoles.CustomerAdmin,
      UserRoles.CustomerAdminGuest,
      UserRoles.Customer,
      UserRoles.Resident,
      UserRoles.Temporary
    ],
    icon: <Home />,
    iconName: 'home',
    path: '/dashboard',
    key: 'dashboard'
  },
  {
    access: [
      UserRoles.PartnerAdmin,
      UserRoles.CustomerAdmin,
      UserRoles.CustomerAdminGuest,
      UserRoles.Customer
    ],
    icon: <Domain />,
    iconName: 'domain',
    path: '/properties',
    mobile: true,
    key: 'properties'
  },
  {
    access: [
      UserRoles.PartnerAdmin,
      UserRoles.Support,
      UserRoles.CustomerAdmin,
      UserRoles.Customer,
      UserRoles.Resident
    ],
    icon: <Assignment />,
    iconName: 'assignment',
    path: '/documents',
    mobile: true,
    key: 'documents'
  },
  {
    access: [
      UserRoles.PartnerAdmin,
      UserRoles.CustomerAdmin,
      UserRoles.Customer,
      UserRoles.Resident
    ],
    icon: <Store />,
    iconName: 'store',
    path: '/my-organisation',
    description: null,
    mobile: true,
    key: 'organisations'
  },
  {
    access: [
      UserRoles.PartnerAdmin,
      UserRoles.CustomerAdmin,
      UserRoles.Customer,
      UserRoles.Resident
    ],
    icon: <ShoppingCart />,
    iconName: 'shopping_cart',
    path: '/orders',
    desktop: true,
    mobile: true,
    key: 'orders'
  },
  {
    access: [
      UserRoles.PartnerAdmin,
      UserRoles.CustomerAdmin,
      UserRoles.Customer,
      UserRoles.Resident,
      UserRoles.Temporary
    ],
    icon: <VpnKey />,
    iconName: 'vpn_key',
    path: '/my-keys',
    mobile: true,
    key: 'my-keys'
  },
  {
    access: [
      UserRoles.PartnerAdmin,
      UserRoles.CustomerAdmin,
      UserRoles.Customer,
      UserRoles.Resident
    ],
    icon: <Event />,
    iconName: 'event',
    path: '/booking',
    path2: '/booking/admin',
    mobile: true,
    key: 'booking'
  },
  // {
  //   access: [UserRoles.PartnerAdmin, UserRoles.CustomerAdmin],
  //   icon: <EventAvailable />,
  //   iconName: 'event_available',
  //   path: '/booking/admin',
  //   mobile: true,
  //   key: 'booking/admin'
  // },
  {
    access: [UserRoles.PartnerAdmin, UserRoles.CustomerAdmin],
    icon: <NetworkLocked />,
    iconName: 'network_locked',
    path: '/keys',
    mobile: false,
    key: 'keys'
  },
  {
    access: [UserRoles.Support, UserRoles.PartnerAdmin],
    icon: <VerifiedUser />,
    iconName: 'verified_user',
    path: '/id-control',
    mobile: true,
    key: 'id-control',
    countries: [Country.SE]
  },
  {
    access: [
      UserRoles.Support,
      UserRoles.PartnerAdmin,
      UserRoles.CustomerAdmin,
      UserRoles.Customer,
      UserRoles.Resident
    ],
    icon: <ConnectWithoutContactIcon />,
    iconName: 'connect_without_contact',
    path: '/cases',
    mobile: true,
    key: 'cases'
  },
  {
    name: 'Reports',
    key: 'reports',
    access: [UserRoles.SystemAdmin],
    icon: <InsertChart />,
    iconName: 'insert_chart',
    path: '/reports',
    mobile: false
  },
  {
    name: 'Admin',
    key: 'admin',
    access: [
      UserRoles.SystemAdmin,
      UserRoles.Support,
      UserRoles.PartnerAdmin,
      UserRoles.CustomerAdmin
    ],
    icon: <AdminIcon />,
    iconName: 'security',
    path: '/admin',
    description: null,
    mobile: true
  },
  {
    access: [UserRoles.Guest],
    icon: <Phone />,
    iconName: 'phone',
    path: 'https://digitallassmed.se/kontakta-oss/',
    mobile: true,
    key: 'contact'
  },
  {
    access: [
      UserRoles.SystemAdmin,
      UserRoles.Support,
      UserRoles.PartnerAdmin,
      UserRoles.InternalAdmin,
      UserRoles.CustomerAdmin,
      UserRoles.CustomerAdminGuest,
      UserRoles.Customer,
      UserRoles.Resident,
      UserRoles.Temporary
    ],
    icon: <Phone />,
    iconName: 'phone',
    path: '/contact',
    mobile: true,
    key: 'contact'
  }
]
