import React, { useEffect, useRef, useState } from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import FormControl from '@material-ui/core/FormControl'
import FilledInput from '@material-ui/core/FilledInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import qs from 'qs'
import { TextField } from '@material-ui/core'
import Typography from '../../../components/Typography'
import Widget from '../../../components/Widget'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import AsyncSelect from '../../../components/AsyncSelect'
import Notification from '../../../common/Notification'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import CustomTable from '../../../components/CustomTable'
import SearchField from '../../../components/SearchField'
import { OrganisationTypes, UserRoles } from '../../../utils/constants'
import SuccessMessage from '../../../components/SuccessMessage'
// eslint-disable-next-line
import { setAuthenticationData } from '../../../redux/reducers/login'
import SsnInput from '../../../components/SsnInput'
import PhoneNumberField from '../../../components/PhoneNumberField'

const styles = {
  root: {
    width: '100%',
    backgroundColor: 'white',
    position: 'relative'
  },
  filterContainer: {
    padding: 22,
    borderBottom: '1px solid var(--divider-color)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  formSeparator: {
    width: '100%',
    height: 1,
    margin: '14px 0px',
    backgroundColor: 'var(--divider-color)'
  },
  linkContainer: {
    borderLeft: '4px solid transparent',
    '&:hover': {
      borderLeftColor: 'var(--color-primary)',
      cursor: 'pointer'
    },
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '18px 16px 18px 14px',
    borderBottom: '1px solid var(--divider-color)'
  },
  deleteModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  deleteModalText: {
    marginBottom: 10
  },
  center: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },

  '@media (max-width: 900px)': {
    filterContainer: {
      borderBottom: 'none',
      paddingBottom: 0
    }
  }
}

export const CustomInput = ({
  containerStyle,
  dataCy,
  endAdornment,
  attribute,
  ...otherProps
}) => {
  const InputComponent = attribute === 'phone' ? PhoneNumberField : TextField

  return (
    <FormControl style={{ width: '100%', ...containerStyle }}>
      <InputComponent
        data-cy={dataCy}
        variant='filled'
        InputProps={
          endAdornment && {
            endAdornment
          }
        }
        {...otherProps}
      />
    </FormControl>
  )
}

const SEARCH_DELAY = 400
const defaultFilter = single => ({
  organisations: {
    orderBy: 'createdAt',
    order: 'desc',
    search: '',
    offset: 0,
    limit: single ? 20 : 3
  },
  users: {
    orderBy: 'createdAt',
    order: 'desc',
    search: '',
    offset: 0,
    limit: single ? 20 : 3
  }
})
const Entity = props => {
  const {
    classes,
    user,
    entity,
    type,
    history,
    single,
    onNewItem,
    url,
    fetch,
    location,
    instructionsVideoId
  } = props

  const urlParams = new URLSearchParams(decodeURI(window.location.search))

  const searchParam = urlParams.get('search')
  const orgIdParam = urlParams.get('organisationId')
  const orgNameParam = urlParams.get('organisationName')

  /* Generic */
  const { isMobile } = useWindowDimensions()
  const [filter, setFilter] = useState({
    ...defaultFilter(single)[entity],
    type,
    ...props.filter,
    search: searchParam || props.filter.search || '',
    organisationId: orgIdParam || props.filter.organisationId || null,
    organisationName: orgNameParam || props.filter.organisationName || null
  })
  const [cachedSearchFilter, setCachedSearchFilter] = useState('')
  const [expanded, setExpanded] = useState([])
  const searchTimeoutRef = useRef(null)
  const [error, setError] = useState(null)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [createError, setCreateError] = useState(null)
  const [deleteError, setDeleteError] = useState(null)
  const [editError, setEditError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCreate, setIsLoadingCreate] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isLoadingEdit, setIsLoadingEdit] = useState(false)
  const [items, setItems] = useState([])
  const [newItem, setNewItem] = useState(null)
  const [deleteItem, setDeleteItem] = useState(null)
  const [editItem, setEditItem] = useState(null)
  const [modalFieldsData, setModalFieldsData] = useState(null)

  /* Partners */
  const [organisationToAssignPartner, setOrganisationToAssignPartner] =
    useState(null)
  const [organisationToAddToPartner, setOrganisationToAddToPartner] =
    useState(null)
  const [partnerToAddToOrganisation, setPartnerToAddToOrganisation] =
    useState(null)
  const [addPartnerStatusModalOpen, setAddPartnerStatusModalOpen] =
    useState(null)
  const [addPartnerStatusError, setAddPartnerStatusError] = useState(null)
  const [addOrganisationToPartnerError, setAddOrganisationToPartnerError] =
    useState(null)
  const [isLoadingAssign, setIsLoadingAssign] = useState(false)

  /* Modals handled from widgets/data/... */
  const [modals, setModals] = useState({
    import: false
  })

  const handleSearchQueryChange = e => {
    if (searchTimeoutRef && searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current)
    }
    const { value } = e.target

    setCachedSearchFilter(value)
    searchTimeoutRef.current = setTimeout(() => {
      setFilter({
        ...filter,
        search: value,
        offset: 0
      })
    }, SEARCH_DELAY)
  }

  useEffect(() => {
    setNewItem(props.newItem)
  }, [props.newItem])

  useEffect(() => {
    if (fetch) {
      ;(async () => {
        searchTimeoutRef.current = null
        setError(null)
        setIsLoading(true)
        try {
          const filteredURL = props.data.fetchUrl
            ? props.data.fetchUrl(filter, user)
            : `/v1/${entity}?${qs.stringify(filter)}`
          const { data } = await axios.get(filteredURL)
          if (filter.offset > 0) {
            setItems([...items, ...data])
          } else {
            setItems(data)
          }
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
        setIsLoading(false)
      })()
    }
  }, [filter])

  useEffect(() => {
    const newFilter = {
      ...filter,
      offset: 0,
      limit: single ? 20 : 3,
      search: searchParam || filter.search || '',
      organisationId: orgIdParam || null,
      organisationName: orgNameParam || null
    }

    if (!single) {
      delete newFilter.organisationId
    }

    setFilter(newFilter)
  }, [single])

  // Force new filter from props
  useEffect(() => {
    if (props.filter && Object.keys(props.filter).length > 0) {
      setFilter({
        ...defaultFilter(single)[entity],
        type,
        ...props.filter,
        search: filter.search
      })
    }
  }, [props.filter])

  useEffect(() => setCachedSearchFilter(filter.search), [filter])

  const handleCreateItem = async () => {
    setCreateError(null)
    setIsLoadingCreate(true)
    try {
      let item
      if (entity === 'devices') {
        item = await axios.post(`/v1/${entity}/${newItem.uuid}/claim`, newItem)
      } else {
        item = await axios.post(`/v1/${entity}`, newItem)
      }
      if (onNewItem) {
        onNewItem(
          item.data,
          setNewItem,
          setSuccessMessage,
          setShowSuccessMessage,
          setItems
        )
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setCreateError(global._(msg))
    }
    setIsLoadingCreate(false)
  }

  useEffect(() => {
    setCreateError(null)
  }, [newItem])

  const handleDeleteItem = async () => {
    setDeleteError(null)
    setIsLoadingDelete(true)
    try {
      if (
        entity === 'organisations' &&
        (!deleteItem.organisation || !deleteItem.organisation.id)
      ) {
        await axios.delete(`/v1/organisations/${deleteItem.id}`)
      } else {
        await axios.delete(
          `/v1/organisations/${deleteItem.organisation.id}/${entity}/${deleteItem.id}`
        )
      }
      setItems(items.filter(item => item.id !== deleteItem.id))
      setDeleteItem(null)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setDeleteError(global._(msg))
    }
    setIsLoadingDelete(false)
  }

  const handleEditItem = async () => {
    setEditError(null)
    setIsLoadingEdit(true)
    try {
      const { data: updatedItem } = await axios.patch(
        `/v1/organisations/${editItem.organisation.id}/${entity}/${editItem.id}`,
        editItem
      )

      const updatedItems = items.map(item => {
        if (item.id === editItem.id) {
          Object.keys(item).forEach(i => {
            if (updatedItem[i]) {
              item[i] = updatedItem[i]
            }
          })
        }

        return item
      })

      setItems(updatedItems)

      setEditItem(null)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setEditError(global._(msg))
    }
    setIsLoadingEdit(false)
  }

  const assignPartnerStatus = async () => {
    if (!organisationToAssignPartner) return
    setAddPartnerStatusError(null)
    setIsLoadingAssign(true)
    try {
      await axios.patch(`/v1/${entity}/${organisationToAssignPartner.id}`, {
        type: OrganisationTypes.Partner
      })
      setItems([
        {
          ...organisationToAssignPartner,
          type: OrganisationTypes.Partner,
          highlight: true
        },
        ...items.filter(o => o.id !== organisationToAssignPartner.id)
      ])
      setOrganisationToAssignPartner(null)
      setAddPartnerStatusModalOpen(null)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setAddPartnerStatusError(global._(msg))
    }
    setIsLoadingAssign(false)
  }

  const handleAddOrganisationToPartner = async () => {
    if (!organisationToAddToPartner || !partnerToAddToOrganisation) return
    setAddOrganisationToPartnerError(null)
    setIsLoadingAssign(true)
    try {
      await axios.patch(`/v1/${entity}/${organisationToAddToPartner.id}`, {
        type: OrganisationTypes.Customer,
        partnerId: partnerToAddToOrganisation.id
      })
      setItems(items.filter(item => item.id !== organisationToAddToPartner.id))
      setOrganisationToAddToPartner(null)
      setPartnerToAddToOrganisation(null)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setAddOrganisationToPartnerError(msg)
    }
    setIsLoadingAssign(false)
  }

  const itemAttributes = props.data.attributes
    ? props.data.attributes(user, {
        type,
        filter,
        newItem,
        setNewItem,
        editItem
      })
    : null
  const buttons = props.data.actionButtons
    ? props.data.actionButtons(user, {
        single,
        type,
        newItem,
        setNewItem,
        filter,
        setAddPartnerStatusModalOpen,
        history,
        isMobile,
        modals,
        setModals
      })
    : null

  let showAllText
  if (!single) {
    if (filter.search) {
      showAllText = global._('Widgets.ShowSearchResults')
    } else if (props.showAllLabel) {
      showAllText = props.showAllLabel
    } else {
      showAllText =
        typeof props.subtitle === 'string'
          ? `${global._('Common.ShowAll')} ${props.subtitle.toLowerCase()}`
          : ''
    }
  }

  let customPadding
  if (single && !isMobile) {
    customPadding = 40
  }

  const handleSingleWidgetOnClick = () => {
    let target
    if (props.url) {
      return history.push({
        pathname: props.url,
        state: { ...filter }
      })
    }

    if (entity === 'organisations') {
      if (type === OrganisationTypes.Partner) {
        target = 'partners'
      } else if (type === OrganisationTypes.Customer) {
        target = 'customers'
      } else if (type === OrganisationTypes.Unbound) {
        target = 'unbound'
      }
    } else if (entity === 'users') {
      target = 'users'
    } else if (entity === 'devices') {
      target = 'devices'
    }

    const { pathname } = history.location
    const cleanPathname = pathname.endsWith('/')
      ? pathname.slice(0, -1)
      : pathname

    history.push({
      pathname: `${cleanPathname}/${target}`,
      state: { ...filter }
    })
  }

  const itemActionModal = (
    modalTitle,
    setItem,
    item,
    isLoadingItem,
    handleItem,
    actionButtonLabel,
    itemError
  ) => (
    <Modal
      onClose={() => setItem(null)}
      title={modalTitle}
      content={
        <Grid container spacing={2}>
          {Object.keys(itemAttributes).map((attribute, index) => {
            if (attribute === 'separator') {
              return <div className={classes.formSeparator} />
            }

            const { size, field, validation, customHelpText } =
              itemAttributes[attribute]
            let helpText
            if (
              item[attribute] &&
              validation &&
              validation.validate &&
              validation.error &&
              !validation.validate(item[attribute])
            ) {
              helpText = validation.error
            } else if (customHelpText) {
              helpText = customHelpText
            } else {
              helpText = itemAttributes[attribute].required
                ? global._('Common.Required')
                : undefined // global._('Common.NotRequired')
            }

            const personalNumberInput = attribute === 'personalNumber' && (
              <SsnInput
                data-cy={`custom-input-${index}`}
                label={global._('PersonalNumber.Label')}
                onSsnValid={({ ssn }) =>
                  setItem({ ...item, personalNumber: ssn })
                }
                helperText={helpText}
              />
            )

            return (
              <Grid
                key={`widgets.${entity}.modal.create.attribute.${attribute}`}
                item
                xs={12}
                sm={size}
              >
                {field || personalNumberInput || (
                  <CustomInput
                    dataCy={`custom-input-${index}`}
                    label={global._(
                      `Widgets.${entity}.Attributes.${attribute}`
                    )}
                    value={item[attribute] || ''}
                    error={
                      item[attribute] &&
                      validation?.error &&
                      !validation?.validate(item[attribute])
                    }
                    onChange={e =>
                      setItem({
                        ...item,
                        [attribute]: attribute === 'phone' ? e : e.target.value
                      })
                    }
                    helperText={helpText}
                    containerStyle={{ marginBottom: 8 }}
                    style={{ width: '100%' }}
                    attribute={attribute}
                  />
                )}
              </Grid>
            )
          })}
          {itemError && (
            <Notification
              type='error'
              message={global._(itemError)}
              style={{ marginTop: 20 }}
            />
          )}
        </Grid>
      }
      buttons={[
        <Button
          key={`widgets.${entity}.modal.create.button.cancel`}
          variant='none'
          data-cy='madmin-entity-modal-button-cancel'
          onClick={() => setItem(null)}
          style={{ marginRight: 10 }}
        >
          {global._('Common.Cancel')}
        </Button>,
        <Button
          data-cy='madmin-entity-modal-button-primary'
          key={`widgets.${entity}.modal.create.button.add`}
          variant='primary'
          onClick={handleItem}
          disabled={Object.keys(itemAttributes).some(attr => {
            const attribute = itemAttributes[attr]
            const value = item[attr]
            if (attribute.required && !value) {
              return true
            }
            if (
              value &&
              attribute.validation &&
              attribute.validation.validate &&
              !attribute.validation.validate(value)
            ) {
              return true
            }
            return false
          })}
          loading={isLoadingItem}
        >
          {actionButtonLabel}
        </Button>
      ]}
    />
  )

  return (
    <Widget
      infoDialog={props.data.infoDialog ? props.data.infoDialog : null}
      single={single}
      icon={<Icon>{props.data.icon({ type })}</Icon>}
      title={props.title}
      subtitle={props.subtitle}
      instructionsVideoId={instructionsVideoId}
      buttons={single ? undefined : buttons}
      /* Only used for mobile */
      url={url}
    >
      <div className={classes.root} data-cy={`${entity}-container`}>
        {props.data.topContent ? props.data.topContent(single) : null}
        {/* Fuzzy search */}
        {(!props.data.doNotShowFilter || single) && (
          <div
            className={classes.filterContainer}
            style={{ paddingBottom: customPadding }}
          >
            {props.data.filter ? (
              props.data.filter({
                isMobile,
                single,
                handleSearchQueryChange,
                entity,
                isLoading,
                user,
                filter,
                buttons,
                setFilter
              })
            ) : (
              <>
                <Grid
                  container
                  spacing={isMobile ? 1 : 4}
                  direction='row'
                  alignItems='center'
                  // justify='space-between'
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ minHeight: 110 }}
                    md={single ? 4 : undefined}
                  >
                    <SearchField
                      label={global._(`Widgets.${entity}.SearchLabel`)}
                      onChange={handleSearchQueryChange}
                      type='text'
                      value={cachedSearchFilter}
                      variant='filled'
                      fullWidth
                      endAdornment={
                        <InputAdornment position='end'>
                          {isLoading ? (
                            <CircularProgress
                              style={{ color: 'black' }}
                              size={20}
                            />
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      }
                      helpText=' '
                    />
                  </Grid>
                  {(entity === 'apartments' || entity === 'users') &&
                    filter &&
                    filter.type !== OrganisationTypes.ServiceDesk &&
                    single &&
                    (user.roles.includes(UserRoles.SystemAdmin) ||
                      user.roles.includes(UserRoles.PartnerAdmin) ||
                      user.roles.includes(UserRoles.Support)) && (
                      <Grid item xs={12} sm={12} md={3}>
                        <AsyncSelect
                          isSearchable
                          helpText={global._(
                            'Widgets.users.FilterOnOrganisation'
                          )}
                          labelKey='name'
                          defaultValue={
                            orgIdParam && orgNameParam
                              ? { value: orgIdParam, label: orgNameParam }
                              : undefined
                          }
                          placeholder={global._('Common.SelectOrganisation')}
                          onChange={opt =>
                            setFilter({
                              ...filter,
                              offset: 0,
                              organisationId: opt ? opt.value.id : null,
                              organisationName: opt ? opt.value.name : null
                            })
                          }
                          url={`/v1/organisations?search={{input}}&orderBy=name&order=asc&ifHas=${entity}`}
                        />
                      </Grid>
                    )}
                  {single && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      style={{ marginBottom: 18 }}
                    >
                      <Grid
                        container
                        spacing={2}
                        direction='row'
                        justify='flex-end'
                      >
                        {buttons &&
                          buttons.map(btn => (
                            <Grid key={`${btn.key}.grid`} item>
                              {btn}
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </div>
        )}
        {error && <Notification type='error' message={error} />}

        {showSuccessMessage && <SuccessMessage subtitle={successMessage} />}

        {(!props.data.doNotShowTable || single) && (
          <>
            <CustomTable
              identifier={`table.${entity}.${
                single ? 'single' : 'multi'
              }.${type}`}
              history={history}
              onScrollEnd={() => {
                if (
                  single &&
                  !isLoading &&
                  items.length === filter.offset + filter.limit
                ) {
                  setFilter({
                    ...filter,
                    offset: filter.offset + filter.limit
                  })
                }
              }}
              onSort={(key, order) => {
                setFilter({
                  ...filter,
                  orderBy: key,
                  order,
                  offset: 0
                })
              }}
              sort={{ key: filter.orderBy, order: filter.order }}
              noResultsMessage={
                !isLoading && single ? props.data.noResultsMessage() : undefined
              }
              mobileData={props.data.MobileTable}
              itemIdentifier={props.data.identifier}
              columns={props.data.Columns[single ? 'single' : 'widget']}
              items={single ? items : items.slice(0, 3)}
              expanded={single ? expanded : []}
              onRowClick={item => {
                if (single) {
                  const identifier = props.data.identifier(item)

                  if (expanded.includes(identifier)) {
                    setExpanded(expanded.filter(oid => oid !== identifier))
                  } else {
                    setExpanded([...expanded, identifier])
                  }
                } else {
                  let detailURL = ''
                  if (entity === 'organisations') {
                    if (
                      type === OrganisationTypes.Partner ||
                      type === OrganisationTypes.Customer ||
                      type === OrganisationTypes.Unbound
                    ) {
                      detailURL = `/organisations/${item.id}`
                    }
                  } else if (entity === 'users') {
                    detailURL = `/admin/users/${item.organisation.id}/${item.id}`
                  } else if (entity === 'devices') {
                    detailURL = `/admin/devices/${item.id}`
                  } else if (entity === 'apartments') {
                    detailURL = `/admin/apartments?search=${item.refId}`
                  }
                  history.push(detailURL !== '' ? detailURL : url)
                }
              }}
              rowExpansionContent={item =>
                props.data.rowExpansionContent(item, user, {
                  history,
                  items,
                  setItems,
                  setError,
                  setDeleteError,
                  setDeleteItem,
                  setEditError,
                  setEditItem,
                  setShowSuccessMessage,
                  showSuccessMessage,
                  setSuccessMessage,
                  type,
                  setAuthenticationData: props.setAuthenticationData,
                  setOrganisationToAddToPartner
                })
              }
            />
            {single && isLoading && (
              <div className={classes.center} style={{ padding: 20 }}>
                <CircularProgress
                  size={24}
                  thickness={4}
                  style={{ color: 'black' }}
                />
              </div>
            )}
          </>
        )}
      </div>

      {/* Link to single widget */}
      {!single &&
        typeof props.data.linkButtons === 'function' &&
        props.data.linkButtons({
          classes,
          user,
          items,
          history,
          filter
        })}
      {!single && !props.data.linkButtons && (
        <div
          className={classes.linkContainer}
          role='button'
          tabIndex='0'
          onClick={() => handleSingleWidgetOnClick()}
          onKeyPress={() => handleSingleWidgetOnClick()}
        >
          <Typography bold>{showAllText}</Typography>
          <ChevronRightIcon style={{ marginLeft: 'auto' }} />
        </div>
      )}

      {/* Create item modal */}
      {newItem &&
        itemActionModal(
          global._(`Widgets.${entity}.ActionButton.${type}`),
          setNewItem,
          newItem,
          isLoadingCreate,
          handleCreateItem,
          global._('Common.Add'),
          createError
        )}
      {editItem &&
        itemActionModal(
          global._(`Widgets.${entity}.Edit.Title`),
          setEditItem,
          editItem,
          isLoadingEdit,
          handleEditItem,
          global._('Common.Update'),
          editError
        )}

      {/* Delete item modal */}
      {deleteItem && (
        <Modal
          onClose={() => setDeleteItem(null)}
          title={global._(`Widgets.${entity}.DeleteItem`)}
          content={
            <div className={classes.deleteModal}>
              {deleteItem.deleteModalSubtitle && (
                <Typography
                  style={{ margin: '0 0 10px 0' }}
                  variant='subtitle'
                  normalLineHeight
                >
                  {deleteItem.deleteModalSubtitle}
                </Typography>
              )}
              <Typography variant='body'>
                {deleteItem.deleteModalConfirm ||
                  global._(`Widgets.${entity}.DeleteItemConfirm`)}
              </Typography>
              {deleteItem.name && (
                <Typography style={{ margin: '10px 0' }} variant='subtitle'>
                  {deleteItem.name}
                </Typography>
              )}
              {!deleteItem.hideDeleteModalInfo && (
                <Typography italic variant='body'>
                  {deleteItem.deleteModalInfo ||
                    global._(`Widgets.${entity}.DeleteItemInfo`)}
                </Typography>
              )}
              {deleteError && (
                <Notification
                  type='error'
                  message={deleteError}
                  style={{ marginTop: 20 }}
                />
              )}
            </div>
          }
          buttons={[
            <Button
              key={`widgets.${entity}.modal.create.button.cancel`}
              variant='none'
              onClick={() => setDeleteItem(null)}
              style={{ marginRight: 10 }}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              key={`widgets.${entity}.modal.create.button.remove`}
              variant='secondary'
              disabled={deleteItem.deleteButtonDisabled}
              onClick={() => handleDeleteItem()}
              loading={isLoadingDelete}
            >
              {global._('AccessGroups.Delete')}
            </Button>
          ]}
        />
      )}

      {/* Add PARTNER to existing */}
      {addPartnerStatusModalOpen && (
        <Modal
          onClose={() => setAddPartnerStatusModalOpen(null)}
          title={global._(`Widgets.${entity}.AddExistingPartnerModal.Title`)}
          content={
            <div>
              <AsyncSelect
                isSearchable
                cacheOptions
                labelKey='name'
                noOptionsMessage={() =>
                  global._(
                    `Widgets.${entity}.AddExistingPartnerModal.NoOptionsMessage`
                  )
                }
                placeholder={global._(
                  `Widgets.${entity}.AddExistingPartnerModal.SelectPlaceholder`
                )}
                onChange={opt =>
                  setOrganisationToAssignPartner(
                    opt && opt.value ? opt.value : null
                  )
                }
                value={
                  organisationToAssignPartner
                    ? {
                        value: organisationToAssignPartner,
                        label: organisationToAssignPartner.name
                      }
                    : undefined
                }
                disabled={isLoadingAssign}
                url={`/v1/organisations?type=${OrganisationTypes.Unbound}&type=null&type=${OrganisationTypes.Customer}&search={{input}}&orderBy=name&order=asc`}
              />
              {addPartnerStatusError && (
                <Notification
                  type='error'
                  message={addPartnerStatusError}
                  style={{ marginTop: 20 }}
                />
              )}
            </div>
          }
          buttons={[
            <Button
              key={`widgets.${entity}.modal.addexistingpartner.button.cancel`}
              variant='none'
              onClick={() => setAddPartnerStatusModalOpen(false)}
              style={{ marginRight: 10 }}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              key={`widgets.${entity}.modal.addexistingpartner.button.add`}
              variant='primary'
              onClick={assignPartnerStatus}
              disabled={!organisationToAssignPartner}
              loading={isLoadingAssign}
            >
              {global._('Common.Assign')}
            </Button>
          ]}
        />
      )}

      {/* Add unbound organisation to PARTNER */}
      {organisationToAddToPartner && (
        <Modal
          title={global._(
            `Widgets.${entity}.AddOrganisationToPartner.AddOrganisationToPartner`
          )}
          onClose={() => {
            setOrganisationToAddToPartner(null)
            setPartnerToAddToOrganisation(null)
          }}
          content={
            <>
              <AsyncSelect
                isSearchable
                cacheOptions
                labelKey='name'
                noOptionsMessage={() =>
                  global._(
                    `Widgets.${entity}.AddOrganisationToPartner.NoOptionsMessage`
                  )
                }
                placeholder={global._(
                  `Widgets.${entity}.AddOrganisationToPartner.SelectPlaceholder`
                )}
                onChange={opt =>
                  setPartnerToAddToOrganisation(
                    opt && opt.value ? opt.value : null
                  )
                }
                value={
                  partnerToAddToOrganisation
                    ? {
                        value: partnerToAddToOrganisation,
                        label: partnerToAddToOrganisation.name
                      }
                    : undefined
                }
                disabled={isLoadingAssign}
                url={`/v1/organisations?type=${OrganisationTypes.Partner}&search={{input}}&orderBy=name&order=asc`}
              />
              {addOrganisationToPartnerError && (
                <Notification
                  type='error'
                  message={addOrganisationToPartnerError}
                  style={{ marginTop: 20 }}
                />
              )}
            </>
          }
          buttons={[
            <Button
              key={`widgets.${entity}.modal.addOrganisationToPartner.button.cancel`}
              variant='none'
              onClick={() => {
                setOrganisationToAddToPartner(null)
                setPartnerToAddToOrganisation(null)
              }}
              style={{ marginRight: 10 }}
            >
              {global._('Common.Cancel')}
            </Button>,
            <Button
              key={`widgets.${entity}.modal.addOrganisationToPartner.button.add`}
              variant='primary'
              onClick={handleAddOrganisationToPartner}
              disabled={!organisationToAddToPartner}
              loading={isLoadingAssign}
            >
              {global._('Common.Assign')}
            </Button>
          ]}
        />
      )}
      {props.data.modals &&
        props.data.modals({
          modals,
          setModals,
          user,
          modalFieldsData,
          setModalFieldsData
        })}
    </Widget>
  )
}

CustomInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  helpText: PropTypes.string,
  containerStyle: PropTypes.object
}

CustomInput.defaultProps = {
  value: null,
  onChange: null,
  label: null,
  helpText: null,
  containerStyle: {}
}

Entity.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired,
  type: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  showAllLabel: PropTypes.string,
  data: PropTypes.shape({
    icon: PropTypes.func,
    attributes: PropTypes.func,
    modals: PropTypes.func,
    actionButtons: PropTypes.func,
    identifier: PropTypes.func,
    noResultsMessage: PropTypes.func,
    MobileTable: PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.func,
      subtitle: PropTypes.func
    }),
    Columns: PropTypes.shape({
      single: PropTypes.array,
      widget: PropTypes.array
    }),
    rowExpansionContent: PropTypes.func,
    linkButtons: PropTypes.func,
    doNotShowTable: PropTypes.bool,
    doNotShowFilter: PropTypes.bool,
    fetchUrl: PropTypes.func,
    filter: PropTypes.func,
    infoDialog: PropTypes.shape({
      title: PropTypes.func,
      content: PropTypes.func
    })
  }).isRequired,
  fetch: PropTypes.bool,
  /* Single widget (fill screen) */
  single: PropTypes.bool,
  newItem: PropTypes.object, // Force new item modal to show if this prop is populated
  onNewItem: PropTypes.func, // Function to be triggered if a new item is created
  filter: PropTypes.object, // Add custom filter,
  setAuthenticationData: PropTypes.func.isRequired, // Used for impersonation
  url: PropTypes.string,
  instructionsVideoId: PropTypes.string
}

Entity.defaultProps = {
  single: false,
  type: null,
  showAllLabel: null,
  newItem: null,
  onNewItem: null,
  filter: {},
  title: null,
  subtitle: null,
  url: null,
  fetch: true,
  instructionsVideoId: undefined
}

const mapStateToProps = state => ({
  user: state.login.user
})

const mapDispatchToProps = dispatch => ({
  setAuthenticationData: authData => dispatch(setAuthenticationData(authData))
})

const EntityWithStyles = injectSheet(styles)(Entity)
const ConnectedEntityWithStyles = connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityWithStyles)
const ConnectedEntityWithStylesAndRouter = withRouter(ConnectedEntityWithStyles)
export default ConnectedEntityWithStylesAndRouter
