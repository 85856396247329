import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import Notification from 'src/common/Notification'
import Button from 'src/components/Button'
import { ElectronicIdentityServices } from 'src/utils/constants'
import { setAuthenticationData as _setAuthenticationData } from 'src/redux/reducers/login'
import Typography from './Typography'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    paddingTop: 50,
    zIndex: 1
  },
  frameWrapper: {
    paddingTop: 36
  },
  frame: {
    border: 'none',
    width: 500,
    height: 600
  },
  cancelContainer: {
    marginTop: 25
  },
  '@media (max-width: 900px)': {
    root: {
      backgroundColor: 'white',
      width: '100%',
      overflowX: 'hidden'
    },
    frameWrapper: {
      paddingTop: 0,
      height: 'calc(100vh - 70px)',
      overflow: 'hidden'
    },
    frame: {
      width: '100vw',
      height: 620
    },
    cancelContainer: {
      width: '100vw',
      backgroundColor: 'white'
    }
  }
}
export const IdentificationAction = {
  LOGIN: 'LOGIN',
  VERIFY: 'VERIFY'
}

const getError = (params: URLSearchParams) => {
  const error = params.get('error')
  const errorDescription = params.get('error_description')
  const errorCode = params.get('code')
  if (error) {
    return {
      type: error,
      description: errorDescription,
      code: errorCode
    }
  }
}

interface IDigitalIdentificationProps {
  classes: {
    [key: string]: string
  }
  onAuthenticationComplete: (arg0: IAuthenticationResponse) => void
  setAuthenticationData: (arg0: IAuthenticationResponse) => void
  onCancel: () => void
  services: string[]
  state: any
  history: any
}

const encodeState = state => encodeURIComponent(btoa(JSON.stringify(state)))
const decodeState = state => {
  if (typeof state === 'string') {
    return JSON.parse(atob(decodeURIComponent(state)))
  }
  return {}
}
const DigitalIdentification = (props: IDigitalIdentificationProps) => {
  const {
    classes,
    onAuthenticationComplete,
    setAuthenticationData,
    onCancel,
    services,
    history,
    state
  } = props

  const redirectURI = `${window.location.origin}/eident/return`
  const [authenticationURL, setAuthenticationURL] = useState('')
  const [error, setError] = useState(null)
  const frameRef = useRef(null)

  const { isMobile } = useWindowDimensions()

  const getAuthUrl = async () => {
    const { data: authorizationEndpointURL } = await axios.get(
      '/v1/auth/identity/endpoint'
    )
    let url = `${authorizationEndpointURL}&redirect_uri=${encodeURIComponent(
      redirectURI
    )}`

    if (state) {
      url += `&state=${encodeState(state)}`
    }

    /* present id services of choice */
    const selectedServices = []
    if (services.includes(ElectronicIdentityServices.AUTO)) {
      const lang = global.getLanguage()
      /* base choices of eID on the users language */
      Object.keys(ElectronicIdentityServices).forEach(serviceKey => {
        if (serviceKey.startsWith(lang)) {
          selectedServices.push(ElectronicIdentityServices[serviceKey])
        }
      })
    } else {
      selectedServices.push(...services)
    }
    if (selectedServices.length > 0) {
      url += `&amr_values=${encodeURIComponent(selectedServices.join(' '))}`
    }
    url += `&wi=r`
    // const { protocol, host } = window.location
    // const cssUrl = `${protocol}//${host}/nets-eident.css`
    // url += `&style=${encodeURIComponent(cssUrl)}`
    // console.log('url', url)
    setAuthenticationURL(url)
  }

  const isComplete = window.location.href.includes('/eident/return')
  useLayoutEffect(() => {
    if (frameRef.current) {
      frameRef.current.onload = () => {
        frameRef.current.style.minHeight = '100%'
      }
    }
  }, [authenticationURL])
  useEffect(() => {
    if (isComplete) {
      // this code runs in iframe
      const params = new URLSearchParams(window.location.search)
      const _error = getError(params)
      if (_error) {
        setError(_error)
      } else {
        const code = params.get('code')
        ;(async () => {
          try {
            const _state: any = decodeState(params.get('state'))

            if (_state.action === IdentificationAction.LOGIN) {
              const { data: authData } =
                await axios.post<IAuthenticationResponse>(
                  `/v1/auth/identity?action=${_state.action}`,
                  { code, redirectURI }
                )
              setAuthenticationData(authData)
              history.replace('/')
            } else if (_state.action === IdentificationAction.VERIFY) {
              let authData
              try {
                const response = await axios.post<IAuthenticationResponse>(
                  `/v1/auth/identity?action=${_state.action}`,
                  { code, redirectURI }
                )
                authData = response.data
              } catch (e) {
                authData = { user: {} }
              }

              const { redirect } = _state
              if (!redirect) {
                console.error('Identification: No redirect set in state')
              } else {
                let url = redirect
                if (url.includes('?')) {
                  url += `&token=${authData.token}&uid=${authData.user.id}`
                } else {
                  url += `?token=${authData.token}&uid=${authData.user.id}`
                }
                history.replace(url)
              }
            } else {
              console.error('Identification: No action set in state')
            }
          } catch (err) {
            const msg = err.response ? err.response.data : err.message
            setError({
              description: global._(msg)
            })
          }
        })()
      }
    } else {
      // this code runs in the main page
      getAuthUrl()
    }
  }, [])

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    } else {
      window.history.back()
      // window.top.location.href = '/login'
    }
  }

  const cancelButton = isMobile ? undefined : (
    <div className={classes.cancelContainer}>
      <Button variant='none' onClick={handleCancel}>
        {global._('Common.Cancel')}
      </Button>
    </div>
  )
  if (authenticationURL) {
    return (
      <div className={classes.frameWrapper}>
        <iframe
          ref={frameRef}
          src={authenticationURL}
          className={classes.frame}
          sandbox='allow-scripts allow-same-origin allow-top-navigation allow-forms'
        />
        {cancelButton}
      </div>
    )
  }
  return (
    <div className={classes.root}>
      {error ? (
        <>
          <Notification type='error' message={error.description} />
          {/* <Button
            variant='none'
            onClick={handleCancel}
            style={{
              marginTop: 15
            }}>
            {global.translate('Common.Back')}
          </Button> */}
        </>
      ) : (
        <>
          <CircularProgress
            size={40}
            thickness={6}
            style={{ color: 'var(--color-primary)', margin: '30px auto' }}
          />
          {/* <Typography align='center'>
            {global._('Login.ElectronicID.WaitingForCompletion')}
          </Typography> */}
          {cancelButton}
        </>
      )}
    </div>
  )
}

DigitalIdentification.propTypes = {
  classes: PropTypes.object.isRequired,
  onAuthenticationComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  services: PropTypes.array,
  state: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

DigitalIdentification.defaultProps = {
  services: [],
  state: {
    action: IdentificationAction.LOGIN
  }
}

const mapDispatchToProps = dispatch => ({
  setAuthenticationData: data => dispatch(_setAuthenticationData(data))
})

export default injectSheet(styles)(
  connect(null, mapDispatchToProps)(DigitalIdentification)
)
