import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import * as Yup from 'yup'
import axios from 'axios'
import { TextField } from '@material-ui/core'
import { Form, Formik } from 'formik'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import FullscreenModal from '../../components/FullscreenModal'
import AsyncSelect from '../../components/AsyncSelect'
import Notification from '../../common/Notification'
import { UserRoles } from '../../utils/constants'

const styles = {
  root: {},
  input: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 20
    }
  }
}

const RepositoryDetails = props => {
  const {
    classes,
    setShowRepositoryModal,
    setEditRepositoryObj,
    repositoryObj,
    fetchRepositories,
    currentOrg,
    isMobile,
    user
  } = props

  const [selectedProperty, setSelectedProperty] = useState(
    repositoryObj && repositoryObj.propertyId
      ? { label: repositoryObj.propertyName, value: repositoryObj }
      : ''
  )
  const [selectedUser, setSelectedUser] = useState(
    repositoryObj && repositoryObj
      ? { label: repositoryObj.responsible, value: repositoryObj }
      : null
  )
  const [error, setError] = useState(null)

  useEffect(() => {
    if (repositoryObj) {
      ;(async () => {
        const { data } = await axios.get(
          `/v1/organisations/${repositoryObj.organisationId}/users/${repositoryObj.userId}`
        )
        setSelectedUser({
          label: `${data.name} (${data.email})`,
          value: repositoryObj
        })
      })()
    }
  }, [])

  const handleSaveOrAdd = async values => {
    const repository = { ...values }
    try {
      if (repositoryObj) {
        await axios.patch(
          `/v1/organisations/${currentOrg.id}/keystorages/${repositoryObj.id}`,
          repository
        )
        localStorage.setItem('repositoryEditedSuccessfully', true)
      } else {
        await axios.post(
          `/v1/organisations/${currentOrg.id}/keystorages`,
          repository
        )
        localStorage.setItem('repositoryAddedSuccessfully', true)
      }
      setEditRepositoryObj(null)
      fetchRepositories()
      setShowRepositoryModal(false)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const formFields = (
    errors,
    touched,
    setTouched,
    handleChange,
    values,
    handleBlur,
    setFieldValue
  ) => (
    <div style={{ textAlign: 'left' }}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '0 0 20px 0' }}
        />
      )}
      <TextField
        value={values.name}
        name='name'
        helperText={global._('Keys.Repositories.HelpText.Name')}
        className={classes.input}
        label={global._('Name')}
        variant='filled'
        error={errors.name}
        onChange={handleChange}
        onClick={handleBlur}
      />
      <AsyncSelect
        isSearchable
        key={`users-${
          selectedUser && selectedUser.value && selectedUser.value.id
        }`}
        helpText={global._('Keys.Repositories.HelpText.Responsible')}
        value={selectedUser}
        error={errors.userId}
        label={obj => `${obj.name || global._('Common.NoName')} (${obj.email})`}
        labelKey='name'
        name='user'
        placeholder={global._('Keys.Repositories.Responsible')}
        onClick={handleBlur}
        onChange={e => {
          setFieldValue('userId', e && e.value && (e.value.id || ''))
          setSelectedUser(e || null)
          setTouched({ userId: true })
        }}
        url={[
          `/v1/organisations/${currentOrg.id}/users?role=${UserRoles.CustomerAdmin}&search={{input}}&orderBy=name&order=asc`,
          `/v1/organisations/${user.organisation.id}/users?role=${UserRoles.PartnerAdmin}&search={{input}}&orderBy=name&order=asc`
        ]}
      />
      <AsyncSelect
        isSearchable
        key={`properties-${
          selectedProperty &&
          selectedProperty.value &&
          selectedProperty.value.id
        }`}
        helpText={global._('Keys.Repositories.HelpText.Property')}
        value={selectedProperty}
        labelKey='name'
        name='propertyId'
        isClearable
        placeholder={global._('Keys.Repositories.Property')}
        onClick={handleBlur}
        onChange={e => {
          setFieldValue('propertyId', e && e.value && (e.value.id || ''))
          setSelectedProperty(e || null)
          setTouched({ propertyId: true })
        }}
        cacheOptions={false}
        error={errors.propertyId}
        style={{ marginTop: 20 }}
        url={`/v1/properties?query={{input}}&orderBy=name&order=asc&organisationId=${
          (currentOrg && currentOrg.id) || ''
        }`}
      />
      <TextField
        value={values.space}
        name='space'
        style={{ marginTop: 20 }}
        className={classes.input}
        label={global._('Keys.Repositories.SpaceDescription')}
        variant='filled'
        error={errors.space}
        onChange={handleChange}
        onClick={handleBlur}
      />
      <Typography variant='body' italic>
        {global._('Keys.Repositories.HelpText.Info')}
      </Typography>
    </div>
  )

  const Wrapper = isMobile ? FullscreenModal : Modal

  const printModalButtons = (values, touched, errors) => {
    const modalButtons = [
      <Button
        variant='primary'
        disabled={
          Object.keys(touched).length === 0 || Object.keys(errors).length
        }
        onClick={() => {
          handleSaveOrAdd(values)
        }}>
        {global._(
          repositoryObj ? 'Keys.Repositories.EditButton' : 'Common.Add'
        )}
      </Button>
    ]

    if (!isMobile) {
      modalButtons.unshift(
        <Button variant='none' onClick={() => setShowRepositoryModal(false)}>
          {global._('Common.Cancel')}
        </Button>
      )
    }

    return modalButtons
  }

  return (
    <Formik
      initialValues={{
        name: (repositoryObj && repositoryObj.name) || '',
        userId: (repositoryObj && repositoryObj.userId) || '',
        propertyId: (repositoryObj && repositoryObj.propertyId) || '',
        space: (repositoryObj && repositoryObj.space) || ''
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(global._('Common.Required')),
        userId: Yup.number().required(global._('Common.Required')),
        propertyId: Yup.string().notRequired(),
        space: Yup.string().notRequired()
      })}>
      {({
        errors,
        touched,
        setTouched,
        handleChange,
        values,
        handleBlur,
        setFieldValue
      }) => (
        <Form>
          <Wrapper
            title={global._(
              repositoryObj
                ? 'Keys.Repositories.EditRepository'
                : 'Keys.Repositories.AddRepository'
            )}
            onClose={() => setShowRepositoryModal(false)}
            rightActionItem='close'
            padding
            buttons={printModalButtons(values, touched, errors)}
            content={formFields(
              errors,
              touched,
              setTouched,
              handleChange,
              values,
              handleBlur,
              setFieldValue
            )}
          />
        </Form>
      )}
    </Formik>
  )
}

RepositoryDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  setShowRepositoryModal: PropTypes.func.isRequired,
  fetchRepositories: PropTypes.func.isRequired,
  currentOrg: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  user: PropTypes.bool.isRequired,
  setEditRepositoryObj: PropTypes.func,
  repositoryObj: PropTypes.object
}

RepositoryDetails.defaultProps = {
  repositoryObj: null,
  setEditRepositoryObj: null
}

const RepositoryDetailsWithStyles = injectSheet(styles)(RepositoryDetails)
export default RepositoryDetailsWithStyles
