import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import { FormControl, Grid } from '@material-ui/core'
import axios from 'axios'
import { InstructionsVideoIds } from 'src/utils/constants'
import Theme from '../../helpers/Theme'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import Notification from '../../common/Notification'
import Modal from '../../components/Modal'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import ObjectListMobile from '../../components/ObjectListMobile'
import CenteredFloatingButtonMobile from '../../components/CenteredFloatingButtonMobile'
import MobileContainer from '../../components/MobileContainer'
import VideoInstructionsModal from '../../components/VideoInstructionsModal'

const styles = {
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 1
  },
  containerMobile: {
    borderBottom: '1px solid rgba(0,0,0, 0.12)',
    width: '100%',
    paddingBottom: 16
  }
}

const PropertiesMobile = props => {
  const {
    classes,
    user,
    history,
    properties,
    error,
    setError,
    isAdmin,
    organisationField,
    searchField
  } = props

  const [showInfoDialog, setShowInfoDialog] = useState(null)

  const lang = global.getLanguage()

  const createNewProperty = async () => {
    try {
      const { data: property } = await axios.post('/v1/properties')
      history.push(`/properties/${property.id}`)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const columns = [
    {
      key: 'icon',
      format: () => 'business'
    },
    {
      key: 'name',
      bold: true,
      variant: 'body',
      clickable: true,
      format: name => name || '(inget namn)'
    },
    {
      key: 'address',
      format: address => (
        <Typography
          variant='bodyFaded'
          style={{ fontSize: 14, lineHeight: '1' }}
        >
          {address || `(${global._('Properties.NoAddressPlaceholder')})`}
        </Typography>
      )
    },
    {
      key: 'organisation',
      hiddenFor: !isAdmin,
      format: organisation => (
        <Typography
          variant='bodyFaded'
          style={{ fontSize: 14, lineHeight: '1' }}
        >
          {organisation && organisation.name
            ? organisation.name
            : global._('Common.NoName')}
        </Typography>
      )
    }
  ]

  return (
    <MobileContainer background='white'>
      <NavigationBar
        title={global._('Properties.Title')}
        backAction={() => history.push('/')}
        rightActionItem={
          <div
            onClick={() => setShowInfoDialog(true)}
            style={{ color: Theme.Colors.PRIMARY }}
          >
            <Icon>info</Icon>
          </div>
        }
      />
      <ScrollView id='scrollable-container'>
        <div className={classes.rootMobile}>
          {error && (
            <Notification
              type='error'
              message={error}
              style={{ margin: '20px 0' }}
            />
          )}
          <div style={{ padding: 'var(--section-padding-default)' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>{searchField()}</FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>{organisationField()}</FormControl>
              </Grid>
            </Grid>
          </div>
          {showInfoDialog && (
            <Modal
              onClose={() => setShowInfoDialog(false)}
              title={global._('Properties.InformationDialogTitle')}
              content={
                <div style={{ textAlign: 'left' }}>
                  <Typography
                    style={{
                      display: 'block',
                      paddingBottom: 12
                    }}
                    variant='body'
                  >
                    {global._('Properties.InfoDialogTitle')}
                  </Typography>
                  <Typography
                    style={{
                      display: 'block',
                      paddingBottom: 12
                    }}
                    variant='bodyItalic'
                  >
                    {global._('Properties.InfoDialogContent')}
                  </Typography>
                  <VideoInstructionsModal
                    videoId={InstructionsVideoIds[lang].PROPERTIES}
                  />
                </div>
              }
              buttons={[
                <Button variant='none' onClick={() => setShowInfoDialog(false)}>
                  {global._('Common.Close')}
                </Button>
              ]}
            />
          )}
          <ObjectListMobile
            objects={properties}
            noResultsMessage={`${global._(
              'Properties.NoExistingProperties'
            )} <br /> ${global._('Properties.AddPropertyText')}`}
            objectPermalink='properties'
            history={history}
            user={user}
            columns={columns}
          />
          <CenteredFloatingButtonMobile onClick={createNewProperty} />
        </div>
      </ScrollView>
    </MobileContainer>
  )
}

PropertiesMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  properties: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
  error: PropTypes.oneOfType([PropTypes.string]),
  setError: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  organisationField: PropTypes.any.isRequired,
  searchField: PropTypes.any.isRequired
}

PropertiesMobile.defaultProps = {
  error: null
}

const PropertiesWithStyle = injectSheet(styles)(PropertiesMobile)
export default PropertiesWithStyle
