import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withUser from 'src/utils/withUser'
import { UserRoles } from 'src/utils/constants'
import TabBar from './TabBar'
import TabBarItem from './TabBarItem'
import Modal from './Modal'
import Button from './Button'
import Typography from './Typography'
import { history } from '../redux/store'
import { signout } from '../redux/reducers/login'

const MobileTabBar = ({ triggerSignout, user }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  return (
    <>
      {showLogoutModal && (
        <Modal
          title={global._('Common.Logout')}
          onClose={() => setShowLogoutModal(false)}
          content={
            <div style={{ textAlign: 'left' }}>
              <Typography style={{ display: 'block' }} variant='body'>
                {global._('Common.LogoutQuestion')}
              </Typography>
            </div>
          }
          buttons={[
            <Button
              variant='none'
              style={{ marginLeft: 0 }}
              onClick={() => setShowLogoutModal(false)}
            >
              {global._('Common.Close')}
            </Button>,
            <Button variant='primary' onClick={() => triggerSignout()}>
              {global._('Common.Logout')}
            </Button>
          ]}
        />
      )}

      <TabBar>
        <TabBarItem
          title={global._('Home.Title')}
          icon='home'
          onClick={() => {
            history.push('/dashboard')
          }}
          selected={window.location.href.indexOf('/dashboard') > -1}
        />
        {user.roles.includes(UserRoles.Support) ? (
          <TabBarItem
            title={global._('Home.Cases')}
            icon='contact_support'
            onClick={() => history.push('/cases')}
            selected={window.location.href.indexOf('/cases') > -1}
          />
        ) : (
          <TabBarItem
            title={global._('TabBar.Locks')}
            icon='vpn_key'
            onClick={() => history.push('/mylocks')}
            selected={window.location.href.indexOf('/mylocks') > -1}
          />
        )}
        {/* <TabBarItem
          title={global._('Common.Logout')}
          onClick={() => setShowLogoutModal(true)}
          icon='account_circle'
        /> */}
      </TabBar>
    </>
  )
}

MobileTabBar.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.node,
  triggerSignout: PropTypes.func.isRequired
}

MobileTabBar.defaultProps = {
  children: null
}

const mapDispatchToProps = dispatch => ({
  triggerSignout: () => dispatch(signout())
})

const ConnectedMobileTabBar = withUser(
  connect(null, mapDispatchToProps)(MobileTabBar)
)
export default ConnectedMobileTabBar
