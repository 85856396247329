const UserMessages = {
  EN: {
    SAM0: 'Please wait..',
    RFA1: 'Start your BankID app.',
    RFA2: 'The BankID app is not installed. Please contact your internet bank.',
    RFA3: 'Action cancelled. Please try again.',
    RFA4: 'An identification or signing for this personal number is already started. Please try again.',
    RFA5: 'Internal error. Please try again.',
    RFA6: 'Action cancelled.',
    RFA8: 'The BankID app is not responding. Please check that the program is started and that you have internet access. If you don’t have a valid BankID you can get one from your bank. Try again.',
    RFA9: 'Enter your security code in the BankID app and select Identify or Sign.',
    RFA13: 'Trying to start your BankID app.',
    RFA14:
      'Searching for BankID:s, it may take a little while... If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this device. If you don’t have a BankID you can order one from your internet bank. If you have a BankID on another device you can start the BankID app on that device.',
    RFA15:
      'Searching for BankID:s, it may take a little while... If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this device. If you don’t have a BankID you can order one from your internet bank',
    RFA16:
      'The BankID you are trying to use is revoked or too old. Please use another BankID or order a new one from your internet bank.',
    RFA17:
      "The BankID app couldn't be found on your computer or mobile device. Please install it and order a BankID from your internet bank. Install the app from your app store or https://install.bankid.com.",
    RFA17B:
      "Failed to scan the QR code. Start the BankID app and scan the QR code. If you don't have the BankID app, you need to install it and order a BankID from your internet bank. Install the app from your app store or https://install.bankid.com",
    RFA18: 'Starta BankID-appen',
    RFA19:
      'Would you like to identify yourself or sign with a BankID on this computer or with a Mobile BankID?',
    RFA20:
      'Would you like to identify yourself or sign with a BankID on this device or with a BankID on another device?',
    RFA21: 'Identification or signing in progress.',
    RFA22: 'Unknown error. Please try again.'
  },
  SE: {
    SAM0: 'Vänligen vänta..',
    RFA1: 'Starta BankID-appen',
    RFA2: 'Du har inte BankID-appen installerad. Kontakta din internetbank.',
    RFA3: 'Åtgärden avbruten. Försök igen',
    RFA4: 'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.',
    RFA5: 'Internt tekniskt fel. Försök igen.',
    RFA6: 'Åtgärden avbruten.',
    RFA8: 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din Bank. Försök sedan igen.',
    RFA9: 'Skriv in din säkerhetskod i BankID-appen och välj Legitimera eller Skriv under.',
    RFA13: 'Försöker starta BankID-appen.',
    RFA14:
      'Söker efter BankID, det kan ta en liten stund... Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du hämta ett hos din internetbank. Om du har ett BankID på en annan enhet kan du starta din BankID-app där',
    RFA15:
      'Söker efter BankID, det kan ta en liten stund... Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du hämta ett hos din internetbank.',
    RFA16:
      'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.',
    RFA17:
      'BankID-appen verkar inte finnas i din dator eller telefon. Installera den och hämta ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',
    RFA17B:
      'Misslyckades att läsa av QR koden. Starta BankID-appen och läs av QR koden. Om du inte har BankID-appen måste du installera den och hämta ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',
    RFA18: 'Starta BankID-appen',
    RFA19:
      'Vill du identifiera dig eller skriva under med BankID på den här datorn eller med ett Mobilt BankID?',
    RFA20:
      'Vill du identifiera dig eller skriva under med ett BankID på den här enheten eller med ett BankID på en annan enhet?',
    RFA21: 'Identifiering eller underskrift pågår.',
    RFA22: 'Okänt fel. Försök igen.'
  }
}
const Hints = {
  internal: {
    loading: 'SAM0'
  },
  PENDING: {
    outstandingTransaction: 'RFA1',
    noClient: 'RFA15',
    userSign: 'RFA9',
    unknown: 'RFA21'
  },
  FAILED: {
    expiredTransaction: 'RFA8',
    certificateErr: 'RFA16',
    userCancel: 'RFA6',
    cancelled: 'RFA3',
    startFailed: 'RFA17',
    unknown: 'RFA22'
  },
  COMPLETE: {
    unknown: 'Complete'
  },
  /* legacy keys */
  pending: {
    outstandingTransaction: 'RFA1',
    noClient: 'RFA15',
    userSign: 'RFA9',
    unknown: 'RFA21'
  },
  failed: {
    expiredTransaction: 'RFA8',
    certificateErr: 'RFA16',
    userCancel: 'RFA6',
    cancelled: 'RFA3',
    startFailed: 'RFA17',
    unknown: 'RFA22'
  },
  complete: {
    unknown: 'Complete'
  }
}

const Errors = {
  alreadyInProgress: 'RFA4',
  invalidParameters: '',
  Maintenance: 'RFA5',
  unknown: 'RFA22'
}

const getUserMessage = (status, hintCode) => {
  if (!Hints[status]) return hintCode

  let language = global.getLanguage()
  if (language !== 'SE') {
    language = 'EN'
  }
  const shortCode = Hints[status][hintCode] || Hints[status].unknown
  if (!UserMessages[language]) return `${hintCode} (${shortCode})`
  if (!UserMessages[language][shortCode]) return shortCode

  const message = UserMessages[language][shortCode]
  return message
}
const getErrorMessage = errorCode => {
  if (!Errors[errorCode]) return errorCode

  let language = global.getLanguage()
  if (language !== 'SE') {
    language = 'EN'
  }
  const shortCode = Errors[errorCode]
  if (!UserMessages[language]) return `Error (${shortCode})`
  if (!UserMessages[language][shortCode]) return shortCode

  const message = UserMessages[language][shortCode] || shortCode
  return message
}

module.exports = {
  getUserMessage,
  getErrorMessage
}
