import React, { useEffect } from 'react'
import { Paper, Typography } from '@material-ui/core'
import injectSheet from 'react-jss'
import PageContentHeaderWithStyles from 'src/components/PageContentHeader'
import {
  IServiceRequest,
  ServiceRequestFilter,
  IServiceRequestGroup
} from './types'
import { serviceRequestsBreadcrumbs } from './serviceRequestsBreadcrumbs'
import Button from 'src/components/Button'
import { ServiceRequestTable } from './ServiceRequestTable'
import axios from 'axios'
import { stringify } from 'querystring'
import { history } from 'src/redux/store'
import { ServiceRequestListFilter } from './ServiceRequestListFilter'
import { usePersistState } from 'src/hooks/usePersistState'
import { ServiceRequestGroupTable } from './ServiceRequestGroupTable'

interface ServiceRequestListProps {
  classes: any
  user: IUser
}

const ServiceRequestList = (props: ServiceRequestListProps) => {
  const { classes, user } = props

  const [filter, setFilter] = usePersistState<ServiceRequestFilter>(
    'ServiceRequest/Filter',
    {
      organisationOption: {
        label: user.organisation.name,
        value: user.organisation
      }
    }
  )

  const [serviceRequestGroups, setServiceRequestGroups] = usePersistState<
    IServiceRequestGroup[]
  >('ServiceRequest/Groups', [])

  const fetchServiceRequests = async () => {
    const qs = stringify(filter as any)
    const { data } = await axios.get<IServiceRequest[]>(
      `/v1/organisations/${filter.organisationOption?.value?.id}/servicerequests?${qs}`
    )

    // Group by groupId
    const groups: IServiceRequestGroup[] = []
    data.forEach(serviceRequest => {
      const group = groups.find(group => group.id === serviceRequest.groupId)
      if (group) {
        group.items.push(serviceRequest)
      } else {
        groups.push({
          id: serviceRequest.groupId!,
          organisationId: serviceRequest.organisationId!,
          organisation: serviceRequest.organisation,
          from: serviceRequest.from!,
          to: serviceRequest.to!,
          message: serviceRequest.message!,
          createdAt: serviceRequest.createdAt!,
          updatedAt: serviceRequest.updatedAt!,
          items: [serviceRequest]
        })
      }
    })
    setServiceRequestGroups(groups)
  }
  useEffect(() => {
    fetchServiceRequests()
  }, [filter])
  useEffect(() => {
    fetchServiceRequests()
  }, [])

  return (
    <div className={classes.root}>
      <PageContentHeaderWithStyles
        breadcrumbItems={serviceRequestsBreadcrumbs}
      />
      <Paper elevation={2}>
        <div className={classes.section}>
          <Typography
            variant='h3'
            style={{ fontWeight: 600, fontSize: '1.5rem' }}
          >
            {translate('ServiceRequests.Title')}
          </Typography>
          <Button
            variant='primary'
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              history.push('/admin/apartments/servicerequests/new')
            }}
          >
            {translate('ServiceRequests.NewServiceRequest')}
          </Button>
        </div>
        <div className={classes.section}>
          <ServiceRequestListFilter filter={filter} onChange={setFilter} />
        </div>
        <ServiceRequestGroupTable data={serviceRequestGroups} />
        <div className={classes.section}></div>
      </Paper>
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 12
  },
  header: {
    padding: 22,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid var(--divider-color)'
  },
  section: {
    padding: '16px 22px',
    width: '100%',
    borderBottom: '1px solid var(--divider-color)',
    display: 'flex',
    alignItems: 'center'
  },
  infoIcon: {
    fontSize: 30,
    transition: 'all 0.3s',
    cursor: 'pointer',
    marginTop: 4,
    marginLeft: 10,
    '&:hover': {
      color: 'var(--color-primary)'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  iconContainer: {
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 500,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  },
  spacer: {
    height: 40
  },
  chevron: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 'auto',
    fontSize: 16,
    color: 'rgba(0,0,0,0.75)',
    marginRight: 10
  },
  iconContainerMobile: {
    padding: '0px 28px'
  },
  mobileRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0',
    borderBottom: '1px solid var(--divider-color)'
  },

  '@media (max-width: 900px)': {
    root: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    rootSingle: {}
  }
}

export default injectSheet(styles)(ServiceRequestList)
