import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon } from '@material-ui/core'
import Paper from '../Paper'
import Typography from '../Typography'
import useWindowDimensions from '../../utils/useWindowDimensions'
import Alert from '../Alert'
import Button from '../Button'

const styles = {
  paper: {},
  '@media (max-width: 900px)': {
    paper: {
      padding: '10px!important'
    }
  },
  '@media (max-width: 450px)': {
    paper: {
      padding: '10px!important'
    }
  }
}

const KeysNotice = ({
  classes,
  history,
  user,
  icon,
  title,
  subtitle,
  actionButtons,
  children,
  onClick,
  requirePersonalNumber
}) => {
  const { isMobile } = useWindowDimensions()

  return (
    <Paper
      classes={classes}
      style={{
        marginBottom: 10,
        width: '100%'
      }}
      mediumPadding={!isMobile}
      onClick={onClick}
    >
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        {icon && (
          <div
            style={{
              width: isMobile ? 48 : 60,
              minWidth: isMobile ? 48 : 60,
              maxWidth: isMobile ? 48 : 60,
              height: isMobile ? 48 : 60,
              minHeight: isMobile ? 48 : 60,
              maxHeight: isMobile ? 48 : 60,
              backgroundColor: 'var(--color-primary)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '100%',
              marginRight: 20
            }}
          >
            <Icon>{icon}</Icon>
          </div>
        )}
        <div>
          {subtitle && (
            <Typography variant='body2' block={isMobile} faded>
              {subtitle}
            </Typography>
          )}
          {title && (
            <Typography variant={isMobile ? 'body' : 'subtitleBig'} bold>
              {title}
            </Typography>
          )}
        </div>
        {!isMobile && (
          <div style={{ marginLeft: 'auto' }}>
            {actionButtons && actionButtons}
          </div>
        )}
      </div>
      {isMobile && (
        <div style={{ width: '100%', marginTop: 15 }}>
          {actionButtons && actionButtons}
        </div>
      )}
      {user && requirePersonalNumber && !user.personalNumber && (
        <div style={{ width: '100%', marginTop: isMobile ? 0 : 20 }}>
          <Alert type='info'>
            <div
              style={{
                display: isMobile ? 'column' : 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <span>{global._('MyKeys.PersonalNumberAlertInfo')}</span>
              <Button
                variant='secondary'
                style={{
                  whiteSpace: 'nowrap',
                  marginLeft: isMobile ? 0 : 20,
                  marginTop: isMobile ? 20 : 0
                }}
                onClick={() => history.push('/myaccount')}
              >
                {global._('MyKeys.AddPersonalNumber')}
              </Button>
            </div>
          </Alert>
        </div>
      )}
    </Paper>
  )
}

KeysNotice.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  icon: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.any,
  actionButtons: PropTypes.node,
  children: PropTypes.any,
  onClick: PropTypes.func,
  requirePersonalNumber: PropTypes.bool
}

KeysNotice.defaultProps = {
  icon: null,
  title: null,
  subtitle: null,
  actionButtons: null,
  children: null,
  onClick: null,
  requirePersonalNumber: true
}

const KeysNoticeWithStyles = injectSheet(styles)(KeysNotice)
export default KeysNoticeWithStyles
