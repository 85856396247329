import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

export const standardTermsJson = {
  blocks: [
    {
      key: 'S8o531',
      text: 'För utkvitterad nyckel gäller följande:',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [{ offset: 0, length: 39, style: 'BOLD' }],
      entityRanges: [],
      data: { 'text-align': 'start' }
    },
    {
      key: 'S8tgfn',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'S47fp3',
      text: 'Nyckeln är ett värdeföremål.',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Sds8uq',
      text: 'Nyckeln ska förvaras på ett betryggande sätt.',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'S4en87',
      text: 'Nyckeln får inte lånas ut.',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Sfm8r0',
      text: 'Vid förlust av nyckeln ska detta meddelas omgående.',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'S7mohg',
      text: 'Vid ev. nyckelrevision ska nyckelinnehavaren bekräfta att nyckeln finns under kontroll.',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ],
  entityMap: {}
}

export const defaultContentFieldsJson = {
  blocks: [
    {
      key: 'Db9jck',
      text: 'Nyckelkvittens',
      type: 'header-three',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D2j6dq',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D2f6dx',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Daenlq',
      text: 'Nyckelns huvudägare',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Dc4vom',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D4r5im',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Ddmkoi',
      text: 'Nyckelns innehavare',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D2q3h',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D3dn15',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D8l8jb',
      text: 'Jag intygar härmed att jag mottagit följande nyckel:',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [{ offset: 0, length: 52, style: 'BOLD' }],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D5hcja',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D9f1px',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D232f0',
      text: 'Märkning',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Dchm2i',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D7clf0',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D1nf0n',
      text: 'Typ',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D6ndod',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D56mbo',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D5820e',
      text: 'Fabrikat',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D6miqo',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D8i3d4',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Df3imp',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D6d6vj',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D3cp3f',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Dfgt8c',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D157r5',
      text: 'Datum',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Dbe8j1',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Dcci8r',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D124dk',
      text: 'Namnteckning',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D51qfh',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Dd60q7',
      text: '                                                                          ',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [{ offset: 13, length: 61, style: 'UNDERLINE' }],
      entityRanges: [],
      data: {}
    },
    {
      key: 'Dfftdq',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D6o7dc',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'De85rk',
      text: 'Namnförtydligande',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D17l4k',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    },
    {
      key: 'D97g0q',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ],
  entityMap: {}
}

const privacyPolicyJson = [
  {
    key: 'S8tgfndz',
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S8o531s',
    text: 'Vilka personuppgifter vi samlar in och varför vi samlar in dem',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [{ offset: 0, length: 62, style: 'BOLD' }],
    entityRanges: [],
    data: { 'text-align': 'start' }
  },
  {
    key: 'S8tgfnz',
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp345',
    text: 'Kontaktformulär',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [{ offset: 0, length: 15, style: 'BOLD' }],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp31',
    text: 'Användaruppgifter insamlade genom kontaktformulär (namn, e-postadress, telefonnummer) sparas på obestämd tid för att kunna återkoppla till dig.',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S8tgfn1',
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp32',
    text: 'Cookie-filer',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [{ offset: 0, length: 15, style: 'BOLD' }],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp33',
    text: 'En cookie är en liten textfil som sparas på din dator och innehåller information. Den används för att underlätta för besökaren på hemsidan. Vi sparar ingen personlig information genom cookies. Du kan ändra inställningar i din webbläsare om du inte vill ha cookies.',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp34',
    text: 'På digitallassmed.se använder vi cookies för att göra hemsidan mer användarvänlig för dig. Om du gör ändringar på hemsidan sparas dessa i en cookie. Nästa gång du besöker sidan kommer du inte behöva göra samma ändring igen. Cookien informerar hemsidan att du har besökt den tidigare och vilka ändringar som gjorts. Om du ändrar dessa inställningar igen så kommer den gamla cookien ersättas med en ny. Cookien används på så sätt för att underlätta för dig som besökare av hemsidan.',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S8tgfn2',
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp35',
    text: 'Inbäddat innehåll från andra webbplatser',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [{ offset: 0, length: 100, style: 'BOLD' }],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp36',
    text: 'Artiklar på denna webbplats kan innehålla inbäddat innehåll (exempelvis videoklipp, bilder, artiklar o.s.v.). Inbäddat innehåll från andra webbplatser beter sig precis på samma sätt som om besökaren har besökt den andra webbplatsen. Dessa webbplatser kan samla in uppgifter om dig, använda cookie-filer, bädda in ytterligare spårning från tredje part och övervaka din interaktion med sagda inbäddade innehåll, inklusive spårning av din interaktion med detta inbäddade innehåll om du har ett konto och är inloggad på webbplatsen i fråga.',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S8tgfn3',
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp37',
    text: 'Hur länge vi behåller era uppgifter',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [{ offset: 0, length: 100, style: 'BOLD' }],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp38',
    text: 'Om du registrerar dig som användare på vår webbplats sparar vi även de personuppgifter som anges i din. Du kan se, redigera eller radera dina personuppgifter när som helst (med undantaget att du inte kan ändra ditt användarnamn). Även webbplatsens administratörer kan se och redigera denna information.',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S8tgfn4',
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp39',
    text: 'Vilka rättigheter du har gällande dina uppgifter',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [{ offset: 0, length: 100, style: 'BOLD' }],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp40',
    text: 'Om du har ett konto eller har skrivit några kommentarer på denna webbplats kan du begära en exportfil med de personuppgifter vi har om dig, inklusive alla uppgifter du har gett oss. Du kan också begära att vi tar bort alla personuppgifter vi har om dig. Detta omfattar inte eventuella uppgifter som vi är tvungna att spara av administrativa, legala eller säkerhetsmässiga skäl.',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  },
  {
    key: 'S47fp41',
    text: 'Personuppgiftsansvarig är Rosie Samuelsson. Du kontaktar henne enklast på info@digiallassmed.se.',
    type: 'p',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  }
]

const genStandardTermsWithPrivacyPolicyJson = () => {
  const terms = JSON.parse(JSON.stringify(standardTermsJson))

  const blocks = terms.blocks.concat(privacyPolicyJson)

  terms.blocks = blocks

  return terms
}

export const standardTermsWithPrivacyPolicyJson =
  genStandardTermsWithPrivacyPolicyJson()

const linebreak = numberOfLines => {
  const blocks = []
  for (let i = 0; i < numberOfLines; i += 1) {
    blocks.push({
      key: uuidv4(),
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    })
  }
  return blocks
}

export const keyLoanTemplate = (template, keys, user, organisation) => {
  if (!template || !template.content) {
    return { blocks: [], entityMap: {} }
  }
  const content = JSON.parse(template.content)
  const keyBlocks = keys.map(key => ({
    key: uuidv4(),
    text: `${key.marking}, ${global._(`Keys.Types.${key.type}`)}, ${key.make}`,
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  }))
  return {
    blocks: [
      {
        key: 'Db9jck',
        text: 'Nyckelkvittens',
        type: 'header-three',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      {
        key: 'Daenlq',
        text: `Huvudägare: ${organisation.name}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      {
        key: 'Ddmkoi',
        text: `Innehavare: ${user.name}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      ...linebreak(2),
      {
        key: 'D8l8jb',
        text: 'Jag intygar härmed att jag mottagit följande nycklar:',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 0, length: 54, style: 'BOLD' }],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      ...keyBlocks,
      ...linebreak(2),
      ...content.blocks,
      ...linebreak(2),
      {
        key: 'D157r5',
        text: 'Datum',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      {
        key: 'D157r6',
        text: moment().format('YYYY-MM-DD'),
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      {
        key: 'D124dk',
        text: 'Namnteckning',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      ...linebreak(2),
      {
        key: 'Dd60q7',
        text: '                                                                          ',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 13, length: 61, style: 'UNDERLINE' }],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      {
        key: 'De85rk',
        text: user.name,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      ...linebreak(2)
    ],
    entityMap: {}
  }
}

const pageDivider = () => ({
  key: uuidv4(),
  text: '                                                                                                                                                                                                         ',
  type: 'unstyled',
  depth: 0,
  inlineStyleRanges: [{ offset: 13, length: 174, style: 'UNDERLINE' }],
  entityRanges: [],
  data: {}
})

export const generateReturnedKeysReceipt = (keys, organisation) => {
  const arr = []

  keys.forEach((key, idx) => {
    arr.push(
      {
        ...linebreak(1),
        key: uuidv4(),
        text: `Nyckel nr. ${idx + 1}`,
        type: 'header-five',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: { 'text-align': 'start' }
      },
      ...linebreak(1),
      {
        key: uuidv4(),
        text: 'Innehavare',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      {
        key: uuidv4(),
        text: `${key.holderName}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 0, length: 39, style: 'BOLD' }],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      {
        key: uuidv4(),
        text: 'Märkning',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: { 'text-align': 'start' }
      },
      {
        key: uuidv4(),
        text: `${key.marking}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 0, length: 39, style: 'BOLD' }],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      {
        key: uuidv4(),
        text: 'Typ',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: { 'text-align': 'start' }
      },
      {
        key: uuidv4(),
        text: `${global._(`Keys.Types.${key.type}`)}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 0, length: 39, style: 'BOLD' }],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      {
        key: uuidv4(),
        text: 'Fabrikat',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: { 'text-align': 'start' }
      },
      {
        key: uuidv4(),
        text: `${key.make || '-'}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 0, length: 39, style: 'BOLD' }],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      {
        ...pageDivider()
      },
      ...linebreak(1)
    )
  })

  return {
    blocks: [
      {
        key: 'a1jqk',
        text: 'Bekräftelse',
        type: 'header-two',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      {
        key: 'bvd9a',
        text: 'Återlämning av nycklar',
        type: 'header-five',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      {
        ...pageDivider()
      },
      ...linebreak(1),
      {
        key: '3q3nt',
        text: 'Datum',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      {
        key: 'cn3af',
        text: moment().format('YYYY-MM-DD'),
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 0, length: 39, style: 'BOLD' }],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      {
        key: 'b4j1p',
        text: 'Huvudägare',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      },
      {
        key: 'v15tr5',
        text: `${organisation.name}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 0, length: 39, style: 'BOLD' }],
        entityRanges: [],
        data: {}
      },
      {
        ...pageDivider()
      },
      ...linebreak(1),
      ...arr,
      {
        key: 'cx3qf',
        text: `Hör av dig till ${organisation.name} (nyckelns huvudägare) med eventuella frågor eller om någon information är felaktig.`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          { offset: 16, length: organisation.name.length, style: 'BOLD' }
        ],
        entityRanges: [],
        data: {}
      },
      ...linebreak(1),
      {
        key: 'ff7f0',
        text: 'Nyckeln eller nycklarna kommer inte längre att vara synliga under "Mina nycklar" i Portalen.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 0, length: 92, style: 'ITALIC' }],
        entityRanges: [],
        data: {}
      }
    ],
    entityMap: {}
  }
}
