import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import NavigationBar from 'src/components/NavigationBar'
import ScrollView from 'src/components/ScrollView'
import axios from 'axios'
import Button from 'src/components/Button'
import MobileContainer from 'src/components/MobileContainer'
import Alert from 'src/components/Alert'
import Notification from 'src/common/Notification'
import useWindowDimensions from '../../utils/useWindowDimensions'

const styles = {}

const VerifyAccessCode = props => {
  const { classes, onCompletion, accessUserUuid } = props

  const { isMobile } = useWindowDimensions()
  const [verificationCode, setVerificationCode] = useState(null)
  const [delegatedAccessData, setDelegatedAccessData] = useState(null)
  const [isSendingVerificationCode, setIsSendingVerificationCode] =
    useState(false)
  const [hasSentVerificationCode, setHasSentVerificationCode] = useState(true)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const sendVerificationCode = async () => {
    setHasSentVerificationCode(false)
    setIsSendingVerificationCode(true)
    setError(null)
    try {
      await axios.get(`/v1/access/public/${accessUserUuid}/verificationCode`)
      setHasSentVerificationCode(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsSendingVerificationCode(false)
  }

  const verifyUuid = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const { data } = await axios.get(`/v1/access/public/${accessUserUuid}`)

      if (!data.initialCodeSent) {
        sendVerificationCode()
      }

      setDelegatedAccessData(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const { data } = await axios.post(
        `/v1/access/public/${accessUserUuid}/auth`,
        { code: verificationCode }
      )

      const { id, secret, exchangeToken } = data
      const encodedSecret = btoa(`${id}:${secret}`)
      const encodedExchangeToken = btoa(`${id}:${exchangeToken}`)
      localStorage.setItem('public.token', encodedSecret)
      localStorage.setItem('public.exchangeToken', encodedExchangeToken)
      localStorage.setItem('public.access', accessUserUuid)
      onCompletion()
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    verifyUuid()
  }, [])

  return (
    <MobileContainer
      showTabBar={false}
      background='var(--color-white)'
      style={{ padding: isMobile ? undefined : '100px 40px' }}
    >
      <NavigationBar
        title={global._('DelegatedAccess.VerifyAccessCode.Access')}
      />
      <ScrollView fullHeight>
        <div style={{ padding: 'var(--section-padding-default)' }}>
          {!hasSentVerificationCode && (
            <Alert
              dangerouslySetInnerHTML={{
                __html: global._('DelegatedAccess.VerifyAccessCode.InfoVerify')
              }}
            />
          )}
          {hasSentVerificationCode &&
            delegatedAccessData &&
            delegatedAccessData.phone && (
              <Alert
                dangerouslySetInnerHTML={{
                  __html: global._('DelegatedAccess.VerifyAccessCode.Info', [
                    delegatedAccessData.phone
                  ])
                }}
              />
            )}
          <Grid container spacing={3} style={{ marginTop: 8 }}>
            <Grid item xs={12}>
              <TextField
                inputRef={input =>
                  input && hasSentVerificationCode && input.focus()
                }
                name='verificationCode'
                label={global._(
                  'DelegatedAccess.VerifyAccessCode.VerificationCode'
                )}
                variant='filled'
                style={{ width: '100%' }}
                onChange={e => {
                  setError(null)
                  setVerificationCode((e && e.target && e.target.value) || null)
                }}
              />
            </Grid>
          </Grid>
          <Button
            variant='primary'
            loading={isLoading}
            disabled={!verificationCode || error}
            onClick={() => handleSubmit()}
            style={{ marginTop: 'var(--section-padding-default)' }}
          >
            {global._('DelegatedAccess.VerifyAccessCode.Verify')}
          </Button>
          <Button
            variant='outlined'
            style={{ marginTop: 20 }}
            loading={isSendingVerificationCode}
            disabled={error}
            onClick={sendVerificationCode}
          >
            {global._(`DelegatedAccess.VerifyAccessCode.Resend`)}
          </Button>
          {error && (
            <Notification
              style={{ marginTop: 20 }}
              type='error'
              message={error}
            />
          )}
        </div>
      </ScrollView>
    </MobileContainer>
  )
}

VerifyAccessCode.propTypes = {
  classes: PropTypes.object.isRequired
}

VerifyAccessCode.defaultProps = {}

const VerifyAccessCodeWithStyles = injectSheet(styles)(VerifyAccessCode)
export default VerifyAccessCodeWithStyles
