import { Typography } from '@material-ui/core'
import { request } from 'http'
import { IServiceRequest, IServiceRequestStatus } from './types'

interface ServiceRequestStatusBadgeProps {
  request: IServiceRequest | undefined
}
export const ServiceRequestStatusBadge = (
  props: ServiceRequestStatusBadgeProps
) => {
  const { request } = props

  if (!request) {
    return null
  }

  const style = {
    color: 'white',
    backgroundColor: 'black',
    padding: '3px 6px',
    borderRadius: '8px',
    display: 'inline-block',
    fontWeight: 700,
    fontSize: 12
  }

  if (request.status === IServiceRequestStatus.PENDING) {
    style.backgroundColor = 'gray'
  } else if (request.status === IServiceRequestStatus.ACCEPTED) {
    style.backgroundColor = '#32a852'
  } else if (request.status === IServiceRequestStatus.DENIED) {
    style.backgroundColor = '#c43d2b'
  }

  return (
    <div style={style}>
      <Typography style={style} variant='body2'>
        {translate(`ServiceRequests.Status.${request.status}`)}
      </Typography>
    </div>
  )
}
