/**
 * Simple shell page to display the Success component.
 *
 * match.params.part2 will contain the newly created partner id
 *
 * Check Routes.js for more info on url params
 */
import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import Success from '../../../components/Success'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'

const Shell = props => {
  const { history, match } = props
  const customerAddedBySystemAdmin = !!match.params.partnerId
  return (
    <Success>
      <Typography
        variant='subtitleBig'
        style={{
          marginBottom: 28
        }}>
        {global._('Admin.SuccessAddCustomer')}
      </Typography>
      <Button
        style={{ width: '100%' }}
        variant='primary'
        onClick={() =>
          history.push(`/organisations/${match.params.customerId}`)
        }>
        {global._('Admin.ToCustomer')}
      </Button>
      <Button
        style={{ width: '100%' }}
        variant='outlined'
        onClick={() =>
          history.push(
            customerAddedBySystemAdmin
              ? `/admin/partners/${match.params.partnerId}/new`
              : '/admin/customers/new'
          )
        }>
        {global._('Admin.AddAdditionalCustomer')}
      </Button>
      <Button variant='none' onClick={() => history.goBack()}>
        {global._('Common.Back')}
      </Button>
    </Success>
  )
}

Shell.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const ShellWithRouter = withRouter(Shell)
export default ShellWithRouter
