import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Icon, TextField } from '@material-ui/core'
import NavigationBar from '../../../components/NavigationBar'
import ScrollView from '../../../components/ScrollView'
import Notification from '../../../common/Notification'
import NavigationList from '../../../components/NavigationList'
import FullscreenModal from '../../../components/FullscreenModal'
import Button from '../../../components/Button'
import MobileContainer from '../../../components/MobileContainer'
import Typography from '../../../components/Typography'

const styles = {
  root: {},
  section: {
    padding: 'var(--section-padding-default)',
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--color-divider-light)'
    }
  }
}

const DeviceDetailMobile = props => {
  const {
    classes,
    history,
    error,
    device,
    deviceStatus,
    showDeviceVersions,
    detailsForm,
    availableRelays,
    setShowDeleteDeviceModal,
    deleteDeviceModal,
    setShowCreateLockModal,
    handleReplaceDevice,
    setShowMobileReplaceDeviceModal,
    showMobileReplaceDeviceModal,
    setError
  } = props

  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [showConnectedLocksModal, setShowConnectedLocksModal] = useState(false)
  const [replaceDeviceId, setReplaceDeviceId] = useState(null)

  const listItems = [
    {
      title: global._('Device.EditDetails'),
      onClick: () => setShowDetailsModal(true),
      icon: 'edit'
    },
    {
      title: global._('Device.Locks'),
      onClick: () => setShowConnectedLocksModal(true),
      icon: 'lock'
    }
  ]

  const locks = availableRelays.map(relay => ({
    title: relay.name,
    subTitle: `${global._('Device.RelayNumber')} ${relay.relay}`,
    icon: 'lock',
    darkIcon: relay.createdAt || false,
    rightIcon: !relay.createdAt && 'add',
    onClick: () =>
      relay.createdAt
        ? history.push(`/${relay.permalink}/${relay.id}`)
        : setShowCreateLockModal(true)
  }))

  useEffect(() => {
    setError(null)
    setReplaceDeviceId(null)
  }, [showMobileReplaceDeviceModal])

  return (
    <MobileContainer background='white'>
      {deleteDeviceModal()}
      {showDetailsModal && (
        <FullscreenModal
          title={global._('Device.EditDetails')}
          padding
          content={<div>{detailsForm()}</div>}
          onClose={() => {
            setShowDetailsModal(false)
          }}
        />
      )}
      {showConnectedLocksModal && (
        <FullscreenModal
          title={global._('Device.Locks')}
          content={
            <div>
              <NavigationList itemList={locks} />
            </div>
          }
          onClose={() => {
            setShowConnectedLocksModal(false)
          }}
        />
      )}
      {showMobileReplaceDeviceModal && (
        <FullscreenModal
          title={global._('Device.ReplaceDevice')}
          padding
          content={
            <div>
              <TextField
                value={replaceDeviceId}
                name='uuid'
                fullWidth
                helperText={global._('Common.Required')}
                className={classes.input}
                label={global._('Device.ReplaceDeviceNewId')}
                variant='filled'
                onChange={e => setReplaceDeviceId(e.target.value)}
                style={{ marginBottom: 20 }}
              />
              <Typography
                variant='body'
                italic
                block
                style={{ marginBottom: 30 }}
              >
                {global._('Device.ReplaceDeviceModalInfo')}
              </Typography>
              {error && (
                <Notification
                  type='error'
                  message={error}
                  style={{ margin: '20px 0' }}
                />
              )}
            </div>
          }
          onClose={() => {
            setShowMobileReplaceDeviceModal(false)
          }}
          rightActionItem='close'
          buttons={
            <Button
              variant='primary'
              disabled={!replaceDeviceId}
              onClick={() => {
                handleReplaceDevice(replaceDeviceId)
              }}
            >
              {global._('Common.Add')}
            </Button>
          }
        />
      )}
      <NavigationBar
        title={`${global._('Device.DeviceFor')} ${device && device.name}`}
        backAction={() => history.push('/admin/devices')}
      />
      <ScrollView id='scrollable-container'>
        <div className={classes.rootMobile}>
          {error && (
            <Notification
              type='error'
              message={error}
              style={{ margin: '20px 0' }}
            />
          )}
          <div className={classes.section}>{deviceStatus()}</div>
          <div className={classes.section}>{showDeviceVersions(true)}</div>
          <div>
            <NavigationList itemList={listItems} />
          </div>
          <div style={{ marginTop: 90 }} className={classes.section}>
            {device && (
              <>
                <Button
                  variant='outlined'
                  style={{ marginBottom: 20 }}
                  onClick={() => setShowMobileReplaceDeviceModal(true)}
                >
                  {global._('Device.ReplaceDevice')}
                </Button>
                <Button
                  variant='secondary'
                  onClick={() => setShowDeleteDeviceModal(true)}
                >
                  {global._('Device.DeleteTheDevice')}
                </Button>
              </>
            )}
          </div>
        </div>
      </ScrollView>
    </MobileContainer>
  )
}

DeviceDetailMobile.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  device: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  deviceStatus: PropTypes.func.isRequired,
  showDeviceVersions: PropTypes.func.isRequired,
  detailsForm: PropTypes.func.isRequired,
  availableRelays: PropTypes.array.isRequired,
  setShowDeleteDeviceModal: PropTypes.func.isRequired,
  deleteDeviceModal: PropTypes.func.isRequired,
  setShowCreateLockModal: PropTypes.func.isRequired,
  handleReplaceDevice: PropTypes.func.isRequired,
  setShowMobileReplaceDeviceModal: PropTypes.func.isRequired,
  showMobileReplaceDeviceModal: PropTypes.bool,
  setError: PropTypes.func.isRequired
}

DeviceDetailMobile.defaultProps = {
  device: null,
  showMobileReplaceDeviceModal: false
}

const DeviceDetailMobileWithStyles = injectSheet(styles)(DeviceDetailMobile)
export default DeviceDetailMobileWithStyles
