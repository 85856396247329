import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  TextField,
  Icon,
  Checkbox,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import TabNavigator from 'src/components/TabNavigator'
import Row from 'src/components/Row'
import Column from 'src/components/Column'
import Paper from 'src/components/Paper'
import PageContentHeader from 'src/components/PageContentHeader'
import Button from 'src/components/Button'
import Typography from 'src/components/Typography'
import Notification from 'src/common/Notification'
import { UserRoles, CertificateTypes, Country } from 'src/utils/constants'
import Modal from 'src/components/Modal'
import SuccessMessage from 'src/components/SuccessMessage'
import Alert from 'src/components/Alert'
import DragZone from 'src/components/DragAndDrop/DragZone'
import ItemBadge from 'src/components/ItemBadge'
import moment from 'moment'
import Products from 'src/components/Products'
import ShippingOptions from 'src/components/ShippingOptions'

const styles = {
  root: {},
  singleRadioButton: {
    borderBottom: '1px solid var(--color-divider-light)',
    marginRight: '30px !important',
    width: 210,
    justifyContent: 'space-between',
    '&:last-child': {
      marginRight: '0 !important'
    }
  }
}

const Navigation = {
  PRODUCTS: '#products',
  SHIPPING: '#shipping',
  SWISH: '#swish',
  NETS: '#nets',
  NOTIFICATIONS: '#notifications'
}

const SettingsOrders = props => {
  const { classes, user, history, breadcrumbItems } = props

  const isPartner =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  if (!isPartner) {
    return <Redirect to='/admin' />
  }

  const navigation = history.location.hash || Navigation.PRODUCTS

  // const [error, setError] = useState()
  const [saved, setSaved] = useState(false)

  const [certificateError, setCertificateError] = useState(null)
  const [
    isDeleteSwishCertificateModalOpen,
    setIsDeleteSwishCertificateModalOpen
  ] = useState(false)

  const [
    isCreateSwishCertificateModalOpen,
    setIsCreateSwishCertificateModalOpen
  ] = useState(false)
  const [certificateFile, setCertificateFile] = useState(null)
  const [certificateFileError, setCertificateFileError] = useState(null)
  const [certificatePassword, setCertificatePassword] = useState('')
  const [isCreatingCertificate, setIsCreatingCertificate] = useState(false)

  const [certificate, setCertificate] = useState(null)
  const [isSavingCertificate, setIsSavingCertificate] = useState(false)

  const [netsError, setNetsError] = useState(null)
  const [netsData, setNetsData] = useState({})
  const [pendingNetsData, setPendingNetsData] = useState({})
  const [isSavingNetsData, setIsSavingNetsData] = useState(false)

  const [notificationsError, setNotificationsError] = useState(null)
  const [notificationsData, setNotificationsData] = useState({})
  const [pendingNotificationsData, setPendingNotificationsData] = useState({})
  const [isSavingNotificationsData, setIsSavingNotificationsData] =
    useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const { data: certificates } = await axios.get(
          `/v1/organisations/${user.organisation.id}/certificates?type=${CertificateTypes.SWISH}`
        )
        if (certificates.length > 0) {
          setCertificate(certificates[0])
        }
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setCertificateError(global._(msg))
      }

      /* load notifications data */
      try {
        const { data: organisation } = await axios.get(
          `/v1/organisations/${user.organisation.id}?view=OrderSettings`
        )
        setNotificationsData({
          orderEmail: organisation.orderEmail
        })
        setNetsData({
          netsCheckoutKey:
            organisation.attributes.find(attr => attr.key === 'netsCheckoutKey')
              ?.value || '',
          netsSecretKey:
            organisation.attributes.find(attr => attr.key === 'netsSecretKey')
              ?.value || '',
          netsTestCheckoutKey:
            organisation.attributes.find(
              attr => attr.key === 'netsTestCheckoutKey'
            )?.value || '',
          netsTestSecretKey:
            organisation.attributes.find(
              attr => attr.key === 'netsTestSecretKey'
            )?.value || '',
          netsEnvironment:
            organisation.attributes.find(attr => attr.key === 'netsEnvironment')
              ?.value || 'test'
        })
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setNotificationsError(global._(msg))
      }
    })()
  }, [])

  const handleCreateSwishCertificate = async () => {
    setCertificateFileError(null)
    setIsCreatingCertificate(true)
    try {
      const { data } = await axios.post(
        `/v1/organisations/${user.organisation.id}/certificates`,
        {
          type: CertificateTypes.SWISH,
          file: certificateFile,
          password: certificatePassword
        }
      )

      setCertificate(data)
      setCertificateFile(null)
      setCertificatePassword('')
      setIsCreateSwishCertificateModalOpen(false)
      setSaved(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setCertificateFileError(global._(msg))
    }
    setIsCreatingCertificate(false)
  }

  const handleDeleteSwishCertificate = async () => {
    setCertificateError(null)
    setIsSavingCertificate(true)
    setSaved(false)
    try {
      await axios.delete(
        `/v1/organisations/${user.organisation.id}/certificates/${certificate.id}`
      )
      setCertificate(null)
      setIsDeleteSwishCertificateModalOpen(false)
      setSaved(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setCertificateError(global._(msg))
    }
    setIsSavingCertificate(false)
  }

  const handleSaveNetsData = async () => {
    setNetsError(null)
    setIsSavingNetsData(true)
    setSaved(false)
    try {
      const data = {
        ...netsData,
        ...pendingNetsData
      }
      await axios.patch(`/v1/organisations/${user.organisation.id}`, data)
      setNetsData(data)
      setPendingNetsData({})
      setSaved(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setNetsError(global._(msg))
    }
    setIsSavingNetsData(false)
  }

  const handleSaveNotificationsData = async () => {
    setIsSavingNotificationsData(true)
    setSaved(false)
    try {
      await axios.patch(
        `/v1/organisations/${user.organisation.id}`,
        pendingNotificationsData
      )
      setNotificationsData({
        ...notificationsData,
        ...pendingNotificationsData
      })
      setPendingNotificationsData({})
      setSaved(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setNotificationsError(global._(msg))
    }
    setIsSavingNotificationsData(false)
  }

  const tabNavigationItems = [
    {
      label: global._('Admin.Settings.Orders.Navigation.Products'),
      icon: 'local_offer',
      value: Navigation.PRODUCTS,
      key: 'products'
    },
    {
      label: global._('Admin.Settings.Orders.Navigation.ShippingOptions'),
      icon: 'local_shipping',
      value: Navigation.SHIPPING,
      key: 'shipping'
    }
  ]

  if (user.organisation.country === Country.NO) {
    tabNavigationItems.push({
      label: global._('Admin.Settings.Orders.Navigation.Nets'),
      icon: 'payment',
      value: Navigation.NETS,
      key: 'nets'
    })
  } else {
    tabNavigationItems.push({
      label: global._('Admin.Settings.Orders.Navigation.Swish'),
      icon: 'attach_money',
      value: Navigation.SWISH,
      key: 'swish'
    })
  }

  tabNavigationItems.push({
    label: global._('Admin.Settings.Orders.Navigation.Notifications'),
    icon: 'notifications',
    value: Navigation.NOTIFICATIONS,
    key: 'notifications'
  })

  let navigationContent
  if (navigation === Navigation.PRODUCTS) {
    navigationContent = (
      <Column align='flex-start' style={{ margin: '-20px' }}>
        <Alert style={{ width: 'calc(100% - 40px)', margin: 20 }}>
          {global._('Admin.Settings.Orders.ProductsInformationAlert')}
        </Alert>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid var(--color-divider-light)'
          }}
        />
        <Products organisationId={user.organisation.id} />
      </Column>
    )
  } else if (navigation === Navigation.SHIPPING) {
    navigationContent = (
      <Column align='flex-start' style={{ margin: '-20px' }}>
        <Alert style={{ width: 'calc(100% - 40px)', margin: 20 }}>
          {global._('Admin.Settings.Orders.ShippingInformationAlert')}
        </Alert>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid var(--color-divider-light)'
          }}
        />
        <ShippingOptions organisationId={user.organisation.id} />
      </Column>
    )
  } else if (navigation === Navigation.SWISH) {
    navigationContent = (
      <Column align='flex-start'>
        {certificateError && (
          <Notification type='error' message={certificateError} />
        )}
        <Typography bold variant='title'>
          {global._('Admin.Settings.Orders.Certificate')}
        </Typography>
        {!certificate && (
          <>
            <Alert style={{ width: '100%', marginTop: 20 }}>
              {global._('Admin.Settings.Orders.CertificateRequiredAlert')}
            </Alert>
            {/* <Row style={{ margin: '20px 0' }}>
              <Icon style={{ fontSize: 30, marginRight: 6 }}>play_circle_filled</Icon>
              <Typography bold variant='body'>Visa instruktioner hur certifikatet kan hämtas från Swish</Typography>
            </Row> */}
          </>
        )}
        {certificate && (
          <Column align='flex-start' style={{ width: '100%' }}>
            <Alert style={{ width: '100%', margin: '20px 0' }}>
              {global._('Admin.Settings.Orders.CertificateExpirationNotice')}
            </Alert>
            <Row style={{ marginBottom: 5, width: '100%' }}>
              <Typography style={{ width: 150 }} bold variant='body'>
                {global._('Admin.Settings.Orders.ValidTo')}
              </Typography>
              <Typography variant='body' style={{ whiteSpace: 'nowrap' }}>
                {moment(certificate.notAfter).calendar()}
              </Typography>
            </Row>
            <Row style={{ marginBottom: 20, width: '100%' }}>
              <Typography style={{ width: 150 }} bold variant='body'>
                {global._('Admin.Settings.Orders.SwishNumber')}
              </Typography>
              <Typography variant='body'>{certificate.commonName}</Typography>
            </Row>
            <ItemBadge
              containerStyle={{
                marginBottom: 20,
                maxWidth: '50%'
              }}
              icon='verified_user'
              label={certificate.name}
              onDelete={() => setIsDeleteSwishCertificateModalOpen(true)}
            />
          </Column>
        )}
        <Button
          variant='primary'
          style={{ marginTop: 20, marginBottom: 10 }}
          loading={isSavingCertificate}
          disabled={isSavingCertificate}
          onClick={() => setIsCreateSwishCertificateModalOpen(true)}
        >
          {global._(certificate ? 'Common.Update' : 'Common.Add')}
        </Button>
        {isCreateSwishCertificateModalOpen && (
          <Modal
            onClose={() => setIsCreateSwishCertificateModalOpen(false)}
            title={global._(
              'Admin.Settings.Orders.CreateSwishCertificateModalTitle'
            )}
            content={
              <Column
                align={certificateFile ? 'flex-start' : 'center'}
                style={{ paddingTop: certificateFile ? 0 : 20 }}
              >
                {!certificateFile && (
                  <Typography variant='body'>
                    {global._(
                      'Admin.Settings.Orders.CreateSwishCertificateModalDescription'
                    )}
                  </Typography>
                )}
                {certificateFile && (
                  <ItemBadge
                    icon='verified_user'
                    label={certificateFile.name}
                    onDelete={() => {
                      setCertificateFile(null)
                      setCertificatePassword('')
                    }}
                  />
                )}
                <div style={{ height: 20 }} />
                <DragZone
                  buttonOnly
                  buttonVariant={certificateFile ? 'outlined' : 'primary'}
                  allowedTypes={[
                    'application/pkcs-12',
                    'application/x-pkcs12' /* , 'application/x-pem-file', 'application/x-x509-ca-cert' */
                  ]}
                  onFileSelected={(file, err) => {
                    if (err) {
                      if (err === 'ERR_FILE_TYPE') {
                        setCertificateFileError(
                          global._(
                            'Admin.Settings.Orders.SwishCertificateFileTypeError'
                          )
                        )
                      }
                    } else {
                      setCertificateFileError(null)
                      setCertificateFile(file)
                    }
                  }}
                />
                {certificateFile &&
                  ['application/pkcs-12', 'application/x-pkcs12'].includes(
                    certificateFile.type
                  ) && (
                    <TextField
                      variant='filled'
                      style={{ width: '100%', marginTop: 20 }}
                      label={global._('Common.Password')}
                      value={certificatePassword}
                      onChange={e => setCertificatePassword(e.target.value)}
                      helperText={global._('Common.Required')}
                    />
                  )}
                {certificateFileError && (
                  <Notification
                    style={{ margin: '20px 0' }}
                    type='error'
                    message={certificateFileError}
                  />
                )}
              </Column>
            }
            buttons={[
              <Button
                variant='none'
                disabled={isCreatingCertificate}
                onClick={() => setIsCreateSwishCertificateModalOpen(false)}
              >
                {global._('Common.Cancel')}
              </Button>,
              <Button
                variant='primary'
                loading={isCreatingCertificate}
                disabled={
                  !certificateFile ||
                  (['application/pkcs-12', 'application/x-pkcs12'].includes(
                    certificateFile.type
                  ) &&
                    !certificatePassword) ||
                  isCreatingCertificate
                }
                onClick={() => handleCreateSwishCertificate()}
              >
                {global._('Common.Add')}
              </Button>
            ]}
          />
        )}
      </Column>
    )
  } else if (navigation === Navigation.NETS) {
    const netsEnvironment =
      pendingNetsData.netsEnvironment || netsData.netsEnvironment
    const netsSecretKey =
      pendingNetsData.netsSecretKey || netsData.netsSecretKey
    const netsCheckoutKey =
      pendingNetsData.netsCheckoutKey || netsData.netsCheckoutKey

    const netsTestSecretKey =
      pendingNetsData.netsTestSecretKey || netsData.netsTestSecretKey
    const netsTestCheckoutKey =
      pendingNetsData.netsTestCheckoutKey || netsData.netsTestCheckoutKey
    let secretKeyError
    if (netsSecretKey && !netsSecretKey.startsWith('live-secret-key-')) {
      secretKeyError = global._('Admin.Settings.Orders.Nets.InvalidKey')
    }
    let checkoutKeyError
    if (netsCheckoutKey && !netsCheckoutKey.startsWith('live-checkout-key-')) {
      checkoutKeyError = global._('Admin.Settings.Orders.Nets.InvalidKey')
    }

    let secretTestKeyError
    if (
      netsTestSecretKey &&
      !netsTestSecretKey.startsWith('test-secret-key-')
    ) {
      secretTestKeyError = global._('Admin.Settings.Orders.Nets.InvalidKey')
    }
    let checkoutTestKeyError
    if (
      netsTestCheckoutKey &&
      !netsTestCheckoutKey.startsWith('test-checkout-key-')
    ) {
      checkoutKeyError = global._('Admin.Settings.Orders.Nets.InvalidKey')
    }
    navigationContent = (
      <Column align='flex-start' style={{ margin: '-20px' }}>
        {netsError && <Notification type='error' message={netsError} />}
        <div
          style={{
            width: '100%',
            padding: '20px',
            borderBottom: '1px solid var(--color-divider-light)'
          }}
        >
          <Column align='flex-start'>
            <Typography bold variant='title' style={{ marginBottom: 18 }}>
              {global._('Admin.Settings.Orders.Nets')}
            </Typography>
            <Alert
              style={{ width: '100%' }}
              dangerouslySetInnerHTML={{
                __html: global._(
                  'Admin.Settings.Orders.Nets.IntegrationHelpText'
                )
              }}
            />
            <Row style={{ margin: '24px 0 10px' }}>
              <FormControl component='fieldset' fullWidth>
                <RadioGroup
                  name='holderType'
                  onChange={e => {
                    setPendingNetsData({
                      ...pendingNetsData,
                      netsEnvironment: e.target.value
                    })
                  }}
                  value={netsEnvironment === 'live' ? 'live' : 'test'}
                  style={{ flexDirection: 'row' }}
                >
                  <FormControlLabel
                    value='live'
                    labelPlacement='start'
                    control={<Radio style={{ color: 'var(--color-black)' }} />}
                    label={global._('Admin.Settings.Orders.Nets.EnableLive')}
                    className={classes.singleRadioButton}
                  />
                  <FormControlLabel
                    value='test'
                    labelPlacement='start'
                    control={<Radio style={{ color: 'var(--color-black)' }} />}
                    label={global._('Admin.Settings.Orders.Nets.EnableTest')}
                    className={classes.singleRadioButton}
                  />
                </RadioGroup>
              </FormControl>
            </Row>
          </Column>
        </div>
        <div
          style={{
            width: '100%',
            padding: '20px 20px 40px',
            borderBottom: '1px solid var(--color-divider-light)'
          }}
        >
          <Column align='flex-start' style={{ width: '100%' }}>
            <Typography bold variant='subtitle'>
              {global._(`Admin.Settings.Orders.Nets.EnvironmentLive`)}
            </Typography>
            <Row style={{ width: '100%' }}>
              <TextField
                variant='filled'
                style={{
                  width: 'calc(50% - 10px)',
                  marginTop: 16,
                  marginRight: 10
                }}
                label={global._('Admin.Settings.Orders.Nets.SecretKey')}
                value={netsSecretKey || ''}
                error={!!secretKeyError}
                helperText={secretKeyError}
                onChange={e =>
                  setPendingNetsData({
                    ...pendingNetsData,
                    netsSecretKey: e.target.value
                  })
                }
              />
              <TextField
                variant='filled'
                style={{
                  width: 'calc(50% - 10px)',
                  marginTop: 16,
                  marginLeft: 10
                }}
                label={global._('Admin.Settings.Orders.Nets.CheckoutKey')}
                value={netsCheckoutKey || ''}
                error={!!checkoutKeyError}
                helperText={checkoutKeyError}
                onChange={e =>
                  setPendingNetsData({
                    ...pendingNetsData,
                    netsCheckoutKey: e.target.value
                  })
                }
              />
            </Row>
          </Column>
        </div>
        <div
          style={{
            width: '100%',
            padding: '20px 20px 40px',
            borderBottom: '1px solid var(--color-divider-light)'
          }}
        >
          <Column align='flex-start' style={{ width: '100%' }}>
            <Typography bold variant='subtitle'>
              {global._(`Admin.Settings.Orders.Nets.EnvironmentTest`)}
            </Typography>
            <Row style={{ width: '100%' }}>
              <TextField
                variant='filled'
                style={{
                  width: 'calc(50% - 10px)',
                  marginTop: 16,
                  marginRight: 10
                }}
                label={global._('Admin.Settings.Orders.Nets.SecretKey')}
                value={netsTestSecretKey || ''}
                error={!!secretTestKeyError}
                helperText={secretTestKeyError}
                onChange={e =>
                  setPendingNetsData({
                    ...pendingNetsData,
                    netsTestSecretKey: e.target.value
                  })
                }
              />
              <TextField
                variant='filled'
                style={{
                  width: 'calc(50% - 10px)',
                  marginTop: 16,
                  marginLeft: 10
                }}
                label={global._('Admin.Settings.Orders.Nets.CheckoutKey')}
                value={netsTestCheckoutKey || ''}
                error={!!checkoutTestKeyError}
                helperText={checkoutTestKeyError}
                onChange={e =>
                  setPendingNetsData({
                    ...pendingNetsData,
                    netsTestCheckoutKey: e.target.value
                  })
                }
              />
            </Row>
          </Column>
        </div>
        <Row justify='flex-end' style={{ width: '100%', padding: '20px' }}>
          <Button
            variant='primary'
            style={{ paddingLeft: 48, paddingRight: 48 }}
            loading={isSavingNetsData}
            disabled={
              Object.keys(pendingNetsData).length === 0 ||
              secretKeyError ||
              checkoutKeyError
            }
            onClick={handleSaveNetsData}
          >
            {global._('Common.Save')}
          </Button>
        </Row>
      </Column>
    )
  } else if (navigation === Navigation.NOTIFICATIONS) {
    let orderEmail = ''
    if (typeof pendingNotificationsData.orderEmail !== 'undefined') {
      ;({ orderEmail } = pendingNotificationsData)
    } else if (typeof notificationsData.orderEmail !== 'undefined') {
      ;({ orderEmail } = notificationsData)
    }
    navigationContent = (
      <Column align='flex-start' style={{ margin: '-20px' }}>
        {notificationsError && (
          <Notification type='error' message={notificationsError} />
        )}
        <div
          style={{
            width: '100%',
            padding: '20px',
            borderBottom: '1px solid var(--color-divider-light'
          }}
        >
          <Typography bold variant='title'>
            {global._('Admin.Settings.Orders.OrderConfirmationEmail')}
          </Typography>
          <TextField
            variant='filled'
            style={{ width: '50%', marginTop: 16 }}
            label={global._('Admin.Settings.Orders.OrderEmail')}
            value={orderEmail}
            onChange={e =>
              setPendingNotificationsData({
                ...pendingNotificationsData,
                orderEmail: e.target.value
              })
            }
            helperText={global._('Common.Required')}
          />
        </div>
        <Row justify='flex-end' style={{ width: '100%', padding: '20px' }}>
          <Button
            variant='primary'
            style={{ paddingLeft: 48, paddingRight: 48 }}
            loading={isSavingNotificationsData}
            disabled={Object.keys(pendingNotificationsData).length === 0}
            onClick={handleSaveNotificationsData}
          >
            {global._('Common.Save')}
          </Button>
        </Row>
      </Column>
    )
  }

  const Content = (
    <>
      {isDeleteSwishCertificateModalOpen && (
        <Modal
          onClose={() => setIsDeleteSwishCertificateModalOpen(false)}
          title={global._(
            'Admin.Settings.Orders.DeleteSwishCertificateModalTitle'
          )}
          content={
            <div style={{ textAlign: 'left' }}>
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='body'
              >
                {global._(
                  'Admin.Settings.Orders.DeleteSwishCertificateModalDescription'
                )}
              </Typography>
              <Typography
                style={{
                  display: 'block',
                  paddingBottom: 12
                }}
                variant='subtitleSmall'
              >
                {certificate && certificate.name}
              </Typography>
              {certificateError && (
                <Notification type='error' message={certificateError} />
              )}
            </div>
          }
          buttons={
            <>
              <Button
                variant='none'
                onClick={() => setIsDeleteSwishCertificateModalOpen(false)}
              >
                {global._('Common.Cancel')}
              </Button>
              <Button
                variant='secondary'
                onClick={() => handleDeleteSwishCertificate()}
              >
                {global._('Delete')}
              </Button>
            </>
          }
        />
      )}
      {navigationContent}
    </>
  )

  return (
    <>
      <PageContentHeader breadcrumbItems={breadcrumbItems} />
      <Paper noPadding>
        {[
          UserRoles.SystemAdmin,
          UserRoles.PartnerAdmin,
          UserRoles.CustomerAdmin,
          UserRoles.Support
        ].some(r => user.roles.includes(r)) && (
          <TabNavigator
            items={tabNavigationItems}
            value={navigation}
            onChange={navigationItem => history.replace(navigationItem.value)}
            itemStyle={{ padding: '8px 12px' }}
          />
        )}
        <div style={{ padding: '20px', width: '100%' }}>{Content}</div>
      </Paper>
      {saved && <SuccessMessage subtitle={global._('Common.ChangesSaved')} />}
    </>
  )
}

SettingsOrders.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired
}

export default injectSheet(styles)(SettingsOrders)
