/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import ExpectedReturnDate from 'src/components/ExpectedReturnDate'
import axios from 'axios'
import useKeyLimitValidator from 'src/utils/validators/useKeyLimitValidator'
import { UserRoles } from '../../../utils/constants'
import AsyncSelect from '../../../components/AsyncSelect'
import ItemBadge from '../../../components/ItemBadge'
import Typography from '../../../components/Typography'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'

const styles = {
  root: {},
  singleRadioButton: {
    borderBottom: '1px solid var(--color-divider-light)',
    marginRight: '30px !important',
    width: 160,
    justifyContent: 'space-between',
    '&:last-child': {
      marginRight: '0 !important'
    }
  }
}

const HolderModal = ({
  classes,
  setShowHolderModal,
  holder,
  setTempHolder,
  setTempReceiptRequest,
  setFieldValue,
  values,
  tempHolder,
  setHolder,
  handleKeySave,
  selectedOrg,
  setReceiptRequest,
  newKey,
  setPendingHolder,
  pendingHolder,
  setPendingReceiptRequest,
  isLoading,
  untilFurtherNotice,
  setUntilFurtherNotice,
  expectedReturnDate,
  setExpectedReturnDate
}) => {
  const { isValidated, isKeyLimitReached } = useKeyLimitValidator({
    user: tempHolder?.type === 'user' ? tempHolder.item : null,
    apartment: tempHolder?.type === 'apartment' ? tempHolder.item : null,
    organisation: selectedOrg
  })

  let editingHolder = holder

  if (pendingHolder) {
    editingHolder = pendingHolder
  }

  const currentHolderItem = tempHolder || editingHolder

  const holderObj = {
    infoText: global._(`Keys.New.Holder.types.${values.holderType}.info`),
    searchFieldKey: `search-${values.holderType}`,
    helpText: global._(`Keys.New.Holder.types.${values.holderType}.helpText`),
    placeholder: global._(
      `Keys.New.Holder.types.${values.holderType}.placeholder`
    ),
    asyncSelectUrl: {
      user: `/v1/organisations/${selectedOrg.id}/users?orderBy=createdAt&order=desc&search={{input}}&activeOnly=true`,
      repository: `/v1/organisations/${selectedOrg.id}/keystorages?orderBy=name&order=asc&search={{input}}`,
      apartment: `/v1/apartments?orderBy=refId&order=asc&search={{input}}&organisationId=${selectedOrg.id}`
    },
    badgeIcon: {
      user: 'face',
      repository: 'archive',
      apartment: 'location_city'
    },
    badgeLabel: {
      user: item => `${item.name || global._('Common.NoName')} (${item.email})`,
      repository: item => item.name || global._('Common.NoName'),
      apartment: item => item.refId || global._('Common.NoName')
    }
  }

  return (
    <Modal
      onClose={() => setShowHolderModal(false)}
      title={
        editingHolder
          ? global._('Keys.New.Holder.ChangeHolder')
          : global._('Keys.New.Holder.AddHolder')
      }
      content={
        <div>
          <FormControl
            component='fieldset'
            fullWidth
            style={{ marginBottom: 20 }}
          >
            <RadioGroup
              name='holderType'
              onChange={e => {
                setTempHolder(null)
                setTempReceiptRequest(null)
                setFieldValue('holderType', e.target.value)
              }}
              value={values.holderType}
              style={{ flexDirection: 'row' }}
            >
              <FormControlLabel
                value='user'
                labelPlacement='start'
                control={<Radio style={{ color: 'var(--color-black)' }} />}
                label={global._('Keys.New.Holder.Person')}
                className={classes.singleRadioButton}
              />
              <FormControlLabel
                value='repository'
                labelPlacement='start'
                control={<Radio style={{ color: 'var(--color-black)' }} />}
                label={global._('Keys.New.Holder.Repository')}
                className={classes.singleRadioButton}
              />
              <FormControlLabel
                value='apartment'
                labelPlacement='start'
                control={<Radio style={{ color: 'var(--color-black)' }} />}
                label={global._('Keys.New.Holder.Apartment')}
                className={classes.singleRadioButton}
              />
            </RadioGroup>
          </FormControl>
          <Typography variant='body' italic>
            {holderObj.infoText}
          </Typography>
          <AsyncSelect
            isSearchable
            key={holderObj.searchFieldKey}
            dropdownIcon='search'
            style={{ marginTop: 20 }}
            helpText={holderObj.helpText}
            value={values.user}
            label={obj => holderObj.badgeLabel[values.holderType](obj)}
            // Don't include users that only have the GUEST role
            filter={objects => {
              const filtered = objects.filter(
                obj =>
                  !obj.roles ||
                  !obj.roles.includes(UserRoles.CustomerAdminGuest)
              )
              return filtered
            }}
            labelKey='name'
            placeholder={holderObj.placeholder}
            onChange={e => {
              setTempHolder(
                {
                  item: e.value,
                  type: values.holderType
                } || null
              )
            }}
            url={holderObj.asyncSelectUrl[values.holderType]}
          />
          {((tempHolder && tempHolder.type === values.holderType) ||
            (editingHolder && editingHolder.type === values.holderType)) && (
            <ItemBadge
              onDelete={() => {
                setTempHolder(null)
                setHolder(null)
                setPendingHolder(null)
                setTempReceiptRequest(null)
                setReceiptRequest(null)
              }}
              label={holderObj.badgeLabel[values.holderType](
                currentHolderItem.item
              )}
              icon={holderObj.badgeIcon[values.holderType]}
              containerStyle={{ margin: '20px 0 8px 0' }}
            />
          )}
          {isValidated && isKeyLimitReached && (
            <Typography
              variant='body2'
              style={{
                marginLeft: 20,
                color: 'var(--color-error)'
              }}
            >
              {global._(
                `Keys.Settings.MaxNumberOfKeysReached.${tempHolder?.type}`
              )}
            </Typography>
          )}
          {values.holderType === 'user' && !newKey && (
            <ExpectedReturnDate
              setUntilFurtherNotice={setUntilFurtherNotice}
              setExpectedReturnDate={setExpectedReturnDate}
              expectedReturnDate={expectedReturnDate}
              untilFurtherNotice={untilFurtherNotice}
            />
          )}
        </div>
      }
      buttons={[
        <Button
          variant='none'
          onClick={() => {
            setTempHolder(null)
            setShowHolderModal(false)
            setExpectedReturnDate(null)
            setUntilFurtherNotice(null)
          }}
        >
          {global._('Common.Cancel')}
        </Button>,
        <Button
          variant='primary'
          loading={isLoading}
          disabled={
            !isValidated ||
            isKeyLimitReached ||
            !tempHolder ||
            (values &&
              values.holderType === 'user' &&
              !newKey &&
              !(untilFurtherNotice || expectedReturnDate))
          }
          onClick={() => {
            if (!newKey && tempHolder.type === 'user') {
              if (pendingHolder) {
                setPendingHolder(tempHolder)
                setPendingReceiptRequest(null)
              } else {
                setHolder(tempHolder)
                setReceiptRequest(null)
              }
            }

            if (
              !newKey &&
              tempHolder &&
              ['repository', 'apartment'].includes(tempHolder.type)
            ) {
              // Existing key - Save key when changing the holder to repository
              handleKeySave({
                userId: null,
                storageId:
                  tempHolder.type === 'repository' ? tempHolder.item.id : null,
                apartmentId:
                  tempHolder.type === 'apartment' ? tempHolder.item.id : null
              })
            } else {
              setShowHolderModal(false)
              setTempReceiptRequest(null)
            }

            if (newKey) {
              setHolder(tempHolder)
            }
          }}
        >
          {editingHolder ? global._('Common.Modify') : global._('Common.Add')}
        </Button>
      ]}
    />
  )
}

HolderModal.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.any,
  newKey: PropTypes.bool,
  handleKeySave: PropTypes.func.isRequired,
  setShowHolderModal: PropTypes.func.isRequired,
  holder: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  setTempHolder: PropTypes.func.isRequired,
  setTempReceiptRequest: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  tempHolder: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  setHolder: PropTypes.func.isRequired,
  selectedOrg: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  setReceiptRequest: PropTypes.func.isRequired,
  setPendingHolder: PropTypes.func.isRequired,
  setPendingReceiptRequest: PropTypes.func.isRequired,
  pendingHolder: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isLoading: PropTypes.bool,
  untilFurtherNotice: PropTypes.bool,
  setUntilFurtherNotice: PropTypes.func.isRequired,
  expectedReturnDate: PropTypes.string,
  setExpectedReturnDate: PropTypes.func.isRequired
}

HolderModal.defaultProps = {
  untilFurtherNotice: null,
  expectedReturnDate: null
}

const HolderModalWithStyles = injectSheet(styles)(HolderModal)
export default HolderModalWithStyles
