import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import clx from 'classnames'
import useLogotype from '../utils/useLogotype'
import withUser from '../utils/withUser'

const styles = {
  img: {
    width: '100%',
    maxWidth: 130,
    display: 'none'
  },
  grayFilter: {
    filter: 'grayscale(100%)'
  },
  '@media (max-width: 900px)': {
    img: {
      display: 'initial'
    }
  }
}
const defaultLogo = '/logos/logo-text-512.png'
const MobileLogo = ({ classes, user, alt, defaultLogotype }) => {
  const { getSrc } = useLogotype(defaultLogotype ? null : user, defaultLogo)
  return (
    <img
      src={getSrc()}
      className={clx(
        classes.img,
        getSrc() !== defaultLogo ? classes.grayFilter : undefined
      )}
      alt={alt}
    />
  )
}

MobileLogo.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  alt: PropTypes.string,
  defaultLogotype: PropTypes.bool
}

MobileLogo.defaultProps = {
  user: null,
  alt: 'logotype',
  defaultLogotype: false
}

const MobileLogoWithStyles = injectSheet(styles)(MobileLogo)
const MobileLogoWithStylesAndUser = withUser(MobileLogoWithStyles)
export default MobileLogoWithStylesAndUser
