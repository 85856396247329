import React from 'react'
import injectSheet from 'react-jss'
import BlueprintWizard from '../../components/BlueprintWizard/Container'

const styles = {
  root: {
    display: 'flex',
    backgroundColor: '#fff',
    minHeight: '100%',
    overflowY: 'auto'
  }
}

const BlueprintWizardContainer = props => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <BlueprintWizard {...props} />
    </div>
  )
}

const BlueprintWizardContainerWithStyles = injectSheet(styles)(
  BlueprintWizardContainer
)
export default BlueprintWizardContainerWithStyles
