import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import WarningIcon from '@material-ui/icons/WarningRounded'
import SuccessIcon from '@material-ui/icons/CheckCircleRounded'
import InformationIcon from '@material-ui/icons/Info'

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 15,
    color: '#4d4d4d'
  },
  error: {
    backgroundColor: '#fff6f6'
  },
  success: {
    backgroundColor: '#F6FFF6'
  },
  information: {
    backgroundColor: '#bbdbf7'
  },
  iconContainer: {
    width: '40px!important',
    height: '40px!important',
    minWidth: 40,
    minHeight: 40,
    maxWidth: 40,
    maxHeight: 40,
    borderRadius: 20,
    marginRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconContainerError: {
    backgroundColor: '#FF7166'
  },
  iconContainerSuccess: {
    backgroundColor: '#31D878'
  },
  iconContainerInformation: {
    backgroundColor: '#58a7ed'
  },
  icon: {
    fontSize: 22,
    color: '#fff'
  },
  iconError: {
    marginBottom: 4
  },
  message: {
    fontSize: 13,
    margin: 0,
    textAlign: 'left',
    wordBreak: 'break-word'
  }
}

const Notification = props => {
  const { classes, type, message, modal, ...rest } = props

  const isError = type === 'error'
  const isSuccess = type === 'success'
  const isInformation = type === 'information'
  let Icon
  if (isError) {
    Icon = WarningIcon
  } else if (isSuccess) {
    Icon = SuccessIcon
  } else if (isInformation) {
    Icon = InformationIcon
  }

  const rootClasses = classnames(classes.root, {
    [classes.error]: isError,
    [classes.success]: isSuccess,
    [classes.information]: isInformation
  })

  const iconContainerClasses = classnames(classes.iconContainer, {
    [classes.iconContainerError]: isError,
    [classes.iconContainerSuccess]: isSuccess,
    [classes.iconContainerInformation]: isInformation
  })

  const iconClasses = classnames(classes.icon, {
    [classes.iconError]: isError,
    [classes.iconSuccess]: isSuccess,
    [classes.iconInformation]: isInformation
  })

  return (
    <div className={rootClasses} {...rest}>
      <div className={iconContainerClasses}>
        <Icon className={iconClasses} />
      </div>
      <p className={classes.message}>{message}</p>
    </div>
  )
}

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['error', 'success']).isRequired,
  message: PropTypes.string.isRequired,
  modal: PropTypes.bool
}

Notification.defaultProps = {
  modal: false
}

export default injectSheet(styles)(Notification)
