import { connect } from 'react-redux'
import {
  toggle,
  handleChange,
  handleFilter,
  fetchAll,
  fetchOrganisations,
  create,
  update,
  remove,
  impersonate,
  resendInvitationLink
} from '../../redux/reducers/users/list'
import Users from './UserList'

const mapStateToProps = state => ({
  ...state.userList
})

const mapDispatchToProps = dispatch => ({
  toggle: user => {
    dispatch(toggle(user))
  },
  handleChange: user => {
    dispatch(handleChange(user))
  },
  handleFilter: filter => {
    dispatch(handleFilter(filter))
  },
  fetchAll: filter => {
    dispatch(fetchAll(filter))
  },
  fetchOrganisations: () => {
    dispatch(fetchOrganisations())
  },
  create: user => {
    dispatch(create(user))
  },
  update: user => {
    dispatch(update(user))
  },
  remove: id => {
    dispatch(remove(id))
  },
  impersonate: id => {
    dispatch(impersonate(id))
  },
  resendInvitationLink: user => {
    dispatch(resendInvitationLink(user))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
