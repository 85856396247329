import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Grid, MenuItem, TextField, FormControl } from '@material-ui/core'
import SearchField from 'src/components/SearchField'
import Paper from 'src/components/Paper'
import AsyncSelect from 'src/components/AsyncSelect'
import {
  DocumentTypes,
  OrganisationServices,
  UserRoles
} from '../../utils/constants'

const styles = {
  root: {},
  input: {
    width: '100%'
  }
}

const Filter = ({ classes, user, handleFilter, filter, isMobile }) => {
  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)

  let Wrapper = Paper

  if (isMobile) {
    Wrapper = 'div'
  }

  return (
    <Wrapper
      smallerPadding
      style={{
        marginBottom: !isMobile && 20,
        padding: isMobile && 'var(--section-padding-default)'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <SearchField
              data-cy='filter-input-textfield-document-name'
              style={{ width: '100%' }}
              name='document-search'
              label={global._('Search')}
              variant='filled'
              value={filter && filter.query}
              onChange={handleFilter('query')}
              className={classes.notchedOutline}
              helperText={global._('Filter.ByDocumentName')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            name='documentType'
            value={filter.type}
            label={global._('Documents.Type')}
            variant='filled'
            className={classes.input}
            onChange={handleFilter('type')}
            helperText={global._('Filter.ByDocumentType')}
            select
          >
            <MenuItem value=''>
              {global._('Documents.AllDocumentTypes')}
            </MenuItem>
            {DocumentTypes &&
              Object.keys(DocumentTypes).map(type => (
                <MenuItem
                  value={DocumentTypes[type]}
                  key={`document-types-${DocumentTypes[type]}`}
                >
                  {global._(`Document.Types.${DocumentTypes[type]}`)}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        {(isPartnerAdmin || isSupport) && (
          <Grid item xs={12} md={3}>
            <AsyncSelect
              isSearchable
              helpText={global._('Common.Required')}
              preselectedId={filter.organisationId}
              value={filter.organisation}
              labelKey='name'
              name='organisation'
              scope={OrganisationServices.DOCUMENTS}
              placeholder={global._('Common.SelectOrganisation')}
              onChange={handleFilter('organisation')}
              url='/v1/organisations?search={{input}}&orderBy=name&order=asc&ifHas=documents'
            />
          </Grid>
        )}
      </Grid>
    </Wrapper>
  )
}

Filter.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  handleFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
}

Filter.defaultProps = {}

const FilterWithStyles = injectSheet(styles)(Filter)
export default FilterWithStyles
