import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import { AttributeFieldTypes } from '../../../utils/constants'

const styles = () => ({
  radioButtonLabel: {
    borderBottom: '1px solid var(--divider-color)',
    marginRight: '70px !important',
    minWidth: 160,
    justifyContent: 'space-between'
  }
})

/* Generic builder */
const AttributeField = ({ field, value, onChange, classes }) => {
  const extraProps = {}
  switch (field.type) {
    case AttributeFieldTypes.MultilineInput:
      extraProps.multiline = true
      extraProps.rowsMax = '4'
      break
    case AttributeFieldTypes.Dropdown:
      extraProps.select = true
      break
    case AttributeFieldTypes.Radio:
      break
    default:
      throw new TypeError('Attribute field type is invalid.')
  }

  let options
  if (field.values) {
    options = field.values.map(val => ({
      label: global._(`AttributeFields.Values.${field.key}.${val}`),
      value: val
    }))
  }

  let helpText
  if (field.helpText) {
    helpText = global._(`AttributeFields.HelpText.${field.key}`)
  } else if (field.required) {
    helpText = global._('Common.Required')
  }
  return (
    <>
      {field.type !== AttributeFieldTypes.Radio && (
        <FormControl fullWidth>
          <TextField
            data-cy={`input-attribute-field-${field.key}`}
            name={`${field.key}-select`}
            variant='filled'
            label={global._(`AttributeFields.Title.${field.key}`)}
            helptext={helpText}
            value={value}
            onChange={event => onChange(event.target)}
            {...extraProps}>
            {options &&
              options.map(item => (
                <MenuItem
                  key={`${field.key} - ${item.value} - option`}
                  value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
      )}
      {field.type === AttributeFieldTypes.Radio && (
        <FormControl fullWidth>
          <FormLabel component='legend'>
            {global._(`AttributeFields.Title.${field.key}`)}
          </FormLabel>
          <RadioGroup
            data-cy='radio-group-attribute-fields'
            name={`${field.key}-radio-group`}
            value={value}
            onChange={event => onChange(event.target)}
            row>
            {options &&
              options.map(item => (
                <FormControlLabel
                  className={classes.radioButtonLabel}
                  key={`${field.key}-${item.value}-radio-option`}
                  value={item.value}
                  control={<Radio style={{ color: 'var(--color-black)' }} />}
                  label={item.label}
                  labelPlacement='start'
                />
              ))}
          </RadioGroup>
          {helpText && <FormHelperText>{helpText}</FormHelperText>}
        </FormControl>
      )}
    </>
  )
}

AttributeField.propTypes = {
  classes: PropTypes.object,
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired
}

AttributeField.defaultProps = {
  classes: {},
  value: null
}

export default withStyles(styles)(AttributeField)
