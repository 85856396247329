import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import injectSheet from 'react-jss'
import { TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'

const styles = {}

const DEBOUNCE_MS = 400
const _SearchField = props => {
  const searchInputRef = useRef()
  const timeout = useRef(null)

  const [hasFocus, setFocus] = useState(false)
  const [value, setValue] = useState(props.value || '')

  useEffect(() => {
    setValue(props.value)
  }, [props.value])
  /* debounce handler */
  const onChange = event => {
    clearTimeout(timeout.current)
    setValue(event.target.value)
    if (typeof props.onChange === 'function') {
      timeout.current = setTimeout(() => {
        props.onChange({ target: searchInputRef.current })
      }, DEBOUNCE_MS)
    }
  }

  return (
    <TextField
      {...props}
      value={value || ''}
      onChange={onChange}
      inputRef={searchInputRef}
      InputLabelProps={{ shrink: !!value || hasFocus || false }}
      InputProps={{
        onFocus: () => setFocus(true),
        onBlur: () => setFocus(false),
        endAdornment: (
          <>
            {value && (
              <Icon
                fontSize='small'
                style={{ cursor: 'pointer', color: 'var(--color-dark-grey)' }}
                onClick={() => {
                  if (typeof props.onChange === 'function') {
                    props.onChange({ target: { value: '' } })
                  }
                  setValue('')
                }}
              >
                clear
              </Icon>
            )}
            <Search />
          </>
        )
      }}
    />
  )
}

_SearchField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

_SearchField.defaultProps = {
  value: null
}

const SearchField = injectSheet(styles)(_SearchField)
export default SearchField
