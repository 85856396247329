import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { Formik } from 'formik'
import { Form } from 'reactstrap'
import * as Yup from 'yup'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import PageContentHeader from '../../../components/PageContentHeader'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import Typography from '../../../components/Typography'
import Paper from '../../../components/Paper'
import LockDetailMobile from './LockDetailMobile'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import Notification from '../../../common/Notification'
import SuccessMessage from '../../../components/SuccessMessage'

const styles = {
  root: {},
  input: {
    width: '100%'
  },
  submitBtnContainer: {
    marginTop: 20
  }
}

const LockDetail = props => {
  const { classes, history, match, breadcrumbItems, signout } = props

  const { isMobile } = useWindowDimensions()

  const [lock, setLock] = useState({})
  const [device, setDevice] = useState({})
  const [availableRelays, setAvailableRelays] = useState([1, 2, 3, 4])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showDeleteLockModal, setShowDeleteLockModal] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const fetchLock = async () => {
    setIsLoading(true)
    try {
      const { data: lockData } = await axios.get(
        `/v1/organisations/${match.params.orgId}/locks/${match.params.id}`
      )
      setLock(lockData)

      setIsLoading(false)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
      setIsLoading(false)
    }
  }

  const fetchDevice = async () => {
    try {
      const { data: deviceData } = await axios.get(
        `/v1/devices/${lock.deviceId}`
      )

      setDevice(deviceData)

      if (deviceData) {
        const allDeviceLocks = deviceData.locks

        if (allDeviceLocks && allDeviceLocks.length > 0) {
          allDeviceLocks.map(deviceLock => {
            setAvailableRelays(prevRelays =>
              prevRelays.filter(
                relay => relay !== deviceLock.relay || relay === lock.relay
              )
            )
          })
        }
      }

      setIsLoading(false)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchLock()
  }, [])

  useEffect(() => {
    if (lock.id) {
      fetchDevice()
    }
  }, [lock])

  const handleDeleteLock = async () => {
    setError(null)
    setIsLoading(true)
    try {
      await axios.delete(
        `/v1/organisations/${match.params.orgId}/locks/${lock.id}`
      )

      history.push(`/admin/devices/${lock.deviceId}`)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(msg)
    }
    setIsLoading(false)
  }

  const handleSave = async ({ name, relay }) => {
    setError(null)

    if (lock && lock.id) {
      const lockObj = { ...lock }

      lockObj.name = name
      lockObj.relay = relay

      try {
        setShowSuccessMessage(false)
        await axios.patch(
          `/v1/organisations/${match.params.orgId}/locks/${lock.id}`,
          lockObj
        )
        setLock(lockObj)

        setShowSuccessMessage(true)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(msg)
      }
    } else {
      const msg = global._('Lock.LockNotFound')
      setError(msg)
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(global._('Common.Required'))
  })

  const lockForm = () => (
    <Formik
      enableReinitialize
      initialValues={{
        name: lock.name || '',
        deviceId: lock.deviceId || '',
        relay: lock.relay || ''
      }}
      validationSchema={validationSchema}>
      {({ errors, touched, handleChange, values, handleBlur, setTouched }) => (
        <Form style={{ width: '100%' }}>
          <Grid container spacing={3} style={{ marginBottom: 1 }}>
            <Grid item xs={12} md={4}>
              <TextField
                onChange={handleChange}
                error={errors.name}
                onClick={handleBlur}
                value={values.name}
                name='name'
                className={classes.input}
                label={global._('User.Name')}
                variant='filled'
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={values.deviceId}
                select
                disabled
                label={global._('Locks.Device')}
                className={classes.input}
                variant='filled'>
                <MenuItem value={device && device.id}>
                  {device && device.name}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                onChange={handleChange}
                error={errors.relay}
                onClick={handleBlur}
                value={values.relay}
                name='relay'
                select
                label={global._('Locks.RelayNumber')}
                helperText={global._('Locks.PickRelayNumber')}
                className={classes.input}
                variant='filled'>
                {availableRelays.map(relay => (
                  <MenuItem value={relay} key={`relay-${relay}`}>
                    {relay}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction='row-reverse'
            className={classes.submitBtnContainer}>
            <Button
              variant='primary'
              disabled={
                Object.keys(touched).length === 0 || Object.keys(errors).length
              }
              onClick={() => {
                handleSave(values)
                setTouched({})
              }}>
              {global._('Common.Save')}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )

  return (
    <>
      {showSuccessMessage ? (
        <SuccessMessage
          subtitle={global._('Common.YourChangesHaveBeenSaved')}
        />
      ) : null}
      {showDeleteLockModal && (
        <Modal
          onClose={() => setShowDeleteLockModal(false)}
          title={global._('Locks.DeleteLock')}
          content={
            <div style={{ textAlign: 'left' }}>
              <Typography variant='body' block style={{ marginBottom: 15 }}>
                {global._('Locks.DeleteLockModalConfirmText')}
              </Typography>
              <Typography
                variant='body'
                bold
                block
                style={{ marginBottom: 15 }}>
                {lock.name}
              </Typography>
              <Typography
                variant='body'
                italic
                block
                style={{ marginBottom: 30 }}>
                {global._('Locks.DeleteLockModalInfo')}
              </Typography>
            </div>
          }
          buttons={[
            <Button
              variant='none'
              onClick={() => setShowDeleteLockModal(false)}>
              {global._('Common.Cancel')}
            </Button>,
            <Button
              variant='secondary'
              onClick={() => {
                handleDeleteLock()
                setShowDeleteLockModal(false)
              }}>
              {global._('Delete')}
            </Button>
          ]}
        />
      )}
      {isMobile ? (
        <LockDetailMobile
          signout={signout}
          history={history}
          lockForm={lockForm()}
          lock={lock}
          setShowDeleteLockModal={setShowDeleteLockModal}
        />
      ) : (
        <>
          {error && (
            <Notification
              type='error'
              message={error}
              style={{ margin: '20px 0' }}
            />
          )}
          <PageContentHeader
            breadcrumbItems={breadcrumbItems}
            actionButtons={
              <div style={{ marginLeft: 'auto' }}>
                <Button
                  variant='secondary'
                  onClick={() =>
                    lock && lock.id && setShowDeleteLockModal(true)
                  }>
                  {global._('Locks.DeleteLock')}
                </Button>
              </div>
            }
          />
          <Paper alignItems='left' mediumPadding>
            {lockForm()}
          </Paper>
        </>
      )}
    </>
  )
}

LockDetail.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array,
  signout: PropTypes.func.isRequired
}

LockDetail.defaultProps = {
  breadcrumbItems: []
}

const LockDetailWithStyles = injectSheet(styles)(LockDetail)
export default LockDetailWithStyles
