import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import DigitalIdentification from 'src/components/DigitalIdentification'
import { Country, ElectronicIdentityServices } from 'src/utils/constants'
import { getCountry } from 'src/utils/helpers'
import BankId from './BankId'
import Credentials from './Credentials'
import Typography from '../../components/Typography'
import useWindowDimensions from '../../utils/useWindowDimensions'
import NavigationBar from '../../components/NavigationBar'
import Content from '../../components/Content'
import ScrollView from '../../components/ScrollView'
import Button from '../../components/Button'
import Link from '../../components/Link'
import Theme from '../../helpers/Theme'
import MobileLogo from '../../components/MobileLogo'
import Paper from '../../components/Paper'
import { history } from '../../redux/store'

const styles = {
  root: {
    height: 'auto',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.06)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '50px 0px 0px',
    overFlowY: 'hidden'
  },
  authContainer: {
    width: '100%'
  },
  linkContainer: {
    fontSize: '18px!important',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: 0,
    fontFamily: '"Open Sans", sans-serif',
    marginTop: 24
  },
  alignedText: {
    textAlign: 'center'
  },

  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */

  '@media (max-width: 900px)': {
    root: {
      backgroundColor: '#f0f0f0',
      padding: '16px 16px 0 16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    alignedText: {
      textAlign: 'left'
    }
  }
}

const Login = props => {
  const { classes, setAuthenticationData } = props

  const { isMobile } = useWindowDimensions()
  const [authType, setAuthType] = useState()

  // Data should be { user, token, exp }
  const onAuthenticationComplete = data => {
    const { user, token, expires } = data
    setAuthenticationData({
      user,
      token,
      expires
    })
  }

  const registerAnAccountLink = (
    <Typography
      variant='body'
      bold={600}
      className={classes.alignedText}
      style={{ marginTop: 20 }}
    >
      {global._('Login.NoAccount')}

      <div>
        <Link onClick={() => history.push('/register-organisation')}>
          {global._('Login.NoAccountLink')}
        </Link>
      </div>
    </Typography>
  )

  const firstTimeLoginLink = (
    <Typography
      variant='body'
      bold={600}
      className={classes.alignedText}
      style={{ marginTop: 25 }}
    >
      {global._('Login.FirstTimeLogin')}
      <div>
        <Link
          data-cy='link-activate-account'
          onClick={() => history.push('/activate-account')}
        >
          {global._('Login.ActivateYourAccount')}
        </Link>
      </div>
    </Typography>
  )

  const country = getCountry()
  if (isMobile) {
    let title
    if (!authType) {
      title = global._('Login.NavigationBarTitle')
    } else if (authType === 'credentials') {
      title = global._('Login.Username')
    } else if (authType === 'electronic-identity') {
      title = global._('Login.ElectronicID')
    } else if (authType === 'bankid-same-device') {
      title = global._('Login.BankId.Mobile.Title.SameDevice')
    } else if (authType === 'bankid-other-device') {
      title = global._('Login.BankId.Mobile.Title.OtherDevice')
    } else if (authType === 'no-bankid') {
      title = global._('Login.BankId')
    } else if (authType === 'no-bankid-mobile') {
      title = global._('Login.BankId.BankIdMobile')
    }
    return (
      <Content
        style={{
          background: authType === 'credentials' && 'var(--color-white)'
        }}
      >
        <NavigationBar
          title={title}
          backAction={
            [
              'credentials',
              'electronic-identity',
              'no-bankid',
              'no-bankid-mobile'
            ].includes(authType) && (() => setAuthType(null))
          }
        />
        <ScrollView fullHeight noBottomPadding>
          <div
            className={classes.root}
            style={{ backgroundColor: authType ? '#fff' : undefined }}
          >
            {authType === 'credentials' && (
              <Credentials
                history={props.history}
                onAuthenticationComplete={onAuthenticationComplete}
              />
            )}
            {(authType === 'bankid-same-device' ||
              authType === 'bankid-other-device') && (
              <BankId
                sameDevice={authType === 'bankid-same-device'}
                onAuthenticationComplete={onAuthenticationComplete}
                onCancel={() => setAuthType(null)}
              />
            )}
            {authType === 'no-bankid' && (
              <div style={{ margin: '-16px -16px 0 -16px' }}>
                <DigitalIdentification
                  services={[ElectronicIdentityServices.NO_BANKID]}
                  onAuthenticationComplete={onAuthenticationComplete}
                  onCancel={() => setAuthType(null)}
                />
              </div>
            )}
            {authType === 'no-bankid-mobile' && (
              <div style={{ margin: '-16px -16px 0 -16px' }}>
                <DigitalIdentification
                  services={[ElectronicIdentityServices.NO_BANKID_MOBILE]}
                  onAuthenticationComplete={onAuthenticationComplete}
                  onCancel={() => setAuthType(null)}
                />
              </div>
            )}
            {authType === 'electronic-identity' && (
              <div style={{ margin: '-16px -16px 0 -16px' }}>
                <DigitalIdentification
                  onAuthenticationComplete={onAuthenticationComplete}
                  onCancel={() => setAuthType(null)}
                />
              </div>
            )}
            {!authType && (
              <>
                <MobileLogo alt='Digital Låssmed' />
                <Typography
                  variant='title'
                  style={{ marginTop: 30, marginBottom: 20 }}
                >
                  {global._('Login.SelectLoginType')}
                </Typography>
                {country === Country.SE && (
                  <>
                    <Button
                      variant='bankid'
                      onClick={() => setAuthType('bankid-same-device')}
                    >
                      <span>{global._('Login.BankIdSameDevice')}</span>
                    </Button>
                    <Button
                      onClick={() => setAuthType('bankid-other-device')}
                      variant='bankid'
                      style={{ marginTop: 20 }}
                    >
                      <span>{global._('Login.BankIdOtherDevice')}</span>
                    </Button>
                    {/* <Link
                      style={{ textAlign: 'left', marginTop: 20 }}
                      onClick={() => setAuthType('electronic-identity')}>
                      {global._('Login.ElectronicID.Other')}
                    </Link> */}
                  </>
                )}
                {country === Country.NO && (
                  <>
                    <Button
                      variant='secondary'
                      data-cy='no-bankid'
                      onClick={() => setAuthType('no-bankid')}
                      style={{
                        width: '100%',
                        padding: '10px 20px',
                        textAlign: 'left'
                      }}
                    >
                      <span>{global._('Login.BankId')}</span>
                    </Button>
                    <Button
                      variant='secondary'
                      data-cy='no-bankid-mobile'
                      onClick={() => setAuthType('no-bankid-mobile')}
                      style={{
                        marginTop: 20,
                        width: '100%',
                        padding: '10px 20px',
                        textAlign: 'left'
                      }}
                    >
                      <span>{global._('Login.BankId.BankIdMobile')}</span>
                    </Button>
                  </>
                )}

                <Button
                  variant='primary'
                  style={{ textAlign: 'left', marginTop: 50 }}
                  onClick={() => setAuthType('credentials')}
                >
                  {global._('Login.Username')}
                </Button>
                {firstTimeLoginLink}
                {registerAnAccountLink}
              </>
            )}
          </div>
        </ScrollView>
      </Content>
    )
  }

  let title
  if (!authType) {
    title = global._('Login.SelectLoginType')
  } else if (authType === 'credentials') {
    title = global._('Login.Credentials.Title')
  } else if (authType === 'bankid-same-device') {
    title = global._('Login.BankId.Title.SameDevice')
  } else if (authType === 'bankid-other-device') {
    title = global._('Login.BankId.Title.OtherDevice')
  }
  return (
    <div className={classes.root}>
      {authType === 'no-bankid' && (
        <DigitalIdentification
          services={[ElectronicIdentityServices.NO_BANKID]}
          onCancel={() => setAuthType(null)}
        />
      )}
      {authType === 'no-bankid-mobile' && (
        <DigitalIdentification
          services={[ElectronicIdentityServices.NO_BANKID_MOBILE]}
          onCancel={() => setAuthType(null)}
        />
      )}
      {authType === 'electronic-identity' && (
        <DigitalIdentification onCancel={() => setAuthType(null)} />
      )}
      {!['no-bankid', 'no-bankid-mobile', 'electronic-identity'].includes(
        authType
      ) && (
        <>
          <Typography variant='h1' align='center' style={{ marginBottom: 50 }}>
            {title}
          </Typography>
          <Paper
            classes={classes}
            alignItems='center'
            biggerPadding={authType !== 'credentials'}
            fixedWidth
          >
            {!authType && (
              <>
                {country === Country.SE && (
                  <>
                    <Button
                      data-cy='bankid-same-device'
                      variant='bankid'
                      onClick={() => setAuthType('bankid-same-device')}
                    >
                      <span>{global._('Login.BankIdSameDevice')}</span>
                    </Button>
                    <Button
                      data-cy='bankid-other-device'
                      variant='bankid'
                      onClick={() => setAuthType('bankid-other-device')}
                      style={{ marginTop: 20 }}
                    >
                      <span>{global._('Login.BankIdOtherDevice')}</span>
                    </Button>
                    {/* <Link
                      style={{ textAlign: 'left', marginTop: 20 }}
                      onClick={() => setAuthType('electronic-identity')}>
                      {global._('Login.ElectronicID.Other')}
                    </Link> */}
                  </>
                )}
                {country === Country.NO && (
                  <>
                    <Button
                      variant='secondary'
                      data-cy='no-bankid'
                      onClick={() => setAuthType('no-bankid')}
                      style={{
                        width: '100%',
                        padding: '10px 20px',
                        textAlign: 'left'
                      }}
                    >
                      <span>{global._('Login.BankId')}</span>
                    </Button>
                    <Button
                      variant='secondary'
                      data-cy='no-bankid-mobile'
                      onClick={() => setAuthType('no-bankid-mobile')}
                      style={{
                        marginTop: 20,
                        width: '100%',
                        padding: '10px 20px',
                        textAlign: 'left'
                      }}
                    >
                      <span>{global._('Login.BankId.BankIdMobile')}</span>
                    </Button>
                  </>
                )}
                {/* NO End */}

                <Button
                  data-cy='login-button-credentials'
                  variant='primary'
                  style={{
                    textAlign: 'left',
                    marginTop: 50,
                    width: '100%',
                    padding: '10px 20px',
                    height: 60
                  }}
                  onClick={() => setAuthType('credentials')}
                >
                  {global._('Login.Username')}
                </Button>
                {firstTimeLoginLink}
              </>
            )}
            <div className={classes.authContainer}>
              {(authType === 'bankid-same-device' ||
                authType === 'bankid-other-device') && (
                <BankId
                  sameDevice={authType === 'bankid-same-device'}
                  onAuthenticationComplete={onAuthenticationComplete}
                  onCancel={() => setAuthType(null)}
                />
              )}
              {authType === 'credentials' && (
                <Credentials
                  history={props.history}
                  onAuthenticationComplete={onAuthenticationComplete}
                />
              )}
            </div>
          </Paper>
        </>
      )}
      {!authType && registerAnAccountLink}
      {authType === 'credentials' && (
        <Typography
          className={classes.linkContainer}
          variant='body'
          align='center'
        >
          <Link
            style={{ textDecoration: 'none' }}
            onClick={() => setAuthType('')}
          >
            {global.translate('Common.Back')}
          </Link>
        </Typography>
      )}
    </div>
  )
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  setAuthenticationData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const LoginWithStyles = injectSheet(styles)(Login)
export default LoginWithStyles
