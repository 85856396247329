/**
 * Simple shell page to display the Success component.
 *
 * match.params.part2 will contain the newly created partner id
 *
 * Check Routes.js for more info on url params
 */
import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import Success from '../../../components/Success'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'

const Shell = props => {
  const { history, match } = props

  return (
    <Success>
      <Typography
        variant='subtitleBig'
        style={{
          marginBottom: 28
        }}>
        {global._('Admin.SuccessAddPartner')}
      </Typography>
      <Button
        style={{ width: '100%' }}
        variant='primary'
        onClick={() =>
          history.push(`/organisations/${match.params.partnerId}`)
        }>
        {global._('Admin.ToPartner')}
      </Button>
      <Button
        style={{ width: '100%' }}
        variant='outlined'
        onClick={() => history.push('/admin/partners/new')}>
        {global._('Admin.AddAdditionalPartner')}
      </Button>
      <Button variant='none' onClick={() => history.push('/admin/partners')}>
        {global._('Common.Back')}
      </Button>
    </Success>
  )
}

Shell.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const ShellWithRouter = withRouter(Shell)
export default ShellWithRouter
