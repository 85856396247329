import { connect } from 'react-redux'
import { setAuthenticationData } from '../../redux/reducers/login'
import Component from './BlueprintWizard'

const mapStateToProps = state => ({
  user: state.login.user,
  token: state.login.token,
  expires: state.login.expires
})

const mapDispatchToProps = dispatch => ({
  setAuthenticationData: data => dispatch(setAuthenticationData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
