/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { ImportStorageKeys } from 'src/utils/constants'
import AsyncSelect from 'src/components/AsyncSelect'
import ImportModal from '../../../../components/ImportModal'
import { getSelectedOrg } from '../../../../utils/helpers'
import withUser from '../../../../utils/withUser'
import { history } from '../../../../redux/store'

const styles = {
  root: {}
}

const ImportKeysModal = ({ user, onClose }) => {
  const selectedOrg = getSelectedOrg(user)
  if (!selectedOrg) {
    history.push('/keys/select-organisation')
  }

  const [lockSystem, setLockSystem] = useState(null)

  return (
    <ImportModal
      storageKey={ImportStorageKeys.KEYS}
      transformRow={row => {
        const holderId = row[6] ? row[6].toString() : ''
        let apartmentRefId = null
        let storageId = null
        if (holderId) {
          if (holderId.startsWith('LGH')) {
            apartmentRefId = holderId
          } else {
            storageId = holderId
          }
        }
        const key = {
          name: row[0] ? row[0].toString() : '', // string
          type: row[1] ? row[1].toString() : '', // string enum ["mekanisk", "digital", "rfid-tagg"], validate in next step
          make: row[2] ? row[2].toString() : '', // string
          marking: row[3] ? row[3].toString() : '', // string
          identifier: row[4] ? row[4].toString() : '', // string
          notes: row[5] ? row[5].toString() : '', // string,
          holderId,
          storageId, // id
          apartmentRefId, // string
          keyringId: row[7] ? row[7].toString() : '', // id (keyringId), eg 23
          organisationId: selectedOrg.id,
          lockSystemId: lockSystem.id
        }

        return key
      }}
      key='admin.keys.import.modal'
      previewUrl='/keys/list/import'
      templateFilePath={`/templates/importmall_nycklar_${global.getLanguage()}.xlsx`}
      locKey='Keys'
      onClose={onClose}
      fieldsData={{
        lockSystem,
        organisation: selectedOrg
      }}
      fields={() => (
        <>
          <AsyncSelect
            isSearchable
            isClearable={false}
            key='import-keys-locksystem-select'
            dropdownIcon='search'
            helpText={global._('Common.Required')}
            value={lockSystem && { label: lockSystem.name, value: lockSystem }}
            placeholder={global._(`Import.Keys.Modal.AsyncFieldPlaceholder`)}
            onChange={opt => {
              setLockSystem(opt ? opt.value : null)
            }}
            url={`/v1/organisations/${selectedOrg.id}/locksystems?search={{input}}&orderBy=name&order=asc`}
          />
        </>
      )}
    />
  )
}

ImportKeysModal.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

ImportKeysModal.defaultProps = {}

const ImportKeysModalWithStyles = injectSheet(styles)(ImportKeysModal)
export default withUser(ImportKeysModalWithStyles)
