import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import NavigationBar from './NavigationBar'

const styles = {
  root: {
    zIndex: 1300,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'var(--color-logo-black)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  content: {
    width: '100%',
    height: '100%',
    marginTop: 64,
    overflow: 'hidden'
  },
  imgDesktop: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  imgMobile: {
    width: '100%'
  },
  buttons: {
    minWidth: '100%',
    zIndex: 99,
    padding: props =>
      props.buttons
        ? [props.buttonPadding ? props.buttonPadding : '18px 18px 30px']
        : '',
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: props =>
      props.boxShadow
        ? props.boxShadow
        : '0px -4px 10px 0px rgba(0, 0, 0, 0.14)',
    background: 'var(--color-white)',
    '& button': {
      marginBottom: props => (props.buttons ? 20 : undefined),
      '&:last-child': {
        margin: 0
      }
    }
  },
  '@media (max-width: 900px)': {
    content: {
      overflow: 'scroll'
    }
  }
}

const imageTypes = ['image/jpeg', 'image/png']
const AttachmentModal = props => {
  const { classes, token, title, attachment, onClose, src, buttons, data } =
    props

  const url = `${window.env.API_BASE_URL}${src}?token=${token}`
  const isImage = imageTypes.includes(attachment.type)
  const isPdf = attachment.type === 'application/pdf'

  const { isMobile } = useWindowDimensions()

  return (
    <div className={classes.root}>
      <NavigationBar
        title={title || global._('Attachment.DefaultTitle')}
        rightActionItem={
          typeof onClose === 'function' && (
            <div onClick={onClose}>
              <Icon>close</Icon>
            </div>
          )
        }
      />
      <div className={classes.content}>
        {isImage && (data || url) && (
          <img
            className={isMobile ? classes.imgMobile : classes.imgDesktop}
            src={data ? `data:${attachment};base64,${data}` : url}
            alt='attachment'
          />
        )}
        {isPdf && url && (
          <div style={{ width: '100%', height: '100%' }}>
            <object
              data={url}
              type='application/pdf'
              style={{ width: '100%', height: '100%' }}
            >
              <iframe
                width='100%'
                height='100%'
                style={{ border: 0 }}
                title={attachment.name}
                src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
              />
            </object>
          </div>
        )}
      </div>
      {buttons && <div className={classes.buttons}>{buttons(data || url)}</div>}
    </div>
  )
}

AttachmentModal.propTypes = {
  classes: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  title: PropTypes.string,
  attachment: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  buttons: PropTypes.func
}

AttachmentModal.defaultProps = {
  title: null,
  buttons: undefined
}

const AttachmentModalWithStyles = injectSheet(styles)(AttachmentModal)

/* Attach to redux store */
const mapStateToProps = state => ({
  token: state.login.token
})
const ConnectedAttachmentModalWithStyles = connect(
  mapStateToProps,
  null
)(AttachmentModalWithStyles)
export default ConnectedAttachmentModalWithStyles
