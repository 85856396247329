import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import { UserRoles, Country } from 'src/utils/constants'
import { clearSelectedOrg, getUnreadItemsCountObj } from 'src/utils/helpers'
import Button from 'src/components/Button'
import Typography from '../../components/Typography'
import Card from '../../components/Card'
import { items } from '../../layouts/PrivateLayout/listItems'
import useWindowDimensions from '../../utils/useWindowDimensions'
import NavigationBar from '../../components/NavigationBar'
import ScrollView from '../../components/ScrollView'
import Theme from '../../helpers/Theme'
import MobileLogo from '../../components/MobileLogo'
import InformationBar from '../../components/InformationBar'
import packageJson from '../../../package.json'
import MobileContainer from '../../components/MobileContainer'
import KeysNoticeWithStyles from 'src/components/Keys/KeysNotice'
import { useAppSelector } from 'src/hooks/useAppSelector'

const styles = {
  root: {},
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: -10,
    padding: 10,
    overflow: 'hidden'
  },
  pageAlert: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '20px 22px',
    paddingRight: 40,
    paddingBottom: 30,
    marginBottom: 20,
    marginTop: 5,
    borderRadius: 3,
    border: '1px solid #e8e8e8',
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)'
  },
  pageAlertCloseIcon: {
    position: 'absolute',
    top: 13,
    right: 13,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.85
    }
  },
  versionLabel: {
    position: 'absolute',
    top: 12,
    right: 18,
    fontSize: '0.5rem',
    color: '#aaa'
  },
  InformationBarContainer: {
    marginBottom: 10
  },

  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */

  '@media (max-width: 900px)': {
    root: {
      padding: '16px 16px 16px 16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    InformationBarContainer: {
      marginBottom: 0
    }
  },
  caseContainer: {
    marginTop: 30,
    width: 'calc(100% + 32px)',
    textAlign: 'left'
  },
  caseList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 -16px',
    marginTop: 12,
    backgroundColor: '#fff',
    padding: '16px 0 40px 0',
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.08)'
  },
  caseListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: Theme.Colors.PRIMARY_TONE_4,
      cursor: 'pointer'
    }
  },
  caseListIcon: {
    color: Theme.Colors.PRIMARY,
    fontSize: 22
  },
  caseListTitle: {
    color: Theme.Colors.TEXT,
    fontSize: 16,
    fontWeight: 600
  },
  caseListChevron: {
    color: Theme.Colors.TEXT
  }
}

const Home = props => {
  const { classes, user, history, setShowChangeOrganisationModal } = props

  const { isMobile } = useWindowDimensions()
  const [messageOfTheDay, setMessageOfTheDay] = useState('')
  const notifications = useAppSelector(state => state.notifications)

  useEffect(() => {
    const motdHidden = localStorage.getItem('motdHidden')
    if (!motdHidden) {
      setMessageOfTheDay(global._('Home.InformationAlertBody'))
    }
  }, [])

  const hideMessageOfTheDay = () => {
    localStorage.setItem('motdHidden', '1')
    setMessageOfTheDay('')
  }

  if (!user) {
    return null
  }

  const country = user.organisation.country || Country.SE

  const content = (
    <>
      {notifications?.serviceRequests?.items?.map(serviceRequest => (
        <KeysNoticeWithStyles
          requirePersonalNumber={false}
          icon='build'
          title={`${global._('ServiceRequests.NotificationTitle')} #${
            serviceRequest.id
          }`}
          subtitle={global._('ServiceRequests.NotificationMessage')}
          key={serviceRequest.id}
          user={user}
          history={history}
          actionButtons={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                style={{
                  width: isMobile ? '100%' : undefined
                }}
                variant='secondary'
                onClick={() => {
                  history.push(`/service-requests/${serviceRequest.id}`)
                }}
              >
                {global._('Common.Answer')}
              </Button>
            </div>
          }
        />
      ))}
      <Grid
        container
        spacing={isMobile ? 2 : 3}
        style={{ marginTop: isMobile ? 16 : 6 }}
      >
        {items.map(item => {
          if (!item.path || item.path === '/dashboard') {
            return null
          }
          if (isMobile && !item.mobile) {
            return null
          }
          if (typeof item.desktop !== 'undefined' && !item.desktop) {
            return null
          }
          if (item.access) {
            if (!user.roles.some(role => item.access.includes(role))) {
              return null
            }
          }
          if (typeof item.desktop !== 'undefined' && !item.desktop) {
            return null
          }
          if (item.access) {
            if (!user.roles.some(role => item.access.includes(role))) {
              return null
            }
          }
          if (
            Array.isArray(item.countries) &&
            !item.countries.includes(country)
          ) {
            return null
          }

          const badges = []
          const buttons = []
          if (
            item.path === '/cases' &&
            [UserRoles.PartnerAdmin, UserRoles.Support].some(r =>
              user.roles.includes(r)
            )
          ) {
            badges.push({
              count: getUnreadItemsCountObj('unreadCasesCount').count,
              label: global._('Cases.ShowAllUnreadCases'),
              onClick: () => history.push('/cases/unread')
            })
          } else if (
            item.path === '/orders' &&
            [UserRoles.PartnerAdmin, UserRoles.Support].some(r =>
              user.roles.includes(r)
            )
          ) {
            badges.push({
              count: getUnreadItemsCountObj('unreadOrdersCount').count,
              label: global._('Common.Updates'),
              onClick: () =>
                history.push({
                  pathname: '/orders',
                  state: {
                    isRead: 'false'
                  }
                })
            })
          } else if (
            item.path === '/documents' &&
            user.roles.includes(UserRoles.CustomerAdmin)
          ) {
            badges.push({
              count: getUnreadItemsCountObj('pendingDocsCount').count,
              label: global._('AWAITING_APPROVAL')
            })
          } else if (
            item.path === '/my-keys' &&
            !user.roles.includes(UserRoles.Support)
          ) {
            badges.push({
              count: getUnreadItemsCountObj('pendingKeysCount').count,
              label: global._('MyKeys.AwaitingRequest')
            })
            badges.push({
              count: getUnreadItemsCountObj('pendingKeyInventoryApprovalsCount')
                .count,
              label: global._('Common.Inventory')
            })
          } else if (item.key === 'booking') {
            if (
              (user.roles.includes(UserRoles.CustomerAdmin) ||
                user.roles.includes(UserRoles.PartnerAdmin)) &&
              !isMobile
            ) {
              // buttons.push(
              //   <Button
              //     key='book-btn-1'
              //     variant='primary'
              //     style={{ width: '100%', marginTop: 24 }}
              //     onClick={() => history.push(item.path)}
              //   >
              //     {global._('DashboardDescriptions.booking.ToMyBookings')}
              //   </Button>
              // )
              buttons.push(
                <Button
                  key='book-btn-2'
                  variant='outlined'
                  style={{
                    width: '100%',
                    marginTop: isMobile ? 12 : 24,
                    textAlign: 'left'
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    clearSelectedOrg()
                    history.push(item.path2)
                  }}
                >
                  {global._('DashboardDescriptions.booking.ToAdministration')}
                </Button>
              )
            }
          }
          return (
            <Grid
              key={`home-card-${item.path}`}
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
            >
              <Card
                title={global._(`DashboardDescriptions.${item.key}.Title`)}
                icon={item.iconName}
                subtitle={global._(
                  `DashboardDescriptions.${item.key}.${user.roles[0]}`
                )}
                link={item.path}
                history={history}
                badges={badges}
                buttons={buttons}
              />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
  if (isMobile) {
    return (
      <MobileContainer>
        <NavigationBar
          title={global._('Home.Title')}
          leftActionItem={
            setShowChangeOrganisationModal ? (
              <div onClick={() => setShowChangeOrganisationModal(true)}>
                <Icon>store</Icon>
              </div>
            ) : undefined
          }
          rightActionItem={
            <div onClick={() => history.push('/myaccount')}>
              <Icon>account_circle</Icon>
            </div>
          }
        />
        <ScrollView>
          <div className={classes.root}>
            <MobileLogo />
            <span style={{ color: '#8e8e8e', marginTop: 22, fontSize: 15 }}>
              {global._('Home.Welcome')}
            </span>
            <Typography
              variant='h1'
              style={{ lineHeight: 1, marginBottom: 20 }}
            >
              {user.name}
            </Typography>
            {messageOfTheDay && (
              <InformationBar
                classes={classes}
                title={global._('Home.InformationAlertTitle')}
                message={global._('Home.InformationAlertBody')}
                closeBtn
                closeBtnAction={hideMessageOfTheDay}
              />
            )}
            {content}
            {/* <Grid container spacing={2} style={{ marginTop: 16 }}>
              {cards.map(card => {
                if (card.path === '/dashboard') return null
                if (!card.path) return null
                if (!card.mobile) return null
                if (card.key === 'admin' && user.roles.includes(UserRoles.Support)) {
                  return null
                }
                return (
                  <Grid key={`home-card-${card.path}`} item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Card
                      title={card.name}
                      icon={card.iconName}
                      subtitle={card.description ? card.description : global._(`DashboardDescriptions.${card.name}.${user.roles}`)}
                      link={card.path}
                      history={history}
                      unreadCasesCount={card.path === '/cases' && unreadCasesCount}
                      unreadOrdersCount={card.path === '/orders' && unreadOrdersCount}
                      pendingDocsCount={card.path === '/documents' && pendingDocsCount}
                      pendingKeysCount={card.path === '/my-keys' && pendingKeysCount}
                    />
                  </Grid>
                )
              })}
            </Grid> */}
            <div className={classes.versionLabel}>
              {`v${packageJson.version}`}
            </div>
          </div>
        </ScrollView>
      </MobileContainer>
    )
  }

  return (
    <div className={classes.root}>
      <Typography variant='h1'>{`${global._('Home.Welcome')} ${
        user.name
      }`}</Typography>
      <div style={{ height: 20 }} />
      {messageOfTheDay && (
        <InformationBar
          classes={classes}
          title={global._('Home.InformationAlertTitle')}
          message={global._('Home.InformationAlertBody')}
          closeBtn
          closeBtnAction={hideMessageOfTheDay}
        />
      )}
      <div style={{ height: 10 }} />
      <div className={classes.content}>
        {content}
        {/* <Grid container spacing={3}>
          {Object.values(items).map(item => {
            if (item.path === '/dashboard') return null
            if (!item.path) return null
            if (typeof item.desktop !== 'undefined' && !item.desktop) {
              return null
            }
            if (item.access) {
              if (!user.roles.some(role => item.access.includes(role))) {
                return null
              }
            }
            return (
              <Grid key={`home-card-${item.path}`} item xs={12} sm={12} md={6} lg={4} xl={3}>
                <Card
                  title={item.name}
                  icon={item.iconName}
                  subtitle={item.description ? item.description : global._(`DashboardDescriptions.${item.name}.${user.roles}`)}
                  link={item.path}
                  history={history}
                  unreadCasesCount={item.path === '/cases' && unreadCasesCount}
                  unreadOrdersCount={item.path === '/orders' && unreadOrdersCount}
                  pendingDocsCount={item.path === '/documents' && pendingDocsCount}
                  pendingKeysCount={item.path === '/my-keys' && pendingKeysCount}
                />
              </Grid>
            )
          })}
        </Grid> */}
      </div>
    </div>
  )
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setShowChangeOrganisationModal: PropTypes.func,
  history: PropTypes.object
}

Home.defaultProps = {
  history: null,
  setShowChangeOrganisationModal: undefined
}

const HomeWithStyles = injectSheet(styles)(Home)
export default HomeWithStyles
