import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import InfiniteScroll from 'src/components/InfiniteScroll'
import axios from 'axios'
import PageContentHeader from 'src/components/PageContentHeader'
import IconColumn from 'src/components/IconColumn'
import List from 'src/components/List'
import LoadingSpinner from 'src/components/LoadingSpinner'
import Notification from 'src/common/Notification'
import SuccessMessage from 'src/components/SuccessMessage'
import Alert from 'src/components/Alert'
import { normalize } from 'src/utils/helpers'
import { Grid } from '@material-ui/core'

const styles = {
  root: {
    minHeight: '100vh',
    background: 'var(--color-material-grey)',
    width: '100%',
    padding: '144px 0 60px',
    display: 'flex',
    alignItems: 'center'
  }
}

const LIMIT = 25

const ApartmentUsers = props => {
  const { classes, history, breadcrumbItems, match } = props

  const { apartmentId } = match.params

  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [apartment, setApartment] = useState(false)
  /* Unselected items */
  const [unselectedItems, setUnselectedItems] = useState([])
  const [filteredUnselectedItems, setFilteredUnselectedItems] = useState([])
  const [unselectedFilter, setUnselectedFilter] = useState({})

  /* Selected items */
  const [selectedItems, setSelectedItems] = useState([])
  const [filteredSelectedItems, setFilteredSelectedItems] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({})

  const [hasChanged, setHasChanged] = useState(false)
  const [error, setError] = useState(null)
  const [saveSuccess, setSaveSuccess] = useState(false)

  const resolveSearchableAttributes = inputArray => {
    if (inputArray.length) {
      inputArray.forEach(item => {
        item.search = ''
        if (item.name) {
          item.search += item.name.toLowerCase()
        }
        if (item.email) {
          item.search += item.email.toLowerCase()
        }
        if (item.personalNumber) {
          item.search += item.personalNumber.toLowerCase()
        }
        item.search = normalize(item.search)
      })
    }

    return inputArray
  }

  const onFilterChange = async (
    filter,
    originalList,
    setFilter,
    setList,
    selected
  ) => {
    let list

    if (filter.search) {
      const search = normalize(filter.search.toLowerCase())
      list = originalList.filter(item => item.search.includes(search))
    } else {
      list = [...originalList]
    }

    const { orderBy } = filter
    if (orderBy) {
      list.sort((a, b) => {
        if (orderBy === 'name' || orderBy === 'email') {
          if (filter.order === 'asc') {
            return a[orderBy].localeCompare(b[orderBy])
          }
          return b[orderBy].localeCompare(a[orderBy])
        }
        return 0
      })
    }

    const compareFilter = selected ? selectedFilter : unselectedFilter
    if (
      filter.orderBy !== compareFilter.orderBy ||
      filter.order !== compareFilter.order ||
      filter.search !== compareFilter.search
    ) {
      setOffset(0)
    }

    setList(list)
    setFilter(filter)
  }

  const onUnselectedFilterChange = filter =>
    onFilterChange(
      filter,
      unselectedItems,
      setUnselectedFilter,
      setFilteredUnselectedItems,
      false
    )
  const onSelectedFilterChange = filter =>
    onFilterChange(
      filter,
      selectedItems,
      setSelectedFilter,
      setFilteredSelectedItems,
      true
    )

  useEffect(() => {
    onUnselectedFilterChange(unselectedFilter)
    onSelectedFilterChange(selectedFilter)
  }, [selectedItems, unselectedItems])

  const offsetLimit = offset + LIMIT

  const handleSave = async () => {
    setIsLoading(true)
    try {
      setError(null)
      setSaveSuccess(false)

      await axios.put(
        `/v1/organisations/${apartment.organisation.id}/apartments/${apartmentId}/updateUsers`,
        {
          userIds: selectedItems.map(i => i.id)
        }
      )

      setHasChanged(false)
      setSaveSuccess(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const { data: _apartment } = await axios.get(
          `/v1/apartments/${apartmentId}`
        )

        let { data: users } = await axios.get(
          `/v1/organisations/${_apartment.organisation.id}/apartments/assignableUsers`
        )

        setApartment(_apartment)

        const userData = u => ({
          id: u.id,
          name: u.name || '',
          email: u.email || '',
          personalNumber: u.personalNumber || '',
          search: u.search
        })

        if (_apartment.users && _apartment.users.length > 0) {
          const selectedUsers = _apartment.users.map(u => ({
            id: u.id,
            name: u.name || '',
            email: u.email || '',
            personalNumber: u.personalNumber || ''
          }))

          resolveSearchableAttributes(selectedUsers)

          setSelectedItems(selectedUsers.map(u => userData(u)))
        }

        // Remove users that are already assigned to the apartment
        users = users.filter(
          u => !_apartment.users.map(u => u.id).includes(u.id)
        )
        resolveSearchableAttributes(users)

        setUnselectedItems(users.map(u => userData(u)))
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }

      setIsLoading(false)
    })()
  }, [])

  const updatedBreacrumbItems = [...breadcrumbItems]

  if (apartment) {
    updatedBreacrumbItems.push({
      name: apartment.number,
      path: '/admin/apartments/users/:apartmentId'
    })
  }

  const handleAdd = item => {
    setHasChanged(true)
    const updated = [item, ...selectedItems]
    setUnselectedItems(unselectedItems.filter(itemObj => itemObj !== item))
    setSelectedItems(updated)
  }

  const handleRemove = item => {
    setHasChanged(true)
    const updated = selectedItems.filter(itemObj => itemObj !== item)
    setUnselectedItems([item, ...unselectedItems])
    setSelectedItems(updated)
  }

  const itemColumns = [
    {
      key: 'icon',
      sortingDisabled: true,
      style: { width: 70 },
      format: () => <IconColumn icon='person' />
    },
    {
      key: 'name',
      sortingKey: 'name',
      longText: true,
      localizationKey: 'Common.Name',
      clickable: false,
      style: { width: '40%' },
      format: (name, item) => {
        if (!name && !item.email && item.personalNumber) {
          return <div style={{ color: '#999' }}>{item.personalNumber}</div>
        }
        return name
      }
    },
    {
      key: 'email',
      sortingKey: 'email',
      longText: true,
      localizationKey: 'Common.Email',
      clickable: false,
      style: { width: '40%' }
    }
  ]

  if (isLoading) return <LoadingSpinner />

  return (
    <div>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      {saveSuccess && (
        <SuccessMessage subtitle={global._('Apartments.Users.SaveSuccess')} />
      )}
      <PageContentHeader breadcrumbItems={updatedBreacrumbItems} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div>
          <Typography variant='title' bold>
            {global._('Apartments.Users.UsersAt')}{' '}
            {apartment && apartment.organisation.name}
          </Typography>
        </div>
        <div>
          <Button
            variant='outlined'
            style={{ marginRight: 18 }}
            onClick={() => history.goBack()}
          >
            {global._('Common.Cancel')}
          </Button>
          <Button
            variant='primary'
            disabled={!hasChanged}
            onClick={() => handleSave()}
          >
            {global._('Common.Save')}
          </Button>
        </div>
      </div>
      <InfiniteScroll
        hasMore={
          filteredUnselectedItems.length > offsetLimit ||
          filteredSelectedItems.length > offsetLimit
        }
        fetchMore={() => setOffset(offsetLimit)}
      >
        <Grid container spacing={3} style={{ marginTop: 12 }}>
          <Grid item xs={6}>
            <List
              columns={itemColumns}
              items={filteredUnselectedItems.slice(0, offsetLimit)}
              isLoading={isLoading}
              classes={classes}
              filter={unselectedFilter}
              setFilter={onUnselectedFilterChange}
              title={global._('AccessGroups.AddUsers')}
              endAdornmentOnClick={item => handleAdd(item)}
              searchPlaceholder={global._('Search')}
              endAdornmentIcon='add_circle'
              // additionalFilterContent={
              //   <Alert type='info' style={{ marginTop: 20 }}>
              //     {global._('Apartments.Users.InfoText')}
              //   </Alert>
              // }
            />
          </Grid>
          <Grid item xs={6}>
            <List
              columns={itemColumns}
              items={filteredSelectedItems.slice(0, offsetLimit)}
              classes={classes}
              filter={selectedFilter}
              setFilter={onSelectedFilterChange}
              title={global._('AccessGroups.Added')}
              buttonTitle={global._('AccessGroups.ClearItems')}
              endAdornmentOnClick={item => handleRemove(item)}
              buttonOnClick={() => {
                const updated = selectedItems.filter(
                  item => !filteredSelectedItems.includes(item)
                )
                setSelectedItems(updated)
                setUnselectedItems([
                  ...filteredSelectedItems,
                  ...unselectedItems
                ])
                setSelectedFilter({ ...selectedFilter, search: '' })
              }}
              searchPlaceholder={global._('Search')}
              endAdornmentIcon='remove_circle'
            />
          </Grid>
        </Grid>
      </InfiniteScroll>
    </div>
  )
}

ApartmentUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array
}

ApartmentUsers.defaultProps = {
  breadcrumbItems: []
}

export default injectSheet(styles)(ApartmentUsers)
