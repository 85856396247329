import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Button from '../Button'
import Typography from '../Typography'
import useWindowDimensions from '../../utils/useWindowDimensions'
import { detectMimeType } from '../../utils/mime'

// Custom dashed lines
const STROKE_WIDTH = 1
const SPACE = 18
const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dashTop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: STROKE_WIDTH,
    backgroundImage: 'linear-gradient(to right, #8e8e8e 50%, transparent 50%)',
    backgroundSize: `${SPACE}px ${STROKE_WIDTH}px`
  },
  dashRight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: STROKE_WIDTH,
    backgroundImage: 'linear-gradient(to top, transparent 50%, #8E8E8E 50%)',
    backgroundSize: `${STROKE_WIDTH}px ${SPACE}px`
  },
  dashBottom: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    height: STROKE_WIDTH,
    backgroundImage: 'linear-gradient(to left, #8e8e8e 50%, transparent 50%)',
    backgroundSize: `${SPACE}px ${STROKE_WIDTH}px`
  },
  dashLeft: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    top: 0,
    width: STROKE_WIDTH,
    backgroundImage: 'linear-gradient(to top, #8e8e8e 50%, transparent 50%)',
    backgroundSize: `${STROKE_WIDTH}px ${SPACE}px`
  }
}

const defaultStyle = {
  position: 'relative',
  padding: 15,
  borderRadius: 3,
  overflow: 'hidden'
}
const defaultBaseStyle = {
  ...defaultStyle,
  backgroundColor: '#E8E8E8'
}

const defaultDragOverStyle = {
  ...defaultStyle,
  backgroundColor: '#87C4F9'
}

const DropZone = props => {
  const {
    onFileSelected,
    classes,
    baseStyle,
    dragOverStyle,
    title,
    subtitle,
    buttonOnly,
    buttonVariant,
    buttonText,
    allowedTypes,
    allowedSize
  } = props
  const { isMobile } = useWindowDimensions()
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const selectFileElementRef = useRef(null)

  // Disable the top level drag and drop listeners
  useEffect(() => {
    const disablingListener = e => e && e.preventDefault()
    window.addEventListener('dragover', disablingListener, false)
    window.addEventListener('drop', disablingListener, false)
    return () => {
      window.removeEventListener('dragover', disablingListener)
      window.removeEventListener('drop', disablingListener)
    }
  }, [])

  const readAndNotify = file => {
    const { name, size } = file
    let { type } = file
    const reader = new FileReader()
    reader.onload = readEvent => {
      if (typeof onFileSelected === 'function') {
        if (!type) {
          type = detectMimeType(name)
        }
        const base64 = readEvent.target.result.split(';base64,').pop()
        let error
        if (allowedTypes && !allowedTypes.includes(type)) {
          error = 'ERR_FILE_TYPE'
        } else if (allowedSize && size > allowedSize) {
          error = 'ERR_FILE_SIZE'
        }
        onFileSelected(
          {
            name,
            type,
            data: base64,
            size
          },
          error
        )
      }
    }
    reader.readAsDataURL(file)
  }

  const onDrop = evt => {
    setIsDraggingOver(false)
    if (evt.dataTransfer.files && evt.dataTransfer.files.length > 0) {
      const [file] = evt.dataTransfer.files
      readAndNotify(file)
      evt.dataTransfer.clearData()
    }
  }

  const handleSelectFile = evt => {
    evt.preventDefault()
    /* eslint-disable-next-line */
    evt.target.value = null
    selectFileElementRef.current.value = null
    selectFileElementRef.current.click()
  }

  const uploadFile = evt => {
    if (evt.target && evt.target.files && evt.target.files.length > 0) {
      const [file] = evt.target.files
      readAndNotify(file)
    }
  }

  let selectedStyle
  if (isDraggingOver) {
    selectedStyle = dragOverStyle || defaultDragOverStyle
  } else {
    selectedStyle = baseStyle || defaultBaseStyle
  }

  if (buttonOnly) {
    return (
      <>
        <input
          type='file'
          style={{ display: 'none' }}
          ref={selectFileElementRef}
          onChange={uploadFile}
        />
        <Button variant={buttonVariant || 'primary'} onClick={handleSelectFile}>
          {buttonText || global._('DragAndDrop.Button.ChooseFile')}
        </Button>
      </>
    )
  }

  if (isMobile) {
    return (
      <div className={classes.root}>
        <input
          data-cy='dragzone'
          type='file'
          style={{ display: 'none' }}
          ref={selectFileElementRef}
          onChange={uploadFile}
        />
        <AddCircleIcon
          style={{
            marginBottom: 15,
            color: '#fff',
            fontSize: 142,
            pointerEvents: 'none'
          }}
        />
        <Button
          variant={buttonVariant || 'primary'}
          onClick={handleSelectFile}
          style={{ marginTop: 24 }}>
          {global._('DragAndDrop.Button.ChooseFile')}
        </Button>
      </div>
    )
  }

  return (
    <div
      className={classes.root}
      style={selectedStyle}
      onDragOver={() => setIsDraggingOver(true)}
      onDragLeave={() => setIsDraggingOver(false)}
      onDrop={onDrop}>
      <input
        data-cy='dragzone'
        type='file'
        style={{ display: 'none' }}
        ref={selectFileElementRef}
        onChange={uploadFile}
      />
      <div className={classes.dashTop} />
      <div className={classes.dashRight} />
      <div className={classes.dashBottom} />
      <div className={classes.dashLeft} />
      <AddCircleIcon
        style={{
          marginTop: 10,
          marginBottom: 15,
          color: '#fff',
          fontSize: 142,
          pointerEvents: 'none'
        }}
      />
      <Typography
        variant='h3'
        bold={500}
        align='center'
        style={{ pointerEvents: 'none', maxWidth: 250, marginBottom: 25 }}>
        {title}
      </Typography>
      <Button
        variant={buttonVariant || 'primary'}
        onClick={handleSelectFile}
        style={{ minWidth: 200 }}>
        {global._('DragAndDrop.Button.ChooseFile')}
      </Button>
      {subtitle && (
        <Typography
          variant='body2'
          style={{
            pointerEvents: 'none',
            marginTop: 25,
            marginBottom: 5,
            fontWeight: 200
          }}>
          {subtitle}
        </Typography>
      )}
    </div>
  )
}

DropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  baseStyle: PropTypes.object,
  dragOverStyle: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonOnly: PropTypes.bool,
  buttonVariant: PropTypes.string
}

DropZone.defaultProps = {
  baseStyle: null,
  dragOverStyle: null,
  title: null,
  subtitle: null,
  buttonOnly: false,
  buttonVariant: null
}

export default injectSheet(styles)(DropZone)
