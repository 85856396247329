import React from 'react'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import CircularProgress from '@material-ui/core/CircularProgress'
import queryString from 'querystring'
import Button from '../../../../components/Button'
import { UserRoles } from '../../../../utils/constants'
import AsyncSelect from '../../../../components/AsyncSelect'
import { CustomInput } from '../Entity'
import { history } from '../../../../redux/store'

export default {
  doNotShowFilter: true,
  doNotShowTable: true,
  fetchUrl: filter => {
    const filterObj = { ...filter }

    const query = queryString.stringify(filterObj)

    return `/v1/organisations/${filter.organisationId}/locks?${query}`
  },
  icon: () => 'lock',
  filter: ({
    single,
    handleSearchQueryChange,
    isLoading,
    filter,
    buttons,
    setFilter
  }) => (
    <Grid
      container
      spacing={2}
      direction='row'
      alignItems='center'
      justify='space-between'
    >
      <Grid item xs={12} sm={12} md={single ? 4 : undefined}>
        <CustomInput
          label={global._('AccessGroups.SearchName')}
          onChange={handleSearchQueryChange}
          endAdornment={
            <InputAdornment position='end'>
              {isLoading ? (
                <CircularProgress style={{ color: 'black' }} size={20} />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          }
          helperText=' '
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <AsyncSelect
          isSearchable
          isClearable={false}
          labelKey='name'
          value={{
            value: filter.organisationId,
            label: filter.organisationName
          }}
          placeholder={global._('Common.SelectOrganisation')}
          disabled={isLoading}
          helpText={global._('Common.Required')}
          url='/v1/organisations?search={{input}}&orderBy=name&order=asc&ifHas=locks'
          onChange={opt =>
            opt &&
            setFilter({
              ...filter,
              offset: 0,
              organisationId: opt ? opt.value.id : null,
              organisationName: opt ? opt.value.name : null
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <AsyncSelect
          key={`accesslocks-${filter.organisationId}`}
          isSearchable
          helpText={global._('AccessGroups.FilterOnProperty')}
          labelKey='name'
          isClearable
          cacheOptions={false}
          defaultValue={
            filter && filter.propertyId
              ? { value: filter.propertyId, label: filter.propertyName }
              : undefined
          }
          placeholder={global._('AccessGroups.SelectProperty')}
          onChange={opt =>
            setFilter({
              ...filter,
              offset: 0,
              propertyId: opt ? opt.value.id : null,
              propertyName: opt ? opt.value.name : null
            })
          }
          url={`/v1/properties?query={{input}}&orderBy=name&order=asc&organisationId=${filter.organisationId}`}
        />
      </Grid>
      {single && buttons && (
        <Grid item xs={12} sm={12} md={4} style={{ marginBottom: 18 }}>
          <Grid container spacing={2} direction='row' justify='flex-end'>
            {buttons.map(btn => (
              <Grid key={`${btn.key}.grid`} item>
                {btn}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  ),
  noResultsMessage: () => global._('AccessGroups.NoAvailableLocks'),
  identifier: lock => lock.id,
  MobileTable: {
    icon: 'lock',
    title: lock => lock.name,
    subtitle: lock =>
      (lock.device.property && lock.device.property.name) ||
      global._('Common.NoName'),
    onClick: item =>
      history.push(
        `/admin/accessgroups/accessbyitem/${item.organisationId}/locks/${item.id}`
      )
  },
  Columns: {
    single: [
      {
        key: 'icon',
        sortable: false,
        localizationKey: '',
        style: { width: 60 },
        transform: isExpanded => (
          <div
            className='tableRowIcon'
            style={{
              width: 38,
              height: 38,
              transition: 'background-color 200ms',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isExpanded ? 'white' : 'var(--color-light-grey)',
              color: 'var(--color-middle-grey)',
              borderRadius: 500
            }}
          >
            <Icon style={{ fontSize: 18 }}>lock</Icon>
          </div>
        )
      },
      {
        key: 'name',
        sortable: true,
        sortKey: 'name',
        localizationKey: 'AccessGroups.Name',
        style: { width: 320 }
      },
      {
        key: 'device',
        sortKey: 'propertyName',
        sortable: true,
        localizationKey: 'AccessGroups.Property',
        transform: device => device && device.property && device.property.name,
        style: { width: 300 }
      },
      {
        key: 'organisation',
        sortKey: 'organisationName',
        sortable: true,
        localizationKey: 'AccessGroups.Organisation',
        transform: value => value.name,
        style: { width: 300 }
      },
      {
        key: 'chevron',
        localizationKey: '',
        sortable: false,
        style: { width: 70 },
        transform: isExpanded => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton
              style={{
                transition: 'transform 150ms',
                marginLeft: 'auto',
                transform: `rotate(${isExpanded ? -90 : 90}deg)`
              }}
            >
              <Icon>chevron_right</Icon>
            </IconButton>
          </div>
        )
      }
    ]
  },
  rowExpansionContent: (item, caller, opts) => (
    <React.Fragment>
      {/* This button should be hidden for now (WIP) */}
      {false && (
        <Button
          variant='outlined'
          onClick={() => {}}
          style={{ marginRight: 18 }}
        >
          {global._('AccessGroups.ShowLockOnBluePrint')}
        </Button>
      )}
      <Button
        variant='outlined'
        onClick={() =>
          opts.history.push(
            `/admin/accessgroups/accessbyitem/${item.organisationId}/locks/${item.id}`
          )
        }
        style={{ marginRight: 18 }}
      >
        {global._('AccessGroups.ShowEligibleUsers')}
      </Button>
    </React.Fragment>
  )
}
