import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import queryString from 'querystring'
import Typography from 'src/components/Typography'
import Notification from 'src/common/Notification'
import RadioButton from 'src/components/RadioButton'
import RadioButtonGroup from 'src/components/RadioButtonGroup'
import Button from 'src/components/Button'
import Modal from 'src/components/Modal'
import LoadingSpinner from 'src/components/LoadingSpinner'
import axios from 'axios'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import FullscreenModal from 'src/components/FullscreenModal'

const styles = {
  root: {},
  section: {
    padding: 'var(--section-padding-default)',
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--divider-color)'
    }
  }
}

const SelectAKeyModal = ({
  classes,
  history,
  user,
  setShowKeySelectionModal
}) => {
  const [error, setError] = useState(null)
  const [selectedKey, setSelectedKey] = useState(null)
  const [keys, setKeys] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const defaultFilter = {
    self: true,
    order: 'asc',
    orderBy: 'name',
    archived: false
  }

  const fetchKeys = async () => {
    setIsLoading(true)

    try {
      const query = queryString.stringify(defaultFilter)

      const { data } = await axios.get(
        `v1/organisations/${user.organisation.id}/keys?${query}`
      )

      const keysArr = data.filter(key => key.signed === 1 || key.apartmentId)

      setKeys(keysArr)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchKeys()
  }, [])

  const { isMobile } = useWindowDimensions()

  const KeyList = () => {
    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <LoadingSpinner />
        </div>
      )
    }

    if (keys.length > 0) {
      return (
        <div className={classes.section} style={{ padding: 0 }}>
          <RadioButtonGroup
            value={selectedKey}
            onChange={setSelectedKey}
            removeLastBorder
          >
            {keys.map(key => (
              <RadioButton
                key={key.marking}
                value={key}
                title={
                  key.personalName || key.name || global._('Common.NoName')
                }
                subtitle={key.marking}
              />
            ))}
          </RadioButtonGroup>
        </div>
      )
    }

    return (
      <div style={{ padding: 20 }}>
        <Typography variant='body'>
          {global._('Cases.Orders.SelectAKey.NoKeysFound')}
        </Typography>
      </div>
    )
  }

  const ProceedButton = () => (
    <Button
      variant='primary'
      disabled={!selectedKey}
      onClick={() =>
        history.push(
          `/orders/new?keyId=${selectedKey.id}&variant=${selectedKey.type}`
        )
      }
    >
      {global._('Common.Proceed')}
    </Button>
  )

  if (isMobile) {
    return (
      <FullscreenModal
        title={global._('Cases.Orders.Keys')}
        content={
          <div>
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ margin: '20px 0' }}
              />
            )}
            <div className={classes.section}>
              <Typography variant='body' bold>
                {global._('Cases.Orders.SelectAKey.SelectAKey')}
              </Typography>
            </div>
            <KeyList />
          </div>
        }
        onClose={() => {
          setShowKeySelectionModal(false)
        }}
        buttons={<ProceedButton />}
      />
    )
  }

  return (
    <Modal
      title={global._('Cases.Orders.SelectAKey.SelectAKey')}
      content={
        <div style={{ overflow: 'auto', height: 300 }}>
          <KeyList />
        </div>
      }
      noContentPadding
      buttons={[
        <Button variant='none' onClick={() => setShowKeySelectionModal(false)}>
          {global._('Common.Cancel')}
        </Button>,
        <ProceedButton />
      ]}
    />
  )
}

SelectAKeyModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

SelectAKeyModal.defaultProps = {
  user: null,
  error: null
}

const SelectAKeyModalWithStyles = injectSheet(styles)(SelectAKeyModal)
export default SelectAKeyModalWithStyles
