import React from 'react'
import moment from 'moment'
import { Icon } from '@material-ui/core'
import { stateToHTML } from 'draft-js-export-html'
import { convertFromRaw } from 'draft-js'
import axios from 'axios'
import Button from '../components/Button'
import {
  CaseStatus,
  CaseInvoiceStatus,
  CaseTypes,
  KeyStatus,
  DocumentStatus,
  UserRoles,
  KeyInventoryStatus,
  KeyInventoryApprovalStatus,
  UserAssignableRoles,
  OrganisationTypes,
  VATAmount,
  Country
} from './constants'
import StateToPdfMake from './pdfMake'

export const mapCaseStatus = status => {
  if (!status) return ''
  if (status === CaseStatus.New) return 'purple'
  if (status === CaseStatus.Started) return 'primary'
  if (status === CaseStatus.Collected) return 'black'
  if (status === CaseStatus.Closed) return 'material-grey'
  return null
}

export const mapCaseInvoiceStatus = status => {
  if (!status) return ''
  if (status === CaseInvoiceStatus.NotChargeable) return 'purple'
  if (status === CaseInvoiceStatus.NotInvoiced) return 'black'
  if (status === CaseInvoiceStatus.Invoiced) return 'material-grey'
  if (status === CaseInvoiceStatus.NotInvoicedCall) return 'black'
  if (status === CaseInvoiceStatus.Paid) return 'black'
  return null
}

export const mapDocumentStatus = status => {
  if (!status) return ''
  if (status === DocumentStatus.AwaitingApproval) return 'purple'
  if (status === DocumentStatus.Accepted) return 'primary'
  if (status === DocumentStatus.Rejected) return 'black'
  if (status === DocumentStatus.None) return 'empty'
  return null
}

export const mapCaseIcon = caseType => {
  if (caseType === CaseTypes.Key) return 'vpn_key'
  if (caseType === CaseTypes.Service) return 'build'
  if (caseType === CaseTypes.Support) return 'help'
  if (caseType === CaseTypes.Relocation) return 'check_circle'
  if (caseType === CaseTypes.Other) return 'more_horiz'
  if (caseType === CaseTypes.Claim) return 'phonelink_off'
  if (caseType === CaseTypes.Feedback) return 'feedback'
  if (caseType === CaseTypes.Order) return 'shopping_cart'
  return null
}

export const mapKeyStatus = status => {
  if (!status) return ''
  if (status === KeyStatus.ACTIVE) return 'primary'
  if (status === KeyStatus.LOST) return 'middle-grey'
  if (status === KeyStatus.DAMAGED) return 'middle-grey'
  if (status === KeyStatus.BLOCKED) return 'light-error'
  if (status === KeyStatus.DISCONTINUED) return 'black'
  return null
}

export const mapKeyInventoryStatus = status => {
  if (!status) return ''
  if (status === KeyInventoryStatus.ACTIVE) return 'primary'
  if (status === KeyInventoryStatus.ENDED) return 'middle-grey'
  return null
}

export const mapKeyInventoryApprovalStatus = status => {
  if (!status) return ''
  if (status === KeyInventoryApprovalStatus.PENDING) return 'primary'
  if (status === KeyInventoryApprovalStatus.VERIFIED) return 'black'
  return null
}

export const checkIfOrgHasAccessToScope = (user, organisation, accessScope) => {
  if (!accessScope) {
    return true
  }

  if (
    user &&
    [UserRoles.SystemAdmin, UserRoles.PartnerAdmin, UserRoles.Support].some(r =>
      user.roles.includes(r)
    )
  ) {
    return true
  }

  const organisationServices = organisation && organisation.services

  if (organisationServices && organisationServices.length > 0 && accessScope) {
    if (organisationServices[0][accessScope]) {
      return true
    }
  }
  return false
}

export const formatShallowDate = (objDate, opts) =>
  moment(objDate).calendar(null, {
    lastDay:
      opts &&
      opts.withoutTime &&
      `[${global._('Common.DateStrings.Yesterday')}]`,
    sameDay:
      opts && opts.withoutTime && `[${global._('Common.DateStrings.Today')}]`,
    nextDay:
      opts &&
      opts.withoutTime &&
      `[${global._('Common.DateStrings.Tomorrow')}]`,
    lastWeek: now => now._locale._calendar.lastWeek.replace('LT', ''),
    nextWeek: now => now._locale._calendar.nextWeek.replace('LT', ''),
    sameElse: opts && opts.withoutTime ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'
  })
export const formatDate = objDate =>
  moment(objDate).calendar(null, { sameElse: 'YYYY-MM-DD HH:mm' })
export const dateNow = () => moment().format('YYYY-MM-DD HH:mm')

export const personalNumberRegex = /(^\d{6}-\d{4}$)|(^\d{11}$)/
export const fullPersonalNumberRegex = /(^\d{12}$)|(^\d{11}$)/
// export const phoneNumberRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const phoneNumberRegex = /^([+])([0-9]|[ -]){8,15}$/
export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const organisationNumberRegExp = /^(\d{6}-\d{4})|(\d{9})$/
export const defaultCasesFilter = ({ location, lastLocation }) => ({
  organisationId: null,
  orderBy: 'createdAt',
  order: 'desc',
  type: '',
  limit: 25,
  offset: 0,
  invoiced: '',
  query: '',
  status: [CaseStatus.New, CaseStatus.Started, CaseStatus.Resting],
  isRead:
    lastLocation && location.state && location.state.isRead
      ? location.state.isRead
      : '',
  createdAtFrom: null,
  createdAtTo: null,
  managerUserId: null
})

export const nameInputAdornment = (
  isEditingName,
  handleUpdate,
  isNameUpdated,
  setIsEditingName,
  nameInputRef
) => {
  let inputAdornment

  if (isEditingName) {
    inputAdornment = (
      <Button
        style={{
          position: 'absolute',
          padding: 0,
          top: 2,
          right: 10,
          height: 6,
          width: 49
        }}
        variant='noHover'
        onClick={handleUpdate}
      >
        {global._('Common.Save')}
      </Button>
    )
  } else if (isNameUpdated) {
    inputAdornment = (
      <Icon
        style={{
          position: 'absolute',
          top: 14,
          right: 10,
          width: 24,
          height: 24,
          color: 'var(--color-primary)'
        }}
      >
        check_circle
      </Icon>
    )
  } else if (!isEditingName) {
    inputAdornment = (
      <Button
        style={{
          position: 'absolute',
          top: 14,
          right: 10,
          width: 24,
          height: 24
        }}
        variant='icon'
        icon='edit'
        onClick={() => {
          setIsEditingName(true)
          setTimeout(() => {
            if (nameInputRef.current) {
              nameInputRef.current.focus()
            }
          }, 100)
        }}
      />
    )
  }

  return inputAdornment
}

export const sizeInMb = size =>
  (size / 1000 / 1000).toFixed(1).toString().replace('.', ',')

export const clearSelectedOrg = () => {
  window.sessionStorage.removeItem('selected_org')
}

export const getSelectedOrg = user => {
  const selectedOrg = window.sessionStorage.getItem('selected_org')

  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)

  if (user && !isPartnerAdmin && !isSupport) {
    return user.organisation
  }

  return selectedOrg ? JSON.parse(selectedOrg) : false
}

export const convertDraftJsToPdfMakeObj = draft => {
  const contentData = new StateToPdfMake(draft)
  const pdfMakeContent = contentData.generate()

  return pdfMakeContent
}

export const signedKeyFormat = obj => {
  if (obj.signed && obj.signedAt) {
    return moment(obj.signedAt).format('YYYY-MM-DD')
  }

  return global._('Common.No')
}

export const parseReceiptTermsContent = content => {
  let parsedContent
  try {
    parsedContent = stateToHTML(convertFromRaw(JSON.parse(content)))
  } catch (e) {
    parsedContent = content
  }

  return parsedContent
}

const updateUnreadItemsCount = (
  count,
  localStorageKeyName,
  setUnreadItemsCount
) => {
  const countObj = {
    count,
    lastUpdated: Date.now()
  }

  window.localStorage.setItem(localStorageKeyName, JSON.stringify(countObj)) // Update across other tabs
  setUnreadItemsCount(count) // Update locally
}

export const getUnreadItemsCount = async (
  withChecks,
  fetchUrl,
  localStorageKeyName,
  setUnreadItemsCount
) => {
  const localUnreadCasesCount = JSON.parse(
    localStorage.getItem(localStorageKeyName)
  )

  const currentDateTime = Date.now()
  const interval = 1 * 60 * 1000 // 1 min

  if (
    !withChecks ||
    !localUnreadCasesCount ||
    (withChecks &&
      currentDateTime - localUnreadCasesCount.lastUpdated > interval)
  ) {
    // withChecks - Check the last updated time and run only if it's been more than a minute ago, this makes sure only 1 fetch is made from the database even if multiple tabs are open
    try {
      const { data } = await axios.get(fetchUrl)
      if (data && data > 0) {
        updateUnreadItemsCount(
          data > 99 ? '99+' : data,
          localStorageKeyName,
          setUnreadItemsCount
        )
      } else {
        updateUnreadItemsCount(null, localStorageKeyName, setUnreadItemsCount)
      }
    } catch (e) {
      updateUnreadItemsCount(null, localStorageKeyName, setUnreadItemsCount)

      const msg = e.response ? e.response.data : e.message
      return msg
    }
  }
}

export const getUnreadItemsCountObj = localStorageKeyName =>
  JSON.parse(window.localStorage.getItem(localStorageKeyName)) || { count: 0 }

export const updateUnreadItemsAcrossTabs = (
  localStorageKeyName,
  setUnreadItemsCount
) => {
  window.addEventListener(
    'storage',
    () => {
      // Update unread cases count across tabs
      const localUnreadCasesCountObj = JSON.parse(
        window.localStorage.getItem(localStorageKeyName)
      )

      if (localUnreadCasesCountObj) {
        setUnreadItemsCount(localUnreadCasesCountObj.count)
      }
    },
    false
  )
}

export const runUnreadItemsCountFetcher = (
  fetchUrl,
  localStorageKeyName,
  setUnreadItemsCount,
  organisation,
  scope,
  user
) => {
  const orgHasAccess = checkIfOrgHasAccessToScope(user, organisation, scope)

  if (orgHasAccess) {
    getUnreadItemsCount(
      false,
      fetchUrl,
      localStorageKeyName,
      setUnreadItemsCount
    )

    const intervalId = setInterval(
      () =>
        getUnreadItemsCount(
          true,
          fetchUrl,
          localStorageKeyName,
          setUnreadItemsCount
        ),
      31000
    ) // Check if there is new data each 31 seconds
    // Cleanup - clear interval if component is unmounted
    return () => clearInterval(intervalId)
  }

  updateUnreadItemsCount(0, localStorageKeyName, setUnreadItemsCount)
}

export const mapRoles = (roles, user, disregardActive) => {
  if (user && Array.isArray(roles)) {
    return roles.map((role, idx) => (
      <span
        key={`${user.id || user.email || idx}-${role}`}
        style={{
          padding: '2px 8px',
          backgroundColor: 'var(--color-middle-grey)',
          color: 'white',
          borderRadius: 3,
          display: 'inline-block',
          fontSize: '0.75rem',
          fontWeight: 600,
          textTransform: !disregardActive && !user.active && 'lowercase',
          marginLeft: idx > 0 ? 5 : 0
        }}
      >
        {!disregardActive && !user.active && (
          <span style={{ textTransform: 'capitalize' }}>{`${global._(
            'Common.NotRegistered'
          )} `}</span>
        )}
        {global._(`User.Roles.${role}`)}
      </span>
    ))
  }
  return null
}

export const getLocalFilter = itemName => {
  const localFilter = localStorage.getItem(itemName)

  return JSON.parse(localFilter)
}

export const setLocalFilter = (
  currentFilter,
  resetOffset,
  itemName,
  setFilter
) => {
  const shallowFilter = { ...currentFilter }

  if (resetOffset) {
    shallowFilter.offset = 0
  }

  setFilter(shallowFilter)

  localStorage.setItem(itemName, JSON.stringify(shallowFilter))
}

/* remove some accents (0300 - 0307), while keeping e.g ÅÄÖ åäö */
/* https://unicode-table.com/en/#0300 */
const accentsRegex = /[\u0300-\u0307]/g
export const normalize = str => {
  if (!str || typeof str !== 'string') {
    return ''
  }

  return (
    str
      /* decomposes e.g è into two code points (e + `) */
      .normalize('NFD')
      /* remove code points matching e.g accents while keeping the original character */
      .replace(accentsRegex, '')
      /* compose into single code points again */
      .normalize('NFC')
  )
}

export const getAvailableRoles = organisation => {
  const availableRoles = []

  Object.keys(UserRoles).forEach(role => {
    if (
      UserAssignableRoles[UserRoles[role]] &&
      UserAssignableRoles[UserRoles[role]].length > 0
    ) {
      UserAssignableRoles[UserRoles[role]].forEach(r => {
        if (!availableRoles.includes(r)) {
          if (
            r === UserRoles.SystemAdmin &&
            organisation.type !== OrganisationTypes.SystemAdmin
          ) {
            return
          }

          if (
            r === UserRoles.PartnerAdmin &&
            organisation.type !== OrganisationTypes.Partner
          ) {
            return
          }

          /* remove support role when target organisation is not service desk */
          if (
            r === UserRoles.Support &&
            organisation.type !== OrganisationTypes.ServiceDesk
          ) {
            return
          }

          /* remove all other roles when target organisation is service desk */
          if (
            r !== UserRoles.Support &&
            organisation.type === OrganisationTypes.ServiceDesk
          ) {
            return
          }

          availableRoles.push(r)
        }
      })
    }
  })

  return availableRoles
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const isLockDisabled = lock =>
  lock &&
  (moment().isBefore(lock.from) || moment().isAfter(lock.to) || lock.disabled)

export const publicRequest = (method, url, data, optionalToken) => {
  const token = optionalToken || localStorage.getItem('public.token')
  const axiosWithoutInterceptors = axios.create()
  const cfg = {
    headers: {
      Authorization: `Basic ${token}`
    }
  }
  if (['post', 'put', 'patch'].includes(method)) {
    return axiosWithoutInterceptors[method.toLowerCase()](url, data, cfg)
  }
  return axiosWithoutInterceptors[method.toLowerCase()](url, cfg)
}

export const calculateVAT = totalPrice =>
  parseFloat(totalPrice - totalPrice / (1 + VATAmount))
    .toFixed(2)
    .replace('.', ',')

export const hasActivePaymentMethod = organisation => {
  if (!organisation) {
    return false
  }

  let _organisation = organisation

  if (organisation.partner) {
    _organisation = organisation.partner
  }

  if (_organisation.activeSwish || _organisation.activeNets) {
    return true
  }

  return false
}

export const getCountry = () =>
  Country[window.location.hostname.split('.').pop().toUpperCase()] || Country.SE

export const getMaxLockShareLimit = lock => {
  let maxShareLimit = 0
  for (const group of lock.accessgroups) {
    if (group.shareLimit > maxShareLimit) {
      maxShareLimit = group.shareLimit
    }
  }

  return maxShareLimit
}

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1)
