import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Notification from '../common/Notification'
import Typography from './Typography'
import Button from './Button'
import Modal from './Modal'
import FullscreenModal from './FullscreenModal'

const ConfirmationModal = props => {
  const { title, message, onClose, onConfirm, type, fullscreen } = props
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const confirmationHandler = async () => {
    if (onConfirm) {
      setIsLoading(true)
      try {
        await onConfirm()
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
        setIsLoading(false)
      }
    }
  }

  const Wrapper = fullscreen ? FullscreenModal : Modal
  return (
    <Wrapper
      onClose={onClose}
      type={type}
      title={title || global._('ConfirmationModal.Title')}
      content={
        <div
          style={{ textAlign: 'left', padding: fullscreen ? 20 : undefined }}>
          <Typography
            variant='body'
            dangerouslySetInnerHTML={{ __html: message }}
          />
          {error && (
            <Notification
              style={{ marginTop: 20 }}
              type='error'
              message={error}
            />
          )}
        </div>
      }
      buttons={[
        <Button
          key='confirmationModal-btn-cancel'
          variant='none'
          onClick={onClose}
          style={{
            marginRight: fullscreen ? undefined : 20,
            marginBottom: fullscreen ? 20 : undefined
          }}>
          {global._('Common.Cancel')}
        </Button>,
        <Button
          key='confirmationModal-btn-confirm'
          variant='secondary'
          loading={isLoading}
          onClick={confirmationHandler}>
          {global._('ConfirmationModal.Confirm')}
        </Button>
      ]}
    />
  )
}

ConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool,
  type: PropTypes.string
}

ConfirmationModal.defaultProps = {
  fullscreen: false,
  title: null,
  type: undefined
}

export default ConfirmationModal
