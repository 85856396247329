import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import Theme from '../helpers/Theme'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    flex: 1
  },
  rootSelected: {
    color: Theme.Colors.PRIMARY,
    fontWeight: 600
  },
  icon: {
    marginBottom: 6,
    fontSize: 26
  },
  text: {
    fontSize: '0.785rem'
  }
}

const TabBarItem = props => {
  const { classes, title, icon, selected, ...otherProps } = props

  const rootClasses = classnames(classes.root, {
    [classes.rootSelected]: selected
  })

  return (
    <div className={rootClasses} {...otherProps}>
      {icon && <Icon className={classes.icon}>{icon}</Icon>}
      <span className={classes.text}>{title}</span>
    </div>
  )
}

TabBarItem.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.string
}

TabBarItem.defaultProps = {
  selected: false,
  title: '',
  icon: null
}

const TabBarItemWithStyles = injectSheet(styles)(TabBarItem)
export default TabBarItemWithStyles
