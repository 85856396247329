import axios from 'axios'
import { useEffect } from 'react'
import {
  NotificationType,
  notificationsSlice
} from 'src/redux/reducers/notificationsSlice'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'

type NotificationOptions = {
  fetch?: boolean
}

export const useNotifications = (options?: NotificationOptions) => {
  const dispatch = useAppDispatch()
  const notifications = useAppSelector(state => state.notifications)

  useEffect(() => {
    if (options?.fetch) {
      const fetchNotifications = async () => {
        try {
          const { data } = await axios.get('/v1/notifications')

          dispatch(notificationsSlice.actions.setNotifications(data))
        } catch (error) {
          console.error(error)
        }
      }

      fetchNotifications()
      const interval = setInterval(() => {
        fetchNotifications()
      }, 60000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [options?.fetch])

  const removeNotification = (notification: any, type: NotificationType) => {
    dispatch(
      notificationsSlice.actions.removeNotification({ notification, type })
    )
  }

  return { notifications, removeNotification }
}
