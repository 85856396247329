import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import Theme from '../helpers/Theme'
import useWindowDimensions from '../utils/useWindowDimensions'

const styles = {
  root: {
    '-webkit-appearance': 'none',
    fontFamily: '"Open Sans", arial',
    boxSizing: 'border-box',
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: 'rgba(0,0,0,0.06)',
    border: 'none',
    borderBottom: '2px solid rgba(0,0,0,0.54)',
    color: '#1c1c1c',
    fontSize: 16,
    fontWeight: 400,
    height: 56,
    padding: 15,
    outline: 'none',
    width: '100%',
    transition: 'border 0.5s',
    '&:disabled': {
      color: '#8e8e8e',
      opacity: 1,
      '-webkit-text-fill-color': '#8e8e8e'
    },
    resize: 'none'
  },
  rootDropdown: {
    '&:disabled': {
      color: '#1c1c1c'
    }
  },
  rootDropdownDisabled: {
    '&:disabled': {
      color: '#8e8e8e'
    }
  },
  rootError: {
    borderBottomColor: Theme.Colors.ERROR
  },
  floatContainer: {
    width: '100%',
    position: 'relative'
  },
  placeholder: {
    fontFamily: 'Open Sans',
    position: 'absolute',
    top: '50%',
    fontSize: 16,
    fontWeight: 300,
    transform: 'translateY(-50%)',
    maxWidth: 'calc(100% - 30px)',
    left: 15,
    textAlign: 'left',
    transition: 'all 0.2s',
    pointerEvents: 'none',
    color: '#8e8e8e'
  },
  placeholderMultiline: {
    top: 26
  },
  placeholderFocused: {
    top: -10,
    left: 7,
    fontSize: 12,
    fontWeight: 600,
    backgroundColor: '#ffffff00',
    padding: '0px 4px',
    color: '#3d3d3d'
  },
  placeholderDisabled: {
    color: '#8a8a8a'
  },
  icon: {
    position: 'absolute',
    right: 10,
    top: 10,
    fontSize: 38
  },
  disabledIcon: {
    color: '#8e8e8e'
  },
  helpText: {
    fontFamily: '"Open Sans", arial',
    pointerEvents: 'none',
    color: 'rgba(0,0,0,0.54)',
    fontSize: 12,
    fontWeight: 400,
    paddingLeft: 11,
    paddingTop: 5,
    textAlign: 'left'
  },

  /* Mobile */

  '@media (max-width: 900px)': {
    root: {
      // padding: '8px 16px',
      // height: 48
    }
  }
}

const Multiline = props => {
  const {
    classes,
    type,
    float,
    placeholder,
    multiline,
    disabled,
    helpText,
    readonly,
    endAdornment,
    /* eslint-disable-next-line */
    dropdown,
    ...rest
  } = props

  const [isFocused, setIsFocused] = useState(false)
  const [height, setHeight] = useState(undefined)

  const onChange = e => {
    const shadow = document.getElementById('shadow')
    shadow.value = e.target.value

    const shadowHeight = shadow.scrollHeight
    setHeight(shadowHeight > 210 ? 210 : shadowHeight)
  }

  // Call once to render correct height if initial value is populated
  useEffect(() => {
    onChange({ target: { value: rest.value } })
  }, [])

  if (float) {
    const placeholderClasses = classnames(
      classes.placeholder,
      classes.placeholderMultiline,
      {
        [classes.placeholderFocused]: isFocused || rest.value,
        [classes.placeholderDisabled]: disabled
      }
    )
    return (
      <div className={classes.floatContainer}>
        <textarea
          className={classes.root}
          style={{
            position: 'absolute',
            visibility: 'hidden',
            pointerEvents: 'none'
          }}
          id='shadow'
        />
        <textarea
          disabled={disabled}
          className={classes.root}
          type={type || 'text'}
          {...rest}
          style={{ height }}
          value={rest.value || ''}
          onFocus={e => {
            setIsFocused(true)
            if (rest.onFocus) rest.onFocus(e)
          }}
          onBlur={e => {
            setIsFocused(false)
            if (rest.onBlur) rest.onBlur(e)
          }}
          onChange={e => {
            onChange(e)
            if (props.onChange) {
              props.onChange(e)
            }
          }}
        />
        <span className={placeholderClasses}>{placeholder}</span>
        {helpText && (
          <div
            style={{
              pointerEvents: 'none',
              color: 'rgba(0,0,0,0.54)',
              fontSize: 14,
              fontWeight: 300,
              paddingLeft: 15,
              paddingTop: 1,
              textAlign: 'left'
            }}>
            {helpText}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={classes.floatContainer}>
      {endAdornment && endAdornment}
      <textarea
        className={classes.root}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          pointerEvents: 'none'
        }}
        id='shadow'
      />
      <textarea
        placeholder={placeholder}
        disabled={disabled}
        className={classes.root}
        type={type || 'text'}
        {...rest}
        style={{
          height,
          paddingRight: endAdornment ? 56 : undefined
        }}
        value={rest.value || ''}
        onFocus={e => {
          setIsFocused(true)
          if (rest.onFocus) rest.onFocus(e)
        }}
        onBlur={e => {
          setIsFocused(false)
          if (rest.onBlur) rest.onBlur(e)
        }}
        onChange={e => {
          onChange(e)
          if (props.onChange) {
            props.onChange(e)
          }
        }}
      />
      <div className={classes.helpText}>
        {rest.value ? placeholder : helpText}
        {/* {helpText && !rest.value && (
          helpText
        )} */}
        {/* {placeholder && rest.value && (
          <div style={{
            pointerEvents: 'none', color: 'rgba(0,0,0,0.54)', fontSize: 14, fontWeight: 300, paddingLeft: 15, paddingTop: 1, textAlign: 'left'
          }}
          >
            {placeholder}
          </div>
        )} */}
      </div>
    </div>
  )
}

Multiline.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  float: PropTypes.bool,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  readonly: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  endAdornment: PropTypes.node
}

Multiline.defaultProps = {
  type: 'text',
  float: false,
  placeholder: null,
  multiline: false,
  disabled: false,
  helpText: null,
  readonly: false,
  icon: null,
  onClick: null,
  onChange: null,
  endAdornment: null
}

const Input = forwardRef((props, ref) => {
  const [isFocused, setIsFocused] = useState(false)
  const { isMobile } = useWindowDimensions()

  const {
    classes,
    type,
    float,
    multiline,
    placeholder,
    disabled,
    readonly,
    helpText,
    disableHelpText,
    icon,
    onClick,
    dropdown,
    error,
    endAdornment,
    ...rest
  } = props

  if (multiline) {
    return <Multiline {...props} />
  }

  const rootClasses = classnames(classes.root, {
    [classes.rootDropdown]: dropdown,
    [classes.rootDropdownDisabled]: dropdown && disabled,
    [classes.rootError]: !!error
  })
  return (
    <div
      className={classes.floatContainer}
      style={{ ...rest.style }}
      onClick={onClick}>
      <input
        ref={ref}
        placeholder={placeholder}
        disabled={disabled || readonly}
        className={rootClasses}
        type={type || 'text'}
        {...rest}
        style={{
          ...rest.style,
          paddingRight: endAdornment ? (disabled ? 50 : isMobile ? 70 : 90) : ''
        }}
      />
      {icon && (
        <Icon
          className={classnames(
            classes.icon,
            disabled && classes.disabledIcon
          )}>
          {icon}
        </Icon>
      )}
      {endAdornment && endAdornment}
      <div
        className={classes.helpText}
        style={{ color: error ? Theme.Colors.ERROR : undefined }}>
        {!!error && error}
        {float && !error && rest.value && placeholder}
        {!float && !error && helpText}
      </div>
      {/* )} */}
    </div>
  )
})

Input.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  float: PropTypes.bool,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  helpText: PropTypes.string,
  disableHelpText: PropTypes.bool,
  readonly: PropTypes.bool,
  icon: PropTypes.string,
  dropdown: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  endAdornment: PropTypes.node
}

Input.defaultProps = {
  type: 'text',
  float: false,
  placeholder: null,
  multiline: false,
  disabled: false,
  helpText: null,
  disableHelpText: false,
  readonly: false,
  icon: null,
  dropdown: false,
  onClick: null,
  onChange: null,
  error: undefined,
  endAdornment: null
}

const InputWithStyles = injectSheet(styles)(Input)
export default InputWithStyles
