import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import { Grid } from '@material-ui/core'
import clx from 'classnames'
import Button from 'src/components/Button'
import Typography from 'src/components/Typography'
import Notification from 'src/common/Notification'
import ObjectList from 'src/components/ObjectList'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { history } from '../redux/store'
import PageContentHeader from './PageContentHeader'

const styles = {
  root: {},
  iconContainer: {
    minWidth: 60,
    maxWidth: 60,
    minHeight: 60,
    maxHeight: 60,
    borderRadius: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--color-light-grey)',
    '& span': {
      fontSize: 30,
      color: 'var(--color-middle-grey)'
    }
  },
  badge: {
    display: 'inline-block',
    wordBreak: 'break-word',
    padding: '6px 12px 6px',
    margin: 2,
    borderRadius: 3,
    fontSize: 12,
    fontWeight: 500
  },
  badgeGreen: {
    backgroundColor: 'var(--color-success-green)',
    color: 'black'
  },
  badgeRed: {
    backgroundColor: 'var(--color-error-red)',
    color: 'white'
  }
}

const ImportPreview = props => {
  const {
    classes,
    items,
    isLoading,
    breadcrumbItems,
    handleImport,
    additionalTitleContent,
    error,
    columns,
    locKey,
    headerIcon
  } = props

  columns.push({
    key: 'importStatus',
    sortingDisabled: true,
    useTypography: false,
    style: { width: 200, textAlign: 'right' },
    format: (state, item) => {
      if (item.errors && item.errors.length > 0) {
        const errors = []
        errors.push(
          <span
            key='Import.NotOK'
            className={clx(classes.badge, classes.badgeRed)}
          >
            {global._('Import.NotOK')}
          </span>
        )

        item.errors.forEach(itemError => {
          const errorSplit = itemError.split('|')
          const locKey = errorSplit[0]
          const substitutions = errorSplit.slice(1)
          errors.push(
            <span
              key={itemError}
              className={clx(classes.badge, classes.badgeRed)}
            >
              {global._(locKey, substitutions)}
            </span>
          )
        })
        return errors
      }
      return (
        <span className={clx(classes.badge, classes.badgeGreen)}>
          {global._('Import.OK')}
        </span>
      )
    }
  })

  const TitleContent = (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottom: '1px solid var(--color-divider-light)',
          padding: 24,
          margin: '-36px -20px 0 -20px'
        }}
      >
        <div className={classes.iconContainer}>
          <Icon>{headerIcon}</Icon>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}
        >
          <Typography variant='body2' faded>
            {global._(`Import.${locKey}.Preview.Title`)}
          </Typography>
          <Typography variant='subtitleBig' bold>
            {global._(`Import.${locKey}.Preview.Subtitle`)}
          </Typography>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Grid container spacing={2}>
            {isLoading ? (
              <Grid item>
                <LoadingSpinner />
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Button
                    variant='none'
                    style={{ width: 130 }}
                    onClick={() => history.goBack()}
                  >
                    {global._('Common.Cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='primary'
                    style={{ width: 130 }}
                    onClick={handleImport}
                    disabled={
                      !items ||
                      items.every(i => i.errors && i.errors.length > 0)
                    }
                  >
                    {global._(`Import.${locKey}.Preview.Import`)}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>
      {additionalTitleContent}
    </div>
  )

  return (
    <div className={classes.root}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      <PageContentHeader breadcrumbItems={breadcrumbItems} />
      <ObjectList
        customHeader={TitleContent}
        expandable={false}
        columns={columns}
        objects={items}
        noResultsMessage={global._(`Import.${locKey}.Preview.NoResultsMessage`)}
        useTypograhy={false}
      />
    </div>
  )
}

ImportPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  additionalTitleContent: PropTypes.node,
  columns: PropTypes.array,
  locKey: PropTypes.string,
  headerIcon: PropTypes.string
}

ImportPreview.defaultProps = {
  additionalTitleContent: '',
  columns: [],
  locKey: '',
  headerIcon: 'face'
}

const ImportPreviewWithStyles = injectSheet(styles)(ImportPreview)
export default ImportPreviewWithStyles
