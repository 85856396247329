import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import { Country } from 'src/utils/constants'
import { organisationNumberRegExp } from 'src/utils/helpers'
import Theme from '../../helpers/Theme'
import Typography from '../../components/Typography'
import Column from '../../components/Column'
import Checkbox from '../../components/Checkbox'
import Row from '../../components/Row'
import Button from '../../components/Button'
import Link from '../../components/Link'
import useWindowDimensions from '../../utils/useWindowDimensions'
import Notification from '../../common/Notification'
import Content from '../../components/Content'
import ScrollView from '../../components/ScrollView'
import NavigationBar from '../../components/NavigationBar'
import Password from '../../components/Password'

const styles = {
  root: {
    backgroundColor: '#fdfdfd',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: 'url("/bg-image.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundOrigin: 'padding-box'
  },
  completeRoot: {
    padding: '55px 190px'
  },
  contentContainer: {
    width: 760,
    maxWidth: '100%',
    backgroundColor: 'var(--color-white)'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    height: 70,
    padding: '22px 47px',
    backgroundColor: 'var(--color-logo-black)',
    marginTop: 70
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid var(--color-divider-light)',
    padding: '22px 45px 12px',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  bullet: {
    position: 'absolute',
    top: 11,
    left: 0,
    width: 10,
    height: 10,
    borderRadius: 500,
    backgroundColor: 'var(--color-text)',
    marginRight: 13
  },
  iconCompleteContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    borderRadius: 500,
    backgroundColor: 'var(--color-primary-tone-3)'
  },
  '@media (max-width: 1200px)': {
    contentContainer: {
      paddingTop: 20
    }
  },

  /* Mobile */
  '@media (max-width: 900px)': {
    root: {
      background: 'none',
      backgroundColor: 'var(--color-white)',
      paddingBottom: 10
    },
    contentContainer: {
      overflow: 'auto'
    },
    container: {
      padding: '0 20px',
      width: '100%',
      borderBottom: 'none',
      '&:first-child': {
        padding: 20,
        marginBottom: 20,
        borderBottom: '1px solid var(--color-divider-light)'
      }
    },
    completeRoot: {
      padding: 20,
      paddingTop: 80,
      backgroundColor: 'var(--color-white)',
      minHeight: '100%'
    }
  }
}

const RegisterOnboarding = props => {
  const {
    classes,
    setAuthenticationData,
    computedMatch: {
      params: { blueprintId }
    },
    user,
    history
  } = props

  const { isMobile } = useWindowDimensions()

  /* Check if registration came from blueprint tool or login */
  const isNormalRegistration =
    window.location.href &&
    window.location.href.includes('/register-organisation')
  /* Easier logic in code */
  const isOnboardingRegistration = !isNormalRegistration

  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [organisationName, setOrganisationName] = useState('')
  const [organisationCountry, setOrganisationCountry] = useState('')
  const [organisationNumber, setOrganisationNumber] = useState('')
  const [organisationAddress, setOrganisationAddress] = useState('')
  const [organisationPostalCode, setOrganisationPostalCode] = useState('')
  const [organisationCity, setOrganisationCity] = useState('')
  const [organisationReference, setOrganisationReference] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [blueprint, setBlueprint] = useState(null)
  const [updatedAuthData, setUpdatedAuthData] = useState(null)

  useEffect(() => {
    if (isOnboardingRegistration && blueprintId) {
      ;(async function fetchBlueprint() {
        try {
          const { data } = await axios.get(`/v1/blueprints/${blueprintId}`)
          setBlueprint(data)
          if (user) setOrganisationName(user.organisation.name)
          setIsInitialLoading(false)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }
      })()
    } else {
      setIsInitialLoading(false)
    }
  }, [])

  const handleSignup = async () => {
    setIsLoading(true)
    try {
      const data = {
        user: {
          email,
          name: organisationReference,
          phone,
          password
        },
        organisation: {
          name: organisationName,
          country: organisationCountry,
          organisationNumber,
          address: organisationAddress,
          postalCode: organisationPostalCode,
          city: organisationCity,
          reference: organisationReference,
          registered: true,
          phone
        }
      }

      const url = isNormalRegistration
        ? '/v1/organisation/register'
        : '/v1/guest/register'
      const response = await axios.post(url, data)
      setUpdatedAuthData(response.data)
      setIsComplete(true)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoading(false)
  }

  const handleSuccessRedirect = () => {
    if (updatedAuthData) {
      /* Will be redirected to dashboard by PublicLayout since user is no longer a guest */
      setAuthenticationData(updatedAuthData)
    }
  }

  if (isInitialLoading && error) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Notification type='error' message={error} />
      </div>
    )
  }

  /* Validate fields */
  const isOrganisationNumberValid =
    organisationNumberRegExp.test(organisationNumber) || !organisationNumber

  const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  const isEmailValid = emailRegExp.test(email)

  const isPasswordValid = password && password.length >= 8
  const arePasswordsEqual = password === passwordRepeat
  const canProceed =
    organisationName &&
    organisationCountry &&
    (isOrganisationNumberValid || !organisationNumber) &&
    organisationReference &&
    // phone &&
    email &&
    isEmailValid &&
    password &&
    isPasswordValid &&
    passwordRepeat &&
    arePasswordsEqual &&
    isPolicyAccepted

  let content
  if (isComplete) {
    content = (
      <Column className={classes.completeRoot} flex>
        <div className={classes.iconCompleteContainer}>
          <Icon style={{ fontSize: 72 }}>done</Icon>
        </div>
        {isOnboardingRegistration && (
          <Typography
            variant='title'
            style={{ padding: '50px 0' }}
            align='center'
          >
            {global._('RegisterOnboarding.Complete.TextPart1')}{' '}
            <span style={{ fontWeight: 600 }}>
              {global._('RegisterOnboarding.Complete.TextPart2Bold')}
            </span>{' '}
            {global._('RegisterOnboarding.Complete.TextPart3')}
          </Typography>
        )}
        {isNormalRegistration && (
          <Typography
            variant='title'
            style={{ padding: '50px 0' }}
            align='center'
          >
            {global._('RegisterOnboarding.Complete.AccountCreated')}
          </Typography>
        )}
        <Button
          data-cy='button-to-account'
          variant='primary'
          onClick={handleSuccessRedirect}
        >
          {global._('RegisterOnboarding.Complete.Button.ToMyAccount')}
        </Button>
        <div style={{ height: 13 }} />
        {isOnboardingRegistration && (
          <>
            <Typography variant='small'>
              {global._('RegisterOnboarding.Complete.Subtext')}
            </Typography>
            {!isMobile && (
              <Typography variant='h3' bold={600} style={{ marginTop: 55 }}>
                {global._('BlueprintWizard.Text.BackTo')}{' '}
                <Link to='https://digitallassmed.se'>digitallassmed.se</Link>
              </Typography>
            )}
          </>
        )}
      </Column>
    )
  } else {
    content = (
      <div className={classes.root}>
        <div className={classes.contentContainer}>
          {!isMobile && (
            <div className={classes.titleContainer}>
              <Typography variant='h2' style={{ color: Theme.Colors.WHITE }}>
                {isNormalRegistration &&
                  global._('RegisterOnboarding.CreateAccount')}
                {isOnboardingRegistration && blueprint && blueprint.name}
                {isOnboardingRegistration &&
                  !blueprint &&
                  global._('Common.Loading')}
              </Typography>
            </div>
          )}
          {/* Header container */}
          <div className={classes.container}>
            <Column
              align='flex-start'
              style={{ padding: isMobile ? 0 : '10px 0px' }}
            >
              <Row style={{ paddingBottom: 16 }}>
                <Typography variant='body'>
                  {global._('RegisterOnboarding.CreateAccountTo')}
                </Typography>
              </Row>
              <Row style={{ padding: '5px 0', position: 'relative' }}>
                <div className={classes.bullet} />
                <Typography variant='body' style={{ marginLeft: 20 }}>
                  {global._('RegisterOnboarding.CreateAccountBullet1')}
                </Typography>
              </Row>
              <Row style={{ padding: '5px 0', position: 'relative' }}>
                <div className={classes.bullet} />
                <Typography variant='body' style={{ marginLeft: 20 }}>
                  {global._('RegisterOnboarding.CreateAccountBullet2')}
                </Typography>
              </Row>
            </Column>
          </div>
          {/* Organisation form container */}
          <div className={classes.container}>
            <Grid container spacing={3} style={{ marginBottom: 1 }}>
              <Grid item xs={12} md={7}>
                <TextField
                  name='organization'
                  autoComplete='organization'
                  value={organisationName}
                  onChange={evt => setOrganisationName(evt.target.value)}
                  label={global._('RegisterOnboarding.Organisation.Name')}
                  helperText={global._('Common.Required')}
                  variant='filled'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  data-cy='input-select-land'
                  value={organisationCountry}
                  onChange={evt => setOrganisationCountry(evt.target.value)}
                  label={global._('RegisterOnboarding.Organisation.Country')}
                  helperText={global._('Common.Required')}
                  variant='filled'
                  fullWidth
                  select
                >
                  {Object.keys(Country).map(c => (
                    <MenuItem value={c} key={c}>
                      {global._(`Countries.${c}`)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  value={organisationNumber}
                  onChange={evt => setOrganisationNumber(evt.target.value)}
                  label={global._(
                    'RegisterOnboarding.Organisation.OrganisationNumber'
                  )}
                  error={!isOrganisationNumberValid}
                  helperText={
                    isOrganisationNumberValid
                      ? undefined // global._('Common.NotRequired')
                      : global._(
                          'RegisterOnboarding.Organisation.OrganisationNumberInvalid'
                        )
                  }
                  variant='filled'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name='address-line1'
                  autoComplete='address-line1'
                  value={organisationAddress}
                  onChange={evt => setOrganisationAddress(evt.target.value)}
                  label={global._('RegisterOnboarding.Organisation.Address')}
                  // helperText={global._('Common.NotRequired')}
                  variant='filled'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  name='postal-code'
                  autoComplete='postal-code'
                  value={organisationPostalCode}
                  onChange={evt => setOrganisationPostalCode(evt.target.value)}
                  label={global._('RegisterOnboarding.Organisation.PostalCode')}
                  // helperText={global._('Common.NotRequired')}
                  variant='filled'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name='city'
                  autoComplete='locality'
                  value={organisationCity}
                  onChange={evt => setOrganisationCity(evt.target.value)}
                  label={global._('RegisterOnboarding.Organisation.City')}
                  // helperText={global._('Common.NotRequired')}
                  variant='filled'
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
          {/* Misc. form container */}
          <div className={classes.container}>
            <Grid container spacing={3} style={{ marginBottom: 1 }}>
              <Grid item xs={12} md={7}>
                <TextField
                  data-cy='input-textfield-name'
                  name='name'
                  autoComplete='name'
                  value={organisationReference}
                  onChange={evt => setOrganisationReference(evt.target.value)}
                  label={global._('RegisterOnboarding.Organisation.Reference')}
                  helperText={global._('Common.Required')}
                  variant='filled'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  data-cy='input-textfield-phone'
                  name='phone'
                  autoComplete='tel'
                  type='phone'
                  value={phone}
                  onChange={evt => setPhone(evt.target.value)}
                  label={global._('RegisterOnboarding.User.Phone')}
                  // helperText={global._('Common.Required')}
                  variant='filled'
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
          {/* User form container */}
          <div className={classes.container}>
            <Grid container spacing={3} style={{ marginBottom: 1 }}>
              <Grid item xs={12}>
                <TextField
                  data-cy='input-textfield-email'
                  name='email'
                  autoComplete='email username'
                  value={email}
                  error={email && !isEmailValid}
                  onChange={evt => setEmail(evt.target.value)}
                  label={global._('RegisterOnboarding.User.Email')}
                  helperText={
                    email && !isEmailValid
                      ? global._('RegisterOnboarding.User.EmailInvalid')
                      : global._('Common.Required')
                  }
                  variant='filled'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Password
                  data-cy='input-textfield-new-password'
                  name='new-password'
                  autoComplete='new-password'
                  value={password}
                  onChange={evt => setPassword(evt.target.value)}
                  error={password && !isPasswordValid}
                  label={global._('RegisterOnboarding.User.Password')}
                  helperText={global._(
                    'RegisterOnboarding.User.PasswordHelpText'
                  )}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Password
                  data-cy='input-textfield-repeat-new-password'
                  name='new-password'
                  autoComplete='new-password'
                  value={passwordRepeat}
                  onChange={evt => setPasswordRepeat(evt.target.value)}
                  error={
                    password &&
                    passwordRepeat &&
                    isPasswordValid &&
                    !arePasswordsEqual
                  }
                  label={global._('RegisterOnboarding.User.PasswordRepeat')}
                  helperText={
                    password &&
                    passwordRepeat &&
                    isPasswordValid &&
                    !arePasswordsEqual
                      ? global._('RegisterOnboarding.User.PasswordMismatch')
                      : ''
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.container}>
            <Row>
              <Checkbox
                style={{ marginRight: 25 }}
                checked={isPolicyAccepted}
                onChange={setIsPolicyAccepted}
              />
              <Typography variant='body'>
                {global._('RegisterOnboarding.ConsentTextPart1')}
                <Link
                  onClick={() => window.open(global._('POLICY_URL'), '_blank')}
                  style={{ paddingLeft: 4 }}
                >
                  {global._('RegisterOnboarding.ConsentTextPart2Link')}
                </Link>
                {global._('RegisterOnboarding.ConsentTextPart3')}
              </Typography>
            </Row>
            {error && (
              <Notification
                type='error'
                message={error}
                style={{ margin: '16px 0' }}
              />
            )}
            <Row flex justify='flex-end' style={{ padding: '20px 0' }}>
              {!isMobile && (
                <Button
                  variant='none'
                  onClick={() => history.push('/')}
                  style={{ marginRight: 10 }}
                >
                  {global._('Common.Cancel')}
                </Button>
              )}
              <Button
                data-cy='button-create-onboarding-account'
                variant='primary'
                disabled={!canProceed}
                loading={isLoading}
                onClick={handleSignup}
              >
                {global._('RegisterOnboarding.Button.Create')}
              </Button>
            </Row>
          </div>
        </div>
      </div>
    )
  }

  if (isMobile) {
    return (
      <Content>
        <NavigationBar
          title={global._('RegisterOnboarding.CreateAccount')}
          backAction={() => history.goBack()}
        />
        <ScrollView fullHeight noBottomPadding>
          {content}
        </ScrollView>
      </Content>
    )
  }
  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>{content}</div>
    </div>
  )
}

RegisterOnboarding.propTypes = {
  classes: PropTypes.object.isRequired,
  setAuthenticationData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  computedMatch: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const RegisterWithStyle = injectSheet(styles)(RegisterOnboarding)
export default RegisterWithStyle
