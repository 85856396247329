import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import qs from 'querystring'
import { FormControl, MenuItem, TextField, Grid } from '@material-ui/core'
import {
  CaseStatus,
  OrderStatus,
  CaseInvoiceStatus,
  UserRoles,
  OrganisationTypes
} from '../../../utils/constants'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Typography from '../../../components/Typography'
import ItemBadge from '../../../components/ItemBadge'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import { DatePicker } from '@material-ui/pickers'
import { Today, Clear } from '@material-ui/icons'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 3,
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
    backgroundColor: 'var(--color-white)',
    marginBottom: 10
  },
  section: {
    borderBottom: '1px solid #eee',
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center'
  },
  '@media (max-width: 900px)': {
    container: {
      boxShadow: 'none',
      marginBottom: 0,
      minHeight: '100%'
    }
  }
}

const Administration = props => {
  const { classes, caseObj, setCaseObjCallback, setError, match } = props

  const { isMobile } = useWindowDimensions()
  const [eligibleCaseManagers, setEligibleCaseManagers] = useState([])
  const [caseManagerOpts, setCaseManagerOpts] = useState()
  const [initialRender, setInitialRender] = useState(true)

  const handleManagerOpts = users => {
    let eligibleManagers = users || eligibleCaseManagers

    if (caseObj.managers && caseObj.managers.length > 0) {
      eligibleManagers = (users || eligibleCaseManagers).filter(
        eligibleManager =>
          caseObj.managers.every(
            assignedManager => assignedManager.userId !== eligibleManager.id
          )
      )
    }

    const managerOpts = eligibleManagers.map(userItem => ({
      label: userItem.name || global._('Common.NoName'),
      value: userItem
    }))
    setCaseManagerOpts(managerOpts)
  }

  const caseUrlType = match.url.includes('/orders') ? 'orders' : 'cases'

  const statuses =
    caseUrlType === 'orders' ? { ...OrderStatus } : { ...CaseStatus }

  useEffect(() => {
    if (initialRender && caseObj && caseObj.organisation) {
      ;(async () => {
        try {
          /* scope to any service desk user */
          const { data: serviceDeskUsers } = await axios.get(
            `/v1/users?${qs.stringify({
              role: UserRoles.Support,
              type: 'managers'
            })}`
          )
          /* scope to users within the partner organisation */
          let partnerAdminUsers = []

          if (
            caseObj &&
            caseObj.organisation &&
            (caseObj.organisation.partnerId ||
              caseObj.organisation.type === OrganisationTypes.Partner)
          ) {
            const { data } = await axios.get(
              `/v1/users?${qs.stringify({
                role: UserRoles.PartnerAdmin,
                organisationId:
                  caseObj.organisation.partnerId || caseObj.organisation.id
              })}`
            )
            partnerAdminUsers = data
          }

          /* add servicedesk tag to support users */
          serviceDeskUsers.forEach(user => {
            user.servicedesk = true
          })

          /* remove duplicate users with prio on keeping support */
          const users = serviceDeskUsers.concat(partnerAdminUsers)
          users.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
          const uniqueUsers = []
          users.forEach(user => {
            if (!uniqueUsers.some(user2 => user2.id === user.id)) {
              uniqueUsers.push(user)
            }
          })

          handleManagerOpts(uniqueUsers)
          setEligibleCaseManagers(uniqueUsers)
        } catch (e) {
          const msg = e.response ? e.response.data : e.message
          setError(global._(msg))
        }

        setInitialRender(false)
      })()
    } else {
      handleManagerOpts()
    }
  }, [caseObj])

  const handleCaseUpdate = async (key, value) => {
    try {
      const { data } = await axios.patch(`v1/${caseUrlType}/${caseObj.id}`, {
        [key]: value
      })
      setCaseObjCallback({ ...caseObj, ...data })
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleAddManager = async user => {
    try {
      const caseManager = {
        user: {
          ...user,
          servicedesk:
            (user.roles && user.roles.includes(UserRoles.Support)) ||
            (user.organisations &&
              user.organisations.some(
                org => org.type === OrganisationTypes.ServiceDesk
              ))
        },
        userId: user.value ? user.value.id : user.id
      }
      const { data } = await axios.post(
        `v1/${caseUrlType}/${caseObj.id}/managers`,
        caseManager
      )
      const newCaseManager = {
        ...data,
        ...caseManager
      }
      const newState = {
        ...caseObj,
        managers: [...caseObj.managers, newCaseManager]
      }
      setCaseObjCallback(newState)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  const handleRemoveManager = async caseManagerInput => {
    try {
      await axios.delete(
        `v1/${caseUrlType}/${caseObj.id}/managers/${caseManagerInput.id}`
      )
      const newState = {
        ...caseObj,
        managers: [
          ...caseObj.managers.filter(
            caseManager => caseManager.id !== caseManagerInput.id
          )
        ]
      }
      setCaseObjCallback(newState)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  if (!props) return <LoadingSpinner />

  return (
    <div className={classes.container}>
      {!isMobile && (
        <div className={classes.section}>
          <Typography variant='subtitleBig' bold>
            {global._('Case.AdministrationTitle')}
          </Typography>
        </div>
      )}
      <div style={{ padding: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                data-cy='input-case-status'
                variant='filled'
                select
                label={global._('Cases.Status')}
                helperText={global._('Case.SetCaseStatus')}
                value={caseObj.status}
                onChange={e => {
                  handleCaseUpdate('status', e.target.value)
                }}>
                {Object.values(statuses).map(type => (
                  <MenuItem key={type} value={type}>
                    {global._(`Case.Status.${type}`)}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          {caseObj && caseObj.status === CaseStatus.Resting && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <DatePicker
                  inputVariant='filled'
                  label={global._('Cases.RestingUntil')}
                  helperText={
                    isMobile
                      ? global._('Cases.RestingReminderDate')
                      : global._('Common.SelectADate')
                  }
                  format='YYYY/MM/DD'
                  InputProps={{
                    endAdornment: caseObj.restingUntilDate ? (
                      <Clear
                        style={{
                          cursor: 'pointer',
                          marginRight: 10,
                          fontSize: 14
                        }}
                        onClick={() =>
                          handleCaseUpdate('restingUntilDate', null)
                        }
                      />
                    ) : (
                      <Today style={{ cursor: 'pointer' }} />
                    )
                  }}
                  cancelLabel={null}
                  okLabel={null}
                  autoOk
                  disablePast
                  value={caseObj.restingUntilDate || null}
                  onChange={date =>
                    handleCaseUpdate(
                      'restingUntilDate',
                      date.format('YYYY-MM-DD')
                    )
                  }
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                data-cy='input-select-invoice-status'
                variant='filled'
                select
                label={global._('Case.SetInvoiceStatus')}
                helperText={global._('Case.SetInvoiceStatus')}
                value={caseObj.invoiced}
                onChange={e => handleCaseUpdate('invoiced', e.target.value)}>
                {Object.values(CaseInvoiceStatus).map(status => (
                  <MenuItem key={status} value={status}>
                    {global._(`Case.InvoiceStatus.${status}`)}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          {caseManagerOpts && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  data-cy='input-choose-manager'
                  variant='filled'
                  select
                  label={global._('Case.ChooseManagerPlaceholder')}
                  helperText={global._('Case.AssignManagerHelptext')}
                  value=''
                  onChange={e => handleAddManager(e.target.value)}>
                  {caseManagerOpts.map(opt => (
                    <MenuItem key={opt.value.id} value={opt.value}>
                      <span>{opt.label}</span>
                      {opt.value.roles && opt.value.roles[0] && (
                        <span style={{ color: 'var(--color-middle-grey)' }}>
                          &nbsp;(
                          {global._(`Case.Roles.${opt.value.roles[0]}`)})
                        </span>
                      )}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          )}
        </Grid>
        {caseObj.managers &&
          caseObj.managers.map(caseManager => (
            <ItemBadge
              key={`manager-list-item-${caseManager.id}`}
              onDelete={() => handleRemoveManager(caseManager)}
              label={
                caseManager &&
                caseManager.user &&
                `${caseManager.user.name || global._('Common.NoName')}${
                  caseManager.user.servicedesk ? ' (Servicedesk)' : ''
                }`
              }
              icon='face'
              containerStyle={{ marginTop: 15 }}
            />
          ))}
      </div>
    </div>
  )
}

Administration.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  caseObj: PropTypes.object.isRequired,
  setCaseObjCallback: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}

const AdministrationWithStyles = injectSheet(styles)(Administration)
export default AdministrationWithStyles
