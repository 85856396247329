/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react'
import Paper from 'src/components/Paper'
import SectionTitle from 'src/components/SectionTitle'
import { FormControl, Grid, MenuItem, TextField } from '@material-ui/core'
import AsyncSelect from 'src/components/AsyncSelect'
import Button from 'src/components/Button'
import { DatePicker } from '@material-ui/pickers'
import { Today } from '@material-ui/icons'
import { OrganisationTypes, ReportParts } from 'src/utils/constants'
import Notification from 'src/common/Notification'
import axios from 'axios'
import { excel as excelExport } from '../../utils/export'
import moment from 'moment'

const Reports = () => {
  const dateFormat = 'YYYY-MM-DD'

  const [organisation, setOrganisation] = useState(null)
  const [allPartners, setAllPartners] = useState()
  const [part, setPart] = useState('all')
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, 'months').day(1).format(dateFormat)
  )
  const [dateTo, setDateTo] = useState(moment().format(dateFormat))
  const [error, setError] = useState(null)

  const getAllPartners = async () => {
    try {
      const { data } = await axios.get(
        `/v1/organisations?orderBy=name&order=asc&type=${OrganisationTypes.Partner}`
      )

      setAllPartners(data)
    } catch (e: any) {
      const msg: any = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  useEffect(() => {
    getAllPartners()
  }, [])

  const generateReport = async () => {
    try {
      const { data: reportData } = await axios.post(`/v1/reports`, {
        organisationId: (organisation && organisation.value.id) || null,
        part,
        dateFrom,
        dateTo
      })

      const sheets: object[] = []

      const weekRange: string[] = []

      let dateStart = moment(dateFrom)

      while (moment(dateTo) > dateStart) {
        weekRange.push(dateStart.format('YYYY-WW'))
        dateStart.add(1, 'week')
      }

      const genSheetData = (part: string) => {
        let partnerColumns: object[] = []
        let partners: object[] = []

        if (organisation) {
          partnerColumns = [
            {
              label: organisation.label,
              value: (row: object) => row[organisation.value.id]
            }
          ]
          partners.push(organisation.value)
        } else {
          partnerColumns = allPartners.map(p => ({
            label: p.name,
            value: (row: object) => row[p.id]
          }))
          partners = allPartners
        }

        const rows: object[] = []

        const partDataPerWeek = {}

        partners.forEach(p => {
          const currentPartnerData = reportData.find(
            rd => rd.organisationId === p.id
          )

          const partData = currentPartnerData.parts[part]

          let totalCount = 0

          weekRange.forEach(week => {
            if (partData) {
              const pd = partData.find(_pd => _pd.weekNumber === week)

              if (pd) {
                totalCount += pd.count
              }

              if (partDataPerWeek[week]) {
                partDataPerWeek[week][p.id] = totalCount
              } else {
                partDataPerWeek[week] = {
                  [p.id]: totalCount
                }
              }
            }
          })
        })

        const rowData = (weekNumber: string, partnerData: object) => {
          let total: number = 0

          Object.keys(partnerData).forEach(d => (total += partnerData[d]))

          let middleData: object = partnerData

          if (part === ReportParts.PARTNERS) {
            const count = partnerData[Object.keys(partnerData)[0]]
            middleData = { partner: partnerData[Object.keys(partnerData)[0]] }
            total = count
          }

          return {
            week: weekNumber,
            ...middleData,
            total
          }
        }

        Object.keys(partDataPerWeek).forEach(week => {
          rows.push(rowData(week, partDataPerWeek[week]))
        })

        let middleColumns = partnerColumns

        if (part === ReportParts.PARTNERS) {
          middleColumns = [
            {
              label: global._('Reports.Partners'),
              value: row => row.partner
            }
          ]
        }

        return {
          name: global._(`Reports.Parts.${part}`),
          columns: [
            {
              label: global._('Reports.WeekEN'),
              value: row => row.week
            },
            ...middleColumns,
            {
              label: global._('Reports.Total'),
              value: row => row.total
            }
          ],
          data: [...rows]
        }
      }

      let sheetData = genSheetData(part)

      if (part === 'all') {
        Object.keys(ReportParts).forEach(p => {
          sheetData = genSheetData(ReportParts[p])

          sheets.push(sheetData)
        })
      } else {
        sheets.push(sheetData)
      }

      excelExport({
        filename: `Generated_report_${moment().format(
          'YYYY.MM.DD_HH.mm.ss'
        )}.xlsx`,
        sheets
      })
    } catch (e: any) {
      const msg: any = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
  }

  return (
    <Paper mediumPadding>
      <div
        style={{
          width: 'calc(100% + 40px)',
          paddingLeft: '20px',
          paddingRight: '20px',
          marginLeft: '-20px',
          marginRight: '-20px',
          marginBottom: '20px',
          borderBottom: '1px solid var(--color-divider-light)'
        }}
      >
        <SectionTitle
          title={global._('Reports.Title')}
          infoDialogTitle={global._('Reports.Title')}
          infoDialogContent={global._('Reports.infoDialogContent')}
        />
      </div>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <FormControl fullWidth>
            <AsyncSelect
              isSearchable
              isClearable={!!organisation}
              value={
                organisation || {
                  value: '',
                  label: global._('Reports.AllPartners')
                }
              }
              labelKey='name'
              name='organisation'
              placeholder={global._('Reports.Partner')}
              onChange={opt => setOrganisation(opt)}
              setAllItems={setAllPartners}
              url={`/v1/organisations?search={{input}}&orderBy=name&order=asc&type=${OrganisationTypes.Partner}`}
            />
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth>
            <TextField
              variant='filled'
              label={global._('Reports.Part')}
              select
              value={part}
              onChange={e => setPart(e.target.value)}
            >
              <MenuItem value='all'>{global._('Reports.AllParts')}</MenuItem>
              {Object.keys(ReportParts).map(p => (
                <MenuItem value={ReportParts[p]}>
                  {global._(`Reports.Parts.${ReportParts[p]}`)}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth>
            <DatePicker
              InputProps={{
                endAdornment: <Today style={{ cursor: 'pointer' }} />
              }}
              inputVariant='filled'
              labelFunc={date => `${global._('Reports.Week')} ${date.week()}`}
              format={dateFormat}
              cancelLabel={null}
              okLabel={null}
              disableFuture
              maxDate={dateTo}
              label={global._('Reports.WeekFrom')}
              autoOk
              value={dateFrom || null}
              onChange={date => {
                setDateFrom(date.startOf('week'))
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth>
            <DatePicker
              InputProps={{
                endAdornment: <Today style={{ cursor: 'pointer' }} />
              }}
              inputVariant='filled'
              labelFunc={date => `${global._('Reports.Week')} ${date.week()}`}
              format={dateFormat}
              cancelLabel={null}
              okLabel={null}
              disableFuture
              label={global._('Reports.WeekTo')}
              autoOk
              minDate={dateFrom}
              value={dateTo || null}
              onChange={date => {
                setDateTo(moment().week() !== date.week() ? date.day(7) : date)
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: 40,
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}
      >
        <Button
          variant='primary'
          disabled={!dateFrom}
          onClick={() => generateReport()}
        >
          {global._('Reports.GenerateReport')}
        </Button>
      </div>
      {error && (
        <Notification style={{ marginTop: 20 }} type='error' message={error} />
      )}
    </Paper>
  )
}

export default Reports
