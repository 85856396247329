import React from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { history } from '../../../../redux/store'

export default {
  icon: () => 'history',
  doNotShowFilter: true,
  doNotShowTable: true,
  linkButtons: opts => {
    const { classes } = opts

    return (
      <>
        {/*  WIP
        <div
          className={classes.linkContainer}
          role='button'
          tabIndex='0'
          onClick={() => history.push('/')}
          onKeyPress={() => history.push('/')}
        >
          <span>
            {global._('Widgets.devices.AllDevicesAndLocks')}
          </span>
          <ChevronRightIcon style={{ marginLeft: 'auto' }} />
        </div>
        <div
          className={classes.linkContainer}
          role='button'
          tabIndex='0'
          onClick={() => history.push('/')}
          onKeyPress={() => history.push('/')}
        >
          <span>
            {global._(filter.search ? 'Widgets.ShowSearchResults' : 'Widgets.devices.AllDevicesAndLocks')}
          </span>
          <ChevronRightIcon style={{ marginLeft: 'auto' }} />
        </div> */}
        <div
          className={classes.linkContainer}
          role='button'
          tabIndex='0'
          onClick={() => history.push('/admin/kee-history')}
          onKeyPress={() => history.push('/admin/kee-history')}
        >
          <span style={{ fontWeight: 600 }}>
            {global._('KeeHistory.ShowAllEvents')}
          </span>
          <ChevronRightIcon style={{ marginLeft: 'auto' }} />
        </div>
      </>
    )
  }
}
