import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { TextField } from '@material-ui/core'
import Button from '../../components/Button'
import Notification from '../../common/Notification'
import Typography from '../../components/Typography'
import useWindowDimensions from '../../utils/useWindowDimensions'
import MobileLogo from '../../components/MobileLogo'
import Password from '../../components/Password'

const styles = {
  root: {},
  formContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 0px'
  },
  thick: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: 0,
    fontFamily: '"Open Sans", sans-serif'
  },
  invisible: {
    border: 0,
    boxShadow: 'none',
    textTransform: 'none',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: 'none'
    }
  },

  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */

  '@media (max-width: 900px)': {
    root: {
      width: '100%'
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
      maxWidth: 500,
      margin: '0 auto'
    }
  },
  input: {
    width: '100%'
  }
}

// eslint-disable-next-line
const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Credentials = props => {
  const { onAuthenticationComplete } = props

  const { isMobile } = useWindowDimensions()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleEmailChange = e => {
    const { value } = e.target
    setEmail(value.toLowerCase())
    setIsValidEmail(emailRegex.test(value))
  }

  const handlePasswordChange = e => {
    const { value } = e.target
    setPassword(value)
  }

  const handleAuth = async () => {
    if (!isValidEmail || !password) return
    setIsLoading(true)

    try {
      const { data } = await axios.post('/v1/auth', { email, password })
      onAuthenticationComplete(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global.translate(msg))
      setIsLoading(false)
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleAuth()
    }
  }

  const { classes } = props
  return (
    <div className={classes.root}>
      {isMobile ? <MobileLogo alt='Digital Låssmed' /> : null}
      <div className={classes.formContainer}>
        {isMobile && (
          <>
            <Typography
              variant='title'
              style={{ marginTop: 30, marginBottom: 20 }}
            >
              {global._('Login.Credentials.Title')}
            </Typography>
          </>
        )}
        {error && (
          <Notification
            type='error'
            message={error}
            style={{ marginTop: 15 }}
          />
        )}
        <div style={{ height: 15 }} />
        <form style={{ width: '100%' }}>
          <TextField
            data-cy='input-username'
            label={global.translate('Login.Credentials.UserName')}
            autoComplete='username'
            onChange={handleEmailChange}
            value={email}
            onKeyDown={handleKeyDown}
            autoFocus
            className={classes.input}
            variant='filled'
          />
          <div style={{ height: isMobile ? 25 : 15 }} />
          <Password
            data-cy='input-password'
            label={global._('Login.Credentials.Password')}
            autoComplete='current-password'
            onChange={handlePasswordChange}
            value={password}
            onKeyDown={handleKeyDown}
          />
          <div style={{ height: 25 }} />
        </form>
        <Button
          data-cy='login-button'
          style={{ width: isMobile ? undefined : 200 }}
          variant='primary'
          size={16}
          disabled={!isValidEmail || !password}
          loading={isLoading}
          onClick={handleAuth}
        >
          <Typography className={classes.thick}>
            {global.translate('Login.Credentials.LoginButton')}
          </Typography>
        </Button>
        <div style={{ height: 20 }} />
        <Button
          variant='none'
          className={classes.invisible}
          onClick={() => props.history.push(`/reset/${email}`)}
          data-cy='forgot-password-button'
        >
          <Typography className={classes.thick}>
            {global.translate('Login.Credentials.RestorePasswordButton')}
          </Typography>
        </Button>
      </div>
    </div>
  )
}

Credentials.propTypes = {
  classes: PropTypes.object.isRequired,
  onAuthenticationComplete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default injectSheet(styles)(Credentials)
