import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import FloatingAlert from './FloatingAlert'

const styles = {}

const SuccessMessage = ({ subtitle, localStorageProp, counter }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  useEffect(() => {
    setShowSuccessMessage(true)

    setTimeout(() => {
      setShowSuccessMessage(false)

      if (localStorageProp) {
        localStorage.removeItem(localStorageProp)
      }
    }, 3000)
  }, [subtitle, counter])

  if (
    localStorageProp &&
    (!localStorage.getItem(localStorageProp) ||
      !JSON.parse(localStorage.getItem(localStorageProp)))
  ) {
    return null
  }

  if (!subtitle) {
    return null
  }

  return (
    showSuccessMessage && (
      <FloatingAlert icon='info' subtitle={subtitle} rightBottom small />
    )
  )
}

SuccessMessage.propTypes = {
  subtitle: PropTypes.string,
  counter: PropTypes.number
}

SuccessMessage.defaultProps = {
  subtitle: null,
  counter: 0
}

const SuccessMessageWithStyles = injectSheet(styles)(SuccessMessage)
export default SuccessMessageWithStyles
