import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import { withRouter } from 'react-router'
import Paper from './Paper'
import Typography from './Typography'
import useWindowDimensions from '../utils/useWindowDimensions'
import Button from './Button'
import Modal from './Modal'
import VideoInstructionsModal from './VideoInstructionsModal'

const styles = {
  root: {
    display: 'flex',
    width: '100%',
    transitionTimingFunction: 'ease-in-out',
    transitionDuration: '300ms',
    transitionProperty: 'all'
  },
  header: {
    padding: 22,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid var(--divider-color)'
  },
  singleHeader: {
    padding: '16px 22px',
    width: '100%',
    borderBottom: '1px solid var(--divider-color)',
    display: 'flex'
  },
  infoIcon: {
    fontSize: 30,
    transition: 'all 0.3s',
    cursor: 'pointer',
    marginTop: 4,
    marginLeft: 10,
    '&:hover': {
      color: 'var(--color-primary)'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  iconContainer: {
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 500,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)'
  },
  spacer: {
    height: 40
  },
  chevron: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 'auto',
    fontSize: 16,
    color: 'rgba(0,0,0,0.75)',
    marginRight: 10
  },
  iconContainerMobile: {
    padding: '0px 28px'
  },
  mobileRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0',
    borderBottom: '1px solid var(--divider-color)'
  },

  '@media (max-width: 900px)': {
    root: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    rootSingle: {}
  }
}

const Widget = ({
  icon,
  title,
  subtitle,
  buttons,
  children,
  classes,
  single,
  url,
  history,
  infoDialog,
  instructionsVideoId
}) => {
  const { isMobile } = useWindowDimensions()
  const [showInfoDialog, setShowInfoDialog] = useState(false)

  if (isMobile) {
    if (single) {
      return children
    }
    return (
      <div className={classes.root} onClick={() => history.push(url)}>
        <div className={classes.iconContainerMobile}>
          {React.cloneElement(icon, {
            style: { color: 'rgba(0,0,0,0.5)', fontSize: 20 }
          })}
        </div>
        <div className={classes.mobileRow}>
          <div className={classes.column}>
            <Typography bold>{subtitle}</Typography>
            <Typography style={{ fontSize: '0.9rem' }} faded>
              {title}
            </Typography>
          </div>
          <div className={classes.chevron}>
            <Icon>chevron_right</Icon>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Grid
      item
      md={12}
      lg={single ? 12 : 6}
      xl={single ? 12 : 4}
      className={classes.root}>
      <Paper
        style={{
          padding: 0,
          width: '100%',
          justifyContent: 'flex-start',
          border: 'none'
        }}>
        {single && (
          <div className={classes.singleHeader}>
            <Typography variant='h3' bold={600} style={{ fontSize: '1.5rem' }}>
              {subtitle}
            </Typography>
            {infoDialog && (
              <Icon
                className={classes.infoIcon}
                onClick={() => setShowInfoDialog(true)}>
                info
              </Icon>
            )}
            {showInfoDialog && (
              <Modal
                title={infoDialog.title()}
                onClose={() => setShowInfoDialog(false)}
                content={infoDialog.content()}
                buttons={[
                  <Button
                    variant='none'
                    onClick={() => setShowInfoDialog(false)}>
                    {global._('Common.Close')}
                  </Button>
                ]}
              />
            )}
            {instructionsVideoId && <VideoInstructionsModal videoId={instructionsVideoId} />}
          </div>
        )}
        {!single && (
          <div className={classes.header}>
            <div className={classes.iconContainer}>
              {React.cloneElement(icon, { style: { fontSize: 30 } })}
            </div>
            <div className={classes.column} style={{ marginLeft: 20 }}>
              <Typography faded variant='body' style={{ fontSize: '0.875rem' }}>
                {title}
              </Typography>
              <Typography variant='subtitle' style={{ lineHeight: 1.1 }}>
                {subtitle}
              </Typography>
            </div>
            <div style={{ marginLeft: 'auto' }}>{buttons}</div>
          </div>
        )}
        {children}
        <div className={classes.spacer} />
      </Paper>
    </Grid>
  )
}

Widget.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  buttons: PropTypes.node,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  single: PropTypes.bool,
  instructionsVideoId: PropTypes.string,
  infoDialog: PropTypes.shape({
    title: PropTypes.func,
    content: PropTypes.func
  })
}

Widget.defaultProps = {
  title: null,
  buttons: null,
  single: false,
  infoDialog: null,
  instructionsVideoId: undefined
}

const WidgetWithStyles = injectSheet(styles)(Widget)
const WidgetWithStylesAndRouter = withRouter(WidgetWithStyles)
export default WidgetWithStylesAndRouter
