import { Icon, Switch } from '@material-ui/core'
import Column from 'src/components/Column'
import Row from 'src/components/Row'
import ScrollView from 'src/components/ScrollView'
import Typography from 'src/components/Typography'
import React from 'react'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import moment from 'moment'

const Picker = ({
  value,
  openTo,
  format,
  icon,
  onChange,
  label,
  disabled,
  unlimited,
  minDate
}) => (
  <KeyboardDateTimePicker
    disabled={disabled}
    disableToolbar
    inputVariant='filled'
    openTo={openTo}
    style={{ width: '100%', marginTop: 15, marginBottom: 6 }}
    label={label}
    format={format}
    value={unlimited ? null : value}
    cancelLabel={global._('Common.Cancel')}
    keyboardIcon={
      <Icon style={{ color: disabled ? 'gray' : 'black' }}>{icon}</Icon>
    }
    okLabel='Ok'
    invalidDateMessage={global._('Common.InvalidDateTime')}
    minDate={minDate}
    minDateMessage={global._('Locks.Share.NotBeforeFrom')}
    strictCompareDates
    onChange={date => {
      onChange(date)
    }}
    ampm={false}
  />
)

const SelectAccessTime = ({ data, onChange }) => (
  <>
    <div>
      <Typography
        variant='body'
        bold
        block
        style={{
          padding: '26px 20px',
          borderBottom: '1px solid var(--color-divider-light)'
        }}
      >
        {global._('Locks.Share.SelectAccessTime')}
      </Typography>
    </div>
    <ScrollView style={{ top: 140 }}>
      <div
        style={{
          padding: 20,
          borderBottom: '1px solid var(--color-divider-light)'
        }}
      >
        <Row justify='space-between'>
          <Typography variant='subtitle'>
            {global._('Locks.Share.Unlimited')}
          </Typography>
          <Switch
            disabled={data.disabled}
            checked={!!data.unlimited}
            onClick={() => onChange('unlimited', !data.unlimited)}
          />
        </Row>
      </div>
      <div
        style={{
          padding: 20,
          borderBottom: '1px solid var(--color-divider-light)'
        }}
      >
        <Column align='flex-start'>
          <Typography variant='subtitle'>{global._('Common.From')}</Typography>
          <Picker
            minDate={
              data.from && moment().isAfter(data.from) ? data.from : moment()
            }
            unlimited={data.unlimited}
            disabled={data.unlimited || data.disabled}
            openTo='date'
            icon='today'
            value={data.from}
            format='YYYY-MM-DD'
            label={global._('Common.ChooseDate')}
            onChange={date => onChange('from', date)}
          />
          <Picker
            minDate={data.from ? data.from : moment()}
            unlimited={data.unlimited}
            disabled={data.unlimited || data.disabled}
            openTo='hours'
            icon='access_time'
            value={data.from}
            format='HH:mm'
            label={global._('Common.ChooseTime')}
            onChange={date => onChange('from', date)}
          />
        </Column>
      </div>
      <div style={{ padding: 20, marginBottom: 20 }}>
        <Column align='flex-start'>
          <Typography variant='subtitle'>{global._('Common.To')}</Typography>
          <Picker
            minDate={data.from ? data.from : moment()}
            unlimited={data.unlimited}
            disabled={data.unlimited || data.disabled}
            openTo='date'
            icon='today'
            value={data.to}
            format='YYYY-MM-DD'
            label={global._('Common.ChooseDate')}
            onChange={date => onChange('to', date)}
          />
          <Picker
            minDate={data.from ? data.from : moment()}
            unlimited={data.unlimited}
            disabled={data.unlimited || data.disabled}
            openTo='hours'
            icon='access_time'
            value={data.to}
            format='HH:mm'
            label={global._('Common.ChooseTime')}
            onChange={date => onChange('to', date)}
          />
        </Column>
      </div>
    </ScrollView>
  </>
)

export default SelectAccessTime
