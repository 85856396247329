import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Icon from '@material-ui/core/Icon'
import Typography from './Typography'

const styles = {
  alert: {
    padding: 16,
    borderRadius: 'var(--default-border-radius)',
    border: '1px solid var(--color-light-grey)',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 32,
    marginRight: 16
  },
  '@media (max-width: 900px)': {
    alert: {
      alignItems: 'flex-start'
    }
  }
}

const Alert = ({
  classes,
  type,
  children,
  style,
  bold,
  dangerouslySetInnerHTML
}) => {
  let icon = type
  let alertBgColor
  let iconBgColor

  if (type === 'success') {
    icon = 'success'
    iconBgColor = 'var(--color-success-green)'
    alertBgColor = 'var(--color-success-green-bg)'
  }

  if (type === 'error') {
    iconBgColor = 'var(--color-error-red)'
    alertBgColor = 'var(--color-error-red-bg)'
  }

  if (type === 'warning') {
    iconBgColor = 'var(--color-warning-orange)'
    alertBgColor = 'var(--color-warning-orange-bg)'
  }

  if (type === 'info') {
    iconBgColor = 'var(--color-primary)'
    alertBgColor = 'var(--color-primary-tone)'
  }

  return (
    <div
      className={classes.alert}
      style={{ ...style, backgroundColor: alertBgColor }}>
      <Icon style={{ color: iconBgColor }} className={classes.icon}>
        {icon}
      </Icon>
      <Typography
        variant='body'
        bold={bold}
        style={{ width: '100%' }}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
        {children}
      </Typography>
    </div>
  )
}

Alert.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  bold: PropTypes.bool
}

Alert.defaultProps = {
  type: 'info',
  style: {},
  bold: false,
  children: null
}

export default injectSheet(styles)(Alert)
