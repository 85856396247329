import { connect } from 'react-redux'
import Component from './PublicLayout'

const mapStateToProps = state => ({
  user: state.login.user,
  token: state.login.token,
  expires: state.login.expires
})

export default connect(mapStateToProps, null)(Component)
