import { connect } from 'react-redux'
import {
  toggleDrawer,
  toggleFeedbackModal,
  toggleUserBar,
  handleCreateFeedback,
  handleReverseImpersonate,
  toggleDeleteConfirmation,
  remove,
  handleDeleteInput
} from '../../redux/reducers/layout'
import PrivateLayout from './PrivateLayoutV2'
import { signout } from '../../redux/reducers/login'

const mapStateToProps = state => ({
  user: state.login.user,
  token: state.login.token,
  expires: state.login.expires,
  ...state.layout
})

const mapDispatchToProps = dispatch => ({
  toggleDrawer: () => {
    dispatch(toggleDrawer())
  },
  toggleUserBar: target => {
    dispatch(toggleUserBar(target))
  },
  signout: () => {
    dispatch(signout())
  },
  toggleFeedbackModal: () => {
    dispatch(toggleFeedbackModal())
  },
  handleCreateFeedback: feedback => {
    dispatch(handleCreateFeedback(feedback))
  },
  handleReverseImpersonate: () => {
    dispatch(handleReverseImpersonate())
  },
  toggleDeleteConfirmation: ({
    content,
    onConfirm,
    requiredInput,
    deleteConfirmationObject
  }) => {
    dispatch(
      toggleDeleteConfirmation({
        content,
        onConfirm,
        requiredInput,
        deleteConfirmationObject
      })
    )
  },
  remove: () => {
    dispatch(remove())
  },
  handleDeleteInput: input => {
    dispatch(handleDeleteInput(input))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayout)
