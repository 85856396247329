import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import Typography from '../Typography'
import Theme from '../../helpers/Theme'

const NavigationItem = ({
  divider,
  title,
  icon,
  disabled,
  onClick,
  active,
  highlighted,
  dataCy
}) => {
  const [isHovering, setIsHovering] = useState(false)
  if (divider) {
    return (
      <div style={{ height: 1, backgroundColor: Theme.Colors.LIGHT_GREY_2 }} />
    )
  }

  const stateStyles = {}
  const containerStyle = {
    cursor: !disabled && onClick ? 'pointer' : 'default',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12.5px 20px',
    backgroundColor:
      isHovering && !disabled && onClick
        ? '#f2f2f2'
        : 'var(--color-ultra-light-grey)'
  }

  if (active) {
    containerStyle.paddingLeft = 25
    containerStyle.borderLeft = `5px solid ${Theme.Colors.PRIMARY}`
  }
  if (highlighted) {
    containerStyle.backgroundColor = isHovering
      ? Theme.Colors.PRIMARY_TONE_4
      : 'var(--color-ultra-light-grey)'
    containerStyle.color = Theme.Colors.LOGO_BLACK
    containerStyle.borderBottom = '1px solid transparent'
    stateStyles.color = Theme.Colors.LOGO_BLACK
  }

  if (disabled) {
    stateStyles.color = '#8e8e8e'
  }

  return (
    <div
      data-cy={dataCy}
      style={containerStyle}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={e => {
        if (disabled || !onClick) return
        onClick(e)
      }}>
      <Typography style={{ ...stateStyles, fontSize: 16, fontWeight: 600 }}>
        {title}
      </Typography>
      <Icon style={stateStyles}>{icon}</Icon>
    </div>
  )
}

NavigationItem.propTypes = {
  divider: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  highlighted: PropTypes.bool,
  onClick: PropTypes.func,
  dataCy: PropTypes.string
}

NavigationItem.defaultProps = {
  divider: false,
  title: '',
  icon: '',
  disabled: false,
  onClick: null,
  active: false,
  highlighted: false,
  dataCy: ''
}

export default NavigationItem
