import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import ScrollView from 'src/components/ScrollView'
import MobileContainer from 'src/components/MobileContainer'
import MobileLogo from 'src/components/MobileLogo'
import LocksList from 'src/components/LocksList'
import { isLockDisabled, publicRequest } from 'src/utils/helpers'
import { Variants as PWAVariants, setPWA } from 'src/utils/PWASelector'
import AddToHomescreen from 'src/components/AddToHomescreen'
import { connect } from 'react-redux'
import { toggleInstallationModal } from 'src/redux/reducers/login'
import Button from 'src/components/Button'
import VerifyAccessCode from './VerifyAccessCode'

const styles = {}

const IS_TEST = !window.location.origin.startsWith(
  'https://portal.digitallassmed.se'
)

const PublicAccessView = props => {
  const { user, history, match } = props

  const { delegatedAccessUserUuid } = match.params

  const [isVerified, setIsVerified] = useState(
    localStorage.getItem('public.access') === delegatedAccessUserUuid
  )
  const [locks, setLocks] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const getLocks = async () => {
    setIsLoading(true)
    setError(null)
    setLocks([])
    try {
      const { data } = await publicRequest('get', '/v1/access/public/locks')
      setLocks(data)
    } catch (e) {
      if (e.response && e.response.data === 'Error.Unauthorized') {
        localStorage.removeItem('public.token')
        localStorage.removeItem('public.exchangeToken')
        localStorage.removeItem('public.access')
        setIsVerified(false)
      } else {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
      setLocks([])
    }
    setIsLoading(false)
  }

  const logout = () => {
    localStorage.removeItem('public.token')
    localStorage.removeItem('public.exchangeToken')
    localStorage.removeItem('public.access')
    localStorage.removeItem('pwa.disabledUntil')
    setIsVerified(false)

    history.push('/')
  }

  /* Initial load */
  useEffect(() => {
    if (isVerified) {
      setPWA(PWAVariants.ACCESS, {
        accessUserUuid: delegatedAccessUserUuid,
        exchangeToken: localStorage.getItem('public.exchangeToken')
      })
      const pwaDisabledUntil =
        Number.parseInt(localStorage.getItem('pwa.disabledUntil'), 10) || 0
      const now = Date.now()
      if (now > pwaDisabledUntil) {
        /* disable for 12 hours */
        const expire = now + 12 * 60 * 60 * 1e3
        localStorage.setItem('pwa.disabledUntil', expire)
        props.toggleInstallationModal(true)
      }
      getLocks()
    }

    if (!isVerified) {
      const urlParams = new URLSearchParams(window.location.search)
      const exchangeTokenInURL = urlParams.get('exchangeToken')
      if (exchangeTokenInURL) {
        // most likely saved as PWA with exchangeToken in URL
        ;(async () => {
          // consumes the one time "exchangeToken" token for a secret and a new exchange token
          try {
            const { data } = await publicRequest(
              'post',
              '/v1/access/public/exchangetoken/secret',
              null,
              exchangeTokenInURL
            )
            const { id, secret, exchangeToken } = data
            const encodedSecret = btoa(`${id}:${secret}`)
            const encodedExchangeToken = btoa(`${id}:${exchangeToken}`)
            localStorage.setItem('public.token', encodedSecret)
            localStorage.setItem('public.exchangeToken', encodedExchangeToken)
            localStorage.setItem('public.access', delegatedAccessUserUuid)
            setIsVerified(true)
          } catch (e) {}
        })()
      }
    }
  }, [isVerified])

  if (!isVerified) {
    return (
      <VerifyAccessCode
        accessUserUuid={delegatedAccessUserUuid}
        onCompletion={() => setIsVerified(true)}
      />
    )
  }

  return (
    <>
      <MobileContainer showTabBar={false}>
        <ScrollView noTabBar noNavigationBar fullHeight>
          <div
            style={{
              background: 'var(--color-white)',
              padding: '22px var(--section-padding-default)',
              borderBottom: '1px solid var(--color-divider-light)'
            }}
          >
            <MobileLogo defaultLogotype />
          </div>
          <LocksList
            shared
            history={history}
            user={user}
            getLocks={getLocks}
            setLocks={setLocks}
            setError={setError}
            isLoading={isLoading}
            locks={locks}
            error={error}
            lockOnClick={lock => history.push(`/access/${lock.id}`)}
            noAccess={lock => isLockDisabled(lock)}
          />
          {IS_TEST && (
            <Button
              variant='none'
              style={{ color: 'var(--color-error-tone-2' }}
              onClick={logout}
            >
              Avsluta sessionen
            </Button>
          )}
        </ScrollView>
      </MobileContainer>
      <AddToHomescreen variant={PWAVariants.ACCESS} />
    </>
  )
}

PublicAccessView.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object.isRequired
}

PublicAccessView.defaultProps = {
  history: null
}

const mapDispatchToProps = dispatch => ({
  toggleInstallationModal: open => dispatch(toggleInstallationModal(open))
})

export default connect(
  null,
  mapDispatchToProps
)(injectSheet(styles)(PublicAccessView))
