import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import * as Yup from 'yup'
import { MenuItem, TextField } from '@material-ui/core'
import { Form, Formik } from 'formik'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import Notification from '../../../common/Notification'
import FullscreenModal from '../../../components/FullscreenModal'

const styles = {
  root: {},
  input: {
    width: '100%'
  },
  submitBtnContainer: {
    marginTop: 20
  },
  icon: {
    fontSize: '18px!important',
    width: '40px!important',
    height: '40px!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: 'var(--color-light-grey)',
    color: 'var(--color-middle-grey)',
    cursor: 'pointer'
  },
  iconEmpty: {
    backgroundColor: 'var(--color-white)'
  }
}

const AddLockModal = props => {
  const {
    classes,
    availableRelays,
    setShowCreateLockModal,
    currentRelay,
    handleCreateLock,
    isMobile,
    error
  } = props
  const addLockFields = (values, handleChange, handleBlur, errors) => (
    <>
      <TextField
        onChange={handleChange}
        error={errors.name}
        onClick={handleBlur}
        value={values.name}
        name='name'
        className={classes.input}
        label={global._('Name')}
        helperText={global._('Common.Required')}
        variant='filled'
        style={{ marginBottom: 20 }}
      />
      <TextField
        onChange={handleChange}
        error={errors.relay}
        onBlur={handleBlur}
        value={values.relay}
        onClick={handleBlur}
        name='relay'
        helperText={global._('Common.Required')}
        className={classes.input}
        label={global._('Device.Relay')}
        variant='filled'
        select>
        {availableRelays.map(
          lock =>
            !lock.createdAt && (
              <MenuItem value={lock.relay} key={`lock-${lock.relay}`}>
                {lock.relay}
              </MenuItem>
            )
        )}
      </TextField>
    </>
  )

  return (
    <Formik
      initialValues={{
        name: '',
        relay: currentRelay
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(global._('Common.Required')),
        relay: Yup.string().required(global._('Common.Required'))
      })}>
      {({ errors, touched, handleChange, values, handleBlur }) => (
        <Form>
          {isMobile ? (
            <FullscreenModal
              title={global._('Device.AddLock')}
              padding
              higherZIndex
              content={
                <div>
                  {error && (
                    <Notification
                      type='error'
                      message={error}
                      style={{ margin: '20px 0' }}
                    />
                  )}
                  {addLockFields(values, handleChange, handleBlur, errors)}
                </div>
              }
              buttons={
                <Button
                  variant='primary'
                  disabled={
                    Object.keys(touched).length === 0 ||
                    Object.keys(errors).length
                  }
                  onClick={() => {
                    handleCreateLock(values)
                  }}>
                  {global._('Common.Save')}
                </Button>
              }
              onClose={() => {
                setShowCreateLockModal(false)
              }}
            />
          ) : (
            <Modal
              onClose={() => setShowCreateLockModal(false)}
              title={global._('Device.AddLock')}
              content={
                <div style={{ textAlign: 'left' }}>
                  {error && (
                    <Notification
                      type='error'
                      message={error}
                      style={{ margin: '20px 0' }}
                    />
                  )}
                  {addLockFields(values, handleChange, handleBlur, errors)}
                </div>
              }
              buttons={[
                <Button
                  variant='none'
                  onClick={() => setShowCreateLockModal(false)}>
                  {global._('Common.Cancel')}
                </Button>,
                <Button
                  variant='primary'
                  disabled={
                    Object.keys(touched).length === 0 ||
                    Object.keys(errors).length
                  }
                  onClick={() => {
                    handleCreateLock(values)
                  }}>
                  {global._('Common.Add')}
                </Button>
              ]}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

AddLockModal.propTypes = {
  classes: PropTypes.object.isRequired,
  availableRelays: PropTypes.array,
  setShowCreateLockModal: PropTypes.func.isRequired,
  currentRelay: PropTypes.number,
  handleCreateLock: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

AddLockModal.defaultProps = {
  isMobile: false,
  availableRelays: [],
  currentRelay: null,
  error: null
}

const AddLockModalWithStyles = injectSheet(styles)(AddLockModal)
export default AddLockModalWithStyles
