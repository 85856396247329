import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'
import { sleep } from 'src/utils/helpers'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import Input from '../../components/Input'
import Notification from '../../common/Notification'
import ssn from '../../utils/ssn'
import { getUserMessage } from '../../utils/BankIDClientMessages'
import MobileLogo from '../../components/MobileLogo'
import SsnInput from '../../components/SsnInput'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%'
  },
  feedback: {
    fontSize: 14,
    textAlign: 'center',
    color: '#3d3d3d'
  },
  '@media (max-width: 900px)': {
    root: {
      width: '100%'
    }
  }
}

const BankId = props => {
  const [personalNumber, setPersonalNumber] = useState('')
  const [isValidPersonalNumber, setIsValidPersonalNumber] = useState(false)
  const [isCollecting, setIsCollecting] = useState(false)
  const [autoStartToken, setAutoStartToken] = useState(null)
  const [orderRef, setOrderRef] = useState(null)
  const [status, setStatus] = useState(null)
  const [hintCode, setHintCode] = useState(null)
  const [error, setError] = useState(null)

  const { classes, onCancel, onAuthenticationComplete, sameDevice } = props

  const cancelAuth = async () => {
    if (orderRef) {
      try {
        await axios.delete(`/v1/auth/bankid/${orderRef}`)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global.translate(msg))
      }
    }
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const collectAuth = async anOrderRef => {
    const url = `/v1/auth/bankid/${anOrderRef}`
    const { data } = await axios.get(url)
    setStatus(data.status)
    setHintCode(data.hintCode)
    if (data.status === 'pending') {
      await sleep(2000)
      return collectAuth(anOrderRef)
    }
    if (data.status === 'complete') {
      return data
    }
    if (data.status === 'failed') {
      setHintCode(data.hintCode)
      setStatus(data.status)
      return null
    }
    return null
  }

  const handleAuth = async () => {
    if (!sameDevice && !isValidPersonalNumber) return
    setStatus(null)
    setHintCode(null)
    setError(null)
    setOrderRef(null)
    setAutoStartToken(null)

    let authData
    try {
      setStatus('internal')
      setHintCode('loading')
      const { data } = await axios.post('/v1/auth/bankid', {
        personalNumber,
        sameDevice
      })
      setOrderRef(data.orderRef)
      setAutoStartToken(data.autoStartToken)
      setIsCollecting(true)
      authData = await collectAuth(data.orderRef)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global.translate(msg))
      setIsCollecting(false)
    }

    // If request was successful
    if (authData) {
      onAuthenticationComplete(authData)
    }
  }

  useEffect(() => {
    if (sameDevice) {
      handleAuth()
    }
  }, [])

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      handleAuth()
    }
  }

  if (error || status === 'failed') {
    return (
      <>
        <Notification
          type='error'
          message={error || getUserMessage(status, hintCode)}
        />
        <Button
          variant='none'
          onClick={cancelAuth}
          style={{
            marginTop: 15
          }}
        >
          {global.translate('Common.Back')}
        </Button>
      </>
    )
  }
  if (isCollecting) {
    return (
      <div
        className={classes.root}
        style={{ alignItems: 'center', paddingTop: 50 }}
      >
        <CircularProgress
          size={40}
          thickness={6}
          style={{ color: 'var(--color-primary)', margin: '30px 0 20px 0' }}
        />
        {(hintCode !== 'outstandingTransaction' || !sameDevice) && (
          <span className={classes.feedback}>
            {getUserMessage(status, hintCode)}
          </span>
        )}
        <div style={{ height: 25 }} />
        {sameDevice && autoStartToken && hintCode !== 'userSign' && (
          <Button
            variant='secondary'
            onClick={() => {
              window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 4px 8px 20px',
              width: '100%',
              marginTop: 15
            }}
          >
            <span>{global._('Login.BankId.OpenBankIdApplication')}</span>
            <img height={42} src='/logos/bankid-white.png' />
          </Button>
        )}
        <Button variant='none' onClick={cancelAuth}>
          {global.translate('Common.Cancel')}
        </Button>
      </div>
    )
  }

  if (!sameDevice) {
    return (
      <div className={classes.root}>
        <MobileLogo alt='logotype' />
        <Typography
          bold={600}
          style={{
            fontSize: '1.25rem',
            margin: '30px 20px 30px 0',
            lineHeight: 1.2
          }}
        >
          {global._('Login.BankId.OtherDeviceDescription')}
        </Typography>
        <SsnInput
          data-cy='input-personal-number'
          label={global._('PersonalNumber.Label')}
          onSsnValid={({ valid, ssn }) => {
            setPersonalNumber(ssn)
            setIsValidPersonalNumber(valid)
          }}
          autoFocus
        />
        <Button
          data-cy='login-bankid-button'
          variant='secondary'
          onClick={handleAuth}
          disabled={!isValidPersonalNumber}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 4px 8px 20px',
            width: '100%',
            marginTop: 20
          }}
        >
          <span>{global._('Login.Credentials.LoginButton')}</span>
          <img height={42} src='/logos/bankid-white.png' />
        </Button>
        <div style={{ height: 15 }} />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button variant='none' onClick={cancelAuth}>
            {global.translate('Common.Cancel')}
          </Button>
        </div>
        {error && (
          <Notification
            type='error'
            message={error}
            style={{ marginTop: 15 }}
          />
        )}
      </div>
    )
  }

  return <div />
}

BankId.propTypes = {
  classes: PropTypes.object.isRequired,
  onAuthenticationComplete: PropTypes.func.isRequired,
  sameDevice: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default injectSheet(styles)(BankId)
