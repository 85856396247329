/**
 * Fetches data from URL and displays it
 * @constructor
 * @param {string} method - Defaults to GET
 * @param {string} url - URL to fetch objects to list, e.g /v1/users
 * @param {boolean} isClearable - Toggles the ability to clear current selection
 * @param {string} labelKey - Key for the property that should be used as label
 * @param {boolean} cacheOptions - Cache fetched options
 */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import Select from 'react-select/async'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import axios from 'axios'
import { Icon } from '@material-ui/core'

/* eslint-disable */
const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== components.Placeholder ? child : null
      )}
    </components.ValueContainer>
  )
}
/* eslint-enable */

const AsyncSelect = ({
  method,
  url,
  isClearable,
  disabled,
  labelKey,
  label,
  noOptionsMessage,
  cacheOptions,
  helpText,
  dropdownIcon,
  filter,
  selectOnLoad,
  preselectedId,
  scope,
  ...otherProps
}) => {
  const _initialLoad = useRef(false)
  const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <Icon>{dropdownIcon}</Icon>
    </components.DropdownIndicator>
  )

  const handleUrls = async inputValue => {
    let resultData = []
    const promises = []

    url.forEach(urlItem => {
      promises.push(axios[method](urlItem.replace('{{input}}', inputValue)))
    })

    const results = await Promise.all(promises)
    results.forEach(result => {
      resultData = resultData.concat(result.data)
    })

    return resultData
  }

  return (
    <FormControl style={{ width: '100%', ...otherProps.style }}>
      <Select
        {...otherProps}
        isDisabled={disabled}
        isClearable={isClearable}
        noOptionsMessage={
          noOptionsMessage || (() => global._('Common.NoOptionsMessage'))
        }
        defaultOptions
        components={{ DropdownIndicator, ValueContainer }}
        cacheOptions={cacheOptions}
        loadOptions={async inputValue => {
          let resultData
          if (typeof url === 'string') {
            const { data } = await axios[method](
              url.replace('{{input}}', inputValue)
            )
            resultData = data
          } else if (Array.isArray(url)) {
            resultData = await handleUrls(inputValue)
          }

          if (typeof filter === 'function') {
            resultData = filter(resultData)
          }

          const labelObj = object => {
            if (typeof label === 'function') {
              return label(object)
            }

            if (scope) {
              const orgServices = object.services

              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {scope && (
                    <span
                      style={{
                        paddingLeft: 16,
                        height: 16,
                        backgroundColor:
                          orgServices &&
                          orgServices[0] &&
                          !orgServices[0][scope]
                            ? 'var(--color-error-red)'
                            : 'var(--color-success-green)',
                        borderRadius: '100%',
                        marginRight: 10,
                        display: 'inline-block'
                      }}
                    />
                  )}
                  <span>{object.name}</span>
                </span>
              )
            }

            return object[labelKey] || global._('Common.NoName')
          }

          const options = resultData.map(object => ({
            value: object,
            label: labelObj(object)
          }))

          if (
            !_initialLoad.current &&
            preselectedId &&
            typeof otherProps.onChange === 'function'
          ) {
            const opt = options.find(o => o.value.id == preselectedId)
            if (opt) {
              otherProps.onChange(opt)
            }
            _initialLoad.current = true
          } else if (
            selectOnLoad &&
            options.length &&
            typeof otherProps.onChange === 'function'
          ) {
            otherProps.onChange(options[0])
          }

          return options
        }}
        styles={{
          dropdownIndicator: (provided, state) => ({
            marginRight: 10,
            color: state.isDisabled ? '#ccc' : undefined,
            cursor: 'pointer',
            '& .MuiIcon-root': {
              verticalAlign: 'middle'
            }
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            transition: 'margin 0.1s',
            overflow: 'visible'
          }),
          indicatorSeparator: () => ({}),
          container: (provided, state) => ({
            ...provided,
            height: 63,
            zIndex: state.isFocused ? 100 : 99
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            top:
              state.hasValue ||
              state.selectProps?.inputValue ||
              state.selectProps?.menuIsOpen
                ? 0
                : '',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize:
              (state.hasValue ||
                state.selectProps?.inputValue ||
                state.selectProps?.menuIsOpen) &&
              12,
            color:
              state.isFocused || state.isMenuOpen
                ? 'rgba(0,0,0,0.5)'
                : 'rgba(0,0,0,0.6)'
          }),
          singleValue: (provided, state) => ({
            ...provided,
            marginTop: 20,
            color: state.isDisabled
              ? 'var(--color-input-border-disabled)'
              : 'inherit'
          }),
          input: (provided, state) => ({
            ...provided,
            marginTop:
              state.hasValue ||
              state.selectProps?.inputValue ||
              state.selectProps?.menuIsOpen
                ? 20
                : 5
          }),
          control: (provided, state) => ({
            ...provided,
            height: '100%',
            padding: '0px 6px',
            fontWeight: 500,
            fontSize: '1rem',
            backgroundColor: `var(--color-input-default) ${
              state.isFocused ? '!important' : ''
            }`,
            borderRadius: 0,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            outline: 'none',
            border: 'none',
            boxShadow: 'none',
            transition: 'background-color 100ms',
            '&:hover': {
              backgroundColor: 'rgb(232,232,232)',
              borderColor: 'var(--color-dark-grey)'
            },
            borderColor: 'var(--color-middle-grey)',
            borderBottom: '2px solid var(--color-black)',
            '&::after': {
              position: 'absolute',
              bottom: -2,
              left: 0,
              display: 'block',
              width: '100%',
              transition: 'transform 200ms',
              content: '""',
              height: 2,
              backgroundColor: 'var(--color-primary)',
              transformOrigin: 'bottom center',
              transform:
                state.isFocused || state.isMenuOpen ? 'scaleX(1)' : 'scaleX(0)'
            }
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? 'var(--color-input-selected)'
              : undefined,
            color: 'var(--color-black)',
            fontSize: '16px',
            lineHeight: 2.5,
            padding: '4px 16px',
            fontFamily: '"Open Sans", Roboto, Arial, sans-serif',
            cursor: 'pointer',
            transition:
              'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '&:hover': {
              backgroundColor: 'var(--color-material-grey)'
            }
          }),
          menu: provided => ({
            ...provided,
            boxShadow:
              '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
            borderRadius: '4px',
            border: 'none'
          }),
          menuList: provided => ({
            ...provided,
            paddingTop: 8,
            paddingBottom: 8
          })
        }}
      />
      {helpText && (
        <FormHelperText
          style={{
            marginLeft: 12
          }}
        >
          {helpText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

AsyncSelect.propTypes = {
  method: PropTypes.string,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  labelKey: PropTypes.string,
  label: PropTypes.func,
  noOptionsMessage: PropTypes.func,
  dropdownIcon: PropTypes.string,
  cacheOptions: PropTypes.bool,
  helpText: PropTypes.string,
  filter: PropTypes.func,
  preselectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectOnLoad: PropTypes.bool
}

AsyncSelect.defaultProps = {
  method: 'get',
  isClearable: true,
  disabled: false,
  noOptionsMessage: () => global._('Common.NoOptionsMessage'),
  cacheOptions: true,
  dropdownIcon: 'arrow_drop_down',
  label: null,
  labelKey: 'name',
  helpText: null,
  filter: null,
  preselectedId: null,
  selectOnLoad: false
}

export default AsyncSelect
