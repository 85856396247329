import React, { useLayoutEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'

const Image = forwardRef((props, ref) => {
  const {
    src,
    token,
    onUpdate,
    zoom,
    delta,
    mobile,
    width,
    height,
    onClick,
    rotationDegrees
  } = props

  useLayoutEffect(() => {
    onUpdate()
  }, [zoom])

  const onLoad = () => {
    if (typeof onUpdate === 'function') {
      onUpdate(ref.current.naturalWidth, ref.current.naturalHeight)
    }
  }

  if (mobile) {
    return (
      <img
        onClick={onClick}
        src={`${src}?clientWidth=${window.innerWidth}&clientHeight=${window.innerHeight}&token=${token}`}
        alt=''
        ref={ref}
        onLoad={onLoad}
        style={{
          transform: `translate3d(${delta.x}px, ${delta.y}px, 0) scale(${zoom}) rotate(${rotationDegrees}deg)`,
          transformOrigin: '0 0',
          width,
          height
        }}
      />
    )
  }

  const maxWidth = `${100 * zoom}%`
  const maxHeight = `${100 * zoom}%`

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
        userSelect: 'none'
      }}>
      <img
        src={`${src}?clientWidth=${window.innerWidth}&clientHeight=${window.innerHeight}&token=${token}`}
        alt=''
        ref={ref}
        style={{
          maxWidth,
          maxHeight,
          width: 'auto',
          height: 'auto',
          transform: `scale(${zoom}) rotate(${rotationDegrees}deg)`,
          marginTop: delta.y,
          marginLeft: delta.x,
          userSelect: 'none'
        }}
        onLoad={onUpdate}
      />
    </div>
  )
})

Image.propTypes = {
  src: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
  delta: PropTypes.object.isRequired,
  rotationDegrees: PropTypes.number.isRequired
}

export default Image
