import React, { useState, useEffect, useRef } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import axios from 'axios'
import PropTypes from 'prop-types'
import Typography from '../../../components/Typography'
import Theme from '../../../helpers/Theme'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Row from '../../../components/Row'
import Modal from '../../../components/Modal'
import NavigationList from '../../../components/NavigationList'
import NavigationBar from '../../../components/NavigationBar'
import FullscreenModal from '../../../components/FullscreenModal'
import ScrollView from '../../../components/ScrollView'
import Column from '../../../components/Column'
import LoadingSpinner from '../../../components/LoadingSpinner'
import MobileContainer from '../../../components/MobileContainer'
import ObjectListMobile from '../../../components/ObjectListMobile'
import CenteredFloatingButtonMobile from '../../../components/CenteredFloatingButtonMobile'

const styles = {
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    marginTop: 1
  },
  containerMobile: {
    borderBottom: '1px solid rgba(0,0,0, 0.12)',
    width: '80%',
    paddingBottom: 16
  },
  rowMobile: {
    width: '100%',
    marginBottom: 20
  },
  fullWidthRow: {
    width: 'calc(100% + 40px)',
    marginBottom: 20,
    marginLeft: '-20px',
    marginRight: '-20px'
  }
}

const PropertyDetailsMobile = props => {
  const {
    match,
    classes,
    setSnackbar,
    location,
    history,
    onDeleteProperty,
    setShowBlueprintUploadModal,
    showBlueprintUploadModal,
    user,
    BlueprintUploadModal
  } = props

  const { propertyId } = match.params
  const [property, setProperty] = useState(null)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [isEditingName, setIsEditingName] = useState(false)
  const [isNameUpdated, setIsNameUpdated] = useState(false)
  const [address, setAddress] = useState('')
  const [isEditingAddress, setIsEditingAddress] = useState(false)
  const [isAddressUpdated, setIsAddressUpdated] = useState(false)
  const [error, setError] = useState(null)
  const [showDeletePropertyModal, setShowDeletePropertyModal] = useState(false)
  const [deleteBlueprintObj, setDeleteBlueprintObj] = useState(null)
  const [showBlueprintsModal, setShowBlueprintsModal] = useState(
    history && history.location && history.location.hash === '#blueprints'
  )
  const nameInputRef = useRef()
  const addressInputRef = useRef()

  useEffect(() => {
    ;(async () => {
      try {
        const { data: aProperty } = await axios.get(
          `/v1/properties/${propertyId}?blueprints=true`
        )
        setProperty(aProperty)
        setName(aProperty.name)
        setAddress(aProperty.address)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setSnackbar(global._(msg), 'error')
      }
    })()
  }, [])

  const onDeleteBlueprint = async () => {
    const blueprint = deleteBlueprintObj

    try {
      await axios.delete(`/v1/blueprints/${blueprint.id}`)
      const aProperty = { ...property }
      aProperty.blueprints = aProperty.blueprints.filter(
        bp => bp.id !== blueprint.id
      )
      setProperty(aProperty)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setSnackbar(global._(msg), 'error')
    }
    setDeleteBlueprintObj(null)
  }

  const handleUpdate = async event => {
    event.preventDefault()
    event.stopPropagation()

    try {
      const data = {}
      if (name !== property.name) {
        data.name = name
      }
      if (address !== property.address) {
        data.address = address
      }

      data.organisationId = property.organisationId

      if (Object.keys(data).length > 0) {
        const res = await axios.patch(`/v1/properties/${property.id}`, data)
        setProperty({
          ...property,
          ...res.data
        })
        if (Object.prototype.hasOwnProperty.call(data, 'name')) {
          setIsNameUpdated(true)
          setTimeout(() => {
            setIsNameUpdated(false)
          }, 2000)
        }
        if (Object.prototype.hasOwnProperty.call(data, 'address')) {
          setIsAddressUpdated(true)
          setTimeout(() => {
            setIsAddressUpdated(false)
          }, 2000)
        }
      }
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setTimeout(() => {
      setIsEditingAddress(false)

      if (!nameError) {
        setIsEditingName(false)
      }
    }, 200)
  }

  const canUpdateName = () => {
    if (isEditingName && name?.length < 3) {
      setNameError(true)
      return false
    }

    return true
  }

  const handleNameChange = e => {
    const { value } = e.target
    if (value.length > 2 && nameError) {
      setNameError(false)
    }

    setName(value)
  }

  if (!property) return <LoadingSpinner flex />

  let nameInputAdornment
  if (isEditingName) {
    nameInputAdornment = (
      <Button
        style={{
          position: 'absolute',
          padding: 0,
          top: 2,
          right: 10,
          height: 6,
          width: 49
        }}
        variant='noHover'
        onClick={handleUpdate}
      >
        {global._('Common.Save')}
      </Button>
    )
  } else if (isNameUpdated) {
    nameInputAdornment = (
      <Icon
        style={{
          position: 'absolute',
          top: 14,
          right: 10,
          color: Theme.Colors.PRIMARY
        }}
      >
        check_circle
      </Icon>
    )
  } else if (!isEditingName) {
    nameInputAdornment = (
      <Button
        style={{
          position: 'absolute',
          top: 14,
          right: 10,
          width: 24,
          height: 24
        }}
        variant='icon'
        icon='edit'
        onClick={() => {
          setIsEditingName(true)
          setTimeout(() => {
            if (nameInputRef.current) {
              nameInputRef.current.focus()
            }
          }, 100)
        }}
      />
    )
  }

  let addressInputAdornment
  if (isEditingAddress || addressInputRef.focus) {
    addressInputAdornment = (
      <Button
        style={{
          position: 'absolute',
          padding: 0,
          top: 2,
          right: 10,
          height: 6,
          width: 49
        }}
        variant='noHover'
        onClick={handleUpdate}
      >
        {global._('Common.Save')}
      </Button>
    )
  } else if (isAddressUpdated) {
    addressInputAdornment = (
      <Icon
        style={{
          position: 'absolute',
          top: 14,
          right: 10,
          width: 24,
          height: 24,
          color: Theme.Colors.PRIMARY
        }}
      >
        check_circle
      </Icon>
    )
  } else if (!isEditingAddress) {
    addressInputAdornment = (
      <Button
        style={{
          position: 'absolute',
          top: 14,
          right: 10,
          width: 24,
          height: 24
        }}
        variant='icon'
        icon='edit'
        onClick={() => {
          setIsEditingAddress(true)
          setTimeout(() => {
            if (addressInputRef.current) {
              addressInputRef.current.focus()
            }
          }, 100)
        }}
      />
    )
  }

  return (
    <MobileContainer>
      {showBlueprintUploadModal && BlueprintUploadModal}
      <NavigationBar
        title={property.name}
        hidden={showBlueprintsModal}
        backAction={() => history.push('/properties')}
      />
      <ScrollView>
        <Column
          align='space-between'
          justify='space-between'
          style={{ backgroundColor: Theme.Colors.WHITE }}
          className={classes.rootMobile}
        >
          {showBlueprintsModal && (
            <FullscreenModal
              title={global._('Properties.BlueprintsModal.Blueprints')}
              onClose={() => {
                history.push('#')
                setShowBlueprintsModal(false)
              }}
              content={
                <MobileContainer background='var(--color-white)'>
                  <ScrollView id='scrollable-container'>
                    <ObjectListMobile
                      objects={property.blueprints}
                      history={history}
                      user={user}
                      rowsAreClickable={false}
                      rowOnClick={obj =>
                        history.push(
                          `/properties/${property.id}/blueprints/${obj.id}`
                        )
                      }
                      noResultsMessage={`${global._(
                        'Properties.NoExistingBlueprintsText'
                      )} ${global._('Properties.AddBlueprintsText')}`}
                      hideArrow
                      largerPadding
                      clickableIcon
                      columns={[
                        {
                          key: 'icon',
                          format: obj => (
                            <Icon style={{ color: 'var(--color-black)' }}>
                              note
                            </Icon>
                          )
                        },
                        { key: 'name', bold: true, variant: 'body' },
                        {
                          key: 'rowactionbutton',
                          format: obj => (
                            <Icon
                              onClick={e => {
                                e.stopPropagation()
                                setDeleteBlueprintObj(obj)
                              }}
                            >
                              delete
                            </Icon>
                          )
                        }
                      ]}
                    />
                    <CenteredFloatingButtonMobile
                      onClick={() => setShowBlueprintUploadModal(true)}
                    />
                  </ScrollView>
                </MobileContainer>
              }
            />
          )}
          {deleteBlueprintObj && (
            <Modal
              onClose={() => setDeleteBlueprintObj(null)}
              title={global._('Properties.RemoveBlueprintModalTitle')}
              content={
                <div style={{ textAlign: 'left' }}>
                  <Typography
                    style={{
                      paddingBottom: 12
                    }}
                    block
                    variant='body'
                  >
                    {global._('Properties.RemoveBlueprintModalConfirmText')}
                  </Typography>
                  <Typography
                    style={{
                      paddingBottom: 12
                    }}
                    block
                    variant='subtitleSmall'
                  >
                    {deleteBlueprintObj.name}
                  </Typography>
                  <Typography
                    style={{
                      paddingBottom: 12
                    }}
                    block
                    variant='bodyItalic'
                  >
                    {global._('Properties.RemoveBlueprintModalWarningText')}
                  </Typography>
                </div>
              }
              buttons={[
                <Button
                  variant='none'
                  onClick={() => setDeleteBlueprintObj(null)}
                >
                  {global._('Common.Cancel')}
                </Button>,
                <Button variant='secondary' onClick={() => onDeleteBlueprint()}>
                  {global._('Delete')}
                </Button>
              ]}
            />
          )}
          {showDeletePropertyModal && (
            <Modal
              onClose={() => setShowDeletePropertyModal(false)}
              title={global._('Properties.RemovePropertyModalTitle')}
              content={
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  <Typography
                    style={{
                      paddingBottom: 12
                    }}
                    block
                    variant='body'
                  >
                    {global._('Properties.RemovePropertyModalConfirmText')}
                  </Typography>
                  <Typography
                    style={{
                      paddingBottom: 12
                    }}
                    block
                    variant='subtitleSmall'
                  >
                    {property.name}
                  </Typography>
                  <Typography
                    style={{
                      paddingBottom: 12
                    }}
                    block
                    variant='bodyItalic'
                  >
                    {global._('Properties.RemovePropertyModalWarningText')}
                  </Typography>
                </div>
              }
              buttons={[
                <Button
                  variant='none'
                  onClick={() => setShowDeletePropertyModal(false)}
                >
                  {global._('Common.Cancel')}
                </Button>,
                <Button variant='secondary' onClick={onDeleteProperty}>
                  {global._('Delete')}
                </Button>
              ]}
            />
          )}
          <Row style={{ paddingBottom: 20 }}>
            <Column style={{ width: '100%' }}>
              <Row style={{ width: '100%' }}>
                <Input
                  error={nameError}
                  endAdornment={nameInputAdornment}
                  disabled={!isEditingName}
                  disableHelpText
                  ref={nameInputRef}
                  value={name}
                  onChange={handleNameChange}
                  onBlur={e => canUpdateName() && handleUpdate(e)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') canUpdateName() && handleUpdate(e)
                  }}
                />
              </Row>
              <Row style={{ width: '100%' }}>
                <Typography
                  style={{
                    marginLeft: 15,
                    color: !nameError ? '#8E8E8E' : '#D74B4B'
                  }}
                  variant='small'
                >
                  {!isEditingName
                    ? `${global._('Properties.Edit')} ${global._(
                        'Properties.PropertyName'
                      )}`
                    : `${global._('Properties.UpdatePropertyNameHelperText')}`}
                </Typography>
              </Row>
            </Column>
          </Row>
          <Row style={{ paddingBottom: 15 }}>
            <Column style={{ width: '100%' }}>
              <Row style={{ width: '100%' }}>
                <Input
                  endAdornment={addressInputAdornment}
                  disableHelpText
                  ref={addressInputRef}
                  disabled={!isEditingAddress}
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  onBlur={e => handleUpdate(e)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') handleUpdate(e)
                  }}
                />
              </Row>
              <Row style={{ width: '100%' }}>
                <Typography
                  style={{
                    color: '#8E8E8E',
                    marginLeft: 15
                  }}
                  variant='small'
                >
                  {global._('Properties.CreateModal.Address')}
                </Typography>
              </Row>
            </Column>
          </Row>
          <Row className={classes.fullWidthRow}>
            <NavigationList
              itemList={[
                {
                  title: global._('Properties.AddAndShowBlueprints'),
                  icon: 'note',
                  darkIcon: true,
                  onClick: () => {
                    history.push('#blueprints')
                    setShowBlueprintsModal(true)
                  }
                }
              ]}
            />
          </Row>
          <Row className={classes.rowMobile}>
            <Button
              variant='primary'
              onClick={() => history.push(`${location.pathname}/quoteoverview`)}
            >
              {global._('Properties.RequestForQuotation')}
            </Button>
          </Row>
          <Row className={classes.rowMobile}>
            <Button
              variant='outlined'
              style={{ marginTop: 20 }}
              onClick={() =>
                property && history.push(`/properties/${property.id}/overview`)
              }
            >
              {global._('Properties.ShowOverview')}
            </Button>
          </Row>
          <Row className={classes.rowMobile} style={{ marginTop: 20 }}>
            <Button
              variant='secondary'
              onClick={() => setShowDeletePropertyModal(true)}
              type='danger'
            >
              {global._('Properties.RemovePropertyButtonTitle')}
            </Button>
          </Row>
        </Column>
      </ScrollView>
    </MobileContainer>
  )
}

PropertyDetailsMobile.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onDeleteProperty: PropTypes.func.isRequired,
  setShowBlueprintUploadModal: PropTypes.func.isRequired,
  showBlueprintUploadModal: PropTypes.bool.isRequired,
  user: PropTypes.object,
  BlueprintUploadModal: PropTypes.any.isRequired
}

PropertyDetailsMobile.defaultProps = {
  user: null
}

export default withStyles(styles)(PropertyDetailsMobile)
