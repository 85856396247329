import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { Today } from '@material-ui/icons'
import { Grid, TextField, Icon, IconButton } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import IconColumn from 'src/components/IconColumn'
import Row from 'src/components/Row'
import Button from 'src/components/Button'
import Notification from 'src/common/Notification'
import Paper from 'src/components/Paper'
import Typography from 'src/components/Typography'
import ObjectList from 'src/components/ObjectList'
import SearchField from 'src/components/SearchField'
import PageContentHeader from 'src/components/PageContentHeader'
import { getSelectedOrg, normalize } from 'src/utils/helpers'
import withUser from 'src/utils/withUser'
import AsyncSelect from 'src/components/AsyncSelect'
import { history } from '../../../redux/store'

const styles = {
  root: {},
  listHeader: {
    width: '100%',
    padding: '20px 20px 0'
  }
}

export const STORAGE_KEY_INVENTORY_CREATED = 'keys.inventory.created.success'

/* eslint-disable */
const List = ({
  title,
  buttonOnClick,
  buttonTitle,
  searchPlaceholder,
  items,
  classes,
  filter,
  setFilter,
  endAdornmentIcon,
  endAdornmentOnClick,
  isLoading
}) => {
  return (
    <Paper style={{ padding: 0 }}>
      <Row justify='space-between' className={classes.listHeader}>
        <Typography variant='subtitleBig' bold>
          {title}
        </Typography>
        <Button
          loading={isLoading}
          disabled={!items.length}
          variant='outlined'
          onClick={buttonOnClick}
        >
          {buttonTitle}
        </Button>
      </Row>
      <div style={{ padding: 20 }}>
        <ObjectList
          filterFields={
            <SearchField
              style={{ width: '100%' }}
              label={searchPlaceholder}
              variant='filled'
              value={filter.search || ''}
              onChange={e => setFilter({ search: e.target.value })}
            />
          }
          filter={filter}
          setFilter={setFilter}
          rowIdentifier={obj => {
            if (obj.isUser) {
              return `user-${obj.id}`
            } else if (obj.isStorage) {
              return `storage-${obj.id}`
            } else if (obj.isApartment) {
              return `apartment-${obj.id}`
            }
          }}
          columns={[
            {
              key: 'icon',
              sortingDisabled: true,
              style: { width: 50 },
              format: (x, holder) => {
                let icon
                if (holder.isUser) {
                  icon = 'person'
                } else if (holder.isStorage) {
                  icon = 'archive'
                } else if (holder.isApartment) {
                  icon = 'location_city'
                }
                return <IconColumn icon={icon} />
              }
            },
            {
              key: 'name',
              localizationKey: 'Keys.Inventory.Column.Name',
              sortingKey: 'name',
              style: { width: 150 },
              format: (name, holder) => (
                <>
                  {holder.name}
                  {holder.isUser && (
                    <span
                      style={{
                        color: 'var(--color-text-faded)'
                      }}
                    >
                      {` (${holder.email || '-'})`}
                    </span>
                  )}
                </>
              ),
              clickable: true
            },
            {
              key: 'keys',
              localizationKey: 'Keys.Inventory.Column.KeyCount',
              format: keys => keys.length,
              sortingKey: 'keys',
              style: { width: 150 },
              clickable: true
            },
            {
              key: 'endAdornment',
              sortingDisabled: true,
              style: { width: 40 },
              format: (x, holder) => (
                <IconButton onClick={() => endAdornmentOnClick(holder)}>
                  <Icon style={{ color: 'var(--color-black)' }}>
                    {endAdornmentIcon}
                  </Icon>
                </IconButton>
              )
            }
          ]}
          expandable={false}
          objects={items}
          wrapper={false}
          renderEmpty
        />
      </div>
    </Paper>
  )
}
/* eslint-enable */

const NewKeyInventoryView = ({ classes, breadcrumbItems, user }) => {
  const selectedOrg = getSelectedOrg(user)
  const [date, setDate] = useState(moment())
  const [notes, setNotes] = useState(null)
  const [template, setTemplate] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  /* Unselected holders (left list) */
  const [unselectedHolders, setUnselectedHolders] = useState([])
  const [filteredUnselectedHolders, setFilteredUnselectedHolders] = useState([])
  const [unselectedFilter, setUnselectedFilter] = useState({})

  /* Select holders (right list) */
  const [selectedHolders, setSelectedHolders] = useState([])
  const [filteredSelectedHolders, setFilteredSelectedHolders] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({})

  const [error, setError] = useState(null)

  const onFilterChange = (filter, originalList, setFilter, setList) => {
    let list
    if (filter.search) {
      const search = normalize(filter.search.toLowerCase())
      list = originalList.filter(holder => holder.search.includes(search))
    } else {
      list = [...originalList]
    }

    const { orderBy } = filter
    if (orderBy) {
      list.sort((a, b) => {
        if (orderBy === 'name') {
          if (filter.order === 'asc') {
            return a[orderBy].localeCompare(b[orderBy])
          }
          return b[orderBy].localeCompare(a[orderBy])
        }
        if (orderBy === 'keys') {
          if (filter.order === 'asc') {
            return a[orderBy].length - b[orderBy].length
          }
          return b[orderBy].length - a[orderBy].length
        }
        return 0
      })
    }
    setList(list)
    setFilter(filter)
  }

  const onUnselectedFilterChange = filter =>
    onFilterChange(
      filter,
      unselectedHolders,
      setUnselectedFilter,
      setFilteredUnselectedHolders
    )
  const onSelectedFilterChange = filter =>
    onFilterChange(
      filter,
      selectedHolders,
      setSelectedFilter,
      setFilteredSelectedHolders
    )

  const onCreate = async () => {
    setIsCreating(true)
    try {
      /* map out only key ids */
      const keys = []
      selectedHolders.forEach(holder => {
        keys.push(...holder.keys.map(key => ({ id: key.id })))
      })

      const data = {
        startDate: date.format('YYYY-MM-DD'),
        notes,
        keys,
        receiptTemplateId: template && template.value ? template.value.id : null
      }

      await axios.post(
        `/v1/organisations/${selectedOrg.id}/keyinventories`,
        data
      )
      localStorage.setItem(STORAGE_KEY_INVENTORY_CREATED, '1')
      history.push('/keys/inventory')
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsCreating(false)
  }

  /* update filtered lists when adding or removing holders */
  useEffect(() => {
    onUnselectedFilterChange(unselectedFilter)
    onSelectedFilterChange(selectedFilter)
  }, [selectedHolders, unselectedHolders])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(
          `/v1/organisations/${selectedOrg.id}/keys`
        )
        const users = {}
        const storages = {}
        const apartments = {}
        const aggregatedHolders = []
        data.forEach(key => {
          if (key.storageId) {
            if (storages[key.storageId]) {
              storages[key.storageId].keys.push(key)
            } else {
              storages[key.storageId] = {
                id: key.storageId,
                name: key.holderName || '',
                search: normalize(key.holderName).toLowerCase(),
                email: key.holderEmail,
                isStorage: true,
                keys: [key]
              }
              aggregatedHolders.push(storages[key.storageId])
            }
          } else if (key.userId) {
            if (users[key.userId]) {
              users[key.userId].keys.push(key)
            } else {
              users[key.userId] = {
                id: key.userId,
                name: key.holderName || '',
                search: normalize(key.holderName).toLowerCase(),
                email: key.holderEmail,
                isUser: true,
                keys: [key]
              }
              aggregatedHolders.push(users[key.userId])
            }
          } else if (key.apartmentId) {
            if (apartments[key.apartmentId]) {
              apartments[key.apartmentId].keys.push(key)
            } else {
              apartments[key.apartmentId] = {
                id: key.apartmentId,
                name: key.holderName || '',
                search: normalize(key.holderName).toLowerCase(),
                email: key.holderEmail,
                isApartment: true,
                keys: [key]
              }
              aggregatedHolders.push(apartments[key.apartmentId])
            }
          }
        })

        setUnselectedHolders(aggregatedHolders)
      } catch (e) {
        const msg = e.response ? e.response.data : e.message
        setError(global._(msg))
      }
      setIsLoading(false)
    })()
  }, [])

  return (
    <div className={classes.root}>
      <PageContentHeader
        breadcrumbItems={breadcrumbItems}
        actionButtons={
          <Button
            loading={isCreating}
            disabled={!selectedHolders.length || !date}
            variant='primary'
            onClick={onCreate}
          >
            {global._('Keys.Inventory.New.CreateInventory')}
          </Button>
        }
      />
      <Paper
        style={{ marginTop: 22, padding: 0 }}
        icon='verified_user'
        title={global._('Keys.Inventory.New.Title')}
        subtitle={global._('Keys.Inventory.New.Subtitle')}
      >
        <Grid
          container
          style={{ padding: '22px 20px', marginBottom: 6 }}
          spacing={3}
        >
          <Grid item xs={6}>
            <DatePicker
              style={{ width: '100%' }}
              InputProps={{
                endAdornment: <Today style={{ cursor: 'pointer' }} />
              }}
              inputVariant='filled'
              label={global._('Keys.Inventory.StartDate')}
              format='YYYY-MM-DD'
              helperText={global._('Keys.Inventory.StartDateHelperText')}
              value={date || null}
              cancelLabel={null}
              okLabel={null}
              minDate={moment()}
              autoOk
              onChange={d => setDate(d)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='filled'
              style={{ width: '100%' }}
              label={global._('Keys.Inventory.Notes')}
              value={notes || ''}
              onChange={e => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <AsyncSelect
              isSearchable
              // helpText={global._('Keys.Inventory.ReceiptTemplateHelperText')}
              value={template}
              labelKey='name'
              name='receiptTemplateId'
              isClearable
              placeholder={global._('Keys.New.Receipt.ReceiptTemplate')}
              onChange={option => {
                setTemplate(option)
              }}
              cacheOptions={false}
              url={`/v1/organisations/${selectedOrg.id}/keyreceipttemplates`}
            />
          </Grid>
        </Grid>
      </Paper>
      {error && <Notification type='error' message={error} />}
      <Grid container spacing={3} style={{ marginTop: 12 }}>
        <Grid item xs={6}>
          <List
            items={filteredUnselectedHolders}
            isLoading={isLoading}
            classes={classes}
            filter={unselectedFilter}
            setFilter={onUnselectedFilterChange}
            title={global._('Keys.Inventory.SelectHolders')}
            buttonTitle={global._('Keys.Inventory.SelectAllHolders')}
            buttonOnClick={() => {
              setUnselectedHolders([])
              setSelectedHolders([...unselectedHolders, ...selectedHolders])
            }}
            searchPlaceholder={global._(
              'Keys.Inventory.HolderSearchPlaceholder'
            )}
            endAdornmentIcon='add_circle'
            endAdornmentOnClick={holder => {
              setUnselectedHolders(unselectedHolders.filter(h => h !== holder))
              setSelectedHolders([holder, ...selectedHolders])
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <List
            items={filteredSelectedHolders}
            classes={classes}
            filter={selectedFilter}
            setFilter={onSelectedFilterChange}
            title={global._('Keys.Inventory.SelectedHolders')}
            buttonTitle={global._('Keys.Inventory.ClearSelectedHolders')}
            buttonOnClick={() => {
              setUnselectedHolders([...selectedHolders, ...unselectedHolders])
              setSelectedHolders([])
            }}
            searchPlaceholder={global._(
              'Keys.Inventory.HolderSearchPlaceholder'
            )}
            endAdornmentIcon='remove_circle'
            endAdornmentOnClick={holder => {
              setUnselectedHolders([holder, ...unselectedHolders])
              setSelectedHolders(selectedHolders.filter(h => h !== holder))
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

NewKeyInventoryView.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired
}

NewKeyInventoryView.defaultProps = {}

const NewKeyInventoryViewWithStylesAndUser = withUser(
  injectSheet(styles)(NewKeyInventoryView)
)
export default NewKeyInventoryViewWithStylesAndUser
