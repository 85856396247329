/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import {
  Grid,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { Form } from 'formik'
import { DatePicker } from '@material-ui/pickers'
import { Today } from '@material-ui/icons'
import axios from 'axios'
import { KeyStatus, KeyType } from '../../../utils/constants'
import Button from '../../../components/Button'
import AsyncSelect from '../../../components/AsyncSelect'
import Typography from '../../../components/Typography'

const styles = {
  root: {}
}

const KeyDetailsForm = ({
  classes,
  handleChange,
  errors,
  values,
  handleBlur,
  keyObj,
  newKey,
  handleKeySave,
  touched,
  setFieldValue,
  selectedOrg,
  setTouched,
  validateField
}) => {
  const [selectedLockSystem, setSelectedLockSystem] = useState(null)

  const checkForDuplicates = async () => {
    const { data } = await axios.post(
      `/v1/organisations/${selectedOrg.id}/keys/validate`,
      {
        lockSystemId: selectedLockSystem && selectedLockSystem.value.id,
        organisationId: selectedOrg.id,
        markings: [values.marking],
        storageIds: [],
        apartmentRefIds: [],
        keyringIds: []
      }
    )

    if (data && data.markings.length > 0 && keyObj.marking !== values.marking) {
      setFieldValue('duplicate', true)
    } else {
      setFieldValue('duplicate', false)
    }

    validateField('marking')
  }

  useEffect(() => {
    checkForDuplicates()
  }, [selectedLockSystem])

  useEffect(() => {
    if (keyObj && keyObj.lockSystemId) {
      setSelectedLockSystem({
        label: keyObj.lockSystemName,
        value: { id: keyObj.lockSystemId }
      })
    }
  }, [keyObj])

  return (
    <Form style={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <AsyncSelect
            isSearchable
            key={`locksystem-${
              selectedLockSystem &&
              selectedLockSystem.value &&
              selectedLockSystem.value.id
            }`}
            helpText={global._('Common.Required')}
            value={selectedLockSystem}
            labelKey='name'
            name='lockSystemId'
            isClearable={false}
            placeholder={global._('Keys.New.LockSystem')}
            onChange={e => {
              setFieldValue('lockSystemId', e && e.value && (e.value.id || ''))
              setSelectedLockSystem(e || null)
              setTouched({ ...touched, lockSystemId: true }, false)
            }}
            cacheOptions={false}
            onClick={handleBlur}
            disabled={keyObj && keyObj.archived}
            error={errors.lockSystemId}
            url={`/v1/organisations/${selectedOrg.id}/locksystems?search={{input}}&orderBy=name&order=asc`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={global._('Keys.New.Status')}
            helperText={global._('Common.Required')}
            fullWidth
            onChange={handleChange}
            error={errors.status}
            onClick={handleBlur}
            value={values.status}
            name='status'
            className={classes.input}
            variant='filled'
            disabled={keyObj && keyObj.archived}
            select>
            {Object.keys(KeyStatus).map(status => (
              <MenuItem
                key={`status-${KeyStatus[status]}`}
                value={KeyStatus[status]}>
                {global._(`Keys.Statuses.${KeyStatus[status]}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label={global._('Keys.New.Type')}
            helperText={global._('Common.Required')}
            fullWidth
            onChange={handleChange}
            error={errors.type}
            onClick={handleBlur}
            value={values.type}
            name='type'
            className={classes.input}
            disabled={keyObj && keyObj.archived}
            variant='filled'
            select>
            {Object.keys(KeyType).map(type => (
              <MenuItem key={`type-${KeyType[type]}`} value={KeyType[type]}>
                {global._(`Keys.Types.${KeyType[type]}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={global._('Keys.New.Marking')}
            helperText={errors.marking || global._('Common.Required')}
            fullWidth
            onChange={handleChange}
            error={errors.marking}
            onClick={handleBlur}
            onBlur={checkForDuplicates}
            value={values.marking}
            name='marking'
            className={classes.input}
            disabled={keyObj && keyObj.archived}
            variant='filled'
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label={global._('Keys.New.Name')}
            fullWidth
            onChange={handleChange}
            error={errors.name}
            onClick={handleBlur}
            value={values.name}
            name='name'
            className={classes.input}
            variant='filled'
            disabled={keyObj && keyObj.archived}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={global._('Keys.New.Manufacture')}
            helperText={global._('Keys.New.ManufactureInfo')}
            fullWidth
            onChange={handleChange}
            error={errors.make}
            onClick={handleBlur}
            value={values.make}
            name='make'
            disabled={keyObj && keyObj.archived}
            className={classes.input}
            variant='filled'
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label={global._('Keys.New.IDNumber')}
            helperText={global._('Keys.New.IDNumberInfo')}
            fullWidth
            onChange={handleChange}
            error={errors.identifier}
            onClick={handleBlur}
            value={values.identifier}
            name='identifier'
            className={classes.input}
            disabled={keyObj && keyObj.archived}
            variant='filled'
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <DatePicker
            InputProps={{
              endAdornment: <Today style={{ cursor: 'pointer' }} />
            }}
            inputVariant='filled'
            label={global._('Keys.New.ExpectedReturnDate')}
            format='YYYY/MM/DD'
            name='expectedReturnDate'
            value={values.expectedReturnDate || null}
            cancelLabel={null}
            okLabel={null}
            fullWidth
            minDateMessage={global._('Keys.New.MinDateMessage')}
            disablePast
            disabled={values.untilFurtherNotice}
            autoOk
            onClick={handleBlur}
            onChange={date => {
              setFieldValue(
                'expectedReturnDate',
                date ? date.format('YYYY-MM-DD') : null
              )
              setTouched({ ...touched, expectedReturnDate: true })
            }}
          />
          <FormControlLabel
            onChange={e => {
              const { checked } = e.target

              setFieldValue('untilFurtherNotice', checked)
              setTouched({ ...touched, untilFurtherNotice: true })

              if (checked) {
                setFieldValue('expectedReturnDate', null)
              }
            }}
            style={{ margin: '0 0 20px', marginRight: 0, minWidth: 155 }}
            label={
              <div>
                <Typography variant='body' bold>
                  {global._('Common.UntilFurtherNotice')}
                </Typography>
              </div>
            }
            control={
              <div
                style={{
                  minWidth: 60,
                  textAlign: 'center',
                  position: 'relative'
                }}>
                <Checkbox
                  checked={values.untilFurtherNotice}
                  name='untilFurtherNotice'
                />
              </div>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label={global._('Keys.New.Note')}
            fullWidth
            multiline
            rows={4}
            onChange={handleChange}
            error={errors.notes}
            onClick={handleBlur}
            value={values.notes}
            name='notes'
            className={classes.input}
            disabled={keyObj && keyObj.archived}
            variant='filled'
          />
        </Grid>
      </Grid>
      {!newKey && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='primary'
            style={{ marginTop: 40, marginBottom: 20 }}
            onClick={() => {
              handleKeySave(values)
            }}
            disabled={
              Object.keys(touched).length === 0 ||
              Object.keys(errors).length ||
              (keyObj && keyObj.archived)
            }>
            {global._('Common.Save')}
          </Button>
        </div>
      )}
    </Form>
  )
}

KeyDetailsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.any,
  values: PropTypes.any,
  handleBlur: PropTypes.func.isRequired,
  keyObj: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  newKey: PropTypes.bool,
  handleKeySave: PropTypes.func.isRequired,
  touched: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  selectedOrg: PropTypes.object.isRequired,
  setTouched: PropTypes.func.isRequired
}

KeyDetailsForm.defaultProps = {
  newKey: false
}

const KeyDetailsFormWithStyles = injectSheet(styles)(KeyDetailsForm)
export default KeyDetailsFormWithStyles
