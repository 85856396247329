import React from 'react'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import axios from 'axios'
import { emailRegExp, phoneNumberRegex } from 'src/utils/helpers'
import { MenuItem, TextField } from '@material-ui/core'
import Button from '../../../../components/Button'
import Badge from '../../../../components/Badge'
import {
  Country,
  OrganisationTypes,
  UserRoles
} from '../../../../utils/constants'
import { history } from '../../../../redux/store'

export default {
  icon: opts => (opts.type === OrganisationTypes.Unbound ? 'store' : 'group'),
  attributes: (caller, opts) => {
    const { type } = opts
    const attributes = {
      name: {
        required: true,
        size: 7
      },
      country: {
        required: true,
        size: 5,
        field: (
          <TextField
            select
            style={{ width: '100%' }}
            variant='filled'
            label={global._('Widgets.organisations.SelectCountry')}
            value={(opts.newItem && opts.newItem.country) || ''}
            onChange={e =>
              opts.setNewItem({ ...opts.newItem, country: e.target.value })
            }
            helperText={global._('Common.Required')}
          >
            {Object.keys(Country).map(c => (
              <MenuItem value={c} key={c}>
                {global._(`Countries.${c}`)}
              </MenuItem>
            ))}
          </TextField>
        )
      },
      organisationNumber: {
        required: true,
        size: 5
      },
      address: {
        size: 7
      },
      postalCode: {
        size: 5
      },
      city: {
        size: 7
      },
      email: {
        required: true,
        validation: {
          validate: str => str && emailRegExp.test(str),
          error: global._('OrganisationDetail.EmailNotValidError')
        },
        size: 12
      },
      reference: {
        required: true,
        size: 12
      },
      phone: {
        // required: true,
        validation: {
          validate: str => str && phoneNumberRegex.test(str),
          error: global._('Common.PhoneNumberInvalid')
        },
        size: 12
      }
    }
    if (type === OrganisationTypes.Partner) {
      attributes.separator = {}
      attributes.caseNotificationEmail = {
        required: true,
        size: 12
      }
      attributes.quoteRequestEmail = {
        required: true,
        size: 12
      }
    }
    return attributes
  },
  actionButtons: (caller, opts) => {
    const { single, type, setAddPartnerStatusModalOpen, setNewItem, filter } =
      opts
    const buttons = []
    if (!caller.roles.includes(UserRoles.Support)) {
      if (single && type === OrganisationTypes.Partner) {
        buttons.push(
          <Button
            key={`widgets.organisations.${type}.button.add.existing`}
            variant='primary'
            onClick={() => setAddPartnerStatusModalOpen(true)}
          >
            {global._('Widgets.organisations.ActionButton.AddExisting')}
          </Button>
        )
      }
      if (
        type === OrganisationTypes.Partner ||
        type === OrganisationTypes.Customer
      ) {
        buttons.push(
          <Button
            key={`widgets.organisations.${type}.button.add.new`}
            variant='primary'
            onClick={() =>
              setNewItem(
                Object.assign(
                  {},
                  { type },
                  filter.partnerId ? { partnerId: filter.partnerId } : undefined
                )
              )
            }
          >
            {global._(
              `Widgets.organisations.ActionButton.${single ? 'AddNew' : type}`
            )}
          </Button>
        )
      }
    }
    return buttons
  },
  identifier: org => org.id,
  noResultsMessage: () => global._('Widgets.organisations.NoResultsMessage'),
  MobileTable: {
    icon: 'store',
    title: org => org.name,
    subtitle: org => org.organisationNumber,
    onClick: item => history.push(`/admin/users/${item.id}`)
  },
  Columns: {
    single: [
      {
        key: 'icon',
        sortable: false,
        localizationKey: '',
        style: { width: 60 },
        transform: isExpanded => (
          <div
            className='tableRowIcon'
            style={{
              width: 38,
              height: 38,
              transition: 'background-color 200ms',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isExpanded ? 'white' : 'var(--color-light-grey)',
              color: 'var(--color-middle-grey)',
              borderRadius: 500
            }}
          >
            <Icon style={{ fontSize: 18 }}>store</Icon>
          </div>
        )
      },
      {
        key: 'name',
        sortable: true,
        localizationKey: 'Widgets.organisations.Attributes.name',
        style: { width: 250 }
      },
      {
        key: 'organisationNumber',
        sortable: true,
        localizationKey: 'Widgets.organisations.Attributes.organisationNumber',
        style: { width: 200 }
      },
      {
        key: 'associatedPartner',
        sortable: false,
        localizationKey: 'Widgets.organisations.Attributes.associatedPartner',
        style: { width: 200 },
        transform: (value, item) => item && item.partner && item.partner.name,
        hiddenFor: (item, innerWidth, user) =>
          !user.roles.includes(UserRoles.Support)
      },
      {
        key: 'createdAt',
        sortable: true,
        localizationKey: 'Widgets.organisations.Attributes.createdAt',
        transform: value => moment(value).calendar(),
        style: { width: 200 }
      },
      {
        key: 'registered',
        sortable: false,
        style: { width: 120 },
        transform: (registered, item) =>
          (item.type === OrganisationTypes.Unbound ||
            item.type === OrganisationTypes.Guest) &&
          (registered ? (
            <Badge variant='primary' label={global._('Common.Registered')} />
          ) : (
            <Badge variant='grey' label={global._('Common.NotRegistered')} />
          ))
      },
      {
        key: 'chevron',
        localizationKey: '',
        sortable: false,
        style: { width: 70 },
        transform: isExpanded => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton
              style={{
                transition: 'transform 150ms',
                marginLeft: 'auto',
                transform: `rotate(${isExpanded ? -90 : 90}deg)`
              }}
            >
              <Icon>chevron_right</Icon>
            </IconButton>
          </div>
        )
      }
    ],
    /* Minified columns */
    widget: [
      {
        key: 'name',
        sortable: true,
        localizationKey: 'Widgets.organisations.Attributes.name',
        width: 300
      },
      {
        key: 'createdAt',
        sortable: true,
        localizationKey: 'Widgets.organisations.Attributes.createdAt',
        transform: value => moment(value).calendar(),
        width: 200
      },
      {
        key: 'chevron',
        sortable: false,
        localizationKey: '',
        style: { width: 50 },
        transform: () => (
          <Icon
            style={{ display: 'block', marginLeft: 'auto', marginRight: 5 }}
          >
            chevron_right
          </Icon>
        )
      }
    ]
  },
  rowExpansionContent: (item, caller, opts) => (
    <React.Fragment>
      <Button
        variant='outlined'
        onClick={() => opts.history.push(`/organisations/${item.id}`)}
        style={{ marginRight: 18 }}
      >
        {global._('Widgets.organisations.ShowProfile')}
      </Button>
      <Button
        variant='outlined'
        onClick={() => opts.history.push(`/admin/users/${item.id}`)}
        style={{ marginRight: 18 }}
      >
        {global._('Widgets.organisations.Users')}
      </Button>
      {opts.type === OrganisationTypes.Partner && (
        <>
          <Button
            variant='outlined'
            onClick={() =>
              opts.history.push(`${opts.history.location.pathname}/${item.id}`)
            }
            style={{ marginRight: 18 }}
          >
            {global._('Widgets.organisations.Customers')}
          </Button>
          <Button
            variant='secondary'
            disabled={item.type !== OrganisationTypes.Partner}
            onClick={async () => {
              opts.setError(null)
              try {
                await axios.patch(`/v1/organisations/${item.id}`, {
                  type: OrganisationTypes.Unbound
                })
                opts.setItems(
                  opts.items.map(o => {
                    if (o === item) {
                      return { ...o, type: OrganisationTypes.Unbound }
                    }
                    return o
                  })
                )
              } catch (e) {
                const msg = e.response ? e.response.data : e.message
                opts.setError(global._(msg))
              }
            }}
          >
            {global._(
              `Widgets.organisations.${
                item.type === OrganisationTypes.Partner
                  ? 'RemovePartnerStatus'
                  : 'PartnerStatusRemoved'
              }`
            )}
          </Button>
        </>
      )}
      {item.type === OrganisationTypes.Unbound && (
        <>
          <Button
            variant='secondary'
            onClick={() => {
              opts.setDeleteItem(item)
              opts.setDeleteError(null)
            }}
            style={{ marginRight: 18 }}
          >
            {global._('Widgets.organisations.DeleteOrganisation')}
          </Button>
          <Button
            variant='primary'
            onClick={() => opts.setOrganisationToAddToPartner(item)}
            style={{ marginRight: 18 }}
          >
            {global._(
              'Widgets.organisations.AddOrganisationToPartner.AddOrganisationToPartner'
            )}
          </Button>
        </>
      )}
    </React.Fragment>
  )
}
