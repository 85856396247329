/* eslint-disable no-multi-assign */
import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import Paper from 'src/components/Paper'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import Alert from 'src/components/Alert'
import Icon from '@material-ui/core/Icon'
import { UserRoles } from 'src/utils/constants'
import PageContentHeader from 'src/components/PageContentHeader'
import useWindowDimensions from 'src/utils/useWindowDimensions'
import NavigationBar from 'src/components/NavigationBar'
import MobileContainer from 'src/components/MobileContainer'
import ScrollView from 'src/components/ScrollView'

const styles = {
  section: {
    width: '100%',
    padding: 'var(--section-padding-default)',
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--color-divider-light)'
    }
  },
  subSection: {
    marginBottom: 20
  }
}

const Contact = ({
  classes,
  user,
  history,
  breadcrumbItems,
  selectedOrganisation
}) => {
  if (!selectedOrganisation) {
    return null
  }

  let {
    name,
    organisationNumber,
    website,
    dutyPhone,
    phone,
    email,
    visitingAddress,
    visitingPostalCode,
    visitingCity,
    address,
    city,
    postalCode,
    additionalInfo,
    directionsURL
  } = selectedOrganisation.partner || selectedOrganisation

  let contactReference = null

  const { isMobile } = useWindowDimensions()

  const isSystemAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.SystemAdmin)
  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)

  if (isPartnerAdmin || isSupport || isSystemAdmin) {
    name = global._('Contact.Prosero.Name')
    website = global._('Contact.Prosero.Website')
    contactReference = global._('Contact.Prosero.ReferenceName')
    phone = global._('Contact.Prosero.ReferencePhone')
    email = global._('Contact.Prosero.Email')
    organisationNumber = null
    dutyPhone = null
    additionalInfo = null
    visitingAddress = null
    visitingPostalCode = null
    visitingCity = null
    address = null
    city = null
    postalCode = null
    directionsURL = null
  }

  const textSection = (title, textArr) => (
    <div className={classes.subSection}>
      <Typography variant='body' bold block>
        {title}
      </Typography>
      {textArr &&
        textArr.length > 0 &&
        textArr.map(t => (
          <Typography variant='body' block>
            {t}
          </Typography>
        ))}
    </div>
  )

  const Content = () => (
    <>
      <div className={classes.section}>
        <Typography variant='title' bold>
          {name}
        </Typography>
      </div>
      {isMobile && dutyPhone && (
        <div className={classes.section}>
          <Button
            key='Contact.CallDutyPhone'
            style={{ marginRight: 20 }}
            variant='primary'
            onClick={() => window.open(`tel:${dutyPhone}`, '_self')}
          >
            {global._('Contact.CallDutyPhone')}
          </Button>
        </div>
      )}
      <div className={classes.section}>
        <div style={{ marginBottom: 20 }}>
          {website && (
            <Button
              key='Common.Website'
              style={{ marginRight: 20 }}
              variant='outlined'
              onClick={() => window.open(website, '_blank')}
            >
              {global._('Common.Website')}
            </Button>
          )}
          {directionsURL && (
            <Button
              key='Common.Directions'
              style={{ marginTop: isMobile ? 10 : '' }}
              variant='outlined'
              onClick={() => window.open(directionsURL, '_blank')}
            >
              {global._('Common.Directions')}
            </Button>
          )}
          {isMobile && phone && (
            <Button
              key='Common.Call'
              variant='outlined'
              style={{ marginTop: 10 }}
              onClick={() => window.open(`tel:${phone}`, '_self')}
            >
              {global._('Common.Call')}
            </Button>
          )}
          {isMobile && email && (
            <Button
              key='Common.SendEmail'
              variant='outlined'
              style={{ marginTop: 10 }}
              onClick={() => window.open(`mailto:${email}`, '_self')}
            >
              {global._('Common.SendEmail')}
            </Button>
          )}
        </div>
        {dutyPhone && !isMobile && (
          <div style={{ marginBottom: 20 }}>
            <Alert>
              {global._('OrganisationDetail.DutyPhone')}{' '}
              <strong>{dutyPhone}</strong>
            </Alert>
          </div>
        )}
        {contactReference &&
          textSection(global._('Common.ContactReference'), [contactReference])}
        {phone && textSection(global._('Common.Phone'), [phone])}
        {email && textSection(global._('Common.Email'), [email])}
        {visitingAddress &&
          textSection(global._('Common.VisitingAddress'), [
            visitingAddress,
            `${visitingPostalCode || ''} ${visitingCity || ''}`
          ])}
        {address &&
          textSection(global._('Common.PostalAddress'), [
            address,
            `${postalCode || ''} ${city || ''}`
          ])}
        {organisationNumber &&
          textSection(global._('OrganisationDetail.OrganisationNumber'), [
            organisationNumber
          ])}
        {additionalInfo && (
          <div className={classes.subSection}>
            <Typography variant='body' italic>
              {additionalInfo}
            </Typography>
          </div>
        )}
      </div>
    </>
  )

  if (isMobile) {
    return (
      <MobileContainer>
        <NavigationBar
          title={global._('Breadcrumbs.Contact')}
          backAction={() => history.push('/')}
        />
        <ScrollView>
          <Content />
        </ScrollView>
      </MobileContainer>
    )
  }

  return (
    <>
      <PageContentHeader breadcrumbItems={breadcrumbItems} />
      <Paper fixedWidth='600' noPadding>
        <Content />
      </Paper>
    </>
  )
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.object.isRequired,
  selectedOrganisation: PropTypes.object.isRequired
}

const ContactWithStyles = injectSheet(styles)(Contact)
export default ContactWithStyles
