/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import axios from 'axios'
import { Grid, FormControl } from '@material-ui/core'
import queryString from 'querystring'
import { useLastLocation } from 'react-router-last-location'
import SummaryBadge from 'src/components/SummaryBadge'
import Row from 'src/components/Row'
import {
  UserRoles,
  BlueprintObjectTypes,
  BlueprintItemTypes,
  InstructionsVideoIds
} from '../../utils/constants'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import Notification from '../../common/Notification'
import useWindowDimensions from '../../utils/useWindowDimensions'
import PropertiesMobile from './PropertiesMobile'
import ObjectList from '../../components/ObjectList'
import SearchField from '../../components/SearchField'
import Paper from '../../components/Paper'
import PageContentHeader from '../../components/PageContentHeader'
import AsyncSelect from '../../components/AsyncSelect'
import CreatePropertyModal from './CreatePropertyModal'
import { getLocalFilter, setLocalFilter } from '../../utils/helpers'

const styles = {
  root: {},
  nameTableCell: {}
}

const Properties = props => {
  const { classes, user, history, breadcrumbItems } = props

  const { isMobile } = useWindowDimensions()
  const [isLoadingProperties, setIsLoadingProperties] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [properties, setProperties] = useState([])
  const [summary, setSummary] = useState({})
  const [organisation, setOrganisation] = useState(
    getLocalFilter('propertiesFilter') &&
      getLocalFilter('propertiesFilter').organisation
      ? getLocalFilter('propertiesFilter').organisation
      : user.organisation
  )
  const [error, setError] = useState(null)

  const lastLocation = useLastLocation()

  const defaultFilter = { organisationId: organisation && organisation.id }

  const lang = global.getLanguage()

  const [filter, setFilter] = useState(
    getLocalFilter('propertiesFilter') || defaultFilter
  )
  const [expandedRows, setExpandedRows] = useState([])

  useEffect(() => {
    if (
      !lastLocation ||
      (lastLocation &&
        lastLocation.pathname &&
        !lastLocation.pathname.includes('properties'))
    ) {
      setLocalFilter(defaultFilter, true, 'propertiesFilter', setFilter)
    } else {
      // Keep filter if coming from a property-related page
      setLocalFilter(
        getLocalFilter('propertiesFilter'),
        true,
        'propertiesFilter',
        setFilter
      )
    }
  }, [history])

  const isAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const defaultOrganisationOption = {
    value: organisation.id,
    label: organisation.name
  }

  const columns = [
    {
      key: 'name',
      localizationKey: 'Name',
      longText: true,
      bold: true,
      clickable: true,
      format: name => name || '(inget namn)'
    },
    { key: 'address', localizationKey: 'Address', clickable: true },
    {
      key: 'organisation',
      localizationKey: 'Filter.Organisation',
      filterKey: 'organisationId',
      sortingDisabled: true,
      valueKey: 'name',
      clickable: true,
      hiddenFor: !isAdmin
    }
  ]

  const fetchProperties = async () => {
    const selectedFilter = getLocalFilter('propertiesFilter')
    /* include blueprints, objects, items */
    selectedFilter.blueprints = true

    setIsLoadingProperties(true)
    try {
      const query = queryString.stringify(selectedFilter)

      const { data } = await axios.get(`/v1/properties?${query}`)
      setProperties(data)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setIsLoadingProperties(false)
  }

  useEffect(() => {
    fetchProperties()
  }, [filter])

  useEffect(() => {
    if (!getLocalFilter('propertiesFilter')) {
      // Set default filter on load
      setLocalFilter(defaultFilter, true, 'propertiesFilter', setFilter)
    }
  }, [])

  useEffect(() => {
    const sum = {
      blueprints: 0,
      doors: 0,
      cylinders: 0,
      hotspots: 0
    }
    properties.forEach(property => {
      if (property.blueprints) {
        sum.blueprints += property.blueprints.length
        property.blueprints.forEach(blueprint => {
          blueprint.objects.forEach(object => {
            if (object.type === BlueprintObjectTypes.Door) {
              sum.doors += 1
            } else if (object.type === BlueprintObjectTypes.Hotspot) {
              sum.hotspots += 1
            }

            object.items.forEach(item => {
              if (item.type === BlueprintItemTypes.Cylinder) {
                sum.cylinders += 1
              }
            })
          })
        })
      }
    })
    setSummary(sum)
  }, [properties])

  const handleFilter = name => e => {
    const { value } = e.target
    const aFilter = { ...filter }
    if (!value || value === 'all') delete aFilter[name]
    else aFilter[name] = value

    setLocalFilter(aFilter, false, 'propertiesFilter', setFilter)
    setExpandedRows([])
  }

  const actionButtons = objectID => (
    <>
      <Button
        data-cy='button-show-overview'
        variant='outlined'
        style={{
          marginRight: 20
        }}
        onClick={() => {
          history.push(`/properties/${objectID}/overview`)
        }}
      >
        {global._('Properties.ShowOverview')}
      </Button>
      <Button
        variant='primary'
        onClick={() => history.push(`/properties/${objectID}/quoteoverview`)}
      >
        {global._('Properties.RequestForQuotation')}
      </Button>
    </>
  )

  const organisationField = () =>
    isAdmin && (
      <Grid item xs={12} sm={12} md={3}>
        <AsyncSelect
          isSearchable
          labelKey='name'
          helpText={global._('Widgets.users.FilterOnOrganisation')}
          defaultValue={defaultOrganisationOption}
          placeholder={global._('Common.SelectOrganisation')}
          onChange={opt => {
            const updatedFilter = { ...filter }

            updatedFilter.organisationId = opt ? opt.value.id : ''
            updatedFilter.organisation = opt ? opt.value : ''

            setLocalFilter(updatedFilter, false, 'propertiesFilter', setFilter)
            setOrganisation(opt ? opt.value : '')
          }}
          url='/v1/organisations?search={{input}}&orderBy=name&order=asc&ifHas=properties'
        />
      </Grid>
    )

  const searchField = () => (
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <SearchField
          style={{ width: '100%' }}
          name='properties-search'
          label={global._('Search')}
          variant='filled'
          value={filter && filter.query}
          onChange={handleFilter('query')}
          className={classes.notchedOutline}
          helperText={global._('Properties.SearchInputText')}
        />
      </FormControl>
    </Grid>
  )

  if (isMobile) {
    return (
      <PropertiesMobile
        {...props}
        properties={properties}
        handleFilter={handleFilter}
        filter={filter}
        setFilter={setFilter}
        setLocalFilter={setLocalFilter}
        setOrganisation={setOrganisation}
        defaultOrganisationOption={defaultOrganisationOption}
        error={error}
        setError={setError}
        isAdmin={isAdmin}
        organisationField={organisationField}
        searchField={searchField}
      />
    )
  }

  return (
    <div className={classes.root}>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      <PageContentHeader
        breadcrumbItems={breadcrumbItems}
        actionButtons={
          <Button
            data-cy='button-add-property'
            variant='primary'
            onClick={() => setIsCreateModalOpen(true)}
            style={{ marginLeft: 'auto' }}
          >
            {global._('Properties.CreateNewProperty')}
          </Button>
        }
      />

      <Paper
        smallerPadding
        style={{ justifyContent: 'space-between', marginBottom: 20 }}
      >
        <Grid container spacing={4}>
          {searchField()}
          {organisationField()}
        </Grid>
      </Paper>
      {properties.length > 0 && (
        <Paper
          smallerPadding
          style={{ justifyContent: 'space-between', marginBottom: 20 }}
        >
          <Row align='center' justify='space-between' style={{ width: '100%' }}>
            <Typography variant='title' bold>
              {global._('Properties.SummaryTitle')}
            </Typography>
            <Button
              data-cy='button-show-overview'
              variant='outlined'
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                let url = '/properties/overview/'
                if (isAdmin) {
                  url += filter.organisationId
                } else {
                  url += user.organisation.id
                }
                history.push(url)
              }}
            >
              {global._('Properties.ShowOverview')}
            </Button>
          </Row>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <SummaryBadge
              label={global._('Properties.Summary.Blueprints')}
              count={summary.blueprints}
            />
            <SummaryBadge
              label={global._('Properties.Summary.Doors')}
              count={summary.doors}
            />
            <SummaryBadge
              label={global._('Properties.Summary.Cylinders')}
              count={summary.cylinders}
            />
            <SummaryBadge
              label={global._('Properties.Summary.Hotspots')}
              count={summary.hotspots}
            />
          </div>
        </Paper>
      )}
      <ObjectList
        sectionTitle={global._('Properties.Title')}
        infoDialogTitle={global._('Properties.InformationDialogTitle')}
        infoDialogContent={
          <div style={{ textAlign: 'left' }}>
            <Typography
              style={{ display: 'block', paddingBottom: 12 }}
              variant='body'
            >
              {global._('Properties.InfoDialogTitle')}
            </Typography>
            <Typography
              style={{ display: 'block', paddingBottom: 12 }}
              variant='bodyItalic'
            >
              {global._('Properties.InfoDialogContent')}
            </Typography>
          </div>
        }
        noResultsMessage={`${global._(
          'Properties.NoExistingProperties'
        )} <br /> ${global._('Properties.AddPropertyText')}`}
        columns={columns}
        objects={properties}
        isLoadingObjects={isLoadingProperties}
        history={history}
        objectPermalink='properties'
        filter={getLocalFilter('propertiesFilter')}
        handleFilter={handleFilter}
        setFilter={setFilter}
        setLocalFilter={setLocalFilter}
        localFilterName='propertiesFilter'
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        actionButtons={actionButtons}
        instructionsVideoId={InstructionsVideoIds[lang].PROPERTIES}
      />
      {isCreateModalOpen && (
        <CreatePropertyModal
          defaultOrganisation={organisation}
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}
    </div>
  )
}

Properties.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array
}

Properties.defaultProps = {
  breadcrumbItems: []
}

const PropertiesWithStyle = injectSheet(styles)(Properties)
export default PropertiesWithStyle
