import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import green from '@material-ui/core/colors/green'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles } from '@material-ui/core/styles'

// const styles1 = theme => ({
//   success: {
//     backgroundColor: green[600]
//   },
//   error: {
//     backgroundColor: theme.palette.error.dark
//   },
//   icon: {
//     fontSize: 20
//   },
//   iconVariant: {
//     opacity: 0.9,
//     marginRight: theme.spacing()
//   },
//   message: {
//     display: 'flex',
//     alignItems: 'center'
//   }
// })
//

// // class MySnackbarContent extends React.Component {
// //   render() {
// //     const {
// //       classes, className, message, onClose, variant
// //     } = this.props
// //     const Icon = variantIcon[variant]
// //     return (
// //       <SnackbarContent
// //         className={classNames(classes[variant], className)}
// //         aria-describedby='client-snackbar'
// //         message={(
// //           <span id='client-snackbar' className={classes.message}>
// //             <Icon className={classNames(classes.icon, classes.iconVariant)} />
// //             {message}
// //           </span>
// //       )}
// //         action={[
// //           <IconButton
// //             key='close'
// //             aria-label='Close'
// //             color='inherit'
// //             className={classes.close}
// //             onClick={onClose}
// //           >
// //             <CloseIcon className={classes.icon} />
// //           </IconButton>
// //         ]}
// //       />
// //     )
// //   }
// // }
// //
// // MySnackbarContent.defaultProps = {
// //   variant: null,
// //   message: null
// // }
// //
// // MySnackbarContent.propTypes = {
// //   classes: PropTypes.object.isRequired,
// //   className: PropTypes.string.isRequired,
// //   message: PropTypes.string,
// //   onClose: PropTypes.func.isRequired,
// //   variant: PropTypes.oneOf(['success', 'error'])
// // }
// //
// // const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent)

const styles = theme => ({
  margin: {
    margin: theme.spacing()
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing()
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
})

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
}

// eslint-disable-next-line
class CustomizedSnackbars extends React.Component {
  handleClose = () => {
    this.props.toggleSnackbar()
  }

  render() {
    const { message, variant, openSnackbar, classes } = this.props
    const Icon = variantIcon[variant]
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={this.handleClose}>
          <SnackbarContent
            className={classNames(classes[variant])}
            aria-describedby='client-snackbar'
            message={
              <span id='client-snackbar' className={classes.message}>
                <Icon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
                {message}
              </span>
            }
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                className={classes.close}
                onClick={this.handleClose}>
                <CloseIcon className={classes.icon} />
              </IconButton>
            ]}
          />
        </Snackbar>
      </React.Fragment>
    )
  }
}

CustomizedSnackbars.defaultProps = {
  message: null,
  variant: null,
  toggleSnackbar: null,
  classes: null,
  openSnackbar: null
}

CustomizedSnackbars.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
  openSnackbar: PropTypes.bool,
  toggleSnackbar: PropTypes.func,
  classes: PropTypes.object
}

export default withStyles(styles)(CustomizedSnackbars)
