/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import FaceIcon from '@material-ui/icons/Face'
import InfoIcon from '@material-ui/icons/Info'
import HelpIcon from '@material-ui/icons/Help'
import { Icon } from '@material-ui/core'
import Typography from './Typography'
import Paper from './Paper'
import useWindowDimensions from '../utils/useWindowDimensions'

const styles = {
  rootWithoutShadow: {
    borderRadius: 3,
    border: '1px solid var(--color-middle-grey-2)'
  },
  paper: {
    padding: props => (props.compact ? '12px 16px' : '30px 40px 40px 30px'),
    backgroundColor: 'var(--color-primary-tone)',
    border: 0,
    position: 'relative',
    width: '100%'
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: props => (props.alignCenter ? 'center' : undefined)
  },
  alertTitle: {
    marginBottom: 20
  },
  icon: {
    fontSize: '36px !important',
    color: 'var(--color-primary-tone-2)'
  },
  rightBottom: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    zIndex: 9999,
    width: '100%'
  },
  small: {
    maxWidth: 390
  },
  closeIcon: {
    position: 'absolute',
    top: 20,
    right: 20,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.85
    }
  },
  '@media (max-width: 900px)': {
    paper: {
      padding: 'var(--section-padding-default)',
      flexDirection: props => (props.compact ? 'row' : undefined),
      alignItems: props => (props.compact ? 'center' : undefined)
    },
    contentWrapper: {
      marginBottom: props => (props.compact ? undefined : 20),
      width: props => (props.compact ? 'auto' : '100%'),
      alignItems: 'center'
    },
    icon: {
      marginRight: props => (props.compact ? 15 : undefined)
    },
    alertTitle: {
      marginBottom: 0
    },
    rightBottom: {
      right: 20,
      bottom: 20,
      width: 'calc(100% - 40px)'
    }
  }
}

const InformationBar = ({
  classes,
  icon,
  title,
  subtitle,
  message,
  closeBtn,
  closeBtnAction,
  noShadow
}) => {
  let IconComponent

  if (icon === 'face') IconComponent = FaceIcon
  if (icon === 'info') IconComponent = InfoIcon
  if (icon === 'help') IconComponent = HelpIcon

  const { isMobile } = useWindowDimensions()

  return (
    <div
      className={`${classes.InformationBarContainer} ${
        noShadow ? classes.rootWithoutShadow : ''
      }`}
    >
      <Paper classes={classes} noShadow={noShadow}>
        <div className={classes.contentWrapper}>
          <IconComponent className={classes.icon} />
          <div
            style={{
              marginLeft:
                title || subtitle || (message && !isMobile) ? 20 : undefined
            }}
          >
            {title ? (
              <Typography
                variant='alertTitle'
                bold
                align='left'
                classes={classes}
                style={{ padding: 0 }}
              >
                {title}
              </Typography>
            ) : null}
            {subtitle ? (
              <Typography
                variant='subtitleBig'
                bold
                align='left'
                classes={classes}
              >
                {subtitle}
              </Typography>
            ) : null}
            {message && !isMobile ? (
              <Typography
                variant='body'
                align='left'
                dangerouslySetInnerHTML={{ __html: message }}
              />
            ) : null}
          </div>
        </div>
        {message && isMobile ? (
          <Typography variant='body' align='left'>
            {message}
          </Typography>
        ) : null}
        {closeBtn ? (
          <div onClick={() => closeBtnAction()} className={classes.closeIcon}>
            <Icon>clear</Icon>
          </div>
        ) : null}
      </Paper>
    </div>
  )
}

InformationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  message: PropTypes.node,
  closeBtn: PropTypes.bool,
  closeBtnAction: PropTypes.func,
  noShadow: PropTypes.bool
}

InformationBar.defaultProps = {
  icon: 'info',
  title: '',
  subtitle: null,
  message: '',
  closeBtn: false,
  closeBtnAction: () => '',
  noShadow: false
}

export default injectSheet(styles)(InformationBar)
