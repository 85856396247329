import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Divider from '@material-ui/core/Divider'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  MenuItem,
  TextField,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import EcoIcon from '@material-ui/icons/Eco'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { getSelectedOrg } from 'src/utils/helpers'
import AsyncSelect from 'src/components/AsyncSelect'
import SelectedOrganisation from 'src/components/SelectedOrganisation'
import { DatePicker } from '@material-ui/pickers'
import { Today } from '@material-ui/icons'
import moment from 'moment'
import Paper from '../../../components/Paper'
import Typography from '../../../components/Typography'
import {
  CaseTypes,
  CaseTypeActions,
  CaseTypeActionSubcategories,
  ClaimReplacement,
  ClaimFactoryReset,
  ClaimReturnTo,
  CaseStatus,
  UserRoles
} from '../../../utils/constants'
import Button from '../../../components/Button'
import DragZone from '../../../components/DragAndDrop/DragZone'
import FileContainer from '../../../components/FileContainer'
import Alert from '../../../components/Alert'
import Notification from '../../../common/Notification'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import FullscreenModal from '../../../components/FullscreenModal'
import PageContentHeader from '../../../components/PageContentHeader'
import Modal from '../../../components/Modal'
import { detectMimeType } from '../../../utils/mime'

const styles = {
  root: {},
  paper: {
    maxWidth: 600,
    width: '100%',
    paddingTop: 30,
    paddingBottom: 30
  },
  breadcrumbs: {
    position: 'static'
  },
  newProductRadioLbl: {
    borderBottom: '1px solid var(--divider-color)',
    marginRight: '70px !important',
    minWidth: 160,
    justifyContent: 'space-between'
  },
  returnToRadioLbl: {
    borderBottom: '1px solid var(--divider-color)',
    justifyContent: 'space-between'
  },
  newProductRadioGrp: {
    flexDirection: 'row'
  },
  returnToRadioGrp: {
    flexDirection: 'column'
  },
  container: {
    width: 500,
    height: 393,
    margin: '0 auto',
    display: 'flex'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    borderRadius: 500,
    backgroundColor: 'var(--color-primary-tone-3)',
    marginBottom: 38
  },
  iconWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex'
  },
  '@media (max-width: 900px)': {
    container: {
      width: 'auto',
      height: 'auto',
      display: 'flex',
      padding: 20,
      justifyContent: 'center'
    },
    iconContainer: {
      alignSelf: 'center'
    }
  }
}

const Wrapper = props => {
  const { isMobile } = useWindowDimensions()

  if (isMobile) return <div {...props} />

  return <Paper {...props} />
}

const NewCase = ({ user, history, classes, breadcrumbItems }) => {
  const AllowedFileSize = 50 * 1000 * 1000 // 50 MB
  const AllowedFileTypes = [
    'image/jpeg',
    'image/png',
    'image/vnd.dwg', // .dwg
    'image/x-dwg', // .dwg
    'application/acad', // .dwg
    'application/pdf',
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel', // .xls, .xlt, .xla
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template', // .xltx
    'video/mp4', // .mp4
    'video/quicktime' // .mov
  ]

  const { isMobile } = useWindowDimensions()

  const [files, setFiles] = useState([])
  const [caseCreated, setCaseCreated] = useState(false)
  const [error, setError] = useState(null)
  const [fileMarkedForDelete, setFileMarkedForDelete] = useState(null)

  const isPartnerAdmin =
    user &&
    Array.isArray(user.roles) &&
    user.roles.includes(UserRoles.PartnerAdmin)
  const isSupport =
    user && Array.isArray(user.roles) && user.roles.includes(UserRoles.Support)

  const selectedOrg = getSelectedOrg(user)

  if (isPartnerAdmin || isSupport) {
    if (!selectedOrg) {
      history.push('/cases/select-organisation')
    }
  }

  delete CaseTypes.Order

  const handleFormSubmit = async values => {
    const valuesObj = { ...values }
    let propAddress

    if (
      [CaseTypes.Service, CaseTypes.Support].includes(valuesObj.type) &&
      values.systemObj
    ) {
      propAddress = values.systemObj.value.address
    }

    if (valuesObj.type === CaseTypes.Feedback) {
      valuesObj.header =
        valuesObj.body && typeof valuesObj.body === 'string'
          ? `${valuesObj.body.split(' ').slice(0, 6).join(' ')}...`
          : ''
    }

    if (values.systemObj) {
      valuesObj.system = values.systemObj.label
      delete valuesObj.systemObj
    }

    let header = ''
    if (valuesObj.body) {
      // eslint-disable-next-line prefer-destructuring
      ;[header] = valuesObj?.body?.split('\n')
      if (header?.length > 40) {
        header = `${header.substring(0, 40)}..`
      }
    }
    try {
      const newCase = {
        ...valuesObj,
        header,
        files,
        status: CaseStatus.New,
        address: propAddress || valuesObj.address,
        organisationId: selectedOrg.id
      }
      await axios.post('v1/cases/', newCase)
      setCaseCreated(true)
      setError()
    } catch (e) {
      let msg = e.response ? e.response.data : e.message
      if (typeof e?.response?.data === 'object') {
        msg = JSON.stringify(e.response.data)
      }
      setError(global._(msg))
    }
  }

  const onFileSelected = selectedFile => {
    const { name, size } = selectedFile
    let errorCode

    if (!selectedFile.type) {
      // eslint-disable-next-line no-param-reassign
      selectedFile.type = detectMimeType(name)
    }

    if (!AllowedFileTypes.includes(selectedFile.type)) {
      errorCode = 'ERR_FILE_TYPE'
      setError(errorCode)
    } else if (size > AllowedFileSize) {
      errorCode = 'ERR_FILE_SIZE'
      setError(errorCode)
    } else {
      try {
        const updatedFiles = [...files, selectedFile]
        setFiles(updatedFiles)
      } catch (e) {
        errorCode = e.response ? e.response.data : e.message
        setError(global._(errorCode))
      }
    }
  }

  const onRemoveFile = fileObj => {
    try {
      const updatedFiles = files.filter(file => file.name !== fileObj.name)
      setFiles(updatedFiles)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      setError(global._(msg))
    }
    setFileMarkedForDelete(null)
  }

  const textField = (fieldName, fieldType, required, props, initialValue) => {
    const textFieldObj = {
      fieldName,
      fieldType,
      required,
      props,
      initialValue: initialValue || ''
    }

    return textFieldObj
  }

  const selectField = (
    fieldName,
    fieldType,
    required,
    props,
    options,
    initialValue,
    hasEmptyOption
  ) => {
    const selectFieldObj = {
      fieldName,
      fieldType,
      required,
      props: {
        ...props,
        select: true
      },
      options,
      initialValue: initialValue || '',
      hasEmptyOption: hasEmptyOption || false
    }

    return selectFieldObj
  }

  const asyncSelectField = (fieldName, required, props) => {
    const selectFieldObj = {
      fieldName,
      fieldType: AsyncSelect,
      required,
      props: {
        ...props
      }
    }

    return selectFieldObj
  }

  const radioButtonsField = (
    fieldName,
    fieldType,
    required,
    props,
    options
  ) => {
    const radioButtonsFieldObj = {
      fieldName,
      fieldType,
      required,
      props: {
        ...props,
        select: true
      },
      options,
      initialValue: ''
    }

    return radioButtonsFieldObj
  }

  const dateField = (fieldName, required, props, initialValue) =>
    textField(
      fieldName,
      DatePicker,
      required,
      {
        InputProps: {
          endAdornment: <Today style={{ cursor: 'pointer' }} />
        },
        inputVariant: 'filled',
        label: global._('Choose'),
        format: 'YYYY-MM-DD',
        cancelLabel: null,
        okLabel: null,
        autoOk: true,
        handleChange: (date, setFieldValue) =>
          setFieldValue(fieldName, date.format('YYYY-MM-DD')),
        ...props
      },
      initialValue || moment().format('YYYY-MM-DD')
    )

  const titleFieldObj = textField('header', TextField, true, {
    label: global._('Cases.Title')
  })

  const descriptionFieldObj = placeholder =>
    textField('body', TextField, true, {
      label: global._('Common.Description'),
      placeholder: placeholder ? global._(placeholder) : undefined,
      multiline: true,
      rows: 4
    })

  const categoryFieldObj = caseType =>
    selectField(
      'action',
      TextField,
      true,
      {
        label: global._('Cases.NewCase.Category')
      },
      CaseTypeActions[CaseTypes[caseType]].map(caseTypeActionKey => {
        if (
          caseTypeActionKey === 'NEW_ORDER' ||
          caseTypeActionKey === 'LOST' ||
          caseTypeActionKey === 'RECLAIM'
        ) {
          return ''
        }

        return {
          key: caseTypeActionKey,
          value: global._(`CaseTypeActions.${caseTypeActionKey}`)
        }
      })
    )

  const subcategoryFieldObj = (caseType, action) => {
    if (!action || !CaseTypeActionSubcategories[caseType][action]) {
      return null
    }
    return selectField(
      'subcategory',
      TextField,
      true,
      {
        label: global._('Cases.NewCase.Subcategory')
      },
      CaseTypeActionSubcategories[caseType][action].map(
        caseTypeActionSubcategoryKey => ({
          key: caseTypeActionSubcategoryKey,
          value: global._(
            `CaseTypeActionSubcategories.${caseTypeActionSubcategoryKey}`
          )
        })
      )
    )
  }

  const priorityFieldObj = priority =>
    selectField(
      'priority',
      TextField,
      false,
      {
        label: global._('Cases.Priority')
        // helperText: priority
        //   ? global._(`Cases.Priority.HelperText.${priority}`)
        //   : undefined
      },
      [
        { key: 'LOW', value: global._('Cases.Priority.LOW') },
        { key: 'NORMAL', value: global._('Cases.Priority.NORMAL') },
        {
          key: 'HIGH',
          value: global._('Cases.Priority.HIGH'),
          disabled: !selectedOrg.hasServiceAgreement
        }
      ],
      '',
      true
    )

  const propertiesFieldObj = asyncSelectField('systemObj', false, {
    label: global._('Cases.NewCase.Property'),
    labelKey: 'name',
    noOptionsMessage: () => global._('Common.NoOptionsMessage'),
    placeholder: global._('Common.SelectProperty'),
    url: `/v1/properties?&query={{input}}&orderBy=name&order=asc&organisationId=${selectedOrg.id}`,
    handleChange: (e, setFieldValue) => setFieldValue('systemObj', e)
  })

  const contactFieldObj = textField('contact', TextField, false, {
    label: global._('Cases.NewCase.Contact'),
    helperText: global._('Cases.NewCase.ContactHelpText')
  })
  const systemNumberFieldObj = props =>
    textField('systemNumber', TextField, false, {
      label: global._('Cases.NewCase.SystemNumber'),
      ...props
    })

  const referenceFieldObj = labelLocalizationKey =>
    textField('reference', TextField, false, {
      label: global._(labelLocalizationKey || 'Cases.NewCase.Reference'),
      helperText: global._('Cases.NewCase.ReferenceExample')
    })

  // const urgentCaseFieldObj = {
  //   fieldName: 'urgent',
  //   fieldType: 'checkbox',
  //   props: {
  //     helperText: global._('Cases.NewCase.UrgentCaseHelper')
  //   },
  //   parentProps: {
  //     label: global._('Cases.NewCase.UrgentCase')
  //   },
  //   initialValue: false
  // }

  let callCaseFieldObj
  if (selectedOrg.hasDutyAgreement) {
    callCaseFieldObj = {
      fieldName: 'onCall',
      fieldType: 'checkbox',
      props: {
        helperText: global._('Cases.NewCase.CallCaseHelper')
      },
      parentProps: {
        label: global._('Cases.NewCase.CallCase')
      },
      initialValue: false
    }
  }

  const attachmentFieldObj = {
    fieldName: 'attachment',
    fieldType: DragZone,
    name: 'files',
    type: 'file',
    className: 'inputfile',
    initialValue: ''
  }

  const formFields = formValues => ({
    type: selectField(
      'type',
      TextField,
      true,
      {
        label: global._('Cases.NewCase.CaseType'),
        handleChange: (e, setFieldValue, resetForm) => {
          resetForm() // Clear form on type change
          setFieldValue('type', e.target.value)
        }
      },
      [
        CaseTypes.Key,
        CaseTypes.Service,
        CaseTypes.Support,
        CaseTypes.Relocation,
        CaseTypes.Other
      ].map(caseTypeKey => ({
        key: caseTypeKey,
        value: global._(`CaseTypes.${caseTypeKey}`)
      }))
    ),
    caseTypeSpecificFields: {
      [CaseTypes.Key]: {
        action: categoryFieldObj('Key'),
        subcategory: subcategoryFieldObj('KEY', formValues?.action),
        // header: titleFieldObj,
        body: descriptionFieldObj(
          formValues?.action &&
            `CaseTypeActionSubcategoriesDescriptionPlaceholders.KEY.${formValues.action}.${formValues?.subcategory}`
        ),
        systemNumber:
          ['NEW', 'BLOCK_AND_REPLACE_KEY'].includes(formValues?.action) &&
          formValues?.subcategory === 'MECHANICAL'
            ? systemNumberFieldObj()
            : undefined,
        marking: textField('marking', TextField, false, {
          label: global._(
            formValues?.action && formValues?.subcategory
              ? `CaseTypeActionSubcategory_Marking.KEY.${formValues.action}.${formValues.subcategory}`
              : 'Cases.NewCase.Keys.KeyMarking'
          )
        }),
        address: textField('address', TextField, false, {
          label: global._('Cases.NewCase.CurrentAddress'),
          helperText: global._('Cases.NewCase.CurrentAddressAlternative')
        }),
        // contact: contactFieldObj,
        reference: referenceFieldObj(),
        priority: priorityFieldObj(formValues?.priority),
        onCall: callCaseFieldObj,
        attachment: attachmentFieldObj
      },
      [CaseTypes.Service]: {
        action: categoryFieldObj('Service'),
        body: descriptionFieldObj(),
        system: propertiesFieldObj,
        space: textField('space', TextField, false, {
          label: global._('Cases.NewCase.Space')
        }),
        productType: ['TROUBLESHOOTING', 'INSTALLATION'].includes(
          formValues?.action
        )
          ? textField('productType', TextField, true, {
              label: global._('Cases.NewCase.Product'),
              helperText: global._('Cases.NewCase.ProductExample')
            })
          : undefined,
        reference: referenceFieldObj(),
        priority: ['TROUBLESHOOTING', 'INSTALLATION'].includes(
          formValues?.action
        )
          ? priorityFieldObj(formValues?.priority)
          : undefined,
        onCall: ['TROUBLESHOOTING'].includes(formValues?.action)
          ? callCaseFieldObj
          : undefined,
        attachment: attachmentFieldObj
      },
      [CaseTypes.Support]: {
        action: categoryFieldObj('Support'),
        body: descriptionFieldObj(),
        system: ['SOFTWARE', 'LOCK_SYSTEM', 'OTHER'].includes(
          formValues?.action
        )
          ? propertiesFieldObj
          : undefined,
        space: ['SOFTWARE', 'LOCK_SYSTEM', 'OTHER'].includes(formValues?.action)
          ? textField('space', TextField, false, {
              label: global._('Cases.NewCase.Space')
            })
          : undefined,

        productType: ['SOFTWARE', 'OTHER'].includes(formValues?.action)
          ? textField('productType', TextField, false, {
              label: global._(`Cases.NewCase.ProductType.${formValues.action}`),
              helperText: global._(
                `Cases.NewCase.ProductTypeHelperText.${formValues.action}`
              )
            })
          : undefined,
        systemNumber: ['LOCK_SYSTEM'].includes(formValues?.action)
          ? systemNumberFieldObj({
              helperText: global._(
                'Cases.NewCase.SystemNumberMechanicalHelperText'
              )
            })
          : undefined,
        contact: contactFieldObj,
        reference: referenceFieldObj('Cases.NewCase.Reference2'),
        priority: ['SOFTWARE', 'LOCK_SYSTEM', 'OTHER'].includes(
          formValues?.action
        )
          ? priorityFieldObj(formValues?.priority)
          : undefined,
        onCall: ['SOFTWARE', 'OTHER'].includes(formValues?.action)
          ? callCaseFieldObj
          : undefined,
        attachment: attachmentFieldObj
      },
      [CaseTypes.Relocation]: {
        body: descriptionFieldObj(`CaseTypeDescriptionPlaceholderRelocation`),
        transferDate: dateField('transferDate', true, {
          label: global._('Case.TransferDate')
        }),
        apartmentNumber: textField('apartmentNumber', TextField, true, {
          label: global._('Case.ApartmentNumber')
        }),
        // contact: contactFieldObj,
        reference: referenceFieldObj(),
        priority: priorityFieldObj(formValues?.priority),
        onCall: callCaseFieldObj,
        attachment: attachmentFieldObj
      },
      [CaseTypes.Other]: {
        body: descriptionFieldObj(`CaseTypeDescriptionPlaceholderRelocation`),
        // contact: contactFieldObj,
        reference: referenceFieldObj(),
        priority: priorityFieldObj(formValues?.priority),
        onCall: callCaseFieldObj,
        attachment: attachmentFieldObj
      }
      // [CaseTypes.Claim]: {
      //   system: propertiesFieldObj,
      //   header: titleFieldObj,
      //   body: descriptionFieldObj(),
      //   productType: textField('productType', TextField, true, {
      //     label: global._('Cases.NewCase.Product'),
      //     helperText: global._('Cases.NewCase.ProductExample')
      //   }),
      //   marking: textField('marking', TextField, true, {
      //     label: global._('Cases.NewCase.Marking'),
      //     helperText: global._('Cases.NewCase.MarkingExample')
      //   }),
      //   serialNumber: textField('serialNumber', TextField, true, {
      //     label: global._('Cases.NewCase.SerialNumber')
      //   }),
      //   replacement: selectField(
      //     'replacement',
      //     TextField,
      //     true,
      //     { label: global._('Cases.NewCase.Claims.ProductReplacement') },
      //     Object.keys(ClaimReplacement).map(claimReplacementKey => ({
      //       key: ClaimReplacement[claimReplacementKey],
      //       value: global._(
      //         `ClaimReplacement.${ClaimReplacement[claimReplacementKey]}`
      //       )
      //     }))
      //   ),
      //   newProductWanted: radioButtonsField(
      //     'newProductWanted',
      //     'radioButtons',
      //     false,
      //     {
      //       formLabel: global._('Cases.NewCase.Claims.NewProductWanted'),
      //       helperText: global._(
      //         'Cases.NewCase.Claims.NewProductWantedHelperText'
      //       )
      //     },
      //     [
      //       { key: 'true', value: global._('Common.Yes') },
      //       { key: 'false', value: global._('Common.No') }
      //     ]
      //   ),
      //   factoryReset: selectField(
      //     'factoryReset',
      //     TextField,
      //     true,
      //     { label: global._('Cases.NewCase.Claims.ProductFactoryReset') },
      //     [
      //       {
      //         key: ClaimFactoryReset.Yes,
      //         value: global._(`ClaimFactoryReset.${ClaimFactoryReset.Yes}`)
      //       },
      //       {
      //         key: ClaimFactoryReset.NotPossible,
      //         value: global._(
      //           `ClaimFactoryReset.${ClaimFactoryReset.NotPossible}`
      //         )
      //       },
      //       {
      //         key: ClaimFactoryReset.Support,
      //         value: global._(`ClaimFactoryReset.${ClaimFactoryReset.Support}`),
      //         alert: global._('Cases.NewCase.Claims.SupportExtraInfo')
      //       }
      //     ]
      //   ),
      //   returnTo: radioButtonsField(
      //     'returnTo',
      //     'radioButtons',
      //     true,
      //     { formLabel: global._('Cases.NewCase.Claims.ReturnTo') },
      //     [
      //       {
      //         key: ClaimReturnTo.SendToManufacturer,
      //         value: global._(
      //           `ClaimReturnTo.${ClaimReturnTo.SendToManufacturer}`
      //         ),
      //         icon: (
      //           <EcoIcon
      //             style={{ fill: 'var(--color-success-green)', marginLeft: 5 }}
      //           />
      //         ),
      //         alert:
      //           formValues &&
      //           formValues.factoryReset &&
      //           formValues.factoryReset === ClaimFactoryReset.Yes
      //             ? global._('Cases.NewCase.Claims.ReturnToExtraInfo')
      //             : global._('Cases.NewCase.Claims.ReturnToFactoryResetInfo')
      //       },
      //       {
      //         key: ClaimReturnTo.SendToPartner,
      //         value: `${global._(
      //           `ClaimReturnTo.${ClaimReturnTo.SendToPartner}`
      //         )}`
      //       }
      //     ]
      //   ),
      //   attachment: attachmentFieldObj
      // },
      // [CaseTypes.Feedback]: {
      //   body: textField('body', TextField, true, {
      //     label: global._('Cases.NewCase.Feedback'),
      //     helperText: global._('Cases.NewCase.TypeYourFeedbackHere'),
      //     multiline: true,
      //     rows: 4
      //   }),
      //   attachment: attachmentFieldObj
      // }
    }
  })

  const printFormField = (
    fieldData,
    handleChange,
    handleBlur,
    formValues,
    errors,
    setFieldValue,
    resetForm
  ) => {
    if (!fieldData) return
    const { fieldType: FieldType, fieldName } = fieldData
    let helperText =
      fieldData.props && fieldData.props.helperText
        ? fieldData.props.helperText
        : null

    if (fieldData.required) {
      helperText = fieldData.props.helperText
        ? `${fieldData.props.helperText} - ${global._('Common.Required')}`
        : `${global._('Common.Required')}`
    }

    const optionWithAlert =
      fieldData.options &&
      fieldData.options.find(
        option => option.key === formValues[fieldName] && option.alert
      )

    if (FieldType === 'checkbox') {
      return (
        <FormControl fullWidth style={{ marginBottom: 20 }}>
          <FormControlLabel
            style={{ marginBottom: '-11px' }}
            control={
              <Checkbox
                {...fieldData.props}
                data-cy={`input-checkbox-${fieldName}`}
                value={formValues[fieldName]}
                color='primary'
                name={fieldName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            {...fieldData.parentProps}
            className={classes.checkboxWrapper}
          />
          {helperText ? (
            <FormHelperText style={{ paddingLeft: 40, marginTop: 0 }}>
              {helperText}
            </FormHelperText>
          ) : null}
        </FormControl>
      )
    }

    if (FieldType === 'radioButtons') {
      return (
        <FormControl
          component='fieldset'
          fullWidth
          style={{ marginBottom: 20 }}
        >
          {fieldData.props.formLabel && (
            <FormLabel component='legend'>
              {fieldData.props.formLabel}
            </FormLabel>
          )}
          <RadioGroup
            className={
              fieldData.fieldName === 'returnTo'
                ? classes.returnToRadioGrp
                : classes.newProductRadioGrp
            }
            aria-label={fieldName}
            name={fieldName}
            onChange={handleChange}
            value={formValues[fieldName]}
            onBlur={handleBlur}
            helperText={helperText}
          >
            {fieldData.options &&
              fieldData.options.map(option => (
                <>
                  <FormControlLabel
                    className={
                      fieldData.fieldName === 'returnTo'
                        ? classes.returnToRadioLbl
                        : classes.newProductRadioLbl
                    }
                    value={option.key}
                    labelPlacement='start'
                    control={<Radio style={{ color: 'var(--color-black)' }} />}
                    label={
                      <span>
                        {option.value}
                        {option.icon && option.icon}
                      </span>
                    }
                    key={option.key}
                  />

                  {option.alert && formValues[fieldName] === option.key ? (
                    <Alert
                      type='info'
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      {option.alert}
                    </Alert>
                  ) : null}
                </>
              ))}
            {helperText ? (
              <FormHelperText
                style={{ paddingLeft: 8, paddingTop: 12, margin: 0 }}
              >
                {helperText}
              </FormHelperText>
            ) : null}
          </RadioGroup>
        </FormControl>
      )
    }

    if (fieldData.name && fieldData.name === 'files') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 20
          }}
        >
          <Grid container>
            {files &&
              files.length > 0 &&
              files.map(fileObj => (
                <Grid
                  item
                  xs={12}
                  style={{
                    marginRight: isMobile ? undefined : 10,
                    marginBottom: 20
                  }}
                  key={fileObj.name}
                >
                  <FileContainer
                    file={fileObj}
                    name={fileObj.name}
                    size={fileObj.size}
                    type={fileObj.type}
                    data={fileObj.data}
                    onDelete={() => setFileMarkedForDelete(fileObj)}
                  />
                </Grid>
              ))}
          </Grid>
          <div style={{ maxWidth: isMobile ? undefined : 207 }}>
            <DragZone
              onFileSelected={onFileSelected}
              buttonOnly
              buttonVariant='outlined'
            />
          </div>
          <Typography
            variant='bodyFaded'
            style={{ fontStyle: 'italic', marginTop: 15 }}
          >
            {global._('Cases.NewCase.FilesInfoText')}
          </Typography>
        </div>
      )
    }

    return (
      <FormControl
        required={fieldData.required}
        fullWidth
        style={{ marginBottom: 20 }}
      >
        <FieldType
          {...fieldData.props}
          data-cy={`input-${fieldName}`}
          helperText={helperText}
          variant='filled'
          required={fieldData.required}
          value={formValues[fieldName]}
          name={fieldName}
          InputLabelProps={{ required: false }}
          onChange={e =>
            fieldData.props.handleChange
              ? fieldData.props.handleChange(e, setFieldValue, resetForm)
              : handleChange(e)
          }
          onBlur={handleBlur}
        >
          {fieldData.options && fieldData.hasEmptyOption && (
            <MenuItem value='' key=''>
              &nbsp;
            </MenuItem>
          )}
          {fieldData.options &&
            fieldData.options.map(
              option =>
                option.key &&
                option.value && (
                  <MenuItem
                    value={option.key}
                    key={option.key}
                    disabled={option.disabled}
                    style={{
                      maxWidth: 600,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'flex'
                    }}
                  >
                    <span
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineHeight: 1.5
                      }}
                    >
                      {option.value}
                    </span>
                  </MenuItem>
                )
            )}
        </FieldType>
        {optionWithAlert && optionWithAlert.key === formValues[fieldName] ? (
          <Alert type='info' style={{ marginTop: 20 }}>
            {optionWithAlert.alert}
          </Alert>
        ) : null}
      </FormControl>
    )
  }

  // Set validation schema based on case type
  const setValidationSchemaObj = values => {
    const validationSchemaObj = {}

    Object.entries(formFields()).forEach(field => {
      const [fieldKey, fieldValue] = field

      if (fieldKey === 'caseTypeSpecificFields' && values.type) {
        Object.entries(formFields()[fieldKey][values.type]).forEach(
          caseTypeField => {
            const [caseFieldKey, caseFieldObj] = caseTypeField
            if (!caseFieldObj) {
              return
            }
            validationSchemaObj[caseFieldKey] = caseFieldObj.required
              ? Yup.mixed().required()
              : Yup.mixed()
          }
        )
      } else {
        validationSchemaObj[fieldKey] = fieldValue.required
          ? Yup.mixed().required()
          : Yup.mixed()
      }
    })

    return validationSchemaObj
  }

  const validationSchema = Yup.lazy(values =>
    Yup.object().shape(setValidationSchemaObj(values))
  )

  const initialValues = {}

  // Set initial form values
  Object.entries(formFields()).forEach(field => {
    const [fieldKey, fieldValue] = field

    if (fieldKey === 'caseTypeSpecificFields') {
      Object.entries(formFields()[fieldKey]).forEach(caseTypeFields => {
        const [caseKey, caseFields] = caseTypeFields

        Object.entries(formFields()[fieldKey][caseKey]).forEach(
          caseTypeField => {
            const [caseFieldKey, caseFieldObj] = caseTypeField

            initialValues[caseFieldKey] = caseFieldObj?.initialValue || ''
          }
        )
      })
    } else {
      initialValues[fieldKey] = fieldValue.initialValue
    }
  })

  const Content = (
    <>
      {error && (
        <Notification
          type='error'
          message={error}
          style={{ margin: '20px 0' }}
        />
      )}
      {caseCreated ? (
        <div className={classes.container}>
          <Wrapper
            classes={isMobile ? undefined : classes}
            fixedWidth={isMobile ? undefined : true}
            className={isMobile ? classes.iconWrapper : undefined}
            alignItems='center'
          >
            <div className={classes.iconContainer}>
              <Icon style={{ fontSize: 68 }}>check</Icon>
            </div>
            <Typography variant='subtitleBig' align='center'>
              {global._('Cases.NewCase.ConfirmText')}
            </Typography>
            <Typography variant='subtitleBig' align='center'>
              {global._('Cases.NewCase.CallbackText')}
            </Typography>
            <Button
              variant='primary'
              style={{ marginTop: 38 }}
              onClick={() => history.push('/cases')}
            >
              {global._('Cases.SectionTitle')}
            </Button>
          </Wrapper>
        </div>
      ) : (
        <>
          {fileMarkedForDelete && (
            <Modal
              onClose={() => setFileMarkedForDelete(null)}
              title={global._('CaseDeleteFileModal.Title')}
              content={
                <div style={{ textAlign: 'left' }}>
                  <Typography
                    style={{
                      display: 'block',
                      paddingBottom: 12
                    }}
                    variant='body'
                  >
                    {global._('CaseDeleteFileModal.DeleteFile')}
                  </Typography>
                  <Typography
                    style={{
                      display: 'block',
                      paddingBottom: 12
                    }}
                    variant='subtitleSmall'
                  >
                    {fileMarkedForDelete.name}
                  </Typography>
                </div>
              }
              buttons={[
                <Button
                  variant='secondary'
                  onClick={() => onRemoveFile(fileMarkedForDelete)}
                >
                  {global._('Delete')}
                </Button>,
                <Button
                  variant='none'
                  onClick={() => setFileMarkedForDelete(null)}
                >
                  {global._('Common.Cancel')}
                </Button>
              ]}
            />
          )}
          {!isMobile && (
            <PageContentHeader
              breadcrumbItems={breadcrumbItems}
              actionButtons={
                (isPartnerAdmin || isSupport) && (
                  <SelectedOrganisation
                    selectedOrg={selectedOrg}
                    history={history}
                    linkTo='/cases/select-organisation'
                  />
                )
              }
            />
          )}
          <Wrapper
            classes={isMobile ? undefined : classes}
            alignItems={isMobile ? undefined : 'left'}
            smallerPadding={isMobile ? undefined : true}
            style={{ padding: isMobile ? 20 : undefined }}
          >
            {isMobile && (isPartnerAdmin || isSupport) && (
              <SelectedOrganisation
                selectedOrg={selectedOrg}
                history={history}
                linkTo='/cases/select-organisation'
              />
            )}
            {!isMobile && (
              <>
                <Typography variant='title'>
                  {global._('Cases.NewCase.Title')}
                </Typography>
                <Divider
                  style={{ margin: 'var(--section-padding-default) -20px' }}
                />
              </>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values)
                setSubmitting(false)
              }}
            >
              {({
                submitForm,
                errors,
                touched,
                handleChange,
                values,
                handleBlur,
                setFieldValue,
                resetForm
              }) => (
                <Form>
                  {Object.keys(formFields()).map(fieldKey => {
                    const fieldData = formFields(values)[fieldKey]

                    if (fieldKey !== 'caseTypeSpecificFields') {
                      return printFormField(
                        fieldData,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        setFieldValue,
                        resetForm
                      )
                    }

                    if (values.type) {
                      return Object.keys(fieldData[values.type]).map(
                        typeFieldsKey => {
                          const typeFieldData =
                            fieldData[values.type][typeFieldsKey]

                          return printFormField(
                            typeFieldData,
                            handleChange,
                            handleBlur,
                            values,
                            errors,
                            setFieldValue,
                            resetForm
                          )
                        }
                      )
                    }
                  })}

                  <Divider
                    style={{ margin: 'var(--section-padding-default) -20px' }}
                  />
                  <div style={{ textAlign: 'right' }}>
                    {!isMobile && (
                      <Button
                        style={{ marginRight: 10 }}
                        onClick={() => history.push('/cases')}
                      >
                        {global._('Common.Cancel')}
                      </Button>
                    )}
                    <Button
                      data-cy='button-submit-case'
                      variant='primary'
                      onClick={() => submitForm()}
                      disabled={
                        Object.keys(touched).length === 0 ||
                        Object.keys(errors).length
                      }
                    >
                      {global._('Common.Send')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      )}
    </>
  )

  if (isMobile) {
    return (
      <FullscreenModal
        title={global._('Cases.NewCase.Title')}
        rightActionItem={
          <Icon onClick={() => history.push('/cases')}>close</Icon>
        }
        content={Content}
      />
    )
  }

  return <div className={classes.root}>{Content}</div>
}

NewCase.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.isRequired
}

const NewCaseWithStyles = injectSheet(styles)(NewCase)
export default NewCaseWithStyles
