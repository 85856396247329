import { Icon } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Notification from '../../../common/Notification'
import Button from '../../../components/Button'
import NavigationBar from '../../../components/NavigationBar'
import ScrollView from '../../../components/ScrollView'
import MobileContainer from '../../../components/MobileContainer'

const styles = {
  root: {},
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    marginTop: 1
  },
  input: {
    width: '100%'
  }
}

const LockDetailMobile = props => {
  const {
    classes,
    history,
    lockForm,
    error,
    signout,
    lock,
    setShowDeleteLockModal
  } = props

  return (
    <MobileContainer background='white'>
      <NavigationBar
        title={lock.name}
        backAction={() => history.push(`/admin/devices/${lock.deviceId}`)}
      />
      <ScrollView id='scrollable-container'>
        <div className={classes.rootMobile}>
          {error && (
            <Notification
              type='error'
              message={error}
              style={{ margin: '20px 0' }}
            />
          )}
          {lockForm}
          <div
            style={{
              paddingTop: 100,
              borderTop: '1px solid var(--color-divider-light)'
            }}
          >
            <Button
              variant='secondary'
              onClick={() => setShowDeleteLockModal(true)}
            >
              {global._('Locks.DeleteLock')}
            </Button>
          </div>
        </div>
      </ScrollView>
    </MobileContainer>
  )
}

LockDetailMobile.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  signout: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  lockForm: PropTypes.func.isRequired,
  setShowDeleteLockModal: PropTypes.func.isRequired,
  lock: PropTypes.object
}

LockDetailMobile.defaultProps = {
  error: undefined,
  lock: {}
}

const LockDetailMobileWithStyles = injectSheet(styles)(LockDetailMobile)
export default LockDetailMobileWithStyles
